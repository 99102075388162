import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import {
  sendRequestCorporateData,
  getCorporateData
} from './CorporateLevelPageHooks';
import { getLabel } from './../utils';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import 'moment/locale/es.js';
import Alert from 'react-bootstrap/Alert';
//CSS
import './CorporateLevelPage.css';
import ModalCorporate from '../KYCModals/ModalCorporate';
import ModalCorporateCompanyShareholder from '../KYCModals/ModalCorporateCompanyShareholder';
import ModalCorporatePersonShareholder from '../KYCModals/ModalCorporatePersonShareholder';
import CorporateShareholdersListBeta from '../KYCModals/CorporateShareholdersListBeta';
import ModalCorporateRepresentativeLegalShareholder from '../KYCModals/ModalCorporateRepresentativeLegalShareholder';
import { confirmAlert } from 'react-confirm-alert';
// import Countries from '../../components/Countries';

const RobotSvg = require('../../images/robot.svg');
const corporateLevelPageClasses = getBEMClasses('corporatelevel-page');

const CorporateLevelPageBeta = (props, context) => {
  const { selectedAccountId, userInfo, accounts } = props;
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>{context.t('Loading...')}</div>
        </React.Fragment>
      );
    }
    const [recordId, setRecordId] = useState(null);
    const [companyMasterId, setCompanyMasterId] = useState(null);
    const [corporateData, setCorporateData] = useState({});

    const [corporateDataStatus, setCorporateDataStatus] = useState({});
    const [corporateDataShareholders, setCorporateDataShareholders] = useState(
      []
    );

    const [charged, setCharged] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(0);
    const [level, setLevel] = useState({});
    /* inicia modal Corporate */
    const [showCorporate, setShowCorporate] = useState(false);
    const handleCorporateClose = () => setShowCorporate(false);
    const handleCorporateShow = () => setShowCorporate(true);
    /* inicia modal Corporate Legal Representative  */
    const [
      showCorporateRepresentativeLegal,
      setShowCorporateRepresentativeLegal
    ] = useState(false);
    const handleCorporateRepresentativeLegalClose = () =>
      setShowCorporateRepresentativeLegal(false);
    const handleCorporateRepresentativeLegalShow = recordId => {
      setRecordId(recordId);
      setShowCorporateRepresentativeLegal(true);
    };
    /* inicia modal Corporate company shareholder */
    const [showCorporateCompany, setShowCorporateCompany] = useState(false);
    const handleCorporateCompanyClose = () => setShowCorporateCompany(false);
    const handleCorporateCompanyShow = recordId => {
      setRecordId(recordId);
      setShowCorporateCompany(true);
    };
    /* inicia modal Corporate person shareholder */
    const [showCorporatePerson, setShowCorporatePerson] = useState(false);
    const handleCorporatePersonClose = () => setShowCorporatePerson(false);
    const handleCorporatePersonShow = recordId => {
      setRecordId(recordId);
      setShowCorporatePerson(true);
    };
    // set MasterID
    const assingMasterId = id => {
      setCompanyMasterId(id);
    };

    async function sendRequest() {
      setLoading(1);
      try {
        let result = await sendRequestCorporateData(
          userInfo.UserId,
          selectedAccountId
        );
        if (result.data.message === 'success') {
          toast.success(
            context.t(
              'Your request is under review. Within the next 24 business hours, you will receive a notification via email.'
            ),
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
          setTimeout(function() {
            window.location.reload(false);
          }, 3000);
        } else {
          console.log('result', result.data.message);
          toast.warn(context.t('An error has occurred'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } catch (error) {
        console.log('error getCorporateData', error);
        toast.warn(context.t('An error has occurred'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }

    const alertIfComplete = (id, isShareholder) => {
      let counterEdit = 0;
      let corporateDataEdit = !empty(corporateData.editFields)
        ? JSON.parse(corporateData.editFields)
        : [];
      if (!empty(corporateDataEdit)) {
        if (!isShareholder && corporateData.id === id) {
        } else {
          counterEdit++;
        }
      }
      for (let s in corporateDataShareholders) {
        let sh = corporateDataShareholders[s];
        let shareholdersEditField = !empty(sh.editFields)
          ? JSON.parse(sh.editFields)
          : [];
        if (!empty(shareholdersEditField)) {
          if (isShareholder && sh.id === id) {
          } else {
            counterEdit++;
          }
        }
      }

      if (counterEdit > 0) {
        confirmAlert({
          title: context.t('Good!'),
          message: context.t(
            'You have corrected this record, you still have some data to correct, when you are ready Send Request'
          ),
          buttons: [
            {
              label: context.t('Got it'),
              onClick: () => {
                window.location.reload(false);
              }
            }
          ]
        });
      } else {
        confirmAlert({
          title: context.t('Excelent!'),
          message: context.t(
            'You have completed all the corrections, do you want to request a review of your application to Banexcoin?'
          ),
          buttons: [
            {
              label: context.t('Send Request'),
              onClick: () => sendRequest()
            },
            {
              label: context.t('Cancel'),
              onClick: () => {
                window.location.reload(false);
              }
            }
          ]
        });
      }
    };

    const sendRequestAlert = e => {
      let counterEdit = 0;
      let corporateDataEdit = !empty(corporateData.editFields)
        ? JSON.parse(corporateData.editFields)
        : [];
      if (!empty(corporateDataEdit)) {
        counterEdit++;
      }
      for (let s in corporateDataShareholders) {
        let sh = corporateDataShareholders[s];
        let shareholdersEditField = !empty(sh.editFields)
          ? JSON.parse(sh.editFields)
          : [];
        if (!empty(shareholdersEditField)) {
          counterEdit++;
        }
      }
      if (counterEdit > 0) {
        confirmAlert({
          title: context.t('Warning'),
          message: context.t('Make sure to correct all the data.'),
          buttons: [
            {
              label: context.t('Cancel'),
              onClick: () => {}
            }
          ]
        });
      } else {
        confirmAlert({
          title: context.t('Are you sure you want to send your request?'),
          message: context.t('Please make sure to fill in all shareholders.'),
          buttons: [
            {
              label: context.t('Send'),
              onClick: () => sendRequest()
            },
            {
              className: 'secondary-color',
              label: context.t('Cancel'),
              onClick: () => {}
            }
          ]
        });
      }
    };

    async function loadLevel() {
      setLoading(1);

      try {
        let result = await getCorporateData(userInfo.UserId, selectedAccountId);
        if (
          !empty(result) &&
          !empty(result.data) &&
          !empty(result.data.corporatedata)
        ) {
          let inputs = JSON.parse(result.data.corporatedata.dataForm);
          let level = {
            ...inputs,
            id: result.data.corporatedata.id,
            reviewStatus: result.data.corporatedata.reviewStatus,
            status: result.data.corporatedata.status,
            editFields: result.data.corporatedata.editFields
          };
          setCorporateData(level);
          assingMasterId(result.data.corporatedata.id);
          let shareholders = [];
          let sh =
            result.data.corporatedata
              .levelsCorporateMasterShareholdersByLevelsCorporateMasterId.nodes;
          // shareholders
          let percentageNumber = 0;
          for (let i in sh) {
            let share = sh[i];
            let fields = JSON.parse(share.dataForm);

            percentageNumber += Number(fields.percentage_number);
            shareholders.push({
              ...fields,
              id: share.id,
              status: share.status,
              editFields: share.editFields,
              updated: share.updated,
              shareholderType: share.shareholderType
            });
          }
          setPercentage(percentageNumber);
          setCorporateDataStatus(result.data.corporatedata.reviewStatus);
          setCorporateDataShareholders(shareholders);
          if (
            result.data.corporatedata.reviewStatus === 'INCORRECT_INFORMATION'
          ) {
            setCharged(true);
          }
        } else {
          setCharged(true);
        }
      } catch (error) {
        console.log('error getCorporateData', error);
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }

      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(selectedAccountId)) {
          loadLevel();
        }
      },
      [selectedAccountId]
    );

    function validURL(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return !!pattern.test(str);
    }

    const GetFilename = url => {
      if (url) {
        return decodeURI(url.split('/').pop());
      }
      return '';
    };

    const PrintValues = json => {
      let editFields = !empty(json.editFields)
        ? JSON.parse(json.editFields)
        : [];
      let result = [];
      for (let i in json) {
        result.push({ [i]: json[i] });
      }
      const filesFields = result.filter(
        (element) =>
          Object.values(element)[0] &&
          String(Object.values(element)[0]).includes("http")
      );
      const sortFilesFields = [ filesFields[5], filesFields[1], filesFields[3], filesFields[2], filesFields[4], filesFields[0]].filter((item) => item);
      result = [
        ...result.filter( (item) => !String(Object.values(item)[0]).includes("http")),
        ...sortFilesFields,
      ];
      let rows = result.map((option, index) => {
        var keyName = Object.keys(option);
        var keyValue = Object.values(option);
        if (
          keyName[0] === 'ip' ||
          keyName[0] === 'id' ||
          keyName[0] === 'status' ||
          keyName[0] === 'reviewStatus'
        ) {
          return null;
        }
        if (keyValue[0] === 'undefined' || keyValue[0] === '') {
          return null;
        }
        let name = getLabel(keyName[0]);

        return (
          <React.Fragment key={index}>
            {keyName[0] !== 'editFields' ? (
              <Col xl="4">
                <Form.Group controlId={keyName[0]}>
                  <Form.Label>{context.t(name)} </Form.Label>
                  {validURL(keyValue[0]) ? (
                    <a className={editFields.includes(keyName[0]) ? 'to_correct file' : ''} href={keyValue[0]}>{GetFilename(keyValue[0])}</a>
                  ) : (
                    <Form.Control
                      className={
                        editFields.includes(keyName[0]) ? 'to_correct' : ''
                      }
                      name={keyName[0]}
                      type="text"
                      readOnly={true}
                      defaultValue={context.t(keyValue[0])}
                    />
                  )}
                </Form.Group>
              </Col>
            ) : (
              ''
            )}
          </React.Fragment>
        );
      });
      return rows;
    };

    return userInfo.Use2FA ? (
      !empty(corporateData) ? (
        <React.Fragment>
          <Loading loading={loading} />
          {showCorporateCompany && 
            <ModalCorporateCompanyShareholder
              show={showCorporateCompany}
              handleClose={handleCorporateCompanyClose}
              preId={recordId}
              companyMasterId={companyMasterId}
              loadLevel={loadLevel}
              reviewStatus={corporateData.reviewStatus}
              alertIfComplete={alertIfComplete}
            />
          }
          {showCorporatePerson && 
            <ModalCorporatePersonShareholder
              show={showCorporatePerson}
              handleClose={handleCorporatePersonClose}
              preId={recordId}
              companyMasterId={companyMasterId}
              loadLevel={loadLevel}
              reviewStatus={corporateData.reviewStatus}
              alertIfComplete={alertIfComplete}
            />
          }
          {showCorporateRepresentativeLegal && 
            <ModalCorporateRepresentativeLegalShareholder
              show={showCorporateRepresentativeLegal}
              handleClose={handleCorporateRepresentativeLegalClose}
              preId={recordId}
              companyMasterId={companyMasterId}
              loadLevel={loadLevel}
              reviewStatus={corporateData.reviewStatus}
              alertIfComplete={alertIfComplete}
            />
          }
          
          <div className={corporateLevelPageClasses()}>
            <Row className="row-header-status">
              <Col xl="6">
                <h1>{context.t('Corporate account activation request')}</h1>
                <p>
                  {context.t(
                    'You will need to provide your company information in order to activate this account as a corporate account.'
                  )}
                </p>
                <div className="percentage-box">
                  {`${percentage}%`} <b>{context.t('Share percentage')}</b>
                </div>
              </Col>
              <Col xl="3" className="status-content" />
              <Col xl="3" className="send-request-content">
                <div>
                  <h3>{context.t('Request Status')}</h3>
                  {!empty(corporateData) && corporateData.reviewStatus ? (
                    <div
                      className={'review_status ' + corporateData.reviewStatus}>
                      {context.t(getLabel(corporateData.reviewStatus))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {empty(corporateDataShareholders) ? (
                  <Button
                    className="send-request-button center"
                    onClick={() =>
                      toast.warn(
                        context.t(
                          'Debe registrar los accionistas, socios o asociados.'
                        ),
                        {
                          position: toast.POSITION.TOP_CENTER
                        }
                      )
                    }>
                    {context.t('Send Request')}
                  </Button>
                ) : !empty(corporateDataShareholders) &&
                (corporateData.reviewStatus === 'NEW_RECORD' ||
                  corporateData.reviewStatus === 'INCORRECT_INFORMATION') ? (
                  <Button
                    className="send-request-button"
                    onClick={() => {
                      sendRequestAlert();
                    }}>
                    {context.t('Send Request')}
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            {!empty(corporateData) &&
            (corporateData.reviewStatus === 'NEW_RECORD' ||
              corporateData.reviewStatus === 'INCORRECT_INFORMATION') ? (
              <Row>
                <Col>
                  <Alert variant="warning" className='p-4'>
                    {context.t(
                      'Remember: When you have uploaded all the information for this level (Majority Shareholders) and you are ready, please click the button {button}',
                      {
                        button: context.t('Send Request')
                      }
                    )}
                  </Alert>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {corporateData.reviewStatus === 'INCORRECT_INFORMATION' &&
            !empty(
              !empty(corporateData.editFields)
                ? JSON.parse(corporateData.editFields)
                : []
            ) ? (
              <ModalCorporate
                preId={corporateData.id}
                show={showCorporate}
                handleClose={handleCorporateClose}
                loadLevel={loadLevel}
                alertIfComplete={alertIfComplete}
              />
            ) : (
              ''
            )}
            <div style={{ textAlign: 'center', padding: '0rem 0 1.5rem' }}>
              {corporateData.reviewStatus === 'INCORRECT_INFORMATION' &&
              !empty(
                !empty(corporateData.editFields)
                  ? JSON.parse(corporateData.editFields)
                  : []
              ) ? (
                <Button
                  className="PendingVerifiedStatus"
                  onClick={() => {
                    handleCorporateShow();
                  }}>
                  {context.t('Edit verification')}
                </Button>
              ) : (
                ''
              )}
            </div>
            <Row>{!empty(corporateData) ? PrintValues(corporateData) : ''}</Row>
            {!empty(corporateData) &&
            empty(corporateDataShareholders) &&
            (corporateData.reviewStatus === 'NEW_RECORD' ||
              corporateData.reviewStatus === 'INCORRECT_INFORMATION') ? (
              <div className="add-shareholder-content">
                <button
                  className="add-shareholder"
                  onClick={() => {
                    handleCorporateRepresentativeLegalShow(null);
                  }}>
                  {context.t('Add Legal Representative')}
                </button>
              </div>
            ) : corporateData.reviewStatus === 'REVIEW_REQUESTED' ||
            corporateData.reviewStatus === 'ACCEPTED' ? (
              <React.Fragment />
            ) : percentage < 100 ? (
              <Row className="add-shareholder-content">
                <Row />
                <Col xl="4">
                  <button
                    className="add-shareholder"
                    onClick={() => {
                      handleCorporateRepresentativeLegalShow(null);
                    }}>
                    {context.t('Add Legal Representative')}
                  </button>
                </Col>
                <Col xl="4">
                  <button
                    className="add-shareholder"
                    onClick={() => {
                      handleCorporatePersonShow(null);
                    }}>
                    {context.t('Add a Partner or Shareholder')}
                  </button>
                </Col>
                <Col xl="4">
                  <button
                    className="add-shareholder"
                    onClick={() => {
                      handleCorporateCompanyShow(null);
                    }}>
                    {context.t('Add Company as Shareholder')}
                  </button>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            <Row>
              <Col className="info_newRecord">
                <p>
                  {context.t(
                    'Register the shareholders, partners or associates who have directly or indirectly a percentage equal to or greater than 25% of the capital stock, contribution or participation of the person or legal entity (company).'
                  )}
                </p>
              </Col>
            </Row>

            <Row>
              <CorporateShareholdersListBeta
                userInfo={userInfo}
                shareholders={corporateDataShareholders}
                selectedAccountId={selectedAccountId}
                loadLevel={loadLevel}
                corporateDataStatus={corporateDataStatus}
                handleCorporateRepresentativeLegalShow={
                  handleCorporateRepresentativeLegalShow
                }
                handleCorporatePersonShow={handleCorporatePersonShow}
                handleCorporateCompanyShow={handleCorporateCompanyShow}
              />
            </Row>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Loading loading={loading} />
          {charged ? (
            <ModalCorporate
              show={showCorporate}
              handleClose={handleCorporateClose}
              loadLevel={loadLevel}
              alertIfComplete={alertIfComplete}
            />
          ) : (
            ''
          )}
          <div className={corporateLevelPageClasses()}>
            <h1>{context.t('Corporate account activation request')}</h1>
            <p>
              {context.t(
                'You will need to provide your company information in order to activate this account as a corporate account.'
              )}
            </p>
            <div className="box-to-select">
              <button
                className="PendingVerifiedStatus"
                onClick={() => {
                  handleCorporateShow();
                }}>
                {context.t('Start Verification')}
              </button>
            </div>
          </div>
        </React.Fragment>
      )
    ) : (
      <React.Fragment>
        <div className={corporateLevelPageClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.log('error interno', e);
    return <div>Error:</div>;
  }
};

CorporateLevelPageBeta.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.object.isRequired
  }),
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(CorporateLevelPageBeta);
