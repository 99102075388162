import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputElement from "../../../../bnxv2/Components/InputElement/InputElement";
import { CardGradient, OptionsForm } from "../../../components";
import isEmpty from "is-empty";

function OtherLocationIncompleteForm(props, context) {
  const { handleSteps, setCustomAddress, deliveryAreas, setOriginForm } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [inputs, setInputs] = useState({
    country: "Perú",
  });
  const [errors, setErrors] = useState({});

  const handleSelectedOption = (title) => setSelectedOption(title);

  const customErrors = () => {
    let errors = {};
    if (isEmpty(inputs.country)) {
      errors.country = "Required field";
    }
    if (isEmpty(inputs.state)) {
      errors.state = "Required field";
    }
    if (isEmpty(inputs.city)) {
      errors.city = "Required field";
    }
    setErrors(errors);
    return errors;
  };

  const removeDuplicatedValues = (objectsArray, key) => {
    if (isEmpty(objectsArray)) return [];
    return objectsArray.reduce((acc, area) => {
      if (!acc.includes(area[key])) {
        return [...acc, area[key]];
      }
      return acc;
    }, []);
  };

  const actions = [
    {
      label: context.t("Cancel"),
      className: "secondary",
      action: () => {
        setCustomAddress("");
        handleSteps.custom(1)
      },
    },
    {
      label: context.t("Next"),
      action: () => {
        const errors = customErrors();
        if (!isEmpty(errors) || isEmpty(selectedOption)) return;
        setCustomAddress({
          ...inputs,
          address: selectedOption,
        });
        handleSteps.custom(4);
      },
      otherAttributes: {
        disabled: isEmpty(selectedOption) || !isEmpty(errors),
      }
    },
  ];

  const handleChange = (e) => {
    setSelectedOption("");
    const { name, value } = e.target;
    if (errors.hasOwnProperty(name)) {
      delete errors[name];
    }
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const countryInputProps = {
    values: {
      value: inputs.country,
      name: "country",
      type: "select",
      title: "Country",
      options: [
        {
          value: "Perú",
          label: "Perú",
        },
      ],
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.country,
  };
  const stateInputProps = {
    values: {
      value: inputs.state,
      name: "state",
      type: "select",
      title: 'State / Region / Department',
      options: removeDuplicatedValues(deliveryAreas, "department").map(
        (state) => ({ value: state, label: state })
      ),
    },
    actions: {
      onChange: (ev) => {
        handleChange(ev);
        setInputs((inputs) => ({ ...inputs, city: "" }));
      },
    },
    error: errors.state,
  };
  const cityInputProps = {
    values: {
      value: inputs.city,
      name: "city",
      type: "select",
      title: 'City / Province',
      options: isEmpty(inputs.state)  ? [] : removeDuplicatedValues(
        deliveryAreas.filter((item) =>
          !isEmpty(inputs.state) ? item["department"] === inputs.state : true
        ),
        "province"
      ).map((city) => ({ value: city, label: city })),
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.city,
  };

  useEffect(() => {
    if (isEmpty(inputs.state) || isEmpty(inputs.city)) {
      return setOptions([]);
    }
    const filteredAreas = deliveryAreas.filter(
      (area) =>
        area.department === inputs.state && area.province === inputs.city
    );
    const options = filteredAreas.map((area) => ({
      title: context.t("Olva Courier Agent"),
      description: `${area["province"]}/${area.district}/${area.city} - ${area["address"]}, ${area.city} - ${area["postalCode"]}`,
    }));
    setOptions(options);
  }, [inputs]);

  
  useEffect(() => {
    setOriginForm(3)
  },[])

  return (
    <div className="other-location-delivery-form incomplete">
      <h2 className="title-main">{context.t('Physical Card Shipping')}</h2>
      <p className="description-main">
        {context.t("Where will we send your Banexcoin Visa Physical card?")}
      </p>
      <CardGradient
        actions={actions}
        message={context.t("To have your Banexcard just select the shipping address")}
      >
        <div className="form">
          <div className="form-top">
            <InputElement {...countryInputProps} />
            <InputElement {...stateInputProps} />
            <InputElement {...cityInputProps} />
          </div>
          <strong>
            {context.t("For now, we cannot send your card to your home, but you can select the nearest or preferred location to pick it up there personally with your ID or CE.")}
          </strong>
          <OptionsForm
            options={options}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
            id="description"
            containerClassName="delivery-scrollable-list"
          />
          <div className="description-text">
            {context.t(
              "Remember that the issuance of the card is FREE, we will only deduct 5 USD from the available balance of your card for the purpose of sending your Physical card according to the coverage areas within Peru."
            )}
          </div>
        </div>
      </CardGradient>
    </div>
  );
}
OtherLocationIncompleteForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OtherLocationIncompleteForm;
