import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import { validateTrustList, saveTrustList } from './TrustListHooks';
import { Link } from 'react-router-dom';
import WAValidator from '@swyftx/api-crypto-address-validator';
import config from 'apex-web/lib/config';
import {
  alphanumeric,
  alphanumericspace,
  email,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';

const RobotSvg = require('../../images/robot.svg');
const trustListFormClasses = getBEMClasses('trust-list-form');

const TrustListForm = (props, context) => {
  const { selectedAccountId, userInfo, products } = props;

  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [operation, setOperation] = useState('');
  const [receiveEmail, setReceiveEmail] = useState('');
  const [address, setAddress] = useState('');

  const customValidations = async (inputs) => {
    let errors = {};

    if (empty(inputs.apProductId)) {
      errors.apProductId = context.t('You must select a cryptocurrency');
    }

    if (empty(inputs.operation)) {
      errors.operation = context.t('You must select the operation');
    }

    errors = await validateAlias(inputs.label, errors);

    if (!empty(operation)) {
      if (operation === 'External Wallet') {
        errors = await validateAddress(inputs, errors);
      } else {
        errors = await validateEmail(inputs.receiveEmail, errors);
      }
    }

    errors = await trustedListData(errors);

    setErrors({
      ...errors
    });

    return errors;
  }

  const validateAlias = async (label, errors) => {
    let msgError;

    if (empty(label)) {
      errors.label = context.t('You must enter an alias');
    } else {
      msgError = alphanumericspace(label);

      if (msgError !== undefined) {
        errors.label = context.t(msgError);
      }
    }

    return errors;
  }

  const validateAddress = async (inputs, errors) => {
    let msgError;

    if (empty(inputs.address)) {
      errors.address = context.t('You must enter an external address');
    } else {
      const foundProduct = !empty(products) ? products.products.find(p => p.ProductId === Number(inputs.apProductId || 0)) : {};
      const cryptoCode = foundProduct.Product;
      const environment = (config.global.gateway === 'wss://api.banexcoin.com/WSGateway/') ? 'prod' : 'testnet';
      const valid = WAValidator.validate(inputs.address, cryptoCode, environment);

      if (valid) {
        msgError = alphanumeric(inputs.address);

        if (msgError !== undefined) {
          errors.address = context.t(msgError);
        }
      } else {
        errors.address = context.t('Invalid address');
      }
    }

    return errors;
  }

  const validateEmail = async (receiveEmail, errors) => {
    let msgError;

    if (empty(receiveEmail)) {
      errors.receiveEmail = context.t("You must enter an recipient's email address");
    } else {
      msgError = email(receiveEmail);

      if (msgError === undefined) {
        msgError = validateReceiverEmail(receiveEmail, userInfo.Email);

        if (msgError !== undefined) {
          errors.receiveEmail = context.t(msgError);
        }
      } else {
        errors.receiveEmail = context.t(msgError);
      }
    }

    return errors;
  }

  const trustedListData = async (errorList) => {
    if (operation === 'External Wallet') {
      delete inputs.receiveEmail;
    } else {
      delete inputs.address;
    }

    const requestValidateTrustList = { ...inputs };
    const responseValidateTrustList = await validateTrustList(userInfo.UserId, requestValidateTrustList)
      .then(result => {
        return result;
      })
      .catch(e => {
        return e.response;
      })
    
    if (Number(responseValidateTrustList.code) !== 200) {
      if (Object.prototype.hasOwnProperty.call(responseValidateTrustList.data, 'data')) {
        errorList = Object.assign(responseValidateTrustList.data.data, errorList);
      }
    }

    return errorList;
  };

  const onSubmitTrustList = async e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setLoading(1);

    try {
      const error = await customValidations(inputs);

      if (empty(error)) {
        const requestSaveTrustList = { ...inputs };
        const responseSaveTrustList = await saveTrustList(userInfo.UserId, requestSaveTrustList);

        if (responseSaveTrustList.status === 200) {
          toast.success(context.t(responseSaveTrustList.data.message), {
            position: toast.POSITION.TOP_CENTER
          });

          props.attributeUpdateTrusList(true);
          form.reset();
          setOperation('');
          setReceiveEmail('');
          setAddress('');
        } else {
          toast.warn(context.t(responseSaveTrustList.data.message), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
    } catch (e) {
      toast.warn(context.t('Information could not be saved'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(0);

    return true;
  };

  const initialState = {
    validated: false,
    userId: userInfo.UserId,
    accountId: selectedAccountId,
    apProductId: null,
    operation: null,
    label: null,
    receiveEmail: null,
    address: null
  };

  const { inputs, errors, setErrors } = useForm(initialState);

  const getClassNameFromInput = (fieldError) => {
    return !empty(fieldError) ? 'is-invalid' : '';
  }

  const getClassNameFromFeedback = (fieldError) => {
    return !empty(fieldError) ? 'mt-3 invalid-feedback' : '';
  }

  const onChangeOperationField = (value) => {
    inputs.operation = value;
    inputs.receiveEmail = null;
    inputs.address = null;
    setOperation(value);
    setReceiveEmail('');
    setAddress('');
    return true;
  }

  const onChangeAliasField = (value) => {
    inputs.label = value;
    return true;
  }

  const onChangeAddressField = (value) => {
    inputs.address = value;
    setAddress(value);
    return true;
  }

  const onChangeReceiveEmailField = (value) => {
    inputs.receiveEmail = value;
    setReceiveEmail(value);
    return true;
  }

  return userInfo.Use2FA ? (
    <React.Fragment>
      <Loading loading={loading} />
      <Form
        noValidate
        onSubmit={onSubmitTrustList}
        encType="application/x-www-form-urlencoded">
        <Row>
          <Col xl="6">
            <Form.Group controlId="apProductId">
              <Form.Label>{context.t('Crypto code')} *</Form.Label>
              <Form.Control
                as="select"
                className={getClassNameFromInput(errors.apProductId)}
                required
                name="apProductId"
                onChange={e => {
                  inputs.apProductId = Number(e.target.value);
                }}>
                <option value="">{context.t('Select')}</option>
                {!empty(products) && !empty(products.products) && products.products.filter(p => p.ProductType === "CryptoCurrency").map((product, index) => {
                  return <option key={"p"+index} value={product.ProductId}>{product.Product}</option>
                })}
              </Form.Control>
              <Form.Control.Feedback
                className={getClassNameFromFeedback(errors.apProductId)}>
                {errors.apProductId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="6">
            <Form.Group controlId="operation">
              <Form.Label>{context.t('Operation')} *</Form.Label>
              <Form.Control
                as="select"
                className={getClassNameFromInput(errors.operation)}
                required
                name="operation"
                onChange={e => {onChangeOperationField(e.target.value)}}>
                <option value="">{context.t('Select')}</option>
                <option value="External Wallet">{ context.t('External wallet') }</option>
                <option value="Banexcoin Transfer">{ context.t('Banexcoin transfer') }</option>
              </Form.Control>
              <Form.Control.Feedback
                className={getClassNameFromFeedback(errors.operation)}>
                {errors.operation}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <Form.Group controlId="alias">
              <Form.Label>{context.t('Alias')} *</Form.Label>
              <Form.Control
                type="text"
                placehoder={context.t('Alias')}
                className={getClassNameFromInput(errors.label)}
                required
                name="alias"
                autoComplete="off"
                onChange={e => {onChangeAliasField(e.target.value)}}
              />
              <Form.Control.Feedback
                className={getClassNameFromFeedback(errors.label)}>
                {errors.label}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="6">
            {!empty(operation) ? (
              operation === 'External Wallet' ? (
                <Form.Group controlId="address">
                  <Form.Label>{context.t('External address')} *</Form.Label>
                  <Form.Control
                    type="text"
                    placehoder={context.t('External address')}
                    className={getClassNameFromInput(errors.address)}
                    required
                    name="address"
                    value={address}
                    autoComplete="off"
                    onChange={e => {onChangeAddressField(e.target.value)}}
                  />
                  <Form.Control.Feedback
                    className={getClassNameFromFeedback(errors.address)}>
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <Form.Group controlId="receiveEmail">
                  <Form.Label>
                    {context.t("Recipient's email address")} *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placehoder={context.t("Recipient's email address")}
                    className={getClassNameFromInput(errors.receiveEmail)}
                    required
                    name="receiveEmail"
                    value={receiveEmail}
                    autoComplete="off"
                    onChange={e => {onChangeReceiveEmailField(e.target.value)}}
                  />
                  <Form.Control.Feedback
                    className={getClassNameFromFeedback(errors.receiveEmail)}>
                    {errors.receiveEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              )
            ) : ('')}
          </Col>
        </Row>
        <Row>
          <Col xl="12" className="align-left">
            <div className="form-group">
              <Form.Text className="text-muted">
                (*) {context.t('Required fields')}
              </Form.Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="4" />
          <Col xl="4" className="align-center">
            <Button
              variant="primary"
              type="submit"
              style={{ padding: '1em', width: 'auto' }}>
              {context.t('Accept')}
            </Button>
          </Col>
          <Col xl="4" />
        </Row>
      </Form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className={trustListFormClasses()}>
        <div className="error-accessing-message">
          <Link to="/settings/user">
            <img src={RobotSvg} className=" roboto" alt="roboto" />
            <div className="text">
              {context.t('Enable 2FA to use this functionality')}
              <button type="button">{context.t('Activate it here')}</button>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

TrustListForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { selectedAccountId, userInfo }, product: products }) => {
  return { selectedAccountId, userInfo, products };
};

export default connect(mapStateToProps)(TrustListForm);
