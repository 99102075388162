//import moment from 'moment';
import apex from 'apex-web/lib/apex';
import HandlerBackend from '../../../../helpers/HandlerBackend';
import empty from 'is-empty';

export const getFiatAccounts = (currencyCode, userId) => {
  let path = `/api/deposit-fiat-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const getV2FiatAccounts = (currencyCode, userId, accountId) => {
  let path = `/api/v2/fiat-accounts/?currencyCode=${currencyCode}`;
  let data = {
    accountId
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const getV2FiatAccountsbyWithdraw = (
  currencyCode,
  userId,
  accountId
) => {
  let path = `/api/v2/fiat-accounts/?currencyCode=${currencyCode}&type=withdraw`;
  let data = {
    accountId
  };
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const getBanexcoinAccounts = (currencyCode, userId) => {
  let path = `/api/banexcoin-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const getBanexcoinAccountsV2 = async (currencyCode, userId) => {
  const path = `/api/v2/banexcoin-accounts?currencyCode=${currencyCode}`;
  const result = await HandlerBackend.sendRequest({
    userId,
    method: 'GET',
    path
  });
  return !empty(result.data.data) ? result.data.data : [];
};

export const submitFormData = async data => {
  let result = {};
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/upload-deposit-image';
  let model = new FormData();
  if (empty(data.proof_payment)) {
    alert('An error has ocurred, please contact support.');
    return null;
  }
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('proof_payment', data.proof_payment);
  model.append('banexcoin_user_account_id', data.AccountId);

  let upload = await HandlerBackend.sendRequest({
    userId: data.banexcoin_user_id,
    method: 'post',
    headers,
    path,
    data: model
  });

  let payload = {
    assetManagerId: 1,
    accountId: data.AccountId,
    assetId: data.assetId,
    assetName: data.assetName,
    amount: data.amount,
    omsId: 1,
    requestCode: null,
    requestIP: null,
    requestUser: data.banexcoin_user_id,
    requestUserName: data.banexcoin_user_name,
    operatorId: 1,
    Status: 0,
    feeAmt: 0.0,
    updatedByUser: 0,
    updatedByUserName: null,
    ticketNumber: 0,
    depositInfo:
      '{"transactionNumber":"' +
      data.transaction_number +
      '","transactionDate":"' +
      data.transaction_date +
      '","amount":"' +
      data.amount +
      '","accountSource":"' +
      data.account_source +
      '","bankNameSource":"' +
      data.bank_name_source +
      '","bankCountrySource":"' +
      data.bank_country_source +
      '","bankNameDestination":"' +
      (data.bank_name_destination ? data.bank_name_destination : '') +
      '","bankCountryDestination":"' +
      (data.bank_country_destination ? data.bank_country_destination : '') +
      '","accountDestination": "' +
      data.account_destination +
      '","sourceOfFunds":"' +
      (data.affidavit_source_funds ? data.affidavit_source_funds : '') +
      '","sourceOfFundsOthers":"' +
      (data.affidavit_source_funds_others
        ? data.affidavit_source_funds_others
        : '') +
      '","depositProof": "' +
      upload.data.upload.proof_payment +
      '"}',
    createdTimestamp: '0001-01-01T00:00:00',
    lastUpdateTimeStamp: '0001-01-01T00:00:00',
    comments: null,
    attachments: null
  };
  result = await apex.connection.CreateDepositTicket(payload);
  return result;
};

export const createDepositByApi = async (data, accountId) => {
  let result = {};
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/create-fiat-deposit';
  let model = new FormData();
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('proof_payment', data.proof_payment);
  model.append('banexcoin_account_id', accountId);
  model.append('assetId', data.assetId);
  model.append('amount', data.amount);
  model.append('transaction_number', data.transaction_number);
  model.append('transaction_date', data.transaction_date);

  model.append('bank_source_id', data.bank_source_id);
  model.append('bank_name_destination', data.bank_name_destination);
  model.append('bank_country_destination', data.bank_country_destination);
  model.append('account_destination', data.account_destination);
  model.append('affidavit_source_funds', data.affidavit_source_funds);
  model.append(
    'affidavit_source_funds_others',
    data.affidavit_source_funds_others
  );
  model.append('account_destination_id', data.account_destination_id);

  result = await HandlerBackend.sendRequest({
    userId: data.banexcoin_user_id,
    method: 'post',
    headers,
    path,
    data: model
  });
  return result;
};

export const sendNotification = async (userId, data, UserAccountId) => {
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  let path = '/api/notifications';

  let model = {
    notification_type: 'DEPOSIT_REQUEST',
    user_id: data.user_id,
    account_id: UserAccountId,
    user_name: data.user_name,
    amount_number: data.amount,
    currency_symbol: data.currency,
    payment_method: 'Efectivo',
    request_code: data.request_code,
    account_source: data.account_source,
    bank_name_source: data.bank_name_source,
    bank_country_source: data.bank_country_source,
    bank_name_destination: data.bank_name_destination,
    bank_country_destination: data.bank_country_destination,
    account_destination: data.account_destination
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
