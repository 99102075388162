import empty from 'is-empty';
import HandlerBackend from '../../../helpers/HandlerBackend';

export const cleanCustomError = errorString => {
  let errorMsg = errorString;
  let errors = [];
  if (errorMsg.includes('LONGERROR:')) {
    errors = errorMsg.replace('LONGERROR:', '').split(';:');
  } else {
    errors.push(errorMsg);
  }
  return errors;
};

export const getPublicCountries = async () => {
  let path = '/api/public-countries';
  return HandlerBackend.sendRequest({
    method: 'get',
    path
  });
};

export const createBanexcoinUserNatural = async data => {
  const path = '/api/v2/signup/natural';
  const model = {
    firstName:data.firstName,
    lastName:data.lastName,
    username: data.username,
    email: data.email,
    password: data.password,
    matchingPassword: data.matchingPassword,
    birthCountry: data.birthCountry,
    residenceCountry: data.residenceCountry,
    documentType: data.documentType,
    documentNumber: data.documentNumber,
    ceBirthday: data.ceBirthday,
    privacyPolicy: data.privacyPolicy,
    iamOlder: data.iamOlder,
    processingPersonalData: !empty(data.processingPersonalData)
      ? data.processingPersonalData
      : false,
    mobilePhone: data.mobilePhone,
  };
  if (
    !empty(data) &&
    data.hasOwnProperty('AffiliateTag') &&
    !empty(data.AffiliateTag)
  ) {
    model.AffiliateTag = data.AffiliateTag;
  }

  return HandlerBackend.sendRequest({
    method: 'post',
    path,
    data: model
  });
};

export const usersInRegistrationProcess = async ( data ) => {
  const path = '/api/v1/register-user';
  const model = {
    banexcoinUsername: data.username,
    email: data.email,
    phone: data.phone,
    privacyPolicy: data.privacyPolicy,
    termsAndConditions: data.iamOlder,
    processingOfPersonalData: data.processingPersonalData ? data.processingPersonalData : false,
  };
  return HandlerBackend.sendRequest({
    method: 'patch',
    path,
    data: model,
    timeout: 120000
  });
};

export const updateUsersInRegistrationProcess = async (id, data) => {
  const path = `/api/v1/register-user?id=${id}`;

  const model = {
    firstName: data.firstName,
    lastName: data.lastName,
    birthday: data.ceBirthday,
    birthCountry: data.birthCountry,
    residenceCountry: data.residenceCountry,
    documentType: data.documentType,
    documentNumber: data.documentNumber
  };

  return HandlerBackend.sendRequest({
    method: 'patch',
    path,
    data: model,
    timeout: 120000
  });
};

export const createBanexcoinUserCorporate = async data => {
  const path = '/api/v2/signup/corporate';
  const model = {
    username: data.username,
    email: data.email,
    password: data.password,
    matchingPassword: data.matchingPassword,
    incorporationCountry: data.incorporationCountry,
    billingCountry: data.billingCountry,
    documentType: data.documentType,
    documentNumber: data.documentNumber,
    privacyPolicy: data.privacyPolicy,
    iamOlder: data.iamOlder,
    processingPersonalData: !empty(data.processingPersonalData)
      ? data.processingPersonalData
      : false,
    mobilePhone: data.mobilePhone,
  };

  if (
    !empty(data) &&
    data.hasOwnProperty('AffiliateTag') &&
    !empty(data.AffiliateTag)
  ) {
    model.AffiliateTag = data.AffiliateTag;
  }

  return HandlerBackend.sendRequest({
    method: 'post',
    path,
    data: model
  });
};
