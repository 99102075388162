import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AdvancedOrderFormComponent from './AdvancedOrderFormComponent';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import {
  placeOrderWithChecks,
  clearOrderForm,
  synchronizePriceWithQuantity,
  synchronizeQuantityWithPrice
} from 'apex-web/lib/redux/actions/orderEntryActions';
import { closeAdvancedOrderSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import {
  advancedOrderFormTypes,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import { marketPriceOfSelectedInstrumentSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import instrumentPositionSelector, {
  selectedInstrumentSelector
} from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import store from 'apex-web/lib/redux/store';
import config from 'apex-web/lib/config';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { marginProductSelector } from 'apex-web/lib/redux/selectors/marginProductSelectors';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderNet } from 'apex-web/lib/helpers/orderHelper';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

var closeSidePane = function closeSidePane() {
  return store.dispatch(closeAdvancedOrderSidePane());
};

var mapStateToProps = function mapStateToProps(state) {
  var form = state.form;

  var isMarginActive = isMarginActiveSelector(state);
  var marginProducts = marginProductSelector(state);
  var selectedInstrument = selectedInstrumentSelector(state);
  var formObj = form.advancedOrderEntry || { values: {} };

  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    disableTrading = _kycVerificationLevel.disableTrading;

  var orderTotal = form.advancedOrderEntry
    ? orderTotalSelector(state, {
        form: form.advancedOrderEntry.values
      })
    : 0;

  return Object.assign(
    {
      formObj: formObj,
      selectedInstrument: selectedInstrument,
      instruments: instrumentSelectorInstruments(state),
      marketPrice: marketPriceOfSelectedInstrumentSelector(state, {
        side: formObj.values.side
      }),
      orderTotal:
        selectedInstrument.Product2Symbol +
        ' ' +
        formatNumberToLocale(
          orderTotal,
          convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)
        ),
      orderNet: getOrderNet(formObj, selectedInstrument, orderTotal),
      positions: positionSelector(state),
      initialValues: {
        side: formObj.values.side || buyValue,
        instrument: selectedInstrument.InstrumentId,
        quantity: '',
        limitPrice: '',
        stopPrice: '',
        trailingAmount: '',
        limitOffset: '',
        displayQuantity: '',
        fee: '0',
        totalAmount: '0',
        pegPriceType: '1',
        price: '',
        orderType:
          formObj.values.orderType || advancedOrderFormTypes.market.displayName,
        timeInForce: '1'
      },
      disableTrading: disableTrading,
      user: state.user,
      blockedActions: state.blockedActions
    },
    instrumentPositionSelector(state),
    {
      VerificationRequiredComponent: React.createElement(
        VerificationRequiredContainer,
        { onVerificationLinkClick: closeSidePane }
      ),
      hideFees: !!config.global.hideFees,
      isMarginActive: isMarginActive,
      marginProducts: marginProducts
    }
  );
};

var mapDispatchToProps = {
  clearAdvancedOrderEntryForm: function clearAdvancedOrderEntryForm() {
    return clearOrderForm('advancedOrderEntry');
  },
  synchronizeQuantityWithPrice: synchronizeQuantityWithPrice,
  synchronizePriceWithQuantity: synchronizePriceWithQuantity
};

var Form = reduxForm({
  form: 'advancedOrderEntry',
  enableReinitialize: true,
  onSubmit: function onSubmit(payload, dispatch) {
    return dispatch(
      placeOrderWithChecks(orderFormTypes.advanced, payload)
    ).then(function(res) {
      if (res.result === false) {
        dispatch(
          showSnack({
            id: 'placeOrderError',
            text: res.errormsg || res.detail,
            type: 'warning'
          })
        );
      }
    });
  }
})(AdvancedOrderFormComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
