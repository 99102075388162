import HandlerBackend from "../helpers/HandlerBackend";

export const phoneValidation = async (userId, data) => {
  const path = "/api/v2/phone/validation/step1";
  const configRequest = {
    userId,
    method: "POST",
    path,
    data,
  };
  return await HandlerBackend.sendRequest(configRequest);
};
