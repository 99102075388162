import React from 'react';
import PropTypes from 'prop-types';
import APTable from 'apex-web/lib/components/common/APTable';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

const baseClass = getBEMClasses('order-history-table');

const makeCell = (data, className, currency = null) => (
  <div
    title={data + ' ' + (currency ? currency : '')}
    className={baseClass('tooltip-wrapper') + ' ' + className}>
    {data} {currency}
  </div>
);

const makePrefix = row => (row.PositionDirection === 'Long' ? '+' : '-');

const ClosedPositionsComponent = (
  { data, instruments, fetching, config, baseAsset },
  context
) => {
  const { maxLines, usePagination } = config;

  const columns = [
    {
      header: context.t('PID'),
      title: context.t('Position Id'),
      dataTest: 'Position ID',
      cell: row => row.PositionId
    },
    {
      header: context.t('Pair'),
      dataTest: 'Pair',
      cell: row => {
        const instrument = instruments.find(
          item => item.InstrumentId === row.InstrumentId
        );
        return instrument ? instrument.Symbol : '';
      }
    },
    {
      header: context.t('Position'),
      dataTest: 'Position',
      cell: row =>
        row.Position
          ? context.t('Position')
          : makeCell(
              `${makePrefix(row)}${formatNumberToLocale(
                row.PositionAmount,
                row.PositionProductSymbol,
                { removeTrailingZeroes: true }
              )}`,
              baseClass('size'),
              row.PositionProductSymbol
            )
    },
    {
      header: context.t('Borrowed Amount'),
      dataTest: 'Size',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.BorrowedAmount, row.BorrowedProductSymbol, {
            removeTrailingZeroes: true
          }),
          baseClass('size'),
          row.BorrowedProductSymbol
        )
    },
    {
      header: context.t('Cost Basis'),
      dataTest: 'Cost Basis',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.CostBasis, row.BorrowedProductSymbol, {
            removeTrailingZeroes: true
          }),
          baseClass('cost-basis'),
          row.BorrowedProductSymbol
        )
    },
    {
      header: context.t('Current Px'),
      dataTest: 'Current Px',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.CurrentPrice, row.PositionProductSymbol, {
            removeTrailingZeroes: true
          }),
          baseClass('current-px'),
          row.PositionProductSymbol
        )
    },
    {
      header: context.t('Position Value'),
      dataTest: 'Position Value',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.PositionValue, baseAsset.Product, {
            removeTrailingZeroes: true
          }),
          baseClass('position-value'),
          baseAsset.Product
        )
    },
    {
      header: context.t('Margin Used'),
      dataTest: 'Margin Used',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.MarginUsed, baseAsset.Product, {
            removeTrailingZeroes: true
          }),
          baseClass('margin-used'),
          baseAsset.Product
        )
    },
    {
      header: context.t('Int. Accrued'),
      dataTest: 'Int. Accrued',
      cell: row =>
        makeCell(
          formatNumberToLocale(
            row.InterestedAccrued,
            row.BorrowedProductSymbol,
            {
              removeTrailingZeroes: true
            }
          ),
          baseClass('int-accrued'),
          row.BorrowedProductSymbol
        )
    },
    {
      header: context.t('Int. Paid'),
      dataTest: 'Int. Paid',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.InterestPaid, row.BorrowedProductSymbol, {
            removeTrailingZeroes: true
          }),
          baseClass('int-accrued'),
          row.BorrowedProductSymbol
        )
    },
    {
      header: context.t('Realized P&L'),
      dataTest: 'Realized P&L',
      cell: row =>
        makeCell(
          formatNumberToLocale(row.UnrealizedPnL, baseAsset.Product, {
            removeTrailingZeroes: true
          }),
          baseClass('realized-pl'),
          baseAsset.Product
        )
    }
  ];

  return (
    <APTable
      {...{
        columns,
        rows: data,
        baseClass,
        fetching,
        pageSize: maxLines,
        usePagination,
        empty: <span className='empty-orders'>{context.t("You have no closed positions.")}</span>,
        componentName: 'ClosedPositionsComponent'
      }}
    />
  );
};

/*
 this.context.t('Closed Positions');
 */
ClosedPositionsComponent.title = 'Closed Positions';

ClosedPositionsComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired
};

ClosedPositionsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ClosedPositionsComponent;
