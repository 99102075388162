import { connect } from 'react-redux';
import ApexChartComponent from './ApexChartComponent';
import { changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import config from "../config"

const { locale } = config.global;

const mapStateToProps = state => {
  const { tickers, apexCore } = state;
  const { level1, level2 } = apexCore;

  const selectedInstrument = selectedInstrumentSelector(state);
  const selectedInstrumentId = selectedInstrument.InstrumentId;

  const marketPrice =
    selectedInstrumentId && level1[selectedInstrument.InstrumentId]
      ? level1[selectedInstrument.InstrumentId].LastTradedPx
      : 0;

  return {
    precision: convertIncrementToIntDecimalPlaces(
      selectedInstrument.PriceIncrement
    ),
    locale,
    chartData: { level2, tickers, marketPrice }
  };
};

const mapDispatchToProps = {
  changeTickersInterval
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApexChartComponent);
