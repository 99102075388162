import React, {useEffect, useState, useRef} from 'react';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import InputElement from '../bnxv2/Components/InputElement/InputElement';
import { onInstrumentSelect } from 'apex-web/lib/redux/actions/buySellActions';
import { FormButtonV2 } from '../../components/common/BnxFormsComponents';
import {ReactComponent as SwitchImage} from './../../images/switch-side.svg';
import {ReactComponent as SwitchImageBG} from './../../images/switch-side-fondo.svg';
import { Form, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import BuySellV2FormConfirm from './BuySellV2FormConfirm';
import { selectInstrument } from 'apex-web/lib/redux/actions//instrumentActions';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import BtcIcon from './../../images/product-icons/btc-48px.svg';
import EthIcon from './../../images/product-icons/eth-48px.svg';
import UsdIcon from './../../images/product-icons/usd-48px.svg';
import UsdcIcon from './../../images/product-icons/usdc-48px.svg';
import PenIcon from './../../images/product-icons/pen-48px.svg';
import CopIcon from './../../images/product-icons/cop-48px.svg';
import ArrowIcon from './../../images/icons-v2/ArrowRight.svg';
import isEmpty from 'is-empty';
import StatusMessage from '../../components/StatusMessage/StatusMessage';
import { Link } from 'react-router-dom';
import InformationIcon from '../../images/bnx-icons/information-circle.svg'
import RangeButtons from '../../components/RangeButtons/RangeButtons';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { useHistory } from 'react-router-dom';
import { FormatDecimalCoinkWithOutSimbols, amountWithCurrency, googleAnalitycs } from '../utils';
import apex from 'apex-web/lib/apex';
import { buySellAction as _buySellAction } from '../../redux/actions/buy_sell_action';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';
import { alertsContentCoink } from '../../constants/alertsContentCoink';

const productsIcons = {
    btc: BtcIcon,
    eth: EthIcon,
    usd:UsdIcon,
    usdc: UsdcIcon,
    pen: PenIcon,
    cop: CopIcon,
}
// @ts-ignore
Number.prototype.toFixedDown = function(digits) {
    var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
        m = this.toString().match(re);
    return m ? parseFloat(m[1]) : this.valueOf();
};

const makeBigNumber = value => new BigNumber(isNaN(value) ? 0 : value);

const BuySellV2Form = (props, context) => {
    const { selectPositionAndSave, buySellAction, blockedActions, showSnack, level1, instrumentsByAccountId, instruments, products, getOrderFee, getAccountFees, getAccountInstrumentStatistics, selectedAccountId, positions, sendOrder, subscribeLevel1, unsubscribeLevel1, isCoink } = props;
    const [currentInstrument, setCurrentInstrument] = useState({
        QuantityIncrement: 0,
        Symbol: '',
        InstrumentId: '',
        Product1: 0,
        Product1Symbol: '',
        Product2: 0,
        Product2Symbol: ''
    });
    const initialFeeData = {
        OrderFee: 0,
        ProductId: 0
    }
    const [feeData, setfeeData] = useState(initialFeeData);
    const [show, setShow] = useState(false);
    const [inputChange, setInputChange] = useState(''); 
    const [accountFees, setAccountFees ] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let initialState = {
        side: '0',
        quantity: '',
        amount: ''
    }

    let history = useHistory();

    const selectRef = useRef(null);
    const selectRef2 = useRef(null);

    const handleClickInside = (name) => {
        const newSelectRef = name === 'select1' ? selectRef : selectRef2;
        if (newSelectRef.current) {
            if(newSelectRef.current.style.cssText) return newSelectRef.current.style = null
            newSelectRef.current.style.height = `${newSelectRef.current.scrollHeight}px`;
            newSelectRef.current.style.zIndex  = `2`;
            newSelectRef.current.style.transition  = `height .3s`;
        }
    };

    const handleClickOutside = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) selectRef.current.style = null;
        if (selectRef2.current && !selectRef2.current.contains(e.target)) selectRef2.current.style = null;
    };

    const onSubmitCallback = async (inputs, form, newErrors) => {
        if (empty(newErrors)) {
            handleShow()
        }
      };

    const customValidations = inputs => {
        let errors = {};
        const amount = Number(inputs.amount);
        const quantity = Number(inputs.quantity);
        if (empty(amount) ) {
            errors.amount = 'Required field';
        }
        if (!empty(amount) && amount <= 0) {
            errors.amount = 'Required field';
        }
        if (!empty(quantity) && quantity <= 0) {
            errors.quantity = 'Required field';
        }
        if (empty(quantity)) {
            errors.quantity = 'Required field';
        }
        setErrors(errors);
        return errors;
    };


    const {
        inputs,
        errors,
        setErrors,
        onDefaultChange,
        onSubmitSimple,
        setInputs
      } = useForm(initialState, onSubmitCallback, customValidations);


    const getAvailableBalanceByProductId = (ProductId) => {
        const allBalances = !empty(positions) ? positions[selectedAccountId] : {}
        const selectedBalance = allBalances[ProductId]
        if(empty(selectedBalance)) {
            return 0
        }
        return makeBigNumber(selectedBalance.Amount).minus(makeBigNumber(selectedBalance.Hold)) ;
    }


    const onInstrumentChange = (e) => {
        const instSymbol = e.target.value;
        const name = e.target.name;
        const instAuth =instrumentsByAccountId[0].instrumentsByAccountId.buysell.find(ii => ii === instSymbol)
        if(!empty(instAuth)) {
            const optionInstrumentData = instruments.find(ins => ins.Symbol === instSymbol); 
            onInstrumentSelect(optionInstrumentData.InstrumentId)
            selectInstrument(parseInt(optionInstrumentData.InstrumentId, 10))
            handleSubscriptionLevel1(unsubscribeLevel1, currentInstrument.InstrumentId)
            handleSubscriptionLevel1(subscribeLevel1, optionInstrumentData.InstrumentId)
            setCurrentInstrument(optionInstrumentData)
            setInputs((inputs) => ({
                ...inputs,
                [name]: instSymbol
            }))
        }
    }

    const availableInstruments = () => {
        if(!(!empty(instruments) && !empty(instrumentsByAccountId) && instrumentsByAccountId[0] && !empty(instrumentsByAccountId[0].instrumentsByAccountId) && !empty(instrumentsByAccountId[0].instrumentsByAccountId.buysell))) {
            return [];
        }
        return instrumentsByAccountId[0].instrumentsByAccountId.buysell.filter((i) => {
            const optionInstrumentData = instruments.find(ins => ins.Symbol === i);
            return !empty(optionInstrumentData);
        })
    }
    
    const getLevelPrice = (InstrumentSymbol) => {
        const instrumentData = instruments.find(ins => ins.Symbol === InstrumentSymbol);
        if(empty(instrumentData)) {
            return {};
        }
        const selectedLevel = level1[instrumentData.InstrumentId];
        if(empty(selectedLevel)) {
            return {};
        }
        return selectedLevel;
    }

    const amountValue = (fieldName, value, side) => {
        const newSide = !empty(side) ? side : inputs.side;
        let result = {
            value: '0',
            price: 0
        };
        if(empty(level1)) {
            return result;
        }
        const selectedLevel = level1[currentInstrument.InstrumentId]
        if(empty(currentInstrument) || empty(currentInstrument.InstrumentId) || empty(selectedLevel)) {
            return result;
        }
        
        const newValue = Number(!empty(value) ? value : 0 )
        if(newSide === '1' && fieldName === 'amount' && selectedLevel.BestBid > 0) {
            result.value = String(newValue * selectedLevel.BestBid)
        }

        if(newSide === '1' && fieldName === 'quantity' && selectedLevel.BestBid > 0) {
            result.value = String(makeBigNumber(Number(newValue)).plus(makeBigNumber(Number(newValue) * (accountFees.FeeAmt / 100))))
        }
        
        if(newSide === '0' && fieldName === 'amount' && selectedLevel.BestOffer > 0) {
            result.value = String(newValue / selectedLevel.BestOffer)
        }
        if(newSide === '0' && fieldName === 'quantity' && selectedLevel.BestOffer > 0) {
            result.value = String(newValue / (makeBigNumber(1).minus(makeBigNumber(accountFees.FeeAmt/100))))
        }

        result.price = (newSide === '0' ? selectedLevel.BestOffer : selectedLevel.BestBid)
        return result
    }

    const calculateFees = async (quantity, Amount, price, side) => {
        const newSide = !empty(side) ? side : inputs.side;
        if(quantity <= 0 || Amount <= 0) {
            return;
        }
        const newQuantity = makeBigNumber(quantity).toFixed()
        const payloadFee = {
            "OMSId":1,
            "AccountId": Number(selectedAccountId),
            "InstrumentId": currentInstrument.InstrumentId,
            "Amount": newSide === '0' ? Amount : newQuantity,
            "Quantity": newSide === '0' ? newQuantity : newQuantity,
            "Side": Number(newSide),
            "Price": String(price),
            "OrderType": 2,
            "MakerTaker": "Taker"
        }
        const fee = await getOrderFee(payloadFee);
        setfeeData(fee);
    }

    const sendOrderToAP = async () => {
        const newSide = inputs.side;
        const selectedLevel = level1[currentInstrument.InstrumentId];
        const priceSideKey = newSide === '0' ? 'BestOffer' : 'BestBid';
        let amount = 0;
        let priceLimit = selectedLevel[priceSideKey];
        if(newSide === '0') {
            amount = inputChange === 'quantity' ? formatNumberByProduct(Number(inputs.quantity) + Number(feeData.OrderFee), currentInstrument['Product1']) : inputs.quantity;
            amount = makeBigNumber(amount).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        }
        if(newSide === '1') {
            const actualPrice = getLevelPrice(currentInstrument.Symbol)[inputs.side === '0' ? 'BestOffer' : 'BestBid']
            const newAmount = makeBigNumber(Number(inputs.amount / actualPrice)).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
            amount = inputChange === 'quantity' ? formatNumberByProduct(newAmount, currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']) : inputs.amount;
            amount = makeBigNumber(amount).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        }
        const orderType = 1;
        let payloadOrder = {
            "InstrumentId": currentInstrument.InstrumentId,
            "OMSId": 1,
            "AccountId": Number(selectedAccountId),
            "TimeInForce": 1,
            "ClientOrderId": 0,
            "OrderIdOCO": 0,
            "UseDisplayQuantity": false,
            "Side": Number(inputs.side),
            "Quantity": amount.replaceAll(',', ''),
            "OrderType": orderType,
        }
        if(orderType !== 1) {
            payloadOrder.LimitPrice = Number(priceLimit)
        }
        const orderResult = await sendOrder(payloadOrder);

        if(orderResult.status === "Accepted") {
            setInputs({
                ...inputs,
                amount: '',
                quantity: ''
            });
            setfeeData(initialFeeData)
        }
    }

    const detectCryptoCurrency = (productDataAmoun) => {
        return (productDataAmoun.ProductType === "CryptoCurrency" ? convertIncrementToIntDecimalPlaces(currentInstrument.QuantityIncrement) : productDataAmoun.DecimalPlaces)
    }

    const getDecimalPlaces = (name, side) => {
        const destinationFieldName = name === 'quantity' ? 'amount' : 'quantity';
        let originProductId = 0;
        let destinationProductId = 0;
        // Compra
        if(side === '0') {
            originProductId = name === 'quantity' ? currentInstrument.Product1 : currentInstrument.Product2;
            destinationProductId = name === 'quantity' ? currentInstrument.Product2 : currentInstrument.Product1;
        }
        // Venta
        if(side === '1') {
            originProductId = name === 'quantity' ? currentInstrument.Product2 : currentInstrument.Product1;
            destinationProductId = name === 'quantity' ? currentInstrument.Product1 : currentInstrument.Product2;
        }
        const originDecimalPlaces = products.find(p => p.ProductId === originProductId)
        const amountDecimalPlaces = products.find(p => p.ProductId === destinationProductId)
        

        return {
            [name]: detectCryptoCurrency(originDecimalPlaces),
            [destinationFieldName]: detectCryptoCurrency(amountDecimalPlaces)
        }
    }

    const onChangePrice = (e) => {
        let { name, value, range = false } = e.target;
        value = value.replaceAll(',', '');
        const productsInputs = getDecimalPlaces(name, inputs.side);
        if (range){
            value = String(Number(value).toFixedDown(productsInputs[name]));
        }
        const inputValue = !empty(value) ? value : 0;
        const regexString = `^\\d*\\.?\\d{0,${productsInputs[name]}}$`;
        const regexFull = new RegExp(regexString);
        if (name === 'amount' && !empty(inputValue) && !regexFull.test(inputValue)) {
            return;
        }
        const destinationFieldName = name === 'quantity' ? 'amount' : 'quantity';
        const amountResult = amountValue(name, inputValue);
        const amountToFill = amountResult.value;

        // @ts-ignore
        const formattedAmountToFill = amountToFill;
        setInputs({
            ...inputs,
            [name]: value,
            [destinationFieldName]: !isEmpty(value) ? formattedAmountToFill : ''
        });
        setInputChange(name)
    }

    const selectAccountFee = (accountFees, rollingTradeVolume) => {
        let newAccountFees = accountFees.filter(f => f.FeeCalcType === "Percentage" && f.FeeType === "TakerFee" && f.IsActive) || []
        if(isEmpty(newAccountFees)){
            return accountFees.find(f => f.FeeCalcType === "Percentage" && f.FeeType === "Flat" && f.IsActive) || {}
        }
        let result = {};
        newAccountFees = newAccountFees.sort((a, b) => a.LadderThreshold - b.LadderThreshold);
        for (let i = 0; i < newAccountFees.length; i++) {
            if (rollingTradeVolume >= newAccountFees[i].LadderThreshold) {
                result = newAccountFees[i];
            } else {
                break;
            }
        }
        if (isEmpty(result)) {
            result = newAccountFees[newAccountFees.length - 1];
        }
        return result;
    }

    const getAccountFee = async () => {
        let accountInstrumentStatistics = await getAccountInstrumentStatistics({ "AccountId": Number(selectedAccountId), "OMSId": 1})
        accountInstrumentStatistics = accountInstrumentStatistics.find(e => e.InstrumentId === currentInstrument.InstrumentId)
        const accountFees = await getAccountFees({ "AccountId": Number(selectedAccountId), "OMSId": 1, "InstrumentId": currentInstrument.InstrumentId})
        const accountFee = selectAccountFee(accountFees, accountInstrumentStatistics.MonthlyTradeVolume)
        setAccountFees(accountFee)
        return accountFee
;    }

    useEffect(() => {
        if(empty(inputs.selectedinstrument)) {
            const availableInstrument = availableInstruments()
            if(!empty(availableInstrument)) {
                const optionInstrumentData = instruments.find(ins => ins.Symbol === availableInstrument[0]);
                setCurrentInstrument(optionInstrumentData);
                selectInstrument(parseInt(optionInstrumentData.InstrumentId, 10))
                setInputs((inputs) => ({
                    ...inputs,
                    selectedinstrument: availableInstrument[0]
                }))
            }
        }
    }, [instruments,instrumentsByAccountId, products, inputs]);

    useEffect(() => {
        if(!empty(selectedAccountId) && !empty(currentInstrument.InstrumentId)) {
            getAccountFee()
        }
    }, [level1, selectedAccountId, currentInstrument]);

    useEffect(() => {
        if(!empty(currentInstrument.InstrumentId) && (!empty(inputs.amount) || !empty(inputs.quantity))) {
            const productsInputs = getDecimalPlaces(inputChange, inputs.side);
            const inputValue = !empty(inputs[inputChange]) ? inputs[inputChange] : 0;
            const regexString = `^\\d*\\.?\\d{0,${productsInputs[inputChange]}}$`;
            const regexFull = new RegExp(regexString);
            if(!empty(inputValue) && !regexFull.test(inputValue)) {
                return;
            }

            const destinationFieldName = inputChange === 'quantity' ? 'amount' : 'quantity';
            const amountResult = amountValue(inputChange, inputValue);
            const amountToFill = amountResult.value;

            // @ts-ignore
            const formattedAmountToFill = amountToFill;
            setInputs(inputs => ({
                ...inputs,
                [destinationFieldName]: formattedAmountToFill
            }))
        }
    }, [level1, currentInstrument]);

    useEffect(() => {
        if(!empty(currentInstrument.InstrumentId)) {
            startFeesCalculation(inputs.side, level1, currentInstrument.InstrumentId)
        }
    }, [level1, inputs.amount]);

    const handleEventName = (updates, intrument) => {
        const actionList = {
            'Buy': 'Compras',
            'Sell': 'Ventas',
        }
        const productsList = {
            'PEN': 'soles',
            'USD': 'dólares',
        }
        const action = actionList.hasOwnProperty(updates.Side) ? actionList[updates.Side] : updates.Side;
        const product1 = intrument.Product1Symbol;
        const preposition = updates.Side === 'Buy' ? 'con' : 'por';
        const product2 = productsList.hasOwnProperty(intrument.Product2Symbol) ? productsList[intrument.Product2Symbol] : intrument.Product2Symbol
        return `${action}_${product1}_${preposition}_${product2}`;
    }
    const userEventUpdate = () => {
        apex.connection.ws
          .filter(x => x.n === 'OrderStateEvent')
          .subscribe(response => {
              const updates = JSON.parse(response.o);
              if(updates.Quantity > 0){
                    const intrument = instruments.find(i => i.InstrumentId === updates.Instrument)
                    if(['Sell', 'Buy'].includes(updates.Side)) {
                        buySellAction({
                            onClick: () => {
                                googleAnalitycs(handleEventName(updates, intrument))
                                buySellAction({})
                                history.push('/wallets/product-details')
                                selectPositionAndSave(intrument.Product1)
                            }
                        })
                    }
                }
            });
    };
    
    useEffect(() => {
        userEventUpdate();
    },[apex]);

    const changeSide = () => {
        const newSide = inputs.side === '0' ? '1' : '0';
        setInputs((inputs) => ({
            ...inputs,
            side: newSide,
            quantity: '',
            amount: ''
        }))
        
    }

    const startFeesCalculation = (side, level1Data, selectedInstrumentId, initializer) => {
        const selectedLevel = level1Data[selectedInstrumentId];
        const priceSideKey = side === '0' ? 'BestOffer' : 'BestBid';
        
        if(side === '0') {
            const quantity = inputs.quantity;
            const amount = inputs.amount;
            calculateFees(quantity, amount, selectedLevel[priceSideKey], side)
        }
        if(side === '1') {
            const quantity = inputs.amount;
            const amount = inputs.quantity;
            calculateFees(quantity, amount, selectedLevel[priceSideKey], side)
        }
    }

    const findProductById = (ProductId) => {
        const result = (!empty(products) ? products : []).find(p => p.ProductId === ProductId)
        return !empty(result) ? result : {}
    }

    const validateToZero = (value) => {
        return !empty(value) ? value : 0;
    }

    const formatNumberByProduct = (amount, selectedProductId) => {
        const product = products.find(p => p.ProductId === selectedProductId);
        if(empty(product) || amount === undefined || amount === null) {
            return '0.00';
        }
        // @ts-ignore
        return formatNumberToLocale(String(Number(amount).toFixedDown(product.DecimalPlaces)),product.DecimalPlaces);
    }

    const formatPriceNumberByProduct = (amount) => {
        // @ts-ignore
        const decimalPlaces = convertIncrementToIntDecimalPlaces(currentInstrument.PriceIncrement);
        // @ts-ignore
        return formatNumberToLocale(String(Number(amount).toFixedDown(decimalPlaces)), decimalPlaces);
    }
 
    const CalculatorLoader = ({instruments, currentInstrument}) => {
        if(!empty(instruments) && !empty(currentInstrument.InstrumentId)) {
            return <React.Fragment/>
        }
        return <div className='buysell-loader-bg'>
            <Spinner animation="border" role="status">
            </Spinner>
        </div>
    }

    const defineSubmit = (e) => {
        e.preventDefault(); 
        if(blockedActions) return
        const availableBalance = formatNumberByProduct(getAvailableBalanceByProductId(currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']), currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1'])
        const amount = Number(valueAmount().replaceAll(',', ''))
        const minimum = Number(minimumAmount())
        if(isMinimun){
            return null
        } 
        if(amount < minimum){
            return showSnack({
                id: 'minimum_balance',
                text: context.t('The entered amount must not be less than the minimum established amount.'),
                type: 'warning',
            });
        }
        if(amount > Number(availableBalance.replaceAll(',', ''))) {
            e.preventDefault();
            return showSnack({
                id: 'not_have_sufficient_balance',
                text: context.t('You do not have sufficient balance in your account. Please make a deposit to proceed with your transaction'),
                type: 'warning',
                buttons: [
                    {
                        text: context.t('Cancel'),
                        className: 'button_V2 grey'
                    },
                    {
                        text: context.t('Deposit funds'),
                        className: 'button_V2 purple',
                        onClick: () => history.push('/deposit')
                    }
                ]
            });
        }
        onSubmitSimple(e)
    }

    const getInteger = (value='') => {
        const cleanedInput = value.replace(/,/g, '');
        const parsedNumber = parseFloat(cleanedInput);
        if (Number.isInteger(parsedNumber)) {
            return parsedNumber.toLocaleString();
        }
        return value;
      }

    const valueQuantity = () => {
        let result = ''
        let orderFee = 0
        let quantity = validateToZero(inputs.quantity)
        quantity = makeBigNumber(quantity).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        if(inputChange !== 'quantity') {
            if(Number(inputs.quantity) > 0){
                const newOrderFee = makeBigNumber(feeData.OrderFee).toFixed(handleSimpleDecimalPlaces(currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol']))
                orderFee = formatNumberByProduct(newOrderFee, currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2']).replaceAll(',', '')
                result = formatNumberByProduct(makeBigNumber(Number(quantity)).minus(makeBigNumber(Number(Number(quantity) > 0 ? orderFee : 0))), currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2'])
            }
            result = getInteger(result.replaceAll(',', ''))
            return result === '0' ? '' : result
        }
        result = `${inputs.quantity}`
        return result
    }

    const valueAmount = () => { 
        let result = ''
        if(inputChange !== 'amount') {
            if(Number(inputs.amount) > 0){
                const actualPrice = getLevelPrice(currentInstrument.Symbol)[inputs.side === '0' ? 'BestOffer' : 'BestBid']
                if(inputs.side === '0'){
                    result = formatNumberByProduct(Number((inputs.amount) * actualPrice), currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1'])
                }
                if(inputs.side === '1'){
                    result = makeBigNumber(Number(inputs.amount / actualPrice)).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
                    result = formatNumberByProduct(result, currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1'])
                }
            }
            return result.replaceAll(',', '')
        }
        result = `${inputs.amount}`
        return result
    }

    const valueProductToBuySell = () => {
        let result = ''
        result = validateToZero(inputs.side === '0' ? inputs.quantity : inputs.amount)
        result = makeBigNumber(result).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        if(result > 0) {
            if(inputChange === 'quantity'){
                if(inputs.side === '0'){
                    result = formatNumberByProduct(makeBigNumber(Number(feeData.OrderFee)).plus(makeBigNumber(Number(result))), currentInstrument['Product1'])
                } else {
                    result = makeBigNumber(Number(result) / getLevelPrice(currentInstrument.Symbol)['BestBid']).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
                    result = formatNumberByProduct(result, currentInstrument['Product1'])
                }
            } else {
                result = formatNumberByProduct(result, currentInstrument['Product1'])
            }
            result = makeBigNumber(result.replaceAll(',','')).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        }
        if(isCoink){
            return FormatDecimalCoinkWithOutSimbols(getInteger(formatNumberToLocale(String(result), handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))), currentInstrument['Product1Symbol'])
        }
        return getInteger(formatNumberToLocale(String(result), handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1')))
    }

    const valueQuantityToReceive = () => {
        const symbol = inputs.side === '0' ? currentInstrument.Product1Symbol : currentInstrument.Product2Symbol
        let result = ''
        let orderFee = 0
        let quantity = validateToZero(inputs.quantity)
        quantity = makeBigNumber(quantity).toFixed(handleDecimalPlaces('amount', currentInstrument.Product1Symbol, '1'))
        if(inputChange !== 'quantity') {
            const newOrderFee = makeBigNumber(feeData.OrderFee).toFixed(handleSimpleDecimalPlaces(currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol']))
            orderFee = formatNumberByProduct(newOrderFee, currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2']).replaceAll(',', '')
        }
        result = formatNumberByProduct(makeBigNumber(Number(quantity)).minus(makeBigNumber(Number(Number(quantity) > 0 ? orderFee : 0))), currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2'])
        if(isCoink){
            return FormatDecimalCoinkWithOutSimbols(result, symbol)
        }
        return result
    }

    const handleSimpleDecimalPlaces = (productSymbol) => {
        const product = products.find(p => p.Product === productSymbol) || {};
        return product.DecimalPlaces || 2
    }

    const valueCommission = () => {
        const sign = Number(inputs.amount || 0) > 0 && feeData.OrderFee > 0 ? '-' : ''
        let result = ''
        if(inputs.side === '1' && inputChange === "quantity" && Number(inputs.amount || 0) > 0){
            result = formatNumberByProduct(Number(inputs.amount) * (accountFees.FeeAmt / 100), currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2'])
        } else {
            const orderFee = makeBigNumber(feeData.OrderFee).toFixed(handleSimpleDecimalPlaces(currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol']))
            result = formatNumberByProduct(Number(inputs.amount || 0) > 0 ? Number(orderFee) : 0, currentInstrument[inputs.side === '0' ? 'Product1' : 'Product2'])
        }
        if(isCoink){
            return `${sign}${FormatDecimalCoinkWithOutSimbols(result, currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol'])}`
        }
        return `${sign}${result}`
    }

    const handleDecimalPlaces = (name, productSymbol, side = inputs.side) => {
        const product = products.find(p => p.Product === productSymbol) || {};
        if(name === 'amount' && !isEmpty(product)) {
            const productsInputs = getDecimalPlaces(name, side);
            return productsInputs[name]
        }
        return product.DecimalPlaces || 2
    }

    const valueBalance = () => {
        return formatNumberByProduct(getAvailableBalanceByProductId(currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']), currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1'])
    }

    const selectProduct1 = () => {
        let allproducts = availableInstruments().map((i) => {
            const optionInstrumentData = instruments.find(ins => ins.Symbol === i)
            if(!isEmpty(optionInstrumentData)) {
                const product1 = products.find(p => p.ProductId === optionInstrumentData.Product1)
                const product2 = products.find(p => p.ProductId === optionInstrumentData.Product2)
                return {
                    id: inputs.side === '0' ? product2.Product : product1.Product,
                    name: context.t(inputs.side === '0' ? product2.ProductFullName : product1.ProductFullName) 
                }
            }
        }).filter(i => i)
        allproducts = allproducts.filter((value, index, array) => {
            return array.findIndex((elemento) => elemento.id === value.id) === index
        })
        return allproducts
    }
    const allProduct1 = selectProduct1()
    
    const selectProduct2 = (value = '') => {
        const product = !isEmpty(value) ? value : inputs.side === '0' ? currentInstrument.Product2Symbol : currentInstrument.Product1Symbol
        let result = []
        if(!isEmpty(product)) {
            const newIntruments = instruments.filter(i => inputs.side === '0' ? i.Product2Symbol === product : i.Product1Symbol === product)
            result = availableInstruments().map((i) => {
                const optionInstrumentData = newIntruments.find(ins => ins.Symbol === i)
                if(!isEmpty(optionInstrumentData)) {
                    const product1 = products.find(p => p.ProductId === optionInstrumentData.Product1) 
                    const product2 = products.find(p => p.ProductId === optionInstrumentData.Product2)
                    return {
                        id: inputs.side === '1' ? product2.Product : product1.Product,
                        name: context.t(inputs.side === '1' ? product2.ProductFullName : product1.ProductFullName) 
                    } 
                }
            }).filter(i => i)
            result = result.filter((value, index, array) => {
                return array.findIndex((elemento) => elemento.id === value.id) === index
            })
        }
        return result
    }
    const allProduct2 = selectProduct2()

    const selectItem = (name, value) => {
        if(name === 'amount'){
            const products = selectProduct2(value)
            const instrument = inputs.side === '0' ? products[0].id + value : value + products[0].id
            onInstrumentChange({ target: { value: instrument, name: 'selectedinstrument' } })
        } else {
            const instrument = inputs.side === '0' ? value + currentInstrument.Product2Symbol : currentInstrument.Product1Symbol + value
            onInstrumentChange({ target: { value: instrument, name: 'selectedinstrument' } })
        }
    }

    const amountToAdd = (text) => {
        let amount = ''
        text.split('').map((_, i) => {
            amount = amount + (i + 1 === text.length ? 2 : 0);
        });
        return amount
    }

    const minimumAmount = () => {
        let price = makeBigNumber(currentInstrument.MinimumQuantity).toFixed()
        if(inputs.side === '0') {
            price = makeBigNumber((getLevelPrice(currentInstrument.Symbol)['BestOffer'] * Number(price)) / 1).toFixed(handleDecimalPlaces('quantity', currentInstrument.Product2Symbol));
            const parts = price.split('.');
            let response = Number(price) + 1
            if(parts.length > 1) {
                response = Number(price) + Number(`0.${amountToAdd(parts[1])}`) 
            }
            response = makeBigNumber(response).toFixed(handleDecimalPlaces('quantity', currentInstrument.Product2Symbol));
            return formatNumberByProduct(Number(response), currentInstrument['Product2'])
        }
        price = formatNumberByProduct(Number(price), currentInstrument['Product1'])
        return price
    }

    const compareMinimun = () => {
        const value = valueAmount()
        const minimum = minimumAmount().replaceAll(',', '')
        return !isEmpty(value) && (Number(value) < Number(minimum))
    }

    const isMinimun = compareMinimun()

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
            handleSubscriptionLevel1(unsubscribeLevel1, currentInstrument.InstrumentId)
            handleSubscriptionLevel1(subscribeLevel1, !isEmpty(instrumentsByAccountId) && !isEmpty(instrumentsByAccountId[0].instrumentsByAccountId) ? (instruments.find(el => el.Symbol === instrumentsByAccountId[0].instrumentsByAccountId.buysell[0]) || {}).InstrumentId: 2)
        };
      }, []);
      
    const handleSubscriptionLevel1 = async (functionToExecute, instrumentId) => {
        if(isEmpty(instrumentId)) return
        return await functionToExecute({
            instrumentId,
            "OMSId": 1 
        })
    }

    return <React.Fragment>
        <BuySellV2FormConfirm {...{show, handleClose, side: inputs.side, sendOrderToAP, inputs, currentInstrument, validateToZero, formatNumberByProduct, feeData, getLevelPrice, formatPriceNumberByProduct, valueQuantityToReceive, valueAmount, valueProductToBuySell, valueCommission, getInteger }} />
        <div className='buysell-container'>
            <CalculatorLoader {...{instruments, currentInstrument}} />
            <div className='buysell-header'>
                <div className={inputs.side === '0' ? 'buysell-active': ''} onClick={()=>inputs.side === '1' && changeSide()}>{context.t('Buy')}</div>
                <div className={inputs.side === '1' ? 'buysell-active': ''} onClick={()=>inputs.side === '0' && changeSide()}>{context.t('Sell')}</div>
            </div>
            <div className='buysell-calculator'>
                <Form
                    validated={inputs.validated}
                    onSubmit={defineSubmit}>
                    <div className='buysell-body'>
                        <p className='buysell-available-balance'>
                            {context.t('My available Balance')}{' '}
                            <b>{amountWithCurrency({
                                amount: !isCoink ? valueBalance() : FormatDecimalCoinkWithOutSimbols(valueBalance(), currentInstrument[inputs.side === '0' ? 'Product2Symbol': 'Product1Symbol']),
                                product: products.find(p => p.Product === currentInstrument[inputs.side === '0' ? 'Product2Symbol': 'Product1Symbol']) || {}
                            })}</b>
                        </p>
                        <div className='container-inputs-buy-sell'>
                            <InputElement {...{
                                inputType: 'currency',
                                values: {
                                    title: inputs.side === '0' ? `${context.t('Amount to send in {symbol}', { symbol: currentInstrument.Product2Symbol })}`: `${context.t('Amount to send in {symbol}', { symbol: currentInstrument.Product1Symbol })}`,
                                    value: valueAmount(),
                                    name: 'amount',
                                    type: 'text',
                                    autoComplete: 'Off',
                                    decimalScale: handleDecimalPlaces('amount', inputs.side === '0' ? currentInstrument.Product2Symbol : currentInstrument.Product1Symbol),
                                },
                                actions: {
                                    onChange: onChangePrice
                                },
                                // @ts-ignore
                                error: errors.amount
                            }} />
                            {!isEmpty(allProduct1) &&
                                <div className={`dropdown-buy-sell ${allProduct1.length < 2 ? 'one-item' : ''}`} ref={selectRef} onClick={() => handleClickInside('select1')}>
                                    <div className='first-item'>
                                        <img src={productsIcons[String(inputs.side === '0' ? `${currentInstrument.Product2Symbol}`: `${currentInstrument.Product1Symbol}`).toLowerCase()]} alt='Product symbol 1' width={24} height={24}/>
                                        {inputs.side === '0' ? currentInstrument.Product2Symbol : currentInstrument.Product1Symbol}
                                        {allProduct1.length > 1 && <img className='arrow-down-item' src={ArrowIcon} alt='Arrow icon' width={20} height={20}/>}
                                    </div>
                                    {allProduct1.filter(e => inputs.side === '0' ? e.id !== currentInstrument.Product2Symbol : e.id !== currentInstrument.Product1Symbol).map((i, key) => 
                                        <div key={`${i.id}-${key}-amount`} className='other-item' onClick={() => selectItem('amount', i.id)}>
                                            <img src={productsIcons[i.id.toLowerCase()]} alt={i.name} width={24}/>
                                            {i.id}
                                        </div>
                                    )}  
                                </div>
                            }
                            {isMinimun &&
                            <p className='minimum-amount-text'>
                                {context.t(inputs.side === '0' ? 'Minimum purchase amount' : 'Minimum sale amount')} : {amountWithCurrency({
                                    amount: !isCoink ? minimumAmount() : FormatDecimalCoinkWithOutSimbols(minimumAmount(), currentInstrument[inputs.side === '0' ? 'Product2Symbol': 'Product1Symbol']),
                                    product: products.find(p => p.Product === currentInstrument[inputs.side === '0' ? 'Product2Symbol': 'Product1Symbol']) || {}
                                })}
                            </p>
                            }
                        </div>
                        <RangeButtons
                            name="amount"
                            inputValue={valueAmount()}
                            onChange={(a, b) => onChangePrice({ target: { name: a, value: String(b), range: true } })}
                            max={valueBalance().replaceAll(',', '')}
                            decimal={handleDecimalPlaces('amount', inputs.side === '0' ? currentInstrument.Product2Symbol : currentInstrument.Product1Symbol)}
                        />
                        <div className='container-inputs-buy-sell'>
                            <InputElement {...{
                                inputType: 'currency',
                                values: {
                                    title: inputs.side === '0' ?  `${context.t('Amount to receive in {symbol}', { symbol: currentInstrument.Product1Symbol })}` : `${context.t('Amount to receive in {symbol}', { symbol: currentInstrument.Product2Symbol })}`,
                                    value: valueQuantity(),
                                    name: 'quantity',
                                    type: 'text',
                                    autoComplete: 'Off',
                                    decimalScale: handleDecimalPlaces('quantity', inputs.side === '0' ? currentInstrument.Product1Symbol : currentInstrument.Product2Symbol),
                                },
                                actions: {
                                    onChange: onChangePrice
                                },
                                // @ts-ignore
                                error: errors.quantity
                            }} />
                            {!isEmpty(allProduct2) &&
                                <div className={`dropdown-buy-sell ${allProduct2.length < 2 ? 'one-item' : ''}`} ref={selectRef2} onClick={() => handleClickInside('select2')}>
                                    <div className='first-item'>
                                        <img src={productsIcons[String(inputs.side === '0' ? `${currentInstrument.Product1Symbol}`: `${currentInstrument.Product2Symbol}`).toLowerCase()]} alt='Product symbol 1' width={24}/>
                                        {inputs.side === '0' ? currentInstrument.Product1Symbol : currentInstrument.Product2Symbol}
                                        {allProduct2.length > 1 && <img className='arrow-down-item' src={ArrowIcon} alt='Arrow icon' width={20} height={20}/>}       
                                    </div>
                                    {allProduct2.filter(e => inputs.side === '0' ? e.id !== currentInstrument.Product1Symbol : e.id !== currentInstrument.Product2Symbol).map((i, key) => 
                                        <div key={`${i.id}-${key}-quantity`} className='other-item' onClick={() => selectItem('quantity', i.id)}>
                                            <img src={productsIcons[i.id.toLowerCase()]} alt={i.name} width={24}/>
                                            {i.id}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <Table className='buysell-details'>
                            <tbody>
                                <tr>
                                    <td className='text-left'>{context.t('Price per {symbol}', { symbol: currentInstrument.Product1Symbol === 'COP' ? '' : currentInstrument.Product1Symbol })}</td>
                                    <td className='text-right'>{amountWithCurrency({
                                        amount: !isCoink 
                                                ? formatPriceNumberByProduct(getLevelPrice(currentInstrument.Symbol)[inputs.side === '0' ? 'BestOffer' : 'BestBid'])
                                                : FormatDecimalCoinkWithOutSimbols(formatPriceNumberByProduct(getLevelPrice(currentInstrument.Symbol)[inputs.side === '0' ? 'BestOffer' : 'BestBid']), currentInstrument.Product2Symbol),
                                        product: products.find(p => p.Product === currentInstrument.Product2Symbol) || {}
                                    })} </td>
                                </tr>
                                <tr>
                                    <td className='text-left container-help-text'>
                                        {context.t('Amount to {action}', {action: (inputs.side === '0' ? context.t('buy') : context.t('sell'))})}
                                        {inputs.side === '0' &&
                                          <OverlayTrigger
                                            key={'info1-tooltip'}
                                            placement={'right'}
                                            overlay={
                                                <Tooltip id={`info1-tooltip`} className='tooltip-buysell'>
                                                    <div className="buysell-text">{context.t('Received amount + commission')}</div>
                                                </Tooltip>
                                            }>
                                                <img src={InformationIcon} alt='Information icon'/> 
                                            </OverlayTrigger>  
                                        }
                                    </td>
                                    <td className='text-right'>{amountWithCurrency({
                                        amount: valueProductToBuySell(),
                                        product: products.find(p => p.Product === currentInstrument.Product1Symbol) || {}
                                    })}</td>
                                </tr>
                                <tr>
                                    <td className='text-left'>{context.t('{action} commission', { action: String(inputs.side === '0' ? context.t('Buying') : context.t('Selling')) })}</td>
                                    <td className={'text-right ' + (Number(inputs.amount || 0) > 0 && feeData.OrderFee > 0 ? 'substract' : '')}>{amountWithCurrency({
                                        amount: valueCommission(),
                                        product: products.find(p => p.Product === currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol']) || {}
                                    })}</td>
                                </tr>
                                <tr>
                                    <td className='text-left container-help-text'>
                                        {context.t('Amount to receive')}
                                        <OverlayTrigger
                                            key={'info2-tooltip'}
                                            placement={'right'}
                                            overlay={
                                                <Tooltip id={`info2-tooltip`} className='tooltip-buysell'>
                                                    <div className="buysell-text">{context.t(inputs.side === '0' ? 'Amount received in your wallet' : 'Amount received after sales commission')}</div>
                                                </Tooltip>
                                            }>
                                            <img src={InformationIcon} alt='Information icon'/> 
                                        </OverlayTrigger>
                                    </td>
                                    <td className='text-right'><b>{amountWithCurrency({
                                        amount: valueQuantityToReceive(),
                                        product: products.find(p => p.Product === currentInstrument[inputs.side === '0' ? 'Product1Symbol' : 'Product2Symbol']) || {}
                                    })}</b></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {
                        blockedActions ? 
                            <div className='coink-alert-swiping-now'>
                                <p>
                                {context.t(alertsContentCoink.swipingIsNowMessage)}
                                </p>
                            </div> : 
                            <FormButtonV2
                                type={blockedActions ? 'button' : 'submit'}
                                variant={empty(errors) ? 'dark' : 'light'}
                                disable={Number(validateToZero(inputs.amount) > Number(formatNumberByProduct(getAvailableBalanceByProductId(currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']), currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']) ? true: false)) || blockedActions}
                                className={`${isMinimun || blockedActions ? 'disable-btn-buysell ' : ''}` + "submit-button buysell-button button_V2 purple " + `side${inputs.side}` + Number(validateToZero(inputs.amount) > Number(formatNumberByProduct(getAvailableBalanceByProductId(currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']), currentInstrument[inputs.side === '0' ? 'Product2' : 'Product1']) ? ' disabled': ''))}>
                                {
                                    inputs.side === '0' ? context.t('Buy {currency1} with {currency2}', { currency1: currentInstrument.Product1Symbol, currency2: currentInstrument.Product2Symbol}) :
                                    context.t('Sell {a} for {b}', { a: currentInstrument.Product1Symbol, b: currentInstrument.Product2Symbol})
                                }
                            </FormButtonV2>
                    }
                    
                    
                </Form>
            </div>
        </div>
    </React.Fragment>
}

BuySellV2Form.contextTypes = {
    t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {

    const {
        level1,
        instrumentsByAccountId,
        instrument: {instruments},
        product: {products},
        user: { selectedAccountId },
        position: { positions },
        blockedActions
    } = state;
    return {
        level1, instrumentsByAccountId, instruments, products, selectedAccountId, positions, blockedActions,
        isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
    };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      getOrderFee: payload => {
        return dispatch(callAPI('GetOrderFee', payload)).then(function (res) {
          return res;
        });
      },
      getAccountFees: payload => {
        return dispatch(callAPI('GetAccountFees', payload)).then(function (res) {
          return res;
        });
      },
      getAccountInstrumentStatistics: payload => {
        return dispatch(callAPI('GetAccountInstrumentStatistics', payload)).then(function (res) {
          return res;
        });
      },
      sendOrder: payload => {
        return dispatch(callAPI('SendOrder', payload)).then(function (res) {
          return res;
        });
      },
      subscribeLevel1: payload => {
        return dispatch(callAPI('SubscribeLevel1', payload)).then(function (res) {
          return res;
        });
      },
      unsubscribeLevel1: payload => {
        return dispatch(callAPI('UnsubscribeLevel1', payload)).then(function (res) {
          return res;
        });
      },
      showSnack: function showSnack(text) {
        return dispatch(_showSnack(text));
      },
      buySellAction: function buySellAction(payload) {
        return dispatch(_buySellAction(payload));
      },
      selectPositionAndSave: function selectPositionAndSave(id) {
        dispatch(_selectPositionAndSave(id));
      }
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(BuySellV2Form);