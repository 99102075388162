import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { updateValidation as _updateValidation } from '../redux/actions/set_validation';
import empty from 'is-empty';
import PageHeaderLayout from '../layouts/PageHeaderLayout/PageHeaderLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import withAuthenticationCoink from '../helpers/withAuthenticationCoink';
import DashboardPage from './DashboardPage';
import DepositPage from './Deposit/DepositPage';
import MarketDataPage from './MarketDataPage';
import BuySellPage from './BuySellPage';
import WalletsPage from './WalletsPage';
import ProductDetailsPage from './ProductDetailsPage';
import SettingsPage from './SettingsPage';

import ExchangePage from './ExchangeV2/ExchangePageContainer';
import EasyBuyBeta from './EasyBuy/EasyBuyPageBeta';
import EasyBuyPageAddCard from './EasyBuy/EasyBuyComponents/EasyBuyPageAddCard';
import EasyBuyPageMyCards from './EasyBuy/EasyBuyComponents/EasyBuyPageMyCards';
import IntermediateLevelPage from './Level/IntermediateLevelPage';
import CorporateLevelPage from './Level/CorporateLevelPage';
import CorporateLevelPagePeople from './Level/CorporateLevelPagePeople';
import CorporateLevelPagePeopleDetail from './Level/CorporateLevelPagePeopleDetail';
import CorporateLevelPageAddCorporateShareholder from './Level/CorporateLevelPageAddCorporateShareholder';
import FixedLeftFluidLayout from 'apex-web/lib/layouts/FixedLeftFluidLayout/FixedLeftFluidLayout';
import WalletDetailsBackButtonComponent from 'apex-web/lib/components/WalletDetails/WalletDetailsBackButtonComponent';
import BalancesMenuContainer from 'apex-web/lib/components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsLayout from 'apex-web/lib/layouts/WalletDetailsLayout/WalletDetailsLayout';
import BinancePage from './BinancePage';
import BinanceDashboard from './BinancePage/BinanceDashboard';
import CorporateLevelPageBeta from './Level/CorporateLevelPageBeta';
import EasyBuyPageMyPaymentAttemps from './EasyBuy/EasyBuyComponents/EasyBuyPageMyPaymentAttemps';
import InteriorPagePhoneConfirmation from './InteriorPagePhoneConfirmation';
import KYCV2FillPage from './KYCV2/KYCV2FillPage';
import KYCV2SuccessPage from './KYCV2/KYCV2SuccessPage';
import KYCV2ErrorPage from './KYCV2/KYCV2ErrorPage';
import CryptoDJ from './CryptoDJ/CryptoDJ';
import ModalPopup from './News/ModalPopup';
import HeadBand from './News/Marquee';
import { getBannerPopup } from './News/MessagesHooks';
import KYCV2VIPPage from './KYCV2/KYCV2VIPPage';
import DisclaimerModal from './DisclaimerModal/DisclaimerModal';
import UpdateInformationModal from './UpdateInformationModal/UpdateInformationModal';
import WithdrawConfirm from './WithdrawConfirm/WithdrawConfirm';
import { connect } from 'react-redux';
import isEmpty from 'is-empty';
import ExpiredDocumentModal from '../components/ExpiredDocument/Modal';
import { getNewLink } from '../components/ExpiredDocument/ExpiredDocumentController';
import CardPage from './CardPage/CardPage';
import WithdrawPage from './Withdraw/WithdrawPage';
import CardController from './CardPage/actions/CardController';
import PropTypes from 'prop-types';
import UserDropdown from '../layouts/PageHeaderLayout/UserDropdown/UserDropdown';
import CoinkDepositFinish from './bnxv2/Pages/CoinkDepositFinish';
import BanexcardRoutes from './BanexcardRoutes';

const InteriorPage = (props, context) => {
  let { theme, changeTheme, location, userInfo, account, expiredDocumentData, component, GetValidate2FARequiredEndpoints, updateValidation, isCoink } = props;
  let users = [
    'andrewvergel',
    'david.arrarte',
    'lc',
    'msrangelh',
    'binancetest'
  ];
  const [blocked, setBlocked] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kycLink, setKycLink] = useState('');
  const [resendGenerate, setResendGenerate] = useState(false);
  const [initial, setInitial] = useState(false);
  const [isbanexcardUser, setIsBanexcardUser] = useState(false);
  const [banexcardInfo, setBanexcardInfo] = useState({
    cardInfo: {},
    balance: {},
    transactions: []
  });
  const [loadingBanexCard, setLoadingBanexCard] = useState(true);
  const [initialTransactions, setInitialTransactions] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [parametersB89, setParametersB89] = useState({});

  const validationByRoute = !empty(location) &&
  (!location.pathname.includes('coink') &&
    !location.pathname.includes('verification/start') &&
    !location.pathname.includes('verification/fill') &&
    !location.pathname.includes('verification/beginner') &&
    !location.pathname.includes('verification/intermediate') &&
    !location.pathname.includes('verification/advanced') &&
    !location.pathname.includes('verification/master') &&
    !location.pathname.includes('verification/vip-dependent') &&
    !location.pathname.includes('verification/vip-independent') &&
    !location.pathname.includes('start/vip') &&
    !location.pathname.includes('start/master') &&
    !location.pathname.includes('verification/success') &&
    !location.pathname.includes('verification/error'))

  var check = users.includes(props.UserInfo.UserName);
  const StyleBnx = () => {
    let StyleBnx;
    if (theme === 'light') {
      StyleBnx = (
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n:root {\n\t--tv-chart__bg-color: #FFFFFF;\n    --secondary__bg-color: rgb(100, 100, 100)!important; /* color de fondo*/\n    --table__row-separator-color: rgba(255, 255, 255, 1);\n    --tv-chart__scale-font-color: rgb(78, 78, 78);\n    --tv-chart__grid-color: rgba(78, 78, 78, 1);\n    --tv-chart__grid-vertical-color: rgba(255, 255, 255, 1);\n    --tv-chart__grid-horizontal-color: rgba(255, 255, 255, 1);\n}\n'
          }}
        />
      );
    } else {
      StyleBnx = (
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n:root {\n\t--tv-chart__bg-color: #404060;\n    --secondary__bg-color: rgb(100, 100, 100)!important; /* color de fondo*/\n    --table__row-separator-color: rgba(255, 255, 255, 0.5);\n    --tv-chart__scale-font-color: rgb(255, 255, 255);\n    --tv-chart__grid-color: rgba(255, 255, 255, 0.3);\n    --tv-chart__grid-vertical-color: rgba(255, 255, 255, 0.3);\n    --tv-chart__grid-horizontal-color: rgba(255, 255, 255, 0.3);\n}\n'
          }}
        />
      );
    }
    return StyleBnx;
  };

  const getAllIncomingMessages = async () => {
    try {
      setLoading(true);
      let getmessages = await getBannerPopup('private', userInfo.UserId);
      if (getmessages.data.hasOwnProperty('messages')) {
        let allMessages = getmessages.data.messages;
        setMessages(allMessages);
      }
    } catch (error) {
      console.error('Error on getAllIncomingMessages', error);
    }
  };

  const getTransactions = (initial = initialTransactions) => {
    if (initial) {
      CardController.getB89Transactions({
        userInfo,
        setBanexcardInfo,
        setLoadingTransactions,
        setInitialTransactions,
        context
      })
    }
  }

  const reloadTransactions = async () => {
    setLoadingTransactions(true)
    await CardController.getB89Balances({
      userInfo,
      setBanexcardInfo
    })
    getTransactions(true)
  }

  const getCardInfo = () => {
    CardController.getB89CardInfo({
      parametersB89,
      setBanexcardInfo
    })
  }

  useEffect(
    () => {
      if(isEmpty(userInfo)) return
      if (!loading) {
        getAllIncomingMessages();
      }
    },
    [loading, userInfo]
    );

    const handleExpiredDocumentModal = async () => {
      await getNewLink({ userInfo, setKycLink })
    }
    useEffect(() => {
      if(isEmpty(userInfo) || isEmpty(account) || account.VerificationLevel < 11) return
      if((expiredDocumentData.daysFromExpiration <= 0 && expiredDocumentData.daysFromExpiration >= -5) || expiredDocumentData.mustUpdate) {
        handleExpiredDocumentModal()
      }
    }, [userInfo, account, expiredDocumentData]);

  const getValidate2FARequired = async() => {
    const res = await GetValidate2FARequiredEndpoints();
    updateValidation({ '2FA': res })
  }

  useEffect(() => {
    if(!isEmpty(userInfo) && !initial) {
      setInitial(true)
      getValidate2FARequired()
      CardController.getB89Info({
        userInfo,
        setIsBanexcardUser,
        setLoadingBanexCard,
        setBanexcardInfo,
        setParametersB89
      })
    }
  }, [userInfo]);
  return (
    <React.Fragment>
      {component.visible &&
        component.elements.map((element) => {
          return element
        })
      }
      {!empty(location) && !location.pathname.includes('coink') ? (
        <React.Fragment>
          <HeadBand messages={messages} ClassName="interior-headband" />
          <ModalPopup messages={messages} />
        </React.Fragment>
      ) : (
        ''
      )}
      {/* DEPRECATED : 14/04/2023 */}
      {/* <Route
        exact
        path={'/coink/dashboard/'}
        render={props => (
          <React.Fragment>
            <CoinkDashboard {...props} setCoinkHeader={setCoinkHeader} />
          </React.Fragment>
        )}
      />
      <Route
        path={'/coink/internal/'}
        render={props => (
          <React.Fragment>
            <CoinkInteriorRoutes {...props} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/coink/dashboard/:token/confirm'}
        render={props => (
          <React.Fragment>
            <HeaderBanexcoin disabledOptions={true} />
            <InteriorPagePhoneConfirmation
              setBlocked={setBlocked}
              blocked={blocked}
              theme={theme}
              changeTheme={changeTheme}
              defaultTheme={'white'}
              location={props.location}
              prefix={'/coink'}
            />
            <CoinkConfirm {...props} />
          </React.Fragment>
        )}
      /> */}
      {/* <StyleBnx /> */}

      {/* DEPRECATED : 14/04/2023 */}
      {/* <Route
        path={'/coink/settings'}
        render={props => (
          <React.Fragment>
            <SettingsPage
              prefix={'/coink'}
              {...props}
              headerChildren={
                <React.Fragment>
                  <InteriorPagePhoneConfirmation
                    setBlocked={setBlocked}
                    blocked={blocked}
                    theme={theme}
                    changeTheme={changeTheme}
                    defaultTheme={'white'}
                    location={props.location}
                    prefix={'/coink'}
                  />
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      /> */}
      {!empty(location) &&
      (location.pathname.includes('verification/start') ||
        location.pathname.includes('verification/fill') ||
        location.pathname.includes('verification/success') ||
        location.pathname.includes('verification/error')) ? (
        <React.Fragment>
          <InteriorPagePhoneConfirmation
            setBlocked={setBlocked}
            blocked={blocked}
            theme={theme}
            changeTheme={changeTheme}
            location={props.location}
            setResendGenerate={setResendGenerate}
          />
        </React.Fragment>
      ) : (
        ''
      )}

      <Route
        exact
        path={'/verification/beginner'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage
              {...props}
              blocked={blocked}
              levelName={'beginner'}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/intermediate'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage
              {...props}
              blocked={blocked}
              levelName={'intermediate'}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/advanced'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage
              {...props}
              blocked={blocked}
              levelName={'advanced'}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/master'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage {...props} blocked={blocked} levelName={'master'} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/master-dependent'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage
              {...props}
              blocked={blocked}
              levelName={'master-dependent'}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/master-independent'}
        render={props => (
          <React.Fragment>
            <KYCV2FillPage
              {...props}
              blocked={blocked}
              levelName={'master-independent'}
            />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/start/master'}
        render={props => (
          <React.Fragment>
            <KYCV2VIPPage {...props} blocked={blocked} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/success'}
        render={props => (
          <React.Fragment>
            <KYCV2SuccessPage {...props} />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path={'/verification/error'}
        render={props => (
          <React.Fragment>
            <KYCV2ErrorPage {...props} />
          </React.Fragment>
        )}
      />
        {validationByRoute && (
          <React.Fragment>
            <PageHeaderLayout
              theme={theme}
              changeTheme={changeTheme}
              isbanexcardUser={isbanexcardUser}
            />
            <DisclaimerModal />
            <UpdateInformationModal />
            <ExpiredDocumentModal data={expiredDocumentData} kycLink={kycLink} />
          </React.Fragment>
        )}
        <div>
          {validationByRoute && (
            <React.Fragment>
              <UserDropdown />
              <CryptoDJ />
              {!location.pathname.includes('cards') && 
              !location.pathname.includes('exchange') &&
              <React.Fragment>
                <WithdrawConfirm />
                <InteriorPagePhoneConfirmation
                  setBlocked={setBlocked}
                  blocked={blocked}
                  theme={theme}
                  changeTheme={changeTheme}
                  location={props.location}
                  setResendGenerate={setResendGenerate}
                />
              </React.Fragment>
            }
            </React.Fragment>
          )}
          <Route path={'/settings'}>
            <SettingsPage />
          </Route>
          <Route exact path={'/dashboard'}>
            <DashboardPage messages={messages} resendGenerate={resendGenerate}/>
          </Route>
          {
            !isCoink && (
              <Route path={'/exchange/:instrument'}>
                <ExchangePage theme={theme} />
              </Route>
            )
          }
          <Route exact path={'/deposit'}>
            <DepositPage />
          </Route>
          <Route exact path={'/withdraw'}>
            <WithdrawPage />
          </Route>
          <Route path={'/dashboard/market-data'}>
            <MarketDataPage />
          </Route>
          <Route exact path={'/wallets'}>
            <WalletsPage />
          </Route>
          <Route path="/wallets/wallet-detail">
            <FixedLeftFluidLayout
              left={[
                <WalletDetailsBackButtonComponent key="1" />,
                <BalancesMenuContainer key="2" />
              ]}>
              <WalletDetailsLayout />
            </FixedLeftFluidLayout>
          </Route>
          <Route path={'/buy-sell'}>
            <BuySellPage />
          </Route>
          {/* {isbanexcardUser &&
            <Route path={'/cards'}>
              <CardPage
                loadingBanexCard={loadingBanexCard}
                banexcardInfo={banexcardInfo}
                loadingTransactions={loadingTransactions}
                getTransactions={getTransactions}
                reloadTransactions={reloadTransactions}
                getCardInfo={getCardInfo}
              />
            </Route>
          } */}
          <Route exact path={'/binance'}>
            {check ? <BinancePage /> : <div>Not authorized</div>}
          </Route>
          <Route exact path={'/binance/dashboard'}>
            {check ? <BinanceDashboard /> : <div>Not authorized</div>}
          </Route>
          <Route path={'/wallets/product-details'}>
            <ProductDetailsPage />
          </Route>
          <Route exact path={'/easy-buy'}>
            <EasyBuyBeta />
          </Route>
          <Route exact path={'/easy-buy/add-card'}>
            <EasyBuyPageAddCard />
          </Route>
          <Route exact path={'/easy-buy/my-credit-cards'}>
            <EasyBuyPageMyCards />
          </Route>
          <Route exact path={'/easy-buy/my-payment-attempts'}>
            <EasyBuyPageMyPaymentAttemps />
          </Route>
          <Route
            path={'/easy-buy/my-payment-attempts/:limit/:page'}
            component={props => (
              <React.Fragment>
                <EasyBuyPageMyPaymentAttemps {...props} />
              </React.Fragment>
            )}
          />
          <Route path={'/intermediate-level'}>
            <IntermediateLevelPage />
          </Route>
          <Route path={'/kyc/corporate'}>
            <CorporateLevelPageBeta />
          </Route>
          <Route path={'/corporate-level'}>
            <CorporateLevelPage />
          </Route>
          <Route path={'/corporate-level-people'}>
            <CorporateLevelPagePeople />
          </Route>
          <Route path={'/corporate-people'}>
            <CorporateLevelPagePeopleDetail />
          </Route>
          <Route path={'/corporate-people/:params'}>
            <CorporateLevelPagePeopleDetail />
          </Route>
          {/* se inactiva sin uso<Route
            path={'/corporate-institution'}
            component={CorporateLevelPageCorporateDetail}
            /> */}
          <Route path={'/corporate-add-corporate-shareholder'}>
            <CorporateLevelPageAddCorporateShareholder />
          </Route>
          {/* <Route path={'/component'} component={ComponentExhibitPage} /> */}
          {/* <Route
              path={'/margin-balances'}
              component={() => (
                <MarginBalancesLayout detailsLink="/wallets/wallet-detail" />
              )}
            />
            <Route
              path={'/asset-activity-details'}
              component={AssetActivityDetailsLayout}
            /> */}
          <Route path={'/coink/deposit/finish'}>
            <CoinkDepositFinish />
          </Route>
         <BanexcardRoutes banexcardInfo={banexcardInfo} getCardInfo={getCardInfo}/>
        </div>
    </React.Fragment>
  );
};


const mapStateToProps = state => {
  return {
    userInfo: state.user.userInfo,
    account : !isEmpty(state.user.accounts) ? state.user.accounts[0] : {},
    expiredDocumentData : !isEmpty(state.globalConfig) ? state.globalConfig.identityDocument : {},
    component: state.component,
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : true,
  };
}

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    GetValidate2FARequiredEndpoints: () =>
      dispatch(callAPI('GetValidate2FARequiredEndpoints', {})).then(res => {
        return res;
    }),
    updateValidation: payload => {
      dispatch(_updateValidation(payload));
    }
  };
};

InteriorPage.contextTypes = {
  t: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticationCoink(InteriorPage));
