import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ActivityReportingButtonsComponent.css';

var activityReportingButtonsClasses = getBEMClasses([
  'activity-reporting-buttons'
]);

var REPORT_TABS = {
  SINGLE: 0,
  CYCLICAL: 1
};

var ActivityReportingButtonsComponent = function ActivityReportingButtonsComponent(
  _ref,
  context
) {
  var openReportsSidePane = _ref.openReportsSidePane;
  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      APButton,
      {
        type: 'submit',
        onClick: function onClick() {
          return openReportsSidePane({ index: REPORT_TABS.SINGLE });
        },
        customClass: activityReportingButtonsClasses()
      },
      context.t('Single Report')
    ),
    React.createElement(
      APButton,
      {
        type: 'submit',
        onClick: function onClick() {
          return openReportsSidePane({ index: REPORT_TABS.CYCLICAL });
        },
        customClass: activityReportingButtonsClasses()
      },
      context.t('Cyclical Report')
    )
  );
};

ActivityReportingButtonsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ActivityReportingButtonsComponent;
