import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import I18n from 'redux-i18n';
import history from './helpers/history';
import * as numberFormatter from 'apex-web/lib/helpers/numberFormatter';
import initAPEXWebActions from 'apex-web/lib/helpers/apexWebActions';
import { unregister } from './registerServiceWorker';
import config from './config';
import cssVars from 'css-vars-ponyfill';

import './styles/base/Fonts.css';
import './styles/base/Base.css';
import './styles/theme/variables.css';
import './styles/components/common/Divider.css';
import './styles/layout/Table.css';
import './styles/layout/app.css';
import './styles/components/common/index.css';

//import 'react-phone-input-2/dist/style.css';
import 'rc-datepicker/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import store from './redux/store';

async function main() {
  try {
    const response = await fetch('https://api.ipify.org/?format=json')
    const { ip } = await response.json()

    const mustFilterIpAddresses = process.env.REACT_APP_MUST_FILTER_IP_ADDRESSES === 'true'
    const allowedIpAddresses = process.env.REACT_APP_ALLOWED_IP_ADDRESSES.split(';')
    if (mustFilterIpAddresses && !allowedIpAddresses.includes(ip))
      window.location.href = 'https://www.banexcoin.com'
  } catch (e) {
    window.location.href = 'https://www.banexcoin.com'
  }

  initAPEXWebActions();

  require('./styles/theme/light.css');
  var detect_browser_language = navigator.language || navigator.userLanguage;
  var iso_browser_language = detect_browser_language.split('-', 1);
  localStorage.setItem('browser_language', iso_browser_language[0]);

  numberFormatter.init({
    locale: config.global.locale,
    getDecimalPlaces: () => store.getState().product.decimalPlaces
  });

  window.cssVariables = {};
  cssVars({
    watch: true,
    silent: true,
    onComplete: function(cssText, styleNode, cssVariables) {
      window.cssVariables = cssVariables;
    }
  });

  const initialState = {
    symbol: 'default'
  };

  const rootElement = document.getElementById('root');

  ReactDOM.render(
      <Provider store={store} globalState={initialState}>
        <Router history={history}>
          <I18n translations={window.APEXTranslations || { en: {} }}>
            <App />
          </I18n>
        </Router>
      </Provider>,
      rootElement
  );
}

main()

unregister();
