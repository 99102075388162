import isEmpty from "is-empty";
import HandlerBackend from "../helpers/HandlerBackend";
import { toast } from "react-toastify";

class CoinkTransactionsController {
  static withdraw = async ({
    userInfo,
    inputs,
    setLoadingWithdrawCoink,
    setError
  }) => {
    try {
      setLoadingWithdrawCoink(true);
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        method: "POST",
        path: `/api/coink/deposit-to-coink`,
        data: {
          accountId: userInfo.AccountId,
          amount: Number(inputs.amount),
          twoFa: inputs.twoFa,
        },
      });
      if (!isEmpty(response.data) && !isEmpty(response.data.message === "success")) {
        const cerrar = document.getElementsByClassName(
          "ap-sidepane__close-button"
        );
        if (cerrar) {
          cerrar[0].click();
        }
        return true;
      }
      return false;
    } catch (error) {
      if (
        !isEmpty(error.response) &&
        !isEmpty(error.response.data) &&
        !isEmpty(error.response.data.message)
      ) {
        setError(error.response.data.message);
      }
      return false;
    } finally {
      setLoadingWithdrawCoink(false);
    }
  };
  static deposit = async ({
    userInfo,
    inputs,
    setLoadingDepositCoink,
    context,
  }) => {
    try {
      setLoadingDepositCoink(true);
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        method: "POST",
        path: `/api/coink/deposit-to-banexcoin`,
        data: {
          accountId: userInfo.AccountId,
          amount: Number(inputs.amount),
        },
      });
      if (
        !isEmpty(response.data) &&
        !isEmpty(response.data.data) &&
        response.data.data.redirectUrl
      ) {
        window.location.href = response.data.data.redirectUrl;
      }else{
        return {
          status: false,
          message: context.t("An error occurred while processing your deposit, please try again."),
        }
      }
    } catch (error) {
      return {
        status: false,
        message: !isEmpty(error.response) && !isEmpty(error.response.data)
          ? error.response.data.message
          : context.t("An error occurred while processing your deposit, please try again."),
      }
    } finally {
      setLoadingDepositCoink(false);
    }
  };
  static depositCallback = async ({
    userInfo,
    requestId,
    setLoadingCallback,
  }) => {
    try {
      setLoadingCallback(true);
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        method: "POST",
        path: `/api/coink/deposit-to-banexcoin/callback/${requestId}`,
      });
      if (!isEmpty(response.data) && !isEmpty(response.data.data)) {
        return response.data.data.coinkWithdrawalStatus;
      }
      return false;
    } catch (error) {
      return false;
    } finally {
      setLoadingCallback(false);
    }
  };
  static getCoinkLimitToWithdraw = async ({ userInfo, setMaximumLimitCoink}) => {
    try {
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        method: "GET",
        path: `/api/coink/deposit-to-coink/limit`,
      });
      if (!isEmpty(response.data) && !isEmpty(response.data.data)) {
        if(response.data.data.difference === null){
          setMaximumLimitCoink(Infinity);
          return true;
        }
        setMaximumLimitCoink(response.data.data.difference);
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}

export default CoinkTransactionsController;
