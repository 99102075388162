import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import RecentActivityWithFilterComponent from './RecentActivityWithFilterComponent';
import resize from 'apex-web/lib/hocs/resize';

var RecentActivityWithFilterComponentForm = reduxForm({
  form: 'recent-activity',
  destroyOnUnmount: false,
  initialValues: {
    type: 'all',
    startDate: undefined,
    endDate: undefined
  }
})(RecentActivityWithFilterComponent);

export default connect()(resize(RecentActivityWithFilterComponentForm));
