import React from 'react';
import PropTypes from 'prop-types';

import SuccessIcon from '../../../../images/coink-imgs/successIconV2.svg';
import ErrorIcon from '../../../../images/coink-imgs/errorIconV2.svg';
import PendingIcon from '../../../../images/coink-imgs/pendingIconV2.svg';

import './statusMessage.css';
import isEmpty from 'is-empty';

const StatusMessage = (
  { type = 'SUCCESS', title = '', message = '', children },
  context
) => {
  const imageType = {
    success: SuccessIcon,
    pending: PendingIcon,
    error: ErrorIcon
  };
  const styleType = type.toUpperCase();
  const img = imageType[type.toLowerCase()];
  return (
    <div className={`statusTransaction firstType_${styleType}`}>
      <div className="contentIconStatus">
        <div className={`spaceConIcon secondType_${styleType}`}>
          <img src={img} width={25} />
        </div>
      </div>
      {!isEmpty(title) && (
        <h2
          className="titInfoDetail"
          dangerouslySetInnerHTML={{
            __html: context.t(title)
          }}
        />
      )}
      {!isEmpty(message) && <p>{context.t(message)}</p>}
      {children && children}
    </div>
  );
};

StatusMessage.contextTypes = {
  t: PropTypes.func
};

export default StatusMessage;
