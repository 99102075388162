import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APSelect from 'apex-web/lib/components/common/APSelect';
import APLabelWithText from '../../../../components/common/APLabelWithText/APLabelWithText';
import APCheckbox from 'apex-web/lib/components/common/APCheckbox';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { getOrderFee } from 'apex-web/lib/helpers/orderHelper';
import APInput from 'apex-web/lib/components/common/APInput';
import { biggerThanZero } from 'apex-web/lib/helpers/formValidations';

const bemClasses = getBEMClasses("advanced-order-form");

const ReportBlockTradeForm = (props, context) => {
  const {
    position1,
    position2,
    instruments,
    handleSubmit,
    submitting,
    disableTrading,
    formObj,
    decimalPlaces,
    selectedInstrument,
    invalid,
    positions,
    VerificationRequiredComponent,
    showLockedIn,
    orderTotal,
    orderNet
  } = props;

  console.log(position1, position2)

  const { side } = formObj.values;
  const boughtProductSymbol =
    side === buyValue
      ? selectedInstrument.Product1Symbol
      : selectedInstrument.Product2Symbol;
  const soldProductSymbol =
    side === buyValue
      ? selectedInstrument.Product2Symbol
      : selectedInstrument.Product1Symbol;

  const availableAmountValue = (band = false) => {
    let p1 = position1
    let p2 = position2
    if(side !== buyValue){
      p1 = position2
      p2 = position1
    }
    if(band){
      return `${p2.Amount} ${p2.ProductSymbol}`
    }
    return`${p1.Amount} ${p1.ProductSymbol}`
  }

  return (
    <form onSubmit={handleSubmit} className={bemClasses("body")}>
      <div className={bemClasses('main-section')}>
        <div>
          <div className={bemClasses()}>
            <APSelect
              name="instrument"
              label={`${context.t('Instrument')}`}
              customClass={bemClasses()}
              options={instruments.map(instrument => {
                const { InstrumentId } = instrument;
                return { value: InstrumentId, label: instrument.Symbol };
              })}
              showTriangles={true}
            />
          </div>

          <div className={bemClasses()}>
            <APInput
              name="counterparty"
              label={`${context.t('Counterparty')}`}
              customClass={bemClasses()}
              validate={[biggerThanZero]}
            />
            {showLockedIn && (
              <div className={bemClasses('checkbox-container')}>
                <APCheckbox
                  name="lockedIn"
                  type="checkbox"
                  id="locked-in"
                  label={context.t('Locked In')}
                />
              </div>
            )}
          </div>
        </div>
      </div>


      <div className={bemClasses('inputs-container')}>
        <div className={bemClasses()}>
          <p className='available-amount-text'>{context.t("Available amount")}: {availableAmountValue()}</p>
          <APNumberInput
            name="quantity"
            placeholder="0"
            label={`${context.t('Product Bought')}`}
            decimalPlaces={decimalPlaces[boughtProductSymbol]}
            customClass={bemClasses()}
            labelInInput={boughtProductSymbol}
            validate={[biggerThanZero]}
          />
        </div>
        <p className='available-amount-text'>{context.t("Available amount")}: {availableAmountValue(true)}</p>
        <APNumberInput
          name="price"
          placeholder="0"
          label={`${context.t('Product Sold')}`}
          decimalPlaces={decimalPlaces[soldProductSymbol]}
          customClass={bemClasses()}
          labelInInput={soldProductSymbol}
          validate={[biggerThanZero]}
        />
      </div>


      <div className={bemClasses()}>
        <APLabelWithText
          label={`${context.t('Fees')}:`}
          text={getOrderFee(formObj, positions)}
          customClass={bemClasses()}
          otherClassName={"details"}
        />
      </div>

      <div className={bemClasses()}>
        <APLabelWithText
          label={`${context.t('Received')}:`}
          text={orderNet}
          customClass={bemClasses()}
          otherClassName={"details"}
        />
      </div>

      <APLabelWithText
        label={`${context.t('Order Total')}:`}
        text={orderTotal}
        customClass={bemClasses()}
        otherClassName={"details"}
      />
      <APButton
        type="submit"
        disabled={submitting || invalid || disableTrading}
        customClass={bemClasses()}
        styleName={
          formObj.values.side === sellValue
            ? styleNames.subtractive
            : styleNames.additive
        }>
        {submitting ? context.t('Processing...') : context.t('Create order')}
      </APButton>
      <div className={bemClasses('verification-required')}>
        {VerificationRequiredComponent}
      </div>
    </form>
  );
};

ReportBlockTradeForm.defaultProps = {
  showLockedIn: true
};

ReportBlockTradeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  position1: PropTypes.object.isRequired,
  position2: PropTypes.object.isRequired,
  instruments: PropTypes.arrayOf(instrumentPropType).isRequired,
  formObj: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  disableTrading: PropTypes.bool.isRequired,
  showLockedIn: PropTypes.bool.isRequired
};

ReportBlockTradeForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReportBlockTradeForm;
