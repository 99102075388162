import React from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeliveryPhysicalCardResult = ({ handleSteps: { previous }, address, type = 'success' }, context) => {
    const ht = useHistory()
    const resultTypes = {
        success: {
            actions: [
                {
                    label: context.t("Understood"),
                    action: () => ht.push("/cards"),
                }
            ],
            component: () => {
                return (
                    <React.Fragment>
                        <h2>{context.t("Banexcard card shipping in process!")}</h2>
                        <div className="container-text">
                            <p>{context.t('Your request is in process. In approximately 24 to 48 hours you will receive an email informing the status of it.')}</p>
                            <p>{context.t('You chose to receive your card at the following address')}:</p>
                            <p><b>{address}</b></p>
                            <p>{context.t('You will receive the card within 10 business days, remember to present your DNI or CE to receive it. In case you need help you can write to us by Live Chat or to')}<a className="external-link" href="/">{"soporte@banexcoin.com"}</a></p>
                        </div>
                    </React.Fragment>
                );
            },
        },
        error: {
            message: context.t("An error occurred while processing your request, please try again or write to us to help you by Chat or to soporte@banexcoin.com"),
            actions: [
                {
                    label: context.t("Try again."),
                    action: () => previous(),
                }
            ]
        },
    };
    return (
        <CardGradient
            actions={resultTypes[type].actions}
            type={type}
            title={resultTypes[type].title}
            message={resultTypes[type].message}
            customClassName='recharge-modal'
        >
            {resultTypes[type].component && resultTypes[type].component()}
        </CardGradient>
    );
}

DeliveryPhysicalCardResult.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default DeliveryPhysicalCardResult;