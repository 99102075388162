import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../../../components";
import bnxBlack from "../../../../../images/bnx-black.svg";
import { currencyFormatter } from "../../../../utils";

function DetailsConfirmation(props, context) {
  const { amount, commission, total, handleSteps, date, setDate } = props;
  const actions = [
    {
      label: context.t("Confirm"),
      action: handleSteps.next,
    }
  ]

  useEffect(() => {
    setDate({
      date: new Date(Date.now()).toLocaleDateString(),
      time: new Date(Date.now()).toLocaleTimeString()
    })
  },[])

  return (
    <CardGradient actions={actions} backAction={handleSteps.previous} customClassName="details-confirmation-w" title={context.t("Confirm recharge")}>
      <div className="details-confirmation">
      <img
          src={bnxBlack}
          className="bnx-bg-black"
          alt="bnx black icon"
          width={50}
        />
        <div className="details-confirmation-row">
          <span>{context.t("Date")}:</span>
          <span className="value">{date.date}</span>
        </div>
        <div className="details-confirmation-row">
          <span>{context.t("Hour")}:</span>
          <span className="value">{date.time}</span>
        </div>
        <div className="details-confirmation-row">
          <span>{context.t("Amount to recharge")}:</span>
          <span className="value">{currencyFormatter({value: amount, currency: "USD"})}</span>
        </div>
        <div className="details-confirmation-row">
          <span>{context.t("Commission")}:</span>
          <span className="value">{currencyFormatter({value: commission, currency: "USD"})}</span>
        </div>
        <div className="details-confirmation-row">
          <span>{context.t("Amount in USD to receive in my Banexcard")}:</span>
          <span className="value">{currencyFormatter({value: total, currency: "USD"})}</span>
        </div>
      </div>
    </CardGradient>
  );
}
DetailsConfirmation.contextTypes = {
  t: PropTypes.func.isRequired,
}
export default DetailsConfirmation;
