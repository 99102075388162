import React from 'react';
import PropTypes from 'prop-types';
import addDays from 'date-fns/add_days';
import subDays from 'date-fns/sub_days';
import APSelect from 'apex-web/lib/components/common/APSelect'
import APButton from 'apex-web/lib/components/common/APButton';
import APDatePicker from 'apex-web/lib/components/common/APDatePicker/APDatePicker';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { required } from 'apex-web/lib/helpers/formValidations';
import ReportAccountsComponent from 'apex-web/lib/components/ReportSidePaneComponents/ReportAccountsComponent';
import 'apex-web/lib/components/ReportSidePaneComponents/ReportFormComponent.css';
import isEmpty from 'is-empty';

const baseClasses = getBEMClasses('report-form');

let CreateReportComponent = function CreateReportComponent(props, context) {
  const accounts = props.accounts,
      userinfo = props.userInfo,
      startDate = props.startDate,
      buttonDisabled = props.buttonDisabled;
  const dateTimeCreated = !isEmpty(userinfo) ? userinfo.DateTimeCreated.split("T")[0] : ""
  return React.createElement(
    'form',
    { onSubmit: props.handleSubmit, className: baseClasses('body') },
    React.createElement(
      'div',
      { className: baseClasses('form-body') },
      React.createElement(ReportAccountsComponent, { accounts: accounts }),
      React.createElement(APSelect, {
        name: 'type',
        label: context.t('Report type:'),
        customClass: baseClasses(),
        validate: [required],
        options: [{ value: 'TradeActivity', label: context.t('Trade activity') }, { value: 'Transaction', label: context.t('Transaction') }],
        showTriangles: true
      }),
      React.createElement(
        'div',
        { className: baseClasses('datapicker-container') },
        React.createElement(APDatePicker, {
          name: 'startDate',
          label: context.t('Start date:'),
          customClass: baseClasses(),
          validate: [required],
          maxDate: subDays(new Date(), 1),
          minDate:addDays(dateTimeCreated,0),
          initialOptions: { theme: 'retail' },
        }),
        React.createElement(APDatePicker, {
          name: 'endDate',
          label: context.t('End date:'),
          customClass: baseClasses(),
          minDate: addDays(startDate, 1),
          maxDate: new Date(),
          validate: [required],
          initialOptions: { theme: 'retail' }
        })
      )
    ),
    React.createElement(
      'div',
      { className: baseClasses('footer') },
      React.createElement(
        APButton,
        {
          type: 'submit',
          customClass: baseClasses(),
          styleName: 'additive',
          disabled: buttonDisabled },
        context.t('Create')
      )
    )
  );
};

CreateReportComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

CreateReportComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CreateReportComponent;