import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import XIcon from '../../images/bnx-icons/x-icon.svg'
//import apex from 'apex-web/lib/apex';

const mapStateToProps = ({
  user: {
    selectedAccountId,
    userInfo: { Use2FA, email }
  }
}) => {
  return { selectedAccountId, Use2FA, email };
};

var Check2FAComponent = function Check2FAComponent(props, context) {
  const [show, setShow] = useState(true);
  return props.fetching ? (
    React.createElement(Spinner, null)
  ) : props.selectedAccountId && !props.Use2FA ? (
    show ? (
      <React.Fragment>
        <div className="alert-top-bar twofabanex">
          <img src={XIcon} alt="X" onClick={()=>setShow(false)} />
          <b>{context.t('Important!')}</b>
          <p>
            {context.t(
              'You must enable 2FA (Two Factor Autentication) to increase security to your account and access to incredible features!'
            )}
            <NavLink to="/settings/user" className='button_V2'>
              {context.t('Activate it here')}
            </NavLink>
          </p>
        </div>
      </React.Fragment>
    ) : (
      ''
    )
  ) : (
    ''
  );
};

Check2FAComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(Check2FAComponent);
