import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import Spinner from 'react-bootstrap/Spinner';
import RecentActivityTableComponent from './RecentActivityTableComponent';
import config from '../../../config';
import {
  formatActivity,
  getColumns
} from '../../../helpers/recentActivityHelper';
import { getFilteredActivity } from '../../../redux/selectors/recentActivitySelector';
import HandlerBackend from '../../../helpers/HandlerBackend';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  let params = {
    userId: state.user.userInfo.UserId,
    selectedAccountId: state.user.selectedAccountId
  };

  var usePagination = config.OrderHistoryData.usePagination;

  var columns = getColumns(
    ownProps.width,
    ownProps.themeModifier,
    ownProps.context
  );

  return {
    usePagination: usePagination,
    columns: columns,
    params: params,
    ownProps: ownProps,
    state: state
  };
};

class RecentActivityTableComponentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searching: false,
      txResults: []
    };
  }

  async getAllTxIds(userId, accountId) {
    let result = [];
    try {
      let path = '/api/txid';
      let params = {
        accountId: `${accountId}`
      };
      let configRequest = {
        userId,
        method: 'post',
        path,
        data: params
      };
      let response = await HandlerBackend.sendRequest(configRequest);
      result = !empty(response.data) ? response.data : [];
      this.setState({ txResults: result });
      this.setState({ loading: false });
    } catch (error) {
      throw error;
    }
    return result;
  }

  componentWillReceiveProps() {
    if (
      !empty(this.props.params.userId) &&
      !empty(this.props.params.selectedAccountId) &&
      !this.state.searching
    ) {
      this.getAllTxIds(
        this.props.params.userId,
        this.props.params.selectedAccountId
      );
      this.setState({ searching: true });
    }
  }

  render() {
    let { ownProps, state } = this.props;
    let { txResults, loading } = this.state;

    var filteredActivity = getFilteredActivity(
      state,
      ownProps.filterForSelected
      );
    var content = formatActivity(filteredActivity, ownProps.context, txResults);
    return (
      <div className={'container-lock ' + (!loading ? 'ready' : '')}>
        <div className="overlay-lock">
          <Spinner animation="grow" />
        </div>
        <RecentActivityTableComponent {...this.props} content={content} />
      </div>
    );
  }
}

RecentActivityTableComponentBox.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(RecentActivityTableComponentBox);
