import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Button, Form } from 'react-bootstrap';
import empty from 'is-empty';
import { useHistory } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import HeaderBanexcoin, {
  Trans,
  TextFieldBnx,
  authSuccess,
  authError,
  require2FA
} from './CoinkUtils';
import faAt from './../../images/icon-at.svg';
import faLockCurved from './../../images/lock_icon.png';
import { useForm } from '../../hooks/formHooks';
import { callAPI } from './../utils';
import BanexcoinCoink_2fa from './../../images/BanexcoinCoink_2fa.png';

const CoinkLogin = (props, context) => {
  let { webAuthenticateUser, authenticate2FA, match } = props;

  let history = useHistory();
  const [numberStep, setNumberStep] = useState(1);
  const [validating, setValidating] = useState(false);
  const [twofaError, setTwofaError] = useState('');
  const seo = {
    title: 'Login - Banexcoin',
    description: 'Login a Banexcoin',
    url: 'https://www.banexcoin.com/coink/login',
    image:
      'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
  };

  const onChange2FA = async e => {
    e.persist();
    let value = e.target.value;
    let valueLength = value.length;
    if (valueLength === 6) {
      if (!validating) {
        setValidating(true);
        let a2fa = await authenticate2FA({
          Code: value
        });
        if (a2fa.type === 'AUTH_SUCCESS') {
          setTwofaError('');
          let token =
            !empty(match.params) && !empty(match.params.token)
              ? match.params.token
              : '';
          history.push('/coink/dashboard/' + token);
        } else {
          setValidating(false);
          setTwofaError('Invalid Auth Code. Please try again.');
        }
      }
    }
  };

  const onSubmitCallback = async (inputs, form) => {
    let wau = await webAuthenticateUser({
      username: inputs.username,
      password: inputs.password
    });
    if (wau.type === 'AUTH_2FA_REQUIRED') {
      if (wau.payload.is2FARequired) {
        setNumberStep(2);
      } else {
        let token =
          !empty(match.params) && !empty(match.params.token)
            ? match.params.token
            : '';
        history.push('/coink/dashboard/' + token);
      }
    }
  };

  const customValidations = inputs => {
    let errors = {};

    if (empty(inputs.username)) {
      errors.username = context.t('You must enter a username');
    }
    if (empty(inputs.password)) {
      errors.password = context.t('You must enter a password');
    }
    setErrors({
      ...errors
    });
    return errors;
  };

  let initialState = {
    validated: false
  };

  let {
    inputs,
    errors,
    setErrors,
    onInputChange,
    onSubmit,
    onSubmitSimple,
    onInputChangeByName,
    onInputFileChange,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);
  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
              .custom_fc_frame.fc-widget-small, button.btn_chat.btn_animated {
                display: none!important;
              }
              `
        }}
      />
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description }
        ]}>
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <div className="body-step-bnx">
        <HeaderBanexcoin disabledOptions={true} />
        <div className="pb-3 pt-5">
          {numberStep === 1 ? (
            <React.Fragment>
              <Trans
                className={'text-center pt-5 pb-4'}
                text={context.t(
                  "<span class='ttl dark-bnx'>We are</span> <span class='ttl orange-bnx'>Banexcoin</span>"
                )}
              />
              <h3>{context.t('Welcome back!')}</h3>
              <Form
                noValidate
                validated={inputs.validated}
                encType="multipart/form-data"
                onSubmit={onSubmitSimple}
                autoComplete="off"
                className="bnx-form">
                <TextFieldBnx
                  fieldProps={{
                    isInvalid: !empty(errors.username) ? true : false,
                    name: 'username',
                    autoComplete: 'off',
                    onChange: onInputChange
                  }}
                  errorMsj={!empty(errors.username) ? errors.username : ''}
                  label={context.t('Email')}
                  icon={<img className="icon" src={faAt} />}
                />
                <TextFieldBnx
                  fieldProps={{
                    isInvalid: !empty(errors.password) ? true : false,
                    name: 'password',
                    autoComplete: 'off',
                    onChange: onInputChange
                  }}
                  type="password"
                  errorMsj={!empty(errors.password) ? errors.password : ''}
                  label={context.t('Password')}
                  icon={<img className="icon" src={faLockCurved} />}
                />
                <Button type="submit" variant="primary" className="btn-bnx">
                  {context.t('Log In')}
                </Button>
                <a
                  className="simple mt-5 text-center"
                  target="_blank"
                  href="https://soporte.banexcoin.com">
                  {context.t('Do you need support?')}
                </a>
              </Form>
            </React.Fragment>
          ) : (
            <div className="step-bnx pb-5">
              <img src={BanexcoinCoink_2fa} className="poster_cover" />
              <div className="pb-5 pt-3 parr-spc">
                <Trans
                  text={context.t(
                    "<span class='ttl dark-bnx'>Two Factor</span> <span class='ttl orange-bnx'>Authentication</span>"
                  )}
                />
              </div>
              <h3>{context.t('Enter your 2FA application token')}</h3>
              <VerificationInput
                removeDefaultStyles
                autoFocus={true}
                length={6}
                validChars="/^\d+$/"
                container={{
                  className: 'pt-5 pb-4'
                }}
                characters={{
                  className: 'characters'
                }}
                character={{
                  className: 'character',
                  classNameInactive: 'character--inactive',
                  classNameSelected: 'character--selected'
                }}
                inputField={{
                  onChange: onChange2FA
                }}
              />
              {!empty(twofaError) ? (
                <div className="text-center error-bnx">
                  {context.t(twofaError)}
                </div>
              ) : (
                ''
              )}
              <div className="pb-5 mb-5" />
              <div className="pb-5 mb-2" />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

CoinkLogin.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    webAuthenticateUser: payload =>
      dispatch(callAPI('WebAuthenticateUser', payload)).then(auth => {
        if (auth.Authenticated && auth.Requires2FA) {
          return dispatch(require2FA(auth));
        } else if (auth.Authenticated && !auth.Requires2FA) {
          return dispatch(authSuccess(auth));
        }
        return dispatch(authError(auth));
      }),
    authenticate2FA: payload =>
      dispatch(callAPI('Authenticate2FA', payload)).then(item => {
        if (!item.Authenticated) {
          return dispatch(authError(item));
        }
        return dispatch(authSuccess(item));
      })
  };
};

const mapStateToProps = ({
  user: { selectedAccountId, userInfo },
  user,
  position: { positions, fetching }
}) => {
  return { selectedAccountId, userInfo, user, positions, fetching };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoinkLogin);
