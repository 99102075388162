const countriesReducer = (state = [], action) => {
    switch (action.type) {
        case 'CREATE_COUNTRIES':
            return [ ...action.payload ];
        case 'UPDATE_COUNTRIES':
            return [ ...state, ...action.payload ];
        case 'RESET_COUNTRIES':
            return [];
        default:
            return state;
    }
};
export default countriesReducer;