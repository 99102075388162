import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { useForm } from '../../hooks/formHooks';
import {
  FormField,
  FormSelector
} from '../../components/common/BnxFormsComponents';

const CryptoDJForm = (props, context) => {
  let {
    show,
    handleClose,
    currentTicket,
    submitDepositAffidavit,
    UserId,
    setBlockedReqCode
  } = props;
  const [loading, setLoading] = useState(false);

  const defaultOptionsFunds = [
    {
      value: context.t('Personal savings'),
      label: context.t('Personal savings')
    },
    {
      value: context.t('Family savings'),
      label: context.t('Family savings')
    },
    {
      value: context.t('Rental of movable property'),
      label: context.t('Rental of movable property')
    },
    {
      value: context.t('Real estate rental'),
      label: context.t('Real estate rental')
    },
    {
      value: context.t('Awards'),
      label: context.t('Awards')
    },
    {
      value: context.t('Donations'),
      label: context.t('Donations')
    },
    {
      value: context.t('Income from self-employment'),
      label: context.t('Income from self-employment')
    },
    {
      value: context.t('Income from labour work'),
      label: context.t('Income from labour work')
    },
    {
      value: context.t('Income rights / patents / intellectual property'),
      label: context.t('Income rights / patents / intellectual property')
    },
    {
      value: context.t('Bank loans'),
      label: context.t('Bank loans')
    },
    {
      value: context.t('Non-bank loans'),
      label: context.t('Non-bank loans')
    },
    {
      value: context.t('Loans from natural persons'),
      label: context.t('Loans from natural persons')
    },
    {
      value: context.t('Loans from legal entities'),
      label: context.t('Loans from legal entities')
    },
    {
      value: context.t('Sale of movable property'),
      label: context.t('Sale of movable property')
    },
    {
      value: context.t('Sale of real estate'),
      label: context.t('Sale of real estate')
    },
    {
      value: context.t('Income coming from stocks/shares'),
      label: context.t('Income coming from stocks/shares')
    },
    {
      value: context.t('Business profit income'),
      label: context.t('Business profit income')
    },
    {
      value: context.t('Virtual asset rental income'),
      label: context.t('Virtual asset rental income')
    },
    {
      value: context.t('Retirement income income'),
      label: context.t('Retirement income income')
    },
    {
      value: context.t('Remittances from abroad'),
      label: context.t('Remittances from abroad')
    },
    {
      value: context.t('Inheritances / Intestate Succession'),
      label: context.t('Inheritances / Intestate Succession')
    },
    {
      value: context.t('Collective funds'),
      label: context.t('Collective funds')
    },
    {
      value: context.t('Buy/Sell Cryptocurrencies'),
      label: context.t('Buy/Sell Cryptocurrencies')
    },
    {
      value: context.t('Buy/Sell US Dollars'),
      label: context.t('Buy/Sell US Dollars')
    },
    {
      value: context.t('Intermediation Service (Trading)'),
      label: context.t('Intermediation Service (Trading)')
    },
    {
      value: context.t('Remittances abroad'),
      label: context.t('Remittances abroad')
    },
    {
      value: context.t('Income from billable services'),
      label: context.t('Income from billable services')
    },
    {
      value: context.t('Income from labor conciliations'),
      label: context.t('Income from labor conciliations')
    },
    {
      value: context.t('Indemnification for legal issues'),
      label: context.t('Indemnification for legal issues')
    },
    {
      value: context.t('Settlement/Labor Benefits'),
      label: context.t('Settlement/Labor Benefits')
    }
  ];

  const onSubmitCallback = async inputs => {
    setLoading(true);
    try {
      if (empty(errors)) {
        let inputData = {
          ...inputs,
          id: currentTicket.id
        };
        let result = await submitDepositAffidavit(inputData, UserId);
        if (
          !empty(result) &&
          result.hasOwnProperty('data') &&
          !empty(result.data) &&
          !empty(result.data.data) &&
          result.data.data.result
        ) {
          handleClose();
          setBlockedReqCode(currentTicket.id);
          toast.success(context.t('Information saved successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
    } catch (error) {
      console.log('Error on affidavit SubmitCallback', error);
    }
    setLoading(false);
  };

  const customValidations = inputs => {
    let errors = {};
    if (empty(inputs.affidavit_source_funds)) {
      errors.affidavit_source_funds = context.t('Required field');
    } else {
      if (
        inputs.affidavit_source_funds == 'Others (Detail)' ||
        inputs.affidavit_source_funds == 'Otros (Detallar)'
      ) {
        if (empty(inputs.affidavit_source_funds_others)) {
          errors.affidavit_source_funds_others = context.t(
            'You must enter the details'
          );
        } else {
          if (empty(inputs.affidavit_source_funds_others.trim())) {
            errors.affidavit_source_funds_others = context.t(
              'You must enter the details'
            );
          }
        }
      }
    }
    setErrors({
      ...errors
    });
    return errors;
  };

  let initialState = {
    validated: false,
    affidavit_source_funds: '',
    affidavit_source_funds_others: ''
  };

  let {
    inputs,
    errors,
    setErrors,
    onInputChange,
    onSubmitSimple,
    onInputChangeByName,
    onInputFileChange,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);
  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} className="modal-bnx-v2 lighting">
        <div className={'bnx-v2-loading' + (loading ? ' show' : '')}>
          <Spinner animation="border" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>{context.t('Register Affidavit')}</Modal.Title>
        </Modal.Header>
        <Form
          noValidate
          validated={inputs.validated}
          encType="multipart/form-data"
          onSubmit={onSubmitSimple}
          autoComplete="off">
          <Modal.Body>
            <Row>
              <Col xl="6">
                <FormField
                  type="text"
                  label="Currency"
                  value={currentTicket.currencyName}
                  disabled={true}
                />
              </Col>
              <Col xl="6">
                <FormField
                  type="text"
                  label="Amount"
                  value={currentTicket.amount}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <FormSelector
                  required
                  name="affidavit_source_funds"
                  label="Affidavit/Source of Funds"
                  options={defaultOptionsFunds}
                  onChange={e => {
                    e.persist();
                    setInputs(inputs => ({
                      ...inputs,
                      affidavit_source_funds: e.target.value,
                      affidavit_source_funds_others: ''
                    }));
                  }}
                  errors={errors}
                />
              </Col>
            </Row>
            {inputs.affidavit_source_funds == 'Others (Detail)' ||
            inputs.affidavit_source_funds == 'Otros (Detallar)' ? (
              <Row className="mt-0">
                <Col xl="12">
                  <FormField
                    required
                    type="text"
                    name="affidavit_source_funds_others"
                    placeholder={context.t('Others (Detail)')}
                    onChange={onInputChange}
                    errors={errors}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {context.t('Close')}
            </Button>
            <Button type="submit" variant="primary">
              {context.t('Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

CryptoDJForm.contextTypes = {
  t: PropTypes.func
};

export default CryptoDJForm;
