import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';

const getNameBySymbol = symbol => {
  let symbols = [
    { symbol: 'BTC', name: 'bitcoin' },
    { symbol: 'USD', name: 'Dolar' },
    { symbol: 'PEN', name: 'Peruvian Sol' },
    { symbol: 'USDC', name: 'USD Coin' }
  ];
  return symbols.find(p => p.symbol == symbol);
};

const getInstrumentID = pair => {
  let pairs = [
    {
      pair: 'BTCUSD',
      currentId: 1,
      instrumentid: 1,
      sideProduct1: 'BTC',
      sidePrice1: 'BestOffer',
      sideProduct2: 'USD',
      sidePrice2: 'BestBid',
      type: 'normal'
    },
    {
      pair: 'USDBTC',
      currentId: 2,
      instrumentid: 1,
      sideProduct1: 'USD',
      sidePrice1: 'BestBid',
      sideProduct2: 'BTC',
      sidePrice2: 'BestOffer',
      type: 'inverted'
    },
    {
      pair: 'BTCPEN',
      currentId: 3,
      instrumentid: 5,
      sideProduct1: 'BTC',
      sidePrice1: 'BestOffer',
      sideProduct2: 'PEN',
      sidePrice2: 'BestBid',
      type: 'normal'
    },
    {
      pair: 'PENBTC',
      currentId: 4,
      instrumentid: 5,
      sideProduct1: 'PEN',
      sidePrice1: 'BestBid',
      sideProduct2: 'BTC',
      sidePrice2: 'BestOffer',
      type: 'inverted'
    },
    {
      pair: 'BTCUSDC',
      currentId: 5,
      instrumentid: 11,
      sideProduct1: 'BTC',
      sidePrice1: 'BestOffer',
      sideProduct2: 'USDC',
      sidePrice2: 'BestBid',
      type: 'normal'
    },
    {
      pair: 'USDCBTC',
      currentId: 6,
      instrumentid: 11,
      sideProduct1: 'USDC',
      sidePrice1: 'BestBid',
      sideProduct2: 'BTC',
      sidePrice2: 'BestOffer',
      type: 'inverted'
    }
  ];
  return pairs.find(p => p.pair == pair);
};

const InputChange = (props, context) => {
  let {
    data,
    instrument,
    currentPair,
    setCurrentPair,
    side1,
    setSide1,
    side2,
    setSide2,
    side1Pair,
    setSide1Pair,
    side2Pair,
    setSide2Pair
  } = props.obj;

  if (empty(data)) {
    return <React.Fragment />;
  }

  let BestBid = formatNumberToLocale(
    data.BestBid,
    convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
  ).replace(/,| /g, '');

  let BestOffer = formatNumberToLocale(
    data.BestOffer,
    convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
  ).replace(/,| /g, '');

  let calculated = formatNumberToLocale(
    // se cambia BestOffer a BestBid y BestBid a BestOffer
    currentPair.type === 'normal'
      ? data.BestBid * side1
      : side1 / data.BestOffer,
    convertIncrementToIntDecimalPlaces(
      currentPair.type === 'normal'
        ? instrument.PriceIncrement
        : instrument.QuantityIncrement
    )
  ).replace(/,| /g, '');

  const calcPrice = val => {
    // se cambia BestOffer a BestBid
    setSide1(val);
    setSide2(data.BestBid * val);
  };

  return (
    <React.Fragment>
      <div className="btn-header">
        {/* <button
          className={(currentPair.currentId===3 ? 'here': '')}
          onClick={(e) => {
            let g = getInstrumentID('BTCPEN')
            setCurrentPair(g);
            calcPrice(side1)
          }}
        >
          BTC->PEN
        </button>
        <button
          className={(currentPair.currentId===1 ? 'here': '')}
          onClick={(e) => {
            let g = getInstrumentID('BTCUSD')
            setCurrentPair(g);
            calcPrice(side1)
          }}
        >
          BTC->USD
        </button> */}
      </div>
      <div className="bodyfull">
        <div className="form-cont">
          <div className="form-bd">
            <label htmlFor="yousend">{context.t('You send')}</label>
            <input
              type="number"
              step="0.01"
              id="yousend"
              name="yousend"
              defaultValue={side1}
              onChange={e => {
                calcPrice(e.target.value);
              }}
              className="inpt"
            />
            <span htmlFor="yousend" className="tlt-right">
              {/* {context.t(getNameBySymbol(currentPair.sideProduct1).name)} */}
              <Form.Control
                as="select"
                name="side2"
                value={currentPair.sideProduct1}
                onChange={e => {
                  let { value } = e.target;
                  let g = getInstrumentID(
                    value + '' + currentPair.sideProduct2
                  );
                  setCurrentPair(g);
                }}>
                {currentPair.sideProduct2 == 'BTC' ? (
                  ''
                ) : (
                  <option value="BTC">bitcoin</option>
                )}
                {currentPair.sideProduct2 == 'PEN' ||
                currentPair.sideProduct2 == 'USD' ||
                currentPair.sideProduct2 == 'USDC' ? (
                  ''
                ) : (
                  <option value="PEN">{context.t('Peruvian Sol')}</option>
                )}
                {currentPair.sideProduct2 == 'USD' ||
                currentPair.sideProduct2 == 'PEN' ||
                currentPair.sideProduct2 == 'USDC' ? (
                  ''
                ) : (
                  <option value="USD">{context.t('US Dollar')}</option>
                )}
                {currentPair.sideProduct2 == 'USD' ||
                currentPair.sideProduct2 == 'PEN' ||
                currentPair.sideProduct2 == 'USDC' ? (
                  ''
                ) : (
                  <option value="USDC">{context.t('USD Coin')}</option>
                )}
              </Form.Control>
            </span>
          </div>
          <div className="form-bd">
            <label htmlFor="youreceive">{context.t('You receive')}</label>
            <input
              type="number"
              step="0.01"
              id="youreceive"
              name="youreceive"
              value={calculated}
              // defaultValue={BestOffer}
              onChange={() => {}}
              className="inpt"
            />
            <span htmlFor="youreceive" className="tlt-right">
              {/* {context.t(getNameBySymbol(currentPair.sideProduct2).name)} */}
              <Form.Control
                as="select"
                className={`example ${currentPair.sideProduct2}`}
                value={currentPair.sideProduct2}
                //defaultValue={currentPair.sideProduct2}
                onChange={e => {
                  let { value } = e.target;
                  let g = getInstrumentID(
                    currentPair.sideProduct1 + '' + value
                  );
                  setCurrentPair(g);
                }}>
                {currentPair.sideProduct1 == 'BTC' ? (
                  ''
                ) : (
                  <option value="BTC">bitcoin</option>
                )}
                {currentPair.sideProduct1 == 'PEN' ||
                currentPair.sideProduct1 == 'USD' ||
                currentPair.sideProduct1 == 'USDC' ? (
                  ''
                ) : (
                  <option value="PEN">{context.t('Peruvian Sol')}</option>
                )}
                {currentPair.sideProduct1 == 'USD' ||
                currentPair.sideProduct1 == 'PEN' ||
                currentPair.sideProduct1 == 'USDC' ? (
                  ''
                ) : (
                  <option value="USD">{context.t('US Dollar')}</option>
                )}
                {currentPair.sideProduct1 == 'USD' ||
                currentPair.sideProduct1 == 'PEN' ||
                currentPair.sideProduct1 == 'USDC' ? (
                  ''
                ) : (
                  <option value="USDC">{context.t('USD Coin')}</option>
                )}
              </Form.Control>
            </span>
          </div>
        </div>
        <div className="revrs-cont">
          <div className="revrs-arr">
            <button
              className="btn-rvrs"
              onClick={e => {
                let g = getInstrumentID(
                  currentPair.sideProduct2 + '' + currentPair.sideProduct1
                );
                setCurrentPair(g);
              }}>
              <FontAwesomeIcon icon={faExchangeAlt} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const LandingCalculator = (props, context) => {
  let { instruments, level1 } = props;

  const [side1, setSide1] = useState(1);
  const [side1Pair, setSide1Pair] = useState('BTC');
  const [side2, setSide2] = useState();
  const [side2Pair, setSide2Pair] = useState('PEN');
  const [currentPair, setCurrentPair] = useState(getInstrumentID('BTCPEN'));

  let newLevel1 = [];
  for (var i in level1) {
    newLevel1.push(level1[i]);
  }
  let pricesBTCUSD = newLevel1.filter(lvl => lvl.InstrumentId === 1);

  let pricesBTCPEN = newLevel1.filter(lvl => lvl.InstrumentId === 5);
  let dataIntrumentBTCPEN = instruments.filter(
    instrument => instrument.InstrumentId === 5
  )[0];

  let currentPrices = newLevel1.filter(
    lvl => lvl.InstrumentId === currentPair.instrumentid
  );
  let currentInstrument = instruments.filter(
    instrument => instrument.InstrumentId === currentPair.instrumentid
  )[0];

  const dateToFormat = new Date().toISOString();
  let language = localStorage.getItem('language');
  moment.locale(language);

  if (!empty(currentPrices)) {
    return (
      <React.Fragment>
        <div className="box-calculator">
          <div className="header">
            <div className="rw-bd">
              <div className="rw-s12">
                <b>{context.t('Bitcoin price instantly')}</b>
              </div>
            </div>
            <div className="rw-bd">
              <div className="rw-s12" style={{ fontSize: '1.2rem' }}>
                {moment().format('MMMM Do YYYY, h:mm:ss a')}
              </div>
            </div>
            <div className="rw-bd">
              <div className="rw-s6">
                <b>
                  {context.t('US Dollar')} ({context.t('BTCUSD')})
                </b>
              </div>
              <div className="rw-s6">
                <b>
                  {context.t('Peruvian Sol')} ({context.t('BTCPEN')})
                </b>
              </div>
            </div>
            <div className="rw-bd">
              <div className="rw-s6 pd3">
                <div className="rw2-bd">
                  <div className="rw2-s6 text-left">
                    <b>{context.t('You Sell')}:</b>
                  </div>
                  <div className="rw2-s6 text-center prcolor">
                    ${' '}
                    {formatNumberToLocale(
                      // Se cambia BestOffer a BestBid
                      !empty(pricesBTCUSD) ? pricesBTCUSD[0].BestBid : 0.0,
                      convertIncrementToIntDecimalPlaces(
                        dataIntrumentBTCPEN.PriceIncrement
                      )
                    )}
                  </div>
                </div>
                <div className="rw2-bd">
                  <div className="rw2-s6 text-left">
                    <b>{context.t('You Buy')}:</b>
                  </div>
                  <div className="rw2-s6 text-center prcolor">
                    ${' '}
                    {formatNumberToLocale(
                      // Se cambia BestBid a BestOffer
                      !empty(pricesBTCUSD) ? pricesBTCUSD[0].BestOffer : 0.0,
                      convertIncrementToIntDecimalPlaces(
                        dataIntrumentBTCPEN.PriceIncrement
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="rw-s6 pd3">
                <div className="rw2-bd">
                  <div className="rw2-s6 text-left">
                    <b>{context.t('You Sell')}:</b>
                  </div>
                  <div className="rw2-s6 text-center prcolor">
                    S/.{' '}
                    {formatNumberToLocale(
                      // Se cambia BestOffer a BestBid
                      !empty(pricesBTCPEN) ? pricesBTCPEN[0].BestBid : 0.0,
                      convertIncrementToIntDecimalPlaces(
                        dataIntrumentBTCPEN.PriceIncrement
                      )
                    )}
                  </div>
                </div>
                <div className="rw2-bd">
                  <div className="rw2-s6 text-left">
                    <b>{context.t('You Buy')}:</b>
                  </div>
                  <div className="rw2-s6 text-center prcolor">
                    S/.{' '}
                    {formatNumberToLocale(
                      // Se cambia BestBid a BestOffer
                      !empty(pricesBTCPEN) ? pricesBTCPEN[0].BestOffer : 0.0,
                      convertIncrementToIntDecimalPlaces(
                        dataIntrumentBTCPEN.PriceIncrement
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            <InputChange
              obj={{
                instrument: currentInstrument,
                data: currentPrices[0],
                currentPair: currentPair,
                setCurrentPair: setCurrentPair,
                side1: side1,
                setSide1: setSide1,
                side2: side2,
                setSide2: setSide2,
                side1Pair: side1Pair,
                setSide1Pair: setSide1Pair,
                side2Pair: side2Pair,
                setSide2Pair: setSide2Pair
              }}
              {...context}
            />
            <div className="footercalc">
              <Link to="/login" className="my-signup lang">
                {context.t('Start Operation')}
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
};

LandingCalculator.contextTypes = {
  t: PropTypes.func.isRequired
};

InputChange.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  }
}) => {
  return { instruments, level1 };
};

export default connect(mapStateToProps)(LandingCalculator);
