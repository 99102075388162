import { connect } from 'react-redux';
import ConfirmOrderModalComponent from './ConfirmOrderModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import { placeOrderWithChecks } from 'apex-web/lib/redux/actions/orderEntryActions';
import { getOrderPrice } from 'apex-web/lib/helpers/orderHelper';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { marketPriceOfSelectedInstrumentSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

const mapStateToProps = function mapStateToProps(state) {
    const form = state.form,
        product = state.apexCore.product;

    const formObj = form.orderEntry || { values: { fee: {} } };

    const productId = formObj.values.fee.ProductId || formObj.values.fee.FeeProductId;
    const feeSymbol = productId ? product.products.find(function (item) {
        return item.ProductId === productId;
    }).Product : '';
    const marketPrice = marketPriceOfSelectedInstrumentSelector(state, {
        side: formObj.values.side
    });

    const price = getOrderPrice(formObj, marketPrice);
    const selectedInstrument = selectedInstrumentSelector(state);
    const orderTotal = orderTotalSelector(state, { form: formObj.values });

    return {
        formObj: formObj,
        feeSymbol: feeSymbol,
        price: price,
        selectedInstrument: selectedInstrument,
        isMarginActive: isMarginActiveSelector(state),
        orderTotal: orderTotal
    };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        confirmReport: function confirmReport(payload) {
            return dispatch(placeOrderWithChecks(orderFormTypes.default, payload)).then(function (res) {
                if (res.result === false) {
                    const text = res.errormsg && res.detail ? res.errormsg + ': ' + res.detail : res.errormsg || res.detail;
                    dispatch(showSnack({
                        id: 'placeOrderError',
                        text: text,
                        type: 'warning'
                    }));
                }
            });
        }
    };
};

const container = connect(mapStateToProps, mapDispatchToProps)(ConfirmOrderModalComponent);

export default modalDecorator({
    name: MODAL_TYPES.CONFIRM_ORDER_REPORT
})(container);