import React from 'react';
import PropTypes from 'prop-types';
import KYCIdentityMindFormStepContainer from './KYC_IMFormStepContainer';
import KYCConfirmLevelModal from '../../KYC/KYCConfirmLevelModal';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
// import pick from 'lodash/pick';
// import omit from 'lodash/omit';
import HandlerBackend from '../../../helpers/HandlerBackend';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var KYC_IMFormComponent = (function(_React$Component) {
  _inherits(KYC_IMFormComponent, _React$Component);

  function KYC_IMFormComponent(props) {
    _classCallCheck(this, KYC_IMFormComponent);

    var _this = _possibleConstructorReturn(
      this,
      (
        KYC_IMFormComponent.__proto__ ||
        Object.getPrototypeOf(KYC_IMFormComponent)
      ).call(this, props)
    );

    _this.onSubmit = function() {
      var level = _this.props.level;
      var page = _this.state.page;

      if (level.steps && level.steps[page + 1]) {
        _this.setState({ page: _this.state.page + 1 });
      } else {
        _this.openModal(true);
      }
    };

    _this.previousPage = function() {
      _this.setState({ page: _this.state.page - 1 });
    };

    _this.openModal = function(openModal) {
      return _this.setState({ openModal: openModal });
    };

    _this.getConfigPayload = function(values) {
      var _this$props = _this.props,
        UserName = _this$props.UserName,
        UserId = _this$props.UserId;

      var configPropsMap = {
        DocumentType: 'docType',
        DocumentState: 'docState',
        DocumentCountry: 'docCountry',
        phone: 'telephone',
        state: 'billingState'
      };

      var Config = Object.keys(values).map(function(prop) {
        return {
          Key: configPropsMap[prop] || prop,
          Value: values[prop]
        };
      });
      // temporarily setting levelIncreaseStatus to under review, no matter what (taken from widgets)
      Config.push({ Key: 'levelIncreaseStatus', Value: 'underReview' });

      return {
        UserName: UserName,
        UserId: UserId,
        Config: Config
      };
    };

    _this.getUserInfo = function(values) {
      var _this$props2 = _this.props,
        accountId = _this$props2.accountId,
        UserId = _this$props2.UserId,
        useAccountId = _this$props2.useAccountId,
        email = _this$props2.email;

      values = _this.condenseMemos(values);
      return Object.assign({}, values, {
        accountName: (useAccountId ? accountId : UserId).toString(),
        email: email,
        billingFlatNumber: '',
        salutation: ''
      });
    };

    _this.condenseMemos = function(values) {
      var memos = {};
      var memosAmount = 0;
      Object.keys(values).map(function(key) {
        memosAmount +=
          key.match(/memo/) && ((memos[key] = values[key]), delete values[key]);
        return values;
      });
      memosAmount > 0 && (values['memo'] = memos);
      return values;
    };

    _this.getAdditionalValidation = function(imageFields) {
      return Object.keys(imageFields)
        .filter(function(prop) {
          return imageFields[prop];
        })
        .map(function(ImageName) {
          return {
            ImageInString: imageFields[ImageName].imgSrc,
            ImageName: ImageName
          };
        });
    };

    _this.submitData = function() {
      var _this$props3 = _this.props;
      //var formValues = _this$props3.formValues;
      var uploadFields = _this$props3.uploadFields,
        userId = _this$props3.UserId;
      //var MinimumVerificationLevel = _this$props3.MinimumVerificationLevel;
      //reset = _this$props3.reset,
      //validateUserRegistrationIdentityMind =
      //  _this$props3.validateUserRegistrationIdentityMind,
      //onSubmit = _this$props3.onSubmit;

      //var values = omit(formValues, uploadFields);
      //var uploadValues = pick(formValues, uploadFields);

      //var AdditionalValidation = _this.getAdditionalValidation(uploadValues);
      //var userInfo = _this.getUserInfo(Object.assign({}, values));
      //var setUserConfigPayload = _this.getConfigPayload(values);

      /*var clientInfo = {
        alphaPointUserID: userId.toString(),
        validationStage: MinimumVerificationLevel, // validation stage, necessary for identityMind
        validator: 'IM'
      };*/

      // Set params for ValidateUserRegistration request
      /*var validateUserRegistrationPayload = {
        clientInfo: clientInfo,
        userInfo: userInfo,
        AdditionalValidation: AdditionalValidation
      };

      var submitInfoIdentityMindPayload = {
        validateUserRegistrationPayload: validateUserRegistrationPayload,
        setUserConfigPayload: setUserConfigPayload
      };*/

      let model = new FormData();

      model.append('full_name', userId.toString());
      model.append('upload_files[]', uploadFields);
      // validateUserRegistrationIdentityMind(submitInfoIdentityMindPayload);

      let headers = {
        'Content-Type': 'multipart/form-data'
      };
      let path = '/api/basic-level';

      return HandlerBackend.sendRequest({
        userId,
        method: 'post',
        headers,
        path,
        data: model
      });
    };

    _this.getMissingFieldsMessage = function() {
      var baseClasses = getBEMClasses('kyc-form');
      var slidePaneClasses = getBEMClasses('slide-pane two');

      return React.createElement(
        'div',
        { className: slidePaneClasses('body') },
        React.createElement(
          'p',
          { className: baseClasses('title') },
          _this.context.t(
            'Verification form is not properly setup, please contact the exchange in order to solve this.'
          )
        )
      );
    };

    _this.getSectionsForModal = function(level) {
      var sections = [];
      level.steps.forEach(function(step) {
        sections = [].concat(
          _toConsumableArray(sections),
          _toConsumableArray(step.sections)
        );
      });
      return { sections: sections };
    };

    _this.state = {
      page: 0,
      openModal: false,
      images: {}
    };
    return _this;
  }

  _createClass(KYC_IMFormComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        var missingFields = this.props.missingFields;

        if (missingFields.length > 0) {
          console.error(
            'The following fields are missing from the configuration file: ',
            missingFields
              .map(function(f) {
                return '"' + f.name + '"';
              })
              .join(', ')
          );
        }
      }
    },
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var _props = this.props,
          processing = _props.processing,
          formValues = _props.formValues,
          level = _props.level,
          missingFields = _props.missingFields;
        var _state = this.state,
          page = _state.page,
          openModal = _state.openModal,
          images = _state.images;

        if (missingFields.length > 0) {
          return this.getMissingFieldsMessage();
        }

        return React.createElement(
          React.Fragment,
          null,
          React.createElement(KYCIdentityMindFormStepContainer, {
            isFirst: page === 0,
            level: level.steps ? level.steps[page] : level,
            previousPage: this.previousPage,
            onSubmit: this.onSubmit
          }),
          React.createElement(KYCConfirmLevelModal, {
            isOpen: openModal,
            close: function close() {
              return _this2.openModal(false);
            },
            onConfirm: this.submitData,
            processing: processing,
            level: level.steps ? this.getSectionsForModal(level) : level,
            values: Object.assign({}, formValues, images)
          })
        );
      }
    }
  ]);

  return KYC_IMFormComponent;
})(React.Component);

KYC_IMFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default KYC_IMFormComponent;
