import React from 'react';
import PropTypes from 'prop-types';
import KYCPolicySpanish from './KYCPolicy/KYCPolicySpanish';
import KYCPolicyEnglish from './KYCPolicy/KYCPolicyEnglish';
import HeaderB from './HeaderB';
import FooterB from './FooterB';
import './GlobalHooksIntrument';
import '../styles/components/common/StandaloneForm.css';
import './TermsConditionsPage.css';

const lang = localStorage.getItem('language');

export const KYCPolicyComponent = (props, context) => {
  return (
    <React.Fragment>
      <div className="elementor">
        <style>
          {
            '\
          #root .container.logged{\
            width: calc(100%);\
            margin-left: 0rem;\
          }\
          .incongito_joyride{\
            display: none;\
          }\
        '
          }
        </style>
        <HeaderB />
        <div className="terms-conditions">
          {lang === 'es' ? <KYCPolicySpanish /> : <KYCPolicyEnglish />}
        </div>
        <FooterB />
      </div>
    </React.Fragment>
  );
};

KYCPolicyComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default KYCPolicyComponent;
