import React from 'react';
import PropTypes from 'prop-types';

const InstrumentTableItem = props => {
  const { value, label, classModifiers, baseClasses, icon } = props;

  return (
    <div className={baseClasses('row', classModifiers)}>
      <span className={baseClasses('value', classModifiers)}>{value}</span>
      <div style={{width:"max-content"}}>
        {icon && <img src={icon} alt="icon" width={12} height={12} className='icon-title-table'/>}
      <span className={baseClasses('title')}>{label}</span>
      </div>
    </div>
  );
};

InstrumentTableItem.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  baseClasses: PropTypes.func.isRequired,
  classModifiers: PropTypes.arrayOf(PropTypes.string)
};

InstrumentTableItem.defaultProps = {
  classModifiers: []
};

export default InstrumentTableItem;
