import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  getBankCountries,
  getBanksByCountry,
  createAccountUS
} from '../../components/AccountComponents/AccountHooks';
import { Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../../components/Loading';
import { useForm } from '../../hooks/formHooks';
import { cleanCustomError } from '../bnxv2/hooks/signupHooks';
import AddUSFormFields from './Forms/AddUSFormFields';

const accountPageClasses = getBEMClasses('account-page');

const AccountAddUSFormPage = (props, context) => {
  let { userInfo, selectedAccountId, history } = props;
  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }
  const [loading, setLoading] = useState(0);
  const [countries, setCountries] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [usBanks, setUsBanks] = useState([]);
  const [intermediaryBanks, setIntermediaryBanks] = useState([]);
  const [bankSquare, setBankSquare] = useState({});
  const [generalErrors, setGeneralErrors] = useState({});

  const cleanExtractBanks = (banks = []) => {
    let uniqueBanks = [];
    let squareBanks = {};

    uniqueBanks = banks.reduce((unique, o) => {
      if (!unique.some(obj => obj.bankName === o.bankName)) {
        unique.push(o);
      }
      return unique;
    }, []);

    return {
      uniqueBanks,
      squareBanks
    };
  };

  const getListCountries = async () => {
    setLoading(1);
    try {
      let result = await getBankCountries(userInfo.UserId);
      if (!empty(result) && result.hasOwnProperty('data')) {
        setCountries(result.data.countries);
      }
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const getListBanksByCountry = async country_code => {
    setLoading(1);
    try {
      let result = await getBanksByCountry(userInfo.UserId, country_code);
      if (
        !empty(result) &&
        result.hasOwnProperty('data') &&
        !empty(result.data.data)
      ) {
        let { uniqueBanks, squareBanks } = cleanExtractBanks(result.data.data);
        setAllBanks(result.data.data);
        setBankSquare(squareBanks);
        setUsBanks(uniqueBanks);
      }
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const getListIntermediaryBanksByCountry = async (country_code = null) => {
    setLoading(1);
    try {
      if (!empty(country_code)) {
        let result = await getBanksByCountry(userInfo.UserId, country_code);
        if (
          !empty(result) &&
          result.hasOwnProperty('data') &&
          !empty(result.data.data)
        ) {
          let { uniqueBanks } = cleanExtractBanks(result.data.data);
          setIntermediaryBanks(uniqueBanks);
        }
      } else {
        setIntermediaryBanks([]);
      }
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  let initialState = {
    validated: false,
    currencyCode: 'USD'
  };

  const onSubmitCallback = async (inputs, form, newErrors) => {
    const errors = customValidations(inputs);
    if(!empty(errors)) return
    setLoading(1);
    try {
      if (empty(newErrors)) {
        let save = await createAccountUS(
          userInfo.UserId,
          inputs,
          selectedAccountId
        );
        if (
          !empty(save) &&
          save.hasOwnProperty('data') &&
          !empty(save.data) &&
          save.data.message === 'success'
        ) {
          toast.success(context.t('Account saved successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
          history.push('/settings/accounts');
        }
      }
    } catch (error) {
      console.log('Error on submition', error);
      let msg = '';
      if (
        error.hasOwnProperty('response') &&
        !empty(error.response.data.data)
      ) {
        console.error('error data', error.response.data);
        msg = error.response.data.data.error_message;
      } else {
        console.error('error', error);
        msg = error.message;
      }
      let cleanedError = cleanCustomError(msg);
      setGeneralErrors({
        multiple: cleanedError
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    if (empty((inputs.transferType || '').trim())) {
      errors.transferType = 'Required transfer type field';
    } else {
      let transferTypes = [
        'DOMESTIC_TRANSFER_US',
        'DOMESTIC_TRANSFER_US_WITH_BANK'
      ];
      if (!transferTypes.includes(inputs.transferType)) {
        errors.transferType = 'Invalid transfer type value';
      }
      if (inputs.transferType === 'DOMESTIC_TRANSFER_US') {
        if (empty((inputs.beneficiaryState || '').trim())) {
          errors.beneficiaryState = 'Required beneficiary state field';
        } else {
          if (inputs.beneficiaryState.length > 20) {
            errors.beneficiaryState =
              'The beneficiary state cannot be longer than 20 characters';
          }
        }

        if (empty((inputs.beneficiaryReference || '').trim())) {
          errors.beneficiaryReference = 'Required beneficiary reference field';
        } else {
          if (inputs.beneficiaryReference.length > 20) {
            errors.beneficiaryReference =
              'The beneficiary reference cannot be longer than 20 characters';
          }
        }
      } else if (inputs.transferType === 'DOMESTIC_TRANSFER_US_WITH_BANK') {
        if (empty((inputs.swiftBicCode || '').trim())) {
          errors.swiftBicCode = 'Required Swift / BIC Code field';
        } else {
          if (inputs.swiftBicCode.length > 80) {
            errors.swiftBicCode =
              'The Swift / BIC Code cannot be longer than 80 characters';
          }
        }

        if (empty((inputs.intermediaryBankId || '').trim())) {
          errors.intermediaryBankId = 'Required intermediary bank field';
        }

        if (empty((inputs.intermediaryBankAddress || '').trim())) {
          errors.intermediaryBankAddress =
            'Required intermediary bank address field';
        } else {
          if (inputs.intermediaryBankAddress.length > 80) {
            errors.intermediaryBankAddress =
              'The intermediary bank address cannot be longer than 80 characters';
          }
        }

        if (empty((inputs.intermediaryBankCountry || '').trim())) {
          errors.intermediaryBankCountry =
            'Required intermediary bank country field';
        } else {
          if (inputs.intermediaryBankCountry.length > 2) {
            errors.intermediaryBankCountry =
              'The intermediary bank country cannot be longer than 2 characters';
          }
        }

        if (empty((inputs.intermediaryBankCity || '').trim())) {
          errors.intermediaryBankCity = 'Required intermediary bank city field';
        } else {
          if (inputs.intermediaryBankCity.length > 30) {
            errors.intermediaryBankCity =
              'The intermediary bank city cannot be longer than 30 characters';
          }
        }

        if (empty((inputs.intermediaryBankState || '').trim())) {
          errors.intermediaryBankState =
            'Required intermediary bank state field';
        } else {
          if (inputs.intermediaryBankState.length > 30) {
            errors.intermediaryBankState =
              'The intermediary bank state cannot be longer than 30 characters';
          }
        }

        if (empty((inputs.intermediaryBankZip || '').trim())) {
          errors.intermediaryBankZip = 'Required intermediary bank zip field';
        } else {
          if (inputs.intermediaryBankZip.length > 20) {
            errors.intermediaryBankZip =
              'The intermediary bank zip cannot be longer than 20 characters';
          }
        }

        if (empty((inputs.intermediarySwiftBicCode || '').trim())) {
          errors.intermediarySwiftBicCode =
            'Required intermediary swift / BIC code field';
        } else {
          if (inputs.intermediarySwiftBicCode.length > 20) {
            errors.intermediarySwiftBicCode =
              'The intermediary swift / BIC code cannot be longer than 20 characters';
          }
        }

        if (empty((inputs.intermediaryAbaRoutingNumber || '').trim())) {
          errors.intermediaryAbaRoutingNumber =
            'Required intermediary ABA / routing number field';
        } else {
          if (inputs.intermediaryAbaRoutingNumber.length > 20) {
            errors.intermediaryAbaRoutingNumber =
              'The intermediary ABA / routing number cannot be longer than 20 characters';
          }
        }

        if (empty((inputs.intermediaryReference || '').trim())) {
          errors.intermediaryReference =
            'Required intermediary reference field';
        } else {
          if (inputs.intermediaryReference.length > 20) {
            errors.intermediaryReference =
              'The intermediary reference cannot be longer than 20 characters';
          }
        }
      } else {
        errors.transferType =
          'Invalid transfer type, please make sure you are sending the correct informacion.';
      }
    }

    if (empty(inputs.alias)) {
      errors.alias = 'Required alias field';
    } else {
      if (!empty(inputs.alias.trim())) {
        const re = /^[a-z\d\-_\s]+$/i;
        let validate = re.test(inputs.alias);
        if (!validate) {
          errors.alias =
            'Invalid alias. Only texts, numbers and spaces are accepted';
        } else {
          if (inputs.alias.length > 40) {
            errors.alias = "The alias can't be more than 40 characters";
          }
        }
      } else {
        errors.alias = 'Required alias field';
      }
    }

    if (empty(inputs.bankId)) {
      errors.bankId = 'Required bank field';
    }

    if (empty((inputs.accountNumber || '').trim())) {
      errors.accountNumber = 'Required Account number field';
    } else {
      const re = /^[a-z0-9]+$/i;
      let validate = re.test(inputs.accountNumber);
      if (!validate) {
        errors.accountNumber =
          'Invalid account number. Only numbers are allowed';
      } else {
        if (inputs.accountNumber.length > 20) {
          errors.accountNumber =
            'The account number can only be up to 20 characters long';
        }
      }
    }

    if (empty((inputs.bankAddress || '').trim())) {
      errors.bankAddress = 'Required bank address field';
    } else {
      if (inputs.bankAddress.length > 80) {
        errors.bankAddress =
          'The bank address cannot be longer than 80 characters';
      }
    }

    if (empty((inputs.abaRoutingNumber || '').trim())) {
      errors.abaRoutingNumber = 'Required ABA / routing number field';
    } else {
      if (inputs.abaRoutingNumber.length > 20) {
        errors.abaRoutingNumber =
          'The ABA / routing number cannot be longer than 20 characters';
      }
    }

    // Beneficiary global
    if (empty((inputs.fullName || '').trim())) {
      errors.fullName = 'Required name of the final beneficiary field';
    } else {
      if (inputs.fullName.length > 20) {
        errors.fullName =
          'The name of the final beneficiary cannot be longer than 20 characters';
      }
    }

    if (empty((inputs.beneficiaryAddress || '').trim())) {
      errors.beneficiaryAddress = 'Required beneficiary address field';
    } else {
      if (inputs.beneficiaryAddress.length > 80) {
        errors.beneficiaryAddress =
          'The beneficiary address cannot be longer than 80 characters';
      }
    }

    if (empty((inputs.beneficiaryCountry || '').trim())) {
      errors.beneficiaryCountry = 'Required beneficiary country field';
    } else {
      if (inputs.beneficiaryCountry.length > 2) {
        errors.beneficiaryCountry =
          'The beneficiary country cannot be longer than 2 characters';
      }
    }

    if (empty((inputs.beneficiaryCity || '').trim())) {
      errors.beneficiaryCity = 'Required beneficiary city field';
    } else {
      if (inputs.beneficiaryCity.length > 20) {
        errors.beneficiaryCity =
          'The beneficiary city cannot be longer than 20 characters';
      }
    }

    if (empty((inputs.beneficiaryZip || '').trim())) {
      errors.beneficiaryZip = 'Required beneficiary zip field';
    } else {
      if (inputs.beneficiaryZip.length > 10) {
        errors.beneficiaryZip =
          'The beneficiary zip cannot be longer than 10 characters';
      }
    }

    if (
      empty(inputs.ownAccountConfirmation) ||
      (!empty(inputs.ownAccountConfirmation) &&
        inputs.ownAccountConfirmation !== true)
    ) {
      errors.ownAccountConfirmation =
        'You must declare that it is your account';
    }

    setErrors(errors);
    return errors;
  };

  let {
    inputs,
    errors,
    setErrors,
    onCheckboxChange,
    onSubmitSimple,
    onInputChange
  } = useForm(initialState, onSubmitCallback, customValidations);

  useEffect(
    () => {
      if (!empty(userInfo) && !empty(userInfo.UserId)) {
        getListCountries();
        getListBanksByCountry('US');
      }
    },
    [userInfo]
  );

  document.title = `${context.t('My Banking Accounts')} | ${context.t(
    'Add bank account'
  )} - Banexcoin`;

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses() + ' minhfix'}>
        <Form
          noValidate
          className="form-accounting-v2"
          onSubmit={onSubmitSimple}>
          <AddUSFormFields
            inputs={inputs}
            onInputChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            onCheckboxChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onCheckboxChange(ev)
            }}
            onInputChangeCleanValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            generalErrors={generalErrors}
            errors={errors}
            usBanks={usBanks}
            countries={countries}
            getListIntermediaryBanksByCountry={
              getListIntermediaryBanksByCountry
            }
            intermediaryBanks={intermediaryBanks}
          />
        </Form>
      </div>
    </React.Fragment>
  );
};

AccountAddUSFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};

export default connect(mapStateToProps)(AccountAddUSFormPage);
