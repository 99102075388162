import React from 'react';
import PropTypes from 'prop-types';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import BuySellV2Form from './BuySellV2Form';

const BuySellV2Component = ({isNewBuySellPage}, context) => {
    return <BuySellV2Form />;
}

BuySellV2Component.contextTypes = {
    t: PropTypes.func.isRequired
};

export default withAuthentication(BuySellV2Component);