import isEmpty from 'is-empty';
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import SearchIcon from "../../images/icons-v2/search-normal.svg"
import ArrowRightIcon from "../../images/icons-v2/ArrowRight.svg"
import './productList.css'

const ProductList = (props, context) => {
    const { title = '', products = [], openSendReceiveSidePane = () => { }, openRetailFiatSidePane = () => { } } = props;

    const [search, setSearch] = useState('');

    const filteredProducts = useMemo(() => {
        return products.filter(product =>
            product.Product.toLowerCase().includes(search.toLowerCase()) || context.t(product.ProductFullName).toLowerCase().includes(search.toLowerCase())
        );
    }, [products, search]);

    const onClick = (item, isCrypto) => {
        if (title === 'Deposit') {
            return isCrypto ? openSendReceiveSidePane(false, item) : openRetailFiatSidePane(true, item)
        }
        else if (title === 'Withdraw') {
            return isCrypto ? openSendReceiveSidePane(true, item) : openRetailFiatSidePane(false, item)
        }
        return null;
    }

    return (
        <div className='currency_space'>
            <h2>{context.t(title)}</h2>
            <p className='select_title'>{context.t('Select currency')}</p>
            {/* <label>
                <img src={SearchIcon} alt="Search image" />
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={context.t('What currency are you looking for?')} />
            </label> */}
            <div className='list_currency'>
                {!isEmpty(filteredProducts) &&
                    filteredProducts.map((product, key) => {
                        const item = {
                            iconFileName: product.iconFileName,
                            ProductSymbol: product.ProductSymbol,
                            ProductFullName: product.ProductFullName,
                            ProductId: product.ProductId
                        }
                        const productType = product.ProductType === "CryptoCurrency"
                        return (
                            <div className={`currency_item`} onClick={() => onClick(item, productType)} key={key}>
                                <div>
                                    <img
                                        alt="product icon"
                                        height="30px"
                                        src={'/local/product-icons/' + product.iconFileName}
                                    />
                                </div>
                                <div className='name_currency'>
                                    <p>{product.Product}</p>
                                    <p>{context.t(product.ProductFullName)}</p>
                                </div>
                                <div className='right_icon'>
                                    <img src={ArrowRightIcon} alt="Arrow right icon" />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}

ProductList.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ProductList