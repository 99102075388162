import React from 'react';
import { ClipLoader } from 'react-spinners';
import empty from 'is-empty';
import iso from './../images/new-iso.png';

class LoadingBnx extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let showLoading = false;

    if (!empty(this.props.loading)) {
      if (this.props.loading > 0) {
        showLoading = true;
      }
    }

    return (
      <div
        className={
          showLoading ? 'loading-overlay bnx' : 'loading-overlay bnx noshow'
        }>
        <div className="loading-wheel bnx">
          <img src={iso} />
          {!empty(this.props.loadingMessage) && (
            <h3 className="text-loading-center">{this.props.loadingMessage}</h3>
          )}
        </div>
      </div>
    );
  }
}

export default LoadingBnx;
