import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';
import { DatePickerInput } from 'rc-datepicker';

const FormDate = (props, context) => {
  const {
    label,
    required,
    type,
    placeholder,
    name,
    step,
    id,
    onChange,
    maxDate,
    startDate,
    errors,
    helpText,
    value
  } = props;
  try {
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      required: required ? true : false,
      type: !empty(type) ? type : 'text',
      placeholder: !empty(placeholder)
        ? context.t(placeholder)
        : !empty(label)
          ? context.t(label)
          : '',
      name,
      step,
      displayFormat: 'DD/MM/YYYY',
      returnFormat: 'YYYY-MM-DD',
      maxDate,
      onChange: value => {
        let inputvalue = null;
        try {
          if (!moment(value).isValid()) {
            throw new Error('Invalid Date');
          }
          inputvalue = moment(value).format('YYYY-MM-DD');
        } catch (error) {
          console.log('Form date | name:', name, 'Error', error.message);
        }
        onChange(name, inputvalue);
      },
      autoComplete: 'off',
      startDate,
      value
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={'v2-field' + (!empty(errors[name]) ? ' invalid' : ' valid')}>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)} {required ? <b className="important">*</b> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        <DatePickerInput
          className="form-control placeholder-color"
          showOnInputClick
          {...propInput}
        />
        {!empty(helpText) && (
          <Form.Text className="text-muted help-simple">
            {context.t(helpText)}
          </Form.Text>
        )}
        {!empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormDate', error);
    return <React.Fragment />;
  }
};

FormDate.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormDate;
