import React from 'react';
import empty from 'is-empty';
import {
  Action,
  Amount,
  Status,
  DateTime
} from '../components/RecentActivity/ActivityCellComponents';
import {
  recentActivitySelector,
  selectedRecentActivitySelector
} from '../redux/selectors/recentActivitySelector';
import { STATUS_PROCESS } from '../constants';

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export var formatActivity = function formatActivity(
  activity,
  context,
  txResults = []
) {
  return activity.map(function(item) {
    var Type = item.Type;

    switch (Type) {
      case 'deposit':
      case 'withdraw': {
        var _item$Action$params = item.Action.params,
          Product = _item$Action$params.Product,
          ProductType = _item$Action$params.ProductType,
          _Status = _item$Action$params.Status,
          TransactionDetails = _item$Action$params.TransactionDetails;

        let filteredObj = txResults.find(t => t.ticketUuid === item.Status.id);
        const infoDetails = () => {
          if (Type === 'deposit') {
            if (ProductType === 'CryptoCurrency') {
              if (isJson(TransactionDetails)) {
                var json = JSON.parse(TransactionDetails);

                if (json.TXId) {
                  return json.TXId;
                }
              }
            }
          } else {
            if (ProductType === 'CryptoCurrency') {
              if (isJson(TransactionDetails)) {
                var json = JSON.parse(TransactionDetails);

                if (!empty(filteredObj)) {
                  return filteredObj.txId;
                }
                if (json.TxId) {
                  if (
                    json.TxId ===
                    '42759703f392d4c78bbca02d27ca63eb0e149b8b0b5a39fb0afa0bd75353d30f'
                  ) {
                    return json.TxId;
                  } else {
                    return json.TxId;
                  }
                }
              }
            }
          }

          return null;
        };

        let subType = '';
        if (Type === 'deposit' || Type === 'withdraw') {
          let payloadDetails = {};
          try {
            payloadDetails = !empty(item.Action.params.TransactionDetails)
              ? JSON.parse(item.Action.params.TransactionDetails)
              : {};
            if (
              payloadDetails.hasOwnProperty('depositType') &&
              !empty(payloadDetails.depositType)
            ) {
              subType = payloadDetails.depositType;
            }
          } catch (error) {}
        }

        item.Action = Object.assign({}, item.Action, {
          primaryText:
            Type === 'deposit'
              ? context.t('Deposited {a}', { a: Product })
              : context.t('Withdrew {a}', { a: Product }),
          secondaryText:
            ProductType === 'CryptoCurrency'
              ? Type === 'deposit'
                ? subType === 'TDCBUY'
                  ? context.t('By credit/debit card purchase')
                  : context.t('from External Address')
                : context.t('to External Address')
              : Type === 'deposit'
                ? subType === 'TDCBUY'
                  ? context.t('By credit/debit card purchase')
                  : context.t('from Bank')
                : context.t('to Bank'),
          blockChainLink: infoDetails()
        });
        item.Status = Object.assign({}, item.Status, {
          status: context.t('{a}', { a: _Status })
        });
        return item;
      }
      case 'send':
      case 'receive': {
        var _item$Action$params2 = item.Action.params,
          _Product = _item$Action$params2.Product,
          ReceiverUserName = _item$Action$params2.ReceiverUserName,
          SenderUserName = _item$Action$params2.SenderUserName;

        const infoDetails = () => {
          if (Type === 'deposit') {
            if (ProductType === 'CryptoCurrency') {
              if (isJson(TransactionDetails)) {
                var json = JSON.parse(TransactionDetails);
                //json.TXId = "e54274f7ba6172db67df276cfbb665149b51dbbfc923a9d8c5459a8aa0cfb983";
                if (json.TXId) {
                  return json.TXId;
                }
              }
            }
          } else {
            if (ProductType === 'CryptoCurrency') {
              if (isJson(TransactionDetails)) {
                var json = JSON.parse(TransactionDetails);
                //json.TxId = "e54274f7ba6172db67df276cfbb665149b51dbbfc923a9d8c5459a8aa0cfb983";
                if (json.TxId) {
                  return json.TxId;
                }
              }
            }
          }

          return null;
        };

        item.Action = Object.assign({}, item.Action, {
          primaryText:
            Type === 'send'
              ? context.t('Sent {a}', { a: _Product })
              : context.t('Received {a}', { a: _Product }),
          secondaryText:
            Type === 'send'
              ? context.t('to ' + ReceiverUserName)
              : context.t('to ' + SenderUserName),
          blockChainLink: infoDetails()
        });
        item.Status = Object.assign({}, item.Status, {
          status: context.t('Complete')
        });
        return item;
      }
      case 'trades': {
        var _item$Action$params3 = item.Action.params,
          Product1Symbol = _item$Action$params3.Product1Symbol,
          Product2Symbol = _item$Action$params3.Product2Symbol,
          Side = _item$Action$params3.Side;

        item.Action = Object.assign({}, item.Action, {
          primaryText:
            Side === 'Buy'
              ? context.t('Bought {a}', { a: Product1Symbol })
              : context.t('Sold {a}', { a: Product1Symbol }),
          secondaryText:
            Side === 'Buy'
              ? context.t('with {a}', { a: Product2Symbol })
              : context.t('for {a}', { a: Product2Symbol }),
          blockChainLink: ''
        });
        item.Status = Object.assign({}, item.Status, {
          status: context.t('Complete')
        });
        return item;
      }
      default:
        return item;
    }
  });
};

export var getColumns = function getColumns(width, themeModifier, context) {
  var columns = [
    {
      header: context.t('Action'),
      cell: function cell(row) {
        return React.createElement(Action, {
          value: row.Action,
          showProductIcon: true,
          themeModifier: themeModifier
        });
      }
    },
    {
      header: context.t('Amount'),
      width: '20rem',
      cell: function cell(row) {
        return React.createElement(Amount, { value: row.Amount });
      }
    },
    {
      header: context.t('Status / ID'),
      width: '40rem',
      cell: function cell(row) {
        let newStatus = row.Status;
        const status = STATUS_PROCESS[row.Status.status];
        if (!empty(status)) newStatus.status = status;
        return React.createElement(Status, { value: newStatus });
      }
    },
    {
      header: context.t('Time & Date'),
      width: '15rem',
      cell: function cell(row) {
        return React.createElement(DateTime, { value: row.TimeStamp });
      }
    }
  ];

  return columns;
};

export var getRecentActivity = function getRecentActivity(state, filter) {
  if (filter) {
    return selectedRecentActivitySelector(state);
  }

  return recentActivitySelector(state);
};
