import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';

export const acceptDisclaimerHook = (data, userId) => {
  let headers = {};
  let path = `/api/v2/disclaimer/accept`;
  let model = {
    userId: data.userId,
    accountId: data.accountId
  };
  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
