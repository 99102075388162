import HandlerBackend from "../../helpers/HandlerBackend"

export const getNewLink = async ({userInfo, setKycLink}) => {
  try {
    const response  = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      path: `/api/document-expiration-date`,
      method: "POST",
      data:{
        banexcoinAccountId: userInfo.AccountId
      }
    })
    const desktopArchitecture = /x64|x86/;
    const isDesktop = desktopArchitecture.test(navigator.userAgent);
    if(response.data && response.data.responseId){
      setKycLink(`${process.env.REACT_APP_KYC}/${response.data.responseId}?desktop=${isDesktop}&language=${localStorage.getItem("language") || "en"}`)
    }
    return true
  } catch (error) {
    return false
  }
}