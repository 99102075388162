import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import config from '../../../config/';
import { connect } from 'react-redux';
import APIcon from '../APIcon';

import './Snackbar.css';
import StatusMessage from '../../StatusMessage/StatusMessage';
import { isEmpty } from 'lodash';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var Snackbar = (function(_Component) {
  _inherits(Snackbar, _Component);

  function Snackbar() {
    _classCallCheck(this, Snackbar);

    let _this = _possibleConstructorReturn(
      this,
      (Snackbar.__proto__ || Object.getPrototypeOf(Snackbar)).apply(
        this,
        arguments
      )
    );
    _this.handleLoading = function() {
      _this.setState({
        loading: true
      });
    };
    _this.state = {
      loading: false
    }
    return _this;
  }

  _createClass(Snackbar, [
    {
      key: 'render',
      value: function render() {
        var bemClasses = getBEMClasses(['snackbar', this.props.customClass]);
        var _props = this.props,
          type = _props.type,
          text = _props.text,
          textVars = _props.textVars,
          buttons = _props.buttons || null,
          callBackAction = _props.callBackAction,
          snackbarReducer = _props.snackbarReducer;
        const state = {
          warning: 'ERROR',
          success: 'SUCCESS',
          pending: 'PENDING',
          info: 'INFO',
        }
        const snackbarElement = document.querySelector('.ap-snackbar');
        if(snackbarElement && snackbarElement.children.length > 1){
          this.props.onClose()
        }
        if( ['UserModified'].includes(text)) return null;
        const newType = state[type] || 'SUCCESS'
        const defaultAction = (e) => {
          if(!isEmpty(callBackAction) && callBackAction.hasOwnProperty('onClick')) callBackAction.onClick();
          if(!isEmpty(snackbarReducer) && snackbarReducer.hasOwnProperty('onClick')) snackbarReducer.onClick();
          this.props.onClose()
        }
        const handleClick = async (element) => {
          if (element.onClick) {
            this.handleLoading();
            await element.onClick();
          }
          this.props.onClose();
        }
        const title = !isEmpty(snackbarReducer) && snackbarReducer.hasOwnProperty('title') ? this.context.t(snackbarReducer.title) : '';
        const message = !isEmpty(snackbarReducer) && snackbarReducer.hasOwnProperty('text') ? this.context.t(snackbarReducer.text) : this.context.t(text, textVars);
        return (
          <div className='modal-general-base'>
            <StatusMessage type={newType} title={title} message={message}>
              <div className="container_buttons_modal">
                {!isEmpty(buttons) ? (
                  buttons.map((element, key) => (
                    <button
                      key={key}
                      type="button"
                      className={element.className}
                      onClick={() => !this.state.loading && handleClick(element)}
                      disabled={this.state.loading}
                    >
                      {this.state.loading && element.loadingText ? this.context.t("Loading ...") : element.text}
                    </button>
                  ))
                ) : newType === "SUCCESS" ? (
                  <button
                    type="button"
                    className="button_V2 purple"
                    onClick={defaultAction}
                  >
                    {this.context.t("Got it")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button_V2 purple"
                    onClick={this.props.onClose}
                  >
                    {this.context.t("Try again")}
                  </button>
                )}
              </div>
            </StatusMessage>
          </div>
        )
      }
    }
  ]);

  return Snackbar;
})(Component);

Snackbar.defaultProps = {
  timeout: config.Snackbar.timeout,
  text: '',
  textVars: {},
  type: config.Snackbar.type,
  customClass: 'custom-snackbar',
  onClose: function onClose() {}
};
Snackbar.contextTypes = {
  t: PropTypes.func.isRequired
};
Snackbar.propTypes = {
  timeout: PropTypes.number,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textVars: PropTypes.object,
  type: PropTypes.oneOf(['info', 'success', 'warning']),
  customClass: PropTypes.string,
  onClose: PropTypes.func
};

const mapStateToProps = function mapStateToProps(state) {
  const { callBackAction, snackbarReducer } = state;
  return { callBackAction, snackbarReducer };
};

export default connect(mapStateToProps, null)(Snackbar);
