import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  formatPercentageValue,
  formatToThousands,
} from "apex-web/lib/helpers/numberFormatter";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import APTable from "apex-web/lib/components/common/APTable";
import "../exchangeHeader.css";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { convertIncrementToIntDecimalPlaces, numberWithCommas2 } from "../../../utils";

const InstrumentSelectorPopupComponent = (props, context) => {
  const {
    onRowClick: onRowClicked,
    level1,
    instruments,
    fetching,
    selectedRow,
    customClass,
    instrumentsByAccountId,
    publicInstruments
  } = props;
  const [rows, setRows] = useState([])
  
  useEffect(() => {
    if (instruments.length < 1) return;
    const instrumentsRow = instruments.map((instrument) => {
      const level1Data = level1[instrument.InstrumentId];
      return {
        ...instrument,
        Rolling24HrPxChange: level1Data && (
          <span
            className={baseClasses(
              level1Data.Rolling24HrPxChange >= 0
                ? "24h-positive"
                : "24h-negative"
            )}
          >
            {(level1Data.Rolling24HrPxChange > 0 ? "+" : "") +
              formatPercentageValue(level1Data.Rolling24HrPxChange)}
          </span>
        ),
        Rolling24HrPxChangePercent: level1Data && React.createElement(
          'span',
          {
            className: baseClasses(level1Data.Rolling24HrPxChangePercent >= 0 ? '24h-positive' : '24h-negative') },
          (level1Data.Rolling24HrPxChangePercent > 0 ? '+' : '') + formatPercentageValue(level1Data.Rolling24HrPxChangePercent)
        ),
        lastTrade: level1Data && level1Data.LastTradedPx.toFixed(2),
      };
    });
    setRows(
      instrumentsByAccountId.length > 0
        ? instrumentsRow.filter((item) =>
            instrumentsByAccountId.includes(item.Symbol)
          )
        : instrumentsRow.filter((item) =>
            publicInstruments.includes(item.Symbol)
          )
    );
  }, [publicInstruments, instruments, level1]);
  
  const baseClasses = getBEMClasses("instrument-selector-popup", customClass);

  const columns = [
    {
      header: context.t("Coin"),
      classModifier: "coin",
      cell: (instrument) => <p>{`${instrument.Product1Symbol} / ${instrument.Product2Symbol}`}</p>
    },
    {
      header: context.t("Price"),
      cell: (instrument) => <p style={{ fontSize: "1.2rem", color: "#a8a8c7", margin: "0" }}>{numberWithCommas2(instrument.lastTrade, convertIncrementToIntDecimalPlaces(instrument.PriceIncrement))}</p>,
    },
    {
      header: context.t("24 Hour % Change"),
      cell: (instrument) =>  instrument.Rolling24HrPxChangePercent,
    },
  ];

  return (
    <APTable
      {...{
        fetching,
        columns,
        onRowClicked,
        selectedRow,
        rows,
        baseClass: baseClasses,
        empty: context.t("No coin is available"),
      }}
    />
  );
};

InstrumentSelectorPopupComponent.propTypes = {
  level1: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  instruments: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

InstrumentSelectorPopupComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

InstrumentSelectorPopupComponent.mapsStateToProps = (state) => {
  const instrumentsByAccountId = !isEmpty(state.instrumentsByAccountId) 
  && !isEmpty(state.instrumentsByAccountId[0]) 
  && !isEmpty(state.instrumentsByAccountId[0].instrumentsByAccountId.exchange) 
  ? state.instrumentsByAccountId[0].instrumentsByAccountId.exchange 
  : []
  return { instrumentsByAccountId };
}
export default connect(InstrumentSelectorPopupComponent.mapsStateToProps)(InstrumentSelectorPopupComponent);
