import { connect } from 'react-redux';
import WalletRowComponent from './WalletRowComponent';
import { getProductByIdSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';
import resize from 'apex-web/lib/hocs/resize';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var _getProductByIdSelect = getProductByIdSelector(state, ownProps.productId),
    iconFileName = _getProductByIdSelect.iconFileName,
    ProductSymbol = _getProductByIdSelect.ProductSymbol,
    ProductFullName = _getProductByIdSelect.ProductFullName,
    ProductType = _getProductByIdSelect.ProductType,
    ProductId = _getProductByIdSelect.ProductId;

  var isCrypto = ProductType === 'CryptoCurrency';

  return {
    iconFileName: iconFileName,
    ProductSymbol: ProductSymbol,
    ProductFullName: ProductFullName,
    isCrypto: isCrypto,
    ProductId: ProductId,
    detailsLink: ownProps.detailsLink
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectPositionAndSave: function selectPositionAndSave() {
      dispatch(_selectPositionAndSave(ownProps.productId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(resize(WalletRowComponent));
