import { connect } from 'react-redux';
import KYCSettingsComponent from './KYCSettingsComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { getKYCComponent } from './KYCTypeSwitchHelper';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    kycConfig = _kycVerificationLevel.kycConfig;

  var LevelsComponent = getKYCComponent(kycConfig);

  return {
    LevelsComponent: LevelsComponent
  };
};

var KYCSettingsContainer = connect(mapStateToProps)(KYCSettingsComponent);

export default KYCSettingsContainer;
