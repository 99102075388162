import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Row, Badge, Col, Table, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { countries } from '../../../config/staticCountry';
import Loading from '../../../components/Loading';
import { getMyCreditCards, deleteCard } from '../EasyBuyHooks';
import { statusCircle } from '../../utils';
import './../EasyBuyPage.css';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const EasyBuyPageMyCards = (props, context) => {
  try {
    const { userInfo, selectedAccountId, accounts } = props;

    const [loading, setLoading] = useState(0);
    const [myCreditCards, setMyCreditCards] = useState({});
    document.title = `${context.t('Easy Buy')} - ${context.t(
      'Add Card'
    )} - Banexcoin`;

    async function startData() {
      setLoading(1);
      let currentAccountInfo = accounts.find(
        a => a.AccountId === selectedAccountId
      );

      let cards = await getMyCreditCards(userInfo.UserId, selectedAccountId);
      if (cards.data.totalCount >= 0) {
        setMyCreditCards(cards.data.data);
      }
      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(accounts) && !empty(selectedAccountId)) {
          startData();
        } else {
          setLoading(1);
        }
      },
      [userInfo, accounts, selectedAccountId]
    );

    const deleteCardHook = async id => {
      setLoading(1);
      try {
        let del = await deleteCard(userInfo.UserId, selectedAccountId, id);
        if (del.data.status === 'DELETED') {
          await startData();
          toast.success(context.t('Credit card deleted successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          toast.warn(context.t('Could not delete credit card'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } catch (error) {
        toast.warn(context.t('Could not delete credit card'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const deleteCardById = (id, number) => {
      confirmAlert({
        title: context.t('Delete card'),
        message: context.t(
          'Are you sure you want to delete the card?: {card}',
          {
            card: number
          }
        ),
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => {
              deleteCardHook(id);
            }
          },
          {
            label: 'Cancelar',
            onClick: () => {}
          }
        ]
      });
    };

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="container credit-card-buy">
          <Row className="justify-content-md-center">
            <Col lg={8}>
              <Row className="col-fix add-card">
                <h2 className="card-title">{context.t('My credit cards')}</h2>
                <Button
                  variant="banexcoin"
                  className="btn-top"
                  onClick={e => {
                    startData();
                  }}>
                  {context.t('Update')}
                </Button>
                <Table striped bordered hover className="credit-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{context.t('Cardholder')}</th>
                      <th>{context.t('Card number')}</th>
                      <th>{context.t('Address')}</th>
                      <th>{context.t('City')}</th>
                      <th>{context.t('District')}</th>
                      <th>{context.t('Country')}</th>
                      <th>{context.t('Credit Card Type')}</th>
                      <th>{context.t('Status')}</th>
                      <th>{context.t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!empty(myCreditCards) &&
                      myCreditCards.map((data, index) => {
                        let detail = JSON.parse(data.payloadResponse);
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{detail.billingDetails.name}</td>
                            <td className="credit-number">{`**** **** **** ${
                              detail.last4
                            }`}</td>
                            <td>{detail.billingDetails.line1}</td>
                            <td>{detail.billingDetails.city}</td>
                            <td>{detail.billingDetails.district}</td>
                            <td>
                              {
                                countries.find(
                                  c =>
                                    c.abbreviation ===
                                    detail.billingDetails.country
                                ).country
                              }
                            </td>
                            <td>{detail.network}</td>
                            <td
                              className={`status ${
                                data.status
                              } status-container-bnx`}>
                              <Badge variant={statusCircle(data.status)}>
                                {context.t(data.status)}
                              </Badge>
                            </td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  deleteCardById(
                                    data.id,
                                    `**** **** **** ${detail.last4}`
                                  );
                                }}>
                                {context.t('Delete')}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div className="card-lefted-footer" style={{ width: '100%' }}>
                  <Link to="/easy-buy/add-card" className="btn btn-banexcoin">
                    {context.t('Add new credit card')}
                  </Link>
                  <Link to="/easy-buy" className="btn btn-secondary">
                    {context.t('Buy more')}
                  </Link>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('Error on EasyBuyAddCard', e);
    return <div>Error:</div>;
  }
};

EasyBuyPageMyCards.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(EasyBuyPageMyCards);
