import HandlerBackend from '../../helpers/HandlerBackend';

export const getBannerPopup = (eventShow, userId) => {
  let path = `/api/bannerpopup/${eventShow}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};
