import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Row, Col } from 'react-bootstrap';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  FormField,
  FormButtonV2,
  FormSelector,
  FormSingleCheckbox
} from '../../../components/common/BnxFormsComponents';
import img_international from './../../../images/img_international.png';
import { IconArrowPopupLeft } from '../../../components/common/BnxIcons';
import { Link } from 'react-router-dom';

const accountPageClasses = getBEMClasses('account-page');

const AddInternationalFormFields = (props, context) => {
  let {
    inputs,
    onInputChangeValidation,
    onCheckboxChangeValidation,
    onInputChangeCleanValidation,
    generalErrors,
    errors,
    countries,
    getListIntermediaryBanksByCountry,
    intermediaryBanks,
    myAllUniqueBanks,
    getListMyAllBanksByCountry,
    goBackPath
  } = props;
  return (
    <Row>
      <Col xl="4">
        <Link
          to={!empty(goBackPath) ? goBackPath : '/settings/accounts/add'}
          className="v2-back-link">
          <IconArrowPopupLeft />
        </Link>
      </Col>
      <Col xl="4">
        <div className={`${accountPageClasses('select-box-item')} reset-style-box`}>
          <img src={img_international} />
          <i>{context.t('My account')}</i>
          <b>{context.t('International')}</b>
        </div>
        <h3>{context.t('Transfer Type')}</h3>
        <FormSelector
          required
          name="transferType"
          defaultOption="Transfer Type"
          options={[
            {
              value: 'INTERNATIONAL',
              label: context.t('International with intermediary bank')
            },
            {
              value: 'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK',
              label: context.t('International without intermediary bank')
            }
          ]}
          onChange={onInputChangeValidation}
          value={inputs.transferType}
          errors={errors}
        />
        <FormField
          required
          name="alias"
          label="Account aliases"
          labelInside="Account aliases"
          placeholder="Alias"
          onChange={onInputChangeValidation}
          value={inputs.alias}
          errors={errors}
        />
        <h3>{context.t('My bank account details')}</h3>
        <FormSelector
          required
          name="currencyCode"
          defaultOption="Select currency"
          options={[{ value: 'USD', label: 'USD' }]}
          onChange={onInputChangeValidation}
          value={inputs.currencyCode}
          disabled={true}
          errors={errors}
        />
        <FormSelector
          required
          name="countryCode"
          labelInside="Country Bank"
          defaultOption="Country Bank"
          options={
            !empty(countries) &&
            countries.map(c => {
              return { value: c.countryCode, label: c.countryName };
            })
          }
          onChange={e => {
            e.persist();
            onInputChangeValidation(e);
            getListMyAllBanksByCountry(e.target.value);
          }}
          value={inputs.countryCode}
          errors={errors}
        />
        <FormSelector
          required
          name="bankId"
          defaultOption="Bank name"
          labelInside="Bank name"
          options={
            myAllUniqueBanks.map(b => {
              return { value: b.id, label: b.bankName };
            })
          }
          onChange={onInputChangeValidation}
          value={inputs.bankId}
          errors={errors}
        />
        <FormField
          required
          name="accountNumber"
          labelInside="Account number"
          placeholder="Account number"
          onChange={onInputChangeCleanValidation}
          value={inputs.accountNumber}
          errors={errors}
        />
        <FormField
          required
          name="bankAddress"
          labelInside="Bank Address"
          placeholder="Bank Address"
          onChange={onInputChangeValidation}
          value={inputs.bankAddress}
          errors={errors}
        />
        <FormField
          required
          name="swiftBicCode"
          labelInside="Swift / BIC code"
          placeholder="Swift / BIC code"
          onChange={onInputChangeValidation}
          value={inputs.swiftBicCode}
          errors={errors}
        />
        <FormField
          required
          name="abaRoutingNumber"
          labelInside="ABA / Routing Number"
          placeholder="ABA / Routing Number"
          onChange={onInputChangeValidation}
          value={inputs.abaRoutingNumber}
          errors={errors}
        />
        <h3>{context.t('Beneficiary information')}</h3>
        <FormField
          required
          name="fullName"
          labelInside="Name of the final beneficiary"
          placeholder="Name of the final beneficiary"
          onChange={onInputChangeValidation}
          value={inputs.fullName}
          errors={errors}
        />
        <FormField
          required
          name="beneficiaryAddress"
          labelInside="Beneficiary Address"
          placeholder="Beneficiary Address"
          onChange={onInputChangeValidation}
          value={inputs.beneficiaryAddress}
          errors={errors}
        />
        <FormSelector
          required
          name="beneficiaryCountry"
          labelInside="Beneficiary Country"
          defaultOption="Beneficiary Country"
          options={
            !empty(countries) &&
            countries.map(c => {
              return { value: c.countryCode, label: c.countryName };
            })
          }
          onChange={onInputChangeValidation}
          value={inputs.beneficiaryCountry}
          errors={errors}
        />
        <FormField
          required
          name="beneficiaryCity"
          labelInside="Beneficiary City"
          placeholder="Beneficiary City"
          onChange={onInputChangeValidation}
          value={inputs.beneficiaryCity}
          errors={errors}
        />
        <FormField
          required
          name="beneficiaryZip"
          labelInside="Beneficiary Zip"
          placeholder="Beneficiary Zip"
          onChange={onInputChangeValidation}
          value={inputs.beneficiaryZip}
          errors={errors}
        />
        {inputs.transferType === 'INTERNATIONAL' && (
          <React.Fragment>
            <h3>{context.t('Intermediary bank information')}</h3>
            <FormSelector
              required
              name="intermediaryBankCountry"
              labelInside="Intermediary Bank Country"
              defaultOption="Intermediary Bank Country"
              options={
                !empty(countries) &&
                countries.map(c => {
                  return { value: c.countryCode, label: c.countryName };
                })
              }
              onChange={e => {
                e.persist();
                onInputChangeValidation(e);
                getListIntermediaryBanksByCountry(e.target.value);
              }}
              value={inputs.intermediaryBankCountry}
              errors={errors}
            />
            <FormSelector
              required
              name="intermediaryBankId"
              labelInside="Intermediary Bank Name"
              defaultOption="Intermediary Bank Name"
              options={
                !empty(intermediaryBanks) &&
                intermediaryBanks.map(b => {
                  return { value: b.id, label: b.bankName };
                })
              }
              onChange={onInputChangeValidation}
              value={inputs.intermediaryBankId}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryBankAddress"
              labelInside="Intermediary bank address"
              placeholder="Intermediary bank address"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryBankAddress}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryBankCity"
              labelInside="Intermediary bank city"
              placeholder="Intermediary bank city"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryBankCity}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryBankState"
              labelInside="Intermediary bank state"
              placeholder="Intermediary bank state"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryBankState}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryBankZip"
              labelInside="Intermediary bank zip"
              placeholder="Intermediary bank zip"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryBankZip}
              errors={errors}
            />
            <FormField
              required
              name="intermediarySwiftBicCode"
              labelInside="Intermediary Swift / BIC code"
              placeholder="Intermediary Swift / BIC code"
              onChange={onInputChangeValidation}
              value={inputs.intermediarySwiftBicCode}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryAbaRoutingNumber"
              labelInside="Intermediary ABA / Routing number"
              placeholder="Intermediary ABA / Routing number"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryAbaRoutingNumber}
              errors={errors}
            />
            <FormField
              required
              name="intermediaryReference"
              labelInside="Intermediary reference"
              placeholder="Intermediary reference"
              onChange={onInputChangeValidation}
              value={inputs.intermediaryReference}
              errors={errors}
            />
          </React.Fragment>
        )}

        <FormSingleCheckbox
          name="ownAccountConfirmation"
          classNameContainer="pl-3"
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: context.t('I declare that it is my personal account')
              }}
            />
          }
          onChange={onCheckboxChangeValidation}
          checked={inputs.ownAccountConfirmation}
          errors={errors}
        />
        {!empty(generalErrors) && !empty(generalErrors.multiple) ? (
          <Row className="justify-content-center mt-1 mb-4 error-msg">
            <Col xl="12">
              <ul className="error-box">
                {generalErrors.multiple.map(e => <li>{context.t(e)}</li>)}
              </ul>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <FormButtonV2
          type="submit"
          variant={empty(errors) ? 'dark' : 'light'}
          className="submit-button">
          {context.t('Save')}
        </FormButtonV2>
      </Col>
    </Row>
  );
};

AddInternationalFormFields.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AddInternationalFormFields;
