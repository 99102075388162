import React from 'react';
import { Route } from 'react-router-dom';
import empty from 'is-empty';
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import SettingsMenuComponent from '../components/SettingsMenu/SettingsMenuComponent';
import UserSettingsPage from './UserSettingsPage';
import TradeReportPage from './TradeReportPage';
import TradeSettingsPage from 'apex-web/lib/components/Settings/TradeSettings';
import APIKeysPage from './APIKeysPage';
import AffiliateProgramPage from './AffiliateProgramPage';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import KYCPage from './KYCPage';
import AccountPage from './AccountPage';
import TrustListPage from './TrustListPage';
import PropTypes from 'prop-types';
import './SettingsPage.css';
import AccountEditPeFormPage from './AccountV2/AccountEditPeFormPage';
import AccountAddPage from './AccountV2/AccountAddPage';
import AccountAddPeFormPage from './AccountV2/AccountAddPeFormPage';
import AccountAddUSFormPage from './AccountV2/AccountAddUSFormPage';
import AccountAddInternationalFormPage from './AccountV2/AccountAddInternationalFormPage';
import AccountEditUSFormPage from './AccountV2/AccountEditUSFormPage';
import AccountEditInternationalFormPage from './AccountV2/AccountEditinternationalFormPage';
import { connect } from 'react-redux';
import SecurityPage from './Setting/SecurityPage';
import SwipingFundsPage from './SwipingFundsPage';

const settingsPageClasses = getBEMClasses('settings-page');

const SettingsPage = (props, context) => {
  let { prefix, location, headerChildren, user } = props;
  let prePath = !empty(prefix) ? prefix : '';
  let pathToken =
    !empty(location) && !empty(location.pathname)
      ? location.pathname.split('/')
      : '';
  let token =
    !empty(pathToken) && !empty(pathToken[pathToken.length - 1])
      ? pathToken[pathToken.length - 1]
      : '';
  return (
    <React.Fragment>
      {!empty(headerChildren) ? headerChildren : ""}
      <h1 className={settingsPageClasses("title")}>{context.t("Settings")}</h1>
      <div className={settingsPageClasses("container")}>
        <div className={settingsPageClasses("menu")}>
          <SettingsMenuComponent prePath={prePath} token={token} />
        </div>
        <div className={settingsPageClasses("content")}>
          {/* BNX AND COINK */}
          {
            user &&
            user.verificationLevelConfigs &&
            user.verificationLevelConfigs.Level >= 100 && (
              <React.Fragment>
                <Route path={"/settings/user"} component={UserSettingsPage} />
                <Route path={"/settings/security"} component={SecurityPage} />
                <Route path={"/settings/trust-list"} component={TrustListPage} />
                <Route path={"/settings/verification-level"} component={KYCPage}/>
                <Route path={"/settings/trade-reports"} component={TradeReportPage} />
                <Route path={"/settings/withdrawal"} component={SwipingFundsPage} />
              </React.Fragment> 
            )
          }
          {/* ONLY BNX */}
          {user &&
            user.verificationLevelConfigs &&
            user.verificationLevelConfigs.Level < 100 && (
              <React.Fragment>
                <Route path={"/settings/user"} component={UserSettingsPage} />
                <Route path={"/settings/security"} component={SecurityPage} />
                <Route path={"/settings/trust-list"} component={TrustListPage} />
                <Route path={"/settings/trade-reports"} component={TradeReportPage} />
                <Route path={"/settings/trade"} component={TradeSettingsPage} />
                <Route path={"/settings/api-keys"} component={APIKeysPage} />
                <Route
                  path={"/settings/affiliate"}
                  component={AffiliateProgramPage}
                />
                <Route
                  path={"/settings/verification-level"}
                  component={KYCPage}
                />
                <Route
                  exact
                  path={"/settings/accounts"}
                  component={AccountPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/add"}
                  component={AccountAddPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/add/pe"}
                  component={AccountAddPeFormPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/edit/pe/:id"}
                  component={AccountEditPeFormPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/add/us"}
                  component={AccountAddUSFormPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/edit/us/:id"}
                  component={AccountEditUSFormPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/add/international"}
                  component={AccountAddInternationalFormPage}
                />
                <Route
                  exact
                  path={"/settings/accounts/edit/international/:id"}
                  component={AccountEditInternationalFormPage}
                />
              </React.Fragment>
            )}
        </div>
      </div>
      <div className={settingsPageClasses("footer")}>
        <PageFooterLayout />
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user
  }
}
SettingsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(SettingsPage)
