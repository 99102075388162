import React, { useEffect, useState } from 'react'
import './verification-start.css'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CardGradient from '../../components/CardGradient'
import CameraIcon from '../../../../images/icons-v2/camera.svg'
import LocationIcon from '../../../../images/icons-v2/location.svg'
import NoteIcon from '../../../../images/icons-v2/note.svg'
import PersonalCardIcon from '../../../../images/icons-v2/personalcard.svg'
import VerificationStartController from '../../controllers/VerificationStartController';
import isEmpty from 'is-empty';
import { Loader } from '../../components';
import { Redirect } from 'react-router-dom';

const VerificationStartPage = (props, context) => {
  const { loadingBanexCard, userLevel, userInfo, lang, banexCard: { hasBanexCard } } = props;
  const ht = useHistory();
  const [loadingPage, setLoadingPage] = useState(true);
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [actions, setActions] = useState([
    {
      label: context.t("Return"),
      className: "secondary",
      action: () => ht.push("/cards")
    }
  ])

  const [options, setOptions] = useState([
    {
      icon: CameraIcon,
      title: 'Selfie Photo',
      description: 'Look directly at the camera (Do not use glasses, caps, or masks).'
    },
    {
      icon: PersonalCardIcon,
      title: 'ID Document Photo',
      description: 'Upload the front and back photos of your ID.'
    },
    {
      icon: NoteIcon,
      title: 'Fill in Personal Information',
      description: 'Complete the form with your personal details.'
    },
    {
      icon: LocationIcon,
      title: 'Provide Residence and Occupation Details',
      description: 'Add your current residence and occupation information.'
    }
  ]);

  const hasKYC = [
    {
      icon: NoteIcon,
      title: 'Fill in Personal Information',
      description: 'Complete the form with your personal details.'
    },
    {
      icon: LocationIcon,
      title: 'Provide Residence and Occupation Details',
      description: 'Add your current residence and occupation information.'
    }
  ]

  const errorActions = [
    {
      label: context.t("Got it"),
      action: () => ht.push("/dashboard")
    }
  ]

  const updateActions = (action) => {
    setActions([
      actions[0],
      {
        label: context.t("Got it"),
        action
      }
    ])
  }

  useEffect(() => {
    if (loadingBanexCard) return;
    if (userLevel >= 11) {
      setOptions([
        {
          icon: LocationIcon,
          title: 'Provide Residence and Occupation Details',
          description: 'Add your current residence and occupation information.'
        }
      ])
      const onClick = () => ht.push("/cards/increment-level")
      updateActions(onClick)
      setLoadingPage(false)
    } else {
      VerificationStartController.generateKYC({
        userInfo,
        setLoadingPage,
        setToken,
        lang,
        updateActions,
        ht,
        setErrorMessage,
        context,
        setOptions,
        hasKYC
      })
    }
  }, [userLevel, loadingBanexCard])

  useEffect(() => {
    if (!isEmpty(token)) {
      const link = `${process.env.REACT_APP_KYC}/${token}?desktop=true&language=${lang}&type=banexCard`;
      const onClick = () => window.open(link, "_self", "noreferrer")
      updateActions(onClick)
    }
  }, [lang])

  if (loadingBanexCard || loadingPage) return <Loader />
   
  if(![0,10,11].includes(userLevel) || hasBanexCard) return <Redirect to="/cards" />

  return (
    <div className='verification-start'>
      {!isEmpty(errorMessage) ?
        <CardGradient
          type='error'
          message={context.t(errorMessage)}
          actions={errorActions}
        /> :
        <CardGradient
          type="support"
          actions={actions}
          customClassName="card-verification"
        >
          <div className='container-verification'>
            <h2>{context.t('To request your virtual Banexcard we first need to validate your identity')}</h2>
            <p>{context.t('For this you will need to follow the steps below')}:</p>
            <div className='list-verification'>
              {options.map(({ icon, title, description }, index) => {
                const number = options.length > 1 ? `${index + 1}. ` : '';
                return (
                  <div className='item-verification' key={index}>
                    <div className='verification-icon'>
                      <img src={icon} alt={title} width={28} />
                    </div>
                    <div className='verification-info'>
                      <p><b>{`${number}${context.t(title)}`}</b></p>
                      <p>{context.t(description)}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </CardGradient>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { user: { userInfo }, banexCard: { loadingBanexCard, userLevel }, i18nState: { lang } } = state;
  return {
    loadingBanexCard,
    userLevel,
    userInfo,
    lang,
    banexCard: state.banexCard,
  };
};

VerificationStartPage.contextTypes = {
  t: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(VerificationStartPage)