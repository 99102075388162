import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import SendReceiveConfirmHeaderComponent from './SendReceiveConfirmHeaderComponent';
import './SendReceiveConfirmModal.css';
import { amountWithCurrency, formatCopCurrency, formatNumbersWithoutRound } from '../../../pages/utils';
import { connect } from 'react-redux';

const bemClasses = getBEMClasses('send-receive-confirm-modal');

let SendReceiveConfirmModal = function SendReceiveConfirmModal(props, context) {
  const isOpen = props.isOpen,
    close = props.close,
    product = props.product,
    isSend = props.isSend,
    values = props.values,
    onConfirm = props.onConfirm,
    useExternalAddress = props.useExternalAddress,
    fee = props.fee,
    hideFees = props.hideFees,
    balance = props.balance,
    amount = props.values.Amount,
    ProductSymbol = props.product.ProductSymbol,
    isCoink = props.isCoink,
    decimalPlaces= props.product.decimalPlaces;
    
    const cleanPrices = (amount, steps) => {
      return Number(Number(amount).toFixed(steps));
    };

    const getValue = (value) => {
      const splittedValue = Number(String(value).split(" ")[0])
      if(isNaN(splittedValue)) return value
      return amountWithCurrency({
        amount: !isCoink ? splittedValue : formatCopCurrency(Number(splittedValue), product.DecimalPlaces).slice(2),
        product,
      })
    }
    
    const remainingBalance = formatNumberToLocale(
      cleanPrices(
        formatNumbersWithoutRound(balance, decimalPlaces) - (amount),
        decimalPlaces
      ),
      ProductSymbol
    );

  const addressLabel = context.t('Withdrawal address');
  const sendLabel = context.t("Recipient's email");
  const receiveLabel = context.t('Applicant email');

  const handleAmountToReceive = () => {
    let result = '-';
    if (amount) {
      result = `${formatNumberToLocale(amount - fee, ProductSymbol)} ${ProductSymbol}`;
      if (remainingBalance.includes('-')) {
        result = <span className='danger-message-v1'>{context.t('Insufficient balance')}</span> 
      }
    }
    return result;
  }

  return React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: React.createElement(SendReceiveConfirmHeaderComponent, {
        isSend: isSend,
        product: product
      }),
      close: close,
      footer: {
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: onConfirm
      },
      customClass: bemClasses()
    },
    React.createElement(
      'header',
      { className: bemClasses('title') },
      context.t('Confirmation')
    ),
    React.createElement(
      'section',
      { className: bemClasses('details') },
      !useExternalAddress &&
        React.createElement(APLabelWithText, {
          label: isSend ? sendLabel : receiveLabel,
          text: values.ReceiverUsername,
          customClass: bemClasses()
        }),
      React.createElement(APLabelWithText, {
        label: context.t('Amount to withdraw'),
        text:
          getValue(formatNumberToLocale(amount, product.ProductSymbol)),
        customClass: bemClasses()
      }),
      useExternalAddress &&
        React.createElement(
          React.Fragment,
          null,
          !hideFees &&
            React.createElement(
              React.Fragment,
              null,
              React.createElement(APLabelWithText, {
                label: context.t('Withdrawal fee'),
                text:
                  getValue(formatNumberToLocale(fee, product.ProductSymbol)),
                customClass: bemClasses()
              }),
              React.createElement(APLabelWithText, {
                label: context.t('Amount to receive'),
                text:getValue(handleAmountToReceive()),
                customClass: bemClasses()
              })
            ),
          React.createElement(APLabelWithText, {
            label: addressLabel,
            text: values.ExternalAddress,
            customClass: bemClasses()
          })
        ),
      React.createElement(APLabelWithText, {
        label: context.t('Note'),
        text: values.Notes,
        customClass: bemClasses()
      }),
      React.createElement(APLabelWithText, {
        label: context.t('Time'),
        text: formatDateTime(new Date()),
        customClass: bemClasses()
      })
    )
  );
};

SendReceiveConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  isSend: PropTypes.bool.isRequired,
  fee: PropTypes.number.isRequired,
  total: PropTypes.number,
  useExternalAddress: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SendReceiveConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired
};

SendReceiveConfirmModal.mapStateToProps = state => {
  return { withdrawDetails: state.withdrawDetails };
}

export default connect(SendReceiveConfirmModal.mapStateToProps)(SendReceiveConfirmModal);
