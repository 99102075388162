import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';

const FormSingleRadio = (props, context) => {
  const {
    label,
    name,
    id,
    onChange,
    errors,
    errorMessage,
    value,
    options
  } = props;
  try {
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      name,
      id: idTwo,
      label: label,
      value
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field' +
          ((!empty(errors) && !empty(errors[name])) || !empty(errorMessage)
            ? ' invalid'
            : ' valid')
        }>
        <Form.Check
          type="radio"
          className="v2-field radius-group"
          {...propInput}>
          {!empty(options) &&
            options.map((item, index) => {
              return (
                <Form.Check
                  key={index}
                  value={item.value}
                  name={name}
                  onChange={onChange}
                  label={item.label}
                  type="radio"
                  checked={value === item.value ? 'checked' : ''}
                  id={`${idTwo}-${index}`}
                />
              );
            })}
        </Form.Check>
        {!empty(errors) && !empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : !empty(errorMessage) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errorMessage)}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormSingleRadio', error);
    return <React.Fragment />;
  }
};

FormSingleRadio.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormSingleRadio;
