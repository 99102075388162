import React, { useState } from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { DeleteShareholder } from './PeopleHooks';
import { Table, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import Dialog from 'react-bootstrap-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { buildSecureGetParams } from '../../helpers/lib';

const PeopleList = (props, context) => {
  let { userInfo } = props;
  let shareholders = props.shareholders;
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>{context.t('Loading...')}</div>
        </React.Fragment>
      );
    }

    const goToView = async id => {
      context.router.history.push(`/corporate-people/${id}`);
    };

    const [loading, setLoading] = useState(0);

    const loadRows = () => {
      let rows = [];
      let shareholders = props.shareholders;
      if (empty(shareholders)) {
        return (
          <tr>
            <td colSpan="14">
              {this.props.loading > 0 ? 'Actualizando información...' : ''}
            </td>
          </tr>
        );
      }

      let arrayAccountUtility = [];
      arrayAccountUtility[1] = context.t('KYC Pending');
      arrayAccountUtility[2] = context.t('KYC In Progress');
      arrayAccountUtility[3] = context.t('KYC Pass');
      arrayAccountUtility[4] = context.t('KYC Failed');

      for (let k in shareholders) {
        let birthday = moment(shareholders[k].birthday);
        birthday = birthday.add(1, 'days');
        //var momentString = shareholders[k].birthday.format('YYYY-MM-DD');
        if (shareholders[k].peopleType === 2) {
          //console.log(shareholders[k].levelsCorporateInstitutionSubpeopleByParentCorporateLevelsId.nodes);
          rows.push(
            <tr key={k}>
              {/* <td>{arrayAccountUtility[shareholders[k].accountUtility]}</td> */}
              <td>{shareholders[k].companyName}</td>
              <td>{shareholders[k].addressMain}</td>
              <td>{shareholders[k].phone}</td>
              <td>{shareholders[k].email}</td>
              <td>{arrayAccountUtility[shareholders[k].status]}</td>
              <td>{loadOptionsDelete(shareholders[k])}</td>
            </tr>
          );
          if (
            !empty(
              shareholders[k]
                .levelsCorporateInstitutionSubpeopleByParentCorporateLevelsId
            )
          ) {
            const sublevel =
              shareholders[k]
                .levelsCorporateInstitutionSubpeopleByParentCorporateLevelsId
                .nodes;
            for (let sk in sublevel) {
              rows.push(
                <tr key={sk}>
                  <td colSpan="6">
                    <div className="parentsubboxes-sk">
                      <div className="subboxes-sk">
                        <b>{context.t('Full Name')}</b>
                        <br />
                        {sublevel[sk].fullName}
                      </div>
                      <div className="subboxes-sk">
                        <b>{context.t('Address')}</b>
                        <br />
                        {sublevel[sk].addressMain}
                      </div>
                      <div className="subboxes-sk">
                        <b>{context.t('Phone')}</b>
                        <br />
                        {sublevel[sk].phone}
                      </div>
                    </div>
                    <div className="parentsubboxes-sk">
                      <div className="subboxes-sk">
                        <b>{context.t('Email')}</b>
                        <br />
                        {sublevel[sk].email}
                      </div>
                      <div className="subboxes-sk">
                        <b>{context.t('Status')}</b>
                        <br />
                        {arrayAccountUtility[parseInt(sublevel[sk].status, 10)]}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            }
          }
        }
      }
      return rows;
    };

    const confirmDelete = async id => {
      const r = window.confirm(
        context.t('Do you really want to delete the record?')
      );
      if (r === true) {
        setLoading(1);
        await DeleteShareholder(id, userInfo.UserId);
        props.updateListAccounts();
        setLoading(0);
      }
    };

    const loadOptionsDelete = row => {
      return (
        <div>
          <OverlayTrigger
            key={row.id}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-${row.id}`}>
                {context.t('Delete Shareholder')}
              </Tooltip>
            }>
            <Button
              variant="info"
              onClick={async () => {
                confirmDelete(row.id);
              }}>
              <FontAwesomeIcon icon="minus-circle" />
            </Button>
          </OverlayTrigger>{' '}
          <Button
            variant="info"
            onClick={() => {
              goToView(buildSecureGetParams({ id: row.id }));
            }}>
            <FontAwesomeIcon icon="minus-circle" />
          </Button>
        </div>
      );
    };

    return (
      <div className="col-lg-12">
        <Loading loading={loading} />
        <Dialog
        // ref={component => {
        //   this.dialog = component;
        // }}
        />
        <Card>
          <Card.Body>
            {!empty(shareholders) ? (
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>{context.t('Company Name')}</th>
                    <th>{context.t('Address')}</th>
                    <th>{context.t('Phone')}</th>
                    <th>{context.t('Email')}</th>
                    <th>{context.t('Status')}</th>
                    <th>{context.t('Options')}</th>
                  </tr>
                </thead>
                <tbody>{loadRows()}</tbody>
              </Table>
            ) : (
              <p>{context.t('There are no records to show')}</p>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  } catch (e) {
    console.log('error interno', e);
    return <div>Error:</div>;
  }
};

PeopleList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.object.isRequired
  }),
  t: PropTypes.func.isRequired
};
const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(PeopleList);
