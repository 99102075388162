export const setUserInfo = (payload) => {
    return {
      type: "SET_USER_INFO",
      payload
    };
  };
  
export const setBlockActions = (payload) => {
  return {
    type: "SET_BLOCK_ACTIONS",
    payload
  };
};
  
export const setSwipingConfig = (payload) => {
  return {
    type: "SET_SWIPING_CONFIG",
    payload
  };
};
  