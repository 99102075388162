import React from 'react';
import PropTypes from 'prop-types';

import { getBEMClasses } from '../../helpers/cssClassesHelper';
import './PageFooterLayout.css';

const pageFooterClasses = getBEMClasses('page-footer');

const PageFooterLayout = (props, context) => {
  return (
    <footer className={pageFooterClasses()}>
      <div className={pageFooterClasses('footer-left-content')}>
        {context.t('{n}™ {y}. All Rights Reserved.', {
          n: process.env.REACT_APP_SITE_NAME,
          y: new Date().getFullYear()
        })}
      </div>
    </footer>
  );
};

PageFooterLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageFooterLayout;
