import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  getFinalBalanceWithdrawValue,
  getAvailableBalance
} from 'apex-web/lib/helpers/withdrawHelper';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { excludeProductSymbols, formatNumbersWithoutRound, FormatDecimalCoinkWithSimbols } from '../../../pages/utils';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import empty from 'is-empty';
import {
  FiatWithdrawModalContainer,
  FiatWithdrawFormContainer,
  FiatWithdrawDetailsComponent
} from './FiatWithdraw';
import {
  FiatDepositFormContainer,
  FiatDepositModalContainer,
  FiatDepositDetailsComponent
} from './FiatDeposit';
import FiatSidePaneHeaderComponent from './FiatSidePaneHeaderComponent';
import FiatFormTabsComponent from './FiatFormTabsComponent';
import './FiatSidePaneComponent.css';
import LimitsBalance from '../../SendReceiveSidePane/LimitsBalance';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var fiatSidePaneClasses = getBEMClasses('fiat-sidepane');

var FiatSidePaneComponent = (function(_React$Component) {
  _inherits(FiatSidePaneComponent, _React$Component);

  function FiatSidePaneComponent(props) {
    _classCallCheck(this, FiatSidePaneComponent);

    var _this = _possibleConstructorReturn(
      this,
      (
        FiatSidePaneComponent.__proto__ ||
        Object.getPrototypeOf(FiatSidePaneComponent)
      ).call(this, props)
    );

    _this.onTabChange = function() {
      _this.setState({
        isSend: !_this.state.isSend,
        data: {}
      });
    };

    _this.onUpdate = function() {
      _this.getWithdrawFee();
      _this.setTotal();
    };

    _this.setTotal = function() {
      var _this$props = _this.props,
        position = _this$props.position,
        fee = _this$props.fee;
      var Amount = _this.state.data.Amount;

      var amount = parseFloat(Amount);
      var balance = getAvailableBalance(position.Amount, position.Hold);
      var total = 0;

      if (amount) {
        total = getFinalBalanceWithdrawValue(balance, amount, fee);
      }

      _this.setState({ total: total });
    };

    _this.getWithdrawFee = function() {
      var _this$props2 = _this.props,
        ProductId = _this$props2.product.ProductId,
        getWithdrawFee = _this$props2.getWithdrawFee;
      var Amount = _this.state.data.Amount
        ? _this.state.data.Amount
        : _this.state.TicketAmount;
      if (!isNaN(Amount)) {
        getWithdrawFee(ProductId, Amount);
      }
    };

    _this.onDataUpdate = function(data) {
      var _this$state = _this.state,
        Amount = _this$state.data.Amount
          ? _this$state.data.Amount
          : _this$state.TicketAmount,
        isSend = _this$state.isSend;

      _this.setState({ data: data }, function() {
        if (!isSend && data.Amount !== Amount) {
          _this.onUpdate();
        }
      });
    };

    _this.onSetBankDetails = function(data) {
      _this.setState({ bankDetails: data });
    };

    _this.setAmounted = function(TicketAmount) {
      _this.setState({ data: { Amount: TicketAmount } });
      _this.setState({ TicketAmount: TicketAmount });
      _this.setState({ displayValue: TicketAmount });
    };

    _this.renderInstructions = function(modificator) {
      return React.createElement(
        'section',
        { className: fiatSidePaneClasses('side-details', modificator) },
        React.createElement(
          'header',
          {
            className: fiatSidePaneClasses('instructions-header', modificator)
          },
          _this.context.t('Instructions')
        ),
        React.createElement(
          'section',
          {
            className: fiatSidePaneClasses('address-instructions', modificator)
          },
          React.createElement(
            'div',
            null,
            _this.context.t('Step 1: Create the deposit ticket.')
          ),
          React.createElement(
            'div',
            null,
            _this.context.t(
              'Step 2: Process deposit instructions on the deposit receipt.'
            )
          )
        )
      );
    };

    _this.state = {
      isSend: props.isSend,
      TicketAmount: 0,
      data: {},
      openConfirmModal: false
    };
    return _this;
  }

  _createClass(FiatSidePaneComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.props.onSidePaneOpen({
          customClass: this.props.showDetails
            ? 'retail-sidepane-with-details'
            : 'retail-sidepane',
          classModifiers: 'with-tabs',
          hideHeader: true
        });
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          product = _props.product,
          position = _props.position,
          fee = _props.fee,
          showDetails = _props.showDetails,
          productConfig = _props.productConfig,
          withdrawDetailsInfo = _props.withdrawDetailsInfo,
          isDepositDisabled = _props.isDepositDisabled,
          isWithdrawDisabled = _props.isWithdrawDisabled;

        var _state = this.state,
          isSend = _state.isSend,
          data = _state.data,
          total = _state.total,
          bankDetails = _state.bankDetails;
        let amount_fiat = _state.TicketAmount;

        const balance = getAvailableBalance(position.Amount, position.Hold);

        const formatToNumber = (numbr, places) => {
          numbr = !empty(numbr) ? numbr : 0.0;
          return Number(numbr.toFixed(places));
        };
        const repeatStringNumTimes = (string, times) => {
          let repeatedString = '';
          while (times > 0) {
            repeatedString += string;
            times--;
          }
          return repeatedString;
        }
        const formatFee = (numbr, places) => {
          const denom = Number('1' + repeatStringNumTimes('0', places || 1));
          numbr = !empty(numbr) ? numbr : 0.0;
          return Math.floor(denom * numbr) / denom;
        };

        const allproducts = this.props.allproducts || []
        let decimalPlaces = allproducts.find(e => e.ProductId === product.ProductId);
        decimalPlaces = decimalPlaces ? decimalPlaces.DecimalPlaces : 2;

        const getItem = SidePaneDetailsComponent.getItem;
        const ProductSymbol = product.ProductSymbol;
        const newItems = ProductSymbol.includes(excludeProductSymbols) ? 'Current Balance' : 'Current Balance in {ProductSymbol}';
        const Amount = data.Amount;
        const TicketAmount = amount_fiat;
        const currentBalance = balance
        ? formatNumberToLocale(formatNumbersWithoutRound(balance, decimalPlaces), ProductSymbol)
        : '-';
        const TotalWithdrawValue = formatNumbersWithoutRound(Amount || TicketAmount, decimalPlaces);
        const TotalWithdraw =
          Amount || TicketAmount
            ? `${formatNumberToLocale(
                formatToNumber(TotalWithdrawValue, decimalPlaces),
                ProductSymbol
              )}`
            : '-';
        const Fee =
          Amount || TicketAmount
            ? `${formatNumberToLocale(fee, ProductSymbol)}`
            : '-';
        const amount =
          !empty(Amount) && !TicketAmount
            ? Amount
              ? formatNumberToLocale(formatNumbersWithoutRound(Amount, decimalPlaces), ProductSymbol)
              : '-'
            : TicketAmount
              ? formatNumberToLocale(formatNumbersWithoutRound(TicketAmount - fee, decimalPlaces), ProductSymbol)
              : '-';
        const newBalance = formatFee(balance, decimalPlaces);
        const remainingBalance =
          balance && (Amount || TicketAmount)
            ? `${formatNumberToLocale(
                formatToNumber(newBalance, decimalPlaces) -
                TotalWithdrawValue,
                ProductSymbol
              )}`
            : '-';

        const handleAmount = () => {
          let result = '-';
          if (Amount || TicketAmount) {
            result = FormatDecimalCoinkWithSimbols(amount , ProductSymbol);
            if (remainingBalance.includes('-')) {
              result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span> 
            }
          }
          return result;
        }

        const handleRemainingBalance = () => {
          let result = '-';
          if (Amount || TicketAmount) {
            result = FormatDecimalCoinkWithSimbols(remainingBalance , ProductSymbol);
            if (remainingBalance.includes('-')) {
              result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span> 
            }
          }
          return result;
        }
        const detailsInfo = withdrawDetailsInfo || [];
        const info = detailsInfo.map((item) => {
          if (item.useLink) {
            return React.createElement(
              'a',
              { href: item.linkAddress },
              this.context.t('{text}', { text: item.text })
            );
          } else {
            return this.context.t(item.text);
          }
        });

        const items = [
          getItem(
            this.context.t( newItems, {
              ProductSymbol: ProductSymbol
            }),
            FormatDecimalCoinkWithSimbols(currentBalance , ProductSymbol)
          ),
          getItem(this.context.t('Total to withdraw'), FormatDecimalCoinkWithSimbols(TotalWithdraw , ProductSymbol)),
          getItem(this.context.t('Fee'), FormatDecimalCoinkWithSimbols(Fee , ProductSymbol)),
          getItem(this.context.t('Amount to receive'), handleAmount()),
          getItem(this.context.t('Remaining Balance'), handleRemainingBalance())
        ]

        return React.createElement(
          'div',
          { className: fiatSidePaneClasses() },
          React.createElement(
            'section',
            { className: fiatSidePaneClasses('main') },
            React.createElement(FiatSidePaneHeaderComponent, {
              product: product
            }),
            React.createElement(
              'section',
              { className: fiatSidePaneClasses('main-form') },
              React.createElement(FiatFormTabsComponent, {
                isSend: isSend,
                toggleTab: this.onTabChange,
                product: product,
                balance: balance,
                isDepositDisabled: isDepositDisabled,
                isWithdrawDisabled: isWithdrawDisabled
              }),
              isSend
                ? React.createElement(FiatDepositFormContainer, {
                    product: product,
                    onChange: this.onDataUpdate,
                    setBankDetails: this.onSetBankDetails
                  })
                : React.createElement(FiatWithdrawFormContainer, {
                    product: product,
                    balance: balance,
                    fee: fee,
                    total: total,
                    requestedAmount: isNaN(amount_fiat) ? 0 : amount_fiat,
                    onChange: this.onDataUpdate,
                    showDetails: showDetails,
                    setAmounted: this.setAmounted,
                    items: items,
                    info: info
                  })
            ),
            isSend && !showDetails
              ? this.renderInstructions('no-details')
              : null
          ),
          showDetails &&
            React.createElement(
              'section',
              { className: fiatSidePaneClasses('side') + ' v2bnx-side' },
              <LimitsBalance
                product={product}
                side={isSend ? 'deposit' : 'withdraw'}
              />,
              isSend
                ? React.createElement(FiatDepositDetailsComponent, {
                    info: productConfig.instructions,
                    product: product,
                    bankDetails: bankDetails
                  })
                : React.createElement(FiatWithdrawDetailsComponent, {
                    data: data,
                    fee: fee,
                    TicketAmount: amount_fiat,
                    product: product,
                    balance: balance,
                    detailsInfo: withdrawDetailsInfo,
                    onChange: this.onDataUpdate
                  })
            ),
          React.createElement(FiatDepositModalContainer, null),
          React.createElement(FiatWithdrawModalContainer, null)
        );
      }
    }
  ]);

  return FiatSidePaneComponent;
})(React.Component);

FiatSidePaneComponent.defaultProps = {
  showDetails: true,
  onSidePaneOpen: function onSidePaneOpen() {},
  isSend: true,
  bankDetails: {}
};

FiatSidePaneComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatSidePaneComponent;
