import React from 'react';
import PropTypes from 'prop-types';
import ContentWithHeaderLayoutTemplate from 'apex-web/lib/components/common/LayoutTemplates/ContentWithHeaderLayoutTemplate/ContentWithHeaderLayoutTemplate';
import EnableTwoFactorAuthContainerFactory from 'apex-web/lib/components/EnableTwoFactorAuth/EnableTwoFactorAuthContainerFactory';
import EnableTwoFactorAuthComponent from './EnableTwoFactorAuth/EnableTwoFactorAuthComponent';

const EnableTwoFactorAuthContainer = EnableTwoFactorAuthContainerFactory(EnableTwoFactorAuthComponent);

let TwoFactorAuthSettingsCardComponent = function TwoFactorAuthSettingsCardComponent(props, context) {
  return React.createElement(
    ContentWithHeaderLayoutTemplate,
    {
      header: context.t('Two-Factor Authentication'),
      customClass: 'profile-and-security-widget',
      classModifiers: ['sm'] },
    React.createElement(EnableTwoFactorAuthContainer, null)
  );
};

TwoFactorAuthSettingsCardComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TwoFactorAuthSettingsCardComponent;