const snackbarReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CREATE_STATE_SNACKBAR':
            return { ...action.payload };
        case 'UPDATE_STATE_SNACKBAR':
            return { ...state, ...action.payload };
        case 'RESET_STATE_SNACKBAR':
            return {};
        default:
            return state;
    }
};
export default snackbarReducer;