import React, { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import APInput from 'apex-web/lib/components/common/APInput';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { required, email, validateReceiverEmail, alphanumeric } from 'apex-web/lib/helpers/formValidations';
import Modal, { closeStyle } from 'simple-react-modal';
import TrustList from '../../TrustList/TrustList';
import './APCStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { validateTrustList, getTrustList } from '../../TrustList/TrustListHooks';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import config from 'apex-web/lib/config';
import WAValidator from '@swyftx/api-crypto-address-validator';
import isEmpty from 'is-empty';

const bemClasses = getBEMClasses('send-form');

const APCryptoAddressInputComponent = (props, context) => {
  const [loading, setLoading] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [trustList, setTrustList] = useState({});
  const [selectedTrustList, setSelectedTrustList] = useState('');
  const productSymbol = props.productSymbol;

  const walletStructure = (value) => {
    const env = (config.global.gateway === 'wss://api.banexcoin.com/WSGateway/') ? 'prod' : 'testnet';
    const valid = WAValidator.validate(value, productSymbol, env);
    return (!valid) ? 'Invalid address' : undefined;
  }

  const checkUserEmail = (value) => {
    return validateReceiverEmail(value, props.userEmail);
  }

  const validateField = (validate = [], id, value) => {
    const error = validate.map(element => element(value)).filter(element => element);
    if(isEmpty(error)){
      removeErrorMenssage();
      return true;
    } 
    validateErrorList({
      'data': {
        [id]: context.t(error[0])
      }
    })
    return false;
  }


  const trustedListData = async (value, id, validate) => {
    const response = validateField(validate, id, value);

    if (response) {
      const id = getIdElement();
      const inputError = document.getElementById(id).parentNode.querySelectorAll('.send-form__error');

      if (inputError.length === 0) {
        removeErrorMenssage(id);
        const frequentOperation = document.getElementById('frequent-operation').checked;

        if (frequentOperation) {
          await validateTrustListData();
        }
      }
    }

    buttonSendCrypto();
    return true;
  }

  const removeErrorMenssage = (id = null) => {
    if (id) {
      document.getElementById(id).parentNode.querySelectorAll('.ap-input__error').forEach(e => e.remove());
    }

    return true;
  }

  const validateTrustListData = async () => {
    buttonSendCrypto(true);
    const requestValidateTrustList = getRequestValidateTrustList();
    const responseCreateTrustList = await validateTrustList(props.userInfo.UserId, requestValidateTrustList)
      .then(result => {
        return result;
      })
      .catch(e => {
        return e.response;
      });

    if (responseCreateTrustList.status !== 200) {
      validateErrorList(responseCreateTrustList.data);
    }

    return true;
  }

  const buttonSendCrypto = (disabled = false) => {
    document.querySelectorAll('.ap-button__btn').forEach((b) => {
      if (disabled) {
        b.disabled = disabled;
      } else {
        const errorMessage = document.querySelectorAll('.ap-input__error');
        b.disabled = (errorMessage.length === 0) ? false : true;
      }
    });

    return true;
  }

  const getRequestValidateTrustList = () => {
    const id = getIdElement();
    const fieldValue = document.getElementById(id).value;

    let requestValidateTrustList = {
      accountId: props.selectedAccountId,
      apProductId: props.productId,
      operation: getOperation(),
      label: getLabelValue()
    };

    if (props.useExternalAddress) {
      requestValidateTrustList.address = fieldValue;      
    } else {
      requestValidateTrustList.receiveEmail = fieldValue;
    }

    return requestValidateTrustList;
  }

  const getIdElement = () => {
    return (props.useExternalAddress) ? 'trust-list-address' : 'trust-list-email';
  }

  const getOperation = () => {
    return (props.useExternalAddress) ? 'External Wallet' : 'Banexcoin Transfer';
  }

  const getLabelValue = () => {
    const frequentOperation = document.getElementById('frequent-operation').checked;
    return (frequentOperation) ? document.getElementById('label').value : null;
  }

  const validateErrorList = (errorList) => {
    if (Object.prototype.hasOwnProperty.call(errorList, 'data')) {
      let fieldId;
      removeErrorMenssage();

      for (const key in errorList.data) {
        switch (key) {
          case 'receiveEmail': fieldId = 'trust-list-email'; break;
          case 'address': fieldId = 'trust-list-address'; break;
          default: fieldId = key; break;
        }

        showErrorMessage(fieldId, errorList.data[key]);
      }
    }

    return true;
  }

  const showErrorMessage = (fieldId, message) => {
    const element = document.getElementById(fieldId);

    if (element) {
      const errorMessage = element.parentNode.querySelectorAll('.send-form__error');

      if (errorMessage.length === 0) {
        let divError = document.createElement('div');
        divError.className = 'ap-input__error';
        divError.innerHTML = message;
  
        removeErrorMenssage(fieldId);
        element.parentNode.insertBefore(divError, element.nextSibling);
      }
    }

    return true;
  }

  const loadMyTrustList = async (cerrar) => {
    setLoading(1);

    try {
      const id = getIdElement();
      const fieldValue = document.getElementById(id).value;
      setSelectedTrustList(fieldValue);

      const requestGetTrustList = {
        apProductId: props.productId,
        operation: getOperation()
      };

      const result = await getTrustList(props.userInfo.UserId, requestGetTrustList);

      if (cerrar) {
        setShowModal(false);
      }

      setTrustList(result.data.trustLists);
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(0);
  };

  useEffect(() => {
    if (!empty(selectedTrustList)) {
      const id = getIdElement();
      document.getElementById(id).focus();
      document.getElementById(id).value = selectedTrustList;
      document.getElementById(id).blur();
      trustedListData(selectedTrustList);
    }
  });

  return (
    <React.Fragment>
      <Modal
        className="modal-class"
        containerClassName="test"
        closeOnOuterClick={true}
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}>
        <div>
          <a
            style={closeStyle}
            onClick={() => {
              setShowModal(false);
            }}>
            X
          </a>
          <TrustList
            trustList={trustList}
            useExternalAddress={props.useExternalAddress}
            updateListTrustList={loadMyTrustList}
            setSelectedTrustList={setSelectedTrustList}
            setShowModal={setShowModal}
          />
        </div>
      </Modal>
      <Loading loading={loading} />
      <div>
        <p className='transfer-title'>{context.t(props.useExternalAddress ? "Send {product} to an external wallet." : "Send {product} to Banexcoin clients for free!", {
          product: productSymbol
        })}</p>
      </div>
      <div className="cloud-info">
        <FontAwesomeIcon icon={faInfoCircle} />
        <span>{ context.t('Important! Once you make the request, we will email you a link to confirm the transaction.') }</span>
      </div>
      <div className="button-trust-list">
        <input
          type="button"
          className="add-right-btn"
          value={context.t('My frequent wallets')}
          onClick={() => {
            setShowModal(true);
            loadMyTrustList();
          }}
        />
      </div>
      {
        props.useExternalAddress ? 
        React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'p',
            {
              className: bemClasses('label-text')
            },
            context.t('External wallet address')
          ),
          React.createElement(APInput, {
            name: 'ExternalAddress',
            type: 'text',
            id: 'trust-list-address',
            autoComplete: 'off',
            customClass: bemClasses(),
            onChange: (e) => {
              trustedListData(e.target.value, 'address', [required, alphanumeric, walletStructure]);
            }
          })
        ) : 
        React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'p',
            {
              className: bemClasses('label-text')
            },
            context.t("Recipient's email address")
          ),
          React.createElement(APInput, {
            name: 'ReceiverUsername',
            type: 'text',
            id: 'trust-list-email',
            autoComplete: 'off',
            customClass: bemClasses(),
            onChange: (e) => {
              trustedListData(e.target.value, 'receiveEmail', [required, email, checkUserEmail]);
            }
          })
        )
      }
    </React.Fragment>
  );
};

APCryptoAddressInputComponent.propTypes = Object.assign(
  {
    productSymbol: PropTypes.string.isRequired
  },
  APInput.propTypes
);

APCryptoAddressInputComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(APCryptoAddressInputComponent);
