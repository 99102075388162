import { connect } from 'react-redux';
import {
  fetchingSelector,
  fundedPositionsSelector
} from 'apex-web/lib/redux/selectors/productPositionSelectors';
import WalletCardGridComponent from './WalletCardGridComponent';
import isEmpty from 'is-empty';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  const { productsByLevel } = state
  const products = fundedPositionsSelector(state);
  const newProducts = (!isEmpty(products) && !isEmpty(productsByLevel)) ? products.filter(e => productsByLevel.includes(e.ProductSymbol) && e.AvailableBalance !== "-" && Number(e.AvailableBalance.replaceAll(',', '')) > 0) : [];
  return {
    user: state.user.userInfo,
    fetching: fetchingSelector(state),
    products: newProducts || [],
    detailsLink: ownProps.detailsLink
  };
};

export default connect(mapStateToProps)(WalletCardGridComponent);
