import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import RecentActivityHeaderFormComponent from './RecentActivityHeaderFormComponent';
import { setActivePage } from 'apex-web/lib/redux/actions/paginationActions';

var mapStateToProps = function mapStateToProps(state) {
  var formValue = formValueSelector('recent-activity');

  return {
    endDate: formValue(state, 'endDate')
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setEndDate: function setEndDate(value) {
      return dispatch(change('recent-activity', 'endDate', value));
    },
    clearFilters: function clearFilters() {
      dispatch(change('recent-activity', 'startDate', null));
      dispatch(change('recent-activity', 'endDate', null));
      dispatch(change('recent-activity', 'type', 'all'));
    },
    resetActivePage: function resetActivePage() {
      return dispatch(
        setActivePage({
          componentName: 'RecentActivityComponent',
          activePage: 0
        })
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentActivityHeaderFormComponent);
