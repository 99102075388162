import React, { useState, useEffect } from 'react'
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { connect } from 'react-redux';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';
import Banexcard from '../../images/Banexcard.png'
import Arrow from '../../images/bnx-icons/arrow.svg'
import Copy from '../../images/bnx-icons/copy.svg'
import Eye from '../../images/eye.svg'
import EyeSlash from '../../images/eye-slash.svg'
import Shield from '../../images/bnx-icons/shield.svg'
import BackArrow from '../../images/back-arrow.svg'
import Reload from '../../images/reload.svg'
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import './cardPage.css'
import { Switch } from '../../components/Switch/Switch';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { Spinner } from '../../components/Spinner/Spinner';
import moment from 'moment-timezone';
import isEmpty from 'is-empty';
import PropTypes from 'prop-types';

const CardPage = (props, context) => {

    const { loadingBanexCard, banexcardInfo = {}, loadingTransactions, getTransactions, reloadTransactions, getCardInfo } = props;
    const { cardInfo = {}, balance, transactions } = banexcardInfo

    const initialTimer = 120;
    const cardNumber = cardInfo.pan;
    const expirationDate = moment(cardInfo.expirationDate).utc().format('MM/YY');
    const securityCode = cardInfo.dynamicCvv;

    const creditLimit = balance.creditLimit;
    const availableBalance = balance.availableBalance;
    const consumedBalance = creditLimit - availableBalance;

    const [showData, setShowData] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [timer, setTimer] = useState(initialTimer);
    const [activateOnlinePurchases, setActivateOnlinePurchases] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [textCopyTooltip, setCopyTextTooltip] = useState(false);

    const convertNumberToMinutes = (number) => {
        const hours = Math.floor(number / 60);
        const minutes = number % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }

    const handleShowData = () => {
        setShowData((prevShowData) => !prevShowData);
        setTimer(initialTimer);
    };

    const handleCopyData = () => {
        let timeout;
        if (!textCopyTooltip) {
            copyToClipboard(cardNumber)
            setCopyTextTooltip(true)
            timeout = setTimeout(() => {
                setCopyTextTooltip(false);
            }, 3000)
        }
        return () => clearTimeout(timeout);
    };

    const handleActivateOnlinePurchases = () => setActivateOnlinePurchases(active => !active);

    const formatCardNumber = (cardNumber = "") => {
        return cardNumber.replace(/\d{4}(?=.)/g, '$& ');
    }

    useEffect(() => {
        let interval;
        if (showData) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 0) {
                        setShowData(false);
                        return initialTimer;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [showData]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            getCardInfo()
        }, initialTimer * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='card-page'>
            {loadingBanexCard ? <Spinner color='orange' /> :
                <div className='card-container'>
                    <div className='card-space'>
                        <h2>Banexcard</h2>
                        <img src={Banexcard} alt="Banexcard" />
                        <div className='card-details'>
                            <div className='card-section-info main-details'>
                                <div className='detail-card'>
                                    <p className='user-name'>{cardInfo.cardHolderName}</p>
                                    <p className='user-card-number'>{showData ? formatCardNumber(cardNumber) : '**** **** **** ****'}</p>
                                    <div>
                                        <p>{context.t('Expiry date')} <span>{showData ? expirationDate : '*****'}</span></p>
                                        <p>{context.t('CVC')} <span>{showData ? securityCode : '***'}</span></p>
                                    </div>
                                </div>
                                <div className='actions-card'>
                                    <div>
                                        {showData && (
                                            <p className='counter-card'>{convertNumberToMinutes(timer)}</p>
                                        )}
                                    </div>
                                    <div className='list-btn-card'>
                                        {showData ?
                                            <div>
                                                <OverlayTrigger
                                                    key={'copy-tooltip'}
                                                    placement={'top'}
                                                    show={showTooltip}
                                                    overlay={
                                                        <Tooltip id={`tooltip-copy`} className={`tooltip-user ${textCopyTooltip ? 'tooltip-copy' : ''}`}>
                                                            <div className="tooltip-container">{context.t(textCopyTooltip ? 'Copied!' : 'Copy data')}</div>
                                                        </Tooltip>
                                                    }>
                                                    <button onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onClick={handleCopyData}><img src={Copy} alt="Copy" /></button>
                                                </OverlayTrigger>
                                            </div> : null
                                        }
                                        <OverlayTrigger
                                            key={'copy-tooltip'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-copy`} className="tooltip-user">
                                                    <div className="tooltip-container">{context.t(showData ? 'Hide info' : 'Show info')}</div>
                                                </Tooltip>
                                            }>
                                            <button onClick={handleShowData}><img src={showData ? EyeSlash : Eye} alt="Eye" /></button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className='card-section-info card-with-icon' onClick={() => { setShowHistory(true); getTransactions() }}>
                                <div><img src={Shield} alt="Shield" /></div>
                                <p>{context.t('Transactions')}</p>
                                <span>|</span>
                                <img src={Arrow} alt="Arrow" />
                            </div>
                            {/* <div className='card-section-info card-with-icon last-info'>
                                <div><img src={Shield} alt="Shield" /></div>
                                <p>{context.t('Online Payment')}</p>
                                <Switch
                                    defaultChecked={activateOnlinePurchases}
                                    onChange={handleActivateOnlinePurchases}
                                    id='switch-toggle-bnx-purchase'
                                />
                            </div> */}
                        </div>
                    </div>
                    <MovementsBanex
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                        consumedBalance={consumedBalance}
                        availableBalance={availableBalance}
                        transactions={transactions}
                        creditLimit={creditLimit}
                        loadingTransactions={loadingTransactions}
                        reloadTransactions={reloadTransactions}
                        context={context}
                    />
                </div>
            }
            <PageFooterLayout />
        </div>
    )
}

const MovementsBanex = ({ showHistory, setShowHistory, consumedBalance, availableBalance, transactions = [], creditLimit, loadingTransactions, reloadTransactions, context }) => {

    const porcentaje = (consumedBalance / creditLimit) * 100;

    return (
        <div div className={`card-space right-transition ${showHistory ? 'show-component' : ''}`}>
            <div className='back-arrow-card' onClick={() => setShowHistory(false)}>
                <img src={BackArrow} alt="Back arrow" />
            </div>
            <OverlayTrigger
                key={'reload-tooltip'}
                placement={'top'}
                overlay={
                    <Tooltip id={`reload-copy`} className="tooltip-user">
                        <div className="tooltip-container">{context.t('Update transactions')}</div>
                    </Tooltip>
                }>
                <div className='card-reload' onClick={() => reloadTransactions()}>
                    <img src={Reload} alt="Reload" />
                </div>
            </OverlayTrigger>
            <h2>{context.t('Banexcard Transactions')}</h2>
            <div className='ammount-section'>
                <div>
                    <div>
                        <p>{context.t('Spent')}</p>
                        <p className='amount'>S/ {formatNumberToLocale(consumedBalance, 2)}</p>
                    </div>
                    <div>
                        <p>{context.t('Available')}</p>
                        <p className='amount'>S/ {formatNumberToLocale(availableBalance, 2)}</p>
                    </div>
                </div>
                <div className='line'>
                    <div style={{ width: `${porcentaje}%` }}></div>
                </div>
            </div>
            <p className='approved-line'>{context.t('Credit Limit')}: S/ {formatNumberToLocale(creditLimit, 2)}</p>
            <div className={`transaction-list ${transactions.length > 4 ? 'more-item' : ''}`}>
                {loadingTransactions ? <div className='loader-transaction'>
                    <Spinner color='orange' />
                    <p>{context.t('Loading transactions')}</p>
                </div> : isEmpty(transactions) ? <div>{context.t('No transactions recorded')}</div> :
                    transactions.map((transaction, key) =>
                        <div className='transaction-item' key={key}>
                            <div>
                                <span />
                                <p>{transaction.merchantName}</p>
                                <p>- S/ {formatNumberToLocale(transaction.totalAmount, 2)}</p>
                            </div>
                            <hr />
                            <div>
                                <p>{context.t('Date')}</p>
                                <p>{moment(transaction.transactionDate).utc().format('YYYY-MM-DD hh:mm:ss a')}</p>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

CardPage.contextTypes = {
    t: PropTypes.func
};

export default connect(null, null)(CardPage);
