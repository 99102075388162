import empty from 'is-empty';

export let accountTypes = [
  {
    type: 'SAVINGS_ACCOUNT',
    name: 'Savings Account'
  },
  {
    type: 'CURRENT_ACCOUNTS',
    name: 'Current Accounts'
  }
];

export let transferTypes = [
  {
    type: 'NATIONAL',
    name: 'National',
    pathEdit: '/settings/accounts/edit/pe/'
  },
  {
    type: 'INTERNATIONAL',
    name: 'International with intermediary bank',
    pathEdit: '/settings/accounts/edit/international/'
  },
  {
    type: 'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK',
    name: 'International without intermediary bank',
    pathEdit: '/settings/accounts/edit/international/'
  },
  {
    type: 'DOMESTIC_TRANSFER_US',
    name: 'Domestic transfer USA',
    pathEdit: '/settings/accounts/edit/us/'
  },
  {
    type: 'DOMESTIC_TRANSFER_US_WITH_BANK',
    name: 'Domestic transfer USA with intermediary bank',
    pathEdit: '/settings/accounts/edit/us/'
  }
];
export let bankLogos = [
  {
    name: 'SCOTIABANK',
    image: '/local/landing-page/images/banks/scotiabank.png'
  },
  {
    name: 'INTERBANK',
    image: '/local/landing-page/images/banks/interbank.png'
  },
  {
    name: 'BBVA',
    image: '/local/landing-page/images/banks/Bbvacontinentallogo.png'
  },
  {
    name: 'BCP',
    image: '/local/landing-page/images/banks/bcp.png'
  },
  {
    name: 'BANCO DE CREDITO DEL PERU',
    image: '/local/landing-page/images/banks/bcp.png'
  },
  {
    name: 'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.)',
    image: '/local/landing-page/images/banks/alfin_logo.png'
  }
];

export const banks_lengths_peru = [
  {
    name: 'SCOTIABANK',
    lengths: [10, 14]
  },
  {
    name: 'INTERBANK',
    lengths: [13]
  },
  {
    name: 'BBVA',
    lengths: [18]
  },
  {
    name: 'BCP',
    lengths: [14, 13],
    byTypes: ['SAVINGS_ACCOUNT', 'CURRENT_ACCOUNTS']
  },
  {
    name: 'BANCO DE CREDITO DEL PERU',
    lengths: [14, 13],
    byTypes: ['SAVINGS_ACCOUNT', 'CURRENT_ACCOUNTS']
  }
];

export const validPeruvianAccountNumber = (
  accountNumber,
  accountBank = '',
  type = null
) => {
  let isValid = false;
  let isTypeNeeded = false;
  let lengthNeeded = '';
  let compareType = '';
  let length = accountNumber.trim().length;
  let search = banks_lengths_peru.find(v => {
    let bname = accountBank.toLowerCase();
    let vbname = v.name.toLowerCase();
    return bname.includes(vbname);
  });

  if (!empty(search)) {
    if (!empty(type) && !empty(search.byTypes)) {
      let index = search.byTypes.findIndex(l => l === type);
      isTypeNeeded = true;
      lengthNeeded = `${search.lengths[index]}`;
      compareType = '';
      if (index >= 0 && search.lengths[index] === length) {
        isValid = true;
      }
    } else {
      lengthNeeded = search.lengths.join(', ').replace(/, ([^,]*)$/, ' or $1');
      compareType = '';
      if (search.lengths.includes(length)) {
        isValid = true;
      }
    }
  } else {
    if (length <= 20) {
      isValid = true;
    } else {
      lengthNeeded = `20`;
      compareType = 'less than or equal';
    }
  }
  return { isValid, isTypeNeeded, lengthNeeded, compareType };
};
