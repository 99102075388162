import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import ReceiveFormContainer from '../ReceiveForm/ReceiveFormContainer';
import ReceiveAddressContainer from '../ReceiveAddress/ReceiveAddressContainer';
import './Receive.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var bemClasses = getBEMClasses('receive');

var ReceiveComponent = (function(_React$Component) {
  _inherits(ReceiveComponent, _React$Component);

  function ReceiveComponent() {
    _classCallCheck(this, ReceiveComponent);

    return _possibleConstructorReturn(
      this,
      (
        ReceiveComponent.__proto__ || Object.getPrototypeOf(ReceiveComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(ReceiveComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          product = _props.product,
          useExternalAddress = _props.useExternalAddress,
          toggleUseExternalAddress = _props.toggleUseExternalAddress,
          onChange = _props.onChange,
          onSubmit = _props.onSubmit,
          disableDeposit = _props.disableDeposit,
          verificationOnClick = _props.verificationOnClick,
          allowTransfer = _props.allowTransfer;

        return React.createElement(
          'section',
          { className: bemClasses() },
          React.createElement(
            'header',
            { className: bemClasses('source') },
            React.createElement(
              'div',
              {
                className: bemClasses('source-item', {
                  active: useExternalAddress
                }),
                onClick: function onClick() {
                  !useExternalAddress && toggleUseExternalAddress();
                }
              },
              this.context.t('External wallet')
            ),
            React.createElement(
              'div',
              { className: bemClasses('source-item-with-border') },
              allowTransfer &&
                React.createElement(
                  'div',
                  {
                    className: bemClasses('source-item', {
                      active: !useExternalAddress
                    }),
                    onClick: function onClick() {
                      useExternalAddress && toggleUseExternalAddress();
                    }
                  },
                  this.context.t('Banexcoin transfer')
                )
            )
          ),
          React.createElement(
            'section',
            { className: bemClasses('body') },
            disableDeposit
              ? React.createElement(VerificationRequiredContainer, {
                  disabled: disableDeposit,
                  onClick: verificationOnClick
                })
              : useExternalAddress
                ? React.createElement(ReceiveAddressContainer, {
                    product: product
                  })
                : React.createElement(ReceiveFormContainer, {
                    product: product,
                    onChange: onChange,
                    onSubmit: onSubmit
                  })
          )
        );
      }
    }
  ]);

  return ReceiveComponent;
})(React.Component);

ReceiveComponent.propTypes = {
  product: product,
  useExternalAddress: PropTypes.bool.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  disableDeposit: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

ReceiveComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveComponent;
