import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import APIcon from '../../common/APIcon';

import './SendReceiveConfirmHeader.css';

var bemClasses = getBEMClasses('send-receive-confirm-header');

var SendReceiveConfirmHeader = function SendReceiveConfirmHeader(
  props,
  context
) {
  var isSend = props.isSend,
    _props$product = props.product,
    ProductFullName = _props$product.ProductFullName,
    ProductSymbol = _props$product.ProductSymbol;

  var headerText = isSend
    ? context.t('Send') + ' ' + ProductSymbol + ' ' + ProductFullName
    : ProductSymbol + ' ' + ProductFullName + ' ' + context.t('Request');
  return React.createElement(
    'header',
    { className: bemClasses() },
    React.createElement(APIcon, {
      name: isSend ? 'sendWithBorder' : 'receiveWithBorder',
      customClass: bemClasses('icon')
    }),
    React.createElement('span', { className: bemClasses('title') }, headerText)
  );
};

SendReceiveConfirmHeader.propTypes = {
  product: PropTypes.object.isRequired,
  isSend: PropTypes.bool.isRequired
};

SendReceiveConfirmHeader.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveConfirmHeader;
