import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  Form,
  Dropdown,
  DropdownButton,
  ButtonGroup
} from 'react-bootstrap';
import empty from 'is-empty';

const FormSelector = (props, context) => {
  const {
    label,
    labelInside,
    required,
    name,
    id,
    onChange,
    options,
    defaultOption,
    value,
    readOnly,
    disabled,
    errors,
    className = ''
  } = props;
  try {
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      as: 'select',
      required: required ? true : false,
      name,
      id,
      onChange,
      value,
      readOnly,
      disabled,
      className: `${empty(value) ? 'selectOption-select' : ''} ${className}`
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field nooneline' +
          (!empty(errors) && !empty(errors[name]) ? ' invalid' : ' valid') +
          (!empty(labelInside) && !empty(value) ? ' inside-container' : '')
        }>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)} {required ? <b className="important">*</b> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        {!empty(labelInside) ? (
          <div className="label-inside">{context.t(labelInside)}</div>
        ) : (
          ''
        )}
        <Form.Control {...propInput}>
          <option value="">
            {!empty(defaultOption)
              ? context.t(defaultOption)
              : context.t('Select Option')}
          </option>
          {!empty(options) &&
            Array.isArray(options) &&
            options.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </Form.Control>
        {!empty(errors) && !empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormSelector', error);
    return <React.Fragment />;
  }
};

FormSelector.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormSelector;
