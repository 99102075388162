import HandlerBackend from "../../../helpers/HandlerBackend";

export const getAccountDeleteReasons = async (userId, language) => {
   try {
      const path = `/api/v1/delete-reasons/${language}`;
      const configRequest = {
         userId,
         method: 'get',
         path
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? response.data.data : [];
   } catch (error) {
      return false;
   }
};

export const postDeleteAccount = async (userId, data) => {
   try {
      const path = '/api/v1/request-delete';
      const configRequest = {
         userId,
         method: 'post',
         path,
         data
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? response.data.success : false;
   } catch (error) {
      return false;
   }
};

export const getCommercialCampaigns = async (userId) => {
   try {
      const path = `/api/users/commercial-campaigns`;
      const configRequest = {
         userId,
         method: 'get',
         path
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status')) ? response.data.data : {};
   } catch (error) {
      return false;
   }
};

export const patchCommercialCampaigns = async (userId, data) => {
   try {
      const path = `/api/users/commercial-campaigns`;
      const configRequest = {
         userId,
         method: 'patch',
         path,
         data
      };
      const response = await HandlerBackend.sendRequest(configRequest);
      return (response.hasOwnProperty('status'));
   } catch (error) {
      return false;
   }
};