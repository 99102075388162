export const createValidation = (payload) => {
   return {
      type: "CREATE_STATE_VALIDATION",
      payload
   };
};
export const updateValidation = (payload) => {
   return {
      type: "UPDATE_STATE_VALIDATION",
      payload
   };
};
