import { connect } from 'react-redux';
import ScheduledReportsTableComponent from './ScheduledReportsTableComponent';
import { cancelReport } from 'apex-web/lib/redux/actions/reportActions';
import { cancelableScheduledReportsSelector } from 'apex-web/lib/redux/selectors/reportSelectors';

var mapStateToProps = function mapStateToProps(state) {
  var reports = cancelableScheduledReportsSelector(state);
  var isLoading = state.report.isLoading;

  return {
    reports: reports,
    isLoading: isLoading
  };
};

var mapDispathToProps = {
  cancelReport: cancelReport
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ScheduledReportsTableComponent);
