import React, { useState } from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { updateTrustList } from './TrustListHooks';
import { Table, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import Dialog from 'react-bootstrap-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TrustListAll = (props, context) => {
  const [loading, setLoading] = useState(0);
  const { userInfo, trustList } = props;

  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }

  const loadRows = () => {
    let rows = [];

    if (empty(trustList)) {
      return (
        <tr>
          <td colSpan="14">{this.props.loading > 0 ? context.t('Updating information...') : ''}</td>
        </tr>
      );
    }

    for (let k in trustList) {
      rows.push(
        <tr key={k}>
          <td className="align-center">{trustList[k].cryptoCode}</td>
          <td>{context.t(trustList[k].operation)}</td>
          <td>{trustList[k].label}</td>
          <td>{trustList[k].fieldOperation}</td>
          <td className="align-center">{loadOptionsDelete(trustList[k])}</td>
        </tr>
      );
    }

    return rows;
  };

  const confirmDelete = async id => {
    const r = window.confirm(
      context.t('Do you really want to delete the record?')
    );

    if (r === true) {
      setLoading(1);
      const requestUpdateTrustList = { status: false };
      await updateTrustList(userInfo.UserId, id, requestUpdateTrustList);
      props.attributeUpdateTrusList();
      setLoading(0);
    }
  };

  const loadOptionsDelete = row => {
    return (
      <OverlayTrigger
        key={row.id}
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-${row.id}`}>
            {context.t('Delete trust list')}
          </Tooltip>
        }>
        <Button
          variant="info"
          onClick={async () => {
            confirmDelete(row.id);
          }}>
          <FontAwesomeIcon icon="minus-circle" />
        </Button>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <Loading loading={loading} />
      <Dialog />
      <Card>
        <Card.Body>
          {!empty(trustList) ? (
            <Table
              bordered
              hover
              responsive
              className="table-withdrawalsAddress">
              <thead>
                <tr>
                  <th className="align-center">{context.t('Crypto code')}</th>
                  <th className="align-center">{context.t('Operation')}</th>
                  <th className="align-center">{context.t('Label')}</th>
                  <th className="align-center">
                    {context.t('Address / Email')}
                  </th>
                  <th className="align-center">{context.t('Options')}</th>
                </tr>
              </thead>
              <tbody>{loadRows()}</tbody>
            </Table>
          ) : (
            <p>{context.t('There are no records to show')}</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

TrustListAll.contextTypes = {
  t: PropTypes.func.isRequired
};
const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(TrustListAll);
