import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SignupNaturalCoinkController from './SignupNaturalCoinkController'
import { Link, useHistory, useLocation } from 'react-router-dom'
import isEmpty from 'is-empty'
import { Col, Modal, Row } from 'react-bootstrap'
import diamond from '../../../../images/diamond.png'
import diamond2 from '../../../../images/diamond2.png'
import signUpNaturalBg from '../../../../images/signup-natural.png';
import BoxContent from '../BoxContent'
import SignupNaturalCoinkForm from './SignupNaturalCoinkForm'
import LoadingBnx from '../../../../components/LoadingBnx'
import { toast } from 'react-toastify'

const statuses = {
	loading: 'loading',
	error: 'error',
	success: 'success',
}
function SignupNaturalCoinkContainer(_, context) {
	const location = useLocation()
	const history = useHistory()
	const [status, setStatus] = useState(statuses.loading)
	const [loading, setLoading] = useState(false)
	const [successScreen, setSuccessScreen] = useState(false)
	const [data, setData] = useState('')

	const handleGetDataByCoinkToken = async (coinkToken) => {
		setStatus(statuses.loading)
		const response = await SignupNaturalCoinkController.getDataByCoinkToken({ coinkToken })
		if (!response || !response.success || response.userAction !== "PASSWORD_CREATE") {
			setStatus(statuses.error)
		}else{
			setStatus(statuses.success)
			setData(response.data)
		}
	}
	const getTokenFromUrl = () => {
		const queryParams = new URLSearchParams(location.search)
		const coinkToken = queryParams.get('t')
		return coinkToken
	}
	const handleSignup = async (inputs) => {
		setLoading(true)
		const token = getTokenFromUrl()
		const response = await SignupNaturalCoinkController.coinkSignupNatural({data: inputs, token })
		setLoading(false)
		if(!response){
			return toast.warn(context.t("An error occurred while trying to register your data. Please try again."), {
				position: toast.POSITION.TOP_CENTER
			});
		}
		setStatus(statuses.success)
		setSuccessScreen(true)
	}

	useEffect(() => {
		const coinkToken = getTokenFromUrl()
		if (isEmpty(coinkToken)) {
			history.push("/signup/natural")
		}
			handleGetDataByCoinkToken(coinkToken)
			window.localStorage.setItem("language", "es")
	}, [])

	if (status === statuses.loading) return <LoadingBnx loading={statuses.loading === status ? 1 : 0}/>
	if (status === statuses.error) return  <div className="bnxv2-box-rounded fix-height problem-login have-account-message">
	<div>
	<React.Fragment>
		<Row className="justify-content-center text-center mt-0 mb-0">
		  <Col xl="10">
			<h1
			  className="subtitle-form"
			  dangerouslySetInnerHTML={{
				__html: context.t("You may already have an active registration on our platform."),
			  }}
			/>
		  </Col>
		</Row>
		<Row className="justify-content-center text-center mt-5 mb-0">
			<Link
			  to="/login"
			  className="v2-field btn-bnxv2 submit-button btn btn-dark"
			>
			  {context.t("Go to login")}
			</Link>
		</Row>
	  </React.Fragment>
	</div>
  </div>
	if (successScreen) return <React.Fragment>
		<Modal
			show={true}
			centered
		>
			<div className='app-modal-information success-register'>
				<h1 className='header-modal-title'>{context.t('Successful registration!')}</h1>
				<p 
                	dangerouslySetInnerHTML={{
                    __html: context.t("<b>We've sent you an email</b> with instructions to activate your account. Follow the steps and join Banexcoin to start enjoying our services.")
                	}}
              	/>
				<Link className="btn btn-dark v2-field btn-bnxv2" to="/login">
					{context.t('I already activated my account')}
				</Link>
			</div>
		</Modal>
	</React.Fragment>
	return (
		<BoxContent
			leftChildren={<img src={signUpNaturalBg} className="portal-img no-tablet" />}
			leftClassName={'no-tablet'}
			rightChildren={
				<div className="bnxv2-box-rounded fix-height">
					<img className="diamond" src={diamond} alt="aux diamond background" />
					<img
						className="diamond2"
						src={diamond2}
						alt="aux diamond background"
					/>
					<SignupNaturalCoinkForm signUp={handleSignup} loading={loading} data={data}/>
					<Row className="justify-content-center text-center mt-2 link-register-bottom">
						<Col xl="10" className="bottom-box-v2 mt-4 mb-4">
							<span className='title'>{context.t('Do you already have an account?')}{' '}</span>
							<Link to="/login">{context.t('Login')}</Link>
						</Col>
					</Row>
				</div>}
			monoSize="5"
			containerClassname="mt-header"
		/>


	)
}

SignupNaturalCoinkContainer.contextTypes = {
	t: PropTypes.func.isRequired
}

export default SignupNaturalCoinkContainer