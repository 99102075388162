import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import QRBanexApp from '../../../images/QR-Banex-app.png';
import "./dropdownUserPublic.css"

const DropdownUserPublic = (props, context) => {
  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <React.Fragment>
      <div className="v2-navbar-bnx">
        <Link to="/login" className='public-auth'>{context.t("Log in")}</Link>
        <Link to="/signup" className='public-auth register'>{context.t("Register")}</Link>
        <div
          className="qr-section visibility"
          onMouseEnter={() => setShowQRCode(true)}
          onMouseLeave={() => setShowQRCode(false)}
        >
          <p className="text-app">{context.t("Download our app")}</p>
          {showQRCode && (
            <div className="qr-container">
              <img src={QRBanexApp} alt="Banex app" />
              <p>{context.t("Download the IOS and Android app")}</p>
            </div>
          )}
        </div>
        <a
          target='_blank'
          href={
            localStorage.getItem("language") === "es"
              ? "https://soporte.banexcoin.com/es-LA/support/home"
              : "https://soporte.banexcoin.com/en/support/home"
          }
          className="text-app link"
        >
          {context.t("Help")}
        </a>
      </div>
    </React.Fragment>
  );
};

DropdownUserPublic.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DropdownUserPublic;
