import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import {
  getCountries,
  getLevelBasicV3,
} from '../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import {
  submitFormBeginnerDataV2,
  resendFormBeginnerDataV2
} from './../KYCV2Hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Alert, Form } from 'react-bootstrap';
import AutoFormV2 from './AutoFormV2';
import { useForm } from '../../../hooks/formHooks';
import { states } from '../../../config/staticWorldContent';
import { province } from '../../../config/staticPeruProv';
import { districts } from '../../../config/staticPeruDist';
import { OcupationsDetails } from '../../../config/staticOcupationDetails';
import {
  getNormalLevelStatus,
  validateField,
  validateLevelNumber
} from './../../../helpers/lib';
import Loading from '../../../components/Loading';
import RecommendationImage from '../../../images/recommendation_face.jpeg'
import isEmpty from 'is-empty';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const KYCV2BeginnerForm = (props, context) => {
  try {
    let {
      userInfo,
      selectedAccountId,
      history,
      providerId,
      startIntervalCheck,
      accounts
    } = props;
    const manualKYC = false; 
    let currentAccount = accounts.find(a => a.AccountId === selectedAccountId);
    let requestedLevel = 11;
    const [loading, setLoading] = useState(1);
    const [countries, setCountries] = useState({});
    const [levelInfo, setLevelInfo] = useState({});
    const [basicLevel, setBasicLevel] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [imagesBlob, setImagesBlob] = useState({});
    const [rucInfo, setRucInfo] = useState('');
    const [rucValidated, setRucValidated] = useState(true);
    const [rucRetry, setRucRetry] = useState(true);
    const [messageData, setMessageData] = useState('');
    const [err, setErr] = useState({});

    const setErrorValidation = inputsData => {
      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputsData);
      customValidate(inputsData, errorsCustom)
      setErr(errorsCustom);
    };

    const customValidate = (inputsData, errorsCustom) => {
      if (!empty(inputsData.middle_name) && (inputsData.middle_name.toLowerCase() === inputsData.first_name.toLowerCase())) {
        errorsCustom.middle_name = true
      } else { delete errorsCustom.middle_name }
      if (inputsData.is_invoice_with_ruc && inputsData.personal_ruc.length <= 10) {
        errorsCustom.personal_ruc = true
      } else { delete errorsCustom.personal_ruc }
    }

    const setBlobByName = (name, blob) => {
      setImagesBlob(imagesBlob => ({
        ...imagesBlob,
        [name]: blob
      }));
    };
    async function setValidate(val) {
      setInputs(inputs => ({
        ...inputs,
        validated: val
      }));
    }

    async function setInputsBasic(basicLevel) {
      let Tempinputs = {
        ...inputs,
        id: basicLevel.id,
        first_name: basicLevel.firstName,
        middle_name: basicLevel.middleName,
        last_name: basicLevel.lastName,
        second_last_name: basicLevel.secondLastName,
        birthday: !empty(basicLevel.birthday)
          ? moment(basicLevel.birthday).format('YYYY-MM-DD')
          : moment().subtract(18, 'years').format('YYYY-MM-DD'),
        nationality: basicLevel.nationality,
        birth_country: basicLevel.birthCountry,
        residence_country: basicLevel.residenceCountry,
        address_main: basicLevel.addressMain,
        building: basicLevel.building,
        apt_number: basicLevel.apt,
        city: basicLevel.city,
        state: basicLevel.state,
        town: basicLevel.town,
        district: basicLevel.district,
        zip_code: basicLevel.zipCode,
        occupation: basicLevel.occupation,
        occupation_details: basicLevel.occupationDetails,
        work_center: basicLevel.workCenter,
        phone: basicLevel.phone,
        is_pep: basicLevel.isPep !== 0,
        billing_country: basicLevel.billingCountry,
        billing_address: basicLevel.billingAddress,
        billing_building: basicLevel.billingBuilding,
        billing_town: basicLevel.billingTown,
        billing_district: basicLevel.billingDistrict,
        billing_apt_number: basicLevel.billingAptNumber,
        billing_state: basicLevel.billingState,
        billing_city: basicLevel.billingCity,
        billing_zip: basicLevel.billingZip,
        is_billing_address_residence: false,
        document_type: basicLevel.documentType,
        document_country: basicLevel.documentCountry,
        document_number: basicLevel.documentNumber,
        document_data: basicLevel.documentData,
        back_document_data: basicLevel.backDocumentData,
        faces_images: basicLevel.facesImages,
        status: basicLevel.status,
        edit_fields: basicLevel.editFields,
        user_level: basicLevel.userLevel,
        next_level: basicLevel.nextLevel,
        is_invoice_with_ruc: !!basicLevel.isInvoiceWithRuc,
        personal_ruc: basicLevel.personalRuc || '',
        is_sunat_validating: false,
        pep_document: basicLevel.pepDocument
      };
      setInputs(Tempinputs);

      setErrorValidation(Tempinputs);
    }

    async function loadLevelInfo() {
      setLoading(1);
      try {
        const result = await getLevelBasicV3(
          userInfo.UserId,
          selectedAccountId
        );
        if (
          !empty(result) &&
          !empty(result.data) &&
          !empty(result.data.level_basic)
        ) {
          let level_basic = result.data.level_basic;
          const nextLevel = level_basic.nextLevel;
          if (nextLevel === requestedLevel) {
            setLevelInfo(levelInfo => ({
              ...levelInfo,
              status: getNormalLevelStatus(level_basic.status)
            }));
          }
          const documentFields = ['document_data', 'faces_images', 'back_document_data', 'pep_document']
          const nameField = {
            document_data: 'documentData',
            faces_images: 'facesImages',
            back_document_data: 'backDocumentData',
            pep_document: 'pepDocument'
          }
          let editFields = [];
          if(!empty(level_basic.editFields && level_basic.status === 5)){
            editFields = JSON.parse(level_basic.editFields)
            editFields.forEach(element => {
              if (documentFields.includes(element)) {
                level_basic[nameField[element]] = ''
              }
            });
          }
          setInputsBasic(level_basic);
        }
      } catch (error) {
        let errorMsg = 'Information could not accesible';
        toast.warn(context.t(errorMsg), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }

    /* Obteniendo carga al iniciar */
    const getListCountries = async () => {
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
        return !empty(result);
      } catch (error) {
        console.log('error', error.message);
        if (!error.message.includes('Network Error')) {
          setTimeout(function () {
            getListCountries();
          }, 3000);
        }
      }
    };

    useEffect(
      () => {
        if (
          !empty(userInfo) &&
          !empty(selectedAccountId)
        ) {
          if (
            !empty(currentAccount) &&
            !validateLevelNumber(
              currentAccount.VerificationLevel,
              requestedLevel
            )
          ) {
            toast.warn(
              context.t(
                'Your current level is not valid to request this increase'
              ),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              }
            );
            history.push('/settings/verification-level');
          } else {
            getListCountries();
            loadLevelInfo();
          }
        }
      },
      [userInfo, selectedAccountId, currentAccount]
    );

    /* terminando carga al iniciar */
    const onSubmitCallback = async inputs => {
      setLoading(1);
      try {
        if (empty(err)) {
          if (
            !empty(levelInfo) &&
            !empty(levelInfo.status) &&
            levelInfo.status.hasOwnProperty('id') &&
            levelInfo.status.id === 5
          ) {
            if(!isEmpty(inputs.is_pep)) inputs.is_pep = inputs.is_pep ? 1 : 0;
            const resultResend = await resendFormBeginnerDataV2(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (
              resultResend.hasOwnProperty('data') &&
              !empty(resultResend.data) &&
              resultResend.data.message === 'success'
            ) {
              history.push('/verification/success');
            }
          } else {
            const result = await submitFormBeginnerDataV2(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (result.hasOwnProperty('data')) {
              history.push('/verification/success');
            }
          }
        } else {
          console.log('err:::', err);
        }
      } catch (error) {
        console.error('error on onSubmitCallback', error);
        if (!empty(error.response) && !empty(error.response.data.data)) {
          setMessageData({
            type: 'error',
            text: context.t(error.response.data.data.error_message)
          });
        } else {
          setMessageData({
            type: 'error',
            text: context.t('Information could not be saved')
          });
        }
      }
      setLoading(0);
    };

    const onSubmitSimplewithoutErrors = e => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      let inputForm = {
        ...inputs,
        banexcoin_user_id: userInfo.UserId,
        banexcoin_user_name: userInfo.UserName,
        banexcoin_user_account_id: selectedAccountId,
        email: !empty(userInfo.Email) ? userInfo.Email : '',
        billing_country: inputs.residence_country,
        billing_address: inputs.address_main,
        billing_state: inputs.state,
        billing_building: inputs.building,
        billing_town: inputs.town,
        billing_district: inputs.district,
        billing_apt_number: inputs.apt_number,
        billing_city: inputs.city,
        billing_zip: inputs.zip_code,
        validated: true
      };

      setInputs(inputForm);

      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputForm);
      setErr(errorsCustom);
      if (form.checkValidity() && empty(errorsCustom)) {
        onSubmitCallback(inputForm, form);
      }
    };

    const customValidations = inputs => {
      let errors = {};
      return errors;
    };

    let initialState = {
      validated: true
    };

    const onInputChangeV2 = e => {
      e.persist();
      let inputsTemp = {
        ...inputs,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        validated: true
      };
      setInputs(inputsTemp);
      setErrorValidation(inputsTemp);
    };

    const onInputFileChangeV2 = (e) => {
      const result = onInputFileChange(e);
      setErrorValidation(result);
    }

    const deleteFile = (name) => {
      const inputsTemp = {
        ...inputs,
        [name]: null
      };
      setInputs(inputsTemp);
      setErrorValidation(inputsTemp);
    }

    let {
      inputs,
      errors,
      setInputs,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    let optionsDocument = [];
    if (!empty(inputs) && inputs.nationality === 'PE') {
      optionsDocument = [{ value: 'ID', name: 'National identity document' }];
    } else {
      optionsDocument = [{ value: 'PP', name: 'Passport' }];
    }

    const translate = data => {
      return empty(data)
        ? {}
        : data.map(r => {
          return {
            id: context.t(r.id),
            name: context.t(r.name)
          };
        });
    };

    let steps = [
      {
        title: 'Personal Data',
        hint: [
          {
            p: 'Complete the form with all your personal data'
          },
          {
            p:
              'The higher the level, the higher your limits will be, once you reach the Beginner level you can operate with the following limits.',
            links: [
              {
                title: 'See limits',
                href:
                  'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
              }
            ]
          }
        ],
        groups: [
          {
            title: 'Personal Data',
            fields: [
              {
                label: 'First Name',
                name: 'first_name',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'first_name'
                  }
                ]
              },
              {
                label: 'Middle name',
                name: 'middle_name',
                type: 'text',
                props: [
                  {
                    required: false,
                    autoComplete: 'middle_name'
                  }
                ],
                customError: 'The middle name cannot be the same as the first name'
              },
              {
                label: 'Last Name',
                name: 'last_name',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'last_name'
                  }
                ]
              },
              {
                label: 'Second Last Name',
                name: 'second_last_name',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'second_last_name'
                  }
                ]
              },
              {
                label: 'Main address',
                name: 'address_main',
                type: 'text',
                size: '12',
                props: [
                  {
                    required: true,
                    autoComplete: 'address_main'
                  }
                ]
              },
              {
                label: 'Date of Birth (mm-dd-YYYY)',
                name: 'birthday',
                type: 'date',
                size: '12',
                props: [
                  {
                    required: true,
                    isEighteen: true
                  }
                ]
              }
            ]
          },
          {
            title: '',
            fields: [
              {
                label: 'Are you PEP?(People Exposed Politically)',
                name: 'is_pep',
                type: 'checkbox',
                size: '12',
                props: [
                  {
                    popup: {
                      title: 'Important Information about PEP people!',
                      message:
                        'PEP: You can consider yourself a PEP if you are a natural person who has performed prominent public functions or prominent functions in an international organization in the last 5 years. The Banexcoin Compliance Department will carry out a verification process for each client to know their status if they are PEP or not. '
                    },
                    parentClassName: 'bottom-minus-fix'
                  }
                ]
              }
            ]
          },
          {
            title: '',
            fields: [
              {
                label: 'PEP Document',
                name: 'pep_document',
                type: 'file',
                size: '12',
                subdata: [
                  {
                    type: 'download-text',
                    className: 'important',
                    label:
                      'Upload files .PDF / .JPG / .JPEG / .PNG and no larger than 5MB.',
                    stepTitle: 'Steps to follow',
                    stepTexts: [
                      {
                        text: context.t(
                          "Click <a href='{link}' target='_blank'>here</a> to download the document and complete it",
                          {
                            link: context.t(
                              'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.pdf'
                            )
                          }
                        )
                      },
                      {
                        text: 'Then upload the file to be validated'
                      }
                    ]
                  }
                ],
                displayOn: [
                  {
                    parent: 'is_pep',
                    conditionValue: true
                  }
                ],
                props: [
                  {
                    required: true,
                    accept: '.pdf,.doc,.docx',
                    className: 'big-input'
                  }
                ]
              }
            ]
          },
          {
            title: '',
            fields: [
              {
                label: 'Invoice with my RUC',
                name: 'is_invoice_with_ruc',
                type: 'checkbox',
                size: '12',
                displayOn: [
                  {
                    parent: 'residence_country',
                    conditionValue: 'PE'
                  }
                ],
                props: [
                  {
                    className: 'bottom-minus-fix'
                  }
                ]
              }
            ]
          },
          {
            title: '',
            fields: [
              {
                label: 'Personal RUC (11 digits)',
                name: 'personal_ruc',
                type: 'text',
                size: '12',
                displayOn: [
                  {
                    parent: 'is_invoice_with_ruc',
                    conditionValue: true
                  }
                ],
                props: [
                  {
                    required: true,
                    autoComplete: 'off',
                    placeholder: empty(inputs.personal_ruc)
                      ? 'Please, insert the RUC number'
                      : inputs.is_sunat_validating === true &&
                        inputs.personal_ruc.length == 11
                        ? 'Searching RUC Information, please wait...'
                        : inputs.personal_ruc,
                    maxlength: 11
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    const biometricRecords = {
      title: 'Biometric records',
      fields: [
        {
          label: 'Document Photo (front)',
          name: 'document_data',
          type: 'file',
          filetype: 'image',
          size: '12',
          className: 'asdf',
          subdata: [
            {
              type: 'download-text',
              label: '(Supported formats: .JPG, .JPEG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              required: true,
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
              className: 'important'
            }
          ]
        },
        {
          label: 'Document Photo (Back)',
          name: 'back_document_data',
          type: 'file',
          filetype: 'image',
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG, .JPEG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              required: true,
              accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png',
              className: 'important'
            }
          ]
        },
        {
          label: 'Selfie Photo',
          name: 'faces_images',
          type: 'file',
          filetype: 'image',
          size: '12',
          subdata: [
            {
              type: 'download-text',
              className: 'important',
              label: '(Supported formats: .JPG, .JPEG and .PNG, maximum size 2 MB)'
            }
          ],
          props: [
            {
              required: true,
              accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif',
              className: 'important'
            }
          ],
          additional: `
          <div class='recommendations'> 
            <div>
              <p>${context.t('Recommendations')}</p>
              <ul>
                <li>${context.t('Have adequate lighting, without shadows.')}</li>
                <li>${context.t('The photo must be sharp and the documents legible.')}</li>
                <li>${context.t('Avoid using accessories that cover the face.')}</li>
                <li>${context.t('Avoid covering the face with hair.')}</li>
              </ul>
            </div>
            <div>
              <img src='${RecommendationImage}' alt='Recommendation image'/>
            </div>
          </div>
          `
        },
      ]
    }

    if (manualKYC) {
      steps[0].groups.splice(1, 0, biometricRecords)
    }

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="v2-box w-12">
          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmitSimplewithoutErrors}>
            <AutoFormV2
              inputs={inputs}
              steps={steps}
              onInputChange={onInputChangeV2}
              onInputFileChange={onInputFileChangeV2}
              deleteFile={deleteFile}
              onInputChangeByName={onInputChangeByName}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              imagesBlob={imagesBlob}
              setBlobByName={setBlobByName}
              requestedLevel={requestedLevel}
              setValidate={setValidate}
              messageData={messageData}
              className="col-xl-7"
              isShareholder={true}
              isDisabled={
                !empty(levelInfo.status) ? levelInfo.status.isBlocked : false
              }
              validateField={validateField}
              err={err}
              setErr={setErr}
              title={'<b>Amateur</b> level increase'}
            />
          </Form>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2BeginnerForm error', e);
    return <div>Error:</div>;
  }
};

KYCV2BeginnerForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  null
)(KYCV2BeginnerForm);
