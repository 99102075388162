//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getCountries = async userId => {
  let path = '/api/countries';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getSwiftBanks = async (countryCode, userId) => {
  let path = `/api/swiftbanks/?country_code=${countryCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getFiatAccounts = userId => {
  let path = `/api/fiat-accounts/`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const getCorporateData = (userId, selectedAccountId) => {
  let path = `/api/corporate/${selectedAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const enableFiatAccount = async (id, userId) => {
  let path = `/api/enable-fiat-accounts/?id=${id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const DeleteShareholder = async (id, userId) => {
  let path = `/api/delete-shareholder/?id=${id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let path = '/api/save-fiat-accounts';
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};
