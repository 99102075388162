import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import apex from 'apex-web/lib/apex';
import moment from 'moment';
import { Row, Col, Alert } from 'react-bootstrap';
import Header from './KYCComponent/KYCV2Header';
import './KYCV2Page.css';
import KYCV2BeginnerForm from './KYCComponent/KYCV2BeginnerForm';
import { getKycIntentionStatusHook } from './KYCV2Hooks';
import KYCV2IntermediateForm from './KYCComponent/KYCV2IntermediateForm';
import KYCV2MasterForm from './KYCComponent/KYCV2MasterForm';
import KYCV2VipIndependentForm from './KYCComponent/KYCV2VipIndependentForm';
import KYCV2VipDependentForm from './KYCComponent/KYCV2VipDependentForm';

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts, instruments, level1 };
};

const KYCV2FillPage = (props, context) => {
  try {
    const {
      userInfo,
      selectedAccountId,
      accounts,
      match,
      blocked,
      levelName
    } = props;
    let history = useHistory();
    document.title = `Banexcoin - ${context.t(
      'Complete the form with all your personal data'
    )}`;
    const [isBlocked, setBlocked] = useState(false);
    const [loading, setLoading] = useState(0);
    const [state, setState] = useState({
      transitions: {
        enterRight: `animated enterRight`,
        enterLeft: `animated enterLeft`,
        exitRight: `animated exitRight`,
        exitLeft: `animated exitLeft`,
        intro: `animated intro`
      }
    });

    const onStepChange = stats => {};

    const goToStepByNumber = n => {
      if (empty(state.SW)) {
        setLoading(1);
      } else {
        state.SW.goToStep(n);
        setLoading(0);
      }
    };

    const setInstance = SW => {
      setState({
        ...state,
        SW
      });
    };

    const getIdType = name => {
      let types = [
        {
          name: 'Document of Identity',
          type: 'ID'
        },
        {
          name: 'ID Card',
          type: 'ID'
        },
        {
          name: 'Permanent ID Card',
          type: 'ID'
        },
        {
          name: 'Permanent Resident Card',
          type: 'RP'
        },
        {
          name: 'Residence Permit',
          type: 'RP'
        },
        {
          name: 'Passport',
          type: 'PP'
        },
        {
          name: 'Passport Diplomatic',
          type: 'PP'
        },
        {
          name: 'Passport Emergency',
          type: 'PP'
        },
        {
          name: 'Passport Official',
          type: 'PP'
        },
        {
          name: 'Passport Service',
          type: 'PP'
        },
        {
          name: 'Passport Special',
          type: 'PP'
        },
        {
          name: 'Passport Temporary',
          type: 'PP'
        }
      ];
      let found = types.find(t => t.name === name);
      return !empty(found) ? found.type : 'PP';
    };

    const findDocumentsDataPerPage = pages => {
      let datas = {};
      for (let p in pages) {
        let page = pages[p];
        let ProcessingResult = page.ProcessingResult;
        let documents = page.ProcessingResult.DocumentData2;
        if (
          ProcessingResult.hasOwnProperty('DocumentTypeDescriptor') &&
          empty(datas.DocumentTypeDescriptor)
        ) {
          datas = {
            ...datas,
            DocumentTypeDescriptor: ProcessingResult.DocumentTypeDescriptor
          };
        }

        for (let d in documents) {
          let document = documents[d];
          if (!empty(document) && empty(datas[d])) {
            if (d === 'ExtendedData') {
              for (let dd in document) {
                let dData = document[dd];
                if (!empty(dData) && empty(datas[dd])) {
                  datas = {
                    ...datas,
                    [dd]: dData
                  };
                }
              }
            } else {
              if (
                (document.hasOwnProperty('Value') && !empty(document.Value)) ||
                !document.hasOwnProperty('Value')
              ) {
                datas = {
                  ...datas,
                  [d]: document
                };
              }
            }
          }
        }
      }
      return datas;
    };

    const setProcessedData = async data => {
      let form = findDocumentsDataPerPage(
        data.PageAsSeparateDocumentProcessingReports
      );
      let docInfoDescription = !empty(form) ? form.DocumentTypeDescriptor : {};
      let inputsForm = {
        first_name: !empty(form.FirstName) ? form.FirstName.Value : '',
        middle_name: !empty(form.MiddleName) ? form.MiddleName.Value : '',
        last_name: !empty(form.LastName) ? form.LastName.Value : '',
        second_last_name: !empty(form.MotherName) ? form.MotherName.Value : '',

        birthday: !empty(form.DateOfBirth)
          ? !empty(form.DateOfBirth.Value)
            ? moment(form.DateOfBirth.Value).format('YYYY-MM-DD')
            : null
          : '',
        nationality: !empty(form.Nationality)
          ? !empty(form.Nationality.Value)
            ? form.Nationality.Value.substring(0, 2)
            : null
          : '',
        address_main: !empty(form.Address) ? form.Address.Value : '',

        document_type: !empty(form.DocumentTypeDescriptor)
          ? getIdType(form.DocumentTypeDescriptor.DocumentType)
          : '',
        document_number: !empty(form.DocumentNumber)
          ? form.DocumentNumber.Value
          : '',
        residence_country: !empty(docInfoDescription)
          ? !empty(docInfoDescription.CountryIso3)
            ? docInfoDescription.CountryIso3.substring(0, 2)
            : null
          : '',
        document_country: !empty(docInfoDescription)
          ? !empty(docInfoDescription.CountryIso3)
            ? docInfoDescription.CountryIso3.substring(0, 2)
            : null
          : ''
      };
      return inputsForm;
    };

    const startIntervalCheck = async id => {
      let result = {};
      try {
        let response = await getKycIntentionStatusHook(
          id,
          userInfo.UserId
        ).catch(e => {
          throw e;
        });

        if (!empty(response.callback)) {
          let callBackData = response.callback[0];
          if (
            !empty(callBackData) &&
            callBackData.hasOwnProperty('callbackResponse') &&
            !empty(callBackData.callbackResponse)
          ) {
            result = await setProcessedData(
              callBackData.callbackResponse.resultData
            );
          }
        }
      } catch (error) {
        console.log('Error on startIntervalCheck::', error);
      }
      return result;
    };
    const getAccountData = async () => {
      try {
        let gac = await apex.connection.GetAccountConfig({
          AccountId: selectedAccountId,
          OMSId: 1
        });
        if (!empty(gac)) {
          let verificationProgress = gac.find(a => a.Key === 'KYC_STATUS');
          if (
            !empty(verificationProgress) &&
            verificationProgress.Value === 'BLOCKED'
          ) {
            setBlocked(true);
          }
        }
      } catch (error) {
        console.log('Error on getAccountData', error);
      }
    };

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(selectedAccountId)) {
          getAccountData();
        }
      },
      [userInfo, selectedAccountId]
    );

    let levelsForms = {
      amateur: (
        <KYCV2BeginnerForm
          history={history}
          providerId={match.params.id}
          startIntervalCheck={startIntervalCheck}
        />
      ),
      intermediate: (
        <KYCV2IntermediateForm history={history} providerId={match.params.id} />
      ),
      advanced: (
        <KYCV2MasterForm history={history} providerId={match.params.id} />
      ),
      'master-dependent': (
        <KYCV2VipDependentForm history={history} providerId={match.params.id} />
      ),
      'master-independent': (
        <KYCV2VipIndependentForm
          history={history}
          providerId={match.params.id}
        />
      )
    };

    return (
      <React.Fragment>
        <div className={'v2-bnx lighting' + (blocked ? ' blocked-window' : '')}>
          <Header />
          <div className="container pb-5">
            <Row>
              <Col md={{ span: 6, offset: 3 }} />
            </Row>
            <div className="v2-step">
              {!isBlocked ? (
                !empty(levelsForms[levelName]) ? (
                  levelsForms[levelName]
                ) : (
                  levelsForms['amateur']
                )
              ) : (
                <React.Fragment>
                  <div className="v2-box w-8">
                    <Alert variant={'warning'} className="alert-special-bnx">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: context.t(
                            'Your account is in the process of verification, contact the support service at the following email {link}',
                            {
                              link: `<a href="mailto:soporte@banexcoin.com">soporte@banexcoin.com</a>`
                            }
                          )
                        }}
                      />
                    </Alert>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2FillPage error', e);
    return <div>Error:</div>;
  }
};

KYCV2FillPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCV2FillPage);
