import React from "react";
import PropTypes from "prop-types";
import { TwoFa } from "../../../components";

function TwoFaConfirmation(props, context) {
  const { handleSteps, action } = props;
  return <TwoFa handleSteps={handleSteps} action={action} />;
}

TwoFaConfirmation.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default TwoFaConfirmation;
