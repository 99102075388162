import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import empty from 'is-empty';
import { getAllDeposits, submitDepositAffidavit } from './CryptoHooks';
import CryptoDJForm from './CryptoDJForm';
import moment from 'moment';
import './CryptoDJ.css';

const CryptoDJ = (props, context) => {
  let {
    userInfo,
    selectedAccountId,
    subscribeAccountEvents,
    depositsStatus
  } = props;
  let pendingAmlStatus = 0;
  const [loading, setLoading] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});
  const [tickets, setTickets] = useState([]);
  const [show, setShow] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [blockedReqCode, setBlockedReqCode] = useState('');

  const handleClose = () => {
    setCurrentTicket({});
    setShow(false);
  };
  const handleShow = ticketData => {
    setCurrentTicket({
      id: ticketData.RequestCode,
      currencyName: ticketData.AssetName,
      amount: ticketData.Amount
    });
    setShow(true);
  };

  const getAllAmlTickets = async (AccountId, UserId) => {
    setLoading(true);
    try {
      let gawt = await getAllDeposits(pendingAmlStatus, UserId);
      if (!empty(gawt) && gawt.hasOwnProperty('data') && !empty(gawt.data)) {
        let allTickets = [];
        if (!empty(gawt.data.data)) {
          allTickets = gawt.data.data;
        }
        setTickets(allTickets);
      }
    } catch (error) {
    }
    setLoading(false);
  };

  const subcribeAccountsChanges = async () => {
    let subs = await subscribeAccountEvents({
      AccountId: selectedAccountId,
      OMSId: 1
    });
    setSubscription(subs.Subscribed);
  };

  useEffect(
    () => {
      if (!empty(selectedAccountId) && !subscription) {
        subcribeAccountsChanges();
      }
    },
    [depositsStatus]
  );

  useEffect(
    () => {
      if (!empty(selectedAccountId) && !empty(userInfo) && !loading) {
        getAllAmlTickets(selectedAccountId, userInfo.UserId);
      }
    },
    [selectedAccountId, depositsStatus]
  );
  if (empty(tickets)) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <CryptoDJForm
        show={show}
        handleClose={handleClose}
        currentTicket={currentTicket}
        submitDepositAffidavit={submitDepositAffidavit}
        UserId={userInfo.UserId}
        setBlockedReqCode={setBlockedReqCode}
      />
      <div className="v2-container-top">
        <div className="pending-notification-bar">
          <h2>
            {context.t(
              'To complete the transaction, we ask you to indicate the origin of the funds in your deposit'
            )}
          </h2>
          <Table striped hover responsive className="bnx-v2-table">
            <thead>
              <tr>
                <th />
                <th>{context.t('Created')} (DD-MM-YYYY)</th>
                <th>{context.t('Quantity')}</th>
                <th>{context.t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((t, index) => {
                let depositInfo = {};
                try {
                  depositInfo = !empty(t.DepositInfo)
                    ? JSON.parse(t.DepositInfo)
                    : {};
                } catch (error) {
                  console.log(
                    'error on parse JSON with deposit ticket',
                    t.RequestCode
                  );
                }
                let utcDate = moment(t.LastUpdateTimeStamp);
                return (
                  <tr key={index}>
                    <td>
                      <span className="title">
                        {context.t('Deposited {a}', { a: t.AssetName })}
                      </span>
                      <span className="body">
                        <b>{context.t('Tx Hash')}:</b> {depositInfo.TXId}
                      </span>
                    </td>
                    <td>
                      <b>{utcDate.format('DD-MM-YYYY HH:mm:ss').toString()}</b>
                    </td>
                    <td className="quantity">
                      {t.Amount} {t.AssetName}
                    </td>
                    <td
                      className={
                        'action' +
                        (t.RequestCode === blockedReqCode ? ' disabled' : '')
                      }>
                      <Button variant="bnx" onClick={() => handleShow(t)}>
                        {context.t('Register Affidavit')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

CryptoDJ.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    subscribeAccountEvents: payload =>
      dispatch(callAPI('SubscribeAccountEvents', payload)).then(res => {
        return res;
      })
  };
};

const mapStateToProps = state => {
  let {
    user: { selectedAccountId, userInfo },
    user,
    position: { positions, fetching },
    deposit: { depositsStatus }
  } = state;
  return {
    selectedAccountId,
    userInfo,
    user,
    positions,
    fetching,
    depositsStatus
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CryptoDJ);
