import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { getSwipingConfig, postSwipingConfig } from "./utils";
import { showSnack as _showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import "./SwipingFunds.css";
import { Spinner } from "../components/Spinner/Spinner";
const config = {
  withdrawal: "withdrawal",
  trading: "trading",
};
function SwipingFundsPage(props, context) {
  const { userInfo, showSnack, verificationLevelConfigs = {} } = props;
  const isCoink = verificationLevelConfigs.Level >= 100;
  const [swipingConfigValue, setSwipingConfigValue] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChange = (ev) => {
    showConfirmationModal(ev.target.value);
  };
  const handleUpdate = async (value) => {
    const response = await postSwipingConfig(userInfo, value, context);
    if (response) {
      getSwipingConfig(userInfo, setSwipingConfigValue, context);
      setTimeout(() => {
        showSnack({
        id: 'swiping_funds',
          text: context.t('Automatic funds withdrawal configuration was updated successfully.'),
          type: 'success',
        });
     }, 1);
     return
    }
    setTimeout(() => {
      showSnack({
        id: 'swiping_funds',
        text: context.t('Could not update swiping configuration.'),
        type: 'warning',
      });
    }, 1);
  };

  const handleGetSwipingConfig = async () => {
    if (isCoink) {
      await getSwipingConfig(userInfo, setSwipingConfigValue, context);
      setLoading(false);
    }
  }
  
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    handleGetSwipingConfig()
  }, [userInfo]);

  const showConfirmationModal = (value) => {
    showSnack({
      id: "treatment_data",
      text: context.t(
        "Are you sure you want to update your automatic funds withdrawal settings on Banexcoin?"
      ),
      type: "info",
      buttons: [
        {
          text: context.t("Cancel"),
          className: "button_V2 grey",
        },
        {
          text: context.t("Yes"),
          className: "button_V2 purple",
          onClick: () => handleUpdate(value),
        },
      ],
    });
  };

  if(loading) return <div className="swiping-spinner-container"><Spinner color="orange" /></div>;

  if (isEmpty(swipingConfigValue) || !isCoink) return <div className="swiping-funds"></div>;

  return (
    <div className="swiping-funds">
      <h2>{context.t("Automatic funds withdrawal")}</h2>
      <p>{context.t("Select your automatic withdrawal preference that will apply every Sunday at 23:55 hrs. (it does not affect your cryptocurrencies):")}</p>
      <label htmlFor={config.withdrawal} className="withdrawal-label-first">
        <input
          id={config.withdrawal}
          type="radio"
          value={config.withdrawal}
          checked={swipingConfigValue === config.withdrawal}
          defaultChecked={swipingConfigValue === config.withdrawal}
          onChange={handleChange}
          name="swiping"
          className="input-radio"
          hidden
        />
        <span>{context.t("Automatic withdrawal to my Coink account")}</span>
      </label>
      <p className="description-swiping">{context.t("We automatically transfer your available pesos from Banexcoin to your Coink account.")}</p>
      <label htmlFor={config.trading}>
        <input
          hidden
          id={config.trading}
          type="radio"
          value={config.trading}
          checked={swipingConfigValue === config.trading}
          defaultChecked={swipingConfigValue === config.trading}
          onChange={handleChange}
          className="input-radio"
          name="swiping"
        />
        <span>{context.t("Exchange to USDC")}</span>
      </label>
      <p className="description-swiping">{context.t("We automatically exchange your pesos to USD Coin (USDC) in Banexcoin.")}</p>
        <p className="asterisk-text first">
          *{context.t('If your account in Coink exceeds the deposit limits when choosing "Automatic withdrawal to my Coink account," we will transfer the necessary amount to meet the limit. The excess will be converted to USD Coin in Banexcoin, commissions apply according to our rates.')}
        </p>
        <div className="selector-swiping">
          <p className="asterisk-text">
          {context.t("You can check")}{" "}<a href="http://soporte.banexcoin.com/es-LA/support/solutions/articles/60000599926" target="_blank">{context.t("HERE")}</a>{" "}{context.t("for more details.")}
          </p>{" "}
          <div>
          </div>
        </div>
    </div>
  );
}

SwipingFundsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

SwipingFundsPage.mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    verificationLevelConfigs: state.user.verificationLevelConfigs,
  };
};
SwipingFundsPage.mapDispatchToProps = (dispatch) => {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

export default connect(
  SwipingFundsPage.mapStateToProps,
  SwipingFundsPage.mapDispatchToProps
)(SwipingFundsPage);
