import React, { useEffect, useRef, useState } from "react";
import config from "../../config";
import isEmpty from "is-empty";
import logo from "../../images/logo-banexcoin-white-middleorange.svg";
import InstrumentSelectorContainer from "./Header/InstrumentSelector/InstrumentSelectorContainer";
import InstrumentTableContainer from "./Header/InstrumentDetails/InstrumentTableContainer";
import ChartContainer from "./Chart/ChartContainer";
import OrderEntry from "./OrderEntry/OrderEntryContainer";
import OrdersTableContainer from "./OrdersTable/OrdersTableContainer";
import OrdersBookContainer from "./OrdersBook/OrdersBookContainer";
import "./exchange.css";
import DropdownUserPublic from "./DropdownUserPublic/DropdownUserPublic";
import { getPublicInstruments } from "./utils/getPublicInstruments";
import OrderEntryContainer from "./OrderEntry/OrderEntryContainer";
import iconClose from "../../images/icons/icon-close.svg";
import { useParams } from "react-router-dom";
import RecentTradesContainer from "./RecentTrades/RecentTradesContainer";
function ExchangePage(props, context) {
  const params = useParams()
  const { instruments, selectedInstrumentId, level1, isAuthenticated, selectInstrument } = props;
  const isNotAuthenticated = isAuthenticated === false
  const [publicInstruments, setPublicInstruments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState("0");
  const [activeTab, setActiveTab] = useState("orderBook");
  const buySellModalRef = useRef(null);
  const {
    OrderHistoryData: { usePagination },
    TradingLayout: { maxLines },
  } = config;

  const handlePublicInstruments = async () => {
    const data = await getPublicInstruments()
    setPublicInstruments(data)
  }
  useEffect(() => {
    if(isAuthenticated || !isEmpty(publicInstruments)) return
    handlePublicInstruments()
  }, [isAuthenticated]);

  function getFilter(level1, selectedInstrumentId) {
    const result = [];
    for (let i in level1) {
      if (level1[i].InstrumentId == selectedInstrumentId) {
        result.push(level1[i]);
      }
    }
    return result;
  }
  const orderHistoryConfig = {
    usePagination,
    maxLines,
    filterMode: "selectedInstrument",
  };

  const titleConstructorItem = (data, key) => {
    return !isEmpty(data[0]) && !isEmpty(data[0][key]) ? data[0][key] : "";
  };
  const titlePageConstructor = () => {
    const currentPrice = getFilter(level1, selectedInstrumentId);
    const currentInstrument = instruments ? instruments.filter(
      (instrument) => instrument.InstrumentId === selectedInstrumentId
    ) : [] ;
    if (isEmpty(currentPrice)) return;
    document.title = `${titleConstructorItem(
      currentPrice,
      "BestBid"
    )} | ${titleConstructorItem(currentInstrument, "Symbol")} | ${context.t(
      "Ask"
    )}: ${titleConstructorItem(currentPrice, "BestOffer")}: ${context.t(
      "Last Price"
    )}: ${titleConstructorItem(currentPrice, "LastTradedPx")} | Banexcoin`;
  };

  const handleShowBuySellModal = (type) => {
    setSelectedOrderType(type);
    setShowModal(!showModal);
    document.body.style.overflow = "hidden";
    const frame = document.getElementById("fc_frame");
    if(frame){
      frame.classList.add("display-none");
    }
  }

  const handleCloseBuySellModal = () => {
    setShowModal(false);
    document.body.style.overflow = "auto";
    const frame = document.getElementById("fc_frame");
    if(frame){
      frame.classList.remove("display-none");
    }
  }
  const handleClickOutsideModal = (event) => {
    if(!buySellModalRef.current || buySellModalRef.current.contains(event.target)) return
    handleCloseBuySellModal()
  }

  useEffect(() => {
    titlePageConstructor();
    if(isEmpty(instruments)) return
    const selectedInstrument = instruments.find((instrument) => instrument.Symbol === params.instrument.replace("_","")) || { InstrumentId: 1 }
    selectInstrument(selectedInstrument.InstrumentId)
  }, [instruments]);

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth < 785) return
        handleCloseBuySellModal()
      if(window.innerWidth < 1024){
        const el = document.getElementsByClassName(".menuly")[0]
        if(el){
          el.classList.add("d-none")
        }
      }
    }
    window.addEventListener("resize",handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    }
  },[])

  return (
    <div className="exchange-container">
      <div className={`trading-header ${isNotAuthenticated ? "pt-1" : "-mt-50"}`}>
        <img
          style={isNotAuthenticated ? { display: "block" } : { display: "none" }}
          src={logo}
          alt="bnx logo"
          width={140}
          height={40}
          className="logo-banexcoin-exchange-public"
        />
        <InstrumentSelectorContainer publicInstruments={publicInstruments} />
        <InstrumentTableContainer />
        {isNotAuthenticated && <DropdownUserPublic />}
      </div>
      <div className="exchange-content">
        <div className="first-column">
          <ChartContainer publicInstruments={publicInstruments}/>
        </div>
        <div className="second-column">
          <div className="tabs-orderbook-recent-trades">
            <div className={activeTab === "orderBook" ? "active" : ""}><span  onClick={() => setActiveTab("orderBook")}>{context.t("Order book")}</span></div>
            <div className={activeTab === "recentTrades" ? "active" : ""}><span  onClick={() => setActiveTab("recentTrades")}>{context.t("Recent trades")}</span></div>
          </div>
          {activeTab === "orderBook" ? <OrdersBookContainer /> : <RecentTradesContainer/>}
        </div>
        <div className="third-column">
          <OrderEntry />
        </div>
      </div>
      <div className="orders-content">
        <OrdersTableContainer config={orderHistoryConfig} />
      </div>
      <div className="order-entry-buttons">
        <button onClick={() => handleShowBuySellModal("0")}>{context.t("Buy")}</button>
        <button onClick={() => handleShowBuySellModal("1")}>{context.t("Sell")}</button>
      </div>
      <div onClick={handleClickOutsideModal}  className={`order-entry-modal-backdrop ${showModal ? "active" : ""}`}></div>
        <div className={`order-entry-modal ${showModal ? "show" : ""}`} ref={buySellModalRef}>
          <div className="close-container"><img onClick={handleCloseBuySellModal} src={iconClose} alt="" width={15} height={15}/></div>
          <OrderEntryContainer defaultValue={selectedOrderType}/>
        </div>
    </div>
  );
}


export default ExchangePage
