import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { ReactComponent as ReactLogo } from './../../../images/close_icon.svg';

const KYCV2Header = (props, context) => {
  try {
    const { className, showClose = true } = props;

    return (
      <React.Fragment>
        <div
          className={'header-v2' + (!empty(className) ? ` ${className}` : '')}>
          <div className="container">
            <img
              src="/local/logos/logo-banexcoin-white-middleorange.svg"
              className="logo-v2"
            />
            <Link to="/settings/verification-level/" className="close-v2">
              {showClose && <ReactLogo />}
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2Header error', e);
    return <div>Error:</div>;
  }
};

KYCV2Header.contextTypes = {
  t: PropTypes.func.isRequired
};

export default KYCV2Header;
