import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  Form,
  Dropdown,
  DropdownButton,
  ButtonGroup
} from 'react-bootstrap';
import empty from 'is-empty';

const FormSelectText = (props, context) => {
  const {
    label,
    warning,
    required,
    name,
    id,
    onChange,
    options,
    defaultOption,
    value,
    errors,
    classNameContainer
  } = props;
  try {
    const titleOption = () => {
      let name = (
        <b>
          {context.t(!empty(defaultOption) ? defaultOption : 'Select Option')}
        </b>
      );
      if (!empty(options) && !empty(value)) {
        let found = options.find(o => o.value === value);
        if (!empty(found)) {
          name = (
            <React.Fragment>
              <div className="item-selector-title selected">
                <span className="title">{context.t(found.name)}</span>
                <span className="subtitle">{context.t(found.sub)}</span>
              </div>
              <div className="item-selector-title selected-right">
                <span className="title text-right">
                  {context.t(found.rightname)}
                </span>
                <span className="subtitle text-right">
                  {context.t(found.rightsub)}
                </span>
              </div>
            </React.Fragment>
          );
        }
      }
      return name;
    };
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      as: ButtonGroup,
      required: required ? true : false,
      name,
      onChange,
      title: <div className="flex selected-flex">{titleOption()}</div>
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={'v2-field ' + classNameContainer + (!empty(errors[name]) ? ' invalid' : ' valid')}>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)}
            {!empty(warning) && (
              <p className="warning-red">
                {context.t(warning)}{' '}
                {required ? <b className="important">*</b> : ''}
              </p>
            )}
          </Form.Label>
        ) : (
          ''
        )}
        <DropdownButton {...propInput} className="selected">
          {!empty(options) &&
            options.map((option, index) => (
              <Dropdown.Item
                as="button"
                type="button"
                key={index}
                onClick={() => {
                  let e = {
                    persist: () => {},
                    target: {
                      name: name,
                      value: option.value
                    }
                  };
                  onChange(e);
                }}>
                <div className="item-selector-item text">
                  <div className="item-selector-title">
                    <span className="title">{context.t(option.name)}</span>
                    <span className="subtitle">{context.t(option.sub)}</span>
                  </div>
                  <div className="item-selector-title">
                    <span className="title text-right">
                      {context.t(option.rightname)}
                    </span>
                    <span className="subtitle text-right">
                      {context.t(option.rightsub)}
                    </span>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
        </DropdownButton>
        {!empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormSelectText', error);
    return <React.Fragment />;
  }
};

FormSelectText.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormSelectText;
