import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  getFiatAccountByIdV2,
  getBanksByCountry,
  updateAccountPE
} from '../../components/AccountComponents/AccountHooks';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../../components/Loading';
import { useForm } from '../../hooks/formHooks';
import { accountTypes, validPeruvianAccountNumber } from './accountDefinitions';
import { cleanCustomError } from '../../pages/bnxv2/hooks/signupHooks';
import AddPeFormFields from './Forms/AddPeFormFields';
const accountPageClasses = getBEMClasses('account-page');

const AccountEditPeFormPage = (props, context) => {
  let { userInfo, selectedAccountId, history, match } = props;
  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(1);
  const [allBanks, setAllBanks] = useState([]);
  const [peruvianBanks, setPeruvianBanks] = useState([]);
  const [bankSquare, setBankSquare] = useState({});
  const [generalErrors, setGeneralErrors] = useState({});

  const cleanExtractBanks = (banks = []) => {
    let uniqueBanks = [];
    let squareBanks = {};

    uniqueBanks = banks.reduce((unique, o) => {
      if (!unique.some(obj => obj.bankName === o.bankName)) {
        unique.push(o);
      }
      return unique;
    }, []);

    squareBanks = banks.reduce(function(r, a) {
      r[a.bankName] = r[a.bankName] || [];
      r[a.bankName].push(a);
      return r;
    }, Object.create(null));

    return {
      uniqueBanks,
      squareBanks
    };
  };

  const getListBanksByCountry = async country_code => {
    setLoading(1);
    try {
      let result = await getBanksByCountry(userInfo.UserId, country_code);
      if (
        !empty(result) &&
        result.hasOwnProperty('data') &&
        !empty(result.data.data)
      ) {
        let { uniqueBanks, squareBanks } = cleanExtractBanks(result.data.data);
        setAllBanks(result.data.data);
        setBankSquare(squareBanks);
        setPeruvianBanks(uniqueBanks);
      }
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const onSubmitCallback = async (inputs, form, newErrors) => {
    const errors = customValidations(inputs);
    if(!empty(errors)) return
    setLoading(1);
    try {
      if (empty(newErrors)) {
        let bank_id = allBanks.find(
          b =>
            b.bankName === inputs.beneficiaryBankname &&
            b.city === inputs.bankCity
        );
        let inputFields = {
          banexcoin_account_id: selectedAccountId,
          alias: inputs.alias,
          currency_code: inputs.currencyCode,
          bank_id: bank_id.id,
          bank_city: inputs.bankCity,
          account_type: inputs.accountType,
          account_number: inputs.accountNumber,
          account_number_cci: inputs.accountNumberCci,
          own_account_confirmation: inputs.ownAccountConfirmation
        };
        let save = await updateAccountPE(userInfo.UserId, inputFields, id);
        if (
          !empty(save) &&
          save.hasOwnProperty('data') &&
          !empty(save.data) &&
          save.data.message === 'success'
        ) {
          toast.success(context.t('Account saved successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
          setGeneralErrors({});
          history.push('/settings/accounts');
        }
      }
    } catch (error) {
      console.log('Error on submition', error);
      let msg = '';
      if (
        error.hasOwnProperty('response') &&
        !empty(error.response.data.data)
      ) {
        console.error('error data', error.response.data);
        msg = error.response.data.data.error_message;
      } else {
        console.error('error', error);
        msg = error.message;
      }
      let cleanedError = cleanCustomError(msg);
      setGeneralErrors({
        multiple: cleanedError
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    if (empty(inputs.alias)) {
      errors.alias = 'Required alias field';
    } else {
      if (!empty(inputs.alias.trim())) {
        const re = /^[a-z\d\-_\s]+$/i;
        let validate = re.test(inputs.alias);
        if (!validate) {
          errors.alias =
            'Invalid alias. Only texts, numbers and spaces are accepted';
        } else {
          if (inputs.alias.length > 40) {
            errors.alias = "The alias can't be more than 40 characters";
          }
        }
      } else {
        errors.alias = 'Required alias field';
      }
    }
    if (empty(inputs.currencyCode)) {
      errors.currencyCode = 'Required currency code information';
    } else {
      let currencyCodes = ['USD', 'PEN'];
      if (!currencyCodes.includes(inputs.currencyCode)) {
        errors.currencyCode = 'Invalid currency code entered';
      }
    }
    if (empty(inputs.beneficiaryBankname)) {
      errors.beneficiaryBankname = 'You must select a bank field';
    }
    if (empty(inputs.bankCity)) {
      errors.bankCity = 'Required bank city field';
    }
    if (!empty(inputs.beneficiaryBankname) && !empty(inputs.bankCity)) {
      let bank_id = allBanks.find(
        b =>
          b.bankName === inputs.beneficiaryBankname &&
          b.city === inputs.bankCity
      );
      if (empty(bank_id)) {
        errors.bankCity = 'Invalid bank square';
      }
    }
    if (empty(inputs.accountType)) {
      errors.accountType = 'Required account type field';
    } else {
      let accountTypes = ['CURRENT_ACCOUNTS', 'SAVINGS_ACCOUNT'];
      if (!accountTypes.includes(inputs.accountType)) {
        errors.accountType = 'Invalid account type entered';
      }
    }
    if (
      empty(inputs.accountNumber) ||
      (!empty(inputs.accountNumber) && empty(inputs.accountNumber.trim()))
    ) {
      errors.accountNumber = 'Required Account number field';
    } else {
      const re = /^[0-9]*$/;
      let validate = re.test(inputs.accountNumber);
      if (!validate) {
        errors.accountNumber =
          'Invalid account number. Only numbers are allowed';
      } else {
        let isValid = validPeruvianAccountNumber(
          inputs.accountNumber.trim(),
          inputs.beneficiaryBankname,
          inputs.accountType
        );
        if (!isValid) {
          errors.accountNumber = 'Invalid account number length';
        }
      }
    }
    if (
      empty(inputs.accountNumberCci) ||
      (!empty(inputs.accountNumberCci) && empty(inputs.accountNumberCci.trim()))
    ) {
      errors.accountNumberCci = 'Required Interbank account number field';
    } else {
      const re = /^[0-9]*$/;
      let validate = re.test(inputs.accountNumberCci);
      if (!validate) {
        errors.accountNumberCci =
          'Invalid interbank account number. Only numbers are allowed';
      } else {
        if (inputs.accountNumberCci.length !== 20) {
          errors.accountNumberCci =
            'The interbank account number must be 20 characters long';
        }
      }
    }

    if (!empty(inputs.accountNumber) && !empty(inputs.accountNumberCci)) {
      if (inputs.accountNumberCci === inputs.accountNumber) {
        errors.accountNumberCci =
          'The interbank account number cannot be the same as the account number';
      }
    }

    if (
      empty(inputs.ownAccountConfirmation) ||
      (!empty(inputs.ownAccountConfirmation) &&
        inputs.ownAccountConfirmation !== true)
    ) {
      errors.ownAccountConfirmation =
        'You must declare that it is your account';
    }
    setErrors(errors);
    return errors;
  };

  let {
    inputs,
    errors,
    setErrors,
    onCheckboxChange,
    onSubmitSimple,
    onInputChange,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);

  const getBankAccountInfo = async id => {
    try {
      let dataAccount = await getFiatAccountByIdV2(
        userInfo.UserId,
        selectedAccountId,
        id
      );
      if (
        !empty(dataAccount) &&
        dataAccount.hasOwnProperty('data') & !empty(dataAccount.data.data)
      ) {
        setInputs({
          ...inputs,
          ...dataAccount.data.data
        });
        setId(id);
      }
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
      setLoading(0);
    }
  };

  useEffect(
    () => {
      if (!empty(userInfo) && !empty(userInfo.UserId) && !empty(id)) {
        getListBanksByCountry('PE');
      }
    },
    [userInfo, id]
  );

  useEffect(
    () => {
      if (!empty(match) && !empty(match.params) && !empty(match.params.id)) {
        getBankAccountInfo(match.params.id);
      }
    },
    [match]
  );
  let initialState = {
    validated: false
  };

  document.title = `${context.t('My Banking Accounts')} | ${context.t(
    'Add bank account'
  )} - Banexcoin`;

  let selectedBankSquares =
    !empty(inputs) && !empty(inputs.beneficiaryBankname)
      ? bankSquare[inputs.beneficiaryBankname]
      : [];
  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses() + ' minhfix'}>
        <Form
          noValidate
          className="form-accounting-v2"
          onSubmit={onSubmitSimple}>
          {!empty(inputs) && !empty(inputs.alias) ? (
            <Alert variant={'info'}>
              <div
                className="top-editing-info"
                dangerouslySetInnerHTML={{
                  __html: context.t(
                    'Now you are editing the account <b>{alias}</b>',
                    {
                      alias: inputs.alias
                    }
                  )
                }}
              />
            </Alert>
          ) : (
            ''
          )}

          <AddPeFormFields
            inputs={inputs}
            onInputChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            onCheckboxChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onCheckboxChange(ev)
            }}
            onInputChangeCleanValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            generalErrors={generalErrors}
            errors={errors}
            accountTypes={accountTypes}
            peruvianBanks={peruvianBanks}
            selectedBankSquares={selectedBankSquares}
            goBackPath="/settings/accounts"
          />
        </Form>
      </div>
    </React.Fragment>
  );
};

AccountEditPeFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};

export default connect(mapStateToProps)(AccountEditPeFormPage);
