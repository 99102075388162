import React, { useEffect, useState } from 'react'
import isEmpty from 'is-empty'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getCountries } from '../../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks'
import './increment-level.css'
import banexcardImage from '../../../../images/banexcard-image.png'
import checkIcon from '../../../../images/icons-v2/check.svg'
import CardGradient from '../../components/CardGradient'
import { useForm } from '../../../../hooks/formHooks'
import { validateField } from '../../../../helpers/lib'
import AutoFormV2 from '../../../KYCV2/KYCComponent/AutoFormV2'
import { states } from '../../../../config/staticWorldContent'
import { province } from '../../../../config/staticPeruProv'
import { districts } from '../../../../config/staticPeruDist'
import { setUserInfo } from '../../../../redux/actions/set_user_info'
import { OcupationsDetails, occupations } from '../../../../config/staticOcupationDetails'
import { sanitizeValue } from '../../../utils';
import IncrementLevelController from '../../controllers/IncrementLevelController';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import { Loader } from '../../components';
import { Redirect } from 'react-router-dom';

const IncrementLevelPage = (props, context) => {

  const { loadingBanexCard, userLevel, level_basic, userInfo, setUserInfo, hasBanexCard } = props;
  const ht = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [err, setErr] = useState({});
  const [validateByChange, setValidateByChange] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);
  const [finalResult, setFinalResult] = useState({});
  const [countries, setCountries] = useState([]);
  const [loadingIncrementLevel, setLoadingIncrementLevel] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});
  const [loadingActions, setLoadingActions] = useState(false);

  const initialState = {
    validated: true,
  };

  const {
    inputs,
    setInputs,
    onInputFileChange
  } = useForm(initialState);

  const actions = [
    {
      label: context.t("Accept"),
      action: () => onChangeTerms()
    }
  ]

  const ocupation = occupations.map(({ value, label }) => ({ value, name: label }));

  const ocupationDetails = () => {
    if (!isEmpty(inputs.occupation) && !isEmpty(OcupationsDetails[inputs.occupation])) {
      return OcupationsDetails[inputs.occupation].sort((a, b) => {
        if (a.name === "Other (Specify)") { return 1; }
        else if (b.name === "Other (Specify)") { return -1; }
        else { return a.name.localeCompare(b.name) }
      })
    }
    return [];
  }

  const initialSteps = [
    {
      groups: [
        {
          title: 'Personal Data',
          fields: [
            {
              label: 'First Name',
              name: 'first_name',
              type: 'text',
              props: [
                {
                  required: true,
                  autoComplete: 'first_name'
                }
              ]
            },
            {
              label: 'Middle name',
              name: 'middle_name',
              type: 'text',
              props: [
                {
                  required: false,
                  autoComplete: 'middle_name'
                }
              ],
              customError: 'The middle name cannot be the same as the first name'
            },
            {
              label: 'Last Name',
              name: 'last_name',
              type: 'text',
              props: [
                {
                  required: true,
                  autoComplete: 'last_name'
                }
              ]
            },
            {
              label: 'Second Last Name',
              name: 'second_last_name',
              type: 'text',
              props: [
                {
                  required: true,
                  autoComplete: 'second_last_name'
                }
              ]
            },
            {
              label: 'Date of Birth (mm-dd-YYYY)',
              name: 'birthday',
              type: 'date',
              size: '12',
              props: [
                {
                  required: true,
                  isEighteen: true
                }
              ]
            }
          ]
        },
        {
          title: '',
          fields: [
            {
              label: 'Are you PEP?(People Exposed Politically)',
              name: 'is_pep',
              type: 'checkbox',
              size: '12',
              props: [
                {
                  popup: {
                    title: 'Important Information about PEP people!',
                    message:
                      'PEP: You can consider yourself a PEP if you are a natural person who has performed prominent public functions or prominent functions in an international organization in the last 5 years. The Banexcoin Compliance Department will carry out a verification process for each client to know their status if they are PEP or not. '
                  },
                  parentClassName: 'bottom-minus-fix'
                }
              ]
            }
          ]
        },
        {
          title: '',
          fields: [
            {
              label: 'PEP Document',
              name: 'pep_document',
              type: 'file',
              size: '12',
              subdata: [
                {
                  type: 'download-text',
                  className: 'important',
                  label:
                    'Upload files .PDF / .JPG / .JPEG / .PNG and no larger than 5MB.',
                  stepTitle: 'Steps to follow',
                  stepTexts: [
                    {
                      text: context.t(
                        "Click <a href='{link}' target='_blank'>here</a> to download the document and complete it",
                        {
                          link: context.t(
                            'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.pdf'
                          )
                        }
                      )
                    },
                    {
                      text: 'Then upload the file to be validated'
                    }
                  ]
                }
              ],
              displayOn: [
                {
                  parent: 'is_pep',
                  conditionValue: true
                }
              ],
              props: [
                {
                  required: true,
                  accept: '.pdf,.jpg,.jpeg,.png',
                  className: 'big-input'
                }
              ],
              customError: errorMessages['pep_document'] || ''
            }
          ]
        },
        {
          title: '',
          fields: [
            {
              label: 'Invoice with my RUC',
              name: 'is_invoice_with_ruc',
              type: 'checkbox',
              size: '12',
              displayOn: [
                {
                  parent: 'residence_country',
                  conditionValue: 'PE'
                }
              ],
              props: [
                {
                  className: 'bottom-minus-fix'
                }
              ]
            }
          ]
        },
        {
          title: '',
          fields: [
            {
              label: 'Personal RUC (11 digits)',
              name: 'personal_ruc',
              type: 'text',
              size: '12',
              displayOn: [
                {
                  parent: 'is_invoice_with_ruc',
                  conditionValue: true
                }
              ],
              props: [
                {
                  required: true,
                  autoComplete: 'off',
                  placeholder: 'Please, insert the RUC number',
                  maxlength: 11
                }
              ],
              customError: errorMessages['personal_ruc'] || ''
            }
          ]
        }
      ]
    },
    {
      groups: [
        {
          title: 'Residence Address',
          fields: [
            {
              label: 'Residence address',
              name: 'address_main',
              type: 'text',
              size: '12',
              props: [
                {
                  required: true,
                  placeholder: 'Residence address',
                  autoComplete: 'address_main'
                }
              ]
            },
            {
              label: 'Building number or name',
              name: 'building',
              type: 'text',
              size: '6',
              props: [
                {
                  required: true,
                  placeholder: 'Building number or name',
                  autoComplete: 'building'
                }
              ]
            },
            {
              label: 'Interior # / Apartment #',
              name: 'apt',
              type: 'text',
              size: '6',
              props: [
                {
                  autoComplete: 'apt',
                  required: false
                }
              ]
            },
            {
              label: 'Zonification',
              name: 'town',
              type: 'text',
              size: '6',
              props: [
                {
                  autoComplete: 'town',
                  required: true
                }
              ]
            },
            {
              label: 'Country of Residence',
              name: 'residence_country',
              type: 'select',
              options: countries,
              loaderText: "Seleccione país de residencia",
              props: [
                {
                  required: true
                }
              ]
            },
            {
              label: 'State',
              name: 'state',
              type: 'select',
              options: states[inputs.residence_country],
              loaderText: "Seleccione Estado / región / departamento",
              props: [
                {
                  required: true
                }
              ]
            },
            inputs.residence_country === 'PE'
              ? {
                label: 'City / Province',
                name: 'city',
                type: 'select',
                options: province[inputs.state],
                props: [
                  {
                    required: true
                  }
                ]
              }
              : {
                label: 'City / Province',
                name: 'city',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
            inputs.residence_country === 'PE'
              ? {
                label: 'Municipality / District',
                name: 'district',
                type: 'select',
                options: districts[inputs.city],
                props: [
                  {
                    required: true
                  }
                ]
              }
              : {
                label: 'Municipality / District',
                name: 'district',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
            {
              label: 'Zip Code',
              name: 'zip_code',
              type: 'text',
              size: '6',
              props: [
                {
                  required: true,
                  placeholder: 'Zip Code',
                  autoComplete: 'zip_code'
                }
              ]
            }
          ]
        },
        {
          title: 'Ocupation Data',
          fields: [
            {
              label: 'Occupation',
              name: 'occupation',
              type: 'select',
              options: ocupation,
              props: [
                {
                  required: true
                }
              ]
            },
            {
              label: 'Specify Occupation / Profession',
              name: 'occupation_details',
              type: 'select',
              options: ocupationDetails(),
              props: [
                {
                  required: true
                }
              ]
            },
            {
              label: 'Other',
              name: 'other_occupation',
              type: 'text',
              size: '12',
              displayOn: [
                {
                  parent: 'occupation_details',
                  conditionValue: 'Other'
                }
              ],
              props: [
                {
                  required: true
                }
              ]
            },
            {
              label: 'Company Name',
              name: 'work_center',
              type: 'text',
              size: '12',
              props: [
                {
                  required: true
                }
              ]
            }
          ]
        },
        {
          title: '',
          fields: [
            {
              label: `I have read and accepted the <span class='terms-conditions' id='terms-conditions'>Terms and Conditions</span>`,
              name: 'terms_and_conditions',
              type: 'checkbox',
              size: '12',
              props: [
                {
                  required: true
                }
              ],
              customError: 'You must accept the Terms and Conditions'
            }
          ]
        }
      ]
    }
  ]

  const steps = userLevel > 10 ? [initialSteps[1]] : initialSteps;

  const onChangeTerms = () => {
    const terms = {
      target: {
        name: 'terms_and_conditions',
        type: 'checkbox',
        checked: true
      }
    }
    onInputChangeV2(terms)
    setModalTerms(false)
  }

  const onInputFileChangeV2 = (e) => {
    onInputFileChange(e);
    const newErrors = err;
    if (newErrors.hasOwnProperty(e.target.name)) {
      delete newErrors[e.target.name];
      setErr(newErrors);
    }
  }

  const onInputChangeV2 = (event, text = '') => {
    const name = isEmpty(text) ? event.target.name : event;
    let newValue = text
    if (isEmpty(newValue)) {
      const { value, checked, type } = event.target
      newValue = type === 'checkbox' ? checked : value
      if (name === 'personal_ruc') {
        newValue = newValue.replace(/[^0-9]/g, '')
      }
    }
    const newInputs = { ...inputs, [name]: newValue }
    setInputs(newInputs)
    if (validateByChange) {
      const errors = customValidate(steps[currentStep - 1].groups, newInputs);
      setErr(errors);
    }
  };

  const customValidate = (group, inputs) => {
    setValidateByChange(true)
    const errors = validateField(group, inputs);
    if (currentStep === 1) {
      errors['middle_name'] = !isEmpty(inputs.middle_name) && (inputs.middle_name.toLowerCase() === inputs.first_name.toLowerCase())
      if (inputs.is_invoice_with_ruc) {
        const validateFirstTwoDigits = ['10', '20'].includes(inputs.personal_ruc.substring(0, 2))
        errors['personal_ruc'] = !validateFirstTwoDigits || inputs.personal_ruc.length <= 10
        setErrorMessages({
          ...errorMessages,
          personal_ruc: !validateFirstTwoDigits ? "The format must be a valid RUC number that begins with 10 or 20" : ''
        })
      }
    }
    if (currentStep === 2) {
      errors['terms_and_conditions'] = !inputs.terms_and_conditions
    }
    Object.keys(errors).forEach(key => {
      if (!errors[key]) delete errors[key]
    })
    return errors;
  }

  const onSubmitSimplewithoutErrors = e => {
    e.preventDefault();
    e.stopPropagation();
    const errors = customValidate(steps[currentStep - 1].groups, inputs);
    setErr(errors);
    if (!isEmpty(errors) || loadingActions) return;
    saveInformation()
  };

  const handleFinalResult = (result) => {
    if (result) {
      return setFinalResult({
        type: "success",
        title: "¡Solicitud enviada!",
        message: "Tu solicitud se encuentra en proceso. En aproximadamente 24 a 48 horas recibirás un email informando el estado de la misma.",
        actions: [
          {
            label: context.t("Entendido"),
            action: () => ht.push("/cards/request/success")
          }
        ]
      })
    }
    setFinalResult({
      type: "error",
      title: "",
      message: "Ocurrió un error al momento de procesar su solicitud, por favor intenta nuevamente o escríbenos para ayudarte por el Chat o a soporte@banexcoin.com",
      actions: [
        {
          label: "Intentar nuevamente",
          action: () => setFinalResult({}),
        }
      ],
    })
  }

  const setInputsBasic = (basicLevel) => {
    const inputs = {
      first_name: basicLevel.firstName,
      middle_name: basicLevel.middleName,
      last_name: basicLevel.lastName,
      second_last_name: basicLevel.secondLastName,
      birthday: !isEmpty(basicLevel.birthday) ? moment(basicLevel.birthday).format('YYYY-MM-DD') : moment().subtract(18, 'years').format('YYYY-MM-DD'),
      is_pep: basicLevel.isPep !== 0,
      pep_document: !isEmpty(basicLevel.editFields) && basicLevel.editFields.includes('pep_document') ? '' : basicLevel.pepDocument,
      is_invoice_with_ruc: !!basicLevel.isInvoiceWithRuc,
      personal_ruc: basicLevel.personalRuc || '',
      address_main: basicLevel.addressMain,
      building: basicLevel.building,
      apt: basicLevel.apt,
      town: basicLevel.town,
      residence_country: sanitizeValue(basicLevel.residenceCountry),
      state: basicLevel.state,
      city: basicLevel.city,
      district: basicLevel.district,
      zip_code: basicLevel.zipCode,
      occupation: basicLevel.occupation,
      occupation_details: basicLevel.occupationDetails,
      other_occupation: sanitizeValue(basicLevel.otherOccupation),
      work_center: basicLevel.workCenter,
      terms_and_conditions: false,
      status: basicLevel.status,
      edit_fields: basicLevel.editFields,
      terms_and_conditions: !isEmpty(basicLevel.editFields)
    }
    setInputs(inputs)
  }

  const handleInitialData = async (level_basic) => {
    const countriesList = await getCountries(userInfo.UserId);
    const countries = countriesList.data.countries;
    setCountries(countries)
    setInputsBasic(level_basic)
    setLoadingIncrementLevel(false)
  }

  const handleCurrentStep = (value) => {
    if (value === 2 && level_basic.status !== 5) return saveKYC();
    setCurrentStep(value)
  }

  const saveKYC = () => {
    IncrementLevelController.saveKYC({
      userInfo,
      inputs,
      setCurrentStep,
      setLoadingActions,
      setErrorMessages,
      setErr,
    })
  }

  const saveInformation = () => {
    IncrementLevelController.saveInformation({
      userInfo,
      inputs,
      setLoadingActions,
      setErrorMessages,
      handleFinalResult,
      setUserInfo,
    })
  }

  useEffect(() => {
    setValidateByChange(false)
  }, [currentStep])

  useEffect(() => {
    if (currentStep === 2 && !modalTerms) {
      const termsConditions = document.getElementById('terms-conditions');
      if (!isEmpty(termsConditions)) termsConditions.addEventListener('click', () => setModalTerms(true));
    }
  }, [currentStep, modalTerms])

  useEffect(() => {
    if (!loadingBanexCard && !isEmpty(level_basic)) {
      handleInitialData(level_basic)
    }
  }, [loadingBanexCard, level_basic])

  
  if (loadingBanexCard || loadingIncrementLevel) {
    return <Loader />
  }
  
  if(![0,10,11].includes(userLevel) || hasBanexCard) return <Redirect to="/cards" />
  
  if (!isEmpty(finalResult)) {
    return <div className="increment-level">
      <CardGradient
        type={finalResult.type}
        title={finalResult.title}
        message={finalResult.message}
        actions={finalResult.actions}
      />
    </div>
  }

  return (
    <div className='increment-level'>
      {modalTerms ?
        <TermsAndConditions
          action={onChangeTerms}
          backAction={() => setModalTerms(false)}
        /> :
        <div className='container-increment'>
          <div className='banexcard-benefits'>
            <img src={banexcardImage} alt="Banexcard" width={150} />
            <div className='benefits-title'>{context.t('Benefits')}</div>
            <div className='benefits-info'>
              <p>{context.t('Each transaction improves your credit line')}</p>
              <img src={checkIcon} alt="check icon" width={14} />
            </div>
            <div className='benefits-info'>
              <p>{context.t('Recharge with USD Coin (USDC) from your Banexcoin wallet')}</p>
              <img src={checkIcon} alt="check icon" width={14} />
            </div>
            <div className='benefits-info'>
              <p>{context.t('Security thanks to dynamic CVV')}</p>
              <img src={checkIcon} alt="check icon" width={14} />
            </div>
          </div>
          <CardGradient customClassName="card-increment v2-bnx">
            <StepsComponent steps={steps} currentStep={currentStep} userLevel={userLevel} />
            <h2 dangerouslySetInnerHTML={{ __html: context.t('Request virtual <b>Banexcard</b>') }} />
            <p>{context.t(currentStep === 1 ? 'Complete the following form with your personal information' : 'Complete the following form with your residential address and occupation')}.</p>
            <Form
              noValidate
              validated={inputs.validated}
              encType="multipart/form-data"
              onSubmit={onSubmitSimplewithoutErrors}
              className='v2-box'
            >
              <AutoFormV2
                inputs={inputs}
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={handleCurrentStep}
                onInputChange={onInputChangeV2}
                onInputFileChange={onInputFileChangeV2}
                onInputChangeByName={onInputChangeV2}
                requestedLevel={2}
                className="col-xl-12 inputs-bxn-v2"
                isDisabled={inputs.status === 1}
                validateField={customValidate}
                err={err}
                setErr={setErr}
                isBanexCard={true}
                loadingActions={loadingActions}
              />
            </Form>
          </CardGradient>
        </div>
      }
    </div>
  )
}

const StepsComponent = ({ steps, currentStep, userLevel }) => {
  const otherSteps = userLevel > 10 ? 3 : 2;
  return (
    <div className="steps-bottom steps-bottom-v2">
      {Array.from({ length: steps.length + otherSteps }, (_, index) => index + 1).map((p, i) =>
        <React.Fragment key={i}>
          <span className={`step-bttm ${p === currentStep + otherSteps ? 'active' : p < currentStep + otherSteps ? 'previous' : ''}`}>
            {p < currentStep + otherSteps ? '✔️' : p}
          </span>
          {p < steps.length + otherSteps && <span className="step-line" />}
        </React.Fragment>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { user: { userInfo }, banexCard: { loadingBanexCard, userLevel }, completedUserInfo: { level_basic } } = state;
  return {
    loadingBanexCard,
    userLevel,
    userInfo,
    level_basic
  };
};

const mapDispatchToProps = { setUserInfo };

IncrementLevelPage.contextTypes = {
  t: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(IncrementLevelPage)