import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import path from 'apex-web/lib/helpers/path';

import './VerificationRequiredComponent.css';

const VerificationRequiredComponent = (props, context) => {
  const {
    customClass,
    className,
    disabled,
    isUnderReview,
    verificationLevelPageUrl,
    enableOnClick,
    onVerificationLinkClick,
    onClick,
    customKYCmessage,
    history,
    disableKYCLinkUnderReview
  } = props;

  const noLink = disableKYCLinkUnderReview
    ? isUnderReview
    : verificationLevelPageUrl === '';
  const bemClasses = getBEMClasses(['verification-required', customClass]);
  const handleOnVerificationLinkClick = () => {
    if (verificationLevelPageUrl.match(/^http/)) {
      window.location.href = verificationLevelPageUrl;
    } else {
      history.push(path(verificationLevelPageUrl));
      if (enableOnClick) onVerificationLinkClick();
    }
  };

  if (disabled)
    return (
      <div className={bemClasses('container')}>
        <div
          className={bemClasses(noLink ? 'no-link' : '')}
          onClick={() => {
            if (noLink) return;
            handleOnVerificationLinkClick();
            onClick && onClick();
          }}>
          <APIcon
            name="alert"
            customClass={bemClasses('warning')}
            classModifiers="small"
          />
          <span
            className={`ap--label ${bemClasses('not-verified')} ${className}`}>
            {customKYCmessage
              ? customKYCmessage
              : getVerificationRequiredMessage(context, isUnderReview, noLink)}
          </span>
        </div>
      </div>
    );
  return null;
};

const getVerificationRequiredMessage = (context, isUnderReview, noLink) => {
  if (noLink && isUnderReview)
    return context.t('You are not yet verified. Verification under review.');
  else if (noLink)
    return context.t(
      'You are not yet verified. Verify your identity to start trading.'
    );
  else if (isUnderReview)
    return context.t(
      'Verification under review. Click here to check your verification status.'
    );
  else
    return context.t(
      'Verify your identity to start trading. Click here to verify.'
    );
};

VerificationRequiredComponent.defaultProps = {
  className: '',
  onVerificationLinkClick: () => {},
  isUnderReview: false,
  disabled: false,
  enableOnClick: true,
  disableKYCLinkUnderReview: false
};

VerificationRequiredComponent.propTypes = {
  className: PropTypes.string,
  verificationLevelPageUrl: PropTypes.string.isRequired,
  onVerificationLinkClick: PropTypes.func.isRequired,
  isUnderReview: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  enableOnClick: PropTypes.bool,
  customKYCmessage: PropTypes.string
};

VerificationRequiredComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(VerificationRequiredComponent);
