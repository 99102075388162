import isEmpty from "is-empty";

export default function getInstrumentPrices (props, selectedCurrency, isCoink = false) {
  let currency = selectedCurrency
  if(props.product.ProductSymbol === selectedCurrency){
    currency = !isCoink ? "USD"  : "COP"
  }
  const instrumentBySelectedCurrency = props.instruments.find(el => el.Product1Symbol === props.product.ProductSymbol && el.Product2Symbol === currency);
  const pricing = props.prices[instrumentBySelectedCurrency.InstrumentId]
  if(!isEmpty(pricing)) return pricing
  return false
}