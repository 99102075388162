export const createBanexCard = (payload) => {
    return {
        type: "CREATE_BANEXCARD",
        payload
    };
};
export const updateBanexCard = (payload) => {
    return {
        type: "UPDATE_BANEXCARD",
        payload
    };
};
export const setBanexcardBalanceData = (payload) => {
    return {
        type: "SET_BALANCE_DATA",
        payload
    };
};
export const setBanexcardConfig = (payload) => {
    return {
        type: "SET_BANEXCARD_CONFIG",
        payload
    };
};
