import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import moment from 'moment';
import 'moment/locale/es';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  DropdownButton,
  Button,
  Modal
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/Loading';
import { fixNumber } from '../../utils';

const SuccesBoxSvg = () => {
  return (
    <div className="success-animation">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52">
        <circle
          className="checkmark__circle"
          cx={26}
          cy={26}
          r={25}
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

const PendingPaymentSvg = () => {
  return (
    <div className="success-animation">
      <div className="loader" />
    </div>
  );
};

const EasyBuyPagePrice = (props, context) => {
  try {
    const {
      level1,
      stepModal,
      inputs,
      myCurrentDataForm,
      startPayment,
      newOnClose,
      form,
      show,
      handleClose,
      loading,
      onSimpleChange,
      responsePayment,
      errorMessage,
      newOnCloseOnFinish,
      myCreditCards,
      findPrice
    } = props;
    let language = localStorage.getItem('language') || 'en';
    let amount = !empty(inputs.amount) ? Number(inputs.amount).toFixed(2) : 0.0;
    let fee = !empty(myCurrentDataForm)
      ? amount - Number(myCurrentDataForm.amount_total)
      : 0.0;

    const getSelectedCard = (cards, selected_id) => {
      if (empty(cards) || empty(selected_id)) {
        return '';
      }
      let data = cards.find(c => c.cardIdCircle === selected_id);
      if (empty(data)) {
        return '';
      }
      let payloadCard = JSON.parse(data.payloadResponse);
      return `XXXX-XXXX-XXXX-${payloadCard.last4} (${payloadCard.network})`;
    };

    const disabledVal = inpt => {
      let errors = {};
      if (!empty(inpt.cvv) && inpt.cvv.length >= 3) {
        delete errors.cvv;
      } else {
        errors.cvv = true;
      }
      if (!empty(inpt.twofacode) && inpt.twofacode.length >= 6) {
        delete errors.twofacode;
      } else {
        errors.twofacode = true;
      }
      return !empty(errors) ? true : false;
    };

    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-ui-alert credit-card">
          <Loading loading={loading} />
          <Modal.Body>
            {stepModal === 'one' ? (
              <React.Fragment>
                <div className="react-confirm-body-details">
                  <h2>{context.t('Purchase Summary')}</h2>
                  <button className="closeModalBeta" onClick={handleClose}>
                    {context.t('X')}
                  </button>
                  <div className="body-data">
                    <Container>
                      <Row className="center-cont">
                        <Col lg={11}>
                          <Row className="none">
                            <Col className="title bold">
                              {context.t('Date')}
                            </Col>
                          </Row>
                          <Row className="none">
                            <Col className="subtitle">
                              {moment()
                                .locale(language)
                                .format('LLLL')}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col className="title">{context.t('Amount')}</Col>
                            <Col className="right-title">
                              USD{' '}
                              {!empty(myCurrentDataForm)
                                ? fixNumber(myCurrentDataForm.amount_total)
                                : 0.0}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="title">{context.t('Fee')}</Col>
                            <Col className="right-title">
                              USD {fixNumber(fee)}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="title">{context.t('Price')}</Col>
                            <Col className="right-title">
                              1 BTC ≈
                              {!empty(
                                findPrice(level1, inputs.amount, 'BTCUSD')
                                  .btcPriceBanexcoin
                              )
                                ? ' ' +
                                  findPrice(
                                    level1,
                                    inputs.amount,
                                    'BTCUSD'
                                  ).btcPriceBanexcoin.toFixed(2)
                                : ' 0.00'}{' '}
                              USD
                            </Col>
                          </Row>
                          <Row className="blured">
                            <Col className="title">
                              {context.t('{product} to Buy', {
                                product: inputs.currency
                              })}
                            </Col>
                            <Col className="right-title">
                              {!empty(
                                findPrice(level1, inputs.amount, 'BTCUSD')
                                  .btcToBuyAprox
                              )
                                ? findPrice(level1, inputs.amount, 'BTCUSD')
                                    .btcToBuyAprox
                                : '0.00'}
                              <FontAwesomeIcon
                                className="card-title-icon"
                                icon={faExclamationCircle}
                                data-tip
                                data-for="ImportantTip"
                              />
                              <ReactTooltip
                                id="ImportantTip"
                                place="left"
                                type="light"
                                effect="solid">
                                {context.t(
                                  'The amount in BTC to buy is approximate, the final amount to receive in your Banexcoin account will be the one that results when making the exchange after the transaction with your credit card is approved.'
                                )}
                              </ReactTooltip>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col className="title bold">
                              {context.t('Total to pay')}
                            </Col>
                            <Col className="right-title bold">USD {amount}</Col>
                          </Row>
                          <Row>
                            <Col className="title bold" xl="4">
                              {context.t('Credit Card')}
                            </Col>
                            <Col className="right-title bold" xl="8">
                              {getSelectedCard(
                                myCreditCards,
                                myCurrentDataForm.selected_card_id
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="title bold">
                              {context.t(
                                'Please confirm the CVV from your credit card'
                              )}
                            </Col>
                            <Col className="right-title bold">
                              <FormControl
                                required
                                name="cvv"
                                type="password"
                                aria-label="CVV"
                                aria-describedby="inputGroup-sizing-sm"
                                step="0.01"
                                className="normal"
                                autoComplete={'off'}
                                onChange={onSimpleChange}
                                value={!empty(inputs.cvv) ? inputs.cvv : ''}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="title bold">
                              {context.t(
                                'Enter the token from your Authenticator app.'
                              )}
                            </Col>
                            <Col className="right-title bold">
                              <FormControl
                                required
                                name="twofacode"
                                type="text"
                                aria-label="2FA Code"
                                aria-describedby="inputGroup-sizing-sm"
                                step="0.01"
                                maxLength="6"
                                className="normal"
                                autoComplete={'off'}
                                onChange={onSimpleChange}
                                value={
                                  !empty(inputs.twofacode)
                                    ? inputs.twofacode
                                    : ''
                                }
                              />
                            </Col>
                          </Row>
                          {!empty(errorMessage) ? (
                            <Alert variant={'danger'}>
                              {context.t(errorMessage)}
                            </Alert>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
                <Row className="center-cont">
                  <Col lg={11}>
                    <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        disabled={disabledVal(inputs)}
                        onClick={() => {
                          startPayment(inputs, form, newOnClose);
                        }}>
                        {context.t('Confirm and pay')}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          newOnClose();
                        }}>
                        {context.t('Cancel')}
                      </button>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) : stepModal === 'success' ? (
              <React.Fragment>
                <div className="react-confirm-body-details">
                  <button
                    className="closeModalBeta"
                    onClick={() => {
                      newOnClose();
                    }}>
                    {context.t('X')}
                  </button>
                  <div className="body-data">
                    <Container>
                      <Row className="center-cont">
                        <Col lg={11}>
                          <Row className="box-icon-box">
                            <Col />
                          </Row>
                          <Row className="title-cont">
                            <Col>
                              <h2>{context.t('You order is processing')}</h2>
                              <h3>
                                {context.t(
                                  'If your card has enough funds, you will be able to see the purchase reflected in your wallet'
                                )}
                              </h3>
                            </Col>
                          </Row>
                          <Row className="none">
                            <Col className="title bold">
                              {context.t('Date')}
                            </Col>
                          </Row>
                          <Row className="none">
                            <Col className="subtitle">
                              {moment().toLocaleString()}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col className="title">
                              {context.t('Payment Id')}
                            </Col>
                            <Col className="right-title">
                              {responsePayment.id}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="title">
                              {context.t('{product} bought', {
                                product: responsePayment.product
                              })}
                            </Col>
                            <Col className="right-title">
                              {responsePayment.productTotalInitial}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col className="title bold">
                              {context.t('Total paid')}
                            </Col>
                            <Col className="right-title bold">
                              USD {responsePayment.paymentTotal}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
                <Row className="center-cont">
                  <Col lg={11}>
                    <div className="react-confirm-alert-button-group">
                      <Link
                        className="btn btn-light p-3"
                        style={{
                          margin: 'auto',
                          fontSize: '1.3rem',
                          fontWeight: '500'
                        }}
                        to="/easy-buy/my-payment-attempts/10/1"
                        onClick={() => {
                          newOnCloseOnFinish();
                        }}>
                        {context.t('Accept and view my transactions')}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Container />
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  } catch (e) {
    console.error('Error on EasyBuyPagePrice', e);
    return <React.Fragment />;
  }
};

EasyBuyPagePrice.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts, instruments, level1 };
};

export default connect(mapStateToProps)(EasyBuyPagePrice);
