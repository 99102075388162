import React from 'react';
import PropTypes from 'prop-types';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import APTable from 'apex-web/lib/components/common/APTable';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ScheduledReportsTableComponent.css';

const baseClass = getBEMClasses('cyclical-report-class');

const activities = {
  TradeActivity:"Trade activity",
  Transaction: "Transaction",
  Treasury:"Treasury"
}

let ScheduledReportsTableComponent = function ScheduledReportsTableComponent(
  props,
  context
) {
  let reports = props.reports,
  isLoading = props.isLoading,
  cancelReport = props.cancelReport;
  
  reports = reports.map(report => {
    return {
      ...report,
      reportFlavor: context.t(activities[report.reportFlavor]),
      ReportFrequency:context.t(report.ReportFrequency)
    }
  })
  const columns = [
    {
      header: context.t('Report Summary'),
      classModifier: 'type',
      cell: function cell(row) {
        return row.reportFlavor;
      }
    },
    {
      header: context.t('Frequency'),
      classModifier: 'frequency',
      cell: function cell(row) {
        return row.ReportFrequency;
      }
    },
    {
      header: context.t('Created'),
      classModifier: 'created',
      cell: function cell(row) {
        return formatDateTime(row.createTime);
      }
    },
    {
      header: context.t('Actions'),
      classModifier: 'actions',
      cell: function cell(row) {
        return React.createElement(CancelAPButton, {
          customClass: baseClass('action-btn'),
          styleName: 'subtractive',
          text: context.t('Cancel Report'),
          onClick: function onClick() {
            return cancelReport(row.RequestId);
          }
        });
      }
    }
  ];

  return React.createElement(
    'div',
    { className: baseClass('container') },
    isLoading
      ? React.createElement('p', null, 'Cargando...')
      : React.createElement(APTable, {
          columns: columns,
          baseClass: baseClass,
          rows: reports,
          empty: context.t('No Report Is Available'),
          fetching: isLoading,
          headerOutside: true
        })
  );
};

ScheduledReportsTableComponent.propTypes = {
  reports: PropTypes.array.isRequired,
  cancelReport: PropTypes.func.isRequired
};

ScheduledReportsTableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ScheduledReportsTableComponent;
