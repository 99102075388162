import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { acceptDisclaimerHook } from './DisclaimerHooks';
import DisclaimerModalForm from './DisclaimerModalForm';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import './DisclaimerModal.css';

const DisclaimerModal = (props, context) => {
  let {
    userInfo,
    selectedAccountId,
    getAccountConfig,
    depositsStatus,
    accounts,
    logout
  } = props;
  let disclaimerLevel = [10];
  const [blockedAll, setBlockedAll] = useState(false);

  const handleClose = () => {
    setBlockedAll(false);
  };
  const handleShow = ticketData => {
    setBlockedAll(true);
  };

  const getAccountConfigs = async () => {
    let configs = await getAccountConfig({
      AccountId: selectedAccountId,
      OMSId: 1
    });
    let findDisclaimer = configs.find(d => d.Key === 'limitsDisclaimer');
    let blockedStatus = true;
    if (!empty(findDisclaimer) && findDisclaimer.Value === 'ACCEPTED') {
      blockedStatus = false;
    }
    setBlockedAll(blockedStatus);
  };

  useEffect(
    () => {
      if (!empty(selectedAccountId) && !empty(userInfo)) {
        let currentAccount = accounts.find(
          a => a.AccountId === selectedAccountId
        );
        if (
          !empty(currentAccount) &&
          disclaimerLevel.includes(currentAccount.VerificationLevel)
        ) {
          getAccountConfigs(selectedAccountId, userInfo.UserId);
        }
      }
    },
    [selectedAccountId, depositsStatus, accounts]
  );
  if (!blockedAll) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <DisclaimerModalForm
        show={blockedAll}
        handleClose={handleClose}
        acceptDisclaimerHook={acceptDisclaimerHook}
        UserId={userInfo.UserId}
        selectedAccountId={selectedAccountId}
        setBlockedAll={setBlockedAll}
        logout={logout}
      />
    </React.Fragment>
  );
};

DisclaimerModal.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    getAccountConfig: payload =>
      dispatch(callAPI('GetAccountConfig', payload)).then(res => {
        return res;
      })
  };
};

const mapStateToProps = state => {
  let {
    user: { selectedAccountId, accounts, userInfo },
    user,
    position: { positions, fetching },
    deposit: { depositsStatus }
  } = state;
  return {
    selectedAccountId,
    accounts,
    userInfo,
    user,
    positions,
    fetching,
    depositsStatus
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisclaimerModal);
