import React from 'react';
import PropTypes from 'prop-types';
import BalancesMenuItemComponent from './BalancesMenuItemComponent';
import MenuComponent from 'apex-web/lib/components/Menu/MenuComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './BalancesMenuComponent.css';

var balancesClasses = getBEMClasses('balances-menu');

var BalancesMenuComponent = function BalancesMenuComponent(props, context) {
  let users = [
    'andrewvergel',
    'david.arrarte',
    'lc',
    'msrangelh',
    'binancetest',
    'thespeed94'
  ];
  var check = users.includes(props.UserName);
  var filterProducts = props.positions.filter(
    e =>
      e.ProductId === 1 ||
      e.ProductId === 10 ||
      e.ProductId === 13 ||
      e.ProductId === 14
  );

  var menuItems = props.positions.map(function(position) {
    return React.createElement(BalancesMenuItemComponent, {
      key: position.ProductId,
      position: position,
      selected: position.ProductId === props.selectedProductId,
      onItemClick: props.selectPositionAndSave
    });
  });

  return React.createElement(
    MenuComponent,
    { customClass: balancesClasses(), dataTest: 'Asset List' },
    menuItems
  );
};

BalancesMenuComponent.propTypes = {
  positions: PropTypes.array.isRequired
};

BalancesMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BalancesMenuComponent;
