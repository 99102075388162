import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { startBinance } from './BinancePage/BinancePageHooks';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import Loading from './../components/Loading';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import apex from 'apex-web/lib/apex';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const lang = localStorage.getItem('language');

class BinancePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: 0, signup: '', login: '' };
  }

  syncChart() {
    (async () => {
      var FromDate = moment();
      FromDate = FromDate.subtract(21, 'days');
      FromDate = FromDate.format('YYYY-MM-DD');

      let ToDate = moment().format('YYYY-MM-DD');

      let GetTickerHistory = await apex.connection.GetTickerHistory({
        InstrumentId: 1,
        Interval: 86400,
        FromDate: FromDate,
        ToDate: ToDate,
        OMSId: 1
      });

      if (!empty(GetTickerHistory)) {
        let dataPrices = [];
        for (let gth in GetTickerHistory) {
          let p = GetTickerHistory[gth];
          dataPrices.push(p[2]);
        }
        this.setState({ dataDefault: dataPrices });
      }
    })();
  }

  componentDidMount() {
    this.chartReference = React.createRef();
  }

  componentDidMount() {}

  render() {
    let { selectedAccountId, user, userInfo } = this.props;
    let { login, signup, loading } = this.state;

    if (empty(userInfo)) {
      return <React.Fragment />;
    }

    const start = async () => {
      this.setState({
        loading: 1
      });
      let startB = await startBinance({
        userId: userInfo.UserId,
        accountEmail: userInfo.Email
      });
      if (!empty(startB)) {
        if (startB.status.respCode === 'SUCCESS') {
          if (startB.status.status === 'WAIT_AUTH') {
            localStorage.setItem(
              'binance',
              JSON.stringify({
                userId: parseInt(startB.status.userId),
                merchantCode: startB.status.merchantCode
              })
            );
            this.setState({
              login: startB.redirect.loginUrl
            });
            this.setState({
              signup: startB.redirect.registerUrl
            });
          }
        } else {
          localStorage.removeItem('binance');
        }
      } else {
        localStorage.removeItem('binance');
      }
      this.setState({
        loading: 0
      });
    };

    const seo = {
      title: 'Binance - Banexcoin',
      description: 'Comprar con Binance',
      url: 'https://www.banexcoin.com/binance/',
      image:
        'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
    };
    return (
      <div>
        <Loading loading={loading} />
        <Helmet
          title={seo.title}
          meta={[
            {
              name: 'description',
              property: 'og:description',
              content: seo.description
            },
            { property: 'og:title', content: seo.title },
            { property: 'og:url', content: seo.url },
            { property: 'og:image', content: seo.image },
            { property: 'og:image:type', content: 'image/png' },
            { property: 'twitter:image:src', content: seo.image },
            { property: 'twitter:title', content: seo.title },
            { property: 'twitter:description', content: seo.description }
          ]}>
          <link rel="canonical" href={seo.url} />
        </Helmet>
        <div>
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="2" />
              <Col md="auto">
                <img src="/local/landing-page/images/Binance_logo.svg" />
              </Col>
              <Col xs lg="2" />
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="2" />
              <Col md="auto">
                {empty(login) && empty(signup) ? (
                  <Button
                    className="binance-btn"
                    onClick={() => {
                      start();
                    }}>
                    {this.context.t('Start with Binance')}
                  </Button>
                ) : (
                  ''
                )}
              </Col>
              <Col xs lg="2" />
            </Row>
            {/* <Row className="justify-content-md-center p-5">
              <Col md="auto">
                {!empty(login) ? (
                  <a href={login} className="binance-btn btn btn-primary m-3">
                    {this.context.t('Login')}
                  </a>
                ) : (
                  ''
                )}
                {!empty(signup) ? (
                  <a href={signup} className="binance-btn btn btn-primary m-3">
                    {this.context.t('Signup')}
                  </a>
                ) : (
                  ''
                )}
              </Col>
            </Row> */}
            <Row className="justify-content-md-center">
              <Col xs lg="2" />
              <Col md="auto">
                {!empty(login) && !empty(signup) ? (
                  <Link
                    to="/binance/dashboard"
                    className="binance-btn btn btn-primary">
                    Ir al dashboard
                  </Link>
                ) : (
                  ''
                )}
              </Col>
              <Col xs lg="2" />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

BinancePage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { selectedAccountId, userInfo }, user }) => {
  return { selectedAccountId, userInfo, user };
};

export default connect(mapStateToProps)(BinancePage);
