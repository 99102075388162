import React, { useEffect, useState } from 'react';
import { fixNumberWithoutRound2 } from '../../../utils';
import './range.css';

const percentages = [0, 25, 50, 75, 100];
export default function RangeComponent({
  max,
  name,
  inputValue = '0',
  onChange = () => {},
  decimalPlaces = 8
}) {
  const [percentage, setPercentage] = useState(0);

  useEffect(
    () => {
      setPercentage(String((Number(inputValue) / Number(max)) * 100));
    },
    [inputValue]
  );

  const changeValue = percentage =>{
    onChange(
      name,
      Number(fixNumberWithoutRound2((Number(max) * Number(percentage)) / 100, decimalPlaces))
      );
    }
  const handleInputRange = ev => {
    changeValue(ev.target.value);
    setPercentage(ev.target.value);
  };
  const handlePercentageByButton = value => {
    setPercentage(value);
    changeValue(value);
  };
  return (
    <div className="exchange range_container">
      <progress className="progress_bar" value={percentage} max="100">
        {percentage}
      </progress>
      <input
        className="range_bar"
        type="range"
        value={percentage}
        onChange={handleInputRange}
        step="0.01"
        min="0"
        max="100"
      />
      <div className="buttonsContainer">
        {percentages.map(value => (
          <button
            key={value}
            className="buttonOption"
            onClick={ev => {
              ev.preventDefault();
              handlePercentageByButton(value);
            }}>
            {value}%
          </button>
        ))}
      </div>
    </div>
  );
}
