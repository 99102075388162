import { connect } from 'react-redux';
import ConfirmCyclicalReportModal from './ConfirmCyclicalReportModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { scheduleReport } from 'apex-web/lib/redux/actions/reportActions';

const mapDispatchToProps = {
  scheduleReport: scheduleReport
};

const container = connect(null, mapDispatchToProps)(ConfirmCyclicalReportModal);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_CYCLICAL_REPORT
})(container);