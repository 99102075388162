import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";

const Recommendations = (props, context) => {
  const { isCoink } = props;
  const defaultRecommendations = () => (
    <div className="bnx-v2 container-workflow withdraw mb-5 recomendation-container">
      <Row>
        <Col>
          <b className="important-message">
            {context.t("Keep in mind the following")}
          </b>
        </Col>
      </Row>
      <Row>
        <Col>
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Minimum withdrawal amount $ 10 or S/ 45."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Your withdrawal will be processed through an immediate or deferred transfer, according to the availability of the bank."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Withdrawals to international accounts have a term of up to 5 business days."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "The withdrawal process is subject to evaluation according to Banexcoin policies."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Bank commissions will be paid by the customer."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "You can only send funds to your own bank account in Banexcoin. The use of joint or pooled accounts is not permitted."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Withdrawals generated before 8:00am and/or after 6:00pm. Peru time from Monday to Friday could be processed the next non-bank business day."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Withdrawals will not be processed for international wire transfers below $1,000."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Banexcoin is not responsible for delays, reviews and/or fees made by your intermediary bank or banks."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Withdrawals are subject to delays during US or Peru holidays or non-working days for the bank."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Before generating a withdrawal review our <a href='{link}' target='_blank'><u>instructions</u></a>.",
                {
                  link: context.t(
                    "https://soporte.banexcoin.com/en/support/solutions/articles/60000522383-fiat-money-withdrawal-peruvian-soles-or-american-dollars-"
                  ),
                }
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
  const coinkRecommendations = () => (
    <div className="bnx-v2 container-workflow withdraw mb-5 recomendation-container">
      <Row>
        <Col>
          <b className="important-message">
            {context.t("Keep in mind the following")}
          </b>
        </Col>
      </Row>
      <Row>
        <Col>
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Minimum withdrawal amount is 50,000 Colombian Pesos."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "Your withdrawal will be processed through an immediate transfer to your available balance in your Coink account."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "The withdrawal process is subject to evaluation according to Banexcoin policies."
              ),
            }}
          />
          <p
            className="important-message-text"
            dangerouslySetInnerHTML={{
              __html: context.t(
                "You can only send funds to your own account at Coink. The use of joint or joint accounts is not permitted."
              ),
            }}
          />
          {/* TODO: PENDIENTE INSTRUCCIONES DE RETIRO PARA COINK */}
          <p className="important-message-text">
            {context.t("Before generating a withdrawal, review our")}{" "}
            <a
              href=""
              target="_blank"
              style={{ fontSize: "12px", color: "#ff8d3f" }}
            >
            {context.t("instructions")}
            </a>
            .
          </p>
        </Col>
      </Row>
    </div>
  );
  return !isCoink ? defaultRecommendations() : coinkRecommendations();
};
const mapStateToProps = (state) => {
  return {
    isCoink:
      !isEmpty(state.user) && !isEmpty(state.user.verificationLevelConfigs)
        ? [100, 101].includes(state.user.verificationLevelConfigs.Level)
        : false,
  };
};
Recommendations.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(Recommendations);
