import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { useForm, submitFormData } from "./FiatWithdrawFormComponentPageHooks";
import { parseNumberToLocale } from './../../../../helpers/numberFormatter';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { Button, Form, Row, Col, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import empty from 'is-empty';
import { useForm } from '../../../../hooks/formHooks';
import coinkLogo from '../../../../images/coink-imgs/mini-iso-coink.svg'
import {
  callAPI,
  getWithdrawPayload,
  submitFormData
} from './FiatWithdrawFormComponentHooks';
import { getV2FiatAccountsbyWithdraw } from './../../../DepositSidePaneComponents/DepositWorkflows/ManualDepositWorkflow/ManualDepositWorkflowComponentPageHooks';
import { toast } from 'react-toastify';
import Loading from '../../../Loading';

import { confirmAlert } from 'react-confirm-alert';
import apex from 'apex-web/lib/apex';
import {
  FormButtonV2,
  FormField,
  FormSelect,
  FormSingleCheckbox
} from '../../../common/BnxFormsComponents';
import { useHistory, withRouter } from 'react-router-dom';
import { updateComponent as _updateComponent } from '../../../../redux/actions/set_component';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import InteriorPagePhoneConfirmation from '../../../../pages/InteriorPagePhoneConfirmation';
import { authorizedLevel } from './../../../../helpers/lib';
import { allBanksInfo } from './../../../BanksInfo';
import { formatNumbersWithoutRound, FormatDecimalCoinkWithSimbols, formattedCoinkAmountWithoutDots, formatCopCurrency } from './../../../../pages/utils'
import { createStateSnackbar as _createStateSnackbar, resetStateSnackbar as _resetStateSnackbar } from '../../../../redux/actions/set_snackbar'

//CSS
import './FiatWithdrawFormComponent.css';
import isEmpty from 'is-empty';
import RememberImg from '../../../../images/RememberImg.svg'
import CoinkTransactionsController from '../../../CoinkTransactionsController';
import { Spinner } from '../../../Spinner/Spinner';
import Modal2fa from '../../../Modal2fa/Modal2fa';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { alertsContentCoink } from '../../../../constants/alertsContentCoink';
import InputElement from '../../../../pages/bnxv2/Components/InputElement/InputElement';
import ModalConfirmationWithdrawCoink from './ModalConfirmationWithdrawCoink';
import InformationIcon from '../../../../images/bnx-icons/information-circle.svg'
import { showSnack as _showSnack } from "apex-web/lib/redux/actions/snackbarActions";

const fiatWithdrawFormComponentClasses = getBEMClasses('fiat-withdraw-form');

const FiatWithdrawFormComponent = (props, context) => {
  try {
    let {
      selectedAccountId,
      balance,
      userInfo,
      product,
      products,
      setAmounted,
      requestedAmount,
      onChange,
      accounts,
      verificationLevelConfigs,
      minWithdraw,
      items,
      info,
      blockedActions,
      calculateFee,
      validate,
      updateComponent,
      component,
      location,
      showSnack,
      createStateSnackbar,
      resetStateSnackbar,
      redirect
    } = props;

    const history = useHistory();
    const [firstInteration, setFirstInteration] = useState(true);
    const [showDetails, setShowDetails] = useState(false);

    const [originalFee, setOriginalFee] = useState({});
    function repeatStringNumTimes(string, times) {
      var repeatedString = '';
      while (times > 0) {
        repeatedString += string;
        times--;
      }
      return repeatedString;
    }
    const formatNumberToLocaleNew = numb => {
      return Number(
        formatNumberToLocale(numb, product.ProductSymbol).replace(/,/g, '')
      );
    };
    const formatToNumber = (numbr, places) => {
      numbr = !empty(numbr) ? numbr : 0.0;
      return Number(numbr.toFixed(places));
    };
    const formatFee = (numbr, places) => {
      let denom = Number('1' + repeatStringNumTimes('0', places || 1));
      numbr = !empty(numbr) ? numbr : 0.0;
      return Math.floor(denom * numbr) / denom;
    };

    if (empty(userInfo) && empty(selectedAccountId) && empty(balance)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    let getVerificationLevel = (array, accountId) => {
      return array.find(element => element.AccountId === accountId);
    };
    let currentAccount = getVerificationLevel(accounts, selectedAccountId);

    const [bankName, setBankName] = useState('');
    const [isInternational, setIsInternational] = useState(false);
    const [currencyTicket, setCurrencyTicket] = useState('');
    const [amount, setAmount] = useState('');
    const [bankAssetName, setBankAssetName] = useState('');
    const [loading, setLoading] = useState(0);
    const [fiatAccounts, setFiatAccounts] = useState({});
    const [interbankNews, setInterbankNews] = useState(false);
    const [originAccounts, setOriginAccounts] = useState([]);
    const [tooltipBank, setTooltipBank] = useState('');
    const [acceptExchangeRate, setAcceptExchangeRate] = useState(false);
    const [loadingWithdrawCoink, setLoadingWithdrawCoink] = useState(false);
    const [show2faModal, setShow2faModal] = useState(false);
    const productId = !isEmpty(product.ProductId) ? product.ProductId : 1;
    const selectedProduct = (products.products || []).find(p => p.ProductId === productId)
    const [coinkAmount, setCoinkAmount] = useState("")
    const [commissionCoink, setCommissionCoink] = useState(0)
    const [maximumLimitCoink, setMaximumLimitCoink] = useState(0)

    const isCoink = verificationLevelConfigs.Level >= 100

    const handleCoinkWithdraw = async ({ code, setError }) => {
      const response = await CoinkTransactionsController.withdraw({
        userInfo,
        inputs: {
          amount: formattedCoinkAmountWithoutDots(coinkAmount),
          twoFa: code
        },
        setError,
        setLoadingWithdrawCoink,
        context
      });
      if(response){
        showSnack({
          id: "coink_trade_withdraw",
          text: context.t("Your withdrawal has been processed successfully."),
          type: "success",
          buttons: [
            {
              text: context.t("Accept"),
              className: "button_V2 purple",
              onClick: () => {
                history.push("/wallets/product-details");
              }
            },
          ],
        })
      }
    };

    useEffect(
      () => {
        async function getFees() {
          let correctTotalFee = await apex.connection.GetOMSWithdrawFees({
            OMSId: 1,
            ProductId: product.ProductId
          });
          if (!empty(correctTotalFee)) {
            var data = correctTotalFee.filter(function(el) {
              return el.IsActive === true;
            });
            if(empty(data)) return
            balance = formatToNumber(balance, 2);
            let feePrcg = data[0].FeeAmt / 100;
            let m = formatToNumber(balance / (1 + feePrcg), 2);
            let calc = formatFee(m * feePrcg, 2);
            setOriginalFee({
              FeeAmount: calc,
              TicketAmount: m
            });
          }
        }
        if (empty(originalFee)) {
          getFees();
        }
      },
      [originalFee]
    );
    async function loadFiatAccounts() {
      if(validate.phone && (!validate['2FA'].includes('createwithdrawticket') || validate['2FA'].includes('createwithdrawticket') && userInfo.Use2FA) && authorizedLevel().includes(currentAccount.VerificationLevel)){
        try {
          setLoading(1);
          let result = await getV2FiatAccountsbyWithdraw(
            product.ProductSymbol,
            userInfo.UserId,
            selectedAccountId
          );
          let fiatAccounts = !empty(result.data)
            ? result.data.fiataccounts
            : [];
          setFiatAccounts(fiatAccounts);
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        } finally {
          setLoading(0);
          interbankOperations();
        }
      }
    }
    useEffect(() => {
      if (!empty(userInfo) && !isCoink) {
        loadFiatAccounts();
      }
    }, []);
    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : '',
      ProductId: product.ProductId,
      assetName: product.ProductSymbol,
      AccountId: userInfo.AccountId
    };

    const successWithdraw = (productId) => {
      setTimeout(() => {
        showSnack({
          id: 'success_withdraw',
          text: context.t('Your withdraw has been successfully added, please check email for instructions.'),
          type: 'success',
          buttons: [
            {
              text: context.t('Got it'),
              className: 'button_V2 purple',
              onClick: () => {
                history.push('/wallets/product-details');
                props.selectPositionAndSave(productId);
              }
            }
          ]
        });
      }, 1);
    }

    const onSubmitCallback = async inputs => {
      try {
        const errors = customValidations(inputs);
        if(!isEmpty(errors)) return
        let payload = await getWithdrawPayload(inputs, fiatAccounts);
        let result = await props.confirmWithdraw(payload);

        if (!empty(result) && result.result === true) {
          showSnack({
            id: 'warning_withdraw',
            text: context.t('For security reasons, we email you a link to authorize the transaction.<br><br> Please access your email account and open the authorization link to proceed with sending funds.'),
            type: 'pending',
            buttons: [
              {
                  text: context.t('Got it'),
                  className: 'button_V2 purple',
                  onClick: () => successWithdraw(payload.ProductId)
              }
            ]
          });
        } else {
          toast.warn(context.t(result.detail || result.errormsg), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      var cerrar = document.getElementsByClassName('ap-sidepane__close-button');
      if (cerrar) {
        cerrar[0].click();
      }
    };

    const customValidations = (inputs, opt = true, international = isInternational) => {
      let errors = {};
      let { balance, allowNegative, fee, requestedAmount } = props;
      if(isCoink) return errors
      if (opt && empty(inputs.account_source)) {
        errors.account_source = context.t('You must select an account');
      }

      if (opt && empty(inputs.full_name)) {
        errors.full_name = context.t('This field is mandatory');
      }

      let feeAmount = fee;
      let remainingAmount = null;

      if (product.ProductSymbol === 'USD' || product.ProductSymbol === 'PEN') {
        feeAmount = feeAmount.toFixed(2);
        balance = formatNumbersWithoutRound(balance, 2);
        remainingAmount =
          parseFloat(balance) - (inputs.amount);
      }

      let isGreaterThanTotal = remainingAmount < 0 ? true : false;

      let minAmount = 0;
      if (product.ProductSymbol === 'USD') {
        minAmount = international ? 1000 : minWithdraw.USD;
      } else if (product.ProductSymbol === 'PEN') {
        minAmount = minWithdraw.PEN;
      }


      if (empty(inputs.amount) || parseFloat(inputs.amount) < minAmount) {
        errors.amount = context.t(
          'The amount must be greater than {value} {symbol}',
          {
            value: minAmount,
            symbol: product.ProductSymbol
          }
        );
      } else {
        if (isGreaterThanTotal) {
          errors.amount = context.t("Insufficient balance");
        }
      }

      setErrors({
        ...errors
      });
      return errors;
    };

    let {
      inputs,
      errors,
      setErrors,
      onInputChange,
      onInputChangeValidation,
      onSubmit,
      onInputChangeByName,
      onCheckboxChangeValidation,
      onInputFileChange,
      setInputs
    } = useForm(initialState, onSubmitCallback, customValidations);

    useEffect(() => {
        if (isEmpty(fiatAccounts)) return
        setOriginAccounts(buildJsonOriginAccounts(fiatAccounts));
      }, [fiatAccounts])  ;
    useEffect(
      () => {
        if (!isEmpty(fiatAccounts) && !isEmpty(inputs)) {
          const res = originAccounts.find(
            item => item.value === inputs.account_source
          );
          if (res) setTooltipBank(res.name);
        }
      },
      [inputs]
    );

    const onFiatAccountChange = e => {
      e.persist();
      setInputs(inputs => ({
        ...inputs,
        account_source: e.target.value
      }));
      if(!firstInteration){
        customValidations({
          ...inputs,
          account_source: e.target.value
        });
      }
    };
    const onChangeVal = async e => {
      if(isEmpty(e.target.value)){
        setInputs(inputs => ({
          ...inputs,
          [e.target.name]: ""
        }));
        return
      }
      if(String(e.target.value).split("").reverse()[0] === "."){
        setInputs(inputs => ({
          ...inputs,
          [e.target.name]: e.target.value
        }));
        return
      }
      if(e.target.value[0] === "0"){
        e.target.value = e.target.value.slice(1)
      }
      const targetValue = String(e.target.value).replace(/-|,/g, '')
      let { allowNegative, fee } = props;
      balance = formatNumberToLocaleNew(balance);
      fee = formatFee(fee, 2);
      let inputValue = !empty(targetValue) ? Number(targetValue) : null;
      setAmount(inputValue);
      inputValue = formatNumberToLocaleNew(
        inputValue || 0.0
      );

      const value = parseNumberToLocale(
        inputValue || 0.0,
        null,
        !allowNegative
      );

      onChange({
        data: {
          Amount: inputValue
        }
      });

      setAmounted(!empty(targetValue) ? value : null);

      const {name} = e.target;
      const newValue = !empty(targetValue) ? value : 0;
      setInputs(inputs => ({
        ...inputs,
        [name]: newValue
      }));
      if(firstInteration){
        customValidations({[name]: newValue}, false)
      } else{
        customValidations({
          ...inputs,
          [name]: newValue
        });
      }
    };

    const buildJsonOriginAccounts = data => {
      let result = [];
      !empty(data) &&
        data.map(fiataccount => {
          let logo = allBanksInfo(fiataccount.beneficiaryBankname);
          if (fiataccount.transferType === 'NATIONAL') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | ${context.t(
                'National'
              )} (${fiataccount.countryName})`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('CCI')}: ${fiataccount.accountNumberCci}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency: `${fiataccount.currencyCode}`
            });
          } else if (fiataccount.transferType === 'DOMESTIC_TRANSFER_US') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('Domestic transfer USA')}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency: `${fiataccount.currencyCode}`
            });
          } else if (
            fiataccount.transferType === 'DOMESTIC_TRANSFER_US_WITH_BANK'
          ) {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t(
                'Domestic transfer USA with intermediary bank'
              )}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency: `${fiataccount.currencyCode}`
            });
          } else if (fiataccount.transferType === 'INTERNATIONAL') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('International with intermediary bank')}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency: `${fiataccount.currencyCode}`
            });
          } else if (
            fiataccount.transferType ===
            'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK'
          ) {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('International without intermediary bank')}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency: `${fiataccount.currencyCode}`
            });
          }
        });
        let accountWithCurrentCurrency = []
        let accountNoCurrentCurrency = []
        result.forEach(item => {
          if(item.currency === product.ProductSymbol){
            accountWithCurrentCurrency.push(item)
          }else{
            accountNoCurrentCurrency.push(item)
          }
        });
      return [...accountWithCurrentCurrency, ...accountNoCurrentCurrency];
    };

    const cerrar = () => {
      var closing = document.getElementsByClassName(
        'ap-sidepane__close-button'
      );
      if (closing) {
        closing[0].click();
      }
    };

    const inmediateAlertBbva = () => {
      const alertIntbnk = confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui custom-notice">
              <p>{context.t('Dear Customer:')}</p>
              <p>
                {context.t(
                  'We inform you that all deposit or withdrawal operations in Peruvian Soles or US Dollars to be carried out in Peru will be handled through Interbank for the time being.'
                )}
              </p>
              <p>
                {context.t(
                  'Temporarily we will not be processing withdrawals for transfers to other banks. Remember that deposit operations will continue to be handled normally through Interbank. We are working to offer new banking alternatives in the short term.'
                )}
              </p>
              <p>
                {context.t(
                  'We appreciate your understanding and apologize for any inconvenience this may cause.'
                )}
              </p>
              <p>{context.t('Sincerely,')}</p>
              <p>{context.t('Banexcoin Team')}</p>
              <div className="btn-alert-container">
                <button
                  className="btn-alert"
                  onClick={() => {
                    onClose();
                  }}>
                  {context.t('Accept')}
                </button>
              </div>
            </div>
          );
        }
      });
    };

    useEffect(
      () => {
        if (interbankNews) {
          inmediateAlertBbva();
        }
      },
      [interbankNews]
    );
    const inmediateOperations = () => {
      const alertIntbnk = confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <p
                className="tiyle-alert"
                dangerouslySetInnerHTML={{
                  __html: context.t('Immediate <b>operations</b>')
                }}
              />
              <p>
                {context.t(
                  'For immediate operations, we accept withdrawals up to USD 10,000 or S/ 30,000. If the order exceeds these limits, your funds will be available, at most, within 24 hours (from Monday to Thursday), or up to 72 hours, if it is generated on Friday; This schedule does not consider holidays.'
                )}
              </p>
              <div className="btn-alert-container">
                <button
                  className="btn-alert"
                  onClick={() => {
                    onClose();
                  }}>
                  {context.t('Accept')}
                </button>
              </div>
            </div>
          );
        }
      });
    };

    const bankAssetNameData = {
      USD: 10000,
      PEN: 30000
    };

    const isInmediatedOperation = () => {
      const foundCurrency = bankAssetNameData[bankAssetName];
      return (
        (bankName || '').includes('INTERBANK') &&
        !empty(foundCurrency) &&
        amount > foundCurrency
      );
    };

    useEffect(
      () => {
        if (!empty(initialState)) {
          setBankAssetName(initialState.assetName);
        }
      },
      [initialState]
    );

    useEffect(
      () => {
        //disparas el modal
        if (isInmediatedOperation()) {
          inmediateOperations();
        }
      },
      [bankName, amount, bankAssetName]
    );

    //Interbank Operations

    const interbankOperations = () => {
      createStateSnackbar({
        title: 'Interbank Operations',
      })
      showSnack({
        id: 'interbank_operations',
        text: context.t('Remember that, according to the internal policies of the banking entity, this transfer will be made within 24 hours with a maximum of 72 hours. This schedule does not consider holidays.'),
        type: 'pending',
        buttons: [
          {
              text: context.t('Got it'),
              className: 'button_V2 purple',
              onClick: () => {
                resetStateSnackbar()
              }
          }
        ]
      });
    };
    const internationalOperations = () => {
      createStateSnackbar({
        title: 'International Operations',
      })
      setTimeout(() => {
        showSnack({
          id: 'international_operations',
          text: context.t('We apologize but the requested option is temporarily unavailable. Currently, we only offer withdrawals through local banks. Rest assured that we are constantly working to enhance our services. Thank you for your patience and understanding.'),
          type: 'pending',
          buttons: [
            {
                text: context.t('Got it'),
                className: 'button_V2 purple',
                onClick: () => resetStateSnackbar()
            }
          ]
        });
      }, 250);
    };
    const differentCurrency = () => {
      showSnack({
        id: 'remember_that',
        text: `${context.t("Remember that")} ${context.t("your operation is in another currency, therefore, the amount you will receive in your account will be subject to the bank's exchange rate")}.`,
        type: 'pending',
        buttons: [
          { 
            text: context.t('No'),
            className: 'button_V2 grey',
            onClick: () => setAcceptExchangeRate(false),
          },
          {
            text: context.t('Yes, I accept'),
            className: 'button_V2 purple',
            onClick: () => setAcceptExchangeRate(true),
          }
        ]
      });
    }

    const validateDifferentCurrency = () => {
      return (!empty(currencyTicket) && !empty(bankAssetName) && (bankAssetName !== currencyTicket)) ? true : false
    }

    const disabledOption = () => {
      return (validateDifferentCurrency() && !acceptExchangeRate) ? true : false;
    }

    const showModal = (value) => {
      if(validateDifferentCurrency() && (currencyTicket === value)){
        differentCurrency();
      }
    }; 
    
    const currentBalance = balance ? formatNumberToLocale(formatNumbersWithoutRound(balance, selectedProduct.DecimalPlaces), product.ProductSymbol) : '-';
    
    const newCurrentBalance = FormatDecimalCoinkWithSimbols(currentBalance, product.ProductSymbol) 
    
    const twoFactorIsActiveToWithdraw = !isEmpty(userInfo) && userInfo.Use2FA

    const canExecuteWithdrawAction = !blockedActions && !loadingWithdrawCoink && twoFactorIsActiveToWithdraw && formattedCoinkAmountWithoutDots(coinkAmount) > 0 && props.balance !== 0 && parseFloat(props.balance) - formattedCoinkAmountWithoutDots(coinkAmount) >= 0 && maximumLimitCoink > 0 

    const handleNextStepWithdraw = () => {
      if(!isEmpty(errors)) return
    const formattedCoinkAmount = formattedCoinkAmountWithoutDots(coinkAmount);
      if (props.balance === 0)
        return setErrors({ amount: `Yo do not have available balance` });
      const labelMax = labelMaximum(formattedCoinkAmount)
      let value = formatCopCurrency(labelMax, 2) || 0
      if(String(labelMax).includes(",")){
        value =  `$ ${Intl.NumberFormat("es-CO",{maximumFractionDigits: 2, minimumFractionDigits: 2,currency:"COP"}).format(Number(labelMax.replaceAll(",","")))}`
      }
      if(!isEmpty(labelMax)){
        return setErrors({ amount: `Excediste el monto máximo a retirar: ${value}` });
      }
      if(canExecuteWithdrawAction) return setShowDetails(true)
      return null
    }

    const formattedCoinkCurrency = (value, withSymbol = false) => {
      return `${withSymbol ? "$" : ""} ${Intl.NumberFormat("es-CO",{maximumFractionDigits: 2, minimumFractionDigits: 2,currency:"COP"}).format(Number(value))}`
    }
    const handleVerify = (location) => {
      redirect(location);
      const closeButton = document.getElementsByClassName('ap-sidepane__close-button');
      if (closeButton) closeButton[0].click();
    }
    
    const handleModalToValidatePhone = () => {
      const modal = document.getElementById('modal');
      const close = document.getElementsByClassName('ap-sidepane__close-button');
      if (close) close[0].click();
      if(modal) {
        updateComponent({ 
          visible: true,
          elements: [
            ...component.elements,
            <InteriorPagePhoneConfirmation
              blocked={true}
              theme={'dark'}
              location={location}
            />
          ]
        });
      }
    }

    useEffect(
      () => {
        if (validateDifferentCurrency()) {
          differentCurrency();
        }
      },
      [currencyTicket, bankAssetName]
    );

    const handleRedirectToSettings = () => {
      history.push("/settings/withdrawal")
      const cerrar = document.getElementsByClassName(
        'ap-sidepane__close-button'
      );
      if (cerrar) {
        cerrar[0].click();
      }
    }

    useEffect(() => {
      if(isCoink){
        showSnack({
          id: "coink_trade_withdrawal",
          text: context.t(alertsContentCoink.rememberMessage),
          type: "info",
          buttons: [
            {
              text: context.t("See my settings"),
              className: "button_V2 grey",
              onClick: handleRedirectToSettings
            },
            {
              text: context.t("Accept"),
              className: "button_V2 purple",
            },
          ],
        });
        CoinkTransactionsController.getCoinkLimitToWithdraw({ userInfo, setMaximumLimitCoink })
      }
    },[userInfo])
    

    const handleCalculateCoinkCommission = async (coinkAmountValue) => {
      try {
        const commission = await calculateFee({
          "OMSId": 1,
          "AccountId": userInfo.AccountId,
          "ProductId": selectedProduct.ProductId,
          "Amount": coinkAmountValue
        })
        if(isEmpty(commission.FeeAmount)){
          throw new Error("Error calculating commission")
        }
        setCommissionCoink(commission.FeeAmount)
      } catch (error) {
        setCommissionCoink(0)
      }finally{
        setLoadingCommission(false)
      }
    }
    let interval = null
    const [loadingCommission, setLoadingCommission] = useState(false)

    useEffect(() => {
      setLoadingCommission(true)
      if(!isCoink) return
      if(isEmpty(coinkAmount)){
        setLoadingCommission(false)
        setCommissionCoink(0)
      }
      const coinkAmountValue = Number(formattedCoinkAmountWithoutDots(coinkAmount))
      interval = setInterval(() => {
        handleCalculateCoinkCommission(coinkAmountValue)
        clearInterval(interval)
      }, 500);
    }, [coinkAmount])

    const handleSetMaximumAmountInCoink = () => {
      if( maximumLimitCoink < Number(currentBalance.replaceAll(',',''))){
        return setCoinkAmount(String(maximumLimitCoink).replaceAll('.',','))
      }
      if(Number(currentBalance.replaceAll(',','.')) === 0) return setCoinkAmount('0') 
      setCoinkAmount(currentBalance.replaceAll(',','').replaceAll('.',','))
    }

    const labelMaximum = (value) => {
      if(maximumLimitCoink > Number(currentBalance.replaceAll(",",""))){
        if(value > Number(currentBalance.replaceAll(",",""))) return currentBalance
        if(maximumLimitCoink !== Infinity && value > maximumLimitCoink) return maximumLimitCoink
      }else{
        if(maximumLimitCoink !== Infinity && value > maximumLimitCoink) return maximumLimitCoink
        if(value > Number(currentBalance.replaceAll(",",""))) return currentBalance
      }
      return null
    }

    const handleChangeAmountCoink =  (e) => {
      if(!isEmpty(errors)){
        delete errors.amount
      }
      setLoadingCommission(true)
      setCoinkAmount(e.target.value)
      clearInterval(interval)
    }
    useEffect(() => {
      if(coinkAmount === "") return
      if(Number(coinkAmount.replaceAll(".","")) < minWithdraw["COP"]){
        return setErrors({ amount: `El monto mínimo a retirar es ${formattedCoinkCurrency(minWithdraw["COP"], true)}` });
      }else{
        delete errors.amount
      }
    },[coinkAmount])
    return (
      <React.Fragment>
        <Loading loading={loading} />
        {!validate.phone ?
          <PrimaryButton 
            className='button-required'
            onClick={handleModalToValidatePhone}
            text='Verify your phone number to start trading. Click here to verify.'
          /> : validate['2FA'].includes('createwithdrawticket') && !userInfo.Use2FA ? (
            <PrimaryButton 
              className='button-required'
              onClick={()=>handleVerify('/settings/user')}
              text='You must enable your 2FA (Two-Factor Authentication) to start trading. Click here to continue.'
            />
          ) : authorizedLevel().includes(currentAccount.VerificationLevel) ? (
          <div className={fiatWithdrawFormComponentClasses()}>
            {!isCoink ? (
              <Form noValidate validated={inputs.validated} onSubmit={onSubmit}>
                <div className="bnx-v2 container-workflow withdraw mb-5">
                  <div className="important-alert">
                    {context.t(
                      "Important! Once you make the request, we will email you a link to confirm the transaction."
                    )}
                  </div>
                  <Row>
                    <Col xl="12" className='fiat-container-max'>
                      <FormField
                        required
                        type="text"
                        name="amount"
                        step="0.01"
                        label="Amount"
                        value={inputs.amount}
                        onChange={onChangeVal}
                        errors={errors}
                      />
                      <Row className="required-fields-helptext mb-0">
                        <Col xl="12">
                          <b className="send-help-text">
                            {context.t("In my wallet: {amount}", {
                              amount: currentBalance,
                            })}
                          </b>
                        </Col>
                      </Row>
                        <button type='button' className='ap-inline-btn__btn' onClick={()=>onChangeVal({ target: { value: currentBalance.replaceAll(',',''), name: 'amount' }})}>{context.t('Maximum')}</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <FormField
                        required
                        type="text"
                        name="full_name"
                        label="Full Name"
                        value={inputs.full_name}
                        onChange={firstInteration ? onInputChange : onInputChangeValidation}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div title={tooltipBank}>
                        <FormSelect
                          required
                          name="account_source"
                          label="Destination Account"
                          value={inputs.account_source || ""}
                          options={buildJsonOriginAccounts(fiatAccounts)}
                          onChange={(e) => {
                            const selectedBank = fiatAccounts.find(
                              (fa) => fa.id === e.target.value
                            );
                            setBankName(selectedBank.beneficiaryBankname);
                            setCurrencyTicket(selectedBank.currencyCode);
                            onFiatAccountChange(e);
                            showModal(selectedBank.currencyCode);
                            if(selectedBank.countryName !== "PERU"){
                              setIsInternational(true)
                              customValidations(inputs, false, true)
                            } else {
                              setIsInternational(false)
                              customValidations(inputs, false, false)
                            }
                          }}
                          errors={errors}
                        />
                      </div>
                      {empty(fiatAccounts) ? (
                        <p
                          onClick={() => {
                            cerrar();
                          }}
                          className="linked-container"
                        >
                          <span className="linked-a">
                            {context.t("You must add a banking account")}
                            {", "}
                          </span>
                          <b>
                            <Link to="/settings/accounts">
                              {context.t("Click here")}
                            </Link>
                          </b>
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                    {!empty(currencyTicket) &&
                      !empty(bankAssetName) &&
                      bankAssetName !== currencyTicket && (
                        <Col>
                          <div className="important-alert danger">
                            {context.t(
                              "Remember that the amount you will receive in your account will be subject to the exchange rate of  the bank."
                            )}
                          </div>
                        </Col>
                      )}
                  </Row>
                  <Row>
                    <Col xl="12">
                      <FormField
                        type="text"
                        name="comments"
                        label="Comments"
                        onChange={onInputChange}
                        value={inputs.comments || ""}
                        errors={errors}
                        disabled={disabledOption()}
                      />
                    </Col>
                  </Row>
                  <Row className='new-side-component'>
                    <SidePaneDetailsComponent 
                      title = '' 
                      items = {items}
                      info = {info}
                      classModifiers = 'withdraw'
                    />
                  </Row>
                  <Row className='checkbox-withdraw'>
                    <Col xl="12" className="check-style-1">
                      <FormSingleCheckbox
                        name="confirmWithdraw"
                        classNameContainer="pl-1"
                        label={""}
                        onChange={(e) => {
                          if(!disabledOption()) {
                            onCheckboxChangeValidation(e)
                            setFirstInteration(false)
                          }
                        }}
                        checked={
                          (!disabledOption() && inputs.confirmWithdraw) || false
                        }
                        errors={errors}
                      />
                      <span
                        className="check-style"
                        dangerouslySetInnerHTML={{
                          __html: context.t(
                            "I acknowledge and accept the terms and conditions expressed here for fiduciary withdrawals."
                          ),
                        }}
                      />
                    </Col>
                  </Row>
                  {isInmediatedOperation() ? (
                    <Row>
                      <Col xl="12" className="check-style-1">
                        <FormSingleCheckbox
                          name="confirmOpInterbank"
                          classNameContainer="pl-3"
                          label={""}
                          onChange={onCheckboxChangeValidation}
                          checked={inputs.confirmOpInterbank || false}
                          errors={errors}
                        />
                        <span className="check-style">
                          {context.t("I have read and accept")}
                          <b
                            class="confirm-iterbank"
                            onClick={() => {
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <div className="custom-ui">
                                      <p
                                        className="tiyle-alert"
                                        dangerouslySetInnerHTML={{
                                          __html: context.t(
                                            "Immediate <b>operations</b>"
                                          ),
                                        }}
                                      />
                                      <p>
                                        {context.t(
                                          "For immediate operations, we accept withdrawals up to USD 10,000 or S/ 30,000. If the order exceeds these limits, your funds will be available, at most, within 24 hours (from Monday to Thursday), or up to 72 hours, if it is generated on Friday; This schedule does not consider holidays."
                                        )}
                                      </p>
                                      <div className="btn-alert-container">
                                        <button
                                          className="btn-alert"
                                          onClick={() => {
                                            onClose();
                                          }}
                                        >
                                          {context.t("Accept")}
                                        </button>
                                      </div>
                                    </div>
                                  );
                                },
                              });
                            }}
                          >
                            {" "}
                            {context.t("consideration with Interbank")}
                          </b>{" "}
                          {context.t("regarding immediate operations.")}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col xl="12">
                      <Button
                        disabled={
                          disabledOption()
                            ? true
                            : !empty(inputs)
                            ? isInmediatedOperation()
                              ? !(
                                  inputs.confirmWithdraw &&
                                  inputs.confirmOpInterbank
                                )
                              : inputs.confirmWithdraw
                              ? false
                              : true
                            : true
                        }
                        variant="dark"
                        className="btn-Withdraw"
                        type="submit"
                      >
                        {context.t("Withdraw")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            ) : (
                blockedActions ?
                <div className='coink-alert-swiping-now'>
                  <p>{context.t(alertsContentCoink.swipingIsNowMessage)}</p>
                </div>
                : 
                <div>
               <Row>
                  <Col xl="12" style={{textAlign:"right", marginBottom:"1rem", textWrap:"nowrap"}}>
                    <b className="send-help-text">
                      {context.t("Available amount in my Banexcoin wallet: {amount}", {
                        amount: newCurrentBalance,
                      })}
                    </b>
                  </Col>
                 <Col xl="12" className='coink-amount'>
                 <InputElement {...{
                      inputType: 'currency',
                      values: {
                          required:true,
                          title: "Quantity",
                          value: coinkAmount.replaceAll('.','').replaceAll(',','.'),
                          name: 'amount',
                          type: 'text',
                          autoComplete: 'Off',
                          decimalScale: 2,
                          thousandSeparator: '.',
                          decimalSeparator: ',',
                          customClassName:"coink-input-amount"
                      },
                      actions: {
                          onChange:handleChangeAmountCoink,
                      },
                      error: errors.amount
                  }} />
                  <button type='button' className='coink-maximum-button' onClick={handleSetMaximumAmountInCoink}>{context.t('Maximum')}</button>
                 </Col>
                 <Col xl="12">
                    <b className="send-help-text">
                      {context.t("Maximum amount to withdraw Coink")}
                      <OverlayTrigger
                          key={'info4-tooltip'}
                          placement={'right'}
                          overlay={
                              <Tooltip id={`info4-tooltip`} className='tooltip-buysell'>
                                  <div className="buysell-text">{context.t("Depending on the operational limits of your Coink account")}</div>
                              </Tooltip>
                          }>
                          <img src={InformationIcon} alt='Information icon' width={15} style={{margin:"-3px .5rem 0"}}/> 
                      </OverlayTrigger>
                      {maximumLimitCoink === Infinity ? context.t("No limit") : formattedCoinkCurrency(maximumLimitCoink, true)}
                    </b>
                </Col>
               </Row>
               <div className='coink-withdraw-details'>
                <div>
                  <p>{context.t('Amount to withdraw')}</p>
                  <p className='one-line'>{`$ ${formattedCoinkCurrency(formattedCoinkAmountWithoutDots(coinkAmount))}`}</p>
                </div>
                <div>
                  <p>{context.t('Withdraw commission')} 
                  </p>
                  <p className='text-danger one-line'>{loadingCommission ? "Calculando..." : `$ ${formattedCoinkCurrency(commissionCoink)}`}</p>
                </div>
                <br />
                <div>
                  <p className='total-to-receive-coink'>{context.t('Amount to receive')}</p>
                  <p className='total-to-receive-coink one-line'>{loadingCommission ? "Calculando..." : `$ ${formattedCoinkCurrency((formattedCoinkAmountWithoutDots(coinkAmount) - commissionCoink)) }`}</p>
                </div>
               </div>
               <br />
               <Row className="justify-content-center mt-2 p-1-5">
                 <Col xl="12" style={{ padding: "0" }}>
                   {
                     <FormButtonV2
                       type="button"
                       onClick={handleNextStepWithdraw}
                       className="submit-button register-form coink-button deposit-withdraw"
                       disabled={
                         blockedActions ||
                         loadingWithdrawCoink ||
                         !twoFactorIsActiveToWithdraw ||
                          maximumLimitCoink <= 0 ||
                          formattedCoinkAmountWithoutDots(coinkAmount) <= 0 ||
                          !isEmpty(errors)
                      }
                     >
                       <p style={{ minWidth: "180px", margin: "0" }}>
                         <img
                           style={{ marginRight: "5px" }}
                           src={coinkLogo}
                           alt="coink logo"
                           width={25}
                         />{" "}
                         {context.t(`Withdraw to {provider}`, {
                           provider: "Coink",
                         })}
                       </p>
                     </FormButtonV2>
                   }
                 </Col>
               </Row>
             </div> 
              
            )}
          </div>
        ) : (
          <PrimaryButton 
            className='button-required'
            onClick={() => handleVerify('/settings/verification-level')}
            text='Verify your identity to start trading. Click here to verify.'
          />
        )}
      <Modal2fa onClick={handleCoinkWithdraw} show={show2faModal} setShow={setShow2faModal} handleReturn= {() => {
        setShow2faModal(false)
        setShowDetails(true)
        }} />
      <ModalConfirmationWithdrawCoink 
        show={showDetails} 
        handleClose={() => setShowDetails(false)} 
        handleContinue= {() => {
          setShowDetails(false)
          setShow2faModal(true)
        }}
        data={{
          amountToWithdraw: formattedCoinkCurrency(formattedCoinkAmountWithoutDots(coinkAmount)),
          commission: formattedCoinkCurrency(commissionCoink),
          totalToReceive: formattedCoinkCurrency((formattedCoinkAmountWithoutDots(coinkAmount) - commissionCoink)),
        }}
        
        />

      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

FiatWithdrawFormComponent.defaultProps = {
  allowNegative: false
};

FiatWithdrawFormComponent.propTypes = {
  allowNegative: PropTypes.bool,
  decimalPlaces: PropTypes.number
};

FiatWithdrawFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { 
    user: { 
      selectedAccountId,
      userInfo,
      accounts,
      verificationLevelConfigs 
    },
    globalConfig: { 
      minWithdraw
    },
    blockedActions,
    validate,
    component
  } = state;
  
  return { userInfo, accounts, selectedAccountId, verificationLevelConfigs, minWithdraw, blockedActions, validate, component};
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    confirmWithdraw: function confirmWithdraw (payload) {
      return dispatch(callAPI('CreateWithdrawTicket', payload)).then(res => {
        return res;
      })
    },
    selectPositionAndSave: function selectPositionAndSave(id) {
      dispatch(_selectPositionAndSave(id));
    },
    calculateFee: payload => {
      return dispatch(callAPI('GetWithdrawFee', payload)).then(function (res) {
        return res;
      });
    },
    redirect: path => {
      ownProps.history.push(path);
    },
    updateComponent: payload => {
      dispatch(_updateComponent(payload));
    },
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
    createStateSnackbar: function createStateSnackbar(payload) {
      return dispatch(_createStateSnackbar(payload));
    },
    resetStateSnackbar: function resetStateSnackbar() {
      return dispatch(_resetStateSnackbar());
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatWithdrawFormComponent));
