export const REGEX_LIST = {
    regexAlphanumeric: /^[a-z0-9]+$/i,
    regexNumeric: /^\d+$/,
    regexToValidateCharacters: /^\S{8,}$/,
    regexToValidateNumber: /^.*(\d){1,}.*$/,
    regexToValidateCapitalLetter: /^.*[A-Z]{1,}.*$/,
    regexToValidateLowerLetter: /^.*[a-z]{1,}.*$/,
    regexToValidateSymbol: /^.*[\u0021-\u002f\u003c-\u0040\u005b-\u005f\-\.]{1,}.*$/,
    regexToValidateUsername: /^[A-Za-z0-9]*$/,
    regexToValidateUsernameLength: /^[A-Za-z0-9]{5,20}$/,
    regexToValidateMail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    regexToValidatePassword: /^(?=.*\d)(?=.*[\u0021-\u002f\u003c-\u0040\u005b-\u005f\-\.])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/,
    regexToValidateSimpleMail: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
    regexToValidateFullName: /^[A-Za-zñÑ\s]+$/,
    regexOnlyNumbers: /^[0-9]*$/,
}