import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Popover from "../../../../components/common/Popover/Popover";
import InstrumentSelectorPopupComponent from "./InstrumentSelectorPopupComponent";
import { getBEMClasses } from "../../../../helpers/cssClassesHelper";
import arrowCircle from "../../../../images/down-arrow-circle.svg";
import { instrumentPropType } from "apex-web/lib/propTypes/instrumentPropTypes";
import "../exchangeHeader.css"
import isEmpty from "is-empty";
import ArrowCircleIcon from "./Arrow";
const baseClasses = getBEMClasses("instrument-selector");

class InstrumentSelectorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.popoverRef = null;
    this.updateLevel1 = true;
  }

  componentWillMount() {
    if (this.props.instruments.length === 0) return;

    const selectedInstrument = this.props.instruments.find((instrument) => {
      return (
        instrument.InstrumentId === this.props.selectedInstrument.InstrumentId
      );
    });

    if (selectedInstrument) return;

    const instrumentId = this.props.instruments[0].InstrumentId;
    this.props.selectInstrument(instrumentId);
  }

  shouldComponentUpdate() {
    return this.updateLevel1;
  }

  runLevel1Updates = () => (this.updateLevel1 = true);

  stopLevel1Updates = () => (this.updateLevel1 = false);

  onRowClick = (instrument) => {
    this.props.redirect(`/exchange/${instrument.Product1Symbol}_${instrument.Product2Symbol}`)
    this.popoverRef.toggleShow();
    this.props.selectInstrument(instrument.InstrumentId);
  };

  render() {
    const {
      fetching,
      instruments,
      selectedInstrument,
      selectedRow,
      level1,
      customClass,
      showFullNameInstrument,
      products = [],
      publicInstruments
    } = this.props;
    const selectedProduct =
      products.find(
        (product) => product.Product === selectedInstrument.Product1Symbol
      ) || {};

    const usdInstrumentWithSelectedCrypto = !isEmpty(instruments) && !isEmpty(selectedInstrument) ? instruments.find(inst => inst.InstrumentId === selectedInstrument.InstrumentId) || {} : {}
    const usdPriceOffer = !isEmpty(usdInstrumentWithSelectedCrypto) && !isEmpty(level1) && level1[usdInstrumentWithSelectedCrypto.InstrumentId] ? level1[usdInstrumentWithSelectedCrypto.InstrumentId].BestOffer : 0
    
    return (
      <Popover
        ref={(ref) => (this.popoverRef = ref)}
        trigger={(props) => (
          <button
            className={baseClasses("trigger")}
            {...props}
          >
            <div className="popup-selector-content">
              <div>
                {!isEmpty(selectedProduct) && (
                  <img
                    src={"/local/product-icons/" + selectedProduct.iconFileName}
                    alt="product img"
                    width={40}
                    height={40}
                  />
                )}
                <div>
                  <p className={baseClasses("symbol")}>
                    {selectedInstrument.Product1Symbol} /{" "}
                    {selectedInstrument.Product2Symbol}
                  </p>
                  <span className="secondary-text">
                    {selectedInstrument.Product1FullName}
                  </span>
                </div>
              </div>
              <div>
                <div className="ml">
                  <p className={`principal-value ${true ? "green" : "red"}`}>
                    {usdPriceOffer && Intl.NumberFormat("en-US",
                    {
                      currency: "USD",
                      style: "currency",
                    }).format(usdPriceOffer)}
                  </p>
                </div>
                <ArrowCircleIcon arrowCircle={arrowCircle}/>
              </div>
            </div>
          </button>
        )}
        content={
          <InstrumentSelectorPopupComponent
            onRowClick={this.onRowClick}
            fetching={fetching}
            instruments={instruments.filter(item => item.InstrumentId !== selectedRow.value)}
            selectedRow={selectedRow}
            level1={level1}
            customClass={customClass}
            showFullNameInstrument={showFullNameInstrument}
            publicInstruments={publicInstruments}
          />
        }
        customClass="instrument-selector-popover"
        drawArrow
      />
    );
  }
}

InstrumentSelectorComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

InstrumentSelectorComponent.propTypes = {
  level1: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  selectedInstrument: instrumentPropType,
  selectedRow: PropTypes.object.isRequired,
};

export default InstrumentSelectorComponent;
