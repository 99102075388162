import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APIcon from 'apex-web/lib/components/common/APIcon';
import './SendReceiveFormTabs.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var bemClasses = getBEMClasses('send-receive-tabs');

var SendReceiveTabs = (function(_React$Component) {
  _inherits(SendReceiveTabs, _React$Component);

  function SendReceiveTabs() {
    _classCallCheck(this, SendReceiveTabs);

    return _possibleConstructorReturn(
      this,
      (
        SendReceiveTabs.__proto__ || Object.getPrototypeOf(SendReceiveTabs)
      ).apply(this, arguments)
    );
  }

  _createClass(SendReceiveTabs, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          isSend = _props.isSend,
          toggleTab = _props.toggleTab;

        return React.createElement(
          'header',
          { className: bemClasses() },
          React.createElement(
            'div',
            {
              className: bemClasses('tab', { selected: !isSend }),
              onClick: function onClick() {
                return isSend && toggleTab();
              }
            },
            React.createElement(APIcon, {
              name: 'buy',
              customClass: bemClasses('tab-icon')
            }),
            React.createElement(
              'span',
              { className: bemClasses('tab-text') },
              this.context.t('Deposit')
            )
          ),
          React.createElement(
            'div',
            {
              className: bemClasses('tab', { selected: isSend }),
              onClick: function onClick() {
                return !isSend && toggleTab();
              }
            },
            React.createElement(APIcon, {
              name: 'sell',
              customClass: bemClasses('tab-icon')
            }),
            React.createElement(
              'span',
              { className: bemClasses('tab-text') },
              this.context.t('Withdraw')
            )
          )
        );
      }
    }
  ]);

  return SendReceiveTabs;
})(React.Component);

SendReceiveTabs.propTypes = {
  isSend: PropTypes.bool.isRequired,
  toggleTab: PropTypes.func.isRequired
};

SendReceiveTabs.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveTabs;
