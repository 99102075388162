import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import empty from 'is-empty';

const FormButtonV2 = (props, context) => {
  const { type, onClick, className, children, variant, disabled, ...rest } = props;
  try {
    let propInput = {
      type: !empty(type) ? type : 'button',
      className:
        'v2-field btn-bnxv2' + (!empty(className) ? ` ${className}` : ''),
      onClick,
      disabled
    };

    return (
      <Button variant={variant} {...propInput} {...rest}>
        {children}
      </Button>
    );
  } catch (error) {
    console.log('error FormButtonV2', error);
    return <React.Fragment />;
  }
};

FormButtonV2.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
  disabled: PropTypes.bool
};

export default FormButtonV2;
