import React, { memo } from 'react';

const ArrowCircleIcon = memo(({arrowCircle}) => {
  return (
    <img
      src={arrowCircle}
      alt="arrow circle icon"
      width={30}
      height={30}
      style={{ marginLeft: "-2px" }}
    />
  );
});

export default ArrowCircleIcon;