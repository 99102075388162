import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import WalletRowContainer from './WalletRowContainer';
import { DepositReferenceIdModalContainer } from '../Retail/RetailSidePanes/FiatDeposit';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import isEmpty from 'is-empty';
import './WalletRowList.css';

const walletRowListClasses = getBEMClasses('wallet-row-list');

const WalletRowListComponent = function WalletRowListComponent(props, context) {
  if (props.fetching) {
    return React.createElement(Spinner, { customClass: walletRowListClasses });
  }

  const items = props.products.map(function(item) {
    return React.createElement(WalletRowContainer, {
      key: item.ProductId,
      productId: item.ProductId,
      detailsLink: props.detailsLink
    });
  });

  return React.createElement(
    'div',
    { className: walletRowListClasses() },
    items,
    React.createElement(DepositReferenceIdModalContainer, null)
  );
};

WalletRowListComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { productsByLevel } = state
  const products = productPositionAssetsSelector(state);
  const newProducts = (!isEmpty(products) && !isEmpty(productsByLevel)) ? products.filter(e => productsByLevel.includes(e.ProductSymbol) && (Number(e.AvailableBalance.replaceAll(',', '') ) <= 0 || e.AvailableBalance === "-") ) : [];
  return { products: newProducts || [] };
};

export default connect(mapStateToProps)(WalletRowListComponent);
