import { connect } from 'react-redux';
import ManualDepositWorkflowComponent from './ManualDepositWorkflowComponent';
import { depositProductSelector } from 'apex-web/lib/redux/selectors/depositWithdrawalSelectors';
import isEmpty from 'is-empty';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var onSubmit = _ref.onSubmit,
    submitting = _ref.submitting;
  return {
    product: depositProductSelector(state),
    onSubmit: onSubmit,
    submitting: submitting,
    coinkBlockedActions: !isEmpty(state.globalConfig) ? state.globalConfig.coinkBlockTimes : {},
  };
};

export default connect(mapStateToProps)(ManualDepositWorkflowComponent);
