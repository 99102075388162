import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import empty from 'is-empty';

const FormUploadFile = (props, context) => {
  const {
    label,
    required,
    type,
    placeholder,
    name,
    step,
    id,
    onChange,
    accept,
    errors
  } = props;
  const [fileName, setFileName] = useState('');
  try {
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      required: required ? true : false,
      type: !empty(type) ? type : 'text',
      placeholder: !empty(placeholder)
        ? context.t(placeholder)
        : !empty(label)
          ? context.t(label)
          : '',
      name,
      step,
      accept
    };
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
      hiddenFileInput.current.click();
    };
    const onChangeCustom = e => {
      let files = e.target.files;
      if (!empty(files)) {
        let name = '';
        if (!empty(files[0])) {
          name = files[0].name;
        }
        setFileName(name);
      }
      if (typeof onChange === 'function') {
        onChange(e);
      }
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={'v2-field' + (!empty(errors[name]) ? ' invalid' : ' valid')}>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)} {required ? <b className="important">*</b> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        <Button className="form-control" onClick={handleClick}>
          {!empty(fileName) ? fileName : context.t('Upload a file')}
        </Button>
        <Form.Control
          {...propInput}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={onChangeCustom}
        />
        {!empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormUploadFile', error);
    return <React.Fragment />;
  }
};

FormUploadFile.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormUploadFile;
