import { connect } from 'react-redux';
import InstrumentSelectorComponent from './InstrumentSelectorComponent';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import {
  selectedInstrumentSelector,
  instrumentSelectorInstruments
} from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { withRouter } from 'react-router-dom';

const mapStateToProps = function mapStateToProps(state) {
  const isMarginActive = isMarginActiveSelector(state);
  let instrument = {};
  if (isMarginActive && !state.margin.instrument.fetching) {
    instrument = state.margin.instrument;
  } else {
    instrument = state.apexCore.instrument;
  }
  const _instrument = instrument,
    fetching = _instrument.fetching;

  const selectedInstrument = selectedInstrumentSelector(state);
  return {
    products: productPositionAssetsSelector(state),
    level1: state.apexCore.level1,
    instruments: instrumentSelectorInstruments(state),
    selectedInstrument: selectedInstrument,
    selectedRow: {
      key: 'InstrumentId',
      value: selectedInstrument.InstrumentId
    },
    currentUserName: state.user.userInfo.UserName,
    fetching: fetching
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    redirect: path => {
      ownProps.history.push(path);
    },
    selectInstrument: instrumentId => dispatch(selectInstrument(instrumentId))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentSelectorComponent));
