import React, { useState, useEffect } from 'react';
import KYCSettings from '../components/KYCSettings';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import './SettingsPage.css';
import './KYCPage.css';
import { Link } from 'react-router-dom';
import { Container, Row, Button } from 'react-bootstrap';
import { openSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import Loading from '../components/Loading';
import GlobalHooks from './GlobalHooks';

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

const KYCPage = (props, context) => {
  const { selectedAccountId, userInfo, accounts } = props;

  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>Loading..</div>
      </React.Fragment>
    );
  }

  let users = ['david.arrarte', 'lc', 'msrangelh', 'thespeed94'];
  var check = users.includes(userInfo.UserName);
  let currentAccount = [];
  for (let i = 0; i < accounts.length; i++) {
    var cuentaEncontrada = accounts[i];
    if (cuentaEncontrada.AccountId == selectedAccountId) {
      currentAccount.push(cuentaEncontrada);
    }
  }

  const [loading, setLoading] = useState(0);
  const [infoMaster, setInfoMaster] = useState({
    requestedLevel: -1,
    status: 'OPEN',
    action: 'OPEN',
    titleBtn: 'Start Verification'
  });

  /* inicia modal */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /* inicia modal intermedio */
  const [showIntermedio, setShowIntermedio] = useState(false);
  const handleIntermedioClose = () => setShowIntermedio(false);
  const handleIntermedioShow = () => setShowIntermedio(true);
  /* inicia modal maestro */
  const [showMaestro, setShowMaestro] = useState(false);
  const handleMaestroClose = () => setShowMaestro(false);
  const handleMaestroShow = () => setShowMaestro(true);

  const account = currentAccount[0];

  const seo = {
    title: context.t('Verification Levels') + ' - Banexcoin',
    description: 'Comprar con Binance',
    url: 'https://www.banexcoin.com/binance/',
    image:
      'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
  };

  const config = {
    className: 'center123',
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  return account ? (
    <React.Fragment>
      <Loading loading={loading} />
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description }
        ]}>
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <Container className="pd2">
        <div className="kyc-settings__header newheader-block">
          <div className="kyc-settings__header-text">
            {context.t('Current Verification Level')}
          </div>
          <div className="kyc-settings__description">
            {context.t(
              'For your security, some of our services require you to provide basic or additional levels of verification.'
            )}
          </div>
          <div className="kyc-settings__description">
            {context.t(
              'To verify your account or to check what your current verification level is, see below.'
            )}
          </div>
        </div>
        <div className="kyc-pagev2 p-5">
          <GlobalHooks config={config} className='card-config-v2'/>
        </div>
      </Container>
    </React.Fragment>
  ) : (
    'Cargando KYC'
  );
};

KYCPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(KYCPage);
