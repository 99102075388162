import React from 'react';
import ScheduleReportFormContainer from './ScheduleReportForm/ScheduleReportFormContainer';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

const baseClasses = getBEMClasses('schedule-report');

const ScheduleReportComponent = function ScheduleReportComponent() {
  return React.createElement(
    'div',
    { className: baseClasses() },
    React.createElement(ScheduleReportFormContainer, null)
  );
};

export default ScheduleReportComponent;