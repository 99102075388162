import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  DropdownButton,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from '../../hooks/formHooks';
import Loading from '../../components/Loading';
import StepWizard from 'react-step-wizard';
import Header from './KYCComponent/KYCV2Header';
import './KYCV2Page.css';
import KYCErrorImg from './../../images/BanexcoinCoink_error.png';

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts, instruments, level1 };
};

const KYCV2SuccessPage = (props, context) => {
  try {
    const { userInfo, selectedAccountId, accounts } = props;
    document.title = `Banexcoin - ${context.t(
      "Whoops! There's been a problem"
    )}`;
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
      transitions: {
        enterRight: `animated enterRight`,
        enterLeft: `animated enterLeft`,
        exitRight: `animated exitRight`,
        exitLeft: `animated exitLeft`,
        intro: `animated intro`
      }
    });

    const onStepChange = stats => {};

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(accounts) && !empty(selectedAccountId)) {
        }
      },
      [userInfo, accounts, selectedAccountId]
    );
    const setInstance = SW => {
      setState({
        ...state,
        SW
      });
    };

    return (
      <React.Fragment>
        <div className="v2-bnx">
          <Loading loading={loading} />
          <Header />
          <div className="container pb-5">
            <Row>
              <Col md={{ span: 6, offset: 3 }} />
            </Row>
            <div className="v2-step">
              <div className="v2-box w-5">
                <div className="step-bnx text-center">
                  <img src={KYCErrorImg} className="v2-image" />
                  <h1 className="pt-5 pb-4">
                    {context.t("Whoops! There's been a problem")}
                  </h1>
                  <h3 className="pb-3">{context.t('Unknown error')}</h3>
                  <div className="pt-5 pb-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2SuccessPage error', e);
    return <div>Error:</div>;
  }
};

KYCV2SuccessPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCV2SuccessPage);
