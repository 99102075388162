import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import empty from 'is-empty';
import { Form, Row, Col } from 'react-bootstrap';
import APIcon from '../../../../components/common/APIcon';
import { REGEX_LIST } from '../../../../constants'
import { toast } from 'react-toastify';
import { FormButtonV2 } from '../../../../components/common/BnxFormsComponents';
import { useForm } from '../../../../hooks/formHooks';
import InputElement from '../../Components/InputElement/InputElement'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import diamond from '../../../../images/diamond.png'
import diamond2 from '../../../../images/diamond2.png'
import isEmpty from 'is-empty';
import ClaimsPageController from './ClaimPageController';
import ReCAPTCHA from "react-google-recaptcha";
function ClaimPageForm(props, context) {
  const history = useHistory()
  const { captcha } = props
  const [errorsValidation, setErrorValidation] = useState({})
  const [step, setstep] = useState(1);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false)
  const initialState = {
    fullname: "",
    address: "",
    documentNumber: "",
    phone: "",
    email: "",
    service: "",
    serviceClaimCurrency: "",
    serviceClaimAmount: "",
    serviceDescription: "",
    claimType: "",

  }
  const {
    inputs,
    errors,
    setErrors,
    onSubmitSimpleWithoutValidation,
    onDefaultChange,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);

  const titlesSection = ["Identification of complaining consumer", "Identification of the contracted property and detail"]

  const arrayIncludeValue = (value, array) => !!array.find(item => item.value === value)

  const handleChangeInput = (e) => {
    onDefaultChange(e)
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
    delete errorsValidation[e.target.name]
  }

  const documentNumberValidation = (inputs, errors) => {
    if (!empty(inputs.documentNumber)) {
      if (inputs.documentType === 'RP') {
        if (!REGEX_LIST.regexAlphanumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Document number must contain only letters and numbers';
        } else if (inputs.documentNumber.length < 9 || inputs.documentNumber.length > 12) {
          errors.documentNumber = 'Document number must contain at least 9 and at most 12 characters';
        }
      }

      if (inputs.documentType === 'ID') {
        if (!REGEX_LIST.regexNumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Document number must contain only numbers';
        } else if (inputs.documentNumber.length !== 8) {
          errors.documentNumber = 'Document number must contain 8 numbers';
        }
      }

      if (inputs.documentType === 'PP') {
        if (!REGEX_LIST.regexAlphanumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Document number must contain only letters and numbers';
        } else if (inputs.documentNumber.length < 6 || inputs.documentNumber.length > 12) {
          errors.documentNumber = 'Document number must contain at least 6 and at most 12 characters';
        }
      }
    }
    return errors
  }

  const emailValidation = (inputs, errors) => {
    if (!empty(inputs.email)) {
      const validateEmail = REGEX_LIST.regexToValidateMail.test(String(inputs.email).toLowerCase());
      if (!validateEmail) {
        errors.email = 'Invalid email address';
      }
    }
    return errors
  }

  const lengthValidation = (inputs, name, errors) => {
    if (!empty(inputs[name])) {
      if (inputs[name].length > 250 || inputs[name].length < 5) {
        errors[name] = "Must contain at least 5 and at most 250 characters"
      }
    }
    return errors
  }
  
  const phoneValidation = (inputs, errors) => {
    if (isEmpty(inputs.phone)) return errors
    if (inputs.phonePrefix === "51") {
      if (inputs.phone[0] != 9) {
        errors.phone = "Phone number must start with digit 9"
      } else if (inputs.phone.length !== 9) {
        errors.phone = 'Must contain 9 characters'
      }
    } else {
      if (inputs.phone.length < 7 || inputs.phone.length > 11) {
        errors.phone = 'Must contain at least 7 and at most 11 characters'
      }
    }
    return errors
  }

  const fullnameValidation = (inputs, errors) => {
    if (!isEmpty(inputs.fullname)) {
      if (inputs.fullname.length < 5) {
        errors.fullname = 'Must contain at least 5 characters'
      }
    }
    return errors
  }

  const addressValidation = (inputs, errors) => {
    if (!isEmpty(inputs.address)) {
      if (inputs.address.length < 5) {
        errors.address = 'Must contain at least 5 characters'
      }
    }
    return errors
  }

  const serviceValidation = (inputs, errors) => {
    if (isEmpty(inputs.service)) return errors
    if (
      inputs.service.includes("FIAT") && !arrayIncludeValue(inputs.serviceClaimCurrency, optionsFIATcurrencyTypes) ||
      inputs.service.includes("Crypto") && !arrayIncludeValue(inputs.serviceClaimCurrency, optionsCRYPTOcurrencyTypes)
    ) {
      errors.serviceClaimCurrency = "Required field"
    }
    return errors
  }

  function customValidations(inputs) {
    let errors = {};
    if (step === 1) {
      errors = fullnameValidation(inputs, errors)
      errors = addressValidation(inputs, errors)
      errors = documentNumberValidation(inputs, errors)
      errors = emailValidation(inputs, errors)
      errors = phoneValidation(inputs, errors)
    }
    if (step === 2) {
      errors = serviceValidation(inputs, errors)
      errors = lengthValidation(inputs, "serviceDescription", errors)
    }
    setErrors(errors);
    return errors;
  };

  const defaultInputsValidation = () => {
    let thereAreEmptyInput = false
    if (step === 1) {
      if (empty(inputs.fullname)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.address)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.documentType)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.documentNumber)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.phone)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.email)) {
        thereAreEmptyInput = true;
      }

    } else {
      if (empty(inputs.service)) {
        thereAreEmptyInput = true;
      }
      if (inputs.service !== "Level increase") {
        if (empty(inputs.serviceClaimCurrency)) {
          thereAreEmptyInput = true;
        }
        if (empty(inputs.serviceClaimAmount)) {
          thereAreEmptyInput = true;
        }
      }
      if (empty(inputs.serviceDescription)) {
        thereAreEmptyInput = true;
      }
      if (isEmpty(token)) {
        thereAreEmptyInput = true
      }
    }
    return thereAreEmptyInput
  }

  const fullnameInputProps = {
    values: {
      title: 'Fullname',
      value: inputs.fullname || '',
      name: 'fullname',
      type: 'text'
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.fullname || errorsValidation["fullname"]
  };

  const addressInputProps = {
    values: {
      title: 'Address',
      value: inputs.address || '',
      name: 'address',
      type: 'text'
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.address || errorsValidation["address"]
  };

  const optionsDocument = [
    { value: 'ID', label: context.t('National identity document') },
    { value: 'RP', label: context.t('Residence permit') },
    { value: 'PP', label: context.t('Passport') }
  ];

  const documentTypeInputProps = {
    values: {
      title: 'Document type',
      value: inputs.documentType || '',
      name: 'documentType',
      type: 'select',
      options: optionsDocument
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.documentType || errorsValidation["documentType"]
  };

  const documentNumberInputProps = {
    values: {
      title: 'Document number',
      value: inputs.documentNumber || '',
      name: 'documentNumber',
      type: 'text'
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.documentNumber || errorsValidation["documentNumber"]
  };


  const phoneInputProps = {
    value: inputs.completePhone || '',
    name: 'phone',
    placeholder: context.t("Enter your phone number"),
    onChange: ({ value, country }) => {
      setInputs({
        ...inputs,
        phonePrefix: country.dialCode,
        phone: value.replace(country.dialCode, ""),
        completePhone: value
      })
    },
    error: errors.phone
  };

  const emailInputProps = {
    values: {
      title: 'Email',
      value: inputs.email || '',
      name: 'email',
      type: 'email'
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.email || errorsValidation["email"]
  };

  const optionsServices = [
    {
      value: "FIAT deposit",
      label: "FIAT deposit"
    },
    {
      value: "FIAT withdrawal",
      label: "FIAT withdrawal"
    },
    {
      value: "Crypto Deposit",
      label: "Crypto deposit"
    },
    {
      value: "Crypto Withdrawal",
      label: "Crypto withdrawal"
    },
    {
      value: "Crypto buying",
      label: "Crypto buy"
    },
    {
      value: "Crypto selling",
      label: "Crypto sell"
    },
    {
      value: "Level increase",
      label: "Level increase"
    },
    {
      value: "Others",
      label: "Others"
    },
  ];
  const serviceInputProps = {
    values: {
      title: 'Service type',
      value: inputs.service || '',
      name: 'service',
      type: 'select',
      options: optionsServices
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.service || errorsValidation["service"]
  };

  const optionsFIATcurrencyTypes = [
    { value: "PEN", label: "PEN" },
    { value: "USD", label: "USD" },
  ]

  const optionsCRYPTOcurrencyTypes = [
    { value: "BTC", label: "BTC" },
    { value: "USDC", label: "USDC" }
  ]

  const validationToDisabledCurrencyFields = (isEmpty(inputs.service) || inputs.service === "Level increase")

  const optionsByServiceType = () => {
    let currenciesByType = []
    if (isEmpty(inputs.service)) return currenciesByType
    if (inputs.service.includes("FIAT")) {
      currenciesByType = [...optionsFIATcurrencyTypes]
    } else if (inputs.service.includes("Crypto")) {
      currenciesByType = [...optionsCRYPTOcurrencyTypes]
    } else {
      currenciesByType = [...optionsFIATcurrencyTypes, ...optionsCRYPTOcurrencyTypes]
    }
    return currenciesByType
  }

  const serviceClaimCurrencyInputProps = {
    values: {
      disabled: validationToDisabledCurrencyFields,
      title: 'Currency type',
      value: validationToDisabledCurrencyFields ? '' : inputs.serviceClaimCurrency,
      name: 'serviceClaimCurrency',
      type: 'select',
      options: optionsByServiceType()
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.serviceClaimCurrency || errorsValidation["serviceClaimCurrency"]
  };
  const serviceClaimAmountInputProps = {
    values: {
      disabled: validationToDisabledCurrencyFields,
      title: 'Amount to claim',
      value: validationToDisabledCurrencyFields ? '' : inputs.serviceClaimAmount,
      name: 'serviceClaimAmount',
      type: 'number',
      step: 0.01
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.serviceClaimAmount || errorsValidation["serviceClaimAmount"]
  };
  const serviceDescriptionInputProps = {
    values: {
      title: 'Description',
      value: inputs.serviceDescription || '',
      name: 'serviceDescription',
      type: 'textarea',
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.serviceDescription || errorsValidation["serviceDescription"]
  };

  const handleSendNotification = async (newInputs) => {
    try {
      const { data } = await ClaimsPageController.sendNotification({ data: newInputs })
      if (data.success) {
        toast.success(context.t(data.message), { position: "top-center" })
        setTimeout(() => {
          history.push("/login")
        }, 1000)
      } else {
        toast.warn(context.t(data.message), { position: "top-center" })
      }
    } catch (error) {
      if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data)) {
        const dataError = error.response.data
        toast.warn(context.t(dataError.message), { position: "top-center" })
        setErrorValidation(dataError.data)
        if (!isEmpty(dataError.data.email) || !isEmpty(dataError.data.documentType) || !isEmpty(dataError.data.phone)) {
          setstep(1)
        }
      }
    }
  }
  const signupFlow = async inputs => {
    setLoading(true);
    try {
      let newInputs = { ...inputs }
      const claimType = document.getElementsByName("claimType")
      claimType.forEach(item => {
        if (item.checked) {
          newInputs["claimType"] = item.value
        }
      })
      newInputs["language"] = localStorage.getItem("language")
      newInputs["gRecaptcha"] = token
      newInputs["serviceClaimAmount"] = Number(inputs.serviceClaimAmount)
      if (inputs.service === "Level increase") {
        newInputs["serviceClaimAmount"] = ""
        newInputs["serviceClaimCurrency"] = ""
      }
      delete newInputs["completePhone"]
      await handleSendNotification(newInputs, token)
    } catch (error) {
      toast.warn(error.response.data.message, { position: "top-center" })
    }
  };

  async function onSubmitCallback(inputs) {
    const newErrors = customValidations(inputs)
    if (!empty(newErrors)) return
    if (step === 1) {
      setstep(2);
    } else {
      await signupFlow(inputs);
    }
  };

  function onSubmitRegister(e) {
    e.preventDefault();
    onSubmitSimpleWithoutValidation(e);
  }

  useEffect(() => {
    if (!empty(errors)) {
      customValidations(inputs)
    }
  }, [inputs])

  return (
    <div className="bnxv2-box-rounded fix-height claim-page">
      <img className="diamond" src={diamond} alt="aux diamond background" />
      <img
        className="diamond2"
        src={diamond2}
        alt="aux diamond background"
      />
      <React.Fragment>
        <Form
          validated={inputs.validate}
          onSubmit={onSubmitRegister}
          className="form-container-v2">
          {(step === 2) && (
            <span className="arrowback" onClick={() => setstep(step - 1)}>
              <APIcon name="arrowBack" />
            </span>
          )}
          <Row className="justify-content-center text-left">
            <Col xl="10">
              <h1 className='title-register-section'>{context.t(titlesSection[step - 1])}</h1>
            </Col>
          </Row>
          {step === 1 && (
            <React.Fragment>
              <Row className="justify-content-center mt-0">
                <Col xl="10">
                  <InputElement {...fullnameInputProps} />
                </Col>
                <Col xl="10">
                  <InputElement {...addressInputProps} />
                </Col>
                <Col xl="10">
                  <InputElement {...documentTypeInputProps} />
                </Col>
                <Col xl="10">
                  <InputElement {...documentNumberInputProps} />
                </Col>
                <Col xl="10">
                  <div className='phone-input-container'>
                    <PhoneInput
                      containerClass={!isEmpty(errors.phone) ? 'error-phone' : ''}
                      inputProps={{ name: phoneInputProps.name, placeholder: phoneInputProps.placeholder }}
                      country={'pe'}
                      value={phoneInputProps.value}
                      onChange={(value, country) => {
                        phoneInputProps.onChange({ value, country })
                      }}
                    />
                    {(errors && errors.phone) && <span className='message-error'>{context.t(errors.phone)}</span>}
                  </div>
                </Col>
                <Col xl="10">
                  <InputElement {...emailInputProps} />
                </Col>
              </Row>
            </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <Row className="justify-content-center mt-0">
                <Col xl="10">
                  <InputElement {...serviceInputProps} />
                </Col>
                <Col xl="10">
                  <InputElement {...serviceClaimCurrencyInputProps} />
                </Col>
                <Col xl="10">
                  <InputElement {...serviceClaimAmountInputProps} />
                </Col>
                <Col xl="10">
                  <span className='title-radio-button-group'>{context.t("Select the notification type")}</span>
                  <div className='radio-button-group'>
                    <label>
                      <span>{context.t("Claim")}</span>
                      <input defaultChecked value="CLAIM" type="radio" name="claimType" />
                      <i className='customize-radio-btn'></i>
                    </label>
                    <label>
                      <span>{context.t("Complain")}</span>
                      <input value="COMPLAIN" type="radio" name="claimType" />
                      <i className='customize-radio-btn'></i>
                    </label>
                  </div>
                </Col>
                <Col xl="10">
                  <InputElement {...serviceDescriptionInputProps} />
                </Col>
                <Col xl="10" className='captcha-container'>
                  <ReCAPTCHA
                    sitekey={captcha}
                    onChange={(token) => setToken(token)}
                    theme="dark"
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row className="justify-content-center mt-2 p-1-5">
            <Col xl="10">
              <FormButtonV2
                disabled={defaultInputsValidation() || loading}
                type="submit"
                variant={empty(errors) ? 'dark' : 'light'}
                className="submit-button register-form">
                {context.t(step === 1 ? "Next" : "Send notification")}
              </FormButtonV2>
            </Col>
          </Row>
        </Form>
        <Row className="mt-5">
          <Col xl="12" className="bottom-box-v2 normal text-center">
            <h5>{context.t("Recipient (consumer, supplier or INDECOPI as appropriate)")}</h5>
          </Col>
        </Row>
      </React.Fragment>

    </div>
  );
}

ClaimPageForm.contextTypes = {
  t: PropTypes.func
};


const mapStateToProps = function mapStateToProps(state) {
  return {
    language: state.i18nState.lang
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    toRedirect: path => {
      ownProps.history.push(path || '/');
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClaimPageForm)
);
