export const setLimits = (payload) => {
    return {
        type: 'SET_LIMITS',
        payload
    };
};

export const setLimitsByProductAndLevel = (payload) => {
    return {
        type: 'SET_LIMITS_BY_PRODUCT_AND_LEVEL',
        payload
    };
}