import React from 'react';
import PropTypes from 'prop-types';
import APVerticalScroller from 'apex-web/lib/components/common/APVerticalScroller/APVerticalScroller';
import APInlineButton from 'apex-web/lib/components/common/APInlineButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import DataTable from './DataTable';

import 'apex-web/lib/styles/components/TradeComponent.css';
import './ordersBook.css';

const tradeComponentClasses = getBEMClasses('trade-component');
const orderBookClasses = getBEMClasses('orderbook');
const flexTable = getBEMClasses('flex-table');

export default class OrderBookComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showCenterButton: false 
    };
    this.onClickCenter = this.onClickCenter.bind(this);
    this.resizeEventListener = this.resizeEventListener.bind(this);
    this.onScrollRestChange = this.onScrollRestChange.bind(this);
    this.handleScrollRestChange = this.handleScrollRestChange.bind(this);

    this.buyScroller = React.createRef();
    this.sellScroller = React.createRef();
  }

  onClickCenter(overrideUserScrolled = false) {
    if (this.sellScroller && this.buyScroller) {
      const scrollToRest = () => {
        this.sellScroller.current.scrollToRest();
        this.buyScroller.current.scrollToRest();
      };

      if (overrideUserScrolled)
        return this.sellScroller.current.resetUserScrolled(() =>
          this.buyScroller.current.resetUserScrolled(scrollToRest)
        );
      scrollToRest();
    }
  }

  resizeEventListener = () => this.onClickCenter(true);

  componentDidUpdate(prevProps) {
    this.onClickCenter(
      this.props.selectedInstrumentId !== prevProps.selectedInstrumentId
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeEventListener);
    this.onClickCenter();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEventListener);
  }

  onScrollRestChange(value) {
    setTimeout(this.handleScrollRestChange, 230);
  }

  handleScrollRestChange() {
    if (!!this.sellScroller) {
      const sellIsAtRest = this.sellScroller.current.getIsAtRest();
      const buyIsAtRest = this.buyScroller.current.getIsAtRest();
      const showCenterButton = !sellIsAtRest || !buyIsAtRest;
      this.setState({
        showCenterButton
      });
    }
  }

  render() {
    const {
      context,
      onScrollRestChange,
      props: { spread } 
    } = this;

    const {
      selectedInstrument,
      cancelOrders,
      level2: { buy, sell },
      buyPriceClicked,
      buyQuantityClicked,
      sellPriceClicked,
      sellQuantityClicked,
      fetching,
    } = this.props;

    return (
      <div className={tradeComponentClasses()}>
        <div className={`${flexTable()} ${orderBookClasses()}`}>
          <div className={`${flexTable('body')} ${orderBookClasses('body')}`}>
            <div className={orderBookClasses('order-book-body')}>
              <p className='order-book-section-title'>{context.t("Price at which you can sell")}</p>
              <APVerticalScroller
                ref={this.sellScroller}
                restPosition="bottom"
                onScrollRestChange={onScrollRestChange}
                customClass={orderBookClasses(
                  'vertical-scroller-body',
                  isFirefox() ? 'firefox' : ''
                )}>
                <div className="orderbook__table-filler">
                  <DataTable
                    quantityDecimalPlaces={
                      this.props.convertedQuantityIncrement
                    }
                    priceDecimalPlaces={this.props.convertedPriceIncrement}
                    selectedInstrument={selectedInstrument}
                    cancelOrders={cancelOrders}
                    onPriceClick={sellPriceClicked}
                    onQuantityClick={sellQuantityClicked}
                    level2Data={sell}
                    classModifier={'sell'}
                    fetching={fetching}
                    />
                </div>
              </APVerticalScroller>
              <p className='order-book-section-title'>{context.t("Price at which you can buy")}</p>
              <APVerticalScroller
                ref={this.buyScroller}
                restPosition="top"
                onScrollRestChange={onScrollRestChange}
                customClass={orderBookClasses(
                  'vertical-scroller-body',
                  isFirefox() ? 'firefox' : ''
                  )}>
                <DataTable
                  quantityDecimalPlaces={this.props.convertedQuantityIncrement}
                  priceDecimalPlaces={this.props.convertedPriceIncrement}
                  selectedInstrument={selectedInstrument}
                  cancelOrders={cancelOrders}
                  onPriceClick={buyPriceClicked}
                  onQuantityClick={buyQuantityClicked}
                  level2Data={buy}
                  classModifier={'buy'}
                  fetching={fetching}
                />
              </APVerticalScroller>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderBookComponent.defaultProps = {
  hideBulkCancelButtons: false,
  level2: {
    buy: [],
    sell: [],
    short: [],
    unknown: []
  }
};

OrderBookComponent.contextTypes = {
  t: PropTypes.func.isRequired
};
