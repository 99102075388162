import React from 'react';
import PropTypes from 'prop-types';
import BanexcoinCoinl_notfound from './../../images/BanexcoinCoinl_notfound.png';
import { Link } from 'react-router-dom';

const EasyBuyPageVerify = (props, context) => {
  try {
    return (
      <div className="box-error">
        <h3>{context.t('You need to verify your account')}</h3>
        <img src={BanexcoinCoinl_notfound} className="error-image" />
        <Link className="btn btn-banexcoin" to="/settings/verification-level/">
          {context.t('Verify account')}
        </Link>
      </div>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

EasyBuyPageVerify.contextTypes = {
  t: PropTypes.func.isRequired
};

export default EasyBuyPageVerify;
