import React from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
import FCSpanish from './FeesCommissions/FCSpanish';
import FCEnglish from './FeesCommissions/FCEnglish';
import HeaderB from './HeaderB';
import FooterB from './FooterB';
import './GlobalHooksIntrument';
import '../styles/components/common/StandaloneForm.css';

import './FeesCommissionsPage.css';

const lang = localStorage.getItem('language');

const FeesCommissionComponent = (props, context) => (
  <React.Fragment>
    <div className="elementor">
      <style>
        {
          '\
          #root .container.logged{\
            width: calc(100%);\
            margin-left: 0rem;\
          }\
          .incongito_joyride{\
            display: none;\
          }\
        '
        }
      </style>
      <HeaderB />
      <div className="terms-conditions">
        {lang === 'es' ? <FCSpanish /> : <FCEnglish />}
      </div>
      <FooterB />
    </div>
  </React.Fragment>
);

FeesCommissionComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FeesCommissionComponent;
