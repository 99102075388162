import { connect } from 'react-redux';
import ActivityReportingButtonsComponent from './ActivityReportingButtonsComponent';
import { openReportsSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

var mapDispatchToProps = {
  openReportsSidePane: openReportsSidePane
};

export default connect(
  null,
  mapDispatchToProps
)(ActivityReportingButtonsComponent);
