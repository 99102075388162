import React from 'react';
import empty from 'is-empty';
import miniIsoCoink from './../images/coink-imgs/mini-iso-coink.svg';
import miniIsoBanexcoin from './../images/coink-imgs/mini-iso-banexcoin-new.svg';
class LoadingCoink extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let showLoading = false;

    if (!empty(this.props.loading)) {
      if (this.props.loading > 0) {
        showLoading = true;
      }
    }

    if (!showLoading) {
      return null;
    }

    return (
      <div className="step-bnx loadingcontainer">
        <div className="loading-logos">
          <img src={miniIsoCoink} className="coink-isom" />
          <img src={miniIsoBanexcoin} className="banexcoin-isom" />
        </div>
        <div className="loading-text">
          <h2>{this.props.title || 'Cargando...'}</h2>
          <p>{this.props.description || ''}</p>
        </div>
      </div>
    );
  }
}

export default LoadingCoink;
