import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import empty from "is-empty";
import NaturalUser from "./../../../images/natural-signup.png";
import CorporateUser from "./../../../images/corporate-signup.png";
import { withRouter } from "react-router-dom";

const url = "https://www.banexcoin.com";
const SelectSignup = (props, context) => {
  const { location } = props;
  const fullpath = `${location.search}`;
  const sp = new URLSearchParams(fullpath);

  return (
    <div className="options-signup">
      <div>
        <a className="layout-auth-logo" href={url}>
          <img
            src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
            alt="Logo"
          />
        </a>
        <h2 dangerouslySetInnerHTML={{__html:context.t(
            "Select the type of account <br/> to register in Banexcoin"
          )}}/>
        <div className="container-options-signup">
          <Link
            to={
              "/signup/natural" +
              (!empty(sp.get("aff")) ? `?aff=${sp.get("aff")}` : "")
            }
            className="link-signup-select option-register-card"
          >
            {/* <span className="no-mobile" /> */}
            <span className="rounded-bg">
              <img src={NaturalUser} />
            </span>
            <span className="text-center righted">
              <span
                className="titlex"
                dangerouslySetInnerHTML={{
                  __html: context.t("<b>Personal</b> account"),
                }}
              />
              <span className="titlex-sub mt-2">{context.t("For individuals")}</span>
            </span>
          </Link>
          <Link
            to={
              "/signup/corporate" +
              (!empty(sp.get("aff")) ? `?aff=${sp.get("aff")}` : "")
            }
            className="link-signup-select option-register-card"
          >
            {/* <span className="no-mobile" /> */}
            <span className="rounded-bg">
              <img src={CorporateUser} />
            </span>
            <span className="text-center righted">
              <span
                className="titlex"
                dangerouslySetInnerHTML={{
                  __html: context.t("<b>Corporate</b> account"),
                }}
              />
              <span className="titlex-sub mt-2">
                {context.t("For businesses")}
              </span>
            </span>
          </Link>
        </div>
        <div className="justify-content-center text-center mt-2 mb-4 link-register-bottom">
            <span className="title">
              {context.t("Do you already have an account?")}{" "}
            </span>
            <Link to="/login">{context.t("Login")}</Link>
        </div>
      </div>
    </div>
  );
};

SelectSignup.contextTypes = {
  t: PropTypes.func,
};

export default withRouter(SelectSignup);
