import React from "react";
import PropTypes from "prop-types";
import cardClock from "../../../../../images/icons-v2/card-clock.svg"
import userCard from "../../../../../images/icons-v2/user-card.svg"
import truck from "../../../../../images/icons-v2/truck.svg"
import { Link } from "react-router-dom";
import "./delivery-process.css";
import { deliveryStatuses } from "../../../constants/deliveryStatuses";
import { connect } from "react-redux";
import { sanitizeValue } from "../../../../utils";
import isEmpty from "is-empty";

const stepsTitle = {
  1:"In delivery process",
  2:"Delivered",
  3:"Activated"
}
const InProcessComponent = ({ context, deliveryCurrentStatus, address }) => {

  const stepClassNameHandler = (currentStep) => {
    if(Array.isArray(currentStep)){
      return currentStep.includes(deliveryCurrentStatus) ? "delivery-step-active" : "";
    }
    return currentStep === deliveryCurrentStatus ? "delivery-step-active" : "";
  }

  const titleConstructor = (status) => context.t(status.replaceAll("_", " ").toLowerCase()); 
  return (
    <>
      <p>{context.t("We are preparing your Banexcard and it will be sent to the address")}:</p>
      <p className="location">
        {address}
      </p>
      <p>{context.t("Remember that the delivery will be made to your person and for security you must present your identity document.")}</p>
      <div className="delivery-steps-images">
        <img title={titleConstructor(deliveryStatuses.IN_PROCESS)} src={cardClock} alt="card clock icon" width={20} height={20} className={stepClassNameHandler([deliveryStatuses.REQUESTED, deliveryStatuses.IN_PROCESS])} />
        <img title={titleConstructor(deliveryStatuses.IN_TRANSIT)} src={truck} alt="truck icon" width={20} height={20} className={stepClassNameHandler(deliveryStatuses.IN_TRANSIT)} />
        <img title={titleConstructor(deliveryStatuses.DELIVERED)} src={userCard} alt="user card icon" width={20} height={20} className={stepClassNameHandler(deliveryStatuses.DELIVERED)} />
      </div>
      <div
        className="line-gradient"
        style={{ width: "300px", height: "1px" }}
      ></div>
    </>
  );
};
const DeliveredCardComponent = ({ context }) => {
  return (
    <div className="card-delivered">
      <p>{context.t("For security, the card you received is not yet active. To use it, you must first activate it with a few simple steps.")}</p>
      <p>{context.t("To start, have your card at hand and click on “Activate card”.")}</p>
      <Link to="/cards/physical-card/activation" className="btn">{context.t("Activate card")}</Link>
      <p className="no-received-card">{context.t("Haven't received the card?")} <a href="https://soporte.banexcoin.com/es-LA/support/solutions">{context.t("Click here")}</a></p>
    </div>
  );
};
const ActivatedCardComponent = ({ context }) => {
  return (
    <div className="card-delivered">
      <p>{context.t("Excellent! now you just need to create a PIN so you can use your card, remember that if you do not create a PIN you will not be able to use your Physical Banexcard.")}</p>
      <Link to="/cards/physical-card/pin" className="btn">{context.t("Create PIN")}</Link>
    </div>
  );
}
function DeliveryProcess(props, context) {
  const { step, deliveryCurrentStatus, banexcardRequestData } = props;
  const { mainAddress, town, district, city, country, zipCode } = banexcardRequestData;

  const registeredAddress = `${sanitizeValue(mainAddress)}, ${sanitizeValue(town)}, ${sanitizeValue(
    district
  )}, ${sanitizeValue(city)}, ${sanitizeValue(country)} - ${sanitizeValue(zipCode)}`

  const stepClassNameHandler = (currentStep) => currentStep === step ? "active" : "";

  const contentStep = (currentStep) => {
    if(step > currentStep){
      return "✔️"
    }
    return currentStep;
  }

  const stepComponent = {
    1: <InProcessComponent context={context} step={step} deliveryCurrentStatus={deliveryCurrentStatus} address={registeredAddress}/>,
    2: <DeliveredCardComponent context={context} />,
    3: <ActivatedCardComponent context={context} />,
  }
  return (
    <div className="delivery-process">
      <h3>
        ¡<strong>Banexcard</strong> Física {stepsTitle[step]}!
      </h3>
      <div className={`step ${stepClassNameHandler(1)}`}>
        <div className="square">
          <span>{contentStep(1)}</span>
        </div>
        <span className="step-text">{context.t("Receive card")}</span>
      </div>
      <div className={`step ${stepClassNameHandler(2)}`}>
        <div className="square">
          <span>{contentStep(2)}</span>
        </div>
        <span className="step-text">{context.t("Activate card")}</span>
      </div>
      <div className={`step ${stepClassNameHandler(3)} last`}>
        <div className="square">
          <span>{contentStep(3)}</span>
        </div>
        <span className="step-text">{context.t("Create PIN")}</span>
      </div>
      {stepComponent[step]}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    banexcardRequestData: !isEmpty(state.banexCard) ? state.banexCard.banexcardRequestData : {}
  }
}
DeliveryProcess.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DeliveryProcess);
