import React from 'react'
import PropTypes from 'prop-types';
import BellImage from '../../images/icons-v2/Bell.svg'

const PrimaryButton = (props, context) => {
   const { text = '', onClick = () => { }, className = '' } = props;
   return (
      <div className={`button_V2 ${className}`} onClick={onClick}>
         <img src={BellImage} alt="bell" />
         <p>{context.t(text)}</p>
      </div>
   )
};

PrimaryButton.contextTypes = {
   t: PropTypes.func
};

export default PrimaryButton;