import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Spinner } from '../Spinner/Spinner';
import { getLimitBalance } from './LimitBalanceHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setLimitsByProductAndLevel } from '../../redux/actions/set_limits';

import './LimitsBalance.css';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import isEmpty from 'is-empty';
import { amountWithCurrency, formatCopCurrency } from '../../pages/utils';

const LimitsBalance = (props, context) => {
  let { accounts, selectedAccountId, userInfo, product, side, isCoink, products, setLimitsByProductAndLevel } = props;
  const [loadingLimits, setloadingLimits] = useState(false);
  const [limits, setLimits] = useState({});
  const currentProduct = products ? (products.find(item => item.Product === product.ProductSymbol) || {}) : {}

  let currentAccount = {};
  for (let i = 0; i < accounts.length; i++) {
    var cuentaEncontrada = accounts[i];
    if (cuentaEncontrada.AccountId == selectedAccountId) {
      currentAccount = cuentaEncontrada;
    }
  }

  if (empty(currentAccount) && empty(product) && empty(limits)) {
    return <React.Fragment />;
  }

  const loadLimits = async () => {
    setloadingLimits(true)
    const result = await getLimitBalance(
      userInfo.UserId,
      selectedAccountId,
      product.ProductSymbol
    );
    setLimits(result.data);
    setLimitsByProductAndLevel(result.data)
    setloadingLimits(false)
  };
  
  const newProductSymbol = product && product.ProductSymbol === 'COP' ? "$" : product.ProductSymbol 


  useEffect(
    () => {
      if (!empty(product) && !empty(currentAccount) && empty(limits) && !loadingLimits) {
        loadLimits();
      }
    },
    [product, currentAccount, limits, loadingLimits]
  );

  const getPorcentaje = (limit, current) => {
    if (empty(current) || empty(limit)) {
      return '0%';
    }
    let limitTemp = limit === -1 ? (current + 1) * 1000 : limit;
    let perc = (current / limitTemp) * 100;
    return (perc >= 100 ? 100 : perc) + '%';
  };

  const getPorcentajeRest = (limit, current) => {
    if (empty(current) || empty(limit)) {
      return '0%';
    }
    return 100 - (current / limit) * 100 + '%';
  };

  const remainingLimit = (limit, current) => {
    if (empty(current) || empty(limit)) {
      return limit;
    }
    return limit - current;
  };

  const cleanFullVal = limitVal => {
    let limits = [-1];
    if (limits.includes(limitVal)) {
      return <b>∞</b>;
    }
    return limitVal
  };

  const validationCoink = (value) => {
    if(isNaN(value)) return value
    return isCoink ? formatCopCurrency(Number(value), products ? (products.find(item => item.Product === product.ProductSymbol) || {}).DecimalPlaces : false).slice(2) : value
  }
  const validationCurrencySymbol = (value) => isCoink ? '' : value

  const currentAmountKey = "OriginalCurrent"
  const originalLimitKey = "OriginalLimit"

  return !empty(limits) ? (
    <div className="container-fluid progress-bar-container">
      <div className="row">
        <div className="col-md-12">
          <h3>
            {context.t('Permitted {side} Limit', {
              side: context.t(side)
            })}
          </h3>
          <p className="limit-details-title">
            <FontAwesomeIcon
              icon="info-circle"
              className="limit-details-info"
            />
            {context.t(
              'Remember that you must not exceed the daily limit of deposits and / or withdrawals according to the level of your Banexcoin Account'
            )}
          </p>
        </div>
        <div className="col-md-12 row-limit">
          {!isCoink && (
            <>
            <div className="title-limit">
              <span>{context.t('Daily Limit')}</span>
              <span className="limit-full">
                {amountWithCurrency({
                  amount: validationCoink(cleanFullVal(limits[side].daily[originalLimitKey])),
                  product: currentProduct
                })}
              </span>
            </div>
            <div className="progress">
              <div
                id="box"
                className="progress-bar"
                role="progressbar"
                style={{
                  width: getPorcentaje(
                    limits[side].daily[originalLimitKey],
                    limits[side].daily[currentAmountKey]
                  ),
                  borderRight: !empty(limits[side].daily[currentAmountKey])
                    ? '1.5px solid #404060'
                    : ''
                }}>
                <span className="valor">
                  {amountWithCurrency({
                    amount: validationCoink(limits[side].daily[currentAmountKey]),
                    product: currentProduct
                  })}
                </span>
              </div>
              <div id="box" className="progress-bar-remaining" role="" />
              <div className="valor-remaining">
                <span>
                  {amountWithCurrency({
                    amount: validationCoink(cleanFullVal(
                      remainingLimit(
                        limits[side].daily[originalLimitKey],
                        limits[side].daily[currentAmountKey])
                    )),
                    product: currentProduct
                  })}
                </span>
                <span className="sr-only" />
              </div>
            </div>
          </>
          )}
          <div className="title-limit">
            <span>{context.t('Monthly Limit')}</span>
            <span className="limit-full">
              {amountWithCurrency({
                    amount: validationCoink(cleanFullVal(limits[side].monthly[originalLimitKey])),
                    product: currentProduct
                  })}
            </span>
          </div>
          <div className="progress">
            <div
              id="box"
              className="progress-bar"
              role="progressbar"
              style={{
                width: getPorcentaje(
                  limits[side].monthly[originalLimitKey],
                  limits[side].monthly[currentAmountKey]
                ),
                borderRight: !empty(limits[side].monthly[currentAmountKey])
                  ? '1.5px solid #404060'
                  : ''
              }}>
              <span className="valor">
                {amountWithCurrency({
                  amount: validationCoink(limits[side].monthly[currentAmountKey]),
                  product: currentProduct
                })}
              </span>
            </div>
            <div id="box" className="progress-bar-remaining" role="" />
            <div className="valor-remaining">
              <span>
                {amountWithCurrency({
                    amount: validationCoink(cleanFullVal(
                      remainingLimit(
                        limits[side].monthly[originalLimitKey],
                        limits[side].monthly[currentAmountKey]
                      ))),
                      product: currentProduct
                  })}
              </span>
              <span className="sr-only" />
            </div>
          </div>
          <div className="title-limit">
            <span>{context.t('Yearly Limit')}</span>
            <span className="limit-full">
              {amountWithCurrency({
                    amount: validationCoink(cleanFullVal(limits[side].yearly[originalLimitKey])),
                    product: currentProduct
                  })}
            </span>
          </div>
          <div className="progress">
            <div
              id="box"
              className="progress-bar"
              role="progressbar"
              style={{
                width: getPorcentaje(
                  limits[side].yearly[originalLimitKey],
                  limits[side].yearly[currentAmountKey]
                ),
                borderRight: !empty(limits[side].yearly[currentAmountKey])
                  ? '1.5px solid #404060'
                  : ''
              }}>
              <span className="valor">
              {amountWithCurrency({
                amount: validationCoink(limits[side].yearly[currentAmountKey]),
                product: currentProduct
              })}
              </span>
            </div>
            <div
              id="box"
              className="progress-bar-remaining"
              role=""
              style={{
                width: getPorcentajeRest(
                  limits[side].yearly[originalLimitKey],
                  limits[side].yearly[currentAmountKey]
                )
              }}
            />
            <div className="valor-remaining">
              <span>
                {amountWithCurrency({
                    amount: validationCoink(cleanFullVal(
                      remainingLimit(
                        limits[side].yearly[originalLimitKey],
                        limits[side].yearly[currentAmountKey]
                      )
                    )),
                    product: currentProduct
                  })}
              </span>
              <span className="sr-only" />
            </div>
          </div>

          <div className="descirption-limit">
            <div className="description-valor">
              <span className="description-valor-text">
                {context.t('Balance {side}', {
                  side:
                    side === 'deposit'
                      ? context.t('Deposited')
                      : context.t('Withdrawn')
                })}
              </span>
            </div>
            <div className="description-remaining">
              <span className="description-remaining-text">
                {context.t('Available Balance')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='initial-loader-general' >
      <Spinner color='orange' />
    </div>
  );
};

LimitsBalance.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  const {
    user: { accounts, selectedAccountId, userInfo }
  } = state
  return {
    accounts,
    selectedAccountId,
    userInfo,
    balances: productPositionAssetsSelector(state),
    isCoink: !isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
    products: state.product.products
  };
};

const mapDispatchToProps = {
  setLimitsByProductAndLevel
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitsBalance);
