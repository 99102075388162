import React from 'react';
import ApexChartContainer from './ApexChartContainer';
import TVChartContainer from './TVChartContainer';
import config from "../config"

const chartOptions = {
  APEX: <ApexChartContainer />,
  TradingView: <TVChartContainer />
};

const { chart } = config.TradingLayout;

const PriceChartContainer = () => chartOptions[chart] || <ApexChartContainer />;
export default PriceChartContainer;
