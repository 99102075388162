import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import WalletDetailsBackButtonComponent from '../components/WalletDetails/WalletDetailsBackButtonComponent';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import BalancesMenuContainer from '../components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsContainer from '../components/WalletDetails/WalletDetailsContainer';
import TransfersContainer from './../components/Transfers/TransfersContainer';
import TransfersFiltersContainer from 'apex-web/lib/components/Transfers/TransfersFilters';
import RecentActivityWithFilterContainer from '../components/RecentActivityWithFilter';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './ProductDetailsPage.css';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import NetworkSelector from '../components/SendReceiveSidePane/ReceiveAddress/NetworkSelector';
import { jsonRed } from '../components/SendReceiveSidePane/SendForm/SelectRedData';
import isEmpty from 'is-empty';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InformationIcon from '../images/bnx-icons/information-circle.svg';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import { _slicedToArray } from './utils';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import APIcon from '../components/common/APIcon';

const layoutClasses = getBEMClasses('product-details-page');
const bemClasses = getBEMClasses('receive-address');

const backPath = '/wallets';

const ProductDetailsPage = (props, context) => {
  const {
    userInfo,
    product,
    getDepositInfo,
    showSnack,
    lang,
    level
  } = props;
  const [selectTab, setSelectTab] = useState('Recent Activity');
  const [dataNetwork, setDataNetwork] = useState([]);
  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [network, setNetwork] = useState('');
  const [textToCopy, setTextToCopy] = useState('')
  const depositType = product.ProductFullName.toLowerCase().replace(/\W/g,''); 
  const isHide = !isEmpty(network) ? '' : ' hide';

  const showDepositInfo = !isEmpty(dataNetwork) && product.ProductType == "CryptoCurrency" && !isEmpty(selectedAddress) && !isEmpty(level) && ![0, 17, 100].includes(level);

  const getCryptoAddress = async (depositList) => {
    try {
      resetAddress()
      const res = await getDepositInfo(userInfo.AccountId, product.ProductId);
      if (!isEmpty(res.DepositInfo)) {
        const addressList = JSON.parse(res.DepositInfo);
        const selectedAddress = addressList[addressList.length - 1];
        setSelectedAddress(selectedAddress);
        const destinationTagHelper1 = destinationTagHelper(selectedAddress);
        const destinationTagHelper2 = _slicedToArray(destinationTagHelper1, 2);
        setAddress(destinationTagHelper2[0])
        if (depositList.length === 1) {
          const info = depositList[0]
          setNetwork(info.value)
          handleMessage(info.value, info.sub)
        }
      }
    } catch (error) {
      resetAddress()
    }
  }

  const resetAddress = () => {
    setAddress('')
    setNetwork('')
    setSelectedAddress('')
  }

  const handleMessage = (value, text) => {
    const newNetwork = context.t(value === '1' ? 'Bitcoin Network' : text)
    const textToCopy = context.t(`My {product} ({symbol}) wallet on Banexcoin ({network}) is: {address}`,
      {
        product: product.ProductFullName,
        symbol: product.ProductSymbol,
        network: newNetwork,
        address
      })
    setTextToCopy(textToCopy)
  }

  const copyAddress = (isShare) => {
    showSnack({
      id: "RECEIVE_ADDRESS_COPY",
      text: context.t(isShare ? "The information has been copied to the clipboard." : "The address has been copied to the clipboard."),
      type: "success"
    });
    navigator.clipboard.writeText(isShare ? textToCopy : address)
  }

  useEffect(() => {
    if (address && network) {
      const subNetwork = (dataNetwork.find(e => e.value === network) || {} ).sub;
      handleMessage(network, subNetwork)
    }
  }, [lang, network])

  useEffect(() => {
    if (userInfo.AccountId && product.ProductId) {
      setSelectTab('Recent Activity')
      const jsonNetwork = jsonRed();
      const depositList = jsonNetwork['deposit'][product.Product];
      setDataNetwork(depositList)
      if (product.ProductType == "CryptoCurrency") getCryptoAddress(depositList);
    }
  }, [userInfo.AccountId, product.ProductId])

  return (
    <React.Fragment>
      <div className={layoutClasses('container')}>
        <div className={layoutClasses('menu')}>
          <WalletDetailsBackButtonComponent key="1" path={backPath} />
          <BalancesMenuContainer key="2" />
        </div>
        <div className={layoutClasses('content')}>
          <div className={layoutClasses('wallet-details')}>
            <WalletDetailsContainer />
          </div>
          {showDepositInfo &&
            <div className={layoutClasses('wallet-details') + ' crypto-address-v2'}>
              <div className='crypto-left-section'>
                <p className={bemClasses("copy-address-text")}>
                  {context.t("{product} deposit address", {
                    product: product.ProductFullName,
                  })}
                  <OverlayTrigger
                    key={"level-for-user-tooltip"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`level-for-user-tooltip`}>
                        <div className="tooltip-general">
                          {context.t(
                            "Copy and paste your {product} address into your external wallet.", {
                            product: product.ProductFullName
                          }
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <img src={InformationIcon} width={20} className='ml-2' alt="Information icon" />
                  </OverlayTrigger>
                </p>
                <NetworkSelector
                  options={dataNetwork}
                  setNetwork={(value) => setNetwork(value)}
                  value={network}
                />
              </div>
              <div className='crypto-right-section'>
                <div className={`${bemClasses("copy-address-payload") + isHide + ' addressV2'}`}>
                  <span>
                    {context.t('{product} network wallet:', { product: product.ProductFullName })}
                  </span>
                  <div>
                    <span className='cryptoAddress'>{address}</span>
                    <span onClick={() => copyAddress(false)} className='icon-share'>
                      <OverlayTrigger
                        key={"copy"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`copy`}>
                            <div className="tooltip-general">
                              {context.t('Copy')}
                            </div>
                          </Tooltip>
                        }>
                        <div>
                          <APIcon name="copy" customClass={bemClasses("copy-icon")} />  
                        </div>
                      </OverlayTrigger>
                    </span>
                    <span onClick={() => copyAddress(true)} className='icon-share copy'>
                      <OverlayTrigger
                        key={"share"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`share`}>
                            <div className="tooltip-general">
                              {context.t('Share')}
                            </div>
                          </Tooltip>
                        }>
                        <div>
                          <APIcon name="share" customClass={bemClasses("copy-icon")} />
                        </div>
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>
                <div className='qr-section '>
                  <p className={`${bemClasses("description") + isHide}`}>
                    {context.t("Or scan the QR code from the external wallet to receive your funds:")}
                  </p>
                  <div className={bemClasses("qr-code") + isHide}>
                    <APQRCode
                      value={`${depositType}:${selectedAddress}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={`${layoutClasses('transfers')} product-details-container`}>
            <div className={`${layoutClasses('transfers-header')} prod-header`}>
              <div onClick={() => setSelectTab('Recent Activity')} className={selectTab === 'Recent Activity' ? 'tab-active' : ''}>{context.t('Recent Activity')}</div>
              {product && product.ProductType !== "NationalCurrency" &&
                <div onClick={() => setSelectTab('Banexcoin transfer')} className={selectTab === 'Banexcoin transfer' ? 'tab-active' : ''}>{context.t('Banexcoin transfer')}</div>
              }
            </div>
            {selectTab === 'Recent Activity' ?
              <RecentActivityWithFilterContainer filterForSelected={true} /> :
              <React.Fragment>
                <TransfersFiltersContainer />
                <TransfersContainer />
              </React.Fragment>
            }
          </div>
        </div>
      </div>
      <PageFooterLayout />
    </React.Fragment>
  )
};

const mapStateToProps = state => {
  const { selectedProductId } = (state.apexCore && state.apexCore.position) || {};
  const product = state.product.products.find(p => p.ProductId === selectedProductId) || {};
  const userInfo = state.user.userInfo
  const lang = state.i18nState.lang
  const level = state.user.verificationLevelConfigs.Level
  return {
    selectedProductId,
    product,
    userInfo,
    lang,
    level,
  };
}

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(data) {
      return dispatch(_showSnack(data));
    },
    getDepositInfo: function getDepositInfo(accountId, productId) {
      return dispatch(callAPI('GetDepositInfo', {
        "OMSId": 1,
        "AccountId": accountId,
        "ProductId": productId,
        "GenerateNewKey": false
      }));
    }
  };
};

ProductDetailsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(ProductDetailsPage));
