import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputElement from "../../../../bnxv2/Components/InputElement/InputElement";
import { CardGradient } from "../../../components";
import isEmpty from "is-empty";
import { districts } from "../../../../../config/staticPeruDist";

function OtherLocationCompleteForm(props, context) {
  const { handleSteps, setCustomAddress, setOriginForm } = props;

  const [inputs, setInputs] = useState({
    country: "Perú",
    state: "Lima",
  });
  const [errors, setErrors] = useState({});

  const customErrors = () => {
    let errors = {};
    if (isEmpty(inputs.country)) {
      errors.country = "Required field";
    }
    if (isEmpty(inputs.state)) {
      errors.state = "Required field";
    }
    if (isEmpty(inputs.city)) {
      errors.city = "Required field";
    }
    if (isEmpty(inputs.district)) {
      errors.district = "Required field";
    }
    if (isEmpty(inputs.street)) {
      errors.street = "Required field";
    }
    if (isEmpty(inputs.building)) {
      errors.building = "Required field";
    }
    if (isEmpty(inputs.apt)) {
      errors.apt = "Required field";
    }
    if (isEmpty(inputs.urbanization)) {
      errors.urbanization = "Required field";
    }
    setErrors(errors);
    return errors;
  };

  const actions = [
    {
      label: context.t("Cancel"),
      className: "secondary",
      action: () => {
        setCustomAddress("");
        handleSteps.previous();
      },
    },
    {
      label: context.t("Next"),
      action: () => {
        const errors = customErrors();
        if (!isEmpty(errors)) return;
        setCustomAddress(inputs);
        handleSteps.custom(4);
      },
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors.hasOwnProperty(name)) {
      delete errors[name];
    }
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const countryInputProps = {
    values: {
      value: inputs.country,
      name: "country",
      type: "select",
      title: "Country",
      options: [
        {
          value: "Perú",
          label: "Perú",
        },
      ],
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.country,
  };
  const stateInputProps = {
    values: {
      value: inputs.state,
      name: "state",
      type: "select",
      title: "State / Region / Department",
      options: [
        {
          value: "Lima",
          label: "Lima",
        },
      ],
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.state,
  };
  const cityInputProps = {
    values: {
      value: inputs.city,
      name: "city",
      type: "select",
      title: "City / Province",
      options: [
        {
          value: "Lima",
          label: "Lima Metropolitana",
        },
        {
          value: "Prov. Const. del Callao",
          label: "Callao",
        },
      ],
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.city,
  };
  const districtInputProps = {
    values: {
      value: inputs.district,
      name: "district",
      type: "select",
      title: "Municipality / District",
      options:
        isEmpty(inputs.city) || isEmpty(districts[inputs.city])
          ? []
          : districts[inputs.city].map((area) => ({
              value: area.id,
              label: area.name,
            })),
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.district,
  };
  const streetInputProps = {
    values: {
      value: inputs.street,
      name: "street",
      title: "Avenue / Street / Path",
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.street,
  };
  const buildingInputProps = {
    values: {
      value: inputs.building,
      name: "building",
      title: "Number / Building",
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.building,
  };
  const aptInputProps = {
    values: {
      value: inputs.apt,
      name: "apt",
      title: "Interior Number / Department / Office",
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.apt,
  };
  const urbanizationInputProps = {
    values: {
      value: inputs.urbanization,
      name: "urbanization",
      title: "URB / AH / CP",
    },
    actions: {
      onChange: handleChange,
    },
    error: errors.urbanization,
  };

  useEffect(() => {
    setOriginForm(2)
  },[])

  return (
    <div className="other-location-delivery-form">
      <h2 className="title-main">{context.t("Physical Card Shipping")}</h2>
      <p className="description-main">
        {context.t("Where will we send your Banexcoin Visa Physical Card?")}
      </p>
      <CardGradient actions={actions} message={context.t("To have your Banexcard just select the shipping address")}>
        <div className="form">
          <div className="form-top">
            <InputElement {...countryInputProps} />
            <InputElement {...stateInputProps} />
            <InputElement {...cityInputProps} />
            <InputElement {...districtInputProps} />
          </div>
          <InputElement {...streetInputProps} />
          <div className="form-bottom">
            <InputElement {...buildingInputProps} />
            <InputElement {...aptInputProps} />
            <InputElement {...urbanizationInputProps} />
          </div>
          <div className="description-text">
            {context.t(
              "Remember that the issuance of the card is FREE, we will only deduct 5 USD from the available balance of your card for the purpose of sending your Physical card according to the coverage areas within Peru."
            )}
          </div>
        </div>
      </CardGradient>
    </div>
  );
}
OtherLocationCompleteForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OtherLocationCompleteForm;
