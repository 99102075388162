import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { Modal, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { FormButtonV2 } from '../../components/common/BnxFormsComponents';
import InformationIcon from '../../images/bnx-icons/information-circle.svg'
import { FormatDecimalCoinkWithSimbols } from '../utils';

const BuySellV2FormConfirm = (props, context) => {
    const {show, handleClose, side, sendOrderToAP, inputs, currentInstrument, validateToZero, getLevelPrice, formatNumberByProduct, feeData, formatPriceNumberByProduct, valueQuantityToReceive, valueAmount, valueProductToBuySell, valueCommission, getInteger} = props
    const [loading, setLoading] = useState(0);
    const submitOrder = async () => {
        setLoading(1)
        try {
            await sendOrderToAP()
            handleClose();
        } catch (error) {
            console.log("Error on submittion", error)
        } finally {
            setLoading(0)
        }
    }
    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='buysell-confirm'
        backdropClassName='buysell-backdrop'
    >
        <Modal.Header closeButton>
            <Modal.Title>{context.t('Confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table hover className='buysell-details'>
                <tbody>
                    <tr>
                        <td>{context.t('Price per {symbol}', { symbol: currentInstrument.Product1Symbol })}</td>
                        <td className='text-right'>{FormatDecimalCoinkWithSimbols(formatPriceNumberByProduct(getLevelPrice(currentInstrument.Symbol)[inputs.side === '0' ? 'BestOffer' : 'BestBid']),currentInstrument.Product2Symbol)}</td>
                    </tr>
                    <tr>
                        <td className='container-help-text'>
                            {context.t('Amount to {action}', {action: (inputs.side === '0' ? context.t('buy') : context.t('sell'))})}
                            {inputs.side === '0' &&
                                <OverlayTrigger
                                key={'info3-tooltip'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`info3-tooltip`} className='tooltip-buysell'>
                                        <div className="buysell-text">{context.t('Received amount + commission')}</div>
                                    </Tooltip>
                                }>
                                    <img src={InformationIcon} alt='Information icon'/> 
                                </OverlayTrigger>  
                            }
                        </td>
                        <td className='text-right'>{valueProductToBuySell()}</td>
                    </tr>
                    <tr>
                        <td>{context.t('Transaction Cost')}</td>
                        <td className='text-right'>{inputs.side === '0' ? `${FormatDecimalCoinkWithSimbols(getInteger(formatNumberByProduct(valueAmount(), currentInstrument['Product2'])),currentInstrument.Product2Symbol)}` : valueProductToBuySell()}</td>
                    </tr>
                    <tr>
                        <td>{context.t('{action} commission', { action: String(inputs.side === '0' ? context.t('Buying') : context.t('Selling')) })}</td>
                        <td className={'text-right ' + (feeData.OrderFee > 0 ? 'substract' : '')}>{valueCommission()}</td>
                    </tr>
                    <tr>
                        <td className='container-help-text'>
                            {context.t('Amount to receive')}
                            <OverlayTrigger
                                key={'info4-tooltip'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`info4-tooltip`} className='tooltip-buysell'>
                                        <div className="buysell-text">{context.t(inputs.side === '0' ? 'Amount received in your wallet' : 'Amount received after sales commission')}</div>
                                    </Tooltip>
                                }>
                                <img src={InformationIcon} alt='Information icon'/> 
                            </OverlayTrigger>
                        </td>
                        <td className='text-right'><b>{valueQuantityToReceive()}</b></td>
                    </tr>

                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <FormButtonV2 variant={'dark'} className='submit-button' disabled={loading === 1} onClick={submitOrder}>
                {loading === 1 ? (
                    <React.Fragment>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">{context.t('Loading')}...</span>
                    </React.Fragment>
                ) : context.t(`Confirm ${side === '0' ? 'Buy' : 'Sell'} Order`)}
            </FormButtonV2>
        </Modal.Footer>
    </Modal>;
}

BuySellV2FormConfirm.contextTypes = {
    t: PropTypes.func.isRequired
};

export default withAuthentication(BuySellV2FormConfirm);