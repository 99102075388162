import React, { useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import empty from 'is-empty';
//import APIcon from '../components/common/APIcon';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Ticket from './GlobalHooksIntrument';
import LandingCalculator from './LandingCalculator';
import CookieConsent from 'react-cookie-consent';
//import { setLanguage } from 'redux-i18n';
import Modal, { closeStyle } from 'simple-react-modal';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { mant } from './utils';

import '../styles/components/common/StandaloneForm.css';
import './LandingPage.css';
import FirstSection from './LandingBeta/FirstSection';
import SecondSection from './LandingBeta/SecondSection';

const lang = localStorage.getItem('language');
var img;
if (lang === 'es') {
  img = 'espana';
} else {
  img = 'estados-unidos';
}
const mapStateToProps = ({
  apexCore: {
    instrument: { instruments, selectedInstrumentId },
    level1
  }
}) => {
  return { instruments, level1 };
};
function numberWithCommas(x) {
  var number = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (number === 'NaN') {
    return '';
  } else {
    return number;
  }
}

function changeLang(x) {
  localStorage.setItem('setted_language', x);
  localStorage.setItem('language', x);
  document.location.reload();
}

export const LandingPageBetaComponent = (props, context) => {
  const { instruments, level1 } = props;
  let langParam = props.match.params.lang;
  console.log('this.props.match.params.lang', props.match.params.lang);
  if (!empty(langParam)) {
    if (langParam === 'ES') {
      localStorage.setItem('language', 'es');
    } else if (langParam === 'EN') {
      localStorage.setItem('language', 'en');
    } else {
      localStorage.setItem('language', 'es');
      return <Redirect to="/landing/" />;
    }
  }

  const [estado, setEstado] = useState(false);
  const mani = mant();
  function show() {
    setEstado(true);
  }

  function close() {
    setEstado(false);
  }

  let newInstruments = [];
  for (let i = 0; i < instruments.length; i++) {
    var instrumentEncontrada = instruments[i];
    if (
      instrumentEncontrada.Symbol === 'BTCPEN' ||
      instrumentEncontrada.Symbol ===
        'BTCUSD' /*||
        instrumentEncontrada.Symbol == 'USDPEN'*/
      //lastPriced.
    ) {
      newInstruments.push(instrumentEncontrada);
    }
  }

  const settings = {
    className: 'elementor-slides',
    slidesToShow: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    speed: 1000,
    arrows: false,
    dots: false,
    rtl: false,
    fade: true
  };

  const getPrice = IdIns => {
    if (IdIns) {
      const x = level1[IdIns];
      if (x) {
        return x.BestBid;
      }
    }
  };

  const seo = {
    title: 'Banexcoin: Exchange de Compra y Venta de Criptomonedas',
    description:
      'Banexcoin es la plataforma de intercambio de criptomonedas y dinero FIAT que acercará a Latinoamerica.',
    url: 'https://www.banexcoin.com',
    image:
      'https://www.banexcoin.com/local/landing-page/images/isotipo_mini.png'
  };

  return (
    <div id="landing">
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description }
        ]}>
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <Modal
        className="test-class" //this will completely overwrite the default css completely
        containerClassName="test"
        closeOnOuterClick={true}
        show={estado}
        onClose={() => {
          close();
        }}>
        <div>
          <a
            style={closeStyle}
            onClick={() => {
              close();
            }}>
            X
          </a>
          <iframe src="./sbs.html" title="sbs" />
        </div>
      </Modal>
      <FirstSection />
      <SecondSection />
    </div>
  );
};

LandingPageBetaComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  connect(mapStateToProps)(LandingPageBetaComponent),
  process.env.REACT_APP_DEFAULT_PATH
);
