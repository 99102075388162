import HandlerBackend from "../../../../helpers/HandlerBackend";

class ProblemLoginController{
    static recoveryUsername = async ({ emailAddress, setErrors, setSuccess }) => {
        try {
            const response = await HandlerBackend.sendRequest({
                method: "POST",
                path:"/api/v1/recoverUsername",
                data:{
                    emailAddress
                }
            })
            if(response.data){
                setSuccess(true)
                return true
            }
            return false
        } catch (error) {
            if(error.response && error.response.data){
                setErrors({usernameRecovery: error.response.data.message})
            }
            return false
        }
    }
}

export default ProblemLoginController;