import React from 'react';
import PropTypes from 'prop-types';

import APIcon from 'apex-web/lib/components/common/APIcon';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { formatDate, formatTime } from 'apex-web/lib/helpers/dateHelper';
import { FormatDecimalCoinkWithOutSimbols, amountWithCurrency } from '../../pages/utils';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';
var baseClasses = getBEMClasses('activity');

export var Action = function Action(props, context) {
  let _props$value = props.value,
    typeIcon = _props$value.typeIcon,
    primaryText = _props$value.primaryText,
    secondaryText = _props$value.secondaryText,
    blockChainLink = _props$value.blockChainLink,
    params = _props$value.params;
  var showProductIcon = props.showProductIcon,
    themeModifier = props.themeModifier;

  return React.createElement(
    'div',
    { className: baseClasses('action') },
    React.createElement(APIcon, {
      name: typeIcon,
      customClass: baseClasses('type-icon')
    }),
    showProductIcon &&
      React.createElement(ProductIconContainer, {
        customClass: baseClasses('product-icon', themeModifier),
        iconFileName: props.value.productIcon.iconFileName,
        size: 36
      }),
    React.createElement(
      'div',
      { className: baseClasses('action-column') },
      React.createElement(
        'div',
        { className: baseClasses('action-text', 'primary') },
        context.t(primaryText, params)
      ),

      React.createElement(
        'div',
        { className: baseClasses('action-text', 'secondary') },
        context.t(secondaryText, params)
      ),
      blockChainLink
        ? React.createElement(
            'div',
            { className: baseClasses('action-text', 'secondary-new') },
            context.t('Tx Hash: '),
            React.createElement(
              'a',
              {
                href:
                  (params.Product === 'BTC'
                    ? 'https://mempool.space/es/tx/'
                    : params.Product === 'USDC'
                      ? 'https://etherscan.io/tx/'
                      : 'https://blockchair.com/es/ethereum/transaction/') +
                  blockChainLink,
                className: 'txid-link',
                target: '_blank',
                title: context.t('View on Explorer')
              },
              blockChainLink
            )
          )
        : ''
    )
  );
};

const InitialAmount = function(props) {
  var _props$value2 = props.value,
    Quantity = _props$value2.Quantity,
    Value = _props$value2.Value,
    Product1Symbol = _props$value2.Product1Symbol,
    Product2Symbol = _props$value2.Product2Symbol,
    isCoink = props.isCoink,
    products = props.products

  return React.createElement(
    'div',
    { className: baseClasses('amount') },
    React.createElement(
      'div',
      { className: baseClasses('amount-quantity') },
      amountWithCurrency({
        amount: !isCoink ? formatNumberToLocale(Quantity, Product1Symbol) : FormatDecimalCoinkWithOutSimbols(formatNumberToLocale(Quantity, Product1Symbol), Product1Symbol),
        product: products.find(e => e.Product === Product1Symbol) || {},
      })
      ),
      React.createElement(
        'div',
        { className: baseClasses('amount-value') },
        amountWithCurrency({
          amount: !isCoink ? formatNumberToLocale(Value, Product2Symbol) : FormatDecimalCoinkWithOutSimbols(formatNumberToLocale(Value, Product2Symbol), Product2Symbol),
          product: products.find(e => e.Product === Product2Symbol) || {},
      })

    )
  );
};

export var Status = function Status(props, context) {
  var _props$value3 = props.value,
    status = _props$value3.status,
    id = _props$value3.id;
  var customClass = props.customClass;

  return React.createElement(
    'div',
    { className: customClass + ' ' + baseClasses('status') },
    React.createElement('div', { className: baseClasses('status-id') }, id),
    React.createElement(
      'div',
      { className: baseClasses('status-text') },
      context.t(status)
    )
  );
};

export var DateTime = function DateTime(props) {
  var raw = props.value.raw;

  return React.createElement(
    'div',
    { className: baseClasses('date') },
    React.createElement(
      'div',
      { className: baseClasses('date-date') },
      formatDate(raw)
    ),
    React.createElement(
      'div',
      { className: baseClasses('date-time') },
      formatTime(raw)
    )
  );
};

export var OrderType = function OrderType(props, context) {
  var orderType = props.value;
  return React.createElement(
    'div',
    { className: baseClasses('order-type') },
    context.t(orderType)
  );
};

export var Quantity = function Quantity(props) {
  var _props$value4 = props.value,
    OrigQuantity = _props$value4.OrigQuantity,
    Product1Symbol = _props$value4.Product1Symbol;

  return React.createElement(
    'div',
    { className: baseClasses('quantity') },
    formatNumberToLocale(OrigQuantity, OrigQuantity.symbol),
    React.createElement(
      'div',
      { className: baseClasses('quantity-text') },
      Product1Symbol
    )
  );
};

export var DepositWithdrawQuantity = function DepositWithdrawQuantity(props) {
  return Quantity({
    value: {
      OrigQuantity: props.value.Quantity,
      Product1Symbol: props.value.Product1Symbol
    }
  });
};

export var Price = function Price(props) {
  var _props$value5 = props.value,
    Price = _props$value5.Price,
    Product2Symbol = _props$value5.Product2Symbol;

  return React.createElement(
    'div',
    { className: baseClasses('price') },
    formatNumberToLocale(Price, Price.symbol),
    ' ',
    Product2Symbol
  );
};

export var CancelAction = function CancelAction(props, context) {
  var orderId = props.value;
  var cancelOrders = props.action;
  return React.createElement(CancelAPButton, {
    text: context.t('Cancel Order'),
    customClass: baseClasses(),
    onClick: function onClick() {
      cancelOrders([orderId]);
    }
  });
};

Action.contextTypes = {
  t: PropTypes.func.isRequired
};

Status.contextTypes = {
  t: PropTypes.func.isRequired
};

Status.defaultProps = {
  customClass: ''
};

OrderType.contextTypes = {
  t: PropTypes.func.isRequired
};

CancelAction.contextTypes = {
  t: PropTypes.func.isRequired
};

InitialAmount.mapStateToProps = function(state) {
  return {
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
    products: state.product.products
  }
}

export const Amount = connect(InitialAmount.mapStateToProps)(InitialAmount);
