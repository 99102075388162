import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

import './Popover.css';

const TriggerWrapper = (TriggerComponent, onClick) => {
  return <TriggerComponent onClick={onClick} />;
};

const baseClass = 'ap-popover';

export default class Popover extends React.Component {
  static propTypes = {
    trigger: PropTypes.func.isRequired,
    content: PropTypes.element.isRequired,
    drawArrow: PropTypes.bool,
    customClass: PropTypes.string
  };

  static defaultProps = {
    drawArrow: false
  };

  constructor(props) {
    super(props);

    this.popupRef = null;
    this.contentRef = null;
    this.state = {
      show: false,
      popoverLeftOffset: 0
    };
  }

  componentWillUnmount = () => {
    this.removeListeners();
  };

  addListeners() {
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  removeListeners() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleClickOutside = e => {
    if (this.popupRef.contains(e.target)) {
      return;
    }
    this.toggleShow();
  };

  handleKeyDown = e => {
    if (e.code === 'Escape') {
      this.toggleShow();
      e.target.blur();
    }
  };

  toggleShow = () => {
    this.setState({ show: !this.state.show, popoverLeftOffset: 0 }, () => {
      if (this.state.show && this.contentRef && this.isOutOfScreen()) {
        const popoverLeftOffset = this.getLeftOffset();
        this.setState({ popoverLeftOffset });
      }

      if (this.state.show) {
        this.addListeners();
      } else {
        this.removeListeners();
      }
    });
  };

  isOutOfScreen = () => {
    const rect = this.contentRef.getBoundingClientRect();
    return rect.x + rect.width > window.innerWidth;
  };

  getLeftOffset = () => {
    const rect = this.contentRef.getBoundingClientRect();
    const scrollWidth = 17;
    const leftOffset = window.innerWidth - (rect.x + rect.width);

    const hasVerticalScroll = document.body.scrollHeight > window.innerHeight;
    return hasVerticalScroll ? leftOffset - scrollWidth : leftOffset;
  };

  getBEMClasses = () => {
    const classes = [
      baseClass,
      this.props.customClass,
      this.props.isMenu && 'popover-menu'
    ];
    return getBEMClasses(classes);
  };

  render() {
    const { trigger, content, drawArrow } = this.props;
    const { show } = this.state;

    const Trigger = TriggerWrapper(trigger, this.toggleShow);
    const bemClasses = this.getBEMClasses();

    const isVisible = bemClasses(show ? 'visible' : 'hidden');

    return (
      <div
        className={classnames('popover', bemClasses('popover'))}
        ref={ref => (this.popupRef = ref)}>
        {Trigger}
        <div
          ref={ref => (this.contentRef = ref)}
          className={`${isVisible} ${bemClasses(
            'content',
            drawArrow && 'with-arrow'
          )}`}>
          {show && content}
        </div>
      </div>
    );
  }
}
