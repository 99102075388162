import React from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { Redirect } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import { redirectExceptions } from 'apex-web/lib/helpers/redirectIfAuthorizedHelper';

function redirectIfAuthorizedCoink(WrappedComponent, defaultPath) {
  var ModifiedComponent = function ModifiedComponent(props) {
    var shouldRedirect = props.shouldRedirect,
      redirectLocation = props.redirectLocation,
      isAuthenticated = props.isAuthenticated;
    if (isAuthenticated) {
      if (redirectExceptions()) {
        return React.createElement(WrappedComponent, props);
      }
      if (shouldRedirect) {
        return React.createElement(Redirect, {
          to: {
            pathname: redirectLocation.pathname,
            search: redirectLocation.search
          }
        });
      } else {
        return React.createElement(Redirect, {
          to: path(defaultPath + '/')
        });
      }
    }

    return React.createElement(WrappedComponent, props);
  };

  var mapStateToProps = function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      shouldRedirect: state.auth.shouldRedirect,
      redirectLocation: state.auth.location
    };
  };

  return connect(
    mapStateToProps,
    null
  )(ModifiedComponent);
}

export default redirectIfAuthorizedCoink;
