import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import banexcardImage from "../../../../images/banexcard-image.png";
import card from "../../../../images/icons-v2/card-pos.svg";
import coin from "../../../../images/icons-v2/coin.svg";
import security from "../../../../images/icons-v2/security.svg";
import earphone from "../../../../images/icons-v2/earphone.svg";
import plus from "../../../../images/icons-v2/plus.svg";
import bookClock from "../../../../images/icons-v2/book-clock.svg";
import pencil from "../../../../images/icons-v2/pencil-box-outline.svg";
import settings from "../../../../images/icons-v2/Setting.svg";
import FormButtonV2 from "../../../../components/common/BnxFormsComponents/FormButton";
import eyeIcon from "../../../../images/eye.svg";
import eyeSlashIcon from "../../../../images/eye-slash.svg";
import {
  Tooltip,
  MenuCard,
  Footer,
  CardGradient,
  Countdown,
  Skeleton,
  Loader,
} from "../../components";

import reloadIcon from "../../../../images/reload.svg";
import copy from "../../../../images/bnx-icons/copy.svg";
import card2 from "../../../../images/icons-v2/simple-card.svg";
import "./banexcard.css";
import { useHistory } from "react-router-dom";
import DeliveryProcess from "./DeliveryProcess/DeliveryProcess";
import PhysicalCardMenu from "./PhysicalCardMenu/PhysicalCardMenu";
import IncrementLevelController from "../../controllers/IncrementLevelController";
import { setUserInfo as _setUserInfo } from '../../../../redux/actions/set_user_info'
import { Spinner } from "../../../../components/Spinner/Spinner"
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import { showSnack as _showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import BanexcardController from "../../controllers/BanexcardController";
import isEmpty from "is-empty";
import { setBanexcardBalanceData } from "../../../../redux/actions/set_banex_card";
import { cardNumberMask, currencyFormatter, sanitizeValue } from "../../../utils";
import moment from "moment";
import { deliveryStatuses } from "../../constants/deliveryStatuses";
import { Redirect } from "react-router-dom";
import { getLevelBasicV3 } from "../../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks";
import { updateBanexCard } from "../../../../redux/actions/set_banex_card";

const NoBanexcardComponent = ({
  context,
  ht,
  userLevel,
  userInfo,
  setUserInfo,
}) => {
  const [finalResult, setFinalResult] = useState({});
  const [loadingActions, setLoadingActions] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [firstInteraction, setFirstInteraction] = useState(true);
  const [modalTerms, setModalTerms] = useState(false);
  const directRequest = userLevel > 11;
  const options = [
    {
      img: card,
      title: context.t("Buy with your card online"),
      description: context.t("Make your online purchases anywhere or pay your subscriptions."),
    },
    {
      img: security,
      title: context.t("Secure purchases"),
      description: context.t("Anti-fraud monitoring in each transaction."),
    },
    {
      img: coin,
      title: context.t("Immediate availability"),
      description: context.t("Use your balance without waiting for conversions or transfers to bank accounts."),
    },
    {
      img: card2,
      title: context.t("Total control of your card"),
      description: context.t("From the web you have total control over your transactions and the security of your card."),
    },
    {
      img: earphone,
      title: context.t("24/7 support"),
      description: context.t("Continuous assistance, greater peace of mind and quick attention at any time of the day."),
    },
  ];

  const handleFinalResult = (result) => {
    if (result) {
      return setFinalResult({
        type: "success",
        title: context.t("Request sent!"),
        message:
         context.t("Your request is in process. In approximately 24 to 48 hours you will receive an email informing you of the status of it."),
        actions: [
          {
            label: context.t("Understood"),
            action: () => ht.push("/cards/request/success"),
          },
        ],
      });
    }
    setFinalResult({
      type: "error",
      title: "",
      message: context.t("An error occurred while processing your request, please try again or write to us to help you by Chat or at soporte@banexcoin.com"),
      actions: [
        {
          label: "Intentar nuevamente",
          action: () => setFinalResult({}),
        },
      ],
    });
  };

  const handleInput = (e) => {
    if (loadingActions) return;
    if (!firstInteraction && !e.target.checked) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
    setTermsAndConditions(e.target.checked);
  };

  const handleClick = () => {
    if (loadingActions) return;
    if (directRequest) {
      if (!termsAndConditions) {
        setFirstInteraction(false);
        return setShowErrorMessage(true);
      }
      const data = { termsAndConditions };
      return IncrementLevelController.saveDirectInformation({
        userInfo,
        setLoadingActions,
        handleFinalResult,
        setUserInfo,
        data,
      });
    }
    ht.push("/cards/verification-start");
  };

  const onChangeTerms = () => {
    setTermsAndConditions(true);
    setModalTerms(false);
  };

  useEffect(() => {
    if (!modalTerms && directRequest) {
      const termsConditions = document.getElementById("terms-conditions");
      if (!isEmpty(termsConditions))
        termsConditions.addEventListener("click", () => setModalTerms(true));
    }
  }, [modalTerms, directRequest]);

  if (!isEmpty(finalResult)) {
    return (
      <div className="increment-level">
        <CardGradient
          type={finalResult.type}
          title={finalResult.title}
          message={finalResult.message}
          actions={finalResult.actions}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      {modalTerms ? (
        <div className="increment-level">
          <TermsAndConditions
            action={onChangeTerms}
            backAction={() => setModalTerms(false)}
          />
        </div>
      ) : (
        <div>
          <h2 className="title-pt1">
             {context.t("Request your")}<strong>Banexcard VISA Virtual</strong>{context.t("for free today!")}</h2>
          <p className="title-pt2">{context.t("Enjoy its benefits!")}</p>
          <div className="card-with-request-details">
            <img
              src={banexcardImage}
              alt="banexcard image"
              className="banexcard-image"
            />
            <section>
              <div className="request-details">
                {options.map((el) => (
                  <div className="request-details-item">
                    <div className="request-details-item-image">
                      <div>
                        <img src={el.img} alt="icon" />
                      </div>
                    </div>
                    <div className="request-details-item-text">
                      <p>{el.title}</p>
                      <span>{el.description}</span>
                    </div>
                  </div>
                ))}
              </div>
              {directRequest && (
                <div className="card-increment terms-conditions_v2">
                  <label className="checkbox_form_v2 form-check-label">
                    <input
                      disabled={loadingActions}
                      type="checkbox"
                      name="terms"
                      checked={termsAndConditions}
                      onChange={handleInput}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: context.t(
                          "I have read and accepted the <span class='terms-conditions' id='terms-conditions'>Terms and Conditions</span>"
                        ),
                      }}
                    />
                  </label>
                  <p
                    className={`invalid-feedback ${
                      showErrorMessage ? "show-complete-error" : ""
                    }`}
                  >
                    {context.t("You must accept the Terms and Conditions")}
                  </p>
                </div>
              )}
              <FormButtonV2
                className="button_V2 purple banexcard-button"
                onClick={handleClick}
                disabled={loadingActions}
                children={
                  loadingActions ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <p>{context.t("¡Obtén tu tarjeta virtual!")}</p>
                  )
                }
              />
            </section>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const Header = ({
  context,
  banexcardBalanceIsVisible,
  setBanexcardBalanceIsVisible,
  userInfo,
  setBanexcardBalanceData,
  level1,
  banexcard: { balanceData: banexcardBalance },
}) => {
  const [loading, setLoading] = React.useState(false);

  const toggleShowBalance = async () => {
    if (!banexcardBalanceIsVisible) {
      setLoading(true);
      const response = await BanexcardController.getUserBalance({ userInfo });
      setLoading(false);
      setBanexcardBalanceData(response);
    }
    setBanexcardBalanceIsVisible(!banexcardBalanceIsVisible);
  };

  const getBalanceValue = (key) => {
    if (isEmpty(banexcardBalance)) return "$ ****.**";
    return currencyFormatter({
      value: banexcardBalance[key],
      currency: "USD",
    });
  };

  const usdPenRate = !isEmpty(level1["5"]) ? level1["5"].BestBid : 0;

  return (
    <div className="header">
      <div className="line-gradient first"></div>
      <div className="balance">
        <img
          src={banexcardBalanceIsVisible ? eyeIcon : eyeSlashIcon}
          alt="eye"
          onClick={toggleShowBalance}
        />
        <p>{context.t("Available balance in my card")}</p>
        {loading ? (
          <div className="loading-spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            <span className="amount">
              {banexcardBalanceIsVisible
                ? getBalanceValue("availableBalance")
                : "$ ****.**"}{" "}
            </span>
            <span className="aprox">≈</span>
            <span className="aprox-value">
              {banexcardBalanceIsVisible
                ? currencyFormatter({
                    value: banexcardBalance["availableBalance"] * usdPenRate,
                    currency: "PEN",
                  })
                : "S/. ****.**"}
            </span>
          </>
        )}
        <Tooltip
          text={`T.C. S/ ${usdPenRate}`}
          id="tooltip-banexcard-conversion"
        />
      </div>
      <div className="line-gradient second"></div>
    </div>
  );
};
function BanexcardPage(props, context) {
  const {
    loadingBanexCard,
    hasBanexCard,
    showSnack,
    userInfo,
    setBanexcardBalanceData,
    banexcard,
    level1,
    banexcardInfo,
    getCardInfo,
    setUserInfo,
    userLevel,
    hasPhysicalBanexcardRequest,
    levelBasic = {},
    updateBanexCard
  } = props;
  const { cardInfo = {} } = banexcardInfo;
  const cardNumber = cardInfo.pan || "1234567845672345";
  const expirationDate = isEmpty(cardInfo)
    ? moment(cardInfo.expirationDate).utc().format("MM/YY")
    : "";
  const securityCode = cardInfo.dynamicCvv;
  const ht = useHistory();
  const [banexcardBalanceIsVisible, setBanexcardBalanceIsVisible] =
    useState(false);
  const [banexcardDataIsVisible, setBanexcardDataIsVisible] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [registeredAddress, setRegisteredAddress] = useState("");

  useEffect(() => {
    if (isEmpty(levelBasic)) return;
    const { addressMain, town, district, city } = levelBasic;
    const registeredAddress = `${sanitizeValue(addressMain)}, ${sanitizeValue(town)}, ${sanitizeValue(
      district
    )}, ${sanitizeValue(city)}`
    setRegisteredAddress(registeredAddress)
  }, [levelBasic]);

  const options = [
    {
      title: context.t("Recharge card"),
      description: context.t("Recharge the balance of your card from your USDC wallet"),
      onClick: () => ht.push("/cards/recharge"),
      icon: plus,
    },
    {
      title: context.t("Movement history"),
      description: context.t("View your operations performed"),
      onClick: () => ht.push("/cards/movements-history"),
      icon: bookClock,
    },
    {
      title: context.t("See Information"),
      description: context.t("View your card information"),
      icon: pencil,
      type: "desplegable",
      children: (
        <div className="details-card-option">
          <p>
             {context.t("Creation date")}: <strong>{!isEmpty(levelBasic.banexcardData) && !isEmpty(levelBasic.banexcardData.updatedAt) ? new Date(levelBasic.banexcardData.updatedAt).toLocaleString() : ""}</strong>
          </p>
          <p>
            {context.t("Card number")}: <strong>{cardNumberMask(cardNumber)}</strong>
          </p>
          <p>
            {context.t("Address")}:
            <strong>{registeredAddress}</strong>
          </p>
        </div>
      ),
    },
  ];

  const handleVisibleCardData = async () => {
    setBanexcardDataIsVisible(!banexcardDataIsVisible);
  };

  const reloadCVV = () => {
    getCardInfo();
    setBanexcardDataIsVisible(false);
    setTimeout(() => {
      setBanexcardDataIsVisible(true);
    }, 1);
  };

  const getCurrentDeliveryStep = () => {
    if(levelBasic.banexcardData.status === deliveryStatuses.IN_PROCESS) return 1
    if(!isEmpty(levelBasic.banexcardData) && banexcard.banexcardRequestData.status === deliveryStatuses.DELIVERED && !levelBasic.banexcardData.isActive) return 2
    if(!isEmpty(levelBasic.banexcardData) && banexcard.banexcardRequestData.status === deliveryStatuses.DELIVERED && !levelBasic.banexcardData.isPinSet) return 3
    return 1
  }  

  const canUsePhysicalBanexcard = !isEmpty(levelBasic.banexcardData) 
    && !isEmpty(banexcard.banexcardRequestData)
    && banexcard.banexcardRequestData.status === deliveryStatuses.DELIVERED 
    && levelBasic.banexcardData.isActive
    && levelBasic.banexcardData.isPinSet

    const getBanexcardRequestById = async (requestId) => {
      const response = await BanexcardController.getRequestPhysicalCardById({ userInfo, requestId })
      return response
    }

    const getUserInfo = async () => {
      try {
        setLoadingUserData(true)
        const info = await getLevelBasicV3(userInfo.UserId, userInfo.AccountId);
        let formattedData = info.data || {}
        if(!isEmpty(formattedData.level_basic) && formattedData.level_basic.hasOwnProperty("cardRequestsByLevelsBasicId")){
            formattedData.level_basic.banexcardData = {
                provider: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].provider : "",
                status: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].status : "",
                updatedAt: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].updatedAt : "",
                ...formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].cardsByCardRequestId.nodes[0].b89CardByB89CardId,
                //TODO: ONLY FOR TESTING
              }
            }
            //TODO: ONLY FOR TESTING
        setUserInfo(formattedData);
        const newData = info.data.level_basic || {};
        const banexCardOptionIsVisible = ![17, 18, 100, 101].includes(newData.userLevel)
        const hasBanexCard = !isEmpty(newData.cardRequestsByLevelsBasicId.nodes) && newData.cardRequestsByLevelsBasicId.nodes[0].status === 'CARD_CREATED'
        const userLevel = newData.userLevel;
        let banexcardRequestData = {}
        //TODO: ONLY FOR TESTING
        if(newData.hasOwnProperty("physicalCardRequestsByLevelsBasicId")){
          banexcardRequestData = await getBanexcardRequestById(newData.physicalCardRequestsByLevelsBasicId.nodes[0].id)
          //TODO: ONLY FOR TESTING
        }
        updateBanexCard({
          loadingBanexCard: false,
          banexCardOptionIsVisible,
          hasBanexCard,
          userLevel,
          banexcardRequestData
        })
      } catch (error) {}
      finally{
        setLoadingUserData(false)
      }
    };

  useEffect(() => {
    if (isEmpty(levelBasic)) return;
    getUserInfo()
  },[])
  
  if (loadingBanexCard) return <Loader />;

  return (
    <div
      className={`banexcard-page ${hasBanexCard ? "has-banexcard-page" : ""}`}
    >
      {!hasBanexCard ? (
        <NoBanexcardComponent
          context={context}
          ht={ht}
          userLevel={userLevel}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      ) : (
        <section className="has-banexcard">
          <Header
            banexcardBalanceIsVisible={banexcardBalanceIsVisible}
            setBanexcardBalanceIsVisible={setBanexcardBalanceIsVisible}
            context={context}
            userInfo={userInfo}
            setBanexcardBalanceData={setBanexcardBalanceData}
            banexcard={banexcard}
            level1={level1}
          />
          <div className="body">
            <div className="left">
              <img
                src={banexcardImage}
                alt="banexcard image"
                className="card-image"
              />
              <div className="details first">
                <div>
                  <p className="label">{context.t("Card number")}</p>
                  <p className="value">
                    {banexcardDataIsVisible
                      ? cardNumber
                      : "XXXX XXXX XXXX XXXX"}
                  </p>
                </div>
                <img
                  src={copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText("4528 1907 0123 8653");
                    showSnack({
                      id: "copy_successfully_card",
                      text: context.t(
                        "Your card number has been copied to the clipboard"
                      ),
                      type: "success",
                    });
                  }}
                />
                <div className="show-banexcard-data">
                  <img
                    src={banexcardDataIsVisible ? eyeIcon : eyeSlashIcon}
                    alt=""
                    onClick={handleVisibleCardData}
                  />
                </div>
              </div>
              <div className="details second">
                <div>
                  <span className="label">{context.t("Expires")}</span>
                  <span className="value">
                    {banexcardDataIsVisible ? expirationDate : "XX/XX"}
                  </span>
                </div>
                <div>
                  <span className="label">CVV</span>
                  <span className="value">
                    {banexcardDataIsVisible ? securityCode : "XXX"}
                  </span>
                </div>
                <div className="reload-countdown">
                  {banexcardDataIsVisible && (
                    <div>
                      <img
                        src={reloadIcon}
                        alt="reload icon"
                        onClick={reloadCVV}
                        width={18}
                      />
                    </div>
                  )}
                  {banexcardDataIsVisible && <Countdown initialValue={120} />}
                </div>
              </div>
            </div>
            <MenuCard
              title="Virtual"
              description={context.t("For your online purchases and subscriptions")}
              options={options}
              // customClassName="min-height-600"
              bottomOption={{
                title: context.t("Set up card"),
                description: context.t("Configure the preferences of your Banexcard"),
                onClick: () => ht.push("/cards/settings"),
                icon: settings,
              }}
            />
            {loadingUserData ? 
              <div className="loader-right-container"><Spinner /></div>
            : canUsePhysicalBanexcard ? (
              <PhysicalCardMenu />
            ) : hasPhysicalBanexcardRequest ? (
              <CardGradient customClassName="min-height-600">
                <DeliveryProcess step={getCurrentDeliveryStep()} deliveryCurrentStatus={banexcard.banexcardRequestData.status} />
              </CardGradient>
            ) : (
              <CardGradient customClassName="right">
                <div>
                  <h3>{context.t("Congratulations!")}</h3>
                  <p>
                    {context.t("Your Banexcoin VISA Virtual card is almost ready for you to use in your subscriptions or purchases on the internet, the first thing you should do is recharge your balance, remember that the funds you recharge will be debited from your available balance of USD Coin (USDC).")}
                  </p>
                </div>
              </CardGradient>
            )}
          </div>
          {!hasPhysicalBanexcardRequest && hasBanexCard && <Footer />}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    banexCard: { hasBanexCard, loadingBanexCard, userLevel },
    user: { userInfo },
  } = state;
  return {
    loadingBanexCard,
    hasBanexCard,
    userLevel,
    userInfo,
    level1: state.level1,
    banexcard: state.banexCard,
    levelBasic: state.completedUserInfo.level_basic,
    hasPhysicalBanexcardRequest:
      !isEmpty(state.completedUserInfo.level_basic) &&
      state.completedUserInfo.level_basic.hasOwnProperty(
        "physicalCardRequestsByLevelsBasicId"
      )
        ? !!state.completedUserInfo.level_basic
            .physicalCardRequestsByLevelsBasicId.nodes[0].id
        : false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
    setBanexcardBalanceData: (payload) => {
      return dispatch(setBanexcardBalanceData(payload));
    },
    setUserInfo: (payload) => {
      return dispatch(_setUserInfo(payload));
    },
    updateBanexCard: (payload) => {
      return dispatch(updateBanexCard(payload));
    }
  };
};
BanexcardPage.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(BanexcardPage);
