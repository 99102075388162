import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import RecentActivityComponent from './RecentActivityComponent';
import resize from 'apex-web/lib/hocs/resize';

var RecentActivityComponentForm = reduxForm({
  form: 'recent-activity',
  destroyOnUnmount: false,
  initialValues: {
    type: 'all',
    startDate: undefined,
    endDate: undefined
  }
})(RecentActivityComponent);

export default connect()(resize(RecentActivityComponentForm));
