import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APTable from 'apex-web/lib/components/common/APTable';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const baseClass = getBEMClasses('order-history-table');

const InactiveOrdersComponent = (
  { data, instruments, config, fetching, selectedInstrument },
  context
) => {
  const { maxLines, usePagination } = config;

  const rowProps = row => ({
    selectedInstrument:
      selectedInstrument ||
      instruments.find(item => item.InstrumentId === row.Instrument) ||
      {}
  });

  const columns = [
    {
      header: context.t('Pair'),
      dataTest: 'Pair',
      cell: (row, { selectedInstrument }) =>
        selectedInstrument ? selectedInstrument.Symbol : ''
    },
    {
      header: context.t('Side'),
      dataTest: 'Side',
      cell: row => (
        <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
          {context.t(row.Side)}
        </div>
      )
    },
    {
      header: context.t('Type'),
      dataTest: 'Type',
      cell: row => (row.IsQuote ? context.t('Quote') : row.OrderType)
    },
    {
      header: context.t('Size'),
      dataTest: 'Size',
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass('size')}>
          {row.OrigQuantity &&
            formatNumberToLocale(
              row.OrigQuantity,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )}
        </div>
      )
    },
    {
      header: context.t('Price'),
      dataTest: 'Price',
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass('price')}>
          {getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          )}
        </div>
      )
    },
    {
      header: context.t('Date/Time'),
      dataTest: 'Date/Time',
      cell: row => formatDateTime(row.ReceiveTime)
    },
    {
      header: context.t('Status'),
      dataTest: 'Status',
      cell: row => context.t(row.OrderState)
    }
  ];

  return (
    <APTable
      {...{
        columns,
        rowProps,
        rows: data,
        baseClass,
        fetching,
        pageSize: maxLines,
        usePagination,
        empty: <span className='empty-orders'>{context.t("You have no inactive orders.")}</span>,
        componentName: 'InactiveOrdersComponent'
      }}
    />
  );
};

InactiveOrdersComponent.title = 'Inactive Orders';

InactiveOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired
};

InactiveOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InactiveOrdersComponent;
