import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Withdrawals from './Withdrawals';

const DepositWithdrawals = (props, context) => {
    const { products, userInfo, manifest } = props;


    return (
        <div className="market-overview-container-table-container">
            <Withdrawals userInfo={userInfo} products={products} manifest={manifest} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        user: { selectedAccountId, accounts, userInfo },
        product: { products },
        productManifest: { manifest }
    } = state;
    return { selectedAccountId, accounts, userInfo, products, manifest };
};

DepositWithdrawals.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(DepositWithdrawals);
