import React from 'react'
import isEmpty from 'is-empty'
import './layout.css'
export default function LayoutAuth({ children, leftAuxChildren, url,className="", twofa = true, imageBackground }) {
  const validationTwoFa = !twofa ? 'loginv2bg' : ''

  return (
    <div className={`layout-auth ${className}`}>
      {(validationTwoFa && imageBackground) && <img src={imageBackground} alt="" className='layout-auth-bg' />}
      <a className='layout-auth-logo' href={url}>
        <img
          src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
          alt="Logo"
        />
      </a>
      <div className="circle1" />
      <div className="circle2" />
      <div className="circle3" />
      <div className={`layout-content ${validationTwoFa}`}>
        <div
          className={`layout-container ${twofa ? 'layout-container-center' : ''
            }`}>
          {!isEmpty(leftAuxChildren) ? leftAuxChildren : <div />}
          <div className={`layout-container-children ${!validationTwoFa ? 'two-factor-a': ''}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}