export const createCountries = (payload) => {
    return {
        type: "CREATE_COUNTRIES",
        payload
    };
};
export const updateCountries = (payload) => {
    return {
        type: "UPDATE_COUNTRIES",
        payload
    };
};
export const resetCountries = () => {
    return {
        type: "RESET_COUNTRIES"
    };
};