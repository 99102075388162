import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'is-empty';
import React from 'react'
import { Dropdown, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUserConfig } from 'apex-web/lib/redux/actions/userActions';
import { changeLanguage } from 'apex-web/lib/redux/actions/languageActions';
import './languageComponent.css'

const LanguageComponent = (props, context) => {

  const { lang, changeLanguage, userInfo: { UserName, UserId }, customClass, handleNavItemClick } = props;

  const languages = {
    es: {
      name: 'Spanish',
      image: '/local/landing-page/images/country/espana.png'
    },
    en: {
      name: 'English',
      image: '/local/landing-page/images/country/estados-unidos.png'
    }
  };

  const buildPayloadLanguage = lang => {
    const languagePayload = {
      UserId,
      UserName,
      configValue: [{ Key: 'Language', Value: lang }],
      language: lang
    };
    return languagePayload;
  };

  return (
    <div>
      <Dropdown drop='right'>
        <Dropdown.Toggle className="language_v2">
          {!isEmpty(languages[lang]) ?
            <>
              <div className='cont_icon'>
                <img src={languages[lang].image} />
              </div>
              <p className={customClass('label')}>{context.t(languages[lang].name)}</p>
              <FontAwesomeIcon className="arrow-toggle" icon={faChevronRight} />
            </> :
            <p>{context.t('English')}</p>
          }
        </Dropdown.Toggle>
        <Dropdown.Menu className='language_v2_items'>
          <Dropdown.Item
            className={customClass('label')}
            as={Button}
            onClick={() => {changeLanguage(buildPayloadLanguage('en')); handleNavItemClick()}}
          >
            <div className="lang-img">
              <img src={languages['en'].image} />
            </div>
            {context.t('English')}
          </Dropdown.Item>
          <Dropdown.Item
            className={customClass('label')}
            as={Button}
            onClick={() => {changeLanguage(buildPayloadLanguage('es')); handleNavItemClick()}}
          >
            <div className="lang-img">
              <img src={languages['es'].image} />
            </div>
            {context.t('Spanish')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

LanguageComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { userInfo },
  i18nState: { lang }
}) => {
  return { userInfo, lang };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: payload => {
      const language = payload.language;
      const UserId = payload.UserId;
      const UserName = payload.UserName;
      const configValue = payload.configValue;
      dispatch(setUserConfig(UserId, UserName, configValue));
      dispatch(changeLanguage(language));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageComponent);