import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import APSegmentedButton from "apex-web/lib/components/common/APSegmentedButton";
import APLabelWithText from "apex-web/lib/components/common/APLabelWithText";
import APNumberInput from "./APNumberInput";
import APButton from "apex-web/lib/components/common/APButton";
import APSelect from "apex-web/lib/components/common/APSelect";
import BuySellTabs from "./BuySellTabs";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import { biggerThanZero } from "apex-web/lib/helpers/formValidations";
import {
  getOrderLeverage,
  getOrderBorrowingPower,
  getSubmitButtonText,
} from "apex-web/lib/helpers/orderHelper.js";
import { formatNumberToLocale, parseNumberToLocale } from "apex-web/lib/helpers/numberFormatter";
import { styleNames } from "apex-web/lib/propTypes/commonComponent";
import {
  orderTypes,
  sellValue,
  buyValue,
} from "apex-web/lib/constants/sendOrder/orderEntryFormConstants";
import { instrumentPropType } from "apex-web/lib/propTypes/instrumentPropTypes";
import { userPermissions } from "apex-web/lib/constants/userPermissionsContants";
import ConfirmOrderModalContainer from "../../../components/ConfirmOrderModal/ConfirmOrderModalContainer";
import { Link } from "react-router-dom";
import "apex-web/lib/styles/components/TradeComponent.css";
import "./OrderEntryComponent.css";
import Range from "./Range/Range";
import { change } from 'redux-form'
import isEmpty from "is-empty";
import { numberWithCommas2 } from "../../utils";

const baseClass = "order-entry";
const baseClasses = getBEMClasses(baseClass);
const tradeComponentClasses = getBEMClasses("trade-component");

const OrderEntryComponent = (props, context) => {
  const {
    authorizedUserPermissions,
    openAdvancedOrderSidePane,
    openReportBlockTradeSidePane,
    orderEntryForm,
    selectedInstrument = {},
    handleSubmit,
    submitting,
    fetching,
    invalid,
    disableTrading,
    VerificationRequiredComponent,
    isMarginActive,
    marginProducts,
    title,
    isAuthenticated,
    positions = {},
    decimalPlaces,
    marketPrice,
    hideFees,
    orderTotal,
    orderNet,
    orderFee,
    defaultValue,
    dispatch,
    level1
  } = props;
  const convertIncrementToIntDecimalPlaces = (increment) => {
    return !increment ? 0 : increment.toString().split(".")[1] ? increment.toString().split(".")[1].length : 0
  }
  const buy = orderEntryForm.values.side === buyValue;
  const sell = orderEntryForm.values.side === sellValue;
  
  const currentBalanceByProduct = () => {
    if (!selectedInstrument) return "-";
    const keySymbol = buy ? "PriceIncrement" : "QuantityIncrement";
    const keyProduct = buy ? "Product2" : "Product1";
    const selectedDecimalPlaces = convertIncrementToIntDecimalPlaces(selectedInstrument[keySymbol])
    const selectedPosition = positions[selectedInstrument[keyProduct]];
    return selectedPosition
      ? (selectedPosition.Amount - selectedPosition.Hold)
      : "-";
  };
  const maxCalculatedAmount = () => {
    if (!selectedInstrument) return 0;
    const keyProduct = buy ? "Product2" : "Product1";
    const selectedDecimalPlaces = convertIncrementToIntDecimalPlaces(selectedInstrument["MinimumQuantity"])
    const selectedPosition = positions[selectedInstrument[keyProduct]] || {};
    return selectedPosition
      ? (selectedPosition.Amount - selectedPosition.Hold).toFixed(selectedDecimalPlaces)
      : 0;
  };

  const handleChangeRange = (_, value) => {
    const decimalPlacesValue = convertIncrementToIntDecimalPlaces(selectedInstrument.MinimumQuantity)
    const formattedMarketPrice = marketPrice.replaceAll(",", "")
    const denominator = (Number(formattedMarketPrice) === 0 ? 1 : Number(formattedMarketPrice))
    const rangeValue  = buy ? (value / denominator).toFixed(decimalPlacesValue) : Number(value).toFixed(decimalPlacesValue)
    dispatch(change('orderEntry', 'quantity', rangeValue))
  };
  const getRangeValue = () => {
    const decimalPlacesValue = convertIncrementToIntDecimalPlaces(selectedInstrument.MinimumQuantity)
    const formattedMarketPrice = marketPrice.replaceAll(",", "")
    const factor = (Number(formattedMarketPrice) === 0 ? 1 : Number(formattedMarketPrice))
    const el = document.getElementsByName("quantity")[0];
    if (el) {
      return buy ? (el.value * factor).toFixed(decimalPlacesValue) : Number(el.value).toFixed(decimalPlacesValue);
    }
    return 0;
  };

  const finalAvailableBalance = () => {
    const currentBalance = currentBalanceByProduct()
    return (isEmpty(selectedInstrument)|| currentBalance === "-") 
      ? "" 
      : numberWithCommas2(currentBalance, convertIncrementToIntDecimalPlaces(buy ? selectedInstrument.PriceIncrement : selectedInstrument.QuantityIncrement))
  }

  useEffect(() => {
    if(!isEmpty(selectedInstrument)){
      dispatch(change('orderEntry', 'quantity', ''))
      dispatch(change('orderEntry', 'limitPrice', ''))
      dispatch(change('orderEntry', 'stopPrice', ''))
    }
  }, [selectedInstrument])

  return (
    <div className={classnames(baseClasses())}>
      {title || null}
      <form onSubmit={handleSubmit} className={baseClasses("form")}>
        <BuySellTabs
          baseClass={baseClass}
          styleName={sell ? styleNames.subtractive : styleNames.additive}
          defaultValue={defaultValue}
          onClick={() => {
              dispatch(change('orderEntry', 'quantity', ''))
              dispatch(change('orderEntry', 'limitPrice', ''))
              dispatch(change('orderEntry', 'stopPrice', ''))
            }}
        />

        <div className={baseClasses("body")}>
          <div className={baseClasses("order-type-wrapper")}>
            <APSegmentedButton
              name="orderType"
              items={[
                {
                  value: orderTypes.market.displayName,
                  text: context.t("Market"),
                  dataTest: "Market Order Type",
                },
                {
                  value: orderTypes.limit.displayName,
                  text: context.t("Limit"),
                  dataTest: "Limit Order Type",
                },
                {
                  value: orderTypes.stopMarket.displayName,
                  text: context.t("Stop"),
                  dataTest: "Stop Order Type",
                },
              ]}
              customClass={baseClass}
              styleName={buy ? styleNames.additive : styleNames.subtractive}
            />
          </div>
          <div className="available-balance">
            <p>
              {context.t("Available balance")}:{" "}
              <span>
                {finalAvailableBalance()}{" "}
                {!buy
                  ? selectedInstrument.Product1Symbol
                  : selectedInstrument.Product2Symbol}
              </span>
            </p>
          </div>
          <div className="quantity">
            <APNumberInput
              type="text"
              placeholder="0"
              name="quantity"
              customClass={baseClass}
              label={context.t("Amount")}
              labelInInput={selectedInstrument && selectedInstrument.Product1Symbol}
              validate={[biggerThanZero]}
              decimalPlaces={
                selectedInstrument && convertIncrementToIntDecimalPlaces(selectedInstrument.MinimumQuantity)
              }
            />
          </div>
          {orderEntryForm.values.orderType === orderTypes.limit.displayName && (
            <React.Fragment>
              <div className="limit">
                <APNumberInput
                  type="text"
                  name="limitPrice"
                  placeholder="0"
                  customClass={baseClass}
                  label={context.t("Limit Price")}
                  labelInInput={
                    selectedInstrument && selectedInstrument.Product2Symbol
                  }
                  validate={[biggerThanZero]}
                  decimalPlaces={
                    selectedInstrument &&
                    convertIncrementToIntDecimalPlaces(
                      selectedInstrument.PriceIncrement
                    )
                  }
                  step={selectedInstrument.PriceIncrement}
                />
              </div>
              <APSelect
                name="timeInForce"
                label={context.t("Time in Force")}
                customClass={baseClasses()}
                options={[
                  { value: 1, label: context.t("Good til canceled") },
                  { value: 3, label: context.t("Immediate or cancel") },
                  { value: 4, label: context.t("Fill or kill") },
                ]}
                showTriangles={true}
              />
            </React.Fragment>
          )}

          {orderEntryForm.values.orderType ===
            orderTypes.stopMarket.displayName && (
            <div className="stop">
              <APNumberInput
                type="text"
                name="stopPrice"
                placeholder="0"
                customClass={baseClass}
                label={context.t("Stop Price")}
                labelInInput={
                  selectedInstrument
                    ? context.t(`${selectedInstrument.Product2Symbol}`)
                    : ""
                }
                validate={[biggerThanZero]}
                decimalPlaces={
                  selectedInstrument &&
                  convertIncrementToIntDecimalPlaces(
                    selectedInstrument.PriceIncrement
                  )
                }
              />
            </div>
          )}
          <Range
            name="amount"
            inputValue={getRangeValue()}
            onChange={handleChangeRange}
            max={String(maxCalculatedAmount())}
          />

          <div className={tradeComponentClasses("section")}>
            {isMarginActive && (
              <React.Fragment>
                <APLabelWithText
                  name="borrowingPower"
                  label={context.t("Borrowing Power")}
                  text={`${
                    buy
                      ? selectedInstrument.Product2Symbol
                      : selectedInstrument.Product1Symbol
                  } ${formatNumberToLocale(
                    getOrderBorrowingPower(
                      orderEntryForm,
                      selectedInstrument,
                      marginProducts
                    ),
                    convertIncrementToIntDecimalPlaces(
                      buy
                        ? selectedInstrument.PriceIncrement
                        : selectedInstrument.QuantityIncrement
                    )
                  )}`}
                  customClass={baseClass}
                />

                <APLabelWithText
                  name="leverage"
                  label={context.t("Leverage")}
                  text={getOrderLeverage(orderEntryForm)}
                  customClass={baseClass}
                />
              </React.Fragment>
            )}
          </div>
          <APLabelWithText
            name="marketPrice"
            label={context.t("Market Price")}
            text={!isEmpty(selectedInstrument) ? `${selectedInstrument.Product2Symbol} ${marketPrice}` : "0"}
            customClass={baseClass}
          />

          {!hideFees && (
            <APLabelWithText
              label={context.t("Fees")}
              customClass={baseClass}
              text={orderFee}
            />
          )}

          <APLabelWithText
            label={context.t("Order Total")}
            text={!isEmpty(selectedInstrument) ? orderTotal : "0"}
            customClass={baseClass}
          />

          {!hideFees && (
            <APLabelWithText
              label={context.t("Net")}
              text={orderNet}
              customClass={baseClass}
            />
          )}
          {!isAuthenticated ? (
            <div className="not-logged-buttons">
              <Link to="/signup" className="register">
                {context.t("Register")}
              </Link>
              <Link to="/login">{context.t("Log in")}</Link>
            </div>
          ) : (
            <>
              <div className={tradeComponentClasses("section")}>
                <APButton
                  type="submit"
                  disabled={submitting || fetching || invalid || disableTrading}
                  customClass={baseClass}
                  styleName={buy ? styleNames.additive : styleNames.subtractive}
                >
                  {submitting
                    ? context.t("Processing...")
                    : context.t(getSubmitButtonText(orderEntryForm))}
                </APButton>
              </div>
              {VerificationRequiredComponent}
            </>
          )}

          {isAuthenticated && (
            <div className={tradeComponentClasses("section")}>
              <div
                className={classnames(baseClasses("item-button"))}
                onClick={openAdvancedOrderSidePane}
              >
                {`« ${context.t("Advanced Orders")}`}
              </div>
            </div>
          )}
          {authorizedUserPermissions &&
            (authorizedUserPermissions.includes(
              userPermissions.submitBlockTrade
            ) ||
              authorizedUserPermissions.includes(
                userPermissions.superUser
              )) && (
              <div className={tradeComponentClasses("section")}>
                <div
                  className={classnames(baseClasses("item-button"))}
                  onClick={openReportBlockTradeSidePane}
                >
                  {`« ${context.t("Block Trade")}`}
                </div>
              </div>
            )}
        </div>
      </form>
      <ConfirmOrderModalContainer />
    </div>
  );
};

OrderEntryComponent.defaultProps = {
  submitting: false,
  isMarginActive: false,
};

OrderEntryComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  authorizedUserPermissions: PropTypes.array.isRequired,
  openAdvancedOrderSidePane: PropTypes.func.isRequired,
  openReportBlockTradeSidePane: PropTypes.func.isRequired,
  selectedInstrument: instrumentPropType,
  orderEntryForm: PropTypes.object.isRequired,
  marketPrice: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  disableTrading: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.element,
  ConfirmLimitOrderModalContainer: PropTypes.element,
  allowNegative: PropTypes.bool,
};

OrderEntryComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OrderEntryComponent;
