import React from 'react';
import PropTypes from 'prop-types';
import Enable2FAForm from './Enable2FAForm';
import Modal from 'apex-web/lib/components/common/Modal/Modal';

import 'apex-web/lib/components/EnableTwoFactorAuth/Enable2FAModal.css';

const Enable2FAModal = function Enable2FAModal(props, context) {
  const GoogleQRCode = props.GoogleQRCode,
      handleSubmit = props.handleSubmit,
      submitting = props.submitting,
      isOpen = props.isOpen,
      close = props.close,
      changeFieldValue = props.changeFieldValue,
      showSnack = props.showSnack,
      userName = props.userName;


  return React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: context.t('Enable Two-Factor Authentication'),
      onCancel: function onCancel() {
        close();
        changeFieldValue();
      },
      close: close },
    React.createElement(Enable2FAForm, {
      userName: userName,
      GoogleQRCode: GoogleQRCode,
      handleSubmit: handleSubmit,
      submitting: submitting,
      showSnack: showSnack
    })
  );
};

Enable2FAModal.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

Enable2FAModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

Enable2FAModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Enable2FAModal;