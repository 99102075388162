import React from 'react';
import PropTypes from 'prop-types';
import PaddedDecimal from 'apex-web/lib/components/common/PaddedDecimal/PaddedDecimal';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

const orderBookClasses = getBEMClasses('orderbook');
const flexTable = getBEMClasses('flex-table');
const flexTableColumn = flexTable('column');
const cancelClassName = `${flexTableColumn} ${orderBookClasses('cancel-btn')}`;

const DataTable = props => {
  const {
    cancelOrders,
    onPriceClick,
    onQuantityClick,
    level2Data,
    classModifier,
    fetching
  } = props;

  const rowClassName = `${flexTable('row')} ${orderBookClasses(
    'order-row',
    classModifier
  )}`;
  const priceClassName = `${flexTableColumn} ${orderBookClasses(
    'table-price',
    classModifier
  )}`;
  const quantityClassName = `${flexTableColumn} ${orderBookClasses(
    'table-qty',
    classModifier
  )}`;
  const mySizeClassName = `${flexTableColumn} ${orderBookClasses(
    'table-my-size',
    isFirefox() ? 'firefox' : ''
  )}`;

  if (fetching) {
    return (
      <Spinner customClass={orderBookClasses} classModifiers={classModifier} />
    );
  } else {
    return level2Data.map((data, index) => (
      <div key={data.Price} className={rowClassName}>
        <div
          data-quantity={data.Quantity}
          data-index={index}
          onClick={onQuantityClick}
          className={quantityClassName}>
          <PaddedDecimal
            value={data.Quantity}
            decimals={props.quantityDecimalPlaces}
          />
        </div>
        <div
          data-price={data.Price}
          onClick={onPriceClick}
          className={priceClassName}>
          <PaddedDecimal
            bemMod={classModifier}
            value={data.Price}
            decimals={props.priceDecimalPlaces}
          />
        </div>
      </div>
    ));
  }
};

DataTable.propTypes = {
  cancelOrders: PropTypes.func.isRequired,
  onPriceClick: PropTypes.func.isRequired,
  onQuantityClick: PropTypes.func.isRequired,
  level2Data: PropTypes.array.isRequired,
  classModifier: PropTypes.string,
  fetching: PropTypes.bool
};

export default DataTable;
