import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import RecentActivityHeaderComponent from './RecentActivityHeader';
import RecentActivityTableComponent from './RecentActivityTable';

import './ActivityComponents.css';

var baseClasses = getBEMClasses('activity');

var RecentActivityComponent = function RecentActivityComponent(props, context) {
  var width = props.width,
    themeModifier = props.themeModifier;

  return React.createElement(
    'div',
    { className: baseClasses() },
    React.createElement(RecentActivityTableComponent, {
      themeModifier: themeModifier,
      width: width,
      context: context
    })
  );
};

RecentActivityComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecentActivityComponent;
