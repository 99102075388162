import React from 'react';
import PropTypes from 'prop-types';
import AdvancedOrderFormContainer from '../AdvancedOrderForm/AdvancedOrderFormContainer';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

import './AdvancedOrderSidePaneComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('advanced-order-sidepane');

var AdvancedOrderSidePane = (function(_React$Component) {
  _inherits(AdvancedOrderSidePane, _React$Component);

  function AdvancedOrderSidePane() {
    _classCallCheck(this, AdvancedOrderSidePane);

    return _possibleConstructorReturn(
      this,
      (
        AdvancedOrderSidePane.__proto__ ||
        Object.getPrototypeOf(AdvancedOrderSidePane)
      ).apply(this, arguments)
    );
  }

  _createClass(AdvancedOrderSidePane, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.props.onSidePaneOpen({
          title: this.context.t('Advanced Orders'),
          customClass: baseClasses(),
          classModifiers: 'with-tabs'
        });
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          form = _props.form,
          setSellOrder = _props.setSellOrder,
          setBuyOrder = _props.setBuyOrder,
          clearAdvancedOrderEntryForm = _props.clearAdvancedOrderEntryForm;

        var buyTabModifier =
          ((form && form.values.side === buyValue) || !form) && 'buy-selected';
        var sellTabModifier =
          form && form.values.side === sellValue && 'sell-selected';

        var selectBuyOrder = function selectBuyOrder() {
          clearAdvancedOrderEntryForm();
          setBuyOrder();
        };

        var selectSellOrder = function selectSellOrder() {
          clearAdvancedOrderEntryForm();
          setSellOrder();
        };

        return React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: baseClasses('tab-container') },
            React.createElement(
              'div',
              {
                className: baseClasses('tab', buyTabModifier),
                onClick: selectBuyOrder
              },
              this.context.t('Buy')
            ),
            React.createElement(
              'div',
              {
                className: baseClasses('tab', sellTabModifier),
                onClick: selectSellOrder
              },
              this.context.t('Sell')
            )
          ),
          React.createElement(AdvancedOrderFormContainer, null)
        );
      }
    }
  ]);

  return AdvancedOrderSidePane;
})(React.Component);

AdvancedOrderSidePane.defaultProps = {
  onSidePaneOpen: function onSidePaneOpen() {}
};

AdvancedOrderSidePane.propTypes = {
  clearAdvancedOrderEntryForm: PropTypes.func.isRequired,
  setBuyOrder: PropTypes.func.isRequired,
  setSellOrder: PropTypes.func.isRequired,
  form: PropTypes.object
};

AdvancedOrderSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AdvancedOrderSidePane;
