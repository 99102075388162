import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  getFiatAccounts,
  getBankCountries
} from '../../components/AccountComponents/AccountHooks';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import img_peru from './../../images/img_peru.png';
import img_us from './../../images/img_us.png';
import img_international from './../../images/img_international.png';
import { IconArrowPopupLeft } from '../../components/common/BnxIcons';

const accountPageClasses = getBEMClasses('account-page');

const AccountAddPage = (props, context) => {
  let { userInfo } = props;
  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <div>Loading..</div>
      </React.Fragment>
    );
  }
  const [loading, setLoading] = useState(0);

  document.title = `${context.t('My Banking Accounts')} | ${context.t(
    'Add bank Account'
  )} - Banexcoin`;

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses() + ' minhfix'}>
        <h2 className={accountPageClasses('title-v2') + ' text-center'}>
          {context.t('Add bank account')}
        </h2>
        <Row>
          <Col xl="4">
            <Link to="/settings/accounts" className="v2-back-link">
              <IconArrowPopupLeft />
            </Link>
          </Col>
          <Col xl="4">
            <Link
              to="/settings/accounts/add/pe"
              className={accountPageClasses('select-box-item')}>
              <img src={img_peru} />
              <i>{context.t('My account on')}</i>
              <b>{context.t('Perú')}</b>
            </Link>
            <Link
              to="/settings/accounts/add/international"
              className={accountPageClasses('select-box-item')}>
              <img src={img_international} />
              <i>{context.t('My account')}</i>
              <b>{context.t('International')}</b>
            </Link>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

AccountAddPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(AccountAddPage);
