import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  orderTypes,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import ReportBlockTradeComponent from './ReportBlockTradeComponent';
import instrumentPositionSelector, {
  selectedInstrumentSelector,
  instrumentSelectorInstruments
} from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { MODAL_TYPES, openModal } from 'apex-web/lib/redux/actions/modalActions';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import store from 'apex-web/lib/redux/store';
import { closeReportBlockTradeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderNet } from 'apex-web/lib/helpers/orderHelper';
import config from 'apex-web/lib/config';

const closeSidePane = () => store.dispatch(closeReportBlockTradeSidePane());

const mapStateToProps = state => {
  const {
    form,
    apexCore: { product }
  } = state;
  const { decimalPlaces } = product;

  const selectedInstrument = selectedInstrumentSelector(state);
  const { disableTrading } = kycVerificationLevelsSelector(state);
  const formObj = form.reportBlockTrade || { values: {} };

  const instruments = instrumentSelectorInstruments(state);

  const orderTotal = form.reportBlockTrade
    ? orderTotalSelector(state, {
        form: form.reportBlockTrade.values
      })
    : 0;

  return {
    instruments,
    selectedInstrument,
    formObj,
    decimalPlaces,
    ...instrumentPositionSelector(state),
    positions: positionSelector(state),
    orderTotal: `${selectedInstrument.Product2Symbol} ${formatNumberToLocale(
      orderTotal,
      selectedInstrument.Product2Symbol
    )}`,
    orderNet: getOrderNet(formObj, selectedInstrument, orderTotal),
    initialValues: {
      side: formObj.values.side || buyValue,
      instrument: selectedInstrument.InstrumentId,
      quantity: '',
      fee: { OrderFee: '0' },
      totalAmount: '0',
      price: '',
      counterparty: '',
      orderType: orderTypes.reportBlockTrade.displayName,
      lockedIn: false
    },
    disableTrading,
    VerificationRequiredComponent: (
      <VerificationRequiredContainer onVerificationLinkClick={closeSidePane} />
    ),
    showLockedIn: config.BlockTrade.showLockedIn
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'reportBlockTrade',
    enableReinitialize: true,
    onSubmit: (payload, dispatch) =>
      dispatch(openModal(MODAL_TYPES.CONFIRM_BLOCK_REPORT))
  })(ReportBlockTradeComponent)
);
