import { REGEX_LIST } from "../../../constants/regexList";
import HandlerBackend from "../../../helpers/HandlerBackend";

export const recoveryPasswordByEmail = async (input) => {
  const isEmail = REGEX_LIST.regexToValidateMail.test(input);
  let data = {};
  if (isEmail) {
    data.email = input
  } else {
    data.username = input
  }
  try {
    const path = "/api/v1/recoveryPassword";
    const response = await HandlerBackend.sendRequest({
      method: "POST",
      path,
      data,
    });
    return response.data;
  } catch (error) {
    return { detail: error.response.data.message };
  }
};
