import React from 'react'
import './circleBxn.css'

const CircleBxn = ({ color = '', className = '' }) => {

  const typeOfCircleByColor = ['orange', 'purple']

  return <div className={`circle-bxn ${typeOfCircleByColor.includes(color) ? color : ''} ${className}`} />

}

export default CircleBxn