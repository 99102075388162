import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import UploaderBnx from './UploaderBnx';
import PhoneInput from 'react-phone-input-2';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import 'react-phone-input-2/lib/style.css';
import 'moment/locale/es.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

var DatePicker = require('reactstrap-date-picker');

const ModalBasic = (props, context) => {
  const { separator = false } = props;
  const convertType = value => {
    if (value === 'undefined') return undefined;
    if (value === 'null') return null;
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  };
  const validateField = (fields, inputs) => {
    let error = {};

    for (let i in fields) {
      let campo = fields[i];
      let name = campo.name;
      let type = campo.type;
      let required = campo.props[0].required;

      let value = empty(convertType(inputs[name] + ''));
      if (required) {
        if (value) {
          if (!empty(campo.displayOn)) {
            let p = campo.displayOn;
            let allValidToDisplay = 0;
            for (let ii in p) {
              let pr = p[ii];
              if (pr.hasOwnProperty('isEqualTo')) {
                if (pr.isEqualTo) {
                  if (inputs[pr.parent] === pr.conditionValue) {
                    if (value) {
                      allValidToDisplay++;
                    }
                  }
                } else {
                  if (inputs[pr.parent] !== pr.conditionValue) {
                    if (value) {
                      allValidToDisplay++;
                    }
                  }
                }
              } else {
                if (inputs[pr.parent] === pr.conditionValue) {
                  if (value) {
                    allValidToDisplay++;
                  }
                }
              }
            }

            if (allValidToDisplay === p.length) {
              error[name] = true;
            }
          } else {
            if (type === 'date') {
              let b = moment(convertType(inputs[name] + ''), 'YYYY-MM-DD');
              if (!b.isValid()) {
                error[name] = true;
              }
            } else if (type === 'tel') {
              if (empty(convertType(inputs[name] + ''))) {
                error[name] = true;
              } else {
                if (inputs[name].length < 9) {
                  error[name] = true;
                }
              }
            } else {
              error[name] = true;
            }
          }
        }
      }

      //validating the lenght of the ruc number second screen
      if (name === 'personal_ruc' && inputs.is_invoice_with_ruc === true) {
        if (
          empty(inputs.personal_ruc) ||
          (!empty(inputs.personal_ruc) && inputs.personal_ruc.length != 11)
        ) {
          error[name] = true;
        }
      }
    }
    return error;
  };

  const isOdd = num => {
    return num % 2;
  };
  const { steps, inputs, requestedLevel, setValidate, imagePrev } = props;
  let editableFields = [];
  if (inputs['edit_fields']) {
    editableFields = JSON.parse(inputs['edit_fields']);
  }
  var today = new Date();

  var currentdate =
    today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
  const StepFields = steps.map((x, i) => {
    let fields = x.fields;
    let stepsNumber = steps.length;
    let currentChild = i + 1;

    let isCurrent = props.currentStep === currentChild ? true : false;

    const FormFields = fields.map((x, i) => {
      let field = x;
      let isEditable = editableFields.includes(field.name);
      let One = [];
      let proper = field.props[0];
      let isRequired = !empty(proper.required) ? proper.required : false;
      let subdata = !empty(field.subdata) ? field.subdata : {};
      let placeh = empty(proper.placeholder)
        ? { placeholder: context.t(field.label) }
        : { placeholder: context.t(proper.placeholder) };
      let eight = proper.isEighteen
        ? {
            max: moment()
              .subtract(18, 'years')
              .format('YYYY-MM-DD')
          }
        : '';
      const isAlertModal = async (title, message, fieldname) => {
        confirmAlert({
          title: context.t(title),
          message: context.t(message),
          buttons: [
            {
              label: context.t('Got it')
            },
            {
              label: context.t("Don't show again"),
              onClick: () => {
                localStorage.setItem(fieldname, 'false');
              }
            }
          ]
        });
      };

      let disabled =
        empty(inputs[field.name]) && empty(inputs.status)
          ? false
          : inputs.status === 6
            ? false
            : inputs.status === 5
              ? isEditable
                ? false
                : true
              : inputs.status === 1
                ? true
                : false;

      let display = false;
      let coincidenceD = 0;
      if (!empty(field.displayOn)) {
        for (let i in field.displayOn) {
          let d = field.displayOn[i];
          if (d.hasOwnProperty('isEqualTo')) {
            if (d.isEqualTo) {
              if (inputs[d.parent] === d.conditionValue) {
                coincidenceD++;
              }
            } else {
              if (
                !empty(inputs[d.parent]) &&
                inputs[d.parent] !== d.conditionValue
              ) {
                coincidenceD++;
              }
            }
          } else {
            if (inputs[d.parent] === d.conditionValue) {
              coincidenceD++;
            }
          }
        }

        if (field.displayOn.length === coincidenceD) {
          display = true;
        }
      } else {
        display = true;
      }

      if (!empty(proper.placeholder)) {
        proper.placeholder = context.t(proper.placeholder);
      }
      if (
        field.type === 'text' ||
        field.type === 'number' ||
        field.type === 'email'
      ) {
        if (empty(field.displayOn) || display) {
          if (
            ((field.name === 'linked_economic_group_doc_type' ||
              field.name === 'linked_economic_group_name') &&
              field.props[0].disabled) ||
            (inputs.is_invoice_with_ruc === true &&
              inputs.is_sunat_validating === true)
          ) {
            disabled = true;
          }
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group
                controlId={field.name}
                className={
                  inputs.status === 5 && isEditable ? 'markaseditable' : ''
                }
                {...placeh}
                {...proper}>
                <Form.Label
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...placeh}
                  {...proper}>
                  {context.t(field.label)}{' '}
                  {isRequired ? <span className="asterisk">*</span> : ''}
                </Form.Label>
                <Form.Control
                  name={field.name}
                  type={field.type}
                  {...placeh}
                  {...proper}
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  defaultValue={inputs[field.name]}
                  disabled={disabled}
                  onChange={props.onInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {context.t('You must enter the {name} field', {
                    name: context.t(field.label)
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'date') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group controlId={field.name}>
                <Form.Label>
                  {context.t(field.label)}{' '}
                  {isRequired ? (
                    <span className="asterisk">*</span>
                  ) : (
                    currentdate
                  )}
                </Form.Label>
                <DatePicker
                  name={field.name}
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  value={
                    !empty(inputs[field.name])
                      ? moment(inputs[field.name]).format('YYYY-MM-DD')
                      : proper.hasOwnProperty('isNormal') && proper.isNormal
                        ? moment().format('YYYY-MM-DD')
                        : moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD')
                  }
                  disabled={disabled}
                  onChange={(v, f) => {
                    let value;
                    if (!empty(v)) {
                      value = moment(v).format('YYYY-MM-DD');
                    } else {
                      value = moment()
                        .subtract(18, 'years')
                        .format('YYYY-MM-DD');
                    }
                    props.onInputChangeByName(field.name, value);
                  }}
                  maxDate={
                    !proper.hasOwnProperty('defaultMinDate')
                      ? proper.hasOwnProperty('isNormal') && proper.isNormal
                        ? moment()
                            .add(1, 'days')
                            .format('YYYY-MM-DD')
                        : moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD')
                      : moment()
                          .add(50, 'years')
                          .format('YYYY-MM-DD')
                  }
                  minDate={
                    proper.hasOwnProperty('defaultMinDate') &&
                    !empty(proper.defaultMinDate)
                      ? moment(proper.defaultMinDate)
                          .add(1, 'days')
                          .format('YYYY-MM-DD')
                      : moment()
                          .subtract(80, 'years')
                          .format('YYYY-MM-DD')
                  }
                  {...eight}
                  required={isRequired}
                  invalid={
                    inputs['validated']
                      ? empty(inputs[field.name])
                        ? true
                        : false
                      : false
                  }
                />{' '}
                <Form.Control.Feedback type="invalid">
                  {context.t('You must enter the {name} field', {
                    name: context.t(field.label)
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'file') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col
              xl={!empty(field.size) ? field.size : '6'}
              key={i}
              className={
                inputs.status === 5 && isEditable ? 'markaseditable' : ''
              }
              {...placeh}
              {...proper}>
              <Form.Group controlId={field.name}>
                <Form.Label
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...placeh}
                  {...proper}>
                  {context.t(field.label)}{' '}
                  {isRequired ? <span className="asterisk">*</span> : ''}
                </Form.Label>
                {field.filetype === 'image' ? (
                  <React.Fragment>
                    <Form.Control
                      required
                      name={field.name}
                      type="file"
                      {...placeh}
                      {...proper}
                      className={
                        inputs.status === 5 && isEditable
                          ? `markaseditable ${empty(inputs[field.name]) ? 'file-image' : ''}`
                          : ''
                      }
                      disabled={disabled}
                      onChange={e => {
                        props.setFiles && props.setFiles(e);
                        props.onInputFileChange && props.onInputFileChange(e);
                        props.onChangeImagePrev && props.onChangeImagePrev(e);
                      }}
                    />
                    {!empty(imagePrev) && !empty(imagePrev[field.name]) ? (
                      <img
                        src={imagePrev[field.name]}
                        style={{ maxWidth: '100%' }}
                        className="middle-row"
                      />
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                ) : (
                  <UploaderBnx
                    name={field.name}
                    type={field.type}
                    className={
                      inputs.status === 5 && isEditable ? 'markaseditable' : ''
                    }
                    {...placeh}
                    {...proper}
                    subdata={subdata}
                    onInputFileChange={props.onInputFileChange}
                    disabled={disabled}
                    separator={separator}
                  />
                )}
                <Form.Text id={'subInfo' + field.name} className="text-warning">
                  {!empty(subdata)
                    ? subdata.map(function(object, i) {
                        if (object.type === 'download-link') {
                          return (
                            <a
                              href={context.t(object.href)}
                              className={
                                inputs.status === 5 && isEditable
                                  ? 'markaseditable'
                                  : ''
                              }
                              {...placeh}
                              {...proper}
                              key={i}
                              target="_blank"
                              dangerouslySetInnerHTML={{
                                __html: context.t(object.label)
                              }}
                            />
                          );
                        } else if (object.type === 'download-text') {
                          if (
                            !empty(object.href) &&
                            !empty(inputs[field.name])
                          ) {
                            return (
                              <div key={i} className={object.className}>
                                {context.t(object.label)}
                              </div>
                            );
                          } else {
                            return (
                              <span
                                key={i}
                                className={`contenedor ${
                                  inputs.status === 5 && isEditable
                                    ? 'markaseditable'
                                    : ''
                                }`}
                                {...placeh}
                                {...proper}>
                                {context.t(object.label)}
                                <img src={context.t(object.href)} />
                              </span>
                            );
                          }
                        }
                      })
                    : ''}
                </Form.Text>
                {props.errors &&
                  props.errors[field.name] && (
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must upload the {name} file', {
                        name: context.t(field.label)
                      })}
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'select') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group
                controlId={field.name}
                className={
                  inputs.status === 5 && isEditable ? 'markaseditable' : ''
                }
                {...placeh}
                {...proper}>
                <Form.Label
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...placeh}
                  {...proper}>
                  {context.t(field.label)}{' '}
                  {isRequired ? <span className="asterisk">*</span> : ''}
                  {!empty(proper.title) && !empty(proper.message) ? (
                    <span
                      onClick={() => {
                        if (!empty(proper.title) && !empty(proper.message)) {
                          isAlertModal(
                            context.t(proper.title),
                            context.t(proper.message),
                            'modal_alert_' + field.name
                          );
                        }
                      }}
                      className="info_alert">
                      ?
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  onClick={() => {
                    if (!empty(proper.title) && !empty(proper.message)) {
                      let isALert = localStorage.getItem(
                        'modal_alert_' + field.name
                      );
                      if (empty(isALert) || isALert === 'true') {
                        isAlertModal(
                          context.t(proper.title),
                          context.t(proper.message),
                          'modal_alert_' + field.name
                        );
                      }
                    }
                  }}
                  as={field.type}
                  name={field.name}
                  type={field.type}
                  className={`${
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  } ${
                    inputs[field.name] === '' ||
                    inputs[field.name] === '-1' ||
                    inputs[field.name] === undefined
                      ? 'defaultOptionSelectForm'
                      : ''
                  }`}
                  {...placeh}
                  {...proper}
                  onChange={props.onInputChange}
                  disabled={disabled}
                  value={inputs[field.name]}>
                  {!empty(field.options) ? (
                    <React.Fragment>
                      <option value="">{context.t('Select')}</option>
                      {field.options.map((option, index) => {
                        var keys = Object.keys(option);
                        return (
                          <option
                            key={index}
                            value={option[keys[0]]}
                            className={
                              option[keys[0]] === '' ? 'title_select' : ''
                            }>
                            {context.t(option[keys[1]])}
                          </option>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <option value="-1">{context.t('Loading')}</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {context.t('You must enter the {name} field', {
                    name: context.t(field.label)
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'option-button') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group controlId={field.name}>
                <Form.Label
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...placeh}
                  {...proper}>
                  {context.t(field.label)}{' '}
                  {isRequired ? <span className="asterisk">*</span> : ''}
                </Form.Label>
                {!empty(field.options) ? (
                  <React.Fragment>
                    {field.options.map((option, index) => {
                      var keys = Object.keys(option);
                      return (
                        <>
                          <label
                            for={'ra' + field.name + index}
                            className={
                              inputs.status === 5 && isEditable
                                ? 'markaseditable'
                                : ''
                            }
                            {...placeh}
                            {...proper}>
                            {context.t(option[keys[1]])}
                          </label>
                          <input
                            onClick={() => {
                              let campos = steps[props.currentStep - 1].fields;
                              let validar = validateField(campos, inputs);
                              if (empty(validar)) {
                                setValidate(false);
                                props.setCurrentStep(props.currentStep + 1);
                              } else {
                                setValidate(true);
                              }
                            }}
                            type="radio"
                            onChange={props.onInputChange}
                            id={'ra' + field.name + index}
                            name={field.name}
                            value={option[keys[0]]}
                            className={
                              inputs.status === 5 && isEditable
                                ? 'markaseditable'
                                : ''
                            }
                            {...placeh}
                            {...proper}
                          />
                        </>
                      );
                    })}
                  </React.Fragment>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  {context.t('You must enter the {name} field', {
                    name: context.t(field.label)
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'tel') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group
                controlId={field.name}
                className={
                  !empty(inputs[field.name])
                    ? inputs[field.name].length < 9
                      ? 'invalid'
                      : 'valid'
                    : 'invalid'
                }>
                <Form.Label>
                  {context.t(field.label)}{' '}
                  {isRequired ? <span className="asterisk">*</span> : ''}
                </Form.Label>
                <PhoneInput
                  inputClass={'form-pay-phone-custom'}
                  onChange={value => {
                    props.onInputChangeByName(field.name, value);
                  }}
                  country={'pe'}
                  value={inputs.phone || ''}
                  isValid={(value, country) => {
                    if (value.length < 9) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  inputProps={{
                    id: field.name,
                    name: field.name,
                    requiredmessage: context.t(
                      'You must enter the phone number'
                    ),
                    required: isRequired
                  }}
                  disabled={disabled}
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {context.t('You must enter the {name} field', {
                    name: context.t(field.label)
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      } else if (field.type === 'info') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              {field.subdata.type === 'text' ? (
                <>
                  <Form.Group
                    className={field.subdata.type === 'text' ? 'info_self' : ''}
                    controlId={field.name}>
                    <Form.Label>
                      {context.t(field.label)}{' '}
                      {isRequired ? <span className="asterisk">*</span> : ''}
                    </Form.Label>
                    <Form.Text className="text-muted">
                      {context.t(field.subdata.content)}
                    </Form.Text>
                  </Form.Group>
                </>
              ) : null}
              {field.subdata.type === 'image' ? (
                <>
                  <Form.Label>
                    {context.t(field.label)}{' '}
                    {isRequired ? <span className="asterisk">*</span> : ''}
                  </Form.Label>
                  <Form.Group controlId={field.name}>
                    <Form.Text className="selfie">
                      <img src={context.t(field.subdata.content)} />
                    </Form.Text>
                  </Form.Group>
                </>
              ) : null}
            </Col>
          );
        }
      } else if (field.type === 'checkbox') {
        if (empty(field.displayOn) || display) {
          One.push(
            <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
              <Form.Group controlId={field.name}>
                <Form.Label>
                  {isRequired ? <span className="asterisk">*</span> : ''}
                </Form.Label>
                <Form.Check
                  name={field.name}
                  type={field.type}
                  inline
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...proper}>
                  <Form.Check.Input
                    type={field.type}
                    isValid
                    onChange={props.onInputChange}
                  />
                  <Form.Check.Label
                    style={{
                      marginTop: '10',
                      marginBottom: '15',
                      color: '#ff9013',
                      fontWeight: 'bold',
                      fontSize: '13px'
                    }}>
                    {context.t(field.label)}
                  </Form.Check.Label>

                  {/****FontAwesome info****/}
                  {field.name === 'is_invoice_with_ruc' ? (
                    <React.Fragment>
                      <div>
                        &nbsp;&nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="2x"
                          title={context.t(
                            'By selecting this option you must indicate your personal RUC,\notherwise your invoices will be issued by your identity document.'
                          )}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Form.Check>
                {inputs.is_sunat_validating === true &&
                field.name === 'is_invoice_with_ruc' ? (
                  <div style={{ color: 'gray', marginTop: '25px' }}>
                    {context.t('Searching RUC Information, please wait...')}
                  </div>
                ) : (
                  ''
                )}
              </Form.Group>
            </Col>
          );
        }
      }

      return <React.Fragment key={i}>{One}</React.Fragment>;
    });

    return (
      <React.Fragment key={i}>
        <div
          className={
            'step-container ' +
            (isCurrent ? 'active' : '') +
            ' paso' +
            currentChild +
            ` ${props.className || ''}`
          }>
          <Row>{FormFields}</Row>

          {!empty(props.errorMessage) ? (
            <Row>
              <Col xl="12">
                <Alert variant={'danger'}>{props.errorMessage}</Alert>
              </Col>
            </Row>
          ) : (
            ''
          )}

          <Row className="h-auto">
            <Col xl="6" className="text-right">
              {steps.length > 1 ? (
                props.currentStep > 1 && props.currentStep <= steps.length ? (
                  <Button
                    variant="info"
                    type="button"
                    disabled={
                      inputs.is_sunat_validating === true ? true : false
                    }
                    onClick={() => {
                      props.setCurrentStep(props.currentStep - 1);
                    }}>
                    {context.t('Back')}
                  </Button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Col>
            <Col xl="6">
              <Button
                variant="info"
                type="submit"
                style={{
                  display: steps.length === props.currentStep ? 'block' : 'none'
                }}
                disabled={
                  requestedLevel === 2
                    ? false
                    : !empty(inputs)
                      ? !props.isShareholder
                        ? empty(inputs.legal_entity_type)
                          ? empty(inputs.document_number) ||
                            empty(inputs.document_country) ||
                            (inputs.document_country === 'PE' &&
                              (inputs.document_number.length < 8 ||
                                inputs.document_number.length > 12)) ||
                            ((inputs.status === 1 ||
                              inputs.status === 4 ||
                              inputs.status === 7) &&
                              inputs.next_level >= requestedLevel)
                            ? true
                            : false
                          : false
                        : false
                      : false
                }>
                {context.t(
                  !empty(props.submitText) ? props.submitText : 'Submit'
                )}
              </Button>
              {steps.length !== props.currentStep ? (
                props.currentStep < steps.length ? (
                  <Button
                    disabled={
                      (inputs.hasOwnProperty('linked_economic_group') &&
                        inputs.linked_economic_group === 'Yes' &&
                        empty(inputs.linked_economic_group_name)) ||
                      (inputs.hasOwnProperty('is_invoice_with_ruc') &&
                        inputs.is_invoice_with_ruc === true &&
                        (inputs.personal_ruc.length != 11 ||
                          inputs.is_sunat_validating === true))
                        ? true
                        : false
                    }
                    variant="info"
                    type="button"
                    onClick={() => {
                      let campos = steps[props.currentStep - 1].fields;
                      let validar = validateField(campos, inputs);
                      if (empty(validar)) {
                        setValidate(false);
                        props.setCurrentStep(props.currentStep + 1);
                      } else {
                        setValidate(true);
                      }
                    }}>
                    {context.t('Next')}
                  </Button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  });

  return (
    <div>
      <Row>{StepFields}</Row>
    </div>
  );
};

ModalBasic.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ModalBasic;
