import React, { useEffect, useState } from 'react';
import empty from 'is-empty';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CarouselPopup from './CarouselPopup';

const ModalPopup = (props, context) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBanner] = useState([]);
  const [closeShowModalData, setCloseShowModalData] = useState([]);
  const [allModalData, setAllModalData] = useState([]);

  const handleClose = () => {
    setShow(false);
    window.localStorage.setItem('modal', JSON.stringify(closeShowModalData));
  };

  const setDataObject = (dataPop) => {
    const showModalId = dataPop.map((m, i) => {
      return m.id;
    });
    setAllModalData(dataPop)
    setCloseShowModalData(showModalId)
  }

  useEffect(
    () => {
      
      if (!empty(props.messages)) {
        const popupsData = (props.messages || []).filter(m => m.type === 'popup');
        setDataObject(popupsData)
        setShowModal(true);
      }
    },
    [props.messages]
  );

  useEffect(
    () => {
      if (showModal && !empty(closeShowModalData)) {
        const showModalLocal = window.localStorage.getItem('modal') ;
        
        let showModalIdArray = [];

        try {
          const convertArray = JSON.parse(showModalLocal);
          showModalIdArray = Array.isArray(convertArray) ? convertArray : [];
        } catch (error) {
          showModalIdArray = [];
        }
        const showBAnnerFilter = (closeShowModalData || []).filter(
          m => !(showModalIdArray || []).includes(m)
        );

        setShowBanner(showBAnnerFilter);

        if (!empty(closeShowModalData)) {
          setShow(true);
        }
      }
    },
    [showModal, closeShowModalData]
  );
  
  const showPopupsData = (allModalData || []).filter(m =>
    showBanner.includes(m.id)
  );

  if (empty(showPopupsData) || navigator.userAgent == 'ReactSnap') {
    return <React.Fragment />;
  }

  return !empty(showPopupsData) ? (
    <Modal
      keyboard={false}
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton />
      <CarouselPopup messages={showPopupsData} />
    </Modal>
  ) : <React.Fragment/>;
};

ModalPopup.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ModalPopup;
