import { connect } from 'react-redux';
import { dismissSnackByUniqueId as _dismissSnackByUniqueId } from 'apex-web/lib/redux/actions/snackbarActions';
import APSnackbar from './../common/APSnackbar/APSnackbar';

var mapStateToProps = function mapStateToProps(state) {
  return {
    snacks: state.snackbar.snacks
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    dismissSnackByUniqueId: function dismissSnackByUniqueId(payload) {
      return dispatch(_dismissSnackByUniqueId(payload));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APSnackbar);
