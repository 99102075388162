import React from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../../../components";
import { useHistory } from "react-router-dom";

function RemoveCardResult(props, context) {
  const { type = "success" } = props;
  const ht = useHistory();
  const resultTypes = {
    success: {
      title: context.t("Card Deleted"),
      description: context.t('Your Banexcard VISA card has been successfully deleted.\n We send you a certificate to your email: {email}',{
        email: ""
      }),
        customClassName:"remove-result-w",
      actions: [
        {
          label: context.t("Understood"),
          action: () => ht.push("/cards"),
        },
      ]
    },
    error: {
      title: context.t("Failed deletion"),
      description: context.t("We are sorry, your card could not be deleted, please try again or write to us to help you by Chat or to soporte@banexcoin.com"),
        customClassName:"remove-result-w",
      actions: [
        {
          label: context.t("Understood"),
          action: () => ht.push("/cards"),
        },
      ]
    },
  };
  return (
    <CardGradient
      actions={resultTypes[type].actions}
      type={type}
      title={resultTypes[type].title}
      message={resultTypes[type].description}
      customClassName={resultTypes[type].customClassName}
    >
      {resultTypes[type].component && resultTypes[type].component()}
    </CardGradient>
  );
}

RemoveCardResult.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RemoveCardResult;
