import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import AutoForm from './AutoForm';
import { useForm } from '../../hooks/formHooks';
import empty from 'is-empty';
import {
  submitFormCorporateBetaShareholderRecord,
  getCountries,
  getLevelCorporateBetaShareholderRecordById,
  resendFormCorporateBetaAllShareholderById
} from '../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loading from '../../components/Loading';
import Alert from 'react-bootstrap/Alert';

import { states } from '../../config/staticWorldContent';
import { province } from '../../config/staticPeruProv';
import { districts } from '../../config/staticPeruDist';

const ModalCorporatePersonShareholder = (props, context) => {
  const {
    show,
    handleClose,
    userInfo,
    selectedAccountId,
    preId,
    companyMasterId,
    loadLevel,
    reviewStatus
  } = props;

  try {
    if (empty(userInfo) || empty(selectedAccountId)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [countries, setCountries] = useState({});
    const [basicLevel, setBasicLevel] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [imagesBlob, setImagesBlob] = useState({});
    const [files, setFiles] = useState({});
    const setBlobByName = (name, blob) => {
      setImagesBlob(imagesBlob => ({
        ...imagesBlob,
        [name]: blob
      }));
    };
    async function setValidate(val) {
      setInputs(inputs => ({
        ...inputs,
        validated: val
      }));
    }

    const openFile = function(event) {
      const input = event.target;

      const reader = new FileReader();
      reader.onload = function() {
        const dataURL = reader.result;
        setFiles({ ...files, [input.name]: dataURL });
      };
      reader.readAsDataURL(input.files[0]);
    };

    async function setInputsBasic(basicLevel) {
      let inputsBas = {
        ...inputs,
        ...basicLevel,
        id: basicLevel.id,
        first_name: basicLevel.first_name,
        middle_name: basicLevel.middle_name,
        last_name: basicLevel.last_name,
        second_last_name: basicLevel.second_last_name,
        birthday: moment(basicLevel.birthday, 'YYYY-MM-DD'),
        nationality: basicLevel.nationality,
        birth_country: basicLevel.birth_country,
        residence_country: basicLevel.residence_country,
        address_main: basicLevel.address_main,
        occupation: basicLevel.occupation,
        work_center: basicLevel.work_center,
        phone: basicLevel.phone,
        is_pep: basicLevel.is_pep,
        billing_country: basicLevel.billing_country,
        billing_address: basicLevel.billing_address,
        billing_state: basicLevel.billing_state,
        billing_city: basicLevel.billing_city,
        billing_zip: basicLevel.billing_zip,
        document_type: basicLevel.document_type,
        document_country: basicLevel.document_country,
        document_number: basicLevel.document_number,
        document_data: basicLevel.document_data,
        back_document_data: basicLevel.back_document_data,
        faces_images: basicLevel.faces_images,
        status: basicLevel.status,
        edit_fields: basicLevel.edit_fields,
        user_level: basicLevel.user_level,
        next_level: basicLevel.next_level,
        email: basicLevel.email,
        is_legal_representative: '0'
      };
      setInputs(inputsBas);
    }

    async function loadLevelBasic(preId) {
      setLoading(1);
      try {
        if (!empty(preId)) {
          let result = await getLevelCorporateBetaShareholderRecordById(
            userInfo.UserId,
            selectedAccountId,
            preId
          );

          let share = result.data.corporatedata.find(function(item) {
            return item.id === preId;
          });

          let inputsForm = JSON.parse(share.dataForm);

          let shareLevel = {
            ...initialState,
            id: share.id,
            reviewStatus: reviewStatus,
            ...inputsForm,
            edit_fields: share.editFields,
            status: reviewStatus === 'INCORRECT_INFORMATION' ? 5 : null
          };
          const incorrectFiels = JSON.parse(share.editFields);
          Object.keys(shareLevel).forEach(element => {
            if (incorrectFiels.includes(element)) {
              shareLevel = { ...shareLevel, [element]: null };
            }
          });
          let errors = {};
          incorrectFiels.forEach(e => {
            errors[e] = true
          })
          setErrors(errors)
          setBasicLevel(shareLevel);
          setInputsBasic(shareLevel);
        }
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }

      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(preId)) {
          loadLevelBasic(preId);
        }
      },
      [preId]
    );

    /* Obteniendo carga al iniciar */
    const getListCountries = async () => {
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
        return !empty(result);
      } catch (error) {
        getListCountries();
        // toast.warn(context.t('Information could not accesible'), {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
    };
    useEffect(() => {
      if (!empty(userInfo)) {
        getListCountries();
      }
    }, []);
    /* terminando carga al iniciar */

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      is_legal_representative: '0'
      //email: !empty(userInfo.Email) ? userInfo.Email : ''
    };

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        if (!empty(preId) && reviewStatus === 'INCORRECT_INFORMATION') {
          let result = await resendFormCorporateBetaAllShareholderById(
            userInfo.UserId,
            inputs,
            selectedAccountId,
            companyMasterId
          );
          handleClose();
          if (props.hasOwnProperty('alertIfComplete')) {
            props.alertIfComplete(inputs.id, true);
          }
        } else {
          let result = await submitFormCorporateBetaShareholderRecord(
            userInfo.UserId,
            inputs,
            selectedAccountId,
            companyMasterId
          );
          await loadLevel();
          let inputsClear = {};
          setInputs(inputs => ({
            ...inputsClear,
            ...initialState,
            validated: false
          }));

          handleClose();
          toast.success(context.t('The shareholder was added successfully'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {};

    let {
      inputs,
      errors,
      setErrors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    let optionsDocument = [];
    if (!empty(inputs) && inputs.nationality === 'PE') {
      optionsDocument = [{ value: 'ID', name: 'National identity document' }];
    } else {
      optionsDocument = [{ value: 'PP', name: 'Passport' }];
    }

    let steps = [
      {
        title: 'Personal Data',
        hint: [
          {
            p: 'Complete the form with all your personal data'
          },
          {
            p: '',
            links: [
              {
                title: 'See limits',
                href:
                  'https://soporte.banexcoin.com/support/solutions/articles/60000609834'
              }
            ]
          }
        ],
        fields: [
          {
            label: 'Percentage Number %',
            name: 'percentage_number',
            type: 'number',
            props: [
              {
                required: true,
                autoComplete: 'off',
                step: '0.01',
                min: '0',
                max: '100'
              }
            ]
          },
          {
            label: 'First Name',
            name: 'first_name',
            type: 'text',
            props: [
              {
                required: true,
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Middle name',
            name: 'middle_name',
            type: 'text',
            props: [
              {
                required: false,
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Last Name',
            name: 'last_name',
            type: 'text',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Second Last Name',
            name: 'second_last_name',
            type: 'text',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Date of Birth',
            name: 'birthday',
            type: 'date',
            props: [
              {
                required: true,
                isEighteen: true
              }
            ]
          },
          {
            label: 'Nationality',
            name: 'nationality',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Birth Country',
            name: 'birth_country',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Country of Residence',
            name: 'residence_country',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Residence address',
            name: 'address_main',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Residence address'
              }
            ]
          },
          {
            label: 'Building number or name',
            name: 'building',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Building number or name',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Interior # / Apartment # / Office #',
            name: 'apt_number',
            type: 'text',
            size: '6',
            props: [
              {
                autoComplete: 'off',
                required: true
              }
            ]
          },
          {
            label: 'Zonification / Town',
            name: 'town',
            type: 'text',
            size: '6',
            props: [
              {
                autoComplete: 'off',
                required: true
              }
            ]
          },
          {
            label: 'State',
            name: 'state',
            type: 'select',
            options: states[inputs.residence_country],
            props: [
              {
                required: true
              }
            ]
          },
          inputs.residence_country === 'PE'
            ? {
                label: 'City / Province',
                name: 'city',
                type: 'select',
                options: province[inputs.state],
                props: [
                  {
                    required: true
                  }
                ]
              }
            : {
                label: 'City / Province',
                name: 'city',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
          inputs.residence_country === 'PE'
            ? {
                label: 'Municipality / District',
                name: 'district',
                type: 'select',
                options: districts[inputs.city],
                props: [
                  {
                    required: true
                  }
                ]
              }
            : {
                label: 'Municipality / District',
                name: 'district',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
          {
            label: 'Zip Code',
            name: 'zip_code',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Zip Code',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Activity, occupation, trade or profession',
            name: 'occupation',
            type: 'text',
            props: [
              {
                required: true,
                placeholder: 'Activity, occupation, trade or profession'
              }
            ]
          },
          {
            label: 'Work Center',
            name: 'work_center',
            type: 'text',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Phone',
            name: 'phone',
            type: 'tel',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Email',
            name: 'email',
            type: 'email',
            props: [
              {
                required: true,
                pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
              }
            ]
          },
          {
            label: 'Is a PEP? (People Exposed Politically)',
            name: 'is_pep',
            type: 'select',
            options: [{ value: '0', name: 'No' }, { value: '1', name: 'Yes' }],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Upload required PEP Document',
            name: 'pep_document',
            type: 'file',
            subdata: [
              {
                type: 'download-link',
                className: 'important',
                label:
                  'Download the document file and fill it out. Then, upload the file to validate it.',
                href:
                  'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.pdf'
              }
            ],
            displayOn: [
              {
                parent: 'is_pep',
                conditionValue: '1'
              }
            ],
            props: [
              {
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          }
        ]
      },
      {
        title: 'Billing Information',
        hint: [
          {
            p:
              'At this address, we can send you some communication if necessary, normally it is where you receive your bank statements or any service'
          }
        ],
        fields: [
          {
            label: 'Same as Residence Address',
            name: 'is_billing_address_residence',
            type: 'checkbox',
            props: [{}]
          },
          {
            label: 'Billing Address',
            name: 'billing_address',
            type: 'text',
            size: 12,
            props: [
              {
                required: true,
                autoComplete: 'off',
                placeholder: inputs.is_billing_address_residence
                  ? inputs.billing_address
                  : 'Billing Address'
              }
            ]
          },
          {
            label: 'Billing Building number or name',
            name: 'billing_building',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: inputs.is_billing_address_residence
                  ? inputs.billing_building
                  : 'Building number or name',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Billing Interior # / Apartment #',
            name: 'billing_apt_number',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: inputs.is_billing_address_residence
                  ? inputs.billing_apt_number
                  : 'Interior # / Apartment #',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Zonification',
            name: 'billing_town',
            type: 'text',
            size: '6',
            props: [
              {
                placeholder: inputs.is_billing_address_residence
                  ? inputs.billing_town
                  : 'Zonification',
                autoComplete: 'off',
                required: true
              }
            ]
          },
          {
            label: 'Billing Country',
            name: 'billing_country',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          inputs.billing_country === 'PE'
            ? {
                label: 'Billing State',
                name: 'billing_state',
                type: 'select',
                options: states[inputs.billing_country],
                props: [
                  {
                    required: true,
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_state
                      : ''
                  }
                ]
              }
            : {
                label: 'Billing State',
                name: 'billing_state',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'off',
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_state
                      : 'Billing State'
                  }
                ]
              },
          inputs.billing_country === 'PE'
            ? {
                label: 'Billing City / Province',
                name: 'billing_city',
                type: 'select',
                options: province[inputs.billing_state],
                props: [
                  {
                    required: true,
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_city
                      : ''
                  }
                ]
              }
            : {
                label: 'Billing City / Province',
                name: 'billing_city',
                type: 'text',
                props: [
                  {
                    required: true,
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_city
                      : 'Billing City / Province'
                  }
                ]
              },
          inputs.billing_country === 'PE'
            ? {
                label: 'Billing Municipality / District',
                name: 'billing_district',
                type: 'select',
                options: districts[inputs.billing_city],
                props: [
                  {
                    required: true,
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_district
                      : ''
                  }
                ]
              }
            : {
                label: 'Billing Municipality / District',
                name: 'billing_district',
                type: 'text',
                props: [
                  {
                    required: true,
                    placeholder: inputs.is_billing_address_residence
                      ? inputs.billing_district
                      : 'District'
                  }
                ]
              },
          {
            label: 'Billing Zip Code',
            name: 'billing_zip',
            type: 'text',
            props: [
              {
                required: true,
                autoComplete: 'off',
                placeholder: inputs.is_billing_address_residence
                  ? inputs.zip_code
                  : 'Zip Code'
              }
            ]
          }
        ]
      },
      {
        title: 'Upload document and selfie',
        hint: [
          {
            p: 'Crop the images before uploading if you wish'
          }
        ],
        fields: [
          {
            label: 'Document Type',
            name: 'document_type',
            type: 'select',
            options: optionsDocument,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Document Country',
            name: 'document_country',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          !empty(inputs.document_country) && inputs.document_country === 'PE'
            ? {
                label: 'Document Number (8/12 digits)',
                name: 'document_number',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'off'
                  }
                ]
              }
            : {
                label: 'Document Number',
                name: 'document_number',
                type: 'text',
                props: [
                  {
                    required: true,
                    autoComplete: 'off'
                  }
                ]
              },
          {
            label: 'Document Data',
            name: 'document_data',
            type: 'file',
            filetype: 'image',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
              }
            ],
            props: [
              {
                required: true,
                accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
              }
            ]
          },
          {
            label:
              inputs.document_type === 'PP'
                ? 'Identity Document of the Country where you reside'
                : 'Back Document Data',
            name: 'back_document_data',
            type: 'file',
            filetype: 'image',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
              }
            ],
            props: [
              {
                required: true,
                accept: 'image/x-png,image/jpeg,image/heic,image/heif,image/png'
              }
            ]
          },
          {
            label: 'Selfie Photo',
            name: 'faces_images',
            type: 'file',
            filetype: 'image',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label: '(Supported formats: .JPG and .PNG, maximum size 2 MB)'
              }
            ],
            props: [
              {
                required: true,
                accept: 'image/x-png,image/png,image/jpeg,image/heic,image/heif'
              }
            ]
          },
          {
            label: 'Instructions for your selfie photo',
            name: 'photo_selfie_instructions',
            type: 'info',
            subdata: {
              type: 'text',
              content: context.t(
                'You must write the word “Banexcoin” on a piece of paper, followed by the current date and ID number provided. Next to it, the photo must be taken with your {document} (this must have a photo) and the complete user’s face, as is shown in the following example:',
                {
                  document:
                    inputs.document_type === 'PP'
                      ? context.t('Passport')
                      : inputs.document_type === 'ID'
                        ? context.t('National identity document')
                        : ''
                }
              )
            },
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: '',
            name: 'photo_selfie_2',
            type: 'info',
            subdata: {
              type: 'image',
              content:
                inputs.document_type === 'PP'
                  ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_pp.png'
                  : inputs.document_type === 'ID'
                    ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_dni.jpg'
                    : ''
            },
            props: [
              {
                required: false
              }
            ]
          }
        ]
      }
    ];

    let img = [];
    let indexImg = 1;

    steps[currentStep - 1].fields.forEach(function(data) {
      if (data.filetype == 'image') {
        img.push(
          <React.Fragment key={indexImg}>
            <h4 className="title-image">{context.t(data.label)}</h4>
            <img
              id={data.name}
              src={
                !empty(files[data.name])
                  ? files[data.name]
                  : !empty(inputs[data.name])
                    ? inputs[data.name]
                    : data.name === 'faces_images'
                      ? inputs.document_type === 'PP'
                        ? 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_pp.png'
                        : 'https://banexcoin.sgp1.digitaloceanspaces.com/banexcoinweb/image_dni.jpg'
                      : '/local/landing-page/images/no-image-box.png'
              }
              className="crop-src-img"
            />
          </React.Fragment>
        );
        indexImg++;
      }
    });

    let hints = [];
    let indexHints = 1;
    let hint = steps[currentStep - 1].hint;
    if (!empty(hint)) {
      steps[currentStep - 1].hint.forEach(function(data) {
        let a = [];
        let links = data.links;
        if (!empty(links)) {
          for (let i in links) {
            a.push(
              <a
                key={i}
                role="button"
                target="_blank"
                className="btn btn-primary"
                href={links[i].href}
                title={context.t(links[i].title)}>
                {context.t(links[i].title)}
              </a>
            );
          }
        }

        hints.push(
          <React.Fragment key={indexHints}>
            <h4>{context.t(data.p)}</h4>
            {a}
          </React.Fragment>
        );
        indexHints++;
      });
    }

    return (
      <Modal show={show} onHide={handleClose} className="modal-step-level">
        <Loading loading={loading} />
        <Modal.Header closeButton />
        <div className="modal-container-step-level">
          <div className="row left-fix">
            <div className="col-4 left-step">
              {/* <img src="/local/landing-page/images/isotipo_new.png" className="logo-step" /> */}
              <div className="step-side-information">
                <span />
                {!empty(steps[currentStep - 1]) ? (
                  <React.Fragment>
                    <h3>
                      {!empty(steps[currentStep - 1].title)
                        ? context.t(steps[currentStep - 1].title)
                        : ''}
                    </h3>
                    {hints}
                    {img}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-8 right-step">
              <div className="row">
                <h3>{context.t('Add a New Partner or Shareholder')}</h3>
                <Form
                  noValidate
                  validated={inputs.validated}
                  encType="multipart/form-data"
                  className={!empty(errors) ? 'was-validated' : ''}
                  onSubmit={onSubmit}>
                  {!empty(inputs) && inputs.status === 1 ? (
                    <Alert variant="warning">
                      {context.t(
                        'Your request is under review. Within the next 24 business hours, you will receive a notification via email.'
                      )}
                    </Alert>
                  ) : inputs.status === 5 ? (
                    <Alert variant="warning">
                      {context.t(
                        "Your request has been rejected because one or more fields are incorrect. Don't worry, you can resend the correct information. The fields to correct are highlighted on the form."
                      )}
                    </Alert>
                  ) : (
                    ''
                  )}
                  <AutoForm
                    inputs={inputs}
                    steps={steps}
                    setFiles={openFile}
                    onInputChange={onInputChange}
                    onInputFileChange={onInputFileChange}
                    onInputChangeByName={onInputChangeByName}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    imagesBlob={imagesBlob}
                    setBlobByName={setBlobByName}
                    requestedLevel={4}
                    isShareholder={true}
                    setValidate={setValidate}
                    submitText={
                      reviewStatus === 'INCORRECT_INFORMATION'
                        ? 'Correct Fields'
                        : null
                    }
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  } catch (error) {
    console.log('error ModalCorporatePersonShareholder', error);
    return <div>Error:</div>;
  }
};

ModalCorporatePersonShareholder.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(ModalCorporatePersonShareholder);
