import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

/*var mapStateToProps = function mapStateToProps(state) {
  console.log("state", state)
  var _ref = state.apexCore ? state.apexCore : {},
      level1 = _ref.level1,
      instrument = _ref.instrument;

  var { instruments } = instrument;
  return {
    level1,
    level2,
    instruments
  };
};*/

const Ticket = (props, context) => {
  let { instruments, level1 } = props;
  var newLevel1 = [];
  for (var i in level1) {
    newLevel1.push(level1[i]);
  }
  const lvlFiltered = newLevel1.filter(
    lvl =>
      lvl.InstrumentId === 1 ||
      lvl.InstrumentId === 5 ||
      lvl.InstrumentId === 11
  );

  const Ticker = lvlFiltered.map((x, i) => {
    const currenInstrument = instruments.filter(
      instrument => instrument.InstrumentId === x.InstrumentId
    )[0];
    let precio = level1[x.InstrumentId].BestBid;
    const lastPrecio = localStorage.getItem(currenInstrument.Symbol)
      ? localStorage.getItem(currenInstrument.Symbol)
      : 0;
    const precioIns = precio ? precio : 0.0;
    const highPrice = x.SessionHigh;
    const status_incdec =
      precioIns >= lastPrecio ? 'trending_up' : 'trending_down';
    localStorage.setItem(currenInstrument.Symbol, precioIns);
    //console.log("Ultimo Precio", x.Symbol, lastPrecio, " Actual", precioIns, status_incdec );
    return (
      <React.Fragment key={x.InstrumentId}>
        {precioIns > 0 ? (
          <div className="ticker-block">
            <p className="ticker-symbol">
              {currenInstrument.Symbol}
              <span className={status_incdec} />
            </p>
            <p className="ticker-last-trade">
              {formatNumberToLocale(
                level1[x.InstrumentId].BestBid,
                convertIncrementToIntDecimalPlaces(
                  currenInstrument.PriceIncrement
                )
              )}
              <span className="currency">
                {currenInstrument.Product2Symbol}
              </span>
            </p>
            <div className="ticker-session">
              <span>{context.t('High:')}</span>
              <span className="session-value">
                {highPrice ? highPrice : '-'}
              </span>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
      <div className="ticker">{Ticker}</div>
    </React.Fragment>
  );
};

Ticket.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  }
}) => {
  return { instruments, level1 };
};

export default connect(mapStateToProps)(Ticket);
