import React from 'react';
import PropTypes from 'prop-types';
import APIcon from 'apex-web/lib/components/common/APIcon/APIcon';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { kycConfigLevelShape } from 'apex-web/lib/propTypes/kycConfigShapePropTypes';

import './KYCLevelComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var kycClasses = getBEMClasses('kyc-level');

var KYCLevelComponent = (function(_React$Component) {
  _inherits(KYCLevelComponent, _React$Component);

  function KYCLevelComponent() {
    _classCallCheck(this, KYCLevelComponent);

    return _possibleConstructorReturn(
      this,
      (
        KYCLevelComponent.__proto__ || Object.getPrototypeOf(KYCLevelComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(KYCLevelComponent, [
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var _props = this.props,
          verified = _props.verified,
          underReview = _props.underReview,
          canIncrease = _props.canIncrease,
          currentLevel = _props.currentLevel,
          openPane = _props.openPane,
          highlightStyle = _props.highlightStyle,
          _props$config = _props.config,
          label = _props$config.label,
          button = _props$config.button,
          verifiedMessage = _props$config.verifiedMessage,
          underReviewMessage = _props$config.underReviewMessage,
          _props$config$descrip = _props$config.description,
          benefits = _props$config$descrip.benefits,
          requirements = _props$config$descrip.requirements;

        var activeCard = currentLevel ? 'selected' : '';

        return React.createElement(
          'div',
          { className: kycClasses(null, activeCard) },
          React.createElement(
            'div',
            { className: kycClasses('header-title', activeCard) },
            this.context.t(label)
          ),
          highlightStyle === 'star' &&
            currentLevel &&
            React.createElement(APIcon, {
              name: 'star',
              className: kycClasses('star-icon')
            }),
          React.createElement(
            'div',
            { className: kycClasses('body') },
            benefits &&
              benefits.length > 0 &&
              React.createElement(
                'div',
                { className: kycClasses('benefits') },
                React.createElement(
                  'div',
                  { className: kycClasses('sub-title', activeCard) },
                  this.context.t('Benefits')
                ),
                React.createElement(
                  'ul',
                  { className: kycClasses('list') },
                  benefits.map(function(benefit, idx) {
                    return React.createElement(
                      'li',
                      { key: idx, className: kycClasses('list-item') },
                      _this2.context.t(benefit)
                    );
                  })
                )
              ),
            React.createElement(
              'div',
              { className: kycClasses('requirements') },
              React.createElement(
                'div',
                { className: kycClasses('sub-title', activeCard) },
                this.context.t('Requirements')
              ),
              React.createElement(
                'div',
                { className: kycClasses('item-description') },
                this.context.t(requirements)
              )
            )
          ),
          verified &&
            React.createElement(
              'div',
              { className: kycClasses('footer', 'verified') },
              React.createElement(APIcon, {
                name: 'simple-check',
                customClass: kycClasses('verified-icon')
              }),
              this.context.t(verifiedMessage)
            ),
          underReview &&
            React.createElement(
              'div',
              { className: kycClasses('footer', 'under-review') },
              React.createElement(APIcon, {
                name: 'pending',
                customClass: kycClasses('under-review-icon')
              }),
              this.context.t(underReviewMessage)
            ),
          canIncrease &&
            React.createElement(
              'div',
              { className: kycClasses('footer', 'can-increase') },
              React.createElement(
                APButton,
                { customClass: kycClasses('change'), onClick: openPane },
                this.context.t(button) ||
                  this.context.t('Increase to {label}', {
                    label: this.context.t(label)
                  })
              )
            ),
          !verified &&
            !underReview &&
            !canIncrease &&
            React.createElement(
              'div',
              { className: kycClasses('footer', 'cannot-increase') },
              React.createElement(
                APButton,
                { customClass: kycClasses(), disabled: true },
                this.context.t(button) ||
                  this.context.t('Increase to {label}', { label: label })
              )
            )
        );
      }
    }
  ]);

  return KYCLevelComponent;
})(React.Component);

KYCLevelComponent.propTypes = {
  verified: PropTypes.bool,
  underReview: PropTypes.bool,
  currentLevel: PropTypes.bool,
  canIncrease: PropTypes.bool,
  config: PropTypes.shape(kycConfigLevelShape),
  openPane: PropTypes.func,
  highlightStyle: PropTypes.string
};

KYCLevelComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default KYCLevelComponent;
