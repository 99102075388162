import React from "react";
import "./skeleton.css";

function Skeleton({ width, height, quantity }) {
  return (
    <div className="skeleton-container">
      {Array(quantity)
        .fill()
        .map((_, i) => <div key={i} className="skeleton" style={{ width, height }}></div>
      )}
    </div>
  );
}

export default Skeleton;
