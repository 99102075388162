import isEmpty from "is-empty";
import HandlerBackend from "../../../helpers/HandlerBackend";

export const getPublicInstruments = async () => {
  try{
    const path = '/api/v1/globalconfig/99999';
    const response = await HandlerBackend.sendRequest({ path });
    const { data } = response 
    if(!isEmpty(data)){
      return data.instrumentsExchange
    }
    return []
  }catch(error){
    return []
  }
}