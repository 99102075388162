import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Table, Badge, Col, Row, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import Loading from '../../../components/Loading';
import { getMyPaymentAttempts } from '../EasyBuyHooks';
import './../EasyBuyPage.css';
import { circlePaymentError, fixNumber, statusCircle } from '../../utils';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const EasyBuyPageMyPaymentAttemps = (props, context) => {
  try {
    const { userInfo, selectedAccountId, accounts } = props;
    let paginator = {};
    let history = useHistory();
    if (
      !props.hasOwnProperty('match') ||
      !props.match.hasOwnProperty('params') ||
      empty(props.match.params)
    ) {
      history.push('/easy-buy/my-payment-attempts/10/1');
    } else {
      paginator = props.match.params;
    }
    const [loading, setLoading] = useState(0);
    const [attempsTotal, setAttempsTotal] = useState(0);
    const [myPaymentAttempts, setMyPaymentAttempts] = useState({});
    document.title = `${context.t('Easy Buy')} - ${context.t(
      'My payment attempts'
    )} - Banexcoin`;

    const changePage = page => {
      history.push(`/easy-buy/my-payment-attempts/10/${page}`);
    };

    async function startData() {
      setLoading(1);
      try {
        if (!empty(paginator)) {
          let attempts = await getMyPaymentAttempts(
            userInfo.UserId,
            selectedAccountId,
            paginator
          );

          if (attempts.data.totalCount >= 0) {
            setMyPaymentAttempts(attempts.data.data);
            setAttempsTotal(attempts.data.totalCount);
          }
        }
      } catch (error) {
        console.log('error getting attempts', error);
      }

      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(selectedAccountId)) {
          startData();
        }
      },
      [userInfo, selectedAccountId, paginator]
    );

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="container credit-card-buy">
          <Row className="justify-content-md-center">
            <Col lg={8}>
              <Row className="col-fix add-card">
                <h2 className="card-title">
                  {context.t('My payment attempts')}
                </h2>
                <Button
                  variant="banexcoin"
                  className="btn-top"
                  onClick={e => {
                    startData();
                  }}>
                  {context.t('Update')}
                </Button>
                <Table striped bordered hover className="credit-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{context.t('Type')}</th>
                      <th>{context.t('Transaction Date')}</th>
                      <th>{context.t('Status')}</th>
                      <th>{context.t('Reference')}</th>
                      <th>{context.t('Purchased amount')}</th>
                      <th>{context.t('Total amount paid')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!empty(myPaymentAttempts) &&
                      myPaymentAttempts.map((data, index) => {
                        let respPayload = {};
                        try {
                          respPayload = !empty(data.payloadResponse)
                            ? JSON.parse(data.payloadResponse)
                            : {};
                        } catch (error) {
                          console.error('error reading data Payload', data);
                        }

                        let initIndex =
                          (Number(paginator.page) - 1) *
                          Number(paginator.limit);

                        return (
                          <tr key={index}>
                            <td>{index + initIndex + 1}</td>
                            <td>
                              {context.t('{product} bought with {currency}', {
                                product: data.product,
                                currency: data.currency
                              })}
                            </td>
                            <td>
                              {moment(data.createdAt)
                                .format('DD-MM-YYYY h:mm:ss a')
                                .toString()}
                            </td>
                            <td
                              className={`status ${
                                data.status
                              } status-container-bnx`}>
                              <Badge variant={statusCircle(data.status)}>
                                {context.t(data.status)}
                              </Badge>
                            </td>
                            <td>{data.reference}</td>
                            <td>
                              {context.t('{amountBought} {product}', {
                                amountBought: data.productTotalInitial,
                                product: data.product
                              })}
                            </td>
                            <td>
                              {context.t('{currency} {paymentTotal}', {
                                paymentTotal: fixNumber(data.paymentTotal),
                                currency: data.currency
                              })}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!empty(paginator) && attempsTotal > 0 ? (
                  <div className="paginator-bottom-bnx">
                    <Pagination
                      activePage={Number(paginator.page)}
                      itemsCountPerPage={Number(paginator.limit)}
                      totalItemsCount={attempsTotal}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="card-lefted-footer" style={{ width: '100%' }}>
                  <Link to="/easy-buy/add-card" className="btn btn-banexcoin">
                    {context.t('Add new credit card')}
                  </Link>
                  <Link to="/easy-buy" className="btn btn-secondary">
                    {context.t('Buy more')}
                  </Link>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('Error on EasyBuyAddCard', e);
    return <div>Error:</div>;
  }
};

EasyBuyPageMyPaymentAttemps.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(EasyBuyPageMyPaymentAttemps);
