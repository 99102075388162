import React, { useState } from 'react';
import empty from 'is-empty';
import { Table, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateTrustList } from './TrustListHooks';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const RobotSvg = require('../../images/robot.svg');
const trustListClasses = getBEMClasses('trust-list');

const TrustList = (props, context) => {
  const {
    selectedAccountId,
    userInfo,
    trustList,
    setSelectedTrustList,
    setShowModal
  } = props;

  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>{context.t('Loading...')}</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);

    const loadRows = () => {
      const trustList = props.trustList;
      let rows = [];

      if (empty(trustList)) {
        return (
          <tr>
            <td colSpan="14">
              {this.props.loading > 0 ? 'Actualizando información..' : ''}
            </td>
          </tr>
        );
      }

      for (let k in trustList) {
        rows.push(
          <tr
            key={k}
            onClick={() => {
              setSelectedTrustList(trustList[k].fieldOperation);
              setShowModal(false);
            }}>
            <td>{trustList[k].label}</td>
            <td>{trustList[k].fieldOperation}</td>
            <td className="align-center">{loadOptionsDelete(trustList[k])}</td>
          </tr>
        );
      }
      return rows;
    };

    const confirmDelete = async id => {
      const r = window.confirm(
        context.t('Do you really want to delete the record?')
      );

      if (r === true) {
        setLoading(1);
        const dataTrustList = { status: false };
        await updateTrustList(userInfo.UserId, id, dataTrustList);
        props.updateListTrustList();
        setLoading(0);
      }
    };

    const loadOptionsDelete = row => {
      return (
        <OverlayTrigger
          key={row.id}
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-${row.id}`}>
              {context.t('Delete trust list')}
            </Tooltip>
          }>
          <Button
            variant="info"
            onClick={async () => {
              confirmDelete(row.id);
            }}>
            <FontAwesomeIcon icon="minus-circle" />
          </Button>
        </OverlayTrigger>
      );
    };

    return userInfo.Use2FA ? (
      <React.Fragment>
        <Loading loading={loading} />
        <div className={trustListClasses()}>
          <Card>
            <Card.Header>
              <Card.Title className="align-center">
                {context.t('Trust list')}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {!empty(trustList) ? (
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th className="align-center">{context.t('Label')}</th>
                      <th className="align-center">
                        {props.useExternalAddress
                          ? context.t('External address')
                          : context.t('Email')}
                      </th>
                      <th className="align-center">{context.t('Options')}</th>
                    </tr>
                  </thead>
                  <tbody>{loadRows()}</tbody>
                </Table>
              ) : (
                <p>{context.t('There are no records to show')}</p>
              )}
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={trustListClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

TrustList.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(TrustList);
