import React from 'react';
import PropTypes from 'prop-types';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FormButtonV2 } from '../../../../components/common/BnxFormsComponents';
import InformationIcon from '../../../../images/bnx-icons/information-circle.svg'

const ModalConfirmationWithdrawCoink = (props, context) => {
    const {show, handleClose, handleContinue, data} = props
    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='buysell-confirm coink-withdraw-confirmation'
        backdropClassName='buysell-backdrop'
    >
        <Modal.Header closeButton>
            <Modal.Title>{context.t('Withdraw Details')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table hover className='buysell-details'>
                <tbody>
                    <tr>
                        <td className='container-help-text'>
                            {context.t('Amount to withdraw')}
                        </td>
                        <td className='text-right'>$ {data.amountToWithdraw}</td>
                    </tr>
                    <tr>
                        <td className='container-help-text'>
                            {context.t('Banexcoin Commission')}
                        </td>
                        <td className='text-right text-danger'>$ {data.commission}</td>
                    </tr>
                    <tr>
                        <td className='container-help-text'>
                            {context.t('Total to receive')}
                        </td>
                        <td className='text-right'>$ {data.totalToReceive}</td>
                    </tr>

                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <FormButtonV2 variant={'dark'} className='submit-button' onClick={handleContinue}>
                {context.t("Continue")}
            </FormButtonV2>
        </Modal.Footer>
    </Modal>;
}

ModalConfirmationWithdrawCoink.contextTypes = {
    t: PropTypes.func.isRequired
};

export default withAuthentication(ModalConfirmationWithdrawCoink);