import React from "react";
import PropTypes from "prop-types";
import checkIcon from "../../../../images/icons-v2/check.svg";
import "./options-form.css";

function OptionsForm(props, context) {
  const { options, handleSelectedOption, selectedOption, id = "title", containerClassName } = props;

  const classNameValidation = (property) =>
    selectedOption === property ? "selected" : "";

  return (
    <ul className={`options-form ${containerClassName || ""}`}>
      {options.map((el) => {
        const { title, description } = el;
        return (
          <li
            className={`options-form-option ${classNameValidation(el[id])}`}
            onClick={() => handleSelectedOption(el[id])}
          >
            <div className='option-info'>
                <span>{title}</span>
                {description && <p>{description}</p>}
            </div>
            {classNameValidation(el[id]) ? (
              <img src={checkIcon} />
            ) : (
              <div className="unselected-circle"></div>
            )}
          </li>
        )
      })}
    </ul>
  );
}

OptionsForm.propTypes = {
  options: PropTypes.array.isRequired,
  handleSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  id: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default OptionsForm;
