import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APTable from 'apex-web/lib/components/common/APTable';
import APButton from 'apex-web/lib/components/common/APButton';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import { formatDate, formatTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import apex from 'apex-web/lib/apex';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import './TransferComponents.css';
import ReactTooltip from 'react-tooltip';

var cancelTransfer = async function cancelTransfer(id, AccountId, context) {
  let payload = { omsid: '1', accountid: '' + AccountId, transferid: '' + id };
  const result = await apex.connection.CancelTransferFunds(payload);
  if (result.result) {
    toast.success(context.t('Operation cancelled'), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000
    });
    setTimeout(function() {
      window.location.reload();
    }, 5000);
  } else {
    toast.warn(context.t('An error has occurred'), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000
    });
    setTimeout(function() {
      window.location.reload();
    }, 5000);
  }
};
var baseClasses = getBEMClasses('transfers');

var TransfersComponent = function TransfersComponent(props, context) {
  var getAmountRow = function getAmountRow(_ref) {
    var Amount = _ref.Amount,
      product = _ref.product;
    return getMultilineCell(
      formatNumberToLocale(Amount, product.DecimalPlaces),
      product.ProductFullName
    );
  };

  var getDateRow = function getDateRow(_ref2) {
    var CreatedTimestamp = _ref2.CreatedTimestamp;
    return getMultilineCell(
      formatDate(CreatedTimestamp),
      formatTime(CreatedTimestamp)
    );
  };

  var getMultilineCell = function getMultilineCell(
    primarytext,
    secondaryText,
    cancelButton,
    statustext
  ) {
    return React.createElement(
      'div',
      { className: baseClasses('multiline-col') },
      React.createElement(
        'div',
        { className: baseClasses('primary-text') },
        primarytext
      ),
      React.createElement(
        'div',
        { className: baseClasses('secondary-text') },
        secondaryText
      ),
      React.createElement(
        'div',
        { className: baseClasses('Status-text') },
        statustext
      ),
      cancelButton
    );
  };

  var getCell = function getCell(field) {
    return function(row) {
      const textWithoutSpaces = row[field]
        .replaceAll(/\n/g, '')
        .replaceAll(' ', '');
      return (
        <div className={baseClasses('primary-text')}>
          <span
            className={`textWithPointSuspense ${
              textWithoutSpaces.length < 12 ? 'maxWidth' : ''
            }`}
            data-tip
            data-for={textWithoutSpaces}>
            {row[field].replaceAll(/\n/g, '')}
          </span>
          {textWithoutSpaces.length > 12 && (
            <ReactTooltip
              id={textWithoutSpaces}
              place="bottom"
              multiline={true}
              effect="solid"
              className="rtooltip2">
              {row[field].replaceAll(/\n/g, '')}
            </ReactTooltip>
          )}
        </div>
      );
    };
  };

  var getActionButtons = function getActionButtons(row) {
    if (row.Status === 'TransferCompleted' || row.Status === 'PayerRejected') {
      return null;
    }

    return React.createElement(
      'div',
      null,
      React.createElement(
        APButton,
        {
          styleName: styleNames.additive,
          onClick: function onClick() {
            return allowRequest(row);
          }
        },
        context.t('Approve')
      ),
      '\xA0',
      React.createElement(
        APButton,
        {
          styleName: styleNames.subtractive,
          onClick: function onClick() {
            return rejectRequest(row);
          }
        },
        context.t('Reject')
      )
    );
  };

  var cancelButton = function cancelButton(row) {
    function alertCancel(id, AccountId) {
      confirmAlert({
        title: context.t('Security Warning'),
        message: context.t('Are you sure you want to cancel the transfer?'),
        buttons: [
          {
            label: 'Accept',
            onClick: () => {
              cancelTransfer(id, AccountId, context);
            }
          },
          {
            label: 'Cancel',
            onClick: () => {}
          }
        ]
      });
    }
    return React.createElement(
      APButton,
      {
        styleName: styleNames.subtractive,
        onClick: function onClick() {
          return alertCancel(row.TransferId, row.SenderAccountId);
        }
      },
      context.t('Cancel')
    );
  };

  var getTransfersColumns = function getTransfersColumns() {
    return [
      {
        header: context.t('Action'),
        classModifier: 'transfers-action',
        cell: function cell(row) {
          var selectedFilter = props.selectedFilter;
          // console.log("datos",row)
          var isSent = selectedFilter === 'sentTransfers';
          var sent = context.t('Sent') + ' ' + row.product.ProductFullName;
          var received =
            context.t('Received') + ' ' + row.product.ProductFullName;
          var from = context.t('From') + ' ' + row.SenderUserName;
          var to = context.t('To') + ' ' + row.ReceiverUserName;
          return isSent
            ? getMultilineCell(sent, to)
            : getMultilineCell(received, from);
        }
      },
      {
        header: context.t('Amount'),
        cell: getAmountRow,
        classModifier: 'amount'
      },
      {
        header: context.t('Note'),
        cell: getCell('Notes')
      },
      {
        header: context.t('Status'),
        classModifier: 'status',
        cell: function cell(row) {
          var selectedFilter = props.selectedFilter;
          var isSent = selectedFilter === 'sentTransfers';
          var state = row.TransferState;
          return isSent ? getMultilineCell(state) : '';
        }
      },
      {
        header: context.t('Option'),
        classModifier: 'option',
        cell: function cell(row) {
          var selectedFilter = props.selectedFilter;
          var isSent = selectedFilter === 'sentTransfers';
          var cancelb = cancelButton(row);
          var state = row.TransferState;
          return state === 'Pending' ? getMultilineCell(cancelb) : '';
        }
      }
    ];
  };

  var allowRequest = function allowRequest(req) {
    props.approveTransferRequest(req.RequestCode);
  };

  var rejectRequest = function rejectRequest(req) {
    props.rejectTransferRequest(req.RequestCode);
  };

  var getRequestsSentColumns = function getRequestsSentColumns() {
    return [
      {
        header: context.t('Recipient'),
        classModifier: 'recipient',
        cell: function cell(row) {
          return getMultilineCell(
            context.t('You requested to Receive') +
              ' ' +
              row.product.ProductFullName,
            context.t('To') + ' ' + row.PayerUsername
          );
        }
      },
      {
        header: context.t('Amount'),
        classModifier: 'amount',
        cell: getAmountRow
      },
      {
        header: context.t('Time'),
        classModifier: 'time',
        cell: getDateRow
      },
      {
        header: context.t('Note'),
        cell: getCell('Notes')
      },
      {
        header: context.t('Status'),
        classModifier: 'status',
        cell: getCell('Status')
      }
    ];
  };

  var getRequestsReceivedColumns = function getRequestsReceivedColumns() {
    return [
      {
        header: context.t('Sent By'),
        classModifier: 'sent-by',
        cell: function cell(row) {
          return getMultilineCell(
            context.t('Request for you to send') +
              ' ' +
              row.product.ProductFullName,
            context.t('From') + ' ' + row.RequestorUsername
          );
        }
      },
      {
        header: context.t('Amount'),
        classModifier: 'amount',
        cell: getAmountRow
      },
      {
        header: context.t('Time'),
        classModifier: 'time',
        cell: getDateRow
      },
      {
        header: context.t('Note'),
        cell: getCell('Notes')
      },
      {
        header: context.t('Status'),
        classModifier: 'status',
        cell: getCell('Status')
      },
      {
        header: context.t('Action'),
        cell: getActionButtons
      }
    ];
  };

  var getColumns = function getColumns(selectedFilter) {
    var columnsMethods = {
      sentTransfers: getTransfersColumns,
      receivedTransfers: getTransfersColumns,
      sentRequests: getRequestsSentColumns,
      receivedRequests: getRequestsReceivedColumns
    };

    return columnsMethods[selectedFilter]();
  };

  var usePagination = props.usePagination,
    selectedFilter = props.selectedFilter;

  var columns = getColumns(selectedFilter);
  var items = props[selectedFilter];

  return React.createElement(
    'div',
    { className: baseClasses('wrapper') },
    React.createElement(APTable, {
      componentName: 'TransfersComponent',
      columns: columns,
      pageSize: 6,
      minRow: 6,
      rows: items,
      baseClass: baseClasses,
      headerOutside: true,
      usePagination: usePagination,
      empty: context.t('No data is available')
    })
  );
};

TransfersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TransfersComponent;
