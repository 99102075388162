import { createSelector } from 'reselect';
import get from 'lodash/get';
import { formValueSelector } from 'redux-form';
import config from 'apex-web/lib/config';
import getTime from 'date-fns/get_time';
import { instrumentsWithProductFullNamesAndProductSymbols } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { typeFilter, dateFilter } from 'apex-web/lib/helpers/activityFilters';
import { getRecentActivity } from '../../helpers/recentActivityHelper';

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

var formattedTradesSelector = createSelector(
  [
    function(state) {
      return state.apexCore.orderHistory.accountTrades;
    },
    instrumentsWithProductFullNamesAndProductSymbols,
    function(_ref) {
      var manifest = _ref.productManifest.manifest;
      return manifest;
    }
  ],
  function(accountTrades, instruments, manifest) {
    var formattedTrades = accountTrades.map(function(_ref2) {
      var Quantity = _ref2.Quantity,
        TradeTimeMS = _ref2.TradeTimeMS,
        Side = _ref2.Side,
        InstrumentId = _ref2.InstrumentId,
        ExecutionId = _ref2.ExecutionId,
        Value = _ref2.Value;

      var instrument = instruments.find(function(instrument) {
        return instrument.InstrumentId === InstrumentId;
      });
      var Product1Symbol = get(instrument, 'Product1Symbol');
      var Product2Symbol = get(instrument, 'Product2Symbol');

      return {
        Type: 'trades',
        Action: {
          typeIcon: Side === 'Buy' ? 'deposit' : 'withdraw',
          productIcon: {
            productManifest: {
              fetching: false
            },
            iconFileName:
              manifest[Product1Symbol] && manifest[Product1Symbol].iconFileName,
            size: 24
          },
          params: {
            Product1Symbol: Product1Symbol,
            Product2Symbol: Product2Symbol,
            Side: Side
          }
        },
        Amount: {
          Quantity: Quantity,
          Value: Value,
          Product1Symbol: Product1Symbol,
          Product2Symbol: Product2Symbol
        },
        Status: { id: ExecutionId },
        TimeStamp: {
          raw: TradeTimeMS
        }
      };
    });
    return formattedTrades;
  }
);

var formattedDepositTicketsSelector = createSelector(
  [
    function(_ref3) {
      var depositsStatus = _ref3.deposit.depositsStatus;
      return depositsStatus;
    },
    function(_ref4) {
      var product = _ref4.apexCore.product;
      return product;
    },
    function(_ref5) {
      var manifest = _ref5.productManifest.manifest;
      return manifest;
    }
  ],
  function(depositTickets, product, manifest) {
    var products = product.products;

    var formattedDepositTickets = depositTickets.map(function(_ref6) {
      //console.log("_ref6", _ref6) consultar depositTickets
      var Amount = _ref6.Amount,
        AssetId = _ref6.AssetId,
        RequestCode = _ref6.RequestCode,
        LastUpdateTimeStamp = _ref6.LastUpdateTimeStamp,
        Status = _ref6.Status,
        TransactionDetails = _ref6.DepositInfo;

      var _ref7 =
          products.find(function(product) {
            return product.ProductId === AssetId;
          }) || {},
        Product = _ref7.Product,
        ProductType = _ref7.ProductType;

      var Value = Amount;

      return {
        Type: 'deposit',
        Action: {
          typeIcon: 'deposit',
          productIcon: {
            productManifest: {
              fetching: false
            },
            iconFileName: manifest[Product] && manifest[Product].iconFileName,
            size: 24
          },
          params: {
            Product: Product,
            ProductType: ProductType,
            Status: Status,
            TransactionDetails: TransactionDetails
          }
        },
        Amount: {
          Quantity: Amount,
          Value: Value,
          Product1Symbol: Product,
          Product2Symbol: Product
        },
        Status: { id: RequestCode },
        TimeStamp: {
          raw: getTime(LastUpdateTimeStamp)
        },
        AssetId: AssetId
      };
    });
    return formattedDepositTickets;
  }
);

var formattedWithdrawalsSelector = createSelector(
  [
    function(_ref8) {
      var withdrawTickets = _ref8.withdraw.withdrawTickets;
      return withdrawTickets;
    },
    function(_ref9) {
      var product = _ref9.apexCore.product;
      return product;
    },
    function(_ref10) {
      var manifest = _ref10.productManifest.manifest;
      return manifest;
    }
  ],
  function(withdrawTickets, product, manifest) {
    var products = product.products;

    var formattedWithdrawals = withdrawTickets.map(function(_ref11) {
      //console.log("_ref11", _ref11) temp
      var Amount = _ref11.Amount,
        AssetId = _ref11.AssetId,
        RequestCode = _ref11.RequestCode,
        LastUpdateTimestamp = _ref11.LastUpdateTimestamp,
        Status = _ref11.Status,
        TransactionDetails = _ref11.WithdrawTransactionDetails;

      var _ref12 =
          products.find(function(product) {
            return product.ProductId === AssetId;
          }) || {},
        Product = _ref12.Product,
        ProductType = _ref12.ProductType;

      var Value = Amount;

      return {
        Type: 'withdraw',
        Action: {
          typeIcon: 'withdraw',
          productIcon: {
            productManifest: {
              fetching: false
            },
            iconFileName: manifest[Product] && manifest[Product].iconFileName,
            size: 24
          },
          params: {
            Product: Product,
            ProductType: ProductType,
            Status: Status,
            TransactionDetails: TransactionDetails
          }
        },
        Amount: {
          Quantity: Amount,
          Value: Value,
          Product1Symbol: Product,
          Product2Symbol: Product
        },
        Status: { id: RequestCode },
        TimeStamp: {
          raw: getTime(LastUpdateTimestamp)
        },
        AssetId: AssetId
      };
    });
    return formattedWithdrawals;
  }
);

var formattedTransfersSelector = createSelector(
  [
    function(_ref13) {
      var _ref13$transfer = _ref13.transfer,
        transfers = _ref13$transfer.transfers,
        transfersReceived = _ref13$transfer.transfersReceived;
      return {
        transfersSent: transfers,
        transfersReceived: transfersReceived
      };
    },
    function(_ref14) {
      var products = _ref14.apexCore.product.products;
      return products;
    },
    function(_ref15) {
      var manifest = _ref15.productManifest.manifest;
      return manifest;
    }
  ],
  function(transfers, products, manifest) {
    var transfersSent = transfers.transfersSent,
      transfersReceived = transfers.transfersReceived;

    var formattedTransfers = transfersSent.map(function(_ref16) {
      var ReceiverUserName = _ref16.ReceiverUserName,
        ProductId = _ref16.ProductId,
        Amount = _ref16.Amount;
      //console.log("_ref16", _ref16)
      var _products$find =
          products.find(function(product) {
            return ProductId === product.ProductId;
          }) || {},
        Product = _products$find.Product;

      return {
        Type: 'send',
        Action: {
          typeIcon: 'withdraw',
          productIcon: {
            productManifest: {
              fetching: false
            },
            iconFileName: manifest[Product] && manifest[Product].iconFileName,
            size: 24
          },
          params: { Product: Product, ReceiverUserName: ReceiverUserName }
        },
        Amount: {
          Quantity: Amount,
          Product1Symbol: Product,
          Value: Amount,
          Product2Symbol: Product
        },
        Status: { id: 0 },
        TimeStamp: {
          raw: -1
        }
      };
    });
    var formattedIncomingTransfers = transfersReceived.map(function(_ref17) {
      var SenderUserName = _ref17.SenderUserName,
        ProductId = _ref17.ProductId,
        Amount = _ref17.Amount;

      var _ref18 =
          products.find(function(product) {
            return ProductId === product.ProductId;
          }) || {},
        Product = _ref18.Product;

      return {
        Type: 'receive',
        Action: {
          typeIcon: 'withdraw',
          productIcon: {
            productManifest: {
              fetching: false
            },
            iconFileName: manifest[Product] && manifest[Product].iconFileName,
            size: 24
          },
          params: { Product: Product, SenderUserName: SenderUserName }
        },
        Amount: {
          Quantity: Amount,
          Product1Symbol: Product,
          Value: Amount,
          Product2Symbol: Product
        },
        Status: { id: 0 },
        TimeStamp: {
          raw: -1
        }
      };
    });
    return [].concat(
      _toConsumableArray(formattedTransfers),
      _toConsumableArray(formattedIncomingTransfers)
    );
  }
);

export var recentActivitySelector = createSelector(
  [
    // This is where we will gather the information
    formattedDepositTicketsSelector,
    formattedWithdrawalsSelector,
    formattedTradesSelector,
    formattedTransfersSelector
  ],
  function(
    formattedDepositTickets,
    formattedWithdrawals,
    formattedTrades,
    formattedTransfers
  ) {
    var results = [].concat(
      _toConsumableArray(formattedDepositTickets),
      _toConsumableArray(formattedWithdrawals),
      _toConsumableArray(formattedTrades)
    );
    if (config.RecentActivity.showTransfers && config.global.allowTransfer) {
      results = [].concat(
        _toConsumableArray(results),
        _toConsumableArray(formattedTransfers)
      );
    }
    return results.sort(function(a, b) {
      var firstTimestamp = get(a, 'TimeStamp.raw');
      var secondTimestamp = get(b, 'TimeStamp.raw');
      if (firstTimestamp > secondTimestamp) {
        return -1;
      } else if (firstTimestamp < secondTimestamp) {
        return 1;
      } else {
        return 0;
      }
    });
  }
);

export var selectedRecentActivitySelector = createSelector(
  [
    recentActivitySelector,
    function(state) {
      return state.apexCore.position.selectedProductId;
    },
    function(state) {
      return state.apexCore.product.products;
    }
  ],
  function(recentActivity, selectedProductId, products) {
    var selectedProduct =
      products.find(function(item) {
        return item.ProductId === selectedProductId;
      }) || {};

    return recentActivity.filter(function(item) {
      return (
        item.Amount.Product1Symbol === selectedProduct.Product ||
        item.Amount.Product2Symbol === selectedProduct.Product
      );
    });
  }
);

export var getFilteredActivity = createSelector(
  [
    function(state) {
      return state;
    },
    function(state, filter) {
      return filter;
    }
  ],
  function(state, filter) {
    var formValue = formValueSelector('recent-activity');
    var recentActivity = getRecentActivity(state, filter);
    var type = formValue(state, 'type');
    var startDate = formValue(state, 'startDate');
    var endDate = formValue(state, 'endDate');

    return recentActivity
      .filter(function(item) {
        return typeFilter(item, type);
      })
      .filter(function(item) {
        return dateFilter(item, startDate, endDate);
      });
  }
);

export var getDepositWithdrawActivity = createSelector(
  [
    formattedDepositTicketsSelector,
    formattedWithdrawalsSelector,
    function(state) {
      return state.apexCore.position.selectedProductId;
    },
    function(state, props) {
      return props.filterMode;
    }
  ],
  function(
    formattedDepositTickets,
    formattedWithdrawals,
    selectedProductId,
    filterMode
  ) {
    var results = [].concat(
      _toConsumableArray(formattedDepositTickets),
      _toConsumableArray(formattedWithdrawals)
    );
    var filteredResults =
      filterMode === 'selectedProduct'
        ? results.filter(function(item) {
            return item.AssetId === selectedProductId;
          })
        : results;

    return filteredResults.sort(function(a, b) {
      var firstTimestamp = get(a, 'TimeStamp.raw');
      var secondTimestamp = get(b, 'TimeStamp.raw');
      if (firstTimestamp > secondTimestamp) {
        return -1;
      } else if (firstTimestamp < secondTimestamp) {
        return 1;
      } else {
        return 0;
      }
    });
  }
);
