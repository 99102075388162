import React from 'react';
import PropTypes from 'prop-types';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import CryptoNavItemsContainer from 'apex-web/lib/components/WalletNavItems/CryptoNavItemsContainer';
import FiatNavItemsContainer from 'apex-web/lib/components/WalletNavItems/FiatNavItemsContainer';
import { DepositReferenceIdModalContainer } from '../Retail/RetailSidePanes/FiatDeposit';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';

import 'apex-web/lib/components/WalletDetails/WalletDetails.css';
import { formatNumberToLocale } from '../../helpers/numberFormatter';
import { FormatDecimalCoinkWithOutSimbols, FormatDecimalCoinkWithSimbols, amountWithCurrency } from '../../pages/utils';

var baseClasses = getBEMClasses('wallet-details');
var cryptoNavItemsClasses = getBEMClasses('wallet-nav-item');

var WalletDetailsComponent = function WalletDetailsComponent(props, context) {
  var verificationLevelConfig = props.verificationLevelConfig,
      openWhitelistSidepane = props.openWhitelistSidepane;
  var _props$positionDetail = props.positionDetails,
      iconFileName = _props$positionDetail.iconFileName,
      Product = _props$positionDetail.Product === 'COP' ? '' : _props$positionDetail.Product,
      ProductFullName = _props$positionDetail.ProductFullName,
      AvailableBalance = _props$positionDetail.AvailableBalance,
      Hold = _props$positionDetail.Hold,
      PendingDeposits = _props$positionDetail.PendingDeposits,
      Amount = _props$positionDetail.Amount,
      ProductType = _props$positionDetail.ProductType,
      ProductId = _props$positionDetail.ProductId,
      rawAmount = _props$positionDetail.rawAmount,
      baseCurrency = props.baseCurrency,
      isCoink = props.isCoink;
  var isCrypto = ProductType === 'CryptoCurrency';

  var renderPositionDetails = function renderPositionDetails(label, value) {
    return React.createElement(
      'div',
      { className: baseClasses('detail') },
      React.createElement(
        'div',
        { className: baseClasses('label') },
        context.t(label)
      ),
      React.createElement(
        'div',
        { className: baseClasses('value') },
        amountWithCurrency({
          amount: isCoink ? FormatDecimalCoinkWithOutSimbols(value, _props$positionDetail.Product) : value,
          product: _props$positionDetail
        })
      )
    );
  };
  var baseCurrencyTitle = void 0;

  if (baseCurrency) {
    var convertedValue = rawAmount * baseCurrency.value;
    var formattedValue = formatNumberToLocale(convertedValue, baseCurrency.DecimalPlaces);
    baseCurrencyTitle = '(' + baseCurrency.Product + ' ' + formattedValue + ')';
  }

  return React.createElement(
    'div',
    { className: baseClasses() },
    props.fetching ? React.createElement(Spinner, { isInline: true, customClass: baseClasses }) : React.createElement(
      React.Fragment,
      null,
      React.createElement(
        'div',
        { className: baseClasses('product-actions-row') },
        React.createElement(
          'div',
          { className: baseClasses('product-label') },
          React.createElement(
            'div',
            { className: baseClasses('product-icon') },
            React.createElement(ProductIconContainer, { iconFileName: iconFileName, size: 48 })
          ),
          React.createElement(
            'div',
            { className: baseClasses('product-symbol-name') },
            Product + ' ' + context.t(ProductFullName),
            ' ',
            React.createElement(
              'span',
              { className: baseClasses('product-conversion') },
              baseCurrencyTitle
            )
          )
        ),
        React.createElement(
          'div',
          { className: baseClasses('wallet-nav-items') },
          isCrypto ? React.createElement(
            React.Fragment,
            null,
            verificationLevelConfig.RequireWhitelistedAddress && React.createElement(APIconButton, {
              action: function action() {
                return openWhitelistSidepane(props.positionDetails);
              },
              iconName: 'tasks',
              label: context.t('Add Address'),
              customClass: cryptoNavItemsClasses()
            }),
            React.createElement(CryptoNavItemsContainer, {
              product: {
                iconFileName: iconFileName,
                ProductFullName: ProductFullName,
                ProductSymbol: Product,
                ProductId: ProductId
              }
            })
          ) : React.createElement(FiatNavItemsContainer, {
            product: {
              iconFileName: iconFileName,
              ProductFullName: ProductFullName,
              ProductSymbol: Product,
              ProductId: ProductId
            }
          })
        )
      ),
      React.createElement(
        'div',
        { className: baseClasses('position-row') },
        renderPositionDetails('Available Balance', AvailableBalance),
        renderPositionDetails('Hold', Hold),
        renderPositionDetails('Pending Deposits', PendingDeposits),
        renderPositionDetails('Total Balance', Amount)
      ),
      React.createElement(DepositReferenceIdModalContainer, null)
    )
  );
};

WalletDetailsComponent.propTypes = {
  positionDetails: PropTypes.object,
  fetching: PropTypes.bool.isRequired
};

WalletDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WalletDetailsComponent;