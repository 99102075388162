import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
// import AccountForm from '../../components/AccountComponents/AccountForm';
import PeopleList from './CorporateLevelPagePeopleList';
import InstituionList from './CorporateLevelPageInstitutionList';
import {
  getCorporateData,
  // enableFiatAccount,
  getCountries
} from './PeopleHooks';
import { Card, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../../components/Loading';

import '../AccountPage.css';

const ps = require('../../images/person-shaholder.png');
const cs = require('../../images/company-shareholder.png');

const accountPageClasses = getBEMClasses('account-page');

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

const AccountPage = (props, context) => {
  const { selectedAccountId, userInfo, accounts } = props;
  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }
  const [loading, setLoading] = useState(0);
  const [corporateData, setCorporateData] = useState({});
  const [shareholders, setShareholders] = useState({});
  const [countries, setCountries] = useState({});

  /* inicia modal */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /* fin de modal */

  const getListAccounts = async () => {
    setLoading(1);
    try {
      let result = await getCorporateData(userInfo.UserId, selectedAccountId);
      setShareholders(
        result.data.corporatedata[0].levelsCorporatePeopleByCorporateLevelsId
          .nodes
      );
      setCorporateData(result.data.corporatedata[0]);
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  const getListCountries = async () => {
    setLoading(1);
    try {
      let result = await getCountries(userInfo.UserId);
      setCountries(result.data.countries);
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  useEffect(
    () => {
      if (!empty(selectedAccountId)) {
        getListAccounts();
        getListCountries();
      }
    },
    [selectedAccountId]
  );

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses()}>
        <h1 className={accountPageClasses('title')}>
          {context.t('Add shareholders to your company info')}
        </h1>
        <h3 className={accountPageClasses('title')}>
          {context.t('Company information')}
        </h3>
        <div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="company_name">
                  {context.t('Company name')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="company_name"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.corporateName}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="company_ruc">
                  {context.t('Tax ID Number')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="company_ruc"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.corporateRuc}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="company_purpose">
                  {context.t('Company Purpose')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="company_purpose"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.corporatePurpose}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="economic_group">
                  {context.t('Economic Group')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="economic_group"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.economicGroup}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="company_address">
                  {context.t('Company Address')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="company_address"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.companyAddress}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="phone_number">
                  {context.t('Phone Number')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="phone_number"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.phoneNumber}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="contact_person">
                  {context.t('Contact Person')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="contact_person"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.contactPerson}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="company_country">
                  {context.t('Company Country')}
                </label>
                <div
                  type="email"
                  className="form-control"
                  id="company_country"
                  aria-describedby="emailHelp"
                  placeholder="Enter email">
                  {corporateData.companyCountry}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <Card.Body>
            <h2>
              {context.t('Shareholders or Director')}

              <Button
                variant="primary"
                role="button"
                className="btn btn-success rightbtn"
                onClick={handleShow}>
                +
              </Button>
              <Modal show={show} onHide={handleClose} className="daas-modal-ui">
                <Modal.Header closeButton>
                  <Modal.Title>{context.t('Add new Shareholder')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {context.t(
                    'Please choose the type of shareholder you wish to register.'
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Link
                    role="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                    to="/corporate-people">
                    <img src={ps} alt="Person Shareholder" />
                    {context.t('Add Natural person')}
                  </Link>
                  <Link
                    role="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                    to="/corporate-add-corporate-shareholder">
                    <img src={cs} alt="Company Shareholder" />
                    {context.t('Add Company')}
                  </Link>
                </Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    {context.t('Close')}
                  </Button> */}
              </Modal>
            </h2>
            <br />
            <br />
            <PeopleList
              shareholders={shareholders}
              updateListAccounts={getListAccounts}
            />
            <br />
            <InstituionList
              shareholders={shareholders}
              updateListAccounts={getListAccounts}
            />
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

AccountPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountPage);
