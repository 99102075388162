import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'apex-web/lib/components/common/Tab/Tab';
import CreateReportFormContainer from './CreateReportFormContainer';
import ScheduleReportComponent from '../ReportSidePaneComponents/ScheduleReportComponent';
import ConfirmSingleReportModalContainer from '../ConfirmSingleReport/ConfirmSingleReportModalContainer';
import ConfirmCyclicalReportModalContainer from '../ConfirmCyclicalReport/ConfirmCyclicalReportModalContainer';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './ReportSidePaneComponent.css';

const _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

const baseClasses = getBEMClasses('reports-sidepane');

let ReportSidePane = function (_React$Component) {
  _inherits(ReportSidePane, _React$Component);

  function ReportSidePane() {
    let _ref;

    let _temp, _this, _ret,args;

    _classCallCheck(this, ReportSidePane);

    for (let _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ReportSidePane.__proto__ || Object.getPrototypeOf(ReportSidePane)).call.apply(_ref, [this].concat(args))), _this), _this.getTabs = function () {
      return [{
        menuItem: _this.context.t('Single Report'),
        render: function render() {
          return React.createElement(CreateReportFormContainer, null);
        }
      }, {
        menuItem: _this.context.t('Cyclical Report'),
        render: function render() {
          return React.createElement(ScheduleReportComponent, null);
        }
      }];
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ReportSidePane, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.onSidePaneOpen({
        hideHeader: true,
        customClass: 'reports-sidepane',
        classModifiers: 'with-tabs'
      });
    }
  }, {
    key: 'render',
    value: function render() {
      const index = this.props.index;
      return React.createElement(
        'div',
        { className: baseClasses('body') },
        React.createElement(
          'div',
          { className: baseClasses('header') },
          this.context.t('Create a report')
        ),
        React.createElement(Tab, {
          panes: this.getTabs(),
          customClass: '' + baseClasses(),
          defaultActiveIndex: index
        }),
        React.createElement(ConfirmSingleReportModalContainer, null),
        React.createElement(ConfirmCyclicalReportModalContainer, null)
      );
    }
  }]);

  return ReportSidePane;
}(React.Component);

ReportSidePane.propTypes = {
  index: PropTypes.number
};
ReportSidePane.defaultProps = {
  index: 0
};
ReportSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};
export default ReportSidePane;


ReportSidePane.defaultProps = {
  onSidePaneOpen: function onSidePaneOpen() {}
};