import KYC_IMContainer from '../KYC_IM';
import KYC_IMLegacy from 'apex-web/lib/components/KYC_IMLegacy';
import KYC_Jumio from 'apex-web/lib/components/KYC_Jumio';
import KYC_Manual from 'apex-web/lib/components/KYC_Manual';

export var getKYCComponent = function getKYCComponent(kycConfig) {
  switch (kycConfig.type) {
    case 'IM':
    case 'IMLegacy':
      return KYC_IMLegacy;
    case 'IM3.2':
      return KYC_IMContainer;
    case 'Jumio':
      return KYC_Jumio;
    case 'Manual':
    case 'HTML':
      return KYC_Manual;
    default:
      break;
  }
};
