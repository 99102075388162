import { connect } from 'react-redux';
import OpenOrdersComponent from './OpenOrdersComponent';
import { openOrdersSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { cancelOrders } from 'apex-web/lib/redux/actions/orderHistoryActions';

const makeMapStateToProps = () => {
  const openOrdersSelector = openOrdersSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const openOrders = openOrdersSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const { activeRequests } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: openOrders,
      instruments,
      fetching,
      selectedInstrument
    };
  };
};

const mapDispatchToProps = {
  cancelOrders
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(OpenOrdersComponent);
