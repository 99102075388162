import { connect } from 'react-redux';
import ReceiveComponent from './ReceiveComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    disableDeposit = _kycVerificationLevel.disableDeposit;

  return { disableDeposit: disableDeposit };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    verificationOnClick: function verificationOnClick() {
      return dispatch(closeSidePane());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveComponent);
