import React from 'react';
import './spinnerstyles.css';

export default function Spinner({ big = false }) {
  return (
    <div className={`lds-ellipsis ${big ? 'spinnerorange' : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
