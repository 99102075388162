import HandlerBackend from "../../../helpers/HandlerBackend";

export const saveKYC = async (userId, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    const path = `/api/banexcard/first-save`;
    const configRequest = {
        userId,
        method: "PATCH",
        headers,
        path,
        data,
    };
    return await HandlerBackend.sendRequest(configRequest);
}
export const saveInformation = async (userId, data) => {
    const path = `/api/banexcard/second-save`;
    const configRequest = {
        userId,
        method: "PATCH",
        path,
        data,
    };
    return await HandlerBackend.sendRequest(configRequest);
}

export const resendInformation = async (userId, data) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    const path = `/api/banexcard/resend-info`;
    const configRequest = {
        userId,
        method: "PATCH",
        headers,
        path,
        data,
    };
    return await HandlerBackend.sendRequest(configRequest);
}