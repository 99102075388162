import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { kycConfigLevelShape } from 'apex-web/lib/propTypes/kycConfigShapePropTypes';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var kycClasses = getBEMClasses('kyc-settings');

var KYCBaseComponent = (function(_React$Component) {
  _inherits(KYCBaseComponent, _React$Component);

  function KYCBaseComponent() {
    _classCallCheck(this, KYCBaseComponent);

    return _possibleConstructorReturn(
      this,
      (
        KYCBaseComponent.__proto__ || Object.getPrototypeOf(KYCBaseComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(KYCBaseComponent, [
    {
      key: 'componentDidUpdate',
      value: function componentDidUpdate(prevProps) {
        var _props = this.props,
          userId = _props.userId,
          userName = _props.userName,
          getUserConfig = _props.getUserConfig;

        if (!prevProps.userId && !prevProps.userName && userId && userName) {
          getUserConfig(userId, userName);
        }
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props2 = this.props,
          kycConfig = _props2.kycConfig,
          verificationLevel = _props2.verificationLevel,
          levelIncreaseStatus = _props2.levelIncreaseStatus,
          LevelsComponent = _props2.LevelsComponent,
          highlightStyle = _props2.highlightStyle;

        var levels = get(kycConfig, 'levels', []);
        var level1 = levels[0];
        var level2 = levels[1];
        var level3 = levels[2];
        var level4 = levels[3];

        var KYCWorkflowLevelsComponentProps = {
          highlightStyle: highlightStyle,
          verificationLevel: verificationLevel,
          levelIncreaseStatus: levelIncreaseStatus,
          levels: levels,
          level1: level1,
          level2: level2,
          level3: level3,
          level4: level4
        };

        return React.createElement(
          'div',
          { className: classnames(kycClasses('', highlightStyle)) },
          React.createElement(
            'div',
            { className: kycClasses('header') },
            React.createElement(
              'div',
              { className: kycClasses('header-text') },
              this.context.t('Current Verification Level')
            ),
            React.createElement(
              'div',
              { className: kycClasses('description') },
              this.context.t(
                'For your security, some of our services require you to provide basic or additional levels of verification.'
              )
            ),
            React.createElement(
              'div',
              { className: kycClasses('description') },
              this.context.t(
                'To verify your account or to check what your current verification level is, see below.'
              )
            )
          ),
          React.createElement(
            'div',
            { className: kycClasses('levels') },
            React.createElement(
              LevelsComponent,
              KYCWorkflowLevelsComponentProps
            )
          )
        );
      }
    }
  ]);

  return KYCBaseComponent;
})(React.Component);

KYCBaseComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

KYCBaseComponent.propTypes = {
  userId: PropTypes.number,
  userName: PropTypes.string,
  values: PropTypes.object,
  verificationLevel: PropTypes.number,
  levelIncreaseStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  openKYCSidePane: PropTypes.func,
  kycConfig: PropTypes.shape({
    type: PropTypes.string,
    levels: PropTypes.arrayOf(PropTypes.shape(kycConfigLevelShape))
  })
};

export default KYCBaseComponent;
