import React from "react";
import VerificationInput from "react-verification-input";
import "./banexcard-pin-creation.css";
import CardGradient from "../../components/CardGradient";
import FormButtonV2 from "../../../../components/common/BnxFormsComponents/FormButton";
import { useHistory } from "react-router-dom";
import BanexcardController from "../../controllers/BanexcardController";
import { connect } from "react-redux";
import { Spinner } from "../../../../components/Spinner/Spinner";
import isEmpty from "is-empty";
import { Loader } from "../../components";
import { deliveryStatuses } from "../../constants/deliveryStatuses";
import { Redirect } from "react-router-dom";

function BanexcardPinCreation(props, context) {
  const { userInfo, banexcard, hasBanexCard, loadingBanexCard, levelBasic } = props
  const [loading, setLoading] = React.useState(false); 
  const [pin, setPin] = React.useState("");
  const [pinConfirmation, setPinConfirmation] = React.useState("");
  const [result, setResult] = React.useState({})
  const ht = useHistory();

  const onChange = (ev) => {
    if(ev.target.value.length <= 4){
      setPin(ev.target.value)
    }
  };
  const onChange2 = (ev) => {
    if(ev.target.value.length <= 4){
      setPinConfirmation(ev.target.value)
    }
  };

  const handleActivation =  async () => {
    setLoading(true)
    const response = await BanexcardController.setPinConfiguration({ userInfo, pin })
    setLoading(false)
    if(response){
      return setResult({
        type: "success",
        title: context.t("PIN created successfully"),
        message: context.t("You have created the PIN of your Banexcard VISA Physical card. Remember that this PIN will be requested whenever you use your physical card."),
        actions:[
          {
            label: context.t("Accept"),
            action: () => ht.push("/cards"),
          }
        ]
      })
    }{
      setResult({
        type: "error",
        title: "Error",
        message: context.t("There was an error creating the PIN, please try again."),
        actions:[
          {
            label: context.t,
            action: () => setResult({}),
          }
        ]
      })
    }
  };

  const isDisabled = pin.length < 4 || loading || pin !== pinConfirmation;

  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard || (!isEmpty(levelBasic.banexcardData) && levelBasic.banexcardData.isPinSet) || banexcard.banexcardRequestData.status !== deliveryStatuses.DELIVERED) return <Redirect to="/cards" />
  
  if(!isEmpty(result)){
    return <div className="activation" style={{maxWidth: "500px"}}>
      <CardGradient {...result} />
    </div>
  }

  return (
    <div className="banexcard-pin-creation">
      <div>
      <h2>{context.t("Create the PIN of your Banexcard Physical")}</h2>
      <p>
        {context.t("Remember this PIN as it will be requested when using your Banexcard Physical")}
      </p>
      </div>
      <div className="inputs-container">
        <h4>{context.t("Enter your new PIN")}</h4>
        <p>{context.t("Your new PIN must contain 4 digits")}</p>
        <VerificationInput
          removeDefaultStyles
          autoFocus={true}
          length={4}
          validChars="/^\d+$/"
          container={{
            className: "pt-4 pb-4",
          }}
          characters={{
            className: "characters",
          }}
          character={{
            className: "character",
            classNameInactive: "character--inactive",
            classNameSelected: "character--selected",
          }}
          inputField={{
            onChange: onChange,
          }}
        />
        <h4>{context.t("Repeat your new PIN")}</h4>
        <VerificationInput
          removeDefaultStyles
          autoFocus={true}
          length={4}
          validChars="/^\d+$/"
          container={{
            className: "pt-4 pb-4",
          }}
          characters={{
            className: "characters",
          }}
          character={{
            className: "character",
            classNameInactive: "character--inactive",
            classNameSelected: "character--selected",
          }}
          inputField={{
            onChange: onChange2,
          }}
        />
      </div>
      <FormButtonV2 disabled={isDisabled} className="" onClick={isDisabled ? null : handleActivation}>
      {
          loading ? <Spinner /> : <span>{context.t("Create PIN")}</span>
        }
      </FormButtonV2>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    levelBasic: state.completedUserInfo.level_basic,
    userInfo,
    banexcard: state.banexCard,
    loadingBanexCard
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(BanexcardPinCreation);
