import React from 'react';
import PropTypes from 'prop-types';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import MenuItemComponent from 'apex-web/lib/components/MenuItem/MenuItemComponent';
import { FormatDecimalCoinkWithSimbols, formatCopCurrency } from '../../pages/utils';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClass = 'balances-menu';
var baseClasses = getBEMClasses(baseClass);

var BalancesMenuItemComponent = (function(_React$Component) {
  _inherits(BalancesMenuItemComponent, _React$Component);

  function BalancesMenuItemComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BalancesMenuItemComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          BalancesMenuItemComponent.__proto__ ||
          Object.getPrototypeOf(BalancesMenuItemComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.handleClick = function() {
        _this.props.onItemClick(_this.props.position.ProductId);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(BalancesMenuItemComponent, [
    {
      key: 'render',
      value: function render() {
        return React.createElement(
          MenuItemComponent,
          {
            onItemClick: this.handleClick,
            selected: this.props.selected,
            customClass: baseClass
          },
          React.createElement(
            'div',
            { className: '' + baseClasses('item-icon') },
            React.createElement(ProductIconContainer, {
              iconFileName: this.props.position.iconFileName,
              size: 28
            })
          ),
          React.createElement(
            'div',
            { className: '' + baseClasses('item-details') },
            React.createElement(
              'div',
              { className: '' + baseClasses('item-symbol-name') },
              this.props.position.Product,
              ' ',
              this.context.t(this.props.position.ProductFullName)
            ),
            React.createElement(
              'div',
              { className: '' + baseClasses('item-balance-value') },
              this.props.isCoink && this.props.position.Amount !== "-" ? FormatDecimalCoinkWithSimbols(this.props.position.Amount, this.props.position.ProductSymbol) : this.props.position.Amount,
            )
          )
        );
      }
    }
  ]);

  return BalancesMenuItemComponent;
})(React.Component);

BalancesMenuItemComponent.propTypes = {
  position: PropTypes.object.isRequired
};

BalancesMenuItemComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return{
    isCoink: !isEmpty(state.user) && !isEmpty(state.user.verificationLevelConfigs) ? [100, 101].includes(state.user.verificationLevelConfigs.Level) : false
  }
}
export default connect(mapStateToProps)(BalancesMenuItemComponent);
