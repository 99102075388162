import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';
import ReactModal from 'react-modal';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import history from 'apex-web/lib/helpers/history';
import APIcon from 'apex-web/lib/components/common/APIcon';

import ChildrenComponents from './SidePaneChildren';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './SidePane.css';
var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var SidePane = (function(_Component) {
  _inherits(SidePane, _Component);

  function SidePane(props) {
    _classCallCheck(this, SidePane);

    var _this = _possibleConstructorReturn(
      this,
      (SidePane.__proto__ || Object.getPrototypeOf(SidePane)).call(this, props)
    );

    _this.getPropsFromChild = function(_ref) {
      var title = _ref.title,
        customClass = _ref.customClass,
        hideCloseLink = _ref.hideCloseLink,
        classModifiers = _ref.classModifiers,
        hideHeader = _ref.hideHeader;

      _this.setState({
        title: title,
        customClass: customClass,
        hideCloseLink: hideCloseLink,
        classModifiers: classModifiers,
        hideHeader: hideHeader
      });
    };

    _this.returnScrollPosition = function() {
      document.body.style.position = 'static';
      window.scroll(0, _this.state.scrollPosition);
    };

    _this.renderCloseIcon = function() {
      var classes = _this.getClasses();

      return React.createElement(
        'div',
        { onClick: _this.props.close, className: classes('close-button') },
        React.createElement(APIcon, { name: 'close', classModifiers: 'big' })
      );
    };

    _this.closePane = function() {
      return _this.props.close(_this.props.name);
    };

    _this.unlisten = null;

    _this.state = {
      title: '',
      customClass: '',
      hideCloseLink: false,
      classModifiers: '',
      hideHeader: false,
      scrollPosition: 0
    };
    return _this;
  }
  _createClass(
    SidePane,
    [
      {
        key: 'componentDidMount',
        value: function componentDidMount() {
          var _this2 = this;

          ReactModal.setAppElement(this.modalRef);
          this.unlisten = history.listen(function(location, action) {
            if (action === 'POP' && _this2.props.isOpen) {
              _this2.props.close();
            }
          });
        }
      },
      {
        key: 'componentDidUpdate',
        value: function componentDidUpdate() {
          var bodyPosition = document.body.style.position;
          if (!this.props.isOpen && bodyPosition === 'fixed') {
            this.returnScrollPosition();
          }
        }
      },
      {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
          this.unlisten();
          this.unlisten = null;
        }
      },
      {
        key: 'getClasses',
        value: function getClasses() {
          return getBEMClasses(['ap-sidepane', this.state.customClass]);
        }
      },
      {
        key: 'render',
        value: function render() {
          var _this3 = this;

          var _props = this.props,
            isOpen = _props.isOpen,
            name = _props.name,
            data = _props.data;
          /*if(name == "KYC_IMForm") {
              this.state.title = ""
            }*/
          var _state = this.state,
            hideCloseLink = _state.hideCloseLink,
            classModifiers = _state.classModifiers;

          if (name === 'KYC_IMForm') {
            _state.title = '';
          }

          var otherProps = _objectWithoutProperties(_state, [
            'hideCloseLink',
            'classModifiers'
          ]);

          var classes = this.getClasses();
          var Child = ChildrenComponents[name];
          var isMobile = navigator.userAgent.match(
            /Android|BlackBerry|iPhone|iPad|iPod/i
          );

          if (name && !Child) {
            console.error(
              this.context.t('SidePane Component named ' + name + ' not found')
            );
            return null;
          }
          return React.createElement(
            'div',
            {
              className: classes(null, classModifiers),
              ref: function ref(_ref2) {
                return (_this3.modalRef = _ref2);
              }
            },
            React.createElement(
              SlidingPane,
              Object.assign(
                {
                  className: classes('container', classModifiers),
                  overlayClassName: classes('overlay', classModifiers),
                  onAfterOpen: function onAfterOpen() {
                    if (isMobile) {
                      // set position after opening sidepane
                      setTimeout(function() {
                        _this3.setState({ scrollPosition: window.pageYOffset });
                        document.body.style.position = 'fixed';
                      }, 500);
                    }
                  },
                  onRequestClose: this.closePane,
                  isOpen: isOpen
                },
                otherProps
              ),
              !hideCloseLink && this.renderCloseIcon(),
              Child &&
                React.createElement(
                  Child,
                  Object.assign(
                    {
                      name: name,
                      closeSidePane: this.closePane
                    },
                    data,
                    {
                      onSidePaneOpen: this.getPropsFromChild
                    }
                  )
                )
            )
          );
        }
      }
    ],
    [
      {
        key: 'getDerivedStateFromProps',
        value: function getDerivedStateFromProps(props, state) {
          if (props.isOpen) {
            var header = document.getElementsByClassName(
              'slide-pane__header'
            )[0];
            if (header && state.hideHeader) {
              header.classList.add('slide-pane__hide-header');
            }
          }
          return state;
        }
      }
    ]
  );

  return SidePane;
})(Component);

SidePane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  hideCloseLink: PropTypes.bool
};
SidePane.defaultProps = {
  customClass: 'custom-sidepane'
};
SidePane.contextTypes = {
  t: PropTypes.func.isRequired
};
export default SidePane;
