import VerifyEmailContainer from 'apex-web/lib/components/VerifyEmail/VerifyEmailContainer';
import ResetPasswordFormContainer from '../ResetPasswordForm/ResetPasswordFormContainer';
import ConfirmWithdrawContainer from 'apex-web/lib/components/ConfirmWithdraw/ConfirmWithdrawContainer';
import DepositConfirmationModalContainer from 'apex-web/lib/components/DepositConfirmation/DepositConfirmationModalContainer';
import StandaloneLoginContainer from 'apex-web/lib/components/Login/StandaloneLoginFormContainer';
import VerifyAddressContainer from 'apex-web/lib/components/VerifyAddress/VerifyAddressContainer';
import VerifyDeviceContainer from 'apex-web/lib/components/VerifyDevice/VerifyDeviceContainer';

export const STAND_ALONE_COMPONENTS = {
  VerifyEmailContainer: VerifyEmailContainer,
  ResetPasswordNoAuthFormContainer: ResetPasswordFormContainer,
  ConfirmWithdrawContainer: ConfirmWithdrawContainer,
  DepositConfirmationContainer: DepositConfirmationModalContainer,
  StandaloneLoginContainer: StandaloneLoginContainer,
  VerifyAddressContainer: VerifyAddressContainer,
  VerifyDeviceContainer: VerifyDeviceContainer
};