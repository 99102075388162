import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { getAllFiatAccountsV2 } from '../../components/AccountComponents/AccountHooks';
import { Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import AccountRow from './AccountRow';
import { transferTypes } from './accountDefinitions';
import {
  FormButtonV2,
  FormSelector
} from '../../components/common/BnxFormsComponents';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import './Account.css';
import isEmpty from 'is-empty';

const accountV2 = getBEMClasses('v2-accounts');

const AccountPage = (props, context) => {
  let { userInfo, selectedAccountId, loading, setLoading } = props;
  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>Loading..</div>
      </React.Fragment>
    );
  }

  const [paginator, setPaginator] = useState({
    page: 1,
    limit: 20
  });
  const [filter, setFilter] = useState({});
  const [fiatAccounts, setFiatAccounts] = useState([]);
    const [filteredFiatAccounts,setFilteredFiatAccounts] = useState([])
  const getListAccounts = async (filter = {}) => {
    setLoading(1);
    try {
      let result = await getAllFiatAccountsV2(
        userInfo.UserId,
        selectedAccountId,
        filter
      );
      let fiatAccounts = []
      if (
        !empty(result) &&
        result.hasOwnProperty('data') &&
        !empty(result.data.data)
      ) {
        fiatAccounts = result.data.data
      }
      setFiatAccounts(fiatAccounts);
      setFilteredFiatAccounts(fiatAccounts)
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  };

  useEffect(() => {
    if (!empty(userInfo)) {
      getListAccounts();
    }
  }, []);
  const onChange = e => {
    e.persist();
    setFilter(filter => ({
      ...filter,
      [e.target.name]: e.target.value
    }));
  };

  const changePage = page => {
    setPaginator({
      ...paginator,
      page: page
    });
  };

  const deleteEmptyFilters = () => {
    const filters = {}
    Object.keys(filter).forEach(filterItem => {
      const filterValue = filter[filterItem]
      if(!isEmpty(filterValue)){
        filters[filterItem] = filterValue
      }
    })
    return filters
  }

  const cleanFilters = () => setFilter({})

  useEffect(() => {
    const filters = deleteEmptyFilters()
    if(isEmpty(filters)){
      setFilteredFiatAccounts(fiatAccounts)
    }else{
      const filterKeys = Object.keys(filters)
      const newFilteredAccounts = fiatAccounts.filter(account => {
        const res = filterKeys.filter(filterKey => filters[filterKey] === account[filterKey])
        return res.length === filterKeys.length
      })
      setFilteredFiatAccounts(newFilteredAccounts)
    }
  },[filter])

  return (
    <React.Fragment>
      <Form
        noValidate
        validated={false}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <Row className={accountV2('search-filter')}>
          <Col xl="3">
            <FormSelector
              name="transferType"
              label="Transfer Type"
              defaultOption="Select"
              options={transferTypes.map(t => ({
                value: t.type,
                label: context.t(t.name)
              }))}
              onChange={onChange}
              value={filter.transferType || ""}
              errors={{}}
            />
          </Col>
          <Col xl="2">
            <FormSelector
              name="currencyCode"
              label="Currency"
              defaultOption="Select"
              options={[
                { value: 'USD', label: 'USD' },
                { value: 'PEN', label: 'PEN' }
              ]}
              onChange={onChange}
              value={filter.currencyCode || ""}
              errors={{}}
            />
          </Col>
          <Col xl="2" className="vertical-bottom">
            <FormButtonV2
              variant={'light'}
              className="submit-button"
              onClick={cleanFilters}>
              {context.t('Clear selection')}
            </FormButtonV2>
          </Col>
          <Col xl="2" className="vertical-bottom" />
          <Col xl="3" className="vertical-bottom text-right">
            <Link to="/settings/accounts/add">
              <FormButtonV2
                variant={'success'}
                disabled={loading === 1 ? 'disabled' : ''}
                className="submit-button">
                {context.t('Add bank account')}
              </FormButtonV2>
            </Link>
          </Col>
        </Row>
      </Form>
      <hr />
      <Row className={accountV2()}>
        {!empty(filteredFiatAccounts) ? (
          filteredFiatAccounts.map((a, index) => {
            return (
              <AccountRow
                key={'fa-' + index}
                account={a}
                selectedAccountId={selectedAccountId}
                UserId={userInfo.UserId}
                setLoading={setLoading}
                getListAccounts={getListAccounts}
              />
            );
          })
        ) : (
          <React.Fragment>
            <Col xl="12" className="text-center mt-5 mb-3">
              <h2 className="empty-message">
                {context.t("You don't have a bank account added yet")}
              </h2>
            </Col>
          </React.Fragment>
        )}
      </Row>
      {!empty(paginator) && fiatAccounts.length > 0 ? (
        <div className="paginator-bottom-bnx">
          <Pagination
            activePage={Number(paginator.page)}
            itemsCountPerPage={Number(paginator.limit)}
            totalItemsCount={fiatAccounts.length}
            pageRangeDisplayed={5}
            onChange={changePage}
            innerClass="pagination v2-list-pagination"
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

AccountPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};

export default connect(mapStateToProps)(AccountPage);
