export const jsonRed = valor => {
  let jsonNetwork = {
    deposit: {
      BTC: [
        {
          value: '1',
          name: 'BTC',
          sub: 'Bitcoin',
          rightname: 'Average deposit time',
          rightsub: '3 Network Confirmations ≈ 30 minutes'
        }
      ],
      USDC: [
        {
          value: '2',
          name: 'USDC (USD Coin)',
          sub: '(ERC-20 Ethereum Network)',
          rightname: 'Average deposit time',
          rightsub: '20 Network Confirmations ≈ 5 minutes'
        }
      ],
      ETH: [
        {
          value: '3',
          name: 'ETH (Ethereum)',
          sub: '(ERC-20 Ethereum Network)',
          rightname: 'Average deposit time',
          rightsub: '128 Network Confirmations ≈ 10 minutes'
        }
      ]
    },
    withdraw: {
      BTC: [
        {
          value: '1',
          name: 'BTC (Bitcoin Network)',
          sub: 'P2pKH,P2SH o Bech32',
          rightname: 'Average withdraw time',
          rightsub: '≈ 20 minutes'
        }
      ],
      USDC: [
        {
          value: '2',
          name: 'USDC (USD Coin)',
          sub: '(ERC-20 Ethereum Network)',
          rightname: 'Average withdraw time',
          rightsub: '≈ 20 minutes'
        }
      ],
      ETH: [
        {
          value: '3',
          name: 'ETH (Ethereum)',
          sub: '(ERC-20 Ethereum Network)',
          rightname: 'Average withdraw time'
        }
      ]
    }
  };
  return jsonNetwork;
};
