import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate, formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import APIconButton from 'apex-web/lib/components/common/APIconButton/APIconButton';
import DownloadAPButton from 'apex-web/lib/components/common/DownloadAPButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import subDays from 'date-fns/sub_days';
import APTable from 'apex-web/lib/components/common/APTable';
import apex from 'apex-web/lib/apex';
import isEmpty from 'is-empty';

import './DownloadReportComponent.css';

const baseClass = getBEMClasses('download-report');

let DownloadReportComponent = function DownloadReportComponent(props, context) {
  let reportWriterResults = props.reportWriterResults,
  isLoading = props.isLoading,
  getUserReportWriterResults = props.getUserReportWriterResults,
  downloadDocument = props.downloadDocument,
  userId = props.userId,
  GetUserReportWriterResultRecords = props.GetUserReportWriterResultRecords,
  createStateSnackbar = props.createStateSnackbar,
  resetStateSnackbar = props.resetStateSnackbar;

  const [reportId, setReportId] = useState('');

  const activities = {
    TradeActivity:"Trade activity",
    onDemand:"on demand",
    Transaction: "Transaction",
    Treasury:"Treasury",
    Hourly:"hourly",
    Daily:"daily",
    Monthly:"monthly",
    Annual:"annual",
  }

  const formatType = function formatType(report) {
    const summary = report.reportDescriptiveHeader.split('|');
    const startTime = formatDate(subDays(summary[2], 1));
    const endTime = formatDate(subDays(summary[3], 1));
    const resume = `${context.t(activities[summary[0]])} ${context.t(activities[summary[1]])} ${context.t('from {startTime} to {endTime}',{startTime,endTime})}`
    return resume
  };

  const isMobile = navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod/i
  );

  const columns = [
    {
      header: context.t('Report Summary'),
      classModifier: 'type',
      cell: function cell(row) {
        return formatType(row);
      }
    },
    {
      header: context.t('Created'),
      classModifier: 'created',
      cell: function cell(row) {
        return formatDateTime(row.reportExecutionStartTime);
      }
    },
    !isMobile && {
      header: context.t('Actions'),
      classModifier: 'actions',
      cell: function cell(row) {
        return React.createElement(DownloadAPButton, {
          onClick: function onClick() {
            return downloadDocument(row.descriptorId, formatType(row), row.reportDescriptiveHeader.split('|')[0], context);
          },
          customClass: baseClass('action-btn'),
          styleName: 'additive',
          text: context.t('Download Report')
        });
      }
    }
  ];

  const reportingEvents = () => {
    apex.connection.ws
      .filter(x => x.n === 'GenerateTransactionActivityReport' || x.n === 'GenerateTradeActivityReport')
      .subscribe(async response =>  {
        const res = JSON.parse(response.o);
        createStateSnackbar({
          title: 'Successful request!',
          text: 'Your report will be ready in a few minutes.',
          onClick: () => resetStateSnackbar()
        })
        setReportId(res.RequestId)
      });
  };

  useEffect(() => {
      reportingEvents();
  }, [apex]);

  useEffect(() => {
    if(!isEmpty(reportId)){
      const interval = setInterval(async () => {
          const result = await GetUserReportWriterResultRecords(userId)
          const searchReport = result.find(r => r.urtTicketId === reportId)
          if(!isEmpty(searchReport)){
            setReportId('')
            getUserReportWriterResults()
            clearInterval(interval)
          }
      }, 3000);
      return () => {
        clearInterval(interval)
      }
    }
  }, [reportId])
  

  return React.createElement(
    'div',
    { className: baseClass('container') },
    React.createElement(APIconButton, {
      customClass: baseClass('refresh-reports'),
      iconName: 'refresh',
      action: getUserReportWriterResults,
      showLabel: false
    }),
    isLoading
      ? React.createElement('p', null, 'Cargando...')
      : React.createElement(APTable, {
        columns: columns,
        baseClass: baseClass,
        rows: reportWriterResults,
        empty: context.t('No Report Is Available'),
        fetching: isLoading,
        headerOutside: true
      })
  );
};

DownloadReportComponent.propTypes = {
  reports: PropTypes.array.isRequired,
  reportWriterResults: PropTypes.array.isRequired
};

DownloadReportComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DownloadReportComponent;
