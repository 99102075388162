import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import CreateReportFormComponent from './CreateReportFormComponent';
import { openModal, MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { getReportsPayload } from 'apex-web/lib/helpers/reportsPayloadHelper';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const FORM = 'createReport';
const selector = formValueSelector(FORM);

const mapStateToProps = function mapStateToProps(state) {
  const _state$user = state.user,
      accounts = _state$user.accounts,
      selectedAccountId = _state$user.selectedAccountId,
      userInfo = _state$user.userInfo


  const startDate = selector(state, 'startDate');
  const endDate = selector(state, 'endDate');

  const buttonDisabled = !startDate || startDate === '__/__/____' || !endDate || endDate === '__/__/____';

  return {
    userInfo:userInfo,
    accounts: accounts,
    startDate: startDate,
    buttonDisabled: buttonDisabled,
    selectedAccountId: selectedAccountId,
    initialValues: _defineProperty({
      type: 'TradeActivity'
    }, selectedAccountId, true)
  };
};

const CreateReportForm = reduxForm({
  form: FORM,
  onSubmit: function onSubmit(values, dispatch, ownProps) {
    var payload = getReportsPayload(values, ownProps);
    return dispatch(openModal(MODAL_TYPES.CONFIRM_SINGLE_REPORT, { payload: payload }));
  }
})(CreateReportFormComponent);

export default connect(mapStateToProps)(CreateReportForm);