import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CardGradient, Range, Tooltip } from "../../../components";
import walletIcon from "../../../../../images/icons-v2/Wallet.svg";
import cardIcon from "../../../../../images/icons-v2/Cards.svg";
import arrow from "../../../../../images/icons-v2/ArrowRight2.svg";
import usdIcon from "../../../../../images/product-icons/usd-green-48px.svg";
import usdcIcon from "../../../../../images/product-icons/usdc-48px.svg";
import InputElement from "../../../../bnxv2/Components/InputElement/InputElement";
import banexcardImage from "../../../../../images/banexcard-image.png";
import { useHistory } from "react-router-dom";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import { setBanexcardBalanceData } from "../../../../../redux/actions/set_banex_card";
import BanexcardController from "../../../controllers/BanexcardController";
import { currencyFormatter } from "../../../../utils";

function RechargeForm(props, context) {
  const {
    amount,
    setAmount,
    commission,
    setCommission,
    total,
    setTotal,
    handleSteps,
    banexCard,
    setBanexcardBalanceData,
    userInfo,
    positions,
    level1
  } = props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [timeout, setTimeoutValue] = useState(null);
  // const usdcBalance = !isEmpty(positions) ? positions[userInfo.AccountId]["4"].Amount : 0
  const usdcBalance = 10000

  const ht = useHistory();

  const handleRange = (name, value) => {
    setAmount(value);
  }

  const handleErrors = (value) => {
    let errors = {};
    if (value < 20) {
      errors.amount = "The minimum recharge amount is $20.00"
    }
    setErrors(errors);
    return errors;
  };
  
  const handleAmountChange = (e) => { 
    const { value } = e.target;
    setAmount(value);
  };

  const amountInputProps = {
    values: {
      title: "",
      value: amount,
      name: "amount",
      type: "number",
      placeholder: "0.00",
    },
    actions: {
      onChange: handleAmountChange,
    },
    error: errors.amount,
  };

  const commissionRowClassName = (value) => {
    if (value >= 500) {
      return amount >= 500 ? "active" : "";
    }
    return amount < 500 ? "active" : "";
  };

  const canExecuteRecharge = isEmpty(errors) && !loading && amount > 0;

  const actions = [
    {
      label: context.t("Recharge Banexcard!"),
      action: canExecuteRecharge
        ? () => {
            handleSteps.next();
          }
        : null,
      otherAttributes: {
        disabled: !canExecuteRecharge,
      },
    },
  ];

  const handleGetBalanceData = async () => {
    const response = await BanexcardController.getUserBalance({ userInfo });
    setBanexcardBalanceData(response);
  };
  useEffect(() => {
    if(isEmpty(userInfo)) return;
    if (isEmpty(banexCard.balanceData)) {
      handleGetBalanceData();
    }
  }, [userInfo]);

  useEffect(() => {
    if(timeout) clearTimeout(timeout);
    if(isEmpty(amount)) return;
    if(amount === 0){
      setTotal(0);
      setCommission(0);
      setErrors({});
      return;
    }
    const errors = handleErrors(amount);
    if (!isEmpty(errors)) {
      setTotal(0);
      setCommission(0);
      return;
    }
    setTimeoutValue(setTimeout(async () => {
      setLoading(true);
      const response = await BanexcardController.calculateFee({
        userInfo,
        amount,
        currency: "USD",
      })
      setTotal(response.total);
      setCommission(response.fee);
      setLoading(false);
      clearTimeout(timeout);
    }, 1000));
  },[amount])

  const usdPenRate =!isEmpty(level1["5"]) ? level1["5"].BestBid : 0
  return (
    <div className="body">
      <div className="left">
        <div className="coins">
          <img width={30} src={usdcIcon} alt="usdc icon" />
          <img
            width={20}
            src={arrow}
            alt="arrow icon"
            style={{ transform: "rotate(90deg)", marginRight: "5px" }}
          />
          <img width={30} src={usdIcon} alt="usd icon" />
        </div>
        <img
          src={banexcardImage}
          alt="banexcard image"
          className="card-image"
          width={150}
        />
      </div>
      <CardGradient
        customClassName="banexcard-recharge-w"
        actions={actions}
        backAction={() => ht.push("/cards")}
      >
        <div className="content">
          <h2>
            {context.t("Recharge my Banexcard")}
            <Tooltip
              id={"banexcard-recharge-title"}
              text={context.t("To use your Banexcard VISA card, it is essential that you recharge it from your USD Coin (USDC) wallet at Banexcoin.")}
            />
          </h2>
          <div className="balances">
            <p>{context.t("My balances")}</p>
            <div className="balance-row">
              <span>
                <img
                  width={18}
                  src={walletIcon}
                  className="wallet-icon"
                  alt=""
                />{" "}
                {context.t("My wallets")} USDC
              </span>
              <span className="value">
                {!isEmpty(positions) ? currencyFormatter({value: usdcBalance, currency: "USD"}) : ""}
                <img width={30} src={usdcIcon} alt="" />
              </span>
            </div>
            <div className="balance-row">
              <span>
                <img width={18} src={cardIcon} className="card-icon" alt="" />{" "}
                 {context.t("My Banexcard")} (USD)
              </span>
              <span className="value">
                {!isEmpty(banexCard.balanceData)
                  ? banexCard.balanceData.availableBalance
                  : ""}
                <img width={30} src={usdIcon} alt="" />
              </span>
            </div>
          </div>
          <div className="inputs">
            <div className="item">
              <p>{context.t("Enter the amount to recharge")} ({context.t("Minimum")} {currencyFormatter({
                value: 20,
                currency: "USD",
              })})</p>
              <InputElement {...amountInputProps} />
            </div>
            <div className="item commission">
              <p>{context.t("Commission")}</p>
              <p className="value">{commission}</p>
            </div>
            <div className="item">
              <p>
                {context.t("Total USD to receive in my Banexcard")}{" "}
                <Tooltip
                  id={"banexcard-recharge-title"}
                  text={context.t("This amount is already discounted from the recharge commission (if applicable), it will be credited to the balance of your Banexcard VISA card and will be available for your purchases.")}
                />
              </p>
              <div className="total" title={total} style={{maxWidth: "171px", overflow: "hidden"}}>
                {loading ? <span className="calculating">{context.t("Calculating...")}</span> : <p>{total}</p>}
              </div>
            </div>
          </div>
          <Range onChange={handleRange} max={usdcBalance} inputValue={amount} decimalPlaces={2} />
          <div className="commission-details">
            <div className="details">
              <div className="headers">
                <span>{context.t("Recharge amount")}</span>
                <span>{context.t("Commission")}</span>
              </div>
              <div className={`commission-row ${commissionRowClassName(0)}`}>
                <span>$20 - $499.99 </span>
                <span>3 USDC</span>
              </div>
              <div className={`commission-row ${commissionRowClassName(500)}`}>
                <span>$500 {context.t("or more")} </span>
                <span>0 ({context.t("No commission")})</span>
              </div>
            </div>
            <div className="change-type-value">
              <p>{context.t("Exchange rate")}: 1USDC = {currencyFormatter({
                value: 1,
                currency: "USD",
              })}</p>
            </div>
          </div>
          <div className="balance-remaining">
            <p>{context.t("Total balance in your Banexcard after this recharge")}:</p>
            <p className="remaining-aprox">
              <strong>{!isEmpty(banexCard.balanceData) ? currencyFormatter({value: banexCard.balanceData.availableBalance + total, currency: "USD"}): 0.00 }</strong> ≈ {isEmpty(banexCard.balanceData) ? 0 : currencyFormatter({
                value:(banexCard.balanceData.availableBalance + total) * usdPenRate,
                currency: "PEN",
              })}{" "}
              <Tooltip
                id={"total-balance-remaining"}
                text={context.t("Exchange rate {usdPenRate} PEN/USD, it is a reference value, take into account that this value may vary at the time of making your operations in Soles.",{
                  usdPenRate
                })}
              />
            </p>
          </div>
        </div>
      </CardGradient>
    </div>
  );
}

RechargeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    banexCard: state.banexCard || {},
    userInfo: state.user.userInfo || {},
    positions: state.position.positions,
    level1: state.level1
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBanexcardBalanceData: (data) => dispatch(setBanexcardBalanceData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RechargeForm);
