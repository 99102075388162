import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { DatePickerInput } from 'rc-datepicker';
import moment from 'moment';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import {
  submitFormData,
  submitFormDataChildren
  // getLevel
} from './CorporateLevelPagePeopleDetailHooks';
import { getSecureGetParams } from '../../helpers/lib';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import Countries from '../../components/Countries';
import {
  // getCorporateData,
  // enableFiatAccount,
  getCountries
} from './PeopleHooks';
//CSS
import './IntermediateLevelPage.css';

const RobotSvg = require('../../images/robot.svg');
const intermediateLevelPageClasses = getBEMClasses('intermediatelevel-page');

const CorporatePeopleForm = (props, context) => {
  const { userInfo } = props;

  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [level, setLevel] = useState({});
    const [countries, setCountries] = useState({});
    const [children, setChildren] = useState(false);

    const getListCountries = async () => {
      setLoading(1);
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    useEffect(() => {
      async function getLevelByUserId() {
        setLoading(1);
        try {
          if (
            !empty(props) &&
            !empty(props.match) &&
            !empty(props.match.params) &&
            !empty(props.match.params.params)
          ) {
            const params = getSecureGetParams(props.match.params.params);
            if (!empty(params) && !empty(params.id)) {
              setChildren(true);
              setInputs(inputs => ({
                ...inputs,
                parent_corporate_levels_id: params.id
              }));
              setLoading(0);
            } else {
            }
          }
        } catch (error) {}
      }
      getLevelByUserId();
    }, []);

    useEffect(() => {
      if (!empty(userInfo)) {
        getListCountries();
      }
    }, []);

    useEffect(() => {
      async function loadLevel() {
        setLoading(1);
        try {
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadLevel();
      }
    }, []);

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result;
        console.log('save_back', inputs);
        if (children) {
          result = await submitFormDataChildren(userInfo.UserId, inputs);
        } else {
          result = await submitFormData(userInfo.UserId, inputs);
        }

        toast.success(
          context.t(
            'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n an email with the response to your request.'
          ),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const handleCountryChange = async event => {
      event.persist();
      setInputs(inputs => ({
        ...inputs,
        country: event.target.value
      }));
    };

    const customValidations = inputs => {
      let errors = {};
      if (empty(inputs.birthday)) {
        errors.birthday = true;
      } else {
        var m = moment(inputs.birthday, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.birthday = true;
        }
      }

      return errors;
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : ''
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    return userInfo.Use2FA ? (
      <React.Fragment>
        <Loading loading={loading} />
        <div className={intermediateLevelPageClasses()}>
          <h1>{context.t('Add New Shareholder or Director')}</h1>
          <p>
            {context.t(
              'To raise the user level you must fill out the form below.'
            )}
          </p>

          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmit}>
            <Row>
              <Col xl="4">
                <Form.Group controlId="email">
                  <Form.Label>{context.t('Email')}</Form.Label>
                  <p>{inputs.email}</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="2">
                <Form.Group controlId="identification_number">
                  <Form.Label>
                    {context.t('Identification number')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="identification_number"
                    type="number"
                    defaultValue={inputs.identification_number || ''}
                    onChange={onInputChange}
                    placeholder={context.t('Identification number')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the identification number')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="2">
                <Form.Group controlId="document_type">
                  <Form.Label>
                    {context.t('Document Type')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="document_type"
                    defaultValue={inputs.document_type || ''}
                    onChange={onInputChange}>
                    <option value="PP">{context.t('Passport')}</option>
                    <option value="DL">{context.t("Driver's Licence")}</option>
                    <option value="ID">
                      {context.t('Government issued Identity Card')}
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the identification number')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="4">
                <Form.Group controlId="full_name">
                  <Form.Label>
                    {context.t('Full name and surname')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="full_name"
                    defaultValue={inputs.full_name || ''}
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Full name and surname')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must put names and surnames')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId="country" title="Company country">
                  <Form.Label>{context.t('Country')}</Form.Label>
                  <Countries
                    nombre={'country'}
                    dv={inputs.country || ''}
                    countries={countries}
                    onChange={onInputChange}
                    handleCountryChange={handleCountryChange}
                  />
                </Form.Group>
              </Col>

              <Col xl="4">
                <Form.Group controlId="birthday">
                  <Form.Label>
                    {context.t('Date of Birth')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>

                  <DatePickerInput
                    autoComplete="off"
                    showOnInputClick
                    onChange={value => {
                      onInputChangeByName('birthday', value);
                    }}
                    displayFormat="DD/MM/YYYY"
                    returnFormat="YYYY-MM-DD"
                    name="birthday"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={errors.birthday ? 'showError' : ''}>
                    {context.t('You must choose a valid date of birth')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group controlId="percentage_number">
                  <Form.Label>
                    {context.t('Percentage Number %')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="percentage_number"
                    defaultValue={inputs.percentage_number || ''}
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Percentage Number')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the Percentage Number')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6">
                <Form.Group controlId="address_main">
                  <Form.Label>
                    {context.t('Main address')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="address_main"
                    defaultValue={inputs.address_main || ''}
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Main address')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the main address')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="6">
                <Form.Group controlId="address_billing">
                  <Form.Label>
                    {context.t('Billing Address')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="address_billing"
                    defaultValue={inputs.address_billing || ''}
                    type="text"
                    onChange={onInputChange}
                    placeholder={context.t('Billing Address')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the billing address')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="4">
                <Form.Group controlId="phone">
                  <Form.Label>
                    {context.t('Phone')} <span className="asterisk">*</span>
                  </Form.Label>
                  <PhoneInput
                    inputClass={'form-pay-phone-custom'}
                    onChange={value => {
                      onInputChangeByName('phone', value);
                    }}
                    value={inputs.phone || ''}
                    inputExtraProps={{
                      id: 'phone',
                      name: 'phone',
                      requiredmessage: context.t(
                        'You must enter the phone number'
                      ),
                      required: true
                    }}
                    // isValid={value => {
                    //   if (this.phone) {
                    //     const {
                    //       selectedCountry: { format, dialCode },
                    //       formattedNumber
                    //     } = this.phone.state;
                    //     return format.length === formattedNumber.length;
                    //   }
                    // }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the phone number')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl="4">
                <Form.Group
                  controlId="is_pep"
                  title="Politically Exposed Person">
                  <Form.Label>
                    {context.t('Is a PEP? (People Exposed Politically)')}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="is_pep"
                    defaultValue={inputs.is_pep || ''}
                    onChange={onInputChange}>
                    <option value="">{context.t('Select if is Pep')}</option>
                    <option value="1">{context.t('Yes')}</option>
                    <option value="0">{context.t('No')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group
                  controlId="is_legal_representative"
                  title="Is a legal representative">
                  <Form.Label>
                    {context.t('Is a Legal Representative?')}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="is_legal_representative"
                    defaultValue={inputs.is_legal_representative || ''}
                    onChange={onInputChange}>
                    <option value="0">{context.t('No')}</option>
                    <option value="1">{context.t('Yes')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="4">
                <Form.Group
                  controlId="proof_residence"
                  title="Photo of any payment receipt">
                  <Form.Label>
                    {context.t('Proof of residence (no more than 3 months)')}{' '}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="proof_residence"
                    type="file"
                    onChange={onInputFileChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t(
                      'You must attach proof of residency and the file size must be less than or equal to 2MB'
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId="photo">
                  <Form.Label>
                    {context.t('Identity Selfie')}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="photo"
                    type="file"
                    onChange={onInputFileChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t(
                      'You must attach the photo and the size of the files must be less than or equal to 2MB'
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId="photo_front">
                  <Form.Label>
                    {context.t('Identity Confirmation Photo (Front)')}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="photo_front"
                    type="file"
                    onChange={onInputFileChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t(
                      'You must attach the photo front and the size of the files must be less than or equal to 2MB'
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl="4">
                <Form.Group controlId="photo_back">
                  <Form.Label>
                    {context.t('Identity Confirmation Photo (Back)')}
                    <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    name="photo_back"
                    type="file"
                    onChange={onInputFileChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {context.t(
                      'You must attach the photo back and the size of the files must be less than or equal to 2MB'
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="form-group">
                  <Form.Text className="text-muted">
                    * {context.t('Required fields')}
                  </Form.Text>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="4" className="footer-form">
                <Button
                  variant="primary"
                  type="submit"
                  style={{ padding: '1em', width: '20%' }}>
                  {context.t('Save and add another')}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  name="save_back"
                  value="true"
                  onClick={onInputChange}
                  style={{ padding: '1em', width: '20%' }}>
                  {context.t('Save and Go Back')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={intermediateLevelPageClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

CorporatePeopleForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(CorporatePeopleForm);
