import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
//import 'react-phone-input-2/dist/style.css';
import './EasyBuyPage.css';

const easyBuyPageClasses = getBEMClasses('easybuy-page');
const EasyBuyES = require('../../images/easy_buy.png');
const vm = require('../../images/visa-mastercard.png');

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, instruments, level1, userInfo, accounts };
};

const EasyBuyPageClosed = (props, context) => {
  try {
    document.title = context.t('Easy Buy') + ' - Banexcoin';
    return (
      <React.Fragment>
        <div className={easyBuyPageClasses()}>
          <div className="error-accessing-message easybuy-msj">
            <img src={EasyBuyES} className="roboto" alt="easy buy" />
            <br />
            <div className="title-soon">
              {context.t('Soon, you can buy Bitcoin with your credit card')}
              <br />
              {context.t('Thanks to')} <b>{context.t('Easy Buy')}</b>
            </div>
            <img src={vm} className="roboto vm-mini" alt="visa mastercard" />
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

EasyBuyPageClosed.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(EasyBuyPageClosed);
