/* eslint-disable import/first */
import React, { Component } from 'react';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import moment from 'moment';
import { Switch, Route, withRouter } from 'react-router-dom';
import { initApex, setGateway } from 'apex-web/lib/apex';
import { Table, Row, Col } from 'react-bootstrap';
import {
  connectionOpened,
  connectionClosed
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { getQueryParam } from './helpers/queryParamsHelper';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import { setInstruments } from './redux/actions/set_instruments'
import { setGlobalConfig } from './redux/actions/set_global_config'
import { createCountries } from './redux/actions/set_countries'
import { setProducts } from './redux/actions/set_products'
import { setLimits } from './redux/actions/set_limits'
import StandAloneLayout from './layouts/StandAloneLayout/StandAloneLayout';
import SignupPage from './pages/bnxv2/Pages/SignupPage';
import SignupByTypePage from './pages/bnxv2/Pages/SignupByTypePage';
import LandingPageCo from './pages/LandingPageCo';
import LandingBetaPage from './pages/LandingBetaPage';
import ProblemLogginPage from './pages/bnxv2/Pages/ProblemLogginPage';
import DepositConfirmation from 'apex-web/lib/components/DepositConfirmation/DepositConfirmationModalContainer';
import Snackbar from './components/APSnackbar/APSnackbarContainer';
import TwoFactor from 'apex-web/lib/components/TwoFactorAuthForm/TwoFactorAuthFormContainer';
import SidePane from './components/common/SidePane/SidePaneContainer';
import { emailLinkRoutes } from 'apex-web/lib/helpers/emailLinksHelper';
import EmailLinks from './components/EmailLayouts/EmailLinksLayout';
import InteriorPage from './pages/InteriorPage';
import LoginPage from './pages/bnxv2/Pages/LoginPage';
import FeesCommissionComponent from './pages/FeesCommissionsPage';
import KYCPolicyPage from './pages/KYCPolicyPage';
import PairPricesPage from './pages/PairPricesPage';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import redirectIfAuthorizedCoink from './helpers/redirectIfAuthorizedCoink';
import SimpleExchangePage from './pages/Exchange/SimpleExchangePage';
import config from './config';
import Require2FA from 'apex-web/lib/components/2FA/TwoFactorAuthContainer';
import StepTitle from './pages/StepsTitle';
import { ToastContainer } from 'react-toastify';
import empty from 'is-empty';
import apex from 'apex-web/lib/apex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMinusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import { mant } from './pages/utils';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/theme/responsive.css';
import CoinkLogin from './pages/Coink/CoinkLogin';
import CoinkPublic from './pages/Coink/CoinkPublic';
import './styles/theme/bnxv2.css';
import './pages/InteriorPagePhoneConfirmation.css';
import LandingPageValidate from './pages/LandingPageValidate';
import ClaimsPage from './pages/bnxv2/Pages/ClaimPage/ClaimsPage';
import { getAllLimits, getInstrumentsByAccountId, setCookieManagement } from './components/AccountComponents/AccountHooks';
import { LANGUAGES } from './constants/languages';
import SignupNaturalCoink from './pages/bnxv2/Components/SignupCoink/SignupNaturalCoinkContainer';
import AssetsController from './components/AssetsController';
import FreshchatWidget from './components/FreshChat/FreshChat';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import ExchangePage from './pages/ExchangeV2/ExchangePageContainer';
import { setBlockActions } from './redux/actions/set_user_info';
import { getPublicCountries } from './pages/bnxv2/hooks/signupHooks';
import isEmpty from 'is-empty';
import { coinkAlert } from './components/Retail/RetailSidePanes/FiatWithdraw/CoinkAlert';
import { alertsContentCoink } from './constants/alertsContentCoink';

library.add(faMinusCircle, faInfoCircle);

class App extends Component {
  constructor(props) {
    super(props);
    setGateway(getGateway());
    initApex(this.props.connectionOpened, this.props.connectionClosed);
    const AffiliateTag = getQueryParam('aff');
    if (AffiliateTag) {
      window.localStorage.setItem('affiliateTag', AffiliateTag);
    }
    var selectedInstrumentId = Number(
      localStorage.getItem('selectedInstrumentId')
    );
    if (selectedInstrumentId === 3) {
      localStorage.setItem('selectedInstrumentId', String(1));
    }
    let t = JSON.parse(localStorage.getItem('apexTemplate'));

    this.componentDidMount = () => {
      let interval = setInterval(() => {
        const el = document.getElementById("fc_frame")
        if(el){
          const p = document.createElement("p")
          p.setAttribute("id","p-freshchat")
          p.innerHTML = "Chat Live"
          el.appendChild(p)
          clearInterval(interval)
        }
        },1000) 
    }

    this.state = {
      instruments:false,
      phoneVerification: false,
      securityVerificationConfig: false,
      availableAssets: false,
      run: true,
      theme: 'dark',
      activate: false,
      disableBeacon: true,
      steps: [
        {
          content: (
            <StepTitle
              title="Welcome to Banexcoin"
              content="Welcome to Banexcoin, a new platform to exchange crypto assets in the easiest way. Next, we will show you each element of the platform:"
            />
          ),
          placement: 'center',
          target: 'body'
        },
        {
          target: '.second-step',
          content: (
            <StepTitle
              title="Verification Levels"
              content="Verification levels are important within Banexcoin, depending on the level you have, there will be benefits for your account, such as: Best rates, wide deposit or withdrawal limits."
            />
          )
        }
      ],
      dontshowalert: false,
      openMenu: false,
      limits: false,
      loading: true
    };
    const lang = localStorage.getItem("language")
    if(!LANGUAGES.includes(lang)){
      localStorage.setItem("language","en")
      window.location.reload()
      
    }
  }
  showJoy = () => {
    this.setState({
      run: true,
      activate: true
    });
  };
  myLogHandler = () => {
    this.setState({
      run: true,
      activate: true
    });
  };
  syncTokenGBC(props) {
    (async () => {
      const token = localStorage.getItem('token');
      let UserConfigFilter = props.UserConfig;
      UserConfigFilter.push({ Key: 'GBC_TOKEN', Value: token });
      const env = { OMSId: 1, UserId: props.UserId, Config: UserConfigFilter };
      let SetUserConfig = await apex.connection.SetUserConfig(env);
    })();
  }
  // deprecado
  //  handleJoyrideCallback = data => {
  //   const { action, index, status, type } = data;
  //   if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
  //     // Update state to advance the tour
  //     this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
  //   } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     // Need to set our running state to false, so we can restart if we click start again.
  //     this.setState({ run: false, activate: false });
  //   }
  // };

  handleChangeCheckbox = e => {
    e.persist();
    if (e.target.checked) {
      this.setState({
        dontshowalert: true
      });
    } else {
      this.setState({
        dontshowalert: false
      });
    }
  };

  changeTheme = theme => {
    this.setState({ theme: theme });
  };
  handleLimits = (data) => {
    let limits = {};
    data.forEach((e) => {
      let products = {};
      e.Products.forEach((p) => {
        products[p.ProductName] = p.MonthlyDepositLimit
      });
      limits[e.Level] = products;
    });
    return limits;
  }

  handleCheckBlockingIsNeededInCoinkUsers = (coinkBlockTimes = {}) => {
    const currentDate = moment()
    const time = coinkBlockTimes.from.time.split(":")
    const calculateRange = () => {
    const theoryfrom = moment().set({
    day: coinkBlockTimes.from.day,
    hour: time[0],
    minutes: time[1],
    second: 0
    })
  const theoryTo = theoryfrom.clone().add("minutes", coinkBlockTimes.mins).subtract(1, "second")
    if(Number(theoryTo) <= Number(currentDate)) {
    return {
      theoryfrom: theoryfrom.clone().add("week", 1),
      theoryTo: theoryTo.clone().add("week", 1)
    }
  }
    return {
      theoryfrom,
      theoryTo
    }
  }

  const isBlocked = () => {
    const date = calculateRange()
    return date.theoryfrom <= currentDate && currentDate < date.theoryTo  
  }
  if(isBlocked() && !this.props.blockedActions){
    this.props.setBlockActions(true)
    coinkAlert(alertsContentCoink.swipingIsNowMessage, this.context)
  }
  if(!isBlocked() && this.props.blockedActions){
    this.props.setBlockActions(false)
  }
}
  async componentDidUpdate(props){
    if(this.props.isCoink){
      localStorage.setItem("language","es")
      localStorage.setItem("is-co","true")
    }
    if(this.state.loading){
      this.setState({
        loading:false
      })
      const countriesReq = await getPublicCountries();
      if (countriesReq.hasOwnProperty('data') && !empty(countriesReq.data)) {
        const countriesData = countriesReq.data.countries;
        const listCountries = countriesData.map((c) => {
          return {
            value: c.countryCode,
            label: c.countryName
          };
        });
        this.props.createCountries(listCountries)
      }
    }
    if(empty(props.UserId)) return;
    if(!this.state.instruments){
      this.setState({
        instruments:true,
      })
      const response = await getInstrumentsByAccountId({UserId:props.UserId,accountId:props.UserInfo.AccountId})
      if(empty(response) || empty(response.data)) return;
      this.props.setInstruments(
        {
          instrumentsByAccountId:{
            buysell:response.data.instruments,
            exchange:response.data.instrumentsExchange
          }
        }
      )
      this.props.setGlobalConfig(response.data)
      if(this.props.isCoink){
        localStorage.setItem("language","es")
        setInterval(() => {
          this.handleCheckBlockingIsNeededInCoinkUsers(response.data.coinkBlockTime)
        },1000) 
      }
      this.props.setProducts(response.data.products)
    }
    if (!this.state.executedOnce && this.props.completedUserInfo && this.props.completedUserInfo.level_basic) {
      const { level_basic:{ banexcoinUserId:userBanexcoinId }} = this.props.completedUserInfo;
      const banxUserCookie = JSON.parse(localStorage.getItem("BanxUserAgreed"));
      if ( userBanexcoinId && banxUserCookie ) {
        const cookieId = banxUserCookie.id;
        const response = await setCookieManagement( { UserId:props.UserId, userBanexcoinId, cookieId } );
        this.setState({ executedOnce: true });
      }
    }
    if(!this.state.limits){
      this.setState({
        limits:true,
      })
      let res = await getAllLimits({UserId:props.UserId})
      this.props.setLimits(res.data.data)
    }
  }
  render() {
    const { pathname } = this.props.location;
    const hideMenu = () => {
      return pathname.includes('coink') ||
        pathname.includes('verification/start') ||
        pathname.includes('verification/fill') ||
        pathname.includes('verification/beginner') ||
        pathname.includes('verification/intermediate') ||
        pathname.includes('verification/advanced') ||
        pathname.includes('verification/master') ||
        pathname.includes('verification/master-dependent') ||
        pathname.includes('verification/master-independent') ||
        pathname.includes('start/master') ||
        pathname.includes('verification/success') ||
        pathname.includes('verification/error')
        ? 'hide-menu'
        : '';
    };
    const isNotclaimBookPath = !pathname.includes("reclamaciones")
    const mantValue = mant();
    const { run, steps, activate, theme, dontshowalert } = this.state;
    const cookies = new Cookies();

    let popup = cookies.get('popup') || 'yes';
    let popupcounter = cookies.get('popupcounter');

    const modalAlert = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert modalAlertBeta">
              <div className="react-confirm-alert-body">
                <div className="body_scroll">
                  <Row>
                    <Col xl="4" className="lateral-image">
                      <img
                        src="/local/landing-page/images/ethereum_img.png"
                        className="maintenance-img"
                      />
                    </Col>
                    <Col xl="8" className="lateral-right">
                      <h1>{this.context.t('Hard Fork Ethereum')}</h1>
                      <p>
                        <b>
                          {this.context.t(
                            'On Thursday, April 15th, 2021, at approximately 09:30 UTC (Block # 12,244,000), the Ethereum consensus protocol will undergo an uncontentious hard fork upgrade.'
                          )}
                        </b>{' '}
                        {this.context.t(
                          'This is likely to cause instability in the ETH network for a period of time.'
                        )}
                      </p>
                      <br />
                      <p>
                        {this.context.t(
                          'Banexcoin clients may experience delays in USDC deposits and withdrawals for up to a few hours after the fork is complete. Therefore, '
                        )}
                        <b>
                          {this.context.t(
                            'Banexcoin will not allow deposits and withdrawals of USD Coin (USDC) from Thursday, April 15th, 2021 at 00:00 a.m. Lima time (05:00 UTC).'
                          )}
                        </b>
                      </p>
                      <br />
                      <p>
                        {this.context.t(
                          'As soon as possible after the fork, Banexcoin will report the resumption of support for USDC deposits and withdrawals.'
                        )}
                      </p>
                      <br />
                      <p style={{ textAlign: 'left' }}>
                        {this.context.t(
                          'A live countdown to the hard fork can be seen on a third-party website here'
                        )}{' '}
                        <a
                          href="https://www.ethernodes.org/berlin"
                          target="_blank">
                          https://www.ethernodes.org/berlin
                        </a>.
                      </p>
                      <br />
                      <p>{this.context.t('We hope your understanding.')}</p>
                      <br />
                      <p className="team-sign">
                        <img
                          src="/local/landing-page/images/isotipo_mini.png"
                          className="inline-img"
                        />{' '}
                        {this.context.t('Banexcoin team.')}
                      </p>
                      {popupcounter >= 3 ? (
                        <p className="dontshowalert">
                          <input
                            type="checkbox"
                            id="dontshowalert"
                            onChange={this.handleChangeCheckbox}
                            value="dontshowalert"
                          />
                          <label htmlFor="dontshowalert">
                            {this.context.t("Don't show again")}
                          </label>
                        </p>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="react-confirm-alert-button-group">
                  <button
                    className="closeModalBeta"
                    onClick={() => {
                      onClose();
                    }}>
                    {this.context.t('X')}
                  </button>
                </div>
              </div>
            </div>
          );
        },
        afterClose: () => {
          let popupcounterNro = !empty(popupcounter)
            ? Number(popupcounter) + 1
            : 1;
          cookies.set('popupcounter', popupcounterNro, {});
          if (popupcounterNro >= 3 && dontshowalert) {
            cookies.set('popup', 'no', {});
          }
        }
      });
    };

    if (!empty(this.props.selectedAccountId)) {
      //this.syncTokenGBC(this.props);
      let start = 1612501200000;
      let end = 1612501200000;
      let today = Number(moment());
      if (today >= start && today <= end) {
        if (popup === 'yes') {
          modalAlert();
        }
      }
    }
    return (
      <React.Fragment>
        <div
          className={
            'App fluid container clearfix' +
            ((this.props.isAuthenticated && isNotclaimBookPath) ? ' logged' : '') +
            ' ' +
            theme +
            ` ${hideMenu()}`
          }>
          <Switch>
            <Route
              path="/version"
              render={() => (
                <div>
                  <p>v3implementation-template: {packageJson.version}</p>
                  <p>apex-web: {packageJson.dependencies['apex-web']}</p>
                </div>
              )}
            />
            <Route exact path={'/'}>
              <LandingPageValidate
                defaultPath={process.env.REACT_APP_DEFAULT_PATH}
              />
            </Route>

            {emailLinkRoutes(pathname, EmailLinks)}
            <Route
              exact
              path={'/login'}
              component={
                !mantValue
                  ? redirectIfAuthorized(
                    LoginPage,
                    process.env.REACT_APP_DEFAULT_PATH
                  )
                  : null
              }
            />
            <Route
              path={'/fees-commissions'}
              render={() => (
                <React.Fragment>
                  <FeesCommissionComponent />
                </React.Fragment>
              )}
            />
            <Route
              path={'/kyc-aml-policy'}
              render={() => (
                <React.Fragment>
                  <KYCPolicyPage />
                </React.Fragment>
              )}
            />
            <Route exact path={'/libro-reclamaciones'} component={ClaimsPage} />
            <Route exact path={'/signup'} component={!mantValue
              ? redirectIfAuthorized(
                SignupPage,
                process.env.REACT_APP_DEFAULT_PATH
              )
              : null} />
            <Route exact path={'/signup/:type'} component={!mantValue
              ? redirectIfAuthorized(
                SignupByTypePage,
                process.env.REACT_APP_DEFAULT_PATH
              )
              : null} />
            <Route exact path={'/signup/natural/coink'} component={SignupNaturalCoink} />

            <Route path={'/problem-logging-in'} component={ProblemLogginPage} />
            <Route
              path={'/twofactorauth'}
              render={() => (
                <StandAloneLayout>
                  <div id="bg-nmy">
                    <div className="left-img">
                      <div>
                        <div className="img1" />
                      </div>
                    </div>
                    <div className="right-block">
                      <div className="oval" />
                      <div className="boxin" />
                    </div>
                  </div>
                  <TwoFactor />
                </StandAloneLayout>
              )}
            />
            <Route path={'/prices/:pairName'} component={PairPricesPage} />
            {config.SimpleExchange.active && (
              <Route
                path={config.SimpleExchange.route}
                component={SimpleExchangePage}
              />
            )}

            {/* passing props.location prevents Blocked Re-renders: https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking */}
            {/* <Route exact path={'/coink/login'} render={() => <CoinkPublic />} />
            <Route
              exact
              path={'/coink/oldlogin/:token'}
              component={redirectIfAuthorizedCoink(
                CoinkLogin,
                '/coink/dashboard'
              )}
            /> */}
            {!this.props.isAuthenticated && <Route path={'/exchange/:instrument'}>
              <ExchangePage />
            </Route>

            }
            <InteriorPage
              location={this.props.location}
              theme={theme}
              changeTheme={this.changeTheme}
              // resizeDashboard={resizeDashboard}
              {...this.props}
            />
            {/* <Route path="" component={NotFoundPage} /> */}
          </Switch>
        </div>
        <Snackbar />
        <SidePane theme={theme} />
        <DepositConfirmation />
        <Require2FA />
        <ToastContainer />
        <FreshchatWidget />
      </React.Fragment>
    );
  }
}

App.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    instrument:state.instrument.instruments,
    isAuthenticated: state.auth.isAuthenticated,
    UserId: state.user.userInfo.UserId,
    UserConfig: state.user.userConfig,
    UserInfo: state.user.userInfo,
    selectedAccountId: state.user.selectedAccountId,
    token: state.auth.token,
    completedUserInfo: state.completedUserInfo,
    isCoink: !isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level): false,
    blockedActions: state.blockedActions
  };
};
const mapDispatchToProps = {
  onInit,
  connectionOpened,
  connectionClosed,
  setInstruments,
  setGlobalConfig,
  setProducts,
  setLimits,
  selectInstrument,
  createCountries,
  setBlockActions
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
