import React from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import empty from 'is-empty';
import Tooltip from '../../../images/tooltip.svg';
import NoImg from '../../../images/no-image-box.png';
const FormSelect = (props, context) => {
  const {
    label,
    required,
    name,
    id,
    onChange,
    options,
    defaultOption,
    value,
    errors,
    showAsterisk = true,
    classNameContainer,
    showInformativeItem
  } = props;

  try {
    const addDefaultSrcImage = ev => {
      ev.target.src = NoImg;
    };
    const titleOption = () => {
      let name = (
        <b className="placeHolderSelect">
          {context.t(!empty(defaultOption) ? defaultOption : 'Select Option')}
        </b>
      );
      if (!empty(options) && !empty(value)) {
        let found = options.find(o => o.value === value);
        if (!empty(found)) {
          name = (
            <React.Fragment>
              <b>{found.name}</b>
              <span>
                <img src={found.logo} /> 
              </span>
            </React.Fragment>
          );
        }
      }
      return name;
    };
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      as: ButtonGroup,
      required: required ? true : false,
      name,
      onChange,
      title: <div className="flex">{titleOption()}</div>
    };
    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field ' +
          (!empty(classNameContainer) ? classNameContainer : '') +
          (!empty(errors) && !empty(errors[name]) ? ' invalid' : ' valid')
        }>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)}{' '}
            {required && showAsterisk ? <b className="important">*</b> : ''}
            {showInformativeItem && (
              <img
                src={Tooltip}
                alt="img_tooltio"
                onClick={showInformativeItem}
              />
            )}
          </Form.Label>
        ) : (
          ''
        )}
        <DropdownButton {...propInput}>
          {!empty(options) &&
            options.map(option => (
              <Dropdown.Item
                className="dropdown-V3"
                as="button"
                type="button"
                key={option.value}
                onClick={() => {
                  let e = {
                    persist: () => {},
                    target: {
                      name: name,
                      value: option.value
                    }
                  };
                  onChange(e);
                }}>
                <div className="item-selector-item">
                  <div className="item-selector-title">
                    <span className="title">{context.t(option.name)}</span>
                    {!empty(option.sub) && (
                      <span className="subtitle">{context.t(option.sub)}</span>
                    )}
                    {!empty(option.twosub) ? (
                      <span className="subtitle">
                        {context.t(option.twosub)}
                      </span>
                    ) : (
                      ''
                    )}
                    {!empty(option.currency) ? (
                      <span className="subtitle currency">
                        <img
                          src={`/local/product-icons/${option.currency.toLowerCase()}-48px.svg`}
                        />
                        {context.t(option.currency)}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="item-selector-logo">
                    {!empty(option.logo) ? <img src={option.logo} alt='bank logo' onError={ev => addDefaultSrcImage(ev)}/> : ''}
                  </div>
                </div>
                  {!empty(option.alertMessage) && <p className='message-alert-accounts-item'><span>{option.alertMessage}</span></p>}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        {!empty(errors) && !empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    return <React.Fragment />;
  }
};

FormSelect.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormSelect;
