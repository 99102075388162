import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";
import "./expiredDocument.css";
import isEmpty from "is-empty";

const ExpiredDocument = (props, context) => {
  const { data, kycLink } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isEmpty(data)) return;
    if ((data.daysFromExpiration && data.daysFromExpiration <= 0 && data.daysFromExpiration >= -5) || data.mustUpdate) {
      setShow(true);
    }
  }, [data]);

  if (isEmpty(data)) return <></>;
  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show && !isEmpty(data) && !isEmpty(kycLink)}
        onHide={() => setShow(false)}
        backdrop="static"
        centered
        className="modal-bnx-v2 lighting modal-disclaimer expired-document-modal"
      >
        <Modal.Header>
          <Modal.Title
            dangerouslySetInnerHTML={{
              __html: context.t("Data update"),
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl="12" className="text-center">
              <p
                dangerouslySetInnerHTML={{
                  __html: context.t(
                    data.daysFromExpiration < 0
                      ? "We need you to update your identity document soon as it is about to expire. Please, update your information as soon as possible to avoid any inconvenience or delay in your operations."
                      : data.daysFromExpiration < 30
                      ? "We need you to update your identity document so that you are not restricted when using our withdrawal and trading services. Please, update your data as soon as possible to avoid any inconvenience or delay in your operations."
                      : "Due to the fact that you have not updated your identity document, we have temporarily withdrawn the permission to make withdrawals and trades on our platform. Please update your details to regain access to these features."
                  ),
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn-bnxv2"
            onClick={async () => {
              window.location.href = kycLink;
            }}
          >
            {context.t("Update here")}
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {context.t("Try later")}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ExpiredDocument.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    user: { userInfo },
  } = state;
  return { userInfo };
};

export default connect(mapStateToProps, null)(ExpiredDocument);
