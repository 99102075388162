import { connect } from 'react-redux';
import DownloadReportComponent from './DownloadReportComponent';
import { downloadDocument } from '../../helpers/downloadHelper';
import { getUserReportWriterResults } from 'apex-web/lib/redux/actions/reportActions';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { createStateSnackbar as _createStateSnackbar, resetStateSnackbar as _resetStateSnackbar } from '../../redux/actions/set_snackbar'

const mapStateToProps = function mapStateToProps(state) {
  const _state$report = state.report,
    reports = _state$report.reports,
    reportWriterResults = _state$report.reportWriterResults,
    isLoading = _state$report.isLoading,
    userId = state.user.userInfo.UserId;

  return {
    reports: reports,
    reportWriterResults: reportWriterResults,
    isLoading: isLoading,
    downloadDocument: downloadDocument,
    userId
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getUserReportWriterResults: () => dispatch(getUserReportWriterResults()),
    GetUserReportWriterResultRecords: userId =>
      dispatch(callAPI('GetUserReportWriterResultRecords', { UserId: userId })).then(res => {
        return res;
      }),
    createStateSnackbar: function createStateSnackbar(payload) {
      return dispatch(_createStateSnackbar(payload));
    },
    resetStateSnackbar: function resetStateSnackbar() {
      return dispatch(_resetStateSnackbar());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReportComponent);
