import React, { useEffect, useState } from 'react';
import empty from 'is-empty';
import { connect } from 'react-redux';
import Marquee from 'react-fast-marquee';

const HeadBand = (props, context) => {
  let { lang, ClassName } = props;
  let popupsData = (props.messages || []).filter(m => m.type === 'headband');

  if (empty(popupsData) || navigator.userAgent == 'ReactSnap') {
    return <React.Fragment />;
  }
  return popupsData.map((h, i) => {
    return (
      !empty(h.eventText[lang]) && (
        <Marquee key={i} gradientColor="none" speed="50" className={ClassName}>
          <div
            dangerouslySetInnerHTML={{
              __html: h.eventText[lang]
            }}
          />
        </Marquee>
      )
    );
  });
};

const mapStateToProps = ({ i18nState: { lang } }) => {
  return { lang };
};

export default connect(
  mapStateToProps,
  null
)(HeadBand);
