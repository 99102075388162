import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Row, Col } from 'react-bootstrap';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import {
  FormField,
  FormButtonV2,
  FormSelector,
  FormSingleCheckbox
} from '../../../components/common/BnxFormsComponents';
import img_peru from './../../../images/img_peru.png';
import { IconArrowPopupLeft } from '../../../components/common/BnxIcons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const accountPageClasses = getBEMClasses('account-page');

const AddPeFormFields = (props, context) => {
  let {
    inputs,
    onInputChangeValidation,
    onCheckboxChangeValidation,
    onInputChangeCleanValidation,
    generalErrors,
    errors,
    accountTypes,
    peruvianBanks,
    selectedBankSquares,
    goBackPath,
    userLevel
  } = props;
  return (
    <Row>
      <Col xl="4">
        <Link
          to={!empty(goBackPath) ? goBackPath : '/settings/accounts/add'}
          className="v2-back-link">
          <IconArrowPopupLeft />
        </Link>
      </Col>
      <Col xl="4">
        <div className={`${accountPageClasses('select-box-item')} reset-style-box`}>
          <img src={img_peru} />
          <i>{context.t('My account on')}</i>
          <b>{context.t('Perú')}</b>
        </div>
        <FormField
          required
          name="alias"
          labelInside="Alias"
          placeholder="Alias"
          onChange={onInputChangeValidation}
          value={inputs.alias}
          errors={errors}
        />
        <FormSelector
          required
          name="currencyCode"
          labelInside="Select currency"
          defaultOption="Select currency"
          options={[
            { value: 'USD', label: 'USD' },
            { value: 'PEN', label: 'PEN' }
          ]}
          onChange={onInputChangeValidation}
          value={inputs.currencyCode}
          errors={errors}
        />
        <FormSelector
          required
          name="beneficiaryBankname"
          labelInside="Select Bank"
          defaultOption="Select Bank"
          options={
            !empty(peruvianBanks) &&
            peruvianBanks.map(b => {
              return { value: b.bankName, label: b.bankName };
            })
          }
          onChange={onInputChangeValidation}
          value={inputs.beneficiaryBankname}
          errors={errors}
        />
        <FormSelector
          required
          name="bankCity"
          labelInside="Select Bank Square"
          defaultOption="Select Bank Square"
          options={
            !empty(selectedBankSquares) &&
            selectedBankSquares.map(b => {
              return { value: b.city, label: context.t(b.city) };
            })
          }
          onChange={onInputChangeValidation}
          value={inputs.bankCity}
          errors={errors}
        />
        <FormSelector
          required
          name="accountType"
          labelInside="Account type"
          defaultOption="Account type"
          options={
            !empty(accountTypes) &&
            accountTypes.map(b => {
              return { value: b.type, label: context.t(b.name) };
            })
          }
          onChange={onInputChangeValidation}
          value={inputs.accountType}
          errors={errors}
        />
        <FormField
          required
          name="accountNumber"
          labelInside="Account number"
          placeholder="Account number"
          onChange={onInputChangeCleanValidation}
          value={inputs.accountNumber}
          errors={errors}
        />
        <FormField
          required
          name="accountNumberCci"
          labelInside="Account number CCI"
          placeholder="Account number CCI"
          onChange={onInputChangeCleanValidation}
          value={inputs.accountNumberCci}
          errors={errors}
        />
        <FormSingleCheckbox
          name="ownAccountConfirmation"
          classNameContainer="pl-3"
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: context.t(
                  `I declare that it is my ${userLevel > 14 ? "corporative" : "personal" } account and the place is indicated`
                )
              }}
            />
          }
          onChange={onCheckboxChangeValidation}
          checked={inputs.ownAccountConfirmation}
          errors={errors}
        />
        {!empty(generalErrors) && !empty(generalErrors.multiple) ? (
          <Row className="justify-content-center mt-1 mb-4 error-msg">
            <Col xl="12">
              <ul className="error-box">
                {generalErrors.multiple.map(e => <li>{context.t(e)}</li>)}
              </ul>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <FormButtonV2
          type="submit"
          variant={empty(errors) ? 'dark' : 'light'}
          className="submit-button">
          {context.t('Save')}
        </FormButtonV2>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    userLevel: state.user.verificationLevelConfigs.Level
  };
};
AddPeFormFields.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AddPeFormFields);
