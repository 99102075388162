//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevelBasic = async (userId, UserAccountId) => {
  let path = `/api/level-basic/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/intermediate-level';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('next_level', '2');
  model.append('id', data.id);
  //model.append('occupation', data.occupation);
  //model.append('work_center', data.work_center);
  model.append('proof_residence', data.proof_residence);
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
