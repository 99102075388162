import React from 'react'
import './depositPage.css'
import ProductListContainer from '../../components/ProductList/ProductListContainer';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';

const DepositPage = () => {
    return (
        <div className='deposit-page'>
            <ProductListContainer title='Deposit' />
            <PageFooterLayout />
        </div>
    )
}

export default DepositPage