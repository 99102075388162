import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import './DashboardPage.css';
import { formatNumberToLocale, formatPercentageValue } from 'apex-web/lib/helpers/numberFormatter';
import { Link } from 'react-router-dom';
import { FormatDecimalCoinkWithSimbols, convertIncrementToIntDecimalPlaces } from '../pages/utils';
import isEmpty from 'is-empty';
function DashboardOverviewV2(props, context) {

	const percentageValue = (value) => {
		const initialPercentageValue = formatPercentageValue(value)
		if (initialPercentageValue.includes("-")){
			return initialPercentageValue.slice(1)
		}
		return initialPercentageValue
	}

	const decimal = (price, increment) => {
		const decimalPrice = String(price).includes(".") ? Number(String(price).split(".")[1].length) : 0
		const decimalIncrement = convertIncrementToIntDecimalPlaces(increment)
		const newDecimal = decimalPrice <= decimalIncrement ? decimalPrice : decimalIncrement;
		return newDecimal >= 2 ? newDecimal : 2;
	}

	const handle24hPrice = (session, best, product, priceIncrement) => {
		return !session 
			? formatNumberToLocale(best, decimal(best, priceIncrement)) 
			: formatNumberToLocale(session, product)
	}

	return (
		<div className='table-container'>
			<div className="divTable">
				<div className="divTableBody">
					<div className="divTableRow header">
						<div className="divTableCell text"><p>{context.t("Change coins")}</p></div>
						<div className="divTableCell text"><p>{context.t("Buy")}</p></div>
						<div className="divTableCell text"><p>{context.t("Sell")}</p></div>
						<div className="divTableCell text"><p>{context.t("Var 24hrs")}</p></div>
						<div className="divTableCell text"><p>{context.t("24hrs Max")}</p></div>
						<div className="divTableCell text"><p>{context.t("24hrs Min")}</p></div>
						<div className="divTableCell text"><p>{context.t("24hrs Vol")}</p></div>
						<div className="divTableCell text"><p>{context.t("Action")}</p></div>
					</div>
					{props.instruments.filter(el => props.availableInstruments.includes(el.Symbol)).map(instrument => {
						const product2 = props.products.find(product => product.ProductId === instrument.Product2)
						const product1 = props.products.find(product => product.ProductId === instrument.Product1)
						const level1 = props.level1[instrument.InstrumentId] || {}
						return (<div className="divTableRow" key={instrument.InstrumentId}>
							<div className="divTableCell">
								<div className="instrument-cell">
									<div className='instrument-cell-element'>
										<img
											alt="product icon"
											height="24px"
											src={'/local/product-icons/' + product1.iconFileName}
										/>
										<span>{instrument.Product1Symbol}</span>
									</div>
									<span>/</span>
									<div className='instrument-cell-element'>
										{product2.iconFileName.includes(".svg") ? <img
											alt="product icon"
											height="24px"
											src={'/local/product-icons/' + product2.iconFileName}
										/> : <div style={{ width: "35px" }}></div>}
										<span>{instrument.Product2Symbol}</span>
									</div>
								</div>
							</div>
							<div className="divTableCell text"><span>{FormatDecimalCoinkWithSimbols(formatNumberToLocale(level1.BestOffer, decimal(level1.BestOffer, instrument.PriceIncrement)),product2.Product)}</span></div>
							<div className="divTableCell text"><span>{FormatDecimalCoinkWithSimbols(formatNumberToLocale(level1.BestBid, decimal(level1.BestBid, instrument.PriceIncrement)),product2.Product)}</span></div>
							<div className={`divTableCell text ${String(level1.Rolling24HrPxChangePercent).includes("-") ? "red" : "green"}`}><div></div><span>{percentageValue(level1.Rolling24HrPxChangePercent)}</span></div>
							<div className="divTableCell text"><span>{FormatDecimalCoinkWithSimbols(handle24hPrice(level1.SessionHigh, level1.BestOffer, product2.Product, instrument.PriceIncrement),product2.Product)}</span></div>
							<div className="divTableCell text"><span>{FormatDecimalCoinkWithSimbols(handle24hPrice(level1.SessionLow, level1.BestBid, product2.Product, instrument.PriceIncrement),product2.Product)}</span></div>
							<div className="divTableCell text"><span>{formatNumberToLocale(level1.Rolling24HrVolume, product1.Product)}</span><span>{product1.Product}</span> </div>
							<div className="divTableCell"><Link to='/buy-sell' className="button_V2 purple btn-bull-dash">{context.t('Buy')}</Link></div>
						</div>)
					})}
 				</div>
			</div>
		</div>
	)
}

DashboardOverviewV2.contextTypes = {
	t: PropTypes.func.isRequired
};

const mapStateToProps = function mapStateToProps(state) {
	const { instrumentsByAccountId } = state
	const level1 = state.apexCore.level1;
	const instruments = instrumentSelectorInstruments(state);
	const products = productPositionAssetsSelector(state);
	return { 
		level1: level1, 
		instruments: instruments, 
		products: products,  
		availableInstruments:
			!isEmpty(instrumentsByAccountId) &&
			!isEmpty(instrumentsByAccountId[0].instrumentsByAccountId) 
				? instrumentsByAccountId[0].instrumentsByAccountId.buysell 
				: [],
	};
};

export default connect(mapStateToProps)(DashboardOverviewV2);