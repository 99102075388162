import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';

var baseClasses = getBEMClasses('activity');

var RecentActivityHeaderComponent = function RecentActivityHeaderComponent(
  props,
  context
) {
  var headerText = props.headerText,
    children = props.children;

  return React.createElement(
    'div',
    { className: baseClasses('header-bar') },
    React.createElement(
      'div',
      { className: baseClasses('header-text') },
      context.t(headerText)
    ),
    children
  );
};

RecentActivityHeaderComponent.defaultProps = {
  headerText: 'Recent Activity'
};

RecentActivityHeaderComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecentActivityHeaderComponent;
