const callbackReducer = (state = {}, action) => {
    switch (action.type) {
        case 'BUY_SELL_ACTION':
            return { ...action.payload };
        case 'WITHDRAWAL_ACTION':
            return { ...action.payload };
        default:
            return state;
    }
};
export default callbackReducer;