import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { useForm } from '../../../hooks/formHooks';
import diamond from '../../../images/diamond.png'
import diamond2 from '../../../images/diamond2.png'
import {
  FormButtonV2,
  FormDate,
  FormSingleCheckbox
} from '../../../components/common/BnxFormsComponents';
import {
  createBanexcoinUserNatural,
  usersInRegistrationProcess,
  updateUsersInRegistrationProcess
} from '../hooks/signupHooks';
import APIcon from '../../../components/common/APIcon';
import ErrorCircle from '../../../images/bnx-icons/error-circle.svg';
import SuccessCircle from '../../../images/bnx-icons/success-circle.svg';
import ArrowDown from '../../../images/bnx-icons/arrow-label-down.svg';
import isEmpty from 'is-empty';
import InputElement from './InputElement/InputElement';
import eye from '../../../images/eye.svg'
import eyeSlash from '../../../images/eye-slash.svg'
import lock from '../../../images/lock.svg'
import user from '../../../images/user.svg'
import mail from '../../../images/mail.svg'
import calendar from '../../../images/calendar.svg'
import { EMAIL_DOMAIN_LIST, PASSWORD_VALIDATION_LIST, REGEX_LIST } from '../../../constants'
import { toast } from 'react-toastify';
import coinkLogo from '../../../images/coink-imgs/mini-iso-coink.svg'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import LoginController from './LoginController';
import { Spinner } from '../../../components/Spinner/Spinner';
import checkIcon from '../../../images/icons/check-circle.svg'
import VideoModal from './videoModal/videoModal';
import { googleAnalitycs } from '../../utils';

const SignupNaturalForm = (props, context) => {
  const [errorsValidation, setErrorsValidation] = useState({})
  const { setLoading, setEnable, location, listCountries, setLoadingMessage } = props;
  const fullPath = `${location.search}`;
  const sp = new URLSearchParams(fullPath);
  const [countries, setCountries] = useState([]);
  const [step, setstep] = useState(1);
  const [successScreen, setSuccessScreen] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState({
    caracter: false,
    number: false,
    capitalLetter: false,
    lowerLetter: false,
    symbol: false
  });
  const [showPasswordHelp, setShowPasswordHelp] = useState(false);
  const [showEmailHelp, setShowEmailHelp] = useState(false);
  const [showSelectItems, setShowSelectItems] = useState(false);
  const [otherMail, setOtherMail] = useState(false);
  const [domain, setDomain] = useState(EMAIL_DOMAIN_LIST[0]);
  const [loadingValidationEmail, setLoadingValidationEmail] = useState(false)
  const [emailIsAvailable, setEmailIsAvailable] = useState(false)
  const [showSuccessEmail, setShowSuccessEmail ] = useState(false)
  const [timeoutValue, setTimeoutValue] = useState(null)

  const [timeoutUsername, setTimeoutUsername] = useState(null)
  const [loadingValidationUsername, setLoadingValidationUsername] = useState(false)
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false)
  const [showSuccessUsername, setShowSuccessUsername ] = useState(false)
  
  const [timeoutPhone, setTimeoutPhone] = useState(null)
  const [loadingValidationPhone, setLoadingValidationPhone] = useState(false)
  const [phoneIsAvailable, setPhoneIsAvailable] = useState(false)
  const [showSuccessPhone, setShowSuccessPhone ] = useState(false)
  const [ idUserReg, setUserIdReg] = useState('')

  const titlesSection = ["Create your account", "What is your name?", "Country and document"]
  const handlePasswordValidator = (value) => {
    setPasswordValidator({
      caracter: REGEX_LIST.regexToValidateCharacters.test(value),
      number: REGEX_LIST.regexToValidateNumber.test(value),
      capitalLetter: REGEX_LIST.regexToValidateCapitalLetter.test(value),
      lowerLetter: REGEX_LIST.regexToValidateLowerLetter.test(value),
      symbol: REGEX_LIST.regexToValidateSymbol.test(value)
    })
  };

  const closeItems = () => {
    if (showSelectItems) {
      setTimeout(() => {
        setShowSelectItems(false)
      }, 200);
    }
  }

  const handleOpenCloseItems = () => {
    setShowSelectItems(!showSelectItems);
  }

  const handleSelectmail = (name) => {
    delete errors["email"]
    delete errorsValidation["email"]
    setDomain(name);
    setOtherMail(false)
    validateEmail(inputs.email, name)
    closeItems()
  }
  
  const handleSelectOtherMail = (otherDomain) => {
    delete errors["email"]
    delete errorsValidation["email"]
    setShowSelectItems(false);
    setDomain(otherDomain || '@');
    setOtherMail(true);
    setTimeout(() => {
      document.getElementById('domainId').focus();
    }, 1);
  }

  const handleChangeEmail = (e) => {
    const { value } = e.target;
    setDomain(value);
    delete errors["email"]
    delete errorsValidation["email"]
    if(timeoutValue){
      clearTimeout(timeoutValue)
    }
    setTimeoutValue(setTimeout(async () => {
      validateEmail(inputs.email, value)
      clearTimeout(timeoutValue)
    }, 1000))
  }

  const initialState = {
    validated: false,
    AffiliateTag: !empty(sp.get('aff')) ? sp.get('aff') : null
  };

  const emptyVal = val => {
    let result = false;
    if (empty(val)) {
      result = true;
    } else {
      result = empty(val.trim());
    }
    return result;
  };

  const signupFlow = async inputs => {
    setErrorsValidation({})
    if(inputs.documentType === "RP"){
      setLoadingMessage(context.t("We are creating your account, this process may take a few seconds."))
    }else{
      setLoadingMessage("")
    }
    setLoading(1);
    try {
      const signupNatural = await createBanexcoinUserNatural({
        ...inputs,
        mobilePhone: {
          number: inputs.phone,
          countryCode: inputs.countryCode.toUpperCase()
        }
      });
      if (!empty(signupNatural.data) && signupNatural.data.message === 'success') {
        setEnable(false);
        setSuccessScreen(true);
        googleAnalitycs('Registro_Personal');
        if(inputs.hasOwnProperty('AffiliateTag')){
          googleAnalitycs('Referidos');
        }
      } else {
        toast.warn(context.t(signupNatural.message), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      if (!empty(error.response) && !empty(error.response.data) && !empty(error.response.data.data)) {
        const response = error.response.data
        if (!empty(response.data.email) || !empty(response.data.username)) {
          setstep(1)
        }
        setErrorsValidation(response.data)
      } else {
        toast.warn(context.t(error.response.data.message), {
          position: toast.POSITION.TOP_CENTER
        });
      }

    } finally {
      setLoading(0);
    }
  };

  const onSubmitCallback = async (inputs) => {
    const newErrors = customValidations(inputs)
    if (!empty(newErrors) || !emailIsAvailable || loadingValidationEmail || !usernameIsAvailable || loadingValidationUsername || !phoneIsAvailable || loadingValidationPhone || !empty(errorsValidation)) return
    if (step === 1) {
      setstep(2);
    const result = await usersInRegistrationProcess({
        ...inputs,
        email: inputs.email + domain,
        phone: inputs.completePhone
      })
    setUserIdReg(result.data.data.id)
    } else if (step === 2) {
      setstep(3)
      await updateUsersInRegistrationProcess(idUserReg, {
        ...inputs,
        firstName: inputs.firstName.trim(),
        lastName: inputs.lastName.trim(),
      })
    } else {
      const newInputs = {
        ...inputs,
        email: inputs.email + domain,
        matchingPassword: inputs.password,
        firstName: inputs.firstName.trim(),
        lastName: inputs.lastName.trim(),
      }
      await updateUsersInRegistrationProcess(idUserReg, newInputs)
      await signupFlow(newInputs);
    }
  };

  const onSubmitRegister = (e) => {
    e.preventDefault();
    closeItems()
    onSubmitSimpleWithoutValidation(e);
  }

  const  defaultInputsValidation = () => {
    let thereAreEmptyInput = false
    if (step === 1) {
      if (empty(inputs.username)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.phone)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.email)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.password)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.iamOlder) || !inputs.iamOlder) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.privacyPolicy) || !inputs.privacyPolicy) {
        thereAreEmptyInput = true;
      }
    } else if (step === 2) {
      if (empty(inputs.firstName)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.lastName)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.ceBirthday)) {
        thereAreEmptyInput = true;
      }
    } else {
      if (empty(inputs.residenceCountry)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.birthCountry)) {
        thereAreEmptyInput = true;
      }
      if (inputs.birthCountry === 'PE' && inputs.residenceCountry === 'PE' && inputs.documentType !== 'ID') {
        thereAreEmptyInput = true;
      }
      if (inputs.birthCountry === 'PE' && inputs.residenceCountry !== 'PE' && inputs.documentType === 'RP') {
        thereAreEmptyInput = true;
      }
      if (inputs.birthCountry !== 'PE' && inputs.residenceCountry === 'PE' && inputs.documentType === 'PP') {
        thereAreEmptyInput = true;
      }
      if (inputs.birthCountry !== 'PE' && inputs.residenceCountry !== 'PE' && inputs.documentType !== 'PP') {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.documentType)) {
        thereAreEmptyInput = true;
      }
      if (empty(inputs.documentNumber)) {
        thereAreEmptyInput = true;
      }
    }
    return thereAreEmptyInput
  }

  const usernameValidation = (inputs, errors) => {
    if (!emptyVal(inputs.username)) {
      const validateUsernameLettersAndNumbers = REGEX_LIST.regexToValidateUsername.test(inputs.username);
      if (!validateUsernameLettersAndNumbers) {
        errors.username = 'Username must contain only letters and numbers';
      } else {
        const validateUsernameLength = REGEX_LIST.regexToValidateUsernameLength.test(inputs.username);
        if (!validateUsernameLength) {
          errors.username = 'Username must contain at least 5 and at most 20 characters';
        }
      }
    }
    return errors
  }

  const emailValidation = (inputs, errors) => {
    if (!emptyVal(inputs.email)) {
      const validateEmail = REGEX_LIST.regexToValidateMail.test(String(inputs.email + domain).toLowerCase());
      if (!validateEmail) {
        errors.email = 'Invalid email address';
      }
    }
    return errors
  }
  const passwordValidation = (inputs, errors) => {
    if(!empty(inputs.password) && inputs.password.length < 8) {
      errors.password = 'Password must contain at least 8 characters';
      return errors
    }
    if (!empty(inputs.password)) {
      const validatePassword = REGEX_LIST.regexToValidatePassword.test(inputs.password);
      if (!validatePassword) {
        errors.password = 'Your password must contain at least one capital letter, numbers and a symbol';
      }
    }
    return errors
  }
  const phoneValidation = (inputs, errors) => {
    if (isEmpty(inputs.phone)) return errors
    if (inputs.countryCode === "pe") {
      if (inputs.phone[0] != 9) {
        errors.phone = "Phone number must start with digit 9"
      } else if (inputs.phone.length !== 9) {
        errors.phone = 'Must contain 9 characters'
      }
    } else {
      if (inputs.phone.length < 7 || inputs.phone.length > 11) {
        errors.phone = 'Must contain at least 7 and at most 11 characters'
      }
    }
    return errors
  }
  const birthdayValidation = (inputs, errors) => {
    if (!empty(inputs.residenceCountry) && !empty(inputs.documentType)) {
      if (inputs.residenceCountry && (inputs.documentType === 'ID' || inputs.documentType === 'RP')) {
        if (!empty(inputs.ceBirthday) && !moment(inputs.ceBirthday).isValid()) {
          errors.ceBirthday = 'Invalid format date';
        }
      }
    }
    return errors
  }
  const documentNumberValidation = (inputs, errors) => {
    if (!empty(inputs.documentNumber)) {

      if (inputs.documentType === 'RP') {
        if (!REGEX_LIST.regexAlphanumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Your document number must contain only letters and numbers';
        } else if (inputs.documentNumber.length < 9 || inputs.documentNumber.length > 12) {
          errors.documentNumber = 'Document number must contain at least 9 and at most 12 characters';
        }
      }

      if (inputs.documentType === 'ID') {
        if (!REGEX_LIST.regexNumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Your document number must contain only numbers';
        } else if (inputs.documentNumber.length !== 8) {
          errors.documentNumber = 'Document number must contain 8 numbers';
        }
      }

      if (inputs.documentType === 'PP') {
        if (!REGEX_LIST.regexAlphanumeric.test(inputs.documentNumber)) {
          errors.documentNumber = 'Your document number must contain only letters and numbers';
        } else if (inputs.documentNumber.length < 6 || inputs.documentNumber.length > 12) {
          errors.documentNumber = 'Document number must contain at least 6 and at most 12 characters';
        }
      }
    }
    return errors
  }
  const customValidations = (inputs) => {
    let errors = {};
    if (step === 1) {
      errors = usernameValidation(inputs, errors)
      errors = phoneValidation(inputs, errors)
      errors = emailValidation(inputs, errors)
      errors = passwordValidation(inputs, errors)
    }
    if (step === 2) {
      errors = birthdayValidation(inputs, errors)
    }
    if (step === 3) {
      errors = documentNumberValidation(inputs, errors)
    }
    setErrors(errors);
    return errors;
  };
  let {
    inputs,
    errors,
    setErrors,
    onDefaultChange,
    onInputChangeValidationEmail,
    onSubmitSimpleWithoutValidation,
    onInputChangeByName,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);

  let optionsDocument = [];
  if (!empty(inputs) && !empty(inputs.birthCountry) && !empty(inputs.residenceCountry)) {
    if (inputs.birthCountry === 'PE') {
      if (inputs.residenceCountry === "PE") {
        optionsDocument = [{ value: 'ID', label: context.t('National identity document') }];
      } else {
        optionsDocument = [{ value: 'ID', label: context.t('National identity document') }, { value: 'PP', label: context.t('Passport') }];
      }
    } else {
      if (inputs.residenceCountry === "PE") {
        optionsDocument = [{ value: 'ID', label: context.t('National identity document') }, { value: 'RP', label: context.t('Residence permit') }];
      } else {
        optionsDocument = [{ value: 'PP', label: context.t('Passport') }];
      }
    }
  }

  const handleChangeInput = (e) => {
    onDefaultChange(e)
    delete errors[e.target.name]
    delete errorsValidation[e.target.name]
  }

  const validateEmail = async (email, domain) => {
    if(isEmpty(email) || isEmpty(domain)) return
    setEmailIsAvailable(false)
    setLoadingValidationEmail(true)
    const emailNotExist = await LoginController.checkEmail(email + domain)
    setLoadingValidationEmail(false)
    if(emailNotExist) {
      setEmailIsAvailable(true)
      setShowSuccessEmail(true)
      setTimeout(() => {
        setShowSuccessEmail(false)
      }, 2000);
      delete errorsValidation["email"]
      return
    } 
      setErrorsValidation({
        ...errorsValidation,
        email: 'The entered email address is invalid or is already registered.'
      })
  }

  const validateInput = async (type, value) => {
    if(type === "username"){
      setLoadingValidationUsername(true)
      const validateUsernameLength = REGEX_LIST.regexToValidateUsernameLength.test(value);
      if(validateUsernameLength){
        setUsernameIsAvailable(false)
        delete errorsValidation[type]
        const dataNotExist = await LoginController.checkInput({[type]: value})
        setLoadingValidationUsername(false)
        if(dataNotExist){
          setUsernameIsAvailable(true)
          setShowSuccessUsername(true)
          setTimeout(() => {
            setShowSuccessUsername(false)
          }, 2000);
          return 
        }
        return setErrorsValidation(e=>({
          ...e,
          username: 'The entered username is invalid or is already registered.'
        }))
      }
      setLoadingValidationUsername(false)
      return setErrorsValidation(e=>({
        ...e,
        username: 'Username must contain at least 5 and at most 20 characters'
      }))
    }
    if(type === "mobilePhone"){
      const data = {
        countryCode: value.country.countryCode,
        phone: value.value.replace(value.country.dialCode, "")
      }
      if(!isEmpty(data.countryCode) && !isEmpty(data.phone) && value.value.length > 1){
        setLoadingValidationPhone(true)
        const errors = phoneValidation(data, {}) 
        if(isEmpty(errors)){
          setPhoneIsAvailable(false)
          delete errorsValidation["phone"]
          delete errors["phone"]
          const dataNotExist = await LoginController.checkInput({
            [type]: {
              countryCode: data.countryCode.toUpperCase(),
              number: data.phone
            } 
          })
          setLoadingValidationPhone(false)
          if(dataNotExist){
            setPhoneIsAvailable(true)
            setShowSuccessPhone(true)
            setTimeout(() => {
              setShowSuccessPhone(false)
            }, 2000);
            return 
          }
          return setErrorsValidation(e=>({
            ...e,
            phone: 'The entered phone number is invalid or is already registered.'
          }))
        }
        setLoadingValidationPhone(false)
        return setErrorsValidation(e=>({
          ...e,
          ...errors
        }))
      }
      delete errorsValidation["phone"]
      delete errors["phone"]
    }
  }

  const phoneInputProps = {
    value: inputs.completePhone || '',
    name: 'phone',
    placeholder: context.t("Enter your phone number"),
    onChange: ({ value, country }) => {
      if(phoneIsAvailable) setPhoneIsAvailable(false)
      if(timeoutPhone) clearTimeout(timeoutPhone)
      setTimeoutPhone(setTimeout(async () => {
        validateInput("mobilePhone", { value, country })
      }, 1000))
      setInputs({
        ...inputs,
        countryCode: country.countryCode,
        phone: value.replace(country.dialCode, ""),
        completePhone: value
      })
      if(value.length < 1){
        delete errorsValidation["phone"]
        delete errors["phone"]
      }
    },
    error: errors.phone || errorsValidation["phone"]
  };

  const emailInputProps = {
    values: {
      title: 'Email',
      value: inputs.email || '',
      name: 'email',
      type: 'text',
      className: 'input-mail-V2',
      icon: mail
    },
    actions: {
      onChange: (e) => {
        if(emailIsAvailable) setEmailIsAvailable(false)
        if(timeoutValue){
          clearTimeout(timeoutValue)
        }
        let enteredEmailDomain = e.target.value.split("@")[1]
        if(isEmpty(enteredEmailDomain)){
          enteredEmailDomain = domain
        }else{
          enteredEmailDomain = "@" + enteredEmailDomain
        }
        setTimeoutValue(setTimeout(async () => {
          validateEmail(e.target.value, enteredEmailDomain)
          clearTimeout(timeoutValue)
        }, 1000))
        handleChangeInput(e)
        const newDomain = onInputChangeValidationEmail(e)
        if(isEmpty(newDomain)) return 
        if(!EMAIL_DOMAIN_LIST.includes(newDomain)){
          handleSelectOtherMail(newDomain)
          return 
        }
        setDomain(newDomain)
      },
      onClick: () => setShowEmailHelp(true),
      onBlur: () => setShowEmailHelp(false)
    },
    error: errors.email || errorsValidation["email"]
  };

  const passwordInputProps = {
    values: {
      title: 'Password',
      value: inputs.password || '',
      name: 'password',
      type: inputs.showPassword ? 'text' : 'password',
      autoComplete: "new-password",
      rightIcon: inputs.showPassword ? eyeSlash : eye,
      icon: lock
    },
    actions: {
      onChange: (e) => {
        handleChangeInput(e)
        handlePasswordValidator(e.target.value);
      },
      onClick: () => setShowPasswordHelp(true),
      onBlur: () => setShowPasswordHelp(false),
      onChangeSecondary: () => togglePass('showPassword')
    },
    error: errors.password
  };
  const usernameInputProps = {
    values: {
      title: 'Username',
      value: inputs.username || '',
      name: 'username',
      type: 'text',
      icon: user,
      maxlength: 20,
    },
    actions: {
      onChange: (e) => {
        const value = e.target.value
        if(REGEX_LIST.regexToValidateUsername.test(value)){
          if(usernameIsAvailable) setUsernameIsAvailable(false)
          if(timeoutUsername) clearTimeout(timeoutUsername)
          onDefaultChange(e)
          setTimeoutUsername(setTimeout(async () => {
            validateInput("username", value)
          }, 1000))
        }
      }
    },
    error: errors.username || errorsValidation["username"]
  };
  const firstNameInputProps = {
    values: {
      title: 'First name',
      value: inputs.firstName || '',
      name: 'firstName',
      type: 'text',
      autoComplete:"off"
    },
    actions: {
      onChange: (e) => {
        if(REGEX_LIST.regexToValidateFullName.test(e.target.value)){
          handleChangeInput(e)
        }
      }
    },
    error: errors.firstName
  };
  const lastNameInputProps = {
    values: {
      title: 'Last name',
      value: inputs.lastName || '',
      name: 'lastName',
      type: 'text',
      autoComplete:"off"
    },
    actions: {
      onChange: (e) => {
        if(REGEX_LIST.regexToValidateFullName.test(e.target.value)){
          handleChangeInput(e)
        }
      }
    },
    error: errors.lastName
  };

  const birthdayInputProps = {
    values: {
      title: context.t("Birthday"),
      value: inputs.ceBirthday || '',
      name: 'ceBirthday',
      type: 'date',
      autoComplete:"off",
      placeholder: "DD/MM/YYYY",
      max: moment().subtract(18, "years").format("YYYY-MM-DD")
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.ceBirthday
  };

  const birthCountryInputProps = {
    values: {
      title: 'Birth country',
      value: inputs.birthCountry || '',
      name: 'birthCountry',
      type: 'select',
      options: countries
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.birthCountry || errorsValidation["birthCountry"]
  };

  const residenceCountryInputProps = {
    values: {
      title: 'Residence country',
      value: inputs.residenceCountry || '',
      name: 'residenceCountry',
      type: 'select',
      options: countries
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.residenceCountry || errorsValidation["residenceCountry"]
  };

  const documentTypeInputProps = {
    values: {
      disabled: (isEmpty(inputs) || (isEmpty(inputs.birthCountry) || isEmpty(inputs.residenceCountry))),
      title: 'Document type',
      value: inputs.documentType || '',
      name: 'documentType',
      type: 'select',
      options: optionsDocument
    },
    actions: {
      onChange: (e) => {
        handleChangeInput(e)
        onInputChangeByName('documentNumber','')
      }
    },
    error: errors.documentType
  };

  const minMaxValDocumentNumber = {
    ID: {
      min: "8",
      max: "8",
      validate: REGEX_LIST.regexOnlyNumbers
    },
    RP: {
      min: "9",
      max: "12",
      validate: REGEX_LIST.regexToValidateUsername
    },
    PP: {
      min: "6",
      max: "12",
      validate: REGEX_LIST.regexToValidateUsername
    }
  }

  const documentNumberInputProps = {
    values: {
      title: 'Document number',
      value: inputs.documentNumber || '',
      name: 'documentNumber',
      type: 'text',
    },
    actions: {
      onChange: (e) => {
        if(inputs.documentType && minMaxValDocumentNumber[inputs.documentType].validate.test(e.target.value) && (e.target.value.length <= minMaxValDocumentNumber[inputs.documentType].max)){
          handleChangeInput(e)
        }
      }
    },
    error: errors.documentNumber || errorsValidation["documentNumber"]
  };

  const togglePass = name => {
    setInputs({
      ...inputs,
      [name]: !inputs[name]
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    onInputChangeByName(name, checked)
  }

  const handleCoinkRegister = () => {
    window.location.href = process.env.REACT_APP_COINK_REGISTER_URL || ""
  }

  const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
      const input = document.querySelector("input[type=tel]");
      if(!input) return
      input.setAttribute("maxlength", 15);
  }, []);

  useEffect(() => {
    const input = document.querySelector("input[type=tel]");
    if(!input) return
    if(inputs.countryCode === "pe"){
      input.setAttribute("maxlength", 15);
    }else{
      input.removeAttribute("maxlength");
    }
  },[inputs.countryCode])

  useEffect(() => {
    if(isEmpty(countries) && !isEmpty(listCountries)){
      setCountries(listCountries)
    }
  }, [listCountries])
  

  const [showVideoModal, setShowVideoModal] = useState(false)
  const handleShowVideoModal = () => {
    setShowVideoModal(true)
  }
  useEffect(() => {
		if(!window.fcWidget) return
		window.fcWidget.on('widget:closed', () => {
			window.fcWidget.hide();
    });
	},[window.fcWidget])
  const handleOpenChat = () => {
		if(!window.fcWidget) return
		window.fcWidget.show();
		window.fcWidget.open();
	}

  const handleArrowBack = () => {
    setstep((prevStep) => prevStep - 1);
    setErrorsValidation({});
  };

  return (
    <div className="bnxv2-box-rounded fix-height">
      {showVideoModal && createPortal(
      <VideoModal onClose ={() => setShowVideoModal(false) }/>,
      document.getElementById('modal')
    )}
      <img className="diamond" src={diamond} alt="aux diamond background" />
      <img className="diamond2" src={diamond2} alt="aux diamond background" />
      {!successScreen ? (
        <React.Fragment>
          <Row />
          <Form
            validated={inputs.validate}
            onSubmit={onSubmitRegister}
            className="form-container-v2"
          >
            <div>
            {(step === 2 || step === 3) && (
              <span className="arrowback" onClick={handleArrowBack}>
                <APIcon name="arrowBack" />
              </span>
            )}
             <img
                src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
                alt="logo"
                width={150}
                className='logo-mobile coink'
                style={step === 2 ? {display:"none"} : {margin:"auto"}}
              />
            </div>
            <Row className="justify-content-center text-left">
              <Col xl="10" className='subtitle-help'>
                <h1 className="title-register-section">
                  {context.t(titlesSection[step - 1])}
                </h1>
                {step === 1 && <p onClick={handleShowVideoModal}>{context.t("Do you need help?")}</p>}
              </Col>
            </Row>
            {step === 1 && (
              <React.Fragment>
                <Row className="justify-content-center mt-0">
                  <Col xl="10">
                    <div className='validate-input-text'>
                      <InputElement {...usernameInputProps} />
                      <div className="spinner-container">
                        {loadingValidationUsername ? (
                          <Spinner />
                        ) : showSuccessUsername ? (
                          <img src={checkIcon} alt="checkIcon" />
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col xl="10">
                    <div>
                      <InputElement {...emailInputProps} />
                      <div className="spinner-container">
                        {loadingValidationEmail ? (
                          <Spinner />
                        ) : showSuccessEmail ? (
                          <img src={checkIcon} alt="checkIcon" />
                        ) : null}
                      </div>
                    </div>
                    <div className="main-select-email">
                      <button
                        type="button"
                        className="header-itm"
                        onBlur={closeItems}
                      >
                        {otherMail ? (
                          <input
                            type="text"
                            value={domain}
                            onChange={handleChangeEmail}
                            autoComplete="off"
                            id="domainId"
                          />
                        ) : (
                          <p onClick={handleOpenCloseItems}>{domain}</p>
                        )}
                        <span onClick={handleOpenCloseItems}>
                          <img src={ArrowDown} alt="ArrowDown" />
                        </span>
                      </button>
                      <div
                        className={`cont-itm-select${
                          showSelectItems ? " active-select" : ""
                        }`}
                      >
                        {EMAIL_DOMAIN_LIST.filter((d) => d !== domain).map(
                          (name, key) => (
                            <p key={key} onClick={() => handleSelectmail(name)}>
                              {name}
                            </p>
                          )
                        )}
                        <p onClick={() => handleSelectOtherMail()}>
                            {context.t("Customized")}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`app-modal-help-text${
                        showEmailHelp ? " show" : ""
                      }`}
                    >
                      <p>{context.t("Confirm that your email is correct")}.</p>
                    </div>
                  </Col>
                  <Col xl="10">
                    <div>
                      <InputElement {...passwordInputProps} />
                    </div>
                    <div
                      className={`app-modal-help-text${
                        showPasswordHelp ? " show" : ""
                      }`}
                    >
                      {PASSWORD_VALIDATION_LIST.map(({ text, type }, key) => (
                        <div className="itm-option-v2" key={key}>
                          <img
                            src={
                              passwordValidator[type]
                                ? SuccessCircle
                                : ErrorCircle
                            }
                            alt="iconStatus"
                            width={20}
                          />
                          <p>{context.t(text)}</p>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xl="10">
                    <div
                      className="phone-input-container claim-page validate-input-text"
                      style={{ marginTop: "0" }}
                    >
                      <PhoneInput
                        containerClass={
                          !isEmpty(errors.phone) ? "error-phone" : ""
                        }
                        inputProps={{
                          name: phoneInputProps.name,
                          placeholder: phoneInputProps.placeholder,
                        }}
                        masks={{ pe: "... ... ..." }}
                        country={"pe"}
                        value={phoneInputProps.value}
                        onChange={(value, country) => {
                          phoneInputProps.onChange({ value, country });
                        }}
                      />
                      <div className="spinner-container">
                        {loadingValidationPhone ? (
                          <Spinner />
                        ) : showSuccessPhone ? (
                          <img src={checkIcon} alt="checkIcon" />
                        ) : null}
                      </div>
                      {(errors && errors.phone) || (errorsValidation && errorsValidation.phone) && (
                        <span className="message-error">
                          {context.t(errors.phone || errorsValidation.phone)}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xl="10">
                    <FormSingleCheckbox
                      name="privacyPolicy"
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: context.t(
                              "I have read and accept the <a href='{link}' target='_blank'>Privacy Policy</a>",
                              {
                                link: "https://soporte.banexcoin.com/support/solutions/articles/60000599926",
                              }
                            ),
                          }}
                        />
                      }
                      onChange={handleCheckboxChange}
                      checked={inputs.privacyPolicy}
                      errors={errors}
                    />
                  </Col>
                  <Col xl="10">
                    <FormSingleCheckbox
                      name="iamOlder"
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: context.t(
                              "I certify that I am over 18 years of age and that I have read and accept the <a href='{link}' target='_blank'>Terms and Conditions</a>",
                              {
                                link: "https://soporte.banexcoin.com/support/solutions/articles/60000599926",
                              }
                            ),
                          }}
                        />
                      }
                      onChange={handleCheckboxChange}
                      checked={inputs.iamOlder}
                      errors={errors}
                    />
                  </Col>
                  <Col xl="10">
                    <FormSingleCheckbox
                      name="processingPersonalData"
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: context.t(
                              "I accept the processing of my personal data for additional purposes. <b>(Optional)</b>"
                            ),
                          }}
                        />
                      }
                      onChange={handleCheckboxChange}
                      checked={inputs.processingPersonalData}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {step === 2 && (
              <React.Fragment>
                <Row className="justify-content-center mt-0">
                  <Col xl="10">
                    <InputElement {...firstNameInputProps} />
                  </Col>
                  <Col xl="10">
                    <InputElement {...lastNameInputProps} />
                  </Col>
                  <Col xl="10" className='input-birthday'>
                    <InputElement {...birthdayInputProps} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {step === 3 && (
              <React.Fragment>
                <Row className="justify-content-center mt-0">
                  <Col xl="10">
                    <InputElement {...birthCountryInputProps} />
                  </Col>
                  <Col xl="10">
                    <InputElement {...residenceCountryInputProps} />
                  </Col>
                  <Col xl="10">
                    <InputElement {...documentTypeInputProps} />
                  </Col>
                  <Col xl="10">
                    <InputElement {...documentNumberInputProps} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Row className="justify-content-center mt-2 p-1-5">
              <Col xl="10">
                {
                  <FormButtonV2
                    disabled={defaultInputsValidation() || !emailIsAvailable || loadingValidationEmail || !usernameIsAvailable || loadingValidationUsername || !phoneIsAvailable || loadingValidationPhone || !empty(errorsValidation)}
                    type="submit"
                    variant={empty(errors) ? "dark" : "light"}
                    className="submit-button register-form general-btn"
                  >
                    {context.t(step === 1 || step === 2 ? "Next" : "Register")}
                  </FormButtonV2>
                }
              </Col>
            </Row>
            {step === 1 &&
              isMobileDevice() &&
              process.env.REACT_APP_COINK_REGISTER_URL && (
                <>
                  <div className="separator-button">
                    <span>ó</span>
                  </div>
                  <Row className="justify-content-center mt-2 p-1-5">
                    <Col xl="10">
                      {
                        <FormButtonV2
                          type="button"
                          onClick={handleCoinkRegister}
                          className="submit-button register-form coink-button general-btn"
                        >
                          <>
                            <img src={coinkLogo} alt="coink logo" width={25} />
                            <span>
                              {context.t("Register with {provider}", {
                                provider: "Coink",
                              })}
                            </span>
                          </>
                        </FormButtonV2>
                      }
                    </Col>
                  </Row>
                </>
              )}
            <Row className="justify-content-center text-center mt-2 link-register-bottom">
              <Col xl="10" className="bottom-box-v2 mt-4">
                <span className="title">
                  {context.t("Do you already have an account?")}{" "}
                </span>
                <Link to="/login">{context.t("Login")}</Link>
              </Col>
            </Row>
          </Form>
          <Row className="mt-5 pb-5 containerFooterOption">
            <a
              href="https://soporte.banexcoin.com/support/solutions/articles/60000599926"
              target="_blank"
            >
              {context.t("Terms and Conditions")}
            </a>
            <a
              href="https://soporte.banexcoin.com/support/tickets/new"
              target="_blank"
            >
              {context.t("Contact us")}
            </a>
            <p className='chat-with-us' onClick={handleOpenChat}>{context.t("Chat with us")}</p>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Modal show={true} centered>
            <div className="app-modal-information success-register">
              <h1 className="header-modal-title">
                {context.t("Successful registration!")}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: context.t(
                    "<b>We've sent you an email</b> with instructions to activate your account. Follow the steps and join Banexcoin to start enjoying our services."
                  ),
                }}
              />
              <Link className="btn btn-dark v2-field btn-bnxv2" to="/login">
                {context.t("I already activated my account")}
              </Link>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

SignupNaturalForm.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = function mapStateToProps(state) {
  return {
    language: state.i18nState.lang,
    listCountries: state.countriesReducer || []
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    toRedirect: path => {
      ownProps.history.push(path || '/');
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignupNaturalForm)
);
