const component = (state = { visible: false, elements: [] }, action) => {
    switch (action.type) {
        case 'CREATE_STATE_COMPONENT':
            return { ...action.payload };
        case 'UPDATE_STATE_COMPONENT':
            return { ...state, ...action.payload };
        case 'RESET_STATE_COMPONENT':
            return { visible: false, elements: [] };
        default:
            return state;
    }
};
export default component;