import React from 'react';
import PropTypes from 'prop-types';
import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import GlobalHooks from './GlobalHooks';

import CarouselNews from './CarouselNewsV2/CarouselNewsV2';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './DashboardPage.css';
import Balance from './Dashboard/Balance';
import Overview from './Dashboard/Overview';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const layoutClasses = getBEMClasses('dashboard-page');

const BanexcardBanner = ({context}) => {
  return <div className='banexcard-banner'>
  <div>
    <span className='congratulations'>{context.t("Congratulations!")}</span>
    <span>{context.t("You have a Pre-Approved Banexcard VISA waiting for you")}</span>
  </div>
  <Link to='/cards' className='button_V2 get-your-card'>{context.t('Get your card here')}</Link>
</div>
}
const DashboardPage = (props, context) => {
  const popupsData = (props.messages || []).filter(m => m.type === 'carousel');
  document.title = context.t('Dashboard') + ' - Banexcoin';
  const config = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const isCoink = props.verificationLevelConfigs.Level >= 100
  return (
    <div className='dashboard_page'>
      {(!props.banexCard.hasBanexCard && props.banexCard.userLevel < 17) && <BanexcardBanner context={context}/>}
      <h1 className={layoutClasses('title')}>{context.t('Home')}</h1>
      <div className='content_dashboard_page'>
        <div className='balance_global_section'>
          <Balance />
          <GlobalHooks config={config} resendGenerate={props.resendGenerate} />
        </div>
        <Overview />  
        <div className="banner-step">
          <CarouselNews messages={popupsData} />
        </div>
        {
        !isCoink && (
          <div className='refers-container'>
            <p>{context.t('Refer a friend or family member so that both can benefit')}.</p>
            <Link to='/settings/affiliate' className='button_V2'>{context.t('Refer friends')}</Link>
          </div>
        )
       }
      </div>
      <div className={layoutClasses('footer')}>
        <PageFooterLayout />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  return { 
    verificationLevelConfigs: user.verificationLevelConfigs ,
    banexCard: state.banexCard
  };
};


DashboardPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withAuthentication(DashboardPage));