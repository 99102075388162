import React, { useState } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import { formatDateTime } from "apex-web/lib/helpers/dateHelper";
import { formatNumberToLocale } from "apex-web/lib/helpers/numberFormatter";
import { getOrderPriceToDisplay } from "apex-web/lib/helpers/orderHistoryHelper";
import APTable from "apex-web/lib/components/common/APTable";
import { convertIncrementToIntDecimalPlaces } from "apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper";
import FiltersContainer from "../Filters/FiltersContainer";
import moment from "moment";

const baseClass = getBEMClasses("order-history-table");

const FilledOrdersComponent = (
  { data, instruments, config, fetching, selectedInstrument },
  context
) => {
  const [transactions, setTransactions] = useState(data);
  const { maxLines, usePagination } = config;

  const handleFilterByDate = () => {
    const startDate = document.getElementsByName("startDate")[0].value;
    const endDate = document.getElementsByName("endDate")[0].value;
    if(!startDate || !endDate) return;
    const startDateObj = moment(startDate, "DD/MM/YYYY");
    const endDateObj = moment(endDate, "DD/MM/YYYY");
    const filteredRows = data.filter((item) => {
      return (
        startDateObj.isBefore(moment(new Date(item.TradeTimeMS))) &&
        endDateObj.isAfter(moment(new Date(item.TradeTimeMS)))
      );
    });
    setTransactions(filteredRows);
  };

  const resetRows = () => {
    setTransactions(data);
  }

  const rowProps = (row) => ({
    selectedInstrument:
      selectedInstrument ||
      instruments.find((item) => item.InstrumentId === row.InstrumentId) ||
      {},
  });

  const columns = [
    {
      header: context.t("ID"),
      dataTest: "ID",
      cell: (row) => row.TradeId,
    },
    {
      header: context.t("Pair"),
      dataTest: "Pair",
      cell: (row, { selectedInstrument }) =>
        selectedInstrument ? selectedInstrument.Symbol : "",
    },
    {
      header: context.t("Side"),
      dataTest: "Side",
      cell: (row) => (
        <div className={baseClass(row.Side === "Buy" ? "buy" : "sell")}>
          {context.t(row.Side)}
        </div>
      ),
    },
    {
      header: context.t("Size"),
      dataTest: "Size",
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass("size")}>
          {row.Quantity &&
            formatNumberToLocale(
              row.Quantity,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )}
        </div>
      ),
    },
    {
      header: context.t("Price"),
      dataTest: "Price",
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass("price")}>
          {getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          )}
        </div>
      ),
    },
    {
      header: context.t("Total"),
      dataTest: "Total",
      cell: (row, { selectedInstrument }) =>
        formatNumberToLocale(
          row.Value,
          convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)
        ),
    },
    {
      header: context.t("Fee"),
      dataTest: "Fee",
      cell: (row, { selectedInstrument }) =>
        formatNumberToLocale(row.Fee, row.Fee.symbol) +
        " " +
        (row.Fee.symbol === "COP" ? "$" : row.Fee.symbol),
    },
    {
      header: context.t("Execution ID"),
      dataTest: "Execution ID",
      cell: (row) => row.ExecutionId,
    },
    {
      header: context.t("Date/Time"),
      dataTest: "Date/Time",
      cell: (row) => formatDateTime(new Date(row.TradeTimeMS)),
    },
  ];

  return (
    <>
      <FiltersContainer handleFilterByDate={handleFilterByDate} resetRows={resetRows} />
      <APTable
        {...{
          columns,
          rowProps,
          rows: transactions,
          baseClass,
          fetching,
          pageSize: maxLines,
          usePagination,
          empty: context.t("No data is available"),
          componentName: "FilledOrdersComponent",
        }}
      />
    </>
  );
};

FilledOrdersComponent.title = "Filled Orders";

FilledOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
};

FilledOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FilledOrdersComponent;
