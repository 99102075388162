import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { sellValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { FormatDecimalCoinkWithOutSimbols } from '../../pages/utils';

var AdvancedOrderLimitPrice = function AdvancedOrderLimitPrice(props, context) {
  var baseClass = props.baseClass,
    symbol1 = props.symbol1,
    symbol2 = props.symbol2,
    value = props.value,
    formSide = props.formSide;

  var bemClasses = getBEMClasses([baseClass]);
  var priceTitle =
    formSide === sellValue ? context.t('Bid Price') : context.t('Ask Price');

  return React.createElement(
    'div',
    { className: bemClasses('limit-price-block') },
    React.createElement(
      'div',
      { className: bemClasses('limit-price-block-label') },
      priceTitle + ' ' + symbol1 + ' (' + symbol2 + ')'
    ),
    React.createElement(
      'div',
      { className: bemClasses('limit-price-block-value') },
      FormatDecimalCoinkWithOutSimbols(value,symbol2 )
    )
  );
};

AdvancedOrderLimitPrice.propTypes = {
  baseClass: PropTypes.string.isRequired,
  symbol1: PropTypes.string.isRequired,
  symbol2: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formSide: PropTypes.string
};

AdvancedOrderLimitPrice.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AdvancedOrderLimitPrice;
