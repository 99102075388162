import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import empty from 'is-empty';

const UploaderBnx = (props, context) => {
  const { separator = false } = props;
  let otherProps = {};
  for (let p in props) {
    if (p !== 'onInputFileChange' && p !== 'subdata') {
      otherProps[p] = props[p];
    }
  }
  const [filename, setFilename] = useState('');
  const [size, setSize] = useState(0);
  const hiddenFileInput = React.useRef(null);
  let accepted = props.accept
    .split('.')
    .join('')
    .split(',');
  let fileExt = filename.split('.').pop();
  let ExtValid = accepted.includes(fileExt);

  let maxSize = props.maxSize ? props.maxSize : 2;
  let validsize = !empty(filename) ? (size > maxSize ? 2 : 1) : 0;

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    props.onInputFileChange(event);
    const fileUploaded = event.target.files[0];
    setFilename(!empty(fileUploaded) ? fileUploaded.name : '');
    setSize(
      !empty(fileUploaded) ? parseFloat(fileUploaded.size) / 1024 / 1024 : 0
    );
    //props.handleFile(fileUploaded);
  };
  return (
    <React.Fragment>
      <Button
        disabled={props.disabled}
        variant={
          validsize === 0
            ? 'info'
            : validsize === 1 && ExtValid
              ? 'success'
              : 'danger'
        }
        type="button"
        onClick={handleClick}
        className={'form-control uploader-btn ' + props.className}>
        {empty(filename)
          ? context.t('Upload a file')
          : empty(filename) && validsize === 1 && ExtValid
            ? context.t('Your file {size} exceeds the size of {maxSize}MB', {
                size: size.toFixed(2),
                maxSize: maxSize
              })
            : '(' + size.toFixed(2) + ' MB) ' + filename}
      </Button>
      <Form.Control
        {...otherProps}
        name={props.name}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        isValid={validsize === 1 && ExtValid ? true : false}
        aria-describedby={'subInfo' + props.name}
      />
      {separator || !empty(filename) ? (
        <Form.Text id={'subInfo' + props.name} className="text-danger">
          {!empty(filename)
            ? !ExtValid
              ? context.t('The uploaded file must be a {accepted} type', {
                  accepted: props.accept
                })
              : ''
            : ''}
        </Form.Text>
      ) : null}
    </React.Fragment>
  );
};

UploaderBnx.contextTypes = {
  t: PropTypes.func.isRequired
};

export default UploaderBnx;
