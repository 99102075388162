import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';
import { required } from 'apex-web/lib/helpers/formValidations';

import './SendReceiveTwoFactorAuthModal.css';

var baseClasses = getBEMClasses('send-receive-two-factor-auth-modal');

var SendReceiveTwoFactorAuthForm = function SendReceiveTwoFactorAuthForm(
  props,
  context
) {
  var handleSubmit = props.handleSubmit,
    error = props.error;

  return React.createElement(
    'form',
    { onSubmit: handleSubmit, className: baseClasses() },
    React.createElement(
      'div',
      { className: baseClasses('text-item') },
      context.t('Enter the token from your Authenticator app.')
    ),
    React.createElement(APAutoTabInput, {
      name: 'code',
      type: 'input',
      numberOfInputs: 6,
      validate: [required]
    }),
    React.createElement(
      'div',
      { className: baseClasses('footer') },
      React.createElement(
        'div',
        { className: error && baseClasses('text-item__failed') },
        error && context.t(error)
      )
    )
  );
};

SendReceiveTwoFactorAuthForm.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

SendReceiveTwoFactorAuthForm.propTypes = {
  handleSubmit: PropTypes.func
};

SendReceiveTwoFactorAuthForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveTwoFactorAuthForm;
