import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import empty from 'is-empty';
import { Tabs, Tab, Alert } from 'react-bootstrap';
import isEmpty from 'is-empty';
import { connect } from 'react-redux';

let limits = [
  {
    symbol: 'USD',
    minimum: 10
  },
  {
    symbol: 'PEN',
    minimum: 30
  }
];

const getMinimunPrices = symbol => {
  return limits.find(s => s.symbol === symbol);
};

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
      typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var FiatDepositDetailsComponent = (function(_React$Component) {
  _inherits(FiatDepositDetailsComponent, _React$Component);

  function FiatDepositDetailsComponent() {
    _classCallCheck(this, FiatDepositDetailsComponent);

    return _possibleConstructorReturn(
      this,
      (
        FiatDepositDetailsComponent.__proto__ ||
        Object.getPrototypeOf(FiatDepositDetailsComponent)
      ).apply(this, arguments)
    );
  }
  _createClass(FiatDepositDetailsComponent, [
    {
      key: 'render',
      value: function render() {
        const bankDetails = this.props.bankDetails;

        // cuentas bancarias Banexcoin
        let status = false;
        let listBankDetails = [];
        let listBankInstructions = [];
        if (!empty(bankDetails)) {
          status = true
          if (!empty(bankDetails.accountDetails)) {
            listBankDetails = bankDetails.accountDetails.map(({ title, value }, key) => {
              return (
                <React.Fragment key={key}>
                  <li className={'title_bank_name'}>
                    {title}
                  </li>
                  <li className={'children_bank_name'}>
                    <b>{value}</b>
                  </li>
                </React.Fragment>
              )
            })
          }
          if (!empty(bankDetails.accountRulesInstructions)) {
            listBankInstructions = bankDetails.accountRulesInstructions.map(({ value_type, value }, key) => {
              let instruction;
              if (value_type === 'title' || value_type === 'subtitle') {
                instruction = <li className={`title_rules_for_receiving_funds_in_this_account ${value_type === 'subtitle' ? 'sub_Tit' : ''}`} key={key}>{value}</li>
              }
              if (value_type === 'listitem') {
                instruction = <li className='children_list' key={key}><b className='limit-details-title'>{value}</b></li>
              }
              if (value_type === 'paragraph') {
                instruction = <p className='title_bank_name limit-details-title' key={key}>{value}</p>
              }
              if (value_type === 'link') {
                instruction = <a className='link-color-v2' href={value} target="_blank" key={key}>{value}</a>
              }
              return instruction;
            })
          }
        }
        if(this.props.isCoink) return <></>
        
        return React.createElement(
          'section',
          { className: 'container' },
          <React.Fragment>
            <Tabs id="controlled-tab-example" className="tab-v2bnx">
              <Tab eventKey="details" title={this.context.t('Details')}>
                {status ? (
                  empty(listBankDetails) ?
                    <Alert key={'no-data'} variant={'secondary'}>
                      {this.context.t('No details')}
                    </Alert> :
                    <ul className="v2bnx details_bank">
                      {listBankDetails}
                    </ul>
                ) :
                  <Alert key={'no-data'} variant={'secondary'}>
                    {this.context.t('Select one of our accounts where you will make your deposit')}
                  </Alert>}
              </Tab>
              <Tab eventKey="instructions" title={this.context.t('Instructions')}>
                {status ? (
                  empty(listBankInstructions) ?
                    <Alert key={'no-data'} variant={'secondary'}>
                      {this.context.t('No instructions')}
                    </Alert> :
                    <ul className="v2bnx details_bank">
                      {listBankInstructions}
                    </ul>
                ) :
                  <Alert key={'no-data'} variant={'secondary'}>
                    {this.context.t('Select one of our accounts where you will make your deposit')}
                  </Alert>}
              </Tab>
            </Tabs>
          </React.Fragment>
        );
      }
    }
  ]);

  return FiatDepositDetailsComponent;
})(React.Component);

FiatDepositDetailsComponent.propTypes = {
  info: PropTypes.object
};

FiatDepositDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isCoink: !isEmpty(state.user) && !isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false
  }
}

export default connect(mapStateToProps)(FiatDepositDetailsComponent);
