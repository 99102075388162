import React from "react";
import PropTypes from "prop-types";
import SuccessIcon from "../../../images/successIcon.png";
import ErrorIcon from "../../../images/errorIcon.png";
import PendingIcon from "../../../images/pendingIcon.png";
import InfoIcon from "../../../images/infoIcon.png";
import SupportIcon from "../../../images/support.png";
import CardStatus from "../../../images/card-status.svg";
import Warning from "../../../images/warning.svg";
import isEmpty from "is-empty";
import arrow from "../../../images/icons-v2/ArrowRight2.svg";
import "./card-gradient.css";

const CardGradient = (props, context) => {
  const {
    type = "",
    title = "",
    message = "",
    children,
    actions,
    customClassName = "",
    backAction,
  } = props;
  const imageType = {
    success: SuccessIcon,
    pending: PendingIcon,
    error: ErrorIcon,
    info: InfoIcon,
    support: SupportIcon,
    card: CardStatus,
    warning: Warning
  };
  const styleType = type.toUpperCase();
  const img = imageType[type.toLowerCase()];
  return (
    <div
      className={`card-gradient type-${styleType} ${
        !isEmpty(children) ? "child" : ""
      } ${
        !isEmpty(type) ? "with-type" : ""
      } ${"with-bottom"} ${customClassName}`}
    >
      {backAction && (
        <img
          width={20}
          src={arrow}
          alt="arrow icon"
          style={{ transform: "rotate(-180deg)" }}
          onClick={backAction}
          className="back-action"
        />
      )}
      {!isEmpty(type) && (
        <div className="content-icon-status">
          <div className={`space-with-icon second-type-${styleType}`}>
            <img src={img} width={type === "support" ? 105 : 35} />
          </div>
        </div>
      )}
      {!isEmpty(title) && (
        <h2
          className="title-info-detail"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      {!isEmpty(message) && (
        <p
          className={`message ${isEmpty(title) ? "white-text" : ""}`}
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      )}
      {children && children}
      {!isEmpty(actions) && (
        <div className="card-gradient-actions">
          {actions.map((el) => (
            <button
              key={el.label}
              className={el.className || ""}
              onClick={async function () {
                el.action && (await el.action());
              }}
              {...el.otherAttributes}
            >
              {el.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

CardGradient.propTypes = {
  type: PropTypes.oneOf([
    "success",
    "pending",
    "error",
    "info",
    "support",
    "card",
    "warning"
  ]),
  title: PropTypes.string,
  message: PropTypes.string,
  customClassName: PropTypes.string,
  children: PropTypes.node,
  backAction: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    })
  ),
};

CardGradient.contextTypes = {
  t: PropTypes.func,
};

export default CardGradient;
