import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import halfCard from '../../../../images/half-card.png'
import CardGradient from '../../components/CardGradient'
import './request-status.css'
function RequestStatusPage(props, context) {
  const ht = useHistory();
  return (
    <div className='request-status-page'>
      <CardGradient customClassName="request-status-max-w" type="card" actions={[
        {
          label: context.t("Got it"),
          action: () => ht.push("/dashboard")
        }
      ]}>
        <div className='content'>
          <img src={halfCard} alt="half card" loading='lazy' width={200} />
          <div style={{ width:"340px" }} className='line-gradient'></div>
          <h4>{context.t("Request in process")}</h4>
          <p>{context.t("Your request is in the validation process, once the process is finished, we will send you an email.")}</p>
        </div>
      </CardGradient>
    </div>
  )
}

RequestStatusPage.contextTypes = {
  t: PropTypes.func.isRequired,
}
export default RequestStatusPage