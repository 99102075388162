//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevelBasic = async (userId, UserAccountId) => {
  let path = `/api/level-basic/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLevelCorporate = async (userId, UserAccountId) => {
  let path = `/api/level-corporate/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/master-level';
  let model = new FormData();
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('next_level', '3');
  model.append('id', data.id);
  model.append('banexcoin_user_account_id', data.banexcoin_user_account_id);
  model.append('master_worker_type', data.master_worker_type);
  model.append('master_origin_type', data.master_origin_type);
  model.append('master_others', data.master_others);
  model.append('master_origin_document', data.master_origin_document);
  model.append('master_affidavit_document', data.master_affidavit_document);
  model.append('master_origin_founds', data.master_origin_founds);
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/resend-master-level';
  let model = new FormData();
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('next_level', '3');
  model.append('id', data.id);
  model.append('banexcoin_user_account_id', data.banexcoin_user_account_id);
  model.append('master_worker_type', data.master_worker_type);
  model.append('master_origin_type', data.master_origin_type);
  model.append('master_others', data.master_others);
  model.append('master_origin_document', data.master_origin_document);
  model.append('master_affidavit_document', data.master_affidavit_document);
  model.append('master_origin_founds', data.master_origin_founds);
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
