import { connect } from 'react-redux';
import get from 'lodash/get';
import InstrumentTableComponent from './InstrumentTableComponent';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

const mapStateToProps = function (state) {
  const selectedInstrument = selectedInstrumentSelector(state);
  const { level1 } = state.apexCore || {};
  const selectedInstrumentId = get(selectedInstrument, 'InstrumentId', '');

  return {
    level1: level1 ? level1[selectedInstrumentId]: undefined,
    selectedInstrument
  };
};

export default connect(mapStateToProps, null)(InstrumentTableComponent);