import HandlerBackend from "../../helpers/HandlerBackend";

class DepositAlertsServices {
  static limitValidation = async ({
    userInfo,
    accountLevel,
    amount,
    currency
  }) => {
    const requestConfig = {
      userId: userInfo.UserId,
      method: "POST",
      path: "/api/v1/levels_limit/alert",
      data: {
        account_id: userInfo.AccountId,
        account_level: accountLevel,
        amount,
        currency
      },
    };
    return await HandlerBackend.sendRequest(requestConfig);
  };
  static createLog = async ({
    userInfo,
    accountLevel
    }) => {
    const requestConfig = {
      userId: userInfo.UserId,
      method: "POST",
      path: "/api/v1/levels_limit/month_log",
      data: {
        user_id:userInfo.UserId,
        user_name:userInfo.UserName,
        user_account_id: userInfo.AccountId,
        user_level: accountLevel,
        person_type: (accountLevel === 17 || accountLevel === 18) ? "corporative" : "natural",
        aml_alert_code: "" ,
        event_type: "DEPOSITOS ACUMULADOS",
        alert_status: "pending"
    },
    };
    return await HandlerBackend.sendRequest(requestConfig);
  };
  static getDepositsByTransactionNumber = async (userInfo, trsnsactionNumber) => {
    const path = `/api/v1/levels_limit/deposits/transaction/${userInfo.AccountId}/${trsnsactionNumber}`
    return await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      methods:"GET",
      path
    });
  }
}

export default DepositAlertsServices;
