import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AddressForm,
  ConfirmAddressModal,
  DeliveryPhysicalCardResult,
  OtherLocationCompleteForm,
  OtherLocationFirst,
  OtherLocationIncompleteForm,
  ProviderAvailableLocationForProvince,
  RechargeModal,
} from "./Steps";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { deliveryTypes } from "../../constants/deliveryTypes";
import BanexcardController from "../../controllers/BanexcardController";
import "./delivery-physical-card.css";
import { sanitizeValue } from "../../../utils";
import { Redirect } from "react-router-dom";
import { Loader } from "../../components";

const DeliveryPhysicalCardPage = (props, context) => {
  const { levelBasic = {}, hasPhysicalBanexcard = false, userInfo, hasBanexCard, loadingBanexCard, hasPhysicalBanexcardRequest } = props;
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState("");
  const [selectedRoad, setSelectedRoad] = useState({});
  const [nextView, setNextView] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState([]);
  const [originForm, setOriginForm] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [responseType, setResponseType] = useState("");

  const handleGetDeliveryAreas = async () => {
    const response = await BanexcardController.getPhysicalCardLocations({
      userInfo,
    });
    setDeliveryAreas(response);
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    handleGetDeliveryAreas();
  }, [userInfo]);

  const handleSteps = {
    next: () => setStep((step) => step + 1),
    previous: () => setStep((step) => step - 1),
    custom: (step) => setStep(step),
  };

  const handleRequestDelivery = async () => {
    let finalAddress = address
    if(selectedRoad === deliveryTypes.WITH_REGISTER_LOCATION){
      finalAddress = {
        address,
        ...levelBasic
      }
    }
    const response = await BanexcardController.requestPhysicalCard({
      data: finalAddress,
      userInfo,
      deliveryAreas
    })
    if(response){
      setResponseType("success")
    }else{
      setResponseType("error")
    }
    handleSteps.next()
  };

  useEffect(() => {
    if (isEmpty(levelBasic)) return;
    const { addressMain, town, district, city } = levelBasic;
    const registeredAddress = `${sanitizeValue(addressMain)}, ${sanitizeValue(town)}, ${sanitizeValue(
      district
    )}, ${sanitizeValue(city)}`
    setAddress(registeredAddress);
    setRegisteredAddress(registeredAddress)
  }, [levelBasic]);

  const isMetropolitanLima =
    levelBasic.city === "Lima" && levelBasic.district === "Lima";

  const componentsSteps = {
    [deliveryTypes.WITH_REGISTER_LOCATION]: {
      1: (
        <ProviderAvailableLocationForProvince
          handleSteps={{
            ...handleSteps,
            previous: () => setSelectedRoad(""),
          }}
          address={registeredAddress}
          setCustomAddress={setAddress}
          deliveryAreas={deliveryAreas}
          levelBasic={levelBasic}
        />
      ),
      2: (
        <ConfirmAddressModal
          handleSteps={handleSteps}
          address={address}
          handleRequestDelivery={handleRequestDelivery}
          levelBasic={levelBasic}
        />
      ),
      3: (
        <DeliveryPhysicalCardResult
          handleSteps={handleSteps}
          address={address}
          type={responseType}
        />
      ),
    },
    [deliveryTypes.WITHOUT_REGISTER_LOCATION]: {
      1: (
        <OtherLocationFirst
          handleSteps={{
            ...handleSteps,
            previous: () => setSelectedRoad(""),
          }}
        />
      ),
      2: (
        <OtherLocationCompleteForm
          handleSteps={handleSteps}
          setCustomAddress={setAddress}
          setOriginForm={setOriginForm}
        />
      ),
      3: (
        <OtherLocationIncompleteForm
          handleSteps={handleSteps}
          setCustomAddress={setAddress}
          deliveryAreas={deliveryAreas}
          setOriginForm={setOriginForm}
        />
      ),
      4: (
        <ConfirmAddressModal
          handleSteps={handleSteps}
          address={address}
          originForm={originForm}
          handleRequestDelivery={handleRequestDelivery}
        />
      ),
      5: (
        <DeliveryPhysicalCardResult
          handleSteps={handleSteps}
          address={address}
          type={responseType}
        />
      ),
    },
  };

  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard || hasPhysicalBanexcardRequest) return <Redirect to="/cards" />

  if (!hasPhysicalBanexcard && !nextView)
    return (
      <div className="delivery-physical-card-page">
        <RechargeModal setNextView={setNextView} />
      </div>
    );

  return (
    <div className="delivery-physical-card-page">
      {!isEmpty(selectedRoad) ? (
        componentsSteps[selectedRoad][step]
      ) : (
        <AddressForm
          handleSteps={{
            ...handleSteps,
            previous: () => setNextView(false),
          }}
          address={registeredAddress}
          isMetropolitanLima={isMetropolitanLima}
          setSelectedRoad={setSelectedRoad}
        />
      )}
    </div>
  );
};

DeliveryPhysicalCardPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    levelBasic: state.completedUserInfo.level_basic,
    userInfo,
    loadingBanexCard,
    hasPhysicalBanexcardRequest: !isEmpty(state.completedUserInfo.level_basic) && state.completedUserInfo.level_basic.hasOwnProperty("physicalCardRequestsByLevelsBasicId")  ? !!state.completedUserInfo.level_basic.physicalCardRequestsByLevelsBasicId.nodes[0].id  : false
  };
};

export default connect(mapStateToProps)(DeliveryPhysicalCardPage);
