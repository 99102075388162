import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import TrustListForm from '../components/TrustList/TrustListForm';
import TrustListAll from '../components/TrustList/TrustListAll';
import { getTrustList } from '../components/TrustList/TrustListHooks';
import { Tabs, Tab, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../components/Loading';
import './TrustListPage.css';

const trustListPageClasses = getBEMClasses('trust-list-page');

const TrustListPage = (props, context) => {
  const { userInfo } = props;

  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(0);
  const [trustList, setTrustList] = useState({});

  const getTrustListAccordingOperation = async () => {
    setLoading(1);
    try {
      const result = await getTrustList(userInfo.UserId);
      setTrustList(result.data.trustLists);
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    setLoading(0);
  }

  useEffect(() => {
    if (empty(trustList)) {
      getTrustListAccordingOperation();
    }
  }, []);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={trustListPageClasses()}>
        <h1 className={trustListPageClasses('title')}>{ context.t('My trust list') }</h1>
        <div className="box-info-header">
          <div className="box-info-header-description">{ context.t('Register your external addresses and emails as a trusted list and you will have them ready for future use. If you need to transfer cryptocurrencies to other Banexcoin clients, you can do it directly and free of charge through the Banexcoin transfer option.') }</div>
          <div className="box-info-header-description">{ context.t('These wallets address are used when you want to deposit your cryptocurrencies funds to an external wallet from Banexcoin.') }</div>
        </div>
        <Card>
          <Card.Body>
            <div className="trust-list-form">
              <Card>
                <Card.Body>
                  <Tabs defaultActiveKey="" id="noanim-tab-example">
                    <Tab
                      eventKey="add"
                      title={context.t('+ Add new address or email')}>
                      <TrustListForm
                        attributeUpdateTrusList={getTrustListAccordingOperation}
                      />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </div>
            <div className="all-trust-list">
              <TrustListAll
                trustList={trustList}
                attributeUpdateTrusList={getTrustListAccordingOperation}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

TrustListPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(TrustListPage);
