import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';

const FormSingleCheckbox = (props, context) => {
  const {
    label,
    name,
    id,
    onChange,
    onClick,
    errors,
    errorMessage,
    checked,
    classNameContainer
  } = props;
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      name,
      id: idTwo,
      label: label,
      onChange,
      onClick,
      checked: checked
    };
    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field' +
          ((!empty(errors) && !empty(errors[name])) || !empty(errorMessage)
            ? ' invalid'
            : ' valid') +
          ' ' +
          classNameContainer
        }>
        <Form.Check type="checkbox" className="v2-field" {...propInput} />
        {!empty(errors) && !empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : !empty(errorMessage) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errorMessage)}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
};

FormSingleCheckbox.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormSingleCheckbox;
