import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import empty from 'is-empty';
import NumberFormat from 'react-number-format';
import './formStyles.css';
import Tooltip from '../../../images/tooltip.svg';

const FormCurrencyField = (props, context) => {
  const {
    label,
    labelInside,
    required = true,
    type = 'text',
    placeholder,
    name,
    step,
    id,
    onChange,
    errors,
    errorMessage,
    disabled,
    value,
    showAsterisk = true,
    showInformativeItem,
    showError = false,
    maxLength = 20,
    showLabelError = true
  } = props;
  try {
    const idTwo = !empty(id) ? id : !empty(name) ? name : '';
    const propInput = {
      placeholder: !empty(placeholder)
        ? context.t(placeholder)
        : !empty(label)
          ? context.t(label)
          : '',
      value,
      onValueChange: (values, sourceInfo) => {
        const { formattedValue, value, floatValue } = values;
        onChange(name, floatValue);
      },
      disabled,
      type: 'decimal'
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field nooneline' +
          ((!empty(errors) && !empty(errors[name])) ||
          !empty(errorMessage) ||
          showError
            ? ' invalid'
            : ' valid') +
          (!empty(labelInside) && !empty(value) ? ' inside-container' : '')
        }>
        {!empty(label) ? (
          <Form.Label className="labelContCoink">
            {context.t(label)}{' '}
            {required && showAsterisk ? <b className="important">*</b> : ''}
            {showInformativeItem && (
              <img
                src={Tooltip}
                alt="img_tooltio"
                onClick={showInformativeItem}
              />
            )}
          </Form.Label>
        ) : (
          ''
        )}
        {!empty(labelInside) ? (
          <div className="label-inside">{context.t(labelInside)}</div>
        ) : (
          ''
        )}

        <NumberFormat
          type={'decimal'}
          inputMode="decimal"
          customInput={Form.Control}
          {...propInput}
          isNumericString={true}
          maxLength={maxLength}
          thousandSeparator={props.thousandSeparator || ''}
          decimalSeparator={props.decimalSeparator || '.'}
          decimalScale={props.decimalScale || 3}
          allowNegative={
            !empty(props.allowNegative) ? props.allowNegative : true
          }
          prefix={props.prefix || ''}
        />
        {!empty(errors) && !empty(errors[name]) && showLabelError ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : !empty(errorMessage) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errorMessage)}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormCurrencyField', error);
    return <React.Fragment />;
  }
};

FormCurrencyField.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormCurrencyField;

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
}
