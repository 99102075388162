import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import RecentActivityHeaderWithFilter from './RecentActivityHeaderWithFilter';
import RecentActivityTableWithFilter from './RecentActivityTableWithFilter';

import '../RecentActivity/ActivityComponents.css';

var baseClasses = getBEMClasses('activity');

var RecentActivityWithFilterComponent = function RecentActivityWithFilterComponent(
  props,
  context
) {
  var width = props.width,
    themeModifier = props.themeModifier;

  return React.createElement(
    'div',
    { className: baseClasses() },
    React.createElement(RecentActivityTableWithFilter, {
      themeModifier: themeModifier,
      width: width,
      context: context
    })
  );
};

RecentActivityWithFilterComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecentActivityWithFilterComponent;
