import React from 'react';
import PropTypes from 'prop-types';
import registerBackground from './../../../images/registerImage.png';
import LayoutAuth from './LayoutAuth/LayoutAuth';
import registerPreview from './../../../images/register_video_preview.png';
import playIcon from './../../../images/play.svg';
import { useParams } from 'react-router-dom';
import isEmpty from 'is-empty';
import { useFreshChat } from '../../../hooks/useFreshChat';

const videos = {
  "natural":"https://www.youtube.com/embed/0T8yj_RdV_4?autoplay=1&rel=0",
  "corporative":"",
}
const BoxContent = (props, context) => {
  const params = useParams();
  const url = 'https://www.banexcoin.com/';
  const [videoIsActive , setVideoIsActive] = React.useState(false)
  const _ = useFreshChat()

  const handlePlayVideo = () => {
    setVideoIsActive(true)
  }
  return (
    <React.Fragment>
      <LayoutAuth
        imageBackground={registerBackground}
        url={url}
        twofa={false}
        leftAuxChildren={
          <div className="register-preview-container">
            {!isEmpty(videos[params.type]) && (
              <div className="register-preview-image" onClick={handlePlayVideo}>
                {videoIsActive ? (
                  <iframe
                    width="1290"
                    height="715"
                    src={videos[params.type]}
                    title="¿Cómo registrarte en Banexcoin?"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                ) : (
                  <div>
                    <img
                      className="preview"
                      src={registerPreview}
                      alt="register preview image"
                    />
                    <img src={playIcon} alt="play icon" width={60} />
                  </div>
                )}
              </div>
            )}
          </div>
        }
      >
        {props.rightChildren}
      </LayoutAuth>
      <div className="container display-flex">{props.bottomChildren}</div>
    </React.Fragment>
  );
};

BoxContent.contextTypes = {
  t: PropTypes.func
};

export default BoxContent;
