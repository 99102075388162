import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/ProfileSettingsCard';
import PasswordSettingsCardComponent from 'apex-web/lib/components/Settings/ProfileAndSecurity/PasswordSettingsCard';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './UserSettingsPage.css';
import TwoFactorAuthSettingsCardComponent from '../components/TwoFactorAuthSettingsCard/TwoFactorAuthSettingsCardComponent';

const userSettingsPageClasses = getBEMClasses('user-settings-page');
const mapStateToProps = ({
  apexCore: {},
  user: {
    userInfo: { Use2FA }
  }
}) => {
  return { Use2FA };
};

const UserSettingsPage = ({ Use2FA }, context) => {

  return (
    <div className={userSettingsPageClasses('maincontainer')}>
      <div className={userSettingsPageClasses('container')}>
        <div className={userSettingsPageClasses('page-header')}>
          <h1 className={userSettingsPageClasses('title')}>
            {context.t('Update Profile')}
          </h1>
          <h2 className={userSettingsPageClasses('sub-title')}>
            {context.t(' Here you can make general settings such as...')}
          </h2>
        </div>
        <div className={userSettingsPageClasses('row')}>
          <ProfileSettingsCardComponent />
        </div>
        <div className={userSettingsPageClasses('row', 'sm') + ' samerow'}>
          <PasswordSettingsCardComponent />
        </div>
      </div>
      <div className={userSettingsPageClasses('container2')}>
        <div className={!Use2FA ? ' shadowling' : ''} id={'2FABox'}>
          <TwoFactorAuthSettingsCardComponent />
        </div>
      </div>
    </div>
  )
}

UserSettingsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(UserSettingsPage);
