import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { FormatDecimalCoinkWithSimbols, ticksToDateString } from '../../pages/utils';
import { getWithdrawals } from './DepositWithdrawalsService';
import moment from 'moment';
import ModalDepositWithdraw from './ModalDepositWithdraw/ModalDepositWithdraw';
import { FormDate, FormButtonV2 } from '../../components/common/BnxFormsComponents';
import './withdrawals.css'
import Pagination from 'react-js-pagination';

const Withdrawals = ({ products, userInfo, selectedAccountId, manifest }, context) => {
    const [isLoading, setLoading] = useState(true);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [details, setDetails] = useState({});
    const [transactionsTotal, setTransactionsTotal] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [transactionsFilter, setTransactionsFilter] = useState({
        initDate: Number(moment('2019-01-01')),
        endDate: Number(moment()),
        accountId: 45,
        limit: 10,
        page: 1
    });
    const [errors, setErrors] = useState({})

    const fillTransactions = async (optionalFilter) => {
        setLoading(true);
        try {
            const filter = optionalFilter || transactionsFilter;
            const response = await getWithdrawals(userInfo.UserId, {...filter, accountId: Number(selectedAccountId)});
            const { data } = response;
            if (!empty(data)) {
                setTransactionsTotal(Number(data.total || 0))
                setTransactions(data.data || []);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const findProduct = (productSymbol) => {
        return products.map(product => {
            return {
                ...product,
                iconFileName: manifest[product.Product] ? manifest[product.Product].iconFileName : ''
            }
        }).find((product) => product.Product === productSymbol) || {};
    }

    const mostrarDetalle = (ticketDetails) => {
        setDetails(ticketDetails);
        setShowDetailsModal(true);
    };

    const changePage = page => {
        const newFilter = { ...transactionsFilter, page };
        setTransactionsFilter(newFilter);
        fillTransactions(newFilter);
    };

    useEffect(() => {
        if (!empty(userInfo) && !empty(products)) {
            fillTransactions();
        }
    }, [userInfo, products, selectedAccountId]);

    if (empty(selectedAccountId)) {
        return (<></>);
    }
    
    return (
        <>
            <div className='table-container'>
                <div className="divTableRow header">
                    <div className="divTableCell divTableFilter">
                        <FormDate
                            required
                            type="text"
                            name="initDate"
                            labelInside={context.t("Start date:")}
                            placeholder={context.t("Start date:")}
                            onChange={(name, value) => {
                                console.log(name, value)
                                const startDateEpoch = Number(moment(value));
                                setTransactionsFilter({ ...transactionsFilter, initDate: startDateEpoch })
                            }}
                            value={moment(transactionsFilter.initDate).format('YYYY-MM-DD')}
                            errors={errors}
                        />
                        <FormDate
                            required
                            type="text"
                            name="endDate"
                            labelInside={context.t("End date:")}
                            placeholder={context.t("End date:")}
                            onChange={(name, value) => {
                                const startDateEpoch = Number(moment(value));
                                setTransactionsFilter({ ...transactionsFilter, endDate: startDateEpoch })
                            }}
                            value={moment(transactionsFilter.endDate).format('YYYY-MM-DD')}
                            errors={errors}
                        />
                        <FormButtonV2
                            type="button"
                            variant={'light'}
                            onClick={() => {
                                fillTransactions()
                            }}
                            className="button-succes-delete"
                            disabled={isLoading}>
                            {context.t('Search')}
                        </FormButtonV2>
                    </div>
                </div>
                <div className={`divTableloading-bg ${isLoading ? 'show' : ''}`}></div>
                <div className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow header">
                            <div className="divTableCell text" style={{ width: '30px' }}></div>
                            <div className="divTableCell text"><p>{context.t("Ticket Number")}</p></div>
                            <div className="divTableCell text"><p>{context.t("Amount")}</p></div>
                            <div className="divTableCell text"><p>{context.t("Currency")}</p></div>
                            <div className="divTableCell text"><p>{context.t("Status")}</p></div>
                            <div className="divTableCell text"><p>{context.t("Currency type")}</p></div>
                            <div className="divTableCell text"><p>{context.t("Date")}</p></div>
                        </div>
                        {transactions.map((transaction) => {
                            const product = findProduct(transaction.symbol);
                            return <div className="divTableRow selector-detail" key={`w${transaction.withdrawTicketId}`} onClick={() => mostrarDetalle({ ...transaction, product })}>
                                <div className="divTableCell" style={{ width: '30px' }}>
                                    <img
                                        alt="product icon"
                                        height="24px"
                                        src={'/local/product-icons/' + (product.iconFileName || 'default-48px.svg')}
                                    />
                                </div>
                                <div className="divTableCell text"><span>{transaction.withdrawTicketId}</span></div>
                                <div className="divTableCell text"><span>{FormatDecimalCoinkWithSimbols(formatNumberToLocale(transaction.amount, product.DecimalPlaces || 2), product.ProductSymbol)}</span></div>
                                <div className="divTableCell text"><span>{context.t(transaction.assetName)}</span></div>
                                <div className="divTableCell text"><span>{context.t(transaction.statusName)}</span></div>
                                <div className="divTableCell text"><span>{context.t(product.ProductType)}</span></div>
                                <div className="divTableCell text"><span>{ticksToDateString(transaction.createdOnTicks)}</span></div>
                            </div>
                        })}
                    </div>
                </div>
                <Pagination
                    activePage={Number(transactionsFilter.page)}
                    itemsCountPerPage={Number(transactionsFilter.limit)}
                    totalItemsCount={transactionsTotal}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                    innerClass="pagination v2-list-pagination divTablePagination"
                    itemClass="page-item"
                    linkClass="page-link"
                />
            </div>
            <ModalDepositWithdraw show={showDetailsModal} setShow={setShowDetailsModal} details={details} actionName={'Withdrawal'} />
        </>
    )
};

const mapStateToProps = (state) => {
    const {
        user: { selectedAccountId },
    } = state;
    return { selectedAccountId };
};

Withdrawals.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(Withdrawals);
