function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

export var getMandatoryFields = function getMandatoryFields() {
  return [
    {
      label: 'First Name', // this.context.t('First Name')
      name: 'firstName',
      type: 'text',
      validators: ['required']
    },
    {
      label: 'Middle Name', // this.context.t('Middle Name')
      name: 'middleName',
      type: 'text',
      validators: []
    },
    {
      label: 'Last Name', // this.context.t('Last Name')
      name: 'lastName',
      type: 'text',
      validators: ['required']
    },
    {
      label: 'Date of Birth', // this.context.t('Date of Birth')
      name: 'dob',
      type: 'date',
      validators: ['required', 'isDate']
    },
    {
      label: 'Billing Phone', // this.context.t('Billing Phone')
      name: 'phone',
      type: 'phone',
      validators: ['required']
    },
    {
      label: 'Billing Country', // this.context.t('Billing Country')
      name: 'billingCountry',
      type: 'country',
      validators: ['required']
    },
    {
      label: 'Billing Street Address', // this.context.t('Billing Street Address')
      name: 'billingStreetAddress',
      type: 'text',
      info: '(100 Washington Way)', // this.context.t('(100 Washington Way)')
      validators: ['required']
    },
    {
      label: 'Billing State', // this.context.t('Billing State')
      name: 'state',
      type: 'text',
      info: 'Abbreviation only',
      validators: ['required']
    },
    {
      label: 'Billing City', // this.context.t('Billing City')
      name: 'billingCity',
      type: 'text',
      validators: ['required']
    },
    {
      label: 'Billing Zip', // this.context.t('Billing Zip')
      name: 'billingZip',
      type: 'text',
      validators: ['required', 'alphanumeric']
    },
    {
      label: 'Type of ID you want to upload', // this.context.t('Type of ID you want to upload')
      name: 'DocumentType',
      type: 'select',
      options: {
        PP: 'Passport', // this.context.t('Passport'),
        DL: 'Drivers License', // this.context.t('Drivers License'),
        ID: 'Identity Card', // this.context.t('Identity Card'),
        RP: 'Residence Permit' // this.context.t('Residence Permit')
      },
      validators: ['required']
    },
    {
      label: 'Document Country', // this.context.t('Document Country')
      name: 'DocumentCountry',
      type: 'country',
      validators: ['required']
    },
    {
      label: 'Document State', // this.context.t('Document State')
      name: 'DocumentState',
      type: 'text',
      validators: ['required']
    },
    {
      placeholder: 'Upload an image of your selected Document', // this.context.t('Upload an image of your selected Document')
      name: 'scandata',
      type: 'image',
      alt: 'Document', // this.context.t('Document')
      maxSize: 3.8, // Max Size supported by IM
      label: 'Document', // this.context.t('Document')
      validators: ['required']
    },
    {
      placeholder: 'Image of the back of your Document', // this.context.t('Image of the back of your Drivers License')
      name: 'backsideimagedata',
      type: 'image',
      info: "Mandatory for driver's License",
      alt: 'Documents back', // this.context.t('Documents back')
      maxSize: 3.8, // Max Size supported by IM
      label: "Document's back", // this.context.t('Document\'s back')
      validators: ['required']
    },
    {
      placeholder: 'Upload selfie', // this.context.t('Upload selfie')
      name: 'faceimages',
      label: 'Selfie', // this.context.t('Selfie')
      type: 'image',
      alt: 'Selfie picture', // this.context.t('Selfie picture')
      maxSize: 3.8, // Max Size supported by IM,
      validators: [],
      info:
        'Optional - Uploading a selfie helps to verify your identity faster.' // this.context.t('Optional - Uplading a selfie helps to verify your identity faster.')
    }
  ];
};

var iterateOnLevel = function iterateOnLevel(level, callbackFn) {
  if (level.steps) {
    level.steps.forEach(function(step) {
      step.sections.forEach(callbackFn);
    });
  } else {
    level.sections.forEach(callbackFn);
  }
};

var getAllConfigFields = function getAllConfigFields(levels) {
  var configFields = [];

  levels.forEach(function(level) {
    return iterateOnLevel(level, function(section) {
      configFields = [].concat(
        _toConsumableArray(configFields),
        _toConsumableArray(section.form || [])
      );
    });
  });

  return configFields;
};

export var getMissingMandatoryFields = function getMissingMandatoryFields(
  levels
) {
  var mandatoryFields = getMandatoryFields();
  var configFields = getAllConfigFields(levels);

  return mandatoryFields.filter(function(mandatoryField) {
    return !configFields.find(function(field) {
      return field.name === mandatoryField.name;
    });
  });
};

export var updateLevelFields = function updateLevelFields(level) {
  var mandatoryFields = getMandatoryFields();

  iterateOnLevel(level, function(section) {
    section.form.forEach(function(field) {
      var mandatoryField = mandatoryFields.find(function(f) {
        return f.name === field.name;
      });

      if (mandatoryField) {
        field.label = field.label || mandatoryField.label;
        field.info = field.info || mandatoryField.info;
        field.type = mandatoryField.type;
        field.validators = field.validators
          ? [].concat(
              _toConsumableArray(
                new Set(
                  [].concat(
                    _toConsumableArray(field.validators),
                    _toConsumableArray(mandatoryField.validators)
                  )
                )
              )
            )
          : mandatoryField.validators;

        if (isUploadField(mandatoryField.type)) {
          field.placeholder = field.placeholder || mandatoryField.placeholder;
          field.maxSize = mandatoryField.maxSize;
        }

        if (['select', 'country'].includes(field.type))
          field.options = field.options || mandatoryField.options || [];
      }
    });
  });
};

var isUploadField = function isUploadField(field) {
  return ['image', 'document', 'upload'].includes(field.type);
};

export var getAdditionalValidationFields = function getAdditionalValidationFields(
  level
) {
  var uploadFields = [];

  iterateOnLevel(level, function(section) {
    section.form.forEach(function(field) {
      if (isUploadField(field)) {
        uploadFields.push(field.name);
      }
    });
  });

  return uploadFields;
};
