import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  formatPercentageValue,
  formatNumberToLocale
} from '../../../../helpers/numberFormatter';
import * as level1DataProp from 'apex-web/lib/propTypes/level1Data';
import { getBEMClasses } from 'apex-web/lib//helpers/cssClassesHelper';
import { instrumentPropType } from 'apex-web/lib/propTypes/instrumentPropTypes';
import InstrumentTableItem from './InstrumentTableItem';
import INSTRUMENT_TABLE_ITEMS from 'apex-web/lib/components/InstrumentTable/InstrumentTableItems';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import upArrowLine from "../../../../images/up-arrow-line.svg";
import clock from "../../../../images/clock.svg";
import "../exchangeHeader.css"

const getProductSymbol = instrument => get(instrument, 'Product1Symbol', '');

const InstrumentTableComponent = (props, context) => {
  const { t } = context;
  const { level1, selectedInstrument, items, customClass } = props;

  const baseClasses = getBEMClasses(['instrument-table', customClass]);

  const productSymbol = get(selectedInstrument, 'Product1Symbol', '');
  const priceSymbol = get(selectedInstrument, 'Product2Symbol', '');

  const instrumentTableItems = items.map(item =>
    level1ItemsData[item.field](t, level1, selectedInstrument)
  );

  return (
    <div className={baseClasses()}>
      {customClass === 'market-overview-instrument' && (
        <div className={baseClasses('row')}>
          <span className={baseClasses('title')}>
            {t(`${productSymbol}${priceSymbol} Price Data:`)}
          </span>
        </div>
      )}
      {instrumentTableItems.map(item => (
        <InstrumentTableItem {...item} baseClasses={baseClasses}/>
      ))}
    </div>
  );
};

InstrumentTableComponent.propTypes = {
  level1: level1DataProp.type.isRequired,
  selectedInstrument: instrumentPropType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired
    })
  )
};

InstrumentTableComponent.defaultProps = {
  level1: level1DataProp.defaultProps,
  items: [
    { field: INSTRUMENT_TABLE_ITEMS.dailyPercentageChange },
    { field: INSTRUMENT_TABLE_ITEMS.dailyMax },
    { field: INSTRUMENT_TABLE_ITEMS.dailyMin },
    { field: INSTRUMENT_TABLE_ITEMS.dailyVolume }
  ]
};

InstrumentTableComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InstrumentTableComponent;

const level1ItemsData = {

  [INSTRUMENT_TABLE_ITEMS.dailyPercentageChange]: (
    t,
    { Rolling24HrPxChangePercent },
    instrument
    ) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyPercentageChange,
    value: formatPercentageValue(Rolling24HrPxChangePercent),
    label: t('24 Hour % Change'),
    classModifiers: [Rolling24HrPxChangePercent >= 0 ? '24h-positive' : '24h-negative'],
    icon: clock
  }),


  [INSTRUMENT_TABLE_ITEMS.dailyVolume]: (
    t,
    { Rolling24HrVolume },
    instrument
  ) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyVolume,
    value: `${formatNumberToLocale(
      Rolling24HrVolume,
      convertIncrementToIntDecimalPlaces(instrument.QuantityIncrement)
    )} ${getProductSymbol(instrument)}`,
    label: t('24 Hour Volume'),
    classModifiers: ['24h-volume']
  }),

  [INSTRUMENT_TABLE_ITEMS.dailyMin]: (t, { SessionLow }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyMin,
    value: formatNumberToLocale(
      SessionLow,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('24 Hour Low'),
    classModifiers: ['24h-min'],
    icon: upArrowLine
  }),

  [INSTRUMENT_TABLE_ITEMS.dailyMax]: (t, { SessionHigh }, instrument) => ({
    key: INSTRUMENT_TABLE_ITEMS.dailyMax,
    value: formatNumberToLocale(
      SessionHigh,
      convertIncrementToIntDecimalPlaces(instrument.PriceIncrement)
    ),
    label: t('24 Hour High'),
    icon: upArrowLine
  })
};
