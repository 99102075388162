import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import OrderEntryComponent from './OrderEntryComponent';
import VerificationRequiredContainer from '../VerificationRequired/VerificationRequiredContainer';
import { SIDE_PANES } from 'apex-web/lib/constants/sidePanesConstants';
import { openSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { marketPriceOfSelectedInstrumentSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import {
  buyValue,
  orderTypes
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import config from 'apex-web/lib/config';
import { getOrderFee, getOrderNet } from 'apex-web/lib/helpers/orderHelper.js';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { isMarginActiveSelector } from 'apex-web/lib/redux/selectors/marginSelectors';
import { marginProductSelector } from 'apex-web/lib/redux/selectors/marginProductSelectors';
import { positionSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import { MODAL_TYPES, openModal } from 'apex-web/lib/redux/actions/modalActions';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

const OrderEntryComponentForm = reduxForm({
  form: 'orderEntry',
  enableReinitialize: false,
  destroyOnUnmount: false,
  onSubmit: (payload, dispatch) => {
    dispatch(openModal(MODAL_TYPES.CONFIRM_ORDER_REPORT));
  }
})(OrderEntryComponent);

const mapStateToProps = state => {
  const { form, user, auth, position } = state;

  const isMarginActive = isMarginActiveSelector(state);
  const marginProducts = marginProductSelector(state);
  let instrument = {};
  if (isMarginActive && !state.margin.instrument.fetching) {
    instrument = state.margin.instrument;
  } else {
    instrument = state.apexCore.instrument;
  }

  const { fetching } = instrument;
  const selectedInstrument = selectedInstrumentSelector(state);
  const selectedInstrumentId = selectedInstrument
    ? selectedInstrument.InstrumentId
    : 1;
  const orderEntryForm = form.orderEntry || { values: {} };

  const { disableTrading } = kycVerificationLevelsSelector(state);

  const orderTotal = form.orderEntry
    ? orderTotalSelector(state, {
        form: form.orderEntry.values
      })
    : 0;

  return {
    isAuthenticated: auth.isAuthenticated,
    authorizedUserPermissions: user.permissions,
    orderEntryForm,
    marketPrice: marketPriceOfSelectedInstrumentSelector(state, {
      side: orderEntryForm.values.side
    }),
    orderTotal: `${selectedInstrument.Product2Symbol} ${formatNumberToLocale(
      orderTotal,
      convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)
    )}`,
    orderNet: getOrderNet(orderEntryForm, selectedInstrument, orderTotal),
    orderFee: getOrderFee(orderEntryForm, positionSelector(state)),
    initialValues: {
      side: orderEntryForm.values.side || buyValue,
      orderType:
        orderEntryForm.values.orderType || orderTypes.limit.displayName,
      quantity: '',
      limitPrice: '',
      stopPrice: '',
      fee: '0',
      totalAmount: '0',
      selectedInstrumentId,
      timeInForce: '1'
    },
    selectedInstrument,
    fetching,
    disableTrading,
    VerificationRequiredComponent: <VerificationRequiredContainer />,
    hideFees: !!config.global.hideFees,
    isMarginActive,
    marginProducts,
    positions: position.positions ? position.positions[state.user.selectedAccountId] : {},
    decimalPlaces: state.product.decimalPlaces,
    level1: state.level1
  };
};

const mapDispatchToProps = {
  openAdvancedOrderSidePane: () => openSidePane(SIDE_PANES.ADVANCED_ORDER),
  openReportBlockTradeSidePane: () => openSidePane(SIDE_PANES.REPORT_BLOCK_TRADE),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderEntryComponentForm);
