import React from 'react'
import './spinner.css'

export const Spinner = ({color=''}) => {
    return (
      <svg
        className={`ring ${color}`}
        viewBox="25 25 50 50"
        strokeWidth="5"
      >
        <circle cx="50" cy="50" r="20" />
      </svg>
    )
  }