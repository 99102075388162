import { generateKYC } from "../services/VerificationStartService";

class VerificationStartController {
    static generateKYC = async ({
        userInfo,
        setLoadingPage,
        setToken,
        lang,
        updateActions,
        ht,
        setErrorMessage,
        context,
        setOptions,
        hasKYC
    }) => {
        try {
            const response = await generateKYC(userInfo.UserId, userInfo.AccountId)
            const token = response.data.responseId
            setToken(token)
            const link = `${process.env.REACT_APP_KYC}/${token}?desktop=true&language=${lang}&type=banexCard`;
            const onClick = () => window.open(link, "_self", "noreferrer")
            updateActions(onClick)
        } catch (error) {
            if (!error.response || !error.response.data || !error.response.data.code) return;
            const code = error.response.data.code
            const message = error.response.data.message
            if (code === "CORE032") {
                const onClick = () => ht.push("/cards/increment-level")
                updateActions(onClick)
                setOptions(hasKYC)
            } else {
                const text = `<p class='error-message-kyc'>${context.t(message)}. <br>${context.t(
                    "If you think we made a mistake, please write to us at {email} or contact us at {web}. We are here to help you!",
                    {
                        email: '<a class="text-link" href="mailto:soporte@banexcoin.com">soporte@banexcoin.com</a>',
                        web: '<a class="text-link web" href="https://soporte.banexcoin.com">soporte.banexcoin.com</a>'
                    })}</p>`
                setErrorMessage(text)
            }
        } finally {
            setLoadingPage(false)
        }
    };
}

export default VerificationStartController