import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import ReportBlockTradeContainer from './ReportBlockTradeContainer';
import ConfirmBlockTradeModalContainer from '../ConfirmBlockTradeModal/ConfirmBlockTradeModalContainer';

const baseClasses = getBEMClasses('advanced-order-sidepane');

class ReportBlockTradeSidePane extends React.Component {
  componentDidMount() {
    this.props.onSidePaneOpen({
      title: this.context.t('Block Trade'),
      customClass: baseClasses(),
      classModifiers: 'with-tabs'
    });
  }
  render() {
    const {
      form,
      setBoughtReport,
      setSoldReport,
      clearReportBlockTrade
    } = this.props;

    const boughtTabModifier =
      ((form && form.values.side === buyValue) || !form) && 'buy-selected';

    const soldTabModifier =
      form && form.values.side === sellValue && 'sell-selected';

    const selectBoughtTab = () => {
      clearReportBlockTrade();
      setBoughtReport();
    };

    const selectSoldTab = () => {
      clearReportBlockTrade();
      setSoldReport();
    };

    return (
      <React.Fragment>
        <div className={baseClasses('tab-container')}>
          <div
            className={baseClasses('tab', boughtTabModifier)}
            onClick={selectBoughtTab}>
            {this.context.t('Buy')}
          </div>
          <div
            className={baseClasses('tab', soldTabModifier)}
            onClick={selectSoldTab}>
            {this.context.t('Sell')}
          </div>
        </div>
        <ReportBlockTradeContainer />
        <ConfirmBlockTradeModalContainer />
      </React.Fragment>
    );
  }
}
ReportBlockTradeSidePane.propTypes = {
  setBoughtReport: PropTypes.func.isRequired,
  setSoldReport: PropTypes.func.isRequired,
  form: PropTypes.object
};

ReportBlockTradeSidePane.defaultProps = {
  onSidePaneOpen: () => {}
};

ReportBlockTradeSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReportBlockTradeSidePane;
