import empty from 'is-empty';
import axios from 'axios';
const urlApi = !empty(process.env.REACT_APP_API_BACKEND)
  ? process.env.REACT_APP_API_BACKEND
  : 'http://localhost:3001';

export default class HandlerBackend {
  static async sendRequest(
    { userId, method, headers, data, path } = {
      userId,
      method,
      headers,
      data,
      path
    }
  ) {
    if (empty(headers)) {
      headers = {
        'Content-Type': 'application/json'
      };
    } else {
      if (empty(headers['Content-Type'])) {
        headers['Content-Type'] = 'application/json';
      }
    }

    if (!empty(localStorage.getItem('token'))) {
      headers.authorization = 'bearer ' + localStorage.getItem('token');
      headers.authorization_user_id = userId;
    } else {
      headers.authorization = 'bearer no-bearer';
      headers.authorization_user_id = '1';
    }

    let response = await axios({
      url: `${urlApi + path}`,
      method: method,
      headers,
      data
    });

    return response;
  }

  static async sendSimpleRequest(
    { method, headers, data, path } = {
      method,
      headers,
      data,
      path
    }
  ) {
    if (empty(headers)) {
      headers = {
        'Content-Type': 'application/json'
      };
    }

    let response = await axios({
      url: `${urlApi + path}`,
      method: method,
      headers,
      data
    });

    return response;
  }
}
