import React, { useEffect, useState } from 'react';
import empty from 'is-empty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';

const CarouselPopup = (props, context) => {
  let { messages, lang } = props;
  if (empty(messages)) {
    return <React.Fragment />;
  }

  return (
    <Carousel>
      {messages.map((m, i) => {
        return (
          <Carousel.Item key={i} interval={5000}>
            <img
              className="d-block w-100"
              src={m.eventImages[lang]}
              alt={`Banner_${i}`}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

const mapStateToProps = ({ i18nState: { lang } }) => {
  return { lang };
};

CarouselPopup.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  null
)(CarouselPopup);
