import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import Enable2FAModalComponent from './Enable2FAModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { enable2FA } from 'apex-web/lib/redux/actions/userActions';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

const mapStateToProps = function mapStateToProps(state) {
  return {
    GoogleQRCode: state.user.qrCode.ManualCode,
    userName: state.user.userInfo.UserName
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
    return {
      showSnack: function showSnack(text) {
        return dispatch(_showSnack(text));
      },
      changeFieldValue: function changeFieldValue(){
        return dispatch(change('enableTwoFactorAuth', 'use2FA', false))
      }
    };
};

const containerForm = reduxForm({
  form: 'enable2FAModal',
  onSubmit: function onSubmit(payload, dispatch, props) {
    return dispatch(enable2FA(payload.code));
  },
  onChange: function onChange(values, dispatch, props) {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(Enable2FAModalComponent);

const container = connect(mapStateToProps, mapDispatchToProps)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.ENABLE_2FA
})(container);