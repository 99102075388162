import { connect } from 'react-redux';
import WalletCardComponent from './WalletCardComponent';
import { getProductByIdSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { baseCurrencyPriceSelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var _getProductByIdSelect = getProductByIdSelector(state, ownProps.productId),
    iconFileName = _getProductByIdSelect.iconFileName,
    ProductSymbol = _getProductByIdSelect.ProductSymbol,
    ProductFullName = _getProductByIdSelect.ProductFullName,
    Amount = _getProductByIdSelect.Amount,
    AvailableBalance = _getProductByIdSelect.AvailableBalance,
    rawAmount = _getProductByIdSelect.rawAmount,
    ProductType = _getProductByIdSelect.ProductType,
    ProductId = _getProductByIdSelect.ProductId,
    productColor = _getProductByIdSelect.productColor;
  var baseCurrency = baseCurrencyPriceSelector(state, ownProps.productId);
  var isCrypto = ProductType === 'CryptoCurrency';
  return {
    iconFileName: iconFileName,
    ProductSymbol: ProductSymbol,
    ProductFullName: ProductFullName,
    Amount: Amount,
    AvailableBalance: AvailableBalance,
    rawAmount: rawAmount,
    isCrypto: isCrypto,
    ProductId: ProductId,
    productColor: productColor,
    baseCurrency: baseCurrency,
    detailsLink: ownProps.detailsLink,
    product: _getProductByIdSelect
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectPositionAndSave: function selectPositionAndSave() {
      dispatch(_selectPositionAndSave(ownProps.productId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletCardComponent);
