import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import APIcon from './../common/APIcon';

const PageHeaderNavMenuComponent = (props, context) => {
  const { customClass, handleNavItemClick } = props;
  const location = useLocation()
  const [hoverIcon, setHoverIcon] = useState('')

  const activeIcon = (path) => location.pathname.includes(path)

  return props.navItems.map(item => (
    <NavLink
      key={item.path}
      to={item.path}
      onClick={handleNavItemClick}
      className={customClass('item', ['hoverable']) + ' ' + item.icon + (!props.isOpen ? " reference-tooltip" : "")}
      activeClassName={customClass('item', ['selected'])}
      onMouseEnter={() => setHoverIcon(item.icon)}
      onMouseLeave={() => setHoverIcon('')}
    >
      <div>
        <APIcon name={(activeIcon(item.path) || hoverIcon === item.icon) ? item.icon + '-active': item.icon} customClass={customClass('icon')} />
        <p className='tooltip-menu'>{context.t(item.text)}</p>
      </div>
      <span className={customClass('label')}>{context.t(item.text)}</span>
    </NavLink>
  ));
};

PageHeaderNavMenuComponent.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  items: PropTypes.array
};

PageHeaderNavMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PageHeaderNavMenuComponent;
