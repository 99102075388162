import React from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../../../components";
import halfCard from "../../../../../images/half-card.png";
import { useHistory } from "react-router-dom";

function RechargeResult(props, context) {
  const { amount, commission, type = "success", handleSteps, date, banexCard, total } = props;
  const ht = useHistory();
  const resultTypes = {
    success: {
      title: context.t("Successful recharge!"),
      description: "",
      customClassName:"recharge-result-success",
      actions: [
        {
          label: context.t("Finish"),
          action: () => ht.push("/cards"),
        },
      ],
      component: () => {
        return (
          <div className="recharge-result">
            <img src={halfCard} alt="half card" loading="lazy" width={200} />
            <div style={{ width: "340px" }} className="line-gradient"></div>
            <div className="final-amount-recharge">
              <p>{context.t("You have recharged your card")}</p>
              <p>{amount - commission}</p>
            </div>
            <div className="recharge-result-row">
              <span>{context.t("Date")}:</span>
              <span className="value">{date.date}</span>
            </div>
            <div className="recharge-result-row">
              <span>{context.t("Hour")}:</span>
              <span className="value">{date.hour}</span>
            </div>
            <div className="recharge-result-row">
              <span>{context.t("Amount debited")}:</span>
              <span className="value">{amount}</span>
            </div>
            <div className="recharge-result-row">
              <span>{context.t("Commission")}:</span>
              <span className="value">{commission}</span>
            </div>
            <div className="recharge-result-row">
              <span>{context.t("Current balance in my Banexcard")}:</span>
              <span className="value">{total + banexCard.balanceData.availableBalance} ≈ 1,524.63 PEN</span>
            </div>
          </div>
        );
      },
    },
    error: {
      title: context.t("Failed recharge"),  
      description: context.t("We are sorry, your recharge could not be completed, please try again or write to us to help you by Chat or to soporte@banexcoin.com"),
        customClassName:"recharge-result-error",
      actions: [
        {
          label: context.t("Understood"),
          action: () => handleSteps.custom(1),
        },
      ],
    },
  };
  return (
    <CardGradient
      actions={resultTypes[type].actions}
      type={type}
      title={resultTypes[type].title}
      message={resultTypes[type].description}
      customClassName={resultTypes[type].customClassName}
    >
      {resultTypes[type].component && resultTypes[type].component()}
    </CardGradient>
  );
}

RechargeResult.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RechargeResult;
