import React from 'react';
import APNavMenuVertical from '../APNavMenuVertical/APNavMenuVertical';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';

import './SettingsMenuComponent.css';
import { connect } from 'react-redux';

const SettingsMenuClasses = getBEMClasses('settings-menu');

const SettingMenuItems = [
  {
    text: 'Profile',
    path: '/settings/user',
  },
  {
    text: 'Verification Level',
    path: '/settings/verification-level'
  },
  {
    text: 'Trade Reports',
    path: '/settings/trade-reports'
  },
  {
    text: 'API Keys',
    path: '/settings/api-keys',
  },
  {
    text: 'Referral Program​',
    path: '/settings/affiliate',
  },
  {
    text: 'My Banking Accounts',
    path: '/settings/accounts'
  },
  {
    text: 'Trust list',
    path: '/settings/trust-list' /*icon: 'paint' */
  },
  {
    text: 'Security and Data Treatment',
    path: '/settings/security'
  }
];
const CoinkSettingMenuItems = [
  {
    text: 'Profile',
    path: '/settings/user',
  },
  {
    text: 'Verification Level',
    path: '/settings/verification-level'
  },
  {
    text: 'Trade Reports',
    path: '/settings/trade-reports'
  },
  {
    text: 'Trust list',
    path: '/settings/trust-list'
  },
  {
    text: 'Security and Data Treatment',
    path: '/settings/security'
  },
  {
    text: 'Automatic withdrawal',
    path: '/settings/withdrawal'
  },
];

class SettingsMenuComponent extends React.Component {
  state = {
    isOpen: false
  };
  handleClick = () => {
    if (!this.state.isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOutsideClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  render() {
    let { prePath, token, user } = this.props;
    return (
      <div
        className={SettingsMenuClasses(
          "container",
          this.state.isOpen ? "" : "closed"
        )}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          className={SettingsMenuClasses(
            "menu-toggle",
            this.state.isOpen ? "active" : ""
          )}
          onClick={this.handleClick}
        >
          {this.context.t("User Settings")}
          <div className={SettingsMenuClasses("triangles-container")}>
            <span className={"triangle-up"} />
            <span className={"triangle-down"} />
          </div>
        </div>
        <APNavMenuVertical
          customClass={SettingsMenuClasses}
          handleNavItemClick={this.handleClick}
          prePath={prePath}
          token={token}
          items={
            user &&
            user.verificationLevelConfigs &&
            user.verificationLevelConfigs.Level < 100
              ? SettingMenuItems
              : CoinkSettingMenuItems
          }
        />
      </div>
    );
  }
}

const mapStateTopProps = state => {
  return {
    user: state.user
  }
}
SettingsMenuComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateTopProps, null)(SettingsMenuComponent);