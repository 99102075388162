import React from 'react'
import './withdrawPage.css'
import ProductListContainer from '../../components/ProductList/ProductListContainer';
import PageFooterLayout from '../../layouts/PageFooterLayout/PageFooterLayout';

const WithdrawPage = () => {
    return (
        <div className='withdraw-page'>
            <ProductListContainer title='Withdraw' />
            <PageFooterLayout />
        </div>
    )
}

export default WithdrawPage