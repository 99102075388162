import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import AutoForm from './AutoForm';
import { useForm } from '../../hooks/formHooks';
import empty from 'is-empty';
import {
  submitFormCorporateData,
  getCountries,
  getLevelCorporateBeta,
  resendFormCorporateData,
  getRucInfo
  // sendKYCNotification
} from '../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loading from '../../components/Loading';
import Alert from 'react-bootstrap/Alert';
import { states } from '../../config/staticWorldContent';
import { province } from '../../config/staticPeruProv';
import { districts } from '../../config/staticPeruDist';
import axios from 'axios';
import InputGroupWithExtras from 'react-bootstrap/esm/InputGroup';

const ModalCorporate = (props, context) => {
  const {
    show,
    handleClose,
    userInfo,
    selectedAccountId,
    loadLevel,
    preId
  } = props;

  try {
    if (empty(userInfo) || empty(selectedAccountId)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [countries, setCountries] = useState({});
    const [basicLevel, setBasicLevel] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [imagesBlob, setImagesBlob] = useState({});
    const [rucInfo, setRucInfo] = useState('');
    const [rucValidated, setRucValidated] = useState(true);
    const [rucRetry, setRucRetry] = useState(true);
    const [files, setFiles] = useState({});

    const setBlobByName = (name, blob) => {
      setImagesBlob(imagesBlob => ({
        ...imagesBlob,
        [name]: blob
      }));
    };
    async function setValidate(val) {
      setInputs(inputs => ({
        ...inputs,
        validated: val
      }));
    }

    async function setInputsBasic(basicLevel) {
      inputs = {
        ...inputs,
        ...basicLevel,
        id: basicLevel.id,
        first_name: basicLevel.first_name,
        middle_name: basicLevel.middle_name,
        last_name: basicLevel.last_name,
        second_last_name: basicLevel.second_last_name,
        birthday: moment(basicLevel.birthday, 'YYYY-MM-DD'),
        nationality: basicLevel.nationality,
        birth_country: basicLevel.birth_country,
        residence_country: basicLevel.residence_country,
        address_main: basicLevel.address_main,
        occupation: basicLevel.occupation,
        work_center: basicLevel.work_center,
        phone: basicLevel.phone,
        is_pep: basicLevel.is_pep,
        billing_country: basicLevel.billing_country,
        billing_address: basicLevel.billing_address,
        billing_state: basicLevel.billing_state,
        billing_city: basicLevel.billing_city,
        billing_zip: basicLevel.billing_zip,
        document_type: basicLevel.document_type,
        document_country: basicLevel.document_country,
        document_number: basicLevel.document_number,
        document_data: basicLevel.document_data,
        back_document_data: basicLevel.back_document_data,
        faces_images: basicLevel.faces_images,
        status: basicLevel.status,
        edit_fields: basicLevel.edit_fields,
        user_level: basicLevel.user_level,
        next_level: basicLevel.next_level,
        linked_economic_group_country: !empty(
          basicLevel.linked_economic_group_country
        )
          ? basicLevel.linked_economic_group_country
          : '',
        linked_economic_group_doc_type: !empty(
          basicLevel.linked_economic_group_doc_type
        )
          ? basicLevel.linked_economic_group_doc_type
          : '',
        linked_economic_group_doc_other_type: !empty(
          basicLevel.linked_economic_group_doc_other_type
        )
          ? basicLevel.linked_economic_group_doc_other_type
          : '',
        linked_economic_group_doc_number: !empty(
          basicLevel.linked_economic_group_doc_number
        )
          ? basicLevel.linked_economic_group_doc_number
          : '',
        linked_economic_group_name: !empty(
          basicLevel.linked_economic_group_name
        )
          ? basicLevel.linked_economic_group_name
          : ''
      };
      setInputs(inputs);
    }

    async function loadLevelBasic() {
      setLoading(1);
      try {
        let result = await getLevelCorporateBeta(
          userInfo.UserId,
          selectedAccountId
        );

        let inputs = JSON.parse(result.data.corporatedata.dataForm);
        let level = {
          ...inputs,
          id: result.data.corporatedata.id,
          reviewStatus: result.data.corporatedata.reviewStatus,
          status:
            result.data.corporatedata.reviewStatus === 'INCORRECT_INFORMATION'
              ? 5
              : null,
          edit_fields: result.data.corporatedata.editFields
        };
        const incorrectFiels = JSON.parse(result.data.corporatedata.editFields);
        Object.keys(level).forEach(element => {
          if (incorrectFiels.includes(element)) {
            level = { ...level, [element]: null };
          }
        });
        let errors = {};
        incorrectFiels.forEach(e => {
          errors[e] = true
        })
        setErrors(errors)
        setBasicLevel(level);
        setInputsBasic(level);
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }

      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(preId)) {
          loadLevelBasic();
        }
      },
      [preId]
    );

    /* identificar Idioma */
    var link_language = localStorage.getItem('language');

    /* Obteniendo carga al iniciar */
    const getListCountries = async () => {
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
        return !empty(result);
      } catch (error) {
        getListCountries();
        // toast.warn(context.t('Information could not accesible'), {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
    };
    useEffect(() => {
      if (!empty(userInfo)) {
        getListCountries();
      }
    }, []);
    /* terminando carga al iniciar */

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result = await getLevelCorporateBeta(
          userInfo.UserId,
          selectedAccountId
        );
        if (
          empty(result.data.corporatedata) ||
          !empty(result.data.corporatedata.editFields)
        ) {
          if (
            !empty(preId) &&
            basicLevel.reviewStatus === 'INCORRECT_INFORMATION'
          ) {
            await resendFormCorporateData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (props.hasOwnProperty('alertIfComplete')) {
              props.alertIfComplete(inputs.id, true);
            }
          } else {
            await submitFormCorporateData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
          }
          //loadLevelBasic();
          await loadLevel();
          handleClose();
        } else {
          handleClose();
          toast.error(
            context.t(
              'You already have an active request.Please wait within a maximum period of 72 business hours, you will receive an email with the response to your request.'
            ),
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 20000
            }
          );
        }
        setLoading(0);
      } catch (error) {
        handleClose();
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};
      if (!inputs.receipt_public_service) {
        errors.receipt_public_service = true;
      }
      if (!inputs.last_income_tax_return) {
        errors.last_income_tax_return = true;
      }
      if (!inputs.company_constitution_act) {
        errors.company_constitution_act = true;
      }
      if (!inputs.incorporation_document) {
        errors.incorporation_document = true;
      }
      if (!inputs.affidavit_origins_funds) {
        errors.affidavit_origins_funds = true;
      }
      if (!inputs.ruc_file) {
        errors.ruc_file = true;
      }
      setErrors(errors);
      return errors;
    };

    const openFile = function(event) {
      const input = event.target;

      const reader = new FileReader();
      reader.onload = function() {
        const dataURL = reader.result;
        setFiles({ ...files, [input.name]: dataURL });
      };
      reader.readAsDataURL(input.files[0]);
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : '',
      is_runac_verified: false
    };

    let {
      inputs,
      errors,
      setErrors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    let optionsDocument = [];

    if (!empty(inputs) && inputs.nationality === 'PE') {
      optionsDocument = [{ value: 'ID', name: 'National identity document' }];
    } else {
      optionsDocument = [{ value: 'PP', name: 'Passport' }];
    }

    const getRucInfoFromService = async () => {
      try {
        let result = await getRucInfo(
          userInfo.UserId,
          inputs.linked_economic_group_doc_number
        );
        setRucInfo(result.data.ruc.split('-')[1]);
        setRucValidated(true);
        setRucRetry(false);
        inputs.linked_economic_group_name = result.data.ruc.split('-')[1];
        inputs.is_runac_verified = true;
      } catch (error) {
        setRucRetry(false);
        setRucValidated(false);
        console.log(error);
      }
    };

    if (
      !empty(inputs.linked_economic_group_doc_type) &&
      inputs.linked_economic_group_doc_type === 'RUC' &&
      !empty(inputs.linked_economic_group_doc_number) &&
      empty(inputs.linked_economic_group_name) &&
      inputs.linked_economic_group_doc_number.length == 11 &&
      rucRetry
    ) {
      getRucInfoFromService();
    }

    if (inputs.linked_economic_group_country === 'PE' && !empty(rucInfo)) {
      inputs.linked_economic_group_name = rucInfo;
    }
    inputs.is_runac_verified = rucValidated;

    let steps = [
      {
        title: 'Corporate Data',
        hint: [
          {
            p: ''
          },
          {
            p:
              'To increase the level of Client to the Corporate Level, you must supply the Detailed Information of the Company, this registration must be carried out only using a Banexcoin account duly created by its current Legal Representative.',
            links: [
              {
                title: 'See limits',
                href:
                  link_language === 'es'
                    ? 'https://soporte.banexcoin.com/es-LA/support/solutions/articles/60000609834'
                    : 'https://soporte.banexcoin.com/en/support/solutions/articles/60000609834-limits-and-benefits'
              }
            ]
          }
        ],
        fields: [
          {
            label: 'Denomination or Full Legal Name of Company',
            name: 'company_legal_name',
            type: 'text',
            props: [
              {
                required: true,
                autoComplete: 'off',
                placeholder: 'Enter Denomination or Full Legal Name of Company'
              }
            ]
          },
          {
            label: 'Type of Legal Entity',
            name: 'legal_entity_type',
            type: 'select',
            options: [
              { value: 'Ltd', name: 'Ltd' },
              { value: 'SE', name: 'SE' },
              { value: 'SPE', name: 'SPE' },
              { value: 'EEIG', name: 'EEIG' },
              { value: 'PLC', name: 'PLC' },
              { value: 'Corp', name: 'Corp' },

              { value: 'SA', name: 'SA' },
              { value: 'SAA', name: 'SAA' },
              { value: 'SAC', name: 'SAC' },
              { value: 'SRL', name: 'SRL' },
              { value: 'EIRL', name: 'EIRL' },
              { value: 'CA', name: 'CA' },

              { value: 'Non-Profit', name: 'Non-Profit (specify)' },
              { value: 'Other', name: 'Other (specify)' }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Non-Profit',
            name: 'non_profit',
            type: 'select',
            options: [
              { value: 'Association', name: 'Association' },
              { value: 'Foundation', name: 'Foundation' },
              { value: 'Committee', name: 'Committee' },
              { value: 'Other', name: 'Other (specify)' }
            ],
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Non-Profit'
              }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Other Non-Profit',
            name: 'other_non_profit',
            type: 'text',
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Non-Profit'
              },
              {
                parent: 'non_profit',
                conditionValue: 'Other'
              }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Other Type of Legal Entity',
            name: 'other_legal_entity_type',
            type: 'text',
            displayOn: [
              {
                parent: 'legal_entity_type',
                conditionValue: 'Other'
              }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Type of Taxpayer Identification Number',
            name: 'taxpayer_identification_number_type',
            type: 'select',
            options: [
              { value: 'TIN', name: 'TIN' },
              { value: 'EIN', name: 'EIN' },
              { value: 'VAT', name: 'VAT' },
              { value: 'NINO', name: 'NINO' },

              { value: 'RUC', name: 'RUC' },
              { value: 'RUT', name: 'RUT' },
              { value: 'NIF', name: 'NIF' },
              { value: 'RIF', name: 'RIF' },

              { value: 'Other', name: 'Other (specify)' }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Other Type of Taxpayer Identification Number',
            name: 'other_taxpayer_identification_number_type',
            type: 'text',
            displayOn: [
              {
                parent: 'taxpayer_identification_number_type',
                conditionValue: 'Other'
              }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Taxpayer Identification Number',
            name: 'taxpayer_identification_number',
            type: 'text',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label:
              'Describe Corporate Purpose / Products or Services that the company provides',
            name: 'corporate_purpose',
            type: 'text',
            size: 12,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Enter Street / Avenue',
            name: 'company_address',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Enter Street / Avenue'
              }
            ]
          },
          {
            label: 'Building number or name',
            name: 'corporate_building',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Building number or name',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Interior # / Apartment # / Office #',
            name: 'corporate_apt_number',
            type: 'text',
            size: '6',
            props: [
              {
                autoComplete: 'off',
                required: true
              }
            ]
          },
          {
            label: 'Zonification / Town',
            name: 'corporate_town',
            type: 'text',
            size: '6',
            props: [
              {
                autoComplete: 'off',
                required: true
              }
            ]
          },
          {
            label: 'Country',
            name: 'country_incorporation',
            type: 'select',
            options: countries,
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'State',
            name: 'state',
            type: 'select',
            options: states[inputs.country_incorporation],
            props: [
              {
                required: true
              }
            ]
          },
          inputs.country_incorporation === 'PE'
            ? {
                label: 'City / Province',
                name: 'city',
                type: 'select',
                options: province[inputs.state],
                props: [
                  {
                    required: true
                  }
                ]
              }
            : {
                label: 'City / Province',
                name: 'city',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
          inputs.country_incorporation === 'PE'
            ? {
                label: 'Municipality / District',
                name: 'corporate_district',
                type: 'select',
                options: districts[inputs.city],
                props: [
                  {
                    required: true
                  }
                ]
              }
            : {
                label: 'Municipality / District',
                name: 'corporate_district',
                type: 'text',
                props: [
                  {
                    required: true
                  }
                ]
              },
          {
            label: 'Zip Code',
            name: 'zip_code',
            type: 'text',
            size: '6',
            props: [
              {
                required: true,
                placeholder: 'Zip Code',
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Registration Date (Constitution)',
            name: 'registration_date',
            type: 'date',
            props: [
              {
                required: true,
                isNormal: true
              }
            ]
          },
          {
            label: 'Activity begin date',
            name: 'begin_date',
            type: 'date',
            props: [
              {
                required: true,
                isNormal: true,
                defaultMinDate: !empty(inputs.registration_date)
                  ? inputs.registration_date
                  : false
              }
            ]
          },
          {
            label: 'Company Phone',
            name: 'company_phone',
            type: 'tel',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Linked economic group',
            name: 'linked_economic_group',
            type: 'select',
            options: [
              { value: 'No', name: 'No' },
              { value: 'Yes', name: 'Yes' }
            ],
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Linked economic group country',
            name: 'linked_economic_group_country',
            type: 'select',
            options: countries,
            displayOn: [
              {
                parent: 'linked_economic_group',
                conditionValue: 'Yes'
              }
            ],
            props: [
              {
                required: inputs.linked_economic_group === 'Yes' ? true : false
              }
            ]
          },
          {
            label: 'Linked economic group document type',
            name: 'linked_economic_group_doc_type',
            type: 'text',
            displayOn: [
              {
                parent: 'linked_economic_group_country',
                conditionValue: 'PE'
              }
            ],
            props: [
              {
                required: inputs.linked_economic_group === 'Yes' ? true : false,
                placeholder: 'RUC',
                autoComplete: 'off',
                disabled: true
              }
            ]
          },
          {
            label: 'Linked economic group document type',
            name: 'linked_economic_group_doc_type',
            type: 'select',
            options: [
              { value: 'TIN', name: 'TIN' },
              { value: 'EIN', name: 'EIN' },
              { value: 'VAT', name: 'VAT' },
              { value: 'NINO', name: 'NINO' },

              { value: 'RUC', name: 'RUC' },
              { value: 'RUT', name: 'RUT' },
              { value: 'NIF', name: 'NIF' },
              { value: 'RIF', name: 'RIF' },

              { value: 'Other', name: 'Other (specify)' }
            ],
            displayOn: [
              {
                parent: 'linked_economic_group_country',
                isEqualTo: false,
                conditionValue: 'PE'
              }
            ],
            props: [
              {
                required: inputs.linked_economic_group === 'Yes' ? true : false
              }
            ]
          },
          {
            label: 'Other linked economic group document type',
            name: 'linked_economic_group_doc_other_type',
            type: 'text',
            displayOn: [
              {
                parent: 'linked_economic_group_doc_type',
                conditionValue: 'Other'
              }
            ],
            props: [
              {
                required: inputs.linked_economic_group === 'Yes' ? true : false
              }
            ]
          },
          {
            label: 'Linked economic group document number',
            name: 'linked_economic_group_doc_number',
            type: 'text',
            displayOn: [
              {
                parent: 'linked_economic_group',
                conditionValue: 'Yes'
              }
            ],
            props: [
              {
                required: inputs.linked_economic_group === 'Yes' ? true : false
              }
            ]
          },
          inputs.linked_economic_group_country !== 'PE' ||
          (inputs.linked_economic_group_country === 'PE' &&
            !inputs.is_runac_verified)
            ? {
                label: 'Linked economic group company name',
                name: 'linked_economic_group_name',
                type: 'text',
                displayOn: [
                  {
                    parent: 'linked_economic_group',
                    conditionValue: 'Yes'
                  }
                ],
                props: [
                  {
                    required:
                      inputs.linked_economic_group === 'Yes' ? true : false,
                    placeholder: 'Please, insert the legal name of the company'
                  }
                ]
              }
            : {
                label: 'Linked economic group company name',
                name: 'linked_economic_group_name',
                type: 'text',
                displayOn: [
                  {
                    parent: 'linked_economic_group',
                    conditionValue: 'Yes'
                  }
                ],
                props: [
                  {
                    required:
                      inputs.linked_economic_group === 'Yes' ? true : false,
                    placeholder: empty(inputs.linked_economic_group_doc_number)
                      ? 'Please, insert the company RUC number'
                      : empty(rucInfo) &&
                        !empty(inputs.linked_economic_group_doc_number)
                        ? 'Searching RUC Information, please wait...'
                        : rucInfo,
                    autoComplete: 'off',
                    disabled: true
                  }
                ]
              }
        ]
      },
      {
        title: 'Contact person information',
        hint: [
          {
            p:
              'Enter information for a contact person in your company or entity.'
          }
        ],
        fields: [
          {
            label: 'Full name',
            name: 'full_name',
            type: 'text',
            size: '12',
            props: [
              {
                required: true
              }
            ]
          },
          {
            label: 'Job Position',
            name: 'job_position',
            type: 'text',
            props: [
              {
                required: true,
                autoComplete: 'off'
              }
            ]
          },
          {
            label: 'Phone',
            name: 'phone',
            type: 'tel',
            props: [
              {
                required: true
              }
            ]
          }
        ]
      },
      {
        title: 'Upload Corporate documents',
        hint: [
          {
            p:
              'Make sure to correctly upload each of the required documents in the supported format and size.'
          }
        ],
        fields: [
          {
            label: 'Company Constitution Act',
            name: 'company_constitution_act',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act act_const',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
          {
            label:
              'Incorporation Document / Validity of Power of Attorney no later than 3 months',
            name: 'incorporation_document',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
          {
            label:
              'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)',
            name: 'receipt_public_service',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
          {
            label: 'Last income tax return',
            name: 'last_income_tax_return',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act Last',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
          {
            label: 'Affidavit of Origins of funds',
            name: 'affidavit_origins_funds',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act affidavit',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
          {
            label: '',
            name: 'corporate_down_file',
            type: 'file',
            size: '12',
            subdata: [
              {
                label:
                  'Download Annex B <b>here</b>, fill it out and upload it.',
                name: 'worker_type',
                type: 'download-link',
                href:
                  'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pdf-en/Affidavit%20of%20Origin%20of%20Funds%20Banexcoin_Level%204%20Corporate%20v1.1.pdf'
              }
            ],
            props: [
              {
                className: 'FileDownCorp',
                accept: 'pdf,.doc,.docx,.jpg,.png'
              }
            ]
          },
          {
            label: 'RUC file',
            name: 'ruc_file',
            type: 'file',
            maxSize: 10,
            size: '12',
            subdata: [
              {
                type: 'download-text',
                className: 'important',
                label:
                  '(Supported formats: PDF, JPG, JPEG and PNG, maximum size 10 MB)'
              }
            ],
            props: [
              {
                className: 'btn_constitution_act affidavit',
                required: true,
                accept: '.pdf,.doc,.docx'
              }
            ]
          },
        ]
      }
    ];

    let img = [];
    let indexImg = 1;
    steps[currentStep - 1].fields.forEach(function(data) {
      if (data.filetype == 'image') {
        img.push(
          <React.Fragment key={indexImg}>
            <h4 className="title-image">{context.t(data.label)}</h4>
            <img
              id={data.name}
              src={
                !empty(files[data.name])
                  ? files[data.name]
                  : !empty(inputs[data.name])
                    ? inputs[data.name]
                    : data.name === 'faces_images'
                      ? '/local/landing-page/images/selfie-example.jpg'
                      : '/local/landing-page/images/no-image-box.png'
              }
              className="crop-src-img"
            />
          </React.Fragment>
        );
        indexImg++;
      }
    });

    let hints = [];
    let indexHints = 1;
    let hint = steps[currentStep - 1].hint;
    if (!empty(hint)) {
      steps[currentStep - 1].hint.forEach(function(data) {
        let a = [];
        let links = data.links;
        if (!empty(links)) {
          for (let i in links) {
            a.push(
              <a
                key={i}
                role="button"
                target="_blank"
                className="btn btn-primary"
                href={links[i].href}
                title={context.t(links[i].title)}>
                {context.t(links[i].title)}
              </a>
            );
          }
        }

        hints.push(
          <React.Fragment key={indexHints}>
            <h4>{context.t(data.p)}</h4>
            {a}
          </React.Fragment>
        );
        indexHints++;
      });
    }

    return (
      <Modal show={show} onHide={handleClose} className="modal-step-level">
        <Loading loading={loading} />
        <Modal.Header closeButton />
        <div className="modal-container-step-level">
          <div className="row left-fix">
            <div className="col-4 left-step">
              {/* <img src="/local/landing-page/images/isotipo_new.png" className="logo-step" /> */}
              <div className="step-side-information">
                <span />
                {!empty(steps[currentStep - 1]) ? (
                  <React.Fragment>
                    <h3>{context.t('Raise my customer level to Corporate')}</h3>
                    {hints}
                    {img}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-8 right-step">
              <div className="row">
                <h3 className="title-corp">
                  {!empty(steps[currentStep - 1].title)
                    ? context.t(steps[currentStep - 1].title)
                    : ''}
                </h3>
                <Form
                  noValidate
                  validated={inputs.validated}
                  encType="multipart/form-data"
                  className={!empty(errors) ? 'was-validated' : ''}
                  onSubmit={onSubmit}>
                  {!empty(inputs) && inputs.status === 1 ? (
                    <Alert variant="warning">
                      {context.t(
                        'Your request is under review. Within the next 24 business hours, you will receive a notification via email.'
                      )}
                    </Alert>
                  ) : inputs.status === 5 ? (
                    <Alert variant="warning">
                      {context.t(
                        "Your request has been rejected because one or more fields are incorrect. Don't worry, you can resend the correct information. The fields to correct are highlighted on the form."
                      )}
                    </Alert>
                  ) : (
                    ''
                  )}
                  <AutoForm
                    errors={errors}
                    inputs={inputs}
                    steps={steps}
                    setFiles={openFile}
                    onInputChange={onInputChange}
                    onInputFileChange={onInputFileChange}
                    onInputChangeByName={onInputChangeByName}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    imagesBlob={imagesBlob}
                    setBlobByName={setBlobByName}
                    requestedLevel={4}
                    setValidate={setValidate}
                    submitText={
                      basicLevel.reviewStatus === 'INCORRECT_INFORMATION'
                        ? 'Correct Fields'
                        : null
                    }
                    separator={true}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  } catch (error) {
    console.log('error ModalCorporate', error);
    return <div>Error:</div>;
  }
};

ModalCorporate.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(ModalCorporate);
