import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import { redirectExceptions } from 'apex-web/lib/helpers/redirectIfAuthorizedHelper';
import LoginPage from './bnxv2/Pages/LoginPage';

const LandingPageValidate = props => {
  const defaultPath = props.defaultPath;

  const shouldRedirect = props.shouldRedirect,
    redirectLocation = props.redirectLocation,
    isAuthenticated = props.isAuthenticated;

  if (isAuthenticated) {
    if (redirectExceptions()) {
      return React.createElement(LoginPage, props);
    }
    if (shouldRedirect) {
      return React.createElement(Redirect, {
        to: { pathname: redirectLocation.pathname }
      });
    } else {
      return React.createElement(Redirect, {
        to: path(defaultPath + '?default=redirect')
      });
    }
  }
  return React.createElement(LoginPage, props);
};

const mapStateToProps = function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    shouldRedirect: state.auth.shouldRedirect,
    redirectLocation: state.auth.location
  };
};

export default connect(
  mapStateToProps,
  null
)(LandingPageValidate);
