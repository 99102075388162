import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Nav, NavDropdown } from 'react-bootstrap';
import empty from 'is-empty';
import apex from 'apex-web/lib/apex';
import { Link, useHistory } from 'react-router-dom';
import header_logo from './../../images/coink-imgs/mini-iso-banexcoin-new.svg';
import notificationIcon from './../../images/coink-imgs/notification.svg';
import arrowLeft from './../../images/coink-imgs/ico_arrow_left.png';
import { setUserNotAuthenticated } from 'apex-web/lib/redux/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_NONE = 'AUTH_NONE';
export const AUTH_REDIRECT = 'AUTH_REDIRECT';
export const AUTH_2FA_REQUIRED = 'AUTH_2FA_REQUIRED';
export const FORCED_LOGOUT = 'FORCED_LOGOUT';
export const ENABLE_2FA_REQUIRED = 'ENABLE_2FA_REQUIRED';

export const COINK_STATUS = [
  {
    key: 'CREATED',
    name: 'Operation created',
    step: 'success'
  },
  {
    key: 'PAID_OUT',
    name: 'Approved transaction',
    step: 'success'
  },
  {
    key: 'REJECTED',
    name: 'Transaction declined',
    step: 'error'
  },
  {
    key: 'EXPIRED',
    name: 'The time for the operation has expired',
    step: 'error'
  },
  {
    key: 'CANCELED',
    name: 'Operation cancelled',
    step: 'error'
  },
  {
    key: 'FAILED',
    name: 'The operation failed when applied',
    step: 'error'
  },
  {
    key: 'REVERSED',
    name: 'Reversed transaction',
    step: 'error'
  },
  {
    key: 'PROCESS_PENDING',
    name: 'Transaction in process',
    step: 'one'
  }
];

export const Trans = ({ text, className }) => (
  <div
    className={!empty(className) ? className : ''}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export const TransP = ({ text, className }) => (
  <p
    className={!empty(className) ? className : ''}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export var logout = function logout() {
  return function(dispatch, getState) {
    var wasAuthenticatedBefore = getState().auth.isAuthenticated;
    var action = setUserNotAuthenticated(wasAuthenticatedBefore);
    apex.connection.RPCPromise('LogOut', {}).catch(function(err) {
      console.warn(err);
    });

    dispatch(action);
  };
};

const HeaderBanexcoin = (props, context) => {
  const ht = useHistory();
  const { action, disableOptions = false, disableBack = false } = props;
  const pathToken =
    !empty(ht.location) && !empty(ht.location.pathname)
      ? ht.location.pathname.split('/')
      : [];
  const handleGoBack = () => {
    if (action) action();
    else ht.goBack();
  };
  const validationToShowOptions = () =>
    pathToken.includes('coink') && !disableOptions;

  const goNotifications = () => ht.push('/coink/internal/transactions/buy');
  return (
    <React.Fragment>
      <div className="top-header" id="headerStyleCoink">
        <div>
          {validationToShowOptions() &&
            !disableBack && (
              <div className="options_header-container" onClick={handleGoBack}>
                <img width={40} src={arrowLeft} />
              </div>
            )}
        </div>
        <img src={header_logo} />
        {validationToShowOptions() && (
          <div
            className="notification_image options_header-container"
            onClick={goNotifications}>
            <img src={notificationIcon} alt="" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, userInfo },
  user,
  position: { positions, fetching }
}) => {
  return { selectedAccountId, userInfo, user, positions, fetching };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

HeaderBanexcoin.contextTypes = {
  t: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBanexcoin);

export const StepperCount = (props, context) => {
  let { total, current } = props;
  let steps = [];
  for (let i = 1; i <= total; i++) {
    steps.push(<span key={i} className={i === current ? 'here' : ''} />);
  }
  return (
    <React.Fragment>
      <div className="stepper-body">{steps}</div>
    </React.Fragment>
  );
};

export const TextFieldBnx = (props, context) => {
  let { type, subtitle, label, id, fieldProps, icon, errorMsj } = props;
  return (
    <React.Fragment>
      <Form.Group
        controlId={!empty(fieldProps) ? fieldProps.name : ''}
        className="bnx-field">
        <Form.Label>{label}</Form.Label>
        <div className="field-control">
          <Form.Control type={!empty(type) ? type : 'text'} {...fieldProps} />
          {icon}
          {!empty(errorMsj) ? (
            <Form.Control.Feedback type="invalid">
              {errorMsj}
            </Form.Control.Feedback>
          ) : (
            ''
          )}
          {!empty(subtitle) ? (
            <Form.Text className="text-muted">{subtitle}</Form.Text>
          ) : (
            ''
          )}
        </div>
      </Form.Group>
    </React.Fragment>
  );
};

export const TextFieldDashBnx = (props, context) => {
  let { type, subtitle, label, id, fieldProps, icon, errorMsj } = props;
  return (
    <React.Fragment>
      <Form.Group
        controlId={!empty(fieldProps) ? fieldProps.name : ''}
        className="bnx-field dash">
        <Form.Label>{label}</Form.Label>
        <div className="field-control">
          <Form.Control type={!empty(type) ? type : 'text'} {...fieldProps} />
          {!empty(icon) ? icon : ''}
          {!empty(errorMsj) ? (
            <Form.Control.Feedback type="invalid">
              {errorMsj}
            </Form.Control.Feedback>
          ) : (
            ''
          )}
          {!empty(subtitle) ? (
            <Form.Text className="text-muted">{subtitle}</Form.Text>
          ) : (
            ''
          )}
        </div>
      </Form.Group>
    </React.Fragment>
  );
};

export const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const authSuccess = payload => ({
  type: AUTH_SUCCESS,
  payload: {
    isAuthenticated: payload.Authenticated,
    userId: payload.UserId,
    token: payload.SessionToken,
    pending: false,
    is2FARequired: false
  }
});

export const authError = payload => ({
  type: AUTH_ERROR,
  payload: {
    isAuthenticated: payload.Authenticated,
    errorMsg: payload.errormsg,
    pending: false
  }
});

export const require2FA = payload => ({
  type: AUTH_2FA_REQUIRED,
  payload: {
    userId: payload.UserId,
    is2FARequired: true
  }
});

export const resolveError = error => {
  let result = {};
  if (error.response.hasOwnProperty('data')) {
    result = error.response.data;
  } else {
    result = {
      data: {
        error_key: 'UnknownError',
        error_code: 'UnknownError',
        error_message: error.message
      }
    };
  }
  return result;
};

export const formatPriceNumber = number => {
  if (empty(number)) {
    return 0.0;
  }
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const constructLink = pathLink => {
  const windowUrl = window.location.search;
  return pathLink + windowUrl.substring(0);
};

export const getParameterByName = name => {
  const newName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + newName + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
