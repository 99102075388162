import React from 'react';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import WalletCard from './WalletCardContainer';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import './WalletCardGrid.css';

var walletCardGridClasses = getBEMClasses('wallet-card-grid');

var WalletCardGrid = function WalletCardGrid(props) {
  var items = props.products.map(function(item) {
    return React.createElement(WalletCard, {
      key: item.ProductId,
      productId: item.ProductId,
      detailsLink: props.detailsLink
    });
  });

  return React.createElement(
    React.Fragment,
    null,
    props.fetching || !!items.length
      ? React.createElement(
          'div',
          { className: walletCardGridClasses() },
          props.fetching ? React.createElement(Spinner, null) : items
        )
      : ''
  );
};

export default WalletCardGrid;
