import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { updateComponent as _updateComponent } from '../../../redux/actions/set_component';
import InteriorPagePhoneConfirmation from '../../../pages/InteriorPagePhoneConfirmation';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { required, alphanumericspace, email } from 'apex-web/lib/helpers/formValidations';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import empty from 'is-empty';
import SendAddressContainer from '../SendAddress/SendAddressContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import NetworkSelector from './NetworkSelector';
import SidePaneDetailsComponent from './SidePaneDetailsComponent';
import { Row } from 'react-bootstrap';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { product as ProductPropTypes } from 'apex-web/lib/propTypes/sendReceiveComponent';
import { jsonRed } from './SelectRedData';
import { authorizedLevel } from './../../../helpers/lib';
import './SendForm.css';
import { validateTrustList } from '../../TrustList/TrustListHooks';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { formatNumbersWithoutRound , FormatDecimalCoinkWithSimbols, amountWithCurrency, formatCopCurrency } from '../../../pages/utils';
import config from 'apex-web/lib/config';
import WAValidator from '@swyftx/api-crypto-address-validator';
import isEmpty from 'is-empty';
import APNumberInput from '../../../components/OrderEntryComponents/APNumberInput';
import getInstrumentPrices from './getInstrumentBySelection';
import valueByCurrency from './valueByCurrency';
import { setWithdrawDetails } from '../../../redux/actions/set_withdraw_details'

var bemClasses = getBEMClasses('send-form');
var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }

  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (superClass) {
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
  }
}

var SendForm = (function(_React$Component) {
  _inherits(SendForm, _React$Component);

  function SendForm() {
    var _ref;
    var _temp, _this, _ret;

    _classCallCheck(this, SendForm);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }
    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SendForm.__proto__ || Object.getPrototypeOf(SendForm)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.state = {
        max: false,
        selectedCurrency: _this.props.product.ProductSymbol,
        pricing: getInstrumentPrices(_this.props, _this.props.product.ProductSymbol, _this.props.isCoink),
        enteredAmount: null,
        aproxDecimalPlaces: 2
      }),
      (_this.handleModalToValidatePhone = () => {
        const modal = document.getElementById('modal');
        const close = document.getElementsByClassName('ap-sidepane__close-button');
        if (close) close[0].click();
        if(modal) {
          _this.props.updateComponent({ 
            visible: true,
            elements: [
              ..._this.props.component.elements,
              <InteriorPagePhoneConfirmation
                blocked={true}
                theme={'dark'}
                location={_this.props.location}
              />
            ]
          });
        }
      }),
      (_this.handleVerify = (location) => {
        _this.props.redirect(location);
        const closeButton = document.getElementsByClassName('ap-sidepane__close-button');
        if (closeButton) closeButton[0].click();
      }),
      (_this.lessThanBalance = function(value) {
        let maxAmount = _this.props.balance;
        _this.setState({ enteredAmount: value });
        _this.props.setWithdrawDetails({ amount: _this.state.selectedCurrency === _this.props.product.ProductSymbol ? value : valueByCurrency(_this.state, value, _this.state.selectedCurrency === _this.props.product.ProductSymbol) });
        if (_this.props.useExternalAddress) {
          let decimalPlaces = Number(_this.props.product.decimalPlaces);
          decimalPlaces = (decimalPlaces % 1 === 0) ? decimalPlaces : convertIncrementToIntDecimalPlaces(decimalPlaces);
          maxAmount = formatNumbersWithoutRound(maxAmount, decimalPlaces);
        }
        if(_this.state.selectedCurrency !== _this.props.product.ProductSymbol){
          value = valueByCurrency(_this.state, value, false);
        }
        const result = (value && (value > maxAmount)) ? _this.context.t('Insufficient balance') : null;
        return result;
      }),
      (_this.minAmountPerCurrency = function(value) {
        let min;
        if(_this.state.selectedCurrency !== _this.props.product.ProductSymbol){
          value = valueByCurrency(_this.state, value, false);
        }
        switch (_this.props.product.ProductSymbol) {
          case 'BTC': min = 0.0001; break;
          case 'USDC': min = 10; break;
          default: min = 0; break;
        }
        return (value && value < min) ? `${_this.context.t('The amount must be greater or equal than {value}', { value: min })} ${_this.props.product.ProductSymbol}` : undefined;
      }),
      (_this.handleMax = function(){
        _this.setState({ max: !_this.state.max });
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

_createClass(
  SendForm,
  [
    {
      key: 'render',
      value: function render() {
        const name = this.props.product.ProductFullName,
          symbol = this.props.product.ProductSymbol,
          decimalPlaces = this.props.product.decimalPlaces,
          handleSubmit = this.props.handleSubmit,
          onSubmit = this.props.onSubmit,
          useExternalAddress = this.props.useExternalAddress,
          toggleUseExternalAddress = this.props.toggleUseExternalAddress,
          balance = this.props.balance,
          product = this.props.product,
          userInfo = this.props.userInfo,
          fee = this.props.fee,
          total = this.props.total,
          showDetails = this.props.showDetails,
          disableWithdraw = this.props.disableWithdraw,
          verificationOnClick = this.props.verificationOnClick,
          allowTransfer = this.props.allowTransfer,
          withdrawError = this.props.withdrawError,
          selectedAccountId = this.props.selectedAccountId,
          accounts = this.props.accounts,
          handleTrustListChange = this.props.handleTrustListChange,
          showLabel = this.props.showLabel,
          submitting = this.props.submitting,
          handleMax = this.handleMax,
          title = this.props.title,
          data = this.props.data,
          submitData = this.props.submitData,
          openConfirmModal = this.props.openConfirmModal,
          closeConfirmModal = this.props.closeConfirmModal,
          hideFees = this.props.hideFees,
          classModifiers = this.props.classModifiers,
          isSend = this.props.isSend,
          items = this.props.items,
          info = this.props.info,
          infoHeader = this.props.infoHeader,
          validate = this.props.validate,
          isCoink = this.props.isCoink;

        const currentAccount = accounts.find(element => element.AccountId === selectedAccountId),
          datosNetwork = jsonRed()['withdraw'][symbol],
          defaultValue = (!empty(datosNetwork)) ? datosNetwork[0].value : '',
          stepAmount = (this.props.product.decimalPlaces === 2) ? '0.01' : null;
        let invalid = (this.props.showLabel) ? true : this.props.invalid;
        const debounceRef = React.createRef();

        if(!validate.phone) {
          return <PrimaryButton 
            className='button-required'
            onClick={this.handleModalToValidatePhone}
            text='Verify your phone number to start trading. Click here to verify.'
          />
        }

        if (!authorizedLevel().includes(currentAccount.VerificationLevel)) {
          return <PrimaryButton 
            className='button-required'
            onClick={() => this.handleVerify('/settings/verification-level')}
            text='Verify your identity to start trading. Click here to verify.'
          />
        }
        if(validate['2FA'].includes('transferfunds') && validate['2FA'].includes('createwithdrawticket') && !userInfo.Use2FA){
          return <PrimaryButton 
            className='button-required'
            onClick={() => this.handleVerify('/settings/user')}
            text='You must enable your 2FA (Two-Factor Authentication) to start trading. Click here to continue.'
          />
        }

        const focusInAlias = (checked) => {
          if (checked) {
            document.getElementById('label').focus();
            document.getElementById('label').value = '';
            document.getElementById('label').blur();
            document.getElementById('label').focus();
          } else {
            const id = getIdElement();
            const fieldValue = document.getElementById(id).value
            trustedListData(fieldValue, id);
          }

          return true;
        }

        const trustedListData = async (value, id = 'label') => {
          if (!empty(value)) {
            const inputError = document.getElementById(id).parentNode.querySelectorAll('.send-form__error');

            if (inputError.length === 0) {
              removeErrorMenssage();
              const frequentOperation = document.getElementById('frequent-operation').checked;
      
              if (frequentOperation) {
                if (debounceRef.current) clearTimeout(debounceRef.current);
                debounceRef.current = setTimeout(async() => {
                  await validateTrustListData();
                }, 1000);
              } else {
                removeErrorMenssage();
              }
            }
          }
          
          return true;
        }

        const removeErrorMenssage = (id = null) => {
          if (id) {
            document.getElementById(id).parentNode.querySelectorAll('.ap-input__error').forEach(e => e.remove());
          }

          return true;
        }

        const amountValue = document.getElementById("amount")  && document.getElementById("amount").value ? document.getElementById("amount").value : 0;

        const validateTrustListData = async () => {
          buttonSendCrypto(true);
          const requestValidateTrustList = getRequestValidateTrustList();
          if(requestValidateTrustList.operation === 'External Wallet'){
            const env = (config.global.gateway === 'wss://api.banexcoin.com/WSGateway/') ? 'prod' : 'testnet';
            const valid = WAValidator.validate(requestValidateTrustList.address, this.props.product.ProductSymbol, env);
            if(!valid){
              showErrorMessage('trust-list-address', 'Invalid address');
              return;
            }
          }
          const responseCreateTrustList = await validateTrustList(this.props.userInfo.UserId, requestValidateTrustList)
            .then(result => {
              return result;
            })
            .catch(e => {
              return e.response;
            });

          if (responseCreateTrustList.status !== 200) {
            validateErrorList(responseCreateTrustList.data);
          }else {
            buttonSendCrypto();
          }
      
          return true;
        }

        const aproxValueInCurrencyCrypto = () => {
          if(!this.state.enteredAmount) return 0
          const el = document.getElementById("amount");
          if(!el || !el.value) return 0
          return valueByCurrency(this.state, this.state.enteredAmount, this.state.selectedCurrency === this.props.product.ProductSymbol).toFixed(this.state.selectedCurrency === this.props.product.ProductSymbol ? 2 : this.props.product.decimalPlaces)
        }
        const buttonSendCrypto = (disabled = false) => {      
          document.querySelectorAll('.ap-button__btn').forEach((b) => {
            if (disabled) {
              b.disabled = disabled;
            } else {
              const errorMessage = document.querySelectorAll('.ap-input__error');
              b.disabled = (errorMessage.length === 0) ? false : true;
            }
          });
      
          return true;
        }

        const validateAddress = (elementId = null) => {
          const id = elementId || getIdElement();
          setTimeout(() => {
            const value = document.getElementById(id) ? document.getElementById(id).value : '';
            if(isEmpty(value)) return showErrorMessage(id, this.context.t('Required'));
            if(id === 'trust-list-address'){
              const env = (config.global.gateway === 'wss://api.banexcoin.com/WSGateway/') ? 'prod' : 'testnet';
              const valid = WAValidator.validate(value, this.props.product.ProductSymbol, env);
              if(!valid) return showErrorMessage(id, this.context.t('Invalid address'));
            }
            if(id === 'trust-list-email'){
              const response = email(value);
              if(!isEmpty(response)) return showErrorMessage(id, this.context.t(response));
            }
            return removeErrorMenssage(id);
          }, 200);
        }

        const changeToggle = (id) => {
          validateAddress(id);
          toggleUseExternalAddress();
        }

        const getRequestValidateTrustList = () => {
          const id = getIdElement();
          let requestValidateTrustList = {
            accountId: this.props.selectedAccountId,
            apProductId: this.props.product.ProductId,
            operation: getOperation(),
            label: getLabelValue()
          };
          
          if (this.props.useExternalAddress) {
            requestValidateTrustList.address = document.getElementById(id).value;
          } else {
            requestValidateTrustList.receiveEmail = document.getElementById(id).value;
          }
      
          return requestValidateTrustList;
        }

        const getIdElement = () => {
          return (this.props.useExternalAddress) ? 'trust-list-address' : 'trust-list-email';
        };
        const getOperation = () => {
          return (this.props.useExternalAddress) ? 'External Wallet' : 'Banexcoin Transfer';
        };

        const getLabelValue = () => {
          const frequentOperation = document.getElementById('frequent-operation').checked;
          return (frequentOperation) ? document.getElementById('label').value : null;
        }

        const validateErrorList = (errorList) => {
          if (Object.prototype.hasOwnProperty.call(errorList, 'data')) {
            let fieldId;
            removeErrorMenssage();
      
            for (const key in errorList.data) {
              switch (key) {
                case 'receiveEmail': fieldId = 'trust-list-email'; break;
                case 'address': fieldId = 'trust-list-address'; break;
                default: fieldId = key; break;
              }

              showErrorMessage(fieldId, errorList.data[key]);
            }
          }
      
          return true;
        }

        const showErrorMessage = (fieldId, message) => {
          const element = document.getElementById(fieldId);
      
          if (element) {
            const errorMessage = element.parentNode.querySelectorAll('.send-form__error');
      
            if (errorMessage.length === 0) {
              let divError = document.createElement('div');
              divError.className = 'ap-input__error';
              divError.innerHTML = message;
        
              removeErrorMenssage(fieldId);
              element.parentNode.insertBefore(divError, element.nextSibling);
            }
          }
      
          return true;
        }

        const currentBalance = balance ? formatNumberToLocale(balance, product.ProductSymbol) : '-';
        
        const finalAmount = this.props.product.ProductSymbol === this.state.selectedCurrency ? this.state.enteredAmount : aproxValueInCurrencyCrypto();
        const cleanPrices = (amount, steps) => {
          return Number(Number(amount).toFixed(steps));
        };
        const amount = cleanPrices(useExternalAddress ? finalAmount : total, this.props.product.decimalPlaces);
        const amountFee = formatNumberToLocale(fee, product.ProductSymbol);
        const newAmount = amount - amountFee;
        buttonSendCrypto(newAmount <= 0)
        
        const newCurrentBalance = FormatDecimalCoinkWithSimbols(currentBalance, product.ProductSymbol)
        const handleSelectedCurrency = (ev) => {
          const pricing = getInstrumentPrices(this.props, ev.target.value, this.props.isCoink)
          let aproxDecimalPlaces = 2
          if(this.props.product.ProductSymbol === ev.target.value){
            aproxDecimalPlaces = (this.props.products.find(e => e.Product === (!isCoink ? "USD" : "COP")) || {}).DecimalPlaces || 2
          }else{
            aproxDecimalPlaces = (this.props.products.find(e => e.Product === this.props.product.ProductSymbol) || {}).DecimalPlaces || 8
          }
          this.setState({ selectedCurrency: ev.target.value, pricing, aproxDecimalPlaces });
          this.lessThanBalance(this.state.enteredAmount);
          const amount = valueByCurrency(this.state,this.state.enteredAmount, this.state.selectedCurrency === this.props.product.ProductSymbol)
          this.props.setWithdrawDetails({ amount: this.state.selectedCurrency === this.props.product.ProductSymbol ? this.state.enteredAmount : amount });
        }
        return React.createElement(
          'form',
          {
            onSubmit: handleSubmit(onSubmit),
            className: bemClasses(),
            noValidate: 'novalidate'
          },
          React.createElement(
            'section',
            {
              className: bemClasses('send-to')
            },
            React.createElement(
              'header',
              {
                className: bemClasses('source')
              },
              React.createElement(
                'div',
                {
                  className: bemClasses('source-item-with-border')
                },
                React.createElement(
                  'div',
                  {
                    className: bemClasses(
                      'source-item',
                      { selected: useExternalAddress }
                    ),
                    onClick: function onClick() {
                      !useExternalAddress && changeToggle('trust-list-address');
                    }
                  },
                  this.context.t('External wallet')
                )
              ),
              allowTransfer && React.createElement(
                'div',
                {
                  className: bemClasses(
                    'source-item',
                    { selected: !useExternalAddress }
                  ),
                  onClick: function onClick() {
                    useExternalAddress && changeToggle('trust-list-email');
                  }
                },
                this.context.t('Banexcoin transfer')
              )
            )
          ),
          disableWithdraw ? 
          React.createElement(
            VerificationRequiredContainer,
            {
              disabled: disableWithdraw,
              onClick: verificationOnClick
            }
          ) :
          (useExternalAddress && validate['2FA'].includes('createwithdrawticket') && !userInfo.Use2FA) || (!useExternalAddress && validate['2FA'].includes('transferfunds') && !userInfo.Use2FA) ?
          React.createElement(
            PrimaryButton,
            {
              className: 'button-required',
              onClick: () => this.handleVerify('/settings/user'),
              text: 'Verify your identity to start trading. Click here to verify.'
            }
          ) :
          React.createElement(
            React.Fragment,
            null,
            React.createElement(
              SendAddressContainer,
              {
                product: product,
                balance: balance,
                userEmail: userInfo.Email,
                useExternalAddress: useExternalAddress
              }
            ),
            useExternalAddress &&
            React.createElement(
              'div',
              { className: 'noSelect' },
              <NetworkSelector
                options={ datosNetwork }
                defaultValue={ defaultValue }
              />
            ),
            !withdrawError && React.createElement(
              React.Fragment,
              null,
              <div className='title-max'>
                <p className={bemClasses('label-text')}>{this.context.t(
                  `Amount of {name} to ${!useExternalAddress ? 'send' : 'withdraw'}`,
                  { name }
                  )}
                </p>
                <span onClick={() => {
                  handleMax()
                  const pricing = getInstrumentPrices(this.props, this.props.product.ProductSymbol, this.props.isCoink)
                  this.setState({ selectedCurrency: this.props.product.ProductSymbol, pricing });
                }}>Max</span>
              </div>,
              React.createElement(
                'p',
                {
                  className: bemClasses('label-text send-help-text')
                },
                this.context.t('In my wallet: {amount}',{ amount: newCurrentBalance })
              ),
              React.createElement(
                'section',
                {
                className: `${bemClasses('amounts')} cripto-container-max`
                },
                <div className='input-select'>
                  {
                    React.createElement(
                      APNumberInput,
                      {
                        name: 'Amount',
                        customClass: bemClasses("input-amount-crypto"),
                        decimalPlaces: decimalPlaces,
                        step: stepAmount,
                        id: 'amount',
                        max: this.state.max,
                        balance: currentBalance,
                        validate: [
                          required,
                          this.lessThanBalance,
                          (useExternalAddress) ? this.minAmountPerCurrency : required
                        ]
                      }                    
                    )
                  }
                  <select className='select-currencies-withdraw' onChange={handleSelectedCurrency}>
                    <option selected={this.state.selectedCurrency === this.props.product.ProductSymbol} value={this.props.product.ProductSymbol}>{this.props.product.ProductSymbol}</option>
                    {!isCoink ? 
                      <>
                        <option selected={this.state.selectedCurrency === "USD"} value="USD">USD</option>
                        <option selected={this.state.selectedCurrency === "PEN"} value="PEN">PEN</option>
                      </> :
                        <option selected={this.state.selectedCurrency === "COP"} value="COP">COP</option>
                      }
                    
                  </select>
                  <p className='conversion-p'>≈ {amountWithCurrency({
                    amount: !isCoink ? aproxValueInCurrencyCrypto() : formatCopCurrency(aproxValueInCurrencyCrypto(),this.state.aproxDecimalPlaces).slice(2),
                    product: this.props.product.ProductSymbol === this.state.selectedCurrency 
                        ? this.props.products.find(e => e.Product === (!isCoink ? "USD" : "COP")) || {}
                        : this.props.products.find(e => e.Product === symbol) || {}
                  })}</p>
                </div>,
              ),
            ),
            React.createElement(
              Row,
              { className: 'new-side-component type-crypto' },
              React.createElement(SidePaneDetailsComponent, {
                title,
                classModifiers,
                finalAmount : this.props.product.ProductSymbol === this.state.selectedCurrency ? this.state.enteredAmount : aproxValueInCurrencyCrypto(),
                useExternalAddress,
                isSend,
                data,
                product,
                fee,
                balance,
                total,
                submitData,
                openConfirmModal,
                closeConfirmModal,
                hideFees
              })
            ),
            React.createElement(
              React.Fragment,
              null,
              React.createElement(
                'p',
                { className: bemClasses('label-text') + " note-optional" },
                this.context.t('Add a note (Optional)')
              ),
              React.createElement(
                APInput,
                {
                  name: 'Notes',
                  id: 'note',
                  autoComplete: 'off',
                  customClass: bemClasses(),
                  rows: 3
                }
              )
            ),
            React.createElement(
              React.Fragment,
              null,
              React.createElement(
                'label',
                { className: bemClasses('label-text') },
                React.createElement(
                  APInput,
                  {
                    type: 'checkbox',
                    name: 'FrequentOperation',
                    id: 'frequent-operation',
                    className: 'input-checkbox',
                    checked: showLabel,
                    onChange: (e) => {
                      invalid = true;
                      handleTrustListChange();
                      setTimeout(focusInAlias, 5, e.target.checked);
                      showLabel && validateAddress()
                    }
                  }
                ),
                this.context.t('Add to trusted list')
              )
            ),
            showLabel && React.createElement(
              React.Fragment,
              null,
              React.createElement(
                'p',
                { className: bemClasses('label-text') },
                this.context.t('Alias')
              ),
              React.createElement(
                APInput,
                {
                  name: 'Label',
                  id: 'label',
                  autoComplete: 'off',
                  customClass: bemClasses(),
                  validate: [required, alphanumericspace],
                  onChange: (e) => {
                    trustedListData(e.target.value);
                  }
                }
              )
            ),
            !withdrawError && React.createElement(
              APButton,
              {
                type: 'submit',
                customClass: bemClasses(),
                styleName: 'additive',
                disabled: invalid || submitting || !this.state.validAddress
              },
              this.context.t('Send'),
              ' ',
              name
            )
          )
        );
      }
    }
  ]);

  return SendForm;
})(React.Component);

const mapStateToProps = function mapStateToProps(state) {
  return {
    component: state.component,
    validate: state.validate,
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
    products: state.product.products
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    redirect: path => {
      ownProps.history.push(path);
    },
    updateComponent: payload => {
      dispatch(_updateComponent(payload));
    },
    setWithdrawDetails
  };
};

SendForm.propTypes = {
  product: ProductPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  useExternalAddress: PropTypes.bool.isRequired,
  balance: PropTypes.number.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  fee: PropTypes.number,
  total: PropTypes.number,
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func,
  showLabel: PropTypes.bool
};

SendForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendForm));
