import React from "react";
import PropTypes from "prop-types";
import Tab from "../../../components/common/Tab/Tab";
import OpenOrdersContainer from "./OpenOrders/OpenOrdersContainer";
import InactiveOrdersContainer from "./InactiveOrders/InactiveOrdersContainer";
import ClosedPositionsContainer from "./ClosedPositions/ClosedPositionsContainer";
import FilledOrdersContainer from "./FilledOrders/FilledOrdersContainer";

import "./ordersTable.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function OrdersTableContainer(props, context) {
  const { config, isAuthenticated } = props;
  const _getPanes = () => {
    const panes = [
      {
        menuItem: OpenOrdersContainer.title,
        render: () => <OpenOrdersContainer config={config} />
      },
      {
        menuItem: FilledOrdersContainer.title,
        render: () => <FilledOrdersContainer config={config} />
      },
      {
        menuItem: InactiveOrdersContainer.title,
        render: () => <InactiveOrdersContainer config={config} />
      },
    ];

    return panes;
  };
  return (
    <div className={`orders-table ${!isAuthenticated ? "not-logged-table" : ""}`}>
      {!isAuthenticated && (
        <div className={"not-logged"}>
          <p>
            <Link to="/login">{context.t("Log in")}</Link> {context.t("or")}{" "}
            <Link to="/signup">{context.t("register")}</Link> {context.t("to operate")}
          </p>
        </div>
      )}
      <Tab
        defaultActiveIndex={0}
        panes={_getPanes()}
        classModifiers={props.classModifiers}
        customClass="order-history"
      />
    </div>
  );
}
OrdersTableContainer.mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

OrdersTableContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(OrdersTableContainer.mapStateToProps)(
  OrdersTableContainer
);
