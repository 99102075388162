import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ReceiveFormComponent from './ReceiveFormComponent';

var mapStateToProps = function mapStateToProps(state) {
  var userEmail = state.user.userInfo.Email;
  var selectedAccountId = state.user.selectedAccountId;
  var accounts = state.user.accounts;
  return {
    userEmail: userEmail,
    selectedAccountId: selectedAccountId,
    accounts: accounts
  };
};

var ReceiveForm = reduxForm({
  form: 'ReceiveForm',
  onSubmit: function onSubmit() {}
})(ReceiveFormComponent);

export default connect(
  mapStateToProps,
  null
)(ReceiveForm);
