import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { clearOrderForm } from 'apex-web/lib/redux/actions/orderEntryActions';
import ReportBlockTradeSidePaneComponent from './ReportBlockTradeSidePaneComponent';

const mapStateToProps = state => {
  const { form } = state;
  return {
    form: form.reportBlockTrade
  };
};

const mapDispatchToProps = {
  setBoughtReport: () => change('reportBlockTrade', 'side', buyValue),
  setSoldReport: () => change('reportBlockTrade', 'side', sellValue),
  clearReportBlockTrade: () => clearOrderForm('reportBlockTrade')
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBlockTradeSidePaneComponent);
