import React, { Component } from "react";
import { connect } from "react-redux";
import empty from 'is-empty'
import PropTypes from "prop-types";
import Slider from "react-slick";
import "./stylesV2.css";

const CarouselNews = (props, context) => {
  const { messages, lang } = props;
  const inclUndefined = ['undefined','null','NaN',undefined,null,NaN,'https://'];
  if (empty(messages)) {
    return <React.Fragment />;
  }
  const MyArrow = (props) => {
    const { className, style, onClick } = props;
    const pointer = className.includes('slick-prev') ? "❮" : "❯";
    const pointerV2 = className.includes('slick-prev') ? "left" : "right";
    return (
      <div className={`ArrowContent ${pointerV2}`}>
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      > {pointer} 
      </div>
      </div>
    );
  }
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    nextArrow: <MyArrow />,
    prevArrow: <MyArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };
  return (
    <Slider
      {...settings}
    >
      {messages.map((m, i) => {
        const externalLink = inclUndefined.includes(m.eventExternalLink[lang]) ? ' ' : m.eventExternalLink[lang];
        const linkClass = inclUndefined.includes(m.eventExternalLink[lang]) ? 'link-none' : '';
        return (
          <div key={i} className='img-with'>
            <a href={externalLink} target="_blank" class={linkClass}>
            <img className='carousel-img' src={m.eventImages[lang] || m.eventImages["en"]} alt={`Banner_${i}`}/>
            </a>            
          </div>
        );
      })}
    </Slider>
  );
};
const mapStateToProps = ({ i18nState: { lang = "en" } }) => {
  return { lang };
};

CarouselNews.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(CarouselNews);
