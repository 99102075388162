import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  required,
  email,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import { authorizedLevel } from './../../../helpers/lib';
import './ReceiveForm.css';

var bemClasses = getBEMClasses('receive-form');
var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var ReceiveForm = (function(_React$Component) {
  _inherits(ReceiveForm, _React$Component);

  function ReceiveForm() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ReceiveForm);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          ReceiveForm.__proto__ ||
          Object.getPrototypeOf(ReceiveForm)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.checkUserEmail = function(value) {
        var userEmail = _this.props.userEmail;

        return validateReceiverEmail(value, userEmail);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(ReceiveForm, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          _props$product = _props.product,
          name = _props$product.ProductFullName,
          symbol = _props$product.ProductSymbol,
          decimalPlaces = _props$product.decimalPlaces,
          handleSubmit = _props.handleSubmit,
          invalid = _props.invalid,
          submitting = _props.submitting,
          onChange = _props.onChange,
          onSubmit = _props.onSubmit,
          selectedAccountId = _props.selectedAccountId,
          accounts = _props.accounts;

        let currentAccount = accounts.find(
          element => element.AccountId === selectedAccountId
        );

        if (!authorizedLevel().includes(currentAccount.VerificationLevel)) {
          return (
            <Link
              className="verification-required__container"
              to="/settings/verification-level"
              onClick={() => {
                var cerrar = document.getElementsByClassName(
                  'ap-sidepane__close-button'
                );
                if (cerrar) {
                  cerrar[0].click();
                }
              }}>
              <div className="verification-required">
                <span className="isvg loaded ap-icon ap-icon--alert ap-icon--small verification-required__warning verification-required__warning--alert verification-required__warning--small">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 12 12">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M5.01 1.194c-.006-.05-.01-.1-.01-.15C5 .466 5.448 0 6 0s1 .467 1 1.043c0 .051-.004.102-.01.15C8.72 1.73 10 3.64 10 5.914l.5 2.435h-9L2 5.913C2 3.64 3.28 1.73 5.01 1.193zm-.51 9.24h3C7.5 11.3 6.828 12 6 12s-1.5-.7-1.5-1.565zM.522 8.349h10.956a.522.522 0 0 1 0 1.043H.522a.522.522 0 1 1 0-1.043z"
                    />
                  </svg>
                </span>
                <span className="ap--label verification-required__not-verified ">
                  {this.context.t(
                    'Verify your identity to start trading. Click here to verify.'
                  )}
                </span>
              </div>
            </Link>
          );
        }

        return <div className='deposit-transfer'>
           <div>
          <p className='transfer-title'>{this.context.t("Request {product} from another user Banexcoin Free!", {
            product: name
          })}</p>
        </div>
          {React.createElement(
          'form',
          { onSubmit: handleSubmit(onSubmit), className: bemClasses() },
          React.createElement(
            'section',
            { className: bemClasses('receive-from') },
            React.createElement(
              'p',
              { className: bemClasses('label-text') },
              this.context.t('Email of the person to whom you request {product}', {
                product: name
              })
            ),
            React.createElement(APInput, {
              name: 'ReceiverUsername',
              customClass: bemClasses(),
              onChange: handleSubmit(onChange),
              validate: [required, email, this.checkUserEmail]
            })
          ),
          React.createElement(
            'p',
            { className: bemClasses('label-text') },
            this.context.t('Amount of {name} to receive', { name: name })
          ),
          React.createElement(
            'section',
            { className: bemClasses('amounts') },
            React.createElement(APNumberInput, {
              name: 'Amount',
              customClass: bemClasses(),
              labelInInput: symbol,
              decimalPlaces: decimalPlaces,
              onChange: handleSubmit(onChange),
              validate: [required]
            })
          ),
          React.createElement(
            'section',
            { className: bemClasses('note') },
            React.createElement(
              'p',
              { className: bemClasses('label-text') + " note-optional" },
              this.context.t('Add a note '),
              React.createElement('em', null, this.context.t('(Optional)'))
            ),
            React.createElement(APInput, {
              name: 'Notes',
              rows: 3,
              customClass: bemClasses()
            })
          ),
          React.createElement(
            APButton,
            {
              type: 'submit',
              customClass: bemClasses(),
              styleName: 'additive',
              disabled: invalid || submitting
            },
            this.context.t('Request'),
            ' ',
            name
          )
        )}
        </div>;
      }
    }
  ]);

  return ReceiveForm;
})(React.Component);

ReceiveForm.propTypes = {
  product: product.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ReceiveForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveForm;
