import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { formatDate } from 'apex-web/lib/helpers/dateHelper';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import { kycConfigLevelShape } from 'apex-web/lib/propTypes/kycConfigShapePropTypes';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './KYCConfirmLevelModalComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var classes = getBEMClasses('kyc-confirm-level');

var KYCConfirmLevelModal = (function(_React$Component) {
  _inherits(KYCConfirmLevelModal, _React$Component);

  function KYCConfirmLevelModal() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, KYCConfirmLevelModal);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          KYCConfirmLevelModal.__proto__ ||
          Object.getPrototypeOf(KYCConfirmLevelModal)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.renderImage = function(image, field) {
        return !image
          ? null
          : React.createElement('img', {
              className: classes('section-field-value-img'),
              src: image.imgSrc,
              alt: field.name
            });
      }),
      (_this.renderUpload = function(value) {
        return !value ? null : value.file && value.file.name;
      }),
      (_this.renderValue = function(values, field) {
        var isSimpleValue = !['date', 'image', 'upload', 'document'].includes(
          field.type
        );
        var renderMethods = {
          date: function date(_date) {
            return formatDate(_date);
          },
          image: _this.renderImage,
          upload: _this.renderUpload,
          document: _this.renderUpload
        };
        var value = values[field.name];
        var renderValue = renderMethods[field.type];

        return isSimpleValue ? value : renderValue(value, field);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(KYCConfirmLevelModal, [
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var _props = this.props,
          level = _props.level,
          values = _props.values,
          processing = _props.processing,
          _close = _props.close,
          onConfirm = _props.onConfirm,
          isOpen = _props.isOpen;

        var sectionGroups = level ? chunk(level.sections, 2) : [];

        return React.createElement(
          Modal,
          {
            customClass: classes(),
            isOpen: isOpen,
            title: this.context.t(
              'Confirm Increase Verification Level Application'
            ),
            close: function close() {
              return _close();
            },
            footer: {
              buttonText: processing
                ? this.context.t('Processing...')
                : this.context.t('Confirm'),
              disabled: processing,
              onClick: onConfirm,
              overrideClose: true,
              buttonStyle: 'success'
            }
          },
          React.createElement(
            'div',
            { className: classes('body') },
            React.createElement(
              'div',
              { className: classes('description') },
              this.context.t(
                'Thanks for applying to increase your Verification Level. Once we have approved your application, you will next be required to submit identification documentation.'
              )
            ),
            sectionGroups.map(function(sections, idx) {
              return React.createElement(
                'div',
                { key: idx, className: classes('sections-wrapper') },
                sections.map(function(section, idx) {
                  return React.createElement(
                    'div',
                    { key: section.label + idx, className: classes('section') },
                    React.createElement(
                      'div',
                      { className: classes('section-header') },
                      _this2.context.t(section.label)
                    ),
                    section &&
                      section.form.map(function(field, idx) {
                        return React.createElement(
                          'div',
                          {
                            key: field.name + idx,
                            className: classes('section-field')
                          },
                          React.createElement(
                            'div',
                            { className: classes('section-field-label') },
                            _this2.context.t(field.label),
                            ':'
                          ),
                          React.createElement(
                            'div',
                            { className: classes('section-field-value') },
                            values && _this2.renderValue(values, field)
                          )
                        );
                      })
                  );
                })
              );
            })
          )
        );
      }
    }
  ]);

  return KYCConfirmLevelModal;
})(React.Component);

KYCConfirmLevelModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  level: PropTypes.shape(kycConfigLevelShape),
  processing: PropTypes.bool
};

KYCConfirmLevelModal.contextTypes = {
  t: PropTypes.func.isRequired
};

KYCConfirmLevelModal.defaultProps = {
  processing: false
};

export default KYCConfirmLevelModal;
