import React from 'react';
import PropTypes from 'prop-types';
import APSwitch from 'apex-web/lib/components/common/APSwitch/APSwitch';
import APButton from 'apex-web/lib/components/common/APButton';
import Enable2FAModalContainer from './Enable2FAModalContainer';
import Disable2FAModalContainer from 'apex-web/lib/components/EnableTwoFactorAuth/Disable2FAModalContainer';
import GetGoogle2FARecoveryKeyModalContainer from '../Modal/GetGoogle2FARecoveryKeyModalContainer'
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './EnableTwoFactorAuthComponent.css';

const bemClasses = getBEMClasses('settings-enable-two-factor-auth');

const EnableTwoFactorAuthComponent = function EnableTwoFactorAuthComponent(props, context) {
  const handleClick = function handleClick(newValue) {
    newValue ? props.disable2FA() : props.enable2FA()
  };

  return React.createElement(
    'div',
    { className: bemClasses() },
    React.createElement(
      'div',
      { className: bemClasses('on-off-2fa-container') },
      React.createElement(
        'div',
        { className: bemClasses('switch') },
        React.createElement(APSwitch, { name: 'use2FA', onClick: handleClick })
      ),
      React.createElement(
        'div',
        { className: bemClasses('info') },
        React.createElement(
          'p',
          null,
          context.t('Two-Factor Authentication (2FA) adds another layer of protection to your account by requiring an additional passcode during the logging in process.')
        ),
        React.createElement(
          'p',
          null,
          context.t('You will need to download Google Authenticator on your smartphone as part of the process to enable 2FA.')
        )
      )
    ),
    props.use2FAOriginal && React.createElement(
      'div',
      null,
      [React.createElement(
        APButton,
        {
          name: 'recovery2FA',
          customClass: bemClasses(),
          onClick: props.getGoogle2FARecoveryKey },
        context.t('Recovery key')
      ),
    React.createElement("p",{className:bemClasses('description-button')},context.t("After activating your 2FA, remember to handwrite your recovery key and keep it in a safe place. This can be used in the future to restore your Google Authenticator (2FA), in case of damage or loss of your mobile device."))]
    ),
    React.createElement(Enable2FAModalContainer, null),
    React.createElement(Disable2FAModalContainer, null),
    React.createElement(GetGoogle2FARecoveryKeyModalContainer, null)
  );
};

EnableTwoFactorAuthComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

EnableTwoFactorAuthComponent.propTypes = {
  getGoogle2FARecoveryKey: PropTypes.func.isRequired,
  disable2FA: PropTypes.func.isRequired,
  enable2FA: PropTypes.func.isRequired,
  use2FAOriginal: PropTypes.bool.isRequired
};

export default EnableTwoFactorAuthComponent;