import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getAllWithdraws = (status, userId) => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/withdraws/${status}`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'get',
    headers,
    path
  });
};
