import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ProblemLoginForm from '../Components/ProblemLoginForm';
import LayoutAuth from '../Components/LayoutAuth/LayoutAuth';

const ProblemLogginPage = (props, context) => {
  const seo = {
    title: '¿Problemas para iniciar sesión? | Banexcoin',
    description:
      'Accede a Banexcoin, invierte y gana criptomonedas desde la comodidad de tu casa.',
    url: 'https://www.banexcoin.com/login/',
    image:
      'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
  };
  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description }
        ]}>
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <LayoutAuth url="https://www.banexcoin.com">
        <ProblemLoginForm />
      </LayoutAuth>
    </React.Fragment>
  );
};

ProblemLogginPage.contextTypes = {
  t: PropTypes.func
};

export default ProblemLogginPage;
