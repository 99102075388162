import { connect } from 'react-redux';
import KYC_IMLevelsComponent from './KYC_IMLevelsComponent';
import { openKYC_IMSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    openKYCSidePane: function openKYCSidePane() {
      return dispatch(openKYC_IMSidePane());
    }
  };
};

var KYC_IMLevelsContainer = connect(
  null,
  mapDispatchToProps
)(KYC_IMLevelsComponent);

export default KYC_IMLevelsContainer;
