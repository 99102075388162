import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'is-empty';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { openRetailSendReceiveSidePane, openRetailFiatSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import ProductList from './ProductList';
import apex from 'apex-web/lib/apex';
import { useHistory } from 'react-router-dom';
import { withdrawalAction as _withdrawalAction } from '../../redux/actions/withdrawal_action';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';

const ProductListContainer = (props) => {
    let { withdrawalAction, selectPositionAndSave, title, products, openSendReceiveSidePane, openRetailFiatSidePane } = props;
    const history = useHistory();
    if (!isEmpty(products)) {
        products = products.sort((a, b) => a.ProductType.localeCompare(b.ProductType))
    }
    const userEventUpdate = () => {
        apex.connection.ws
            .filter(x => x.n === 'TransferFunds')
            .subscribe(response => {
                const res = JSON.parse(response.o);
                if (res.result) {
                    const res2 = JSON.parse(res.detail);
                    withdrawalAction({
                        onClick: () => {
                            withdrawalAction({})
                            history.push('/wallets/product-details')
                            selectPositionAndSave(res2.ProductId)
                        }
                    })
                }
            });
    };
    useEffect(() => {
        userEventUpdate();
    }, [apex]);
    return <ProductList title={title} products={products} openSendReceiveSidePane={openSendReceiveSidePane} openRetailFiatSidePane={openRetailFiatSidePane} />
}
const mapStateToProps = function mapStateToProps(state) {
    const products = productPositionAssetsSelector(state);
    const productsByLevel = state.productsByLevel;
    const newProducts = (!isEmpty(products) && !isEmpty(productsByLevel)) ? products.filter(e => productsByLevel.includes(e.ProductSymbol)) : [];
    return { products: newProducts };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
    return {
        openSendReceiveSidePane: function openSendReceiveSidePane(isSend, product) {
            dispatch(openRetailSendReceiveSidePane(isSend, product));
        },
        openRetailFiatSidePane: function openRetailFiatSidePane(isSend, product) {
            dispatch(openRetailFiatSidePane(isSend, product))
        },
        withdrawalAction: function withdrawalAction(payload) {
            return dispatch(_withdrawalAction(payload));
        },
        selectPositionAndSave: function selectPositionAndSave(id) {
            dispatch(_selectPositionAndSave(id));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer) 