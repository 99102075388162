import React, { useState } from 'react'
import { fixNumberWithoutRound2 } from '../../pages/utils';
import { useEffect } from 'react';
import isEmpty from 'is-empty';

const RangeButtons = ({
    max,
    inputValue,
    name,
    onChange,
    decimal
}) => {

    const percentages = [25, 50, 75, 100];

    const [amountByPercentage, setAmountByPercentage] = useState({});

    const changeValue = value =>
        onChange(
            name,
            Number(fixNumberWithoutRound2((Number(max) * Number(value)) / 100, decimal))
        );

    useEffect(() => {
        let amount = {};
        percentages.forEach((element) => {
            amount[element] = fixNumberWithoutRound2((Number(max) * element) / 100, decimal);
        });
        setAmountByPercentage(amount)
    }, [max])

    return (
        <div className="range_container">
            <div className="buttonsContainer buy-sell-range-buttons">
                {percentages.map(value => (
                    <button
                        key={value}
                        className={`buttonOption ${inputValue > 0 && amountByPercentage[value] === Number(inputValue) ? 'selected-buySell' : ''}`}
                        onClick={ev => {
                            ev.preventDefault();
                            changeValue(value);
                        }}>
                        {value} %
                    </button>
                ))}
            </div>
        </div>
    );
}

export default RangeButtons