import { useState } from 'react';
import empty from 'is-empty';
import isEmpty from 'is-empty';

export const useForm = (initialState = {}, callback, customValidations) => {
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState({});

  const onSetBillingAddress = value => {
    if (value) {
      setInputs(inputs => ({
        ...inputs,
        billing_country: inputs.residence_country,
        billing_address: inputs.address_main,
        billing_building: inputs.building,
        billing_town: inputs.town,
        billing_apt_number: inputs.apt_number,
        billing_state: inputs.state,
        billing_city: inputs.city,
        billing_zip: inputs.zip_code,
        billing_district: inputs.district
      }));
    } else {
      setInputs(inputs => ({
        ...inputs,
        billing_country: '',
        billing_address: '',
        billing_building: '',
        billing_town: '',
        billing_state: '',
        billing_apt_number: '',
        billing_city: '',
        billing_district: '',
        billing_zip: ''
      }));
    }
  };

  const onSetLinkedEconomicGroup = () => {
    setInputs(inputs => ({
      ...inputs,
      linked_economic_group_country: '',
      linked_economic_group_doc_type: '',
      linked_economic_group_doc_number: '',
      linked_economic_group_name: ''
    }));
  };

  const onSetLinkedEconomicGroupDocumentType = value => {
    setInputs(inputs => ({
      ...inputs,
      linked_economic_group_doc_type: value
    }));
  };

  const onInputChange = e => {
    e.persist();

    if (e.target.id === 'linked_economic_group_country') {
      let value = '';
      if (e.target.value === 'PE') {
        value = 'RUC';
        setInputs(inputs => ({
          ...inputs,
          [e.target.id]: e.target.value
        }));
        onSetLinkedEconomicGroupDocumentType(value);
      } else {
        setInputs(inputs => ({
          ...inputs,
          [e.target.id]: e.target.value
        }));
        onSetLinkedEconomicGroupDocumentType(value);
      }
    }

    if (e.target.id === 'linked_economic_group') {
      if (e.target.value === 'No') {
        setInputs(inputs => ({
          ...inputs,
          [e.target.id]: e.target.value
        }));
        onSetLinkedEconomicGroup();
      } else {
        setInputs(inputs => ({
          ...inputs,
          [e.target.id]: e.target.value
        }));
      }
    }

    if (e.target.id === 'is_billing_address_residence') {
      let state = !inputs.is_billing_address_residence;
      setInputs(inputs => ({
        ...inputs,
        [e.target.id]: state
      }));
      onSetBillingAddress(state);
    }

    if (e.target.id === 'is_invoice_with_ruc') {
      let state = !inputs.is_invoice_with_ruc;
      setInputs(inputs => ({
        ...inputs,
        [e.target.id]: state
      }));
    } else {
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: e.target.value
      }));
    }
  };

  const onDefaultChange = (e) => {
    const { name, value } = e.target;
    if(!isEmpty(errors[name])){
      delete errors[name]
    }
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const onInputChangeWithCallback = (e,callback) => {
    let inputsTemp = {
      ...inputs,
      [e.target.name]: e.target.value
    };
    if(!isEmpty(errors[e.target.name])){
      delete errors[e.target.name]
    }
    let returnedObject = {}
    if(callback){
      returnedObject = callback()
    }
    setInputs({...inputsTemp,...returnedObject});
  };
  const onInputChangeValidation = (e,callback) => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    let inputsTemp = {
      ...inputs,
      [e.target.name]: e.target.value
    };
    let returnedObject = {}
    if(callback){
      returnedObject = callback()
    }
    setInputs({...inputsTemp,...returnedObject});
    customValidations(inputsTemp);
  };

  const onInputChangeValidationEmail = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    const emailParts = e.target.value.split('@')
    const inputsTemp = {
      ...inputs,
      [e.target.name]: emailParts[0]
    };
    setInputs(inputsTemp);
    if (emailParts[1]){
      return `@${emailParts[1]}`
    }
    return ""
  };

  const onInputChangeValidationByName = (name, value) => {
    let inputsTemp = {
      ...inputs,
      [name]: value
    };
    setInputs(inputsTemp);
    customValidations(inputsTemp);
  };

  const onInputChangeCleanValidation = e => {
    e.persist();
    let inputsTemp = {
      ...inputs,
      [e.target.name]: (e.target.value || '').replace(/[^0-9a-z]/gi, '')
    };
    setInputs(inputsTemp);
    customValidations(inputsTemp);
  };

  const onCheckboxChangeValidation = e => {
    e.persist();
    let inputsTemp = {
      ...inputs,
      [e.target.name]: e.target.checked
    };
    setInputs(inputsTemp);
    customValidations(inputsTemp);
  };
  const onCheckboxChange = e => {
    e.persist();
    let inputsTemp = {
      ...inputs,
      [e.target.name]: e.target.checked
    };
    setInputs(inputsTemp);
  };

  const onInputChangeByName = (name, value) => {
    setInputs(inputs => ({
      ...inputs,
      [name]: value
    }));
  };

  const onInputFileChange = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    const newInputs = {
      ...inputs,
      [e.target.name]: e.target.files[0]
    }
    setInputs(newInputs);
    return newInputs;
  };

  const onInputFileChangeImages = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    delete errors[e.target.name]
    let dataInputs = {
      ...inputs,
      [e.target.name]: null
    };
    if (!empty(e.target.files) && e.target.files.length > 0) {
      dataInputs[e.target.name] = e.target.files[0]
    };
    setInputs(dataInputs);
  }

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    setInputs(inputs => ({
      ...inputs,
      validated: true
    }));

    let errorsCustom = customValidations(inputs);
    setErrors(errorsCustom);
    if (form.checkValidity() && empty(errorsCustom)) {
      callback(inputs);
      form.reset();
    }
  };

  const onSubmitSimple = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    let inputsTemp = {
      ...inputs,
      validated: true
    };
    setInputs(inputsTemp);
    let errorsCustom = customValidations(inputsTemp);
    setErrors(errorsCustom);
    if (form.checkValidity() && empty(errorsCustom)) {
      callback(inputsTemp,form,errorsCustom);
    }
  };
  const onSubmitSimpleWithoutValidation = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      callback(inputs);
    }
  };

  return {
    onDefaultChange,
    onInputChange,
    onInputChangeValidation,
    onInputChangeValidationEmail,
    onInputChangeValidationByName,
    onInputChangeCleanValidation,
    onCheckboxChangeValidation,
    onInputChangeByName,
    onInputFileChange,
    onInputFileChangeImages,
    onCheckboxChange,
    onSubmit,
    onSubmitSimple,
    setInputs,
    setErrors,
    onSubmitSimpleWithoutValidation,
    onInputChangeWithCallback,
    errors,
    inputs
  };
};
