import React, { useState, useEffect } from 'react';
import { convertType } from './../../../helpers/lib';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import {
  getLevelBasic
} from '../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import AutoFormV2 from './AutoFormV2';
import { useForm } from '../../../hooks/formHooks';
import {
  getNormalLevelStatus,
  validateField,
  validateLevelNumber
} from '../../../helpers/lib';
import Loading from '../../../components/Loading';
import {
  submitFormVipDependentData,
  resendFormVipDependenData
} from './../KYCV2Hooks';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const KYCV2VipDependentForm = (props, context) => {
  try {
    let { userInfo, selectedAccountId, history, accounts, redirect } = props;
    let currentAccount = accounts.find(a => a.AccountId === selectedAccountId);
    let requestedLevel = 14;
    const [loading, setLoading] = useState(1);
    const [levelInfo, setLevelInfo] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [imagesBlob, setImagesBlob] = useState({});
    const [messageData, setMessageData] = useState('');
    const [err, setErr] = useState({});

    const setErrorValidation = inputsData => {
      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputsData);
      setErr(errorsCustom);
    };

    const setBlobByName = (name, blob) => {
      setImagesBlob(imagesBlob => ({
        ...imagesBlob,
        [name]: blob
      }));
    };
    async function setValidate(val) {
      setInputs(inputs => ({
        ...inputs,
        validated: val
      }));
    }

    async function setInputsBasic(basicLevel) {
      let Tempinputs = {
        ...inputs,
        id: basicLevel.id,
        first_name: basicLevel.firstName,
        middle_name: basicLevel.middleName,
        last_name: basicLevel.lastName,
        second_last_name: basicLevel.secondLastName,
        birthday: !empty(basicLevel.birthday)
          ? moment(basicLevel.birthday, 'YYYY-MM-DD')
          : null,
        nationality: basicLevel.nationality,
        birth_country: basicLevel.birthCountry,
        residence_country: basicLevel.residenceCountry,
        address_main: basicLevel.addressMain,
        building: basicLevel.building,
        apt_number: basicLevel.apt,
        city: basicLevel.city,
        state: basicLevel.state,
        town: basicLevel.town,
        district: basicLevel.district,
        zip_code: basicLevel.zipCode,
        occupation: basicLevel.occupation,
        occupation_details: basicLevel.occupationDetails,
        work_center: basicLevel.workCenter,
        phone: basicLevel.phone,
        is_pep: basicLevel.isPep,
        billing_country: basicLevel.billingCountry,
        billing_address: basicLevel.billingAddress,
        billing_building: basicLevel.billingBuilding,
        billing_town: basicLevel.billingTown,
        billing_district: basicLevel.billingDistrict,
        billing_apt_number: basicLevel.billingAptNumber,
        billing_state: basicLevel.billingState,
        billing_city: basicLevel.billingCity,
        billing_zip: basicLevel.billingZip,
        is_billing_address_residence: false,
        document_type: basicLevel.documentType,
        document_country: basicLevel.documentCountry,
        document_number: basicLevel.documentNumber,
        document_data: basicLevel.documentData,
        back_document_data: basicLevel.backDocumentData,
        faces_images: basicLevel.facesImages,
        status: basicLevel.status,
        edit_fields: basicLevel.editFields,
        user_level: basicLevel.userLevel,
        next_level: basicLevel.nextLevel,
        is_invoice_with_ruc: false,
        personal_ruc: '',
        is_sunat_validating: false
      };
      if (basicLevel.masterWorkerType === 'dependent') {
        Tempinputs.master_origin_type = basicLevel.masterOriginType;
        Tempinputs.master_others = basicLevel.masterOthers;
        Tempinputs.master_origin_document = basicLevel.masterOriginDocument;
        Tempinputs.master_affidavit_document =
          basicLevel.masterAffidavitDocument;
      }
      setInputs(Tempinputs);

      setErrorValidation(Tempinputs);
    }

    async function loadLevelInfo() {
      setLoading(1);
      try {
        let result = await getLevelBasic(userInfo.UserId, selectedAccountId);
        if (
          !empty(result) &&
          !empty(result.data) &&
          !empty(result.data.level_basic)
        ) {
          let level_basic = result.data.level_basic;
          const nextLevel = level_basic.nextLevel;
          let type = convertType(level_basic.masterWorkerType);
          if (!empty(type) && type.toLowerCase() !== 'dependent') {
            redirect(`/verification/master-${type.toLowerCase()}`);
          }
          if (nextLevel === requestedLevel) {
            setLevelInfo(levelInfo => ({
              ...levelInfo,
              status: getNormalLevelStatus(level_basic.status)
            }));
          }
          const documentFields = ['master_origin_document', 'master_affidavit_document']
          const nameField = {
            master_origin_document: 'masterOriginDocument',
            master_affidavit_document: 'masterAffidavitDocument'
          }
          let editFields = [];
          if(!empty(level_basic.editFields && level_basic.status === 5)){
            editFields = JSON.parse(level_basic.editFields)
            editFields.forEach(element => {
              if (documentFields.includes(element)) {
                level_basic[nameField[element]] = ''
              }
            });
          }
          setInputsBasic(level_basic);
        }
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }

    useEffect(
      () => {
        if (
          !empty(userInfo) &&
          !empty(selectedAccountId) &&
          !empty(currentAccount)
        ) {
          if (
            !validateLevelNumber(
              currentAccount.VerificationLevel,
              requestedLevel
            )
          ) {
            toast.warn(
              context.t(
                'Your current level is not valid to request this increase'
              ),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              }
            );
            history.push('/settings/verification-level');
          } else {
            loadLevelInfo();
          }
        }
      },
      [userInfo, selectedAccountId, currentAccount]
    );

    /* terminando carga al iniciar */
    const onSubmitCallback = async inputs => {
      setLoading(1);
      try {
        if (empty(err)) {
          if (
            !empty(levelInfo) &&
            !empty(levelInfo.status) &&
            levelInfo.status.hasOwnProperty('id') &&
            levelInfo.status.id === 5
          ) {
            let resultResend = await resendFormVipDependenData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (
              resultResend.hasOwnProperty('data') &&
              !empty(resultResend.data) &&
              resultResend.data.message === 'success'
            ) {
              history.push('/verification/success');
            }
          } else {
            let result = await submitFormVipDependentData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (result.hasOwnProperty('data')) {
              history.push('/verification/success');
            }
          }
        } else {
          console.log('err:::', err);
        }
      } catch (error) {
        console.error('error on onSubmitCallback', error);
        if (!empty(error.response) && !empty(error.response.data.data)) {
          setMessageData({
            type: 'error',
            text: context.t(error.response.data.data.error_message)
          });
        } else {
          setMessageData({
            type: 'error',
            text: context.t('Information could not be saved')
          });
        }
      }
      setLoading(0);
    };

    const onSubmitSimplewithoutErrors = e => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      let inputForm = {
        ...inputs,
        banexcoin_user_id: userInfo.UserId,
        banexcoin_user_name: userInfo.UserName,
        banexcoin_user_account_id: selectedAccountId,
        email: !empty(userInfo.Email) ? userInfo.Email : '',
        billing_country: inputs.residence_country,
        billing_address: inputs.address_main,
        billing_state: inputs.state,
        billing_building: inputs.building,
        billing_town: inputs.town,
        billing_district: inputs.district,
        billing_apt_number: inputs.apt_number,
        billing_city: inputs.city,
        billing_zip: inputs.zip_code,
        validated: true
      };

      setInputs(inputForm);

      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputForm);
      setErr(errorsCustom);
      if (empty(errorsCustom)) {
        onSubmitCallback(inputForm, form);
      }
    };

    const customValidations = inputs => {
      let errors = {};
      return errors;
    };

    let initialState = {
      validated: false,
      worker_type: 'dependent'
    };

    const onInputChangeV2 = e => {
      e.persist();
      let inputsTemp = {
        ...inputs,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        validated: true
      };
      setInputs(inputsTemp);
      setErrorValidation(inputsTemp);
    };

    const onInputFileChangev2 = e => {
      if (typeof e.persist === 'function') {
        e.persist();
      }
      let inputsTemp = {
        ...inputs,
        [e.target.name]: e.target.files[0],
        validated: true
      };
      setErrorValidation(inputsTemp);
      if(!e.accepted){
        setErr({ [e.target.name]: true });
        inputsTemp[e.target.name] = null
      } 
      setInputs(inputsTemp);
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    let optionsDocument = [];
    if (!empty(inputs) && inputs.nationality === 'PE') {
      optionsDocument = [{ value: 'ID', name: 'National identity document' }];
    } else {
      optionsDocument = [{ value: 'PP', name: 'Passport' }];
    }

    const translate = data => {
      return empty(data)
        ? {}
        : data.map(r => {
            return {
              id: context.t(r.id),
              name: context.t(r.name)
            };
          });
    };

    let steps = [
      {
        title: '',
        hint: [],
        groups: [
          {
            title: 'Dependent Worker',
            hint: [
              {
                p: 'Please provide the following information in PDF format.'
              }
            ],

            fields: [
              {
                label: 'Document supporting the Origin of your Funds',
                name: 'master_origin_type',
                type: 'select',
                size: 12,
                options: [
                  {
                    value: '',
                    label: 'PROOF OF THE ORIGIN OF BANKED FUNDS:',
                    name: ''
                  },
                  {
                    value: 'Cancellation of an account by Retirement',
                    name: 'Cancellation of an account by Retirement'
                  },
                  {
                    value:
                      'Savings account statement issued by financial entity',
                    name: 'Savings account statement issued by financial entity'
                  },
                  {
                    value: 'Proof of Remittances from Exterior',
                    name: 'Proof of Remittances from Exterior'
                  },
                  {
                    value: '',
                    label: 'PROOF OF THE ORIGIN OF UNBANKED FUNDS:',
                    name: ''
                  },
                  {
                    value: 'Sale of Products(Copies of invoices, sales slips)',
                    name: 'Sale of Products(Copies of invoices, sales slips)'
                  },
                  { value: 'Sale of Real Estate', name: 'Sale of Real Estate' },
                  {
                    value: 'Sale of Movable Property',
                    name: 'Sale of Movable Property'
                  },
                  {
                    value: 'Rental of Personal Property (copies of contracts)',
                    name: 'Rental of Personal Property (copies of contracts)'
                  },
                  {
                    value: 'Properties (copies of contracts)',
                    name: 'Properties (copies of contracts)'
                  },
                  {
                    value: 'Other types of rental (copies of contracts)',
                    name: 'Other types of rental (copies of contracts)'
                  },
                  {
                    value:
                      'Corporate Contributions (Minutes of boards of directors registered in Public records)',
                    name:
                      'Corporate Contributions (Minutes of boards of directors registered in Public records)'
                  },
                  {
                    value:
                      'Inheritance (Intestate Succession registered in Public Registries)',
                    name:
                      'Inheritance (Intestate Succession registered in Public Registries)'
                  },
                  {
                    value: 'Donations (Notary certificate or Public deed)',
                    name: 'Donations (Notary certificate or Public deed)'
                  },
                  {
                    value:
                      'Funds from meetings of collective savings (DJ with data of contributors)',
                    name:
                      'Funds from meetings of collective savings (DJ with data of contributors)'
                  },
                  {
                    value:
                      'Receipt for Honoraries (According to amount traded)',
                    name: 'Receipt for Honoraries (According to amount traded)'
                  },
                  {
                    value: 'Payment slip for payroll honoraries',
                    name: 'Payment slip for payroll honoraries'
                  },
                  { value: 'Others (Specify)', name: 'Others (Specify)' }
                ],
                displayOn: [],
                props: [
                  {
                    required: true
                  }
                ]
              },
              {
                label: 'Indicate the Type of Document to Upload',
                name: 'master_others',
                type: 'text',
                size: 12,
                displayOn: [
                  {
                    parent: 'master_origin_type',
                    conditionValue: 'Others (Specify)'
                  }
                ],
                props: [
                  {
                    required: true,
                    placeholder: 'Indicate the Type of Document to Upload'
                  }
                ]
              },
              {
                label: 'Document',
                name: 'master_origin_document',
                type: 'file',
                size: 12,
                subdata: [
                  {
                    type: 'download-text',
                    className: 'important',
                    label:
                      'Upload files .PDF / .JPG / .JPEG / .PNG and no larger than 5MB.'
                  }
                ],
                displayOn: [],
                props: [
                  {
                    className: 'big-input',
                    required: true,
                    accept: '.pdf, .png, .jpeg, .jpg'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        title: '',
        hint: [],
        groups: [
          {
            title: 'Affidavit of Origin of Funds',
            hint: [
              {
                p: 'Please provide the following information in PDF format.'
              }
            ],

            fields: [
              {
                label: 'Affidavit of Origin of Funds',
                name: 'master_affidavit_document',
                type: 'file',
                size: 12,
                subdata: [
                  {
                    type: 'download-text',
                    className: 'important',
                    label:
                      'Upload files .PDF / .JPG / .JPEG / .PNG and no larger than 5MB.',
                    stepTitle: 'Steps to follow',
                    stepTexts: [
                      {
                        text: context.t(
                          "Click <a href='{link}' target='_blank'>here</a> to download Annex B",
                          {
                            link: context.t(
                              'https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/dependent/AFFIDAVIT%20OF%20ORIGIN%20OF%20FUNDS%20%28Dependent%20worker%29.pdf'
                            )
                          }
                        )
                      },
                      {
                        text: 'Then fill in the downloaded document.'
                      },
                      {
                        text: 'Finally, upload the file to be validated.'
                      }
                    ]
                  }
                ],
                displayOn: [],
                props: [
                  {
                    className: 'big-input',
                    accept: '.pdf, .png, .jpeg, .jpg'
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="v2-box w-8">
          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmitSimplewithoutErrors}>
            <AutoFormV2
              inputs={inputs}
              steps={steps}
              onInputChange={onInputChangeV2}
              onInputFileChange={onInputFileChangev2}
              onInputChangeByName={onInputChangeByName}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              imagesBlob={imagesBlob}
              setBlobByName={setBlobByName}
              requestedLevel={1}
              setValidate={setValidate}
              messageData={messageData}
              className="col-xl-11"
              isShareholder={true}
              isDisabled={
                !empty(levelInfo.status) ? levelInfo.status.isBlocked : false
              }
              validateField={validateField}
              err={err}
              setErr={setErr}
              title={'<b>Master Dependent</b> level increase'}
            />
          </Form>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2VipDependentForm error', e);
    return <div>Error:</div>;
  }
};

KYCV2VipDependentForm.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    redirect: path => {
      ownProps.history.push(path);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCV2VipDependentForm);
