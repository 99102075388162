import React, { useState, useEffect } from 'react';
import empty from 'is-empty';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setUserConfig,
  selectAccount
} from 'apex-web/lib/redux/actions/userActions';
import { logout } from 'apex-web/lib/redux/actions/authActions';
import { changeLanguage } from 'apex-web/lib/redux/actions/languageActions';
import {
  Button,
  Dropdown
} from 'react-bootstrap';
import ProfileIcon from '../../../images/icons-v2/Profile.svg'
import SettingIcon from '../../../images/icons-v2/Setting.svg'
import NotificationIcon from '../../../images/icons-v2/Notification.svg'
import apex from 'apex-web/lib/apex';
import './UserDropdown.css';
import {
  IconProfileSecurity,
  IconSignup,
  IconUser
} from './../../../components/common/BnxIcons';
import QRBanexApp from '../../../images/QR-Banex-app.png';

const UserDropdown = (props, context) => {
  let {
    userInfo: { UserName, Email, UserId },
    userInfo,
    user: { accounts },
    logout,
    selectedAccountId,
    selectAccountSwitcher,
    verificationLevelConfigs = {},
  } = props;
  const [accountConfigs, setAccountConfigs] = useState({});
  const [settingHelpText, setSettingHelpText] = useState(true);
  const [usernameHelpText, setUsernameHelpText] = useState(true);
  const [showQRCode, setShowQRCode] = useState(false);

  let levels = [
    {
      level: 0,
      name: '<b>No</b> Verified Level'
    },
    {
      level: 10,
      name: '<b>Explorer</b> Level'
    },
    {
      level: 11,
      name: '<b>Amateur</b> Level'
    },
    {
      level: 12,
      name: '<b>Intermediate</b> Level'
    },
    {
      level: 13,
      name: '<b>Advanced</b> Level'
    },
    {
      level: 14,
      name: '<b>Master</b> Level'
    },
    {
      level: 17,
      name: '<b>Not verified</b>'
    },
    {
      level: 18,
      name: '<b>Corporate</b> Level'
    },
    {
      level: 5,
      name: '<b>Unknown</b> Level'
    },
    {
      level: 6,
      name: '<b>Unknown</b> Level'
    },
    {
      level: 100,
      name: '<b>You need to verify your account</b> level'
    },
    {
      level: 101,
      name: '<b>Coink</b> Level'
    },
  ];

  let levelIncreaseStatusData = [
    {
      status: 'INPROGRESS',
      className: 'inProgress',
      text: 'In progress'
    },
    {
      status: 'VERIFIED',
      className: 'verified',
      text: 'Verified'
    },
    {
      status: 'NOTVERIFIED',
      className: 'notVerified',
      text: 'Not Verified'
    },
    {
      status: 'REJECTED',
      className: 'rejected',
      text: 'Rejected'
    },
    {
      status: 'INCORRECT_INFORMATION',
      className: 'rejected',
      text: 'Incorrect Information'
    }
  ];

  const isCoink = verificationLevelConfigs.Level >= 100

  const currentStatusLevel = levelIncreaseStatusData.find(
    s =>
      s.status ===
      (!empty(accountConfigs)
        ? accountConfigs.verificationProgress
        : 'VERIFIED')
  ) || {
    status: '',
    className: 'verified',
    text: 'Verified'
  };

  const { VerificationLevel: verificationLevel, AccountName } =
    accounts.filter(account => account.AccountId === selectedAccountId)[0] ||
    {};
  const currentLevel = !empty(verificationLevel)
    ? levels.find(l => l.level === verificationLevel)
    : levels.find(l => l.level === 0);

  const switchAccount = async (accountId, omsid) => {
    try {
      await selectAccountSwitcher(accountId, omsid);
    } catch (error) {
      console.log('Error on switchAccount', error);
    }
  };

  const loadAccountConfig = async () => {
    try {
      const gac = await apex.connection.GetAccountConfig({
        AccountId: selectedAccountId,
        OMSId: 1
      });
      const accountType = gac.find(a => a.Key === 'ACCOUNT_TYPE');
      const verificationProgress = gac.find(a => a.Key === 'KYC_STATUS');
      const configAccount = {
        accountType: !empty(accountType) ? accountType.Value : 'NATURAL',
        verificationProgress: !empty(verificationProgress)
          ? verificationProgress.Value
          : 'NOSTATUS'
      };
      setAccountConfigs(configAccount);
    } catch (error) {
      console.log('error on loadlevelmaster::', error);
    }
  };

  useEffect(
    () => {
      if (!empty(userInfo) && !empty(selectedAccountId) && !empty(accounts)) {
        loadAccountConfig();
      }
    },
    [userInfo, selectedAccountId, accounts]
  );

  const handleShowHelpText = (type) => {
    switch (type) {
      case 'settings':
        setSettingHelpText(!settingHelpText);
        break;
      case 'username':
        setUsernameHelpText(!usernameHelpText);
        break;
    }
  };

  const handleLogout = () => {
    logout();
    const language = window.localStorage.getItem("language")
    window.localStorage.clear()
    window.localStorage.setItem("language", language)
    window.fcWidget.user.clear()
  }

  return (
    <React.Fragment>
      <div className="v2-navbar-bnx">
        <div
          className="qr-section visibility"
          onMouseEnter={() => setShowQRCode(true)}
          onMouseLeave={() => setShowQRCode(false)}
        >
          <p className="text-app">{context.t("Download our app")}</p>
          {showQRCode && (
            <div className="qr-container">
              <img src={QRBanexApp} alt="Banex app" />
              <p>{context.t("Download the IOS and Android app")}</p>
            </div>
          )}
        </div>
        <span className="visibility">|</span>
        <Dropdown onToggle={() => handleShowHelpText("settings")}>
          <Dropdown.Toggle
            split
            variant="success"
            className="nav-top-items item-setting"
          >
            <img src={SettingIcon} alt="Setting icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="left" className="normal-childrens">
            <Dropdown.Item as={Link} to="/settings/security/">
              <React.Fragment>{context.t("Security")}</React.Fragment>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/settings/trade-reports/">
              <React.Fragment>{context.t("Trade Reports")}</React.Fragment>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/settings/trust-list/">
              <React.Fragment>{context.t("Trust list")}</React.Fragment>
            </Dropdown.Item>
            {!isCoink ? (
              <React.Fragment>
                <Dropdown.Item as={Link} to="/settings/affiliate/">
                  <React.Fragment>
                    {context.t("Referral Program​")}
                  </React.Fragment>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/settings/accounts/">
                  <React.Fragment>
                    {context.t("My Banking Accounts")}
                  </React.Fragment>
                </Dropdown.Item>
              </React.Fragment>
            ): 
            <Dropdown.Item as={Link} to="/settings/withdrawal">
              <React.Fragment>
                {context.t("Automatic withdrawal")}
              </React.Fragment>
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
        <span className='line2'>|</span>
        <a
          target='_blank'
          href={
            localStorage.getItem("language") === "es"
              ? "https://soporte.banexcoin.com/es-LA/support/home"
              : "https://soporte.banexcoin.com/en/support/home"
          }
          className="text-app link"
        >
          {context.t("Help")}
        </a>
        <span>|</span>
        <Dropdown onToggle={() => handleShowHelpText("username")}>
          <Dropdown.Toggle
            split
            variant="success"
            className="nav-top-items item-profile">
            <p className="visibility">{accounts.length > 1 ? AccountName : UserName}</p>
            <div>
              <img src={ProfileIcon} alt="Icon profile" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu align="right">
            {accounts.length > 1 ? (
              <React.Fragment>
                <Dropdown.Header className="username">
                  <b>{AccountName}</b>
                </Dropdown.Header>
                {accounts
                  .filter((account) => account.AccountId !== selectedAccountId)
                  .map((account, index) => {
                    return (
                      <Button
                        className="btn-user"
                        key={index}
                        onClick={() => {
                          switchAccount(account.AccountId, account.OMSID);
                        }}>
                        {context.t("Switch to {accountName}", {
                          accountName: account.AccountName,
                        })}
                      </Button>
                    );
                  })}
              </React.Fragment>
            ) : (
              <Dropdown.Header className="username">
                <b>{UserName}</b>
              </Dropdown.Header>
            )}
            <Dropdown.Header className="basic-info">
              <b>
                {!empty(currentLevel) ? (
                  <div className="level-info">
                    <span
                      className="level-name"
                      dangerouslySetInnerHTML={{
                        __html: context.t(currentLevel.name),
                      }}
                    />{" "}
                    {!empty(currentStatusLevel.status) && (
                      <span
                        className={
                          "is-verified " + currentStatusLevel.className
                        }>
                        {context.t(currentStatusLevel.text)}
                      </span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </b>
            <Dropdown.Header className="email" style={{ paddingLeft: "0" }}>
              <b>{Email}</b>
            </Dropdown.Header>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to="/settings/verification-level">
              <React.Fragment>
                <IconUser /> {context.t("Verification Level")}
              </React.Fragment>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to="/settings/user">
              <React.Fragment>
                <IconProfileSecurity /> {context.t("Profile")}
              </React.Fragment>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Button} onClick={handleLogout}>
              <IconSignup /> {context.t("Logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts, verificationLevelConfigs  },
  user,
  i18nState: { lang }
}) => {
  return { selectedAccountId, userInfo, accounts, user, lang, verificationLevelConfigs  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    changeLanguage: payload => {
      const language = payload.language;
      const UserId = payload.UserId;
      const UserName = payload.UserName;
      const configValue = payload.configValue;
      dispatch(setUserConfig(UserId, UserName, configValue));
      dispatch(changeLanguage(language));
    },
    selectAccountSwitcher: (accountId, OmdId) =>
      dispatch(selectAccount(accountId, OmdId))
  };
};

UserDropdown.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDropdown);
