import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';

export const updateInformationHook = (data, userId) => {
  let headers = {};
  let path = `/api/v2/personal-data-updating`;
  let model = {
    userId: data.userId,
    accountId: data.accountId,
    accountType: data.accountType,
    incorporationCountry: data.incorporationCountry,
    billingCountry: data.billingCountry,
    birthCountry: data.birthCountry,
    residenceCountry: data.residenceCountry,
    ceBirthday: data.ceBirthday,
    documentType: data.documentType,
    documentNumber: data.documentNumber
  };
  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
