import React, { useState } from 'react';
import empty from 'is-empty';
import { Table, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import Dialog from 'react-bootstrap-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { buildSecureGetParams } from '../../helpers/lib';
import { deleteLevelCorporateBetaShareholderRecordById } from '../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import { getLabel } from './../utils';
import CorporateLevelPageBeta from '../Level/CorporateLevelPageBeta';

const CorporateShareholdersListBeta = (props, context) => {
  let {
    userInfo,
    shareholders,
    selectedAccountId,
    loadLevel,
    corporateDataStatus,
    handleCorporateRepresentativeLegalShow,
    handleCorporatePersonShow,
    handleCorporateCompanyShow
  } = props;
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>{context.t('Loading...')}</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);

    const loadRowsRepresentativeLegal = () => {
      let rows = [];
      let shareholders = props.shareholders;
      if (empty(shareholders)) {
        return (
          <tr>
            <td colSpan="14">
              {this.props.loading > 0 ? 'Actualizando información...' : ''}
            </td>
          </tr>
        );
      }
      shareholders.sort(
        (a, b) => Number(b.percentage_number) - Number(a.percentage_number)
      );
      for (let k in shareholders) {
        if (getLabel(shareholders[k].is_legal_representative) === 'Yes') {
          let edit_fields = !empty(shareholders[k].editFields)
            ? JSON.parse(shareholders[k].editFields)
            : [];
          let fullname = [
            shareholders[k].first_name,
            shareholders[k].middle_name,
            shareholders[k].last_name,
            shareholders[k].second_last_name
          ];
          let notNullNames = fullname.filter(x => x !== null);
          let returnValue = notNullNames.join(' ');
          returnValue = returnValue.replace(/  +/g, ' ');
          let type = getLabel(shareholders[k].shareholderType);
          rows.push(
            <tr key={k} className={`shareholde-table ${!empty(edit_fields) ? 'correct-fields' : ''}`}>
              <td>{returnValue}</td>
              <td>{context.t(type)}</td>
              <td>{shareholders[k].percentage_number}</td>
              <td>{getLabel(shareholders[k].is_legal_representative)}</td>
              <td>{shareholders[k].document_type}</td>
              <td>{shareholders[k].document_number}</td>
              {corporateDataStatus === 'ACCEPTED' ||
              corporateDataStatus === 'REVIEW_REQUESTED' ? (
                <td />
              ) : (
                <>
                  <td className='shareholder-list-btn'>
                    {loadOptionsDelete(shareholders[k])}
                    {!empty(edit_fields) ? (
                      <Button
                        variant="success"
                        onClick={async () => {
                          handleCorporateRepresentativeLegalShow(
                            shareholders[k].id
                          );
                        }}>
                        {context.t('Correct Fields')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </td>
                </>
              )}
            </tr>
          );
        }
      }
      return rows;
    };

    const loadRows = () => {
      let rows = [];
      let shareholders = props.shareholders;
      if (empty(shareholders)) {
        return (
          <tr>
            <td colSpan="14">
              {this.props.loading > 0 ? 'Actualizando información...' : ''}
            </td>
          </tr>
        );
      }

      for (let k in shareholders) {
        if (
          shareholders[k].shareholderType === 'PERSON_SHAREHOLDER' &&
          shareholders[k].is_legal_representative !== '1'
        ) {
          let edit_fields = !empty(shareholders[k].editFields)
            ? JSON.parse(shareholders[k].editFields)
            : [];
          let fullname = [
            shareholders[k].first_name,
            shareholders[k].middle_name,
            shareholders[k].last_name,
            shareholders[k].second_last_name
          ];
          let notNullNames = fullname.filter(x => x !== null);
          let returnValue = notNullNames.join(' ');
          returnValue = returnValue.replace(/  +/g, ' ');
          let type = getLabel(shareholders[k].shareholderType);
          rows.push(
            <tr key={k} className={`shareholde-table ${!empty(edit_fields) ? 'correct-fields' : ''}`}>
              <td>{returnValue}</td>
              <td>{context.t(type)}</td>
              <td>{shareholders[k].percentage_number}</td>
              <td>{getLabel(shareholders[k].is_legal_representative)}</td>
              <td>{shareholders[k].document_type}</td>
              <td>{shareholders[k].document_number}</td>
              {corporateDataStatus === 'ACCEPTED' ||
              corporateDataStatus === 'REVIEW_REQUESTED' ? (
                <td />
              ) : (
                <>
                  <td className='shareholder-list-btn'>
                    {loadOptionsDelete(shareholders[k])}
                    {!empty(edit_fields) ? (
                      <Button
                        variant="success"
                        onClick={async () => {
                          handleCorporatePersonShow(shareholders[k].id);
                        }}>
                        {context.t('Correct Fields')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </td>
                </>
              )}
            </tr>
          );
        }
      }
      return rows;
    };

    const loadRowsCompany = () => {
      let rows = [];
      let shareholders = props.shareholders;
      if (empty(shareholders)) {
        return (
          <tr>
            <td colSpan="14">
              {this.props.loading > 0 ? 'Actualizando información...' : ''}
            </td>
          </tr>
        );
      }

      for (let k in shareholders) {
        if (shareholders[k].shareholderType === 'COMPANY_SHAREHOLDER') {
          let edit_fields = !empty(shareholders[k].editFields)
            ? JSON.parse(shareholders[k].editFields)
            : [];
          let type = getLabel(shareholders[k].shareholderType);
          rows.push(
            <tr key={k} className={`shareholde-table ${!empty(edit_fields) ? 'correct-fields' : ''}`}>
              <td>{shareholders[k].company_legal_name}</td>
              <td>{context.t(type)}</td>
              <td>{shareholders[k].percentage_number}</td>
              <td>
                {context.t(shareholders[k].taxpayer_identification_number_type)}
              </td>
              <td>{shareholders[k].taxpayer_identification_number}</td>
              {corporateDataStatus === 'ACCEPTED' ||
              corporateDataStatus === 'REVIEW_REQUESTED' ? (
                <td />
              ) : (
                <>
                  <td className='shareholder-list-btn'>
                    {loadOptionsDelete(shareholders[k])}
                    {!empty(edit_fields) ? (
                      <Button
                        variant="success"
                        onClick={async () => {
                          handleCorporateCompanyShow(shareholders[k].id);
                        }}>
                        {context.t('Correct Fields')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </td>
                </>
              )}
            </tr>
          );
        }
      }
      return rows;
    };

    const confirmDelete = async id => {
      const r = window.confirm(
        context.t('Do you really want to delete the record?')
      );
      if (r === true) {
        setLoading(1);
        await deleteLevelCorporateBetaShareholderRecordById(
          userInfo.UserId,
          selectedAccountId,
          id
        );
        setLoading(0);
        await loadLevel();
      }
    };

    const loadOptionsDelete = row => {
      return (
        <div>
          <OverlayTrigger
            key={row.id}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-${row.id}`}>
                {context.t('Delete Shareholder')}
              </Tooltip>
            }>
            <Button
              variant="info"
              onClick={async () => {
                confirmDelete(row.id);
              }}>
              <FontAwesomeIcon icon="minus-circle" />
            </Button>
          </OverlayTrigger>
        </div>
      );
    };
    return (
      <div className="col-lg-12">
        <Loading loading={loading} />
        <Dialog
        // ref={component => {
        //   this.dialog = component;
        // }}
        />
        <Card>
          <Card.Body>
            {!empty(shareholders) ? (
              <Table
                bordered
                hover
                responsive
                className="table-addlevelrepresentative">
                <thead>
                  <tr>
                    <th colSpan="7" className="header-name-table">
                      {context.t('Add Legal Representative')}
                    </th>
                  </tr>
                  <tr className="header-title-table">
                    <th>{context.t('Full Name')}</th>
                    <th>{context.t('Shareholder Type')}</th>
                    <th>{context.t('Percentage Number %')}</th>
                    <th>{context.t('Is a Legal Representative?')}</th>
                    <th>{context.t('Document Type')}</th>
                    <th>{context.t('Document Number')}</th>
                    <th>{context.t('Options')}</th>
                  </tr>
                </thead>
                <tbody>{loadRowsRepresentativeLegal()}</tbody>
              </Table>
            ) : (
              <p>{context.t('There are no records to show')}</p>
            )}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            {!empty(shareholders) ? (
              <Table
                bordered
                hover
                responsive
                className="table-addlevelrepresentative">
                <thead>
                  <tr>
                    <th colSpan="7" className="header-name-table">
                      {context.t('Add new Shareholder')}
                    </th>
                  </tr>
                  <tr className="header-title-table">
                    <th>{context.t('Full Name')}</th>
                    <th>{context.t('Shareholder Type')}</th>
                    <th>{context.t('Percentage Number %')}</th>
                    <th>{context.t('Is a Legal Representative?')}</th>
                    <th>{context.t('Document Type')}</th>
                    <th>{context.t('Document Number')}</th>
                    <th>{context.t('Options')}</th>
                  </tr>
                </thead>
                <tbody>{loadRows()}</tbody>
              </Table>
            ) : (
              <p>{context.t('There are no records to show')}</p>
            )}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            {!empty(shareholders) ? (
              <Table
                bordered
                hover
                responsive
                className="table-addlevelrepresentative">
                <thead>
                  <tr>
                    <th colSpan="7" className="header-name-table">
                      {context.t('Add Company as Shareholder')}
                    </th>
                  </tr>
                  <tr className="header-title-table">
                    <th>
                      {context.t('Denomination or Full Legal Name of Company')}
                    </th>
                    <th>{context.t('Shareholder Type')}</th>
                    <th>{context.t('Percentage Number %')}</th>
                    <th>
                      {context.t('Type of Taxpayer Identification Number')}
                    </th>
                    <th>{context.t('Taxpayer Identification Number')}</th>
                    <th>{context.t('Options')}</th>
                  </tr>
                </thead>
                <tbody>{loadRowsCompany()}</tbody>
              </Table>
            ) : (
              <p>{context.t('There are no records to show')}</p>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  } catch (e) {
    console.log('error interno', e);
    return <div>Error:</div>;
  }
};

CorporateShareholdersListBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CorporateShareholdersListBeta;
