import { connect } from 'react-redux';
import ReceiveAddressComponent from './ReceiveAddressComponent';
import { fetchProductDepositTemplate } from './../../../redux/actions/depositActions';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state) {
  var selectedAccountId = state.user.selectedAccountId;
  var accounts = state.user.accounts;
  return {
    deposit: state.deposit,
    WalletsDisabledMessage: config.global.WalletsDisabledMessage,
    selectedAccountId: selectedAccountId,
    accounts: accounts
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    selectDepositProduct: function selectDepositProduct(productId) {
      return dispatch(fetchProductDepositTemplate(productId));
    },
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveAddressComponent);
