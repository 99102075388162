import HandlerBackend from "../helpers/HandlerBackend"

class AssetsController{
    static async getAvailableAssets({ userInfo }){
        try{
            const response = await HandlerBackend.sendRequest({
                userId: userInfo.UserId,
                method: "GET",
                path: "/api/v1/assets"
            })
            return response.data.assets
        }catch(err){
            return false 
        }
    }
}

export default AssetsController