import { toast } from "react-toastify";
import { getB89BalanceyAccountId, getB89ParametersByAccountId, getB89TransactionsByAccountId, getCardInfo, getToken } from "./CardService";

class CardController {
    static getB89Info = async ({
        userInfo,
        setIsBanexcardUser,
        setLoadingBanexCard,
        setBanexcardInfo,
        setParametersB89
    }) => {
        try {
            setLoadingBanexCard(true)
            const { UserId, AccountId } = userInfo
            const { data: { data: { b89CardIds, b89ProductId, b89PartnerId, clientId } } } = await getB89ParametersByAccountId(UserId, AccountId)
            setIsBanexcardUser(true)
            setParametersB89({ b89CardIds, b89ProductId, b89PartnerId, clientId })
            const { data: { access_token } } = await getToken();
            const { data: { data: cardInfo } } = await getCardInfo(b89CardIds, b89ProductId, b89PartnerId, access_token, clientId)
            const { data: { data: balance } } = await getB89BalanceyAccountId(UserId, AccountId)
            setBanexcardInfo({
                cardInfo: cardInfo || {},
                balance: balance || {},
                transactions: []
            })
        } catch (error) {
        } finally {
            setLoadingBanexCard(false)
        }
    };

    static getB89Transactions = async ({
        userInfo,
        setBanexcardInfo,
        setLoadingTransactions,
        setInitialTransactions,
        context
    }) => {
        try {
            setLoadingTransactions(true)
            const { UserId, AccountId } = userInfo
            const { data: { data: transactions } } = await getB89TransactionsByAccountId(UserId, AccountId)
            setBanexcardInfo(info => ({ ...info, transactions: transactions || [] }))
            setInitialTransactions(false)
        }
        catch (error) {
            toast.warn(context.t("Information could not accesible"), { position: toast.POSITION.TOP_CENTER })
        }
        finally {
            setLoadingTransactions(false)
        }
    }

    static getB89Balances = async ({
        userInfo,
        setBanexcardInfo,
    }) => {
        try {
            const { UserId, AccountId } = userInfo
            const { data: { data: balance } } = await getB89BalanceyAccountId(UserId, AccountId)
            setBanexcardInfo(info => ({ ...info, balance: balance || {} }))
        }
        catch (error) { }
    }

    static getB89CardInfo = async ({
        parametersB89,
        setBanexcardInfo
    }) => {
        try {
            const { b89CardIds: b89CardId, b89ProductId, b89PartnerId, clientId } = parametersB89;
            const { data: { access_token } } = await getToken();
            const { data: { data: cardInfo } } = await getCardInfo(b89CardId, b89ProductId, b89PartnerId, access_token, clientId)
            setBanexcardInfo(info => ({ ...info, cardInfo: cardInfo || {} }))
        }
        catch (error) { }
    }

}

export default CardController;