import React from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect';
import APButton from 'apex-web/lib/components/common/APButton';
import APDatePicker from 'apex-web/lib/components/common/APDatePicker/APDatePicker';
import { required } from 'apex-web/lib/helpers/formValidations';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ReportAccountsComponent from 'apex-web/lib/components/ReportSidePaneComponents/ReportAccountsComponent';

import 'apex-web/lib/components/ReportSidePaneComponents/ReportFormComponent.css';

const baseClasses = getBEMClasses('report-form');

const ScheduleReportFormComponent = function ScheduleReportFormComponent(props, context) {
  const accounts = props.accounts,
      handleSubmit = props.handleSubmit,
      buttonDisabled = props.buttonDisabled;

  return React.createElement(
    'form',
    { onSubmit: handleSubmit, className: baseClasses('body') },
    React.createElement(
      'div',
      { className: baseClasses('form-body') },
      React.createElement(ReportAccountsComponent, { accounts: accounts }),
      React.createElement(APSelect, {
        name: 'type',
        label: context.t('Report Type:'),
        customClass: baseClasses(),
        validate: [required],
        options: [{ value: 'TradeActivity', label: context.t('Trade Activity') }, { value: 'Transaction', label: context.t('Transaction') }],
        showTriangles: true
      }),
      React.createElement(
        'div',
        { className: baseClasses('datapicker-container') },
        React.createElement(APDatePicker, {
          name: 'startDate',
          label: context.t('Start date:'),
          minDate: new Date(),
          customClass: baseClasses(),
          validate: [required],
          initialOptions: { theme: 'retail' }
        }),
        React.createElement(APSelect, {
          name: 'frequency',
          label: context.t('Frequency:'),
          customClass: baseClasses(),
          classModifiers: 'small',
          validate: [required],
          options: [{ value: 'Hourly', label: context.t('Hourly') }, { value: 'Daily', label: context.t('Daily') }, { value: 'Weekly', label: context.t('Weekly') }, { value: 'Monthly', label: context.t('Monthly') }, { value: 'Annual', label: context.t('Annual') }],
          showTriangles: true
        })
      )
    ),
    React.createElement(
      'div',
      { className: baseClasses('footer') },
      React.createElement(
        APButton,
        {
          type: 'submit',
          customClass: baseClasses(),
          styleName: 'additive',
          disabled: buttonDisabled },
        context.t('Create')
      )
    )
  );
};

ScheduleReportFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

ScheduleReportFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ScheduleReportFormComponent;