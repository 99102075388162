import isEmpty from "is-empty"
import DepositAlertsServices from "./DepositAlertsServices"

class DepositAlertsController{
    static limitValidationByLevel = async ({userInfo,accountLevel,amount,currency}) => {
        try {
            const response = await DepositAlertsServices.limitValidation({userInfo,accountLevel,amount,currency})
            if(!isEmpty(response.data) && response.status === 200){
                return response.data
            }
            return false
        } catch (error) {
            return false
        }
    }
    static limitValidationCreatorLog = async ({userInfo,accountLevel}) => {
        try {
            const response = await DepositAlertsServices.createLog({userInfo, accountLevel})
            if(!isEmpty(response.data) && response.status === 200){
                return response.data.result
            }
            return false
        } catch (error) {
            return false
        }
    }
}

export default DepositAlertsController