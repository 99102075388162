import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CardGradient, OptionsForm } from '../../../components'
import isEmpty from 'is-empty'

function ProviderAvailableLocationForProvince(props, context) {
  const { address, handleSteps, setCustomAddress, deliveryAreas, levelBasic = {} } = props
  const [selectedOption, setSelectedOption] = useState("")
  const [options, setOptions] = useState([])

  const handleSelectedOption = (title) => setSelectedOption(title)

  const actions = [
    {   
      label: context.t("Previous"),
      action: handleSteps.previous,
      className: "secondary",
  },
    {
      label: context.t("Next"),
      action:() => {
        if(isEmpty(selectedOption)) return
        setCustomAddress(selectedOption)
        handleSteps.next()
      },
      otherAttributes:{
        disabled: isEmpty(selectedOption)
      }
    }
  ]

  useEffect(() => {
    const opts = deliveryAreas.filter(area => area.department.trim().includes(levelBasic.state) && (area.province.includes(levelBasic.city) || area.address.includes(levelBasic.city)))
    setOptions(opts.map(area => ({title: "Olva Courier", description:area.address})))
  },[])

  return (
    <div className='provider-available-location-province'>
      <CardGradient actions={actions}>
        <p>{context.t("Shipping options")}</p>
        <div className='my-location'>
          <span>{context.t("You selected your registration address for shipping.")}</span>
          <p>{address}</p>
        </div>
        <strong>{context.t("For now, we cannot send your card to your home, but you can select the nearest or preferred location to pick it up there personally with your ID or CE.")}</strong>
        <OptionsForm options={options} handleSelectedOption={handleSelectedOption} selectedOption={selectedOption} id="description" containerClassName="delivery-scrollable-list"/>
      </CardGradient>
    </div>
  )
}

ProviderAvailableLocationForProvince.contextTypes = {
  t: PropTypes.func.isRequired,
}
export default ProviderAvailableLocationForProvince