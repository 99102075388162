import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import empty from 'is-empty';
import path from '../helpers/path';
import PropTypes from 'prop-types';
import apex from 'apex-web/lib/apex';
import { validateLevelNumber, isMayor, isMayorIgual } from '../helpers/lib';
import HandlerBackend from '../helpers/HandlerBackend';
import Slider from 'react-slick';
import arrowIcon from '../images/icons-v2/ArrowRight.svg'
import arrowIcon2 from '../images/icons-v2/ArrowRight2.svg'
import verifiedIcon from '../images/icons-v2/Verified.svg'
import './globalHooks.css'
import CircleBxn from './Dashboard/shared/CircleBxn';
import isEmpty from 'is-empty';
import { Spinner } from '../components/Spinner/Spinner';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import APIcon from '../components/common/APIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InformationIcon from '../images/bnx-icons/information-circle.svg'

const mapStateToProps = (state) => {
  const { user, limits } = state
  const { selectedAccountId, accounts, userInfo } = user
  return { selectedAccountId, accounts, userInfo, limits };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

const LevelBlocksComponent = (props, context) => {
  const [accountConfigs, setAccountConfigs] = useState({});
  const { limits, showSnack, selectedAccountId, accounts, userInfo, config = {}, className = '', resendGenerate } = props;
  const [link, setLink] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [monthlyLimit, setMonthlyLimit] = useState([]);
  const [levelsNaturalV2, setLevelsNaturalV2] = useState([
    {
      parentClassname: 'initialLevel amateurLevel-bg',
      parentName: 'Amateur',
      levelNumber: 11,
      levelDescription: 'For beginners starting at the universe of the Crypto',
      path: `${link}`
    },
    {
      parentClassname: 'intermediateLevel intermediateLevel-bg',
      parentName: 'Intermediate',
      levelNumber: 12,
      levelDescription:
        'For those who have a certain level of knowledge and handling Crypto',
      path: '/verification/intermediate'
    },
    {
      parentClassname: 'masterLevel advancedLevel-bg',
      parentName: 'Advanced',
      levelNumber: 13,
      levelDescription: 'For Experts who have extensive knowledge in Crypto',
      path: '/verification/advanced'
    },
    {
      parentClassname: 'corporateLevel masterLevel-bg',
      parentName: 'Master',
      levelNumber: 14,
      levelDescription: 'A new level with better benefits',
      path: '/start/master'
    }
  ]);
  const [coinkLevels,setCoinkLevels] = useState([
    {
      parentClassname: 'initialLevel amateurLevel-bg',
      parentName: 'You need to verify your account',
      levelNumber: 101,
      levelDescription: 'To access the benefits of deposit, withdrawal, purchase and sale in Banexcoin, you must verify your account.',
      path: `${link}`
    },
  ])
  const [errorMessage, setErrorMessage] = useState('')

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrowIcon} alt="Arrow icon" />
      </div>
    );
  }
  
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={arrowIcon} alt="Arrow icon" />
      </div>
    );
  }

  const [settings, setSettings] = useState({});

  const lng = localStorage.getItem('language') || 'en';

  let currentAccount = [];
  for (let i = 0; i < accounts.length; i++) {
    var cuentaEncontrada = accounts[i];
    if (cuentaEncontrada.AccountId === selectedAccountId) {
      currentAccount.push(cuentaEncontrada);
    }
  }
  const account = currentAccount[0];

  let kycStatus = [
    {
      name: 'NOSTATUS',
      text: 'Verify your identity',
      isPending: true
    },
    {
      name: 'INPROGRESS',
      text: 'In progress',
      isPending: true
    },
    {
      name: 'VERIFIED',
      text: 'Verified',
      isPending: false
    },
    {
      name: 'REJECTED',
      text: 'Rejected',
      isPending: true
    },
    {
      name: 'INCORRECT_INFORMATION',
      text: 'Incorrect information',
      isPending: true
    }
  ];

  let levelsCorporateV2 = [
    {
      parentClassname: 'initialLevel zeroLevel hideButton',
      parentName: 'Not Verified',
      levelNumber: 17,
      levelDescription: 'You can use minimal Banexcoin features',
      path: ''
    },
    {
      parentClassname: 'corporateLevel',
      parentName: 'Corporate Level',
      levelNumber: 18,
      levelDescription: 'Exclusive level for Entities & Corpotations',
      path: '/kyc/corporate'
    }
  ];

  const loadLevelMaster = async () => {
    try {
      setInitial(true)
      let gac = await apex.connection.GetAccountConfig({
        AccountId: selectedAccountId,
        OMSId: 1
      });

      let accountType = gac.find(a => a.Key === 'ACCOUNT_TYPE');
      let verificationProgress = gac.find(a => a.Key === 'KYC_STATUS');
      let residenceCountry = gac.find(a => a.Key === 'residenceCountry');

      let configAccount = {
        accountType: !empty(accountType) ? accountType.Value : 'NATURAL',
        verificationProgress: !empty(verificationProgress)
          ? verificationProgress.Value
          : 'NOSTATUS',
        residenceCountry: !empty(residenceCountry) ? residenceCountry.Value : ''
      };
      const isCoink = [100, 101].includes(account.VerificationLevel);
      if(isCoink){
        const newData = {
          'INPROGRESS' : {
            title: 'Verification in process',
            description: 'Your verification is in process, you will be notified by email once the process is complete.'
          },
          'VERIFIED': {
            title: 'Verified',
            description: 'Now you can access the benefits of deposit, withdrawal, purchase and sale in Banexcoin!'
          },
          'NOSTATUS': {
            title: 'You need to verify your account',
            description: 'To access the benefits of deposit, withdrawal, purchase and sale in Banexcoin, you must verify your account.'
          },
        }
        const newCoinkLevelsNaturalV2 = coinkLevels.map(item => {
          if(item.levelNumber === 101){
            return {
              ...item,
              parentName: newData[configAccount.verificationProgress] ? newData[configAccount.verificationProgress].title : newData['NOSTATUS'].title,
              levelDescription: newData[configAccount.verificationProgress] ? newData[configAccount.verificationProgress].description : newData['NOSTATUS'].description,
              path: configAccount.verificationProgress === 'INPROGRESS' ? '/verification/success' : item.path,
            }
          }
          return item
        })
        setCoinkLevels(newCoinkLevelsNaturalV2)
      }
      setAccountConfigs(configAccount);
    } catch (error) {
      console.log('error on loadlevelmaster::', error);
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
    } finally{
      setInitial(false)
    }
  };

  const assignLinkToLevel = (link) => {
    const newLevelsNaturalV2 = levelsNaturalV2.map(item => {
      if(item.levelNumber === 11){
        return {
          ...item,
          path: link
        }
      }
      return item
    })
    setLevelsNaturalV2(newLevelsNaturalV2)
    const newCoinkLevelsNaturalV2 = coinkLevels.map(item => {
      if(item.levelNumber === 101){
        return {
          ...item,
          path: link
        }
      }
      return item
    })
    setCoinkLevels(newCoinkLevelsNaturalV2)
  }

  const insertLinkToCoink = (link) => {
    const newCoinkLevelsNaturalV2 = coinkLevels.map(item => {
      if(item.levelNumber === 101){
        return {
          ...item,
          path: link
        }
      }
      return item
    })
    setCoinkLevels(newCoinkLevelsNaturalV2)
  }

  const generate = async () => {
    try {
      const path = `/api/v1/generate`;
      const response = await HandlerBackend.sendRequest({
          userId: userInfo.UserId,
          method: 'POST',
          path,
          data: {
              banexcoin_account_id: selectedAccountId
          }
      });
      setToken(response.data.responseId);
      const link = `${process.env.REACT_APP_KYC}/${response.data.responseId}?desktop=true&language=${lng}`
      setLink(link)
      assignLinkToLevel(link)
      insertLinkToCoink(link)
    } catch (error) {
      if(!error.response || !error.response.data || !error.response.data.code) return
      const code = error.response.data.code
      const message = error.response.data.message
      if(code === "CORE032"){
        const link = !empty(accounts) && accounts[0].VerificationLevel > 18 ? "/verification/success" : "/verification/beginner" 
        setLink(link)
        assignLinkToLevel(link)
        insertLinkToCoink(link)
      } else {
        setErrorMessage(message)
      }
    }
  }

  useEffect(() => {
    if(!isEmpty(limits)){
      let monthlyDepositLimit = {};
      limits.forEach((e) => {
        let products = {};
        e.Products.forEach((p) => {
          products[p.ProductName] = p.MonthlyDepositLimit
        });
        monthlyDepositLimit[e.Level] = products;
      });
      setMonthlyLimit(monthlyDepositLimit)
    }
  }, [limits])

  useEffect(() => {
    if(link.includes(`${process.env.REACT_APP_KYC}`)){
      const completeLink = `${process.env.REACT_APP_KYC}/${token}?desktop=true&language=${lng}`
      setLink(completeLink)
      assignLinkToLevel(completeLink)
      insertLinkToCoink(completeLink)
    }
  }, [lng])

  useEffect(
    () => {
      if (!empty(userInfo) && !empty(selectedAccountId) && !empty(accounts) && !empty(account)) {
        loadLevelMaster();
      }
    },
    [userInfo, selectedAccountId, accounts, account]
  );
  
  useEffect(() => {
    if (!initial && !empty(userInfo) && !empty(selectedAccountId) && !empty(account) && ( account.VerificationLevel === 0 || account.VerificationLevel === 10 || account.VerificationLevel === 100 )) {
      generate()
    }
  }, [userInfo, selectedAccountId, account, resendGenerate, initial])

  useEffect(() => {
    if (
      loading &&
      !empty(accountConfigs) &&
      !empty(account) &&
      !empty(accountConfigs.residenceCountry)
      ) {
        let newLevelsNaturalV2 = levelsNaturalV2;
        if (accountConfigs.residenceCountry === 'PE') {
          if(account.VerificationLevel > 0){
            newLevelsNaturalV2.unshift({
              parentClassname: 'zeroLevel explorerLevel-bg',
              parentName: 'Explorer',
              levelNumber: 10,
              levelDescription: 'You can use minimal Banexcoin features',
              path: ''
            });
          }
        }
      let initial = levelsData.map((e) => e.levelNumber).indexOf(account.VerificationLevel) || 0;
      initial = initial < 0 ? 0 : initial;
      const textLevel = getClassName(account.VerificationLevel === 0 ? 10 : account.VerificationLevel + 1)
      const textToValidate = ['In progress', 'Rejected','Incorrect information']
      if(textToValidate.includes(textLevel.text)) initial = initial + 1;
      if(className === 'card-config-v2' && initial === 4) initial = 3;
      setLevelsNaturalV2(newLevelsNaturalV2);
      setSettings({
        ...config,
        initialSlide: initial,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      })
      setLoading(false)
    }
  }, [accountConfigs, account, loading])

  useEffect(() => {
    if(accountConfigs.accountType === 'CORPORATE'){
    setSettings({
      ...config,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: 1,
    })
    }
    if(accountConfigs.accountType === 'NATURAL' && (account && (account.VerificationLevel === 100 || account.VerificationLevel === 101))){
    setSettings({
      ...config,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: 0,
    })
    }
  }, [accountConfigs])
  

  if (empty(userInfo) || empty(selectedAccountId) || empty(account)) {
    return <div className='global-hooks initial-data'>
      <Spinner color='orange'/>
    </div>;
  }

  const getClassName = nro => {
    let checkLevel = validateLevelNumber(account.VerificationLevel, nro);
    let esMayor = isMayor(account.VerificationLevel, nro);

    let foundStatus = kycStatus.find(
      s => s.name === accountConfigs.verificationProgress
    );
    let defaultStatus = kycStatus.find(s => s.name === 'NOSTATUS');
    let statusL =
      !empty(foundStatus) &&
      checkLevel &&
      accountConfigs.verificationProgress !== 'VERIFIED'
        ? foundStatus
        : defaultStatus;

    return {
      className:
        'PendingVerifiedStatus ' +
        (checkLevel
          ? statusL.name.toLowerCase()
          : esMayor
            ? 'pending'
            : 'hidden'),
      text: !empty(statusL.text) ? statusL.text : 'Start Verification'
    };
  };

  const isCorporative = [17, 18].includes(account.VerificationLevel);
  const isCoink = [100, 101].includes(account.VerificationLevel);

  let levelsData = (accountConfigs.accountType === 'CORPORATE' || isCorporative)
  ? levelsCorporateV2 
  : isCoink
  ? coinkLevels
  : levelsNaturalV2;
  
  const isNextLevel = (currentLevel, levelNumber) => {
    const newcurrentLevel = !currentLevel ? (accountConfigs.accountType ==='NATURAL' ? 10 : 17) : currentLevel
    const levels = [0, 10, 11, 12, 13, 14, 17, 18, 100, 101];
    return levels.indexOf(newcurrentLevel) + 1 === (levels.indexOf(levelNumber) || 1);
  }

  const showErrorMessage = () => {
    if(!isEmpty(errorMessage)){
      showSnack({
        id: 'error_message',
        text: `<p class='error-message-kyc'>${context.t(errorMessage)}. <br>${context.t(
          "If you think we made a mistake, please write to us at {email} or contact us at {web}. We are here to help you!",
          {
              email: '<a class="text-link" href="mailto:soporte@banexcoin.com">soporte@banexcoin.com</a>',
              web: '<a class="text-link web" href="https://soporte.banexcoin.com">soporte.banexcoin.com</a>'
          })}</p>`,
        type: 'warning'
      });
    }
  }

  const formatNumberWithKM = (number) => {
    let newNumber = 0;
    let compareNumber = 0;
    let letter = "";
    if (number >= 1000000) {
      newNumber = (number / 1000000).toFixed(1);
      compareNumber = parseFloat(newNumber) * 1000000;
      letter = "M";
    } else if (number >= 1000) {
      newNumber = (number / 1000).toFixed(1);
      compareNumber = parseFloat(newNumber) * 1000;
      letter = "K";
    }
    newNumber = Number(newNumber)
    return compareNumber === number ? `${newNumber}${letter}` : number.toLocaleString("en-US");
  }

  const messageInProcess = (status, level) => {
    if(status !== 'In progress') return;
    return <div className='message-in-process coink'>
      {context.t('Thank you for waiting!')}
      <p>{context.t('Your request is in process and will be responded to within a maximum of 24 hours.')}</p>
      <p>{context.t('We will notify you via email with the result of your request.')}</p>
      </div>
  }

  const showCircle = () => (className === 'card-config-v2' && levelsData.length < 3)

  if (empty(monthlyLimit)) {
    return <div className='global-hooks initial-data'>
      <Spinner color='orange'/>
    </div>;
  }

  const levelContent = (level) => {
    if(isCoink){
      return (
        <div className='coink-details-level'>
          <p>{level === 100 ? "Una vez verificado, podrás" : "Felicidades, ahora puedes"}:</p>
          <ul>
            <li>Depositar Pesos y/o Criptomonedas</li>
            <li>Intercambiar</li>
            <li>Retirar Pesos y/o Criptomonedas</li>
          </ul>
        </div>
      )
    }
    return (
      <div className="levelContent">
        {context.t('Allows you to perform operations up to')}
        <p>{context.t(
          `{firstPrice} or {secondPrice} per month`,
          {
            firstPrice: `S/${formatNumberWithKM(monthlyLimit[level.levelNumber]['PEN'])}`,
            secondPrice: `$${formatNumberWithKM(monthlyLimit[level.levelNumber]['USD'])}`
          }
        )}.</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      {(account.VerificationLevel === 0 &&  accountConfigs.residenceCountry === 'PE') && 
        <Alert variant={'warning'} className="alert-v2 alert-initial-message">
          {`${context.t('Due to an operational issue, an error has been detected in your signup. We are working to solve it as soon as possible.')} ${context.t('If you want to operate as soon as possible, we invite you to request your level increase to Amateur, with many more benefits')}`}
        </Alert>
      }
      <div className={`global-hooks ${className}`}>
        {!isEmpty(account) && !empty(levelsData) && !empty(settings) && (
          <div className='card-level'>
            <CircleBxn color='orange' className='circle-top-level' />
            {!showCircle() && <CircleBxn color='purple' className='circle-bottom-level' />}
            <div className='customer-level-card'>
              <h2>{context.t('Customer level')}</h2>
              <OverlayTrigger
                key={'level-for-user-tooltip'}
                placement={'right'}
                overlay={
                  <Tooltip id={`level-for-user-tooltip`}>
                      <div className="tooltip-general">{context.t('Choose the level at which you want to transact')}</div>
                  </Tooltip>
                }>
                  <img src={InformationIcon} alt='Information icon'/> 
              </OverlayTrigger>  
            </div>
            <Slider {...settings} className='LevelBlock level-item'>
              {levelsData.map((level, index) =>
                <div key={index} className={'LevelForSingle ' + `qty-${levelsData.length} ` + `${isCoink ? "levelForSingle-coink" : ""}`}>
                  <div className="level-info-title">
                    <h2>{context.t(level.parentName)}</h2>
                    {isMayorIgual(account.VerificationLevel, level.levelNumber) && <img src={verifiedIcon} alt="Verified icon" />}
                  </div>
                  {levelContent(isCoink ? account.VerificationLevel : level)}
                  {messageInProcess(getClassName(level.levelNumber).text, level.parentName)}
                  {isMayorIgual(account.VerificationLevel, level.levelNumber) ?
                    (!isCoink && <button className="VerifiedStatus">
                      <APIcon name='check' /> {context.t('Verified')}
                    </button>) :
                    !empty(level.path) ? (
                      isNextLevel(account.VerificationLevel, level.levelNumber) ? (
                        level.path.includes("http") ?
                          <button onClick={() => window.open(level.path, "_self", "noreferrer")} className={getClassName(level.levelNumber).className}>
                            {context.t(getClassName(level.levelNumber).text)}
                          </button> :
                          <Link to={path(level.path)} className={getClassName(level.levelNumber).className}>
                            {context.t(getClassName(level.levelNumber).text)}
                          </Link>
                      ) :
                        <div className='min-height-button'>
                          {context.t("To achieve this level, you must have passed the previous level")}
                        </div>
                    ) :
                      <button className={getClassName(level.levelNumber).className} onClick={showErrorMessage}>
                        {context.t(getClassName(level.levelNumber).text)}
                      </button>
                  }
                </div>
              )}
            </Slider>
          </div>)
        }
      </div>
    </React.Fragment>    
  )
};

LevelBlocksComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelBlocksComponent);