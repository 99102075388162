import React from 'react';
import PropTypes from 'prop-types';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { formatDate } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import '../ConfirmSingleReport/ConfirmSingleReportModal.css';

const confirmSingleReportClasses = getBEMClasses('confirm-single-report');

const activities = {
  TradeActivity:"Trade activity",
  Transaction: "Transaction",
  Treasury:"Treasury"
}

let ConfirmCyclicalReportForm = function ConfirmCyclicalReportForm(props, context) {
  return React.createElement(
    'div',
    { className: confirmSingleReportClasses() },
    React.createElement(
      'p',
      { className: confirmSingleReportClasses('title') },
      context.t('Confirm')
    ),
    React.createElement(APLabelWithText, {
      label: context.t('Report type:'),
      text: context.t(activities[props.payload.type] || props.payload.type),
      customClass: confirmSingleReportClasses()
    }),
    React.createElement(APLabelWithText, {
      label: context.t('Start date:'),
      text: formatDate(props.payload.startDate),
      customClass: confirmSingleReportClasses()
    }),
    React.createElement(APLabelWithText, {
      label: context.t('Frequency:'),
      text: context.t(props.payload.frequency),
      customClass: confirmSingleReportClasses()
    })
  );
};

ConfirmCyclicalReportForm.propTypes = {
  payload: PropTypes.object
};

ConfirmCyclicalReportForm.defaultProps = {
  payload: {
    type: '',
    startDate: '',
    frequency: ''
  }
};

ConfirmCyclicalReportForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmCyclicalReportForm;