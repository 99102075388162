import React from 'react';
// import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent.css';

// var baseClasses = getBEMClasses('instrument-row');

const FCEnglish = (props, context) => {
  return (
    <div>
      <div
        className="banner-op"
        style={{ height: '170px', marginBottom: '50px' }}>
        <h1 className="lang" key="tarifas_comisiones">
          Fee and Tariffs
        </h1>
        <p className="lang" key="best_rates_com">
          Here at Banexcoin we work with the best rates and commissions.
        </p>
      </div>
      <article className="padding-terms">
        <p className="lang parrafo" key="tarifas_comisiones_prr1">
          In Banexcoin we have many ways to increase the balance of your
          account. Once you have sufficient funds, you may use your balance to
          exchange your crypto assets or fiat currency that we manage in our
          platform. Also, we count with a flat rate for all exchange operations,
          where you will be able to see a mínimum percentage before performing
          any way of exchanging such currencies, depending only on the level of
          verification of the user.
        </p>
        <p className="lang parrafo" key="tarifas_comisiones_prr2">
          Besides you can deposit your Crypto assets without paying any fee. For
          security matters and in order to avoid double spending risks, we have
          established a certain number of confirmations, subject to the type of
          cryptocurrency. These confirmations mean the insertion of the
          transaction of a block incorporated into the blockchain by the miners.
          Each subsequent block increases an additional confirmation for such
          transaction. In example, in the BTC Blockchain the average time to
          mine for each block is 10 minutes, so Banexcoin requires six (06)
          confirmations, so any user can see in his account credited there
          bitcoins in a lapse of sixty (60) minutes approximately, as long as
          the miners take ten minutes to mine a block. It may occur from time to
          time that it could last more, and that is when we call it congestion
          in the network, in such case it is out of the Platform control,
          therefore it is exempt from any liability regarding extemporary
          execution timing in such matter.
        </p>
        <p />
        <div className="box-center">
          <table className="table">
            <tbody>
              <tr className="tr-title">
                <td
                  colSpan={6}
                  className="text-center lang"
                  key="tarifas_comisiones_tiempos">
                  Times and Commissions for Deposits and Withdrawals
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_peru">
                  Fees Apply for Peru{' '}
                  <img
                    alt="peru"
                    src="local/landing-page/images/country/peru.png"
                  />
                </td>
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_panama">
                  Fees Apply for Panamá{' '}
                  <img
                    alt="panama"
                    src="local/landing-page/images/country/panama.png"
                  />
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td className="lang" key="fiat_depo">
                  Fiat Deposits
                </td>
                <td className="lang" key="estimate_time">
                  Estimated time for Deposit*
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_depo">
                    Commission fee for Deposits
                  </span>
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td className="bor-rg">
                  <span className="lang" key="perc_com_depo">
                    Commission fee for Deposits
                  </span>
                </td>
              </tr>
              <tr>
                <td>Banexcoin transfer</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td className="bor-rg">
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="tarjeta_Credito">
                    Credit Card **
                  </span>
                </td>
                <td>2 min.</td>
                <td className="bor-lf">50 US$</td>
                <td>4%</td>
                <td className="bor-lf">50 US$</td>
                <td className="bor-rg">4%</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_same">
                    National Transfer from the same Bank
                  </span>
                </td>
                <td>5 min.</td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_other">
                    Transferencia Nacional desde el otro Banco
                  </span>
                </td>
                <td>
                  <span className="lang" key="one_work_day">
                    1 labour day
                  </span>
                </td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td className="none-apply">XXX</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_inter">
                    International wire transfer***
                  </span>
                </td>
                <td>48h - 72h</td>
                <td className="bor-lf">1000 US$</td>
                <td>50 US$</td>
                <td className="bor-lf">1000 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td>
                  <span className="lang" key="depo_cript">
                    Cryptocurrencies Deposits
                  </span>
                </td>
                <td>
                  <span className="lang" key="number_conf_depo">
                    # Confirmation for Deposits
                  </span>
                </td>
                <td>
                  <span className="lang" key="estimate_time">
                    Estimated time for Deposit*
                  </span>
                </td>
                <td>
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_depo">
                    Commission fee for Deposits
                  </span>
                </td>
              </tr>
              <tr>
                <td>Bitcoin (BTC)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>BitcoinCash (BCH)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>150 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Dash (DASH)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>15 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Eosio (EOS)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Ether (ETH)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>6 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Litecoin (LTC)</td>
                <td>
                  12{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Monero (XMR)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Ripple (XRP)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>USD Coin (USDC)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>6 Min.</td>
                <td>1</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>USD Tether (USDT)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>1</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>Zcash (ZEC)</td>
                <td>
                  24{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table">
            <tbody>
              <tr>
                <td />
                <td />
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_peru">
                  Fees Apply for Peru{' '}
                  <img
                    alt="peru"
                    src="local/landing-page/images/country/peru.png"
                  />
                </td>
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_panama">
                  Fees Apply for Panamá{' '}
                  <img
                    alt="panama"
                    src="local/landing-page/images/country/panama.png"
                  />
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td className="lang" key="reti_fiat">
                  Fiat Withdrawals
                </td>
                <td className="lang" key="estimate_time_reti">
                  Estimated time for Withdrawals*
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_reti">
                    Commission for Withdrawals
                  </span>
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td className="bor-rg">
                  <span className="lang" key="perc_com_reti">
                    Commission for Withdrawals
                  </span>
                </td>
              </tr>
              <tr>
                <td>Banexcoin transfer</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td className="bor-rg">
                  <span className="lang" key="no_cost">
                    free
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_same">
                    National Transfer from the same Bank
                  </span>
                </td>
                <td>20 min.</td>
                <td className="bor-lf">10US$</td>
                <td>0.51%</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_other">
                    Transferencia Nacional desde el otro Banco
                  </span>
                </td>
                <td>
                  <span className="lang" key="one_work_day">
                    1 labour day
                  </span>
                </td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td className="none-apply">XXX</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_inter">
                    International wire transfer***
                  </span>
                </td>
                <td>48h - 72h</td>
                <td className="bor-lf">2000 US$</td>
                <td>50 US$</td>
                <td className="bor-lf">2000 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td>
                  <span className="lang" key="reti_cript">
                    Cryptocurrencies Withdrawals
                  </span>
                </td>
                <td>
                  <span className="lang" key="number_conf_reti">
                    # Confirmation for Withdrawals
                  </span>
                </td>
                <td>
                  <span className="lang" key="estimate_time_reti">
                    Estimated time for Withdrawals*
                  </span>
                </td>
                <td>
                  <span className="lang" key="minimun_amount">
                    Minimum Amount
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_reti">
                    Commission for Withdrawals
                  </span>
                </td>
              </tr>
              <tr>
                <td>Bitcoin (BTC)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.002 BTC</td>
                <td>0.0015 BTC</td>
              </tr>
              <tr>
                <td>BitcoinCash (BCH)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>150 min.</td>
                <td>0.002 BCH</td>
                <td>0.001 BCH</td>
              </tr>
              <tr>
                <td>Dash (DASH)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>15 min.</td>
                <td>0.004 DASH</td>
                <td>0.05 DASH</td>
              </tr>
              <tr>
                <td>Eosio (EOS)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td>0.2 EOS</td>
                <td>0.1 EOS</td>
              </tr>
              <tr>
                <td>Ether (ETH)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>6 min.</td>
                <td>0.02 ETH</td>
                <td>0.01 ETH</td>
              </tr>
              <tr>
                <td>Litecoin (LTC)</td>
                <td>
                  12{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.002 LTC</td>
                <td>0.001 LTC</td>
              </tr>
              <tr>
                <td>Monero (XMR)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.01 XMR</td>
                <td>0.0001 XMR</td>
              </tr>
              <tr>
                <td>Ripple (XRP)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Immediate
                  </span>
                </td>
                <td>20 XRP</td>
                <td>0.3 XRP</td>
              </tr>
              <tr>
                <td>USD Coin (USDC)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>6 Min.</td>
                <td>10 USDC</td>
                <td>3 USDC</td>
              </tr>
              <tr>
                <td>USD Tether (USDT)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>10 USDT</td>
                <td>4 USDT</td>
              </tr>
              <tr>
                <td>Zcash (ZEC)</td>
                <td>
                  24{' '}
                  <span className="lang" key="confirm">
                    Confirmations
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.01 ZEC</td>
                <td>0.005 ZEC</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td colSpan={4} className="text-center lang" key="com_buy_sell">
                  Cryptocurrencies Buy / Sell (trading) Commission Fee
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td>
                  <span className="lang" key="level_ini1">
                    Initial - Level 1
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_int2">
                    Intermediate - Level 2
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_ma3">
                    Master - Level 3
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_corp4">
                    Corporate - Level 4
                  </span>
                </td>
              </tr>
              <tr>
                <td>0.2%</td>
                <td>0.15%</td>
                <td>0.10%</td>
                <td>0.10%</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <div className="lang parrafo" key="tarifas_clause1">
            * It shows the estimated time of confirmation, due to execution time
            may vary upon web conditions- You must take in to account that
            estimated time of waiting is related to the time awaited till
            availability of the funds at your Banexcoin account which involves
            that transaction should be confirmed in the first block after the
            same has been sent. If your transaction wasn't confirmed in the
            first block possible, then the transaction could take more time then
            expected relying on the conditions of the web at that moment.
          </div>
          <div className="lang parrafo" key="tarifas_clause2">
            ** Credit Cards Visa and Mastercard will be the only ones accepted.
            The owner must be the same as the user in the Banexcoin account who
            is requesting the deposit of the funds.
          </div>
          <div className="lang parrafo" key="tarifas_clause3">
            *** This transaction must be done by the user of the Banexcoin
            Account and he must previously assume the charges made by the bank
            for the execution of the transfer and under no circumstances may be
            transferred to Banexcoin, which the funds will be destinated to if
            this doesn’t occur transaction will be returned deducting any
            associated charges.
          </div>
          <div className="lang parrafo" key="tarifas_clause4">
            The times of transference for deposit or withdrawal of fiat currency
            are estimated and will be counted during labor days, it will depend
            on the processing time of the banking network.
          </div>
          <div className="lang parrafo" key="tarifas_clause5">
            The Commission Fees above mentioned are subject to changes without
            previous notice.
          </div>
        </div>
      </article>
    </div>
  );
};

export default FCEnglish;
