import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import empty from 'is-empty';

const FormField = (props, context) => {
  const {
    label,
    required,
    type,
    placeholder,
    name,
    step,
    id,
    onChange,
    errors,
    errorMessage,
    icon,
    onIconClick,
    value
  } = props;
  try {
    let idTwo = !empty(id) ? id : !empty(name) ? name : '';
    let propInput = {
      required: required ? true : false,
      type: !empty(type) ? type : 'text',
      placeholder: !empty(placeholder)
        ? context.t(placeholder)
        : !empty(label)
          ? context.t(label)
          : '',
      name,
      step,
      value,
      onChange
    };

    return (
      <React.Fragment>
        {!empty(label) ? (
          <Form.Label className="label-bnxv2" htmlFor={idTwo}>
            {context.t(label)} {required ? <b className="important">*</b> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        <Form.Group
          controlId={idTwo}
          className={
            'v2-field' +
            ((!empty(errors) && !empty(errors[name])) || !empty(errorMessage)
              ? ' invalid'
              : ' valid') +
            (!empty(icon) ? ' with-icon' : '')
          }>
          <div className="oneline-box">
            <Form.Control {...propInput} />
            {!empty(icon) ? (
              <label
                className={
                  'input-group-append' +
                  (typeof onIconClick === 'function' ? ' selectable' : '')
                }
                htmlFor={idTwo}
                onClick={() => {
                  if (typeof onIconClick === 'function') {
                    onIconClick();
                  }
                }}>
                <span className="input-group-text">{icon}</span>
              </label>
            ) : (
              ''
            )}
          </div>
          {!empty(errors) && !empty(errors[name]) ? (
            <Form.Control.Feedback type="invalid">
              {context.t(errors[name])}
            </Form.Control.Feedback>
          ) : !empty(errorMessage) ? (
            <Form.Control.Feedback type="invalid">
              {context.t(errorMessage)}
            </Form.Control.Feedback>
          ) : (
            ''
          )}
        </Form.Group>
      </React.Fragment>
    );
  } catch (error) {
    console.log('error FormField', error);
    return <React.Fragment />;
  }
};

FormField.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormField;
