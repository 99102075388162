import React from 'react';

const KYCPolicySpanish = (props, context) => {
  return (
    <div>
      <div
        className="banner-op"
        style={{ height: '170px', marginBottom: '50px' }}>
        <h1 className="lang" key="tarifas_comisiones">
          Políticas de KYC & AML
        </h1>
        <p className="subt">(Conoce a tu cliente y anti-lavado de dinero)</p>
      </div>
      <article className="padding-terms">
        <p>
          En respuesta a la creciente preocupación de la comunidad internacional
          sobre el problema del lavado de activos y del financiamiento del
          terrorismo, muchos países del mundo están promulgando y fortaleciendo
          sus leyes sobre el tema, adecuándose y haciendo suyas las 40
          recomendaciones emitidas por el Grupo de Acción Financiera
          Internacional (GAFI) y el Perú no es ajeno a ello.
        </p>
        <p>
          Banexcoin como integrante del sistema económico peruano, declara su
          compromiso en la lucha contra el lavado de activos y el financiamiento
          del terrorismo, habiendo establecido políticas internas que conlleven
          a combatirlos, a fin de impedir la incorporación, dentro de sus
          actividades, de fondos vinculados presuntamente al lavado de activos y
          que financien el terrorismo, resguardando su buena imagen y
          reputación, en concordancia con la aplicación de la Resolución SBS N°
          789-2018.
        </p>
        <p>
          En línea con lo anteriormente enunciado y en cumplimiento de la
          normativa vigente, Banexcoin ha establecido mecanismos de Conocimiento
          del Cliente – KYC (del inglés Know Your Customer), así como
          procedimientos para la Prevención del Lavado de Dinero – AML (del
          inglés Anti-Money Laundering), los cuales aplicamos antes de iniciar y
          durante la relación comercial, permitiéndonos identificar y establecer
          un perfil de nuestros clientes. Por ello requerimos la información y
          documentación que hemos detallado en nuestra plataforma para los
          distintos niveles de clientes, los cuales necesariamente deben ser
          proporcionados, sin ellos no podrán completar el proceso de aceptación
          como cliente.
        </p>
        <p>
          En el desarrollo de la relación comercial, así como en la atención de
          alguna operación en particular, Banexcoin podrá solicitar información
          y documentación adicional al cliente, quien se obliga a
          proporcionarla, caso contrario, Banexcoin se reserva la potestad de
          mantener o no la relación comercial con el cliente, de acuerdo con lo
          establecido en nuestros “Términos y Condiciones”.
        </p>
        <p>
          Queda establecido que Banexcoin, en cumplimiento de las normas sobre
          protección de datos personales, resguarda la información y/o
          documentación proporcionada por el cliente, bajo las pautas de la
          debida reserva y confidencialidad.
        </p>
      </article>
    </div>
  );
};

export default KYCPolicySpanish;
