import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';
import { cleanText } from './../../helpers/lib';

export const doPayment = async token => {
  let path = '/api/pagos';
  let result = {};
  let data = JSON.parse(localStorage.getItem('payment'));

  try {
    // Prepare data values locally
    data = !empty(data) ? data : {};
    if (!empty(data.card_number)) {
      delete data.card_number;
      delete data.card_expiration;
      delete data.card_ccv;
    }

    data.action = 'payment.step2';
    data.token_id = token;

    let response = await HandlerBackend.sendRequest({
      userId: data.banexcoin_user_id,
      method: 'post',
      path,
      data
    });

    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
};

export const getKycIntentionHook = async userId => {
  let path = '/api/v2/kyc/intention';
  let result = {};
  let data = {};

  try {
    let response = await HandlerBackend.sendRequest({
      userId: userId,
      method: 'get',
      path,
      data
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
};

export const getKycIntentionStatusHook = async (id, userId) => {
  let path = `/api/v2/kyc/status/${id}`;
  let result = {};
  let data = {};

  try {
    let response = await HandlerBackend.sendRequest({
      userId: userId,
      method: 'get',
      path,
      data
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
};

export const sendLinkToCustomHook = async (id, type, userId) => {
  let path = `/api/v2/kyc/${type}/${id}`;
  let result = {};
  let data = {};

  try {
    let response = await HandlerBackend.sendRequest({
      userId: userId,
      method: 'get',
      path,
      data
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
};

export function convertImageToBase64(img, outputFormat) {
  var originalWidth = img.style.width;
  var originalHeight = img.style.height;

  img.style.width = 'auto';
  img.style.height = 'auto';
  img.crossOrigin = 'Anonymous';

  var canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);

  img.style.width = originalWidth;
  img.style.height = originalHeight;

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  var dataUrl = canvas.toDataURL(outputFormat);

  return dataUrl;
}

export function convertImageUrlToBase64(url, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = 'anonymous';
  img.onload = function() {
    callback(convertImageToBase64(this, outputFormat));
  };
  img.src = url;
}

export const submitFormIntermediateData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/intermediate';
  let model = {
    banexcoin_account_id: UserAccountId,
    address_main: data.address_main,
    building: data.building,
    apt: cleanText(data.apt),
    town: data.town,
    residence_country: data.residence_country,
    state: data.state,
    city: data.city,
    district: data.district,
    zip_code: data.zip_code,
    occupation: data.occupation,
    occupation_details: data.occupation_details,
    work_center: data.work_center
  };
  if(!empty(data.other_occupation)) model.other_occupation = data.other_occupation;

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormIntermediateData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/intermediate/resend';

  let editFields = !empty(data.edit_fields) ? JSON.parse(data.edit_fields) : [];
  let model = {};
  for (let i of editFields) {
    let nameForm = i;
    let valueForm = data[nameForm];
    model[nameForm] = cleanText(valueForm);
  }
  model.banexcoin_account_id = UserAccountId;

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormMasterData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/master';

  let model = new FormData();

  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('proof_of_residence', data.proof_of_residence);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormMasterData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/master/resend';

  let model = new FormData();

  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('proof_of_residence', data.proof_of_residence);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormVipDependentData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/vip-dependent';

  let model = new FormData();

  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('master_origin_type', data.master_origin_type);
  model.append('master_others', data.master_others);
  model.append('master_origin_document', data.master_origin_document);
  model.append('master_affidavit_document', data.master_affidavit_document);
  // model.append('master_origin_founds', data.master_origin_founds);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormVipDependenData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/vip-dependent/resend';

  let editFields = !empty(data.edit_fields) ? JSON.parse(data.edit_fields) : [];
  let model = new FormData();
  for (let i of editFields) {
    let nameForm = i;
    let valueForm = data[nameForm];
    model.append(nameForm, valueForm);
  }
  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormVipIndependentData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/vip-independent';

  let model = new FormData();

  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('master_origin_type', data.master_origin_type);
  model.append('master_others', data.master_others);
  model.append('master_origin_document', data.master_origin_document);
  model.append('master_affidavit_document', data.master_affidavit_document);
  model.append('master_origin_founds', data.master_origin_founds);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormVipIndependentData = async (
  userId,
  data,
  UserAccountId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/vip-independent/resend';

  let editFields = !empty(data.edit_fields) ? JSON.parse(data.edit_fields) : [];
  let model = new FormData();
  for (let i of editFields) {
    let nameForm = i;
    let valueForm = data[nameForm];
    model.append(nameForm, valueForm);
  }
  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormBeginnerData = async (
  userId,
  data,
  UserAccountId,
  providerId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/beginner';

  let model = new FormData();
  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name);
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', data.birthday);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);
  model.append('residence_country', data.residence_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_country', data.billing_country);
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('address_main', data.address_main);
  model.append('provider_id', providerId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormBeginnerDataV2 = async (
  userId,
  data,
  UserAccountId
) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  const path = '/api/v3/verification/beginner';

  let model = new FormData();
  model.append('banexcoin_account_id', UserAccountId);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name);
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', data.birthday);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);
  model.append('address_main', data.address_main);
  model.append('document_data', data.document_data);
  model.append('back_document_data', data.back_document_data);
  model.append('faces_images', data.faces_images);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormBeginnerData = async (
  userId,
  data,
  UserAccountId,
  providerId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/v2/verification/beginner/resend';

  let editFields = !empty(data.edit_fields) ? JSON.parse(data.edit_fields) : [];
  let model = new FormData();
  for (let i of editFields) {
    let nameForm = i;
    let valueForm = data[nameForm];
    model.append(nameForm, valueForm);
  }
  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);
  model.append('provider_id', providerId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormBeginnerDataV2 = async (
  userId,
  data,
  UserAccountId
) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  const path = '/api/v3/verification/beginner/resend';

  const editFields = !empty(data.edit_fields) ? JSON.parse(data.edit_fields) : [];
  let model = new FormData();
  for (let i of editFields) {
    const nameForm = i;
    const valueForm = data[nameForm];
    model.append(nameForm, valueForm);
  }
  model.append('banexcoin_account_id', UserAccountId);
  model.append('banexcoin_user_id', userId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
