import React from 'react';
import empty from 'is-empty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';
import StepWelcome from './Stepper/CoinkStepWelcome';
import HeaderBanexcoin from './CoinkUtils';
import CoinkStepLoginError from './Stepper/CoinkStepLoginError';

class CoinkPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitions: {
        enterRight: `animated enterRight`,
        enterLeft: `animated enterLeft`,
        exitRight: `animated exitRight`,
        exitLeft: `animated exitLeft`,
        intro: `animated intro`
      },
      errorCode: '',
      mounting: true,
      working: true
    };
  }

  onStepChange(stats) {}
  wakeUpHeader(state) {
    this.setState({ displayHeader: state });
  }

  setWorking(stado) {
    this.setState({ working: stado });
  }
  setMounting(stado) {
    this.setState({ mounting: stado });
  }

  componentWillMount() {
    localStorage.clear();
  }

  componentDidMount() {
    document.body.classList.add('coink-app');
    this.setMounting(false);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.working &&
      !empty(this.state.SW) &&
      typeof this.state.SW.nextStep == 'function'
    ) {
      this.setWorking(false);
    }
  }

  setErrorCode = value => {
    this.setState({
      errorCode: value
    });
  };

  render() {
    let { SW, transitions, theme } = this.state;
    let { match, UserId } = this.props;
    const setInstance = SW => {
      this.setState({
        ...this.state,
        SW
      });
    };

    return (
      <React.Fragment>
        <div className="body-step-bnx nh">
          <HeaderBanexcoin disableOptions={true} />
          <div id="bodyStyleCoink">
            <StepWizard
              onStepChange={this.onStepChange}
              instance={setInstance}
              transitions={transitions}
              className="pl-4 pr-4">
              <StepWelcome
                propsFunction={SW}
                match={match}
                wakeUpHeader={this.wakeUpHeader}
                setData={this.setErrorCode}
                working={this.state.working}
                mounting={this.state.mounting}
              />
              <CoinkStepLoginError
                propsFunction={SW}
                errorCode={this.state.errorCode}
              />
            </StepWizard>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CoinkPublic.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = ({
  user: { selectedAccountId, userInfo },
  user,
  user: {
    userInfo: { UserId }
  },
  position: { positions, fetching }
}) => {
  return { selectedAccountId, userInfo, user, positions, fetching, UserId };
};

export default connect(mapStateToProps)(CoinkPublic);
