import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import apex from 'apex-web/lib/apex';
import { useEffect, useState } from 'react';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import APIcon from 'apex-web/lib/components/common/APIcon';
import CryptoNavItemsContainer from 'apex-web/lib/components/WalletNavItems/CryptoNavItemsContainer';
import FiatNavItemsContainer from 'apex-web/lib/components/WalletNavItems/FiatNavItemsContainer';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import resize from 'apex-web/lib/hocs/resize';
import empty from 'is-empty';
import { FormatDecimalCoinkWithOutSimbols, FormatDecimalCoinkWithSimbols, amountWithCurrency } from '../../pages/utils';
import './WalletCard.css';

var walletCardClasses = getBEMClasses('wallet-card');

var WalletCard = function WalletCard(props, context) {
  var iconFileName = props.iconFileName,
    ProductSymbol = props.ProductSymbol,
    ProductFullName = props.ProductFullName,
    Amount = props.Amount,
    AvailableBalance = props.AvailableBalance,
    rawAmount = props.rawAmount,
    isCrypto = props.isCrypto,
    ProductId = props.ProductId,
    productColor = props.productColor,
    baseCurrency = props.baseCurrency,
    width = props.width,
    product = props.product;

  var iconSize = width > 1024 ? 44 : 28;

  return React.createElement(
    'div',
    { className: walletCardClasses() + ' container-with-shadow' },
    React.createElement(
      'div',
      { className: walletCardClasses('icon-label-row') },
      React.createElement(
        'div',
        {
          style: { borderTop: '4px solid ' + productColor },
          className: walletCardClasses('icon')
        },
        React.createElement(ProductIconContainer, {
          iconFileName: iconFileName,
          size: iconSize
        })
      ),
      React.createElement(
        'span',
        { className: walletCardClasses('symbol-label') },
        ProductSymbol + ' ' + context.t(ProductFullName)
      )
    ),
    React.createElement(
      'div',
      { className: walletCardClasses('balance-row') },
      React.createElement(
        'div',
        { className: walletCardClasses('balance') },
        React.createElement(
          'span',
          { className: walletCardClasses('label') },
          context.t('Available Balance')
        ),
        React.createElement(
          'div',
          { className: walletCardClasses('amount') },
          React.createElement(
            'span',
            { className: walletCardClasses('amount-text') },
              amountWithCurrency({
                amount: FormatDecimalCoinkWithOutSimbols(AvailableBalance, ProductSymbol),
                product
              })
          )
        )
      ),
      baseCurrency &&
        React.createElement(
          'div',
          { className: walletCardClasses('balance') },
          React.createElement(
            'span',
            { className: walletCardClasses('label') },
            baseCurrency.Product,
            ' '
          ),
          React.createElement(
            'div',
            { className: walletCardClasses('amount') },
            React.createElement(
              'span',
              { className: walletCardClasses('amount-text') },
              formatNumberToLocale(
                rawAmount * baseCurrency.value,
                baseCurrency.DecimalPlaces
              )
            )
          )
        )
    ),
    React.createElement(
      'div',
      { className: walletCardClasses('activity-link-row') },
      React.createElement(
        'div',
        { className: walletCardClasses('activity-link-container') },
        React.createElement(
          Link,
          {
            className: walletCardClasses('activity-link'),
            to: path(props.detailsLink),
            onClick: props.selectPositionAndSave
          },
          context.t('Details'),
          ' ',
          React.createElement(APIcon, {
            name: 'arrowNext',
            customClass: walletCardClasses('next-icon')
          })
        )
      ),
      React.createElement(
        'div',
        { className: walletCardClasses('icon-nav-buttons') },
        isCrypto
          ? React.createElement(CryptoNavItemsContainer, {
              product: {
                iconFileName: iconFileName,
                ProductSymbol: ProductSymbol,
                ProductFullName: ProductFullName,
                ProductId: ProductId
              },
              showIconButtonLabel: false
            })
          : React.createElement(FiatNavItemsContainer, {
              product: {
                iconFileName: iconFileName,
                ProductSymbol: ProductSymbol,
                ProductFullName: ProductFullName,
                ProductId: ProductId
              },
              showIconButtonLabel: false
            })
      )
    )
  );
};

WalletCard.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(WalletCard);
