import { getUserInfo } from 'apex-web/lib/redux/actions/userActions';
import { getUserApiKeys } from 'apex-web/lib/redux/actions/apiKeyActions';
import { getAffiliateCount } from 'apex-web/lib/redux/actions/affiliateActions';
import {
  getUserReports,
  getUserReportWriterResults
} from 'apex-web/lib/redux/actions/reportActions';
export var AUTH_SUCCESS = 'AUTH_SUCCESS';
export var AUTH_ERROR = 'AUTH_ERROR';
export var AUTH_NONE = 'AUTH_NONE';
export var AUTH_REDIRECT = 'AUTH_REDIRECT';
export var AUTH_2FA_REQUIRED = 'AUTH_2FA_REQUIRED';
export var FORCED_LOGOUT = 'FORCED_LOGOUT';
export var ENABLE_2FA_REQUIRED = 'ENABLE_2FA_REQUIRED';

export var authSuccess = function authSuccess(payload) {
  return {
    type: AUTH_SUCCESS,
    payload: {
      isAuthenticated: payload.Authenticated,
      userId: payload.UserId,
      token: payload.SessionToken,
      pending: false,
      is2FARequired: false
    }
  };
};

export const onLogin = (dispatch, action) => {
  localStorage.setItem('token', action.SessionToken);
  dispatch(initDataAfterLogin(action.UserId));
  dispatch(authSuccess(action));
};

const initDataAfterLogin = userId => async dispatch => {
  dispatch(getUserInfo(userId));
  dispatch(getUserApiKeys(userId));
  dispatch(getAffiliateCount());
  dispatch(getUserReports(userId));
  dispatch(getUserReportWriterResults());
};
