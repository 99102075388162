import { connect } from 'react-redux';
import DepthChartComponent from './DepthChartComponent';
import store from 'apex-web/lib/redux/store';
import { buyPriceClicked, sellPriceClicked } from 'apex-web/lib/redux/actions/orderEntryActions';
import config from 'apex-web/lib/config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

const DepthChart = config.DepthChart;


const mapStateToProps = function mapStateToProps(state) {
  const level2 = state.apexCore.level2;
  const fetching = level2.fetching;


  const selectedInstrument = selectedInstrumentSelector(state);

  return {
    level2: level2,
    store: store,
    fetching: fetching,
    DepthChart: DepthChart,
    selectedInstrument: selectedInstrument
  };
};

const mapDispatchToProps = {
  buyPriceClicked: buyPriceClicked,
  sellPriceClicked: sellPriceClicked
};

export default connect(mapStateToProps, mapDispatchToProps)(DepthChartComponent);