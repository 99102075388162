import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getTotalRender, getAvailableBalance } from 'apex-web/lib/helpers/withdrawHelper';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import SendReceiveHeaderComponent from './SendReceiveHeader/SendReceiveHeaderComponent';
import SendReceiveFormTabsComponent from './SendReceiveFormTabs/SendReceiveFormTabsComponent';
import SendFormContainer from './SendForm/SendFormContainer';
import ReceiveContainer from './Receive/ReceiveContainer';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { formatNumbersWithoutRound } from '../../pages/utils';
import SendReceiveDetailsComponent from './SendReceiveDetails/SendReceiveDetailsComponent';
import SendReceiveConfirmModal from './SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import SendReceiveTwoFactorAuthContainer from './SendReceiveTwoFactorAuth/SendReceiveTwoFactorAuthContainer';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import './SendReceiveSidePane.css';
import LimitsBalance from './LimitsBalance';
import { connect } from 'react-redux';

const sendReceiveClasses = getBEMClasses('send-receive');

const _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var SendReceiveSidePane = (function(_React$Component) {
  _inherits(SendReceiveSidePane, _React$Component);

  function SendReceiveSidePane(props, context) {
    _classCallCheck(this, SendReceiveSidePane);

    var _this = _possibleConstructorReturn(
      this,
      (
        SendReceiveSidePane.__proto__ ||
        Object.getPrototypeOf(SendReceiveSidePane)
      ).call(this, props)
    );

    _this.toggleUseExternalAddress = function() {
      _this.setState({ useExternalAddress: !_this.state.useExternalAddress });
    };

    _this.handleTrustListChange = function() {
      _this.setState({ showLabel: !_this.state.showLabel });
    };

    _this.onUpdate = function() {
      _this.getWithdrawFee();
      _this.setTotal();
    };

    _this.setTotal = function() {
      var position = _this.props.position;
      var _this$state = _this.state,
        Amount = _this$state.data.Amount,
        fee = _this$state.fee;

      var balance = getAvailableBalance(position.Amount, position.Hold);
      var amount = parseFloat(Amount);
      var total = 0;

      if (amount) {
        total = getTotalRender(balance, amount, fee);
      }

      _this.setState({ total: total });
    };

    _this.getWithdrawFee = function() {
      var _this$props = _this.props,
        ProductId = _this$props.product.ProductId,
        getWithdrawFee = _this$props.getWithdrawFee;
      var Amount = _this.state.data.Amount;

      if (!isNaN(Amount)) {
        getWithdrawFee(ProductId, Amount);
      }
    };

    _this.onDataUpdate = function(data) {
      var _this$state2 = _this.state,
        Amount = _this$state2.data.Amount,
        useExternalAddress = _this$state2.useExternalAddress,
        isSend = _this$state2.isSend;

      _this.setState({ data: data }, function() {
        if (useExternalAddress && isSend && data.Amount !== Amount) {
          _this.onUpdate();
        }
      });
    };

    _this.openConfirmModal = function(data) {
      return _this.setState({ openConfirmModal: true, data: data });
    };

    _this.closeConfirmModal = function() {
      return _this.setState({ openConfirmModal: false });
    };

    _this.onTabChange = function() {
      _this.setState({
        isSend: !_this.state.isSend,
        data: {},
        useExternalAddress: true
      });
    };

    _this.submitData = async function(customData) {
      let _this$props2 = _this.props,
      receiveFunds = _this$props2.receiveFunds,
      sendFunds = _this$props2.sendFunds,
      sendToAddress = _this$props2.sendToAddress,
      ProductId = _this$props2.product.ProductId,
      template = _this$props2.template;
      
      if (ProductId === 14) {
        template = {
          TemplateType: 'ToExternalEthereumAddress',
          Comment: '',
          ExternalAddress: ''
        };
      }
      
      let TemplateType = template.TemplateType;
      
      const _this$state3 = _this.state,
      isSend = _this$state3.isSend,
      useExternalAddress = _this$state3.useExternalAddress,
      data = _this$state3.data,
      _this$state3$data = _this$state3.data,
      Notes = _this$state3$data.Notes,
      ReceiverUsername = _this$state3$data.ReceiverUsername,
      Amount = _this$state3$data.Amount,
      selectedAccountId = _this$props2.position.AccountId;
      const customAmount = (customData || {}).hasOwnProperty("Amount") ? Number(customData.Amount) : Amount;
      
      const payload = {
        Notes: Notes || '',
        ReceiverUsername: ReceiverUsername,
        Amount: customAmount,
        ProductId: ProductId,
        selectedAccountId
      };

      if (isSend) {
        let dataTrustList = { userId: _this$props2.UserId };

        if (_this$state3.showLabel) {
          dataTrustList = {
            ...dataTrustList,
            accountId: _this.props.position.AccountId,
            apProductId: _this.props.product.ProductId,
            operation: useExternalAddress
              ? 'External Wallet'
              : 'Banexcoin Transfer',
            label: _this.state.data.Label
          };
        }

        if (_this$state3.useExternalAddress) {
          dataTrustList.address = _this.state.data.ExternalAddress;

          (async () => {
            const addressPayload = {
              ProductId: ProductId,
              Amount: customAmount,
              TemplateType: TemplateType,
              TemplateForm: Object.assign({}, template, {
                ...data,
                Amount: customAmount
              })
            };

            await sendToAddress(addressPayload, dataTrustList);
          })();
        } else {
          dataTrustList.receiveEmail = _this.state.data.ReceiverUsername;
          await sendFunds(payload, dataTrustList);
        }
      } else {
        receiveFunds(payload);
      }

      _this.closeConfirmModal();
    };

    _this.state = {
      isSend: props.isSend,
      useExternalAddress: true,
      data: {},
      openConfirmModal: false,
      fee: null
    };

    return _this;
  }

  _createClass(
    SendReceiveSidePane,
    [
      {
        key: 'componentDidMount',
        value: function componentDidMount() {
          this.props.onSidePaneOpen({
            customClass: this.props.showDetails
              ? 'retail-sidepane-with-details'
              : 'retail-sidepane',
            hideHeader: true
          });
        }
      },
      {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
          if (prevState.fee !== this.state.fee) {
            this.setTotal();
          }
        }
      },
      {
        key: 'render',
        value: function render() {
          var _props = this.props,
            product = _props.product,
            _props$position = _props.position,
            Amount = _props$position.Amount,
            Hold = _props$position.Hold,
            fee = _props.fee,
            showDetails = _props.showDetails,
            allowTransfer = _props.allowTransfer,
            submitData = _props.submitData,
            closeConfirmModal = _props.closeConfirmModal,
            openConfirmModal = _props.openConfirmModal,
            hideFees = _props.hideFees;
            var _state = this.state,
            isSend = _state.isSend,
            useExternalAddress = _state.useExternalAddress,
            data = _state.data,
            total = _state.total,
            modalIsOpen = _state.openConfirmModal,
            showLabel = _state.showLabel;
            var balance = getAvailableBalance(Amount, Hold);
            
          const isDisabled = () => {
            let status = false;
            let desactivar = true;
            if (!desactivar) {
              if (product.ProductSymbol === 'USDC') {
                let start = 1618444800000;
                let today = Number(moment());
                if (today >= start) {
                  status = true;
                }
              }
            }

            return status;
          };
          let infoHeader = '';
          const title = isSend
            ? this.context.t('')
            : this.context.t('Instructions');
          const classModifiers = isSend ? 'top' : '';

         

          return React.createElement(
            'div',
            { className: sendReceiveClasses() },
            React.createElement(
              'section',
              { className: sendReceiveClasses('main') },
              React.createElement(SendReceiveHeaderComponent, {
                product: product
              }),
              React.createElement(
                'section',
                { className: sendReceiveClasses('main-form') },
                React.createElement(SendReceiveFormTabsComponent, {
                  isSend: isSend,
                  toggleTab: this.onTabChange
                }),
                isDisabled() ? (
                  <React.Fragment>
                    <div className="disabled-box">
                      { this.context.t('Banexcoin will not allow deposits and withdrawals of USD Coin (USDC) from Thursday, April 15th, 2021 at 00:00 a.m. Lima time (05:00 UTC)') }{' '}
                      <b>{ this.context.t('until the hard fork upgrade has finished from the Ethereum consensus protocol.') }</b>
                    </div>
                  </React.Fragment>
                ) : isSend ? (
                  React.createElement(SendFormContainer, {
                    product: product,
                    useExternalAddress: useExternalAddress,
                    balance: balance,
                    fee: fee,
                    total: total,
                    showDetails: showDetails,
                    toggleUseExternalAddress: this.toggleUseExternalAddress,
                    onChange: this.onDataUpdate,
                    onSubmit: this.openConfirmModal,
                    allowTransfer: allowTransfer,
                    handleTrustListChange: this.handleTrustListChange,
                    showLabel: showLabel,
                    title: title,
                    infoHeader: infoHeader,
                    classModifiers: classModifiers + (useExternalAddress ? ' text-external-box' : ''),
                    isSend,
                    data,
                    balance,
                    total,
                    submitData : this.submitData,
                    openConfirmModal : modalIsOpen,
                    closeConfirmModal : this.closeConfirmModal,
                    hideFees
                  })
                ) : (
                  React.createElement(ReceiveContainer, {
                    product: product,
                    useExternalAddress: useExternalAddress,
                    toggleUseExternalAddress: this.toggleUseExternalAddress,
                    onChange: this.onDataUpdate,
                    onSubmit: this.openConfirmModal,
                    allowTransfer: allowTransfer
                  })
                )
              )
            ),
            showDetails &&
              React.createElement(
                'section',
                { className: sendReceiveClasses('side') },
                React.createElement(
                  'section',
                  {
                    className: sendReceiveClasses(
                      isSend ? 'send-side-details' : 'side-details'
                    )
                  },
                  <LimitsBalance
                    product={product}
                    side={isSend ? 'withdraw' : 'deposit'}
                  />,
                  React.createElement(SendReceiveDetailsComponent, {
                    isSend: isSend,
                    useExternalAddress: useExternalAddress,
                    details: data,
                    balance: balance,
                    total: total,
                    product: product,
                    fee: fee
                  })
                )
              ),
              modalIsOpen && !isSend && React.createElement(SendReceiveConfirmModal, {
                isOpen: modalIsOpen,
                useExternalAddress: useExternalAddress,
                close: this.closeConfirmModal,
                product: product,
                isSend: isSend,
                values: data,
                balance: balance,
                total: total,
                fee: fee,
                onConfirm: this.submitData,
                hideFees: hideFees
              }),
              React.createElement(SendReceiveTwoFactorAuthContainer, null)
          );
        }
      }
    ],
    [
      {
        key: 'getDerivedStateFromProps',
        value: function getDerivedStateFromProps(nextProps, prevState) {
          if (nextProps.fee !== prevState.fee) {
            return { fee: nextProps.fee };
          }
          return null;
        }
      }
    ]
  );

  return SendReceiveSidePane;
})(React.Component);

SendReceiveSidePane.defaultProps = {
  showDetails: true,
  onSidePaneOpen: function onSidePaneOpen() {},
  isSend: true
};

SendReceiveSidePane.propTypes = {
  allowTransfer: PropTypes.bool.isRequired,
  openRetailSendSidePane: PropTypes.func,
  openRetailReceiveSidePane: PropTypes.func,
  product: product,
  position: PropTypes.shape({
    AccountId: PropTypes.number,
    Amount: PropTypes.number,
    Hold: PropTypes.number,
    OMSId: PropTypes.number,
    PendingDeposits: PropTypes.number,
    PendingWithdraws: PropTypes.number,
    ProductId: PropTypes.number,
    ProductSymbol: PropTypes.string,
    TotalDayDeposits: PropTypes.number,
    TotalDayWithdraws: PropTypes.number,
    TotalMonthWithdraws: PropTypes.number,
    TotalYearWithdraws: PropTypes.number
  })
};

SendReceiveSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};

SendReceiveSidePane.mapStateToProps = function(state) {
  return {
    withdrawDetails: state.withdrawDetails,
  }
}

export default connect(SendReceiveSidePane.mapStateToProps)(SendReceiveSidePane);
