import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuCard } from '../../../components';
import storeLocation from "../../../../../images/icons-v2/store-location.svg";
import international from "../../../../../images/icons-v2/international.svg";
import blockCard from "../../../../../images/icons-v2/block-card.svg";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'is-empty';
import BanexcardController from '../../../controllers/BanexcardController';
import { setBanexcardConfig as _setBanexcardConfig } from '../../../../../redux/actions/set_banex_card';
import { banexcardSettingCodes } from '../../../constants/banexcardSettingCodes';

function PhysicalCardMenu(props, context) {
  const { banexcardConfig, userInfo, setBanexcardConfig } = props;
  const ht = useHistory();
  const [internationalToggle, setInternationalToggle] = React.useState(false);
  const [inPersonPurchasesToggle, setInPersonPurchasesToggle] = React.useState(false);
  const [blockCardToggle, setBlockCardToggle] = React.useState(false);

  const findBanexcardConfigByCode = (code) => {
    const item =  banexcardConfig.find(config => config.channelCode === code);
    if(!item) return false;
    return item.enabled;
  }
  const options = [
    {
      title: context.t("Foreign purchases"),
      description: context.t("You can buy in other countries"),
      onClick: () => setInternationalToggle(internationalToggle => !internationalToggle),
      icon: international,
      type:"toggle",
      value: findBanexcardConfigByCode(banexcardSettingCodes.SHOPPING_ABROAD.code)
    },
    {
      title: context.t("In-store purchases"),
      description: context.t("You can buy in stores"),
      onClick: () => setInPersonPurchasesToggle(inPersonPurchasesToggle => !inPersonPurchasesToggle),
      icon: storeLocation,
      type:"toggle",
      value: findBanexcardConfigByCode(banexcardSettingCodes.SHOPPING_ABROAD.code)
    },
    {
      title: context.t("Block card"),
      description: context.t("Your physical card will not be able to be used"),
      icon: blockCard,
      type: "toggle",
      onClick: () => setBlockCardToggle(blockCardToggle => !blockCardToggle),
    },
  ];

  const handleGetUserConfig = async () => {
    const response = await BanexcardController.getBanexcardConfiguration({
      userInfo,
    });
    setBanexcardConfig(response);
  };

  useEffect(() => {
    if(!isEmpty(banexcardConfig) || isEmpty(userInfo)) return 
    handleGetUserConfig();
  }, [userInfo]);

  return <MenuCard title="Physical" description={context.t("For your in-store purchases")} options={options} customClassName="min-h-544"/>
}

PhysicalCardMenu.contextTypes = {
  t: PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
  const { banexCard: { config }, user: { userInfo } } = state;
  return {
    userInfo,
    banexcardConfig: config,
  };
}
  
const mapDispatchToProps = (dispatch) => ({
  setBanexcardConfig: (config) => dispatch(_setBanexcardConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalCardMenu);