import HandlerBackend from "../../../helpers/HandlerBackend";

export const resendEmail = async (input) => {
  try {
    const path = "/api/v1/resend-verification-email";
    const response = await HandlerBackend.sendRequest({
      method: "POST",
      path,
      data:{
        emailAddress: input
      }
    });
    return response.data;
  } catch (error) {
    return { detail: error.response.data.message };
  }
};
