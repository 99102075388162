import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import TransfersComponent from './TransfersComponent';
import {
  approveTransferRequest as _approveTransferRequest,
  rejectTransferRequest as _rejectTransferRequest
} from 'apex-web/lib/redux/actions/transferRequestActions';
import { transfersByProductIdSelector } from 'apex-web/lib/redux/selectors/transfersTableSelectors';
import config from '../../config';

var mapStateToProps = function mapStateToProps(state) {
  var _transfersByProductId = transfersByProductIdSelector(state),
    receivedRequests = _transfersByProductId.receivedRequests,
    sentRequests = _transfersByProductId.sentRequests,
    sentTransfers = _transfersByProductId.sentTransfers,
    receivedTransfers = _transfersByProductId.receivedTransfers;

  var formSelector = formValueSelector('transfers-filters-form');
  var selectedFilter = formSelector(state, 'transfersFilters');

  var products = state.apexCore.product.products;
  var usePagination = config.OrderHistoryData.usePagination;

  var addProduct = function addProduct(item) {
    return (item.product = products.find(function(p) {
      return item.ProductId === p.ProductId;
    }));
  };

  receivedRequests.forEach(addProduct);
  sentRequests.forEach(addProduct);
  sentTransfers.forEach(addProduct);
  receivedTransfers.forEach(addProduct);

  return {
    receivedRequests: receivedRequests,
    sentRequests: sentRequests,
    sentTransfers: sentTransfers,
    receivedTransfers: receivedTransfers,
    usePagination: usePagination,
    selectedFilter: selectedFilter ? selectedFilter : 'sentTransfers'
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    approveTransferRequest: function approveTransferRequest(requestCode) {
      return dispatch(_approveTransferRequest(requestCode));
    },
    rejectTransferRequest: function rejectTransferRequest(requestCode) {
      return dispatch(_rejectTransferRequest(requestCode));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransfersComponent);
