import HandlerBackend from '../../helpers/HandlerBackend';
import moment from 'moment';
import isEmpty from 'is-empty';

export const loginCoink = (token, userId) => {
  let model = {
    handshake_token: token
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/coink/login`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getNewQuote = ({ token, userId, amount, side, instrument }) => {
  let model = {
    coinkId: token,
    amount,
    side,
    instrument
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/coink/quotes`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getBalanceCoink = (token, userId) => {
  let model = {
    coinkId: token
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/balance`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const startBuyingCoink = (token, userId, data) => {
  let model = {
    handshake_token: token,
    callback_url: data.callback_url,
    subject: 'Pago crypto',
    amount: data.amount_buy,
    currency: 'COP',
    product: 'BTC',
    product_amount: data.quantity_buy
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/coink/buy`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const confirmBuyingTransactionCoink = (userId, data) => {
  let model = {
    payment_id: data.payment_id
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/coink/transaction-confirm`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getLoginToken = async data => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/token`;

  return HandlerBackend.sendSimpleRequest({
    method: 'post',
    headers,
    path,
    data: data
  });
};

export const resendOtp = async data => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v4/coink/sell_resend_otp`;

  return HandlerBackend.sendSimpleRequest({
    method: 'post',
    headers,
    path,
    data: data
  });
};

export const startBuyingCoinkV2 = (token, userId, data) => {
  let model = {
    coinkId: token,
    callback_url: data.callback_url,
    subject: 'Pago crypto',
    amount: data.amount,
    currency: 'COP',
    product: 'BTC',
    product_amount: data.product_amount
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/buy`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const startBuyingCoinkV3 = ({
  coinkId,
  userId,
  quoteId,
  callbackUrl
}) => {
  const data = {
    coinkId,
    quoteId,
    callback_url: callbackUrl
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/buy`;
  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data
  });
};

export const confirmBuyTransactionV2 = (token, userId, data) => {
  let model = {
    coinkId: token,
    payment_id: data.payment_id
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/buy_auth`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const confirmBuyTransactionV3 = ({ token, userId, data }) => {
  let model = {
    coinkId: token,
    payment_id: data.payment_id
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/buy_auth`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const startSellingCoinkV2 = (token, userId, data) => {
  let model = {
    coinkId: token,
    callback_url: data.callback_url,
    subject: 'Venta crypto',
    amount: Number(data.amount),
    currency: 'COP',
    product: data.product,
    product_amount: data.product_amount,
    sell_info: {
      descr: 'Venta Crypto / Banexcoin'
    }
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/sell`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const startSellingCoinkV3 = (token, userId, data) => {
  let model = {
    coinkId: token,
    callback_url: data.callback_url,
    subject: 'Venta crypto',
    amount: Number(data.amount),
    currency: 'COP',
    product: data.product,
    product_amount: data.product_amount,
    sell_info: {
      descr: 'Venta Crypto / Banexcoin'
    }
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/sell_auth`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const confirmSellTransactionV3 = (token, userId, data) => {
  let model = {
    coinkId: token,
    paymentAttempsId: data.paymentAttempsId,
    otp: data.otp
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/sell`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const startSellingCoinkV4 = (token, userId, data) => {
  let model = {
    coinkId: token,
    quoteId: data.quoteId,
    callback_url: data.callback_url,
    subject: 'Venta crypto'
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v4/coink/sell`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const confirmSellTransactionV4 = (token, userId, data) => {
  let model = {
    coinkId: token,
    paymentAttempsId: data.paymentAttempsId,
    otp: data.otp
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v4/coink/sell_auth`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getTransactionInfoV2 = (token, userId, data) => {
  let model = {
    transactionId: data.transaction_id,
    timestamp: Number(moment())
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v2/coink/transaction_info`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const calcQuantityAprox = (amountInput, price, instrument) => {
  let total = parseFloat(amountInput);
  let feeProcessingPercentage = parseFloat(4);
  let feeInternal = parseFloat(0.75);
  let feeProcessing = total * (feeProcessingPercentage / 100);
  let amount = total - (feeProcessing + feeInternal);

  let btcToBuyAprox = amount / price;

  let fixedbtcToBuyAprox = parseFloat(parseFloat(btcToBuyAprox).toFixed(8));
  return fixedbtcToBuyAprox;
};

export const genBiometricLink = (userId, data) => {
  let model = {
    successRedirectUrl: data.successRedirectUrl,
    errorRedirectUrl: data.errorRedirectUrl
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/coink/onboarding/create`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const checkBiometricLink = userId => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/coink/onboarding/query-callback`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'get',
    headers,
    path
  });
};

export const confirmOnboarding = (userId, data) => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/coink/onboarding/confirm`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: data
  });
};

export const resendOnboarding = (userId, data) => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/coink/onboarding/confirm/resend`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: data
  });
};

export const withdrawCryptoOne = (token, userId, data) => {
  let model = {
    coinkId: token,
    amount: data.amount,
    externalAddress: data.externalAddress,
    product: data.product
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/sendto`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const withdrawCryptoTwo = (token, userId, data) => {
  let model = {
    coinkId: token,
    otp: data.otp,
    withdrawId: data.withdrawId
  };

  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/sendto_auth`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getTransaccionsByType = (userId, data) => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v3/coink/transactions/${data.type}/${data.accountid}/${
    data.limit
  }/${data.page}`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'get',
    headers,
    path
  });
};

export const submitDepositAffidavit = (data, userId) => {
  const headers = {};
  const path = '/api/v1/deposit';
  const model = {
    id: data.id,
    affidavit_source_funds: !isEmpty(data.affidavit_source_funds)
      ? data.affidavit_source_funds
      : '',
    affidavit_source_funds_others: !isEmpty(data.affidavit_source_funds_others)
      ? data.affidavit_source_funds_others
      : ''
  };
  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
