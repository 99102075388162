import React from 'react'
import { confirmAlert } from "react-confirm-alert";
export const coinkAlert = (content, context) => {
  if(!context) return <></>
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui custom-notice">
          <p>{context.t('Retiro/Trade de fondos')}</p>
          <p>{context.t(content)}</p>
          <div className="btn-alert-container">
            <button
              className="btn-alert coink-swiping"
              onClick={() => {
                onClose();
              }}>
              {context.t('Accept')}
            </button>
          </div>
        </div>
      );
    }
  });
};