import HandlerBackend from "../../../../helpers/HandlerBackend";

export default class ClaimsPageController{
    static sendNotification = ({data}) => {
        const path = '/api/v1/libro-reclamaciones';
        return HandlerBackend.sendRequest({
          method: 'POST',
          headers:{
            "Content-Type":"application/json"
          },
          path,
          data
        });
    }
}