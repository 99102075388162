import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import KYC_IMFormStepComponent from './KYC_IMFormStepComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    formValues: getFormValues('kycIMForm')(state)
  };
};

var KYCIdentityMindFormStepForm = reduxForm({
  form: 'kycIMForm',
  multipartForm: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(KYC_IMFormStepComponent);

export default connect(mapStateToProps)(KYCIdentityMindFormStepForm);
