export const createStateSnackbar = (payload) => {
    return {
        type: "CREATE_STATE_SNACKBAR",
        payload
    };
};
export const updateStateSnackbar = (payload) => {
    return {
        type: "UPDATE_STATE_SNACKBAR",
        payload
    };
};
export const resetStateSnackbar = () => {
    return {
        type: "RESET_STATE_SNACKBAR"
    };
};