import React, {  useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'is-empty'
import { Col, Form, Row } from 'react-bootstrap'
import InputElement from '../InputElement/InputElement'
import ErrorCircle from '../../../../images/bnx-icons/error-circle.svg';
import SuccessCircle from '../../../../images/bnx-icons/success-circle.svg';
import eye from '../../../../images/eye.svg'
import eyeSlash from '../../../../images/eye-slash.svg'
import lock from '../../../../images/lock.svg'
import { FormButtonV2, FormSingleCheckbox } from '../../../../components/common/BnxFormsComponents'
import { PASSWORD_VALIDATION_LIST, REGEX_LIST } from '../../../../constants'
import { useForm } from '../../../../hooks/formHooks'
import { OcupationsDetails as occupationsDetails, occupations } from '../../../../config/staticOcupationDetails'
import { Spinner } from '../../../../components/Spinner/Spinner'
import backIcon from '../../../../images/icons-v2/ArrowRight2.svg'
import { getPublicCountries } from '../../hooks/signupHooks'

function SignupNaturalCoinkForm(props, context) {
	const { signUp, loading, data } = props
	const [showPasswordHelp, setShowPasswordHelp] = useState(false);
	const [showPasswordHelp2, setShowPasswordHelp2] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	const [countries, setCountries] = useState({});
	const [step, setStep] = useState(1);
	const [passwordValidator, setPasswordValidator] = useState({
		caracter: false,
		number: false,
		capitalLetter: false,
		lowerLetter: false,
		symbol: false
	});
	const [passwordValidator2, setPasswordValidator2] = useState({
		caracter: false,
		number: false,
		capitalLetter: false,
		lowerLetter: false,
		symbol: false
	});

	const getCountries = async () => {
    try {
      const countriesReq = await getPublicCountries();
      if (countriesReq.hasOwnProperty('data') && !isEmpty(countriesReq.data)) {
        const countriesData = countriesReq.data.countries;
        const listCountries = countriesData.map((c) => {
          return {
            value: c.countryCode,
            label: c.countryName
          };
        });
        setCountries(listCountries);
      }
    } catch (error) {
			setCountries([]);
    }
  };

	useEffect(() => {
    getCountries();
  }, []);

	const customValidations = (inputs) => {
		let errors = {};
		if (!inputs.privacyPolicy) {
			errors.privacyPolicy = 'You must accept the privacy policy'
		}
		if (!inputs.iamOlderTerms) {
			errors.iamOlderTerms = 'You must be older than 18 years old'
		}
		if(isEmpty(inputs.occupation)) {
			errors.occupation = 'You must select an occupation'
		}
		if(isEmpty(inputs.occupationDetails)) {
			errors.occupationDetails = 'You must select an occupation details'
		}
		if(isEmpty(inputs.city)) {
			errors.city = 'You must enter a city'
		}
		if(isEmpty(inputs.state)) {
			errors.state = 'You must enter a municipality or locality'
		}
		if(isEmpty(inputs.addressMain)) {
			errors.addressMain = 'You must enter an address'
		}
		if(inputs.occupationDetails === 'Other' && isEmpty(inputs.otherOccupation)) {
			errors.otherOccupation = 'You must detail your occupation'
		}
		errors = passwordValidation(inputs, errors)
		if(inputs.password !== inputs.confirmPassword) {
			errors.confirmPassword = 'Passwords do not match'
		}
		setErrors(errors);
		return errors;
	};

	const passwordValidation = (inputs, errors) => {
		if(!isEmpty(inputs.password) && inputs.password.length < 8) {
      errors.password = 'Password must contain at least 8 characters';
      return errors
    }
		if (!isEmpty(inputs.password)) {
			const validatePassword = REGEX_LIST.regexToValidatePassword.test(inputs.password);
			if (!validatePassword) {
				errors.password = 'Your password must contain at least one capital letter, numbers and a symbol';
			}
		}
		return errors
	}

	const handlePasswordValidator = (value, type) => {
		if (type === 'confirmPassword') {
			return setPasswordValidator2({
				caracter: REGEX_LIST.regexToValidateCharacters.test(value),
				number: REGEX_LIST.regexToValidateNumber.test(value),
				capitalLetter: REGEX_LIST.regexToValidateCapitalLetter.test(value),
				lowerLetter: REGEX_LIST.regexToValidateLowerLetter.test(value),
				symbol: REGEX_LIST.regexToValidateSymbol.test(value)
			})
		}
		setPasswordValidator({
			caracter: REGEX_LIST.regexToValidateCharacters.test(value),
			number: REGEX_LIST.regexToValidateNumber.test(value),
			capitalLetter: REGEX_LIST.regexToValidateCapitalLetter.test(value),
			lowerLetter: REGEX_LIST.regexToValidateLowerLetter.test(value),
			symbol: REGEX_LIST.regexToValidateSymbol.test(value)
		})
	};

	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target
		onInputChangeByName(name, checked)
	}

	const {
		inputs,
		errors,
		setErrors,
		onDefaultChange,
		onInputChangeByName,
		setInputs
	} = useForm({
		password: "",
		occupation: "",
		occupationDetails: "",
		privacyPolicy: false,
		processingPersonalData: false,
		iamOlderTerms: false,
		residenceCountry: "CO",
		birthCountry: "CO"
	}, () => {}, customValidations);
		
		const handleSignup = async (e) => {
			e.preventDefault();
			const newErrors = customValidations(inputs)
			if (!isEmpty(newErrors)) return
			let data = {...inputs}
			if(isEmpty(inputs.otherOccupation)){
				data.otherOccupation = null
			}
			await signUp(data)
		}
	
	const handleChangeInput = (e) => {
		onDefaultChange(e)
		delete errors[e.target.name]
	}

	const passwordInputProps = {
		values: {
			title: 'Password',
			value: inputs.password || '',
			name: 'password',
			type: showPassword ? 'text' : 'password',
			autoComplete: "new-password",
			rightIcon: showPassword ? eyeSlash : eye,
			icon: lock
		},
		actions: {
			onChange: (e) => {
				handleChangeInput(e)
				handlePasswordValidator(e.target.value);
			},
			onClick: () => setShowPasswordHelp(true),
			onBlur: () => setShowPasswordHelp(false),
			onChangeSecondary: () => setShowPassword(showPassword => !showPassword)
		},
		error: errors.password
	};
	const confirmPasswordInputProps = {
		values: {
			title: 'Confirm password',
			value: inputs.confirmPassword || '',
			name: 'confirmPassword',
			type: showPassword2 ? 'text' : 'password',
			autoComplete: "new-password",
			rightIcon: showPassword2 ? eyeSlash : eye,
			icon: lock
		},
		actions: {
			onChange: (e) => {
				handleChangeInput(e)
				handlePasswordValidator(e.target.value, 'confirmPassword');
			},
			onClick: () => setShowPasswordHelp2(true),
			onBlur: () => setShowPasswordHelp2(false),
			onChangeSecondary: () => setShowPassword2(showPassword => !showPassword)
		},
		error: errors.confirmPassword
	};

	const residenceCountryInputProps = {
    values: {
      title: 'Residence country',
      value: inputs.residenceCountry || 'CO',
      name: 'residenceCountry',
      type: 'select',
      options: countries
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.residenceCountry
  };
	
	const cityInputProps = {
		values: {
			title: 'Department',
			value: inputs.city || '',
			name: 'city',
			type: 'text',
			options: []
		},
		actions: {
			onChange: handleChangeInput
		},
		error: errors.city
	};

	const stateInputProps = {
		values: {
			title: 'Municipality/Location',
			value: inputs.state || '',
			name: 'state',
			type: 'text'
		},
		actions: {
			onChange: handleChangeInput
		},
		error: errors.state
	};

	const addressMainInputProps = {
		values: {
			title: 'Address',
			value: inputs.addressMain || '',
			name: 'addressMain',
			type: 'text'
		},
		actions: {
			onChange: handleChangeInput
		},
		error: errors.addressMain
	};
  const birthCountryInputProps = {
    values: {
      title: 'Birth country',
      value: inputs.birthCountry || 'CO',
      name: 'birthCountry',
      type: 'select',
      options: countries
    },
    actions: {
      onChange: handleChangeInput
    },
    error: errors.birthCountry 
  };


	const occupationInputProps = {
		values: {
			title: 'Occupation',
			value: inputs.occupation || '',
			name: 'occupation',
			type: 'select',
			options: occupations
		},
		actions: {
			onChange: (e) => {
				handleChangeInput(e)
				setInputs(inputs => ({...inputs, occupationDetails: ''}))
			} 
		},
		error: errors.occupation
	};

	const occupationDetailsInputProps = {
		values: {
			title: 'Occupation details',
			value: inputs.occupationDetails || '',
			name: 'occupationDetails',
			type: "select",
			options: (occupationsDetails[inputs.occupation] || []).map(item => ({ value: item.id, label: item.name}))
		},
		actions: {
			onChange: (e) => {
				handleChangeInput(e)
				setInputs(inputs => ({...inputs, otherOccupation: ''}))
			} 
		},
		error: errors.occupationDetails
	};

	const emailInputProps = {
		values: {
			title: 'Correo electrónico',
			value: data.email,
			name: 'email',
			type: 'text'
		},
		actions: {
			onChange: () => {}
		}
	};

	const otherOccupationInputProps = {
		values: {
			title: 'Especifica tu ocupación',
			value: inputs.otherOccupation || '',
			name: 'otherOccupation',
			type: 'text'
		},
		actions: {
			onChange: handleChangeInput
		},
		error: errors.otherOccupation
	};

	const defaultInputsValidation = () => {
		if(step === 1){
			if (isEmpty(inputs.state)) return true
			if (isEmpty(inputs.addressMain)) return true
			if (isEmpty(inputs.city)) return true
			if (isEmpty(inputs.occupation)) return true
			if (isEmpty(inputs.occupationDetails)) return true
			if (isEmpty(inputs.birthCountry)) return true
			if (isEmpty(inputs.residenceCountry)) return true
		}else{
			if (isEmpty(inputs.password)) return true
			if (isEmpty(inputs.iamOlderTerms) || !inputs.iamOlderTerms) return true
			if (isEmpty(inputs.privacyPolicy) || !inputs.privacyPolicy) return true
		}
		return false
	}

	const handleStep = (value) => {
		setStep(step => step + value)
	}

	useEffect(() => {
		if(!window.fcWidget) return
		window.fcWidget.on('widget:closed', () => {
			window.fcWidget.hide();
    });
	},[window.fcWidget])

	const handleOpenWidget = () => {
		if(!window.fcWidget) return
		window.fcWidget.show();
		window.fcWidget.open();
	}

	return (
    <Form className="form-container-v2">
      <Row className="justify-content-center text-left">
        <Col xl="10 text-center mb-4">
				{step === 2 && (
						<div className="arrow-back" onClick={() => handleStep(-1)}>
							<img src={backIcon} alt="" />
						</div>
				)}
          <img
            src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
            alt="logo"
            width={150}
						className='logo-mobile coink'
						style={step === 2 ? {display:"none"} : {}}
          />
        </Col>
        <Col xl="10">
					<div className='subtitle-help'>
						<h1 className="title-register-section coink" style={{ marginTop:"1rem" }}>
							{step === 1 ? context.t(`Hello, {name}`, { name: data.firstName }) : context.t("One more step...")}
						</h1>
						<p className='do-you-need-help' onClick={handleOpenWidget} style={{margin:"0"}}>{context.t("Do you need help?")}</p>
					</div>
					<p className='desc-coink'>
            {context.t(
              "Please complete the following information for your registration"
            )}
          </p>
        </Col>
      </Row>
      <React.Fragment>
        <Row className="justify-content-center mt-0">
          {step === 1 && (
            <React.Fragment>
							<div className='subtitle-inputs'>
								<p>{context.t("Address")}</p>
							</div>
							<Col xl="10">
                <InputElement {...residenceCountryInputProps} />
              </Col>
              <Col xl="10">
                <InputElement {...cityInputProps} />
              </Col>
              <Col xl="10">
                <InputElement {...stateInputProps} />
              </Col>
              <Col xl="10">
                <InputElement {...addressMainInputProps} />
              </Col>
              <div className='subtitle-inputs'>
								<p>{context.t("Additional Data")}</p>
							</div>
              <Col xl="10">
                <InputElement {...birthCountryInputProps} />
              </Col>
              <div className='subtitle-inputs'>
								<p>{context.t("What do you do for a living?")}</p>
							</div>
              <Col xl="10">
                <InputElement {...occupationInputProps} />
              </Col>
              <Col xl="10">
                <InputElement {...occupationDetailsInputProps} />
              </Col>
              {!isEmpty(inputs) && inputs.occupationDetails === "Other" && (
                <Col xl="10">
                  <InputElement {...otherOccupationInputProps} />
                </Col>
              )}
            </React.Fragment>
          )}
          {step === 2 && (
						<React.Fragment>
							<div className='subtitle-inputs'>
								<p>{context.t("Access information")}</p>
							</div>
              <Col xl="10">
                <InputElement {...emailInputProps} />
              </Col>
              <Col xl="10">
                <div>
                  <InputElement {...passwordInputProps} />
                </div>
                <div
                  className={`app-modal-help-text${
                    showPasswordHelp ? " show" : ""
                  }`}
                >
                  {PASSWORD_VALIDATION_LIST.map(({ text, type }, key) => (
                    <div className="itm-option-v2" key={key}>
                      <img
                        src={
                          passwordValidator[type] ? SuccessCircle : ErrorCircle
                        }
                        alt="iconStatus"
                        width={20}
                      />
                      <p>{context.t(text)}</p>
                    </div>
                  ))}
                </div>
              </Col>
              <Col xl="10">
                <div>
                  <InputElement {...confirmPasswordInputProps} />
                </div>
                <div
                  className={`app-modal-help-text${
                    showPasswordHelp2 ? " show" : ""
                  }`}
                >
                  {PASSWORD_VALIDATION_LIST.map(({ text, type }, key) => (
                    <div className="itm-option-v2" key={key}>
                      <img
                        src={
                          passwordValidator2[type] ? SuccessCircle : ErrorCircle
                        }
                        alt="iconStatus"
                        width={20}
                      />
                      <p>{context.t(text)}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </React.Fragment>
          )}
        </Row>
        {step === 2 && (
          <React.Fragment>
            <Row className="justify-content-center checkbox-items">
              <Col xl="10">
                <FormSingleCheckbox
                  name="privacyPolicy"
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: context.t(
                          "I have read and accept the <a href='{link}' target='_blank'>Privacy Policy</a>",
                          {
                            link: "https://soporte.banexcoin.com/support/solutions/articles/60000599926",
                          }
                        ),
                      }}
                    />
                  }
                  onChange={handleCheckboxChange}
                  checked={inputs.privacyPolicy}
                  errors={errors}
                />
              </Col>
              <Col xl="10">
                <FormSingleCheckbox
                  name="iamOlderTerms"
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: context.t(
                          "I certify that I am over 18 years of age and that I have read and accept the <a href='{link}' target='_blank'>Terms and Conditions</a>",
                          {
                            link: "https://soporte.banexcoin.com/support/solutions/articles/60000599926",
                          }
                        ),
                      }}
                    />
                  }
                  onChange={handleCheckboxChange}
                  checked={inputs.iamOlderTerms}
                  errors={errors}
                />
              </Col>
              <Col xl="10">
                <FormSingleCheckbox
                  name="processingPersonalData"
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: context.t(
                          "I accept the processing of my personal data for additional purposes. <b>(Optional)</b>"
                        ),
                      }}
                    />
                  }
                  onChange={handleCheckboxChange}
                  checked={inputs.processingPersonalData}
                  errors={errors}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
      <Row className="justify-content-center mt-2 p-1-5">
        <Col xl="10">
          {
            <FormButtonV2
              disabled={defaultInputsValidation() || loading}
              variant={isEmpty(errors) ? "dark" : "light"}
              className="submit-button register-form"
							onClick={step === 1 ? () => handleStep(1) : handleSignup}
            >
              {step === 1 ? context.t("Next") : !loading ? context.t("Register") : <Spinner />}
            </FormButtonV2>
          }
        </Col>
      </Row>
    </Form>
  );
}
SignupNaturalCoinkForm.contextTypes = {
	t: PropTypes.func.isRequired
};
export default SignupNaturalCoinkForm