import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APLogo from '../../components/common/APLogo/APLogo';
import PageHeaderNavComponent from '../../components/PageHeader/PageHeaderNavComponent';
import Check2FA from './PageHeaderLayoutCheck2FA';
import UserDropdown from './UserDropdown/UserDropdown';
import './PageHeaderLayout.css';
import './UserSummaryOverrides.css';
import isEmpty from 'is-empty';
import APIcon from '../../components/common/APIcon';
import BLogo from "../../images/b.png"
import { useHistory } from 'react-router-dom';

const pageHeaderClasses = getBEMClasses('page-header');

const PageHeaderComponent = (props, context) => {
  const {
    theme,
    userInfo: { UserName },
    isCoink, 
    banexCardOptionIsVisible,
    level_basic,
  } = props;
  const history = useHistory();

  const [mainNavItems, setMainNavItems] = useState([
    { enabled: true, text: 'Home', icon: 'home', path: '/dashboard' },
    { enabled: true, text: 'Wallets', icon: 'wallet', path: '/wallets' },
    { enabled: true, text: 'Deposit', icon: 'deposit', path: '/deposit' },
    { enabled: true, text: 'Buy and Sell', icon: 'buyCrypto', path: '/buy-sell' },
    { enabled: false, text: 'Exchange', icon: 'exchange2', path: '/exchange/BTC_PEN' },
    { enabled: true, text: 'Withdraw', icon: 'withdraw', path: '/withdraw' },
    // { text: 'Promotions', icon: 'promotion', path: '#5' },
  ]);
  const [isOpen, setIsOpen] = useState(false);

  const users = [
    'andrewvergel',
    'david.arrarte',
    'lc',
    'msrangelh',
    'binancetest',
    'thespeed94'
  ];
  
  const getMenuItems = () => {
    if (!isCoink) return mainNavItems
    return mainNavItems.filter(item => item.enabled)
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.getElementsByClassName('page-header__header-item page-header__header-item--logo')[0].classList.toggle('open');
    document.getElementsByClassName('App fluid container clearfix logged dark ')[0].classList.toggle('with-menu');
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.getElementsByClassName('page-header__header-item page-header__header-item--logo')[0].classList.remove('open');
    document.getElementsByClassName('App fluid container clearfix logged dark ')[0].classList.remove('with-menu');
  }

  const openMenu = () => {
    setIsOpen(true);
    document.getElementsByClassName('page-header__header-item page-header__header-item--logo')[0].classList.add('open');
    document.getElementsByClassName('App fluid container clearfix logged dark ')[0].classList.add('with-menu');
  }

  useEffect(() => {
    if (banexCardOptionIsVisible && !isEmpty(level_basic)) {
      let path = '/cards'
      if (!isEmpty(level_basic.cardRequestsByLevelsBasicId.nodes) && level_basic.cardRequestsByLevelsBasicId.nodes[0].status === 'CREATED') {
        if(level_basic.status === 5) {
          path = '/cards/increment-level'
        } else {
          path = '/cards/request/success'
        }
      }
      setMainNavItems(prev => {
        const newItems = prev.filter(m => m.text !== 'Cards');
        newItems.push({
          text: 'Cards',
          icon: 'record',
          path
        })
        return newItems;
      })
    }
  }, [banexCardOptionIsVisible, level_basic])

  useEffect(() => {
    if (!isEmpty(UserName)) {
      const checkUser = users.includes(UserName);
      const checkBinanceOption = mainNavItems.some(m => m.text === 'Binance');
      if (checkUser && !checkBinanceOption) {
        setMainNavItems(prev => [...prev, {
          text: 'Binance',
          icon: 'binance',
          path: '/binance'
        }]);
      } else if (checkBinanceOption) {
        setMainNavItems(prev => prev.filter(m => m.text !== 'Binance'))
      }
    }
  }, [UserName])
  
  useEffect(() => {
    if(history.location.pathname.includes("exchange")){
      closeMenu();
    }else{
      toggleMenu();
    }
    const routerListener = history.listen((el) => {
      if(el.pathname.includes("exchange")){
        closeMenu();
      }else{
        openMenu();
      }
      })
    return () => {
      routerListener();
    };
  },[])

  return (
    <div className={isOpen ? "menu_hover": ""}>
      <div className={pageHeaderClasses('header-item', 'logo')}>
        <img src={BLogo} width={20} alt="banexcoin logo" className={isOpen ? "logo-opened-menu" : "logo-closed-menu"} style={{paddingTop: "8rem"}}/>
        <div className='second-logo'>
        <APLogo theme={theme}/>
        </div>
        <div className={!isOpen ? "logo-opened-menu" : "logo-closed-menu"}>
          <APLogo theme={theme} />
        </div>
      </div>
      <PageHeaderNavComponent isOpen={isOpen} toggleMenu={toggleMenu} navItems={getMenuItems()}/>
      <Check2FA />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user: { selectedAccountId, userInfo }, user, banexCard: { banexCardOptionIsVisible }, completedUserInfo: { level_basic } } = state
  return { 
    selectedAccountId,
    userInfo,
    user,
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : true,
    banexCardOptionIsVisible,
    level_basic,
  };
};

export default connect(mapStateToProps)(PageHeaderComponent);
