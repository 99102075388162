import isEmpty from "is-empty"
import HandlerBackend from "../../../helpers/HandlerBackend"
import { REGEX_LIST } from "../../../constants";

class LoginController {
  static signIn = async ({inputs}) => {
    let data = {
      password : inputs.password
    }
    const validEmail = REGEX_LIST.regexToValidateSimpleMail;
    if(validEmail.test(inputs.username)){
      data.emailAddress = inputs.username
    }else{
      data.username = inputs.username
    }
    try {
      const response = await HandlerBackend.sendRequest({
        path : "/api/check-credentials",
        method : "POST",
        data,
      })
      if(!isEmpty(response.data) && response.data.message === "success"){
        const statusData = {
          status: true
        }
        if(!isEmpty(response.data) && !isEmpty(response.data.data)){
          statusData.username = response.data.data.username
        }
        return statusData
      }
      return {
        status: false
      }
    } catch (error) {
      if(!isEmpty(error.response) && !isEmpty(error.response.data) && !isEmpty(error.response.data.message)){
        return {
          status: false,
          message: error.response.data.message
        }
      }
      return {
        status: false
      }
    }
  }

  static checkEmail = async (email) => {
    try {
        const path = `/api/check-email-address/${email}`;
        const response = await HandlerBackend.sendRequest({
        method: 'GET',
        path
        });
        return response.data
    } catch (error) {
        return false
    }
}

  static checkInput = async (data) => {
    try {
        const path = `/api/validate-input`;
        const response = await HandlerBackend.sendRequest({
        method: 'POST',
        path,
        data
        });
        return response.data
    } catch (error) {
        return false
    }
  }
}

export default LoginController