import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter,Link } from "react-router-dom";
import { callAPI } from "../../../components/Retail/RetailSidePanes/FiatWithdraw/FiatWithdrawFormComponentHooks";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { onLogin } from "../hooks/authActions";
import {
  FormSingleRadio,
} from "../../../components/common/BnxFormsComponents";
import InputElement from "./InputElement/InputElement";
import user from "../../../images/user.svg";
import email from "../../../images/mail.svg";
import isEmpty from "is-empty";
import { REGEX_LIST } from "../../../constants/regexList";
import { recoveryPasswordByEmail } from "../hooks/recoveryPassword";
import { Spinner } from "../../../components/Spinner/Spinner";
import ProblemLoginController from "./Login/ProblemLoginController";
import { resendEmail } from "../hooks/resendEmail";

const ProblemLoginForm = (props, context) => {
  const [loading,setLoading] = useState(false)
  const [inputs,setInputs] = useState({
    username:"",
    email:"",
    usernameRecovery:""
  })
  const [errors,setErrors] = useState({})
  const [success, setSuccess] = useState(false);
  const [selectedType, setSelectedType] = useState("user");

  const onDefaultChange = ev => {
    setInputs({...inputs,[ev.target.name]:ev.target.value})
    delete errors[ev.target.name]
  }

  const customValidations = (inputs) => {
    let errors = {};
    if (selectedType === "user") {
      if (isEmpty(inputs.username)) {
        errors.username = "Required field";
      }
    }
    if (selectedType === "email") {
      if (isEmpty(inputs.email)) {
        errors.email = "Required field";
      }else if(!REGEX_LIST.regexToValidateMail.test(inputs.email)){
        errors.email = "Invalid email";
      }
    }
    if (selectedType === "usernameRecovery") {
      if (isEmpty(inputs.usernameRecovery)) {
        errors.usernameRecovery = "Required field";
      }else if(!REGEX_LIST.regexToValidateMail.test(inputs.usernameRecovery)){
        errors.usernameRecovery = "Invalid email";
      }
    }
    setErrors(errors);
    return errors;
  };

  const startReset = async (inputs) => {
    try {
      const reset = await recoveryPasswordByEmail(inputs.username)
      if (reset.result) {
        setSuccess(true);
        setErrors({})
      }else{
        setSuccess(false);
        setErrors({username: reset.detail})
      }
    } catch (error) {
      setSuccess(false);
      setErrors({email:"Error trying to reset password"})
    }
  };

  const startResendEmail = async (inputs) => {
    try {
      const reset = await resendEmail(inputs.email);
      if (reset.message) {
        setSuccess(true);
        setErrors({})
      }else{
        setSuccess(false);
        setErrors({email:"Incorrect fields."})
      }
    } catch (error) {
      setSuccess(false);
      setErrors({email:"Incorrect fields."})
    }
  };

  const handleSuccessMessageBySelectedInputType  = () => {
    if(selectedType === "user"){
      return `We'll check to see if there's an associated account with this ${REGEX_LIST.regexToValidateMail.test(inputs.username) ? "email" : "username"} and we will send the instructions to reset your password securely.`
    }
    if(selectedType === "usernameRecovery"){
      return `We'll check to see if there's an associated user and we will send the instructions to the email.`
    }
    if(selectedType === "email"){
      return "We'll check if there's an associated account with this email and we will send to you the corresponding instructions to activate your account."
    }
  }
  const handleSendMaiL = async () => {
    const errors = customValidations(inputs)
    if(!isEmpty(errors)) return;
    setLoading(true)
    if (selectedType === "user") {
      await startReset(inputs);
    }
    if (selectedType === "email") {
      await startResendEmail(inputs);
    }
    if (selectedType === "usernameRecovery") {
      await ProblemLoginController.recoveryUsername({ emailAddress: inputs.usernameRecovery,setErrors, setSuccess });
    }
    setLoading(false)
  };

  const optionsType = [
    {
      value: "user",
      label: context.t("I’m an existing user and I forgot my password."),
    },
    {
      value: "usernameRecovery",
      label: context.t("I’m an existing user and I forgot my username."),
    },
    {
      value: "email",
      label: context.t(
        "I’m a new user and I haven’t received my verification email."
      ),
    },
  ];

  const handleChangeSelectedType = (e) => {
    e.persist();
    setInputs({username:"",email:"", usernameRecovery:""});
    setErrors({})
    setSelectedType(e.target.value);
  }

  const usernameInputProps = {
    values: {
      type: "text",
      name: "username",
      title: "Username / Email",
      icon: user,
      value: inputs.username
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.username,
  };
  const usernameRecoveryInputProps = {
    values: {
      type: "text",
      name: "usernameRecovery",
      title: "Email",
      icon: email,
      value: inputs.usernameRecovery,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.usernameRecovery,
  };
  const emailInputProps = {
    values: {
      type: "text",
      name: "email",
      title: "Email",
      icon: email,
      value: inputs.email,
    },
    actions: {
      onChange: onDefaultChange,
    },
    error: errors.email,
  };

  return (
    <div className="bnxv2-box-rounded fix-height problem-login">
      {!success ? (
        <React.Fragment>
          <Row />
          <div>
            <Row className="justify-content-center text-center">
              <Col xl="10">
                <h1
                  className="title-form"
                  dangerouslySetInnerHTML={{
                    __html: context.t("Problems logging in?"),
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center text-center mt-0">
              <Col xl="10">
                <p
                  className="title-form-normal description-reset-password"
                  
                >{context.t("Select your issue and provide us with your username or email.",)}</p>
              </Col>
            </Row>
            <Row className="justify-content-center text-center mt-4" style={{margin:"2rem 0"}}>
              <Col xl="10" className="p-0">
                <FormSingleRadio
                  name="troubleType"
                  onChange={handleChangeSelectedType}
                  options={optionsType}
                  value={selectedType}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl="10">
                {
                  selectedType === "user" && (
                    <InputElement {...usernameInputProps} />
                  )
                }
                {
                  selectedType === "usernameRecovery" && (
                    <InputElement {...usernameRecoveryInputProps} />
                  )
                }
                {
                  selectedType === "email" && (
                    <InputElement {...emailInputProps} />
                  )
                }
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col xl="10">
                <button
                  onClick={handleSendMaiL}
                  className="default-primary-button reset-password general-btn"
                  disabled={loading}
                >
                  {loading ? <Spinner/> : context.t("Send Email")}
                </button>
              </Col>
            </Row>
            <Row className="justify-content-center text-right mt-4">
              <Col xl="10" className="bottom-box-v2">
                <Row className="mt-2 display-flex-oneline">
                  <Col
                    xl="6"
                    className="text-center right-border link-register-bottom"
                  >
                    <Link to="/login" className="bottom-link text-dark">
                      {context.t("Login")}
                    </Link>
                  </Col>
                  <Col xl="6" className="text-center link-register-bottom">
                    <Link to="/signup/" className="bottom-link text-dark">
                      {context.t("Sign up")}
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Col className="mt-5 px-4 mx-2 bottom-options-terms">
              <a
                href="https://soporte.banexcoin.com/support/solutions/articles/60000599926"
                target="_blank"
              >
                {context.t("Terms and Conditions")}
              </a>
              <a
                href="https://soporte.banexcoin.com/support/tickets/new"
                target="_blank"
              >
                {context.t("Contact us")}
              </a>
          </Col>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row className="justify-content-center text-center mt-0 mb-0">
            <Col xl="10">
              <h1
                className="subtitle-form problem"
                dangerouslySetInnerHTML={{
                  __html: context.t(handleSuccessMessageBySelectedInputType()),
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-5 mb-0">
            <Col xl="10">
              <Link
                to="/login"
                className="v2-field btn-bnxv2 submit-button btn btn-dark"
              >
                {context.t("Login")}
              </Link>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

ProblemLoginForm.contextTypes = {
  t: PropTypes.func,
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    webAuthenticateUser: (payload) =>
      dispatch(callAPI("WebAuthenticateUser", payload)).then((res) => {
        return res;
      }),
    authenticate2FA: (payload) =>
      dispatch(callAPI("Authenticate2FA", payload)).then((res) => {
        return res;
      }),
    setonLogin: (payload) => {
      onLogin(dispatch, payload);
      ownProps.history.push("/dashboard?default=redirect");
    },
    resetPassword: (payload) =>
      dispatch(callAPI("ResetPassword", payload)).then((res) => {
        return res;
      }),
    resendVerificationEmail: (payload) =>
      dispatch(callAPI("ResendVerificationEmail", payload)).then((res) => {
        return res;
      }),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ProblemLoginForm));
