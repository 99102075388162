//import moment from 'moment';
import apex from 'apex-web/lib/apex';
import empty from 'is-empty';
import axios from 'axios';
import HandlerBackend from '../../helpers/HandlerBackend';

const setBinanceId = props => {
  (async () => {
    const id = props.binanceId;
    let UserConfigFilter = props.UserConfig;
    UserConfigFilter.push({ Key: 'BinanceId', Value: id });
    const env = { OMSId: 1, UserId: props.UserId, Config: UserConfigFilter };
    let setUserConfig = await apex.connection.SetUserConfig(env);
    console.log('SetUserConfig', setUserConfig);
    //const MSJ = app.result ? 'Variable Seteada' : '';
  })();
};

export const getFiatAccounts = (currencyCode, userId) => {
  let path = `/api/withdrawal-fiat-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

const getIP = async () => {
  let path = `https://www.cloudflare.com/cdn-cgi/trace`;

  let response = await axios({
    url: `${path}`,
    method: 'post'
  });
  let data = response.data;
  data = data
    .split('\n')
    .filter(el => el.startsWith('ip'))
    .join('\n');
  data = data.replace('ip=', '');
  return data;
};

export const startBinance = async data => {
  let ip = await getIP();
  let path = '/api/binance/auth';
  let model = JSON.stringify({
    merchantUserAccount: data.accountEmail,
    userIp: ip
  });

  let headers = {
    'Content-Type': 'application/json'
  };
  let response = await HandlerBackend.sendRequest({
    userId: data.userId,
    method: 'POST',
    headers,
    data: model,
    path
  });
  return response.data;
};

export const getQuote = async data => {
  let model = {
    requestedAmount: data.requestedAmount,
    payType: data.payType,
    binanceUserId: data.binanceUserId,
    merchantUserAccount: data.merchantUserAccount
  };

  let path = '/api/binance/quote';

  let headers = {
    'Content-Type': 'application/json'
  };
  const response = await HandlerBackend.sendRequest({
    userId: data.userId,
    method: 'post',
    headers,
    path,
    data: model
  });
  return response.data;
};

export const executeTransactionCall = async data => {
  let timestamp = Date.now();
  let model = {
    accountId: data.accountId,
    note: data.note,
    orderId: data.userId + '' + timestamp,
    quoteId: data.quoteId,
    binanceUserId: data.binanceUserId,
    merchantUserAccount: data.merchantUserAccount,
    requestedAmount: data.requestedAmount,
    quotePrice: data.quotePrice,
    quoteDataToken: data.quoteDataToken
  };

  let path = '/api/binance/execute';

  let headers = {
    'Content-Type': 'application/json'
  };
  const response = await HandlerBackend.sendRequest({
    userId: data.userId,
    method: 'post',
    headers,
    path,
    data: model
  });
  return response.data;
};

export const sendNotification = async (userId, data, UserAccountId) => {
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  let path = '/api/notifications';

  let model = {
    notification_type: 'WITHDRAW_REQUEST',
    user_id: data.user_id,
    account_id: UserAccountId,
    user_name: data.user_name,
    amount_number: data.amount,
    currency_symbol: data.currency,
    payment_method: 'Efectivo',
    bank_city: data.bank_city
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
