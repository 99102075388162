import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { callAPI } from '../../../components/Retail/RetailSidePanes/FiatWithdraw/FiatWithdrawFormComponentHooks';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from '../../../hooks/formHooks';
import { onLogin } from '../hooks/authActions';
import { FormButtonV2 } from '../../../components/common/BnxFormsComponents';
import lock from '../../../images/lock.svg'
import eye from '../../../images/eye.svg'
import eyeSlash from '../../../images/eye-slash.svg'
import user from '../../../images/user.svg'
import InputElement from './InputElement/InputElement'
import VerificationInput from 'react-verification-input';
import { Modal } from 'react-bootstrap';
import InfoCircle from '../../../images/info-circle.svg'
import isEmpty from 'is-empty';
import diamond from '../../../images/diamond.png'
import diamond2 from '../../../images/diamond2.png'
import {Spinner} from '../../../components/Spinner/Spinner'
import LoginController from './LoginController';

const LoginForm = (props, context) => {
  const {
    webAuthenticateUser,
    authenticate2FA,
    setonLogin,
    setTwofa,
    twofa,
    url
  } = props;
  const initialCounter = 5;
  const [loading,setLoading] = useState(false)
  const [generalErrors, setGeneralErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorCounter, setErrorCounter] = useState(initialCounter);

  const initialState = {
    validated: false,
    showPassword: false
  };

  const handleClose = () => setShowErrorModal(false);

  const handleShowErrorModal = (count, list) => {
    try {
      setGeneralErrors({
        main: ''
      });
      const encode = window.btoa(JSON.stringify(list));
      localStorage.setItem('cn_er2', encode);
      setErrorCounter(count);
      setShowErrorModal(true);
    } catch (error) {
      return false
    }
  }

  const emptyVal = val => {
    let result = false;
    if (empty(val)) {
      result = true;
    } else {
      result = empty(val.trim());
    }
    return result;
  };

  const loginFlow = async inputs => {
    try {
      setLoading(true)
      const login = await LoginController.signIn({inputs})
      let newUsersList = {};
      let count = initialCounter - 1;
      const decode = window.atob(localStorage.getItem('cn_er2') || 'e30=');
      const usersList = JSON.parse(decode);
      if (!login.status) {
        const newCount = (!isEmpty(usersList) && !isEmpty(usersList[inputs.username])) ? usersList[inputs.username] - 1 : count;
        newUsersList = {
          ...usersList,
          [inputs.username]: newCount
        };
        count = newCount;
        if (login.message === "2FA code is required") {
          await webAuthenticateUser({
            username: login.username,
            password: inputs.password,
          })
          setTwofa(true);
          return setGeneralErrors({
            main: ''
          });
        }
        if (login.message !== "The user you are trying to log in with is locked" && count > 0) {
          handleShowErrorModal(count, newUsersList);
        } else {
          const messageError = 'User is locked. Please contact administrator'
          if (!isEmpty(usersList)) {
            delete usersList[inputs.username];
            const encode = window.btoa(JSON.stringify(usersList));
            localStorage.setItem('cn_er2', encode);
          }
          setErrorCounter(initialCounter);
          setGeneralErrors({ main: messageError });
        }
      } else {
        if (!isEmpty(usersList)) {
          delete usersList[inputs.username];
          const encode = window.btoa(JSON.stringify(usersList));
          localStorage.setItem('cn_er2', encode);
        }
        const response = await webAuthenticateUser({
          username: login.username,
          password: inputs.password,
        })
        if(response.Authenticated){
          if(response.Requires2FA){
            return setTwofa(true);
          }
          return setonLogin(response);
        }
      }
    } catch (error) {
      return false
    }finally{
      setLoading(false)
    }
  };

  const onChange2FA = async e => {
    e.persist();
    const value = e.target.value;
    const valueLength = value.length;
    if (valueLength === 6) {
      const a2fa = await authenticate2FA({
        Code: value
      });
      if (a2fa.Authenticated) {
        setGeneralErrors({
          main: ''
        });
          setonLogin(a2fa);
      } else {
        setGeneralErrors({
          main: 'Invalid Auth Code. Please try again.'
        });
      }
    }
  };

  const onSubmitCallback = async (inputs, form, newErrors) => {
    if (empty(newErrors)) {
      await loginFlow(inputs);
    }
  };
  const customValidations = inputs => {
    let errors = {};
    if (emptyVal(inputs.username)) {
      errors.username = 'Required field';
    }
    if (empty(inputs.password)) {
      errors.password = 'Required field';
    }
    setErrors(errors);
    return errors;
  };
  const {
    inputs,
    errors,
    setErrors,
    onDefaultChange,
    onSubmitSimple,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);

  const togglePass = () => {
    setInputs({
      ...inputs,
      showPassword: !inputs.showPassword
    });
  };

  const EmailInputProps = {
    values: {
      title: 'Username or email',
      value: inputs.username,
      name: 'username',
      type: 'text',
      icon: user,
      autocomplete: 'new-password',
      autoFocus: true
    },
    actions: {
      onChange: onDefaultChange
    },
    error: errors.username
  };
  const PasswordInputProps = {
    values: {
      title: 'Password',
      value: inputs.password,
      name: 'password',
      icon: lock,
      rightIcon: inputs.showPassword ? eyeSlash : eye,
      type: inputs.showPassword ? 'text' : 'password',
    },
    actions: {
      onChange: onDefaultChange,
      onChangeSecondary: togglePass
    },
    error: errors.password
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      customValidations(inputs)
    }
  }, [inputs])

  return (
    <div className="bnxv2-box-rounded fix-height">
      <img className="diamond login-diamond" src={diamond} alt="aux diamond background" />
      <img
        className="diamond2 login-diamond"
        src={diamond2}
        alt="aux diamond background"
      />
      {!twofa ? (
        <React.Fragment>
          <Modal
            backdrop="static"
            onHide={handleClose}
            show={showErrorModal}
            centered
          >
            <div className='app-modal-information'>
              <Modal.Header closeButton className='button-modal-close' />
              <div className='container-image-alert'><img src={InfoCircle} alt="info circle" /></div>
              <h1 className='header-modal-title'>{context.t('Your username or password is incorrect, you have {value} more attempts', { value: errorCounter })}.</h1>
              <p>{context.t('Remember that you must')}...</p>
              <div>
                <p>1- {context.t('Use the username you created or your email')}.</p>
                <p>2- {context.t('You must enter the password correctly')}.</p>
                <p>3- {context.t('Use the pre-configured 2-factor authentication code on your phone')}.</p>
              </div>
              <p className='footer-modal-text'>
                {context.t("If you don't remember your password or you haven't received your verification email, click")}{' '}
                <Link to="/problem-logging-in">{context.t('here')}.</Link>
              </p>
            </div>
          </Modal>
          <Row />
          <Form
            noValidate
            validated={inputs.validated}
            onSubmit={onSubmitSimple}>
            <Row className="justify-content-center text-center">
              <Col xl="10">
                <a href={url} className='pointerEvents-action'>
                  <h1
                    className="title-form title-login-desktop"
                    dangerouslySetInnerHTML={{
                      __html: context.t('We are <b>Banexcoin</b>')
                    }}
                  />
                  <img
                    src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
                    alt="logo"
                    width={150}
                    className='logo-mobile coink'
                    style={{margin:"auto"}}
                  />
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center text-center mt-0">
              <Col xl="10">
                <h3
                  className="title-form-normal"
                  dangerouslySetInnerHTML={{
                    __html: context.t('Sign in')
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center inputscontainer">
              <Col xl="10">
                <InputElement {...EmailInputProps} />
              </Col>
              <Col xl="10">
                <InputElement {...PasswordInputProps} />
              </Col>
            </Row>
            {(!empty(generalErrors) && !empty(generalErrors.main) && empty(errors)) && (
              <Row className="justify-content-center mt-1 mb-4 error-msg">
                <p>{context.t(generalErrors.main)}.</p>
              </Row>
            )}
            <Row className="justify-content-center mt-2">
              <Col xl="10">
                <FormButtonV2
                  type="submit"
                  variant={empty(errors) ? 'dark' : 'light'}
                  disabled={loading}
                  className="submit-button register-form general-btn">
                  {loading ? <Spinner/> : context.t('Login')}
                </FormButtonV2>
              </Col>
            </Row>
            <Row className={`justify-content-center text-center mt-4 problems-1`}>
              <Col xl="0" className="bottom-box-v2 problems-text">
                <span>{context.t('Are you new?')} </span>
                <Link to="/signup/">
                  {context.t('Sign up')}
                </Link>
              </Col>
              <Col xl="0" className="bottom-box-v2 problems-text login-problem">
                <Link to="/problem-logging-in">
                  {context.t('Trouble logging in?')}
                </Link>
              </Col>
            </Row>
          </Form>
          <Row className="mt-5 bottomlinks">
            <Col xl="6" className="bottom-box-v2 normal text-left">
              <a
                href="https://soporte.banexcoin.com/support/solutions/articles/60000599926"
                target="_blank">
                {context.t('Terms and Conditions')}
              </a>
            </Col>
            <Col xl="6" className="bottom-box-v2 normal text-right">
              <a
                href="https://soporte.banexcoin.com/support/tickets/new"
                target="_blank">
                {context.t('Contact us')}
              </a>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row className="justify-content-center text-center mt-0 mb-0">
            <Col xl="10">
              <h4
                className="subtitle-form two-fa-text-h4"
                dangerouslySetInnerHTML={{
                  __html: context.t(
                    'Verification code (2FA)'
                  )
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-0 mb-0">
            <Col xl="10">
              <VerificationInput
                removeDefaultStyles
                autoFocus={true}
                length={6}
                validChars="/^\d+$/"
                container={{
                  className: 'pt-4 pb-4'
                }}
                characters={{
                  className: 'characters'
                }}
                character={{
                  className: 'character',
                  classNameInactive: 'character--inactive',
                  classNameSelected: 'character--selected'
                }}
                inputField={{
                  onChange: onChange2FA
                }}
              />
            </Col>
          </Row>
          {!empty(generalErrors) && !empty(generalErrors.main) ? (
            <Row className="justify-content-center error-msg">
              <Col xl="10"><p>{context.t(generalErrors.main)}</p></Col>
            </Row>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </div>
  );
};

LoginForm.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    webAuthenticateUser: payload =>
      dispatch(callAPI('WebAuthenticateUser', payload)).then(res => {
        return res;
      }),
    authenticate2FA: payload =>
      dispatch(callAPI('Authenticate2FA', payload)).then(res => {
        return res;
      }),
    setonLogin: payload => {
      onLogin(dispatch, payload);
      ownProps.history.push('/dashboard?default=redirect');
    },
    redirect: path => {
      ownProps.history.push(path);
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(LoginForm)
);
