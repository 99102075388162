import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';
import resize from 'apex-web/lib/hocs/resize';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import 'apex-chart-react/lib/chart-style.css';
import 'apex-web/lib/styles/components/TradeComponent.css';
import './DepthChart.css';
import {numberWithCommas2} from '../../../../pages/utils';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


var tradeComponentClasses = getBEMClasses('trade-component');
var depthChartComponentClasses = getBEMClasses('depth-chart');

var mousePos = { x: 0, y: 0 };
var mouseOver = false;
var defaultDepthChartZoom = 5;

var DepthChartComponent = function (_React$Component) {
  _inherits(DepthChartComponent, _React$Component);

  function DepthChartComponent(props) {
    _classCallCheck(this, DepthChartComponent);

    var _this = _possibleConstructorReturn(this, (DepthChartComponent.__proto__ || Object.getPrototypeOf(DepthChartComponent)).call(this, props));

    _this.setListeners = function () {
      var chartDom = document.getElementById('depthchart');
      chartDom.addEventListener('mouseover', function () {
        mouseOver = true;
      });
      chartDom.addEventListener('mouseout', function () {
        mouseOver = false;
        _this.drawChart();
      });
      chartDom.addEventListener('mousemove', function (evt) {
        var rect = chartDom.getBoundingClientRect();
        mousePos = {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top
        };

        _this.drawChart();
      });
      chartDom.addEventListener('click', function () {
        if (_this.mouseHoverBuy) {
          _this.props.buyPriceClicked(_this.closestMousePositionInfo.price);
        } else {
          _this.props.sellPriceClicked(_this.closestMousePositionInfo.price);
        }
      });
    };

    _this.getSellTooltipRotation = function (tooltipSize, offsetCentralChartLine, priceAxisHeight) {
      var marginTop = _this.closestMousePositionInfo.y - tooltipSize.height;
      var marginLeft = _this.closestMousePositionInfo.x - tooltipSize.width;
      var marginBottom = _this.closestMousePositionInfo.y + tooltipSize.height;
      var centralChartLimit = _this.chartWidth / 2 + offsetCentralChartLine;
      var bottomChartLimit = _this.chartHeight - priceAxisHeight;

      var rightBottomRotation = marginLeft <= centralChartLimit && marginTop <= 0;
      var rightTopRotation = marginLeft <= centralChartLimit && marginBottom >= bottomChartLimit;
      var leftBottomRotation = marginLeft >= centralChartLimit && marginTop <= 0;

      if (rightBottomRotation) {
        return { x: 1, y: -1 };
      } else if (rightTopRotation) {
        return { x: 1, y: 1 };
      } else if (leftBottomRotation) {
        return { x: -1, y: -1 };
      } else {
        return { x: -1, y: 1 };
      }
    };

    _this.getBuyTooltipRotation = function (tooltipSize, offsetCentralChartLine, priceAxisHeight) {
      var marginTop = _this.closestMousePositionInfo.y - tooltipSize.height;
      var marginRight = _this.closestMousePositionInfo.x + tooltipSize.width;
      var marginBottom = _this.closestMousePositionInfo.y + tooltipSize.height;
      var centralChartLimit = _this.chartWidth / 2 - offsetCentralChartLine;
      var bottomChartLimit = _this.chartHeight - priceAxisHeight;

      var leftBottomRotation = marginRight >= centralChartLimit && marginTop <= 0;
      var leftTopRotation = marginRight >= centralChartLimit && marginBottom >= bottomChartLimit;
      var rightBottomRotation = marginRight <= centralChartLimit && marginTop <= 0;

      if (leftBottomRotation) {
        return { x: -1, y: -1 };
      } else if (leftTopRotation) {
        return { x: -1, y: 1 };
      } else if (rightBottomRotation) {
        return { x: 1, y: -1 };
      } else {
        return { x: 1, y: 1 };
      }
    };

    _this.renderTooltip = function (depthChartTextFont, depthChartTextColor, lastTradeUpColorTransparent, lastTradeDownColorTransparent) {
      var tooltipSize = {
        width: 100,
        height: 60,
        paddingLeft: 20,
        paddingTop: 10
      };
      var offsetCentralChartLine = 60;
      var priceAxisHeight = 10;

      var sellTooltipRotation = _this.getSellTooltipRotation(tooltipSize, offsetCentralChartLine, priceAxisHeight);
      var buyTooltipRotation = _this.getBuyTooltipRotation(tooltipSize, offsetCentralChartLine, priceAxisHeight);

      var getFieldPositionX = function getFieldPositionX() {
        var padding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var x = _this.closestMousePositionInfo.x;

        var tooltipRotation = _this.mouseHoverBuy ? buyTooltipRotation : sellTooltipRotation;
        var rightBottomRotation = tooltipRotation.x === 1 && tooltipRotation.y === -1;
        var rightTopRotation = tooltipRotation.x === 1 && tooltipRotation.y === 1;

        if (rightBottomRotation || rightTopRotation) {
          return x + tooltipSize.paddingLeft + padding;
        } else {
          return x - tooltipSize.width + tooltipSize.paddingLeft + padding;
        }
      };

      var getFieldPositionY = function getFieldPositionY() {
        var padding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var y = _this.closestMousePositionInfo.y;

        var tooltipRotation = _this.mouseHoverBuy ? buyTooltipRotation : sellTooltipRotation;
        var rightBottomRotation = tooltipRotation.x === 1 && tooltipRotation.y === -1;
        var leftBottomRotation = tooltipRotation.x === -1 && tooltipRotation.y === -1;

        if (rightBottomRotation || leftBottomRotation) {
          return y + tooltipSize.paddingTop + padding;
        } else {
          return y - tooltipSize.height + tooltipSize.paddingTop + padding;
        }
      };

      if (mouseOver && (_this.mouseHoverBuy || _this.mouseHoverSell)) {
        _this.ctx2.beginPath();
        _this.ctx2.arc(_this.closestMousePositionInfo.x, _this.closestMousePositionInfo.y, 3, 0, 2 * Math.PI, false);
        _this.ctx2.fillStyle = 'rgba(205,205,205, 0.3)';
        _this.ctx2.fill();
        _this.ctx2.lineWidth = 1;
        _this.ctx2.strokeStyle = 'rgba(205,205,205, 0.3)';
        _this.ctx2.stroke();

        if (_this.mouseHoverBuy) {
          _this.ctx2.font = depthChartTextFont;
          _this.ctx2.fillStyle = depthChartTextColor;
          _this.ctx2.fillText(_this.closestMousePositionInfo.price.toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(), getFieldPositionY(10));
          _this.ctx2.fillText('Buy', getFieldPositionX(), getFieldPositionY(25));
          _this.ctx2.fillText(_this.closestMousePositionInfo.volume.toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(30), getFieldPositionY(25));
          _this.ctx2.fillText('Cost', getFieldPositionX(), getFieldPositionY(40));
          _this.ctx2.fillText((_this.closestMousePositionInfo.volume * _this.closestMousePositionInfo.price).toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(30), getFieldPositionY(40));
          _this.ctx2.fillStyle = lastTradeUpColorTransparent;
          _this.ctx2.fillRect(_this.closestMousePositionInfo.x, _this.closestMousePositionInfo.y - tooltipSize.height * buyTooltipRotation.y, tooltipSize.width * buyTooltipRotation.x, tooltipSize.height * buyTooltipRotation.y);
        }

        if (_this.mouseHoverSell) {
          _this.ctx2.font = depthChartTextFont;
          _this.ctx2.fillStyle = depthChartTextColor;
          _this.ctx2.fillText(_this.closestMousePositionInfo.price.toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(), getFieldPositionY(10));
          _this.ctx2.fillText('Buy', getFieldPositionX(), getFieldPositionY(25));
          _this.ctx2.fillText(_this.closestMousePositionInfo.volume.toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(30), getFieldPositionY(25));
          _this.ctx2.fillText('Cost', getFieldPositionX(), getFieldPositionY(40));
          _this.ctx2.fillText((_this.closestMousePositionInfo.volume * _this.closestMousePositionInfo.price).toFixed(convertIncrementToIntDecimalPlaces(_this.props.selectedInstrument.PriceIncrement)), getFieldPositionX(30), getFieldPositionY(40));
          _this.ctx2.fillStyle = lastTradeDownColorTransparent;
          _this.ctx2.fillRect(_this.closestMousePositionInfo.x, _this.closestMousePositionInfo.y - tooltipSize.height * sellTooltipRotation.y, tooltipSize.width * sellTooltipRotation.x, tooltipSize.height * sellTooltipRotation.y);
        }
      }
    };

    _this.zoomDpethChartPlus = function () {
      var newDepthChartZoom = _this.state.depthchartzoom / 1.25;
      if (newDepthChartZoom < 0.0001) {
        _this.setState({
          depthchartzoom: 0.0001
        });
      } else {
        _this.setState({
          depthchartzoom: newDepthChartZoom
        });
      }

      _this.drawChart();
    };

    _this.zoomDpethChartMinus = function () {
      var newDepthChartZoom = _this.state.depthchartzoom * 1.25;
      if (newDepthChartZoom > 1000) {
        _this.setState({
          depthchartzoom: 1000
        });
      } else {
        _this.setState({
          depthchartzoom: newDepthChartZoom
        });
      }

      _this.drawChart();
    };

    _this.state = {
      depthchartzoom: _this.props.DepthChart.depthchartzoom || defaultDepthChartZoom
    };
    return _this;
  }

  _createClass(DepthChartComponent, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.cssVariable = getCSSVar();
      this.setListeners();
      this.drawChart();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.selectedInstrument.InstrumentId !== this.props.selectedInstrument.InstrumentId) {
        this.setState({
          depthchartzoom: this.props.DepthChart.depthchartzoom || defaultDepthChartZoom
        });
      }

      this.drawChart();
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {}
  }, {
    key: 'drawChart',
    value: function drawChart() {
      var _props = this.props,
          DepthChart = _props.DepthChart,
          smallScreen = _props.smallScreen,
          level2 = _props.level2;


      var depthChartHeight = DepthChart.depthChartHeight + 50 || 300;
      var depthChartTextColor = DepthChart.depthChartTextColor || 'rgb(200,200,200)'; // Grey
      var depthChartTextFont = DepthChart.depthChartTextFont || '12px';
      var depthChartXAxisTextPadding = DepthChart.depthChartXAxisTextPadding || 60;
      var depthChartYAxisTextPadding = DepthChart.depthChartYAxisTextPadding || 20;
      var depthChartBottomPadding = DepthChart.depthChartBottomPadding || 20;
      var depthChartXAxisStepCnt = DepthChart.depthChartXAxisStepCnt || 9;
      var depthChartXAxisStepColor = this.cssVariable('--chart__x-axis-step-color');
      var lastTradeDownColor = this.cssVariable('--chart__bearish-color');

      var lastTradeDownColorTransparent = this.cssVariable('--chart__bearish-color_transparent');

      var lastTradeUpColor = this.cssVariable('--chart__bullish-color');
      var lastTradeUpColorTransparent = this.cssVariable('--chart__bullish-color_transparent');

      var buyBook = level2.buy;
      var sellBook = level2.sell;

      var c2 = document.getElementById('depthchart');
      var ch = document.getElementById('depthChartHolder');
      c2.width = ch.clientWidth;
      c2.height = smallScreen ? ch.clientHeight : depthChartHeight;
      this.chartHeight = c2.height;
      this.chartWidth = c2.width;
      var maxBidPrice = 0;
      var minAskPrice = 999999;
      let defaultValue = true

      var sortedSellBook = sellBook.map(function (order) {
        return order.Price;
      });
      sortedSellBook.forEach(function (price) {
        if (price < minAskPrice){
          minAskPrice = price;
          defaultValue = false
        }
      });

      var sortedBuyBook = buyBook.map(function (order) {
        return order.Price;
      });
      sortedBuyBook.forEach(function (price) {
        if (price > maxBidPrice) maxBidPrice = price;
      });

      const center = defaultValue ? 0 : (minAskPrice + maxBidPrice) / 2;
      
      this.relativeDepthChartZoom = Math.abs((minAskPrice - maxBidPrice) * this.state.depthchartzoom);
      
      const midMarketPrice = document.getElementById('midMarketPrice');
      const decimalPlaces = convertIncrementToIntDecimalPlaces(this.props.selectedInstrument.PriceIncrement)
      midMarketPrice.innerHTML = numberWithCommas2(center, decimalPlaces);
      var maxAskDisplay = center + this.relativeDepthChartZoom;
      var minBidDisplay = center - this.relativeDepthChartZoom;

      var visibleAskDepth = 0;
      var sellLevels = 0;
      for (var i = sortedSellBook.length - 1; i >= 0; --i) {
        if (sellBook[i].Price > maxAskDisplay) break;
        visibleAskDepth += sellBook[i].Quantity;
        ++sellLevels;
      }
      var visibleBidDepth = 0;
      var buyLevels = 0;
      for (var _i = 0; _i < sortedBuyBook.length; _i++) {
        if (buyBook[_i].Price < minBidDisplay) break;
        visibleBidDepth += buyBook[_i].Quantity;
        ++buyLevels;
      }

      var topvisibleDepth = visibleAskDepth;
      if (visibleBidDepth > visibleAskDepth) topvisibleDepth = visibleBidDepth;

      var multX = this.chartWidth / (this.relativeDepthChartZoom * 2); // pixels per unit of price
      var multY = (this.chartHeight - depthChartBottomPadding) / topvisibleDepth; // pixels per unit of depth

      this.ctx2 = c2.getContext('2d');
      this.ctx2.clearRect(0, 0, this.chartWidth, this.chartHeight);
      var lastY = this.chartHeight - depthChartBottomPadding;
      var lastX = this.chartWidth / 2;

      this.ctx2.beginPath();
      this.ctx2.moveTo(lastX, 50);
      this.ctx2.save();
      this.ctx2.lineWidth = 1;
      this.ctx2.lineTo(lastX, this.chartHeight - depthChartBottomPadding);
      this.ctx2.strokeStyle = depthChartTextColor;
      this.ctx2.stroke();

      this.mouseHoverBuy = false;
      this.mouseHoverSell = false;
      if (mousePos.x < lastX) {
        this.mouseHoverBuy = true;
      } else if (mousePos.x > lastX) {
        this.mouseHoverSell = true;
      }

      var lastTextEntry = lastX;
      var lastpx = center;
      lastX = this.chartWidth / 2 - (center - parseFloat(sortedBuyBook[0])) * multX;

      var lastXDrawn = 0;
      var lastYDrawn = 0;

      var lastTextEntryY = lastY;
      var lastTextEntryX = lastX;
      var depthShown = 0;

      this.closestMousePositionInfo = {
        price: 0,
        volume: 0,
        x: 0,
        y: 0,
        xdiff: 999999
      };
      this.closestMousePositionInfo.mouseX = mousePos.x;

      for (var _i2 = 0; _i2 < depthChartXAxisStepCnt; _i2++) {
        var xAxisText = (minBidDisplay + this.relativeDepthChartZoom * 2 / (depthChartXAxisStepCnt - 1) * _i2).toFixed(convertIncrementToIntDecimalPlaces(this.props.selectedInstrument.PriceIncrement));
        var xAxisStepI = (this.chartWidth - 35) / (depthChartXAxisStepCnt - 1) * _i2;
        this.ctx2.font = depthChartTextFont;
        this.ctx2.fillStyle = depthChartXAxisStepColor;
        this.ctx2.fillText(xAxisText, xAxisStepI, this.chartHeight - 10);
      }

      for (var _i3 = 0; _i3 < buyLevels; _i3++) {
        var lvl = buyBook[_i3];
        depthShown += lvl.Quantity;
        var x = parseInt(lastX - (lastpx - lvl.Price) * multX, 10);
        var y = this.chartHeight - parseInt(depthShown * multY, 10) - depthChartBottomPadding;

        if (this.mouseHoverBuy) {
          if (this.closestMousePositionInfo.xdiff > Math.abs(lastX - mousePos.x)) {
            this.closestMousePositionInfo.price = lvl.Price;
            this.closestMousePositionInfo.xdiff = Math.abs(lastX - mousePos.x);
            this.closestMousePositionInfo.volume = depthShown;
            this.closestMousePositionInfo.x = lastX;
            this.closestMousePositionInfo.y = y;
          }
        }

        lastpx = lvl.Price;

        if (lastTextEntryX - x > depthChartXAxisTextPadding && x - 40 > 0) {
          lastTextEntryX = x;
        }

        if (x <= this.chartWidth && y <= this.chartHeight && x >= 0) {
          this.ctx2.beginPath();
          this.ctx2.moveTo(lastX, y);
          this.ctx2.save();
          this.ctx2.lineWidth = 1;
          this.ctx2.lineTo(x, y);
          this.ctx2.strokeStyle = lastTradeUpColor;
          this.ctx2.stroke();

          this.ctx2.beginPath();
          this.ctx2.moveTo(lastX, lastY);
          this.ctx2.lineTo(lastX, y);
          this.ctx2.stroke();
          this.ctx2.restore();
          this.ctx2.fillStyle = lastTradeUpColorTransparent;

          this.ctx2.fillRect(lastX, y, x - lastX, this.chartHeight - depthChartBottomPadding - y);

          if (lastTextEntryY - y >= depthChartYAxisTextPadding && y - 10 > 0) {
            lastTextEntryY = y;
            this.ctx2.save();
            this.ctx2.beginPath();
            this.ctx2.moveTo(x, y);
            this.ctx2.lineW = 1;
            this.ctx2.lineTo(0, y);
            this.ctx2.strokeStyle = lastTradeUpColor;
            this.ctx2.setLineDash([2, 3]);
            this.ctx2.stroke();
            this.ctx2.restore();
          }

          if (x - lastTextEntry >= depthChartXAxisTextPadding) {
            this.ctx2.save();
            this.ctx2.beginPath();
            this.ctx2.moveTo(x, this.chartHeight - depthChartBottomPadding);
            this.ctx2.lineW = 1;
            this.ctx2.lineTo(x, this.chartHeight - 10);
            this.ctx2.strokeStyle = lastTradeUpColor;
            this.ctx2.stroke();
            this.ctx2.restore();
            lastTextEntry = x;
          }

          lastXDrawn = x;
          lastYDrawn = y;
        }

        lastX = x;
        lastY = y;
      }

      this.ctx2.fillStyle = lastTradeUpColorTransparent;
      this.ctx2.fillRect(0, lastYDrawn, lastXDrawn, this.chartHeight - depthChartBottomPadding - lastYDrawn);
      this.ctx2.beginPath();
      this.ctx2.moveTo(lastXDrawn, lastYDrawn);
      this.ctx2.save();
      this.ctx2.lineWidth = 1;
      this.ctx2.lineTo(0, lastYDrawn);
      this.ctx2.strokeStyle = lastTradeUpColor;
      this.ctx2.stroke();
      this.ctx2.restore();

      lastY = this.chartHeight - depthChartBottomPadding;
      lastX = this.chartWidth / 2;
      lastTextEntry = lastX;
      lastpx = center;
      lastTextEntryY = lastY;
      lastX = this.chartWidth / 2 + (parseFloat(sortedSellBook[sortedSellBook.length - 1]) - center) * multX;
      lastTextEntryX = 0;
      var shownum = sortedSellBook.length - sellLevels - 1;
      if (shownum < 0) shownum = 0;
      var depthShownA = 0;
      for (var _i4 = sortedSellBook.length - 1; _i4 >= shownum; _i4--) {
        var _lvl = sellBook[_i4];
        depthShownA += _lvl.Quantity;
        var _x3 = parseInt(lastX + (_lvl.Price - lastpx) * multX, 10);
        var _y = this.chartHeight - parseInt(depthShownA * multY, 10) - depthChartBottomPadding;
        lastpx = _lvl.Price;

        if (this.mouseHoverSell) {
          if (this.closestMousePositionInfo.xdiff > Math.abs(lastX - mousePos.x)) {
            this.closestMousePositionInfo.xdiff = Math.abs(lastX - mousePos.x);
            this.closestMousePositionInfo.price = _lvl.Price;
            this.closestMousePositionInfo.volume = depthShownA;
            this.closestMousePositionInfo.x = lastX;
            this.closestMousePositionInfo.y = _y;
          }
        }

        if (_x3 - lastTextEntryX > depthChartXAxisTextPadding && _x3 + 40 < this.chartWidth) {
          lastTextEntryX = _x3;
        }

        if (_x3 <= this.chartWidth && _y <= this.chartHeight && _x3 >= 0) {
          this.ctx2.save();
          this.ctx2.beginPath();
          this.ctx2.moveTo(lastX, _y);
          this.ctx2.lineWidth = 1;
          this.ctx2.lineTo(_x3, _y);
          this.ctx2.strokeStyle = lastTradeDownColor;
          this.ctx2.stroke();

          this.ctx2.beginPath();
          this.ctx2.moveTo(lastX, lastY);
          this.ctx2.lineTo(lastX, _y);
          this.ctx2.stroke();
          this.ctx2.restore();
          this.ctx2.fillStyle = lastTradeDownColorTransparent;

          this.ctx2.fillRect(lastX, _y, _x3 - lastX, this.chartHeight - depthChartBottomPadding - _y);

          if (lastTextEntryY - _y >= depthChartYAxisTextPadding && _y - 10 > 0) {
            lastTextEntryY = _y;
            this.ctx2.save();
            this.ctx2.beginPath();
            this.ctx2.moveTo(this.chartWidth, _y);
            this.ctx2.lineW = 1;
            this.ctx2.lineTo(_x3, _y);
            this.ctx2.strokeStyle = lastTradeDownColor;
            this.ctx2.setLineDash([2, 3]);
            this.ctx2.stroke();
            this.ctx2.restore();
          }
          if (lastTextEntry - _x3 >= depthChartXAxisTextPadding) {
            this.ctx2.font = depthChartTextFont;
            this.ctx2.fillStyle = depthChartTextColor;
            this.ctx2.fillText(_lvl.Price.toFixed(convertIncrementToIntDecimalPlaces(this.props.selectedInstrument.PriceIncrement)), _x3, this.chartHeight);

            this.ctx2.save();
            this.ctx2.beginPath();
            this.ctx2.moveTo(_x3, this.chartHeight - depthChartBottomPadding);
            this.ctx2.lineW = 1;
            this.ctx2.lineTo(_x3, this.chartHeight - 10);
            this.ctx2.strokeStyle = lastTradeDownColor;
            this.ctx2.stroke();
            this.ctx2.restore();

            lastTextEntry = _x3;
          }
          lastXDrawn = _x3;
          lastYDrawn = _y;
        }
        lastX = _x3;
        lastY = _y;
      }

      this.ctx2.fillStyle = lastTradeDownColorTransparent;
      this.ctx2.fillRect(lastXDrawn, lastYDrawn, this.chartWidth, this.chartHeight - depthChartBottomPadding - lastYDrawn);
      this.ctx2.beginPath();
      this.ctx2.moveTo(lastXDrawn, lastYDrawn);
      this.ctx2.save();
      this.ctx2.lineWidth = 1;
      this.ctx2.lineTo(this.chartWidth, lastYDrawn);
      this.ctx2.strokeStyle = lastTradeDownColor;
      this.ctx2.stroke();
      this.ctx2.restore();

      this.renderTooltip(depthChartTextFont, depthChartTextColor, lastTradeUpColorTransparent, lastTradeDownColorTransparent);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          DepthChart = _props2.DepthChart,
          smallScreen = _props2.smallScreen,
          fetching = _props2.fetching;


      var chartContainerHeight = DepthChart.depthChartHeight ? DepthChart.depthChartHeight + 50 : 350;

      if (smallScreen) {
        chartContainerHeight = '100%';
      }

      return React.createElement(
        'div',
        {
          style: {
            minHeight: chartContainerHeight,
            height: chartContainerHeight
          },
          className: tradeComponentClasses() + ' ' + depthChartComponentClasses() },
        fetching && React.createElement(Spinner, null),
        React.createElement(
          'div',
          {
            id: 'depthChartHolder',
            className: '' + depthChartComponentClasses('') },
          React.createElement('canvas', {
            id: 'depthchart',
            style: {
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: chartContainerHeight
            }
          }),
          React.createElement(
            'div',
            { className: '' + depthChartComponentClasses('buttons') },
            React.createElement(
              'button',
              {
                className: '' + depthChartComponentClasses('button--zoom'),
                onClick: function onClick() {
                  return _this2.zoomDpethChartPlus();
                } },
              '+'
            ),
            React.createElement(
              'span',
              { id: 'midMarketPrice' },
              '0'
            ),
            React.createElement(
              'button',
              {
                className: '' + depthChartComponentClasses('button--zoom'),
                onClick: function onClick() {
                  return _this2.zoomDpethChartMinus();
                } },
              '-'
            )
          ),
          React.createElement(
            'div',
            { className: '' + depthChartComponentClasses('zoom-label') },
            this.context.t('Zoom: ' + this.state.depthchartzoom.toFixed(2))
          ),
          React.createElement(
            'div',
            { className: '' + depthChartComponentClasses('label') },
            this.context.t('Mid Market Price')
          )
        )
      );
    }
  }]);

  return DepthChartComponent;
}(React.Component);

DepthChartComponent.defaultProps = {
  smallScreen: false
};

DepthChartComponent.propTypes = {
  store: PropTypes.object.isRequired,
  level2: PropTypes.object.isRequired
};

DepthChartComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default resize(DepthChartComponent);