import React, { useState, useEffect } from 'react';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import BoxContent from '../Components/BoxContent';
import SignupNaturalForm from '../Components/SignupNaturalForm';
import SignupCorporateForm from '../Components/SignupCorporateForm';
import SignUpNatural from './../../../images/signup-natural.png';
import LoadingBnx from './../../../components/LoadingBnx';

const SignupByTypePage = (props, context) => {
  let { match } = props;
  if (
    !(
      match.hasOwnProperty('params') &&
      !empty(match.params) &&
      match.params.hasOwnProperty('type') &&
      !empty(match.params.type)
    )
  ) {
    return <React.Fragment />;
  }
  const [enable, setEnable] = useState(true);
  const [loading, setLoading] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const seo = {
    title: 'Regístrate | Banexcoin',
    description:
      'Regístrate en Banexcoin, invierte y gana criptomonedas desde la comodidad de tu casa.',
    url: 'https://www.banexcoin.com/signup/',
    image:
      'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
  };
  let formsType = {
    natural: {
      image: <img src={SignUpNatural} className="portal-img no-tablet" />,
      form: <SignupNaturalForm setEnable={setEnable} setLoading={setLoading} setLoadingMessage={setLoadingMessage} />
    },
    corporate: {
      image: <img src={SignUpNatural} className="portal-img no-tablet" />,
      form: (
        <SignupCorporateForm setEnable={setEnable} setLoading={setLoading} />
      )
    }
  };
  let type = match.params.type;
  let currentType = formsType[type];
  if (empty(currentType)) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description }
        ]}>
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <LoadingBnx loading={loading} loadingMessage={loadingMessage} />
      <BoxContent
        leftChildren={enable ? currentType.image : ''}
        leftClassName={'no-tablet'}
        rightChildren={currentType.form}
        monoSize="5"
        containerClassname="mt-header"
      />
    </React.Fragment>
  );
};

SignupByTypePage.contextTypes = {
  t: PropTypes.func
};

export default SignupByTypePage;
