import React from 'react';
import KYCLevelComponent from '../KYC/KYCLevel';
import {
  getIMLevel1VerificationProps,
  getIMLevel2VerificationProps,
  getIMLevelVerificationProps
} from 'apex-web/lib/components/KYC/kycLevelsVerificationPropsHelper';

var KYC_IMLevelsComponent = function KYC_IMLevelsComponent(props) {
  var highlightStyle = props.highlightStyle,
    levels = props.levels,
    levelIncreaseStatus = props.levelIncreaseStatus,
    openKYCSidePane = props.openKYCSidePane,
    verificationLevel = props.verificationLevel;

  return levels.map(function(level) {
    if (level.level === 1) {
      var levelProps = getIMLevel1VerificationProps(
        verificationLevel,
        levelIncreaseStatus
      );

      return React.createElement(
        KYCLevelComponent,
        Object.assign(
          {
            key: level.level,
            verified: true,
            config: level
          },
          levelProps,
          {
            highlightStyle: highlightStyle
          }
        )
      );
    } else {
      var _levelProps =
        level.level === 2
          ? getIMLevel2VerificationProps(verificationLevel, levelIncreaseStatus)
          : getIMLevelVerificationProps(
              level.level,
              verificationLevel,
              levelIncreaseStatus
            );

      return React.createElement(
        KYCLevelComponent,
        Object.assign(
          {
            key: level.level,
            config: level
          },
          _levelProps,
          {
            openPane: openKYCSidePane,
            highlightStyle: highlightStyle
          }
        )
      );
    }
  });
};

export default KYC_IMLevelsComponent;
