import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import './SendReceiveHeader.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var bemClasses = getBEMClasses('send-receive-header');

var SendReceiveHeader = (function(_React$Component) {
  _inherits(SendReceiveHeader, _React$Component);

  function SendReceiveHeader() {
    _classCallCheck(this, SendReceiveHeader);

    return _possibleConstructorReturn(
      this,
      (
        SendReceiveHeader.__proto__ || Object.getPrototypeOf(SendReceiveHeader)
      ).apply(this, arguments)
    );
  }

  _createClass(SendReceiveHeader, [
    {
      key: 'render',
      value: function render() {
        var _props$product = this.props.product,
          iconFileName = _props$product.iconFileName,
          ProductFullName = _props$product.ProductFullName,
          ProductSymbol = _props$product.ProductSymbol;

        return React.createElement(
          'header',
          { className: bemClasses() },
          React.createElement(ProductIconContainer, {
            iconFileName: iconFileName,
            customClass: bemClasses('logo')
          }),
          React.createElement(
            'div',
            { className: bemClasses('title') },
            ProductSymbol,
            ' ',
            ProductFullName
          )
        );
      }
    }
  ]);

  return SendReceiveHeader;
})(React.Component);

SendReceiveHeader.propTypes = {
  name: PropTypes.string
};

export default SendReceiveHeader;
