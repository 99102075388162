import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';

class DataDepositBank extends Component {
  
   copyText(id) {
    const copyText = document.getElementById(id);
    let textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);
      document.execCommand("Copy");
      textArea.remove();
  
    const parent = copyText.parentElement;
    if(parent) {
      let cloudElement = parent.getElementsByClassName("cloud-text");
      if(cloudElement.length > 0) {
        cloudElement[0].classList.add("active");
        setTimeout(function(){
          cloudElement[0].classList.remove("active");
        }, 3000);
      }
    }
  }

  loadBank(info) {
    if(!empty(info)){
      return (
        <div
          className={'card_banking' + (!empty(this.props.className) ? ` ${this.props.className}` : '')} id="card_banking_box">
            {info.map(({title, value},key) =>
            <React.Fragment key={key}>
                <div id={`bbk_${key}`}>
                  <b>{title}:</b>
                </div>
                <div id={`bbk_${key}_value`}>
                  <span id={`bbk_${key}_value_val`}>{value}</span>
                  <img src="/local/landing-page/images/copy.svg" class="copy-btn" onClick={()=>this.copyText(`bbk_${key}_value_val`)} />
                  <div class="cloud-text">
                    {this.context.t('Copied to the clipboard!')}
                  </div>
                </div>
            </React.Fragment>
            )}
          </div>
      );
    }
    return <div />;
  }

  render() {
    const { selectedAccountId, idBank, user, info } = this.props;

    return !empty(selectedAccountId) && !empty(idBank) ? (
      <React.Fragment>
        {this.loadBank(info)}
      </React.Fragment>
    ) : (
      <React.Fragment />
    );
  }
}
DataDepositBank.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { selectedAccountId, userInfo }, user }) => {
  return { selectedAccountId, userInfo, user };
};

export default connect(mapStateToProps)(DataDepositBank);
