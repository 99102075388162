//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevel = async userId => {
  let path = '/api/level';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getCorporateData = (userId, selectedAccountId) => {
  let path = `/api/level-corporate/${selectedAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const sendRequestCorporateData = (userId, selectedAccountId) => {
  let path = `/api/level-corporate/${selectedAccountId}/request-review`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate-level';
  let model = new FormData();

  /* nuevo */
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('next_level', '4');
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('corporate_name', data.corporate_name);
  model.append('corporate_ruc', data.corporate_ruc);
  model.append('corporate_purpose', data.corporate_purpose);
  model.append('economic_group', data.economic_group);
  model.append('economic_address', data.economic_address);
  model.append('phone_number', data.phone_number);
  model.append('contact_person', data.contact_person);
  model.append('company_country', data.company_country);
  model.append('constitution_act', data.constitution_act);
  model.append('incorporation_document', data.incorporation_document);
  model.append('billing_file', data.billing_file);
  model.append('financial_statements', data.financial_statements);
  model.append('affidavit_income_tax', data.affidavit_income_tax);
  model.append('support_origin_funds', data.support_origin_funds);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
