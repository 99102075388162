export const districts = {
  Chachapoyas: [
    { id: 'Asunción', name: 'Asunción' },
    { id: 'Balsas', name: 'Balsas' },
    { id: 'Chachapoyas', name: 'Chachapoyas' },
    { id: 'Cheto', name: 'Cheto' },
    { id: 'Chiliquin', name: 'Chiliquin' },
    { id: 'Chuquibamba', name: 'Chuquibamba' },
    { id: 'Granada', name: 'Granada' },
    { id: 'Huancas', name: 'Huancas' },
    { id: 'La Jalca', name: 'La Jalca' },
    { id: 'Leimebamba', name: 'Leimebamba' },
    { id: 'Magdalena', name: 'Magdalena' },
    { id: 'Mariscal Castilla', name: 'Mariscal Castilla' },
    { id: 'Molinopampa', name: 'Molinopampa' },
    { id: 'Montevideo', name: 'Montevideo' },
    { id: 'Olleros', name: 'Olleros' },
    { id: 'Quinjalca', name: 'Quinjalca' },
    { id: 'San Francisco de Daguas', name: 'San Francisco de Daguas' },
    { id: 'San Isidro de Maino', name: 'San Isidro de Maino' },
    { id: 'Soloco', name: 'Soloco' },
    { id: 'Sonche', name: 'Sonche' }
  ],
  Bagua: [
    { id: 'Bagua', name: 'Bagua' },
    { id: 'Aramango', name: 'Aramango' },
    { id: 'Copallin', name: 'Copallin' },
    { id: 'El Parco', name: 'El Parco' },
    { id: 'Imaza', name: 'Imaza' },
    { id: 'La Peca', name: 'La Peca' }
  ],
  Bongará: [
    { id: 'Jumbilla', name: 'Jumbilla' },
    { id: 'Chisquilla', name: 'Chisquilla' },
    { id: 'Churuja', name: 'Churuja' },
    { id: 'Corosha', name: 'Corosha' },
    { id: 'Cuispes', name: 'Cuispes' },
    { id: 'Florida', name: 'Florida' },
    { id: 'Jazan', name: 'Jazan' },
    { id: 'Recta', name: 'Recta' },
    { id: 'San Carlos', name: 'San Carlos' },
    { id: 'Shipasbamba', name: 'Shipasbamba' },
    { id: 'Valera', name: 'Valera' },
    { id: 'Yambrasbamba', name: 'Yambrasbamba' }
  ],
  Condorcanqui: [
    { id: 'Nieva', name: 'Nieva' },
    { id: 'El Cenepa', name: 'El Cenepa' },
    { id: 'Río Santiago', name: 'Río Santiago' }
  ],
  Luya: [
    { id: 'Lamud', name: 'Lamud' },
    { id: 'Camporredondo', name: 'Camporredondo' },
    { id: 'Cocabamba', name: 'Cocabamba' },
    { id: 'Colcamar', name: 'Colcamar' },
    { id: 'Inguilpata', name: 'Inguilpata' },
    { id: 'Longuita', name: 'Longuita' },
    { id: 'Lonya Chico', name: 'Lonya Chico' },
    { id: 'Luya', name: 'Luya' },
    { id: 'Luya Viejo', name: 'Luya Viejo' },
    { id: 'María', name: 'María' },
    { id: 'Ocalli', name: 'Ocalli' },
    { id: 'Ocumal', name: 'Ocumal' },
    { id: 'Pisuquia', name: 'Pisuquia' },
    { id: 'Providencia', name: 'Providencia' },
    { id: 'San Cristóbal', name: 'San Cristóbal' },
    { id: 'SanFranciscodeYeso', name: 'San Francisco de Yeso' },
    { id: 'SanJerónimo', name: 'San Jerónimo' },
    { id: 'San Juan de Lopecancha', name: 'San Juan de Lopecancha' },
    { id: 'SantaCatalina', name: 'Santa Catalina' },
    { id: 'SantoTomas', name: 'Santo Tomas' },
    { id: 'Tingo', name: 'Tingo' },
    { id: 'Trita', name: 'Trita' }
  ],
  'Rodríguez de Mendoza': [
    { id: 'San Nicolás', name: 'San Nicolás' },
    { id: 'Chirimoto', name: 'Chirimoto' },
    { id: 'Cochamal', name: 'Cochamal' },
    { id: 'Huambo', name: 'Huambo' },
    { id: 'Limabamba', name: 'Limabamba' },
    { id: 'Longar', name: 'Longar' },
    { id: 'Mariscal Benavides', name: 'Mariscal Benavides' },
    { id: 'Milpuc', name: 'Milpuc' },
    { id: 'Omia', name: 'Omia' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Totora', name: 'Totora' },
    { id: 'Vista Alegre', name: 'Vista Alegre' }
  ],
  Utcubamba: [
    { id: 'Bagua Grande', name: 'Bagua Grande' },
    { id: 'Cajaruro', name: 'Cajaruro' },
    { id: 'Cumba', name: 'Cumba' },
    { id: 'El Milagro', name: 'El Milagro' },
    { id: 'Jamalca', name: 'Jamalca' },
    { id: 'Lonya Grande', name: 'LevaLonya Grandento' },
    { id: 'Yamon', name: 'Yamon' }
  ],
  Aija: [
    { id: 'Aija', name: 'Aija' },
    { id: 'Coris', name: 'Coris' },
    { id: 'Huacllan', name: 'Huacllan' },
    { id: 'La Merced', name: 'La Merced' },
    { id: 'Succha', name: 'Succha' }
  ],
  'Antonio Raymondi': [
    { id: 'Aczo', name: 'Aczo' },
    { id: 'Chaccho', name: 'Chaccho' },
    { id: 'Chingas', name: 'Chingas' },
    { id: 'Llamellin', name: 'Llamellin' },
    { id: 'Mirgas', name: 'Mirgas' },
    { id: 'San Juan de Rontoy', name: 'San Juan de Rontoy' }
  ],
  Asunción: [
    { id: 'Acochaca', name: 'Acochaca' },
    { id: 'Chacas', name: 'Chacas' }
  ],
  Bolognesi: [
    { id: 'Aquia', name: 'Aquia' },
    { id: 'Antonio Raymondi', name: 'Antonio Raymondi' },
    { id: 'Abelardo Pardo Lezameta', name: 'Abelardo Pardo Lezameta' },
    { id: 'Chiquian', name: 'Chiquian' },
    { id: 'Cajacay', name: 'Cajacay' },
    { id: 'Canis', name: 'Canis' },
    { id: 'Colquioc', name: 'Colquioc' },
    { id: 'Huallanca', name: 'Huallanca' },
    { id: 'Huasta', name: 'Huasta' },
    { id: 'Huayllacayan', name: 'Huayllacayan' },
    { id: 'La Primavera', name: 'La Primavera' },
    { id: 'Mangas', name: 'Mangas' },
    { id: 'Pacllon', name: 'Pacllon' },
    { id: 'San Miguel de Corpanqui', name: 'San Miguel de Corpanqui' },
    { id: 'Ticllos', name: 'Ticllos' }
  ],
  Carhuaz: [
    { id: 'Carhuaz', name: 'Carhuaz' },
    { id: 'Acopampa', name: 'Acopampa' },
    { id: 'Amashca', name: 'Amashca' },
    { id: 'Anta', name: 'Anta' },
    { id: 'Ataquero', name: 'Ataquero' },
    { id: 'Marcara', name: 'Marcara' },
    { id: 'Pariahuanca', name: 'Pariahuanca' },
    { id: 'San Miguel de Aco', name: 'San Miguel de Aco' },
    { id: 'Shilla', name: 'Shilla' },
    { id: 'Tinco', name: 'Tinco' },
    { id: 'Yungar', name: 'Yungar' }
  ],
  'Carlos Fermín Fitzcarrald': [
    { id: 'San Luis', name: 'San Luis' },
    { id: 'San Nicolás', name: 'San Nicolás' },
    { id: 'Yauya', name: 'Yauya' }
  ],
  Casma: [
    { id: 'Casma', name: 'Casma' },
    { id: 'Buena Vista Alta', name: 'Buena Vista Alta' },
    { id: 'Comandante Noel', name: 'Comandante Noel' },
    { id: 'Yautan', name: 'Yautan' }
  ],
  Corongo: [
    { id: 'Corongo', name: 'Corongo' },
    { id: 'Aco', name: 'Aco' },
    { id: 'Bambas', name: 'Bambas' },
    { id: 'Cusca', name: 'Cusca' },
    { id: 'La Pampa', name: 'La Pampa' },
    { id: 'Yanac', name: 'Yanac' },
    { id: 'Yupan', name: 'Yupan' }
  ],
  Huari: [
    { id: 'Huari', name: 'Huari' },
    { id: 'Anra', name: 'Anra' },
    { id: 'Cajay', name: 'Cajay' },
    { id: 'Chavin de Huantar', name: 'Chavin de Huantar' },
    { id: 'Huacachi', name: 'Huacachi' },
    { id: 'Huacchis', name: 'Huacchis' },
    { id: 'Huachis', name: 'Huachis' },
    { id: 'Huantar', name: 'Huantar' },
    { id: 'Masin', name: 'Masin' },
    { id: 'Paucas', name: 'Paucas' },
    { id: 'Ponto', name: 'Ponto' },
    { id: 'Rahuapampa', name: 'Rahuapampa' },
    { id: 'Rapayan', name: 'Rapayan' },
    { id: 'San Marcos', name: 'San Marcos' },
    { id: 'San Pedro de Chana', name: 'San Pedro de Chana' },
    { id: 'Uco', name: 'Uco' }
  ],
  Huaraz: [
    { id: 'Huaraz', name: 'Huaraz' },
    { id: 'Cochabamba', name: 'Cochabamba' },
    { id: 'Colcabamba', name: 'Colcabamba' },
    { id: 'Huanchay', name: 'Huanchay' },
    { id: 'Independencia', name: 'Independencia' },
    { id: 'Jangas', name: 'Jangas' },
    { id: 'La Libertad', name: 'La Libertad' },
    { id: 'Olleros', name: 'Olleros' },
    { id: 'Pampas Grande', name: 'Pampas Grande' },
    { id: 'Pariacoto', name: 'Pariacoto' },
    { id: 'Pira', name: 'Pira' },
    { id: 'Tarica', name: 'Tarica' }
  ],
  Huarmey: [
    { id: 'Huarmey', name: 'Huarmey' },
    { id: 'Cochapeti', name: 'Cochapeti' },
    { id: 'Culebras', name: 'Culebras' },
    { id: 'Huayan', name: 'Huayan' },
    { id: 'Malvas', name: 'Malvas' }
  ],
  Huaylas: [
    { id: 'Caraz', name: 'Caraz' },
    { id: 'Huallanca', name: 'Huallanca' },
    { id: 'Huata', name: 'Huata' },
    { id: 'Huaylas', name: 'Huaylas' },
    { id: 'Mato', name: 'Mato' },
    { id: 'Pamparomas', name: 'Pamparomas' },
    { id: 'Pueblo Libre', name: 'Pueblo Libre' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Santo Toribio', name: 'Santo Toribio' },
    { id: ' Yuracmarca', name: ' Yuracmarca' }
  ],
  'Mariscal Luzuriaga': [
    { id: 'Piscobamba', name: 'Piscobamba' },
    { id: 'Casca', name: 'Casca' },
    { id: 'Eleazar Guzmán Barron', name: 'Eleazar Guzmán Barron' },
    { id: 'Fidel Olivas Escudero', name: 'Fidel Olivas Escudero' },
    { id: 'Llama', name: 'Llama' },
    { id: 'Llumpa', name: 'Llumpa' },
    { id: 'Lucma', name: 'Lucma' },
    { id: 'Musga', name: 'Musga' }
  ],
  Ocros: [
    { id: 'Ocros', name: 'Ocros' },
    { id: 'Acas', name: 'Acas' },
    { id: 'Cajamarquilla', name: 'Cajamarquilla' },
    { id: 'Carhuapampa', name: 'Carhuapampa' },
    { id: 'Cochas', name: 'Cochas' },
    { id: 'Congas', name: 'Congas' },
    { id: 'Llipa', name: 'Llipa' },
    { id: 'San Cristóbal de Rajan', name: 'San Cristóbal de Rajan' },
    { id: 'San Pedro', name: 'San Pedro' },
    { id: 'Santiago de Chilcas', name: 'Santiago de Chilcas' }
  ],
  Pallasca: [
    { id: 'Cabana', name: 'Cabana' },
    { id: 'Bolognesi', name: 'Bolognesi' },
    { id: 'Conchucos', name: 'Conchucos' },
    { id: 'Huacaschuque', name: 'Huacaschuque' },
    { id: 'Huandoval', name: 'Huandoval' },
    { id: 'Lacabamba', name: 'Lacabamba' },
    { id: 'Llapo', name: 'Llapo' },
    { id: 'Pallasca', name: 'Pallasca' },
    { id: 'Pampas', name: 'Pampas' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Tauca', name: 'Tauca' }
  ],
  Pomabamba: [
    { id: 'Huayllan', name: 'Huayllan' },
    { id: 'Parobamba', name: 'Parobamba' },
    { id: 'Pomabamba', name: 'Pomabamba' },
    { id: 'Quinuabamba', name: 'Quinuabamba' }
  ],
  Recuay: [
    { id: 'Recuay', name: 'Recuay' },
    { id: 'Catac', name: 'Catac' },
    { id: 'Cotaparaco', name: 'Cotaparaco' },
    { id: 'Huayllapampa', name: 'Huayllapampa' },
    { id: 'Llacllin', name: 'Llacllin' },
    { id: 'Marca', name: 'Marca' },
    { id: 'Pampas Chico', name: 'Pampas Chico' },
    { id: 'Pararin', name: 'Pararin' },
    { id: 'Tapacocha', name: 'Tapacocha' },
    { id: 'Ticapampa', name: 'Ticapampa' }
  ],
  Santa: [
    { id: 'Chimbote', name: 'Chimbote' },
    { id: 'Cáceres del Perú', name: 'Cáceres del Perú' },
    { id: 'Coishco', name: 'Coishco' },
    { id: 'Macate', name: 'Macate' },
    { id: 'Moro', name: 'Moro' },
    { id: 'Nepeña', name: 'Nepeña' },
    { id: 'Samanco', name: 'Samanco' },
    { id: 'Santa', name: 'Santa' },
    { id: 'Nuevo Chimbote', name: 'Nuevo Chimbote' }
  ],
  Sihuas: [
    { id: 'Acobamba', name: 'Acobamba' },
    { id: 'Alfonso Ugarte', name: 'Alfonso Ugarte' },
    { id: 'Cashapampa', name: 'Cashapampa' },
    { id: 'Chingalpo', name: 'Chingalpo' },
    { id: 'Huayllabamba', name: 'Huayllabamba' },
    { id: 'Quiches', name: 'Quiches' },
    { id: 'Ragash', name: 'Ragash' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'Sicsibamba', name: 'Sicsibamba' },
    { id: 'Sihuas', name: 'Sihuas' }
  ],
  Yungay: [
    { id: 'Cascapara', name: 'Cascapara' },
    { id: 'Mancos', name: 'Mancos' },
    { id: 'Matacoto', name: 'Matacoto' },
    { id: 'Quillo', name: 'Quillo' },
    { id: 'Ranrahirca', name: 'Ranrahirca' },
    { id: 'Shupluy', name: 'Shupluy' },
    { id: 'Yanama', name: 'Yanama' },
    { id: 'Yungay', name: 'Yungay' }
  ],
  Abancay: [
    { id: 'Abancay', name: 'Abancay' },
    { id: 'Chacoche', name: 'Chacoche' },
    { id: 'Circa', name: 'Circa' },
    { id: 'Curahuasi', name: 'Curahuasi' },
    { id: 'Huanipaca', name: 'Huanipaca' },
    { id: 'Lambrama', name: 'Lambrama' },
    { id: 'Pichirhua', name: 'Pichirhua' },
    { id: 'San Pedro de Cachora', name: 'San Pedro de Cachora' },
    { id: 'Tamburco', name: 'Tamburco' }
  ],
  Andahuaylas: [
    { id: 'Andahuaylas', name: 'Andahuaylas' },
    { id: 'Andarapa', name: 'Andarapa' },
    { id: 'Chiara', name: 'Chiara' },
    { id: 'Huancarama', name: 'Huancarama' },
    { id: 'Huancaray', name: 'Huancaray' },
    { id: 'Huayana', name: 'Huayana' },
    { id: 'Kishuara', name: 'Kishuara' },
    { id: 'Pacobamba', name: 'Pacobamba' },
    { id: 'Pacucha', name: 'Pacucha' },
    { id: 'Pampachiri', name: 'Pampachiri' },
    { id: 'Pomacocha', name: 'Pomacocha' },
    { id: 'San Antonio de Cachi', name: 'San Antonio de Cachi' },
    { id: 'San Jerónimo', name: 'San Jerónimo' },
    { id: 'San Miguel de Chaccrampa', name: 'San Miguel de Chaccrampa' },
    { id: 'Santa María de Chicmo', name: 'Santa María de Chicmo' },
    { id: 'Talavera', name: 'Talavera' },
    { id: 'Tumay Huaraca', name: 'Tumay Huaraca' },
    { id: 'Turpo', name: 'Turpo' },
    { id: 'Kaquiabamba', name: 'Kaquiabamba' },
    { id: 'José María Arguedas', name: 'José María Arguedas' }
  ],
  Antabamba: [
    { id: 'Antabamba', name: 'Antabamba' },
    { id: 'El Oro', name: 'El Oro' },
    { id: 'Huaquirca', name: 'Huaquirca' },
    { id: 'Juan Espinoza Medrano', name: 'Juan Espinoza Medrano' },
    { id: 'Oropesa', name: 'Oropesa' },
    { id: 'Pachaconas', name: 'Pachaconas' },
    { id: 'Sabaino', name: 'Sabaino' }
  ],
  Aymaraes: [
    { id: 'Chalhuanca', name: 'Chalhuanca' },
    { id: 'Capaya', name: 'Capaya' },
    { id: 'Caraybamba', name: 'Caraybamba' },
    { id: 'Chapimarca', name: 'Chapimarca' },
    { id: 'Colcabamba', name: 'Colcabamba' },
    { id: 'Cotaruse', name: 'Cotaruse' },
    { id: 'Ihuayllo', name: 'Ihuayllo' },
    { id: 'Justo Apu Sahuaraura', name: 'Justo Apu Sahuaraura' },
    { id: 'Lucre', name: 'Lucre' },
    { id: 'Pocohuanca', name: 'Pocohuanca' },
    { id: 'San Juan de Chacña', name: 'San Juan de Chacña' },
    { id: 'Sañayca', name: 'Sañayca' },
    { id: 'Soraya', name: 'Soraya' },
    { id: 'Tapairihua', name: 'Tapairihua' },
    { id: 'Tintay', name: 'Tintay' },
    { id: 'Toraya', name: 'Toraya' },
    { id: 'Antabamba', name: 'Antabamba' },
    { id: 'Yanaca', name: 'Yanaca' }
  ],
  Cotabambas: [
    { id: 'Challhuahuacho', name: 'Challhuahuacho' },
    { id: 'Cotabambas', name: 'Cotabambas' },
    { id: 'Coyllurqui', name: 'Coyllurqui' },
    { id: 'Haquira', name: 'Haquira' },
    { id: 'Mara', name: 'Mara' },
    { id: 'Tambobamba', name: 'Tambobamba' }
  ],
  Chincheros: [
    { id: 'Chincheros', name: 'Chincheros' },
    { id: 'Anco Huallo', name: 'Anco Huallo' },
    { id: 'Cocharcas', name: 'Cocharcas' },
    { id: 'Huaccana', name: 'Huaccana' },
    { id: 'Ocobamba', name: 'Ocobamba' },
    { id: 'Ongoy', name: 'Ongoy' },
    { id: 'Uranmarca', name: 'Uranmarca' },
    { id: 'Ranracancha', name: 'Ranracancha' },
    { id: 'Rocchacc', name: 'Rocchacc' },
    { id: 'El Porvenir', name: 'El Porvenir' },
    { id: 'Los Chankas', name: 'Los Chankas' }
  ],
  Grau: [
    { id: 'Chuquibambilla', name: 'Chuquibambilla' },
    { id: 'Curpahuasi', name: 'Curpahuasi' },
    { id: 'Gamarra', name: 'Gamarra' },
    { id: 'Huayllati', name: 'Huayllati' },
    { id: 'Mamara', name: 'Mamara' },
    { id: 'Micaela Bastidas', name: 'Micaela Bastidas' },
    { id: 'Pataypampa', name: 'Pataypampa' },
    { id: 'Progreso', name: 'Progreso' },
    { id: 'San Antonio', name: 'San Antonio' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Turpay', name: 'Turpay' },
    { id: 'Vilcabamba', name: 'Vilcabamba' },
    { id: 'Virundo', name: 'Virundo' },
    { id: 'Curasco', name: 'Curasco' }
  ],
  Arequipa: [
    { id: 'Arequipa', name: 'Arequipa' },
    { id: 'Alto Selva Alegre', name: 'Alto Selva Alegre' },
    { id: 'Cayma', name: 'Cayma' },
    { id: 'Cerro Colorado', name: 'Cerro Colorado' },
    { id: 'Characato', name: 'Characato' },
    { id: 'Chiguata', name: 'Chiguata' },
    { id: 'Jacobo Hunter', name: 'Jacobo Hunter' },
    { id: 'La Joya', name: 'La Joya' },
    { id: 'Mariano Melgar', name: 'Mariano Melgar' },
    { id: 'Miraflores', name: 'Miraflores' },
    { id: 'Mollebaya', name: 'Mollebaya' },
    { id: 'Paucarpata', name: 'Paucarpata' },
    { id: ' Pocsi', name: ' Pocsi' },
    { id: 'Polobaya', name: 'Polobaya' },
    { id: 'Quequeña', name: 'Quequeña' },
    { id: 'Sabandia', name: 'Sabandia' },
    { id: 'Sachaca', name: 'Sachaca' },
    { id: 'San Juan de Siguas', name: 'San Juan de Siguas' },
    { id: 'San Juan de Tarucani', name: 'San Juan de Tarucani' },
    { id: 'Santa Isabel de Siguas', name: 'Santa Isabel de Siguas' },
    { id: 'Santa Rita de Siguas', name: 'Santa Rita de Siguas' },
    { id: 'Socabaya', name: 'Socabaya' },
    { id: 'Tiabaya', name: 'Tiabaya' },
    { id: 'Uchumayo', name: 'Uchumayo' },
    { id: 'Vitor', name: 'Vitor' },
    { id: 'Yanahuara', name: 'Yanahuara' },
    { id: 'Yarabamba', name: 'Yarabamba' },
    { id: 'Yura', name: 'Yura' },
    {
      id: 'José Luis Bustamante Y Rivero',
      name: 'José Luis Bustamante Y Rivero'
    }
  ],
  Camaná: [
    { id: 'Camaná', name: 'Camaná' },
    { id: 'José María Quimper', name: 'José María Quimper' },
    { id: 'Mariano Nicolás Valcárcel', name: 'Mariano Nicolás Valcárcel' },
    { id: 'Mariscal Cáceres', name: 'Mariscal Cáceres' },
    { id: 'Nicolás de Pierola', name: 'Nicolás de Pierola' },
    { id: 'Ocoña', name: 'Ocoña' },
    { id: 'Quilca', name: 'Quilca' },
    { id: 'Samuel Pastor', name: 'Samuel Pastor' }
  ],
  Caravelí: [
    { id: 'Caravelí', name: 'Caravelí' },
    { id: 'Acarí', name: 'Acarí' },
    { id: 'Atico', name: 'Atico' },
    { id: 'Atiquipa', name: 'Atiquipa' },
    { id: 'Bella Unión', name: 'Bella Unión' },
    { id: 'Cahuacho', name: 'Cahuacho' },
    { id: 'Chala', name: 'Chala' },
    { id: 'Huanuhuanu', name: 'Huanuhuanu' },
    { id: 'Jaqui', name: 'Jaqui' },
    { id: 'Lomas', name: 'Lomas' },
    { id: 'Quicacha', name: 'Quicacha' },
    { id: 'Yauca', name: 'Yauca' }
  ],
  Castilla: [
    { id: 'Aplao', name: 'Aplao' },
    { id: 'Andagua', name: 'Andagua' },
    { id: 'Ayo', name: 'Ayo' },
    { id: 'Chachas', name: 'Chachas' },
    { id: 'Chilcaymarca', name: 'Chilcaymarca' },
    { id: 'Choco', name: 'Choco' },
    { id: 'Huancarqui', name: 'Huancarqui' },
    { id: 'Machaguay', name: 'Machaguay' },
    { id: 'Orcopampa', name: 'Orcopampa' },
    { id: 'Pampacolca', name: 'Pampacolca' },
    { id: 'Tipan', name: 'Tipan' },
    { id: 'Uñon', name: 'Uñon' },
    { id: 'Uraca', name: 'Uraca' },
    { id: 'Viraco', name: 'Viraco' }
  ],
  Caylloma: [
    { id: 'Chivay', name: 'Chivay' },
    { id: 'Achoma', name: 'Achoma' },
    { id: 'Cabanaconde', name: 'Cabanaconde' },
    { id: 'Callalli', name: 'Callalli' },
    { id: 'Caylloma', name: 'Caylloma' },
    { id: 'Coporaque', name: 'Coporaque' },
    { id: 'Huambo', name: 'Huambo' },
    { id: 'Huanca', name: 'Huanca' },
    { id: 'Ichupampa', name: 'Ichupampa' },
    { id: 'Lari', name: 'Lari' },
    { id: 'Lluta', name: 'Lluta' },
    { id: 'Maca', name: 'Maca' },
    { id: 'Madrigal', name: 'Madrigal' },
    { id: 'Majes', name: 'Majes' },
    { id: 'San Antonio de Chuca', name: 'San Antonio de Chuca' },
    { id: 'Sibayo', name: 'Sibayo' },
    { id: 'Tapay', name: 'Tapay' },
    { id: 'Tisco', name: 'Tisco' },
    { id: 'Tuti', name: 'Tuti' },
    { id: 'Yanque', name: 'Yanque' }
  ],
  Condesuyos: [
    { id: 'Andaray', name: 'Andaray' },
    { id: 'Cayarani', name: 'Cayarani' },
    { id: 'Chichas', name: 'Chichas' },
    { id: 'Chuquibamba', name: 'Chuquibamba' },
    { id: 'Iray', name: 'Iray' },
    { id: 'Río Grande', name: 'Río Grande' },
    { id: 'Salamanca', name: 'Salamanca' },
    { id: 'Yanaquihua', name: 'Yanaquihua' }
  ],
  Islay: [
    { id: 'Cocachacra', name: 'Cocachacra' },
    { id: 'Dean Valdivia', name: 'Dean Valdivia' },
    { id: 'Islay', name: 'Islay' },
    { id: 'Mejia', name: 'Mejia' },
    { id: 'Mollendo', name: 'Mollendo' },
    { id: 'Punta de Bombón', name: 'Punta de Bombón' }
  ],
  'La Uniòn': [
    { id: 'Alca', name: 'Alca' },
    { id: 'Charcana', name: 'Charcana' },
    { id: 'Cotahuasi', name: 'Cotahuasi' },
    { id: 'Huaynacotas', name: 'Huaynacotas' },
    { id: 'Pampamarca', name: 'Pampamarca' },
    { id: 'Puyca', name: 'Puyca' },
    { id: 'Quechualla', name: 'Quechualla' },
    { id: 'Sayla', name: 'Sayla' },
    { id: 'Tauria', name: 'Tauria' },
    { id: 'Tomepampa', name: 'Tomepampa' },
    { id: 'Toro', name: 'Toro' }
  ],
  Cangallo: [
    { id: 'Cangallo', name: 'Cangallo' },
    { id: 'Chuschi', name: 'Chuschi' },
    { id: 'Los Morochucos', name: 'Los Morochucos' },
    { id: 'María Parado de Bellido', name: 'María Parado de Bellido' },
    { id: 'Paras', name: 'Paras' },
    { id: 'Totos', name: 'Totos' }
  ],
  Huamanga: [
    { id: 'Ayacucho', name: 'Ayacucho' },
    { id: 'Acocro', name: 'Acocro' },
    { id: 'Acos Vinchos', name: 'Acos Vinchos' },
    { id: 'Carmen Alto', name: 'Carmen Alto' },
    { id: 'Chiara', name: 'Chiara' },
    { id: 'Ocros', name: 'Ocros' },
    { id: 'Pacaycasa', name: 'Pacaycasa' },
    { id: 'Quinua', name: 'Quinua' },
    { id: 'San José de Ticllas', name: 'San José de Ticllas' },
    { id: 'San Juan Bautista', name: 'San Juan Bautista' },
    { id: 'Santiago de Pischa', name: 'Santiago de Pischa' },
    { id: 'Socos', name: 'Socos' },
    { id: 'Tambillo', name: 'Tambillo' },
    { id: 'Vinchos', name: 'Vinchos' },
    { id: 'Jesús Nazareno', name: 'Jesús Nazareno' },
    {
      id: 'Andrés Avelino Cáceres Dorregaray',
      name: 'Andrés Avelino Cáceres Dorregaray'
    }
  ],
  Sancos: [
    { id: 'Carapo', name: 'Carapo' },
    { id: 'Sacsamarca', name: 'Sacsamarca' },
    { id: 'Sancos', name: 'Sancos' },
    { id: 'Santiago de Lucanamarca', name: 'Santiago de Lucanamarca' }
  ],
  Huanta: [
    { id: 'Ayahuanco', name: 'Ayahuanco' },
    { id: 'Canayre', name: 'Canayre' },
    { id: 'Chaca', name: 'Chaca' },
    { id: 'Huanta', name: 'Huanta' },
    { id: 'Huamanguilla', name: 'Huamanguilla' },
    { id: 'Iguain', name: 'Iguain' },
    { id: 'Llochegua', name: 'Llochegua' },
    { id: 'Luricocha', name: 'Luricocha' },
    { id: 'Santillana', name: 'Santillana' },
    { id: 'Sivia', name: 'Sivia' },
    { id: 'Pucacolpa', name: 'Pucacolpa' },
    { id: 'Uchuraccay', name: 'Uchuraccay' }
  ],
  Mar: [
    { id: 'Anco', name: 'Anco' },
    { id: 'Anchihuay', name: 'Anchihuay' },
    { id: 'Ayna', name: 'Ayna' },
    { id: 'Chilcas', name: 'Chilcas' },
    { id: 'Chungui', name: 'Chungui' },
    { id: 'Luis Carranza', name: 'Luis Carranza' },
    { id: 'Oronccoy', name: 'Oronccoy' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'San Miguel', name: 'San Miguel' },
    { id: 'Samugari', name: 'Samugari' },
    { id: 'Tambo', name: 'Tambo' }
  ],
  Lucanas: [
    { id: 'Aucara', name: 'Aucara' },
    { id: 'Cabana', name: 'Cabana' },
    { id: 'Carmen Salcedo', name: 'Carmen Salcedo' },
    { id: 'Chaviña', name: 'Chaviña' },
    { id: 'Chipao', name: 'Chipao' },
    { id: 'Huac-Huas', name: 'Huac-Huas' },
    { id: 'Laramate', name: 'Laramate' },
    { id: 'Leoncio Prado', name: 'Leoncio Prado' },
    { id: 'Llauta', name: 'Llauta' },
    { id: 'Lucanas', name: 'Lucanas' },
    { id: 'Ocaña', name: 'Ocaña' },
    { id: 'Otoca', name: 'Otoca' },
    { id: 'Puquio', name: 'Puquio' },
    { id: 'Saisa', name: 'Saisa' },
    { id: 'San Cristóbal', name: 'San Cristóbal' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'San Pedro', name: 'San Pedro' },
    { id: 'San Pedro de Palco', name: 'San Pedro de Palco' },
    { id: 'Sancos', name: 'Sancos' },
    { id: 'Santa Ana de Huaycahuacho', name: 'Santa Ana de Huaycahuacho' },
    { id: 'Santa Lucia', name: 'Santa Lucia' }
  ],
  Parinacochas: [
    { id: 'Coracora', name: 'Coracora' },
    { id: 'Chumpi', name: 'Chumpi' },
    { id: 'Coronel Castañeda', name: 'Coronel Castañeda' },
    { id: 'Pacapausa ', name: 'Pacapausa ' },
    { id: 'Pullo', name: 'Pullo' },
    { id: 'Puyusca', name: 'Puyusca' },
    {
      id: 'San Francisco de Ravacayco',
      name: 'San Francisco de Ravacayco'
    },
    { id: 'Upahuacho', name: 'Upahuacho' }
  ],
  'Pàucar del Sara Sara': [
    { id: 'Colta', name: 'Colta' },
    { id: 'Corculla', name: 'Corculla' },
    { id: 'Lampa', name: 'Lampa' },
    { id: 'Marcabamba', name: 'Marcabamba' },
    { id: 'Oyolo', name: 'Oyolo' },
    { id: 'Pararca', name: 'Pararca' },
    { id: 'Pausa', name: 'Pausa' },
    { id: 'San Javier de Alpabamba', name: 'San Javier de Alpabamba' },
    { id: 'San José de Ushua', name: 'San José de Ushua' },
    { id: 'Sara Sara', name: 'Sara Sara' }
  ],
  Sucre: [
    { id: 'Belén', name: 'Belén' },
    { id: 'Chalcos', name: 'Chalcos' },
    { id: 'Chilcayoc', name: 'Chilcayoc' },
    { id: 'Huacaña', name: 'Huacaña' },
    { id: 'Morcolla', name: 'Morcolla' },
    { id: 'Paico', name: 'Paico' },
    { id: 'San Pedro de Larcay', name: 'San Pedro de Larcay' },
    { id: 'San Salvador de Quije', name: 'San Salvador de Quije' },
    { id: 'Santiago de Paucaray', name: 'Santiago de Paucaray' },
    { id: 'Soras', name: 'Soras' },
    { id: 'Querobamba', name: 'Querobamba' }
  ],
  'Víctor Fajardo': [
    { id: 'Alcamenca', name: 'Alcamenca' },
    { id: 'Apongo', name: 'Apongo' },
    { id: 'Asquipata', name: 'Asquipata' },
    { id: 'Canaria', name: 'Canaria' },
    { id: 'Cayara', name: 'Cayara' },
    { id: 'Colca', name: 'Colca' },
    { id: 'Huamanquiquia', name: 'Huamanquiquia' },
    { id: 'Huancapi', name: 'Huancapi' },
    { id: 'Huancaraylla', name: 'Huancaraylla' },
    { id: 'Hualla', name: 'Hualla' },
    { id: 'Sarhua', name: 'Sarhua' },
    { id: 'Vilcanchos', name: 'Vilcanchos' }
  ],
  'Vilcas Huamán': [
    { id: 'Accomarca', name: 'Accomarca' },
    { id: 'Carhuanca', name: 'Carhuanca' },
    { id: 'Concepción', name: 'Concepción' },
    { id: 'Huambalpa', name: 'Huambalpa' },
    { id: 'Independencia', name: 'Independencia' },
    { id: 'Saurama', name: 'Saurama' },
    { id: 'Vischongo', name: 'Vischongo' },
    { id: 'Vilcas Huaman', name: 'Vilcas Huaman' }
  ],
  Cajamarca: [
    { id: 'Asunción', name: 'Asunción' },
    { id: 'Cajamarca', name: 'Cajamarca' },
    { id: 'Chetilla', name: 'Chetilla' },
    { id: 'Cospan', name: 'Cospan' },
    { id: 'Encañada', name: 'Encañada' },
    { id: 'Jesús', name: 'Jesús' },
    { id: 'Llacanora', name: 'Llacanora' },
    { id: 'Los Baños del Inca', name: 'Los Baños del Inca' },
    { id: 'Magdalena', name: 'Magdalena' },
    { id: 'Matara', name: 'Matara' },
    { id: 'Namora', name: 'Namora' },
    { id: 'San Juan', name: 'San Juan' }
  ],
  Cajabamba: [
    { id: 'Cajabamba', name: 'Cajabamba' },
    { id: 'Cachachi', name: 'Cachachi' },
    { id: 'Condebamba', name: 'Condebamba' },
    { id: 'Sitacocha', name: 'Sitacocha' }
  ],
  Celendín: [
    { id: 'Celendín', name: 'Celendín' },
    { id: 'Chumuch', name: 'Chumuch' },
    { id: 'Cortegana', name: 'Cortegana' },
    { id: 'Huasmin', name: 'Huasmin' },
    { id: 'Jorge Chávez', name: 'Jorge Chávez' },
    { id: 'José Gálvez', name: 'José Gálvez' },
    { id: 'Miguel Iglesias', name: 'Miguel Iglesias' },
    { id: 'Oxamarca', name: 'Oxamarca' },
    { id: 'Sorochuco', name: 'Sorochuco' },
    { id: 'Sucre', name: 'Sucre' },
    { id: 'Utco', name: 'Utco' },
    { id: 'La Libertad de Pallan', name: 'La Libertad de Pallan' }
  ],
  Chota: [
    { id: 'Anguia', name: 'Anguia' },
    { id: 'Chadin', name: 'Chadin' },
    { id: 'Chiguirip', name: 'Chiguirip' },
    { id: 'Chota', name: 'Chota' },
    { id: 'Chimban', name: 'Chimban' },
    { id: 'Choropampa', name: 'Choropampa' },
    { id: 'Cochabamba', name: 'Cochabamba' },
    { id: 'Conchan', name: 'Conchan' },
    { id: 'Huambos', name: 'Huambos' },
    { id: 'Lajas', name: 'Lajas' },
    { id: 'Llama ', name: 'Llama ' },
    { id: 'Miracosta', name: 'Miracosta' },
    { id: 'Paccha', name: 'Paccha' },
    { id: 'Pion', name: 'Pion' },
    { id: 'Querocoto', name: 'Querocoto' },
    { id: 'San Juan de Licupis', name: 'San Juan de Licupis' },
    { id: 'Tacabamba', name: 'Tacabamba' },
    { id: 'Tocmoche', name: 'Tocmoche' },
    { id: 'Chalamarca', name: 'Chalamarca' }
  ],
  Contumazá: [
    { id: 'Contumaza', name: 'Contumaza' },
    { id: 'Chilete', name: 'Chilete' },
    { id: 'Cupisnique', name: 'Cupisnique' },
    { id: 'Guzmango', name: 'Guzmango' },
    { id: 'San Benito', name: 'San Benito' },
    { id: 'Santa Cruz de Toledo', name: 'Santa Cruz de Toledo' },
    { id: 'Tantarica', name: 'Tantarica' },
    { id: 'Yonan', name: 'Yonan' }
  ],
  Cutervo: [
    { id: 'Callayuc', name: 'Callayuc' },
    { id: 'Choros', name: 'Choros' },
    { id: 'Cutervo', name: 'ContCutervoumaza' },
    { id: 'Cujillo', name: 'Cujillo' },
    { id: 'La Ramada', name: 'La Ramada' },
    { id: 'Pimpingos', name: 'Pimpingos' },
    { id: 'Querocotillo', name: 'Querocotillo' },
    { id: 'San Andrés de Cutervo', name: 'San Andrés de Cutervo' },
    { id: 'San Juan de Cutervo', name: 'San Juan de Cutervo' },
    { id: 'San Luis de Lucma', name: 'San Luis de Lucma' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Santo Domingo de la Capilla', name: 'Santo Domingo de la Capilla' },
    { id: 'Santo Tomas', name: 'Santo Tomas' },
    { id: 'Socota', name: 'Socota' },
    { id: 'Toribio Casanova', name: 'Toribio Casanova' }
  ],
  Hualgayoc: [
    { id: 'Bambamarca', name: 'Bambamarca' },
    { id: 'Chugur', name: 'Chugur' },
    { id: 'Hualgayoc', name: 'Hualgayoc' }
  ],
  Jaén: [
    { id: 'Bellavista', name: 'Bellavista' },
    { id: 'Chontali', name: 'Chontali' },
    { id: 'Colasay', name: 'Colasay' },
    { id: 'Huabal', name: 'Huabal' },
    { id: 'Jaén', name: 'Jaén' },
    { id: 'Las Pirias', name: 'Las Pirias ' },
    { id: 'Pomahuaca', name: 'Pomahuaca' },
    { id: 'Pucara', name: 'Pucara' },
    { id: 'Sallique', name: 'Sallique' },
    { id: 'San Felipe', name: 'San Felipe' },
    { id: 'San José del Alto', name: 'San José del Alto' },
    { id: 'Santa Rosa', name: 'Santa Rosa' }
  ],
  'San Ignacio': [
    { id: 'San Ignacio', name: 'San Ignacio' },
    { id: 'Chirinos', name: 'Chirinos' },
    { id: 'Huarango', name: 'Huarango' },
    { id: 'La Coipa', name: 'La Coipa' },
    { id: 'Namballe', name: 'Namballe' },
    { id: 'San Jose de Lourdes', name: 'San Jose de Lourdes' },
    { id: 'Tabacones', name: 'Tabacones' }
  ],
  'San Marcos': [
    { id: 'Chancay', name: 'Chancay' },
    { id: 'Eduardo Villanueva', name: 'Eduardo Villanueva' },
    { id: 'Gregorio Pita', name: 'Gregorio Pita' },
    { id: 'Ichocan', name: 'Ichocan' },
    { id: 'José Manuel Quiroz', name: 'José Manuel Quiroz' },
    { id: 'José Sabogal', name: 'José Sabogal' },
    { id: 'Pedro Gálvez', name: 'Pedro Gálvez' }
  ],
  'San Miguel': [
    { id: 'Bolívar', name: 'Bolívar' },
    { id: 'Calquis', name: 'Calquis' },
    { id: 'Catilluc', name: 'Catilluc' },
    { id: 'El Prado', name: 'El Prado' },
    { id: 'La Florida', name: 'La Florida' },
    { id: 'Llapa', name: 'Llapa' },
    { id: 'Nanchoc', name: 'Nanchoc' },
    { id: 'Niepos', name: 'Niepos' },
    { id: 'San Gregorio', name: 'San Gregorio' },
    { id: 'San Miguel', name: 'San Miguel' },
    { id: 'San Silvestre de Cochan', name: 'San Silvestre de Cochan' },
    { id: 'Tongod', name: 'Tongod' },
    { id: 'Unión Agua Blanca', name: 'Unión Agua Blanca' }
  ],
  'San Pablo': [
    { id: 'San Pablo', name: 'San Pablo' },
    { id: 'San Bernardino', name: 'San Bernardino' },
    { id: 'San Luis', name: 'San Luis' },
    { id: 'Tumbaden', name: 'Tumbaden' }
  ],
  'Santa Cruz': [
    { id: 'Andabamba', name: 'Andabamba' },
    { id: 'Catache', name: 'Catache' },
    { id: 'Chancaybaños', name: 'Chancaybaños' },
    { id: 'La Esperanza', name: 'La Esperanza' },
    { id: 'Ninabamba', name: 'Ninabamba' },
    { id: 'Pulan', name: 'Pulan' },
    { id: 'Saucepampa', name: 'Saucepampa' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Sexi', name: 'Sexi' },
    { id: 'Uticyacu', name: 'Uticyacu' },
    { id: 'Yauyucan', name: 'Yauyucan' }
  ],
  'Prov. Const. del Callao': [
    { id: 'Bellavista', name: 'Bellavista' },
    { id: 'Callao', name: 'Callao' },
    { id: 'Carmen de la Legua Reynoso', name: 'Carmen de la Legua Reynoso' },
    { id: 'La Perla', name: 'La Perla' },
    { id: 'La Punta', name: 'La Punta' },
    { id: 'Ventanilla', name: 'Ventanilla' },
    { id: 'Mi Perú', name: 'Mi Perú' }
  ],
  Acomayo: [
    { id: 'Acomayo', name: 'Acomayo' },
    { id: 'Acopia', name: 'Acopia' },
    { id: 'Acos', name: 'Acos' },
    { id: 'Mosoc Llacta', name: 'Mosoc Llacta' },
    { id: 'Pomacanchi', name: 'Pomacanchi' },
    { id: 'Rondocan', name: 'Rondocan' },
    { id: 'Sangarara', name: 'Sangarara' }
  ],
  Anta: [
    { id: 'Anta', name: 'Anta' },
    { id: 'Ancahuasi', name: 'Ancahuasi' },
    { id: 'Cachimayo', name: 'Cachimayo' },
    { id: 'Chinchaypujio', name: 'Chinchaypujio' },
    { id: 'Huarocondo', name: 'Huarocondo' },
    { id: 'Limatambo', name: 'Limatambo' },
    { id: 'Mollepata', name: 'Mollepata' },
    { id: 'Pucyura', name: 'Pucyura' },
    { id: 'Zurite', name: 'Zurite' }
  ],
  Calca: [
    { id: 'Calca', name: 'Calca' },
    { id: 'Coya', name: 'Coya' },
    { id: 'Lamay', name: 'Lamay' },
    { id: 'Lares', name: 'Lares' },
    { id: 'Pisac', name: 'Pisac' },
    { id: 'San Salvador', name: 'San Salvador' },
    { id: 'Taray', name: 'Taray' },
    { id: 'Yanatile', name: 'Yanatile' }
  ],
  Canas: [
    { id: 'Checca', name: 'Checca' },
    { id: 'Kunturkanki', name: 'Kunturkanki' },
    { id: 'Langui', name: 'Langui' },
    { id: 'Layo', name: 'Layo' },
    { id: 'Pampamarca', name: 'Pampamarca' },
    { id: 'Quehue', name: 'Quehue' },
    { id: 'Tupac Amaru', name: 'Tupac Amaru' },
    { id: 'Yanaoca', name: 'Yanaoca' }
  ],
  Canchis: [
    { id: 'Checacupe', name: 'Checacupe' },
    { id: 'Combapata', name: 'Combapata' },
    { id: 'Marangani', name: 'Marangani' },
    { id: 'Pitumarca', name: 'Pitumarca' },
    { id: 'San Pablo', name: 'San Pablo' },
    { id: 'San Pedro', name: 'San Pedro' },
    { id: 'Sicuani', name: 'Sicuani' },
    { id: 'Tinta', name: 'Tinta' }
  ],
  Cusco: [
    { id: 'Ccorca', name: 'Ccorca' },
    { id: 'Cusco', name: 'Cusco' },
    { id: 'Poroy', name: 'Poroy' },
    { id: 'San Jerónimo', name: 'San Jerónimo' },
    { id: 'San Sebastian', name: 'San Sebastian' },
    { id: 'Santiago', name: 'Santiago' },
    { id: 'Saylla', name: 'Saylla' },
    { id: 'Wanchaq', name: 'Wanchaq' }
  ],
  Chumbivilcas: [
    { id: 'Capacmarca', name: 'Capacmarca' },
    { id: 'Chamaca', name: 'Chamaca' },
    { id: 'Colquemarca', name: 'Colquemarca' },
    { id: 'Livitaca', name: 'Livitaca' },
    { id: 'Llusco', name: 'Llusco' },
    { id: 'Santo Tomas', name: 'Santo Tomas' },
    { id: 'Quiñota', name: 'Quiñota' },
    { id: 'Velille', name: 'Velille' }
  ],
  Espinar: [
    { id: 'Alto Pichigua', name: 'Alto Pichigua' },
    { id: 'Condoroma', name: 'Condoroma' },
    { id: 'Coporaque', name: 'Coporaque' },
    { id: 'Espinar', name: 'Espinar' },
    { id: 'Ocoruro', name: 'Ocoruro' },
    { id: 'Pallpata', name: 'Pallpata' },
    { id: 'Pichigua', name: 'Pichigua' },
    { id: 'Suyckutambo', name: 'Suyckutambo' }
  ],
  'La Convención': [
    { id: 'Echarate', name: 'Echarate' },
    { id: 'Huayopata', name: 'Huayopata' },
    { id: 'Inkawasi', name: 'Inkawasi' },
    { id: 'Kimbiri', name: 'Kimbiri' },
    { id: 'Maranura', name: 'Maranura' },
    { id: 'Megantoni', name: 'Megantoni' },
    { id: 'Ocobamba', name: 'Ocobamba' },
    { id: 'Pichari', name: 'Pichari' },
    { id: 'Quellouno', name: 'Quellouno' },
    { id: 'Santa Ana', name: 'Santa Ana' },
    { id: 'Santa Teresa', name: 'Santa Teresa' },
    { id: 'Vilcabamba', name: 'Vilcabamba' },
    { id: 'Villa Virgen', name: 'Villa Virgen' },
    { id: 'Villa Kintiarina', name: 'Villa Kintiarina' }
  ],
  Paruro: [
    { id: 'Accha', name: 'Accha' },
    { id: 'Ccapi', name: 'Ccapi' },
    { id: 'Colcha', name: 'Colcha' },
    { id: 'Huanoquite', name: 'Huanoquite' },
    { id: 'Omacha', name: 'Omacha' },
    { id: 'Paccaritambo', name: 'Paccaritambo' },
    { id: 'Paruro', name: 'Paruro' },
    { id: 'Pillpinto', name: 'Pillpinto' },
    { id: 'Yaurisque', name: 'Yaurisque' }
  ],
  Paucartambo: [
    { id: 'Caicay', name: 'Caicay' },
    { id: 'Challabamba', name: 'Challabamba' },
    { id: 'Colquepata', name: 'Colquepata' },
    { id: 'Huancarani', name: 'Huancarani' },
    { id: 'Kosñipata', name: 'Kosñipata' },
    { id: 'Paucartambo', name: 'Paucartambo' }
  ],
  Quispicanchi: [
    { id: 'Andahuaylillas', name: 'Andahuaylillas' },
    { id: 'Camanti', name: 'Camanti' },
    { id: 'Ccarhuayo', name: 'Ccarhuayo' },
    { id: 'Ccatca', name: 'Ccatca' },
    { id: 'Cusipata', name: 'Cusipata' },
    { id: 'Huaro', name: 'Huaro' },
    { id: 'Lucre', name: 'Lucre' },
    { id: 'Marcapata', name: 'Marcapata' },
    { id: 'Ocongate', name: 'Ocongate' },
    { id: 'Oropesa', name: 'Oropesa' },
    { id: 'Quiquijana', name: 'Quiquijana' },
    { id: 'Urcos', name: 'Urcos' }
  ],
  Urubamba: [
    { id: 'Chinchero', name: 'Chinchero' },
    { id: 'Huayllabamba', name: 'Huayllabamba' },
    { id: 'Machupicchu', name: 'Machupicchu' },
    { id: 'Maras', name: 'Maras' },
    { id: 'Ollantaytambo', name: 'Ollantaytambo' },
    { id: 'Urubamba', name: 'Urubamba' },
    { id: 'Yucay', name: 'Yucay' }
  ],
  Acobamba: [
    { id: 'Acobamba', name: 'Acobamba' },
    { id: 'Andabamba', name: 'Andabamba' },
    { id: 'Anta', name: 'Anta' },
    { id: 'Caja', name: 'Caja' },
    { id: 'Marcas', name: 'Marcas' },
    { id: 'Paucara', name: 'Paucara' },
    { id: 'Pomacocha', name: 'Pomacocha' },
    { id: 'Rosario', name: 'Rosario' }
  ],
  Angaraes: [
    { id: 'Anchonga', name: 'Anchonga' },
    { id: 'Callanmarca', name: 'Callanmarca' },
    { id: 'Ccochaccasa', name: 'Ccochaccasa' },
    { id: 'Chincho', name: 'Chincho' },
    { id: 'Congalla', name: 'Congalla' },
    { id: 'Huanca-Huanca', name: 'Huanca-Huanca' },
    { id: 'Huayllay Grande', name: 'Huayllay Grande' },
    { id: 'Julcamarca', name: 'Julcamarca' },
    { id: 'Lircay', name: 'Lircay' },
    { id: 'San Antonio de Antaparco', name: 'San Antonio de Antaparco' },
    { id: 'Santo Tomas de Pata', name: 'Santo Tomas de Pata' },
    { id: 'Secclla', name: 'Secclla' }
  ],
  Castrovirreyna: [
    { id: 'Arma', name: 'Arma' },
    { id: 'Aurahua', name: 'Aurahua' },
    { id: 'Capillas', name: 'Capillas' },
    { id: 'Chupamarca', name: 'Chupamarca' },
    { id: 'Castrovirreyna', name: 'Castrovirreyna' },
    { id: 'Cocas', name: 'Cocas' },
    { id: 'Huachos', name: 'Huachos' },
    { id: 'Huamatambo', name: 'Huamatambo' },
    { id: 'Mollepampa', name: 'Mollepampa' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'Santa Ana', name: 'Santa Ana' },
    { id: 'Tantara', name: 'Tantara' },
    { id: 'Ticrapo', name: 'Ticrapo' }
  ],
  Churcampa: [
    { id: 'Anco', name: 'Anco' },
    { id: 'Chinchihuasi', name: 'Chinchihuasi' },
    { id: 'Cosme', name: 'Cosme' },
    { id: 'Churcampa', name: 'Churcampa' },
    { id: 'El Carmen', name: 'El Carmen' },
    { id: 'La Merced', name: 'La Merced' },
    { id: 'Locroja', name: 'Locroja' },
    { id: 'Paucarbamba', name: 'Paucarbamba' },
    { id: 'San Miguel de Mayocc', name: 'San Miguel de Mayocc' },
    { id: 'San Pedro de Coris', name: 'San Pedro de Coris' },
    { id: 'Pachamarca', name: 'Pachamarca' }
  ],
  Huaytará: [
    { id: 'Ayavi', name: 'Ayavi' },
    { id: 'Córdova', name: 'Córdova' },
    { id: 'Huaytara', name: 'Huaytara' },
    { id: 'Huayacundo Arma', name: 'Huayacundo Arma' },
    { id: 'Laramarca', name: 'Laramarca' },
    { id: 'Ocoyo', name: 'Ocoyo' },
    { id: 'Pilpichaca', name: 'Pilpichaca' },
    { id: 'Querco', name: 'Querco' },
    { id: 'Quito-Arma', name: 'Quito-Arma' },
    { id: 'San Antonio de Cusicancha', name: 'San Antonio de Cusicancha' },
    { id: 'San Francisco de Sangayaico', name: 'San Francisco de Sangayaico' },
    { id: 'San Isidro', name: 'San Isidro' },
    { id: 'Santiago de Chocorvos', name: 'Santiago de Chocorvos' },
    { id: 'Santiago de Quirahuara', name: 'Santiago de Quirahuara' },
    { id: 'Santo Domingo de Capillas', name: 'Santo Domingo de Capillas' },
    { id: 'Tambo', name: 'Tambo' }
  ],
  Huancavelica: [
    { id: 'Acobambilla', name: 'Acobambilla' },
    { id: 'Acoria', name: 'Acoria' },
    { id: 'Ascensión', name: 'Ascensión' },
    { id: 'Conayca', name: 'Conayca' },
    { id: 'Cuenca', name: 'Cuenca' },
    { id: 'Huachocolpa', name: 'Huachocolpa' },
    { id: 'Huancavelica', name: 'Huancavelica' },
    { id: 'Huando', name: 'Huando' },
    { id: 'Huayllahuara', name: 'Huayllahuara' },
    { id: 'Izcuchaca', name: 'AyaIzcuchacavi' },
    { id: 'Laria', name: 'Laria' },
    { id: 'Manta', name: 'Manta' },
    { id: 'Mariscal Cáceres', name: 'Mariscal Cáceres' },
    { id: 'Moya', name: 'Moya' },
    { id: 'Nuevo Occoro', name: 'Nuevo Occoro' },
    { id: 'Palca', name: 'Palca' },
    { id: 'Pilchaca', name: 'Pilchaca' },
    { id: 'Vilca', name: 'Vilca' },
    { id: 'Yauli', name: 'Yauli' }
  ],
  Tayacaja: [
    { id: 'Acostambo', name: 'Acostambo' },
    { id: 'Acraquia', name: 'Acraquia' },
    { id: 'Ahuaycha', name: 'Ahuaycha' },
    { id: 'Andaymarca', name: 'Andaymarca' },
    { id: 'Colcabamba', name: 'Colcabamba' },
    { id: 'Daniel Hernández', name: 'Daniel Hernández' },
    { id: 'Huachocolpa', name: 'Huachocolpa' },
    { id: 'Huaribamba', name: 'Huaribamba' },
    { id: 'Ñahuimpuquio', name: 'Ñahuimpuquio' },
    { id: 'Pampas', name: 'Pampas' },
    { id: 'Pazos', name: 'Pazos' },
    { id: 'Pichos', name: 'Pichos' },
    { id: 'Quishuar', name: 'Quishuar' },
    { id: 'Quichuas', name: 'Quichuas' },
    { id: 'Roble', name: 'Roble' },
    { id: 'Salcabamba', name: 'Salcabamba' },
    { id: 'Salcahuasi', name: 'Salcahuasi' },
    { id: 'Santiago de Tucuma', name: 'Santiago de Tucuma' },
    { id: 'San Marcos de Rocchac', name: 'San Marcos de Rocchac' },
    { id: 'Surcubamba', name: 'Surcubamba' },
    { id: 'Tintay Puncu', name: 'Tintay Puncu' }
  ],
  Ambo: [
    { id: 'Ambo', name: 'Ambo' },
    { id: 'Cayna', name: 'Cayna' },
    { id: 'Colpas', name: 'Colpas' },
    { id: 'Conchamarca', name: 'Conchamarca' },
    { id: 'Huacar', name: 'Huacar' },
    { id: 'San Francisco', name: 'San Francisco' },
    { id: 'San Rafael', name: 'San Rafael' },
    { id: 'Tomay Kichwa', name: 'Tomay Kichwa' }
  ],
  Mayo: [
    { id: 'Chuquis', name: 'Chuquis' },
    { id: 'La Unión', name: 'La Unión' },
    { id: 'Marías', name: 'Marías' },
    { id: 'Pachas', name: 'Pachas' },
    { id: 'Quivilla', name: 'Quivilla' },
    { id: 'Ripan', name: 'Ripan' },
    { id: 'Shunqui', name: 'Shunqui' },
    { id: 'Sillapata', name: 'Sillapata' },
    { id: 'Yanas', name: 'Yanas' }
  ],
  Huánuco: [
    { id: 'Amarilis', name: 'Amarilis' },
    { id: 'Chinchao', name: 'Chinchao' },
    { id: 'Churubamba', name: 'Churubamba' },
    { id: 'Huanuco', name: 'Huanuco' },
    { id: 'Margos', name: 'Margos' },
    { id: 'Pillco Marca', name: 'Pillco Marca' },
    { id: 'Quisqui (Kichki)', name: 'Quisqui (Kichki)' },
    { id: 'San Francisco de Cayran', name: 'San Francisco de Cayran' },
    { id: 'San Pedro de Chaulan', name: 'San Pedro de Chaulan' },
    { id: 'Santa María del Valle', name: 'Santa María del Valle' },
    { id: 'San Pablo de Pillao', name: 'San Pablo de Pillao' },
    { id: 'Yacus', name: 'Yacus' },
    { id: 'Yarumayo', name: 'Yarumayo' }
  ],
  Huacaybamba: [
    { id: 'Canchabamba', name: 'Canchabamba' },
    { id: 'Cochabamba', name: 'Cochabamba' },
    { id: 'Huacaybamba', name: 'Huacaybamba' },
    { id: 'Pinra', name: 'Pinra' }
  ],
  Huamalíes: [
    { id: 'Arancay', name: 'Arancay' },
    { id: 'Chavín de Pariarca', name: 'Chavín de Pariarca' },
    { id: 'Jacas Grande', name: 'Jacas Grande' },
    { id: 'Jircan', name: 'Jircan' },
    { id: 'Llata', name: 'Llata' },
    { id: 'Miraflores', name: 'Miraflores' },
    { id: 'Monzón', name: 'Monzón' },
    { id: 'Punchao', name: 'Punchao' },
    { id: 'Puños', name: 'Puños' },
    { id: 'Singa', name: 'Singa' },
    { id: 'Tantamayo', name: 'Tantamayo' }
  ],
  'Leoncio Prado': [
    { id: 'Castillo Grande', name: 'Castillo Grande' },
    { id: 'Daniel Alomía Robles', name: 'Daniel Alomía Robles' },
    { id: 'Hermílio Valdizan', name: 'Hermílio Valdizan' },
    { id: 'José Crespo y Castillo', name: 'José Crespo y Castillo' },
    { id: 'Luyando', name: 'Luyando' },
    { id: 'Mariano Damaso Beraun', name: 'Mariano Damaso Beraun' },
    { id: 'Pueblo Nuevo', name: 'Pueblo Nuevo' },
    { id: 'Pucayacu', name: 'Pucayacu' },
    { id: 'Rupa-Rupa', name: 'Rupa-Rupa' },
    { id: 'Santo Domingo de Anda', name: 'Santo Domingo de Anda' }
  ],
  Marañón: [
    { id: 'Cholon', name: 'Cholon' },
    { id: 'Huacrachuco', name: 'Huacrachuco' },
    { id: 'La Morada', name: 'La Morada' },
    { id: 'San Buenaventura', name: 'San Buenaventura' },
    {
      id: 'Santa Rosa de Alto Yanajanca',
      name: 'Santa Rosa de Alto Yanajanca'
    }
  ],
  Pachitea: [
    { id: 'Chaglla', name: 'Chaglla' },
    { id: 'Molino', name: 'Molino' },
    { id: 'Panao', name: 'Panao' },
    { id: 'Umari', name: 'Umari' }
  ],
  'Puerto Inca': [
    { id: 'Codo del Pozuzo', name: 'Codo del Pozuzo' },
    { id: 'Honoria', name: 'Honoria' },
    { id: 'Puerto Inca', name: 'Puerto Inca' },
    { id: 'Tournavista', name: 'Tournavista' },
    { id: 'Yuyapichis', name: 'Yuyapichis' }
  ],
  Lauricocha: [
    { id: 'Baños', name: 'Baños' },
    { id: 'Jesús', name: 'Jesús' },
    { id: 'Jivia', name: 'Jivia' },
    { id: 'Queropalca', name: 'Queropalca' },
    { id: 'Rondos', name: 'Rondos' },
    { id: 'San Francisco de Asís', name: 'San Francisco de Asís' },
    { id: 'San Miguel de Cauri', name: 'San Miguel de Cauri' }
  ],
  Yarowilca: [
    { id: 'Aparicio Pomares', name: 'Aparicio Pomares' },
    { id: 'Chavinillo', name: 'Chavinillo' },
    { id: 'Cahuac', name: 'Cahuac' },
    { id: 'Chacabamba', name: 'Chacabamba' },
    { id: 'Choras', name: 'Choras' },
    { id: 'Jacas Chico', name: 'Jacas Chico' },
    { id: 'Obas', name: 'Obas' },
    { id: 'Pampamarca', name: 'Pampamarca' }
  ],
  Ica: [
    { id: 'Ica', name: 'Ica' },
    { id: 'La Tinguiña', name: 'La Tinguiña' },
    { id: 'Los Aquijes', name: 'Los Aquijes' },
    { id: 'Ocucaje', name: 'Ocucaje' },
    { id: 'Pachacutec', name: 'Pachacutec' },
    { id: 'Parcona', name: 'Parcona' },
    { id: 'Pueblo Nuevo', name: 'Pueblo Nuevo' },
    { id: 'Salas', name: 'Salas' },
    { id: 'San José de Los Molinos', name: 'San José de Los Molinos' },
    { id: 'San Juan Bautista', name: 'San Juan Bautista' },
    { id: 'Santiago', name: 'Santiago' },
    { id: 'Subtanjalla', name: 'Subtanjalla' },
    { id: 'Tate', name: 'Tate' },
    { id: 'Yauca del Rosario', name: 'Yauca del Rosario' }
  ],
  Chincha: [
    { id: 'Alto Laran', name: 'Alto Laran' },
    { id: 'Chavin', name: 'Chavin' },
    { id: 'Chincha Alta', name: 'Chincha Alta' },
    { id: 'Chincha Baja', name: 'Chincha Baja' },
    { id: 'El Carmen', name: 'El Carmen' },
    { id: 'Grocio Prado', name: 'Grocio Prado' },
    { id: 'Pueblo Nuevo', name: 'Pueblo Nuevo' },
    { id: 'San Juan de Yanac', name: 'San Juan de Yanac' },
    { id: 'San Pedro de Huacarpana', name: 'San Pedro de Huacarpana' },
    { id: 'Sunampe', name: 'Sunampe' },
    { id: 'Tambo de Mora', name: 'Tambo de Mora' }
  ],
  Nasca: [
    { id: 'Changuillo', name: 'Changuillo' },
    { id: 'El Ingenio', name: 'El Ingenio' },
    { id: 'Marcona', name: 'Marcona' },
    { id: 'Nasca', name: 'Nasca' },
    { id: 'Vista Alegre', name: 'Vista Alegre' }
  ],
  Palpa: [
    { id: 'Llipata', name: 'Llipata' },
    { id: 'Palpa', name: 'Palpa' },
    { id: 'Río Grande', name: 'Río Grande' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Tibillo', name: 'Tibillo' }
  ],
  Pisco: [
    { id: 'Huancano', name: 'Huancano' },
    { id: 'Humay', name: 'Humay' },
    { id: 'Independencia', name: 'Independencia' },
    { id: 'Paracas', name: 'Paracas' },
    { id: 'Pisco', name: 'Pisco' },
    { id: 'San Andrés', name: 'San Andrés' },
    { id: 'San Clemente', name: 'San Clemente' },
    { id: 'Tupac Amaru Inca', name: 'Tupac Amaru Inca' }
  ],
  Huancayo: [
    { id: 'Carhuacallanga', name: 'Carhuacallanga' },
    { id: 'Chacapampa', name: 'Chacapampa' },
    { id: 'Chicche', name: 'Chicche' },
    { id: 'Chilca', name: 'Chilca' },
    { id: 'Chincha', name: 'Chincha' },
    { id: 'Chongos Alto', name: 'Chongos Alto' },
    { id: 'Chupuro', name: 'Chupuro' },
    { id: 'Colca', name: 'Colca' },
    { id: 'Cullhuas', name: 'Cullhuas' },
    { id: 'El Tambo', name: 'El Tambo' },
    { id: 'Huacrapuquio', name: 'Huacrapuquio' },
    { id: 'Huancayo', name: 'Huancayo' },
    { id: 'Hualhuas', name: 'Hualhuas' },
    { id: 'Huancan', name: 'Huancan' },
    { id: 'Huasicancha', name: 'Huasicancha' },
    { id: 'Huayucachi', name: 'Huayucachi' },
    { id: 'Ingenio', name: 'Ingenio' },
    { id: 'Pariahuanca', name: 'Pariahuanca' },
    { id: 'Pilcomayo', name: 'Pilcomayo' },
    { id: 'Pucara', name: 'Pucara' },
    { id: 'Quichuay', name: 'Quichuay' },
    { id: 'Quilcas', name: 'Quilcas' },
    { id: 'San Agustín', name: 'San Agustín' },
    { id: 'San Jerónimo de Tunan', name: 'San Jerónimo de Tunan' },
    { id: 'Saño', name: 'Saño' },
    { id: 'Sapallanga', name: 'Sapallanga' },
    { id: 'Sicaya', name: 'Sicaya' },
    { id: 'Santo Domingo de Acobamba', name: 'Santo Domingo de Acobamba' },
    { id: 'Viques', name: 'Viques' }
  ],
  Concepción: [
    { id: 'Aco', name: 'Aco' },
    { id: 'Andamarca', name: 'Andamarca' },
    { id: 'Chambara', name: 'Chambara' },
    { id: 'Cochas', name: 'Cochas' },
    { id: 'Concepción', name: 'Concepción' },
    { id: 'Comas', name: 'Comas' },
    { id: 'Heroínas Toledo', name: 'Heroínas Toledo' },
    { id: 'Manzanares', name: 'Manzanares' },
    { id: 'Mariscal Castilla', name: 'Mariscal Castilla' },
    { id: 'Matahuasi', name: 'Matahuasi' },
    { id: 'Mito', name: 'Mito' },
    { id: 'Nueve de Julio', name: 'Nueve de Julio' },
    { id: 'Orcotuna', name: 'Orcotuna' },
    { id: 'San José de Quero', name: 'San José de Quero' },
    { id: 'Santa Rosa de Ocopa', name: 'Santa Rosa de Ocopa' }
  ],
  Chanchamayo: [
    { id: 'Chanchamayo', name: 'Chanchamayo' },
    { id: 'Perene', name: 'Perene' },
    { id: 'Pichanaqui', name: 'Pichanaqui' },
    { id: 'San Luis de Shuaro', name: 'San Luis de Shuaro' },
    { id: 'San Ramón', name: 'San Ramón' },
    { id: 'Vitoc', name: 'Vitoc' }
  ],
  Jauja: [
    { id: 'Acolla', name: 'Acolla' },
    { id: 'Apata', name: 'Apata' },
    { id: 'Ataura', name: 'Ataura' },
    { id: 'Canchayllo', name: 'Canchayllo' },
    { id: 'Curicaca', name: 'Curicaca' },
    { id: 'El Mantaro', name: 'El Mantaro' },
    { id: 'Huamali', name: 'Huamali' },
    { id: 'Huaripampa', name: 'Huaripampa' },
    { id: 'Huertas', name: 'Huertas' },
    { id: 'Janjaillo', name: 'Janjaillo' },
    { id: 'Jauja', name: 'Jauja' },
    { id: 'Julcán', name: 'Julcán' },
    { id: 'Leonor Ordóñez', name: 'Leonor Ordóñez' },
    { id: 'Llocllapampa', name: 'Llocllapampa' },
    { id: 'Marco', name: 'Marco' },
    { id: 'Masma', name: 'Masma' },
    { id: 'Masma Chicche', name: 'Masma Chicche' },
    { id: 'Molinos', name: 'Molinos' },
    { id: 'Monobamba', name: 'Monobamba' },
    { id: 'Muqui', name: 'Muqui' },
    { id: 'Muquiyauyo', name: 'Muquiyauyo' },
    { id: 'Paca', name: 'Paca' },
    { id: 'Paccha', name: 'Paccha' },
    { id: 'Pancan', name: 'Pancan' },
    { id: 'Parco', name: 'Parco' },
    { id: 'Pomacancha', name: 'Pomacancha' },
    { id: 'Ricran', name: 'Ricran' },
    { id: 'San Lorenzo', name: 'San Lorenzo' },
    { id: 'San Pedro de Chunan', name: 'San Pedro de Chunan' },
    { id: 'Sausa', name: 'Sausa' },
    { id: 'Sincos', name: 'Sincos' },
    { id: 'Tunan Marca', name: 'Tunan Marca' },
    { id: 'Yauli', name: 'Yauli' },
    { id: 'Yauyos', name: 'Yauyos' }
  ],
  Junín: [
    { id: 'Carhuamayo', name: 'Carhuamayo' },
    { id: 'Junin', name: 'Junin' },
    { id: 'Ondores', name: 'Ondores' },
    { id: 'Ulcumayo', name: 'Ulcumayo' }
  ],
  Satipo: [
    { id: 'Coviriali', name: 'Coviriali' },
    { id: 'Llaylla', name: 'Llaylla' },
    { id: 'Mazamari', name: 'Mazamari' },
    { id: 'Pampa Hermosa', name: 'Pampa Hermosa' },
    { id: 'Pangoa', name: 'Pangoa' },
    { id: 'Río Negro', name: 'Río Negro' },
    { id: 'Río Tambo', name: 'Río Tambo' },
    { id: 'Satipo', name: 'Satipo' },
    { id: 'Vizcatan del Ene', name: 'Vizcatan del Ene' }
  ],
  Tarma: [
    { id: 'Acobamba', name: 'Acobamba' },
    { id: 'Huaricolca', name: 'Huaricolca' },
    { id: 'Huasahuasi', name: 'Huasahuasi' },
    { id: 'La Unión', name: 'La Unión' },
    { id: 'Palca', name: 'Palca' },
    { id: 'Palcamayo', name: 'Palcamayo' },
    { id: 'San Pedro de Cajas', name: 'San Pedro de Cajas' },
    { id: 'Tarma', name: 'Tarma' },
    { id: 'Tapo', name: 'Tapo' }
  ],
  Yauli: [
    { id: 'Chacapalpa', name: 'Chacapalpa' },
    { id: 'Huay-Huay', name: 'Huay-Huay' },
    { id: 'La Oroya', name: 'La Oroya' },
    { id: 'Marcapomacocha', name: 'Marcapomacocha' },
    { id: 'Morococha', name: 'Morococha' },
    { id: 'Paccha', name: 'Paccha' },
    {
      id: 'Santa Bárbara de Carhuacayan',
      name: 'Santa Bárbara de Carhuacayan'
    },
    { id: 'Santa Rosa de Sacco', name: 'Santa Rosa de Sacco' },
    { id: 'Suitucancha', name: 'Suitucancha' },
    { id: 'Yauli', name: 'Yauli' }
  ],
  Chupaca: [
    { id: 'Ahuac', name: 'Ahuac' },
    { id: 'Chongos Bajo', name: 'Chongos Bajo' },
    { id: 'Chupaca', name: 'Chupaca' },
    { id: 'Huachac', name: 'Huachac' },
    { id: 'Huamancaca Chico', name: 'Huamancaca Chico' },
    { id: 'San Juan de Iscos', name: 'San Juan de Iscos' },
    { id: 'San Juan de Jarpa', name: 'San Juan de Jarpa' },
    { id: 'Tres de Diciembre', name: 'Tres de Diciembre' },
    { id: 'Yanacancha', name: 'Yanacancha' }
  ],
  Ascope: [
    { id: 'Ascope', name: 'Ascope' },
    { id: 'Casa Grande', name: 'Casa Grande' },
    { id: 'Chicama', name: 'Chicama' },
    { id: 'Chocope', name: 'Chocope' },
    { id: 'Magdalena de Cao', name: 'Magdalena de Cao' },
    { id: 'Paijan', name: 'Paijan' },
    { id: 'Rázuri', name: 'Rázuri' },
    { id: 'Santiago de Cao', name: 'Santiago de Cao' }
  ],
  Trujillo: [
    { id: 'El Porvenir', name: 'El Porvenir' },
    { id: 'Florencia de Mora', name: 'Florencia de Mora' },
    { id: 'Huanchaco', name: 'Huanchaco' },
    { id: 'La Esperanza', name: 'La Esperanza' },
    { id: 'Laredo', name: 'Laredo' },
    { id: 'Moche', name: 'Moche' },
    { id: 'Poroto', name: 'Poroto' },
    { id: 'Salaverry', name: 'Salaverry' },
    { id: 'Simbal', name: 'Simbal' },
    { id: 'Trujillo', name: 'Trujillo' },
    { id: 'Victor Larco Herrera', name: 'Victor Larco Herrera' }
  ],
  Bolívar: [
    { id: 'Bambamarca', name: 'Bambamarca' },
    { id: 'Bolívar', name: 'Bolívar' },
    { id: 'Condormarca', name: 'Condormarca' },
    { id: 'Longotea', name: 'Longotea' },
    { id: 'Uchumarca', name: 'Uchumarca' },
    { id: 'Ucuncha', name: 'Ucuncha' }
  ],
  Chepén: [
    { id: 'Chepen', name: 'Chepen' },
    { id: 'Pacanga', name: 'Pacanga' },
    { id: 'Pueblo Nuevo', name: 'Pueblo Nuevo' }
  ],
  Julcán: [
    { id: 'Calamarca', name: 'Calamarca' },
    { id: 'Carabamba', name: 'Carabamba' },
    { id: 'Huaso', name: 'Huaso' },
    { id: 'Julcan', name: 'Julcan' }
  ],
  Otuzco: [
    { id: 'Agallpampa', name: 'Agallpampa' },
    { id: 'Charat', name: 'Charat' },
    { id: 'Huaranchal', name: 'Huaranchal' },
    { id: 'La Cuesta', name: 'La Cuesta' },
    { id: 'Mache', name: 'Mache' },
    { id: 'Otuzco', name: 'Otuzco' },
    { id: 'Paranday', name: 'Paranday' },
    { id: 'Salpo', name: 'Salpo' },
    { id: 'Sinsicap', name: 'Sinsicap' },
    { id: 'Usquil', name: 'Usquil' }
  ],
  Pacasmayo: [
    { id: 'Guadalupe', name: 'Guadalupe' },
    { id: 'Jequetepeque', name: 'Jequetepeque' },
    { id: 'Pacasmayo', name: 'Pacasmayo' },
    { id: 'San Pedro de Lloc', name: 'San Pedro de Lloc' },
    { id: 'San José', name: 'San José' }
  ],
  Pataz: [
    { id: 'Buldibuyo', name: 'Buldibuyo' },
    { id: 'Chillia', name: 'Chillia' },
    { id: 'Huancaspata', name: 'Huancaspata' },
    { id: 'Huaylillas', name: 'Huaylillas' },
    { id: 'Huayo', name: 'Huayo' },
    { id: 'Ongon', name: 'Ongon' },
    { id: 'Parcoy', name: 'Parcoy' },
    { id: 'Pataz', name: 'Pataz' },
    { id: 'Pias', name: 'Pias' },
    { id: 'Santiago de Challas', name: 'Santiago de Challas' },
    { id: 'Taurija', name: 'Taurija' },
    { id: 'Tayabamba', name: 'Tayabamba' },
    { id: 'Urpay', name: 'Urpay' }
  ],
  'Sanchez Carrion': [
    { id: 'Huamachuco', name: 'Huamachuco' },
    { id: 'Chugay', name: 'Chugay' },
    { id: 'Cochorco', name: 'Cochorco' },
    { id: 'Curgos', name: 'Curgos' },
    { id: 'Marcabal', name: 'Marcabal' },
    { id: 'Sanagoran', name: 'Sanagoran' },
    { id: 'Sarin', name: 'Sarin' },
    { id: 'Sartimbamba', name: 'Sartimbamba' }
  ],
  'Santiago de Chuco': [
    { id: 'Angasmarca', name: 'Angasmarca' },
    { id: 'Cachicadan', name: 'Cachicadan' },
    { id: 'Mollebamba', name: 'Mollebamba' },
    { id: 'Mollepata', name: 'Mollepata' },
    { id: 'Quiruvilca', name: 'Quiruvilca' },
    { id: 'Santiago de Chuco', name: 'Santiago de Chuco' },
    { id: 'Santa Cruz de Chuca', name: 'Santa Cruz de Chuca' },
    { id: 'Sitabamba', name: 'Sitabamba' }
  ],
  Chimú: [
    { id: 'Cascas', name: 'Cascas' },
    { id: 'Lucma', name: 'Lucma' },
    { id: 'Compin', name: 'Compin' },
    { id: 'Sayapullo', name: 'Sayapullo' }
  ],
  Virú: [
    { id: 'Chao', name: 'Chao' },
    { id: 'Guadalupito', name: 'Guadalupito' },
    { id: 'Viru', name: 'Viru' }
  ],
  Chiclayo: [
    { id: 'Cayalti', name: 'Cayalti' },
    { id: 'Chiclayo', name: 'Chiclayo' },
    { id: 'Chongoyape', name: 'Chongoyape' },
    { id: 'Eten', name: 'Eten' },
    { id: 'Eten Puerto', name: 'Eten Puerto' },
    { id: 'José Leonardo Ortiz', name: 'José Leonardo Ortiz' },
    { id: 'La Victoria', name: 'La Victoria' },
    { id: 'Lagunas', name: 'Lagunas' },
    { id: 'Monsefu', name: 'Monsefu' },
    { id: 'Nueva Arica', name: 'Nueva Arica' },
    { id: 'Oyotun', name: 'Oyotun' },
    { id: 'Picsi', name: 'Picsi' },
    { id: 'Pimentel', name: 'Pimentel' },
    { id: 'Reque', name: 'Reque' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Saña', name: 'Saña' },
    { id: 'Patapo', name: 'Patapo' },
    { id: 'Pomalca', name: 'Pomalca' },
    { id: 'Pucala', name: 'Pucala' },
    { id: 'Tuman', name: 'Tuman' }
  ],
  Ferreñafe: [
    { id: 'Cañaris', name: 'Cañaris' },
    { id: 'Ferreñafe', name: 'Ferreñafe' },
    { id: 'Incahuasi', name: 'Incahuasi' },
    { id: 'Manuel Antonio Mesones Muro', name: 'Manuel Antonio Mesones Muro' },
    { id: 'Pitipo', name: 'Pitipo' },
    { id: 'Pueblo Nuevo', name: 'Pueblo Nuevo' }
  ],
  Lambayeque: [
    { id: 'Chochope', name: 'Chochope' },
    { id: 'Illimo', name: 'Illimo' },
    { id: 'Jayanca', name: 'Jayanca' },
    { id: 'Lambayeque', name: 'Lambayeque' },
    { id: 'Mochumi', name: 'Mochumi' },
    { id: 'Morrope', name: 'Morrope' },
    { id: 'Motupe', name: 'Motupe' },
    { id: 'Olmos', name: 'Olmos' },
    { id: 'Pacora', name: 'Pacora' },
    { id: 'Salas', name: 'Salas' },
    { id: 'San José', name: 'San José' },
    { id: 'Tucume', name: 'Tucume' }
  ],
  Lima: [
    { id: 'Ancón', name: 'Ancón' },
    { id: 'Ate', name: 'Ate' },
    { id: 'Barranco', name: 'Barranco' },
    { id: 'Breña', name: 'Breña' },
    { id: 'Carabayllo', name: 'Carabayllo' },
    { id: 'Chaclacayo', name: 'Chaclacayo' },
    { id: 'Chorrillos', name: 'Chorrillos' },
    { id: 'Cieneguilla', name: 'Cieneguilla' },
    { id: 'Comas', name: 'Comas' },
    { id: 'El Agustino', name: 'El Agustino' },
    { id: 'Independencia', name: 'Independencia' },
    { id: 'Jesús María', name: 'Jesús María' },
    { id: 'La Molina', name: 'La Molina' },
    { id: 'La Victoria', name: 'La Victoria' },
    { id: 'Lima', name: 'Lima' },
    { id: 'Lince', name: 'Lince' },
    { id: 'Olivos', name: 'Olivos' },
    { id: 'Lurigancho', name: 'Lurigancho' },
    { id: 'Lurin', name: 'Lurin' },
    { id: 'Magdalena', name: 'Magdalena del Mar' },
    { id: 'Pueblo Libre', name: 'Pueblo Libre' },
    { id: 'Miraflores', name: 'Miraflores' },
    { id: 'Pachacamac', name: 'Pachacamac' },
    { id: 'Pucusana', name: 'Pucusana' },
    { id: 'Puente Piedra', name: 'Puente Piedra' },
    { id: 'Punta Hermosa', name: 'Punta Hermosa' },
    { id: 'Punta Negra', name: 'Punta Negra' },
    { id: 'Rímac', name: 'Rímac' },
    { id: 'San Bartolo', name: 'San Bartolo' },
    { id: 'San Borja', name: 'San Borja' },
    { id: 'San Isidro', name: 'San Isidro' },
    { id: 'San Juan de Lurigancho', name: 'San Juan de Lurigancho' },
    { id: 'San Juan de Miraflores', name: 'San Juan de Miraflores' },
    { id: 'San Luis', name: 'San Luis' },
    { id: 'San Martín de Porres', name: 'San Martín de Porres' },
    { id: 'San Miguel', name: 'San Miguel' },
    { id: 'Santa Anita', name: 'Santa Anita' },
    { id: 'Santa María del Mar', name: 'Santa María del Mar' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Santiago de Surco', name: 'Santiago de Surco' },
    { id: 'Surquillo', name: 'Surquillo' },
    { id: 'Villa El Salvador', name: 'Villa El Salvador' },
    { id: 'Villa María del Triunfo', name: 'Villa María del Triunfo' }
  ],
  Barranca: [
    { id: 'Barranca', name: 'Barranca' },
    { id: 'Paramonga', name: 'Paramonga' },
    { id: 'Pativilca', name: 'Pativilca' },
    { id: 'Supe', name: 'Supe' },
    { id: 'Supe Puerto', name: 'Supe Puerto' }
  ],
  Cajatambo: [
    { id: 'Cajatambo', name: 'Cajatambo' },
    { id: 'Copa', name: 'Copa' },
    { id: 'Gorgor', name: 'Gorgor' },
    { id: 'Huancapon', name: 'Huancapon' },
    { id: 'Manas', name: 'Manas' }
  ],
  Canta: [
    { id: 'Arahuay', name: 'Arahuay' },
    { id: 'Canta', name: 'Canta' },
    { id: 'Huamantanga', name: 'Huamantanga' },
    { id: 'Huaros', name: 'Huaros' },
    { id: 'Lachaqui', name: 'Lachaqui' },
    { id: 'San Buenaventura', name: 'San Buenaventura' },
    { id: 'Santa Rosa de Quives', name: 'Santa Rosa de Quives' }
  ],
  Cañete: [
    { id: 'Asia', name: 'Asia' },
    { id: 'Calango', name: 'Calango' },
    { id: 'Cerro Azul', name: 'Cerro Azul' },
    { id: 'Chilca', name: 'Chilca' },
    { id: 'Coayllo', name: 'Coayllo' },
    { id: 'Imperial', name: 'Imperial' },
    { id: 'Lunahuana', name: 'Lunahuana' },
    { id: 'Mala', name: 'Mala' },
    { id: 'Nuevo Imperial', name: 'Nuevo Imperial' },
    { id: 'Pacaran', name: 'Pacaran' },
    { id: 'Quilmana', name: 'Quilmana' },
    { id: 'San Antonio', name: 'San Antonio' },
    { id: 'San Luis', name: 'San Luis' },
    { id: 'Santa Cruz de Flores', name: 'Santa Cruz de Flores' },
    { id: 'San Vicente de Cañete', name: 'San Vicente de Cañete' },
    { id: 'Zúñiga', name: 'Zúñiga' }
  ],
  Huaral: [
    { id: 'Atavillos Alto', name: 'Atavillos Alto' },
    { id: 'Atavillos Bajo', name: ' Atavillos Bajo' },
    { id: 'Aucallama', name: 'Aucallama' },
    { id: 'Chancay', name: 'Chancay' },
    { id: 'Huaral', name: 'Huaral' },
    { id: 'Ihuari', name: 'Ihuari' },
    { id: 'Lampian', name: 'Lampian' },
    { id: 'Pacaraos', name: 'Pacaraos' },
    { id: 'San Miguel de Acos', name: 'San Miguel de Acos' },
    { id: 'Santa Cruz de Andamarca', name: 'Santa Cruz de Andamarca' },
    { id: 'Sumbilca', name: 'Sumbilca' },
    { id: 'Veintisiete de Noviembre', name: 'Veintisiete de Noviembre' }
  ],
  Huarochirí: [
    { id: 'Antioquia', name: 'Antioquia' },
    { id: 'Callahuanca', name: 'Callahuanca' },
    { id: 'Carampoma', name: 'Carampoma' },
    { id: 'Chicla', name: 'Chicla' },
    { id: 'Cuenca', name: 'Cuenca' },
    { id: 'Huachupampa', name: 'Huachupampa' },
    { id: 'Huanza', name: 'Huanza' },
    { id: 'Huarochiri', name: 'Huarochiri' },
    { id: 'Lahuaytambo', name: 'Lahuaytambo' },
    { id: 'Langa', name: 'Langa' },
    { id: 'Laraos', name: 'Laraos' },
    { id: 'Mariatana', name: 'Mariatana' },
    { id: 'Matucana', name: 'Matucana' },
    { id: 'Ricardo Palma', name: 'Ricardo Palma' },
    { id: 'San Andrés de Tupicocha', name: 'San Andrés de Tupicocha' },
    { id: 'San Antonio', name: 'San Antonio' },
    { id: 'San Bartolomé', name: 'San Bartolomé' },
    { id: 'San Damian', name: 'San Damian' },
    { id: 'San Juan de Iris', name: 'San Juan de Iris' },
    { id: 'San Juan de Tantaranche', name: 'San Juan de Tantaranche' },
    { id: 'San Lorenzo de Quinti', name: 'San Lorenzo de Quinti' },
    { id: 'San Mateo', name: 'San Mateo' },
    { id: 'San Mateo de Otao', name: 'San Mateo de Otao' },
    { id: 'San Pedro de Casta', name: 'San Pedro de Casta' },
    { id: 'San Pedro de Huancayre', name: 'San Pedro de Huancayre' },
    { id: 'San Pedro de Laraos', name: 'San Pedro de Laraos' },
    { id: 'Sangallaya', name: 'Sangallaya' },
    { id: 'Santa Cruz de Cocachacra', name: 'Santa Cruz de Cocachacra' },
    { id: 'Santa Eulalia', name: 'Santa Eulalia' },
    { id: 'Santiago de Anchucaya', name: 'Santiago de Anchucaya' },
    { id: 'Santiago de Tuna', name: 'Santiago de Tuna' },
    {
      id: 'Santo Domingo de Los Olleros',
      name: 'Santo Domingo de Los Olleros'
    },
    { id: 'Surco', name: 'Surco' }
  ],
  Huaura: [
    { id: 'Ambar', name: 'Ambar' },
    { id: 'Caleta de Carquin', name: 'Caleta de Carquin' },
    { id: 'Checras', name: 'Checras' },
    { id: 'Hualmay', name: 'Hualmay' },
    { id: 'Huaura', name: 'Huaura' },
    { id: 'Huacho', name: 'Huacho' },
    { id: 'Leoncio Prado', name: 'Leoncio Prado' },
    { id: 'Paccho', name: 'Paccho' },
    { id: 'Santa Leonor', name: 'Santa Leonor' },
    { id: 'Santa María', name: 'Santa María' },
    { id: 'Sayan', name: 'Sayan' },
    { id: 'Vegueta', name: 'Vegueta' }
  ],
  Oyón: [
    { id: 'Andajes', name: 'Andajes' },
    { id: 'Caujul', name: 'Caujul' },
    { id: 'Cochamarca', name: 'Cochamarca' },
    { id: 'Navan', name: 'Navan' },
    { id: 'Oyon', name: 'Oyon' },
    { id: 'Pachangara', name: 'Pachangara' }
  ],
  Yauyos: [
    { id: 'Alis', name: 'Alis' },
    { id: 'Allauca', name: 'Allauca' },
    { id: 'Ayaviri', name: 'Ayaviri' },
    { id: 'Azángaro', name: 'Azángaro' },
    { id: 'Cacra', name: 'Cacra' },
    { id: 'Carania', name: 'Carania' },
    { id: 'Catahuasi', name: 'Catahuasi' },
    { id: 'Chocos', name: 'Chocos' },
    { id: 'Cochas', name: 'Cochas' },
    { id: 'Colonia', name: 'Colonia' },
    { id: 'Hongos', name: 'Hongos' },
    { id: 'Huampara', name: 'Huampara' },
    { id: 'Huancaya', name: 'Huancaya' },
    { id: 'Huangascar', name: 'Huangascar' },
    { id: 'Huantan', name: 'Huantan' },
    { id: 'Huañec', name: 'Huañec' },
    { id: 'Laraos', name: 'Laraos' },
    { id: 'Lincha', name: 'Lincha' },
    { id: 'Madean', name: 'Madean' },
    { id: 'Miraflores', name: 'Miraflores' },
    { id: 'Omas', name: 'Omas' },
    { id: 'Putinza', name: 'Putinza' },
    { id: 'Quinches', name: 'Quinches' },
    { id: 'Quinocay', name: 'Quinocay' },
    { id: 'San Joaquín', name: 'San Joaquín' },
    { id: 'San Pedro de Pilas', name: 'San Pedro de Pilas' },
    { id: 'Tanta', name: 'Tanta' },
    { id: 'Tauripampa', name: 'Tauripampa' },
    { id: 'Tomas', name: 'Tomas' },
    { id: 'Tupe', name: 'Tupe' },
    { id: 'Viñac', name: 'Viñac' },
    { id: 'Vitis', name: 'Vitis' },
    { id: 'Yauyos', name: 'Yauyos' }
  ],
  'Alto Amazonas': [
    { id: 'Balsapuerto', name: 'Balsapuerto' },
    { id: 'Jeberos', name: 'Jeberos' },
    { id: 'Lagunas', name: 'Lagunas' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Teniente Cesar López Rojas', name: 'Teniente Cesar López Rojas' },
    { id: 'Yurimaguas', name: 'Yurimaguas' }
  ],
  'Datem del Marañón': [
    { id: 'Andoas', name: 'Andoas' },
    { id: 'Barranca', name: 'Barranca' },
    { id: 'Cahuapanas', name: 'Cahuapanas' },
    { id: 'Manseriche', name: 'Manseriche' },
    { id: 'Morona', name: 'Morona' },
    { id: 'Pastaza', name: 'Pastaza' }
  ],
  Loreto: [
    { id: 'Nauta', name: 'Nauta' },
    { id: 'Parinari', name: 'Parinari' },
    { id: 'Tigre', name: 'AndoTigreas' },
    { id: 'Trompeteros', name: 'Trompeteros' },
    { id: 'Urarinas', name: 'Urarinas' }
  ],
  'Mariscal Ramón Castilla': [
    { id: 'Pebas', name: 'Pebas' },
    { id: 'Ramón Castilla', name: 'Ramón Castilla' },
    { id: 'San Pablo', name: 'San Pablo' },
    { id: 'Yavari', name: 'Yavari' }
  ],
  Maynas: [
    { id: 'Alto Nanay', name: 'Alto Nanay' },
    { id: 'Belén', name: 'Belén' },
    { id: 'Fernando Lores', name: 'Fernando Lores' },
    { id: 'Indiana', name: 'Indiana' },
    { id: 'Iquitos', name: 'Iquitos' },
    { id: 'Las Amazonas', name: 'Las Amazonas' },
    { id: 'Mazan', name: 'Mazan' },
    { id: 'Napo', name: 'Napo' },
    { id: 'Punchana', name: 'Punchana' },
    { id: 'San Juan Bautista', name: 'San Juan Bautista' },
    { id: 'Torres Causana', name: 'Torres Causana' }
  ],
  Putumayo: [
    { id: 'Putumayo', name: 'Putumayo' },
    { id: 'Rosa Panduro', name: 'Rosa Panduro' },
    { id: 'Teniente Manuel Clavero', name: 'Teniente Manuel Clavero' },
    { id: 'Yaguas', name: 'Yaguas' }
  ],
  Requena: [
    { id: 'Alto Tapiche', name: 'Alto Tapiche' },
    { id: 'Capelo', name: 'Capelo' },
    { id: 'Emilio San Martín', name: 'Emilio San Martín' },
    { id: 'Jenaro Herrera', name: 'Jenaro Herrera' },
    { id: 'Maquia', name: 'Maquia' },
    { id: 'Puinahua', name: 'Puinahua' },
    { id: 'Requena', name: 'Requena' },
    { id: 'Saquena', name: 'Saquena' },
    { id: 'Soplin', name: 'Soplin' },
    { id: 'Tapiche', name: 'Tapiche' },
    { id: 'Yaquerana', name: 'Yaquerana' }
  ],
  Ucayali: [
    { id: 'Contamana', name: 'Contamana' },
    { id: 'Inahuaya', name: 'Inahuaya' },
    { id: 'Padre Márquez', name: 'Padre Márquez' },
    { id: 'Pampa Hermosa', name: 'Pampa Hermosa' },
    { id: 'Sarayacu', name: 'Sarayacu' },
    { id: 'Vargas Guerra', name: 'Vargas Guerra' }
  ],
  Tahuamanu: [
    { id: 'Iñapari', name: 'Iñapari' },
    { id: 'Iberia', name: 'Iberia' },
    { id: 'Tahuamanu', name: 'Tahuamanu' }
  ],
  Manu: [
    { id: 'Fitzcarrald', name: 'Fitzcarrald' },
    { id: 'Huepetuhe', name: 'Huepetuhe' },
    { id: 'Madre de Dios', name: 'Madre de Dios' },
    { id: 'Manu', name: 'Manu' }
  ],
  Tambopata: [
    { id: 'Inambari', name: 'Inambari' },
    { id: 'Las Piedras', name: 'Las Piedras' },
    { id: 'Laberinto', name: 'Laberinto' },
    { id: 'Tambopata', name: 'Tambopata' }
  ],
  'General Sánchez Cerro': [
    { id: 'Chojata', name: 'Chojata' },
    { id: 'Coalaque', name: 'Coalaque' },
    { id: 'Ichuña', name: 'Ichuña' },
    { id: 'La Capilla', name: 'La Capilla' },
    { id: 'Lloque', name: 'Lloque' },
    { id: 'Matalaque', name: 'Matalaque' },
    { id: 'Omate', name: 'Omate' },
    { id: 'Puquina', name: 'Puquina' },
    { id: 'Quinistaquillas', name: 'Quinistaquillas' },
    { id: 'Ubinas', name: 'Ubinas' },
    { id: 'Yunga', name: 'Yunga' }
  ],
  Ilo: [
    { id: 'El Algarrobal', name: 'El Algarrobal' },
    { id: 'Ilo', name: 'Ilo' },
    { id: 'Pacocha', name: 'Pacocha' }
  ],
  'Mariscal Nieto': [
    { id: 'Carumas', name: 'Carumas' },
    { id: 'Cuchumbaya', name: 'Cuchumbaya' },
    { id: 'Moquegua', name: 'Moquegua' },
    { id: 'Samegua', name: 'Samegua' },
    { id: 'San Cristóbal', name: 'San Cristóbal' },
    { id: 'Torata', name: 'Torata' }
  ],
  'Daniel Alcides Carrión': [
    { id: 'Chacayan', name: 'Chacayan' },
    { id: 'Goyllarisquizga', name: 'Goyllarisquizga' },
    { id: 'Paucar', name: 'Paucar' },
    { id: 'San Pedro de Pillao', name: 'San Pedro de Pillao' },
    { id: 'Santa Ana de Tusi', name: 'Santa Ana de Tusi' },
    { id: 'Tapuc', name: 'Tapuc' },
    { id: 'Vilcabamba', name: 'Vilcabamba' },
    { id: 'Yanahuanca', name: 'Yanahuanca' }
  ],
  Pasco: [
    { id: 'Chaupimarca', name: 'Chaupimarca' },
    { id: 'Huachon', name: 'Huachon' },
    { id: 'Huariaca', name: 'Huariaca' },
    { id: 'Huayllay', name: 'Huayllay' },
    { id: 'Ninacaca', name: 'Ninacaca' },
    { id: 'Pallanchacra', name: 'Pallanchacra' },
    { id: 'Paucartambo', name: 'Paucartambo' },
    {
      id: 'San Francisco de Asís de Yarusyacan',
      name: 'San Francisco de Asís de Yarusyacan'
    },
    { id: 'Simon Bolívar', name: 'Simon Bolívar' },
    { id: 'Ticlacayan', name: 'Ticlacayan' },
    { id: 'Tinyahuarco', name: 'Tinyahuarco' },
    { id: 'Vicco', name: 'Vicco' },
    { id: 'Yanacancha', name: 'Yanacancha' }
  ],
  Oxapampa: [
    { id: 'Chontabamba', name: 'Chontabamba' },
    { id: 'Constitución ', name: 'Constitución ' },
    { id: 'Huancabamba', name: 'Huancabamba' },
    { id: 'Oxapampa', name: 'Oxapampa' },
    { id: 'Palcazu', name: 'Palcazu' },
    { id: 'Pozuzo', name: 'Pozuzo' },
    { id: 'Puerto Bermúdez', name: 'Puerto Bermúdez' },
    { id: 'Villa Rica', name: 'Villa Rica' }
  ],
  Ayabaca: [
    { id: 'Ayabaca', name: 'Castilla' },
    { id: 'Frias', name: 'Frias' },
    { id: 'Jilili', name: 'Jilili' },
    { id: 'Lagunas', name: 'Lagunas' },
    { id: 'Montero', name: 'Montero' },
    { id: 'Pacaipampa', name: 'Pacaipampa' },
    { id: 'Paimas', name: 'Paimas' },
    { id: 'Sapillica', name: 'Sapillica' },
    { id: 'Sicchez', name: 'Sicchez' },
    { id: 'Suyo', name: 'Suyo' }
  ],
  Huancabamba: [
    { id: 'Canchaque', name: 'Canchaque' },
    { id: 'El Carmen de la Frontera', name: 'El Carmen de la Frontera' },
    { id: 'Huarmaca', name: 'Huarmaca' },
    { id: 'Huancabamba', name: 'Huancabamba' },
    { id: 'Lalaquiz', name: 'CastiLalaquizlla' },
    { id: 'San Miguel de El Faique', name: 'San Miguel de El Faique' },
    { id: 'Sondor', name: 'Sondor' },
    { id: 'Sondorillo', name: 'Sondorillo' }
  ],
  Morropón: [
    { id: 'Buenos Aires', name: 'Buenos Aires' },
    { id: 'Chalaco', name: 'Chalaco' },
    { id: 'Chulucanas', name: 'Chulucanas' },
    { id: 'La Matanza', name: 'La Matanza' },
    { id: 'Morropon', name: 'Morropon' },
    { id: 'Salitral', name: 'Salitral' },
    { id: 'San Juan de Bigote', name: 'San Juan de Bigote' },
    { id: 'Santa Catalina de Mossa', name: 'Santa Catalina de Mossa' },
    { id: 'Santo Domingo', name: 'Santo Domingo' },
    { id: 'Yamango', name: 'Yamango' }
  ],
  Piura: [
    { id: 'Castilla', name: 'Castilla' },
    { id: 'Catacaos', name: 'Catacaos' },
    { id: 'Cura Mori', name: 'Cura Mori' },
    { id: 'El Tallan', name: 'El Tallan' },
    { id: 'La Arena', name: 'La Arena' },
    { id: 'La Unión', name: 'La Unión' },
    { id: 'Las Lomas', name: 'Las Lomas' },
    { id: 'Piura', name: 'Piura' },
    { id: 'Tambo Grande', name: 'Tambo Grande' },
    { id: 'Veintiseis de Octubre', name: 'Veintiseis de Octubre' }
  ],
  Paita: [
    { id: 'Amotape', name: 'Amotape' },
    { id: 'Arenal', name: 'Arenal' },
    { id: 'Colan', name: 'Colan' },
    { id: 'La Huaca', name: 'La Huaca' },
    { id: 'Paita', name: 'Paita' },
    { id: 'Tamarindo', name: 'Tamarindo' },
    { id: 'Vichayal', name: 'Vichayal' }
  ],
  Sullana: [
    { id: 'Bellavista', name: 'Bellavista' },
    { id: 'Ignacio Escudero', name: 'Ignacio Escudero' },
    { id: 'Lancones', name: 'Lancones' },
    { id: 'Marcavelica', name: 'Marcavelica' },
    { id: 'Miguel Checa', name: 'Miguel Checa' },
    { id: 'Querecotillo', name: 'Querecotillo' },
    { id: 'Salitral', name: 'Salitral' },
    { id: 'Sullana', name: 'Sullana' }
  ],
  Talara: [
    { id: 'El Alto', name: 'El Alto' },
    { id: 'La Brea', name: 'La Brea' },
    { id: 'Lobitos', name: 'Lobitos' },
    { id: 'Los Organos', name: 'Los Organos' },
    { id: 'Mancora', name: 'Mancora' },
    { id: 'Pariñas', name: 'Pariñas' }
  ],
  Sechura: [
    { id: 'Bellavista de la Unión', name: 'Bellavista de la Unión' },
    { id: 'Bernal', name: 'Bernal' },
    { id: 'Cristo Nos Valga', name: 'Cristo Nos Valga' },
    { id: 'Rinconada Llicuar', name: 'Rinconada Llicuar' },
    { id: 'Sechura', name: 'Sechura' },
    { id: 'Vice', name: 'Vice' }
  ],
  Yunguyo: [
    { id: 'Anapia', name: 'Anapia' },
    { id: 'Copani', name: 'Copani' },
    { id: 'Cuturapi', name: 'Cuturapi' },
    { id: 'Ollaraya', name: 'Ollaraya' },
    { id: 'Tinicachi', name: 'Tinicachi' },
    { id: 'Unicachi', name: 'Unicachi' },
    { id: 'Yunguyo', name: 'Yunguyo' }
  ],
  Sandia: [
    { id: 'Alto Inambari', name: 'Alto Inambari' },
    { id: 'Cuyocuyo', name: 'Cuyocuyo' },
    { id: 'Limbani', name: 'Limbani' },
    { id: 'Patambuco', name: 'Patambuco' },
    { id: 'Phara', name: 'Phara' },
    { id: 'Quiaca', name: 'Quiaca' },
    { id: 'Sandia', name: 'Sandia' },
    { id: 'San Juan del Oro', name: 'San Juan del Oro' },
    { id: 'San Pedro de Putina Punco', name: 'San Pedro de Putina Punco' },
    { id: 'Yanahuaya', name: 'Yanahuaya' }
  ],
  'San Román': [
    { id: 'Cabana', name: 'Cabana' },
    { id: 'Cabanillas', name: 'Cabanillas' },
    { id: 'Caracoto', name: 'Caracoto' },
    { id: 'Juliaca', name: 'Juliaca' },
    { id: 'San Miguel', name: 'San Miguel' }
  ],
  'San Antonio de Putina': [
    { id: 'Ananea', name: 'Ananea' },
    { id: 'Pedro Vilca Apaza', name: 'Pedro Vilca Apaza' },
    { id: 'Putina', name: 'Putina' },
    { id: 'Quilcapuncu', name: 'Quilcapuncu' },
    { id: 'Sina', name: 'Sina' }
  ],
  Puno: [
    { id: 'Acora', name: 'Acora' },
    { id: 'Amantani', name: 'Amantani' },
    { id: 'Atuncolla', name: 'Atuncolla' },
    { id: 'Capachica', name: 'Capachica' },
    { id: 'Chucuito', name: 'Chucuito' },
    { id: 'Coata', name: 'Coata' },
    { id: 'Huata', name: 'Huata' },
    { id: 'Mañazo', name: 'Mañazo' },
    { id: 'Paucarcolla', name: 'Paucarcolla' },
    { id: 'Pichacani', name: 'Pichacani' },
    { id: 'Plateria', name: 'Plateria' },
    { id: 'Puno', name: 'Puno' },
    { id: 'San Antonio', name: 'San Antonio' },
    { id: 'Tiquillaca', name: 'Tiquillaca' },
    { id: 'Vilque', name: 'Vilque' }
  ],
  Moho: [
    { id: 'Conima', name: 'Conima' },
    { id: 'Huayrapata', name: 'Huayrapata' },
    { id: 'Moho', name: 'Moho' },
    { id: 'Tilali', name: 'Tilali' }
  ],
  Melgar: [
    { id: 'Ayaviri', name: 'Ayaviri' },
    { id: 'Antauta', name: 'Antauta' },
    { id: 'Cupi', name: 'Cupi' },
    { id: 'Llalli', name: 'Llalli' },
    { id: 'Macari', name: 'Macari' },
    { id: 'Nuñoa', name: 'Nuñoa' },
    { id: 'Orurillo', name: 'Orurillo' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Umachiri', name: 'Umachiri' }
  ],
  Lampa: [
    { id: 'Cabanilla', name: 'Cabanilla' },
    { id: 'Calapuja', name: 'Calapuja' },
    { id: 'Lampa', name: 'Lampa' },
    { id: 'Nicasio', name: 'Nicasio' },
    { id: 'Ocuviri', name: 'Ocuviri' },
    { id: 'Palca', name: 'Palca' },
    { id: 'Paratia', name: 'Paratia' },
    { id: 'Pucara', name: 'Pucara' },
    { id: 'Santa Lucia', name: 'Santa Lucia' },
    { id: 'Vilavila', name: 'Vilavila' }
  ],
  Huancané: [
    { id: 'Cojata', name: 'Cojata' },
    { id: 'Huatasani', name: 'Huatasani' },
    { id: 'Huancane', name: 'Huancane' },
    { id: 'Inchupalla', name: 'Inchupalla' },
    { id: 'Pusi', name: 'Pusi' },
    { id: 'Rosaspata', name: 'Rosaspata' },
    { id: 'Taraco', name: 'Taraco' },
    { id: 'Vilque Chico', name: 'Vilque Chico' }
  ],
  'El Collao': [
    { id: 'Capazo', name: 'Capazo' },
    { id: 'Conduriri', name: 'Conduriri' },
    { id: 'Ilave', name: 'Ilave' },
    { id: 'Pilcuyo', name: 'Pilcuyo' },
    { id: 'Santa Rosa', name: 'Santa Rosa' }
  ],
  Chucuito: [
    { id: 'Desaguadero', name: 'Desaguadero' },
    { id: 'Huacullani', name: 'Huacullani' },
    { id: 'Juli', name: 'Juli' },
    { id: 'Kelluyo', name: 'Kelluyo' },
    { id: 'Pisacoma', name: 'Pisacoma' },
    { id: 'Pomata', name: 'Pomata' },
    { id: 'Zepita', name: 'Zepita' }
  ],
  Carabaya: [
    { id: 'Ajoyani', name: 'Ajoyani' },
    { id: 'Ayapata', name: 'Ayapata' },
    { id: 'Coasa', name: 'Coasa' },
    { id: 'Corani', name: 'Corani' },
    { id: 'Crucero', name: 'Crucero' },
    { id: 'Ituata', name: 'Ituata' },
    { id: 'Macusani', name: 'Macusani' },
    { id: 'Ollachea', name: 'Ollachea' },
    { id: 'San Gaban', name: 'San Gaban' },
    { id: 'Usicayos', name: 'Usicayos' }
  ],
  Azángaro: [
    { id: 'Azángaro', name: 'Azángaro' },
    { id: 'Achaya', name: 'Achaya' },
    { id: 'Arapa', name: 'Arapa' },
    { id: 'Asillo', name: 'Asillo' },
    { id: 'Caminaca', name: 'Caminaca' },
    { id: 'Chupa', name: 'Chupa' },
    { id: 'José Domingo Choquehuanca', name: 'José Domingo Choquehuanca' },
    { id: 'Muñani', name: 'Muñani' },
    { id: 'Potoni', name: 'Potoni' },
    { id: 'Saman', name: 'Saman' },
    { id: 'San Anton', name: 'San Anton' },
    { id: 'San José', name: 'San José' },
    { id: 'San Juan de Salinas', name: 'San Juan de Salinas' },
    { id: 'Santiago de Pupuja', name: 'Santiago de Pupuja' },
    { id: 'Tirapata', name: 'Tirapata' }
  ],
  Moyobamba: [
    { id: 'Calzada', name: 'Calzada' },
    { id: 'Habana', name: 'Habana' },
    { id: 'Jepelacio', name: 'Jepelacio' },
    { id: 'Moyobamba', name: 'Moyobamba' },
    { id: 'Soritor', name: 'Soritor' },
    { id: 'Yantalo', name: 'Yantalo' }
  ],
  Bellavista: [
    { id: 'Alto Biavo', name: 'Alto Biavo' },
    { id: 'Bajo Biavo', name: 'Bajo Biavo' },
    { id: 'Bellavista', name: 'Bellavista' },
    { id: 'Huallaga', name: 'Huallaga' },
    { id: 'San Pablo', name: 'San Pablo' },
    { id: 'San Rafael', name: 'San Rafael' }
  ],
  'El Dorado': [
    { id: 'Agua Blanca', name: 'Agua Blanca' },
    { id: 'San Martín', name: 'San Martín' },
    { id: 'San José de Sisa', name: 'San José de Sisa' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Shatoja', name: 'Shatoja' }
  ],
  Huallaga: [
    { id: 'Alto Saposoa', name: 'Alto Saposoa' },
    { id: 'El Eslabón', name: 'El Eslabón' },
    { id: 'Piscoyacu', name: 'Piscoyacu' },
    { id: 'Saposoa', name: 'Saposoa' },
    { id: 'Sacanche', name: 'Sacanche' },
    { id: 'Tingo de Saposoa', name: 'Tingo de Saposoa' }
  ],
  Lamas: [
    { id: 'Alonso de Alvarado', name: 'Alonso de Alvarado' },
    { id: 'Barranquita', name: 'Barranquita' },
    { id: 'Caynarachi', name: 'Caynarachi' },
    { id: 'Cuñumbuqui', name: 'Cuñumbuqui' },
    { id: 'Lamas', name: 'Lamas' },
    { id: 'Pinto Recodo', name: 'Pinto Recodo' },
    { id: 'Rumisapa', name: 'Rumisapa' },
    { id: 'San Roque de Cumbaza', name: 'San Roque de Cumbaza' },
    { id: 'Shanao', name: 'Shanao' },
    { id: 'Tabalosos', name: 'Tabalosos' },
    { id: 'Zapatero', name: 'Zapatero' }
  ],
  'Mariscal Cáceres': [
    { id: 'Campanilla', name: 'Campanilla' },
    { id: 'Juanjuí', name: 'Juanjuí' },
    { id: 'Huicungo', name: 'Huicungo' },
    { id: 'Pachiza', name: 'Pachiza' },
    { id: 'Pajarillo', name: 'Pajarillo' }
  ],
  Picota: [
    { id: 'Buenos Aires', name: 'Buenos Aires' },
    { id: 'Caspisapa', name: 'Caspisapa' },
    { id: 'Pilluana', name: 'Pilluana' },
    { id: 'Picota', name: 'Picota' },
    { id: 'Pucacaca', name: 'Pucacaca' },
    { id: 'San Cristóbal', name: 'San Cristóbal' },
    { id: 'San Hilarión', name: 'San Hilarión' },
    { id: 'Shamboyacu', name: 'Shamboyacu' },
    { id: 'Tingo de Ponasa', name: 'Tingo de Ponasa' },
    { id: 'Tres Unidos', name: 'Tres Unidos' }
  ],
  Rioja: [
    { id: 'Awajun', name: 'Awajun' },
    { id: 'Elías Soplin Vargas', name: 'Elías Soplin Vargas' },
    { id: 'Nueva Cajamarca', name: 'Nueva Cajamarca' },
    { id: 'Pardo Miguel', name: 'Pardo Miguel' },
    { id: 'Posic', name: 'Posic' },
    { id: 'Rioja', name: 'Rioja' },
    { id: 'San Fernando', name: 'San Fernando' },
    { id: 'Yorongos', name: 'Yorongos' },
    { id: 'Yuracyacu', name: 'Yuracyacu' }
  ],
  'San Martín': [
    { id: 'Alberto Leveau', name: 'Alberto Leveau' },
    { id: 'Cacatachi', name: 'Cacatachi' },
    { id: 'Chazuta', name: 'Chazuta' },
    { id: 'Chipurana', name: 'Chipurana' },
    { id: 'El Porvenir', name: 'El Porvenir' },
    { id: 'Huimbayoc', name: 'Huimbayoc' },
    { id: 'Juan Guerra', name: 'Juan Guerra' },
    { id: 'La Banda de Shilcayo', name: 'La Banda de Shilcayo' },
    { id: 'Morales', name: 'Morales' },
    { id: 'Papaplaya', name: 'Papaplaya' },
    { id: 'San Antonio', name: 'San Antonio' },
    { id: 'Sauce', name: 'Sauce' },
    { id: 'Shapaja', name: 'Shapaja' },
    { id: 'Tarapoto', name: 'Tarapoto' }
  ],
  Tocache: [
    { id: 'Nuevo Progreso', name: 'Nuevo Progreso' },
    { id: 'Polvora', name: 'Polvora' },
    { id: 'Shunte', name: 'Shunte' },
    { id: 'Tocache', name: 'Tocache' },
    { id: 'Uchiza', name: 'Uchiza' }
  ],
  Candarave: [
    { id: 'Candarave', name: 'Candarave' },
    { id: 'Cairani', name: 'Cairani' },
    { id: 'Camilaca', name: 'Camilaca' },
    { id: 'Curibaya', name: 'Curibaya' },
    { id: 'Huanuara', name: 'Huanuara' },
    { id: 'Quilahuani', name: 'Quilahuani' }
  ],
  'Jorge Basadre': [
    { id: 'Ilabaya', name: 'Ilabaya' },
    { id: 'Ite', name: 'Ite' },
    { id: 'Locumba', name: 'Locumba' }
  ],
  Tacna: [
    { id: 'Alto de la Alianza', name: 'Alto de la Alianza' },
    { id: 'Calana', name: 'Calana' },
    { id: 'Ciudad Nueva', name: 'Ciudad Nueva' },
    {
      id: 'Coronel Gregorio Albarracín Lanchipa',
      name: 'Coronel Gregorio Albarracín Lanchipa'
    },
    { id: 'Inclan', name: 'Inclan' },
    { id: 'Pachia', name: 'Pachia' },
    { id: 'Palca', name: 'Palca' },
    { id: 'Pocollay', name: 'Pocollay' },
    { id: 'Sama', name: 'Sama' },
    { id: 'Tacna', name: 'Tacna' },
    { id: 'La Yarada los Palos', name: 'La Yarada los Palos' }
  ],
  Tarata: [
    { id: 'Héroes Albarracín', name: 'Héroes Albarracín' },
    { id: 'Estique', name: 'Estique' },
    { id: 'Estique-Pampa', name: 'Estique-Pampa' },
    { id: 'Sitajara', name: 'Sitajara' },
    { id: 'Susapaya', name: 'Susapaya' },
    { id: 'Tarata', name: 'Tarata' },
    { id: 'Tarucachi', name: 'Tarucachi' },
    { id: 'Ticaco', name: 'Ticaco' }
  ],
  'Contralmirante Villa': [
    { id: 'Casitas', name: 'Casitas' },
    { id: 'Canoas de Punta Sal', name: 'Canoas de Punta Sal' },
    { id: 'Zorritos', name: 'Zorritos' }
  ],
  Tumbes: [
    { id: 'Corrales', name: 'Corrales' },
    { id: 'La Cruz', name: 'La Cruz' },
    { id: 'Pampas de Hospital', name: 'Pampas de Hospital' },
    { id: 'San Jacinto', name: 'San Jacinto' },
    { id: 'San Juan de la Virgen', name: 'San Juan de la Virgen' },
    { id: 'Tumbes', name: 'Tumbes' }
  ],
  Zarumilla: [
    { id: 'Aguas Verdes', name: 'Aguas Verdes' },
    { id: 'Matapalo', name: 'Matapalo' },
    { id: 'Papayal', name: 'Papayal' },
    { id: 'Zarumilla', name: 'Zarumilla' }
  ],
  Atalaya: [
    { id: 'Raymondi', name: 'Raymondi' },
    { id: 'Sepahua', name: 'Sepahua' },
    { id: 'Tahuania', name: 'Tahuania' },
    { id: 'Yurua', name: 'Yurua' }
  ],
  'Coronel Portillo': [
    { id: 'Calleria', name: 'Calleria' },
    { id: 'Campoverde', name: 'Campoverde' },
    { id: 'Iparia', name: 'Iparia' },
    { id: 'Masisea', name: 'Masisea' },
    { id: 'Manantay', name: 'Manantay' },
    { id: 'Nueva Requena', name: 'Nueva Requena' },
    { id: 'Yarinacocha', name: 'Yarinacocha' }
  ],
  'Padre Abad': [
    { id: 'Alexander Von Humboldt', name: 'Alexander Von Humboldt' },
    { id: 'Curimana', name: 'Curimana' },
    { id: 'Irazola', name: 'Irazola' },
    { id: 'Neshuya', name: 'Neshuya' },
    { id: 'Padre Abad', name: 'Padre Abad' }
  ],
  Purús: [{ id: 'Purús', name: 'Purús' }]
};
