import React from 'react';
import PropTypes from 'prop-types';
import ApexChartBuilder from 'apex-chart-react';
import { BehaviorSubject } from 'rxjs';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';

import 'apex-chart-react/lib/chart-style.css';
import 'apex-web/lib/styles/components/TradeComponent.css';

const tradeComponentClasses = getBEMClasses('trade-component');
const priceChartComponentClasses = getBEMClasses('price-chart');

class PriceChartComponent extends React.Component {
  renderChart() {
    const { chartData, changeTickersInterval, locale } = this.props;
    this.chartDataSubject = new BehaviorSubject(chartData);
    const chartConfig = {
      chartDataSubject: this.chartDataSubject,
      changeTickersInterval: changeTickersInterval,
      currency_character: '',
      locale: locale,
      time_intervals: this.getTimeIntervalsConfig(),
      price_history: this.getPriceHistoryConfig(),
      volume_history: this.getVolumeHistoryConfig(),
      range_selector: this.getRangeSelectorConfig(),
      date_format_months: [
        this.context.t('Jan', {}, 'January'),
        this.context.t('Feb', {}, 'February'),
        this.context.t('Mar', {}, 'March'),
        this.context.t('Apr', {}, 'April'),
        this.context.t('May', {}, 'May (the month)'),
        this.context.t('Jun', {}, 'June'),
        this.context.t('Jul', {}, 'July'),
        this.context.t('Aug', {}, 'August'),
        this.context.t('Sep', {}, 'September'),
        this.context.t('Oct', {}, 'October'),
        this.context.t('Nov', {}, 'November'),
        this.context.t('Dec', {}, 'December')
      ]
    };
    this.chart = new ApexChartBuilder('apex-chart', chartConfig);
    this.chart.DrawChart();
  }

  getTimeIntervalsConfig() {
    return [
      {
        label: '5m',
        seconds: 300
      },
      {
        label: '15m',
        seconds: 900
      },
      {
        label: '30m',
        seconds: 1800
      },
      {
        label: '1h',
        seconds: 3600
      },
      {
        label: '1d',
        seconds: 86400
      }
    ];
  }

  getPriceHistoryConfig() {
    return {
      font_size: 12,
      font_face: this.cssVariable('--font__typeface'),
      border_primary_color: this.cssVariable('--chart__border-color-primary'),
      border_secondary_color: this.cssVariable(
        '--chart__border-color-secondary'
      ),
      background_color: this.cssVariable('--secondary__bg-color'),
      bullish_color: this.cssVariable('--chart__bullish-color'),
      bearish_color: this.cssVariable('--chart__bearish-color'),
      price_line_color: this.cssVariable('--chart__price-line-color'),
      sma_line_color: this.cssVariable('--chart__sma-line'),
      ema_line_color: this.cssVariable('--chart__ema-line-color'),
      bollinger_band_upper_line_color: this.cssVariable(
        '--chart__bollinger-band-upper-color'
      ),
      bollinger_band_lower_line_color: this.cssVariable(
        '--chart__bollinger-band-lower-color'
      ),
      market_price_line_color: this.cssVariable(
        '--chart__market-price-line-color'
      ),
      market_price_label_color: this.cssVariable(
        '--chart__market-price-label-color'
      ),
      market_price_background_color: this.cssVariable(
        '--chart__market-price-bg-color'
      ),
      x_label_color: this.cssVariable('--font__color-secondary'),
      y_label_color: this.cssVariable('--font__color-secondary'),
      info_height: 30,
      info_precision: this.props.precision,
      y_label_width: 30,
      y_label_precision: this.props.precision,
      x_label_height: 30,
      x_label_precision: this.props.precision,
      info_labels: [
        this.context.t('Price'),
        this.context.t('Open'),
        this.context.t('Close'),
        this.context.t('High'),
        this.context.t('Low'),
        this.context.t('Volume')
      ],
      use_bg_grid_lines: true,
      grid_lines_dashed: false,
      grid_lines_color: this.cssVariable('--table__row-separator-color')
    };
  }

  getVolumeHistoryConfig() {
    return {
      font_size: 12,
      font_face: this.cssVariable('--font__typeface'),
      border_primary_color: this.cssVariable('--chart__border-color-primary'),
      border_secondary_color: this.cssVariable('--chart__row-separator-color'),
      background_color: this.cssVariable('--secondary__bg-color'),
      bullish_color: this.cssVariable('--chart__volume-bullish-color'),
      bearish_color: this.cssVariable('--chart__volume-bearish-color'),
      x_label_color: this.cssVariable('--font__color-secondary'),
      y_label_color: this.cssVariable('--font__color-secondary'),
      y_label_width: 30,
      y_label_precision: this.props.precision,
      x_label_height: 2,
      x_label_precision: this.props.precision
    };
  }

  getRangeSelectorConfig() {
    return {
      font_size: 12,
      font_face: this.cssVariable('--font__typeface'),
      border_primary_color: this.cssVariable('--chart__border-color-primary'),
      border_secondary_color: this.cssVariable('--chart__row-separator-color'),
      background_color: this.cssVariable('--secondary__bg-color'),
      market_price_line_color: this.cssVariable('--font__typeface'),
      market_price_label_color: this.cssVariable('--font__typeface'),
      price_line_color: this.cssVariable('--chart__price-line-color'),
      range_selection_color: this.cssVariable('--chart__range-selection-color'),
      x_label_color: this.cssVariable('--font__color-secondary'),
      y_label_color: this.cssVariable('--font__color-secondary'),
      y_label_width: 0,
      y_label_precision: this.props.precision,
      x_label_height: 22,
      x_label_precision: this.props.precision
    };
  }

  componentDidMount() {
    this.cssVariable = getCSSVar();
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chartData !== this.props.chartData) {
      this.chartDataSubject.next(this.props.chartData);
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.DestroyChart();
      this.chart = null;
    }
  }

  render() {
    return (
      <div
        className={`${tradeComponentClasses()} ${priceChartComponentClasses()}`}>
        <div
          id="apex-chart"
          className={`${priceChartComponentClasses('chart')}`}
        />
      </div>
    );
  }
}

PriceChartComponent.propTypes = {
  precision: PropTypes.number
};

PriceChartComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PriceChartComponent;
