//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';
import empty from 'is-empty';

export const getCountries = async userId => {
  let path = '/api/countries';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getBankCountries = async userId => {
  let path = '/api/bank/countries';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getBanksByCountry = async (userId, country_code) => {
  let path = `/api/v2/banks/all/${country_code}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getSwiftBanks = async (countryCode, userId) => {
  let path = `/api/swiftbanks/?country_code=${countryCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getFiatAccounts = userId => {
  let path = `/api/fiat-accounts/`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const enableFiatAccount = async (id, userId) => {
  let path = `/api/enable-fiat-accounts/?id=${id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const disabledFiatAccount = async (id, userId, accountId) => {
  let path = `/api/v2/bankaccount/${accountId}/${id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'delete',
    path
  });
};

export const getAllFiatAccountsV2 = (userId, accountId, filter) => {
  let path = `/api/v2/bankaccount/all/${accountId}`;
  if (!empty(filter)) {
    let params = new URLSearchParams(filter).toString();
    path = `${path}?${params}`;
  }

  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getFiatAccountByIdV2 = (userId, accountId, id) => {
  let path = `/api/v2/bankaccount/${accountId}/${id}/`;

  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let path = '/api/save-fiat-accounts';

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const createAccountPE = async (userId, data) => {
  let path = '/api/v2/bankaccount/peru/add';

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const updateAccountPE = async (userId, data, id) => {
  let path = `/api/v2/bankaccount/peru/edit/${id}`;

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const updateAliasAccount = async (bankId, userId, accountId, data) => {
  const path = `/api/v2/bankaccount/edit/${accountId}/${bankId}`;

  return HandlerBackend.sendRequest({
    userId,
    method: 'put',
    path,
    data: data
  });
};

export const createAccountUS = async (userId, data, accountId) => {
  let path = '/api/v2/bankaccount/us/add';

  let inputs = {
    banexcoin_account_id: accountId,
    alias: data.alias,
    bank_id: data.bankId,
    account_number: data.accountNumber,
    bank_address: data.bankAddress,
    aba_routing_number: data.abaRoutingNumber,
    swift_bic_code: data.swiftBicCode,
    transfer_type: data.transferType,
    full_name: data.fullName,
    beneficiary_address: data.beneficiaryAddress,
    beneficiary_country: data.beneficiaryCountry,
    beneficiary_city: data.beneficiaryCity,
    beneficiary_state: data.beneficiaryState,
    beneficiary_zip: data.beneficiaryZip,
    beneficiary_reference: data.beneficiaryReference,
    intermediary_bank_id: data.intermediaryBankId,
    intermediary_bank_country: data.intermediaryBankCountry,
    intermediary_bank_address: data.intermediaryBankAddress,
    intermediary_bank_city: data.intermediaryBankCity,
    intermediary_bank_state: data.intermediaryBankState,
    intermediary_bank_zip: data.intermediaryBankZip,
    intermediary_swift_bic_code: data.intermediarySwiftBicCode,
    intermediary_aba_routing_number: data.intermediaryAbaRoutingNumber,
    intermediary_reference: data.intermediaryReference,
    own_account_confirmation: data.ownAccountConfirmation
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: inputs
  });
};

export const updateAccountUS = async (userId, data, accountId, id) => {
  let path = `/api/v2/bankaccount/us/edit/${id}`;

  let inputs = {
    banexcoin_account_id: accountId,
    alias: data.alias,
    bank_id: data.bankId,
    account_number: data.accountNumber,
    bank_address: data.bankAddress,
    aba_routing_number: data.abaRoutingNumber,
    swift_bic_code: data.swiftBicCode,
    transfer_type: data.transferType,
    full_name: data.fullName,
    beneficiary_address: data.beneficiaryAddress,
    beneficiary_country: data.beneficiaryCountry,
    beneficiary_city: data.beneficiaryCity,
    beneficiary_state: data.beneficiaryState,
    beneficiary_zip: data.beneficiaryZip,
    beneficiary_reference: data.beneficiaryReference,
    intermediary_bank_id: data.intermediaryBankId,
    intermediary_bank_country: data.intermediaryBankCountry,
    intermediary_bank_address: data.intermediaryBankAddress,
    intermediary_bank_city: data.intermediaryBankCity,
    intermediary_bank_state: data.intermediaryBankState,
    intermediary_bank_zip: data.intermediaryBankZip,
    intermediary_swift_bic_code: data.intermediarySwiftBicCode,
    intermediary_aba_routing_number: data.intermediaryAbaRoutingNumber,
    intermediary_reference: data.intermediaryReference,
    own_account_confirmation: data.ownAccountConfirmation
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: inputs
  });
};

export const createAccountInternational = async (userId, data, accountId) => {
  let path = '/api/v2/bankaccount/international/add';

  let inputs = {
    aba_routing_number:data.abaRoutingNumber,
    banexcoin_account_id: accountId,
    alias: data.alias,
    currency_code: data.currencyCode,
    country_code: data.countryCode,
    bank_id: data.bankId,
    account_number: data.accountNumber,
    bank_address: data.bankAddress,
    swift_bic_code: data.swiftBicCode,
    full_name: data.fullName,
    beneficiary_address: data.beneficiaryAddress,
    beneficiary_country: data.beneficiaryCountry,
    beneficiary_city: data.beneficiaryCity,
    beneficiary_zip: data.beneficiaryZip,
    transfer_type: data.transferType,
    intermediary_bank_id: data.intermediaryBankId,
    intermediary_bank_country: data.intermediaryBankCountry,
    intermediary_bank_address: data.intermediaryBankAddress,
    intermediary_bank_city: data.intermediaryBankCity,
    intermediary_bank_state: data.intermediaryBankState,
    intermediary_bank_zip: data.intermediaryBankZip,
    intermediary_swift_bic_code: data.intermediarySwiftBicCode,
    intermediary_aba_routing_number: data.intermediaryAbaRoutingNumber,
    intermediary_reference: data.intermediaryReference,
    own_account_confirmation: data.ownAccountConfirmation
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: inputs
  });
};

export const editAccountInternational = async (userId, data, accountId, id) => {
  let path = `/api/v2/bankaccount/international/edit/${id}`;

  let inputs = {
    banexcoin_account_id: accountId,
    alias: data.alias,
    currency_code: data.currencyCode,
    country_code: data.countryCode,
    bank_id: data.bankId,
    account_number: data.accountNumber,
    bank_address: data.bankAddress,
    swift_bic_code: data.swiftBicCode,
    full_name: data.fullName,
    beneficiary_address: data.beneficiaryAddress,
    beneficiary_country: data.beneficiaryCountry,
    beneficiary_city: data.beneficiaryCity,
    beneficiary_zip: data.beneficiaryZip,
    transfer_type: data.transferType,
    intermediary_bank_id: data.intermediaryBankId,
    intermediary_bank_country: data.intermediaryBankCountry,
    intermediary_bank_address: data.intermediaryBankAddress,
    intermediary_bank_city: data.intermediaryBankCity,
    intermediary_bank_state: data.intermediaryBankState,
    intermediary_bank_zip: data.intermediaryBankZip,
    intermediary_swift_bic_code: data.intermediarySwiftBicCode,
    intermediary_aba_routing_number: data.intermediaryAbaRoutingNumber,
    intermediary_reference: data.intermediaryReference,
    own_account_confirmation: data.ownAccountConfirmation
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: inputs
  });
};

export const getInstrumentsByAccountId = async ({UserId, accountId}) => {
  try {
    const path = `/api/v1/globalconfig/${accountId}`
    return await HandlerBackend.sendRequest({
      userId: UserId,
      method: 'GET',
      path,
    });    
  } catch (error) {
    return {}
  }
}

export const getAllLimits = async ({UserId}) => {
  try {
    const path = `/api/limits/all`
    return await HandlerBackend.sendRequest({
      userId: UserId,
      method: 'GET',
      path,
    });    
  } catch (error) {
    return {}
  }
}

export const setCookieManagement = async ( { UserId, userBanexcoinId, cookieId} ) => {
  try {
    const path = `/api/v1/cookie-management/assigned`;
    return await HandlerBackend.sendRequest({
      userId: UserId,
      method: "PATCH",
      path,
      data: {
        userBanexcoinId,
        cookieId
      },
    });
  } catch (error) {
    return {};
  }
};