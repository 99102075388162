import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import {
  getCountries,
  getLevelBasic
} from '../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks';
import {
  submitFormIntermediateData,
  resendFormIntermediateData
} from './../KYCV2Hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Alert, Form } from 'react-bootstrap';
import AutoFormV2 from './AutoFormV2';
import { useForm } from '../../../hooks/formHooks';
import { states } from '../../../config/staticWorldContent';
import { province } from '../../../config/staticPeruProv';
import { districts } from '../../../config/staticPeruDist';
import { OcupationsDetails } from '../../../config/staticOcupationDetails';
import {
  getNormalLevelStatus,
  validateField,
  validateLevelNumber
} from '../../../helpers/lib';
import Loading from '../../../components/Loading';

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const KYCV2IntermediateForm = (props, context) => {
  try {
    let { userInfo, selectedAccountId, history, accounts } = props;
    let currentAccount = accounts.find(a => a.AccountId === selectedAccountId);
    let requestedLevel = 12;
    const [loading, setLoading] = useState(1);
    const [countries, setCountries] = useState({});
    const [levelInfo, setLevelInfo] = useState({});
    const [basicLevel, setBasicLevel] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const [imagesBlob, setImagesBlob] = useState({});
    const [rucInfo, setRucInfo] = useState('');
    const [rucValidated, setRucValidated] = useState(true);
    const [rucRetry, setRucRetry] = useState(true);
    const [messageData, setMessageData] = useState('');
    const [err, setErr] = useState({});

    const setErrorValidation = inputsData => {
      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputsData);
      setErr(errorsCustom);
    };

    const setBlobByName = (name, blob) => {
      setImagesBlob(imagesBlob => ({
        ...imagesBlob,
        [name]: blob
      }));
    };
    async function setValidate(val) {
      setInputs(inputs => ({
        ...inputs,
        validated: val
      }));
    }

    async function setInputsBasic(basicLevel) {
      let Tempinputs = {
        ...inputs,
        id: basicLevel.id,
        first_name: basicLevel.firstName,
        middle_name: basicLevel.middleName,
        last_name: basicLevel.lastName,
        second_last_name: basicLevel.secondLastName,
        birthday: !empty(basicLevel.birthday)
          ? moment(basicLevel.birthday, 'YYYY-MM-DD')
          : null,
        nationality: basicLevel.nationality,
        birth_country: basicLevel.birthCountry,
        residence_country:
          basicLevel.residenceCountry !== 'undefined'
            ? basicLevel.residenceCountry
            : null,
        address_main: basicLevel.addressMain,
        building: basicLevel.building,
        apt: basicLevel.apt,
        city: basicLevel.city,
        state: basicLevel.state,
        town: basicLevel.town,
        district: basicLevel.district,
        zip_code: basicLevel.zipCode,
        occupation: basicLevel.occupation,
        occupation_details: basicLevel.occupationDetails,
        work_center: basicLevel.workCenter,
        phone: basicLevel.phone,
        is_pep: basicLevel.isPep,
        billing_country: basicLevel.billingCountry,
        billing_address: basicLevel.billingAddress,
        billing_building: basicLevel.billingBuilding,
        billing_town: basicLevel.billingTown,
        billing_district: basicLevel.billingDistrict,
        billing_apt_number: basicLevel.billingAptNumber,
        billing_state: basicLevel.billingState,
        billing_city: basicLevel.billingCity,
        billing_zip: basicLevel.billingZip,
        is_billing_address_residence: false,
        document_type: basicLevel.documentType,
        document_country: basicLevel.documentCountry,
        document_number: basicLevel.documentNumber,
        document_data: basicLevel.documentData,
        back_document_data: basicLevel.backDocumentData,
        faces_images: basicLevel.facesImages,
        status: basicLevel.status,
        edit_fields: basicLevel.editFields,
        user_level: basicLevel.userLevel,
        next_level: basicLevel.nextLevel,
        is_invoice_with_ruc: false,
        personal_ruc: '',
        is_sunat_validating: false,
        other_occupation: basicLevel.otherOccupation
      };
      setInputs(Tempinputs);

      setErrorValidation(Tempinputs);
    }

    async function loadLevelInfo() {
      setLoading(1);
      try {
        let result = await getLevelBasic(userInfo.UserId, selectedAccountId);
        if (
          !empty(result) &&
          !empty(result.data) &&
          !empty(result.data.level_basic)
        ) {
          let nextLevel = result.data.level_basic.nextLevel;
          if (nextLevel === requestedLevel) {
            setLevelInfo(levelInfo => ({
              ...levelInfo,
              status: getNormalLevelStatus(result.data.level_basic.status)
            }));
          }
          setInputsBasic(result.data.level_basic);
        }
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }

    /* Obteniendo carga al iniciar */
    const getListCountries = async () => {
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
        return !empty(result);
      } catch (error) {
        console.log('error', error.message);
        if (!error.message.includes('Network Error')) {
          setTimeout(function() {
            getListCountries();
          }, 3000);
        }
      }
    };

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(selectedAccountId)) {
          if (
            !empty(currentAccount) &&
            !validateLevelNumber(
              currentAccount.VerificationLevel,
              requestedLevel
            )
          ) {
            toast.warn(
              context.t(
                'Your current level is not valid to request this increase'
              ),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              }
            );
            history.push('/settings/verification-level');
          } else {
            loadLevelInfo();
          }
        }
      },
      [userInfo, selectedAccountId, currentAccount]
    );

    useEffect(() => {
      if(!empty(userInfo)) getListCountries();
    },[userInfo])
    /* terminando carga al iniciar */
    const onSubmitCallback = async inputs => {
      setLoading(1);
      try {
        console.log('err::', err, 'inputs', inputs);
        if (empty(err)) {
          if (
            !empty(levelInfo) &&
            !empty(levelInfo.status) &&
            levelInfo.status.hasOwnProperty('id') &&
            levelInfo.status.id === 5
          ) {
            let resultResend = await resendFormIntermediateData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (
              resultResend.hasOwnProperty('data') &&
              !empty(resultResend.data) &&
              resultResend.data.message === 'success'
            ) {
              history.push('/verification/success');
            }
          } else {
            let result = await submitFormIntermediateData(
              userInfo.UserId,
              inputs,
              selectedAccountId
            );
            if (result.hasOwnProperty('data')) {
              history.push('/verification/success');
            }
          }
        } else {
          console.log('err:::', err);
        }
      } catch (error) {
        console.error('error on onSubmitCallback', error);
        if (!empty(error.response) && !empty(error.response.data.data)) {
          setMessageData({
            type: 'error',
            text: context.t(error.response.data.data.error_message)
          });
        } else {
          setMessageData({
            type: 'error',
            text: context.t('Information could not be saved')
          });
        }
      }
      setLoading(0);
    };

    const onSubmitSimplewithoutErrors = e => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      let inputForm = {
        ...inputs,
        banexcoin_user_id: userInfo.UserId,
        banexcoin_user_name: userInfo.UserName,
        banexcoin_user_account_id: selectedAccountId,
        email: !empty(userInfo.Email) ? userInfo.Email : '',
        billing_country: inputs.residence_country,
        billing_address: inputs.address_main,
        billing_state: inputs.state,
        billing_building: inputs.building,
        billing_town: inputs.town,
        billing_district: inputs.district,
        billing_apt_number: inputs.apt,
        billing_city: inputs.city,
        billing_zip: inputs.zip_code,
        validated: true
      };

      setInputs(inputForm);

      let grupos = steps[currentStep - 1].groups;
      let errorsCustom = validateField(grupos, inputForm);
      setErr(errorsCustom);
      if (empty(errorsCustom)) {
        onSubmitCallback(inputForm, form);
      }
    };

    const customValidations = inputs => {
      let errors = {};
      return errors;
    };

    let initialState = {
      validated: false
    };

    const onInputChangeV2 = e => {
      e.persist();
      let inputsTemp = {
        ...inputs,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        validated: true
      };
      if(e.target.name === 'occupation') {
        inputsTemp.occupation_details = e.target.value === 'Employer' ? 'Other' : '';
      }
      if(e.target.name === 'residence_country') {
        inputsTemp.state = ''
        inputsTemp.city = ''
        inputsTemp.district = ''
      }
      if(e.target.name === 'state') {
        inputsTemp.city = ''
        inputsTemp.district = ''
      }
      if(e.target.name === 'city') {
        inputsTemp.district = ''
      }
      setInputs(inputsTemp);
      setErrorValidation(inputsTemp);
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    let optionsDocument = [];
    if (!empty(inputs) && inputs.nationality === 'PE') {
      optionsDocument = [{ value: 'ID', name: 'National identity document' }];
    } else {
      optionsDocument = [{ value: 'PP', name: 'Passport' }];
    }

    const translate = data => {
      return empty(data)
        ? {}
        : data.map(r => {
            return {
              id: r.id,
              name: context.t(r.name)
            };
          });
    };

    let steps = [
      {
        title: 'Residence Address',
        hint: [
          {
            p: 'Crop the images before uploading if you wish'
          }
        ],
        groups: [
          {
            title: 'Residence Address',
            fields: [
              {
                label: 'Residence address',
                name: 'address_main',
                type: 'text',
                size: '12',
                props: [
                  {
                    required: true,
                    placeholder: 'Residence address',
                    autoComplete: 'address_main'
                  }
                ]
              },
              {
                label: 'Building number or name',
                name: 'building',
                type: 'text',
                size: '6',
                props: [
                  {
                    required: true,
                    placeholder: 'Building number or name',
                    autoComplete: 'building'
                  }
                ]
              },
              {
                label: 'Interior # / Apartment #',
                name: 'apt',
                type: 'text',
                size: '6',
                props: [
                  {
                    autoComplete: 'apt',
                    required: false
                  }
                ]
              },
              {
                label: 'Zonification',
                name: 'town',
                type: 'text',
                size: '6',
                props: [
                  {
                    autoComplete: 'town',
                    required: true
                  }
                ]
              },
              {
                label: 'Country of Residence',
                name: 'residence_country',
                type: 'select',
                options: countries,
                loaderText : "Seleccione país de residencia",
                props: [
                  {
                    required: true
                  }
                ]
              },
              {
                label: 'State',
                name: 'state',
                type: 'select',
                options: states[inputs.residence_country],
                loaderText : "Seleccione Estado / región / departamento",
                props: [
                  {
                    required: true
                  }
                ]
              },
              inputs.residence_country === 'PE'
                ? {
                    label: 'City / Province',
                    name: 'city',
                    type: 'select',
                    options: province[inputs.state],
                    props: [
                      {
                        required: true
                      }
                    ]
                  }
                : {
                    label: 'City / Province',
                    name: 'city',
                    type: 'text',
                    props: [
                      {
                        required: true
                      }
                    ]
                  },
              inputs.residence_country === 'PE'
                ? {
                    label: 'Municipality / District',
                    name: 'district',
                    type: 'select',
                    options: districts[inputs.city],
                    props: [
                      {
                        required: true
                      }
                    ]
                  }
                : {
                    label: 'Municipality / District',
                    name: 'district',
                    type: 'text',
                    props: [
                      {
                        required: true
                      }
                    ]
                  },
              {
                label: 'Zip Code',
                name: 'zip_code',
                type: 'text',
                size: '6',
                props: [
                  {
                    required: true,
                    placeholder: 'Zip Code',
                    autoComplete: 'zip_code'
                  }
                ]
              }
            ]
          },
          {
            title: 'Ocupation Data',
            fields: [
              {
                label: 'Occupation',
                name: 'occupation',
                type: 'select',
                options: [
                  { value: 'Housewife', name: 'Housewife' },
                  { value: 'Unemployed', name: 'Unemployed' },
                  { value: 'Employee', name: 'Employee' },
                  { value: 'Employer', name: 'Employer' },
                  { value: 'Student', name: 'Student' },
                  { value: 'Retired', name: 'Retired' },
                  {
                    value: 'Member of the Armed Forces',
                    name: 'Member of the Armed Forces'
                  },
                  { value: 'Clergy member', name: 'Clergy member' },
                  { value: 'Laborer', name: 'Laborer' },
                  { value: 'Domestic Worker', name: 'Domestic Worker' },
                  { value: 'Independent Worker', name: 'Independent Worker' }
                ],
                props: [
                  {
                    required: true
                  }
                ]
              },
              {
                label: 'Specify Occupation / Profession',
                name: 'occupation_details',
                type: 'select',
                options:
                  !empty(inputs.occupation) &&
                  !empty(OcupationsDetails[inputs.occupation])
                    ? OcupationsDetails[inputs.occupation].sort(
                        (a, b) => {
                          if (a.name === "Other (Specify)"){ return 1; }
                          else if (b.name === "Other (Specify)") { return -1; }
                          else { return a.name.localeCompare(b.name) } 
                        } 
                      )
                    : '',
                props: [
                  {
                    required: true
                  }
                ]
              },
              {
                label: 'Other',
                name: 'other_occupation',
                type: 'text',
                size: '12',
                displayOn: [
                  {
                    parent: 'occupation_details',
                    conditionValue: 'Other'
                  }
                ],
                props: [
                  {
                    required: true
                  }
                ]
              },
              {
                label: 'Company Name',
                name: 'work_center',
                type: 'text',
                size: '12',
                props: [
                  {
                    required: true
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="v2-box w-12">
          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmitSimplewithoutErrors}>
            <AutoFormV2
              inputs={inputs}
              steps={steps}
              onInputChange={onInputChangeV2}
              onInputFileChange={onInputFileChange}
              onInputChangeByName={onInputChangeByName}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              imagesBlob={imagesBlob}
              setBlobByName={setBlobByName}
              requestedLevel={requestedLevel}
              setValidate={setValidate}
              messageData={messageData}
              className="col-xl-7"
              isShareholder={true}
              isDisabled={
                !empty(levelInfo.status) ? levelInfo.status.isBlocked : false
              }
              validateField={validateField}
              err={err}
              setErr={setErr}
              title={'<b>Intermediate</b> level increase'}
            />
          </Form>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2IntermediateForm error', e);
    return <div>Error:</div>;
  }
};

KYCV2IntermediateForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  null
)(KYCV2IntermediateForm);
