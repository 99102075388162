import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import visa from 'payment-icons/min/flat/visa.svg';
import Card from 'creditcards/card';
import NumberFormat from 'react-number-format';
import creditCardType from 'credit-card-type';
import './CreditCardLogos.css';

const CreditCardInput = (props, context) => {
  try {
    const [ccNumber, setCcNumber] = useState('');
    const [ccCurrentType, setCcCurrentType] = useState(null);
    let propiedades = {
      ...props
    };
    delete propiedades['dispatch'];
    delete propiedades['accounts'];
    delete propiedades['selectedAccountId'];
    delete propiedades['userInfo'];
    delete propiedades['onChange'];

    const card = Card([]);

    const onChangeField = e => {
      e.persist();
      let valor = e.target.value;
      let types = creditCardType(valor);
      let typeString = {};
      if (types.length === 0 || types.length > 1) {
        typeString = null;
      } else {
        typeString = types[0].type.toLowerCase();
      }
      let valorParsed = card.parse(valor);

      setCcCurrentType(typeString);
      if (props.hasOwnProperty('onChange')) {
        props.onChange({
          credit_card_number: valorParsed,
          credit_card_type: typeString
        });
      }
    };

    return (
      <React.Fragment>
        <div className={`card-logos-boxe rccs__card--${ccCurrentType}`}>
          <div className="rccs__card--background" />
        </div>
        <NumberFormat
          customInput={FormControl}
          {...propiedades}
          className="credit-number-input"
          onChange={onChangeField}
          format="#### #### #### ####"
          mask="_"
        />
      </React.Fragment>
    );
  } catch (e) {
    console.error('Error on EasyBuyAddCard', e);
    return <div>Error:</div>;
  }
};

CreditCardInput.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CreditCardInput;
