import moment from 'moment';

export const jsonBankNew = valor => {
  let bankArray = [
    {
      id: 'b85234ca-bf1c-54fa-7423-d0725d65ce2a',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/scotiabank.png',
      bank_name: 'Scotiabank (Peru) SOLES (PEN)',
      bank_alias: 'SCOTIABANK PERU S.A.A.',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '0007962010',
      interbank_account_number: '00924900000796201040',
      rules_for_receiving_funds_in_this_account:
      localStorage.getItem('language') === 'es'
      ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
      : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: '18e7cb5d-f108-b042-a5de-8c7cdb941575',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/scotiabank.png',
      bank_name: 'Scotiabank USD (Peru)',
      bank_alias: 'SCOTIABANK PERU S.A.A.',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '0004969054',
      interbank_account_number: '00924900000496905448',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: '1a0b72c3-37a5-289d-2dff-4601715ccff8',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/interbank.png',
      bank_name: 'Interbank USD (Peru)',
      bank_alias: 'BANCO INTERNACIONAL DEL PERU (INTERBANK)',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '1083001943080',
      swift_code:"BINPPEPL",
      bank_address:"La Victoria Av. Carlos Villarán, 140 Lima – Perú",
      beneficiary_address:"Av. José Pardo 434, Lima - Perú",
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: 'ffdd076c-fb14-d1cd-be4f-39699d238cde',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/interbank.png',
      bank_name: 'Interbank (Peru) SOLES (PEN)',
      bank_alias: 'BANCO INTERNACIONAL DEL PERU (INTERBANK)',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '1083001943073',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    
    // data test
    {
      id: '9e069b25-5df0-c03f-8a73-a01acc70d9b1',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/pacific_mercantile_bank.png',
      bank_logo2: '/local/landing-page/images/banks/primetrust.png',
      bank_name: 'PACIFIC MERCANTILE BANK',
      bank_address: '949 South Coast Drive, Third Floor, Costa Mesa, CA 92626',
      bank_phone: '1 (702) 840-4000',
      credit_to: 'Prime Trust, LLC',
      address: '330 S Rampart Ave, Suite 260, Las Vegas, NV 89145',
      routing_number: '122242869',
      account_number: '45585603',
      swift_code: 'PMERUS66',
      final_credit_to_reference: valor,
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Siempre deberá indicarse el campo Referencia: ' +
            valor +
            ' \n - ACH o Wires de Bancos Dentro y fuera de USA a excepción de Perú. \n - Limites de Acuerdo a Nivel de Cliente. \n - Solo se aceptarán Transferencias desde la cuenta Registrada perteneciente al Cliente Banexcoin, cualquier transferencia recibida desde una cuenta de terceros será Rechazada y Genera Penalidad de USD 20.'
          : '- The field Reference: QCCUS3Z6Q must always be indicated. \n - ACH or Bank Wires Inside and outside the USA except for Peru. \n - Limits of Agreement at Client Level. \n - Only Transfers from the Registered account belonging to the Banexcoin Client will be accepted, any transfer received from a third-party account will be Rejected and Generates Penalty of USD 20'
    },
    // cuentas internacionales en US
    {
      id: 'a8889166-90fd-a951-390e-0e4d2117d8b7',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/zelle.png',
      bank_type: 'Zelle',
      in_the_name_of: 'Banexcoin Sa Pa',
      zelle: 'zelle@banexcoin.com',
      reference: valor,
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Solo transferencias vía QuickPay con Zelle ®. \n - Las transferencias vía QuickPay con Zelle ® solo se pueden recibir desde el correo o número telefónico Registrado en Banexcoin, en caso de cualquier transferencia no sea recibida con estas instrucciones será rechazada y Genera Penalidad de USD 20.'
          : '- Only transfers via QuickPay with Zelle ®. \n - Transfers via QuickPay with Zelle ® can only be received from the email or telephone number Registered in Banexcoin, in case any transfer is not received with these instructions it will be rejected and generates a USD 20 penalty.'
    },

    {
      id: 'a0d20412-54d2-2d2e-e35d-3f084bebb296',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/primetrust.png',
      bank_logo2: '/local/landing-page/images/banks/royal_business_logo.png',
      bank_name: 'Royal Business Bank',
      bank_address: '1055 Wilshire Blvd. Suite 1200, Los Angeles CA 90017',
      bank_phone: '1 888 616 8188',
      credit_to: 'Prime Trust, LLC',
      address: '330 S Rampart Ave, Suite 260, Las Vegas NV 89145',
      routing_number: '122045037',
      account_number: '2030136050',
      swift_code: 'RBBCUS6L',
      final_credit_to_reference: 'QCCUS3Z6Q',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Siempre deberá indicarse el campo Referencia: QCCUS3Z6Q \n - Solo Wire Transfer fuera de los Estados Unidos. \n - Solo se aceptarán Transferencias desde la cuenta Registrada perteneciente al Cliente Banexcoin, cualquier transferencia recibida desde una cuenta de terceros será Rechazada y Genera Penalidad de USD 20.'
          : '- The field Reference: QCCUS3Z6Q must always be indicated. \n - Wire Transfer only outside the United States. \n - Limits of Agreement at Client Level. \n - Only Transfers from the Registered account belonging to the Banexcoin Client will be accepted, any transfer received from a third-party account will be Rejected and Generates Penalty of USD 20'
    },
    {
      id: 'd3135064-70a7-d27e-4f00-3ae6688af1e1',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/primetrust.png',
      in_the_name_of: 'Banexcoin SA.',
      account_number: '420058909641',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Primero debe registrar su cuenta de Prime Trust para nosotros como contraparte. \n - Entonces, debe registrar Banexcoin como contraparte con los datos a continuación. \n - Una vez que ambas cuentas estén registradas, puede realizar la transferencia y se le cargará en su cuenta de Banexcoin. \n\n Cuando autoriza a las contrapartes, puede liquidar transferencias de efectivo y activos al instante utilizando la red PrimeX. Una vez que una contraparte esté autorizada, verá esta cuenta como destino cuando realice una Transferencia PrimeX.'
          : '- First you must to register your Prime Trust Account for us as counterparty. \n - Then, you must to register Banexcoin as counterparty with the data below. \n - Once both account are registered, you can make the transfer and will be charged to your Banexcoin Account. \n\n When you authorize counterparties, you are able to settle cash and asset transfers instantly using the PrimeX network. Once a counterparty is authorized they will see this account as a destination when making a PrimeX Transfer.'
    },
    {
      id: '363f0986-4e12-02da-d6a6-5737b277e877',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/Bbvacontinentallogo.png',
      bank_name: 'BBVA Continental (Peru)',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '001101830100128477',
      interbank_account_number: '01118300010012847715',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: '2713800a-7866-3fd6-38cf-f6328a213f70',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/Bbvacontinentallogo.png',
      bank_name: 'BBVA Continental (Peru)',
      bank_alias: 'BANCO BBVA CONTINENTAL',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      current_account: '001101830100128469',
      interbank_account_number: '01118300010012846912',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: 'b55d9212-ff49-cd61-50e3-41b30d9e1708',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/alfin_logo.png',
      bank_name:
        'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.) DOLARES (USD)',
      bank_alias: 'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.)',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      account_number: '01770884700002',
      interbank_account_number: '05810001770884700292',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: 'dbef4b2d-ca98-98ee-1e9b-121619a0a435',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/alfin_logo.png',
      bank_name:
        'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.) SOLES (PEN)',
      bank_alias: 'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.)',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      account_number: '01770884700001',
      interbank_account_number: '05810001770884700194',
      rules_for_receiving_funds_in_this_account:
      localStorage.getItem('language') === 'es'
      ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
        },
    {
      id: '781594d6-f916-068c-c3ec-8fdd6d038522',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/banco_comercio.png',
      bank_name: 'BANCO DE COMERCIO',
      bank_alias: 'BANCO DE COMERCIO',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      account_number: '410201298393',
      interbank_account_number: '02304541020129839388',
      swift_code:"BDCMPEPL",
      bank_address:"Av. Canaval y Moreyra, 454 Lima - Perú",
      beneficiary_address:"Av. José Pardo 434, Lima - Perú",
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10  USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: '0b808ef3-3513-4df2-530c-0fac1514b614',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/banco_comercio.png',
      bank_name: 'BANCO DE COMERCIO',
      bank_alias: 'BANCO DE COMERCIO',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      account_number: '410201298409',
      interbank_account_number: '02304541020129840982',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    },
    {
      id: '0b808ef3-3513-4df2-530c-0fac1514b614',
      symbol: 'PEN',
      bank_logo: '/local/landing-page/images/banks/banco_comercio.png',
      bank_name: 'BANCO DE COMERCIO',
      bank_alias: 'BANCO DE COMERCIO',
      ruc: '20603726139',
      in_the_name_of: 'Banexcoin SA',
      account_number: '410201298409',
      interbank_account_number: '02304541020129840982',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Límites de acuerdo al nivel de cliente. \n - Solo se aceptarán transferencias desde la cuenta registrada perteneciente al cliente Banexcoin. \n - Cualquier transferencia recibida desde una cuenta de terceros será rechazada y se aplicará una penalidad, de 10 USD o 45 Soles, de acuerdo a la moneda recibida. \n - El proceso de depósito está sujeto a evaluación de acuerdo a las políticas de Banexcoin. \n - Monto mínimo de depósito 10 USD o 45 Soles. \n - El depósito será procesado cuando el mismo se visualice en la cuenta de Banexcoin. \n - En cada depósito realizado debe adjuntar la foto original y nítida del voucher o comprobante.'
          : '- Customer level agreement limits. \n Transfers will only be accepted from The Registered account belonging to The Banexcoin client. \n - Any transfer received from a third party account will be rejected and a penalty of 10 USD or 45 Soles will be applied depending on the currency received. \n - The deposit process is subject to evaluation according to Banexcoin policies. \n - Minimum Deposit Amount 10 USD or 45 Soles. \n - The deposit will be processed, when it is displayed in the Banexcoin account. \n - In each deposit made, you must attach the original photo and clear voucher or receipt.'
    }
  ];
  let start = 1612501200000;
  if (Number(moment()) >= start) {
    bankArray.push({
      id: '010c7fd5-44a0-f96c-ad64-cc7c401854ca',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/primetrust.png',
      bank_logo2:
        '/local/landing-page/images/banks/signature-bank-logo-tan.png',
      bank_name: 'SIGNATURE BANK',
      bank_address: '565 Fifth Avenue New York NY',
      bank_phone: '1 (702) 840-4000',
      credit_to: 'Prime Trust, LLC',
      routing_number: '026013576',
      account_number: '1503666126',
      swift_code: 'SIGNUS33XXX',
      reference: 'QCCUS3Z6Q',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Siempre deberá indicarse el campo Referencia: QCCUS3Z6Q \n - Solo Domestic Wire Transfer dentro de los Estados Unidos (No ACH). \n - Solo se aceptarán Transferencias desde la cuenta Registrada perteneciente al Cliente Banexcoin, cualquier transferencia recibida desde una cuenta de terceros será Rechazada y Genera Penalidad de USD 20.'
          : '- The field Reference: QCCUS3Z6Q must always be indicated. \n - Domestic Wire Transfer only within the United States (Non-ACH). \n - Limits of Agreement at Client Level. \n - Only Transfers from the Registered account belonging to the Banexcoin Client will be accepted, any transfer received from a third-party account will be Rejected and Generates Penalty of USD 20'
    });
  } else {
    bankArray.push({
      id: '010c7fd5-44a0-f96c-ad64-cc7c401854ca',
      symbol: 'USD',
      bank_logo: '/local/landing-page/images/banks/primetrust.png',
      bank_logo2: '/local/landing-page/images/banks/NanoBanc_logo.png',
      bank_name: 'Nano Banc',
      bank_address: '7700 Irvine Center Drive Ste 700 Irvine CA, 92618',
      bank_phone: '1 (702) 840-4000',
      credit_to: 'Prime Trust, LLC',
      routing_number: '122245251',
      account_number: '6300102308',
      final_credit_to_reference: 'QCCUS3Z6Q',
      rules_for_receiving_funds_in_this_account:
        localStorage.getItem('language') === 'es'
          ? '- Siempre deberá indicarse el campo Referencia: QCCUS3Z6Q \n - Solo Domestic Wire Transfer dentro de los Estados Unidos (No ACH). \n - Solo se aceptarán Transferencias desde la cuenta Registrada perteneciente al Cliente Banexcoin, cualquier transferencia recibida desde una cuenta de terceros será Rechazada y Genera Penalidad de USD 20.'
          : '- The field Reference: QCCUS3Z6Q must always be indicated. \n - Domestic Wire Transfer only within the United States (Non-ACH). \n - Limits of Agreement at Client Level. \n - Only Transfers from the Registered account belonging to the Banexcoin Client will be accepted, any transfer received from a third-party account will be Rejected and Generates Penalty of USD 20'
    });
  }

  return bankArray;
};

export const allBanksInfo = nombre => {
  let banks = [
    {
      name: 'Prime Trust, LLC',
      bank_logo: '/local/landing-page/images/banks/primetrust.png'
    },
    {
      name: 'BANCO BBVA CONTINENTAL',
      bank_logo: '/local/landing-page/images/banks/Bbvacontinentallogo.png'
    },
    {
      name: 'SCOTIABANK PERU S.A.A.',
      bank_logo: '/local/landing-page/images/banks/scotiabank.png'
    },
    {
      name: 'BANCO INTERNACIONAL DEL PERU (INTERBANK)',
      bank_logo: '/local/landing-page/images/banks/interbank.png'
    },
    {
      name: 'BANCO DE CREDITO DEL PERU',
      bank_logo: '/local/landing-page/images/banks/bcp_mini.png'
    },
    {
      name: 'ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.)',
      bank_logo: '/local/landing-page/images/banks/alfin_logo.png'
    },
    {
      name: 'BANCO DE COMERCIO',
      bank_logo: '/local/landing-page/images/banks/banco_comercio.png'
    },
  ];
  let found = banks.find(b => b.name === nombre);
  
  return found;
};
