import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Form, Row, Col, Tab, Tabs } from 'react-bootstrap';
// import PhoneInput from 'react-phone-input-2';
// import { DatePickerInput } from 'rc-datepicker';
import moment from 'moment';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import { submitFormData, getLevelBasic } from './MasterLevelPageHooks';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
//CSS
import './MasterLevelPage.css';

const RobotSvg = require('../../images/robot.svg');
const intermediateLevelPageClasses = getBEMClasses('masterlevel-page');

const MasterLevel = ({ selectedAccountId, userInfo }, context) => {
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [basicLevel, setBasicLevel] = useState({});
    const [key, setKey] = useState(1);

    useEffect(() => {
      async function loadLevelBasic() {
        setLoading(1);

        try {
          let result = await getLevelBasic(userInfo.UserId, selectedAccountId);
          setBasicLevel(result.data.level_basic);
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadLevelBasic();
      }
    }, []);

    const isValidUrl = string => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };

    useEffect(
      () => {
        inputs = {
          ...inputs,
          id: basicLevel.id,
          first_name: basicLevel.firstName,
          middle_name: basicLevel.middleName,
          last_name: basicLevel.lastName,
          second_last_name: basicLevel.secondLastName,
          birthday: moment(basicLevel.birthday, 'YYYY-MM-DD'),
          nationality: basicLevel.nationality,
          birth_country: basicLevel.birthCountry,
          address_main: basicLevel.addressMain,
          phone: basicLevel.phone,
          is_pep: basicLevel.isPep,
          billing_country: basicLevel.billingCountry,
          billing_address: basicLevel.billingAddress,
          billing_state: basicLevel.billingState,
          billing_city: basicLevel.billingCity,
          billing_zip: basicLevel.billingZip,
          document_type: basicLevel.documentType,
          document_country: basicLevel.documentCountry,
          document_number: basicLevel.documentNumber,
          document_city: basicLevel.documentCity,
          document_data: basicLevel.documentData,
          back_document_data: basicLevel.backDocumentData,
          faces_images: basicLevel.facesImages
        };
        setInputs(inputs);
      },
      [basicLevel]
    );

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result = await submitFormData(userInfo.UserId, inputs);
        console.log('onSubmitCallback', result);
        var cerrar = document.getElementsByClassName(
          'ap-sidepane__close-button'
        );
        if (cerrar) {
          cerrar[0].click();
        }

        toast.success(
          context.t(
            'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n an email with the response to your request.'
          ),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
        setLoading(1);

        try {
          let resultRequest = await getLevelBasic(
            userInfo.UserId,
            selectedAccountId
          );
          setBasicLevel(resultRequest.data.level_basic);
          setKey(1);
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};
      if (empty(inputs.birthday)) {
        errors.birthday = true;
      } else {
        var m = moment(inputs.birthday, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.birthday = true;
        }
      }

      return errors;
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : ''
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    return userInfo.Use2FA ? (
      <React.Fragment>
        <Loading loading={loading} />
        {basicLevel.status === 4 ? (
          <div>{context.t('Your account has been locked.')}</div>
        ) : (
          <div className={intermediateLevelPageClasses()}>
            <h1>{context.t('Raise user level to master')}</h1>
            <p>
              {context.t(
                'To raise the user level you must fill out the form below.'
              )}
            </p>
            <Form
              noValidate
              validated={inputs.validated}
              encType="multipart/form-data"
              onSubmit={onSubmit}>
              <Tabs activeKey={key} onSelect={k => setKey(k)}>
                <Tab eventKey={1} title={context.t('Personal Data')}>
                  <Row>
                    <Col xl="4">
                      <p>{context.t('Email')}:</p>
                      <p>{basicLevel.email}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="3">
                      <p>{context.t('First Name')}:</p>
                      <p>{basicLevel.firstName}</p>
                    </Col>

                    <Col xl="3">
                      <p>{context.t('Middle Name')}:</p>
                      <p>{basicLevel.middleName}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Last Name')}:</p>
                      <p>{basicLevel.lastName}</p>
                    </Col>

                    <Col xl="3">
                      <p>{context.t('Second Last Name')}:</p>
                      <p>{basicLevel.secondLastName}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Birthday')}:</p>
                      <p>{basicLevel.birthday}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Nationality')}:</p>
                      <p>{basicLevel.nationality}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Birth Country')}:</p>
                      <p>{basicLevel.birthCountry}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p>{context.t('Address Main')}:</p>
                      <p>{basicLevel.addressMain}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Phone')}:</p>
                      <p>{basicLevel.phone}</p>
                    </Col>

                    <Col xl="6">
                      <p>
                        {context.t('Is a PEP? (People Exposed Politically)')}:
                      </p>
                      <p>
                        {basicLevel.isPep == '1'
                          ? context.t('Yes')
                          : context.t('No')}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Billing Country')}:</p>
                      <p>{basicLevel.billingCountry}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Billing State')}:</p>
                      <p>{basicLevel.billingState}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Billing City')}:</p>
                      <p>{basicLevel.billingCity}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Billing Zip')}:</p>
                      <p>{basicLevel.billingZip}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p>{context.t('Billing Address')}:</p>
                      <p>{basicLevel.billingAddress}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <Button
                        variant="info"
                        type="button"
                        onClick={() => setKey(2)}
                        style={{ width: '40%' }}>
                        {context.t('Next')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey={2} title={context.t('Documents')}>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Type')}:</p>
                      <p>{basicLevel.documentType}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Document Number')}:</p>
                      <p>{basicLevel.documentNumber}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Country')}:</p>
                      <p>{basicLevel.documentCountry}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Document City')}:</p>
                      <p>{basicLevel.documentCity}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Data')}:</p>
                      <img
                        src={
                          !empty(basicLevel.documentData)
                            ? isValidUrl(basicLevel.documentData)
                              ? basicLevel.documentData
                              : URL.createObjectURL(basicLevel.documentData)
                            : 'default'
                        }
                        height="60px"
                        width="60px"
                        alt={context.t('Document Data')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Back Document Data')}:</p>
                      <img
                        src={
                          !empty(basicLevel.backDocumentData)
                            ? isValidUrl(basicLevel.backDocumentData)
                              ? basicLevel.backDocumentData
                              : URL.createObjectURL(basicLevel.backDocumentData)
                            : 'default'
                        }
                        height="60px"
                        width="60px"
                        alt={context.t('Back Document Data')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Faces Images')}:</p>
                      <img
                        src={
                          !empty(basicLevel.facesImages)
                            ? isValidUrl(basicLevel.facesImages)
                              ? basicLevel.facesImages
                              : URL.createObjectURL(basicLevel.facesImages)
                            : 'default'
                        }
                        height="60px"
                        width="60px"
                        alt={context.t('Faces Images')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <Button
                        variant="warning"
                        type="button"
                        onClick={() => setKey(1)}
                        style={{ width: '40%' }}>
                        {context.t('Back')}
                      </Button>
                    </Col>
                    <Col xl="6">
                      <Button
                        variant="info"
                        type="button"
                        onClick={() => setKey(3)}
                        style={{ width: '40%' }}>
                        {context.t('Next')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                {empty(basicLevel.proofOfResidence) ? (
                  <Tab eventKey={3} title={context.t('Intermediate Level')}>
                    <Row>
                      <Col xl="6">
                        <Form.Group controlId="occupation">
                          <Form.Label>
                            {context.t(
                              'Activity, occupation, trade or profession'
                            )}{' '}
                            <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            name="occupation"
                            defaultValue={inputs.occupation || ''}
                            type="text"
                            onChange={onInputChange}
                            placeholder={context.t(
                              'Activity, occupation, trade or profession'
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {context.t(
                              'You must enter the Activity, occupation, trade or profession'
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xl="6">
                        <Form.Group controlId="work_center">
                          <Form.Label>
                            {context.t('Work Center')}{' '}
                            <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            name="work_center"
                            defaultValue={inputs.work_center || ''}
                            type="text"
                            onChange={onInputChange}
                            placeholder={context.t('Work Center')}
                          />
                          <Form.Control.Feedback type="invalid">
                            {context.t('You must enter the Work Center')}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <Form.Group
                          controlId="proof_residence"
                          title={context.t(
                            'Proof of residence no longer than 3 months (copy of receipt of any service: electricity, water supply, cable or telephone service)'
                          )}>
                          <Form.Label>
                            {context.t(
                              'Proof of residence no longer than 3 months (copy of receipt of any service: electricity, water supply, cable or telephone service)'
                            )}{' '}
                            <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            name="proof_residence"
                            type="file"
                            onChange={onInputFileChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {context.t(
                              'You must attach document data and the file size must be less than or equal to 2MB'
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="warning"
                          type="button"
                          onClick={() => setKey(2)}
                          style={{ width: '40%' }}>
                          {context.t('Back')}
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Button
                          variant="info"
                          type="button"
                          onClick={() => setKey(4)}
                          style={{ width: '40%' }}>
                          {context.t('Next')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                ) : (
                  <Tab eventKey={3} title={context.t('Intermediate Level')}>
                    <Row>
                      <Col xl="6">
                        <p>
                          {context.t(
                            'Activity, occupation, trade or profession'
                          )}:
                        </p>
                        <p>{basicLevel.occupation}</p>
                      </Col>
                      <Col xl="6">
                        <p>{context.t('Work Center')}:</p>
                        <p>{basicLevel.workCenter}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <p>
                          {context.t(
                            'Proof of residence no longer than 3 months (copy of receipt of any service: electricity, water supply, cable or telephone service)'
                          )}
                        </p>
                        <p>
                          <a href={basicLevel.proofOfResidence} target="_blank">
                            Proof of residence Upload
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="warning"
                          type="button"
                          onClick={() => setKey(2)}
                          style={{ width: '40%' }}>
                          {context.t('Back')}
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Button
                          variant="info"
                          type="button"
                          onClick={() => setKey(4)}
                          style={{ width: '40%' }}>
                          {context.t('Next')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                )}
                {!empty(basicLevel.originOfFunds) && basicLevel.status !== 5 ? (
                  <Tab eventKey={4} title={context.t('Master Level')}>
                    <Row>
                      <Col xl="12">
                        <p>
                          {context.t(
                            'Submit Affidavit of Origin of Funds: See Annex B (if dependent)'
                          )}:
                        </p>
                        <p>
                          <a href={basicLevel.originOfFunds}>
                            {context.t(
                              'Submit Affidavit of Origin of Funds Upload'
                            )}
                          </a>
                        </p>
                      </Col>
                      <Col xl="12">
                        <p>
                          {context.t(
                            'Present Financial Statements (if independent or shareholder)'
                          )}:
                        </p>
                        <p>
                          <a href={basicLevel.financialStatements}>
                            {context.t('Present Financial Statements Upload')}
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <p>
                          {context.t(
                            'Submit documents that support the source of funds, if requested'
                          )}
                        </p>
                        <p>
                          <a href={basicLevel.sourceOfFunds}>
                            {context.t(
                              'Submit documents that support the source of funds Upload'
                            )}
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <p>
                          {context.t(
                            'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n an email with the response to your request.'
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Tab>
                ) : (
                  <Tab eventKey={4} title={context.t('Level Master')}>
                    <Row>
                      {empty(basicLevel.originOfFunds) ? (
                        <Col xl="12">
                          <Form.Group
                            controlId="origin_of_funds"
                            title={context.t(
                              'Submit Affidavit of Origin of Funds: See Annex B (if dependent)'
                            )}>
                            <Form.Label>
                              {context.t(
                                'Submit Affidavit of Origin of Funds: See Annex B (if dependent)'
                              )}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="origin_of_funds"
                              type="file"
                              onChange={onInputFileChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t(
                                'You must attach document data and the file size must be less than or equal to 2MB'
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="12">
                          <p>
                            {context.t(
                              'Submit Affidavit of Origin of Funds: See Annex B (if dependent)'
                            )}:
                          </p>
                          <p>
                            <a href={basicLevel.originOfFunds}>
                              {context.t(
                                'Submit Affidavit of Origin of Funds Upload'
                              )}
                            </a>
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.financialStatements) ? (
                        <Col xl="12">
                          <Form.Group
                            controlId="financial_statements"
                            title={context.t(
                              'Present Financial Statements (if independent or shareholder)'
                            )}>
                            <Form.Label>
                              {context.t(
                                'Present Financial Statements (if independent or shareholder)'
                              )}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="financial_statements"
                              type="file"
                              onChange={onInputFileChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t(
                                'You must attach document data and the file size must be less than or equal to 2MB'
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="12">
                          <p>
                            {context.t(
                              'Present Financial Statements (if independent or shareholder)'
                            )}:
                          </p>
                          <p>
                            <a href={basicLevel.financialStatements}>
                              {context.t('Present Financial Statements Upload')}
                            </a>
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.sourceOfFunds) ? (
                        <Col xl="12">
                          <Form.Group
                            controlId="source_of_funds"
                            title={context.t(
                              'Submit documents that support the source of funds, if requested'
                            )}>
                            <Form.Label>
                              {context.t(
                                'Submit documents that support the source of funds, if requested'
                              )}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="source_of_funds"
                              type="file"
                              onChange={onInputFileChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t(
                                'You must attach document data and the file size must be less than or equal to 2MB'
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="12">
                          <p>
                            {context.t(
                              'Submit documents that support the source of funds, if requested'
                            )}
                          </p>
                          <p>
                            <a href={basicLevel.sourceOfFunds}>
                              {context.t(
                                'Submit documents that support the source of funds Upload'
                              )}
                            </a>
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="warning"
                          type="button"
                          onClick={() => setKey(3)}
                          style={{ width: '40%' }}>
                          {context.t('Back')}
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ width: '40%' }}>
                          {context.t('Send')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </Form>
          </div>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={intermediateLevelPageClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

MasterLevel.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(MasterLevel);
