import React from 'react'
import PropTypes from 'prop-types'
import InformationIcon from '../../../../images/bnx-icons/information-circle.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './tooltip.css'

function TooltipComponent(props) {
  const {id, text, className = ''} = props
  return (
    <OverlayTrigger
    key={'info3-tooltip'}
    placement={'right'}
    overlay={
        <Tooltip id={id} className={`tooltip-buysell ${className}`}>
            <p style={{margin:"0"}} className="buysell-text" dangerouslySetInnerHTML={{__html: text}} />
        </Tooltip>
    }>
        <img src={InformationIcon} alt='Information icon'/> 
    </OverlayTrigger>  
  )
}

TooltipComponent.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
}

export default TooltipComponent