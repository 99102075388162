export const states = {
  AF: [
    { id: 'Badakhshan', name: 'Badakhshan' },
    { id: 'Badghis', name: 'Badghis' },
    { id: 'Baghlan', name: 'Baghlan' },
    { id: 'Balkh', name: 'Balkh' },
    { id: 'Bamian', name: 'Bamian' },
    { id: 'Daykondi', name: 'Daykondi' },
    { id: 'Farah', name: 'Farah' },
    { id: 'Faryab', name: 'Faryab' },
    { id: 'Ghazni', name: 'Ghazni' },
    { id: 'Ghowr', name: 'Ghowr' },
    { id: 'Helmand', name: 'Helmand' },
    { id: 'Herat', name: 'Herat' },
    { id: 'Jowzjan', name: 'Jowzjan' },
    { id: 'Kabul', name: 'Kabul' },
    { id: 'Kandahar', name: 'Kandahar' },
    { id: 'Kapisa', name: 'Kapisa' },
    { id: 'Khost', name: 'Khost' },
    { id: 'Konar', name: 'Konar' },
    { id: 'Kondoz', name: 'Kondoz' },
    { id: 'Laghman', name: 'Laghman' },
    { id: 'Lowgar', name: 'Lowgar' },
    { id: 'Nangarhar', name: 'Nangarhar' },
    { id: 'Nimruz', name: 'Nimruz' },
    { id: 'Nurestan', name: 'Nurestan' },
    { id: 'Oruzgan', name: 'Oruzgan' },
    { id: 'Paktia', name: 'Paktia' },
    { id: 'Paktika', name: 'Paktika' },
    { id: 'Panjshir', name: 'Panjshir' },
    { id: 'Parvan', name: 'Parvan' },
    { id: 'Samangan', name: 'Samangan' },
    { id: 'Sar-e Pol', name: 'Sar-e Pol' },
    { id: 'Takhar', name: 'Takhar' },
    { id: 'Vardak', name: 'Vardak' },
    { id: 'Zabol', name: 'Zabol' }
  ],
  AL: [
    { id: 'Berat', name: 'Berat' },
    { id: 'Dibres', name: 'Dibres' },
    { id: 'Durres', name: 'Durres' },
    { id: 'Elbasan', name: 'Elbasan' },
    { id: 'Fier', name: 'Fier' },
    { id: 'Gjirokastre', name: 'Gjirokastre' },
    { id: 'Korce', name: 'Korce' },
    { id: 'Kukes', name: 'Kukes' },
    { id: 'Lezhe', name: 'Lezhe' },
    { id: 'Shkoder', name: 'Shkoder' },
    { id: 'Tirane', name: 'Tirane' },
    { id: 'Vlore', name: 'Vlore' }
  ],
  DZ: [
    { id: 'Adrar', name: 'Adrar' },
    { id: 'Ain Defla', name: 'Ain Defla' },
    { id: 'Ain Temouchent', name: 'Ain Temouchent' },
    { id: 'Alger', name: 'Alger' },
    { id: 'Annaba', name: 'Annaba' },
    { id: 'Batna', name: 'Batna' },
    { id: 'Bechar', name: 'Bechar' },
    { id: 'Bejaia', name: 'Bejaia' },
    { id: 'Biskra', name: 'Biskra' },
    { id: 'Blida', name: 'Blida' },
    { id: 'Bordj Bou Arreridj', name: 'Bordj Bou Arreridj' },
    { id: 'Bouira', name: 'Bouira' },
    { id: 'Boumerdes', name: 'Boumerdes' },
    { id: 'Chlef', name: 'Chlef' },
    { id: 'Constantine', name: 'Constantine' },
    { id: 'Djelfa', name: 'Djelfa' },
    { id: 'El Bayadh', name: 'El Bayadh' },
    { id: 'El Oued', name: 'El Oued' },
    { id: 'El Tarf', name: 'El Tarf' },
    { id: 'Ghardaia', name: 'Ghardaia' },
    { id: 'Guelma', name: 'Guelma' },
    { id: 'Illizi', name: 'Illizi' },
    { id: 'Jijel', name: 'Jijel' },
    { id: 'Khenchela', name: 'Khenchela' },
    { id: 'Laghouat', name: 'Laghouat' },
    { id: 'Muaskar', name: 'Muaskar' },
    { id: 'Medea', name: 'Medea' },
    { id: 'Mila', name: 'Mila' },
    { id: 'Mostaganem', name: 'Mostaganem' },
    { id: "M'Sila", name: "M'Sila" },
    { id: 'Naama', name: 'Naama' },
    { id: 'Oran', name: 'Oran' },
    { id: 'Ouargla', name: 'Ouargla' },
    { id: 'Oum el Bouaghi', name: 'Oum el Bouaghi' },
    { id: 'Relizane', name: 'Relizane' },
    { id: 'Saida', name: 'Saida' },
    { id: 'Setif', name: 'Setif' },
    { id: 'Sidi Bel Abbes', name: 'Sidi Bel Abbes' },
    { id: 'Skikda', name: 'Skikda' },
    { id: 'Souk Ahras', name: 'Souk Ahras' },
    { id: 'Tamanghasset', name: 'Tamanghasset' },
    { id: 'Tebessa', name: 'Tebessa' },
    { id: 'Tiaret', name: 'Tiaret' },
    { id: 'Tindouf', name: 'Tindouf' },
    { id: 'Tipaza', name: 'Tipaza' },
    { id: 'Tissemsilt', name: 'Tissemsilt' },
    { id: 'Tizi Ouzou', name: 'Tizi Ouzou' },
    { id: 'Tlemcen', name: 'Tlemcen' }
  ],
  AD: [
    { id: 'Andorra la Vella', name: 'Andorra la Vella' },
    { id: 'Canillo', name: 'Canillo' },
    { id: 'Encamp', name: 'Encamp' },
    { id: 'Escaldes-Engordany', name: 'Escaldes-Engordany' },
    { id: 'La Massana', name: 'La Massana' },
    { id: 'Ordino', name: 'Ordino' },
    { id: 'Sant Julia de Loria', name: 'Sant Julia de Loria' }
  ],
  AO: [
    { id: 'Bengo', name: 'Bengo' },
    { id: 'Benguela', name: 'Benguela' },
    { id: 'Bie', name: 'Bie' },
    { id: 'Cabinda', name: 'Cabinda' },
    { id: 'Cuando Cubango', name: 'Cuando Cubango' },
    { id: 'Cuanza Norte', name: 'Cuanza Norte' },
    { id: 'Cuanza Sul', name: 'Cuanza Sul' },
    { id: 'Cunene', name: 'Cunene' },
    { id: 'Huambo', name: 'Huambo' },
    { id: 'Huila', name: 'Huila' },
    { id: 'Luanda', name: 'Luanda' },
    { id: 'Lunda Norte', name: 'Lunda Norte' },
    { id: 'Lunda Sul', name: 'Lunda Sul' },
    { id: 'Malanje', name: 'Malanje' },
    { id: 'Moxico', name: 'Moxico' },
    { id: 'Namibe', name: 'Namibe' },
    { id: 'Uige', name: 'Uige' },
    { id: 'Zaire', name: 'Zaire' }
  ],
  AI: [
    { id: 'Anguila', name: 'Anguila' },
    { id: 'Anguilita', name: 'Anguilita' },
    { id: 'Dog', name: 'Dog' },
    { id: 'Little Scrub', name: 'Little Scrub' },
    { id: 'Cayos Prickly Pear', name: 'Cayos Prickly Pear' },
    { id: 'Sandy', name: 'Sandy' },
    { id: 'Scrub', name: 'Scrub' },
    { id: 'Seal', name: 'Seal' },
    { id: 'Sombrero', name: 'Sombrero' }
  ],
  AG: [
    { id: 'Barbuda', name: 'Barbuda' },
    { id: 'Redonda', name: 'Redonda' },
    { id: 'Saint George', name: 'Saint George' },
    { id: 'Saint John', name: 'Saint John' },
    { id: 'Saint Mary', name: 'Saint Mary' },
    { id: 'Saint Paul', name: 'Saint Paul' },
    { id: 'Saint Peter', name: 'Saint Peter' },
    { id: 'Saint Philip', name: 'Saint Philip' }
  ],
  AR: [
    { id: 'Buenos Aires', name: 'Buenos Aires' },
    { id: 'Buenos Aires Capital', name: 'Buenos Aires Capital' },
    { id: 'Catamarca', name: 'Catamarca' },
    { id: 'Chaco', name: 'Chaco' },
    { id: 'Chubut', name: 'Chubut' },
    { id: 'Cordoba', name: 'Cordoba' },
    { id: 'Corrientes', name: 'Corrientes' },
    { id: 'Entre Rios', name: 'Entre Rios' },
    { id: 'Formosa', name: 'Formosa' },
    { id: 'Jujuy', name: 'Jujuy' },
    { id: 'La Pampa', name: 'La Pampa' },
    { id: 'La Rioja', name: 'La Rioja' },
    { id: 'Mendoza', name: 'Mendoza' },
    { id: 'Misiones', name: 'Misiones' },
    { id: 'Neuquen', name: 'Neuquen' },
    { id: 'Rio Negro', name: 'Rio Negro' },
    { id: 'Salta', name: 'Salta' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'San Luis', name: 'San Luis' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Santa Fe', name: 'Santa Fe' },
    { id: 'Santiago del Estero', name: 'Santiago del Estero' },
    { id: 'Tierra del Fuego', name: 'Tierra del Fuego' },
    { id: 'Tucuman', name: 'Tucuman' }
  ],
  AM: [
    { id: 'Aragatsotn', name: 'Aragatsotn' },
    { id: 'Ararat', name: 'Ararat' },
    { id: 'Armavir', name: 'Armavir' },
    { id: "Geghark'unik'", name: "Geghark'unik'" },
    { id: "Kotayk'", name: "Kotayk'" },
    { id: 'Lorri', name: 'Lorri' },
    { id: 'Shirak', name: 'Shirak' },
    { id: "Syunik'", name: "Syunik'" },
    { id: 'Tavush', name: 'Tavush' },
    { id: "Vayots' Dzor", name: "Vayots' Dzor" },
    { id: 'Yerevan', name: 'Yerevan' }
  ],
  AW: [
    {
      id: 'Norte (Noord)/ Tanki Leendert',
      name: 'Norte (Noord)/ Tanki Leendert'
    },
    { id: 'Oranjestad West', name: 'Oranjestad West' },
    { id: 'Oranjestad Oost', name: 'Oranjestad Oost' },
    { id: 'Paradera', name: 'Paradera' },
    { id: 'San Nicolás Noord', name: 'San Nicolás Noord' },
    { id: 'San Nicolás Zuid', name: 'San Nicolás Zuid' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Savaneta', name: 'Savaneta' }
  ],
  AS: [
    { id: 'Eastern District', name: 'Eastern District' },
    { id: "Manu'a District", name: "Manu'a District" },
    { id: 'Western District', name: 'Western District' }
  ],
  AU: [
    {
      id: 'Australian Capital Territory',
      name: 'Australian Capital Territory'
    },
    { id: 'New South Wales', name: 'New South Wales' },
    { id: 'Northern Territory', name: 'Northern Territory' },
    { id: 'Queensland', name: 'Queensland' },
    { id: 'South Australia', name: 'South Australia' },
    { id: 'Tasmania', name: 'Tasmania' },
    { id: 'Victoria', name: 'Victoria' },
    { id: 'Western Australia', name: 'Western Australia' }
  ],
  AT: [
    { id: 'Burgenland', name: 'Burgenland' },
    { id: 'Kaernten', name: 'Kaernten' },
    { id: 'Niederoesterreich', name: 'Niederoesterreich' },
    { id: 'Oberoesterreich', name: 'Oberoesterreich' },
    { id: 'Salzburg', name: 'Salzburg' },
    { id: 'Steiermark', name: 'Steiermark' },
    { id: 'Tirol', name: 'Tirol' },
    { id: 'Vorarlberg', name: 'Vorarlberg' },
    { id: 'Wien', name: 'Wien' }
  ],
  AZ: [
    { id: 'Abseron Rayonu', name: 'Abseron Rayonu' },
    { id: 'Agcabadi Rayonu', name: 'Agcabadi Rayonu' },
    { id: 'Agdam Rayonu', name: 'Agdam Rayonu' },
    { id: 'Agdas Rayonu', name: 'Agdas Rayonu' },
    { id: 'Agstafa Rayonu', name: 'Agstafa Rayonu' },
    { id: 'Agsu Rayonu', name: 'Agsu Rayonu' },
    { id: 'Astara Rayonu', name: 'Astara Rayonu' },
    { id: 'Balakan Rayonu', name: 'Balakan Rayonu' },
    { id: 'Barda Rayonu', name: 'Barda Rayonu' },
    { id: 'Beylaqan Rayonu', name: 'Beylaqan Rayonu' },
    { id: 'Bilasuvar Rayonu', name: 'Bilasuvar Rayonu' },
    { id: 'Cabrayil Rayonu', name: 'Cabrayil Rayonu' },
    { id: 'Calilabad Rayonu', name: 'Calilabad Rayonu' },
    { id: 'Daskasan Rayonu', name: 'Daskasan Rayonu' },
    { id: 'Davaci Rayonu', name: 'Davaci Rayonu' },
    { id: 'Fuzuli Rayonu', name: 'Fuzuli Rayonu' },
    { id: 'Gadabay Rayonu', name: 'Gadabay Rayonu' },
    { id: 'Goranboy Rayonu', name: 'Goranboy Rayonu' },
    { id: 'Goycay Rayonu', name: 'Goycay Rayonu' },
    { id: 'Haciqabul Rayonu', name: 'Haciqabul Rayonu' },
    { id: 'Imisli Rayonu', name: 'Imisli Rayonu' },
    { id: 'Ismayilli Rayonu', name: 'Ismayilli Rayonu' },
    { id: 'Kalbacar Rayonu', name: 'Kalbacar Rayonu' },
    { id: 'Kurdamir Rayonu', name: 'Kurdamir Rayonu' },
    { id: 'Lacin Rayonu', name: 'Lacin Rayonu' },
    { id: 'Lankaran Rayonu', name: 'Lankaran Rayonu' },
    { id: 'Lerik Rayonu', name: 'Lerik Rayonu' },
    { id: 'Masalli Rayonu', name: 'Masalli Rayonu' },
    { id: 'Neftcala Rayonu', name: 'Neftcala Rayonu' },
    { id: 'Oguz Rayonu', name: 'Oguz Rayonu' },
    { id: 'Qabala Rayonu', name: 'Qabala Rayonu' },
    { id: 'Qax Rayonu', name: 'Qax Rayonu' },
    { id: 'Qazax Rayonu', name: 'Qazax Rayonu' },
    { id: 'Qobustan Rayonu', name: 'Qobustan Rayonu' },
    { id: 'Quba Rayonu', name: 'Quba Rayonu' },
    { id: 'Qubadli Rayonu', name: 'Qubadli Rayonu' },
    { id: 'Qusar Rayonu', name: 'Qusar Rayonu' },
    { id: 'Saatli Rayonu', name: 'Saatli Rayonu' },
    { id: 'Sabirabad Rayonu', name: 'Sabirabad Rayonu' },
    { id: 'Saki Rayonu', name: 'Saki Rayonu' },
    { id: 'Salyan Rayonu', name: 'Salyan Rayonu' },
    { id: 'Samaxi Rayonu', name: 'Samaxi Rayonu' },
    { id: 'Samkir Rayonu', name: 'Samkir Rayonu' },
    { id: 'Samux Rayonu', name: 'Samux Rayonu' },
    { id: 'Siyazan Rayonu', name: 'Siyazan Rayonu' },
    { id: 'Susa Rayonu', name: 'Susa Rayonu' },
    { id: 'Tartar Rayonu', name: 'Tartar Rayonu' },
    { id: 'Tovuz Rayonu', name: 'Tovuz Rayonu' },
    { id: 'Ucar Rayonu', name: 'Ucar Rayonu' },
    { id: 'Xacmaz Rayonu', name: 'Xacmaz Rayonu' },
    { id: 'Xanlar Rayonu', name: 'Xanlar Rayonu' },
    { id: 'Xizi Rayonu', name: 'Xizi Rayonu' },
    { id: 'Xocali Rayonu', name: 'Xocali Rayonu' },
    { id: 'Xocavand Rayonu', name: 'Xocavand Rayonu' },
    { id: 'Yardimli Rayonu', name: 'Yardimli Rayonu' },
    { id: 'Yevlax Rayonu', name: 'Yevlax Rayonu' },
    { id: 'Zangilan Rayonu', name: 'Zangilan Rayonu' },
    { id: 'Zaqatala Rayonu', name: 'Zaqatala Rayonu' },
    { id: 'Zardab Rayonu', name: 'Zardab Rayonu' },
    { id: 'Ali Bayramli Sahari', name: 'Ali Bayramli Sahari' },
    { id: 'Baki Sahari', name: 'Baki Sahari' },
    { id: 'Ganca Sahari', name: 'Ganca Sahari' },
    { id: 'Lankaran Sahari', name: 'Lankaran Sahari' },
    { id: 'Mingacevir Sahari', name: 'Mingacevir Sahari' },
    { id: 'Naftalan Sahari', name: 'Naftalan Sahari' },
    { id: 'Saki Sahari', name: 'Saki Sahari' },
    { id: 'Sumqayit Sahari', name: 'Sumqayit Sahari' },
    { id: 'Susa Sahari', name: 'Susa Sahari' },
    { id: 'Xankandi Sahari', name: 'Xankandi Sahari' },
    { id: 'Yevlax Sahari', name: 'Yevlax Sahari' },
    { id: 'Naxcivan Muxtar', name: 'Naxcivan Muxtar' }
  ],
  BS: [
    {
      id: 'Acklins and Crooked Islands',
      name: 'Acklins and Crooked Islands'
    },
    { id: 'Bimini', name: 'Bimini' },
    { id: 'Cat Island', name: 'Cat Island' },
    { id: 'Exuma', name: 'Exuma' },
    { id: 'Freeport', name: 'Freeport' },
    { id: 'Fresh Creek', name: 'Fresh Creek' },
    { id: "Governor's Harbour", name: "Governor's Harbour" },
    { id: 'Green Turtle Cay', name: 'Green Turtle Cay' },
    { id: 'Harbour Island', name: 'Harbour Island' },
    { id: 'High Rock', name: 'High Rock' },
    { id: 'Inagua', name: 'Inagua' },
    { id: 'Kemps Bay', name: 'Kemps Bay' },
    { id: 'Long Island', name: 'Long Island' },
    { id: 'Marsh Harbour', name: 'Marsh Harbour' },
    { id: 'Mayaguana', name: 'Mayaguana' },
    { id: 'New Providence', name: 'New Providence' },
    {
      id: 'Nichollstown and Berry Islands',
      name: 'Nichollstown and Berry Islands'
    },
    { id: 'Ragged Island', name: 'Ragged Island' },
    { id: 'Rock Sound', name: 'Rock Sound' },
    { id: 'Sandy Point', name: 'Sandy Point' },
    { id: 'San Salvador and Rum Cay', name: 'San Salvador and Rum Cay' }
  ],
  BH: [
    { id: 'Al Hadd', name: 'Al Hadd' },
    { id: 'Al Manamah', name: 'Al Manamah' },
    { id: 'Al Mintaqah al Gharbiyah', name: 'Al Mintaqah al Gharbiyah' },
    { id: 'Al Mintaqah al Wusta', name: 'Al Mintaqah al Wusta' },
    {
      id: 'Al Mintaqah ash Shamaliyah',
      name: 'Al Mintaqah ash Shamaliyah'
    },
    { id: 'Al Muharraq', name: 'Al Muharraq' },
    {
      id: "Ar Rifa' wa al Mintaqah al Janubiyah",
      name: "Ar Rifa' wa al Mintaqah al Janubiyah"
    },
    { id: 'Jidd Hafs', name: 'Jidd Hafs' },
    { id: 'Madinat Hamad', name: 'Madinat Hamad' },
    { id: "Madinat 'Isa", name: "Madinat 'Isa" },
    { id: 'Juzur Hawar', name: 'Juzur Hawar' },
    { id: 'Sitrah', name: 'Sitrah' }
  ],
  BD: [
    { id: 'Barisal', name: 'Barisal' },
    { id: 'Chittagong', name: 'Chittagong' },
    { id: 'Dhaka', name: 'Dhaka' },
    { id: 'Khulna', name: 'Khulna' },
    { id: 'Rajshahi', name: 'Rajshahi' },
    { id: 'Sylhet', name: 'Sylhet' }
  ],
  BB: [
    { id: 'Christ Church', name: 'Christ Church' },
    { id: 'Saint Andrew', name: 'Saint Andrew' },
    { id: 'Saint George', name: 'Saint George' },
    { id: 'Saint James', name: 'Saint James' },
    { id: 'Saint John', name: 'Saint John' },
    { id: 'Saint Joseph', name: 'Saint Joseph' },
    { id: 'Saint Lucy', name: 'Saint Lucy' },
    { id: 'Saint Michael', name: 'Saint Michael' },
    { id: 'Saint Peter', name: 'Saint Peter' },
    { id: 'Saint Philip', name: 'Saint Philip' },
    { id: 'Saint Thomas', name: 'Saint Thomas' }
  ],
  BY: [
    { id: 'Brest', name: 'Brest' },
    { id: 'Homyel', name: 'Homyel' },
    { id: 'Horad Minsk', name: 'Horad Minsk' },
    { id: 'Hrodna', name: 'Hrodna' },
    { id: 'Mahilyow', name: 'Mahilyow' },
    { id: 'Minsk', name: 'Minsk' },
    { id: 'Vitsyebsk', name: 'Vitsyebsk' }
  ],
  BE: [
    { id: 'Antwerpen', name: 'Antwerpen' },
    { id: 'Brabant Wallon', name: 'Brabant Wallon' },
    { id: 'Brussels', name: 'Brussels' },
    { id: 'Flanders', name: 'Flanders' },
    { id: 'Hainaut', name: 'Hainaut' },
    { id: 'Liege', name: 'Liege' },
    { id: 'Limburg', name: 'Limburg' },
    { id: 'Luxembourg', name: 'Luxembourg' },
    { id: 'Namur', name: 'Namur' },
    { id: 'Oost-Vlaanderen', name: 'Oost-Vlaanderen' },
    { id: 'Vlaams-Brabant', name: 'Vlaams-Brabant' },
    { id: 'Wallonia', name: 'Wallonia' },
    { id: 'West-Vlaanderen', name: 'West-Vlaanderen' }
  ],
  BZ: [
    { id: 'Belize', name: 'Belize' },
    { id: 'Cayo', name: 'Cayo' },
    { id: 'Corozal', name: 'Corozal' },
    { id: 'Orange Walk', name: 'Orange Walk' },
    { id: 'Stann Creek', name: 'Stann Creek' },
    { id: 'Toledo', name: 'Toledo' }
  ],
  BJ: [
    { id: 'Alibori', name: 'Alibori' },
    { id: 'Atakora', name: 'Atakora' },
    { id: 'Atlantique', name: 'Atlantique' },
    { id: 'Borgou', name: 'Borgou' },
    { id: 'Collines', name: 'Collines' },
    { id: 'Donga', name: 'Donga' },
    { id: 'Kouffo', name: 'Kouffo' },
    { id: 'Littoral', name: 'Littoral' },
    { id: 'Mono', name: 'Mono' },
    { id: 'Oueme', name: 'Oueme' },
    { id: 'Plateau', name: 'Plateau' },
    { id: 'Zou', name: 'Zou' }
  ],
  BM: [
    { id: 'Devonshire', name: 'Devonshire' },
    { id: 'Hamilton', name: 'Hamilton' },
    { id: 'Paget', name: 'Paget' },
    { id: 'Pembroke', name: 'Pembroke' },
    { id: 'Saint George', name: 'Saint George' },
    { id: "Saint George's", name: "Saint George's" },
    { id: 'Sandys', name: 'Sandys' },
    { id: "Smith's", name: "Smith's" },
    { id: 'Southampton', name: 'Southampton' },
    { id: 'Warwick', name: 'Warwick' }
  ],
  BT: [
    { id: 'Bumthang', name: 'Bumthang' },
    { id: 'Chukha', name: 'Chukha' },
    { id: 'Dagana', name: 'Dagana' },
    { id: 'Gasa', name: 'Gasa' },
    { id: 'Haa', name: 'Haa' },
    { id: 'Lhuntse', name: 'Lhuntse' },
    { id: 'Mongar', name: 'Mongar' },
    { id: 'Paro', name: 'Paro' },
    { id: 'Pemagatshel', name: 'Pemagatshel' },
    { id: 'Punakha', name: 'Punakha' },
    { id: 'Samdrup Jongkhar', name: 'Samdrup Jongkhar' },
    { id: 'Samtse', name: 'Samtse' },
    { id: 'Sarpang', name: 'Sarpang' },
    { id: 'Thimphu', name: 'Thimphu' },
    { id: 'Trashigang', name: 'Trashigang' },
    { id: 'Trashiyangste', name: 'Trashiyangste' },
    { id: 'Trongsa', name: 'Trongsa' },
    { id: 'Tsirang', name: 'Tsirang' },
    { id: 'Wangdue Phodrang', name: 'Wangdue Phodrang' },
    { id: 'Zhemgang', name: 'Zhemgang' }
  ],
  BO: [
    { id: 'Chuquisaca', name: 'Chuquisaca' },
    { id: 'Cochabamba', name: 'Cochabamba' },
    { id: 'Beni', name: 'Beni' },
    { id: 'La Paz', name: 'La Paz' },
    { id: 'Oruro', name: 'Oruro' },
    { id: 'Pando', name: 'Pando' },
    { id: 'Potosi', name: 'Potosi' },
    { id: 'Santa Cruz', name: 'Santa Cruz' },
    { id: 'Tarija', name: 'Tarija' }
  ],
  BQ: [
    { id: 'Kralendijk', name: 'Kralendijk' },
    { id: 'Rincón', name: 'Rincón' }
  ],
  BA: [
    { id: 'Una-Sana [Federation]', name: 'Una-Sana [Federation]' },
    { id: 'Posavina [Federation]', name: 'Posavina [Federation]' },
    { id: 'Tuzla [Federation]', name: 'Tuzla [Federation]' },
    { id: 'Zenica-Doboj [Federation]', name: 'Zenica-Doboj [Federation]' },
    {
      id: 'Bosnian Podrinje [Federation]',
      name: 'Bosnian Podrinje [Federation]'
    },
    {
      id: 'Central Bosnia [Federation]',
      name: 'Central Bosnia [Federation]'
    },
    {
      id: 'Herzegovina-Neretva [Federation]',
      name: 'Herzegovina-Neretva [Federation]'
    },
    {
      id: 'West Herzegovina [Federation]',
      name: 'West Herzegovina [Federation]'
    },
    { id: 'Sarajevo [Federation]', name: 'Sarajevo [Federation]' },
    { id: 'West Bosnia [Federation]', name: 'West Bosnia [Federation]' },
    { id: 'Banja Luka [RS]', name: 'Banja Luka [RS]' },
    { id: 'Bijeljina [RS]', name: 'Bijeljina [RS]' },
    { id: 'Doboj [RS]', name: 'Doboj [RS]' },
    { id: 'Fo?a [RS]', name: 'Fo?a [RS]' },
    { id: 'Sarajevo-Romanija [RS]', name: 'Sarajevo-Romanija [RS]' },
    { id: 'Trebinje [RS]', name: 'Trebinje [RS]' },
    { id: 'Vlasenica [RS]', name: 'Vlasenica [RS]' }
  ],
  BW: [
    { id: 'Central', name: 'Central' },
    { id: 'Ghanzi', name: 'Ghanzi' },
    { id: 'Kgalagadi', name: 'Kgalagadi' },
    { id: 'Kgatleng', name: 'Kgatleng' },
    { id: 'Kweneng', name: 'Kweneng' },
    { id: 'North East', name: 'North East' },
    { id: 'North West', name: 'North West' },
    { id: 'South East', name: 'South East' },
    { id: 'Southern', name: 'Southern' }
  ],
  BR: [
    { id: 'Acre', name: 'Acre' },
    { id: 'Alagoas', name: 'Alagoas' },
    { id: 'Amapa', name: 'Amapa' },
    { id: 'Amazonas', name: 'Amazonas' },
    { id: 'Bahia', name: 'Bahia' },
    { id: 'Ceara', name: 'Ceara' },
    { id: 'Distrito Federal', name: 'Distrito Federal' },
    { id: 'Espirito Santo', name: 'Espirito Santo' },
    { id: 'Goias', name: 'Goias' },
    { id: 'Maranhao', name: 'Maranhao' },
    { id: 'Mato Grosso', name: 'Mato Grosso' },
    { id: 'Mato Grosso do Sul', name: 'Mato Grosso do Sul' },
    { id: 'Minas Gerais', name: 'Minas Gerais' },
    { id: 'Para', name: 'Para' },
    { id: 'Paraiba', name: 'Paraiba' },
    { id: 'Parana', name: 'Parana' },
    { id: 'Pernambuco', name: 'Pernambuco' },
    { id: 'Piaui', name: 'Piaui' },
    { id: 'Rio de Janeiro', name: 'Rio de Janeiro' },
    { id: 'Rio Grande do Norte', name: 'Rio Grande do Norte' },
    { id: 'Rio Grande do Sul', name: 'Rio Grande do Sul' },
    { id: 'Rondonia', name: 'Rondonia' },
    { id: 'Roraima', name: 'Roraima' },
    { id: 'Santa Catarina', name: 'Santa Catarina' },
    { id: 'Sao Paulo', name: 'Sao Paulo' },
    { id: 'Sergipe', name: 'Sergipe' },
    { id: 'Tocantins', name: 'Tocantins' }
  ],
  BN: [
    { id: 'Belait', name: 'Belait' },
    { id: 'Brunei and Muara', name: 'Brunei and Muara' },
    { id: 'Temburong', name: 'Temburong' },
    { id: 'Rincón', name: 'Rincón' }
  ],
  BG: [
    { id: 'Blagoevgrad', name: 'Blagoevgrad' },
    { id: 'Burgas', name: 'Burgas' },
    { id: 'Dobrich', name: 'Dobrich' },
    { id: 'Gabrovo', name: 'Gabrovo' },
    { id: 'Khaskovo', name: 'Khaskovo' },
    { id: 'Kurdzhali', name: 'Kurdzhali' },
    { id: 'Kyustendil', name: 'Kyustendil' },
    { id: 'Lovech', name: 'Lovech' },
    { id: 'Montana', name: 'Montana' },
    { id: 'Pazardzhik', name: 'Pazardzhik' },
    { id: 'Pernik', name: 'Pernik' },
    { id: 'Pleven', name: 'Pleven' },
    { id: 'Wallonia', name: 'Wallonia' },
    { id: 'Razgrad', name: 'Razgrad' },
    { id: 'Ruse', name: 'Ruse' },
    { id: 'Shumen', name: 'Shumen' },
    { id: 'Silistra', name: 'Silistra' },
    { id: 'Sliven', name: 'Sliven' },
    { id: 'Smolyan', name: 'Smolyan' },
    { id: 'Sofiya', name: 'Sofiya' },
    { id: 'Sofiya-Grad', name: 'Sofiya-Grad' },
    { id: 'Stara Zagora', name: 'Stara Zagora' },
    { id: 'Turgovishte', name: 'Turgovishte' },
    { id: 'Varna', name: 'Varna' },
    { id: 'Veliko Turnovo', name: 'Veliko Turnovo' },
    { id: 'Vidin', name: 'Vidin' },
    { id: 'Vratsa', name: 'Vratsa' },
    { id: 'Yambol', name: 'Yambol' }
  ],
  BF: [
    { id: 'Bale', name: 'Bale' },
    { id: 'Bam', name: 'Bam' },
    { id: 'Banwa', name: 'Banwa' },
    { id: 'Bazega', name: 'Bazega' },
    { id: 'Bougouriba', name: 'Bougouriba' },
    { id: 'Boulgou', name: 'Boulgou' },
    { id: 'Boulkiemde', name: 'Boulkiemde' },
    { id: 'Comoe', name: 'Comoe' },
    { id: 'Ganzourgou', name: 'Ganzourgou' },
    { id: 'Gnagna', name: 'Gnagna' },
    { id: 'Gourma', name: 'Gourma' },
    { id: 'Houet', name: 'Houet' },
    { id: 'Ioba', name: 'Ioba' },
    { id: 'Kadiogo', name: 'Kadiogo' },
    { id: 'Kenedougou', name: 'Kenedougou' },
    { id: 'Komondjari', name: 'Komondjari' },
    { id: 'Kompienga', name: 'Kompienga' },
    { id: 'Kossi', name: 'Kossi' },
    { id: 'Koulpelogo', name: 'Koulpelogo' },
    { id: 'Kouritenga', name: 'Kouritenga' },
    { id: 'Kourweogo', name: 'Kourweogo' },
    { id: 'Leraba', name: 'Leraba' },
    { id: 'Loroum', name: 'Loroum' },
    { id: 'Mouhoun', name: 'Mouhoun' },
    { id: 'Namentenga', name: 'Namentenga' },
    { id: 'Nahouri', name: 'Nahouri' },
    { id: 'Nayala', name: 'Nayala' },
    { id: 'Noumbiel', name: 'Noumbiel' },
    { id: 'Oubritenga', name: 'Oubritenga' },
    { id: 'Oudalan', name: 'Oudalan' },
    { id: 'Passore', name: 'Passore' },
    { id: 'Poni', name: 'Poni' },
    { id: 'Sanguie', name: 'Sanguie' },
    { id: 'Sanmatenga', name: 'Sanmatenga' },
    { id: 'Seno', name: 'Seno' },
    { id: 'Sissili', name: 'Sissili' },
    { id: 'Soum', name: 'Soum' },
    { id: 'Sourou', name: 'Sourou' },
    { id: 'Tapoa', name: 'Tapoa' },
    { id: 'Tuy', name: 'Tuy' },
    { id: 'Yagha', name: 'Yagha' },
    { id: 'Yatenga', name: 'Yatenga' },
    { id: 'Ziro', name: 'Ziro' },
    { id: 'Zondoma', name: 'Zondoma' },
    { id: 'Zoundweogo', name: 'Zoundweogo' }
  ],
  BI: [
    { id: 'Bubanza', name: 'Bubanza' },
    { id: 'Bujumbura Mairie', name: 'Bujumbura Mairie' },
    { id: 'Bujumbura Rural', name: 'Bujumbura Rural' },
    { id: 'Bururi', name: 'Bururi' },
    { id: 'Cankuzo', name: 'Cankuzo' },
    { id: 'Cibitoke', name: 'Cibitoke' },
    { id: 'Gitega', name: 'Gitega' },
    { id: 'Karuzi', name: 'Karuzi' },
    { id: 'Kayanza', name: 'Kayanza' },
    { id: 'Kirundo', name: 'Kirundo' },
    { id: 'Makamba', name: 'Makamba' },
    { id: 'Muramvya', name: 'Muramvya' },
    { id: 'Muyinga', name: 'Muyinga' },
    { id: 'Mwaro', name: 'Mwaro' },
    { id: 'Ngozi', name: 'Ngozi' },
    { id: 'Rutana', name: 'Rutana' },
    { id: 'Ruyigi', name: 'Ruyigi' }
  ],
  KH: [
    { id: 'Banteay Mean Chey', name: 'Banteay Mean Chey' },
    { id: 'Batdambang', name: 'Batdambang' },
    { id: 'Kampong Cham', name: 'Kampong Cham' },
    { id: 'Kampong Chhnang', name: 'Kampong Chhnang' },
    { id: 'Kampong Spoe', name: 'Kampong Spoe' },
    { id: 'Kampong Thum', name: 'Kampong Thum' },
    { id: 'Kampot', name: 'Kampot' },
    { id: 'Kandal', name: 'Kandal' },
    { id: 'Koh Kong', name: 'Koh Kong' },
    { id: 'Kracheh', name: 'Kracheh' },
    { id: 'Mondol Kiri', name: 'Mondol Kiri' },
    { id: 'Otdar Mean Chey', name: 'Otdar Mean Chey' },
    { id: 'Pouthisat', name: 'Pouthisat' },
    { id: 'Preah Vihear', name: 'Preah Vihear' },
    { id: 'Prey Veng', name: 'Prey Veng' },
    { id: 'Rotanakir', name: 'Rotanakir' },
    { id: 'Siem Reab', name: 'Siem Reab' },
    { id: 'Stoeng Treng', name: 'Stoeng Treng' },
    { id: 'Svay Rieng', name: 'Svay Rieng' },
    { id: 'Takao', name: 'Takao' },
    { id: 'Keb', name: 'Keb' },
    { id: 'Pailin', name: 'Pailin' },
    { id: 'Phnom Penh', name: 'Phnom Penh' },
    { id: 'Preah Seihanu', name: 'Preah Seihanu' }
  ],
  CM: [
    { id: 'Adamaoua', name: 'Adamaoua' },
    { id: 'Centre', name: 'Centre' },
    { id: 'Est', name: 'Est' },
    { id: 'Extreme-Nord', name: 'Extreme-Nord' },
    { id: 'Littoral', name: 'Littoral' },
    { id: 'Nord', name: 'Nord' },
    { id: 'Nord-Ouest', name: 'Nord-Ouest' },
    { id: 'Ouest', name: 'Ouest' },
    { id: 'Sud', name: 'Sud' },
    { id: 'Sud-Ouest', name: 'Sud-Ouest' }
  ],
  CA: [
    { id: 'Alberta', name: 'Alberta' },
    { id: 'British Columbia', name: 'British Columbia' },
    { id: 'Manitoba', name: 'Manitoba' },
    { id: 'New Brunswick', name: 'New Brunswick' },
    { id: 'Newfoundland and Labrador', name: 'Newfoundland and Labrador' },
    { id: 'Northwest Territories', name: 'Northwest Territories' },
    { id: 'Nova Scotia', name: 'Nova Scotia' },
    { id: 'Nunavut', name: 'Nunavut' },
    { id: 'Ontario', name: 'Ontario' },
    { id: 'Prince Edward Island', name: 'Prince Edward Island' },
    { id: 'Quebec', name: 'Quebec' },
    { id: 'Yukon Territory', name: 'Yukon Territory' }
  ],
  CV: [
    { id: 'Boavista', name: 'Boavista' },
    { id: 'Branco', name: 'Branco' },
    { id: 'Brava', name: 'Brava' },
    { id: 'Fogo', name: 'Fogo' },
    { id: 'Maio', name: 'Maio' },
    { id: 'Raso', name: 'Raso' },
    { id: 'Sal', name: 'Sal' },
    { id: 'Santa Luzia', name: 'Santa Luzia' },
    { id: 'Santiago', name: 'Santiago' },
    { id: 'Santo Antão', name: 'Santo Antão' },
    { id: 'São Nicolau', name: 'São Nicolau' },
    { id: 'São Vicente', name: 'São Vicente' }
  ],
  KY: [
    { id: 'George Town', name: 'George Town' },
    { id: 'West Bay', name: 'West Bay' },
    { id: 'Bodden Town', name: 'Bodden Town' },
    { id: 'North Side', name: 'North Side' },
    { id: 'East End', name: 'East End' },
    { id: 'Cayman Brac', name: 'Cayman Brac' },
    { id: 'Little Cayman', name: 'Little Cayman' }
  ],
  CF: [
    { id: 'Bamingui-Bangoran', name: 'Bamingui-Bangoran' },
    { id: 'Bangui', name: 'Bangui' },
    { id: 'Basse-Kotto', name: 'Basse-Kotto' },
    { id: 'Haute-Kotto', name: 'Haute-Kotto' },
    { id: 'Haut-Mbomou', name: 'Haut-Mbomou' },
    { id: 'Kemo', name: 'Kemo' },
    { id: 'Lobaye', name: 'Lobaye' },
    { id: 'Mambere-Kadei', name: 'Mambere-Kadei' },
    { id: 'Mbomou', name: 'Mbomou' },
    { id: 'Nana-Grebizi', name: 'Nana-Grebizi' },
    { id: 'Nana-Mambere', name: 'Nana-Mambere' },
    { id: 'Ombella-Mpoko', name: 'Ombella-Mpoko' },
    { id: 'Ouaka', name: 'Ouaka' },
    { id: 'Ouham', name: 'Ouham' },
    { id: 'Ouham-Pende', name: 'Ouham-Pende' },
    { id: 'Sangha-Mbaere', name: 'Sangha-Mbaere' },
    { id: 'Vakaga', name: 'Vakaga' }
  ],
  TD: [
    { id: 'Batha', name: 'Batha' },
    { id: 'Biltine', name: 'Biltine' },
    { id: 'Borkou-Ennedi-Tibesti', name: 'Borkou-Ennedi-Tibesti' },
    { id: 'Chari-Baguirmi', name: 'Chari-Baguirmi' },
    { id: 'Guéra', name: 'Guéra' },
    { id: 'Kanem', name: 'Kanem' },
    { id: 'Lac', name: 'Lac' },
    { id: 'Logone Occidental', name: 'Logone Occidental' },
    { id: 'Logone Oriental', name: 'Logone Oriental' },
    { id: 'Mayo-Kebbi', name: 'Mayo-Kebbi' },
    { id: 'Moyen-Chari', name: 'Moyen-Chari' },
    { id: 'Ouaddaï', name: 'Ouaddaï' },
    { id: 'Salamat', name: 'Salamat' },
    { id: 'Tandjile', name: 'Tandjile' }
  ],
  CL: [
    { id: 'Aysen', name: 'Aysen' },
    { id: 'Antofagasta', name: 'Antofagasta' },
    { id: 'Araucania', name: 'Araucania' },
    { id: 'Atacama', name: 'Atacama' },
    { id: 'Bio-Bio', name: 'Bio-Bio' },
    { id: 'Coquimbo', name: 'Coquimbo' },
    { id: "O'Higgins", name: "O'Higgins" },
    { id: 'Los Lagos', name: 'Los Lagos' },
    {
      id: 'Magallanes y la Antartica Chilena',
      name: 'Magallanes y la Antartica Chilena'
    },
    { id: 'Maule', name: 'Maule' },
    {
      id: 'Santiago Region Metropolitana',
      name: 'Santiago Region Metropolitana'
    },
    { id: 'Tarapaca', name: 'Tarapaca' },
    { id: 'Valparaiso', name: 'Valparaiso' }
  ],
  CN: [
    { id: 'Anhui', name: 'Anhui' },
    { id: 'Fujian', name: 'Fujian' },
    { id: 'Gansu', name: 'Gansu' },
    { id: 'Guangdong', name: 'Guangdong' },
    { id: 'Guizhou', name: 'Guizhou' },
    { id: 'Hainan', name: 'Hainan' },
    { id: 'Hebei', name: 'Hebei' },
    { id: 'Heilongjiang', name: 'Heilongjiang' },
    { id: 'Henan', name: 'Henan' },
    { id: 'Hubei', name: 'Hubei' },
    { id: 'Hunan', name: 'Hunan' },
    { id: 'Jiangsu', name: 'Jiangsu' },
    { id: 'Jiangxi', name: 'Jiangxi' },
    { id: 'Jilin', name: 'Jilin' },
    { id: 'Liaoning', name: 'Liaoning' },
    { id: 'Qinghai', name: 'Qinghai' },
    { id: 'Shaanxi', name: 'Shaanxi' },
    { id: 'Shandong', name: 'Shandong' },
    { id: 'Shanxi', name: 'Shanxi' },
    { id: 'Sichuan', name: 'Sichuan' },
    { id: 'Yunnan', name: 'Yunnan' },
    { id: 'Zhejiang', name: 'Zhejiang' },
    { id: 'Guangxi', name: 'Guangxi' },
    { id: 'Nei Mongol', name: 'Nei Mongol' },
    { id: 'Ningxia', name: 'Ningxia' },
    { id: 'Xinjiang', name: 'Xinjiang' },
    { id: 'Xizang (Tibet)', name: 'Xizang (Tibet)' },
    { id: 'Beijing', name: 'Beijing' },
    { id: 'Chongqing', name: 'Chongqing' },
    { id: 'Shanghai', name: 'Shanghai' },
    { id: 'Tianjin', name: 'Tianjin' }
  ],
  CO: [
    { id: 'Amazonas', name: 'Amazonas' },
    { id: 'Antioquia', name: 'Antioquia' },
    { id: 'Arauca', name: 'Arauca' },
    { id: 'Atlantico', name: 'Atlantico' },
    { id: 'Bogota District Capital', name: 'Bogota District Capital' },
    { id: 'Bolivar', name: 'Bolivar' },
    { id: 'Boyaca', name: 'Boyaca' },
    { id: 'Caldas', name: 'Caldas' },
    { id: 'Caqueta', name: 'Caqueta' },
    { id: 'Casanare', name: 'Casanare' },
    { id: 'Cauca', name: 'Cauca' },
    { id: 'Cesar', name: 'Cesar' },
    { id: 'Choco', name: 'Choco' },
    { id: 'Cordoba', name: 'Cordoba' },
    { id: 'Cundinamarca', name: 'Cundinamarca' },
    { id: 'Guainia', name: 'Guainia' },
    { id: 'Guaviare', name: 'Guaviare' },
    { id: 'Huila', name: 'Huila' },
    { id: 'La Guajira', name: 'La Guajira' },
    { id: 'Magdalena', name: 'Magdalena' },
    { id: 'Meta', name: 'Meta' },
    { id: 'Narino', name: 'Narino' },
    { id: 'Norte de Santander', name: 'Norte de Santander' },
    { id: 'Putumayo', name: 'Putumayo' },
    { id: 'Quindio', name: 'Quindio' },
    { id: 'Risaralda', name: 'Risaralda' },
    { id: 'San Andres & Providencia', name: 'San Andres & Providencia' },
    { id: 'Santander', name: 'Santander' },
    { id: 'Sucre', name: 'Sucre' },
    { id: 'Tolima', name: 'Tolima' },
    { id: 'Valle del Cauca', name: 'Valle del Cauca' },
    { id: 'Vaupes', name: 'Vaupes' },
    { id: 'Vichada', name: 'Vichada' }
  ],
  CD: [
    { id: 'Bandundu', name: 'Bandundu' },
    { id: 'Bas-Congo', name: 'Bas-Congo' },
    { id: 'Equateur', name: 'Equateur' },
    { id: 'Kasai-Occidental', name: 'Kasai-Occidental' },
    { id: 'Kasai-Oriental', name: 'Kasai-Oriental' },
    { id: 'Katanga', name: 'Katanga' },
    { id: 'Kinshasa', name: 'Kinshasa' },
    { id: 'Maniema', name: 'Maniema' },
    { id: 'Nord-Kivu', name: 'Nord-Kivu' },
    { id: 'Orientale', name: 'Orientale' },
    { id: 'Sud-Kivu', name: 'Sud-Kivu' }
  ],
  CK: [
    { id: 'Atiu', name: 'Atiu' },
    { id: 'Aitutaki', name: 'Aitutaki' },
    { id: 'Manuae', name: 'Manuae' },
    { id: 'Manihiki', name: 'Manihiki' },
    { id: 'Mauke', name: 'Mauke' },
    { id: 'Mangaia', name: 'Mangaia' },
    { id: 'Penrhyn', name: 'Penrhyn' },
    { id: 'Palmerston', name: 'Palmerston' },
    { id: 'Pukapuka', name: 'Pukapuka' },
    { id: 'Nassau', name: 'Nassau' },
    { id: 'Rarotonga', name: 'Rarotonga' },
    { id: 'Rakahanga', name: 'Rakahanga' },
    { id: 'Suwarrow', name: 'Suwarrow' },
    { id: 'Tema Reef', name: 'Tema Reef' },
    { id: 'Takutea', name: 'Takutea' }
  ],
  CG: [
    { id: 'Bouenza', name: 'Bouenza' },
    { id: 'Brazzaville', name: 'Brazzaville' },
    { id: 'Cuvette', name: 'Cuvette' },
    { id: 'Cuvette-Ouest', name: 'Cuvette-Ouest' },
    { id: 'Kouilou', name: 'Kouilou' },
    { id: 'Lekoumou', name: 'Lekoumou' },
    { id: 'Likouala', name: 'Likouala' },
    { id: 'Niari', name: 'Niari' },
    { id: 'Plateaux', name: 'Plateaux' },
    { id: 'Pool', name: 'Pool' },
    { id: 'Sangha', name: 'Sangha' }
  ],
  CR: [
    { id: 'Alajuela', name: 'Alajuela' },
    { id: 'Cartago', name: 'Cartago' },
    { id: 'Guanacaste', name: 'Guanacaste' },
    { id: 'Heredia', name: 'Heredia' },
    { id: 'Limon', name: 'Limon' },
    { id: 'Puntarenas', name: 'Puntarenas' },
    { id: 'San Jose', name: 'San Jose' }
  ],
  CI: [
    { id: 'Abidjan', name: 'Abidjan' },
    { id: 'Bas-Sassandra', name: 'Bas-Sassandra' },
    { id: 'Comoé', name: 'Comoé' },
    { id: 'Denguélé', name: 'Denguélé' },
    { id: 'Gôh-Djiboua', name: 'Gôh-Djiboua' },
    { id: 'Lacs', name: 'Lacs' },
    { id: 'Lagunes', name: 'Lagunes' },
    { id: 'Montagnes', name: 'Montagnes' },
    { id: 'Sassandra-marahoué', name: 'Sassandra-marahoué' },
    { id: 'Savanes', name: 'Savanes' },
    { id: 'Vallée du Bandama', name: 'Vallée du Bandama' },
    { id: 'Woroba', name: 'Woroba' },
    { id: 'Yamoussoukro', name: 'Yamoussoukro' },
    { id: 'Zanzan', name: 'Zanzan' }
  ],
  HR: [
    { id: 'Bjelovarsko-Bilogorska', name: 'Bjelovarsko-Bilogorska' },
    { id: 'Brodsko-Posavska', name: 'Brodsko-Posavska' },
    { id: 'Dubrovacko-Neretvanska', name: 'Dubrovacko-Neretvanska' },
    { id: 'Istarska', name: 'Istarska' },
    { id: 'Karlovacka', name: 'Karlovacka' },
    { id: 'Koprivnicko-Krizevacka', name: 'Koprivnicko-Krizevacka' },
    { id: 'Krapinsko-Zagorska', name: 'Krapinsko-Zagorska' },
    { id: 'Licko-Senjska', name: 'Licko-Senjska' },
    { id: 'Medimurska', name: 'Medimurska' },
    { id: 'Osjecko-Baranjska', name: 'Osjecko-Baranjska' },
    { id: 'Pozesko-Slavonska', name: 'Pozesko-Slavonska' },
    { id: 'Primorsko-Goranska', name: 'Primorsko-Goranska' },
    { id: 'Sibensko-Kninska', name: 'Sibensko-Kninska' },
    { id: 'Sisacko-Moslavacka', name: 'Sisacko-Moslavacka' },
    { id: 'Splitsko-Dalmatinska', name: 'Splitsko-Dalmatinska' },
    { id: 'Varazdinska', name: 'Varazdinska' },
    { id: 'Viroviticko-Podravska', name: 'Viroviticko-Podravska' },
    { id: 'Vukovarsko-Srijemska', name: 'Vukovarsko-Srijemska' },
    { id: 'Zadarska', name: 'Zadarska' },
    { id: 'Zagreb', name: 'Zagreb' },
    { id: 'Zagrebacka', name: 'Zagrebacka' }
  ],
  CU: [
    { id: 'Camaguey', name: 'Camaguey' },
    { id: 'Ciego de Avila', name: 'Ciego de Avila' },
    { id: 'Cienfuegos', name: 'Cienfuegos' },
    { id: 'Ciudad de La Habana', name: 'Ciudad de La Habana' },
    { id: 'Granma', name: 'Granma' },
    { id: 'Guantanamo', name: 'Guantanamo' },
    { id: 'Holguin', name: 'Holguin' },
    { id: 'Isla de la Juventud', name: 'Isla de la Juventud' },
    { id: 'La Habana', name: 'La Habana' },
    { id: 'Las Tunas', name: 'Las Tunas' },
    { id: 'Matanzas', name: 'Matanzas' },
    { id: 'Pinar del Rio', name: 'Pinar del Rio' },
    { id: 'Sancti Spiritus', name: 'Sancti Spiritus' },
    { id: 'Santiago de Cuba', name: 'Santiago de Cuba' },
    { id: 'Villa Clara', name: 'Villa Clara' }
  ],
  CW: [
    { id: 'Willemstad', name: 'Willemstad' },
    { id: 'Barber', name: 'Barber' },
    { id: 'Boca Samí', name: 'Boca Samí' },
    { id: 'Brievengat', name: 'Brievengat' },
    { id: 'Saliña', name: 'Saliña' },
    { id: 'Santa Catarina', name: 'Santa Catarina' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Sint Michiel', name: 'Sint Michiel' },
    { id: 'Sint willibrordus', name: 'Sint willibrordus' },
    { id: 'Soto', name: 'Soto' },
    { id: 'Westpunt', name: 'Westpunt' }
  ],
  CY: [
    { id: 'Famagusta', name: 'Famagusta' },
    { id: 'Kyrenia', name: 'Kyrenia' },
    { id: 'Larnaca', name: 'Larnaca' },
    { id: 'Limassol', name: 'Limassol' },
    { id: 'Nicosia', name: 'Nicosia' },
    { id: 'Paphos', name: 'Paphos' }
  ],
  CZ: [
    { id: 'Jihocesky Kraj', name: 'Jihocesky Kraj' },
    { id: 'Jihomoravsky Kraj', name: 'Jihomoravsky Kraj' },
    { id: 'Karlovarsky Kraj', name: 'Karlovarsky Kraj' },
    { id: 'Kralovehradecky Kraj', name: 'Kralovehradecky Kraj' },
    { id: 'Liberecky Kraj', name: 'Liberecky Kraj' },
    { id: 'Moravskoslezsky Kraj', name: 'Moravskoslezsky Kraj' },
    { id: 'Olomoucky Kraj', name: 'Olomoucky Kraj' },
    { id: 'Pardubicky Kraj', name: 'Pardubicky Kraj' },
    { id: 'Plzensky Kraj', name: 'Plzensky Kraj' },
    { id: 'Praha', name: 'Praha' },
    { id: 'Stredocesky Kraj', name: 'Stredocesky Kraj' },
    { id: 'Ustecky Kraj', name: 'Ustecky Kraj' },
    { id: 'Vysocina', name: 'Vysocina' },
    { id: 'Zlinsky Kraj', name: 'Zlinsky Kraj' }
  ],
  DK: [
    { id: 'Arhus', name: 'Arhus' },
    { id: 'Bornholm', name: 'Bornholm' },
    { id: 'Frederiksberg', name: 'Frederiksberg' },
    { id: 'Frederiksborg', name: 'Frederiksborg' },
    { id: 'Fyn', name: 'Fyn' },
    { id: 'Kobenhavn', name: 'Kobenhavn' },
    { id: 'Kobenhavns', name: 'Kobenhavns' },
    { id: 'Nordjylland', name: 'Nordjylland' },
    { id: 'Ribe', name: 'Ribe' },
    { id: 'Ringkobing', name: 'Ringkobing' },
    { id: 'Roskilde', name: 'Roskilde' },
    { id: 'Sonderjylland', name: 'Sonderjylland' },
    { id: 'Storstrom', name: 'Storstrom' },
    { id: 'Vejle', name: 'Vejle' },
    { id: 'Vestsjalland', name: 'Vestsjalland' },
    { id: 'Viborg', name: 'Viborg' }
  ],
  DJ: [
    { id: 'Ali Sabih', name: 'Ali Sabih' },
    { id: 'Dikhil', name: 'Dikhil' },
    { id: 'Djibouti', name: 'Djibouti' },
    { id: 'Obock', name: 'Obock' },
    { id: 'Tadjoura', name: 'Tadjoura' }
  ],
  DM: [
    { id: 'Saint Andrew', name: 'Saint Andrew' },
    { id: 'Saint David', name: 'Saint David' },
    { id: 'Saint George', name: 'Saint George' },
    { id: 'Saint John', name: 'Saint John' },
    { id: 'Saint Joseph', name: 'Saint Joseph' },
    { id: 'Saint Luke', name: 'Saint Luke' },
    { id: 'Saint Mark', name: 'Saint Mark' },
    { id: 'Saint Patrick', name: 'Saint Patrick' },
    { id: 'Saint Paul', name: 'Saint Paul' },
    { id: 'Saint Peter', name: 'Saint Peter' }
  ],
  DO: [
    { id: 'Azua', name: 'Azua' },
    { id: 'Baoruco', name: 'Baoruco' },
    { id: 'Barahona', name: 'Barahona' },
    { id: 'Dajabon', name: 'Dajabon' },
    { id: 'Distrito Nacional', name: 'Distrito Nacional' },
    { id: 'Duarte', name: 'Duarte' },
    { id: 'Elias Pina', name: 'Elias Pina' },
    { id: 'El Seibo', name: 'El Seibo' },
    { id: 'Espaillat', name: 'Espaillat' },
    { id: 'Hato Mayor', name: 'Hato Mayor' },
    { id: 'Independencia', name: 'Independencia' },
    { id: 'La Altagracia', name: 'La Altagracia' },
    { id: 'La Romana', name: 'La Romana' },
    { id: 'La Vega', name: 'La Vega' },
    { id: 'Maria Trinidad Sanchez', name: 'Maria Trinidad Sanchez' },
    { id: 'Monsenor Nouel', name: 'Monsenor Nouel' },
    { id: 'Monte Cristi', name: 'Monte Cristi' },
    { id: 'Monte Plata', name: 'Monte Plata' },
    { id: 'Pedernales', name: 'Pedernales' },
    { id: 'Peravia', name: 'Peravia' },
    { id: 'Puerto Plata', name: 'Puerto Plata' },
    { id: 'Salcedo', name: 'Salcedo' },
    { id: 'Samana', name: 'Samana' },
    { id: 'Sanchez Ramirez', name: 'Sanchez Ramirez' },
    { id: 'San Cristobal', name: 'San Cristobal' },
    { id: 'San Jose de Ocoa', name: 'San Jose de Ocoa' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'San Pedro de Macoris', name: 'San Pedro de Macoris' },
    { id: 'Santiago', name: 'Santiago' },
    { id: 'Santiago Rodriguez', name: 'Santiago Rodriguez' },
    { id: 'Santo Domingo', name: 'Santo Domingo' },
    { id: 'Valverde', name: 'Valverde' }
  ],
  EC: [
    { id: 'Azuay', name: 'Azuay' },
    { id: 'Bolivar', name: 'Bolivar' },
    { id: 'Canar', name: 'Canar' },
    { id: 'Carchi', name: 'Carchi' },
    { id: 'Chimborazo', name: 'Chimborazo' },
    { id: 'Cotopaxi', name: 'Cotopaxi' },
    { id: 'El Oro', name: 'El Oro' },
    { id: 'Esmeraldas', name: 'Esmeraldas' },
    { id: 'Galapagos', name: 'Galapagos' },
    { id: 'Guayas', name: 'Guayas' },
    { id: 'Imbabura', name: 'Imbabura' },
    { id: 'Loja', name: 'Loja' },
    { id: 'Los Rios', name: 'Los Rios' },
    { id: 'Manabi', name: 'Manabi' },
    { id: 'Morona-Santiago', name: 'Morona-Santiago' },
    { id: 'Napo', name: 'Napo' },
    { id: 'Orellana', name: 'Orellana' },
    { id: 'Pastaza', name: 'Pastaza' },
    { id: 'Pichincha', name: 'Pichincha' },
    { id: 'Sucumbios', name: 'Sucumbios' },
    { id: 'Tungurahua', name: 'Tungurahua' },
    { id: 'Zamora-Chinchipe', name: 'Zamora-Chinchipe' }
  ],
  EG: [
    { id: 'Ad Daqahliyah', name: 'Ad Daqahliyah' },
    { id: 'Al Bahr al Ahmar', name: 'Al Bahr al Ahmar' },
    { id: 'Al Buhayrah', name: 'Al Buhayrah' },
    { id: 'Al Fayyum', name: 'Al Fayyum' },
    { id: 'Al Gharbiyah', name: 'Al Gharbiyah' },
    { id: 'Al Iskandariyah', name: 'Al Iskandariyah' },
    { id: "Al Isma'iliyah", name: "Al Isma'iliyah" },
    { id: 'Al Jizah', name: 'Al Jizah' },
    { id: 'Al Minufiyah', name: 'Al Minufiyah' },
    { id: 'Al Minya', name: 'Al Minya' },
    { id: 'Al Qahirah', name: 'Al Qahirah' },
    { id: 'Al Qalyubiyah', name: 'Al Qalyubiyah' },
    { id: 'Al Wadi al Jadid', name: 'Al Wadi al Jadid' },
    { id: 'Ash Sharqiyah', name: 'Ash Sharqiyah' },
    { id: 'As Suways', name: 'As Suways' },
    { id: 'Aswan', name: 'Aswan' },
    { id: 'Asyut', name: 'Asyut' },
    { id: 'Bani Suwayf', name: 'Bani Suwayf' },
    { id: "Bur Sa'id", name: "Bur Sa'id" },
    { id: 'Dumyat', name: 'Dumyat' },
    { id: "Janub Sina'", name: "Janub Sina'" },
    { id: 'Kafr ash Shaykh', name: 'Kafr ash Shaykh' },
    { id: 'Matruh', name: 'Matruh' },
    { id: 'Qina', name: 'Qina' },
    { id: "Shamal Sina'", name: "Shamal Sina'" },
    { id: 'Suhaj', name: 'Suhaj' }
  ],
  SV: [
    { id: 'Ahuachapan', name: 'Ahuachapan' },
    { id: 'Cabanas', name: 'Cabanas' },
    { id: 'Chalatenango', name: 'Chalatenango' },
    { id: 'Cuscatlan', name: 'Cuscatlan' },
    { id: 'La Libertad', name: 'La Libertad' },
    { id: 'La Paz', name: 'La Paz' },
    { id: 'La Union', name: 'La Union' },
    { id: 'Morazan', name: 'Morazan' },
    { id: 'San Migue', name: 'San Migue' },
    { id: 'San Salvador', name: 'San Salvador' },
    { id: 'Santa Ana', name: 'Santa Ana' },
    { id: 'San Vicente', name: 'San Vicente' },
    { id: 'Sonsonate', name: 'Sonsonate' },
    { id: 'Usulutan', name: 'Usulutan' }
  ],
  CQ: [
    { id: 'Annobon', name: 'Annobon' },
    { id: 'Bioko Norte', name: 'Bioko Norte' },
    { id: 'Bioko Sur', name: 'Bioko Sur' },
    { id: 'Centro Sur', name: 'Centro Sur' },
    { id: 'Kie-Ntem', name: 'Kie-Ntem' },
    { id: 'Litoral', name: 'Litoral' },
    { id: 'Wele-Nzas', name: 'Wele-Nzas' }
  ],
  ER: [
    { id: 'Anseba', name: 'Anseba' },
    { id: 'Debub', name: 'Debub' },
    { id: "Debubawi K'eyih Bahri", name: "Debubawi K'eyih Bahri" },
    { id: 'Gash Barka', name: 'Gash Barka' },
    { id: "Ma'akel", name: "Ma'akel" },
    { id: 'Semenawi Keyih Bahri', name: 'Semenawi Keyih Bahri' }
  ],
  EE: [
    { id: 'Harjumaa (Tallinn)', name: 'Harjumaa (Tallinn)' },
    { id: 'Hiiumaa (Kardla)', name: 'Hiiumaa (Kardla)' },
    { id: 'Ida-Virumaa (Johvi)', name: 'Ida-Virumaa (Johvi)' },
    { id: 'Jarvamaa (Paide)', name: 'Jarvamaa (Paide)' },
    { id: 'Jogevamaa (Jogeva)', name: 'Jogevamaa (Jogeva)' },
    { id: 'Laanemaa (Haapsalu)', name: 'Laanemaa (Haapsalu)' },
    { id: 'Laane-Virumaa (Rakvere)', name: 'Laane-Virumaa (Rakvere)' },
    { id: 'Parnumaa (Parnu)', name: 'Parnumaa (Parnu)' },
    { id: 'Polvamaa (Polva)', name: 'Polvamaa (Polva)' },
    { id: 'Raplamaa (Rapla)', name: 'Raplamaa (Rapla)' },
    { id: 'Saaremaa (Kuressaare)', name: 'Saaremaa (Kuressaare)' },
    { id: 'Tartumaa (Tartu)', name: 'Tartumaa (Tartu)' },
    { id: 'Valgamaa (Valga)', name: 'Valgamaa (Valga)' },
    { id: 'Viljandimaa (Viljandi)', name: 'Viljandimaa (Viljandi)' },
    { id: 'Vorumaa (Voru)', name: 'Vorumaa (Voru)' }
  ],
  ET: [
    { id: 'Addis Ababa', name: 'Addis Ababa' },
    { id: 'Afar', name: 'Afar' },
    { id: 'Amhara', name: 'Amhara' },
    { id: 'Binshangul Gumuz', name: 'Binshangul Gumuz' },
    { id: 'Dire Dawa', name: 'Dire Dawa' },
    { id: 'Gambela Hizboch', name: 'Gambela Hizboch' },
    { id: 'Harari', name: 'Harari' },
    { id: 'Oromia', name: 'Oromia' },
    { id: 'Somali', name: 'Somali' },
    { id: 'Tigray', name: 'Tigray' },
    {
      id: 'Southern Nations, Nationalities, and Peoples Region',
      name: 'Southern Nations, Nationalities, and Peoples Region'
    }
  ],
  FK: [
    { id: 'East Falkland', name: 'East Falkland' },
    { id: 'West Falkland', name: 'West Falkland' }
  ],
  FO: [
    { id: 'Eysturoyar sýsla', name: 'Eysturoyar sýsla' },
    { id: 'Norðoyar sýsla', name: 'Norðoyar sýsla' },
    { id: 'Sandoyar sýsla', name: 'Sandoyar sýsla' },
    { id: 'Streymoyar sýsla', name: 'Streymoyar sýsla' },
    { id: 'Suðuroyar sýsla', name: 'Suðuroyar sýsla' },
    { id: 'Vága sýsla', name: 'Vága sýsla' }
  ],
  FJ: [
    { id: 'Central (Suva)', name: 'Central (Suva)' },
    { id: 'Eastern (Levuka)', name: 'Eastern (Levuka)' },
    { id: 'Northern (Labasa)', name: 'Northern (Labasa)' },
    { id: 'Rotuma', name: 'Rotuma' },
    { id: 'Western (Lautoka)', name: 'Western (Lautoka)' }
  ],
  FI: [
    { id: 'Aland', name: 'Aland' },
    { id: 'Etela-Suomen Laani', name: 'Etela-Suomen Laani' },
    { id: 'Ita-Suomen Laani', name: 'Ita-Suomen Laani' },
    { id: 'Lansi-Suomen Laani', name: 'Lansi-Suomen Laani' },
    { id: 'Lappi', name: 'Lappi' },
    { id: 'Oulun Laani', name: 'Oulun Laani' }
  ],
  FR: [
    { id: 'Alsace', name: 'Alsace' },
    { id: 'Aquitaine', name: 'Aquitaine' },
    { id: 'Auvergne', name: 'Auvergne' },
    { id: 'Basse-Normandie', name: 'Basse-Normandie' },
    { id: 'Bourgogne', name: 'Bourgogne' },
    { id: 'Bretagne', name: 'Bretagne' },
    { id: 'Centre', name: 'Centre' },
    { id: 'Champagne-Ardenne', name: 'Champagne-Ardenne' },
    { id: 'Corse', name: 'Corse' },
    { id: 'Franche-Comte', name: 'Franche-Comte' },
    { id: 'Haute-Normandie', name: 'Haute-Normandie' },
    { id: 'Ile-de-France', name: 'Ile-de-France' },
    { id: 'Corse', name: 'Corse' },
    { id: 'Languedoc-Roussillon', name: 'Languedoc-Roussillon' },
    { id: 'Limousin', name: 'Limousin' },
    { id: 'Lorraine', name: 'Lorraine' },
    { id: 'Midi-Pyrenees', name: 'Midi-Pyrenees' },
    { id: 'Nord-Pas-de-Calais', name: 'Nord-Pas-de-Calais' },
    { id: 'Pays de la Loire', name: 'Pays de la Loire' },
    { id: 'Picardie', name: 'Picardie' },
    { id: 'Poitou-Charentes', name: 'Poitou-Charentes' },
    {
      id: "Provence-Alpes-Cote d'Azur",
      name: "Provence-Alpes-Cote d'Azur"
    },
    { id: 'Rhone-Alpes', name: 'Rhone-Alpes' }
  ],
  GM: [
    { id: 'Banjul', name: 'Banjul' },
    { id: 'Central River', name: 'Central River' },
    { id: 'Lower River', name: 'Lower River' },
    { id: 'North Bank', name: 'AlsaNorth Bankce' },
    { id: 'Upper River', name: 'Upper River' },
    { id: 'Western', name: 'Western' }
  ],
  GE: [
    { id: 'Abjasia', name: 'Abjasia' },
    { id: 'Samegrelo-Zemo Svaneti', name: 'Samegrelo-Zemo Svaneti' },
    { id: 'Guria', name: 'Guria' },
    { id: 'Ayaria', name: 'Ayaria' },
    {
      id: 'Racha-Lechjumi y Kvemo Svaneti',
      name: 'Racha-Lechjumi y Kvemo Svaneti'
    },
    { id: 'Imereti', name: 'Imereti' },
    { id: 'Samtsje-Yavajeti', name: 'Samtsje-Yavajeti' },
    { id: 'Shida Kartli', name: 'Shida Kartli' },
    { id: 'Mtsjeta-Mtianeti', name: 'Mtsjeta-Mtianeti' },
    { id: 'Kvemo Kartli', name: 'Kvemo Kartli' },
    { id: 'Kajeti', name: 'Kajeti' },
    { id: 'Tiflis', name: 'Tiflis' }
  ],
  DE: [
    { id: 'Baden-Wuerttemberg', name: 'Baden-Wuerttemberg' },
    { id: 'Bayern', name: 'Bayern' },
    { id: 'Berlin', name: 'Berlin' },
    { id: 'Brandenburg', name: 'Brandenburg' },
    { id: 'Bremen', name: 'Bremen' },
    { id: 'Hamburg', name: 'Hamburg' },
    { id: 'Hessen', name: 'Hessen' },
    { id: 'Mecklenburg-Vorpommern', name: 'Mecklenburg-Vorpommern' },
    { id: 'Niedersachsen', name: 'Niedersachsen' },
    { id: 'Nordrhein-Westfalen', name: 'Nordrhein-Westfalen' },
    { id: 'Rheinland-Pfalz', name: 'Rheinland-Pfalz' },
    { id: 'Saarland', name: 'Saarland' },
    { id: 'Sachsen', name: 'Sachsen' },
    { id: 'Sachsen-Anhalt', name: 'Sachsen-Anhalt' },
    { id: 'Schleswig-Holstein', name: 'Schleswig-Holstein' },
    { id: 'Thueringen', name: 'Thueringen' }
  ],
  GH: [
    { id: 'Ashanti', name: 'Ashanti' },
    { id: 'Brong-Ahafo', name: 'Brong-Ahafo' },
    { id: 'Central', name: 'Central' },
    { id: 'Eastern', name: 'Eastern' },
    { id: 'Greater Accra', name: 'Greater Accra' },
    { id: 'Northern', name: 'Northern' },
    { id: 'Upper East', name: 'Upper East' },
    { id: 'Upper West', name: 'Upper West' },
    { id: 'Volta', name: 'Volta' },
    { id: 'Western', name: 'Western' }
  ],
  GR: [
    { id: 'Agion Oros', name: 'Agion Oros' },
    { id: 'Achaia', name: 'Achaia' },
    { id: 'Aitolia kai Akarmania', name: 'Aitolia kai Akarmania' },
    { id: 'Argolis', name: 'Argolis' },
    { id: 'Arkadia', name: 'Arkadia' },
    { id: 'Arta', name: 'Arta' },
    { id: 'Attiki', name: 'Attiki' },
    { id: 'Chalkidiki', name: 'Chalkidiki' },
    { id: 'Chanion', name: 'Chanion' },
    { id: 'Chios', name: 'Chios' },
    { id: 'Dodekanisos', name: 'Dodekanisos' },
    { id: 'Drama', name: 'Drama' },
    { id: 'Evros', name: 'Evros' },
    { id: 'Evrytania', name: 'Evrytania' },
    { id: 'Evvoia', name: 'Evvoia' },
    { id: 'Florina', name: 'Florina' },
    { id: 'Fokidos', name: 'Fokidos' },
    { id: 'Fthiotis', name: 'Fthiotis' },
    { id: 'Grevena', name: 'Grevena' },
    { id: 'Ileia', name: 'Ileia' },
    { id: 'Imathia', name: 'Imathia' },
    { id: 'Ioannina', name: 'Ioannina' },
    { id: 'Irakleion', name: 'Irakleion' },
    { id: 'Karditsa', name: 'Karditsa' },
    { id: 'Kastoria', name: 'Kastoria' },
    { id: 'Kavala', name: 'Kavala' },
    { id: 'Kefallinia', name: 'Kefallinia' },
    { id: 'Kerkyra', name: 'Kerkyra' },
    { id: 'Kilkis', name: 'Kilkis' },
    { id: 'Korinthia', name: 'Korinthia' },
    { id: 'Kozani', name: 'Kozani' },
    { id: 'Kyklades', name: 'Kyklades' },
    { id: 'Lakonia', name: 'Lakonia' },
    { id: 'Larisa', name: 'Larisa' },
    { id: 'Lasithi', name: 'Lasithi' },
    { id: 'Lefkas', name: 'Lefkas' },
    { id: 'Lesvos', name: 'Lesvos' },
    { id: 'Magnisia', name: 'Magnisia' },
    { id: 'Messinia', name: 'Messinia' },
    { id: 'Pella', name: 'Pella' },
    { id: 'Pieria', name: 'Pieria' },
    { id: 'Preveza', name: 'Preveza' },
    { id: 'Rethynnis', name: 'Rethynnis' },
    { id: 'Rodopi', name: 'Rodopi' },
    { id: 'Samos', name: 'Samos' },
    { id: 'Serrai', name: 'Serrai' },
    { id: 'Thesprotia', name: 'Thesprotia' },
    { id: 'Thessaloniki', name: 'Thessaloniki' },
    { id: 'Trikala', name: 'Trikala' },
    { id: 'Voiotia', name: 'Voiotia' },
    { id: 'Xanthi', name: 'Xanthi' },
    { id: 'Zakynthos', name: 'Zakynthos' }
  ],
  GL: [
    { id: 'Avannaa (Nordgronland)', name: 'Avannaa (Nordgronland)' },
    { id: 'Tunu (Ostgronland)', name: 'Tunu (Ostgronland)' },
    { id: 'Kitaa (Vestgronland)', name: 'Kitaa (Vestgronland)' }
  ],
  GD: [
    {
      id: 'Carriacou and Petit Martinique',
      name: 'Carriacou and Petit Martinique'
    },
    { id: 'Saint Andrew', name: 'Saint Andrew' },
    { id: 'Saint David', name: 'Saint David' },
    { id: 'Saint George', name: 'Saint George' },
    { id: 'Saint John', name: 'Saint John' },
    { id: 'Saint Mark', name: 'Saint Mark' },
    { id: 'Saint Patrick', name: 'Saint Patrick' }
  ],
  GT: [
    { id: 'Alta Verapaz', name: 'Alta Verapaz' },
    { id: 'Baja Verapaz', name: 'Baja Verapaz' },
    { id: 'Chimaltenango', name: 'Chimaltenango' },
    { id: 'Chiquimula', name: 'Chiquimula' },
    { id: 'El Progreso', name: 'El Progreso' },
    { id: 'Escuintla', name: 'Escuintla' },
    { id: 'Guatemala', name: 'Guatemala' },
    { id: 'Huehuetenango', name: 'Huehuetenango' },
    { id: 'Izabal', name: 'Izabal' },
    { id: 'Jalapa', name: 'Jalapa' },
    { id: 'Jutiapa', name: 'Jutiapa' },
    { id: 'Peten', name: 'Peten' },
    { id: 'Quetzaltenango', name: 'Quetzaltenango' },
    { id: 'Quiche', name: 'Quiche' },
    { id: 'Retalhuleu', name: 'Retalhuleu' },
    { id: 'Sacatepequez', name: 'Sacatepequez' },
    { id: 'San Marcos', name: 'San Marcos' },
    { id: 'Santa Rosa', name: 'Santa Rosa' },
    { id: 'Solola', name: 'Solola' },
    { id: 'Suchitepequez', name: 'Suchitepequez' },
    { id: 'Totonicapan', name: 'Totonicapan' },
    { id: 'Zacapa', name: 'Zacapa' }
  ],
  GN: [
    { id: 'Beyla', name: 'Beyla' },
    { id: 'Boffa', name: 'Boffa' },
    { id: 'Boke', name: 'Boke' },
    { id: 'Conakry', name: 'Conakry' },
    { id: 'Coyah', name: 'Coyah' },
    { id: 'Dabola', name: 'Dabola' },
    { id: 'Dalaba', name: 'Dalaba' },
    { id: 'Dinguiraye', name: 'Dinguiraye' },
    { id: 'Dubreka', name: 'Dubreka' },
    { id: 'Faranah', name: 'Faranah' },
    { id: 'Forecariah', name: 'Forecariah' },
    { id: 'Fria', name: 'Fria' },
    { id: 'Gaoual', name: 'Gaoual' },
    { id: 'Gueckedou', name: 'Gueckedou' },
    { id: 'Kankan', name: 'Kankan' },
    { id: 'Kerouane', name: 'Kerouane' },
    { id: 'Kindia', name: 'Kindia' },
    { id: 'Kissidougou', name: 'Kissidougou' },
    { id: 'Koubia', name: 'Koubia' },
    { id: 'Koundara', name: 'Koundara' },
    { id: 'Kouroussa', name: 'Kouroussa' },
    { id: 'Labe', name: 'Labe' },
    { id: 'Lelouma', name: 'Lelouma' },
    { id: 'Lola', name: 'Lola' },
    { id: 'Macenta', name: 'Macenta' },
    { id: 'Mali', name: 'Mali' },
    { id: 'Mamou', name: 'Mamou' },
    { id: 'Mandiana', name: 'Mandiana' },
    { id: 'Nzerekore', name: 'Nzerekore' },
    { id: 'Pita', name: 'Pita' },
    { id: 'Siguiri', name: 'Siguiri' },
    { id: 'Telimele', name: 'Telimele' },
    { id: 'Tougue', name: 'Tougue' },
    { id: 'Yomou', name: 'Yomou' }
  ],
  GW: [
    { id: 'Bafata', name: 'Bafata' },
    { id: 'Biombo', name: 'Biombo' },
    { id: 'Bissau', name: 'Bissau' },
    { id: 'Bolama', name: 'Bolama' },
    { id: 'Cacheu', name: 'Cacheu' },
    { id: 'Gabu', name: 'Gabu' },
    { id: 'Oio', name: 'Oio' },
    { id: 'Quinara', name: 'Quinara' },
    { id: 'Tombali', name: 'Tombali' }
  ],
  GY: [
    { id: 'Barima-Waini', name: 'Barima-Waini' },
    { id: 'Cuyuni-Mazaruni', name: 'Cuyuni-Mazaruni' },
    { id: 'Demerara-Mahaica', name: 'Demerara-Mahaica' },
    { id: 'East Berbice-Corentyne', name: 'East Berbice-Corentyne' },
    {
      id: 'Essequibo Islands-West Demerara',
      name: 'Essequibo Islands-West Demerara'
    },
    { id: 'Mahaica-Berbice', name: 'Mahaica-Berbice' },
    { id: 'Pomeroon-Supenaam', name: 'Pomeroon-Supenaam' },
    { id: 'Potaro-Siparuni', name: 'Potaro-Siparuni' },
    { id: 'Upper Demerara-Berbice', name: 'Upper Demerara-Berbice' },
    {
      id: 'Upper Takutu-Upper Essequibo',
      name: 'Upper Takutu-Upper Essequibo'
    }
  ],
  HT: [
    { id: 'Artibonite', name: 'Artibonite' },
    { id: 'Centre', name: 'Centre' },
    { id: "Grand 'Anse", name: "Grand 'Anse" },
    { id: 'Nord', name: 'Nord' },
    { id: 'Centre', name: 'Centre' },
    { id: 'Nord-Ouest', name: 'Nord-Ouest' },
    { id: 'Ouest', name: 'Ouest' },
    { id: 'Sud', name: 'Sud' },
    { id: 'Sud-Est', name: 'Sud-Est' }
  ],
  HN: [
    { id: 'Atlantida', name: 'Atlantida' },
    { id: 'Choluteca', name: 'Choluteca' },
    { id: 'Colon', name: 'Colon' },
    { id: 'Comayagua', name: 'Comayagua' },
    { id: 'Copan', name: 'Copan' },
    { id: 'Cortes', name: 'Cortes' },
    { id: 'El Paraiso', name: 'El Paraiso' },
    { id: 'Francisco Morazan', name: 'Francisco Morazan' },
    { id: 'Gracias a Dios', name: 'Gracias a Dios' },
    { id: 'Intibuca', name: 'Intibuca' },
    { id: 'Islas de la Bahia', name: 'Islas de la Bahia' },
    { id: 'La Paz', name: 'La Paz' },
    { id: 'Lempira', name: 'Lempira' },
    { id: 'Ocotepeque', name: 'Ocotepeque' },
    { id: 'Olancho', name: 'Olancho' },
    { id: 'Santa Barbara', name: 'Santa Barbara' },
    { id: 'Valle', name: 'Valle' },
    { id: 'Yoro', name: 'Yoro' }
  ],
  HK: [
    { id: 'Yuen Long District', name: 'Yuen Long District' },
    { id: 'Tsuen Wan District', name: 'Tsuen Wan District' },
    { id: 'Tai Po District', name: 'Tai Po District' },
    { id: 'Sai Kung District', name: 'Sai Kung District' },
    { id: 'Islands District', name: 'Islands District' },
    {
      id: 'Central and Western District',
      name: 'Central and Western District'
    },
    { id: 'Wan Chai', name: 'Wan Chai' },
    { id: 'Eastern', name: 'Eastern' },
    { id: 'Southern', name: 'Southern' },
    { id: 'North', name: 'North' },
    { id: 'Yau Tsim Mong', name: 'Yau Tsim Mong' },
    { id: 'Sham Shui Po', name: 'Sham Shui Po' },
    { id: 'Kowloon City', name: 'Kowloon City' },
    { id: 'Wong Tai Sin', name: 'Wong Tai Sin' },
    { id: 'Kwun Tong', name: 'Kwun Tong' },
    { id: 'Kwai Tsing', name: 'Kwai Tsing' },
    { id: 'Tuen Mun', name: 'Tuen Mun' },
    { id: 'Sha Tin', name: 'Sha Tin' }
  ],
  HU: [
    { id: 'Bacs-Kiskun', name: 'Bacs-Kiskun' },
    { id: 'Baranya', name: 'Baranya' },
    { id: 'Bekes', name: 'Bekes' },
    { id: 'Borsod-Abauj-Zemplen', name: 'Borsod-Abauj-Zemplen' },
    { id: 'Csongrad', name: 'Csongrad' },
    { id: 'Fejer', name: 'Fejer' },
    { id: 'Gyor-Moson-Sopron', name: 'Gyor-Moson-Sopron' },
    { id: 'Hajdu-Bihar', name: 'Hajdu-Bihar' },
    { id: 'Heves', name: 'Heves' },
    { id: 'Jasz-Nagykun-Szolnok', name: 'Jasz-Nagykun-Szolnok' },
    { id: 'Komarom-Esztergom', name: 'Komarom-Esztergom' },
    { id: 'Nograd', name: 'Nograd' },
    { id: 'Pest', name: 'Pest' },
    { id: 'Somogy', name: 'Somogy' },
    { id: 'Szabolcs-Szatmar-Bereg', name: 'Szabolcs-Szatmar-Bereg' },
    { id: 'Tolna', name: 'Tolna' },
    { id: 'Vas', name: 'Vas' },
    { id: 'Veszprem', name: 'Veszprem' },
    { id: 'Zala', name: 'Zala' }
  ],
  IS: [
    { id: 'Austurland', name: 'Austurland' },
    { id: 'Hofudhborgarsvaedhi', name: 'Hofudhborgarsvaedhi' },
    { id: 'Nordhurland Eystra', name: 'Nordhurland Eystra' },
    { id: 'Nordhurland Vestra', name: 'Nordhurland Vestra' },
    { id: 'Sudhurland', name: 'Sudhurland' },
    { id: 'Sudhurnes', name: 'Sudhurnes' },
    { id: 'Vestfirdhir', name: 'Vestfirdhir' },
    { id: 'Vesturland', name: 'Vesturland' }
  ],
  IN: [
    {
      id: 'Andaman and Nicobar Islands',
      name: 'Andaman and Nicobar Islands'
    },
    { id: 'Andhra Pradesh', name: 'Andhra Pradesh' },
    { id: 'Arunachal Pradesh', name: 'Arunachal Pradesh' },
    { id: 'Assam', name: 'Assam' },
    { id: 'Bihar', name: 'Bihar' },
    { id: 'Chandigarh', name: 'Chandigarh' },
    { id: 'Chhattisgarh', name: 'Chhattisgarh' },
    { id: 'Dadra and Nagar Haveli', name: 'Dadra and Nagar Haveli' },
    { id: 'Daman and Diu', name: 'Daman and Diu' },
    { id: 'Delhi', name: 'Delhi' },
    { id: 'Goa', name: 'Goa' },
    { id: 'Gujarat', name: 'Gujarat' },
    { id: 'Haryana', name: 'Haryana' },
    { id: 'Himachal Pradesh', name: 'Himachal Pradesh' },
    { id: 'Jammu and Kashmir', name: 'Jammu and Kashmir' },
    { id: 'Jharkhand', name: 'Jharkhand' },
    { id: 'Karnataka', name: 'Karnataka' },
    { id: 'Kerala', name: 'Kerala' },
    { id: 'Lakshadweep', name: 'Lakshadweep' },
    { id: 'Madhya Pradesh', name: 'Madhya Pradesh' },
    { id: 'Maharashtra', name: 'Maharashtra' },
    { id: 'Manipur', name: 'Manipur' },
    { id: 'Meghalaya', name: 'Meghalaya' },
    { id: 'Mizoram', name: 'Mizoram' },
    { id: 'Nagaland', name: 'Nagaland' },
    { id: 'Orissa', name: 'Orissa' },
    { id: 'Pondicherry', name: 'Pondicherry' },
    { id: 'Punjab', name: 'Punjab' },
    { id: 'Rajasthan', name: 'Rajasthan' },
    { id: 'Sikkim', name: 'Sikkim' },
    { id: 'Tamil Nadu', name: 'Tamil Nadu' },
    { id: 'Telangana', name: 'Telangana' },
    { id: 'Tripura', name: 'Tripura' },
    { id: 'Uttaranchal', name: 'Uttaranchal' },
    { id: 'Uttar Pradesh', name: 'Uttar Pradesh' },
    { id: 'West Bengal', name: 'West Bengal' }
  ],
  ID: [
    { id: 'Aceh', name: 'Aceh' },
    { id: 'Bali', name: 'Bali' },
    { id: 'Banten', name: 'Banten' },
    { id: 'Bengkulu', name: 'Bengkulu' },
    { id: 'Gorontalo', name: 'Gorontalo' },
    { id: 'Irian Jaya Barat', name: 'Irian Jaya Barat' },
    { id: 'Jakarta Raya', name: 'Jakarta Raya' },
    { id: 'Jambi', name: 'Jambi' },
    { id: 'Jawa Barat', name: 'Jawa Barat' },
    { id: 'Jawa Tengah', name: 'Jawa Tengah' },
    { id: 'Jawa Timur', name: 'Jawa Timur' },
    { id: 'Kalimantan Barat', name: 'Kalimantan Barat' },
    { id: 'Kalimantan Selatan', name: 'Kalimantan Selatan' },
    { id: 'Kalimantan Tengah', name: 'Kalimantan Tengah' },
    { id: 'Kalimantan Timur', name: 'Kalimantan Timur' },
    { id: 'Kepulauan Bangka Belitung', name: 'Kepulauan Bangka Belitung' },
    { id: 'Kepulauan Riau', name: 'Kepulauan Riau' },
    { id: 'Lampung', name: 'Lampung' },
    { id: 'Maluku', name: 'Maluku' },
    { id: 'Maluku Utara', name: 'Maluku Utara' },
    { id: 'Nusa Tenggara Barat', name: 'Nusa Tenggara Barat' },
    { id: 'Nusa Tenggara Timur', name: 'Nusa Tenggara Timur' },
    { id: 'Papua', name: 'Papua' },
    { id: 'Riau', name: 'Riau' },
    { id: 'Sulawesi Barat', name: 'Sulawesi Barat' },
    { id: 'Sulawesi Selatan', name: 'Sulawesi Selatan' },
    { id: 'Sulawesi Tengah', name: 'Sulawesi Tengah' },
    { id: 'Sulawesi Tenggara', name: 'Sulawesi Tenggara' },
    { id: 'Sulawesi Utara', name: 'Sulawesi Utara' },
    { id: 'Sumatera Barat', name: 'Sumatera Barat' },
    { id: 'Sumatera Selatan', name: 'Sumatera Selatan' },
    { id: 'Sumatera Utara', name: 'Sumatera Utara' },
    { id: 'Yogyakarta', name: 'Yogyakarta' }
  ],
  IR: [
    { id: 'Ardabil', name: 'Ardabil' },
    { id: 'Azarbayjan-e Gharbi', name: 'Azarbayjan-e Gharbi' },
    { id: 'Azarbayjan-e Sharqi', name: 'Azarbayjan-e Sharqi' },
    { id: 'Bushehr', name: 'Bushehr' },
    {
      id: 'Chahar Mahall va Bakhtiari',
      name: 'Chahar Mahall va Bakhtiari'
    },
    { id: 'Esfahan', name: 'Esfahan' },
    { id: 'Fars', name: 'Fars' },
    { id: 'Gilan', name: 'Gilan' },
    { id: 'Golestan', name: 'Golestan' },
    { id: 'Hamadan', name: 'Hamadan' },
    { id: 'Hormozgan', name: 'Hormozgan' },
    { id: 'Ilam', name: 'Ilam' },
    { id: 'Kerman', name: 'Kerman' },
    { id: 'Kermanshah', name: 'Kermanshah' },
    { id: 'Khorasan-e Janubi', name: 'Khorasan-e Janubi' },
    { id: 'Khorasan-e Razavi', name: 'Khorasan-e Razavi' },
    { id: 'Khorasan-e Shemali', name: 'Khorasan-e Shemali' },
    { id: 'Khuzestan', name: 'Khuzestan' },
    { id: 'Kohgiluyeh va Buyer Ahmad', name: 'Kohgiluyeh va Buyer Ahmad' },
    { id: 'Kordestan', name: 'Kordestan' },
    { id: 'Lorestan', name: 'Lorestan' },
    { id: 'Markazi', name: 'Markazi' },
    { id: 'Mazandaran', name: 'Mazandaran' },
    { id: 'Qazvin', name: 'Qazvin' },
    { id: 'Qom', name: 'Qom' },
    { id: 'Semnan', name: 'Semnan' },
    { id: 'Sistan va Baluchestan', name: 'Sistan va Baluchestan' },
    { id: 'Tehran', name: 'Tehran' },
    { id: 'Yazd', name: 'Yazd' },
    { id: 'Zanjan', name: 'Zanjan' }
  ],
  IQ: [
    { id: 'Al Anbar', name: 'Al Anbar' },
    { id: 'Al Basrah', name: 'Al Basrah' },
    { id: 'Al Muthanna', name: 'Al Muthanna' },
    { id: 'Al Qadisiyah', name: 'Al Qadisiyah' },
    { id: 'An Najaf', name: 'An Najaf' },
    { id: 'Arbil', name: 'Arbil' },
    { id: 'As Sulaymaniyah', name: 'As Sulaymaniyah' },
    { id: 'Babil', name: 'Babil' },
    { id: 'Baghdad', name: 'Baghdad' },
    { id: 'Dahuk', name: 'Dahuk' },
    { id: 'Dhi Qar', name: 'Dhi Qar' },
    { id: 'Diyala', name: 'Diyala' },
    { id: "Karbala'", name: "Karbala'" },
    { id: 'Maysan', name: 'Maysan' },
    { id: 'Ninawa', name: 'Ninawa' },
    { id: 'Salah ad Din', name: 'Salah ad Din' },
    { id: 'Wasit', name: 'Wasit' }
  ],
  IE: [
    { id: 'Carlow', name: 'Carlow' },
    { id: 'Cavan', name: 'Cavan' },
    { id: 'Clare', name: 'Clare' },
    { id: 'Cork', name: 'Cork' },
    { id: 'Donegal', name: 'Donegal' },
    { id: 'Dublin', name: 'Dublin' },
    { id: 'Galway', name: 'Galway' },
    { id: 'Kerry', name: 'Kerry' },
    { id: 'Kildare', name: 'Kildare' },
    { id: 'Kilkenny', name: 'Kilkenny' },
    { id: 'Laois', name: 'Laois' },
    { id: 'Leitrim', name: 'Leitrim' },
    { id: 'Limerick', name: 'Limerick' },
    { id: 'Longford', name: 'Longford' },
    { id: 'Louth', name: 'Louth' },
    { id: 'Mayo', name: 'Mayo' },
    { id: 'Meath', name: 'Meath' },
    { id: 'Monaghan', name: 'Monaghan' },
    { id: 'Offaly', name: 'Offaly' },
    { id: 'Roscommon', name: 'Roscommon' },
    { id: 'Sligo', name: 'Sligo' },
    { id: 'Tipperary', name: 'Tipperary' },
    { id: 'Waterford', name: 'Waterford' },
    { id: 'Westmeath', name: 'Westmeath' },
    { id: 'Wexford', name: 'Wexford' },
    { id: 'Wicklow', name: 'Wicklow' }
  ],
  IL: [
    { id: 'Central', name: 'Central' },
    { id: 'Haifa', name: 'Haifa' },
    { id: 'Jerusalem', name: 'Jerusalem' },
    { id: 'Northern', name: 'Northern' },
    { id: 'Southern', name: 'Southern' },
    { id: 'Tel Aviv', name: 'Tel Aviv' }
  ],
  IT: [
    { id: 'Abruzzo', name: 'Abruzzo' },
    { id: 'Basilicata', name: 'Basilicata' },
    { id: 'Calabria', name: 'Calabria' },
    { id: 'Campania', name: 'Campania' },
    { id: 'Emilia-Romagna', name: 'Emilia-Romagna' },
    { id: 'Friuli-Venezia Giulia', name: 'Friuli-Venezia Giulia' },
    { id: 'Lazio', name: 'Lazio' },
    { id: 'Liguria', name: 'Liguria' },
    { id: 'Lombardia', name: 'Lombardia' },
    { id: 'Marche', name: 'Marche' },
    { id: 'Molise', name: 'Molise' },
    { id: 'Piemonte', name: 'Piemonte' },
    { id: 'Puglia', name: 'Puglia' },
    { id: 'Sardegna', name: 'Sardegna' },
    { id: 'Sicilia', name: 'Sicilia' },
    { id: 'Toscana', name: 'Toscana' },
    { id: 'Trentino-Alto Adige', name: 'Trentino-Alto Adige' },
    { id: 'Umbria', name: 'Umbria' },
    { id: "Valle d'Aosta", name: "Valle d'Aosta" },
    { id: 'Veneto', name: 'Veneto' }
  ],
  JM: [
    { id: 'Clarendon', name: 'Clarendon' },
    { id: 'Hanover', name: 'Hanover' },
    { id: 'Kingston', name: 'Kingston' },
    { id: 'Manchester', name: 'Manchester' },
    { id: 'Portland', name: 'Portland' },
    { id: 'Saint Andrew', name: 'Saint Andrew' },
    { id: 'Saint Ann', name: 'Saint Ann' },
    { id: 'Saint Catherine', name: 'Saint Catherine' },
    { id: 'Saint Elizabeth', name: 'Saint Elizabeth' },
    { id: 'Saint James', name: 'Saint James' },
    { id: 'Saint Mary', name: 'Saint Mary' },
    { id: 'Saint Thomas', name: 'Saint Thomas' },
    { id: 'Trelawny', name: 'Trelawny' },
    { id: 'Westmoreland', name: 'Westmoreland' }
  ],
  JP: [
    { id: 'Aichi', name: 'Aichi' },
    { id: 'Akita', name: 'Akita' },
    { id: 'Aomori', name: 'Aomori' },
    { id: 'Chiba', name: 'Chiba' },
    { id: 'Ehime', name: 'Ehime' },
    { id: 'Fukui', name: 'Fukui' },
    { id: 'Fukuoka', name: 'Fukuoka' },
    { id: 'Fukushima', name: 'Fukushima' },
    { id: 'Gifu', name: 'Gifu' },
    { id: 'Gumma', name: 'Gumma' },
    { id: 'Hiroshima', name: 'Hiroshima' },
    { id: 'Hokkaido', name: 'Hokkaido' },
    { id: 'Hyogo', name: 'Hyogo' },
    { id: 'Ibaraki', name: 'Ibaraki' },
    { id: 'Ishikawa', name: 'Ishikawa' },
    { id: 'Iwate', name: 'Iwate' },
    { id: 'Kagawa', name: 'Kagawa' },
    { id: 'Kagoshima', name: 'Kagoshima' },
    { id: 'Kanagawa', name: 'Kanagawa' },
    { id: 'Kochi', name: 'Kochi' },
    { id: 'Kumamoto', name: 'Kumamoto' },
    { id: 'Kyoto', name: 'Kyoto' },
    { id: 'Mie', name: 'Mie' },
    { id: 'Miyagi', name: 'Miyagi' },
    { id: 'Miyazaki', name: 'Miyazaki' },
    { id: 'Nagano', name: 'Nagano' },
    { id: 'Nagasaki', name: 'Nagasaki' },
    { id: 'Nara', name: 'Nara' },
    { id: 'Niigata', name: 'Niigata' },
    { id: 'Oita', name: 'Oita' },
    { id: 'Okayama', name: 'Okayama' },
    { id: 'Okinawa', name: 'Okinawa' },
    { id: 'Osaka', name: 'Osaka' },
    { id: 'Saga', name: 'Saga' },
    { id: 'Saitama', name: 'Saitama' },
    { id: 'Shiga', name: 'Shiga' },
    { id: 'Shimane', name: 'Shimane' },
    { id: 'Shizuoka', name: 'Shizuoka' },
    { id: 'Tochigi', name: 'Tochigi' },
    { id: 'Tokushima', name: 'Tokushima' },
    { id: 'Tokyo', name: 'Tokyo' },
    { id: 'Tottori', name: 'Tottori' },
    { id: 'Toyama', name: 'Toyama' },
    { id: 'Wakayama', name: 'Wakayama' },
    { id: 'Yamagata', name: 'Yamagata' },
    { id: 'Yamaguchi', name: 'Yamaguchi' },
    { id: 'Yamanashi', name: 'Yamanashi' }
  ],
  KEY: [
    { id: 'Central', name: 'Central' },
    { id: 'Coast', name: 'Coast' },
    { id: 'Eastern', name: 'Eastern' },
    { id: 'Nairobi Area', name: 'Nairobi Area' },
    { id: 'North Eastern', name: 'North Eastern' },
    { id: 'Nyanza', name: 'Nyanza' },
    { id: 'Rift Valley', name: 'Rift Valley' },
    { id: 'Western', name: 'Western' }
  ],
  KI: [
    { id: 'Gilbert Islands', name: 'Gilbert Islands' },
    { id: 'Phoenix Islands', name: 'Phoenix Islands' },
    { id: 'Line islands', name: 'Line islands' }
  ],
  KW: [
    { id: 'Al Ahmadi', name: 'Al Ahmadi' },
    { id: 'Al Farwaniyah', name: 'Al Farwaniyah' },
    { id: 'Al Asimah', name: 'Al Asimah' },
    { id: 'Al Jahra', name: 'Al Jahra' },
    { id: 'Hawalli', name: 'Hawalli' },
    { id: 'Mubarak Al-Kabeer', name: 'Mubarak Al-Kabeer' }
  ],
  KG: [
    { id: 'Batken Oblasty', name: 'Batken Oblasty' },
    { id: 'Bishkek Shaary', name: 'Bishkek Shaary' },
    { id: 'Chuy Oblasty', name: 'Chuy Oblasty' },
    { id: 'Jalal-Abad Oblasty', name: 'Jalal-Abad Oblasty' },
    { id: 'Naryn Oblasty', name: 'Naryn Oblasty' },
    { id: 'Osh Oblasty', name: 'Osh Oblasty' },
    { id: 'Talas Oblasty', name: 'Talas Oblasty' },
    { id: 'Ysyk-Kol Oblasty', name: 'Ysyk-Kol Oblasty' }
  ],
  LA: [
    { id: 'Attapu', name: 'Attapu' },
    { id: 'Bokeo', name: 'Bokeo' },
    { id: 'Bolikhamxai', name: 'Bolikhamxai' },
    { id: 'Champasak', name: 'Champasak' },
    { id: 'Houaphan', name: 'Houaphan' },
    { id: 'Khammouan', name: 'Khammouan' },
    { id: 'Louangnamtha', name: 'Louangnamtha' },
    { id: 'Louangphrabang', name: 'Louangphrabang' },
    { id: 'Oudomxai', name: 'Oudomxai' },
    { id: 'Phongsali', name: 'Phongsali' },
    { id: 'Salavan', name: 'Salavan' },
    { id: 'Savannakhet', name: 'Savannakhet' },
    { id: 'Viangchan', name: 'Viangchan' },
    { id: 'Xaignabouli', name: 'Xaignabouli' },
    { id: 'Xaisomboun', name: 'Xaisomboun' },
    { id: 'Xekong', name: 'Xekong' },
    { id: 'Xiangkhoang', name: 'Xiangkhoang' }
  ],
  LV: [
    { id: 'Aizkraukles Rajons', name: 'Aizkraukles Rajons' },
    { id: 'Aluksnes Rajons', name: 'Aluksnes Rajons' },
    { id: 'Balvu Rajons', name: 'Balvu Rajons' },
    { id: 'Bauskas Rajons', name: 'Bauskas Rajons' },
    { id: 'Cesu Rajons', name: 'Cesu Rajons' },
    { id: 'Daugavpils', name: 'Daugavpils' },
    { id: 'Daugavpils Rajons', name: 'Daugavpils Rajons' },
    { id: 'Dobeles Rajons', name: 'Dobeles Rajons' },
    { id: 'Gulbenes Rajons', name: 'Gulbenes Rajons' },
    { id: 'Jekabpils Rajons', name: 'Jekabpils Rajons' },
    { id: 'Jelgava', name: 'Jelgava' },
    { id: 'Jelgavas Rajons', name: 'Jelgavas Rajons' },
    { id: 'Jurmala', name: 'Jurmala' },
    { id: 'Kraslavas Rajons', name: 'Kraslavas Rajons' },
    { id: 'Kuldigas Rajons', name: 'Kuldigas Rajons' },
    { id: 'Liepaja', name: 'Liepaja' },
    { id: 'Liepajas Rajons', name: 'Liepajas Rajons' },
    { id: 'Limbazu Rajons', name: 'Limbazu Rajons' },
    { id: 'Ludzas Rajons', name: 'Ludzas Rajons' },
    { id: 'Madonas Rajons', name: 'Madonas Rajons' },
    { id: 'Ogres Rajons', name: 'Ogres Rajons' },
    { id: 'Preilu Rajons', name: 'Preilu Rajons' },
    { id: 'Rezekne', name: 'Rezekne' },
    { id: 'Rezeknes Rajons', name: 'Rezeknes Rajons' },
    { id: 'Riga', name: 'Riga' },
    { id: 'Rigas Rajons', name: 'Rigas Rajons' },
    { id: 'Saldus Rajons', name: 'Saldus Rajons' },
    { id: 'Talsu Rajons', name: 'Talsu Rajons' },
    { id: 'Tukuma Rajons', name: 'Tukuma Rajons' },
    { id: 'Valkas Rajons', name: 'Valkas Rajons' },
    { id: 'Valmieras Rajons', name: 'Valmieras Rajons' },
    { id: 'Ventspils', name: 'Ventspils' },
    { id: 'Ventspils Rajons', name: 'Ventspils Rajons' }
  ],
  LB: [
    { id: 'Beyrouth', name: 'Beyrouth' },
    { id: 'Beqaa', name: 'Beqaa' },
    { id: 'Liban-Nord', name: 'Liban-Nord' },
    { id: 'Liban-Sud', name: 'Liban-Sud' },
    { id: 'Mont-Liban', name: 'Mont-Liban' },
    { id: 'Nabatiye', name: 'Nabatiye' }
  ],
  LS: [
    { id: 'Berea', name: 'Berea' },
    { id: 'Butha-Buthe', name: 'Butha-Buthe' },
    { id: 'Leribe', name: 'Leribe' },
    { id: 'Mafeteng', name: 'Mafeteng' },
    { id: 'Maseru', name: 'Maseru' },
    { id: "Mohale's Hoek", name: "Mohale's Hoek" },
    { id: 'Mokhotlong', name: 'Mokhotlong' },
    { id: "Qacha's Nek", name: "Qacha's Nek" },
    { id: 'Quthing', name: 'Quthing' },
    { id: 'Thaba-Tseka', name: 'Thaba-Tseka' }
  ],
  LR: [
    { id: 'Bomi', name: 'Bomi' },
    { id: 'Bong', name: 'Bong' },
    { id: 'Gbarpolu', name: 'Gbarpolu' },
    { id: 'Grand Bassa', name: 'Grand Bassa' },
    { id: 'Grand Cape Mount', name: 'Grand Cape Mount' },
    { id: 'Grand Gedeh', name: 'Grand Gedeh' },
    { id: 'Grand Kru', name: 'Grand Kru' },
    { id: 'Lofa', name: 'Lofa' },
    { id: 'Margibi', name: 'Margibi' },
    { id: 'Maryland', name: 'Maryland' },
    { id: 'Montserrado', name: 'Montserrado' },
    { id: 'Nimba', name: 'Nimba' },
    { id: 'River Cess', name: 'River Cess' },
    { id: 'River Gee', name: 'River Gee' },
    { id: 'Sinoe', name: 'Sinoe' }
  ],
  LY: [
    { id: 'Ajdabiya', name: 'Ajdabiya' },
    { id: "Al 'Aziziyah", name: "Al 'Aziziyah" },
    { id: 'Al Fatih', name: 'Al Fatih' },
    { id: 'Al Jabal al Akhdar', name: 'Al Jabal al Akhdar' },
    { id: 'Al Jufrah', name: 'Al Jufrah' },
    { id: 'Al Khums', name: 'Al Khums' },
    { id: 'Al Kufrah', name: 'Al Kufrah' },
    { id: 'An Nuqat al Khams', name: 'An Nuqat al Khams' },
    { id: "Ash Shati'", name: "Ash Shati'" },
    { id: 'Awbari', name: 'Awbari' },
    { id: 'Az Zawiyah', name: 'Az Zawiyah' },
    { id: 'Banghazi', name: 'Banghazi' },
    { id: 'Darnah', name: 'Darnah' },
    { id: 'Ghadamis', name: 'Ghadamis' },
    { id: 'Gharyan', name: 'Gharyan' },
    { id: 'Misratah', name: 'Misratah' },
    { id: 'Murzuq', name: 'Murzuq' },
    { id: 'Sabha', name: 'Sabha' },
    { id: 'Sawfajjin', name: 'Sawfajjin' },
    { id: 'Surt', name: 'Surt' },
    { id: 'Tarabulus', name: 'Tarabulus' },
    { id: 'Tarhunah', name: 'Tarhunah' },
    { id: 'Tubruq', name: 'Tubruq' },
    { id: 'Yafran', name: 'Yafran' },
    { id: 'Zlitan', name: 'Zlitan' }
  ],
  LI: [
    { id: 'Balzers', name: 'Balzers' },
    { id: 'Eschen', name: 'Eschen' },
    { id: 'Gamprin', name: 'Gamprin' },
    { id: 'Mauren', name: 'Mauren' },
    { id: 'Planken', name: 'Planken' },
    { id: 'Ruggell', name: 'Ruggell' },
    { id: 'Schaan', name: 'Schaan' },
    { id: 'Schellenberg', name: 'Schellenberg' },
    { id: 'Triesen', name: 'Triesen' },
    { id: 'Triesenberg', name: 'Triesenberg' },
    { id: 'Vaduz', name: 'Vaduz' }
  ],
  LT: [
    { id: 'Alytaus', name: 'Alytaus' },
    { id: 'Kauno', name: 'Kauno' },
    { id: 'Klaipedos', name: 'Klaipedos' },
    { id: 'Marijampoles', name: 'Marijampoles' },
    { id: 'Panevezio', name: 'Panevezio' },
    { id: 'Siauliu', name: 'Siauliu' },
    { id: 'Taurages', name: 'Taurages' },
    { id: 'Telsiu', name: 'Telsiu' },
    { id: 'Utenos', name: 'Utenos' },
    { id: 'Vilniaus', name: 'Vilniaus' }
  ],
  LU: [
    { id: 'Diekirch', name: 'Diekirch' },
    { id: 'Grevenmacher', name: 'Grevenmacher' },
    { id: 'Luxembourg', name: 'Luxembourg' }
  ],
  MK: [
    { id: 'Aerodrom', name: 'Aerodrom' },
    { id: 'Aracinovo', name: 'Aracinovo' },
    { id: 'Berovo', name: 'Berovo' },
    { id: 'Bitola', name: 'Bitola' },
    { id: 'Bogdanci', name: 'Bogdanci' },
    { id: 'Bogovinje', name: 'Bogovinje' },
    { id: 'Bosilovo', name: 'Bosilovo' },
    { id: 'Brvenica', name: 'Brvenica' },
    { id: 'Butel', name: 'Butel' },
    { id: 'Cair', name: 'Cair' },
    { id: 'Caska', name: 'Caska' },
    { id: 'Centar', name: 'Centar' },
    { id: 'Centar Zupa', name: 'Centar Zupa' },
    { id: 'Cesinovo', name: 'Cesinovo' },
    { id: 'Cucer-Sandevo', name: 'Cucer-Sandevo' },
    { id: 'Debar', name: 'Debar' },
    { id: 'Debartsa', name: 'Debartsa' },
    { id: 'Delcevo', name: 'Delcevo' },
    { id: 'Demir Hisar', name: 'Demir Hisar' },
    { id: 'Demir Kapija', name: 'Demir Kapija' },
    { id: 'Dojran', name: 'Dojran' },
    { id: 'Dolneni', name: 'Dolneni' },
    { id: 'Drugovo', name: 'Drugovo' },
    { id: 'Gazi Baba', name: 'Gazi Baba' },
    { id: 'Gevgelija', name: 'Gevgelija' },
    { id: 'Gjorce Petrov', name: 'Gjorce Petrov' },
    { id: 'Gostivar', name: 'Gostivar' },
    { id: 'Gradsko', name: 'Gradsko' },
    { id: 'Ilinden', name: 'Ilinden' },
    { id: 'Jegunovce', name: 'Jegunovce' },
    { id: 'Karbinci', name: 'Karbinci' },
    { id: 'Karpos', name: 'Karpos' },
    { id: 'Kavadarci', name: 'Kavadarci' },
    { id: 'Kicevo', name: 'Kicevo' },
    { id: 'Kisela Voda', name: 'Kisela Voda' },
    { id: 'Kocani', name: 'Kocani' },
    { id: 'Konce', name: 'Konce' },
    { id: 'Kratovo', name: 'Kratovo' },
    { id: 'Kriva Palanka', name: 'Kriva Palanka' },
    { id: 'Krivogastani', name: 'Krivogastani' },
    { id: 'Krusevo', name: 'Krusevo' },
    { id: 'Kumanovo', name: 'Kumanovo' },
    { id: 'Lipkovo', name: 'Lipkovo' },
    { id: 'Lozovo', name: 'Lozovo' },
    { id: 'Makedonska Kamenica', name: 'Makedonska Kamenica' },
    { id: 'Makedonski Brod', name: 'Makedonski Brod' },
    { id: 'Mavrovo i Rastusa', name: 'Mavrovo i Rastusa' },
    { id: 'Mogila', name: 'Mogila' },
    { id: 'Negotino', name: 'Negotino' },
    { id: 'Novaci', name: 'Novaci' },
    { id: 'Novo Selo', name: 'Novo Selo' },
    { id: 'Ohrid', name: 'Ohrid' },
    { id: 'Oslomej', name: 'Oslomej' },
    { id: 'Pehcevo', name: 'Pehcevo' },
    { id: 'Petrovec', name: 'Petrovec' },
    { id: 'Plasnica', name: 'Plasnica' },
    { id: 'Prilep', name: 'Prilep' },
    { id: 'Probistip', name: 'Probistip' },
    { id: 'Radovis', name: 'Radovis' },
    { id: 'Rankovce', name: 'Rankovce' },
    { id: 'Resen', name: 'Resen' },
    { id: 'Rosoman', name: 'Rosoman' },
    { id: 'Saraj', name: 'Saraj' },
    { id: 'Skopje', name: 'Skopje' },
    { id: 'Sopiste', name: 'Sopiste' },
    { id: 'Staro Nagoricane', name: 'Staro Nagoricane' },
    { id: 'Stip', name: 'Stip' },
    { id: 'Struga', name: 'Struga' },
    { id: 'Strumica', name: 'Strumica' },
    { id: 'Studenicani', name: 'Studenicani' },
    { id: 'Suto Orizari', name: 'Suto Orizari' },
    { id: 'Sveti Nikole', name: 'Sveti Nikole' },
    { id: 'Tearce', name: 'Tearce' },
    { id: 'Tetovo', name: 'Tetovo' },
    { id: 'Valandovo', name: 'Valandovo' },
    { id: 'Vasilevo', name: 'Vasilevo' },
    { id: 'Veles', name: 'Veles' },
    { id: 'Vevcani', name: 'Vevcani' },
    { id: 'Vinica', name: 'Vinica' },
    { id: 'Vranestica', name: 'Vranestica' },
    { id: 'Vrapciste', name: 'Vrapciste' },
    { id: 'Zajas', name: 'Zajas' },
    { id: 'Zelenikovo', name: 'Zelenikovo' },
    { id: 'Zelino', name: 'Zelino' },
    { id: 'Zrnovci', name: 'Zrnovci' }
  ],
  MG: [
    { id: 'Antananarivo', name: 'Antananarivo' },
    { id: 'Antsiranana', name: 'Antsiranana' },
    { id: 'Fianarantsoa', name: 'Fianarantsoa' },
    { id: 'Mahajanga', name: 'Mahajanga' },
    { id: 'Toamasina', name: 'Toamasina' },
    { id: 'Toliara', name: 'Toliara' }
  ],
  MW: [
    { id: 'Balaka', name: 'Balaka' },
    { id: 'Blantyre', name: 'Blantyre' },
    { id: 'Chikwawa', name: 'Chikwawa' },
    { id: 'Chiradzulu', name: 'Chiradzulu' },
    { id: 'Chitipa', name: 'Chitipa' },
    { id: 'Dedza', name: 'Dedza' },
    { id: 'Dowa', name: 'Dowa' },
    { id: 'Karonga', name: 'Karonga' },
    { id: 'Kasungu', name: 'Kasungu' },
    { id: 'Likoma', name: 'Likoma' },
    { id: 'Lilongwe', name: 'Lilongwe' },
    { id: 'Machinga', name: 'Machinga' },
    { id: 'Mangochi', name: 'Mangochi' },
    { id: 'Mchinji', name: 'Mchinji' },
    { id: 'Mulanje', name: 'Mulanje' },
    { id: 'Mwanza', name: 'Mwanza' },
    { id: 'Mzimba', name: 'Mzimba' },
    { id: 'Ntcheu', name: 'Ntcheu' },
    { id: 'Nkhata Bay', name: 'Nkhata Bay' },
    { id: 'Nkhotakota', name: 'Nkhotakota' },
    { id: 'Nsanje', name: 'Nsanje' },
    { id: 'Ntchisi', name: 'Ntchisi' },
    { id: 'Phalombe', name: 'Phalombe' },
    { id: 'Rumphi', name: 'Rumphi' },
    { id: 'Salima', name: 'Salima' },
    { id: 'Thyolo', name: 'Thyolo' },
    { id: 'Zomba', name: 'Zomba' }
  ],
  MY: [
    { id: 'Johor', name: 'Johor' },
    { id: 'Kedah', name: 'Kedah' },
    { id: 'Kelantan', name: 'Kelantan' },
    { id: 'Kuala Lumpur', name: 'Kuala Lumpur' },
    { id: 'Labuan', name: 'Labuan' },
    { id: 'Malacca', name: 'Malacca' },
    { id: 'Negeri Sembilan', name: 'Negeri Sembilan' },
    { id: 'Pahang', name: 'Pahang' },
    { id: 'Perak', name: 'Perak' },
    { id: 'Perlis', name: 'Perlis' },
    { id: 'Penang', name: 'Penang' },
    { id: 'Sabah', name: 'Sabah' },
    { id: 'Sarawak', name: 'Sarawak' },
    { id: 'Selangor', name: 'Selangor' },
    { id: 'Terengganu', name: 'Terengganu' }
  ],
  MV: [
    { id: 'Alifu', name: 'Alifu' },
    { id: 'Baa', name: 'Baa' },
    { id: 'Dhaalu', name: 'Dhaalu' },
    { id: 'Faafu', name: 'Faafu' },
    { id: 'Gaafu Alifu', name: 'Gaafu Alifu' },
    { id: 'Gaafu Dhaalu', name: 'Gaafu Dhaalu' },
    { id: 'Gnaviyani', name: 'Gnaviyani' },
    { id: 'Haa Alifu', name: 'Haa Alifu' },
    { id: 'Haa Dhaalu', name: 'Haa Dhaalu' },
    { id: 'Kaafu', name: 'Kaafu' },
    { id: 'Laamu', name: 'Laamu' },
    { id: 'Lhaviyani', name: 'Lhaviyani' },
    { id: 'Maale', name: 'Maale' },
    { id: 'Meemu', name: 'Meemu' },
    { id: 'Noonu', name: 'Noonu' },
    { id: 'Raa', name: 'Raa' },
    { id: 'Seenu', name: 'Seenu' },
    { id: 'Shaviyani', name: 'Shaviyani' },
    { id: 'Thaa', name: 'Thaa' },
    { id: 'Vaavu', name: 'Vaavu' }
  ],
  ML: [
    { id: 'Bamako (Capital)', name: 'Bamako (Capital)' },
    { id: 'Gao', name: 'Gao' },
    { id: 'Kayes', name: 'Kayes' },
    { id: 'Kidal', name: 'Kidal' },
    { id: 'Koulikoro', name: 'Koulikoro' },
    { id: 'Mopti', name: 'Mopti' },
    { id: 'Segou', name: 'Segou' },
    { id: 'Sikasso', name: 'Sikasso' },
    { id: 'Tombouctou', name: 'Tombouctou' }
  ],
  MT: [
    { id: 'Comino', name: 'Comino' },
    { id: 'Cominotto', name: 'Cominotto' },
    { id: 'Delmarva', name: 'Delmarva' },
    { id: 'Filfla', name: 'Filfla' },
    { id: 'Gozo', name: 'Gozo' },
    { id: 'Halfa', name: 'Halfa' },
    { id: 'Islas de San Pablo', name: 'Islas de San Pablo' },
    { id: 'Malta', name: 'Malta' },
    { id: 'Manoel', name: 'Manoel' },
    { id: 'Tac-Cawl', name: 'Tac-Cawl' },
    { id: 'Ta`Fraben', name: 'Ta`Fraben' }
  ],
  MH: [
    { id: 'Enewetak/Ujelang', name: 'Enewetak/Ujelang' },
    { id: 'Kili/Bikini/Ejit', name: 'Kili/Bikini/Ejit' },
    { id: 'Rongelap', name: 'Rongelap' },
    { id: 'Utirik', name: 'Utirik' },
    { id: 'Wotho', name: 'Wotho' },
    { id: 'Likiep', name: 'Likiep' },
    { id: 'Ailuk', name: 'Ailuk' },
    { id: 'Mejit', name: 'Mejit' },
    { id: 'Ujae', name: 'Ujae' },
    { id: 'Lae', name: 'Lae' },
    { id: 'Kwajalein', name: 'Kwajalein' },
    { id: 'Wotje', name: 'Wotje' },
    { id: 'Maloelap', name: 'Maloelap' },
    { id: 'Lib (isla)', name: 'Lib (isla)' },
    { id: 'Namu', name: 'Namu' },
    { id: 'Aur', name: 'Aur' },
    { id: 'Ailinglaplap', name: 'Ailinglaplap' },
    { id: 'Jabat', name: 'Jabat' },
    { id: 'Majuro', name: 'Majuro' },
    { id: 'Arno', name: 'Arno' },
    { id: 'Namdrik', name: 'Namdrik' },
    { id: 'Jaluit', name: 'Jaluit' },
    { id: 'Mili', name: 'Mili' },
    { id: 'Ebon', name: 'Ebon' }
  ],
  MQ: [
    { id: 'Fort-de-France', name: 'Fort-de-France' },
    { id: 'La Trinité', name: 'La Trinité' },
    { id: 'Le Marin', name: 'Le Marin' },
    { id: 'Saint-Pierre', name: 'Saint-Pierre' }
  ],
  MR: [
    { id: 'Adrar', name: 'Adrar' },
    { id: 'Assaba', name: 'Assaba' },
    { id: 'Brakna', name: 'Brakna' },
    { id: 'Dakhlet Nouadhibou', name: 'Dakhlet Nouadhibou' },
    { id: 'Gorgol', name: 'Gorgol' },
    { id: 'Guidimaka', name: 'Guidimaka' },
    { id: 'Hodh Ech Chargui', name: 'Hodh Ech Chargui' },
    { id: 'Hodh El Gharbi', name: 'Hodh El Gharbi' },
    { id: 'Inchiri', name: 'Inchiri' },
    { id: 'Nouakchott', name: 'Nouakchott' },
    { id: 'Tagant', name: 'Tagant' },
    { id: 'Tiris Zemmour', name: 'Tiris Zemmour' },
    { id: 'Trarza', name: 'Trarza' }
  ],
  MU: [
    { id: 'Agalega Islands', name: 'Agalega Islands' },
    { id: 'Black River', name: 'Black River' },
    { id: 'Cargados Carajos Shoals', name: 'Cargados Carajos Shoals' },
    { id: 'Flacq', name: 'Flacq' },
    { id: 'Grand Port', name: 'Grand Port' },
    { id: 'Moka', name: 'Moka' },
    { id: 'Pamplemousses', name: 'Pamplemousses' },
    { id: 'Plaines Wilhems', name: 'Plaines Wilhems' },
    { id: 'Port Louis', name: 'Port Louis' },
    { id: 'Riviere du Rempart', name: 'Riviere du Rempart' },
    { id: 'Rodrigues', name: 'Rodrigues' },
    { id: 'Savanne', name: 'Savanne' }
  ],
  MX: [
    { id: 'Aguascalientes', name: 'Aguascalientes' },
    { id: 'Baja California', name: 'Baja California' },
    { id: 'Baja California Sur', name: 'Baja California Sur' },
    { id: 'Campeche', name: 'Campeche' },
    { id: 'Chiapas', name: 'Chiapas' },
    { id: 'Chihuahua', name: 'Chihuahua' },
    { id: 'Coahuila de Zaragoza', name: 'Coahuila de Zaragoza' },
    { id: 'Colima', name: 'Colima' },
    { id: 'Distrito Federal', name: 'Distrito Federal' },
    { id: 'Durango', name: 'Durango' },
    { id: 'Guanajuato', name: 'Guanajuato' },
    { id: 'Guerrero', name: 'Guerrero' },
    { id: 'Hidalgo', name: 'Hidalgo' },
    { id: 'Jalisco', name: 'Jalisco' },
    { id: 'Mexico', name: 'Mexico' },
    { id: 'Michoacan de Ocampo', name: 'Michoacan de Ocampo' },
    { id: 'Morelos', name: 'Morelos' },
    { id: 'Nayarit', name: 'Nayarit' },
    { id: 'Nuevo Leon', name: 'Nuevo Leon' },
    { id: 'Oaxaca', name: 'Oaxaca' },
    { id: 'Puebla', name: 'Puebla' },
    { id: 'Queretaro de Arteaga', name: 'Queretaro de Arteaga' },
    { id: 'Quintana Roo', name: 'Quintana Roo' },
    { id: 'San Luis Potosi', name: 'San Luis Potosi' },
    { id: 'Sinaloa', name: 'Sinaloa' },
    { id: 'Sonora', name: 'Sonora' },
    { id: 'Tabasco', name: 'Tabasco' },
    { id: 'Tamaulipas', name: 'Tamaulipas' },
    { id: 'Tlaxcala', name: 'Tlaxcala' },
    { id: 'Veracruz-Llave', name: 'Veracruz-Llave' },
    { id: 'Yucatan', name: 'Yucatan' },
    { id: 'Zacatecas', name: 'Zacatecas' }
  ],
  FM: [
    { id: 'Caroline Islands', name: 'Caroline Islands' },
    { id: 'Gilbert Islands', name: 'Gilbert Islands' },
    { id: 'Mariana Islands', name: 'Mariana Islands' },
    { id: 'Marshall Islands', name: 'Marshall Islands' }
  ],
  MD: [
    { id: 'Anenii Noi', name: 'Anenii Noi' },
    { id: 'Basarabeasca', name: 'Basarabeasca' },
    { id: 'Briceni', name: 'Briceni' },
    { id: 'Cahul', name: 'Cahul' },
    { id: 'Cantemir', name: 'Cantemir' },
    { id: 'Calarasi', name: 'Calarasi' },
    { id: 'Causeni', name: 'Causeni' },
    { id: 'Cimislia', name: 'Cimislia' },
    { id: 'Criuleni', name: 'Criuleni' },
    { id: 'Donduseni', name: 'Donduseni' },
    { id: 'Drochia', name: 'Drochia' },
    { id: 'Dubasari', name: 'Dubasari' },
    { id: 'Edinet', name: 'Edinet' },
    { id: 'Falesti', name: 'Falesti' },
    { id: 'Floresti', name: 'Floresti' },
    { id: 'Glodeni', name: 'Glodeni' },
    { id: 'Hincesti', name: 'Hincesti' },
    { id: 'Ialoveni', name: 'Ialoveni' },
    { id: 'Leova', name: 'Leova' },
    { id: 'Nisporeni', name: 'Nisporeni' },
    { id: 'Ocnita', name: 'Ocnita' },
    { id: 'Orhei', name: 'Orhei' },
    { id: 'Rezina', name: 'Rezina' },
    { id: 'Riscani', name: 'Riscani' },
    { id: 'Singerei', name: 'Singerei' },
    { id: 'Soldanesti', name: 'Soldanesti' },
    { id: 'Soroca', name: 'Soroca' },
    { id: 'Stefan-Voda', name: 'Stefan-Voda' },
    { id: 'Straseni', name: 'Straseni' },
    { id: 'Taraclia', name: 'Taraclia' },
    { id: 'Telenesti', name: 'Telenesti' },
    { id: 'Ungheni', name: 'Ungheni' },
    { id: 'Balti', name: 'Balti' },
    { id: 'Bender', name: 'Bender' },
    { id: 'Chisinau', name: 'Chisinau' },
    { id: 'Gagauzia', name: 'Gagauzia' },
    { id: 'Stinga Nistrului', name: 'Stinga Nistrului' }
  ],
  MC: [
    { id: 'Monaco-Ville', name: 'Monaco-Ville' },
    { id: 'Monte Carlo', name: 'Monte Carlo' },
    { id: 'La Condamine', name: 'La Condamine' }
  ],
  MN: [
    { id: 'Arhangay', name: 'Arhangay' },
    { id: 'Bayanhongor', name: 'Bayanhongor' },
    { id: 'Bayan-Olgiy', name: 'Bayan-Olgiy' },
    { id: 'Bulgan', name: 'Bulgan' },
    { id: 'Darhan Uul', name: 'Darhan Uul' },
    { id: 'Dornod', name: 'Dornod' },
    { id: 'Dornogovi', name: 'Dornogovi' },
    { id: 'Dundgovi', name: 'Dundgovi' },
    { id: 'Dzavhan', name: 'Dzavhan' },
    { id: 'Govi-Altay', name: 'Govi-Altay' },
    { id: 'Govi-Sumber', name: 'Govi-Sumber' },
    { id: 'Hentiy', name: 'Hentiy' },
    { id: 'Hovd', name: 'Hovd' },
    { id: 'Hovsgol', name: 'Hovsgol' },
    { id: 'Omnogovi', name: 'Omnogovi' },
    { id: 'Orhon', name: 'Orhon' },
    { id: 'Ovorhangay', name: 'Ovorhangay' },
    { id: 'Selenge', name: 'Selenge' },
    { id: 'Suhbaatar', name: 'Suhbaatar' },
    { id: 'Tov', name: 'Tov' },
    { id: 'Ulaanbaatar', name: 'Ulaanbaatar' },
    { id: 'Uvs', name: 'Uvs' }
  ],
  ME: [
    { id: 'Andrijevica', name: 'Andrijevica' },
    { id: 'Bar', name: 'Bar' },
    { id: 'Berane', name: 'Berane' },
    { id: 'Bijelo Polje', name: 'Bijelo Polje' },
    { id: 'Budva', name: 'Budva' },
    { id: 'Cetinje', name: 'Cetinje' },
    { id: 'Danilovgrad', name: 'Danilovgrad' },
    { id: 'Gusinje', name: 'Gusinje' },
    { id: 'Herceg Novi', name: 'Herceg Novi' },
    { id: 'Kolašin', name: 'Kolašin' },
    { id: 'Kotor', name: 'Kotor' },
    { id: 'Mojkovac', name: 'Mojkovac' },
    { id: 'Nikšić', name: 'Nikšić' },
    { id: 'Petnjica', name: 'Petnjica' },
    { id: 'Plav', name: 'Plav' },
    { id: 'Plužine', name: 'Plužine' },
    { id: 'Pljevlja', name: 'Pljevlja' },
    { id: 'Podgorica', name: 'Podgorica' },
    { id: 'Rožaje', name: 'Rožaje' },
    { id: 'Šavnik', name: 'Šavnik' },
    { id: 'Tivat', name: 'Tivat' },
    { id: 'Tuzi', name: 'Tuzi' },
    { id: 'Ulcinj', name: 'Ulcinj' },
    { id: 'Žabljak', name: 'Žabljak' }
  ],
  MS: [
    { id: 'Amersham', name: 'Amersham' },
    { id: 'Baker Hill', name: 'Baker Hill' },
    { id: 'Bank Level', name: 'Bank Level' },
    { id: 'Banks', name: 'Banks' },
    { id: 'Beech Hill', name: 'Beech Hill' },
    { id: 'Bethel', name: 'Bethel' },
    { id: 'Black Ghaut Basin', name: 'Black Ghaut Basin' },
    { id: 'Bramble', name: 'Bramble' },
    { id: 'Bransby', name: 'Bransby' },
    { id: 'Bugby Hole', name: 'Bugby Hole' },
    { id: 'Cavalla Hill', name: 'Cavalla Hill' },
    { id: 'Cheapend', name: 'Cheapend' },
    { id: 'Cork Hill', name: 'Cork Hill' },
    { id: 'Cork Hill Village', name: 'Cork Hill Village' },
    { id: 'Cudjoe Head', name: 'Cudjoe Head' },
    { id: 'Cudjoehead Village', name: 'Cudjoehead Village' },
    { id: 'Cuojoe Head', name: 'Cuojoe Head' },
    { id: 'Dagenham', name: 'Dagenham' },
    { id: 'Davy Hill', name: 'Davy Hill' },
    { id: 'Delvins', name: 'Delvins' },
    { id: 'Dick Hill', name: 'Dick Hill' },
    { id: "Drummond's", name: "Drummond's" },
    { id: 'Duberry Hill', name: 'Duberry Hill' },
    { id: "Dyer's", name: 'Agadir' },
    { id: 'Agadir', name: "Dyer's" },
    { id: 'Elberton', name: 'Elberton' },
    { id: 'Fairfield', name: 'Fairfield' },
    { id: 'Agadir', name: 'Agadir' },
    { id: 'Fairy Walk', name: 'Fairy Walk' },
    { id: 'Farm', name: 'Farm' },
    { id: 'Farm Village', name: 'Farm Village' },
    { id: 'Ffryes', name: 'Ffryes' },
    { id: 'Fleming', name: 'Fleming' },
    { id: 'Fogarty', name: 'Fogarty' },
    { id: "Fox's Bay", name: "Fox's Bay" },
    { id: 'Frith', name: 'Frith' },
    { id: 'Gages', name: 'Gages' },
    { id: 'Gallows Square', name: 'Gallows Square' },
    { id: "Gerald's", name: "Gerald's" },
    { id: "Gerald's Bottom", name: "Gerald's Bottom" },
    { id: 'Gingoes', name: 'Gingoes' },
    { id: 'Glen Mhor', name: 'Glen Mhor' },
    { id: 'Gun Hill', name: 'Gun Hill' },
    { id: 'Happy Hill', name: 'Happy Hill' },
    { id: 'Harris', name: 'Harris' },
    { id: 'Harris Lookout', name: 'Harris Lookout' },
    { id: 'Harris Village', name: 'Harris Village' },
    { id: 'Hodge’s Hill', name: 'Hodge’s Hill' },
    { id: 'Hope', name: 'Hope' },
    { id: 'Iles Bay', name: 'Iles Bay' },
    { id: 'Jones Hill', name: 'Jones Hill' },
    { id: 'Jubilee Town', name: 'Jubilee Town' },
    { id: 'Judy Piece', name: 'Judy Piece' },
    { id: 'Kate Howe', name: 'Kate Howe' },
    { id: 'Kinsale Village', name: 'Kinsale Village' },
    { id: "Lee's", name: "Lee's" },
    { id: 'Locust Valley', name: 'Locust Valley' },
    { id: 'Locust Valley', name: 'Locust Valley' },
    { id: 'Long Ground', name: 'Long Ground' },
    { id: 'Mango Hill', name: 'Mango Hill' },
    { id: 'Marko Bottom', name: 'Marko Bottom' },
    { id: 'Molyneux', name: 'Molyneux' },
    { id: "Morris'", name: "Morris'" },
    { id: 'Nixons', name: 'Nixons' },
    { id: 'Old Norwood', name: 'Old Norwood' },
    { id: 'Old Quaw', name: 'Old Quaw' },
    { id: 'Old Towne', name: 'Old Towne' },
    { id: 'Olveston', name: 'Olveston' },
    { id: 'Parsons', name: 'Parsons' },
    { id: 'Plimut', name: 'Plimut' },
    { id: 'Plimutas', name: 'Plimutas' },
    { id: 'Plimuto', name: 'Plimuto' },
    { id: 'Plymouth', name: 'Plymouth' },
    { id: 'Plymouth på Montserrat', name: 'Plymouth på Montserrat' },
    { id: 'Pond Bottom', name: 'Pond Bottom' },
    { id: 'Rendezvous', name: 'Rendezvous' },
    { id: 'Richmond', name: 'Richmond' },
    { id: 'Richmond Hill', name: 'Richmond Hill' },
    { id: "Roche's Yard", name: "Roche's Yard" },
    { id: 'Rocklands', name: 'Rocklands' },
    { id: 'Ryners', name: 'Ryners' },
    { id: "Saint George's Hill", name: "Saint George's Hill" },
    { id: 'Saint John', name: 'Saint John' },
    { id: "Saint Patrick's", name: "Saint Patrick's" },
    { id: 'Saint Peters', name: 'Saint Peters' },
    { id: 'Salem', name: 'Salem' },
    { id: 'Shooters Hill', name: 'Shooters Hill' },
    { id: 'Soldier Ghaut', name: 'Soldier Ghaut' },
    { id: 'Spanish Point', name: 'Spanish Point' },
    { id: 'Streatham', name: 'Streatham' },
    { id: "Trant's", name: "Trant's" },
    { id: 'Trials', name: 'Trials' },
    { id: "Tuitt's", name: "Tuitt's" },
    { id: 'Victoria', name: 'Victoria' },
    { id: 'Virgin Island', name: 'Virgin Island' },
    { id: 'Webbs', name: 'Webbs' },
    { id: 'Weekes', name: 'Weekes' },
    { id: 'West Hill Settlement', name: 'West Hill Settlement' },
    { id: 'Windy Hill', name: 'Windy Hill' },
    { id: 'Woodlands', name: 'Woodlands' },
    { id: 'Zion Hill', name: 'Zion Hill' }
  ],
  MA: [
    { id: 'Agadir', name: 'Agadir' },
    { id: 'Al Hoceima', name: 'Al Hoceima' },
    { id: 'Azilal', name: 'Azilal' },
    { id: 'Beni Mellal', name: 'Beni Mellal' },
    { id: 'Ben Slimane', name: 'Ben Slimane' },
    { id: 'Boulemane', name: 'Boulemane' },
    { id: 'Casablanca', name: 'Casablanca' },
    { id: 'Chaouen', name: 'Chaouen' },
    { id: 'El Jadida', name: 'El Jadida' },
    { id: 'El Kelaa des Sraghna', name: 'El Kelaa des Sraghna' },
    { id: 'Er Rachidia', name: 'Er Rachidia' },
    { id: 'Essaouira', name: 'Essaouira' },
    { id: 'Fes', name: 'Fes' },
    { id: 'Figuig', name: 'Figuig' },
    { id: 'Guelmim', name: 'Guelmim' },
    { id: 'Ifrane', name: 'Ifrane' },
    { id: 'Kenitra', name: 'Kenitra' },
    { id: 'Khemisset', name: 'Khemisset' },
    { id: 'Khenifra', name: 'Khenifra' },
    { id: 'Khouribga', name: 'Khouribga' },
    { id: 'Laayoune', name: 'Laayoune' },
    { id: 'Larache', name: 'Larache' },
    { id: 'Marrakech', name: 'Marrakech' },
    { id: 'Meknes', name: 'Meknes' },
    { id: 'Nador', name: 'Nador' },
    { id: 'Ouarzazate', name: 'Ouarzazate' },
    { id: 'Oujda', name: 'Oujda' },
    { id: 'Rabat-Sale', name: 'Rabat-Sale' },
    { id: 'Safi', name: 'Safi' },
    { id: 'Settat', name: 'Settat' },
    { id: 'Sidi Kacem', name: 'Sidi Kacem' },
    { id: 'Tangier', name: 'Tangier' },
    { id: 'Tan-Tan', name: 'Tan-Tan' },
    { id: 'Taounate', name: 'Taounate' },
    { id: 'Taroudannt', name: 'Taroudannt' },
    { id: 'Tata', name: 'Tata' },
    { id: 'Taza', name: 'Taza' },
    { id: 'Tetouan', name: 'Tetouan' },
    { id: 'Tiznit', name: 'Tiznit' }
  ],
  MZ: [
    { id: 'Cabo Delgado', name: 'Cabo Delgado' },
    { id: 'Gaza', name: 'Gaza' },
    { id: 'Inhambane', name: 'Inhambane' },
    { id: 'Manica', name: 'Manica' },
    { id: 'Maputo', name: 'Maputo' },
    { id: 'Cidade de Maputo', name: 'Cidade de Maputo' },
    { id: 'Nampula', name: 'Nampula' },
    { id: 'Niassa', name: 'Niassa' },
    { id: 'Sofala', name: 'Sofala' },
    { id: 'Tete', name: 'Tete' },
    { id: 'Zambezia', name: 'Zambezia' }
  ],
  MM: [
    { id: 'Ayeyarwady', name: 'Ayeyarwady' },
    { id: 'Bago', name: 'Bago' },
    { id: 'Chin', name: 'Chin' },
    { id: 'Kachin', name: 'Kachin' },
    { id: 'Kayah', name: 'Kayah' },
    { id: 'Kayin', name: 'Kayin' },
    { id: 'Magway', name: 'Magway' },
    { id: 'Mandalay', name: 'Mandalay' },
    { id: 'Mon', name: 'Mon' },
    { id: 'Rakhine', name: 'Rakhine' },
    { id: 'Shan', name: 'Shan' },
    { id: 'Sagaing', name: 'Sagaing' },
    { id: 'Tanintharyi', name: 'Tanintharyi' },
    { id: 'Yangon', name: 'Yangon' },
    { id: 'Naypyidaw', name: 'Naypyidaw' }
  ],
  NA: [
    { id: 'Caprivi', name: 'Caprivi' },
    { id: 'Erongo', name: 'Erongo' },
    { id: 'Hardap', name: 'Hardap' },
    { id: 'Karas', name: 'Karas' },
    { id: 'Khomas', name: 'Khomas' },
    { id: 'Kunene', name: 'Kunene' },
    { id: 'Ohangwena', name: 'Ohangwena' },
    { id: 'Okavango', name: 'Okavango' },
    { id: 'Omaheke', name: 'Omaheke' },
    { id: 'Omusati', name: 'Omusati' },
    { id: 'Oshana', name: 'Oshana' },
    { id: 'Oshikoto', name: 'Oshikoto' },
    { id: 'Otjozondjupa', name: 'Otjozondjupa' }
  ],
  KP: [
    { id: 'Chagang', name: 'Chagang' },
    { id: 'North Hamgyong', name: 'North Hamgyong' },
    { id: 'South Hamgyong', name: 'South Hamgyong' },
    { id: 'North Hwanghae', name: 'North Hwanghae' },
    { id: 'South Hwanghae', name: 'South Hwanghae' },
    { id: 'Kangwon', name: 'Kangwon' },
    { id: "North P'yongan", name: "North P'yongan" },
    { id: "South P'yongan", name: "South P'yongan" },
    { id: 'Yanggang', name: 'Yanggang' },
    { id: 'Kaesong', name: 'Kaesong' },
    { id: 'Najin', name: 'Najin' },
    { id: "Namp'o", name: "Namp'o" },
    { id: 'Pyongyang', name: 'Pyongyang' }
  ],
  NR: [
    { id: 'Aiwo', name: 'Aiwo' },
    { id: 'Anabar', name: 'Anabar' },
    { id: 'Anetan', name: 'Anetan' },
    { id: 'Anibare', name: 'Anibare' },
    { id: 'Baiti', name: 'Baiti' },
    { id: 'Boe', name: 'Boe' },
    { id: 'Buada', name: 'Buada' },
    { id: 'Denigomodu', name: 'Denigomodu' },
    { id: 'Ewa', name: 'Ewa' },
    { id: 'Ijuw', name: 'Ijuw' },
    { id: 'Meneng', name: 'Meneng' },
    { id: 'Nibok', name: 'Nibok' },
    { id: 'Uaboe', name: 'Uaboe' },
    { id: 'Yaren', name: 'Yaren' }
  ],
  NP: [
    { id: 'Bagmati', name: 'Bagmati' },
    { id: 'Bheri', name: 'Bheri' },
    { id: 'Dhawalagiri', name: 'Dhawalagiri' },
    { id: 'Gandaki', name: 'Gandaki' },
    { id: 'Janakpur', name: 'Janakpur' },
    { id: 'Karnali', name: 'Karnali' },
    { id: 'Kosi', name: 'Kosi' },
    { id: 'Lumbini', name: 'Lumbini' },
    { id: 'Mahakali', name: 'Mahakali' },
    { id: 'Mechi', name: 'Mechi' },
    { id: 'Narayani', name: 'Narayani' },
    { id: 'Rapti', name: 'Rapti' },
    { id: 'Sagarmatha', name: 'Sagarmatha' },
    { id: 'Seti', name: 'Seti' }
  ],
  MP: [
    { id: 'Agrihan', name: 'Agrihan' },
    { id: 'Aguigan', name: 'Aguigan' },
    { id: 'Alamagan', name: 'Alamagan' },
    { id: 'Anatahan', name: 'Anatahan' },
    { id: 'Farallon de Medinilla', name: 'Farallon de Medinilla' },
    { id: 'Farallon de Pajaros', name: 'Farallon de Pajaros' },
    { id: 'Guam', name: 'Guam' },
    { id: 'Guguan', name: 'Guguan' },
    { id: 'Maug Islands', name: 'Maug Islands' },
    { id: 'Pagan', name: 'Pagan' },
    { id: 'Papaungan', name: 'Papaungan' },
    { id: 'Rota', name: 'Rota' },
    { id: 'Saipan', name: 'Saipan' },
    { id: 'Sarigan', name: 'Sarigan' },
    { id: 'Tinian', name: 'Tinian' }
  ],
  NO: [
    { id: 'Akershus', name: 'Akershus' },
    { id: 'Aust-Agder', name: 'Aust-Agder' },
    { id: 'Buskerud', name: 'Buskerud' },
    { id: 'Finnmark', name: 'Finnmark' },
    { id: 'Hedmark', name: 'Hedmark' },
    { id: 'Hordaland', name: 'Hordaland' },
    { id: 'More og Romsdal', name: 'More og Romsdal' },
    { id: 'Nordland', name: 'Nordland' },
    { id: 'Nord-Trondelag', name: 'Nord-Trondelag' },
    { id: 'Oppland', name: 'Oppland' },
    { id: 'Oslo', name: 'Oslo' },
    { id: 'Ostfold', name: 'Ostfold' },
    { id: 'Rogaland', name: 'Rogaland' },
    { id: 'Sogn og Fjordane', name: 'Sogn og Fjordane' },
    { id: 'Sor-Trondelag', name: 'Sor-Trondelag' },
    { id: 'Telemark', name: 'Telemark' },
    { id: 'Troms', name: 'Troms' },
    { id: 'Vest-Agder', name: 'Vest-Agder' },
    { id: 'Vestfold', name: 'Vestfold' }
  ],
  NL: [
    { id: 'Drenthe', name: 'Drenthe' },
    { id: 'Flevoland', name: 'Flevoland' },
    { id: 'Friesland', name: 'Friesland' },
    { id: 'Gelderland', name: 'Gelderland' },
    { id: 'Groningen', name: 'Groningen' },
    { id: 'Limburg', name: 'Limburg' },
    { id: 'Noord-Brabant', name: 'Noord-Brabant' },
    { id: 'Noord-Holland', name: 'Noord-Holland' },
    { id: 'Overijssel', name: 'Overijssel' },
    { id: 'Utrecht', name: 'Utrecht' },
    { id: 'Zeeland', name: 'Zeeland' },
    { id: 'Zuid-Holland', name: 'Zuid-Holland' }
  ],
  NC: [
    { id: 'Loyalty Islands Province', name: 'Loyalty Islands Province' },
    { id: 'North Province', name: 'North Province' },
    { id: 'South Province', name: 'South Province' }
  ],
  NZ: [
    { id: 'Auckland', name: 'Auckland' },
    { id: 'Bay of Plenty', name: 'Bay of Plenty' },
    { id: 'Canterbury', name: 'Canterbury' },
    { id: 'Chatham Islands', name: 'Chatham Islands' },
    { id: 'Gisborne', name: 'Gisborne' },
    { id: "Hawke's Bay", name: "Hawke's Bay" },
    { id: 'Manawatu-Wanganui', name: 'Manawatu-Wanganui' },
    { id: 'Marlborough', name: 'Marlborough' },
    { id: 'Nelson', name: 'Nelson' },
    { id: 'Northland', name: 'Northland' },
    { id: 'Otago', name: 'Otago' },
    { id: 'NelsSouthlandon', name: 'Southland' },
    { id: 'Taranaki', name: 'Taranaki' },
    { id: 'Tasman', name: 'Tasman' },
    { id: 'Waikato', name: 'Waikato' },
    { id: 'Wellington', name: 'Wellington' },
    { id: 'West Coast', name: 'West Coast' }
  ],
  NI: [
    { id: 'Atlantico Norte', name: 'Atlantico Norte' },
    { id: 'Atlantico Sur', name: 'Atlantico Sur' },
    { id: 'Boaco', name: 'Boaco' },
    { id: 'Carazo', name: 'Carazo' },
    { id: 'Chinandega', name: 'Chinandega' },
    { id: 'Chontales', name: 'Chontales' },
    { id: 'Esteli', name: 'Esteli' },
    { id: 'Granada', name: 'Granada' },
    { id: 'Jinotega', name: 'Jinotega' },
    { id: 'Leon', name: 'Leon' },
    { id: 'Madriz', name: 'Madriz' },
    { id: 'Managua', name: 'Managua' },
    { id: 'Masaya', name: 'Masaya' },
    { id: 'Matagalpa', name: 'Matagalpa' },
    { id: 'Nueva Segovia', name: 'Nueva Segovia' },
    { id: 'Rio San Juan', name: 'Rio San Juan' },
    { id: 'Rivas', name: 'Rivas' }
  ],
  NE: [
    { id: 'Agadez', name: 'Agadez' },
    { id: 'Diffa', name: 'Diffa' },
    { id: 'Dosso', name: 'Dosso' },
    { id: 'Maradi', name: 'Maradi' },
    { id: 'Niamey', name: 'Niamey' },
    { id: 'Tahoua', name: 'Tahoua' },
    { id: 'Tillaberi', name: 'Tillaberi' },
    { id: 'Zinder', name: 'Zinder' }
  ],
  NG: [
    { id: 'Abia', name: 'Abia' },
    { id: 'Abuja Federal Capital', name: 'Abuja Federal Capital' },
    { id: 'Adamawa', name: 'Adamawa' },
    { id: 'Akwa Ibom', name: 'Akwa Ibom' },
    { id: 'Anambra', name: 'Anambra' },
    { id: 'Bauchi', name: 'Bauchi' },
    { id: 'Bayelsa', name: 'Bayelsa' },
    { id: 'Benue', name: 'Benue' },
    { id: 'Borno', name: 'Borno' },
    { id: 'Cross River', name: 'Cross River' },
    { id: 'Delta', name: 'Delta' },
    { id: 'Ebonyi', name: 'Ebonyi' },
    { id: 'Edo', name: 'Edo' },
    { id: 'Ekiti', name: 'Ekiti' },
    { id: 'Enugu', name: 'Enugu' },
    { id: 'Gombe', name: 'Gombe' },
    { id: 'Imo', name: 'Imo' },
    { id: 'Jigawa', name: 'Jigawa' },
    { id: 'Kaduna', name: 'Kaduna' },
    { id: 'Kano', name: 'Kano' },
    { id: 'Katsina', name: 'Katsina' },
    { id: 'Kebbi', name: 'Kebbi' },
    { id: 'Kogi', name: 'Kogi' },
    { id: 'Kwara', name: 'Kwara' },
    { id: 'Lagos', name: 'Lagos' },
    { id: 'Nassarawa', name: 'Nassarawa' },
    { id: 'Niger', name: 'Niger' },
    { id: 'Ogun', name: 'Ogun' },
    { id: 'Ondo', name: 'Ondo' },
    { id: 'Osun', name: 'Osun' },
    { id: 'Oyo', name: 'Oyo' },
    { id: 'Plateau', name: 'Plateau' },
    { id: 'Rivers', name: 'Rivers' },
    { id: 'Sokoto', name: 'Sokoto' },
    { id: 'Taraba', name: 'Taraba' },
    { id: 'Yobe', name: 'Yobe' },
    { id: 'Zamfara', name: 'Zamfara' }
  ],
  OM: [
    { id: 'Ad Dakhiliyah', name: 'Ad Dakhiliyah' },
    { id: 'Al Batinah', name: 'NiAl Batinahamey' },
    { id: 'Al Wusta', name: 'Al Wusta' },
    { id: 'Ash Sharqiyah', name: 'Ash Sharqiyah' },
    { id: 'Az Zahirah', name: 'Az Zahirah' },
    { id: 'Masqat', name: 'Masqat' },
    { id: 'Musandam', name: 'Musandam' },
    { id: 'Dhofar', name: 'Dhofar' }
  ],
  PK: [
    { id: 'Balochistan', name: 'Balochistan' },
    {
      id: 'North-West Frontier Province',
      name: 'North-West Frontier Province'
    },
    { id: 'Punjab', name: 'Punjab' },
    { id: 'Sindh', name: 'Sindh' },
    {
      id: 'Islamabad Capital Territory',
      name: 'Islamabad Capital Territory'
    },
    {
      id: 'Federally Administered Tribal Areas',
      name: 'Federally Administered Tribal Areas'
    }
  ],
  PW: [
    { id: 'Aimeliik', name: 'Aimeliik' },
    { id: 'Airai', name: 'Airai' },
    { id: 'Angaur', name: 'Angaur' },
    { id: 'Hatohobei', name: 'Hatohobei' },
    { id: 'Kayangel', name: 'Kayangel' },
    { id: 'Koror', name: 'Koror' },
    { id: 'Melekeok', name: 'Melekeok' },
    { id: 'Ngaraard', name: 'Ngaraard' },
    { id: 'Ngarchelong', name: 'Ngarchelong' },
    { id: 'Ngardmau', name: 'Ngardmau' },
    { id: 'Ngeremlengui', name: 'Ngeremlengui' },
    { id: 'Ngatpang', name: 'Ngatpang' },
    { id: 'Ngchesar', name: 'Ngchesar' },
    { id: 'Ngiwal', name: 'Ngiwal' },
    { id: 'Peleliu', name: 'Peleliu' },
    { id: 'Sonsorol', name: 'Sonsorol' }
  ],
  PS: [
    { id: 'East Jerusalem', name: 'East Jerusalem' },
    { id: 'Gaza Strip', name: 'Gaza Strip' },
    { id: 'West Bank', name: 'West Bank' }
  ],
  PA: [
    { id: 'Bocas del Toro', name: 'Bocas del Toro' },
    { id: 'Chiriqui', name: 'Chiriqui' },
    { id: 'Cocle', name: 'Cocle' },
    { id: 'Colon', name: 'Colon' },
    { id: 'Darien', name: 'Darien' },
    { id: 'Herrera', name: 'Herrera' },
    { id: 'Los Santos', name: 'Los Santos' },
    { id: 'Panama', name: 'Panama' },
    { id: 'San Blas', name: 'San Blas' },
    { id: 'Veraguas', name: 'Veraguas' }
  ],
  PG: [
    { id: 'Bougainville', name: 'Bougainville' },
    { id: 'Central', name: 'Central' },
    { id: 'Chimbu', name: 'Chimbu' },
    { id: 'Eastern Highlands', name: 'Eastern Highlands' },
    { id: 'East New Britain', name: 'East New Britain' },
    { id: 'East Sepik', name: 'East Sepik' },
    { id: 'Enga', name: 'Enga' },
    { id: 'Gulf', name: 'Gulf' },
    { id: 'Madang', name: 'Madang' },
    { id: 'Manus', name: 'Manus' },
    { id: 'Milne Bay', name: 'Milne Bay' },
    { id: 'Morobe', name: 'Morobe' },
    { id: 'National Capital', name: 'National Capital' },
    { id: 'New Ireland', name: 'New Ireland' },
    { id: 'Northern', name: 'Northern' },
    { id: 'Sandaun', name: 'Sandaun' },
    { id: 'Southern Highlands', name: 'Southern Highlands' },
    { id: 'Western', name: 'Western' },
    { id: 'Western Highlands', name: 'Western Highlands' },
    { id: 'West New Britain', name: 'West New Britain' }
  ],
  PY: [
    { id: 'Alto Paraguay', name: 'Alto Paraguay' },
    { id: 'Alto Parana', name: 'Alto Parana' },
    { id: 'Amambay', name: 'Amambay' },
    { id: 'Asuncion', name: 'Asuncion' },
    { id: 'Boqueron', name: 'Boqueron' },
    { id: 'Caaguazu', name: 'Caaguazu' },
    { id: 'Caazapa', name: 'Caazapa' },
    { id: 'Canindeyu', name: 'Canindeyu' },
    { id: 'Central', name: 'Central' },
    { id: 'Concepcion', name: 'Concepcion' },
    { id: 'Cordillera', name: 'Cordillera' },
    { id: 'Guaira', name: 'Guaira' },
    { id: 'Itapua', name: 'Itapua' },
    { id: 'Misiones', name: 'Misiones' },
    { id: 'Neembucu', name: 'Neembucu' },
    { id: 'Paraguari', name: 'Paraguari' },
    { id: 'Presidente Hayes', name: 'Presidente Hayes' },
    { id: 'San Pedro', name: 'San Pedro' }
  ],
  PE: [
    { id: 'Amazonas', name: 'Amazonas' },
    { id: 'Ancash', name: 'Ancash' },
    { id: 'Apurimac', name: 'Apurimac' },
    { id: 'Arequipa', name: 'Arequipa' },
    { id: 'Ayacucho', name: 'Ayacucho' },
    { id: 'Cajamarca', name: 'Cajamarca' },
    { id: 'Callao', name: 'Callao' },
    { id: 'Cusco', name: 'Cusco' },
    { id: 'Huancavelica', name: 'Huancavelica' },
    { id: 'Huanuco', name: 'Huanuco' },
    { id: 'Ica', name: 'Ica' },
    { id: 'Junin', name: 'Junin' },
    { id: 'La Libertad', name: 'La Libertad' },
    { id: 'Lambayeque', name: 'Lambayeque' },
    { id: 'Lima', name: 'Lima' },
    { id: 'Loreto', name: 'Loreto' },
    { id: 'Madre de Dios', name: 'Madre de Dios' },
    { id: 'Moquegua', name: 'Moquegua' },
    { id: 'Pasco', name: 'Pasco' },
    { id: 'Piura', name: 'Piura' },
    { id: 'Puno', name: 'Puno' },
    { id: 'San Martin', name: 'San Martin' },
    { id: 'Tacna', name: 'Tacna' },
    { id: 'Tumbes', name: 'Tumbes' },
    { id: 'Ucayali', name: 'Ucayali' }
  ],
  PH: [
    { id: 'Abra', name: 'Abra' },
    { id: 'Agusan del Norte', name: 'Agusan del Norte' },
    { id: 'Agusan del Sur', name: 'Agusan del Sur' },
    { id: 'Aklan', name: 'Aklan' },
    { id: 'Albay', name: 'Albay' },
    { id: 'Antique', name: 'Antique' },
    { id: 'Apayao', name: 'Apayao' },
    { id: 'Aurora', name: 'Aurora' },
    { id: 'Basilan', name: 'Basilan' },
    { id: 'Bataan', name: 'Bataan' },
    { id: 'Batanes', name: 'Batanes' },
    { id: 'Batangas', name: 'Batangas' },
    { id: 'Biliran', name: 'Biliran' },
    { id: 'Benguet', name: 'Benguet' },
    { id: 'Bohol', name: 'Bohol' },
    { id: 'Bukidnon', name: 'Bukidnon' },
    { id: 'Bulacan', name: 'Bulacan' },
    { id: 'Cagayan', name: 'Cagayan' },
    { id: 'Camarines Norte', name: 'Camarines Norte' },
    { id: 'Camarines Sur', name: 'Camarines Sur' },
    { id: 'Camiguin', name: 'Camiguin' },
    { id: 'Capiz', name: 'Capiz' },
    { id: 'Catanduanes', name: 'Catanduanes' },
    { id: 'Cavite', name: 'Cavite' },
    { id: 'NordlCebuand', name: 'Cebu' },
    { id: 'Compostela', name: 'Compostela' },
    { id: 'Davao del Norte', name: 'Davao del Norte' },
    { id: 'Davao del Sur', name: 'Davao del Sur' },
    { id: 'Davao Oriental', name: 'Davao Oriental' },
    { id: 'Eastern Samar', name: 'Eastern Samar' },
    { id: 'Guimaras', name: 'Guimaras' },
    { id: 'Ifugao', name: 'Ifugao' },
    { id: 'Ilocos Norte', name: 'Ilocos Norte' },
    { id: 'Ilocos Sur', name: 'Ilocos Sur' },
    { id: 'Iloilo', name: 'Iloilo' },
    { id: 'Isabela', name: 'Isabela' },
    { id: 'Kalinga', name: 'Kalinga' },
    { id: 'Laguna', name: 'Laguna' },
    { id: 'Lanao del Norte', name: 'Lanao del Norte' },
    { id: 'Lanao del Sur', name: 'Lanao del Sur' },
    { id: 'La Union', name: 'La Union' },
    { id: 'Leyte', name: 'Leyte' },
    { id: 'MaguindanaoTroms', name: 'Maguindanao' },
    { id: 'Marinduque', name: 'Marinduque' },
    { id: 'Masbate', name: 'Masbate' },
    { id: 'Mindoro Occidental', name: 'Mindoro Occidental' },
    { id: 'Mindoro Oriental', name: 'Mindoro Oriental' },
    { id: 'Misamis Occidental', name: 'Misamis Occidental' },
    { id: 'Misamis Oriental', name: 'Misamis Oriental' },
    { id: 'Mountain Province', name: 'Mountain Province' },
    { id: 'Negros Occidental', name: 'Negros Occidental' },
    { id: 'Negros Oriental', name: 'Negros Oriental' },
    { id: 'North Cotabato', name: 'North Cotabato' },
    { id: 'Northern Samar', name: 'Northern Samar' },
    { id: 'Nueva Ecija', name: 'Nueva Ecija' },
    { id: 'Nueva Vizcaya', name: 'Nueva Vizcaya' },
    { id: 'Palawan', name: 'Palawan' },
    { id: 'Pampanga', name: 'Pampanga' },
    { id: 'Pangasinan', name: 'Pangasinan' },
    { id: 'Quezon', name: 'Quezon' },
    { id: 'Quirino', name: 'Quirino' },
    { id: 'Rizal', name: 'Rizal' },
    { id: 'Romblon', name: 'Romblon' },
    { id: 'Samar', name: 'Samar' },
    { id: 'Sarangani', name: 'Sarangani' },
    { id: 'Siquijor', name: 'Siquijor' },
    { id: 'Sorsogon', name: 'Sorsogon' },
    { id: 'South Cotabato', name: 'South Cotabato' },
    { id: 'Southern Leyte', name: 'Southern Leyte' },
    { id: 'Sultan Kudarat', name: 'Sultan Kudarat' },
    { id: 'Sulu', name: 'Sulu' },
    { id: 'Surigao del Norte', name: 'Surigao del Norte' },
    { id: 'Surigao del Sur', name: 'Surigao del Sur' },
    { id: 'Tarlac', name: 'Tarlac' },
    { id: 'Tawi-Tawi', name: 'Tawi-Tawi' },
    { id: 'Zambales', name: 'Zambales' },
    { id: 'Zamboanga del Norte', name: 'Zamboanga del Norte' },
    { id: 'Zamboanga del Sur', name: 'Zamboanga del Sur' },
    { id: 'Zamboanga Sibugay', name: 'Zamboanga Sibugay' }
  ],
  PL: [
    {
      id: 'Greater Poland (Wielkopolskie)',
      name: 'Greater Poland (Wielkopolskie)'
    },
    {
      id: 'Kuyavian-Pomeranian (Kujawsko-Pomorskie)',
      name: 'Kuyavian-Pomeranian (Kujawsko-Pomorskie)'
    },
    {
      id: 'Lesser Poland (Malopolskie)',
      name: 'Lesser Poland (Malopolskie)'
    },
    { id: 'Lodz (Lodzkie)', name: 'Lodz (Lodzkie)' },
    {
      id: 'Lower Silesian (Dolnoslaskie)',
      name: 'Lower Silesian (Dolnoslaskie)'
    },
    { id: 'Lublin (Lubelskie)', name: 'Lublin (Lubelskie)' },
    { id: 'Lubusz (Lubuskie)', name: 'Lubusz (Lubuskie)' },
    { id: 'Masovian (Mazowieckie)', name: 'Masovian (Mazowieckie)' },
    { id: 'Opole (Opolskie)', name: 'Opole (Opolskie)' },
    { id: 'Podlasie (Podlaskie)', name: 'Podlasie (Podlaskie)' },
    { id: 'Pomeranian (Pomorskie)', name: 'Pomeranian (Pomorskie)' },
    { id: 'Silesian (Slaskie)', name: 'Silesian (Slaskie)' },
    {
      id: 'Subcarpathian (Podkarpackie)',
      name: 'Subcarpathian (Podkarpackie)'
    },
    {
      id: 'Swietokrzyskie (Swietokrzyskie)',
      name: 'Swietokrzyskie (Swietokrzyskie)'
    },
    {
      id: 'Warmian-Masurian (Warminsko-Mazurskie)',
      name: 'Warmian-Masurian (Warminsko-Mazurskie)'
    },
    {
      id: 'West Pomeranian (Zachodniopomorskie)',
      name: 'West Pomeranian (Zachodniopomorskie)'
    }
  ],
  PT: [
    { id: 'Aveiro', name: 'Aveiro' },
    { id: 'Acores', name: 'Acores' },
    { id: 'Beja', name: 'Beja' },
    { id: 'Braga', name: 'Braga' },
    { id: 'Braganca', name: 'Braganca' },
    { id: 'Castelo Branco', name: 'Castelo Branco' },
    { id: 'Coimbra', name: 'Coimbra' },
    { id: 'Evora', name: 'Evora' },
    { id: 'Faro', name: 'Faro' },
    { id: 'Guarda', name: 'Guarda' },
    { id: 'Leiria', name: 'Leiria' },
    { id: 'Lisboa', name: 'Lisboa' },
    { id: 'Madeira', name: 'Madeira' },
    { id: 'Portalegre', name: 'Portalegre' },
    { id: 'Porto', name: 'Porto' },
    { id: 'Santarem', name: 'Santarem' },
    { id: 'Setubal', name: 'Setubal' },
    { id: 'Viana do Castelo', name: 'Viana do Castelo' },
    { id: 'Vila Real', name: 'Vila Real' },
    { id: 'Viseu', name: 'Viseu' }
  ],
  PR: [
    { id: 'Arecibo', name: 'Arecibo' },
    { id: 'Aguadilla Pueblo', name: 'Aguadilla Pueblo' },
    { id: 'Bayamón', name: 'Bayamón' },
    { id: 'Cabo Rojo', name: 'Cabo Rojo' },
    { id: 'Caguas', name: 'Caguas' },
    { id: 'Carlota Amalia', name: 'Carlota Amalia' },
    { id: 'Carolina', name: 'Carolina' },
    { id: 'Frederiksted', name: 'Frederiksted' },
    { id: 'Guayama', name: 'Guayama' },
    { id: 'Humacao', name: 'Humacao' },
    { id: 'Isabela', name: 'Isabela' },
    { id: 'Mayaguez', name: 'Mayaguez' },
    { id: 'Ponce', name: 'Ponce' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'Yauco', name: 'Yauco' }
  ],
  QA: [
    { id: 'Ad Dawhah', name: 'Ad Dawhah' },
    { id: 'Al Ghuwayriyah', name: 'Al Ghuwayriyah' },
    { id: 'Al Jumayliyah', name: 'Al Jumayliyah' },
    { id: 'Al Khawr', name: 'Al Khawr' },
    { id: 'Al Wakrah', name: 'Al Wakrah' },
    { id: 'Ar Rayyan', name: 'Ar Rayyan' },
    { id: 'Jarayan al Batinah', name: 'Jarayan al Batinah' },
    { id: 'Madinat ash Shamal', name: 'Madinat ash Shamal' },
    { id: "Umm Sa'id", name: "Umm Sa'id" },
    { id: 'Umm Salal', name: 'Umm Salal' }
  ],
  RE: [
    { id: 'La Possession', name: 'La Possession' },
    { id: 'Le Port', name: 'Le Port' },
    { id: 'Saint-Denis', name: 'Saint-Denis' },
    { id: 'Saint-Benoit', name: 'Saint-Benoit' },
    { id: 'Saint-Gilles', name: 'Saint-Gilles' },
    { id: 'Saint-Leu', name: 'Saint-Leu' },
    { id: 'Saint-Paul', name: 'Saint-Paul' },
    { id: 'Saint-Pierre', name: 'Saint-Pierre' },
    { id: 'Etang-Salé', name: 'Etang-Salé' }
  ],
  RO: [
    { id: 'Alba', name: 'Alba' },
    { id: 'Arad', name: 'Arad' },
    { id: 'Arges', name: 'Arges' },
    { id: 'Bacau', name: 'Bacau' },
    { id: 'Bihor', name: 'Bihor' },
    { id: 'Bistrita-Nasaud', name: 'Bistrita-Nasaud' },
    { id: 'Botosani', name: 'Botosani' },
    { id: 'Braila', name: 'Braila' },
    { id: 'Brasov', name: 'Brasov' },
    { id: 'Bucuresti', name: 'Bucuresti' },
    { id: 'Buzau', name: 'Buzau' },
    { id: 'Calarasi', name: 'Calarasi' },
    { id: 'Caras-Severin', name: 'Caras-Severin' },
    { id: 'Cluj', name: 'Cluj' },
    { id: 'Constanta', name: 'Constanta' },
    { id: 'Covasna', name: 'Covasna' },
    { id: 'Dimbovita', name: 'Dimbovita' },
    { id: 'Dolj', name: 'Dolj' },
    { id: 'Galati', name: 'Galati' },
    { id: 'Gorj', name: 'Gorj' },
    { id: 'Giurgiu', name: 'Giurgiu' },
    { id: 'Harghita', name: 'Harghita' },
    { id: 'Hunedoara', name: 'Hunedoara' },
    { id: 'Ialomita', name: 'Ialomita' },
    { id: 'Iasi', name: 'Iasi' },
    { id: 'Ilfov', name: 'Ilfov' },
    { id: 'Maramures', name: 'Maramures' },
    { id: 'Mehedinti', name: 'Mehedinti' },
    { id: 'Mures', name: 'Mures' },
    { id: 'Neamt', name: 'Neamt' },
    { id: 'Olt', name: 'Olt' },
    { id: 'Prahova', name: 'Prahova' },
    { id: 'Salaj', name: 'Salaj' },
    { id: 'Satu Mare', name: 'Satu Mare' },
    { id: 'Sibiu', name: 'Sibiu' },
    { id: 'Suceava', name: 'Suceava' },
    { id: 'Teleorman', name: 'Teleorman' },
    { id: 'Timis', name: 'Timis' },
    { id: 'Tulcea', name: 'Tulcea' },
    { id: 'Vaslui', name: 'Vaslui' },
    { id: 'Vilcea', name: 'Vilcea' },
    { id: 'Vrancea', name: 'Vrancea' }
  ],
  RU: [
    { id: 'Amur', name: 'Amur' },
    { id: "Arkhangel'sk", name: "Arkhangel'sk" },
    { id: "Astrakhan'", name: "Astrakhan'" },
    { id: 'GikongBelgorodoro', name: 'Belgorod' },
    { id: 'Bryansk', name: 'Bryansk' },
    { id: 'Chelyabinsk', name: 'Chelyabinsk' },
    { id: 'BrChitaaila', name: 'Chita' },
    { id: 'Irkutsk', name: 'Irkutsk' },
    { id: 'Ivanovo', name: 'Ivanovo' },
    { id: 'Kaliningrad', name: 'Kaliningrad' },
    { id: 'Kaluga', name: 'Kaluga' },
    { id: 'Kamchatka', name: 'Kamchatka' },
    { id: 'Kemerovo', name: 'Kemerovo' },
    { id: 'Kirov', name: 'Kirov' },
    { id: 'Kostroma', name: 'Kostroma' },
    { id: 'Kurgan', name: 'Kurgan' },
    { id: 'Kursk', name: 'Kursk' },
    { id: 'Leningrad', name: 'Leningrad' },
    { id: 'Lipetsk', name: 'Lipetsk' },
    { id: 'Magadan', name: 'Magadan' },
    { id: 'Moscow', name: 'Moscow' },
    { id: 'Murmansk', name: 'Murmansk' },
    { id: 'Nizhniy Novgorod', name: 'Nizhniy Novgorod' },
    { id: 'Novgorod', name: 'Novgorod' },
    { id: 'Novosibirsk', name: 'Novosibirsk' },
    { id: 'Omsk', name: 'Omsk' },
    { id: 'Orenburg', name: 'Orenburg' },
    { id: 'Orel', name: 'Orel' },
    { id: 'Penza', name: 'Penza' },
    { id: "Perm'", name: "Perm'" },
    { id: 'Pskov', name: 'Pskov' },
    { id: 'Rostov', name: 'Rostov' },
    { id: 'Ryazan', name: 'Ryazan' },
    { id: 'Sakhalin', name: 'Sakhalin' },
    { id: 'Samara', name: 'Samara' },
    { id: 'Saratov', name: 'Saratov' },
    { id: 'Smolensk', name: 'Smolensk' },
    { id: 'Sverdlovsk', name: 'Sverdlovsk' },
    { id: 'Tambov', name: 'Tambov' },
    { id: 'Tomsk', name: 'Tomsk' },
    { id: 'Tula', name: 'Tula' },
    { id: "Tver'", name: "Tver'" },
    { id: "Tyumen'", name: "Tyumen'" },
    { id: "Ul'yanovsk", name: "Ul'yanovsk" },
    { id: 'Vladimir', name: 'Vladimir' },
    { id: 'Volgograd', name: 'Volgograd' },
    { id: 'Vologda', name: 'Vologda' },
    { id: 'Voronezh', name: 'Voronezh' },
    { id: "Yaroslavl'", name: "Yaroslavl'" },
    { id: 'Adygeya', name: 'Adygeya' },
    { id: 'Altay', name: 'Altay' },
    { id: 'Bashkortostan', name: 'Bashkortostan' },
    { id: 'Buryatiya', name: 'Buryatiya' },
    { id: 'Mindoro Oriental', name: 'Chechnya' },
    { id: 'Chuvashiya', name: 'Chuvashiya' },
    { id: 'Dagestan', name: 'Dagestan' },
    { id: 'Ingushetiya', name: 'Ingushetiya' },
    { id: 'Kabardino-Balkariya', name: 'Kabardino-Balkariya' },
    { id: 'Kalmykiya', name: 'Kalmykiya' },
    { id: 'Karachayevo-Cherkesiya', name: 'Karachayevo-Cherkesiya' },
    { id: 'Kareliya', name: 'Kareliya' },
    { id: 'Khakasiya', name: 'Khakasiya' },
    { id: 'Komi', name: 'Komi' },
    { id: 'Mariy-El', name: 'Mariy-El' },
    { id: 'Mordoviya', name: 'Mordoviya' },
    { id: 'Sakha', name: 'Sakha' },
    { id: 'North Ossetia', name: 'North Ossetia' },
    { id: 'Tatarstan', name: 'Tatarstan' },
    { id: 'Tyva', name: 'Tyva' },
    { id: 'Udmurtiya', name: 'Udmurtiya' },
    { id: 'Aga Buryat', name: 'Aga Buryat' },
    { id: 'Chukotka', name: 'Chukotka' },
    { id: 'Evenk', name: 'Evenk' },
    { id: 'Khanty-Mansi', name: 'Khanty-Mansi' },
    { id: 'Komi-Permyak', name: 'Komi-Permyak' },
    { id: 'Koryak', name: 'Koryak' },
    { id: 'Nenets', name: 'Nenets' },
    { id: 'Taymyr', name: 'Taymyr' },
    { id: "Ust'-Orda Buryat", name: "Ust'-Orda Buryat" },
    { id: 'Yamalo-Nenets', name: 'Yamalo-Nenets' },
    { id: 'Altay', name: 'Altay' },
    { id: 'Khabarovsk', name: 'Khabarovsk' },
    { id: 'Krasnodar', name: 'Krasnodar' },
    { id: 'Krasnoyarsk', name: 'Krasnoyarsk' },
    { id: 'Primorskiy', name: 'Primorskiy' },
    { id: "Stavropol'", name: "Stavropol'" },
    { id: 'Moscow', name: 'Moscow' },
    { id: 'St. Petersburg', name: 'St. Petersburg' },
    { id: 'Yevrey', name: 'Yevrey' }
  ],
  RW: [
    { id: 'Butare', name: 'Butare' },
    { id: 'Byumba', name: 'Byumba' },
    { id: 'Cyangugu', name: 'Cyangugu' },
    { id: 'Gikongoro', name: 'Gikongoro' },
    { id: 'Gisenyi', name: 'Gisenyi' },
    { id: 'Gitarama', name: 'Gitarama' },
    { id: 'Kibungo', name: 'Kibungo' },
    { id: 'Kibuye', name: 'Kibuye' },
    { id: 'Kigali Rurale', name: 'Kigali Rurale' },
    { id: 'Kigali-ville', name: 'Kigali-ville' },
    { id: 'Umutara', name: 'Umutara' },
    { id: 'Ruhengeri', name: 'Ruhengeri' }
  ],
  PM: [
    { id: 'Miquelon', name: 'Miquelon' },
    { id: 'Saint-Pierre', name: 'Saint-Pierre' }
  ],
  WS: [
    { id: "A'ana", name: "A'ana" },
    { id: 'Aiga-i-le-Tai', name: 'Aiga-i-le-Tai' },
    { id: 'Atua', name: 'Atua' },
    { id: "Fa'asaleleaga", name: "Fa'asaleleaga" },
    { id: "Gaga'emauga", name: "Gaga'emauga" },
    { id: 'Gagaifomauga', name: 'Gagaifomauga' },
    { id: 'Palauli', name: 'Palauli' },
    { id: "Satupa'itea", name: "Satupa'itea" },
    { id: 'Tuamasaga', name: 'Tuamasaga' },
    { id: "Va'a-o-Fonoti", name: "Va'a-o-Fonoti" },
    { id: 'Vaisigano', name: 'Vaisigano' }
  ],
  SM: [
    { id: 'Acquaviva', name: 'Acquaviva' },
    { id: 'Borgo Maggiore', name: 'Borgo Maggiore' },
    { id: 'Chiesanuova', name: 'Chiesanuova' },
    { id: 'Domagnano', name: 'Domagnano' },
    { id: 'Faetano', name: 'Faetano' },
    { id: 'Fiorentino', name: 'Fiorentino' },
    { id: 'Montegiardino', name: 'Montegiardino' },
    { id: 'San Marino Citta', name: 'San Marino Citta' },
    { id: 'Serravalle', name: 'Serravalle' }
  ],
  ST: [
    { id: 'Água Grande', name: 'Água Grande' },
    { id: 'Cantagalo', name: 'Cantagalo' },
    { id: 'Caué', name: 'Caué' },
    { id: 'Lembá', name: 'Lembá' },
    { id: 'Lobata', name: 'Lobata' },
    { id: 'Mé-Zóchi', name: 'Mé-Zóchi' },
    {
      id: 'Autonomous Region of Príncipe ',
      name: 'Autonomous Region of Príncipe '
    }
  ],
  SA: [
    { id: 'Al Bahah', name: 'Al Bahah' },
    { id: 'Al Hudud ash Shamaliyah', name: 'Al Hudud ash Shamaliyah' },
    { id: 'Al Jawf', name: 'Al Jawf' },
    { id: 'Al Madinah', name: 'Al Madinah' },
    { id: 'Al Qasim', name: 'Al Qasim' },
    { id: 'Ar Riyad', name: 'Ar Riyad' },
    { id: 'Ash Sharqiyah', name: 'Ash Sharqiyah' },
    { id: "'Asir", name: "'Asir" },
    { id: "Ha'il", name: "Ha'il" },
    { id: 'Jizan', name: 'Jizan' },
    { id: 'Makkah', name: 'Makkah' },
    { id: 'Najran', name: 'Najran' },
    { id: 'Tabuk', name: 'Tabuk' }
  ],
  SN: [
    { id: 'Dakar', name: 'Dakar' },
    { id: 'Diourbel', name: 'Diourbel' },
    { id: 'Fatick', name: 'Fatick' },
    { id: 'Kolda', name: 'Kolda' },
    { id: 'Louga', name: 'Louga' },
    { id: 'Matam', name: 'Matam' },
    { id: 'Saint-Louis', name: 'Saint-Louis' },
    { id: 'Tambacounda', name: 'Tambacounda' },
    { id: 'Thies', name: 'Thies' },
    { id: 'Ziguinchor', name: 'Ziguinchor' }
  ],
  RS: [
    { id: 'Kosovo', name: 'Kosovo' },
    { id: 'Montenegro', name: 'Montenegro' },
    { id: 'Serbia', name: 'Serbia' },
    { id: 'Vojvodina', name: 'Vojvodina' }
  ],
  SC: [
    { id: 'Anse aux Pins', name: 'Anse aux Pins' },
    { id: 'Anse Boileau', name: 'Anse Boileau' },
    { id: 'Anse Etoile', name: 'Anse Etoile' },
    { id: 'Anse Louis', name: 'Anse Louis' },
    { id: 'Anse Royale', name: 'Anse Royale' },
    { id: 'Baie Lazare', name: 'Baie Lazare' },
    { id: 'Baie Sainte Anne', name: 'Baie Sainte Anne' },
    { id: 'Beau Vallon', name: 'Beau Vallon' },
    { id: 'Bel Air', name: 'Bel Air' },
    { id: 'Bel Ombre', name: 'Bel Ombre' },
    { id: 'Cascade', name: 'Cascade' },
    { id: 'Glacis', name: 'Glacis' },
    { id: "Grand' Anse", name: "Grand' Anse" },
    { id: "Grand' Anse", name: "Grand' Anse" },
    { id: 'La Digue', name: 'La Digue' },
    { id: 'La Riviere Anglaise', name: 'La Riviere Anglaise' },
    { id: 'Mont Buxton', name: 'Mont Buxton' },
    { id: 'Mont Fleuri', name: 'Mont Fleuri' },
    { id: 'Plaisance', name: 'Plaisance' },
    { id: 'Pointe La Rue', name: 'Pointe La Rue' },
    { id: 'Port Glaud', name: 'Port Glaud' },
    { id: 'Saint Louis', name: 'Saint Louis' },
    { id: 'Takamaka', name: 'Takamaka' }
  ],
  SL: [
    { id: 'Eastern Province', name: 'Eastern Province' },
    { id: 'Northern Province', name: 'Northern Province' },
    { id: 'North West Province', name: 'North West Province' },
    { id: 'Southern Province', name: 'Southern Province' }
  ],
  SG: [
    { id: 'Central Region', name: 'Central Region' },
    { id: 'East Region', name: 'East Region' },
    { id: 'North Region', name: 'North Region' },
    { id: 'North-East Region', name: 'North-East Region' },
    { id: 'West Region', name: 'West Region' }
  ],
  SX: [
    { id: 'Cul de Sac', name: 'Cul de Sac' },
    { id: 'Koolbaai', name: 'Koolbaai' },
    { id: 'Saint-Martin', name: 'Saint-Martin' },
    { id: 'Philipsburg', name: 'Philipsburg' }
  ],
  SK: [
    { id: 'Banskobystricky', name: 'Banskobystricky' },
    { id: 'Bratislavsky', name: 'Bratislavsky' },
    { id: 'Kosicky', name: 'Kosicky' },
    { id: 'Nitriansky', name: 'Nitriansky' },
    { id: 'Presovsky', name: 'Presovsky' },
    { id: 'Trenciansky', name: 'Trenciansky' },
    { id: 'Trnavsky', name: 'Trnavsky' },
    { id: 'Zilinsky', name: 'Zilinsky' }
  ],
  SI: [
    { id: 'Ajdovscina', name: 'Ajdovscina' },
    { id: 'Beltinci', name: 'AnkaraBeltinci' },
    { id: 'Benedikt', name: 'Benedikt' },
    { id: 'Bistrica ob Sotli', name: 'Bistrica ob Sotli' },
    { id: 'Bled', name: 'Bled' },
    { id: 'Bloke', name: 'Bloke' },
    { id: 'Bohinj', name: 'Bohinj' },
    { id: 'Borovnica', name: 'Borovnica' },
    { id: 'Bovec', name: 'Bovec' },
    { id: 'Braslovce', name: 'Braslovce' },
    { id: 'Brda', name: 'Brda' },
    { id: 'Brezice', name: 'Brezice' },
    { id: 'Brezovica', name: 'Brezovica' },
    { id: 'Cankova', name: 'Cankova' },
    { id: 'Celje', name: 'Celje' },
    { id: 'Cerklje na Gorenjskem', name: 'Cerklje na Gorenjskem' },
    { id: 'Cerknica', name: 'Cerknica' },
    { id: 'Cerkno', name: 'Cerkno' },
    { id: 'Cerkvenjak', name: 'Cerkvenjak' },
    { id: 'Crensovci', name: 'Crensovci' },
    { id: 'Crna na Koroskem', name: 'Crna na Koroskem' },
    { id: 'Crnomelj', name: 'Crnomelj' },
    { id: 'Destrnik', name: 'Destrnik' },
    { id: 'Divaca', name: 'Divaca' },
    { id: 'Dobje', name: 'Dobje' },
    { id: 'GiresDobrepoljeun', name: 'Dobrepolje' },
    { id: 'GiDobrnaresun', name: 'Dobrna' },
    {
      id: 'Dobrova-Horjul-Polhov Gradec',
      name: 'Dobrova-Horjul-Polhov Gradec'
    },
    { id: 'Dobrovnik-Dobronak', name: 'Dobrovnik-Dobronak' },
    { id: 'Dolenjske Toplice', name: 'Dolenjske Toplice' },
    { id: 'Dol pri Ljubljani', name: 'Dol pri Ljubljani' },
    { id: 'Domzale', name: 'Domzale' },
    { id: 'Dornava', name: 'Dornava' },
    { id: 'Dravograd', name: 'Dravograd' },
    { id: 'Duplek', name: 'Duplek' },
    { id: 'Gorenja Vas-Poljane', name: 'PheGorenja Vas-Poljanetchabun' },
    { id: 'Gorisnica', name: 'Gorisnica' },
    { id: 'PhetchaGornja Radgonabun', name: 'PhGornja Radgonaetchabun' },
    { id: 'Gornji Grad', name: 'Gornji Grad' },
    { id: 'Gornji Petrovci', name: 'Gornji Petrovci' },
    { id: 'Grad', name: 'Grad' },
    { id: 'Grosuplje', name: 'PhetcGrosupljehabun' },
    { id: 'Hajdina', name: 'Hajdina' },
    { id: 'HoHoce-Slivnicarjul', name: 'Hoce-Slivnica' },
    { id: 'Hodos-Hodos', name: 'Hodos-Hodos' },
    { id: 'Horjul', name: 'Horjul' },
    { id: 'Hrastnik', name: 'Hrastnik' },
    { id: 'Hrpelje-Kozina', name: 'SivHrpelje-Kozinaas' },
    { id: 'Idrija', name: 'Idrija' },
    { id: 'Ig', name: 'Ig' },
    { id: 'Ilirska Bistrica', name: 'SivaIlirska Bistricas' },
    { id: 'Ivancna Gorica', name: 'SivIvancna Goricaas' },
    { id: 'Izola-Isola', name: 'SivIzola-Isolaas' },
    { id: 'Jesenice', name: 'Jesenice' },
    { id: 'Jezersko', name: 'Jezersko' },
    { id: 'Jursinci', name: 'Jursinci' },
    { id: 'Kamnik', name: 'Kamnik' },
    { id: 'Kanal', name: 'Kanal' },
    { id: 'Kidricevo', name: 'Kidricevo' },
    { id: 'Kobarid', name: 'KKobaridostel' },
    { id: 'Kobilje', name: 'Kobilje' },
    { id: 'Kocevje', name: 'Kocevje' },
    { id: 'Kmen', name: 'Kmen' },
    { id: 'Komenda', name: 'KosKomendatel' },
    { id: 'Koper-Capodistria', name: 'Koper-Capodistria' },
    { id: 'Kostel', name: 'Kostel' },
    { id: 'Kozje', name: 'LenKozjeart' },
    { id: 'Kranj', name: 'LenKranjart' },
    { id: 'Kranjska Gora', name: 'Kranjska Gora' },
    { id: 'Krizevci', name: 'Krizevci' },
    { id: 'Krsko', name: 'Krsko' },
    { id: 'Kungota', name: 'Kungota' },
    { id: 'Kuzma', name: 'Kuzma' },
    { id: 'Lasko', name: 'Lasko' },
    { id: 'Lenart', name: 'Lenart' },
    { id: 'Lendava-Lendva', name: 'Lendava-Lendva' },
    { id: 'Litija', name: 'Litija' },
    { id: 'Ljubljana', name: 'Ljubljana' },
    { id: 'Ljubno', name: 'Ljubno' },
    { id: 'Ljutomer', name: 'Ljutomer' },
    { id: 'Logatec', name: 'Logatec' },
    { id: 'Loska Dolina', name: 'Loska Dolina' },
    { id: 'Loski Potok', name: 'Loski Potok' },
    { id: 'Lovrenc na Pohorju', name: 'Lovrenc na Pohorju' },
    { id: 'Luce', name: 'Luce' },
    { id: 'Lukovica', name: 'Lukovica' },
    { id: 'Majsperk', name: 'Majsperk' },
    { id: 'Maribor', name: 'Maribor' },
    { id: 'Markovci', name: 'Markovci' },
    { id: 'Medvode', name: 'Medvode' },
    { id: 'Menges', name: 'Menges' },
    { id: 'Metlika', name: 'Metlika' },
    { id: 'Mezica', name: 'Mezica' },
    { id: 'Miklavz na Dravskem Polju', name: 'Miklavz na Dravskem Polju' },
    { id: 'Miren-Kostanjevica', name: 'Miren-Kostanjevica' },
    { id: 'Mirna Pec', name: 'Mirna Pec' },
    { id: 'Mislinja', name: 'Mislinja' },
    { id: 'Moravce', name: 'Moravce' },
    { id: 'Moravske Toplice', name: 'Moravske Toplice' },
    { id: 'Mozirje', name: 'Mozirje' },
    { id: 'Murska Sobota', name: 'Murska Sobota' },
    { id: 'Muta', name: 'Muta' },
    { id: 'Naklo', name: 'Naklo' },
    { id: 'Nazarje', name: 'Nazarje' },
    { id: 'Nova Gorica', name: 'Nova Gorica' },
    { id: 'Novo Mesto', name: 'Novo Mesto' },
    { id: 'Odranci', name: 'Odranci' },
    { id: 'Oplotnica', name: 'Oplotnica' },
    { id: 'Ormoz', name: 'Ormoz' },
    { id: 'Osilnica', name: 'Osilnica' },
    { id: 'Pesnica', name: 'Pesnica' },
    { id: 'Piran-Pirano', name: 'Polzela' },
    { id: 'Pivka', name: 'Pivka' },
    { id: 'Podcetrtek', name: 'Polzela' },
    { id: 'Podlehnik', name: 'Podlehnik' },
    { id: 'Polzela', name: 'Podvelka' },
    { id: 'Polzela', name: 'Polzela' },
    { id: 'Postojna', name: 'Postojna' },
    { id: 'Prebold', name: 'Prebold' },
    { id: 'Preddvor', name: 'Preddvor' },
    { id: 'Prevalje', name: 'Prevalje' },
    { id: 'Ptuj', name: 'Ptuj' },
    { id: 'Puconci', name: 'Puconci' },
    { id: 'Race-Fram', name: 'Race-Fram' },
    { id: 'Radece', name: 'Radece' },
    { id: 'Radenci', name: 'Radenci' },
    { id: 'Radlje ob Dravi', name: 'Radlje ob Dravi' },
    { id: 'Radovljica', name: 'Radovljica' },
    { id: 'Ravne na Koroskem', name: 'Ravne na Koroskem' },
    { id: 'Razkrizje', name: 'Razkrizje' },
    { id: 'Ribnica', name: 'Ribnica' },
    { id: 'Ribnica na Pohorju', name: 'Ribnica na Pohorju' },
    { id: 'Rogasovci', name: 'Rogasovci' },
    { id: 'Rogaska Slatina', name: 'Rogaska Slatina' },
    { id: 'Rogatec', name: 'Rogatec' },
    { id: 'Ruse', name: 'Ruse' },
    { id: 'Salovci', name: 'Salovci' },
    { id: 'Selnica ob Dravi', name: 'Selnica ob Dravi' },
    { id: 'Semic', name: 'Semic' },
    { id: 'Sempeter-Vrtojba', name: 'Sempeter-Vrtojba' },
    { id: 'Sencur', name: 'Sencur' },
    { id: 'Sentilj', name: 'Sentilj' },
    { id: 'Sentjernej', name: 'Sentjernej' },
    { id: 'Sentjur pri Celju', name: 'Sentjur pri Celju' },
    { id: 'Sevnica', name: 'Sevnica' },
    { id: 'Sezana', name: 'Sezana' },
    { id: 'Skocjan', name: 'Skocjan' },
    { id: 'Skofja Loka', name: 'Skofja Loka' },
    { id: 'Skofljica', name: 'Skofljica' },
    { id: 'Slovenj Gradec', name: 'Slovenj Gradec' },
    { id: 'Slovenska Bistrica', name: 'Slovenska Bistrica' },
    { id: 'Slovenske Konjice', name: 'Slovenske Konjice' },
    { id: 'Smarje pri Jelsah', name: 'Smarje pri Jelsah' },
    { id: 'Smartno ob Paki', name: 'Smartno ob Paki' },
    { id: 'Smartno pri Litiji', name: 'Smartno pri Litiji' },
    { id: 'Sodrazica', name: 'Sodrazica' },
    { id: 'Solcava', name: 'Solcava' },
    { id: 'Sostanj', name: 'Sostanj' },
    { id: 'Starse', name: 'Starse' },
    { id: 'Store', name: 'Store' },
    { id: 'Sveta Ana', name: 'Sveta Ana' },
    {
      id: 'Sveti Andraz v Slovenskih Goricah',
      name: 'Sveti Andraz v Slovenskih Goricah'
    },
    { id: 'Tabor', name: 'Sveti Jurij' },
    { id: 'Tabor', name: 'Tabor' },
    { id: 'Tisina', name: 'Tisina' },
    { id: 'Tolmin', name: 'Tolmin' },
    { id: 'Trbovlje', name: 'Trbovlje' },
    { id: 'Trebnje', name: 'Trebnje' },
    { id: 'Trnovska Vas', name: 'Trnovska Vas' },
    { id: 'Trzic', name: 'Trzic' },
    { id: 'Trzin', name: 'Trzin' },
    { id: 'Turnisce', name: 'Turnisce' },
    { id: 'Velenje', name: 'Velenje' },
    { id: 'Velika Polana', name: 'Velika Polana' },
    { id: 'Velike Lasce', name: 'Velike Lasce' },
    { id: 'Verzej', name: 'Verzej' },
    { id: 'Videm', name: 'Videm' },
    { id: 'Vipava', name: 'Vipava' },
    { id: 'Vitanje', name: 'Vitanje' },
    { id: 'Vodice', name: 'Vodice' },
    { id: 'Vojnik', name: 'Vojnik' },
    { id: 'Vransko', name: 'Vransko' },
    { id: 'Vrhnika', name: 'Vrhnika' },
    { id: 'Vuzenica', name: 'Vuzenica' },
    { id: 'Zagorje ob Savi', name: 'Zagorje ob Savi' },
    { id: 'Zalec', name: 'Zalec' },
    { id: 'Zavrc', name: 'Zavrc' },
    { id: 'Zelezniki', name: 'Zelezniki' },
    { id: 'Zetale', name: 'Zetale' },
    { id: 'Flintshire', name: 'Flintshire' },
    { id: 'Zirovnica', name: 'Zirovnica' },
    { id: 'Zuzemberk', name: 'Zuzemberk' },
    { id: 'Zrece', name: 'FlintshZreceire' }
  ],
  SB: [
    { id: 'Central', name: 'Central' },
    { id: 'Choiseul', name: 'Choiseul' },
    { id: 'Guadalcanal', name: 'Guadalcanal' },
    { id: 'Honiara', name: 'Honiara' },
    { id: 'Isabel', name: 'Isabel' },
    { id: 'Makira', name: 'Makira' },
    { id: 'Malaita', name: 'Malaita' },
    { id: 'Rennell and Bellona', name: 'Rennell and Bellona' },
    { id: 'Temotu', name: 'Temotu' },
    { id: 'Western', name: 'Western' }
  ],
  SO: [
    { id: 'Awdal', name: 'Awdal' },
    { id: 'Bakool', name: 'Bakool' },
    { id: 'Banaadir', name: 'Banaadir' },
    { id: 'Bari', name: 'Bari' },
    { id: 'Bay', name: 'Bay' },
    { id: 'Galguduud', name: 'Galguduud' },
    { id: 'Gedo', name: 'Gedo' },
    { id: 'Hiiraan', name: 'Hiiraan' },
    { id: 'Jubbada Dhexe', name: 'Jubbada Dhexe' },
    { id: 'Jubbada Hoose', name: 'Jubbada Hoose' },
    { id: 'Mudug', name: 'Mudug' },
    { id: 'Nugaal', name: 'Nugaal' },
    { id: 'Sanaag', name: 'Sanaag' },
    { id: 'Shabeellaha Dhexe', name: 'Shabeellaha Dhexe' },
    { id: 'Shabeellaha Hoose', name: 'Shabeellaha Hoose' },
    { id: 'Sool', name: 'Sool' },
    { id: 'Togdheer', name: 'Togdheer' },
    { id: 'Woqooyi Galbeed', name: 'Woqooyi Galbeed' }
  ],
  ZA: [
    { id: 'Eastern Cape', name: 'Eastern Cape' },
    { id: 'Free State', name: 'Free State' },
    { id: 'Gauteng', name: 'Gauteng' },
    { id: 'KwaZulu-Natal', name: 'KwaZulu-Natal' },
    { id: 'Limpopo', name: 'Limpopo' },
    { id: 'Mpumalanga', name: 'Mpumalanga' },
    { id: 'North-West', name: 'North-West' },
    { id: 'Northern Cape', name: 'Northern Cape' },
    { id: 'Western Cape', name: 'Western Cape' }
  ],
  KR: [
    { id: 'Seoul', name: 'Seoul' },
    { id: 'Busan City', name: 'Busan City' },
    { id: 'Daegu City', name: 'Daegu City' },
    { id: 'Incheon City', name: 'AnkaIncheon Cityra' },
    { id: 'Gwangju City', name: 'Gwangju City' },
    { id: 'Daejeon City', name: 'Daejeon City' },
    { id: 'Ulsan', name: 'Ulsan' },
    { id: 'Gyeonggi Province', name: 'Gyeonggi Province' },
    { id: 'Gangwon Province', name: 'Gangwon Province' },
    {
      id: 'North Chungcheong Province',
      name: 'North Chungcheong Province'
    },
    {
      id: 'South Chungcheong Province',
      name: 'South Chungcheong Province'
    },
    { id: 'North Jeolla Province', name: 'North Jeolla Province' },
    { id: 'South Jeolla Province', name: 'South Jeolla Province' },
    { id: 'North Gyeongsang Province', name: 'North Gyeongsang Province' },
    { id: 'South Gyeongsang Province', name: 'South Gyeongsang Province' },
    { id: 'Jeju', name: 'Jeju' }
  ],
  SS: [
    { id: 'Abyei', name: 'Abyei' },
    { id: 'Central Equatoria', name: 'Central Equatoria' },
    { id: 'Eastern Equatoria', name: 'Eastern Equatoria' },
    { id: 'Jonglei', name: 'Jonglei' },
    { id: 'Lakes', name: 'Lakes' },
    { id: 'Northern Bahr el Ghazal', name: 'Northern Bahr el Ghazal' },
    { id: 'Pibor', name: 'Pibor' },
    { id: 'Unity', name: 'Unity' },
    { id: 'Upper Nile', name: 'Upper Nile' },
    { id: 'Ruweng', name: 'Ruweng' },
    { id: 'Warrap', name: 'Warrap' },
    { id: 'Western Bahr el Ghazal', name: 'Western Bahr el Ghazal' },
    { id: 'Western Equatoria', name: 'Western Equatoria' }
  ],
  ES: [
    { id: 'Andalucia', name: 'Andalucia' },
    { id: 'Aragon', name: 'Aragon' },
    { id: 'Asturias', name: 'Asturias' },
    { id: 'Baleares', name: 'Baleares' },
    { id: 'Ceuta', name: 'Ceuta' },
    { id: 'Canarias', name: 'Canarias' },
    { id: 'Cantabria', name: 'Cantabria' },
    { id: 'Castilla-La Mancha', name: 'Castilla-La Mancha' },
    { id: 'Castilla y Leon', name: 'Castilla y Leon' },
    { id: 'Cataluna', name: 'Cataluna' },
    { id: 'Comunidad Valenciana', name: 'Comunidad Valenciana' },
    { id: 'Extremadura', name: 'Extremadura' },
    { id: 'Galicia', name: 'Galicia' },
    { id: 'La Rioja', name: 'La Rioja' },
    { id: 'Madrid', name: 'Madrid' },
    { id: 'Melilla', name: 'Melilla' },
    { id: 'Murcia', name: 'Murcia' },
    { id: 'Navarra', name: 'Navarra' },
    { id: 'Pais Vasco', name: 'Pais Vasco' }
  ],
  LK: [
    { id: 'Central', name: 'Central' },
    { id: 'North Central', name: 'North Central' },
    { id: 'North Eastern', name: 'North Eastern' },
    { id: 'North Western', name: 'North Western' },
    { id: 'Sabaragamuwa', name: 'Sabaragamuwa' },
    { id: 'Southern', name: 'Southern' },
    { id: 'Uva', name: 'Uva' },
    { id: 'Western', name: 'Western' }
  ],
  SD: [
    { id: "A'ali an Nil", name: "A'ali an Nil" },
    { id: 'Al Bahr al Ahmar', name: 'Al Bahr al Ahmar' },
    { id: 'Al Buhayrat', name: 'Al Buhayrat' },
    { id: 'Al Jazirah', name: 'Al Jazirah' },
    { id: 'Al Khartum', name: 'Al Khartum' },
    { id: 'Al Qadarif', name: 'Al Qadarif' },
    { id: 'Al Wahdah', name: 'Al Wahdah' },
    { id: 'An Nil al Abyad', name: 'An Nil al Abyad' },
    { id: 'An Nil al Azraq', name: 'An Nil al Azraq' },
    { id: 'Ash Shamaliyah', name: 'Ash Shamaliyah' },
    { id: 'Bahr al Jabal', name: 'Bahr al Jabal' },
    { id: "Gharb al Istiwa'iyah", name: "Gharb al Istiwa'iyah" },
    { id: 'Gharb Bahr al Ghazal', name: 'Gharb Bahr al Ghazal' },
    { id: 'Gharb Darfur', name: 'Gharb Darfur' },
    { id: 'Gharb Kurdufan', name: 'Gharb Kurdufan' },
    { id: 'Janub Darfur', name: 'Janub Darfur' },
    { id: 'Janub Kurdufan', name: 'Janub Kurdufan' },
    { id: 'Junqali', name: 'Junqali' },
    { id: 'Kassala', name: 'Kassala' },
    { id: 'Nahr an Nil', name: 'Nahr an Nil' },
    { id: 'Shamal Bahr al Ghazal', name: 'Shamal Bahr al Ghazal' },
    { id: 'Shamal Darfur', name: 'Shamal Darfur' },
    { id: 'Shamal Kurdufan', name: 'Shamal Kurdufan' },
    { id: "Sharq al Istiwa'iyah", name: "Sharq al Istiwa'iyah" },
    { id: 'Sinnar', name: 'Sinnar' },
    { id: 'Warab', name: 'Warab' }
  ],
  SR: [
    { id: 'Brokopondo', name: 'Brokopondo' },
    { id: 'Commewijne', name: 'Commewijne' },
    { id: 'Coronie', name: 'Coronie' },
    { id: 'Marowijne', name: 'Marowijne' },
    { id: 'Nickerie', name: 'Nickerie' },
    { id: 'Para', name: 'Para' },
    { id: 'Paramaribo', name: 'Paramaribo' },
    { id: 'Saramacca', name: 'Saramacca' },
    { id: 'Sipaliwini', name: 'Sipaliwini' },
    { id: 'Wanica', name: 'Wanica' }
  ],
  SZ: [
    { id: 'Hhohho', name: 'Hhohho' },
    { id: 'Lubombo', name: 'Lubombo' },
    { id: 'Manzini', name: 'Manzini' },
    { id: 'Shiselweni', name: 'Shiselweni' }
  ],
  SE: [
    { id: 'Blekinge', name: 'Blekinge' },
    { id: 'Dalarna', name: 'Dalarna' },
    { id: 'Gävleborg', name: 'Gävleborg' },
    { id: 'Gotland', name: 'Gotland' },
    { id: 'Halland', name: 'Halland' },
    { id: 'Jämtland', name: 'Jämtland' },
    { id: 'Jönköping', name: 'Jönköping' },
    { id: 'Kalmar', name: 'Kalmar' },
    { id: 'Kronoberg', name: 'Kronoberg' },
    { id: 'Norrbotten', name: 'Norrbotten' },
    { id: 'Örebro', name: 'Örebro' },
    { id: 'Östergötland', name: 'Östergötland' },
    { id: 'Skåne', name: 'Skåne' },
    { id: 'Södermanland', name: 'Södermanland' },
    { id: 'Stockholm', name: 'Stockholm' },
    { id: 'Uppsala', name: 'Uppsala' },
    { id: 'Värmland', name: 'Värmland' },
    { id: 'Västerbotten', name: 'Västerbotten' },
    { id: 'Västernorrland', name: 'Västernorrland' },
    { id: 'Västmanland', name: 'Västmanland' },
    { id: 'Västra Götaland', name: 'Västra Götaland' }
  ],
  CH: [
    { id: 'Aargau', name: 'Aargau' },
    { id: 'Appenzell Ausser-Rhoden', name: 'Appenzell Ausser-Rhoden' },
    { id: 'Appenzell Inner-Rhoden', name: 'Appenzell Inner-Rhoden' },
    { id: 'Basel-Landschaft', name: 'Basel-Landschaft' },
    { id: 'Basel-Stadt', name: 'Basel-Stadt' },
    { id: 'Bern', name: 'Bern' },
    { id: 'Fribourg', name: 'Fribourg' },
    { id: 'Geneve', name: 'Geneve' },
    { id: 'Glarus', name: 'Glarus' },
    { id: 'Graubunden', name: 'Graubunden' },
    { id: 'Jura', name: 'Jura' },
    { id: 'Luzern', name: 'Luzern' },
    { id: 'Neuchatel', name: 'Neuchatel' },
    { id: 'Nidwalden', name: 'Nidwalden' },
    { id: 'Obwalden', name: 'Obwalden' },
    { id: 'Sankt Gallen', name: 'Sankt Gallen' },
    { id: 'Schaffhausen', name: 'Schaffhausen' },
    { id: 'Schwyz', name: 'Schwyz' },
    { id: 'Solothurn', name: 'Solothurn' },
    { id: 'Thurgau', name: 'Thurgau' },
    { id: 'Ticino', name: 'Ticino' },
    { id: 'Uri', name: 'Uri' },
    { id: 'Valais', name: 'Valais' },
    { id: 'Vaud', name: 'Vaud' },
    { id: 'Zug', name: 'Zug' },
    { id: 'Zurich', name: 'Zurich' }
  ],
  SY: [
    { id: 'Al Hasakah', name: 'Al Hasakah' },
    { id: 'Al Ladhiqiyah', name: 'Al Ladhiqiyah' },
    { id: 'Al Qunaytirah', name: 'Al Qunaytirah' },
    { id: 'Ar Raqqah', name: 'Ar Raqqah' },
    { id: "As Suwayda'", name: "As Suwayda'" },
    { id: "Dar'a", name: "Dar'a" },
    { id: 'Dayr az Zawr', name: 'Dayr az Zawr' },
    { id: 'Dimashq', name: 'Dimashq' },
    { id: 'Halab', name: 'Halab' },
    { id: 'Hamah', name: 'Hamah' },
    { id: 'Hims', name: 'Hims' },
    { id: 'Idlib', name: 'Idlib' },
    { id: 'Rif Dimashq', name: 'Rif Dimashq' },
    { id: 'Tartus', name: 'Tartus' }
  ],
  TW: [
    { id: 'Chang-hua', name: 'Chang-hua' },
    { id: 'Chia-i', name: 'Chia-i' },
    { id: 'Hsin-chu', name: 'Hsin-chu' },
    { id: 'Hua-lien', name: 'Hua-lien' },
    { id: 'I-lan', name: 'I-lan' },
    { id: 'Kao-hsiung', name: 'Kao-hsiung' },
    { id: 'Kin-men', name: 'Kin-men' },
    { id: 'Lien-chiang', name: 'Lien-chiang' },
    { id: 'Miao-li', name: 'Miao-li' },
    { id: "Nan-t'ou", name: "Nan-t'ou" },
    { id: "P'eng-hu", name: "P'eng-hu" },
    { id: "P'ing-tung", name: "P'ing-tung" },
    { id: "T'ai-chung", name: "T'ai-chung" },
    { id: "T'ai-nan", name: "T'ai-nan" },
    { id: "T'ai-pei", name: "T'ai-pei" },
    { id: "T'ai-tung", name: "T'ai-tung" },
    { id: "T'ao-yuan", name: "T'ao-yuan" },
    { id: 'Yun-lin', name: 'Yun-lin' },
    { id: 'Chia-i', name: 'Chia-i' },
    { id: 'Chi-lung', name: 'Chi-lung' },
    { id: 'Hsin-chu', name: 'Hsin-chu' },
    { id: "T'ai-chung", name: "T'ai-chung" },
    { id: "T'ai-nan", name: "T'ai-nan" },
    { id: 'Kao-hsiung city', name: 'Kao-hsiung city' },
    { id: "T'ai-pei city", name: "T'ai-pei city" }
  ],
  TJ: [
    {
      id: 'Districts of Republican Subordination',
      name: 'Districts of Republican Subordination'
    },
    { id: 'Dushanbe', name: 'Dushanbe' },
    {
      id: 'Gorno-Badakhshan Autonomous Region',
      name: 'Gorno-Badakhshan Autonomous Region'
    },
    { id: 'Khatlon', name: 'Khatlon' },
    { id: 'Sughd', name: 'Sughd' }
  ],
  TZ: [
    { id: 'Arusha', name: 'Arusha' },
    { id: 'Dar es Salaam', name: 'Dar es Salaam' },
    { id: 'Dodoma', name: 'Dodoma' },
    { id: 'Iringa', name: 'Iringa' },
    { id: 'Kagera', name: 'Kagera' },
    { id: 'Kigoma', name: 'Kigoma' },
    { id: 'Kilimanjaro', name: 'Kilimanjaro' },
    { id: 'Lindi', name: 'Lindi' },
    { id: 'Manyara', name: 'Manyara' },
    { id: 'Mara', name: 'Mara' },
    { id: 'Mbeya', name: 'Mbeya' },
    { id: 'Morogoro', name: 'Morogoro' },
    { id: 'Mtwara', name: 'Mtwara' },
    { id: 'Mwanza', name: 'Mwanza' },
    { id: 'Pemba North', name: 'Pemba North' },
    { id: 'Pemba South', name: 'Pemba South' },
    { id: 'Pwani', name: 'Pwani' },
    { id: 'Rukwa', name: 'Rukwa' },
    { id: 'Ruvuma', name: 'Ruvuma' },
    { id: 'Shinyanga', name: 'Shinyanga' },
    { id: 'Singida', name: 'Singida' },
    { id: 'Tabora', name: 'Tabora' },
    { id: 'Tanga', name: 'Tanga' },
    { id: 'Zanzibar Central/South', name: 'Zanzibar Central/South' },
    { id: 'Zanzibar North', name: 'Zanzibar North' },
    { id: 'Zanzibar Urban/West', name: 'Zanzibar Urban/West' }
  ],
  TH: [
    { id: 'Amnat Charoen', name: 'Amnat Charoen' },
    { id: 'Ang Thong', name: 'Ang Thong' },
    { id: 'Buriram', name: 'Buriram' },
    { id: 'Chachoengsao', name: 'Chachoengsao' },
    { id: 'Chai Nat', name: 'Chai Nat' },
    { id: 'Chaiyaphum', name: 'Chaiyaphum' },
    { id: 'Chanthaburi', name: 'Chanthaburi' },
    { id: 'Chiang Mai', name: 'Chiang Mai' },
    { id: 'Chiang Rai', name: 'Chiang Rai' },
    { id: 'Chon Buri', name: 'Chon Buri' },
    { id: 'Chumphon', name: 'Chumphon' },
    { id: 'Kalasin', name: 'Kalasin' },
    { id: 'Kamphaeng Phet', name: 'Kamphaeng Phet' },
    { id: 'Kanchanaburi', name: 'Kanchanaburi' },
    { id: 'Khon Kaen', name: 'Khon Kaen' },
    { id: 'Krabi', name: 'Krabi' },
    { id: 'Krung Thep Mahanakhon', name: 'Krung Thep Mahanakhon' },
    { id: 'Lampang', name: 'Lampang' },
    { id: 'Lamphun', name: 'Lamphun' },
    { id: 'Loei', name: 'Loei' },
    { id: 'Lop Buri', name: 'Lop Buri' },
    { id: 'Mae Hong Son', name: 'Mae Hong Son' },
    { id: 'Maha Sarakham', name: 'Maha Sarakham' },
    { id: 'Mukdahan', name: 'Mukdahan' },
    { id: 'Nakhon Nayok', name: 'Nakhon Nayok' },
    { id: 'Nakhon Pathom', name: 'Nakhon Pathom' },
    { id: 'Nakhon Phanom', name: 'Nakhon Phanom' },
    { id: 'Nakhon Ratchasima', name: 'Nakhon Ratchasima' },
    { id: 'Nakhon Sawan', name: 'Nakhon Sawan' },
    { id: 'Nakhon Si Thammarat', name: 'Nakhon Si Thammarat' },
    { id: 'Nan', name: 'Nan' },
    { id: 'Narathiwat', name: 'Narathiwat' },
    { id: 'Nong Bua Lamphu', name: 'Nong Bua Lamphu' },
    { id: 'Nong Khai', name: 'Nong Khai' },
    { id: 'Nonthaburi', name: 'Nonthaburi' },
    { id: 'Pathum Thani', name: 'Pathum Thani' },
    { id: 'Pattani', name: 'Pattani' },
    { id: 'Phangnga', name: 'Phangnga' },
    { id: 'Phatthalung', name: 'Phatthalung' },
    { id: 'Phayao', name: 'Phayao' },
    { id: 'Phetchabun', name: 'Phetchabun' },
    { id: 'Phetchaburi', name: 'Phetchaburi' },
    { id: 'Phichit', name: 'Phichit' },
    { id: 'Phitsanulok', name: 'Phitsanulok' },
    { id: 'Phra Nakhon Si Ayutthaya', name: 'Phra Nakhon Si Ayutthaya' },
    { id: 'Phrae', name: 'Phrae' },
    { id: 'Phuket', name: 'Phuket' },
    { id: 'Prachin Buri', name: 'Prachin Buri' },
    { id: 'Prachuap Khiri Khan', name: 'Prachuap Khiri Khan' },
    { id: 'Ranong', name: 'Ranong' },
    { id: 'Ratchaburi', name: 'Ratchaburi' },
    { id: 'Rayong', name: 'Rayong' },
    { id: 'Roi Et', name: 'Roi Et' },
    { id: 'Sa Kaeo', name: 'Sa Kaeo' },
    { id: 'Sakon Nakhon', name: 'Sakon Nakhon' },
    { id: 'Samut Prakan', name: 'Samut Prakan' },
    { id: 'Samut Sakhon', name: 'Samut Sakhon' },
    { id: 'Samut Songkhram', name: 'Samut Songkhram' },
    { id: 'Sara Buri', name: 'Sara Buri' },
    { id: 'Satun', name: 'Satun' },
    { id: 'Sing Buri', name: 'Sing Buri' },
    { id: 'Sisaket', name: 'Sisaket' },
    { id: 'Songkhla', name: 'Songkhla' },
    { id: 'Sukhothai', name: 'Sukhothai' },
    { id: 'Suphan Buri', name: 'Suphan Buri' },
    { id: 'Surat Thani', name: 'Surat Thani' },
    { id: 'Surin', name: 'Surin' },
    { id: 'Tak', name: 'Tak' },
    { id: 'Trang', name: 'Trang' },
    { id: 'Trat', name: 'Trat' },
    { id: 'Ubon Ratchathani', name: 'Ubon Ratchathani' },
    { id: 'Udon Thani', name: 'Udon Thani' },
    { id: 'Uthai Thani', name: 'Uthai Thani' },
    { id: 'Uttaradit', name: 'Uttaradit' },
    { id: 'Yala', name: 'Yala' },
    { id: 'Yasothon', name: 'Yasothon' }
  ],
  TG: [
    { id: 'Kara', name: 'Kara' },
    { id: 'Plateaux', name: 'Plateaux' },
    { id: 'Savanes', name: 'Savanes' },
    { id: 'Centrale', name: 'Centrale' },
    { id: 'Maritime', name: 'Maritime' }
  ],
  TO: [
    { id: "Vava'u", name: "Vava'u" },
    { id: "Ha'apai", name: "Ha'apai" },
    { id: 'Tongatapu', name: 'Tongatapu' }
  ],
  TT: [
    { id: 'Couva', name: 'Couva' },
    { id: 'Diego Martin', name: 'Diego Martin' },
    { id: 'Mayaro', name: 'Mayaro' },
    { id: 'Penal', name: 'Penal' },
    { id: 'Princes Town', name: 'Princes Town' },
    { id: 'Sangre Grande', name: 'Sangre Grande' },
    { id: 'San Juan', name: 'San Juan' },
    { id: 'Siparia', name: 'Siparia' },
    { id: 'Tunapuna', name: 'Tunapuna' },
    { id: 'Port-of-Spain', name: 'Port-of-Spain' },
    { id: 'San Fernando', name: 'San Fernando' },
    { id: 'Arima', name: 'Arima' },
    { id: 'Point Fortin', name: 'Point Fortin' },
    { id: 'Chaguanas', name: 'Chaguanas' },
    { id: 'Tobago', name: 'Tobago' }
  ],
  TN: [
    { id: 'Ariana (Aryanah)', name: 'Ariana (Aryanah)' },
    { id: 'Beja (Bajah)', name: 'Beja (Bajah)' },
    { id: "Ben Arous (Bin 'Arus)", name: "Ben Arous (Bin 'Arus)" },
    { id: 'Bizerte (Banzart)', name: 'Bizerte (Banzart)' },
    { id: 'Gabes (Qabis)', name: 'Gabes (Qabis)' },
    { id: 'Gafsa (Qafsah)', name: 'MadrGafsa (Qafsah)id' },
    { id: 'Jendouba (Jundubah)', name: 'Jendouba (Jundubah)' },
    { id: 'Kairouan (Al Qayrawan)', name: 'Kairouan (Al Qayrawan)' },
    { id: 'Kasserine (Al Qasrayn)', name: 'Kasserine (Al Qasrayn)' },
    { id: 'Kebili (Qibili)', name: 'Kebili (Qibili)' },
    { id: 'Kef (Al Kaf)', name: 'Kef (Al Kaf)' },
    { id: 'Mahdia (Al Mahdiyah)', name: 'Mahdia (Al Mahdiyah)' },
    { id: 'Manouba (Manubah)', name: 'Manouba (Manubah)' },
    { id: 'Medenine (Madanin)', name: 'Medenine (Madanin)' },
    { id: 'Monastir (Al Munastir)', name: 'Monastir (Al Munastir)' },
    { id: 'Nabeul (Nabul)', name: 'Nabeul (Nabul)' },
    { id: 'Sfax (Safaqis)', name: 'Sfax (Safaqis)' },
    {
      id: 'Sidi Bou Zid (Sidi Bu Zayd)',
      name: 'Sidi Bou Zid (Sidi Bu Zayd)'
    },
    { id: 'Siliana (Silyanah)', name: 'Siliana (Silyanah)' },
    { id: 'Sousse (Susah)', name: 'Sousse (Susah)' },
    { id: 'Tataouine (Tatawin)', name: 'Tataouine (Tatawin)' },
    { id: 'Tozeur (Tawzar)', name: 'Tozeur (Tawzar)' },
    { id: 'Tunis', name: 'Tunis' },
    { id: 'Zaghouan (Zaghwan)', name: 'Zaghouan (Zaghwan)' }
  ],
  TR: [
    { id: 'Adana', name: 'Adana' },
    { id: 'Adiyaman', name: 'Adiyaman' },
    { id: 'Afyonkarahisar', name: 'Afyonkarahisar' },
    { id: 'Agri', name: 'Agri' },
    { id: 'Aksaray', name: 'Aksaray' },
    { id: 'Amasya', name: 'Amasya' },
    { id: 'Ankara', name: 'Ankara' },
    { id: 'MaAntalyadrid', name: 'Antalya' },
    { id: 'Ardahan', name: 'Ardahan' },
    { id: 'Artvin', name: 'Artvin' },
    { id: 'Aydin', name: 'Aydin' },
    { id: 'Balikesir', name: 'Balikesir' },
    { id: 'Bartin', name: 'Bartin' },
    { id: 'Batman', name: 'Batman' },
    { id: 'Bayburt', name: 'Bayburt' },
    { id: 'Bilecik', name: 'Bilecik' },
    { id: 'Bingol', name: 'Bingol' },
    { id: 'Bitlis', name: 'Bitlis' },
    { id: 'Bolu', name: 'Bolu' },
    { id: 'Burdur', name: 'Burdur' },
    { id: 'Bursa', name: 'Bursa' },
    { id: 'Canakkale', name: 'Canakkale' },
    { id: 'Cankiri', name: 'Cankiri' },
    { id: 'Corum', name: 'Corum' },
    { id: 'Denizli', name: 'Denizli' },
    { id: 'Diyarbakir', name: 'Diyarbakir' },
    { id: 'Duzce', name: 'Duzce' },
    { id: 'Edirne', name: 'Edirne' },
    { id: 'Elazig', name: 'Elazig' },
    { id: 'Erzincan', name: 'Erzincan' },
    { id: 'Erzurum', name: 'Erzurum' },
    { id: 'Eskisehir', name: 'Eskisehir' },
    { id: 'Gaziantep', name: 'Gaziantep' },
    { id: 'Giresun', name: 'Giresun' },
    { id: 'Gumushane', name: 'Gumushane' },
    { id: 'Hakkari', name: 'Hakkari' },
    { id: 'Hatay', name: 'Hatay' },
    { id: 'Igdir', name: 'Igdir' },
    { id: 'Isparta', name: 'Isparta' },
    { id: 'Istanbul', name: 'Istanbul' },
    { id: 'Izmir', name: 'Izmir' },
    { id: 'Kahramanmaras', name: 'Kahramanmaras' },
    { id: 'Karabuk', name: 'Karabuk' },
    { id: 'Karaman', name: 'Karaman' },
    { id: 'Kars', name: 'Kars' },
    { id: 'Kastamonu', name: 'Kastamonu' },
    { id: 'Kilis', name: 'Kilis' },
    { id: 'Kirikkale', name: 'Kirikkale' },
    { id: 'Kirklareli', name: 'Kirklareli' },
    { id: 'Kirsehir', name: 'Kirsehir' },
    { id: 'Kocaeli', name: 'Kocaeli' },
    { id: 'Konya', name: 'Konya' },
    { id: 'Kutahya', name: 'Kutahya' },
    { id: 'Malatya', name: 'Malatya' },
    { id: 'Manisa', name: 'Manisa' },
    { id: 'Mardin', name: 'Mardin' },
    { id: 'Mersin', name: 'Mersin' },
    { id: 'Mugla', name: 'Mugla' },
    { id: 'Mus', name: 'Mus' },
    { id: 'Nevsehir', name: 'Nevsehir' },
    { id: 'Nigde', name: 'Nigde' },
    { id: 'Ordu', name: 'Ordu' },
    { id: 'Osmaniye', name: 'Osmaniye' },
    { id: 'Rize', name: 'Rize' },
    { id: 'Sakarya', name: 'Sakarya' },
    { id: 'Samsun', name: 'Samsun' },
    { id: 'Sanliurfa', name: 'Sanliurfa' },
    { id: 'Siirt', name: 'Siirt' },
    { id: 'Sinop', name: 'Sinop' },
    { id: 'Sirnak', name: 'Sirnak' },
    { id: 'Sivas', name: 'Sivas' },
    { id: 'Tekirdag', name: 'Tekirdag' },
    { id: 'Tokat', name: 'Tokat' },
    { id: 'Trabzon', name: 'Trabzon' },
    { id: 'Tunceli', name: 'Tunceli' },
    { id: 'Usak', name: 'Usak' },
    { id: 'Van', name: 'Van' },
    { id: 'Yalova', name: 'Yalova' },
    { id: 'Yozgat', name: 'Yozgat' },
    { id: 'Zonguldak', name: 'Zonguldak' }
  ],
  TM: [
    { id: 'Ahal Welayaty (Ashgabat)', name: 'Ahal Welayaty (Ashgabat)' },
    {
      id: 'Balkan Welayaty (Balkanabat)',
      name: 'Balkan Welayaty (Balkanabat)'
    },
    { id: 'Dashoguz Welayaty', name: 'Dashoguz Welayaty' },
    {
      id: 'Lebap Welayaty (Turkmenabat)',
      name: 'Lebap Welayaty (Turkmenabat)'
    },
    { id: 'Mary Welayaty', name: 'Mary Welayaty' }
  ],
  TC: [
    { id: 'Grand Turk', name: 'Grand Turk' },
    { id: 'Middle Caicos', name: 'Middle Caicos' },
    { id: 'North Caicos', name: 'North Caicos' },
    { id: 'Providenciales', name: 'Providenciales' },
    { id: 'Salt Cay', name: 'Salt Cay' },
    { id: 'South Caicos', name: 'South Caicos' }
  ],
  UG: [
    { id: 'Adjumani', name: 'Adjumani' },
    { id: 'Apac', name: 'Apac' },
    { id: 'Arua', name: 'Arua' },
    { id: 'Bugiri', name: 'Bugiri' },
    { id: 'Bundibugyo', name: 'Bundibugyo' },
    { id: 'Bushenyi', name: 'Bushenyi' },
    { id: 'Busia', name: 'Busia' },
    { id: 'Gulu', name: 'Gulu' },
    { id: 'Hoima', name: 'Hoima' },
    { id: 'Iganga', name: 'Iganga' },
    { id: 'Jinja', name: 'Jinja' },
    { id: 'Kabale', name: 'Kabale' },
    { id: 'Kabarole', name: 'Kabarole' },
    { id: 'Kaberamaido', name: 'Kaberamaido' },
    { id: 'Kalangala', name: 'Kalangala' },
    { id: 'Kampala', name: 'Kampala' },
    { id: 'Kamuli', name: 'Kamuli' },
    { id: 'Kamwenge', name: 'Kamwenge' },
    { id: 'Kanungu', name: 'Kanungu' },
    { id: 'Kapchorwa', name: 'Kapchorwa' },
    { id: 'Kasese', name: 'Kasese' },
    { id: 'Katakwi', name: 'Katakwi' },
    { id: 'Kayunga', name: 'Kayunga' },
    { id: 'Kibale', name: 'Kibale' },
    { id: 'Kiboga', name: 'Kiboga' },
    { id: 'Kisoro', name: 'Kisoro' },
    { id: 'Kitgum', name: 'Kitgum' },
    { id: 'Kotido', name: 'Kotido' },
    { id: 'Kumi', name: 'Kumi' },
    { id: 'Kyenjojo', name: 'Kyenjojo' },
    { id: 'Lira', name: 'Lira' },
    { id: 'Luwero', name: 'Luwero' },
    { id: 'Masaka', name: 'Masaka' },
    { id: 'Masindi', name: 'Masindi' },
    { id: 'Mayuge', name: 'Mayuge' },
    { id: 'Mbale', name: 'Mbale' },
    { id: 'Mbarara', name: 'Mbarara' },
    { id: 'Moroto', name: 'Moroto' },
    { id: 'Moyo', name: 'Moyo' },
    { id: 'Mpigi', name: 'Mpigi' },
    { id: 'Mubende', name: 'Mubende' },
    { id: 'Mukono', name: 'Mukono' },
    { id: 'Nakapiripirit', name: 'Nakapiripirit' },
    { id: 'Nakasongola', name: 'Nakasongola' },
    { id: 'Nebbi', name: 'Nebbi' },
    { id: 'Ntungamo', name: 'Ntungamo' },
    { id: 'Pader', name: 'Pader' },
    { id: 'Pallisa', name: 'Pallisa' },
    { id: 'Rakai', name: 'Rakai' },
    { id: 'Rukungiri', name: 'Rukungiri' },
    { id: 'Sembabule', name: 'Sembabule' },
    { id: 'Sironko', name: 'Sironko' },
    { id: 'Soroti', name: 'Soroti' },
    { id: 'Tororo', name: 'Tororo' },
    { id: 'Wakiso', name: 'Wakiso' },
    { id: 'Yumbe', name: 'Yumbe' }
  ],
  UA: [
    { id: 'Cherkasy', name: 'Cherkasy' },
    { id: 'Chernihiv', name: 'Chernihiv' },
    { id: 'Chernivtsi', name: 'Chernivtsi' },
    { id: 'Crimea', name: 'Crimea' },
    { id: "Dnipropetrovs'k", name: "Dnipropetrovs'k" },
    { id: "Donets'k", name: "Donets'k" },
    { id: "Ivano-Frankivs'k", name: "Ivano-Frankivs'k" },
    { id: 'Kharkiv', name: 'Kharkiv' },
    { id: 'Kherson', name: 'Kherson' },
    { id: "Khmel'nyts'kyy", name: "Khmel'nyts'kyy" },
    { id: 'Kirovohrad', name: 'Kirovohrad' },
    { id: 'Kiev', name: 'Kiev' },
    { id: 'Kyyiv', name: 'Kyyiv' },
    { id: "Luhans'k", name: "Luhans'k" },
    { id: "L'viv", name: "L'viv" },
    { id: 'Mykolayiv', name: 'Mykolayiv' },
    { id: 'Odesa', name: 'Odesa' },
    { id: 'Poltava', name: 'Poltava' },
    { id: 'Rivne', name: 'Rivne' },
    { id: "Sevastopol'", name: "Sevastopol'" },
    { id: 'Sumy', name: 'Sumy' },
    { id: "Ternopil'", name: "Ternopil'" },
    { id: 'Vinnytsya', name: 'Vinnytsya' },
    { id: "Volyn'", name: "Volyn'" },
    { id: 'Zakarpattya', name: 'Zakarpattya' },
    { id: 'Zaporizhzhya', name: 'Zaporizhzhya' },
    { id: 'Zhytomyr', name: 'Zhytomyr' }
  ],
  UY: [
    { id: 'Artigas', name: 'Artigas' },
    { id: 'Canelones', name: 'Canelones' },
    { id: 'Cerro Largo', name: 'Cerro Largo' },
    { id: 'Colonia', name: 'Colonia' },
    { id: 'Durazno', name: 'Durazno' },
    { id: 'Flores', name: 'Flores' },
    { id: 'Florida', name: 'Florida' },
    { id: 'Lavalleja', name: 'Lavalleja' },
    { id: 'Maldonado', name: 'Maldonado' },
    { id: 'Montevideo', name: 'Montevideo' },
    { id: 'Paysandú', name: 'Paysandú' },
    { id: 'Río Negro', name: 'Río Negro' },
    { id: 'Rivera', name: 'Rivera' },
    { id: 'Rocha', name: 'Rocha' },
    { id: 'San José', name: 'San José' },
    { id: 'Salto', name: 'Salto' },
    { id: 'Soriano', name: 'Soriano' },
    { id: 'Tacuarembó', name: 'Tacuarembó' },
    { id: 'Treinta y Tres  ', name: 'Treinta y Tres  ' }
  ],
  AE: [
    { id: 'Abu Dhabi', name: 'Abu Dhabi' },
    { id: "'Ajman", name: "'Ajman" },
    { id: 'Al Fujayrah', name: 'Al Fujayrah' },
    { id: 'Sharjah', name: 'Sharjah' },
    { id: 'Dubai', name: 'Dubai' },
    { id: "Ra's al Khaymah", name: "Ra's al Khaymah" },
    { id: 'Umm al Qaywayn', name: 'Umm al Qaywayn' }
  ],
  GB: [
    { id: 'ConnectAberconwy and Colwynicut', name: 'Aberconwy and Colwyn' },
    { id: 'Aberdeen City', name: 'Aberdeen City' },
    { id: 'Aberdeenshire', name: 'Aberdeenshire' },
    { id: 'Anglesey', name: 'Anglesey' },
    { id: 'Angus', name: 'Angus' },
    { id: 'Antrim', name: 'Antrim' },
    { id: 'Argyll and Bute', name: 'Argyll and Bute' },
    { id: 'Armagh', name: 'Armagh' },
    { id: 'Avon', name: 'Avon' },
    { id: 'Ayrshire', name: 'Ayrshire' },
    { id: 'IdBath and NE Somersetaho', name: 'Bath and NE Somerset' },
    { id: 'Bedfordshire', name: 'Bedfordshire' },
    { id: 'Belfast', name: 'Belfast' },
    { id: 'Berkshire', name: 'Berkshire' },
    { id: 'Berwickshire', name: 'Berwickshire' },
    { id: 'MaryBFPOland', name: 'BFPO' },
    { id: 'Blaenau Gwent', name: 'Blaenau Gwent' },
    { id: 'Buckinghamshire', name: 'MinBuckinghamshirenesota' },
    { id: 'Caernarfonshire', name: 'Caernarfonshire' },
    { id: 'Caerphilly', name: 'Caerphilly' },
    { id: 'Caithness', name: 'Caithness' },
    { id: 'Cambridgeshire', name: 'Cambridgeshire' },
    { id: 'Cardiff', name: 'Cardiff' },
    { id: 'Cardiganshire', name: 'Cardiganshire' },
    { id: 'Carmarthenshire', name: 'Carmarthenshire' },
    { id: 'Ceredigion', name: 'Ceredigion' },
    { id: 'Channel Islands', name: 'Channel Islands' },
    { id: 'Cheshire', name: 'Cheshire' },
    { id: 'City of Bristol', name: 'OklahCity of Bristoloma' },
    { id: 'Clackmannanshire', name: 'Clackmannanshire' },
    { id: 'Clwyd', name: 'Clwyd' },
    { id: 'Conwy', name: 'Conwy' },
    { id: 'Cornwall/Scilly', name: 'Cornwall/Scilly' },
    { id: 'Cumbria', name: 'Cumbria' },
    { id: 'Denbighshire', name: 'Denbighshire' },
    { id: 'Derbyshire', name: 'Derbyshire' },
    { id: 'Derry/Londonderry', name: 'DeDerry/Londonderryvon' },
    { id: 'Devon', name: 'Devon' },
    { id: 'Dorset', name: 'Dorset' },
    { id: 'Down', name: 'Down' },
    { id: 'Dumfries and Galloway', name: 'Dumfries and Galloway' },
    { id: 'Dunbartonshire', name: 'Dunbartonshire' },
    { id: 'Dundee', name: 'Dundee' },
    { id: 'Durham', name: 'Durham' },
    { id: 'Dyfed', name: 'Dyfed' },
    { id: 'East Ayrshire', name: 'East Ayrshire' },
    { id: 'East Dunbartonshire', name: 'East Dunbartonshire' },
    { id: 'East Lothian', name: 'East Lothian' },
    { id: 'East Renfrewshire', name: 'East Renfrewshire' },
    { id: 'East Riding Yorkshire', name: 'East Riding Yorkshire' },
    { id: 'East Sussex', name: 'East Sussex' },
    { id: 'Edinburgh', name: 'Edinburgh' },
    { id: 'England', name: 'England' },
    { id: 'Essex', name: 'Essex' },
    { id: 'Falkirk', name: 'Falkirk' },
    { id: 'Fermanagh', name: 'Fermanagh' },
    { id: 'Fife', name: 'Fife' },
    { id: 'Flintshire', name: 'Flintshire' },
    { id: 'Glasgow', name: 'Glasgow' },
    { id: 'Gloucestershire', name: 'Gloucestershire' },
    { id: 'Greater London', name: 'Greater London' },
    { id: 'Greater Manchester', name: 'Greater Manchester' },
    { id: 'Gwent', name: 'Gwent' },
    { id: 'Gwynedd', name: 'Gwynedd' },
    { id: 'Hampshire', name: 'Hampshire' },
    { id: 'Hartlepool', name: 'Hartlepool' },
    { id: 'Hereford and Worcester', name: 'Hereford and Worcester' },
    { id: 'Hertfordshire', name: 'Hertfordshire' },
    { id: 'Highlands', name: 'Highlands' },
    { id: 'Inverclyde', name: 'Inverclyde' },
    { id: 'Inverness-Shire', name: 'Inverness-Shire' },
    { id: 'Isle of Man', name: 'Isle of Man' },
    { id: 'Isle of Wight', name: 'Isle of Wight' },
    { id: 'Kent', name: 'Kent' },
    { id: 'Kincardinshire', name: 'Kincardinshire' },
    { id: 'Kingston Upon Hull', name: 'Kingston Upon Hull' },
    { id: 'Kinross-Shire', name: 'Kinross-Shire' },
    { id: 'Kirklees', name: 'Kirklees' },
    { id: 'Lanarkshire', name: 'Lanarkshire' },
    { id: 'Lancashire', name: 'Lancashire' },
    { id: 'Leicestershire', name: 'Leicestershire' },
    { id: 'Lincolnshire', name: 'Lincolnshire' },
    { id: 'Londonderry', name: 'Londonderry' },
    { id: 'Merseyside', name: 'Merseyside' },
    { id: 'Merthyr Tydfil', name: 'Merthyr Tydfil' },
    { id: 'Mid Glamorgan', name: 'Mid Glamorgan' },
    { id: 'Mid Lothian', name: 'Mid Lothian' },
    { id: 'Middlesex', name: 'Middlesex' },
    { id: 'Monmouthshire', name: 'Monmouthshire' },
    { id: 'Moray', name: 'Moray' },
    { id: 'Neath & Port Talbot', name: 'Neath & Port Talbot' },
    { id: 'Newport', name: 'Newport' },
    { id: 'Norfolk', name: 'Norfolk' },
    { id: 'North Ayrshire', name: 'North Ayrshire' },
    { id: 'North East Lincolnshire', name: 'North East Lincolnshire' },
    { id: 'North Lanarkshire', name: 'North Lanarkshire' },
    { id: 'North Lincolnshire', name: 'North Lincolnshire' },
    { id: 'North Somerset', name: 'North Somerset' },
    { id: 'North Yorkshire', name: 'North Yorkshire' },
    { id: 'Northamptonshire', name: 'Northamptonshire' },
    { id: 'Northern Ireland', name: 'Northern Ireland' },
    { id: 'Northumberland', name: 'Northumberland' },
    { id: 'Nottinghamshire', name: 'Nottinghamshire' },
    { id: 'Orkney and Shetland Isles', name: 'Orkney and Shetland Isles' },
    { id: 'Oxfordshire', name: 'Oxfordshire' },
    { id: 'Pembrokeshire', name: 'Pembrokeshire' },
    { id: 'Perth and Kinross', name: 'Perth and Kinross' },
    { id: 'Powys', name: 'Powys' },
    { id: 'Redcar and Cleveland', name: 'Redcar and Cleveland' },
    { id: 'Renfrewshire', name: 'Renfrewshire' },
    { id: 'Rhonda Cynon Taff', name: 'Rhonda Cynon Taff' },
    { id: 'Rutland', name: 'Rutland' },
    { id: 'Scottish Borders', name: 'Scottish Borders' },
    { id: 'Shetland', name: 'Shetland' },
    { id: 'Shropshire', name: 'Shropshire' },
    { id: 'Somerset', name: 'Somerset' },
    { id: 'South Ayrshire', name: 'South Ayrshire' },
    { id: 'South Glamorgan', name: 'South Glamorgan' },
    { id: 'South Gloucesteshire', name: 'South Gloucesteshire' },
    { id: 'South Lanarkshire', name: 'South Lanarkshire' },
    { id: 'South Yorkshire', name: 'South Yorkshire' },
    { id: 'Staffordshire', name: 'Staffordshire' },
    { id: 'Stirling', name: 'Stirling' },
    { id: 'Stockton On Tees', name: 'Stockton On Tees' },
    { id: 'Suffolk', name: 'Suffolk' },
    { id: 'Surrey', name: 'Surrey' },
    { id: 'Swansea', name: 'Swansea' },
    { id: 'Torfaen', name: 'Torfaen' },
    { id: 'Tyne and Wear', name: 'Tyne and Wear' },
    { id: 'Tyrone', name: 'Tyrone' },
    { id: 'Vale Of Glamorgan', name: 'Vale Of Glamorgan' },
    { id: 'Wales', name: 'Wales' },
    { id: 'Warwickshire', name: 'Warwickshire' },
    { id: 'West Berkshire', name: 'West Berkshire' },
    { id: 'West Dunbartonshire', name: 'West Dunbartonshire' },
    { id: 'West Glamorgan', name: 'West Glamorgan' },
    { id: 'West Lothian', name: 'West Lothian' },
    { id: 'West Midlands', name: 'West Midlands' },
    { id: 'West Sussex', name: 'West Sussex' },
    { id: 'West Yorkshire', name: 'West Yorkshire' },
    { id: 'Western Isles', name: 'Western Isles' },
    { id: 'Wiltshire', name: 'Wiltshire' },
    { id: 'Wirral', name: 'Wirral' },
    { id: 'Worcestershire', name: 'Worcestershire' },
    { id: 'Wrexham', name: 'Wrexham' },
    { id: 'York', name: 'York' }
  ],
  US: [
    { id: 'Alabama', name: 'Alabama' },
    { id: 'Alaska', name: 'Alaska' },
    { id: 'Arizona', name: 'Arizona' },
    { id: 'Arkansas', name: 'Arkansas' },
    { id: 'California', name: 'California' },
    { id: 'Colorado', name: 'Colorado' },
    { id: 'Connecticut', name: 'Connecticut' },
    { id: 'Delaware', name: 'Delaware' },
    { id: 'District of Columbia', name: 'District of Columbia' },
    { id: 'Florida', name: 'Florida' },
    { id: 'Georgia', name: 'Georgia' },
    { id: 'Hawaii', name: 'Hawaii' },
    { id: 'Idaho', name: 'Idaho' },
    { id: 'Illinois', name: 'Illinois' },
    { id: 'Indiana', name: 'Indiana' },
    { id: 'Iowa', name: 'Iowa' },
    { id: 'Kansas', name: 'Kansas' },
    { id: 'Kentucky', name: 'Kentucky' },
    { id: 'Louisiana', name: 'Louisiana' },
    { id: 'Maine', name: 'Maine' },
    { id: 'Maryland', name: 'Maryland' },
    { id: 'Massachusetts', name: 'Massachusetts' },
    { id: 'Michigan', name: 'Michigan' },
    { id: 'Minnesota', name: 'Minnesota' },
    { id: 'Mississippi', name: 'Mississippi' },
    { id: 'Missouri', name: 'Missouri' },
    { id: 'Montana', name: 'Montana' },
    { id: 'Nebraska', name: 'Nebraska' },
    { id: 'Nevada', name: 'Nevada' },
    { id: 'New Hampshire', name: 'New Hampshire' },
    { id: 'New Jersey', name: 'New Jersey' },
    { id: 'New Mexico', name: 'New Mexico' },
    { id: 'New York', name: 'New York' },
    { id: 'North Carolina', name: 'North Carolina' },
    { id: 'North Dakota', name: 'North Dakota' },
    { id: 'Ohio', name: 'Ohio' },
    { id: 'Oklahoma', name: 'Oklahoma' },
    { id: 'Oregon', name: 'Oregon' },
    { id: 'Pennsylvania', name: 'Pennsylvania' },
    { id: 'Rhode Island', name: 'Rhode Island' },
    { id: 'South Carolina', name: 'South Carolina' },
    { id: 'South Dakota', name: 'South Dakota' },
    { id: 'Tennessee', name: 'Tennessee' },
    { id: 'Texas', name: 'Texas' },
    { id: 'Utah', name: 'Utah' },
    { id: 'Vermont', name: 'Vermont' },
    { id: 'Virginia', name: 'Virginia' },
    { id: 'Washington', name: 'Washington' },
    { id: 'West Virginia', name: 'West Virginia' },
    { id: 'Wisconsin', name: 'Wisconsin' },
    { id: 'Wyoming', name: 'Wyoming' }
  ],
  UR: [
    { id: 'Artigas', name: 'Artigas' },
    { id: 'Canelones', name: 'Canelones' },
    { id: 'Cerro Largo', name: 'Cerro Largo' },
    { id: 'Colonia', name: 'Colonia' },
    { id: 'Durazno', name: 'Durazno' },
    { id: 'Flores', name: 'Flores' },
    { id: 'Florida', name: 'Florida' },
    { id: 'Lavalleja', name: 'Lavalleja' },
    { id: 'Maldonado', name: 'Maldonado' },
    { id: 'Montevideo', name: 'Montevideo' },
    { id: 'Paysandu', name: 'Paysandu' },
    { id: 'Rio Negro', name: 'Rio Negro' },
    { id: 'Rivera', name: 'Rivera' },
    { id: 'Rocha', name: 'Rocha' },
    { id: 'Salto', name: 'Salto' },
    { id: 'San Jose', name: 'San Jose' },
    { id: 'Soriano', name: 'Soriano' },
    { id: 'Tacuarembo', name: 'Tacuarembo' },
    { id: 'Treinta y Tres', name: 'Treinta y Tres' }
  ],
  UZ: [
    { id: 'Andijon Viloyati', name: 'Andijon Viloyati' },
    { id: 'Buxoro Viloyati', name: 'Buxoro Viloyati' },
    { id: "Farg'ona Viloyati", name: "Farg'ona Viloyati" },
    { id: 'Jizzax Viloyati', name: 'Jizzax Viloyati' },
    { id: 'Namangan Viloyati', name: 'Namangan Viloyati' },
    { id: 'Navoiy Viloyati', name: 'Navoiy Viloyati' },
    { id: 'Qashqadaryo Viloyati', name: 'Qashqadaryo Viloyati' },
    {
      id: "Qaraqalpog'iston Respublikasi",
      name: "Qaraqalpog'iston Respublikasi"
    },
    { id: 'Samarqand Viloyati', name: 'Samarqand Viloyati' },
    { id: 'Sirdaryo Viloyati', name: 'Sirdaryo Viloyati' },
    { id: 'Surxondaryo Viloyati', name: 'Surxondaryo Viloyati' },
    { id: 'Toshkent Shahri', name: 'Toshkent Shahri' },
    { id: 'Toshkent Viloyati', name: 'Toshkent Viloyati' },
    { id: 'Xorazm Viloyati', name: 'Xorazm Viloyati' }
  ],
  VU: [
    { id: 'Malampa', name: 'Malampa' },
    { id: 'Penama', name: 'Penama' },
    { id: 'Sanma', name: 'Sanma' },
    { id: 'Shefa', name: 'Shefa' },
    { id: 'Tafea', name: 'Tafea' },
    { id: 'Torba', name: 'Torba' }
  ],
  VE: [
    { id: 'Amazonas', name: 'Amazonas' },
    { id: 'Anzoategui', name: 'Anzoategui' },
    { id: 'Apure', name: 'Apure' },
    { id: 'Aragua', name: 'Aragua' },
    { id: 'Barinas', name: 'Barinas' },
    { id: 'Bolivar', name: 'Bolivar' },
    { id: 'Carabobo', name: 'Carabobo' },
    { id: 'Cojedes', name: 'Cojedes' },
    { id: 'Delta Amacuro', name: 'Delta Amacuro' },
    { id: 'Dependencias Federales', name: 'Dependencias Federales' },
    { id: 'Distrito Federal', name: 'Distrito Federal' },
    { id: 'Falcon', name: 'Falcon' },
    { id: 'Guarico', name: 'Guarico' },
    { id: 'Lara', name: 'Lara' },
    { id: 'Merida', name: 'Merida' },
    { id: 'Miranda', name: 'Miranda' },
    { id: 'Monagas', name: 'Monagas' },
    { id: 'Nueva Esparta', name: 'Nueva Esparta' },
    { id: 'Portuguesa', name: 'Portuguesa' },
    { id: 'Sucre', name: 'Sucre' },
    { id: 'Tachira', name: 'Tachira' },
    { id: 'Trujillo', name: 'Trujillo' },
    { id: 'Vargas', name: 'Vargas' },
    { id: 'Yaracuy', name: 'Yaracuy' },
    { id: 'Zulia', name: 'Zulia' }
  ],
  VN: [
    { id: 'An Giang', name: 'An Giang' },
    { id: 'Bac Giang', name: 'Bac Giang' },
    { id: 'Bac Kan', name: 'Bac Kan' },
    { id: 'Bac Lieu', name: 'Bac Lieu' },
    { id: 'Bac Ninh', name: 'Bac Ninh' },
    { id: 'Ba Ria-Vung Tau', name: 'Ba Ria-Vung Tau' },
    { id: 'Ben Tre', name: 'Ben Tre' },
    { id: 'Binh Dinh', name: 'Binh Dinh' },
    { id: 'Binh Duon', name: 'Binh Duong' },
    { id: 'Binh Phuoc', name: 'Binh Phuoc' },
    { id: 'Binh Thuan', name: 'Binh Thuan' },
    { id: 'Ca Mau', name: 'Ca Mau' },
    { id: 'Cao Bang', name: 'Cao Bang' },
    { id: 'Dac Lak', name: 'Dac Lak' },
    { id: 'Dac Nong', name: 'Dac Nong' },
    { id: 'Dien Bien', name: 'Dien Bien' },
    { id: 'Dong Nai', name: 'Dong Nai' },
    { id: 'Dong Thap', name: 'Dong Thap' },
    { id: 'Gia Lai', name: 'Gia Lai' },
    { id: 'Ha Giang', name: 'Ha Giang' },
    { id: 'Hai Duong', name: 'Hai Duong' },
    { id: 'Ha Nam', name: 'Ha Nam' },
    { id: 'Ha Tay', name: 'Ha Tay' },
    { id: 'Ha Tinh', name: 'Ha Tinh' },
    { id: 'Hau Giang', name: 'Hau Giang' },
    { id: 'Hoa Binh', name: 'Hoa Binh' },
    { id: 'Hung Yen', name: 'Hung Yen' },
    { id: 'Khanh Hoa', name: 'Khanh Hoa' },
    { id: 'Kien Giang', name: 'Kien Giang' },
    { id: 'Kon Tum', name: 'Kon Tum' },
    { id: 'Lai Chau', name: 'Lai Chau' },
    { id: 'Lam Dong', name: 'Lam Dong' },
    { id: 'Lang Son', name: 'Lang Son' },
    { id: 'Lao Cai', name: 'Lao Cai' },
    { id: 'Long An', name: 'Long An' },
    { id: 'Nam Dinh', name: 'Nam Dinh' },
    { id: 'Nghe An', name: 'Nghe An' },
    { id: 'Ninh Binh', name: 'Ninh Binh' },
    { id: 'Ninh Thuan', name: 'Ninh Thuan' },
    { id: 'Phu Tho', name: 'Phu Tho' },
    { id: 'Phu Yen', name: 'Phu Yen' },
    { id: 'Quang Binh', name: 'Quang Binh' },
    { id: 'Quang Nam', name: 'Quang Nam' },
    { id: 'Quang Ngai', name: 'Quang Ngai' },
    { id: 'Quang Ninh', name: 'Quang Ninh' },
    { id: 'Quang Tri', name: 'Quang Tri' },
    { id: 'Soc Trang', name: 'Soc Trang' },
    { id: 'Son La', name: 'Son La' },
    { id: 'Tay Ninh', name: 'Tay Ninh' },
    { id: 'Thai Binh', name: 'Thai Binh' },
    { id: 'East Thai Nguyen', name: 'East Thai Nguyen' },
    { id: 'East Thanh Hoa', name: 'East Thanh Hoa' },
    { id: 'Thua Thien-Hue', name: 'Thua Thien-Hue' },
    { id: 'Tien Giang', name: 'Tien Giang' },
    { id: 'Tra Vinh', name: 'Tra Vinh' },
    { id: 'Tuyen Quang', name: 'Tuyen Quang' },
    { id: 'Vinh Long', name: 'Vinh Long' },
    { id: 'Vinh Phuc', name: 'Vinh Phuc' },
    { id: 'Yen Bai', name: 'Yen Bai' },
    { id: 'Can Tho', name: 'Can Tho' },
    { id: 'Da Nang', name: 'Da Nang' },
    { id: 'Hai Phong', name: 'Hai Phong' },
    { id: 'Hanoi', name: 'Hanoi' },
    { id: 'Ho Chi Minh', name: 'Ho Chi Minh' }
  ],
  VA: [{ id: 'Vatican City', name: 'Vatican City' }],
  VC: [{ id: 'ST VINCENT AND GRENADINES', name: 'ST VINCENT AND GRENADINES' }],
  VG: [
    { id: 'Anegada', name: 'Anegada' },
    { id: 'Jost Van Dyke', name: 'Jost Van Dyke' },
    { id: 'Tortola', name: 'Tortola' },
    { id: ' Virgin Gorda', name: ' Virgin Gorda' }
  ],
  VI: [
    { id: 'Saint Croix', name: 'Saint Croix' },
    { id: 'Saint John', name: 'Saint John' },
    { id: 'Saint Thomas', name: 'Saint Thomas' }
  ],
  WF: [
    { id: 'Alofi Island', name: 'Alofi Island' },
    { id: 'Hoorn Islands', name: 'Hoorn Islands' },
    { id: "Matā'Utu", name: "Matā'Utu" },
    { id: 'Wallis Islands', name: 'Wallis Islands' }
  ],
  YE: [
    { id: 'Abyan', name: 'Abyan' },
    { id: 'Adan', name: 'Adan' },
    { id: 'Ad Dali', name: 'Ad Dali' },
    { id: 'Al Bayda', name: 'Al Bayda' },
    { id: 'Al Hudaydah', name: 'Al Hudaydah' },
    { id: 'Al Jawf', name: 'Al Jawf' },
    { id: 'Al Mahrah', name: 'Al Mahrah' },
    { id: 'Al Mahwit', name: 'Al Mahwit' },
    { id: 'Amran', name: 'Amran' },
    { id: 'Dhamar', name: 'Dhamar' },
    { id: 'Hadramawt', name: 'Hadramawt' },
    { id: 'Hajjah', name: 'Hajjah' },
    { id: 'Ibb', name: 'Ibb' },
    { id: 'Lahij', name: 'Lahij' },
    { id: "Ma'rib", name: "Ma'rib" },
    { id: "Sa'dah", name: "Sa'dah" },
    { id: "San'a", name: "San'a" },
    { id: 'Shabwah', name: 'Shabwah' },
    { id: "Ta'izz", name: "Ta'izz" }
  ],
  ZM: [
    { id: 'Central', name: 'Central' },
    { id: 'Copperbelt', name: 'Copperbelt' },
    { id: 'Eastern', name: 'Eastern' },
    { id: 'Luapula', name: 'Luapula' },
    { id: 'Lusaka', name: 'Lusaka' },
    { id: 'Northern', name: 'Northern' },
    { id: 'North-Western', name: 'North-Western' },
    { id: 'Southern', name: 'Southern' },
    { id: 'Western', name: 'Western' }
  ],
  ZW: [
    { id: 'Bulawayo', name: 'Bulawayo' },
    { id: 'Harare', name: 'Harare' },
    { id: 'Manicaland', name: 'Manicaland' },
    { id: 'Mashonaland Central', name: 'Mashonaland Central' },
    { id: 'Mashonaland East', name: 'Mashonaland East' },
    { id: 'Mashonaland West', name: 'Mashonaland West' },
    { id: 'Masvingo', name: 'Masvingo' },
    { id: 'Matabeleland North', name: 'Matabeleland North' },
    { id: 'Matabeleland South', name: 'Matabeleland South' },
    { id: 'Midlands', name: 'Midlands' }
  ],
  PF: [
    { id: 'Austral Islands', name: 'Austral Islands' },
    { id: 'Gambier Islands', name: 'Gambier Islands' },
    { id: 'Leeward Islands', name: 'Leeward Islands' },
    { id: 'Marquesas Islands', name: 'Marquesas Islands' },
    { id: 'Tuamotu Archipelago', name: 'Tuamotu Archipelago' },
    { id: 'Windward Islands', name: 'Windward Islands' }
  ],
  GA: [
    { id: 'Estuaire (Libreville)', name: 'Estuaire (Libreville)' },
    { id: 'Haut-Ogooué (Franceville)', name: 'Haut-Ogooué (Franceville)' },
    { id: 'Moyen-Ogooué (Lambaréné)', name: 'Moyen-Ogooué (Lambaréné)' },
    { id: 'Ngounié (Mouila)', name: 'Ngounié (Mouila)' },
    { id: 'Nyanga (Tchibanga)', name: 'Nyanga (Tchibanga)' },
    { id: 'Ogooué-Ivindo (Makokou)', name: 'Ogooué-Ivindo (Makokou)' },
    { id: 'Ogooué-Lolo (Koulamoutou)', name: 'Ogooué-Lolo (Koulamoutou)' },
    {
      id: 'Ogooué-Maritime (Port-Gentil)',
      name: 'Ogooué-Maritime (Port-Gentil)'
    },
    { id: 'Woleu-Ntem (Oyem)', name: 'Woleu-Ntem (Oyem)' }
  ]
};
