import ReportBlockTrade from "../../../pages/ExchangeV2/OrderEntry/ReportBlockTrade/ReportBlockTradeSidePaneContainer"
import ApiKeys from 'apex-web/lib/components/ApiKeysSidePaneComponents/ApiKeysSidePane/ApiKeysSidePaneComponent';
import AdvancedOrder from '../../../components/OrderEntryComponents/AdvancedOrderSidePane/AdvancedOrderSidePaneContainer';
import SendReceive from '../../SendReceiveSidePane/SendReceiveSidePaneContainer';
import KYC_IMForm from '../../KYC_IM/KYC_IMForm';
import IntermediateLevelPage from '../../../pages/Level/IntermediateLevelPage';
import KYC_IMLegacyForm from 'apex-web/lib/components/KYC_IMLegacy/KYC_IMLegacyForm';
import KYC_JumioForm from 'apex-web/lib/components/KYC_Jumio/KYC_JumioIframe';
import KYC_ManualForm from 'apex-web/lib/components/KYC_Manual/KYC_ManualForm';
import Reports from '../../../components/ReportSidePane/ReportSidePaneComponent';
import Deposits from '../../DepositSidePaneComponents/DepositSidePaneComponent';
import Withdraws from 'apex-web/lib/components/WithdrawSidePaneComponents/WithdrawSidePaneComponent';
import RetailFiat from '../../Retail/RetailSidePanes/FiatSidePaneContainer';
import MasterLevelPage from '../../../pages/Level/MasterLevelPage';

export default {
  ReportBlockTrade: ReportBlockTrade,
  ApiKeys: ApiKeys,
  AdvancedOrder: AdvancedOrder,
  SendReceive: SendReceive,
  KYC_IMForm: KYC_IMForm,
  IntermediateLevelPage: IntermediateLevelPage,
  KYC_IMLegacyForm: KYC_IMLegacyForm,
  KYC_JumioForm: KYC_JumioForm,
  KYC_ManualForm: KYC_ManualForm,
  Reports: Reports,
  Deposits: Deposits,
  Withdraws: Withdraws,
  RetailFiat: RetailFiat,
  MasterLevelPage: MasterLevelPage
};
