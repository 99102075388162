import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import empty from 'is-empty';
import { toast } from 'react-toastify';

const CryptoDJForm = (props, context) => {
  let {
    show,
    handleClose,
    acceptDisclaimerHook,
    UserId,
    selectedAccountId,
    setBlockedAll,
    logout
  } = props;
  const [loading, setLoading] = useState(false);

  const sendAccept = async () => {
    setLoading(true);
    try {
      if (empty(selectedAccountId) || empty(UserId)) {
        throw new Error('AccountId or UserId not provided');
      }
      let inputData = {
        userId: UserId,
        accountId: selectedAccountId
      };
      let result = await acceptDisclaimerHook(inputData, UserId);
      if (
        !empty(result) &&
        !empty(result.data) &&
        result.data.message === 'success'
      ) {
        setBlockedAll(false);
      }
    } catch (error) {
      console.log('Error on sendAccept', error);
    }
    setLoading(false);
  };

  const sendDecline = async () => {
    setLoading(true);
    try {
      if (empty(selectedAccountId) || empty(UserId)) {
        throw new Error('AccountId or UserId not provided');
      }

      let result = await logout();
      console.log('result Logout', result);
    } catch (error) {
      console.log('Error on sendAccept', error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-bnx-v2 lighting modal-disclaimer">
        <div className={'bnx-v2-loading' + (loading ? ' show' : '')}>
          <Spinner animation="border" />
        </div>
        <Modal.Header>
          <Modal.Title
            dangerouslySetInnerHTML={{
              __html: context.t('<b>Disclaimer</b>')
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl="12">
              <ul className="disclaimer-list">
                <li
                  dangerouslySetInnerHTML={{
                    __html: context.t(
                      '<b>Level available for people domiciled in Peru</b> (with identity document: Peruvian DNI and Foreign Alien Card)'
                    )
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: context.t(
                      "It has an operating limit <a href='{link}' target='_blank'>(Click here to know more about it)</a>.",
                      {
                        link:
                          'https://soporte.banexcoin.com/support/solutions/articles/60000706287'
                      }
                    )
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: context.t(
                      'Once reached and if you want to continue trading, request the increase to the next level.'
                    )
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: context.t(
                      'In case you make deposits that exceed this limit and you have not requested the level increase, the funds will be returned to the account / wallet of origin applying the corresponding expenses.'
                    )
                  }}
                />
              </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn-bnxv2"
            onClick={() => sendAccept()}>
            {context.t('I agree')}
          </Button>
          <Button variant="secondary" onClick={() => sendDecline()}>
            {context.t('Try later')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

CryptoDJForm.contextTypes = {
  t: PropTypes.func
};

export default CryptoDJForm;
