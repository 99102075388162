import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from '../../../../components/common/APLabelWithText/APLabelWithText';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderNet } from 'apex-web/lib/helpers/orderHelper';
import { numberWithCommas2 } from '../../../../pages/utils';

import './ConfirmBlockTradeModalComponent.css';

const bemClasses = getBEMClasses('confirm-block-trade-modal');

const ConfirmBlockTradeModal = (props, context) => {
  const {
    formObj,
    selectedInstrument,
    isOpen,
    close,
    confirmReport,
    feeSymbol,
    orderTotal
  } = props;
  const isBuySide = formObj.values.side === buyValue;
  const boughtProductSymbol = isBuySide
    ? selectedInstrument.Product1Symbol
    : selectedInstrument.Product2Symbol;
  const soldProductSymbol = isBuySide
    ? selectedInstrument.Product2Symbol
    : selectedInstrument.Product1Symbol;
  const items = [
    {
      label: context.t('Instrument'),
      text: `${selectedInstrument.Symbol}`
    },
    {
      label: context.t('Counterparty'),
      text: `${formObj.values.counterparty ? formObj.values.counterparty : '-'}`
    },
    {
      label: context.t('Locked in'),
      text: `${formObj.values.lockedIn ? context.t('Yes') : context.t('No')}`
    },
    {
      label: context.t('Product Bought'),
      text: `${numberWithCommas2(formObj.values.quantity)} ${boughtProductSymbol}`
    },
    {
      label: context.t('Product Sold'),
      text: `${numberWithCommas2(formObj.values.price)} ${soldProductSymbol}`
    },
    {
      label: context.t('Fee'),
      text: numberWithCommas2(formatNumberToLocale(formObj.values.fee.OrderFee, feeSymbol))
    },
    {
      label: context.t('Final Amount'),
      text: getOrderNet(formObj, selectedInstrument, orderTotal, false)
    },
    {
      label: context.t('Final Value'),
      text: numberWithCommas2(formObj.values.price)
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      title={context.t('Confirm Block Trade')}
      close={close}
      footer={{
        buttonText: isBuySide
          ? context.t('Confirm Buy Order')
          : context.t('Confirm Sell Order'),
        buttonStyle: isBuySide ? 'additive' : 'subtractive',
        onClick: () => confirmReport(formObj.values)
      }}
      customClass={bemClasses(
        formObj.values.side === buyValue ? 'bought' : 'sold'
      )}>
      <div className={bemClasses()}>
        {items.map(item => {
          return (
            <APLabelWithText
              key={item.label}
              label={item.label}
              text={item.text}
              customClass={bemClasses()}
            />
          );
        })}
        <APLabelWithText
          label={`${context.t('Date')}:`}
          text={formatDateTime(new Date())}
          customClass={bemClasses()}
        />
      </div>
    </Modal>
  );
};

ConfirmBlockTradeModal.propTypes = {
  formObj: PropTypes.object.isRequired,
  selectedInstrument: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  confirmReport: PropTypes.func.isRequired
};

ConfirmBlockTradeModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmBlockTradeModal;
