import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import StatusMessage from "../../../components/StatusMessage/StatusMessage";
import { FormButtonV2 } from "../../../components/common/BnxFormsComponents";
import { useHistory, useLocation } from "react-router-dom";
import CoinkTransactionsController from "../../../components/CoinkTransactionsController";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import LoadingBnx from "../../../components/LoadingBnx";

const statuses = {
  CREATED: { type: "success", message: "Your deposit was created" },
  PAID_OUT: { type: "success", message: "Your deposit was approved" },
  REJECTED: { type: "error", message: "Your deposit was rejected" },
  EXPIRED: { type: "error", message: "Your deposit request has expired" },
  CANCELLED: { type: "error", message: "Your deposit request has cancelled" },
  FAILED: { type: "error", message: "Your deposit request failed" },
  REVERSED: {
    type: "error",
    message: "Your deposit request has been reversed",
  },
};
function CoinkDepositFinish(props, context) {
  const { userInfo } = props;
  const location = useLocation();
  const history = useHistory();
  const [loadingCallback, setLoadingCallback] = useState(true);
  const [status, setStatus] = useState({
    type: "error",
    message:
      "An error occurred while processing your deposit, please try again.",
  });

  const statusIsError = status.type === "error";

  const handleDepositCallback = async () => {
    if (isEmpty(location.search)) {
      return history.push("/");
    }
    const queryParams = new URLSearchParams(location.search);
    const requestId = queryParams.get("request_id");
    const response = await CoinkTransactionsController.depositCallback({
      context,
      requestId,
      setLoadingCallback,
      userInfo,
    });
    if (response && statuses.hasOwnProperty(response)) {
      setStatus(statuses[response]);
    }
  };
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    handleDepositCallback();
  }, [userInfo]);

  if (loadingCallback) return <LoadingBnx loading={loadingCallback ? 1 : 0} />;

  return (
    <div className="coink-deposit-finish-container">
      <StatusMessage
        type={status.type.toUpperCase()}
        title={context.t(status.message)}
        children={
          <div className="container-coink-buttons-flex">
            <div className="contVertBTN">
              <FormButtonV2
                type="button"
                variant={"light"}
                onClick={() => history.push("/")}
                className="button-succes-delete"
              >
                {context.t("Go to home")}
              </FormButtonV2>
            </div>
            {statusIsError && (
            <div className="contVertBTN">
              <FormButtonV2
                type="button"
                onClick={handleDepositCallback}
                className="button-succes-delete try-again-button"
              >
                {context.t("Try again")}
              </FormButtonV2>
            </div>
            )}
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};
CoinkDepositFinish.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(CoinkDepositFinish);
