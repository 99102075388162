//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';
import { jsonToQueryString } from '../utils';

export const getWithdrawals = async (userId, filters) => {
  const queryString = jsonToQueryString(filters);
  let path = '/api/transactions/withdrawals?'+queryString;
  
  // @ts-ignore
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};
