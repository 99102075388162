import { connect } from 'react-redux';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';
import BalancesMenuComponent from './BalancesMenuComponent';
import isEmpty from 'is-empty';

const mapStateToProps = function mapStateToProps(state) {
  const { productsByLevel } = state
  const positions = productPositionAssetsSelector(state);
  const newProducts = (!isEmpty(positions) && !isEmpty(productsByLevel)) ? positions.filter(e => productsByLevel.includes(e.ProductSymbol)) : [];
  const position = state.apexCore.position;
  const selectedProductId = position.selectedProductId;
  const UserName = state.user.userInfo.UserName;

  return {
    positions: newProducts,
    selectedProductId: selectedProductId,
    UserName: UserName
  };
};

const mapDispatchToProps = {
  selectPositionAndSave: selectPositionAndSave
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalancesMenuComponent);
