import React from 'react';
import APInput from 'apex-web/lib/components/common/APInput';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APSelect from 'apex-web/lib/components/common/APSelect';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APCryptoAddressInputContainer from '../components/common/APCryptoAddressInput/APCryptoAddressInputContainer';
import { required, biggerThanZero } from 'apex-web/lib/helpers/formValidations';

export var fieldToLabel = function fieldToLabel(field) {
  return field.replace(/([a-z])([A-Z])/g, '$1 $2');
};

var noValidationFields = ['DestinationTagNumber', 'Comment', 'TemplateType'];

export var templateFormRenderer = function templateFormRenderer(
  template,
  customClass,
  context,
  userEmail,
  useExternalAddress
) {
  var _ref =
      arguments.length > 3 && arguments[5] !== undefined ? arguments[5] : {},
    Product = _ref.Product,
    ProductId = _ref.ProductId,
    ProductSymbol = _ref.ProductSymbol,
    DecimalPlaces = _ref.DecimalPlaces;

  if (template.TemplateType === 'ToExternalBitcoinAddress') {
    template = {
      ExternalAddress: '',
      TemplateType: 'ToExternalBitcoinAddress'
    };
  } else if (template.TemplateType === 'ToExternalEthereumAddress') {
    template = {
      ExternalAddress: '',
      TemplateType: 'ToExternalEthereumAddress'
    };
  }

  return Object.keys(template).map(function(field) {
    var label =
      field === 'BankAccountName'
        ? context.t('Bank Name')
        : context.t(fieldToLabel(field));
    var validate = noValidationFields.includes(field) ? null : required;
    var type = field === 'TemplateType' ? 'hidden' : 'text';

    if (template[field] && template[field].indexOf('[') === 0) {
      var options = template[field].split(',').map(function(el) {
        var value = el.trim().replace(/[[\]]/g, '');
        return { value: value, label: value };
      });

      if (field === 'Country') {
        try {
          var parsedArray = JSON.parse(template[field]);
          options = parsedArray.map(function(el) {
            return {
              value: el.Code,
              label: el.Name
            };
          });
        } catch (e) {
          // do nothing
        }
      }

      return React.createElement(APSelect, {
        key: field,
        name: field,
        customClass: customClass,
        'data-test': field,
        label: label,
        validate: [required],
        options: options
      });
    }

    if (field === 'ExternalAddress') {
      return React.createElement(APCryptoAddressInputContainer, {
        productSymbol: Product || ProductSymbol,
        productId: ProductId,
        userEmail: userEmail,
        useExternalAddress: useExternalAddress,
        key: field,
        name: field,
        'data-test': field,
        customClass: customClass,
        label: context.t(label)
      });
    }

    if (template[field] && field !== 'TemplateType') {
      return React.createElement(APLabelWithText, {
        key: field,
        'data-test': field,
        label: context.t(label) + ':',
        text: template[field],
        customClass: customClass,
        classModifiers: 'template-info'
      });
    } else {
      var info = void 0;

      if (field === 'DestinationTagNumber') {
        info = context.t(
          'Please note that a destination tag may be required if you are withdrawing to an exchange or online wallet.'
        );
      }

      if (field.toLowerCase() === 'amount') {
        return React.createElement(APNumberInput, {
          key: field,
          type: 'text',
          name: 'Amount',
          labelInInput: Product || ProductSymbol,
          label: context.t('Amount of ' + (Product || ProductSymbol)),
          decimalPlaces: DecimalPlaces,
          customClass: customClass,
          validate: [required, biggerThanZero]
        });
      }

      return React.createElement(APInput, {
        key: field,
        name: field,
        'data-test': label,
        customClass: customClass,
        label: label,
        type: type,
        info: info,
        validate: validate
      });
    }
  });
};

export var templateConfirmRenderer = function templateConfirmRenderer(
  template,
  values,
  classNames,
  context
) {
  return Object.keys(template || {}).map(function(field) {
    return React.createElement(APLabelWithText, {
      label:
        field === 'BankAccountName'
          ? context.t('Bank Name')
          : context.t(fieldToLabel(field)),
      key: field,
      'data-test': field,
      text: values[field],
      customClass: classNames()
    });
  });
};
