import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  getBankCountries,
  getBanksByCountry,
  createAccountInternational
} from '../../components/AccountComponents/AccountHooks';
import { Row, Col, Form } from 'react-bootstrap';
import empty from 'is-empty';
import Loading from '../../components/Loading';
import { useForm } from '../../hooks/formHooks';
import { cleanCustomError } from '../bnxv2/hooks/signupHooks';
import AddInternationalFormFields from './Forms/AddInternationalFormFields';
import { REGEX_LIST } from '../../constants/regexList';
import { confirmAlert } from 'react-confirm-alert';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

const accountPageClasses = getBEMClasses('account-page');

const AccountAddInternationalFormPage = (props, context) => {
  let { userInfo, selectedAccountId, history, showSnack } = props;
  if (empty(userInfo) || empty(selectedAccountId)) {
    return (
      <React.Fragment>
        <div>{context.t('Loading...')}</div>
      </React.Fragment>
    );
  }
  const [loading, setLoading] = useState(0);
  const [countries, setCountries] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [usBanks, setUsBanks] = useState([]);
  const [myAllUniqueBanks, setMyAllUniqueBanks] = useState([]);
  const [intermediaryBanks, setIntermediaryBanks] = useState([]);
  const [bankSquare, setBankSquare] = useState({});
  const [generalErrors, setGeneralErrors] = useState({});

  const cleanExtractBanks = (banks = []) => {
    let uniqueBanks = [];
    let squareBanks = {};

    uniqueBanks = banks.reduce((unique, o) => {
      if (!unique.some(obj => obj.bankName === o.bankName)) {
        unique.push(o);
      }
      return unique;
    }, []);

    return {
      uniqueBanks,
      squareBanks
    };
  };

  const getListCountries = async () => {
    setLoading(1);
    try {
      let result = await getBankCountries(userInfo.UserId);
      if (!empty(result) && result.hasOwnProperty('data')) {
        setCountries(result.data.countries);
      }
    } catch (error) {
      setTimeout(() => {
        showSnack(
          {
            id: "cancel_withdraw",
            text: context.t(
              "Information could not accesible"
            ),
            type: "warning",
            buttons: [
              {
                text: context.t("Got it"),
                className: "button_V2 purple",
              },
            ],
          });
      }, 1);
    }
    setLoading(0);
  };

      const internationalOperations = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui custom-notice">
              <h1>{context.t('International operations')}</h1>
              <p>
                {context.t(
                  "We are currently not accepting withdrawals to international banks. However, you can save your account and use it when we activate the option. Rest assured that we are constantly working to enhance our services. Thank you for your understanding!"
                )}
              </p>
              <div className="btn-alert-container">
                <button
                  className="btn-alert"
                  onClick={() => {
                    onClose();
                  }}>
                  {context.t('Accept')}
                </button>
              </div>
            </div>
          );
        }
      });
    };

  const getListBanksByCountry = async country_code => {
    setLoading(1);
    try {
      let result = await getBanksByCountry(userInfo.UserId, country_code);
      if (
        !empty(result) &&
        result.hasOwnProperty('data') &&
        !empty(result.data.data)
      ) {
        let { uniqueBanks, squareBanks } = cleanExtractBanks(result.data.data);
        setAllBanks(result.data.data);
        setBankSquare(squareBanks);
        setUsBanks(uniqueBanks);
      }
    } catch (error) {
      setTimeout(() => {
        showSnack(
          {
            id: "cancel_withdraw",
            text: context.t(
              "Information could not accesible"
            ),
            type: "warning",
            buttons: [
              {
                text: context.t("Got it"),
                className: "button_V2 purple",
              },
            ],
          });
      },1);
    }
    setLoading(0);
  };

  const getListMyAllBanksByCountry = async (country_code = null) => {
    setLoading(1);
    try {
      if (!empty(country_code)) {
        let result = await getBanksByCountry(userInfo.UserId, country_code);
        if (!empty(result) && result.hasOwnProperty('data') && !empty(result.data.data)) {
          const { uniqueBanks } = cleanExtractBanks(result.data.data);
          setMyAllUniqueBanks(uniqueBanks);
        } else {
          setMyAllUniqueBanks([]);
        }
      } else {
        setIntermediaryBanks([]);
      }
    } catch (error) {
      setTimeout(() => {
        showSnack(
          {
            id: "cancel_withdraw",
            text: context.t(
              "Information could not accesible"
            ),
            type: "warning",
            buttons: [
              {
                text: context.t("Got it"),
                className: "button_V2 purple",
              },
            ],
          });
      }, 1);
    }
    setLoading(0);
  };

  const getListIntermediaryBanksByCountry = async (country_code = null) => {
    setLoading(1);
    try {
      if (!empty(country_code)) {
        let result = await getBanksByCountry(userInfo.UserId, country_code);
        if (
          !empty(result) &&
          result.hasOwnProperty('data') &&
          !empty(result.data.data)
        ) {
          let { uniqueBanks } = cleanExtractBanks(result.data.data);
          setIntermediaryBanks(uniqueBanks);
        }
      } else {
        setIntermediaryBanks([]);
      }
    } catch (error) {
      setTimeout(() => {
        showSnack(
          {
            id: "cancel_withdraw",
            text: context.t(
              "Information could not accesible"
            ),
            type: "warning",
            buttons: [
              {
                text: context.t("Got it"),
                className: "button_V2 purple",
              },
            ],
          });
      }, 1);
    }
    setLoading(0);
  };

  let initialState = {
    validated: false,
    currencyCode: 'USD',
    transferType: 'INTERNATIONAL'
  };

  const onSubmitCallback = async (inputs, form, newErrors) => {
    const errors = customValidations(inputs);
    if(!empty(errors)) return
    setLoading(1);
    try {
      if (empty(newErrors)) {
        let save = await createAccountInternational(
          userInfo.UserId,
          inputs,
          selectedAccountId
        );
        if (
          !empty(save) &&
          save.hasOwnProperty('data') &&
          !empty(save.data) &&
          save.data.message === 'success'
        ) {
          setTimeout(() => {
            showSnack({
              id: 'cancel_withdraw',
              text: context.t("Account saved successfully"),
              type: 'sucess',
              buttons: [
                {
                  text: context.t('Got it'),
                  className: 'button_V2 purple',
                  onClick: () => history.push('/settings/accounts')
                }
              ]
          });
        }, 1);
        }
      }
    } catch (error) {
      console.log('Error on submition', error);
      let msg = '';
      if (
        error.hasOwnProperty('response') &&
        !empty(error.response.data.data)
      ) {
        console.error('error data', error.response.data);
        msg = error.response.data.data.error_message;
      } else {
        console.error('error', error);
        msg = error.message;
      }
      let cleanedError = cleanCustomError(msg);
      setGeneralErrors({
        multiple: cleanedError
      });
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};

    if (empty((inputs.abaRoutingNumber || '').trim())) {
      errors.abaRoutingNumber = 'Required field';
    } else {
      if (inputs.abaRoutingNumber.length !== 9 || !REGEX_LIST.regexToValidateNumber.test(inputs.abaRoutingNumber)) {
        errors.abaRoutingNumber = 'Must contain 9 numbers'
      }
    }
    const existBank = myAllUniqueBanks.find(bank => bank.id === inputs.bankId)
    if (empty(inputs.bankId) || !existBank) {
      errors.bankId = 'Required field';
    }

    if (empty((inputs.countryCode || '').trim())) {
      errors.countryCode = 'Required field';
    }

    if (empty((inputs.transferType || '').trim())) {
      errors.transferType = 'Required field';
    } else {
      if (inputs.transferType === 'INTERNATIONAL') {
        if (empty((inputs.intermediaryBankId || '').trim())) {
          errors.intermediaryBankId = 'Required field';
        }

        if (empty((inputs.intermediaryBankAddress || '').trim())) {
          errors.intermediaryBankAddress = 'Required field';
        } else {
          if (inputs.intermediaryBankAddress.length > 80) {
            errors.intermediaryBankAddress = 'Must contain at most 80 characters';
          }
        }

        if (empty((inputs.intermediaryBankCountry || '').trim())) {
          errors.intermediaryBankCountry = 'Required field';
        } else {
          if (inputs.intermediaryBankCountry.length > 2) {
            errors.intermediaryBankCountry = 'Must contain at most 2 characters';
          }
        }

        if (empty((inputs.intermediaryBankCity || '').trim())) {
          errors.intermediaryBankCity = 'Required field';
        } else {
          if (inputs.intermediaryBankCity.length > 30) {
            errors.intermediaryBankCity = 'Must contain at most 30 characters';
          }
        }

        if (empty((inputs.intermediaryBankState || '').trim())) {
          errors.intermediaryBankState = 'Required field';
        } else {
          if (inputs.intermediaryBankState.length > 30) {
            errors.intermediaryBankState = 'Must contain at most 30 characters';
          }
        }

        if (empty((inputs.intermediaryBankZip || '').trim())) {
          errors.intermediaryBankZip = 'Required field';
        } else {
          if (inputs.intermediaryBankZip.length > 20) {
            errors.intermediaryBankZip = 'Must contain at most 20 characters';
          }
        }

        if (empty((inputs.intermediarySwiftBicCode || '').trim())) {
          errors.intermediarySwiftBicCode = 'Required field';
        } else {
          if (inputs.intermediarySwiftBicCode.length > 20) {
            errors.intermediarySwiftBicCode = 'Must contain at most 20 characters';
          }
        }

        if (empty((inputs.intermediaryAbaRoutingNumber || '').trim())) {
          errors.intermediaryAbaRoutingNumber = 'Required field';
        } else {
          if (inputs.intermediaryAbaRoutingNumber.length !== 9 || !REGEX_LIST.regexToValidateNumber.test(inputs.intermediaryAbaRoutingNumber)) {
            errors.intermediaryAbaRoutingNumber = 'Must contain 9 numbers';
          }
        }

        if (empty((inputs.intermediaryReference || '').trim())) {
          errors.intermediaryReference = 'Required field';
        } else {
          if (inputs.intermediaryReference.length > 20) {
            errors.intermediaryReference = 'Must contain at most 20 characters';
          }
        }
      }
    }

    if (empty(inputs.alias)) {
      errors.alias = 'Required field';
    } else {
      if (!empty(inputs.alias.trim())) {
        const re = /^[a-z\d\-_\s]+$/i;
        let validate = re.test(inputs.alias);
        if (!validate) {
          errors.alias = 'Invalid alias. Only texts, numbers and spaces are accepted';
        } else {
          if (inputs.alias.length > 40) {
            errors.alias = "Must contain at most 40 characters";
          }
        }
      } else {
        errors.alias = 'Required field';
      }
    }

    if (empty((inputs.accountNumber || '').trim())) {
      errors.accountNumber = 'Required field';
    } else {
      const re = /^[a-z0-9]+$/i;
      let validate = re.test(inputs.accountNumber);
      if (!validate) {
        errors.accountNumber = 'Invalid account number. Only numbers are allowed';
      } else {
        if (inputs.accountNumber.length > 20) {
          errors.accountNumber = 'Must contain at most 20 characters';
        }
      }
    }

    if (empty((inputs.bankAddress || '').trim())) {
      errors.bankAddress = 'Required field';
    } else {
      if (inputs.bankAddress.length > 80) {
        errors.bankAddress = 'Must contain at most 80 characters';
      }
    }

    if (empty((inputs.swiftBicCode || '').trim())) {
      errors.swiftBicCode = 'Required field';
    } else {
      if (inputs.swiftBicCode.length > 20) {
        errors.swiftBicCode = 'Must contain at most 20 characters';
      }
    }

    // Beneficiary global
    if (empty((inputs.fullName || '').trim())) {
      errors.fullName = 'Required field';
    } else {
      if (inputs.fullName.length > 20) {
        errors.fullName = 'Must contain at most 20 characters';
      }
    }

    if (empty((inputs.beneficiaryAddress || '').trim())) {
      errors.beneficiaryAddress = 'Required field';
    } else {
      if (inputs.beneficiaryAddress.length > 80) {
        errors.beneficiaryAddress = 'Must contain at most 80 characters';
      }
    }

    if (empty((inputs.beneficiaryCountry || '').trim())) {
      errors.beneficiaryCountry = 'Required field';
    } else {
      if (inputs.beneficiaryCountry.length > 2) {
        errors.beneficiaryCountry = 'Must contain at most 2 characters';
      }
    }

    if (empty((inputs.beneficiaryCity || '').trim())) {
      errors.beneficiaryCity = 'Required field';
    } else {
      if (inputs.beneficiaryCity.length > 20) {
        errors.beneficiaryCity = 'Must contain at most 20 characters';
      }
    }

    if (empty((inputs.beneficiaryZip || '').trim())) {
      errors.beneficiaryZip = 'Required field';
    } else {
      if (inputs.beneficiaryZip.length > 10) {
        errors.beneficiaryZip = 'Must contain at most 10 characters';
      }
    }

    if ( empty(inputs.ownAccountConfirmation) || (!empty(inputs.ownAccountConfirmation) && inputs.ownAccountConfirmation !== true)) {
      errors.ownAccountConfirmation = 'You must declare that it is your account';
    }
    if (!empty(errors)) {
      console.log('errors::', errors);
    }
    setErrors(errors);
    return errors;
  };

  let {
    inputs,
    errors,
    setErrors,
    onInputChange,
    onCheckboxChange,
    onSubmitSimple
  } = useForm(initialState, onSubmitCallback, customValidations);

  useEffect(
    () => {
      if (!empty(userInfo) && !empty(userInfo.UserId)) {
        getListCountries();
        getListBanksByCountry('US');
      }
    },
    [userInfo]
  );

  document.title = `${context.t('My Banking Accounts')} | ${context.t(
    'Add bank account'
  )} - Banexcoin`;

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses() + ' minhfix'}>
        <Form
          noValidate
          className="form-accounting-v2"
          onSubmit={onSubmitSimple}>
          <AddInternationalFormFields
            inputs={inputs}
            onInputChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            onCheckboxChangeValidation={(ev) => {
              delete errors[ev.target.name]
              onCheckboxChange(ev)
            }}
            onInputChangeCleanValidation={(ev) => {
              delete errors[ev.target.name]
              onInputChange(ev)
            }}
            generalErrors={generalErrors}
            errors={errors}
            countries={countries}
            getListIntermediaryBanksByCountry={
              getListIntermediaryBanksByCountry
            }
            intermediaryBanks={intermediaryBanks}
            myAllUniqueBanks={myAllUniqueBanks}
            getListMyAllBanksByCountry={getListMyAllBanksByCountry}
          />
        </Form>
      </div>
    </React.Fragment>
  );
};

AccountAddInternationalFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};
const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

const mapStateToProps = ({ user: { userInfo, selectedAccountId } }) => {
  return { userInfo, selectedAccountId };
};


export default connect(mapStateToProps,mapDispatchToProps)(AccountAddInternationalFormPage);
