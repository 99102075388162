import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';
import empty from 'is-empty';

export const submitAddCreditCard = async (userId, data, UserAccountId) => {
  let headers = {
    //'Content-Type': 'multipart/form-data'
  };
  let path = '/api/credit-card';

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: data
  });
};

export const getMyCreditCards = async (userId, UserAccountId) => {
  let path = `/api/credit-card`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getMyPaymentAttempts = async (
  userId,
  UserAccountId,
  paginator
) => {
  let path = `/api/payments?page=${paginator.page}&limit=${paginator.limit}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLevelNormal = async (userId, UserAccountId) => {
  let path = `/api/level-basic/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLevelCorporateBeta = async (userId, UserAccountId) => {
  let path = `/api/level-corporate/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const deleteCard = async (userId, UserAccountId, id) => {
  let path = `/api/credit-card/${id}/delete`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'put',
    path
  });
};

export const newPaymentAttempt = async (userId, data, UserAccountId, id) => {
  let headers = {
    //'Content-Type': 'multipart/form-data'
  };
  let path = `/api/payment/credit-card`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: data
  });
};
