import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import SendFormComponent from './SendFormComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    disableWithdraw = _kycVerificationLevel.disableWithdraw;
  var withdrawError = !!state.withdraw.error;
  var selectedAccountId = state.user.selectedAccountId;
  var accounts = state.user.accounts;

  return {
    disableWithdraw: disableWithdraw,
    userInfo: state.user.userInfo,
    withdrawError: withdrawError,
    selectedAccountId: selectedAccountId,
    accounts: accounts,
    prices: state.level1,
    instruments: state.instrument.instruments,
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    verificationOnClick: function verificationOnClick() {
      return dispatch(closeSidePane());
    }
  };
};

var SendForm = reduxForm({
  form: 'SendForm',
  onSubmit: function onSubmit() {}
})(SendFormComponent);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendForm);
