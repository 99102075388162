import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import miniIsoCoink from './../../../images/coink-imgs/mini-iso-coink.svg';
import miniIsoBanexcoin from './../../../images/coink-imgs/mini-iso-banexcoin-new.svg';
import { getLoginToken } from './../CoinkHooks';
import apex from 'apex-web/lib/apex';
import { onLogin } from '../../bnxv2/hooks/authActions';
import { changeLanguage } from 'apex-web/lib/redux/actions/languageActions';

const StepWelcome = (props, context) => {
  let {
    propsFunction,
    setonLogin,
    setData,
    working,
    mounting,
    redirect,
    lang,
    changeLanguageTo
  } = props;
  const [errorLogin, setErrorLogin] = useState(false);

  const autoLogin = async () => {
    const windowUrl = window.location.search;
    const search = new URLSearchParams(windowUrl.substring(0));
    const data = Object.fromEntries(search);
    if (
      !empty(data.u) &&
      !empty(data.token) &&
      !empty(data.seed) &&
      !empty(data.pk) &&
      !empty(data.pk)
    ) {
      if (!working) {
        try {
          let payload = {
            url: window.location.href,
            userId: Number(data.u),
            token: data.token,
            seed: data.seed,
            publicKey: data.pk,
            cid: data.cid
          };

          let loginNow = await getLoginToken(payload);
          if (!empty(loginNow) && !empty(loginNow.data)) {
            const app = await apex.connection.AuthenticateUser(loginNow.data);
            if (!empty(app)) {
              if (app.Authenticated) {
                localStorage.setItem('cid', data.cid);
                setonLogin(app);
                redirect('/coink/dashboard/');
              } else {
                setData('INVALID_API_KEY');
                setErrorLogin(true);
              }
            }
          }
        } catch (error) {
          console.log('Error on login function', error);
          if (
            !empty(error.response) &&
            !empty(error.response.data) &&
            error.response.data.code
          ) {
            setData(error.response.data.code);
          }
          setErrorLogin(true);
        }
      }
    } else {
      if (data.action === 'unlogin') {
        setErrorLogin(true);
      }
    }
  };
  useEffect(
    () => {
      if (!empty(propsFunction)) {
        if (typeof propsFunction.nextStep == 'function') {
          if (!working && !mounting) {
            autoLogin();
          }
        }
      }
    },
    [propsFunction, working, mounting]
  );

  useEffect(
    () => {
      if (!empty(propsFunction)) {
        if (typeof propsFunction.nextStep == 'function') {
          if (errorLogin) {
            propsFunction.nextStep();
          }
        }
      }
    },
    [errorLogin, propsFunction, working]
  );

  useEffect(
    () => {
      if (empty(lang) || (!empty(lang) && lang === 'en')) {
        changeLanguageTo({
          language: 'es'
        });
      }
    },
    [lang]
  );

  return (
    <div className="step-bnx">
      <div className="loading-logos">
        <img src={miniIsoCoink} className="coink-isom" />
        <img src={miniIsoBanexcoin} className="banexcoin-isom" />
        <h3
          dangerouslySetInnerHTML={{
            __html: context.t('Logging <b>in...</b>')
          }}
        />
      </div>
    </div>
  );
};

StepWelcome.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = ({ i18nState: { lang } }) => {
  return { lang };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    setonLogin: payload => {
      onLogin(dispatch, payload);
    },
    redirect: path => {
      ownProps.history.push(path);
    },
    changeLanguageTo: payload => {
      dispatch(changeLanguage(payload.language));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StepWelcome)
);
