import React from "react";
import PropTypes from "prop-types";
import { TwoFa } from "../../../components";

function TwoFaConfirmation(props, context) {
  const { handleSteps, recharge } = props;
  return <TwoFa handleSteps={handleSteps} action={recharge}/>;
}

TwoFaConfirmation.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default TwoFaConfirmation;
