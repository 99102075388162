import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { getQuote, executeTransactionCall } from './BinancePageHooks';
import empty from 'is-empty';
import Loading from './../../components/Loading';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import apex from 'apex-web/lib/apex';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';

const lang = localStorage.getItem('language');

class BinanceDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepNum: 1,
      action: 'buy',
      amountBuy: 0,
      validAmount: false,
      validated: false,
      quote: {},
      executeFinale: {},
      loading: 0,
      noteAction: ''
    };
  }

  syncChart() {
    (async () => {
      var FromDate = moment();
      FromDate = FromDate.subtract(21, 'days');
      FromDate = FromDate.format('YYYY-MM-DD');

      let ToDate = moment().format('YYYY-MM-DD');

      let GetTickerHistory = await apex.connection.GetTickerHistory({
        InstrumentId: 1,
        Interval: 86400,
        FromDate: FromDate,
        ToDate: ToDate,
        OMSId: 1
      });

      if (!empty(GetTickerHistory)) {
        let dataPrices = [];
        for (let gth in GetTickerHistory) {
          let p = GetTickerHistory[gth];
          dataPrices.push(p[2]);
        }
        this.setState({ dataDefault: dataPrices });
      }
    })();
  }

  changeStep(num) {
    this.setState({ stepNum: num });
  }
  changeAction(act) {
    this.setState({ action: act });
  }
  onChangeField(e) {
    if (e.target.name === 'amountBuy') {
      let amount = isNaN(parseInt(e.target.value))
        ? 0
        : parseInt(e.target.value);
      this.setState({ [e.target.name]: amount });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  validaForm(val) {
    this.setState({ validated: val });
  }
  validAmount(val) {
    this.setState({ validAmount: val });
  }
  onSubmit(event) {
    event.preventDefault();

    // custom form handling here
  }

  componentDidMount() {}

  componentDidMount() {}

  render() {
    let {
      selectedAccountId,
      user,
      userInfo,
      positions,
      fetching,
      amountMsg
    } = this.props;
    let balance = {};
    let {
      stepNum,
      action,
      validated,
      amountBuy,
      validAmount,
      quote,
      loading,
      noteAction,
      executeFinale
    } = this.state;
    if (empty(userInfo) && fetching) {
      return <React.Fragment />;
    }
    if (!fetching) {
      var keys = Object.keys(positions);
      let balances = positions[keys];
      for (let b in balances) {
        if (balances[b].ProductSymbol === 'USD') {
          balance = balances[b];
        }
      }
    }

    const callQuote = async () => {
      try {
        this.setState({
          loading: 1
        });
        let BStorage = JSON.parse(localStorage.getItem('binance'));
        let quote = {
          userId: userInfo.UserId,
          requestedAmount: amountBuy,
          payType: action === 'buy' ? 0 : 1,
          binanceUserId: BStorage.userId.toString(),
          merchantUserAccount: userInfo.Email
        };
        let gQuote = await getQuote(quote);
        if (!empty(gQuote)) {
          if (gQuote.respCode === 'SUCCESS') {
            this.setState({
              quote: gQuote
            });
            this.changeStep(2);
          } else {
            toast.warn(this.context.t(gQuote.respMsg), {
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
        this.setState({
          loading: 0
        });
      } catch (error) {
        console.log('error on callQuote', error);
        toast.warn(this.context.t('An error has ocurred'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    };

    const getQuoteStart = () => {
      this.validaForm(true);
      if (this.state.amountBuy > 0 && this.state.amountBuy <= balance.Amount) {
        this.validAmount(true);
        callQuote();
      } else {
        this.validAmount(false);
      }
    };

    const executeTransaction = () => {
      (async () => {
        try {
          this.setState({
            loading: 1
          });
          let BStorage = JSON.parse(localStorage.getItem('binance'));
          let executePayload = {
            requestedAmount: amountBuy,
            quotePrice: quote.quotePrice,
            accountId: selectedAccountId,
            note: noteAction,
            userId: userInfo.UserId,
            quoteId: quote.quoteId,
            binanceUserId: BStorage.userId.toString(),
            merchantUserAccount: userInfo.Email,
            quoteDataToken: quote.quoteDataToken
          };
          let executeResponse = await executeTransactionCall(executePayload);
          if (!empty(executeResponse)) {
            this.changeStep(3);
            if (executeResponse.respCode === 'SUCCESS') {
              this.setState({
                executeFinale: executeResponse
              });
            } else {
              toast.warn(this.context.t(executeResponse.respMsg), {
                position: toast.POSITION.TOP_CENTER
              });
            }
          } else {
            toast.warn(this.context.t('Timeout error'), {
              position: toast.POSITION.TOP_CENTER
            });
          }
          this.setState({
            loading: 0
          });
        } catch (error) {
          console.log('error on executeTransaction', error);
          toast.warn(this.context.t('An error has ocurred'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })();
    };

    const seo = {
      title: 'Binance - Banexcoin',
      description: 'Comprar con Binance',
      url: 'https://www.banexcoin.com/binance/',
      image:
        'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
    };
    return (
      <div>
        <Loading loading={loading} />
        <Helmet
          title={seo.title}
          meta={[
            {
              name: 'description',
              property: 'og:description',
              content: seo.description
            },
            { property: 'og:title', content: seo.title },
            { property: 'og:url', content: seo.url },
            { property: 'og:image', content: seo.image },
            { property: 'og:image:type', content: 'image/png' },
            { property: 'twitter:image:src', content: seo.image },
            { property: 'twitter:title', content: seo.title },
            { property: 'twitter:description', content: seo.description }
          ]}>
          <link rel="canonical" href={seo.url} />
        </Helmet>
        <div className="text-center">
          <div className="binance-container col-4">
            <Form
              noValidate
              validated={validated}
              onSubmit={this.onSubmit}
              autoComplete="off">
              <Row className="justify-content-md-center">
                <Col xs lg="6">
                  <img
                    src="/local/landing-page/images/Binance_logo.svg"
                    className="logo-binance"
                  />
                </Col>
              </Row>
              <div
                className={'binance-step ' + (stepNum === 1 ? 'current' : '')}>
                <Row className="justify-content-md-center">
                  <Col md="auto">
                    <h3>{this.context.t('What do you want to do today?')}</h3>
                  </Col>
                </Row>
                <Row className="mt-0 justify-content-md-center">
                  <Col md="auto">
                    <p>
                      {this.context.t('Available Balance')}{' '}
                      {!empty(balance)
                        ? balance.Amount.toFixed(2) +
                          ' ' +
                          balance.ProductSymbol
                        : ''}
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs lg="12" className="text-center">
                    <Button
                      className={
                        'binance-btn switcher first ' +
                        (action === 'buy' ? 'active' : '')
                      }
                      onClick={() => {
                        this.changeAction('buy');
                      }}>
                      {this.context.t('Buy {product}', { product: 'USD' })}
                    </Button>
                    <Button
                      className={
                        'binance-btn switcher last ' +
                        (action === 'sell' ? 'active' : '')
                      }
                      onClick={() => {
                        this.changeAction('sell');
                      }}>
                      {this.context.t('Sell {product}', { product: 'USD' })}
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-md-center pt-4 pb-4">
                  <Col xs lg="8" className="text-center amount-container">
                    <Form.Group controlId="amountBuy">
                      <Form.Control
                        required
                        type="number"
                        name="amountBuy"
                        placeholder="0.00 USD"
                        onChange={e => {
                          this.onChangeField(e);
                        }}
                      />
                      {!validAmount ? (
                        <Form.Text className="text-danger">
                          {this.state.amountBuy > balance.Amount
                            ? this.context.t('Not enough funds')
                            : this.context.t('Please enter the amount')}
                        </Form.Text>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center pt-4 pb-4">
                  <Col xs lg="12" className="text-center amount-container">
                    <Button
                      className="binance-btn"
                      onClick={() => {
                        getQuoteStart();
                      }}>
                      {this.context.t('Continue')}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div
                className={'binance-step ' + (stepNum === 2 ? 'current' : '')}>
                <Row className="justify-content-md-center pt-4 pb-4">
                  <Col xs lg="12" className="text-center amount-container">
                    {!empty(quote) ? (
                      <div className="card-prices" key={quote.quoteId}>
                        <h2>{this.context.t(this.context.t(action))}</h2>
                        <h4>
                          {this.context.t('{product} to {action}', {
                            product: 'BTC',
                            action: this.context.t(action)
                          })}
                          <br />
                          {this.context.t('Available Balance')}{' '}
                          {!empty(balance)
                            ? balance.Amount.toFixed(2) +
                              ' ' +
                              balance.ProductSymbol
                            : ''}
                        </h4>
                        <div className="buy-number">
                          <p>{this.context.t('Price received')}</p>
                          <h3>{quote.quotePrice} USD</h3>
                        </div>
                        <Row>
                          <Col xs lg="6" className="text-left">
                            {this.context.t('You send')}
                          </Col>
                          <Col xs lg="6" className="text-right">
                            {amountBuy} USD
                          </Col>
                        </Row>
                        <Row>
                          <Col xs lg="6" className="text-left">
                            {this.context.t('You receive')}
                          </Col>
                          <Col xs lg="6" className="text-right">
                            {quote.totalAmount} BTC
                          </Col>
                        </Row>
                        <Form.Group controlId="noteAction">
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name="noteAction"
                            className="noteAction"
                            placeholder={this.context.t('Note (Optional)')}
                            onChange={e => {
                              this.onChangeField(e);
                            }}
                          />
                        </Form.Group>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-md-center pt-4 pb-4">
                  <Col xs lg="4" className="text-center amount-container">
                    <Button
                      className="binance-btn"
                      onClick={() => {
                        this.changeStep(1);
                      }}>
                      {this.context.t('Back')}
                    </Button>
                  </Col>
                  <Col xs lg="4" className="text-center amount-container">
                    <Button
                      className="binance-btn"
                      onClick={e => {
                        executeTransaction();
                      }}>
                      {this.context.t('{action} {product}', {
                        action: this.context.t(action),
                        product: 'BTC'
                      })}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div
                className={'binance-step ' + (stepNum === 3 ? 'current' : '')}>
                {!empty(quote) ? (
                  <div className="card-prices details" key={quote.quoteId}>
                    <Row>
                      <Col xs lg="6" className="text-left">
                        {this.context.t('Quote Id')}
                      </Col>
                      <Col xs lg="6" className="text-right">
                        {executeFinale.quoteId}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs lg="6" className="text-left">
                        {this.context.t('Order Id')}
                      </Col>
                      <Col xs lg="6" className="text-right">
                        {executeFinale.orderId}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs lg="6" className="text-left">
                        {this.context.t('Payment Id')}
                      </Col>
                      <Col xs lg="6" className="text-right">
                        {executeFinale.paymentId}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs lg="6" className="text-left">
                        {this.context.t('Status')}
                      </Col>
                      <Col xs lg="6" className="text-right">
                        {executeFinale.status}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ''
                )}
                <Row className="justify-content-md-center pt-4 pb-4">
                  <Col xs lg="8" className="text-center amount-container">
                    <Link to="/binance" className="binance-btn btn btn-primary">
                      {this.context.t('Finish')}
                    </Link>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

BinanceDashboard.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = ({
  user: { selectedAccountId, userInfo },
  user,
  position: { positions, fetching }
}) => {
  return { selectedAccountId, userInfo, user, positions, fetching };
};

export default connect(mapStateToProps)(BinanceDashboard);
