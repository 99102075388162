import React from 'react'
import PropTypes from 'prop-types'
import rightArrow from "../../../../images/icons-v2/arrow-right-white.svg";
import FormButtonV2 from '../../../../components/common/BnxFormsComponents/FormButton';
import { useHistory } from 'react-router-dom';
import "./footer.css";

function Footer(_, context) {
  const ht = useHistory()
  return (
    <div className="banexcard-footer">
      <p>
        {context.t("Request your {html} for your face-to-face purchases (Available only in Peru)",{
          html: <strong>{context.t("Physical Banexcard")}</strong>
        })}
      </p>{" "}
      <span>
        {context.t("Click here")} <img src={rightArrow} alt="" />{" "}
      </span>
      <FormButtonV2 className="button" onClick={() => ht.push("/cards/delivery/physical-card")}>
        {context.t("I want it!")}
      </FormButtonV2>
    </div>
  )
}

Footer.contextTypes = {
  t: PropTypes.func.isRequired
}
export default Footer