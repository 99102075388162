import React from "react";
import APDatePicker from "apex-web/lib/components/common/APDatePicker/APDatePicker";
import PropTypes from "prop-types";
import APButton from "apex-web/lib/components/common/APButton";

function FiltersComponent(props, context) {
  const { startDate, clearDate, handleFilterByDate, resetRows } = props;
  const clearFilters = () => {
    clearDate("startDate");
    clearDate("endDate");
    resetRows();
  };

  return (
    <div className="filters-exchange-history">
      <APDatePicker
        name="startDate"
        label={context.t("from")}
        maxDate={new Date()}
        format={value => value}
        iconClick={() => clearDate('startDate')}
      />

      <APDatePicker
        name="endDate"
        label={context.t("to")}
        minDate={startDate}
        maxDate={new Date()}
        format={value => value}
        iconClick={() => clearDate('endDate')}
      />
      <button className="search-transactions-filters" onClick={handleFilterByDate}>{context.t("Search")}</button>
      <APButton
        classModifiers="clear-filters"
        type="button"
        onClick={clearFilters}
      >
        {context.t("Clear Filters")}
      </APButton>
    </div>
  );
}

FiltersComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default FiltersComponent;
