import React, { useEffect, useState } from "react";
import FreshChat from "react-freshchat";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { setUserInfo } from "../../redux/actions/set_user_info";
import { getLevelBasicV3 } from "../KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks";
import { updateBanexCard } from "../../redux/actions/set_banex_card";
import BanexcardController from "../../pages/Banexcard/controllers/BanexcardController";

const FreshchatWidget = (props, context) => {
  const { userInfo, setUserInfo, updateBanexCard } = props;
  const [user, setUser] = useState({});

  const lang = localStorage.getItem("language");
  const userPhone =
  !isEmpty(user) && !isEmpty(user.banexcoinUserByBanexcoinUserId.phone)
    ? user.banexcoinUserByBanexcoinUserId.phone.replace("+", "")
    : null;

  
  const getBanexcardRequestById = async (requestId) => {
    const response = await BanexcardController.getRequestPhysicalCardById({ userInfo, requestId })
    return response
  }
  const getUserInfo = async () => {
    try {
      const info = await getLevelBasicV3(userInfo.UserId, userInfo.AccountId);
      let formattedData = info.data || {}
      if(!isEmpty(formattedData.level_basic) && formattedData.level_basic.hasOwnProperty("cardRequestsByLevelsBasicId")){
          formattedData.level_basic.banexcardData = {
              provider: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].provider : "",
              status: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].status : "",
              updatedAt: formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0] ? formattedData.level_basic.cardRequestsByLevelsBasicId.nodes[0].updatedAt : "",
              //TODO: ONLY FOR TESTING
            }
          }
          //TODO: ONLY FOR TESTING
      setUserInfo(formattedData);
      const newData = info.data.level_basic || {};
      setUser(newData);
      const banexCardOptionIsVisible = ![17, 18, 100, 101].includes(newData.userLevel)
      const hasBanexCard = !isEmpty(newData.cardRequestsByLevelsBasicId.nodes) && newData.cardRequestsByLevelsBasicId.nodes[0].status === 'CARD_CREATED'
      const userLevel = newData.userLevel;
      let banexcardRequestData = {}
      //TODO: ONLY FOR TESTING
      if(newData.hasOwnProperty("physicalCardRequestsByLevelsBasicId") && !isEmpty(newData.physicalCardRequestsByLevelsBasicId.nodes)){
        banexcardRequestData = await getBanexcardRequestById(newData.physicalCardRequestsByLevelsBasicId.nodes[0].id)
      }
      updateBanexCard({
        loadingBanexCard: false,
        banexCardOptionIsVisible,
        hasBanexCard,
        userLevel,
        banexcardRequestData
      })
    } catch (error) {}
  };

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getUserInfo();
    }
  }, [userInfo]);

  useEffect(() => {
    if (window.fcWidget) {
      if (!isEmpty(user) && !window.location.pathname.includes("login")) {
        window.fcWidget.user.setProperties({
          externalId: userInfo.UserId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: userPhone,
        });
      }  
    }
  }, [user]);

  if(process.env.REACT_APP_TOKEN_FRESHCHAT === undefined || process.env.REACT_APP_HOST_FRESHCHAT === undefined) return (<></>) 
  return (
    <div>
        <FreshChat
          token={process.env.REACT_APP_TOKEN_FRESHCHAT}
          host={process.env.REACT_APP_HOST_FRESHCHAT}
          locale={lang}
        />
    </div>
  );
};
FreshchatWidget.contextTypes = {
  t: PropTypes.func,
};

const mapDispatchToProps = { setUserInfo, updateBanexCard };

const mapStateToProps = (state) => {
  let {
    user: { selectedAccountId, userInfo },
    user,
  } = state;
  return {
    selectedAccountId,
    userInfo,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreshchatWidget);
