import { connect } from 'react-redux';
import ConfirmSingleReportModal from './ConfirmSingleReportModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { generateReport } from 'apex-web/lib/redux/actions/reportActions';

const mapDispatchToProps = {
  generateReport: generateReport
};

const container = connect(null, mapDispatchToProps)(ConfirmSingleReportModal);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_SINGLE_REPORT
})(container);