import React from 'react';
import PageHeaderNavMenuComponent from './PageHeaderNavMenuComponent';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import APIcon from '../common/APIcon';
import './PageHeaderNavComponent.css';
import LanguageComponent from '../../layouts/PageHeaderLayout/LanguageComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const PageHeaderNavClasses = getBEMClasses('page-header-nav');

export default class PageHeaderNavComponent extends React.Component {
  state = {
    isOpen: false
  };

  handleClick = () => {
    if (!this.state.isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
      document.removeEventListener('resize', this.handleResize, false);
    }

    const body = document.body;
    const hasOverflowClass = body.classList.contains('overflow-hidden');
    const screenWidth = window.innerWidth;
    if (screenWidth < 1024) {
      if (hasOverflowClass) {
        body.classList.remove('overflow-hidden');
      } else {
        body.classList.add('overflow-hidden');
      }
    }
    
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOutsideClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  };

  handleResize(){
    if(!this.state) return
      if(!this.state.isOpen){
        const body = document.body;
        body.classList.remove('overflow-hidden');
      }
  }
  componentDidMount() {
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  render() {
    return (
      <div
        className={PageHeaderNavClasses(
          'container',
          this.state.isOpen ? '' : 'closed'
        )}
        ref={node => {
          this.node = node;
        }}>
        <div
          className={PageHeaderNavClasses(
            'menu-toggle',
            this.state.isOpen ? 'active' : ''
          )}
          onClick={this.handleClick}>
          <APIcon
            name="menu-toggle"
            customClass={PageHeaderNavClasses('icon')}
          />
        </div>
        <div className='menuly'>
          <div className="menuly-child">
            <PageHeaderNavMenuComponent
              customClass={PageHeaderNavClasses}
              handleNavItemClick={this.handleClick}
              navItems={this.props.navItems}
              isOpen={this.props.isOpen}
            />
          </div>
          <div />
          <div className={`toggle-button ${this.props.isOpen ? "open" : ""}`}>
            <button onClick={this.props.toggleMenu}><FontAwesomeIcon size='20' color='white' className="arrow-toggle" icon={faChevronRight} /></button>
          </div>
          <div onClick={!this.props.isOpen ? this.props.toggleMenu: null}>
            <LanguageComponent customClass={PageHeaderNavClasses} handleNavItemClick={this.handleClick}/>
          </div>
        </div>
      </div>
    );
  }
}
