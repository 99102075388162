import React from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../../../components";
import { useHistory } from "react-router-dom";

function AttentionModal(props, context) {
  const { handleSteps } = props;
  const ht = useHistory();
  const actions = [
    {
      label: context.t("Keep card"),
      action: () => ht.push("/cards"),
      className: "secondary",
    },
    {
      label: context.t("Yes, delete"),
      action: handleSteps.next,
    },
  ];
  return (
    <CardGradient
      customClassName="attention-modal"
      type="warning"
      title={context.t("!Attention!")}
      actions={actions}
    >
      <div className="attention-content">
        <p>
          {context.t("Your {html} currently has an available balance of {balance} that you have not yet used. When deleting your card, take into account the following:",{
            balance: "$ 289.50",
            html: <strong>Banexcard</strong>
          })}
        </p>
        <ul>
          <li>
            {context.t("The cancellation of your {html} will be carried out without any additional charge.",{
              html: <strong>Banexcard</strong>
            })}
          </li>
          <li>{context.t("After the process, you will not be able to use your {html}.",{
            html: <strong>Banexcard</strong>
            })}
          </li>
        </ul>
        <p>
          {context.t("It is important to note that this process requires the completion of possible pending transactions. If there are none, we will automatically transfer the balance of {balance} to your USDC Wallet at Banexcoin within 24 business hours.",{
            balance: <strong className="rest">$289.50</strong>
          })}
        </p>
        <p>
          {context.t("If there are any, these will be deducted from the available balance to be transferred.")}
        </p>
      </div>
    </CardGradient>
  );
}

AttentionModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default AttentionModal;
