import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { buyValue } from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

import 'apex-web/lib/components/ConfirmOrderModal/ConfirmOrderModalComponent.css';

const bemClasses = getBEMClasses('confirm-order-modal');

const ConfirmOrderModalComponent = function ConfirmOrderModalComponent(props, context) {
    const formObj = props.formObj,
        isOpen = props.isOpen,
        close = props.close,
        confirmReport = props.confirmReport,
        feeSymbol = props.feeSymbol,
        _props$selectedInstru = props.selectedInstrument,
        QuantityIncrement = _props$selectedInstru.QuantityIncrement,
        PriceIncrement = _props$selectedInstru.PriceIncrement,
        Product1Symbol = _props$selectedInstru.Product1Symbol,
        Product2Symbol = _props$selectedInstru.Product2Symbol,
        price = props.price,
        orderTotal = props.orderTotal,
        isMarginActive = props.isMarginActive;

    const isBuySide = formObj.values.side === buyValue;

    let items = [{
        label: context.t('Order Type:'),
        text: context.t(formObj.values.orderType)
    }, {
        label: context.t('Quantity'),
        text: formatNumberToLocale(formObj.values.quantity, convertIncrementToIntDecimalPlaces(QuantityIncrement)) + ' ' + Product1Symbol
    }, {
        label: context.t('Price'),
        text: formatNumberToLocale(price, convertIncrementToIntDecimalPlaces(PriceIncrement)) + ' ' + Product2Symbol
    }, {
        label: context.t('Fee'),
        text: formatNumberToLocale(formObj.values.fee.OrderFee, feeSymbol) + ' ' + feeSymbol
    }, {
        label: context.t('Value'),
        text: formatNumberToLocale(orderTotal, convertIncrementToIntDecimalPlaces(PriceIncrement)) + ' ' + Product2Symbol
    }];

    if (isMarginActive) {
        items.splice(1, 0, {
            label: context.t('Leverage'),
            text: formObj.values.fee.Leverage
        });
    }

    return React.createElement(
        Modal,
        {
            isOpen: isOpen,
            title: isMarginActive ? context.t('Confirm Margin Order') : context.t('Confirm Order'),
            close: close,
            footer: {
                buttonText: isBuySide ? context.t('Confirm Buy Order') : context.t('Confirm Sell Order'),
                buttonStyle: isBuySide ? 'additive' : 'subtractive',
                onClick: function onClick() {
                    return confirmReport(formObj.values);
                }
            },
            customClass: bemClasses(formObj.values.side === buyValue ? 'bought' : 'sold')
        },
        React.createElement(
            'div',
            { className: bemClasses() },
            items.map(function (item) {
                return React.createElement(APLabelWithText, {
                    key: item.label,
                    label: item.label,
                    text: item.text,
                    customClass: bemClasses()
                });
            })
        )
    );
};

ConfirmOrderModalComponent.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ConfirmOrderModalComponent;