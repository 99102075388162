import { connect } from 'react-redux';
import SendAddressComponent from './SendAddressComponent';
import {
  fetchProductWithdrawTemplates,
  fetchProductWithdrawTemplate
} from 'apex-web/lib/redux/actions/withdrawActions';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state) {
  return {
    withdrawInfo: state.withdraw,
    WalletsDisabledMessage: config.global.WalletsDisabledMessage
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    selectWithdrawProduct: function selectWithdrawProduct(productId) {
      return dispatch(fetchProductWithdrawTemplates(productId));
    },
    selectWithdrawTemplate: function selectWithdrawTemplate(
      productId,
      templateType
    ) {
      return dispatch(fetchProductWithdrawTemplate(productId, templateType));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendAddressComponent);
