import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Footer, Loader, MenuCard } from "../../components";
import shoppingCart from "../../../../images/icons-v2/shopping-cart.svg";
import trash from "../../../../images/icons-v2/trash.svg";
import pencil from "../../../../images/icons-v2/pencil-box-outline.svg";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import BanexcardController from "../../controllers/BanexcardController";
import { showSnack as _showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import { banexcardSettingCodes } from "../../constants/banexcardSettingCodes";
import banexcardImage from "../../../../images/banexcard-image.png";
import { Redirect } from "react-router-dom";
import "./settings.css";
import { setBanexcardConfig as _setBanexcardConfig } from "../../../../redux/actions/set_banex_card";
import { useState } from "react";
import { cardNumberMask, sanitizeValue } from "../../../utils";

function Settings(props, context) {
  const { userInfo, showSnack, hasPhysicalBanexcardRequest, hasBanexCard, loadingBanexCard, setBanexcardConfig, banexcardConfig: userConfig, levelBasic = {}, banexcardInfo } = props;
  const { cardInfo = {} } = banexcardInfo;
  const cardNumber = cardInfo.pan || "1234567845672345";
  const ht = useHistory();
  const [registeredAddress, setRegisteredAddress] = useState("");

  const handleGetUserConfig = async () => {
    const response = await BanexcardController.getBanexcardConfiguration({
      userInfo,
    });
    setBanexcardConfig(response);
  };
  useEffect(() => {
    if (isEmpty(userInfo)) return;
    handleGetUserConfig();
  }, [userInfo]);

  const enabledLabelByUserConfigToToggle = (item) => {
    if (isEmpty(userConfig)) return "";
    const element = userConfig.find((element) => element.channelCode === item);
    if (element) return element.enabled ? "disable" : "enable";
    return "";
  };

  const valueByUserConfigToSwitch = (item) => {
    if (isEmpty(userConfig)) return false;
    const element = userConfig.find((element) => element.channelCode === item);
    if (element) return Boolean(element.enabled);
    return false;
  };

  const handleSetCardConfig = async (channelCode) => {
    const response = await BanexcardController.setBanexcardConfiguration({
      userInfo,
      channelCode,
      enabled: !valueByUserConfigToSwitch(channelCode),
    });
    if(!isEmpty(response)){
      handleGetUserConfig();
    }
  };

  useEffect(() => {
    if (isEmpty(levelBasic)) return;
    const { addressMain, town, district, city } = levelBasic;
    const registeredAddress = `${sanitizeValue(addressMain)}, ${sanitizeValue(town)}, ${sanitizeValue(
      district
    )}, ${sanitizeValue(city)}`
    setRegisteredAddress(registeredAddress)
  }, [levelBasic]);


  const options = [
    {
      title: context.t("Online purchases"),
      description: context.t("Enable or disable your card for online purchases"),
      onClick: () =>
        showSnack({
          id: "online_purchase",
          text: context.t(
            `Are you sure you want to ${enabledLabelByUserConfigToToggle(
              banexcardSettingCodes.ONLINE_PURCHASE.code
            )} the ${banexcardSettingCodes.ONLINE_PURCHASE.label}?`
          ),
          type: "info",
          buttons: [
            {
              text: context.t("Cancel"),
              className: 'button_V2 grey'
            },
            {
              text: context.t("Accept"),
              onClick: () => handleSetCardConfig(banexcardSettingCodes.ONLINE_PURCHASE.code),
              className: 'button_V2 purple'
            },
          ],
        }),
      icon: shoppingCart,
      type: "toggle",
      value: valueByUserConfigToSwitch(
        banexcardSettingCodes.ONLINE_PURCHASE.code
      ),
    },
    // {
    //   title: "Renovar datos de tarjeta",
    //   description:
    //     "Renueva tu tarjeta con un nuevo número, fecha de vencimiento y CVV",
    //   onClick: () => {},
    //   icon: cardReload,
    // },
    {
      title: context.t("See information"),
      description: context.t("View your card information"),
      onClick: () => {},
      icon: pencil,
      type: "desplegable",
      children: (
        <div className="details-card-option">
          <p>
             {context.t("Creation date")}: <strong>{!isEmpty(levelBasic.banexcardData) && !isEmpty(levelBasic.banexcardData.updatedAt) ? new Date(levelBasic.banexcardData.updatedAt).toLocaleString() : ""}</strong>
          </p>
          <p>
            {context.t("Card number")}: <strong>{cardNumberMask(cardNumber)}</strong>
          </p>
          <p>
            {context.t("Address")}:
            <strong>{registeredAddress}</strong>
          </p>
        </div>
      ),
    },
  ];


  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard) return <Redirect to="/cards" />

  return (
    <section className="banexcard-settings-page">
      <h2>{context.t("Configure card")}</h2>
      <div className="body">
        <div className="left">
              <img
                src={banexcardImage}
                alt="banexcard image"
                className="banexcard-image"
              />
            </div>
        <MenuCard
          title="Virtual"
          description={context.t("For your purchases and subscriptions online")}
          options={options}
          bottomOption={{
            title: context.t("Delete Card"),
            description: context.t("Do you want to cancel your virtual card? Do it here"),
            onClick: () => ht.push("/cards/settings/remove-card"),
            icon: trash,
          }}
        />
      </div>
      {(!hasPhysicalBanexcardRequest && hasBanexCard) && <Footer />}
    </section>
  );
}

const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard, config }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    userInfo,
    loadingBanexCard,
    banexcardConfig: config,
    levelBasic: state.completedUserInfo.level_basic,
    hasPhysicalBanexcardRequest: !isEmpty(state.completedUserInfo.level_basic) && state.completedUserInfo.level_basic.hasOwnProperty("physicalCardRequestsByLevelsBasicId")  ? !!state.completedUserInfo.level_basic.physicalCardRequestsByLevelsBasicId.nodes[0].id  : false
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showSnack: (message) => dispatch(_showSnack(message)),
    setBanexcardConfig: (config) => dispatch(_setBanexcardConfig(config)),
  };
};
Settings.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
