import { connect } from 'react-redux';
import KYCBaseComponent from './KYCBaseComponent';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { getUserConfig as _getUserConfig } from 'apex-web/lib/redux/actions/userActions';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
    verificationLevel = _kycVerificationLevel.verificationLevel,
    levelIncreaseStatus = _kycVerificationLevel.levelIncreaseStatus,
    kycConfig = _kycVerificationLevel.kycConfig;

  var highlightStyle = kycConfig.highlightStyle;

  return {
    verificationLevel: verificationLevel,
    levelIncreaseStatus: levelIncreaseStatus,
    kycConfig: kycConfig,
    highlightStyle: highlightStyle,
    userId: state.user.userInfo.UserId,
    userName: state.user.userInfo.UserName
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getUserConfig: function getUserConfig(userId, username) {
      return dispatch(_getUserConfig(userId, username));
    }
  };
};

var KYCBaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCBaseComponent);

export default KYCBaseContainer;
