import React from 'react';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import EmptyWallet from '../../../images/icons-v2/empty-wallet.svg'
import { Link } from 'react-router-dom';
import APTable from 'apex-web/lib/components/common/APTable';

var baseClasses = getBEMClasses('activity');

var RecentActivityTableComponent = function RecentActivityTableComponent(
  props
) {
  var columns = props.columns,
    content = props.content,
    context = props.context,
    onRowClicked = props.onRowClicked,
    usePagination = props.usePagination;
    
  return React.createElement(APTable, {
    componentName: 'RecentActivityComponent',
    columns: columns,
    pageSize: 6,
    onRowClicked,
    minRow: 6,
    rows: content,
    baseClass: baseClasses,
    headerOutside: true,
    usePagination: usePagination,
    empty: <div className='no-activity'>
      <img src={EmptyWallet} alt="Icon wallet" />
      <p>{context.t("You don't have funds yet")}</p>
      <Link to='/deposit' className="button_V2 purple">{context.t('Deposit now')}</Link>
    </div>
  });
};

RecentActivityTableComponent.defaultProps = {
  usePagination: false
};

export default RecentActivityTableComponent;
