import { connect } from 'react-redux';
import ClosedPositionsComponent from './ClosedPositionsComponent';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { closedPositionsSelector } from 'apex-web/lib/redux/selectors/marginHistorySelectors';
import { marginBaseAssetSelector } from 'apex-web/lib/redux/selectors/marginSelectors';

const mapStateToProps = (state, ownProps) => {
  const closedPositions = closedPositionsSelector(state, ownProps.config);
  const instruments = instrumentSelectorInstruments(state);
  const { activeRequests } = state.margin.history;
  const fetching = activeRequests > 0;

  return {
    data: closedPositions,
    instruments,
    fetching,
    baseAsset: marginBaseAssetSelector(state)
  };
};

export default connect(mapStateToProps)(ClosedPositionsComponent);
