import { connect } from 'react-redux';
import InactiveOrdersComponent from './InactiveOrdersComponent';
import { inactiveOrdersSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { cancelOrders } from 'apex-web/lib/redux/actions/orderHistoryActions';

const makeMapStateToProps = () => {
  const inactiveOrdersSelector = inactiveOrdersSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const inactiveOrders = inactiveOrdersSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const { activeRequests } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: inactiveOrders,
      instruments,
      fetching,
      selectedInstrument
    };
  };
};

const mapDispatchToProps = {
  cancelOrders
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InactiveOrdersComponent);
