import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { FormButtonV2, FormSingleCheckbox, FormTextArea } from '../common/BnxFormsComponents';
import { IconArrowPopupLeft } from '../common/BnxIcons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEmpty from 'is-empty';
import StatusMessage from '../../pages/Coink/Components/Cards/StatusMessage';
import './deleteAccount.css';

const accountPageClasses = getBEMClasses('account-page');

const DeleteAccount = ({ list, deleteUserAccount, messageSuccess, goBack }, context) => {
   const [optSelected, setOptSelected] = useState([]);
   const [reasonDetails, setReasonDetails] = useState('');
   const [commentary, setCommentary] = useState('');
   const [sendRequest, setSendRequest] = useState(false);

   const onChangeOptionSelected = (value) => {
      let newOptSelected = [];
      if (optSelected.includes(value)) {
         newOptSelected = optSelected.filter(item => item !== value);
      } else {
         newOptSelected = [...optSelected, value];
      }
      setOptSelected(newOptSelected);
   }

   const validateCheck = (value) => {
      return optSelected.includes(value) ? true : false;
   }
   const showOtheReason = () => {
      return optSelected.includes('Other reason') ? true : false;
   }
   const disabledButton = () => {
      let status = true;
      if (!isEmpty(optSelected)) {
         if (optSelected.includes('Other reason') && isEmpty(reasonDetails)) {
            status = true;
         } else {
            status = false;
         }
      }
      return status;
   }

   const onSubmit = (e) => {
      e.preventDefault();
      setSendRequest(true);
      const data = {
         optSelected,
         reasonDetails: optSelected.includes('Other reason') ? reasonDetails : '',
         commentary
      }
      deleteUserAccount(data);
   }

   return (
      <Form className='content-form-delete' noValidate onSubmit={onSubmit}>
         <Row>
            <Col xl="4">
               <div className="v2-back-link back-arrow-delete" onClick={goBack}>
                  <IconArrowPopupLeft />
               </div>
            </Col>
            <Col xl="4" className='p-0'>
               {messageSuccess ?
                  <div className={`${accountPageClasses('select-box-item')} reset-style-box delete-account delete-account-container`}>
                     <div className="body-step-bnx jc-t nBodyOp nBodyBtn container-Data-Button coink-app success-msg">
                        <div className="step-bnx delete-section">
                           <div className="contSFwitDra">
                              <StatusMessage
                                 type="SUCCESS"
                                 title="Your request has been received"
                                 message="We will contact you shortly to confirm your request."
                              >
                                 <FormButtonV2
                                 type="button"
                                 variant={'light'}
                                 onClick={goBack}
                                 className="button-succes-delete">
                                 {context.t('Accept')}
                                 </FormButtonV2>
                              </StatusMessage>
                           </div>
                        </div>
                     </div>
                  </div> :
                  <div className='container-delete'>
                     <div className={`${accountPageClasses('select-box-item')} reset-style-box delete-title`}>
                        <b>{context.t('Delete my account')}</b>
                     </div>
                     <p>{context.t('Choose the reasons why you leave us')}{` :(`}</p>
                     <div className='list-items-delete'>
                        {list.map(({ label, value }, key) =>
                           <FormSingleCheckbox
                              key={key}
                              id={`${key}-check`}
                              name={`${key}-check`}
                              classNameContainer="pl-2"
                              label={<span dangerouslySetInnerHTML={{ __html: label }} />}
                              onChange={() => !sendRequest ? onChangeOptionSelected(value) : {}}
                              checked={validateCheck(value)}
                           />)
                        }
                     </div>
                     {showOtheReason() &&
                        <React.Fragment>
                           <FormTextArea
                              required
                              as="textarea"
                              onChange={(e) => !sendRequest ? setReasonDetails(e.target.value) : {}}
                              placeholder={context.t('Describe the reason why you want to delete your account')}
                              value={reasonDetails}
                              className="font-18"
                           />
                           <p>{context.t('One of our values is respect, we value your opinion.')}</p>
                        </React.Fragment>
                     }
                     <h3>{context.t('How should we improve?')}</h3>
                     <p className='text-suggestion'>{context.t('Your opinion is very important in order to improve our platform, feel free to express yourself.')}</p>
                     <FormTextArea
                        required
                        as="textarea"
                        onChange={(e) => !sendRequest ? setCommentary(e.target.value) : {}}
                        placeholder={context.t('Optional')}
                        value={commentary}
                        className="font-18"
                     />
                     <FormButtonV2
                        type="submit"
                        variant={'light'}
                        disabled={!sendRequest ? disabledButton() : sendRequest}>
                        {context.t(sendRequest ? 'Submitting...' : 'Delete account')}
                     </FormButtonV2>
                  </div>
               }
            </Col>
         </Row>
      </Form>
   )
}

DeleteAccount.contextTypes = {
   t: PropTypes.func.isRequired
};

export default DeleteAccount