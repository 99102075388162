import { connect } from 'react-redux';
import DepositWorkflowsComponent from './DepositWorkflowsComponent';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var submitting = _ref.submitting,
    onSubmit = _ref.onSubmit,
    setBankDetails = _ref.setBankDetails;

  return {
    templateInfo: state.deposit.templateInfo,
    submitting: submitting,
    isLoading: state.deposit.isLoading,
    onSubmit: onSubmit,
    setBankDetails: setBankDetails
  };
};

export default connect(mapStateToProps)(DepositWorkflowsComponent);
