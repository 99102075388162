import apex from 'apex-web/lib/apex';
import xlsx from "json-as-xlsx";
import moment from 'moment';

const csvJSON = (csv) => {
  const newCsv = csv.replaceAll('"', '');
  const lines = newCsv.split("\n");
  let result = [];
  const headers = lines[0].replaceAll(/\r/g, '').split(",");

  for (let i = 1; i < lines.length; i++) {
    let obj = {};
    const currentline = lines[i].split(",");
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = (currentline[j] || '').replaceAll(/\r/g, '');
    }
    result.push(obj);
  }
  return result;
}

const getDate = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

const handleTransactionType = (transactionType) => {
  if (!transactionType) return '';
  const type = {
    ManualEntry: "Refund",
    Trade: "Trade",
    Transfer: "Transfer",
    Deposit: "Deposit",
    Withdraw: "Withdraw"
  }
  return `Banexcoin ${type[transactionType] || ''}`;
}

const getColumns = (type, context) => {
  const reportType = {
    Transaction: [
      { label: context.t('Transaction Date'), value: (row) => getDate(row.PostingDatetime) },
      { label: context.t('Customer'), value: "AccountHandle" },
      { label: context.t('Asset'), value: "Product" },
      { label: context.t('Credit Amount'), value: "CR" },
      { label: context.t('Debit Amount'), value: "DR" },
      { label: context.t('Transaction Type'), value: "ReferenceTransactionType", value: (row) => context.t(handleTransactionType(row.ReferenceTransactionType)) },
    ],
    TradeActivity: [
      { label: context.t('Trade ID'), value: "TradeId" },
      { label: context.t('Transaction Time'), value: (row) => getDate(row.TransReportDatetime) },
      { label: context.t('Side'), value: (row) => context.t(row.Side) },
      { label: context.t('Size'), value: "Size" },
      { label: context.t('Pair'), value: "Instrument" },
      { label: context.t('Price'), value: "Price" },
      { label: context.t('MakerTaker'), value: "MakerTaker" },
      { label: context.t('Customer'), value: "AccountHandle" },
      { label: 'Fee', value: "Fee" }
    ]
  }
  return reportType[type] || reportType.Transaction;
};

export const createDocumentFile = function createDocumentFile(fileInfo, filename, type, context) {
  const calls = fileInfo.numSlices;
  let sliceNum = 0;

  function createAndSaveFile(res, fileName) {
    if (res.statusCode === 'Success') {
      const decodedBase64 = window.atob(res.base64Bytes);
      const list = csvJSON(decodedBase64);
      const columns = getColumns(type, context);
      const data = [
        {
          sheet: "Report",
          columns,
          content: list,
        }
      ]

      const settings = {
        fileName: fileName,
        extraLength: 3,
        writeOptions: {}
      }
      setTimeout(() => {
        xlsx(data, settings)
      }, 1);
    }
  }

  for (let i = 0; i < calls; i++) {
    apex.connection.DownloadDocumentSlice({
      descriptorId: fileInfo.descriptorId,
      sliceNum: sliceNum++
    }).then(function (res) {
      createAndSaveFile(res, filename);
    });
  }
};

export const downloadDocument = function downloadDocument(descriptorId, filename, type, context) {
  apex.connection.DownloadDocument({
    descriptorId: descriptorId
  }).then(function (res) {
    createDocumentFile(res, filename, type, context);
  });
};