import React from 'react';
// import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent.css';

// var baseClasses = getBEMClasses('instrument-row');

const KYCPolicyEnglish = (props, context) => {
  return (
    <div>
      <div
        className="banner-op"
        style={{ height: '170px', marginBottom: '50px' }}>
        <h1 className="lang" key="tarifas_comisiones">
          KYC & AML Policies
        </h1>
        <p className="subt">(Know Your Customer and Anti-Money Laundering)</p>
      </div>
      <article className="padding-terms">
        <p>
          In response to the growing concern of the international community
          about the problem of money laundering and terrorist financing, many
          countries around the world are enacting and strengthening their laws
          on the subject, adapting and endorsing the 40 recommendations issued
          by the Task Force Financial International (FATF) and Peru is no
          stranger to it.
        </p>
        <p>
          Banexcoin as a member of the Peruvian economic system, declares its
          commitment in the fight against money laundering and terrorist
          financing, having established internal policies that lead to combat
          them, in order to prevent the incorporation, within its activities, of
          allegedly linked funds to money laundering and to finance terrorism,
          safeguarding its good image and reputation, in accordance with the
          application of SBS Resolution No. 789-2018.
        </p>
        <p>
          In line with the foregoing and in compliance with current regulations,
          Banexcoin has established Customer Knowledge mechanisms - KYC (Know
          Your Customer), as well as procedures for the Prevention of Money
          Laundering - AML ( Anti-Money) Laundering), which we apply before
          starting and during the business relationship, allowing us to identify
          and establish a profile of our customers. Therefore, we require the
          information and documentation that we have detailed in our platform
          for the different levels of clients, which must necessarily be
          provided, without them they will not be able to complete the
          acceptance process as a client.
        </p>
        <p>
          In the development of the commercial relationship, as well as in the
          attention of any particular operation, Banexcoin may request
          additional information and documentation from the client, who
          undertakes to provide it, otherwise, Banexcoin reserves the right to
          maintain or not the commercial relationship with the client, in
          accordance with the provisions of our “Terms and Conditions”.
        </p>
        <p>
          It is established that Banexcoin, in compliance with the rules on
          protection of personal data, safeguards the information and / or
          documentation provided by the client, under the guidelines of due
          reservation and confidentiality.
        </p>
      </article>
    </div>
  );
};

export default KYCPolicyEnglish;
