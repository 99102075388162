import React, { useState } from "react";
import PropTypes from "prop-types";
import { DetailsConfirmation, RechargeForm, RechargeResult, TwoFaConfirmation } from "./Steps";
import "./recharge.css";
import { connect } from "react-redux";
import BanexcardController from "../../controllers/BanexcardController";
import LoadingBnx from "../../../../components/LoadingBnx";
import { Redirect } from "react-router-dom";
import { Loader } from "../../components";

function Recharge(props, context) {
  const { banexCard, userInfo } = props;
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [commission, setCommission] = useState({ amount: 0, type: "FIXED", calculated: 0 });
  const [result, setResult] = useState({ status: "success" });
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({})

  const handleSteps = {
    next: () => setStep(step => step + 1),
    previous: () => setStep(step => step - 1),
    custom: (step) => setStep(step)
  }

  const handleExecuteRecharge = async (twoFaCode) => {
    setLoading(true);
    const response = await BanexcardController.recharge({
      userInfo,
      data: {
        amount: total,
        currency: "USD",
        twoFaCode,
        calculatedFee: commission.calculated,
      },
    });
    setLoading(false);
    if (response) {
      setResult("success");
    }
    setResult("error");
    handleSteps.next();
  };
  const componentSteps = {
    1: (
      <RechargeForm
        amount={amount}
        total={total}
        setTotal={setTotal}
        setAmount={setAmount}
        commission={commission.calculated}
        setCommission={setCommission}
        handleSteps={handleSteps}
        banexCard={banexCard}
      />
    ),
    2: <DetailsConfirmation amount={amount} commission={commission.calculated} total={total} handleSteps={handleSteps} date={date} setDate={setDate}/>,
    3: <TwoFaConfirmation handleSteps={handleSteps} recharge={handleExecuteRecharge}/>,
    4: <RechargeResult amount={amount} commission={commission.calculated} total={total} type={result} handleSteps={handleSteps} date={date} banexCard={banexCard}/>
  };

  if(banexCard.loadingBanexCard) return <Loader />
  if(!banexCard.hasBanexCard) return <Redirect to="/cards" />

  return (
    <section className="banexcard-recharge-page">
      <LoadingBnx loading={loading ? 1 : 0} />
      {componentSteps[step]}
    </section>
  );
}

const mapStateToProps = state => {
  return {
    banexCard: state.banexCard || {},
    userInfo: state.user.userInfo,
  }
}
Recharge.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(Recharge);
