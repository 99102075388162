import { connect } from 'react-redux';
import { get } from 'lodash';
import FiatSidePaneComponent from './FiatSidePaneComponent';
import {
  createWithdrawTicket,
  getWithdrawFee as _getWithdrawFee
} from 'apex-web/lib/redux/actions/withdrawActions';

import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';

const mapStateToProps = function mapStateToProps(state, ownProps) {
  const productSymbol = get(ownProps, 'product.ProductSymbol', null);
  const productConfig = config.Deposit[productSymbol] || {};
  const productId = get(ownProps, 'product.ProductId', null);
  const position = positionByProductIdSelector(productId)(state);
  const _state$withdraw = state.withdraw,
    fee = _state$withdraw.withdrawFee,
    TemplateType = _state$withdraw.template.TemplateType;

  const withdrawDetailsInfo = config.Withdraw.WithdrawDetails;
  const isDepositDisabled = ownProps.isDepositDisabled,
    isWithdrawDisabled = ownProps.isWithdrawDisabled;
  
  const allproducts = state.product.products

  return {
    position: position,
    fee: fee,
    TemplateType: TemplateType,
    productConfig: productConfig,
    withdrawDetailsInfo: withdrawDetailsInfo,
    isDepositDisabled: isDepositDisabled,
    isWithdrawDisabled: isWithdrawDisabled,
    allproducts: allproducts
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    sendToAddress: function sendToAddress(payload) {
      return dispatch(createWithdrawTicket(payload));
    },
    getWithdrawFee: function getWithdrawFee(productId, amount) {
      return dispatch(_getWithdrawFee(productId, amount));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiatSidePaneComponent);
