import HandlerBackend from '../../helpers/HandlerBackend';

export const getLimitBalance = async (userId, account_id, currency_symbol) => {
  let path = '/api/user/limits';
  let data = {
    account_id,
    currency_symbol
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data
  });
};
