import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import empty from 'is-empty';
import moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { getAllWithdraws } from './WithdrawActionCalls';
import Loading from '../../components/Loading';
import './WithdrawConfirm.css';
import apex from 'apex-web/lib/apex';

const WithdrawConfirm = (props, context) => {
  let {
    userInfo,
    selectedAccountId,
    subscribeAccountEvents,
    withdrawStatus,
    cancelWithdraw,
    getWithdrawTicket,
    showSnack
  } = props;
  let pendingAmlStatus = 'Pending2Fa';
  const [loading, setLoading] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});
  const [tickets, setTickets] = useState([]);
  const [show, setShow] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [blockedReqCode, setBlockedReqCode] = useState('');

  const getAllAmlTickets = async (AccountId, UserId) => {
    setLoading(true);
    try {
      let gawt = await getAllWithdraws(pendingAmlStatus, UserId);
      if (!empty(gawt) && gawt.hasOwnProperty('data') && !empty(gawt.data)) {
        let allTickets = [];
        if (!empty(gawt.data.data)) {
          allTickets = gawt.data.data;
        }
        setTickets(allTickets);
      }
    } catch (error) {
    }
    setLoading(false);
  };

  const cancelTicket = async ticket => {
    setLoading(true);
    try {
      let getTicketData = await getWithdrawTicket({
        OMSId: 1,
        AccountId: selectedAccountId,
        RequestCode: ticket.RequestCode
      });
      if (getTicketData.Status !== 'Pending2Fa') {
        await getAllAmlTickets(selectedAccountId, userInfo.UserId);
        toast.warn(
          context.t('An error occurred canceling the ticket. please retry'),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
      } else {
        let cancel = await cancelWithdraw({
          OMSId: 1,
          UserId: userInfo.UserId,
          AccountId: selectedAccountId,
          RequestCode: ticket.RequestCode
        });
        if (cancel.result) {
          await getAllAmlTickets(selectedAccountId, userInfo.UserId);
          setTimeout(() => {
            showSnack({
              id: 'success_cancel_withdraw',
              text: context.t("Ticket successfully canceled"),
              type: 'success',
            });
          }, 1);
        } else {
          toast.warn(
            context.t('An error occurred canceling the ticket. please retry'),
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
        }
      }
    } catch (error) {
      console.log('Error on getAllAmlTickets', error);
    }
    setLoading(false);
  };

  const confirmAction = ticket => {
    showSnack({
      id: 'cancel_withdraw',
      text: context.t("Are you sure you want to cancel this withdraw ticket?"),
      type: 'pending',
      buttons: [
        {
          text: context.t('No'),
          className: 'button_V2 grey'
        },
        {
          text: context.t('Yes, I want to cancel it'),
          className: 'button_V2 purple',
          onClick: () => cancelTicket(ticket),
          loadingText: true
        }
      ]
    });
  };

  const subcribeAccountsChanges = async () => {
    let subs = await subscribeAccountEvents({
      AccountId: selectedAccountId,
      OMSId: 1
    });
    setSubscription(subs.Subscribed);
  };

  useEffect(
    () => {
      if (!empty(selectedAccountId) && !subscription) {
        subcribeAccountsChanges();
      }
    },
    [withdrawStatus]
  );

  useEffect(
    () => {
      if (!empty(selectedAccountId) && !empty(userInfo) && !loading) {
        getAllAmlTickets(selectedAccountId, userInfo.UserId);
      }
    },
    [selectedAccountId, withdrawStatus]
  );
 let timer = null;
  const withdrawEventUpdate = () => {
    apex.connection.ws
      .filter(x => x.n === 'WithdrawTicketUpdateEvent')
      .subscribe(response => {
        clearInterval(timer);
        timer = setInterval(() => {
          getAllAmlTickets(selectedAccountId, userInfo.UserId);
          clearInterval(timer);
        }
        , 2000);
      });
  };

  useEffect(
    () => {
      if(empty(userInfo)) return
      withdrawEventUpdate();
    },
    [userInfo]
  );

  if (empty(tickets) || empty(selectedAccountId)) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className="v2-container-top">
        <div className="pending-notification-bar">
          <h2>
            {context.t(
              'You have one or more pending withdrawal requests, you must confirm it by entering the email received in your mailbox.'
            )}
          </h2>
          <Table striped hover responsive className="bnx-v2-table">
            <thead>
              <tr>
                <th />
                <th>{context.t('Created')} (DD-MM-YYYY)</th>
                <th>{context.t('Quantity')}</th>
                <th>{context.t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((t, index) => {
                let depositInfo = {};
                try {
                  depositInfo = !empty(t.DepositInfo)
                    ? JSON.parse(t.DepositInfo)
                    : {};
                } catch (error) {
                  console.log(
                    'error on parse JSON with deposit ticket',
                    t.RequestCode
                  );
                }
                let utcDate = moment(t.LastUpdateTimestamp);
                return (
                  <tr key={index}>
                    <td>
                      <span className="title">
                        {context.t('Withdrew {a}', { a: t.AssetName })}
                      </span>
                    </td>
                    <td>
                      <b>{utcDate.format('DD-MM-YYYY HH:mm:ss').toString()}</b>
                    </td>
                    <td className="quantity">
                      {t.Amount} {t.AssetName}
                    </td>
                    <td
                      className={
                        'action' +
                        (t.RequestCode === blockedReqCode ? ' disabled' : '')
                      }>
                      <Button
                        variant="bnx error"
                        onClick={() => confirmAction(t)}>
                        {context.t('Cancel Ticket')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

WithdrawConfirm.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    subscribeAccountEvents: payload =>
      dispatch(callAPI('SubscribeAccountEvents', payload)).then(res => {
        return res;
      }),
    cancelWithdraw: payload =>
      dispatch(callAPI('CancelWithdraw', payload)).then(res => {
        return res;
      }),
    getWithdrawTicket: payload =>
      dispatch(callAPI('GetWithdrawTicket', payload)).then(res => {
        return res;
      }),
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

const mapStateToProps = state => {
  let {
    user: { selectedAccountId, userInfo },
    user,
    position: { positions, fetching },
    withdraw: { withdrawStatus }
  } = state;
  return {
    selectedAccountId,
    userInfo,
    user,
    positions,
    fetching,
    withdrawStatus
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawConfirm);
