import React from "react";
import PropTypes from "prop-types";
import APButton from "apex-web/lib/components/common/APButton";
import APNumberInput from "apex-web/lib/components/common/APNumberInput";
import APSelect from "apex-web/lib/components/common/APSelect";
import APLabelWithText from "../../../components/common/APLabelWithText/APLabelWithText";
import UserBalanceComponent from "apex-web/lib/components/UserBalance/UserBalanceComponent";
import MarketPriceComponent from "../AdvancedOrderMarketPriceComponent";
import { instrumentPropType } from "apex-web/lib/propTypes/instrumentPropTypes";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import {
  getOrderFee,
  getOrderLeverage,
  getOrderBorrowingPower,
  getSubmitButtonText,
} from "apex-web/lib/helpers/orderHelper";
import { styleNames } from "apex-web/lib/propTypes/commonComponent";
import {
  advancedOrderFormTypes,
  buyValue,
  sellValue,
} from "apex-web/lib/constants/sendOrder/orderEntryFormConstants";
import { pegPriceTypesNames } from "apex-web/lib/constants/sendOrder/pegPriceTypeConstants";
import {
  isTrailingAmountFieldInForm,
  isLimitOffsetFieldInForm,
  isPegPriceFieldInForm,
  isLimitPriceFieldInForm,
  isStopPriceFieldInForm,
  isDisplayQuantityFieldInForm,
  isTimeInForceFieldInForm,
} from "apex-web/lib/helpers/placeOrderHelper";
import {
  biggerThanZero,
  biggerThanOrEqualToZero,
  requiredNumeric,
} from "apex-web/lib/helpers/formValidations";
import { convertIncrementToIntDecimalPlaces } from "apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper";
import { formatNumberWithDecimalsWithCOP } from "../../../pages/utils";
import "./AdvancedOrderForm.css";
import isEmpty from "is-empty";

var bemClasses = getBEMClasses("advanced-order-form");

var AdvancedOrderForm = function AdvancedOrderForm(props, context) {
  var marketPrice = props.marketPrice,
    handleSubmit = props.handleSubmit,
    instruments = props.instruments,
    submitting = props.submitting,
    formObj = props.formObj,
    positions = props.positions,
    position1 = props.position1,
    position2 = props.position2,
    clearAdvancedOrderEntryForm = props.clearAdvancedOrderEntryForm,
    selectedInstrument = props.selectedInstrument,
    _props$selectedInstru = props.selectedInstrument,
    Product1Symbol = _props$selectedInstru.Product1Symbol,
    Product2Symbol = _props$selectedInstru.Product2Symbol,
    PriceIncrement = _props$selectedInstru.PriceIncrement,
    QuantityIncrement = _props$selectedInstru.QuantityIncrement,
    disableTrading = props.disableTrading,
    invalid = props.invalid,
    VerificationRequiredComponent = props.VerificationRequiredComponent,
    hideFees = props.hideFees,
    orderTotal = props.orderTotal,
    orderNet = props.orderNet,
    isMarginActive = props.isMarginActive,
    marginProducts = props.marginProducts,
    blockedActions = props.blockedActions,
    UserName = props.user.userInfo.UserName;

  let users = [
    "andrewvergel",
    "lc",
    "msrangelh",
    "thespeed94",
    "mjr",
    "banexcoin-usdcusd",
  ];
  var authProduct = users.includes(UserName);
  let newInstruments = instruments.filter(
    (i) =>
      i.Symbol == "BTCPEN" ||
      i.Symbol == "BTCUSD" ||
      i.Symbol == "BTCUSDC" ||
      (authProduct ? i.Symbol === "USDCUSD" : "")
  );
  const availableAmountValue = (band = false) => {
    let p1 = position1
    let p2 = position2
    if(!isEmpty(formObj) && !isEmpty(formObj.values) && formObj.values.side !== buyValue){
      p1 = position2
      p2 = position1
    }
    if(band){
      return `${p2.Amount} ${p2.ProductSymbol}`
    }
    return`${p1.Amount} ${p1.ProductSymbol}`
  }
  return (
    <form
      className={bemClasses("body")}
      onSubmit={blockedActions ? null : handleSubmit}
    >
      <div className={bemClasses("main-section")}>
        <div>
          <div className={bemClasses()}>
            <APSelect
              name="instrument"
              label={context.t("Instrument")}
              customClass={bemClasses()}
              options={newInstruments.map((instrument) => {
                var InstrumentId = instrument.InstrumentId;
                return { value: InstrumentId, label: instrument.Symbol };
              })}
              showTriangles={true}
            />
          </div>
          <div>
            <APSelect
              name="orderType"
              label={context.t("Order Type")}
              customClass={bemClasses()}
              onChange={clearAdvancedOrderEntryForm}
              options={Object.keys(advancedOrderFormTypes).map((key) => {
                var displayName = advancedOrderFormTypes[key].displayName;
                return { value: displayName, label: displayName };
              })}
              showTriangles={true}
            />
          </div>
        </div>
      </div>
      {renderQuantityField(props, context)}
      <div style={{ width:"100%" }}>
        <p className='available-amount-text'>{context.t("Available amount")}: {availableAmountValue()}</p>
        <p className='available-amount-text'>{context.t("Available amount")}: {availableAmountValue(true)}</p>
      </div>
      {isLimitPriceFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APNumberInput
            name="limitPrice"
            placeholder="0"
            decimalPlaces={convertIncrementToIntDecimalPlaces(PriceIncrement)}
            label={context.t("Limit Price")}
            customClass={bemClasses()}
            labelInInput={Product2Symbol}
            validate={[biggerThanZero]}
          />
        </div>
      )}
      {isStopPriceFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APNumberInput
            name="stopPrice"
            placeholder="0"
            decimalPlaces={convertIncrementToIntDecimalPlaces(PriceIncrement)}
            label={context.t("Stop Price")}
            customClass={bemClasses()}
            labelInInput={Product2Symbol}
            validate={[biggerThanZero]}
          />
        </div>
      )}
      {isTrailingAmountFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APNumberInput
            name="trailingAmount"
            placeholder="0"
            decimalPlaces={convertIncrementToIntDecimalPlaces(PriceIncrement)}
            label={context.t("Trailing Amount")}
            customClass={bemClasses()}
            labelInInput={Product2Symbol}
            validate={[biggerThanZero]}
          />
        </div>
      )}
      {isLimitOffsetFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APNumberInput
            name="limitOffset"
            placeholder="0"
            decimalPlaces={convertIncrementToIntDecimalPlaces(PriceIncrement)}
            label={context.t("Limit Offset")}
            customClass={bemClasses()}
            labelInInput={Product2Symbol}
            validate={[biggerThanZero]}
          />
        </div>
      )}
      {isPegPriceFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APSelect
            name="pegPriceType"
            label={context.t("Peg Price")}
            customClass={bemClasses()}
            classModifiers="short"
            options={Object.keys(pegPriceTypesNames).map((key) => {
              return {
                value: key,
                label: pegPriceTypesNames[key],
              };
            })}
          />
        </div>
      )}
      {isDisplayQuantityFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APNumberInput
            name="displayQuantity"
            placeholder="0"
            decimalPlaces={convertIncrementToIntDecimalPlaces(
              QuantityIncrement
            )}
            label={context.t("Display Quantity")}
            customClass={bemClasses()}
            labelInInput={Product1Symbol}
            validate={[requiredNumeric, biggerThanOrEqualToZero]}
          />
        </div>
      )}
      {isTimeInForceFieldInForm(formObj.values.orderType) && (
        <div className={bemClasses()}>
          <APSelect
            name="timeInForce"
            label={context.t("Time in Force")}
            customClass={bemClasses()}
            options={[
              { value: 1, label: context.t("Good Til Canceled") },
              { value: 3, label: context.t("Immediate or Cancel") },
              { value: 4, label: context.t("Fill or Kill") },
            ]}
          />
        </div>
      )}
      {isMarginActive && (
        <>
          <APLabelWithText
            name="borrowingPower"
            label={context.t("Borrowing Power")}
            text={
              (formObj.values.side === sellValue
                ? Product1Symbol
                : Product2Symbol) +
              " " +
              getOrderBorrowingPower(
                formObj,
                selectedInstrument,
                marginProducts
              )
            }
            customClass={bemClasses()}
          />
          <APLabelWithText
            name="leverage"
            label={context.t("Leverage")}
            text={getOrderLeverage(formObj)}
            customClass={bemClasses()}
          />
        </>
      )}
      {!hideFees && (
        <APLabelWithText
          label={context.t("Fees") + ":"}
          text={formatNumberWithDecimalsWithCOP(
            getOrderFee(formObj, positions)
          )}
          customClass={`${bemClasses()}`}
          otherClassName={"details"}
        />
      )}
      <APLabelWithText
        label={context.t("Order Total") + ":"}
        text={formatNumberWithDecimalsWithCOP(orderTotal)}
        customClass={`${bemClasses()}`}
        otherClassName={"details"}
      />
      <APLabelWithText
        label={context.t("Received") + ":"}
        text={orderNet}
        customClass={`${bemClasses()}`}
        otherClassName={"details"}
      />
      <APButton
        type={blockedActions ? "button" : "submit"}
        disabled={submitting || disableTrading || invalid || blockedActions}
        customClass={bemClasses()}
        styleName={
          formObj.values.side === sellValue
            ? styleNames.subtractive
            : styleNames.additive
        }
      >
        {submitting
          ? context.t("Processing...")
          : context.t(getSubmitButtonText(formObj))}
      </APButton>
      <div className={bemClasses("verification-required")}>
        {VerificationRequiredComponent}
      </div>
    </form>
  );
};

var renderQuantityField = function renderQuantityField(props, context) {
  var _props$selectedInstru2 = props.selectedInstrument,
    Product1Symbol = _props$selectedInstru2.Product1Symbol,
    QuantityIncrement = _props$selectedInstru2.QuantityIncrement;

  return React.createElement(
    "div",
    { className: bemClasses() },
    React.createElement(APNumberInput, {
      name: "quantity",
      placeholder: "0",
      label: context.t("Order Size"),
      decimalPlaces: convertIncrementToIntDecimalPlaces(QuantityIncrement),
      customClass: bemClasses(),
      labelInInput: Product1Symbol,
      validate: [biggerThanZero],
    })
  );
};

AdvancedOrderForm.propTypes = {
  positions: PropTypes.object.isRequired,
  position1: PropTypes.object.isRequired,
  position2: PropTypes.object.isRequired,
  decimalPlaces: PropTypes.object.isRequired,
  selectedInstrument: PropTypes.object.isRequired,
  marketPrice: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  instruments: PropTypes.arrayOf(instrumentPropType).isRequired,
  formObj: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  clearAdvancedOrderEntryForm: PropTypes.func.isRequired,
  synchronizeQuantityWithPrice: PropTypes.func.isRequired,
  synchronizePriceWithQuantity: PropTypes.func.isRequired,
  disableTrading: PropTypes.bool,
  VerificationRequiredComponent: PropTypes.element,
};

AdvancedOrderForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AdvancedOrderForm;
