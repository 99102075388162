import React, { useState } from "react";
import PropTypes from "prop-types";
import StandAloneLayout from "apex-web/lib/layouts/StandAloneLayout/StandAloneLayout";
import "apex-web/lib/styles/components/common/StandaloneForm.css";
import "./ResetPasswordFormComponent.css";
import InputElement from "../../pages/bnxv2/Components/InputElement/InputElement";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import lock from "../../images/lock.svg";
import ErrorCircle from "../../images/bnx-icons/error-circle.svg";
import SuccessCircle from "../../images/bnx-icons/success-circle.svg";
import isEmpty from "is-empty";
import { PASSWORD_VALIDATION_LIST, REGEX_LIST } from "../../constants";
import { Link } from "react-router-dom";
function ResetPasswordFormComponent(props, context) {
  const { message, fetchError,processingResetPassword } = props;
  const [showPasswordHelp, setShowPasswordHelp] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState({
    caracter: false,
    number: false,
    capitalLetter: false,
    lowerLetter: false,
    symbol: false,
  });
  const handlePasswordValidator = (value) => {
    setPasswordValidator({
      caracter: REGEX_LIST.regexToValidateCharacters.test(value),
      number: REGEX_LIST.regexToValidateNumber.test(value),
      capitalLetter: REGEX_LIST.regexToValidateCapitalLetter.test(value),
      lowerLetter: REGEX_LIST.regexToValidateLowerLetter.test(value),
      symbol: REGEX_LIST.regexToValidateSymbol.test(value),
    });
  };
  const [showPassword, setShowPassword] = useState({
    password: false,
    matchingPassword: false,
  });
  const [passwords, setPasswords] = useState({
    password: "",
    matchingPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleChangePasswords = (ev) => {
    setPasswords({ ...passwords, [ev.target.name]: ev.target.value });
    delete errors[ev.target.name];
  };

  const handleValidations = () => {
    let errors = {};
    if (!isEmpty(passwords.password)) {
      if (passwords.password.length < 8) {
        errors.password = "Must contain at least 8 characters";
      } else {
        const validatePassword = REGEX_LIST.regexToValidatePassword.test(
          passwords.password
        );
        if (!validatePassword) {
          errors.password =
            "Your password must contain at least one capital letter, numbers and a symbol";
        }
      }
    }
    if (!isEmpty(passwords.matchingPassword)) {
      if (passwords.password !== passwords.matchingPassword) {
        errors.matchingPassword = "Passwords are not equals";
      }
    }
    setErrors(errors);
    return errors;
  };

  const handleResetPassword = async (ev) => {
    ev.preventDefault();
    const errors = handleValidations();
    if (!isEmpty(errors)) return;
    props.onSubmit(passwords, props.dispatch);
  };

  const passwordInputProps = {
    values: {
      title: context.t("Password"),
      value: passwords.password,
      name: "password",
      type: showPassword.password ? "text" : "password",
      autocomplete: "new-password",
      rightIcon: showPassword.password ? eye : eyeSlash,
      icon: lock,
    },
    actions: {
      onChange: (ev) => {
        handleChangePasswords(ev);
        handlePasswordValidator(ev.target.value);
      },
      onClick: () => setShowPasswordHelp(true),
      onBlur: () => setShowPasswordHelp(false),
      onChangeSecondary: () =>
        setShowPassword({ ...showPassword, password: !showPassword.password }),
    },
    error: errors.password,
  };

  const confirmPasswordInputProps = {
    values: {
      title: context.t("Confirm your Password"),
      value: passwords.matchingPassword,
      name: "matchingPassword",
      type: showPassword.matchingPassword ? "text" : "password",
      autocomplete: "new-password",
      rightIcon: showPassword.matchingPassword ? eye : eyeSlash,
      icon: lock,
    },
    actions: {
      onChange: handleChangePasswords,
      onClick: () => setShowPasswordHelp(true),
      onBlur: () => setShowPasswordHelp(false),
      onChangeSecondary: () =>
        setShowPassword({
          ...showPassword,
          matchingPassword: !showPassword.matchingPassword,
        }),
    },
    error: errors.matchingPassword,
  };

  return (
    <StandAloneLayout>
      <a className="layout-auth-logo" href="https://banexcoin.com">
        <img
          src="/static/media/logo-banexcoin-white-middleorange.8998e8f7.svg"
          alt="Logo"
        />
      </a>
      {!message || message.includes("same") ? (
        <div className="reset-password-container">
          <p className="reset-password-container-title">
            {context.t("Reset your password")}
          </p>
          <form onSubmit={handleResetPassword}>
            <div style={{ position: "relative" }}>
              <div>
                <InputElement {...passwordInputProps} />
              </div>
              <div
                className={`app-modal-help-text ${
                  showPasswordHelp ? "show" : ""
                }`}
              >
                {PASSWORD_VALIDATION_LIST.map(({ text, type }, key) => (
                  <div className="itm-option-v2" key={key}>
                    <img
                      src={
                        passwordValidator[type] ? SuccessCircle : ErrorCircle
                      }
                      alt="iconStatus"
                      width={20}
                    />
                    <p>{context.t(text)}</p>
                  </div>
                ))}
              </div>
            </div>
            <InputElement {...confirmPasswordInputProps} />
            <button className="default-primary-button" type="submit" disabled={processingResetPassword}>
              {context.t("Reset your password")}
            </button>
          </form>
        </div>
      ) : (
        <div className="reset-password-container">
          <p className="reset-password-container-title message">{context.t(message)}</p>
          {fetchError ? (
            <Link className="default-primary-button" to="/problem-logging-in">
              {context.t('Problem resetting your password?')}
            </Link>
          ) : (
            <Link className="default-primary-button" to="/login">{context.t("Go to Exchange")}</Link>
          )}
        </div>
      )}
    </StandAloneLayout>
  );
}
ResetPasswordFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  processingResetPassword: false,
  message: "",
  fetchError: false,
};
ResetPasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  processingResetPassword: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool,
};
ResetPasswordFormComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default ResetPasswordFormComponent;
