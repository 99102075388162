import BanexcardService from "../services/BanexcardService";

class BanexcardController {
  static getUserBalance = async ({ userInfo }) => {
    try {
      const response = await BanexcardService.getBanexcardBalance({ userInfo });
      if(response.message === "success"){
        return response.data
      }
      return {}
    } catch (error) {
      return {}
    }
  };
  static getBanexcardTransactions = async ({ userInfo }) => {
    try {
      const response = await BanexcardService.getBanexcardTransactions({ userInfo, limit: 10 });
      if(response.message === "success"){
        return response.data
      }
      return []
    } catch (error) {
      return []
    }
  };
  static getBanexcardConfiguration = async ({ userInfo }) => {
    try {
      const response = await BanexcardService.getBanexcardConfiguration({ userInfo });
      if(response.message === "success"){
        return response.data
      }
      return []
    } catch (error) {
      return []
    }
  };
  static getPhysicalCardLocations = async ({ userInfo }) => {
    try {
      const response = await BanexcardService.getPhysicalCardLocations({ userInfo });
      if(response.message === "success"){
        return response.data
      }
      return []
    } catch (error) {
      return []
    }
  };
  static getRequestPhysicalCardById = async ({ userInfo, requestId }) => {
    try {
      const response = await BanexcardService.getRequestPhysicalCardById({ userInfo, requestId });
      if(response.message === "success"){
        return response.data
    }
      return {}
    } catch (error) {
      return {}
      //TODO: ONLY FOR TESTING
    }
  };
  static setBanexcardConfiguration = async ({ userInfo, channelCode, enabled }) => {
    try {
      const response = await BanexcardService.postBanexcardConfiguration({ userInfo, body: { channelCode, enabled } });
      if(response.message === "success"){
        return true
      }
      return false
    } catch (error) {
      return false
    }
  };
  static setPinConfiguration = async ({ userInfo, pin }) => {
    try {
      const response = await BanexcardService.postPinConfiguration({ userInfo, body: { pin } });
      if(response.message === "success"){
        return true
      }
      return false
    } catch (error) {
      //TODO: ONLY FOR TESTING
      return false
    }
  };
  static setActivationCodeToAssociation = async ({ userInfo, activationCode }) => {
    try {
      const response = await BanexcardService.postActivationCodeToAssociation({ userInfo, body: { activationCode } });
      if(response.message === "success"){
        return true
      }
      return false
    } catch (error) {
      //TODO: ONLY FOR TESTING
      return false
    }
  };
  static requestPhysicalCard = async ({ userInfo, data, deliveryAreas }) => {
    const body = {
      country:  null,
      coord: null,
      city: null,
      state: null,
      town: null,
      zipCode: null,
      apt: null,
      building: null,
      district: null,
      reference: null,
      mainAddress: null,
    }
    if(data.hasOwnProperty("id")){
      body.country = data.residenceCountry
      body.city = data.city
      body.state = data.state
      body.town = data.town
      body.zipCode = data.zipCode
      body.apt = data.apt
      body.building = data.building
      body.district = data.district
      body.mainAddress = data.addressMain
    }else if(data.hasOwnProperty("urbanization")){
      body.country = data.country
      body.city = data.city
      body.state = data.state
      body.town = data.urbanization
      body.zipCode = data.zipCode
      body.building = data.building
      body.mainAddress = data.street
      body.apt = data.apt
      body.district = data.district
    }else{
      const area = deliveryAreas.find(area => data.address.includes(area.address))
      if(!area) return false
      body.mainAddress = area.address
      body.city = area.province
      body.country = data.country
      body.state = area.department
      body.district = area.district
      body.zipCode = area.postalCode
    }
    try {
      const response = await BanexcardService.postRequestPhysicalCard({ userInfo, body });
      if(response.message === "success"){
        return true
      }
      return false
    } catch (error) {
      return false
    }
  };
  static calculateFee = async ({ userInfo, amount, currency}) => {
    try {
      const response = await BanexcardService.getCalculateFee({ userInfo, data: {amount, currency} });
      if(response.message === "success"){
        return response.data
      }
      return {}
    } catch (error) {
      return {}
    }
  }
  static recharge = async ({ userInfo, data}) => {
    try {
      const response = await BanexcardService.postRechargeCard({ userInfo, body: data });
      if(response.message === "success"){
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }
}

export default BanexcardController;
