import { connect } from 'react-redux';
import KYC_IMFormComponent from './KYC_IMFormComponent';
import { getFormValues, reset as _reset } from 'redux-form';
import config from '../../../config';
import { validateUserRegistrationIdentityMind as _validateUserRegistrationIdentityMind } from 'apex-web/lib/redux/actions/kycIdentityMindActions';
import {
  getMissingMandatoryFields,
  updateLevelFields,
  getAdditionalValidationFields
} from './KYC_IMFormHelpers';

var mapStateToProps = function mapStateToProps(state) {
  var _state$user = state.user,
    accountId = _state$user.selectedAccountId,
    _state$user$userInfo = _state$user.userInfo,
    UserId = _state$user$userInfo.UserId,
    email = _state$user$userInfo.Email,
    UserName = _state$user$userInfo.UserName;
  var _config$KYC = config.KYC,
    MinimumVerificationLevel = _config$KYC.MinimumVerificationLevel,
    useAccountId = _config$KYC.useAccountId,
    levels = _config$KYC.levels;

  var level = levels[0];
  var missingFields = getMissingMandatoryFields(levels);
  var uploadFields = getAdditionalValidationFields(level);
  updateLevelFields(level);

  return {
    accountId: accountId,
    UserId: UserId,
    UserName: UserName,
    email: email,
    useAccountId: useAccountId,
    missingFields: missingFields,
    level: level,
    MinimumVerificationLevel: MinimumVerificationLevel,
    uploadFields: uploadFields,
    formValues: getFormValues('kycIMForm')(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    validateUserRegistrationIdentityMind: function validateUserRegistrationIdentityMind(
      payload
    ) {
      return dispatch(_validateUserRegistrationIdentityMind(payload));
    },
    reset: function reset() {
      return dispatch(_reset('kycIMForm'));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYC_IMFormComponent);
