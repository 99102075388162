import React, { useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import empty from 'is-empty';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Collapse, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useForm } from '../../../../hooks/formHooks';
import coinkLogo from '../../../../images/coink-imgs/mini-iso-coink.svg'
import {
  createDepositByApi,
  getV2FiatAccounts,
  getBanexcoinAccountsV2
} from './ManualDepositWorkflowComponentPageHooks';
import Loading from '../../../Loading';
import DataDepositBank from '../../../DataDepositBank';
import { jsonBankNew, allBanksInfo } from './../../../BanksInfo';
import {
  FormField,
  FormSelect,
  FormUploadFile,
  FormSelector,
  FormDate,
  FormSingleCheckbox,
  FormButtonV2
} from '../../../common/BnxFormsComponents';
import { authorizedLevel } from './../../../../helpers/lib';
import './ManualDepositWorkflowComponent.css';
import DepositAlertsController from '../../DepositAlertsController';
import isEmpty from 'is-empty';
import DepositAlertsServices from '../../DepositAlertsServices';
import CoinkTransactionsController from '../../../CoinkTransactionsController';
import { Spinner } from '../../../Spinner/Spinner';
import { alertsContentCoink } from '../../../../constants/alertsContentCoink';
import { useHistory, withRouter } from 'react-router-dom';
import InputElement from '../../../../pages/bnxv2/Components/InputElement/InputElement';
import { formatCopCurrency, formattedCoinkAmountWithoutDots } from '../../../../pages/utils';
import { showSnack as _showSnack } from "apex-web/lib/redux/actions/snackbarActions";
import { createStateSnackbar as _createStateSnackbar, resetStateSnackbar as _resetStateSnackbar } from '../../../../redux/actions/set_snackbar'
import { error } from 'shelljs';

const manualDepositWorkflowComponentClasses = getBEMClasses(
  'manual-deposit-workflow-component'
);
const usdMin = 10;
const penMin = 45;

const ManualDepositWorkflowComponent = (props, context) => {
    const {
      userInfo,
      product,
      selectedAccountId,
      accounts,
      setBankDetails,
      verificationLevelConfigs,
      blockedActions,
      showSnack,
      createStateSnackbar,
      resetStateSnackbar,
      limitsByProductAndLevel
    } = props;

      if (empty(userInfo) || empty(accounts)) return <div>Loading...</div>

    const currentAccount = accounts.find(element => element.AccountId === selectedAccountId);

    const [loading, setLoading] = useState(0);
    const [fiatAccounts, setFiatAccounts] = useState([]);
    const [banexcoinAccounts, setBanexcoinAccounts] = useState([]);
    const [idBankDb, setIdBankDb] = useState(null);
    const [idOriginBank, setIdOriginBank] = useState(null);
    const [openBank, setOpenBank] = useState(false);
    const [openSteps, setOpenSteps] = useState({
      step1: true,
      step2: false,
      step3: false
    });
    const [bankName, setBankName] = useState('');
    const [bankNameUser, setBankNameUser] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [interbankNews, setInterbankNews] = useState(false);
    const [originAccounts, setOriginAccounts] = useState([]);
    const [tooltipBank, setTooltipBank] = useState('');
    const [bankInformation, setBankInformation] = useState([]);
    const lang = localStorage.getItem("language") || 'en';
    const [needStatementsOfFunds,setNeedStatementsOfFunds] = useState(false)
    const [generateAlert,setGenerateAlert] = useState(false)
    const [loadingValidationStatementOfFunds,setLoadingValidationStatementOfFunds] = useState(false)
    const isCoink = verificationLevelConfigs.Level >= 100
    const [coinkAmount, setCoinkAmount] = useState("")
    const [maximumAvailableCoink, setMaximumAvailableCoink] = useState(0)
    const history = useHistory()
      
    async function loadFiatAccounts() {
        setLoading(1);
        try {
          const result = await getV2FiatAccounts(
            product.Product,
            userInfo.UserId,
            selectedAccountId
          );
          if (
            !empty(result) &&
            result.hasOwnProperty('data') &&
            result.data.fiataccounts
          ) {
            setFiatAccounts(result.data.fiataccounts);
          }
          return true
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
          return false
        }finally{
          setLoading(0);
        }
      }


  async function loadBanexcoinAccountsV2() {
    setLoading(1);
    try {
      const result = await getBanexcoinAccountsV2(
        product.Product,
        userInfo.UserId
      );
      setBanexcoinAccounts(result);
      return true;
    } catch (error) {
      toast.warn(context.t('Information could not accesible'), {
        position: toast.POSITION.TOP_CENTER
      });
      return false;
    }finally{
      setLoading(0);
    }
  }

    async function loadFiatInfoFrom() {
      fiatAccounts.forEach((account) => {
        if (account.id === inputs.account_source) {
          inputs.account_source = account.accountNumber;
          inputs.bank_name_source = account.swiftBankBySwiftBankId.bankName;
          inputs.bank_country_source = account.swiftBankBySwiftBankId.countryByCountryCode.countryName;
        }
      })
      return true;
    }

    useEffect(() => {
      if (!empty(userInfo) && !isCoink) {
        loadFiatAccounts();
        loadBanexcoinAccountsV2();
      }
    }, [userInfo]);

    useEffect(() => {
        if (!empty(inputs.account_source)) {
          loadFiatInfoFrom();
        }
      }, [inputs]);
    const onSubmitCallback = async inputs => {
      if(openSteps.step2){
        const needStatementOfFunds = await validationIfNeedStatementofFunds()
        if(needStatementOfFunds && empty(inputs.affidavit_source_funds)) return
      }
      const cerrar = document.getElementsByClassName('ap-sidepane__close-button');
      try {
        const inputErrors = customValidations(inputs);
        if (empty(inputs.proof_payment)) {
          confirmAlert({
            title: context.t('Warning'),
            message: context.t(
              'For your security, we need the proof of deposit (screenshot, photo) issued by your bank.'
            ),
            buttons: []
          });
        } else {
          if (!empty(inputErrors)) return
          if (openSteps.step3) {
            setLoading(1);
            const result = await createDepositByApi(inputs, selectedAccountId);
            if (empty(result) || !result.hasOwnProperty('data') || result.data.message !== 'success') {
              toast.warn(context.t('Information could not be saved'), {
                position: toast.POSITION.TOP_CENTER
              });
              return
            }
            const response = await DepositAlertsServices.getDepositsByTransactionNumber(userInfo, inputs.transaction_number)
            if(isEmpty(response.data.result)){
              cerrar && cerrar[0].click();
              toast.warn(context.t('The ticket could not be generated. Please try again.'), {
                position: toast.POSITION.TOP_CENTER
              });
              return
            }
            if(generateAlert && !isEmpty(accounts) && !isEmpty(response.data) && !isEmpty(response.data.result)){
              const response = await DepositAlertsController.limitValidationCreatorLog({userInfo,accountLevel:accounts[0].VerificationLevel})
              if(response){
                cerrar && cerrar[0].click();
                showSnack({
                  id: 'generated_successfully',
                  text: context.t('The deposit ticket was generated successfully.'),
                  type: 'success',
                });
              }
              return 
            }
            cerrar && cerrar[0].click();
            showSnack({
              id: 'generated_successfully',
              text: context.t('The deposit ticket was generated successfully.'),
              type: 'success',
            });
          } else {
            setOpenSteps({
              ...openSteps,
              step1: false,
              step2: false,
              step3: true
            });
          }
        }
      } catch (error) {
        if (cerrar) {
          cerrar[0].click();
        }
        toast.warn(context.t(error.response.data.data.error_message), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const amountValidation = (value) => {
      let err = {};
      if (!empty(value) && !isNaN(Number(value))) {
        if (product.Product === 'USD') {
          if (Number(value) < usdMin) {
            err.amount = context.t(
              'The amount must be greater or equal than {value}',
              { value: usdMin }
            );
          }
        } else if (product.Product === 'PEN') {
          if (Number(value) < penMin) {
            err.amount = context.t(
              'The amount must be greater or equal than {value}',
              { value: penMin }
            );
          }
        }
      } else {
        err.amount = context.t('Required field');
      }
      if(isEmpty(err)){
        delete errors.amount
      }else{
        setErrors(errors => ({...errors, ...err }))
      }
    }

    const customValidations = inputs => {
      let errors = {};
      if (empty(idBankDb)) {
        errors.account_destination = context.t('Required field');
      }
      if (empty(idOriginBank)) {
        errors.account_source = context.t('Required field');
      }
      if (empty(inputs.transaction_number)) {
        errors.transaction_number = context.t('Required field');
      }
      if (empty(inputs.transaction_date)) {
        errors.transaction_date = context.t('Required field');
      } else {
        var m = moment(inputs.transaction_date, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.transaction_date = context.t('Invalid date');
        }
      }
      if (empty(inputs.proof_payment)) {
        errors.proof_payment = context.t('Required field');
      }
      if (!empty(inputs.amount) && !isNaN(Number(inputs.amount))) {
        if (product.Product === 'USD') {
          if (Number(inputs.amount) < usdMin) {
            errors.amount = context.t(
              'The amount must be greater or equal than {value}',
              { value: usdMin }
            );
          }
        } else if (product.Product === 'PEN') {
          if (Number(inputs.amount) < penMin) {
            errors.amount = context.t(
              'The amount must be greater or equal than {value}',
              { value: penMin }
            );
          }
        }
      } else {
        errors.amount = context.t('Required field');
      }
      if (empty(inputs.affidavit_source_funds) && needStatementsOfFunds) {
        errors.affidavit_source_funds = context.t('Required field');
      } else {
        if (
          inputs.affidavit_source_funds == 'Others (Detail)' ||
          inputs.affidavit_source_funds == 'Otros (Detallar)'
        ) {
          if (empty(inputs.affidavit_source_funds_others)) {
            errors.affidavit_source_funds_others = context.t(
              'You must enter the details'
            );
          } else {
            if (empty(inputs.affidavit_source_funds_others.trim())) {
              errors.affidavit_source_funds_others = context.t(
                'You must enter the details'
              );
            }
          }
        }
      }

      if (empty(inputs.proof_payment)) {
        errors.proof_payment = context.t('Required field');
      } else {
        const filePath = inputs.proof_payment.name;
        const extension = /[.]/.exec(filePath)
          ? /[^.]+$/.exec(filePath)
          : undefined;

        const acceptedFiles = ['jpeg', 'jpg', 'png', 'pdf'];
        if (!acceptedFiles.includes(String(extension[0]).toLowerCase())) {
          errors.proof_payment = context.t('You can only attach image or pdf files only');
        }
      }

      setErrors({
        ...errors
      });
      return errors;
    };

    const getBankingData = (e, data) => {
      setIdBankDb(e.target.value);
      const newData = {
        accountDetails: (!empty(data.accountDetails) && data.accountDetails.hasOwnProperty(lang)) ? data.accountDetails[lang] : [],
        accountRulesInstructions: (!empty(data.accountRulesInstructions) && data.accountRulesInstructions.hasOwnProperty(lang)) ? data.accountRulesInstructions[lang] : []
      }
      setBankDetails(newData);
      setBankInformation(newData.accountDetails);
      return true;
    };

    const destinationChange = e => {
      e.persist();
      if (e.target.value) {
        const selectedAcount = banexcoinAccounts.filter(
          account => account.id === e.target.value
        );
        let selected = selectedAcount[0];
        setInputs(inputs => ({
          ...inputs,
          account_destination_id: e.target.value,
          account_destination: selected.accountNumber,
          bank_name_destination: selected.swiftBankBySwiftBankId.bankName,
          bank_country_destination: selected.swiftBankBySwiftBankId.city
        }));
      } else {
        setInputs(inputs => ({
          ...inputs,
          account_destination_id: '',
          account_destination: '',
          bank_name_destination: '',
          bank_country_destination: ''
        }));
      }
    };

    const initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : '',
      assetId: product.ProductId,
      assetName: product.Product,
      AccountId: userInfo.AccountId
    };

    let {
      inputs,
      errors,
      setErrors,
      onInputChange,
      onSubmitSimple,
      onInputFileChangeImages,
      onCheckboxChangeValidation,
      onInputChangeWithCallback,
      setInputs,
      onDefaultChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    const onFiatAccountChange = e => {
      e.persist();
      delete errors.account_source;
      setInputs(inputs => ({
        ...inputs,
        bank_source_id: e.target.value
      }));
    };

    const buildJsonAccounts = data => {
      return !empty(data) &&
        data.map(banexcoinaccount => {
          return {
            value: banexcoinaccount.id,
            name: `${banexcoinaccount.swiftBankBySwiftBankId.bankName}`,
            sub: `${banexcoinaccount.accountNumber} - ${context.t(
              'Country'
            )}: ${banexcoinaccount.countryCode}`,
            logo: !empty(banexcoinaccount.accountLogo) ? banexcoinaccount.accountLogo : null,
            alertMessage: (!empty(banexcoinaccount.accountMessage) && banexcoinaccount.accountMessage.hasOwnProperty(lang)) ? banexcoinaccount.accountMessage[lang] : ''
          }
        });
    };    
    const interbankValidationUSD = (fiatAccount) => (fiatAccount.beneficiaryBankname === "BANCO INTERNACIONAL DEL PERU (INTERBANK)") || fiatAccount.countryName !== "PERU" 
    const interbankValidationPEN = (fiatAccount) => (fiatAccount.beneficiaryBankname === "BANCO INTERNACIONAL DEL PERU (INTERBANK)") 
    const alfinValidation = (fiatAccount) => fiatAccount.countryName === "PERU"
    const comercioValidationUSD = (fiatAccount) => fiatAccount 
    const comercioValidationPEN = (fiatAccount) => fiatAccount.countryName === "PERU" 
    const bcpValidationUSD = (fiatAccount) => fiatAccount.countryName === "PERU" 
    const bcpValidationPEN = (fiatAccount) => fiatAccount.countryName === "PERU" 
    const deltecValidation = (fiatAccount) => fiatAccount 

    const filterToSelectedBank = {
      "BANCO INTERNACIONAL DEL PERU (INTERBANK)": (product.Product === "USD") ? interbankValidationUSD : interbankValidationPEN ,
      "ALFIN BANCO S.A. (ANTES BANCO AZTECA DEL PERU S.A.)": alfinValidation,
      "BANCO DE COMERCIO": (product.Product === "USD")  ? comercioValidationUSD : comercioValidationPEN,
      "BANCO DE CREDITO DEL PERU": (product.Product === "USD")  ? bcpValidationUSD : bcpValidationPEN,
      "DELTEC BANK AND TRUST LIMITED": deltecValidation,
    }
    useEffect(() => {
        if (empty(bankName) || empty(fiatAccounts)) return
        let availableOriginAccounts = []
        const selectedeFilterToBank = filterToSelectedBank[bankName]
        if(!empty(selectedeFilterToBank)){
          availableOriginAccounts = fiatAccounts.filter(selectedeFilterToBank)
        }
        setOriginAccounts(buildJsonOriginAccounts(availableOriginAccounts));
      }, [bankName]);

    useEffect(() => {
        if (!empty(fiatAccounts)) {
          const response = originAccounts.find(item => item.value === idOriginBank);
          if (response) setTooltipBank(response.name);
        }
      },[idOriginBank]);

    const helperTextValidationToAddBankAccount = () => {
      const usdHelperText = (product.Product === "USD") ? context.t('You must add an Interbank account or international banking account') : context.t('You must add an Interbank account');
      return (bankName === "BANCO INTERNACIONAL DEL PERU (INTERBANK)") ? usdHelperText : context.t('You must add a banking account')
    }

    const buildJsonOriginAccounts = data => {
      let result = [];
      !empty(data) &&
        data.forEach(fiataccount => {
          let logo = allBanksInfo(fiataccount.beneficiaryBankname);
          if (fiataccount.transferType === 'NATIONAL') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | ${context.t(
                'National'
              )} (${fiataccount.countryName})`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('CCI')}: ${fiataccount.accountNumberCci}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency:fiataccount.currencyCode
            });
          } else if (fiataccount.transferType === 'DOMESTIC_TRANSFER_US') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | ${context.t(
                'Domestic transfer USA'
              )}  (${fiataccount.countryName})`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency:fiataccount.currencyCode
            });
          } else if (
            fiataccount.transferType === 'DOMESTIC_TRANSFER_US_WITH_BANK'
          ) {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t(
                'Domestic transfer USA with intermediary bank'
              )}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency:fiataccount.currencyCode
            });
          } else if (fiataccount.transferType === 'INTERNATIONAL') {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('International')}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency:fiataccount.currencyCode
            });
          } else if (
            fiataccount.transferType ===
            'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK'
          ) {
            result.push({
              value: fiataccount.id,
              name: `${fiataccount.beneficiaryBankname} | (${
                fiataccount.countryName
              })`,
              sub: `${context.t('Account Number')}: ${
                fiataccount.accountNumber
              }`,
              twosub: `${context.t('International without intermediary bank')}`,
              logo: !empty(logo) ? logo.bank_logo : '',
              currency:fiataccount.currencyCode
            });
          }
        });
        let accountWithCurrentCurrency = []
        let accountNoCurrentCurrency = []
        result.forEach(item => {
          if(item.currency === product.ProductSymbol){
            accountWithCurrentCurrency.push(item)
          }else{
            accountNoCurrentCurrency.push(item)
          }
        });
        return [...accountWithCurrentCurrency, ...accountNoCurrentCurrency];
    };

    const defaultOptionsFunds = [
      {
        value: context.t('Personal savings'),
        label: context.t('Personal savings')
      },
      {
        value: context.t('Family savings'),
        label: context.t('Family savings')
      },
      {
        value: context.t('Rental of movable property'),
        label: context.t('Rental of movable property')
      },
      {
        value: context.t('Real estate rental'),
        label: context.t('Real estate rental')
      },
      {
        value: context.t('Awards'),
        label: context.t('Awards')
      },
      {
        value: context.t('Donations'),
        label: context.t('Donations')
      },
      {
        value: context.t('Income from self-employment'),
        label: context.t('Income from self-employment')
      },
      {
        value: context.t('Income from labour work'),
        label: context.t('Income from labour work')
      },
      {
        value: context.t('Income rights / patents / intellectual property'),
        label: context.t('Income rights / patents / intellectual property')
      },
      {
        value: context.t('Bank loans'),
        label: context.t('Bank loans')
      },
      {
        value: context.t('Non-bank loans'),
        label: context.t('Non-bank loans')
      },
      {
        value: context.t('Loans from natural persons'),
        label: context.t('Loans from natural persons')
      },
      {
        value: context.t('Loans from legal entities'),
        label: context.t('Loans from legal entities')
      },
      {
        value: context.t('Sale of movable property'),
        label: context.t('Sale of movable property')
      },
      {
        value: context.t('Sale of real estate'),
        label: context.t('Sale of real estate')
      },
      {
        value: context.t('Income coming from stocks/shares'),
        label: context.t('Income coming from stocks/shares')
      },
      {
        value: context.t('Business profit income'),
        label: context.t('Business profit income')
      },
      {
        value: context.t('Virtual asset rental income'),
        label: context.t('Virtual asset rental income')
      },
      {
        value: context.t('Retirement income income'),
        label: context.t('Retirement income income')
      },
      {
        value: context.t('Remittances from abroad'),
        label: context.t('Remittances from abroad')
      },
      {
        value: context.t('Inheritances / Intestate Succession'),
        label: context.t('Inheritances / Intestate Succession')
      },
      {
        value: context.t('Collective funds'),
        label: context.t('Collective funds')
      },
      {
        value: context.t('Buy/Sell Cryptocurrencies'),
        label: context.t('Buy/Sell Cryptocurrencies')
      },
      {
        value: context.t('Buy/Sell US Dollars'),
        label: context.t('Buy/Sell US Dollars')
      },
      {
        value: context.t('Intermediation Service (Trading)'),
        label: context.t('Intermediation Service (Trading)')
      },
      {
        value: context.t('Remittances abroad'),
        label: context.t('Remittances abroad')
      },
      {
        value: context.t('Income from billable services'),
        label: context.t('Income from billable services')
      },
      {
        value: context.t('Income from labor conciliations'),
        label: context.t('Income from labor conciliations')
      },
      {
        value: context.t('Indemnification for legal issues'),
        label: context.t('Indemnification for legal issues')
      },
      {
        value: context.t('Settlement/Labor Benefits'),
        label: context.t('Settlement/Labor Benefits')
      }
    ];

    const makeOpenStep = (nameStep, status) => {
      setOpenSteps({
        ...openSteps,
        [nameStep]: status
      });
    };

    const cerrar = () => {
      var closing = document.getElementsByClassName(
        'ap-sidepane__close-button'
      );
      if (closing) {
        closing[0].click();
      }
    };

    const selectedDestinationAccount = banexcoinAccounts.filter(
      a => a.id === inputs.account_destination_id
    );
    const selectedSourceAccount = fiatAccounts.filter(
      a => a.id === inputs.bank_source_id
    );

    let primetrustAccount = [
      //produccion
      '45585603',
      '1503666126', //staging
      '2030136050',
      '6300102308'
    ];

    const isPrimeTrust = () => {
      const validationIfIncludesPrimeTust = !empty(selectedBank) && (selectedBank.swiftBankBySwiftBankId.bankName || '').includes('PRIME TRUST')
      const foundPrimetrust = primetrustAccount.includes(selectedBank.accountNumber);
      return validationIfIncludesPrimeTust && foundPrimetrust;
    };
 
    const isBankInternational = () => !empty(selectedBank) && selectedBank.currencyCode === "USD" && selectedBank.countryCode !== "PE" && !empty(selectedBank.reference);
    
  const isReferencefNull = isEmpty(selectedBank) || selectedBank.reference === "null"

    const inmediateAlertBbva = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui custom-notice">
              <p>{context.t('Dear Customer:')}</p>
              <p>
                {context.t(
                  'We inform you that all deposit or withdrawal operations in Peruvian Soles or US Dollars to be carried out in Peru will be handled through Interbank for the time being.'
                )}
              </p>
              <p>
                {context.t(
                  'Temporarily we will not be processing withdrawals for transfers to other banks. Remember that deposit operations will continue to be handled normally through Interbank. We are working to offer new banking alternatives in the short term.'
                )}
              </p>
              <p>
                {context.t(
                  'We appreciate your understanding and apologize for any inconvenience this may cause.'
                )}
              </p>
              <p>{context.t('Sincerely,')}</p>
              <p>{context.t('Banexcoin Team')}</p>
              <div className="btn-alert-container">
                <button
                  className="btn-alert"
                  onClick={() => {
                    onClose();
                  }}>
                  {context.t('Accept')}
                </button>
              </div>
            </div>
          );
        }
      });
    };

    const [loadingDepositCoink, setLoadingDepositCoink] = useState(false)

    const handleCoinkErrors = () => {
      if(formattedCoinkAmountWithoutDots(coinkAmount) > maximumAvailableCoink){
        setErrors({
          amountCoink: context.t("The amount exceeds the maximum available.")
        })
        return true
      }
      return false
    }
    const handleCoinkDeposit = async () => {
      const thereAreErrors = handleCoinkErrors()
      if(thereAreErrors) return 
      delete errors.amountCoink
      const {status, message} = await CoinkTransactionsController.deposit({userInfo, context, inputs: {
        amount: formattedCoinkAmountWithoutDots(coinkAmount),
      }, setLoadingDepositCoink})
      if(!status){
        showSnack({
          id: "deposit_fail",
          text: context.t(message),
          type: "warning",
        });
      }
    }

    useEffect(() => {
      handleCoinkErrors()
    },[coinkAmount])

    useEffect(
      () => {
        if (interbankNews) {
          inmediateAlertBbva();
        }
      },
      [interbankNews]
    );


    useEffect(() => {
      if(isCoink){
        showSnack({
          id: "coink_trade_withdrawal",
          text: context.t(alertsContentCoink.rememberMessage),
          type: "info",
          buttons: [
            {
              text: context.t("See my settings"),
              className: "button_V2 grey",
              onClick: handleRedirectToSettings
            },
            {
              text: context.t("Accept"),
              className: "button_V2 purple",
            },
          ],
        });
      }
    },[])

    const interbankOperations = () => {
      createStateSnackbar({
        title: 'Interbank Operations',
      })
      showSnack({
        id: 'interbank_operations',
        text: context.t('Remember that, according to the internal policies of the banking entity, this transfer will be made within 24 hours with a maximum of 72 hours. This schedule does not consider holidays.'),
        type: 'pending',
        buttons: [
          {
              text: context.t('Got it'),
              className: 'button_V2 purple',
              onClick: () => resetStateSnackbar()
          }
        ]
      });
    };

    const validationIfNeedStatementofFunds = async () => {
      if(isEmpty(accounts)) return false
      setLoadingValidationStatementOfFunds(true)
      const response = await DepositAlertsController.limitValidationByLevel({userInfo,accountLevel:accounts[0].VerificationLevel,amount:Number(inputs.amount),currency:product.Product})
      let exceedTheLimit=false
      if(response){
        exceedTheLimit = Object.values(response).some(value => value)
        setGenerateAlert(response.monthAlert)
        if (empty(inputs.affidavit_source_funds) && exceedTheLimit) {
          setErrors({...errors,affidavit_source_funds:"Required field"})
        }
        setNeedStatementsOfFunds(exceedTheLimit)
        setLoadingValidationStatementOfFunds(false)
      }
      return exceedTheLimit
    }

    const handleRedirectToSettings = () => {
      history.push("/settings/withdrawal")
      const cerrar = document.getElementsByClassName(
        'ap-sidepane__close-button'
      );
      if (cerrar) {
        cerrar[0].click();
      }
    }

    useEffect(
      () => {
        if (
          !empty(bankName) &&
          !empty(bankNameUser) &&
          bankName !== bankNameUser
        ) {
          interbankOperations();
        }
      },
      [bankNameUser, bankName]
    );

    useEffect(() => {
      if(isEmpty(limitsByProductAndLevel)) return 
      const {monthly, yearly} = limitsByProductAndLevel.deposit
      const monthlyDifference = monthly.OriginalLimit - monthly.OriginalCurrent
      const yearlyDifference = yearly.OriginalLimit - yearly.OriginalCurrent
      setMaximumAvailableCoink(Math.min(monthlyDifference,yearlyDifference))
    },[limitsByProductAndLevel])

    const handleSetMaximum = () => {
      setCoinkAmount(formatCopCurrency(maximumAvailableCoink, 2).slice(2))
      delete errors.amountCoink
     }

    return (
      <React.Fragment>
        <Loading loading={loading} />
        {authorizedLevel().includes(currentAccount.VerificationLevel) ? (
          <div className={manualDepositWorkflowComponentClasses()}>
            <Form
              noValidate
              validated={inputs.validated}
              encType="multipart/form-data"
              onSubmit={onSubmitSimple}
              autoComplete="off"
            >
              <div className="bnx-v2 container-workflow deposit">
                <div className="container-form">
                  {!isCoink ? (
                    <React.Fragment>
                      <Row className={openSteps.step3 ? "just-hide" : ""}>
                        <Col xl="12">
                          <h4 className="title-step">
                            {context.t("Step {number}", { number: 1 })}
                          </h4>
                          <Button
                            onClick={() =>
                              makeOpenStep("step1", !openSteps.step1)
                            }
                            aria-controls="step1-collapse-text"
                            variant="light"
                            className="collapse-btn"
                          >
                            {openSteps.step1 ? (
                              <FontAwesomeIcon icon={faArrowDown} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowRight} />
                            )}
                          </Button>
                        </Col>
                      </Row>
                      <Collapse
                        in={openSteps.step1}
                        className={openSteps.step3 ? "just-hide" : ""}
                      >
                        <div>
                          <Row>
                            <Col xl="12">
                              <FormSelect
                                required
                                name="account_destination"
                                label="Select one of our accounts where you will make your deposit"
                                defaultOption="Select Account"
                                value={idBankDb || ""}
                                onChange={(e) => {
                                  const selectedBank = banexcoinAccounts.find(
                                    (fa) => fa.id === e.target.value
                                  );
                                  setBankName(
                                    selectedBank.swiftBankBySwiftBankId.bankName
                                  );
                                  setSelectedBank(selectedBank);
                                  onInputChange(e);
                                  destinationChange(e);
                                  getBankingData(e, selectedBank);
                                  setOpenSteps({
                                    ...openSteps,
                                    step1: false,
                                    step2: true,
                                  });
                                }}
                                options={
                                  !isCoink
                                    ? buildJsonAccounts(banexcoinAccounts)
                                    : []
                                }
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="important-alert">
                                {context.t(
                                  "Please make the deposit in the selected bank before generating your deposit receipt."
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <b className="important-solo">*</b>{" "}
                              <b className="important-message">
                                {context.t("Required fields")}
                              </b>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                      <Row className={openSteps.step3 ? "just-hide" : ""}>
                        <Col xl="12">
                          <h4 className="title-step">
                            {context.t("Step {number}", { number: 2 })}
                          </h4>
                          <Button
                            onClick={() =>
                              makeOpenStep("step2", !openSteps.step2)
                            }
                            aria-controls="step2-collapse-text"
                            variant="light"
                            className="collapse-btn"
                          >
                            {openSteps.step2 ? (
                              <FontAwesomeIcon icon={faArrowDown} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowRight} />
                            )}
                          </Button>
                        </Col>
                        <Col xl="12">
                          <h3 className="subtitle-step text-step-two">
                            {context.t(
                              "Deposit and fill in the information of your transfer so that it can be verified and we credit you the funds:"
                            )}
                          </h3>
                        </Col>
                      </Row>
                      <Collapse
                        in={openSteps.step2}
                        className={openSteps.step3 ? "just-hide" : ""}
                      >
                        <div>
                          <Row>
                            <Col xl="12">
                              {!empty(idBankDb) ? (
                                <React.Fragment>
                                  <Collapse in={true}>
                                    <DataDepositBank
                                      className="v2-databank"
                                      idBank={idBankDb}
                                      info={bankInformation}
                                    />
                                  </Collapse>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            {(isBankInternational() && !isReferencefNull) && (
                              <Col>
                                <div className="important-alert deposit-alert">
                                <p
                                  className="title-alert deposit-text"
                                  dangerouslySetInnerHTML={{
                                    __html: context.t(
                                      "When ordering the transfer you should always indicate the Reference: {reference}.",
                                      {
                                        reference: `<b>${selectedBank.reference}</b>`
                                      }
                                    )
                                  }}
                                />
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div title={tooltipBank}>
                                <FormSelect
                                  required
                                  name="account_source"
                                  label="Source Account"
                                  defaultOption="Select Account"
                                  value={idOriginBank || ""}
                                  onChange={(e) => {
                                    const selectedBankUser = fiatAccounts.find(
                                      (fa) => fa.id === e.target.value
                                    );
                                    setBankNameUser(
                                      selectedBankUser.beneficiaryBankname
                                    );
                                    onFiatAccountChange(e);
                                    setIdOriginBank(e.target.value);
                                  }}
                                  options={originAccounts}
                                  errors={errors}
                                />
                              </div>
                              {empty(originAccounts) ? (
                                <p className="linked-container">
                                  <span className="linked-a">
                                    {helperTextValidationToAddBankAccount()}
                                    {", "}
                                  </span>
                                  <b onClick={() => cerrar()}>
                                    <Link to="/settings/accounts">
                                      {context.t("Click here")}
                                    </Link>
                                  </b>
                                </p>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6">
                              <FormField
                                required
                                type="text"
                                name="transaction_number"
                                label="Transaction Number"
                                onChange={onDefaultChange}
                                errors={errors}
                              />
                            </Col>
                            <Col xl="6">
                              <FormDate
                                required
                                type="text"
                                value={inputs.transaction_date}
                                name="transaction_date"
                                label="Transaction Date"
                                onChange={(name, value) => {
                                  onDefaultChange({ target: { name, value }});  
                                }}
                                maxDate={moment()}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <FormField
                                required
                                type="number"
                                name="amount"
                                step="0.01"
                                label="Amount"
                                onChange={(ev) => {
                                  onInputChangeWithCallback(ev, () => ({
                                    affidavit_source_funds: null,
                                  }));
                                  setNeedStatementsOfFunds(false);
                                  amountValidation(ev.target.value);
                                }}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <FormUploadFile
                                required
                                type="file"
                                name="proof_payment"
                                label="Upload proof of payment"
                                onChange={onInputFileChangeImages}
                                accept={
                                  "image/png, image/jpg, image/jpeg, application/pdf"
                                }
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row className="required-fields-helptext">
                            <Col xl="12">
                              <b className="important-message">
                                {context.t(
                                  "Supported formats: jpg, jpeg, png and pdf"
                                )}
                                .
                              </b>
                            </Col>
                          </Row>
                          {needStatementsOfFunds && (
                            <Row>
                              <Col xl="12" className='select-statement-funds-selector'>
                                <FormSelector
                                  required
                                  name="affidavit_source_funds"
                                  label="Select the source of your funds (Affidavit)"
                                  options={defaultOptionsFunds}
                                  onChange={(e) => {
                                    e.persist();
                                    delete errors.affidavit_source_funds;
                                    let newInputs = {
                                      ...inputs,
                                      affidavit_source_funds: e.target.value,
                                      affidavit_source_funds_others: "",
                                    };
                                    setInputs(newInputs);
                                  }}
                                  errors={errors}
                                />
                              </Col>
                            </Row>
                          )}
                          {inputs.affidavit_source_funds == "Others (Detail)" ||
                          inputs.affidavit_source_funds ==
                            "Otros (Detallar)" ? (
                            <Row className="mt-0">
                              <Col xl="12">
                                <FormField
                                  required
                                  type="text"
                                  name="affidavit_source_funds_others"
                                  placeholder={context.t("Others (Detail)")}
                                  onChange={onInputChange}
                                  errors={errors}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row className="required-fields-helptext">
                            <Col xl="12">
                              <b className="important-solo">*</b>{" "}
                              <b className="important-message">
                                {context.t("Required fields")}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <Button
                                variant="dark"
                                className="submit-button"
                                type="submit"
                                disabled={
                                  empty(fiatAccounts) ||
                                  loadingValidationStatementOfFunds
                                }
                              >
                                {loadingValidationStatementOfFunds
                                  ? context.t("Loading")
                                  : context.t("Next")}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                      <Collapse in={openSteps.step3}>
                        <div>
                          <Row>
                            <Col xl="12">
                              <Button
                                className="back-button-confirm"
                                onClick={() => {
                                  setOpenSteps({
                                    ...openSteps,
                                    step1: false,
                                    step2: true,
                                    step3: false,
                                  });
                                }}
                              >
                                {"<"}
                              </Button>
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: context.t("Deposit <b>details</b>"),
                                }}
                                className="bnx-title"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12" className="one-row-data">
                              <div>
                                <label className="label-confirm-bnx">
                                  {context.t("Source Account")}
                                </label>
                                <p className="value-confirm-bnx">
                                  {!empty(selectedSourceAccount) ? (
                                    selectedSourceAccount.map((ba, index) => {
                                      return (
                                        <React.Fragment>
                                          <b>
                                            {ba.beneficiaryBankname ||
                                              ba.bankName}
                                          </b>
                                          <br />
                                          <span>
                                            {ba.accountNumber} -{" "}
                                            {ba.countryName}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })
                                  ) : (
                                    <i>{context.t("No data")}</i>
                                  )}
                                </p>
                              </div>
                              <div>
                                {!empty(selectedSourceAccount) ? (
                                  selectedSourceAccount.map((ba, index) => {
                                    const allBanksInfos = jsonBankNew();
                                    const logo = allBanksInfos.find(
                                      (b) => b.bank_alias === ba.bankName
                                    );
                                    return !empty(logo) ? (
                                      <img src={logo.bank_logo} />
                                    ) : (
                                      <b />
                                    );
                                  })
                                ) : (
                                  <i />
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12" className="one-row-data">
                              <div>
                                <label className="label-confirm-bnx">
                                  {context.t("Destination Account")}
                                </label>
                                <p className="value-confirm-bnx">
                                  {!empty(selectedDestinationAccount) ? (
                                    selectedDestinationAccount.map(
                                      (ba, index) => {
                                        return ba.swiftBankBySwiftBankId
                                          .bankName;
                                      }
                                    )
                                  ) : (
                                    <i>{context.t("No data")}</i>
                                  )}
                                </p>
                              </div>
                              <div>
                                {!empty(selectedDestinationAccount) ? (
                                  selectedDestinationAccount.map(
                                    (detailsAccount, index) => {
                                      const logo = detailsAccount.accountLogo;
                                      if (empty(logo)) {
                                        return (
                                          <React.Fragment key={index + "i"} />
                                        );
                                      }
                                      return (
                                        <img src={logo} key={index + "i"} />
                                      );
                                    }
                                  )
                                ) : (
                                  <i />
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="6" className="malf-data">
                              <Row>
                                <Col xl="12">
                                  <label className="label-confirm-bnx">
                                    {context.t("Transaction Number")}
                                  </label>
                                  <p className="value-confirm-bnx">
                                    {inputs.transaction_number}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <label className="label-confirm-bnx">
                                    {context.t("Transaction Date")}
                                  </label>
                                  <p className="value-confirm-bnx">
                                    {inputs.transaction_date}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <label className="label-confirm-bnx">
                                    {context.t("Amount")}
                                  </label>
                                  <p className="value-confirm-bnx">
                                    {inputs.amount}
                                  </p>
                                </Col>
                              </Row>
                              {needStatementsOfFunds && (
                                <Row>
                                  <Col xl="12">
                                    <label className="label-confirm-bnx">
                                      {context.t("Affidavit/Source of Funds")}
                                    </label>
                                    <p className="value-confirm-bnx">
                                      {context.t(
                                        inputs.affidavit_source_funds || ""
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col xl="6" className="malf-data">
                              <Row>
                                {!empty(inputs.proof_payment) && (
                                  <Col xl="12">
                                    <label className="label-confirm-bnx">
                                      {context.t("Proof of deposit")}
                                    </label>
                                    {inputs.proof_payment.name.includes(
                                      ".pdf"
                                    ) ? (
                                      <a
                                        href={URL.createObjectURL(
                                          inputs.proof_payment
                                        )}
                                        target="_blank"
                                        className="value-confirm-bnx new-link-v2"
                                      >
                                        {inputs.proof_payment.name}
                                      </a>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(
                                          inputs.proof_payment
                                        )}
                                        width="100%"
                                      />
                                    )}
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <FormSingleCheckbox
                                name="confirmDeposit"
                                classNameContainer="pl-3"
                                label={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: context.t(
                                        "I confirm that the deposit details entered are correct in compliance with the <a href='https://soporte.banexcoin.com/support/solutions/articles/60000599926' target='_blank'>Terms and Conditions</a>"
                                      ),
                                    }}
                                  />
                                }
                                onChange={onCheckboxChangeValidation}
                                checked={inputs.confirmDeposit || false}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <Button
                                variant="dark"
                                className="submit-button"
                                type="submit"
                                disabled={!inputs.confirmDeposit}
                              >
                                {context.t("Place Deposit Ticket")}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    blockedActions ? <div className='coink-alert-swiping-now'>
                      <p>
                      {context.t(alertsContentCoink.swipingIsNowMessage)}
                      </p>
                    </div> : <div>
                    <Alert variant={"warning"} className="warning-withdraw mt-4">
                      <h4><b>Recuerda que...</b></h4>
                      <p>
                        Si tu billetera presenta un balance en Pesos, 
                        éste permanecerá <b>hasta el día domingo a las 11:55pm.</b>
                      </p>
                      <p>En caso no realices alguna compra o retiro, Banexcoin los devolverá a tu cuenta Coink o realizará un intercambio a USDC en base a tu configuración de retiro seleccionada. <br/></p>
                      <p>Puedes cambiar esta configuración dando <span className='click-here-coink' onClick={handleRedirectToSettings}>clic aquí</span>.</p>
                      <p>
                        {`Para mayor detalle, revisa nuestros `}
                        <a target="_blank" href="http://soporte.banexcoin.com/es-LA/support/solutions/articles/60000599926">Términos y Condiciones</a>.
                      </p>
                    </Alert>
                    <Row>
                      <Col xl="12" className='coink-account-field'>
                          <p>{context.t("Origin")}
                          </p>
                          <p>
                            {context.t("My {provider} account",{
                              provider: "Coink"
                            })}
                          </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12" className='coink-amount'>
                      <InputElement {...{
                        inputType: 'currency',
                        values: {
                            required:true,
                            title: "Quantity",
                            value: coinkAmount.replaceAll('.', '').replaceAll(',', '.'),
                            name: 'amountCoink',
                            type: 'text',
                            autoComplete: 'Off',
                            decimalScale: 2,
                            thousandSeparator: '.',
                            decimalSeparator: ',',
                        },
                        actions: {
                            onChange: (e) => {
                              setCoinkAmount(e.target.value)
                              delete errors.amountCoink
                            },
                        },
                        error: errors.amountCoink
                        }} />
                        <button type='button' className='coink-maximum-button' onClick={handleSetMaximum}>{context.t('Maximum')}</button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12" className='limits-details-p'>
                        <p>{context.t("Maximum limit to deposit in Banexcoin")}</p>
                        <p>{formatCopCurrency(maximumAvailableCoink, 2)}</p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-2 p-1-5">
                      <Col xl="12" style={{ padding: "0" }}>
                        {
                          <FormButtonV2
                            type="button"
                            onClick={
                              (!loadingDepositCoink && !blockedActions && isEmpty(errors.amountCoink)) ? handleCoinkDeposit : null
                            }
                            className="submit-button register-form coink-button deposit-withdraw"
                            disabled={formattedCoinkAmountWithoutDots(coinkAmount) <= 0 || loadingDepositCoink || blockedActions || !isEmpty(errors.amountCoink)}
                          >
                            <p style={{minWidth:"180px", margin: "0"}}>
                            {
                              loadingDepositCoink ? (
                                <Spinner />
                              ) : (
                                <>
                                <img
                                style={{marginRight:"5px"}}
                                src={coinkLogo}
                                alt="coink logo"
                                width={25}
                              />{" "}
                                  {context.t(`Deposit with {provider}`, {
                                    provider: "Coink",
                                  })}
                                </>
                              )
                            }
                            </p>
                          </FormButtonV2>
                        }
                      </Col>
                    </Row>
                  </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <Link
            className="verification-required__container"
            to="/settings/verification-level"
            onClick={() => {
              var cerrar = document.getElementsByClassName(
                "ap-sidepane__close-button"
              );
              if (cerrar) {
                cerrar[0].click();
              }
            }}
          >
            <div className="verification-required">
              <span className="isvg loaded ap-icon ap-icon--alert ap-icon--small verification-required__warning verification-required__warning--alert verification-required__warning--small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  viewBox="0 0 12 12"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M5.01 1.194c-.006-.05-.01-.1-.01-.15C5 .466 5.448 0 6 0s1 .467 1 1.043c0 .051-.004.102-.01.15C8.72 1.73 10 3.64 10 5.914l.5 2.435h-9L2 5.913C2 3.64 3.28 1.73 5.01 1.193zm-.51 9.24h3C7.5 11.3 6.828 12 6 12s-1.5-.7-1.5-1.565zM.522 8.349h10.956a.522.522 0 0 1 0 1.043H.522a.522.522 0 1 1 0-1.043z"
                  />
                </svg>
              </span>
              <span className="ap--label verification-required__not-verified ">
                {context.t(
                  "Verify your identity to start trading. Click here to verify."
                )}
              </span>
            </div>
          </Link>
        )}
      </React.Fragment>
    );
};

ManualDepositWorkflowComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {
    user: { userInfo, selectedAccountId, accounts, verificationLevelConfigs },
    blockedActions,
    limitsByProductAndLevel
  } = state;
  return { accounts, userInfo, selectedAccountId, verificationLevelConfigs, blockedActions,
    limitsByProductAndLevel  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
    createStateSnackbar: function createStateSnackbar(payload) {
      return dispatch(_createStateSnackbar(payload));
    },
    resetStateSnackbar: function resetStateSnackbar() {
      return dispatch(_resetStateSnackbar());
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManualDepositWorkflowComponent));
