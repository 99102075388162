import React from 'react';
// import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent.css';

// var baseClasses = getBEMClasses('instrument-row');

const FCSpanish = (props, context) => {
  return (
    <div>
      <div
        className="banner-op"
        style={{ height: '170px', marginBottom: '50px' }}>
        <h1 className="lang" key="tarifas_comisiones">
          Tarifas y Comisiones
        </h1>
        <p className="lang" key="best_rates_com">
          Aquí en Banexcoin trabajamos con las mejores tarifas y comisiones.
        </p>
      </div>
      <article className="padding-terms">
        <p className="lang parrafo" key="tarifas_comisiones_prr1">
          En Banexcoin contamos con varias formas de aumentar el saldo de tu
          cuenta, una vez tu cuenta tenga saldo suficiente puedes utilizarlo
          para intercambiarlo por los criptoactivos o dinero fiat manejándolos
          dentro de nuestra plataforma, además contamos con una tarifa plana
          para todas las operaciones de intercambio y solo verás un mínimo
          porcentaje o residual antes de realizar cualquier forma de intercambio
          el cual dependerá solamente del nivel de verificación que tengas como
          Usuario.
        </p>
        <p className="lang parrafo" key="tarifas_comisiones_prr2">
          Como Usuario podrás depositar tus criptomonedas sin tener que pagar
          comisión alguna, por razones de seguridad y a fines de evitar riesgos
          de doble gasto se han establecido cierto números de confirmaciones
          dependiendo de criptoactivos, estas confirmaciones no son mas que la
          inclusión de una transacción&nbsp;en un bloque incluido en la cadena
          de bloques por los mineros de dicha blockchain, cada bloque
          subsiguiente aumenta una confirmación a dicha transacción, como
          ejemplo en la blockchain de Bitcoin se tiene un tiempo promedio de
          extracción por bloque de 10 minutos, Banexcoin requiere de 6
          confirmaciones&nbsp;por tanto el Usuario verá en su cuenta acreditados
          los bitcoin que depositó en un plazo de 60 minutos aproximados siempre
          y cuando los mismos tomen los 10 minutos para minar el bloque, puede
          que tomen mas tiempo siendo así decimos que la red se encuentra
          congestionada y ella escapa de las manos de la Plataforma, por tanto
          debe quedar eximida de responsabilidad cuando se trate de tiempos que
          no puede controlar.
        </p>
        <p />
        <div className="box-center">
          <table className="table">
            <tbody>
              <tr className="tr-title">
                <td
                  colSpan={6}
                  className="text-center lang"
                  key="tarifas_comisiones_tiempos">
                  Tiempos y Comisiones por Depósitos y Retiros
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_peru">
                  Tarifas Aplican para Perú{' '}
                  <img
                    alt="peru"
                    src="local/landing-page/images/country/peru.png"
                  />
                </td>
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_panama">
                  Tarifas Aplican para Panamá{' '}
                  <img
                    alt="panama"
                    src="local/landing-page/images/country/panama.png"
                  />
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td className="lang" key="fiat_depo">
                  Depositos Fiat
                </td>
                <td className="lang" key="estimate_time">
                  Tiempo Estimado para Depósito*
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_depo">
                    % Comisión para Depósito
                  </span>
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td className="bor-rg">
                  <span className="lang" key="perc_com_depo">
                    % Comisión para Depósito
                  </span>
                </td>
              </tr>
              <tr>
                <td>Banexcoin transfer</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td className="bor-rg">
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="tarjeta_Credito">
                    Tarjeta de Crédito **
                  </span>
                </td>
                <td>2 min.</td>
                <td className="bor-lf">50 US$</td>
                <td>4%</td>
                <td className="bor-lf">50 US$</td>
                <td className="bor-rg">4%</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_same">
                    Transferencia Nacional desde el mismo Banco
                  </span>
                </td>
                <td>5 min.</td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_other">
                    Transferencia Nacional desde el otro Banco{' '}
                  </span>
                </td>
                <td>
                  <span className="lang" key="one_work_day">
                    1 día Habil
                  </span>
                </td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td className="none-apply">XXX</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_inter">
                    Transferencia Internacional***
                  </span>
                </td>
                <td>48h - 72h</td>
                <td className="bor-lf">1000 US$</td>
                <td>50 US$</td>
                <td className="bor-lf">1000 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td>
                  <span className="lang" key="depo_cript">
                    Depósitos Criptoactivos
                  </span>
                </td>
                <td>
                  <span className="lang" key="number_conf_depo">
                    # Confirmaciones para Depósito
                  </span>
                </td>
                <td>
                  <span className="lang" key="estimate_time">
                    Tiempo estimado para Depósito*
                  </span>
                </td>
                <td>
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_depo">
                    % Comisión para Depósito
                  </span>
                </td>
              </tr>
              <tr>
                <td>Bitcoin (BTC)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>BitcoinCash (BCH)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>150 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Dash (DASH)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>15 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Eosio (EOS)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Ether (ETH)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>6 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Litecoin (LTC)</td>
                <td>
                  12{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Monero (XMR)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Ripple (XRP)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>USD Coin (USDC)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>6 Min.</td>
                <td>1</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>USD Tether (USDT)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>1</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>Zcash (ZEC)</td>
                <td>
                  24{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.0001</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table">
            <tbody>
              <tr>
                <td />
                <td />
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_peru">
                  Tarifas Aplican para Perú{' '}
                  <img
                    alt="peru"
                    src="local/landing-page/images/country/peru.png"
                  />
                </td>
                <td
                  colSpan={2}
                  className="country-separate lang"
                  key="tarifas_app_panama">
                  Tarifas Aplican para Panamá{' '}
                  <img
                    alt="panama"
                    src="local/landing-page/images/country/panama.png"
                  />
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td className="lang" key="reti_fiat">
                  Retiros Fiat
                </td>
                <td className="lang" key="estimate_time_reti">
                  Tiempo Estimado para Retiro*
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_reti">
                    % Comisión para Retiro
                  </span>
                </td>
                <td className="bor-lf">
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td className="bor-rg">
                  <span className="lang" key="perc_com_reti">
                    % Comisión para Retiro
                  </span>
                </td>
              </tr>
              <tr>
                <td>Banexcoin transfer</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td>
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
                <td className="bor-lf">5 US$</td>
                <td className="bor-rg">
                  <span className="lang" key="no_cost">
                    Sin Costo
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_same">
                    Transferencia Nacional desde el mismo Banco
                  </span>
                </td>
                <td>20 min.</td>
                <td className="bor-lf">10US$</td>
                <td>0.51%</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_nac_other">
                    Transferencia Nacional desde el otro Banco
                  </span>
                </td>
                <td>
                  <span className="lang" key="one_work_day">
                    1 día Habil
                  </span>
                </td>
                <td className="bor-lf">30 S/. ó 10 US$</td>
                <td className="none-apply">XXX</td>
                <td className="bor-lf">10 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
              <tr>
                <td>
                  <span className="lang" key="trans_inter">
                    Transferencia Internacional***
                  </span>
                </td>
                <td>48h - 72h</td>
                <td className="bor-lf">2000 US$</td>
                <td>50 US$</td>
                <td className="bor-lf">2000 US$</td>
                <td className="none-apply bor-rg">XXX</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td>
                  <span className="lang" key="reti_cript">
                    Retiros Criptoactivos
                  </span>
                </td>
                <td>
                  <span className="lang" key="number_conf_reti">
                    # Confirmaciones para Retiros
                  </span>
                </td>
                <td>
                  <span className="lang" key="estimate_time_reti">
                    Tiempo estimado para Retiros*
                  </span>
                </td>
                <td>
                  <span className="lang" key="minimun_amount">
                    Monto Mínimo
                  </span>
                </td>
                <td>
                  <span className="lang" key="perc_com_reti">
                    % Comisión para Retiros
                  </span>
                </td>
              </tr>
              <tr>
                <td>Bitcoin (BTC)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.002 BTC</td>
                <td>0.0015 BTC</td>
              </tr>
              <tr>
                <td>BitcoinCash (BCH)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>150 min.</td>
                <td>0.002 BCH</td>
                <td>0.001 BCH</td>
              </tr>
              <tr>
                <td>Dash (DASH)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>15 min.</td>
                <td>0.004 DASH</td>
                <td>0.05 DASH</td>
              </tr>
              <tr>
                <td>Eosio (EOS)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td>0.2 EOS</td>
                <td>0.1 EOS</td>
              </tr>
              <tr>
                <td>Ether (ETH)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>6 min.</td>
                <td>0.02 ETH</td>
                <td>0.01 ETH</td>
              </tr>
              <tr>
                <td>Litecoin (LTC)</td>
                <td>
                  12{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.002 LTC</td>
                <td>0.001 LTC</td>
              </tr>
              <tr>
                <td>Monero (XMR)</td>
                <td>
                  15{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>30 min.</td>
                <td>0.01 XMR</td>
                <td>0.0001 XMR</td>
              </tr>
              <tr>
                <td>Ripple (XRP)</td>
                <td>N / A</td>
                <td>
                  <span className="lang" key="inmediate">
                    Inmediato
                  </span>
                </td>
                <td>20 XRP</td>
                <td>0.3 XRP</td>
              </tr>
              <tr>
                <td>USD Coin (USDC)</td>
                <td>
                  30{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>6 Min.</td>
                <td>10 USDC</td>
                <td>3 USDC</td>
              </tr>
              <tr>
                <td>USD Tether (USDT)</td>
                <td>
                  6{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>10 USDT</td>
                <td>4 USDT</td>
              </tr>
              <tr>
                <td>Zcash (ZEC)</td>
                <td>
                  24{' '}
                  <span className="lang" key="confirm">
                    Confirmaciones
                  </span>
                </td>
                <td>60 min.</td>
                <td>0.01 ZEC</td>
                <td>0.005 ZEC</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <table className="table text-center">
            <tbody>
              <tr className="tr-title">
                <td colSpan={4} className="text-center lang" key="com_buy_sell">
                  Comisión por Compra / Venta de Criptomonedas (Intercambio)
                </td>
              </tr>
              <tr className="tr-subtitle">
                <td>
                  <span className="lang" key="level_ini1">
                    Inicial - Nivel 1
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_int2">
                    Intermedio - Nivel 2
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_ma3">
                    Maestro - Nivel 3
                  </span>
                </td>
                <td>
                  <span className="lang" key="level_corp4">
                    Corporativo - Nivel 4
                  </span>
                </td>
              </tr>
              <tr>
                <td>0.2%</td>
                <td>0.15%</td>
                <td>0.10%</td>
                <td>0.10%</td>
              </tr>
            </tbody>
          </table>
          <div className="mini-height clearfix" />
          <div className="lang parrafo" key="tarifas_clause1">
            * Se muestran tiempos de confirmación aproximados ya que los tiempos
            de ejecución pueden variar según las condiciones de la red. Tenga en
            cuenta que los tiempos estimados es el tiempo en el cual se espera
            estén disponibles los fondos en su cuenta Banexcoin y suponen que su
            transacción se ha confirmado en el primer bloque después de enviada
            la transacción. Si su transacción no fué confirmada en el primer
            bloque posible, entonces puede tomar mucho más tiempo dependiendo de
            las condiciones de la red para ese momento.
          </div>
          <div className="lang parrafo" key="tarifas_clause2">
            ** Solo son aceptadas Tarjetas de Crédito Visa y MasterCard, el
            titular&nbsp;debe ser el mismo que el usuario de la cuenta Banexcoin
            que está solicitando el depósito de&nbsp;fondos.
          </div>
          <div className="lang parrafo" key="tarifas_clause3">
            *** Esta operación deberá&nbsp;ser realizada por el titular de la
            Cuenta Banexcoin a la cual van dirigidos los fondos y el mismo
            deberá previamente asumir los cobros realizados por la entidad
            bancaria para la ejecución de la transferencia y bajo ningún
            concepto podrán ser transferidos a Banexcoin, en caso contrario será
            reversada dicha operación dedudiendo los cargos asociados.
          </div>
          <div className="lang parrafo" key="tarifas_clause4">
            Los tiempos de transferencias para depósitos o retiros fiat son
            aproximados y están expresados en tiempo hábil/útil o laboral,&nbsp;
            dependen de los tiempos de procesamiento de la&nbsp;Red Bancaria y
            pueden estar sujetos a revisión por parte de la&nbsp;misma.
          </div>
          <div className="lang parrafo" key="tarifas_clause5">
            Las tablas de comisiones aquí mostradas están sujetas a cambio sin
            previo aviso.
          </div>
        </div>
      </article>
    </div>
  );
};

export default FCSpanish;
