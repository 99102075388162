import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import ConfirmSingleReportForm from './ConfirmSingleReportForm';

let ConfirmSingleReportModal = function ConfirmSingleReportModal(props, context) {
  return React.createElement(
    Modal,
    {
      isOpen: props.isOpen,
      title: context.t('Create single report'),
      customClass: 'confirm-report-modal',
      close: props.close,
      footer: {
        buttonText: context.t('Generate report'),
        buttonStyle: 'additive',
        onClick: function onClick() {
          return props.generateReport(props.payload);
        }
      } },
    React.createElement(ConfirmSingleReportForm, { payload: props.payload })
  );
};

ConfirmSingleReportModal.propTypes = {
  payload: PropTypes.object,
  close: PropTypes.func.isRequired
};

ConfirmSingleReportModal.defaultProps = {
  payload: {
    type: '',
    startDate: '',
    endDate: ''
  }
};

ConfirmSingleReportModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ConfirmSingleReportModal;