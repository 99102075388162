//import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevel = async userId => {
  let path = '/api/level';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate-add-corporate-shareholder';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('company_name', data.company_name); //
  model.append('country', data.country); //
  model.append('address_main', data.address_main); //
  model.append('email', data.email); // ----
  model.append('phone', data.phone); //
  model.append('identification_number', data.identification_number); //
  model.append('is_legal_representative', data.is_legal_representative);
  model.append('address_billing', data.address_billing); //
  model.append('percentage_number', data.percentage_number); //

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
