import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Form, Modal, Button } from 'react-bootstrap';
import empty from 'is-empty';

const FormField = (props, context) => {
  const {
    label,
    labelInside,
    required,
    type,
    placeholder,
    name,
    step,
    id,
    onChange,
    errors,
    errorMessage,
    disabled,
    value,
    className = '',
    showAsterisk = true
  } = props;
  try {
    const idTwo = !empty(id) ? id : !empty(name) ? name : '';
    const propInput = {
      required: required ? true : false,
      type: !empty(type) ? type : 'text',
      placeholder: !empty(placeholder)
        ? context.t(placeholder)
        : !empty(label)
          ? context.t(label)
          : '',
      name,
      value,
      step,
      onChange,
      disabled,
      className
    };

    return (
      <Form.Group
        controlId={idTwo}
        className={
          'v2-field nooneline' +
          ((!empty(errors) && !empty(errors[name])) || !empty(errorMessage)
            ? ' invalid'
            : ' valid') +
          (!empty(labelInside) && !empty(value) ? ' inside-container' : '') +
          (!empty(className) ? ` ${className}` : '')
        }>
        {!empty(label) ? (
          <Form.Label>
            {context.t(label)}{' '}
            {required && showAsterisk ? <b className="important">*</b> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        {!empty(labelInside) ? (
          <div className="label-inside">{context.t(labelInside)}</div>
        ) : (
          ''
        )}
        <Form.Control {...propInput} />
        {!empty(errors) && !empty(errors[name]) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errors[name])}
          </Form.Control.Feedback>
        ) : !empty(errorMessage) ? (
          <Form.Control.Feedback type="invalid">
            {context.t(errorMessage)}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
      </Form.Group>
    );
  } catch (error) {
    console.log('error FormField', error);
    return <React.Fragment />;
  }
};

FormField.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FormField;
