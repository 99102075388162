import moment from 'moment';
import HandlerBackend from '../../../helpers/HandlerBackend';
import empty from 'is-empty';

export const getCountries = async userId => {
  let path = '/api/countries';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLevelBasic = async (userId, UserAccountId) => {
  let path = `/api/level-basic/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const getLevelBasicV2 = async (userId, UserAccountId, providerId) => {
  let path = `/api/v2/level-basic/${UserAccountId}/${providerId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};
export const getLevelBasicV3 = async (userId, UserAccountId) => {
  const path = `/api/v3/level-basic/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormBeginerData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/create-level-basic';
  let model = new FormData();

  model.append('kyc_type', 'V2');
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name ? data.middle_name : '');
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('town', data.town);
  model.append('city', data.city);
  model.append('apt', data.apt_number);
  model.append('state', data.state);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('occupation_details', data.occupation_details);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_state', data.billing_state);
  model.append('billing_building', data.billing_building);
  model.append('billing_town', data.billing_town);
  model.append('billing_district', data.billing_district);
  model.append('billing_apt_number', data.billing_apt_number);
  model.append('billing_city', data.billing_city);
  model.append('billing_zip', data.billing_zip);
  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('banexcoin_user_account_id', UserAccountId);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormBeginerData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/resend-level-basic';
  let model = new FormData();

  model.append('kyc_type', 'V2');
  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name);
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('town', data.town);
  model.append('city', data.city);
  model.append('apt', !empty(data.apt_number) ? data.apt_number : '');
  model.append('state', data.state);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('occupation_details', data.occupation_details);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', parseInt(data.is_pep));
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_state', data.billing_state);
  model.append('billing_building', data.billing_building);
  model.append('billing_town', data.billing_town);
  model.append('billing_district', data.billing_district);
  model.append(
    'billing_apt_number',
    !empty(data.billing_apt_number) ? data.billing_apt_number : ''
  );
  model.append('billing_city', data.billing_city);
  model.append('billing_zip', data.billing_zip);
  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('banexcoin_user_account_id', UserAccountId);
  model.append('id', data.id);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/create-level-basic';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name ? data.middle_name : '');
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('town', data.town);
  model.append('city', data.city);
  model.append('apt', data.apt_number);
  model.append('state', data.state);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('occupation_details', data.occupation_details);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_state', data.billing_state);
  model.append('billing_building', data.billing_building);
  model.append('billing_town', data.billing_town);
  model.append('billing_district', data.billing_district);
  model.append('billing_apt_number', data.billing_apt_number);
  model.append('billing_city', data.billing_city);
  model.append('billing_zip', data.billing_zip);
  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('document_data', data.document_data);
  model.append('back_document_data', data.back_document_data);
  model.append('faces_images', data.faces_images);
  model.append('banexcoin_user_account_id', UserAccountId);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const editFormData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/edit-level-basic';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name);
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('town', data.town);
  model.append('city', data.city);
  model.append('apt', data.apt_number);
  model.append('state', data.state);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('occupation_details', data.occupation_details);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_state', data.billing_state);
  model.append('billing_building', data.billing_building);
  model.append('billing_town', data.billing_town);
  model.append('billing_district', data.billing_district);
  model.append('billing_apt_number', data.billing_apt_number);
  model.append('billing_city', data.billing_city);
  model.append('billing_zip', data.billing_zip);
  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('document_data', data.document_data);
  model.append('back_document_data', data.back_document_data);
  model.append('faces_images', data.faces_images);
  model.append('banexcoin_user_account_id', UserAccountId);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/resend-level-basic';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name);
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('town', data.town);
  model.append('city', data.city);
  model.append('apt', data.apt_number);
  model.append('state', data.state);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('occupation_details', data.occupation_details);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', parseInt(data.is_pep));
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country);
  model.append('billing_address', data.billing_address);
  model.append('billing_state', data.billing_state);
  model.append('billing_building', data.billing_building);
  model.append('billing_town', data.billing_town);
  model.append('billing_district', data.billing_district);
  model.append('billing_apt_number', data.billing_apt_number);
  model.append('billing_city', data.billing_city);
  model.append('billing_zip', data.billing_zip);
  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('document_data', data.document_data);
  model.append('back_document_data', data.back_document_data);
  model.append('faces_images', data.faces_images);
  model.append('banexcoin_user_account_id', UserAccountId);
  model.append('id', data.id);
  model.append('is_invoice_with_ruc', data.is_invoice_with_ruc);
  model.append('personal_ruc', data.personal_ruc);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getLevelCorporateBeta = async (userId, UserAccountId) => {
  let path = `/api/level-corporate/${UserAccountId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormCorporateData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/create-level-corporate/${UserAccountId}`;
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('company_legal_name', data.company_legal_name);
  model.append('legal_entity_type', data.legal_entity_type);
  model.append('non_profit', data.non_profit);
  model.append(
    'other_non_profit',
    data.other_non_profit ? data.other_non_profit : ''
  );
  model.append('other_legal_entity_type', data.other_legal_entity_type);
  model.append(
    'taxpayer_identification_number_type',
    data.taxpayer_identification_number_type
  );
  model.append(
    'other_taxpayer_identification_number_type',
    data.other_taxpayer_identification_number_type
  );
  model.append(
    'taxpayer_identification_number',
    data.taxpayer_identification_number
  );
  model.append('corporate_purpose', data.corporate_purpose);
  model.append('company_address', data.company_address);
  model.append('city', data.city);
  model.append('state', data.state);
  model.append('zip_code', data.zip_code);
  model.append('country_incorporation', data.country_incorporation);
  model.append(
    'registration_date',
    moment(data.registration_date).format('YYYY-MM-DD')
  );
  model.append('linked_economic_group', data.linked_economic_group);
  model.append('company_phone', data.company_phone);
  model.append('full_name', data.full_name);
  model.append('job_position', data.job_position);
  model.append('phone', data.phone);
  model.append('company_constitution_act', data.company_constitution_act);
  model.append('incorporation_document', data.incorporation_document);
  model.append('receipt_public_service', data.receipt_public_service);
  model.append('last_income_tax_return', data.last_income_tax_return);
  model.append('affidavit_origins_funds', data.affidavit_origins_funds);
  model.append('ruc_file', data.ruc_file);
  model.append('begin_date', moment(data.begin_date).format('YYYY-MM-DD'));
  model.append('corporate_building', data.corporate_building);
  model.append('corporate_apt_number', data.corporate_apt_number);
  model.append('corporate_town', data.corporate_town);
  model.append('corporate_district', data.corporate_district);
  model.append(
    'linked_economic_group_country',
    data.linked_economic_group_country
  );
  model.append(
    'linked_economic_group_doc_type',
    data.linked_economic_group_doc_type
  );
  model.append(
    'linked_economic_group_doc_other_type',
    !empty(data.linked_economic_group_doc_other_type)
      ? data.linked_economic_group_doc_other_type
      : ''
  );
  model.append(
    'linked_economic_group_doc_number',
    data.linked_economic_group_doc_number
  );
  model.append('linked_economic_group_name', data.linked_economic_group_name);
  model.append('is_runac_verified', data.is_runac_verified);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const resendFormCorporateData = async (userId, data, UserAccountId) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/resend-level-corporate/${UserAccountId}`;

  let model = new FormData();
  for (let i in data) {
    let r = data[i];
    if (i === 'registration_date' || i === 'begin_date') {
      model.append(i, moment(r).format('YYYY-MM-DD'));
    } else {
      model.append(i, r);
    }
  }
  model.append('banexcoin_user_account_id', UserAccountId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormCorporateBetaShareholderRecord = async (
  userId,
  data,
  UserAccountId,
  companyMasterId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/level-corporate/${UserAccountId}/add/person-shareholder/${companyMasterId}`;
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append(
    'percentage_number',
    !empty(data.percentage_number) ? data.percentage_number : '0'
  );
  model.append('is_legal_representative', data.is_legal_representative);
  model.append('first_name', data.first_name);
  model.append('middle_name', data.middle_name ? data.middle_name : '');
  model.append('last_name', data.last_name);
  model.append('second_last_name', data.second_last_name);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('nationality', data.nationality);
  model.append('birth_country', data.birth_country);
  model.append('residence_country', data.residence_country);
  model.append('address_main', data.address_main);
  model.append('building', data.building);
  model.append('apt_number', data.apt_number);
  model.append('town', data.town);
  model.append('state', data.state);
  model.append('city', data.city);
  model.append('district', data.district);
  model.append('zip_code', data.zip_code);
  model.append('occupation', data.occupation);
  model.append('work_center', data.work_center);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('is_pep', data.is_pep);
  model.append('pep_document', data.pep_document);
  model.append('billing_country', data.billing_country); //
  model.append('billing_address', data.billing_address); //
  model.append('billing_state', data.billing_state); //
  model.append('billing_city', data.billing_city); //
  model.append('billing_zip', data.billing_zip);

  model.append('billing_building', data.billing_building); //
  model.append('billing_apt_number', data.billing_apt_number); //
  model.append('billing_town', data.billing_town); //
  model.append('billing_district', data.billing_district); //

  model.append('document_type', data.document_type);
  model.append('document_country', data.document_country);
  model.append('document_number', data.document_number);
  model.append('document_data', data.document_data);
  model.append('back_document_data', data.back_document_data);
  model.append('faces_images', data.faces_images);
  model.append('banexcoin_user_account_id', UserAccountId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

/** */
export const resendFormCorporateBetaAllShareholderById = async (
  userId,
  data,
  UserAccountId,
  companyMasterId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/level-corporate/${UserAccountId}/resend/person-shareholder/${companyMasterId}`;
  let model = new FormData();
  for (let i in data) {
    let r = data[i];
    if (i === 'birthday') {
      model.append(i, moment(r).format('YYYY-MM-DD'));
    } else {
      model.append(i, r);
    }
  }
  model.append('banexcoin_user_account_id', UserAccountId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getLevelCorporateBetaShareholderRecordById = async (
  userId,
  UserAccountId,
  recordId
) => {
  let path = `/api/level-corporate/${UserAccountId}/get/shareholders/${recordId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const deleteLevelCorporateBetaShareholderRecordById = async (
  userId,
  UserAccountId,
  recordId
) => {
  let path = `/api/level-corporate/${UserAccountId}/delete/shareholder/${recordId}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const submitFormCorporateBetaShareholderRecordCompany = async (
  userId,
  data,
  UserAccountId,
  companyMasterId
) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = `/api/level-corporate/${UserAccountId}/add/company-shareholder/${companyMasterId}`;
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('percentage_number', data.percentage_number);
  model.append('is_legal_representative', data.is_legal_representative);
  model.append('company_legal_name', data.company_legal_name);
  model.append(
    'legal_entity_type',
    !empty(data.legal_entity_type) ? data.legal_entity_type : ''
  );
  model.append('non_profit', data.non_profit);
  model.append('other_non_profit', data.other_non_profit);
  model.append('other_legal_entity_type', data.other_legal_entity_type);
  model.append(
    'taxpayer_identification_number_type',
    data.taxpayer_identification_number_type
  );
  model.append(
    'other_taxpayer_identification_number_type',
    data.other_taxpayer_identification_number_type
  );
  model.append(
    'taxpayer_identification_number',
    data.taxpayer_identification_number
  );
  model.append('corporate_purpose', data.corporate_purpose);
  model.append('company_address', data.company_address);
  model.append('building', data.building);
  model.append('apt_number', data.apt_number);
  model.append('town', data.town);
  model.append('district', data.district);
  model.append('city', data.city);
  model.append('state', data.state);
  model.append('zip_code', data.zip_code);
  model.append('country_incorporation', data.country_incorporation);
  model.append(
    'registration_date',
    moment(data.registration_date).format('YYYY-MM-DD')
  );
  model.append('begin_date', moment(data.begin_date).format('YYYY-MM-DD'));
  model.append('linked_economic_group', data.linked_economic_group);
  model.append('company_phone', data.company_phone);
  model.append('company_email', data.company_email);
  model.append('banexcoin_user_account_id', UserAccountId);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
/*****************************************************SERVICES*************************************************/

export const getRucInfo = async (userId, ruc) => {
  let data = {
    ruc: ruc
  };
  let path = '/api/services/ruc';
  return HandlerBackend.sendRequest({
    userId,
    method: 'POST',
    path,
    data
  });
};
