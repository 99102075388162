import React, { useState } from 'react'
import PropTypes from "prop-types";
import { CardGradient } from '../../../components';

const ConfirmAddressModal = (props, context) => {
    const { handleSteps: { custom }, address, originForm, handleRequestDelivery } = props;
    const [loading, setLoading] = useState(false);
    const actions = [
        {   
            label: context.t("Previous"),
            action: () => custom(originForm || 1),
            className: "secondary",
        },
        {
            label: context.t("Understood"),
            action: async () => {
                setLoading(true);
                await handleRequestDelivery();
                setLoading(false);
            },
            otherAttributes: {
                disabled: loading  
            }
        },
    ];

    const addressConstructor = () => {
        if(address === "") return ""
        if(typeof address === "string") return address
        if(address.hasOwnProperty("urbanization")){
            return `${address.street}, ${address.building}, ${address.apt}, ${address.urbanization} - ${address.district}, ${address.city}, ${address.state}`
        } 
        return address.address
    }

    return (
        <CardGradient
            type='pending'
            actions={actions}
            customClassName='recharge-modal confirmation'
        >
            <h2>{context.t("Confirm your shipping address")}</h2>
            <div className='container-text'>
                <p>{context.t("We will send your Physical Banexcard to the following address")}:</p>
                <p className='address'><b>{addressConstructor()}</b></p>
                <p>{context.t("Remember that the delivery is Personal and you must present your DNI or CE.")}</p>
                <p>{context.t("In case we need to contact you, we will call you at the number")} xxx xxx 683.</p>
            </div>
        </CardGradient>
    )
}

ConfirmAddressModal.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default ConfirmAddressModal