import { connect } from 'react-redux';
import RecentTradesComponent from './RecentTradesComponent';
import { recentTradesSelector } from 'apex-web/lib/redux/selectors/recentTradesSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

const mapStateToProps = state => {
  const recentTrades = recentTradesSelector(state);
  const { fetching } = state.recentTrades;
  return {
    recentTrades,
    fetching,
    selectedInstrument: selectedInstrumentSelector(state)
  };
};

export default connect(mapStateToProps)(RecentTradesComponent);
