import isEmpty from "is-empty";
import { resendInformation, saveInformation, saveKYC } from "../services/IncrementLevelService";
import { getLevelBasicV3 } from "../../../components/KYC_IM/KYC_IMForm/KYC_IMFormStepComponentHooks";
import { convertSnakeCaseToCamelCase } from "../../utils";

class IncrementLevelController {
    static saveKYC = async ({
        userInfo,
        inputs,
        setCurrentStep,
        setLoadingActions,
        setErrorMessages,
        setErr,
    }) => {
        try {
            setLoadingActions(true)
            const data = new FormData();
            data.append("firstName", inputs.first_name);
            data.append("middleName", inputs.middle_name);
            data.append("lastName", inputs.last_name);
            data.append("secondLastName", inputs.second_last_name);
            data.append("birthday", inputs.birthday);
            data.append("isPep", inputs.is_pep);
            data.append("isInvoiceWithRuc", inputs.is_invoice_with_ruc);
            if (inputs.is_pep) data.append("pepDocument", inputs.pep_document);
            if (inputs.is_invoice_with_ruc) data.append("personalRuc", inputs.personal_ruc);
            await saveKYC(userInfo.UserId, data);
            setCurrentStep(2)
        } catch (error) {
            const data = error.response.data.data || {}
            if (!isEmpty(data)) {
                setErrorMessages((prev) => ({
                    ...prev,
                    pep_document: !isEmpty(data.pepDocument) ? data.pepDocument : '',
                    personal_ruc: !isEmpty(data.personalRuc) ? data.personalRuc : '',
                }))
                const err = {
                    pep_document: data.hasOwnProperty('pepDocument'),
                    personal_ruc: data.hasOwnProperty('personalRuc'),
                };
                Object.keys(err).forEach(key => {
                    if (!err[key]) delete err[key]
                })
                setErr((prev) => ({ ...prev, ...err }))
            }
        }
        finally {
            setLoadingActions(false)
        }
    };

    static saveInformation = async ({
        userInfo,
        inputs,
        setLoadingActions,
        setErrorMessages,
        handleFinalResult,
        setUserInfo,
    }) => {
        try {
            setLoadingActions(true)
            const data = {
                addressMain: inputs.address_main,
                building: inputs.building,
                apt: inputs.apt,
                town: inputs.town,
                residenceCountry: inputs.residence_country,
                state: inputs.state,
                city: inputs.city,
                district: inputs.district,
                zipCode: inputs.zip_code,
                occupation: inputs.occupation,
                occupationDetails: inputs.occupation_details,
                otherOccupation: inputs.other_occupation,
                workCenter: inputs.work_center,
                termsAndConditions: inputs.terms_and_conditions,
            };
            if (inputs.status !== 5) {
                await saveInformation(userInfo.UserId, data);
            } else {
                const newEditFields = JSON.parse(inputs.edit_fields)
                const resendData = new FormData();
                newEditFields.forEach((item) => {
                    resendData.append(convertSnakeCaseToCamelCase(item), inputs[item]);
                })
                await resendInformation(userInfo.UserId, resendData)
            }
            handleFinalResult(true)
            const info = await getLevelBasicV3(userInfo.UserId, userInfo.AccountId);
            setUserInfo(info.data)
        } catch (error) {
            const data = error.response.data.data || {}
            if (!isEmpty(data)) setErrorMessages((prev) => ({ ...prev, ...data }))
            handleFinalResult(false)
        }
        finally {
            setLoadingActions(false)
        }
    };

    static saveDirectInformation = async ({
        userInfo,
        setLoadingActions,
        handleFinalResult,
        setUserInfo,
        data,
    }) => {
        try {
            setLoadingActions(true)
            await saveInformation(userInfo.UserId, data);
            handleFinalResult(true)
            const info = await getLevelBasicV3(userInfo.UserId, userInfo.AccountId);
            setUserInfo(info.data)
        } catch (error) {
            handleFinalResult(false)
        }
        finally {
            setLoadingActions(false)
        }
    }
}

export default IncrementLevelController