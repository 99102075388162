import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import claimBackground from '../../../../images/claim-bg.png'
import LayoutAuth from '../../Components/LayoutAuth/LayoutAuth';
import ClaimsPageForm from './ClaimsPageForm';
import './claimPage.css'
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const LeftBoxInfo = ({context}) => {
  return (
    <div className='left-box-info'>
      <div className='title-left-box-info'>
        <h2>{context.t("Customer complaint book")}</h2>
        <p>Banexcoin S.A. - RUC 20603726139</p>
      </div>
      <div>
        <h2>{context.t("Claim")}</h2>
        <p>{context.t("Disagreement related to products or services.")}</p>
        <h2>{context.t("Complain")}</h2>
        <p>{context.t("Disagreement not related to the products or services; or, discomfort or dissatisfaction regarding the attention to the public.")}</p>
      </div>
    </div>
  )
}


function ClaimsPage(props,context) {
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    if (lang) {
      return localStorage.setItem('language', lang);
    }
  }, [])
  const url = 'https://www.banexcoin.com/';
  return (
    <React.Fragment>
      <LayoutAuth imageBackground={claimBackground} leftAuxChildren={<LeftBoxInfo context={context} />} url={url} twofa={false}>
      <Helmet
        title={context.t("Banexcoin - Customer complaint book")}>
        <link rel="canonical" href="https://www.banexcoin.com/libro-reclamaciones" />
      </Helmet>
        <React.Fragment>
          <ClaimsPageForm captcha={process.env.REACT_APP_RECAPTCHA} />
        </React.Fragment>
      </LayoutAuth>
    </React.Fragment>
  )
}

ClaimsPage.contextTypes = {
  t: PropTypes.func
};


export default ClaimsPage