import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { useForm } from '../../hooks/formHooks';
import {
  FormFieldIcon,
  FormField,
  FormButtonV2,
  FormSelector,
  FormDate,
  FormSingleCheckbox
} from '../../components/common/BnxFormsComponents';
import {
  getPublicCountries,
  cleanCustomError
} from '../bnxv2/hooks/signupHooks';

const UpdateInformationModalForm = (props, context) => {
  let {
    show,
    handleClose,
    updateInformationHook,
    UserId,
    selectedAccountId,
    setBlockedAll,
    logout,
    lang
  } = props;
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState({});
  const [generalErrors, setGeneralErrors] = useState({});

  const getCountries = async () => {
    try {
      let countriesReq = await getPublicCountries();
      if (countriesReq.hasOwnProperty('data') && !empty(countriesReq.data)) {
        let countriesData = countriesReq.data.countries;
        let listCountries = countriesData.map((c, i) => {
          return {
            value: c.countryCode,
            label: c.countryName
          };
        });
        setCountries(listCountries);
      }
    } catch (error) {
      console.log('Error on getCountries', error);
    }
  };

  const sendDecline = async () => {
    setLoading(true);
    try {
      if (empty(selectedAccountId) || empty(UserId)) {
        throw new Error('AccountId or UserId not provided');
      }

      let result = await logout();
      console.log('result Logout', result);
    } catch (error) {
      console.log('Error on sendAccept', error);
    }
    setLoading(false);
  };

  let accountType = [
    { value: 'EA', label: context.t('Corporate Account') },
    { value: 'PA', label: context.t('Personal Account') }
  ];

  const onSubmitCallback = async (inputs, form, newErrors) => {
    setLoading(true);
    try {
      if (empty(newErrors)) {
        let inputData = {
          ...inputs,
          userId: UserId,
          accountId: selectedAccountId
        };
        let result = await updateInformationHook(inputData, UserId);
        if (
          !empty(result) &&
          result.hasOwnProperty('data') &&
          !empty(result.data) &&
          result.data.message === 'success'
        ) {
          setBlockedAll(false);
        }
      }
    } catch (error) {
      console.log('Error on onSubmitCallback', error);
      let msg = '';
      if (
        error.hasOwnProperty('response') &&
        !empty(error.response.data.data)
      ) {
        console.error('error data', error.response.data);
        msg = error.response.data.data.error_message;
      } else {
        console.error('error', error);
        msg = error.message;
      }
      let cleanedError = cleanCustomError(msg);
      setGeneralErrors({
        multiple: cleanedError
      });
    }
    setLoading(false);
  };

  const customValidations = inputs => {
    let errors = {};
    if (!empty(inputs) && !empty(inputs.accountType)) {
      if (inputs.accountType === 'PA') {
        if (empty(inputs.birthCountry)) {
          errors.birthCountry = 'Required field';
        }
        if (empty(inputs.residenceCountry)) {
          errors.residenceCountry = 'Required field';
        }
        if (empty(inputs.documentType)) {
          errors.documentType = 'Required field';
        }
        if (!empty(inputs.residenceCountry) && !empty(inputs.documentType)) {
          if (
            inputs.residenceCountry === 'PE' &&
            inputs.documentType === 'RP'
          ) {
            if (empty(inputs.ceBirthday)) {
              errors.ceBirthday = 'Required field';
            }
          }
        }
        if (empty(inputs.documentNumber)) {
          errors.documentNumber = 'Required field';
        } else {
          let regexAlphanumeric = /^[a-z0-9]+$/i;
          var regexNumeric = /^\d+$/;
          if (
            inputs.documentType === 'RP' &&
            (inputs.documentNumber.length > 12 ||
              !regexAlphanumeric.test(inputs.documentNumber))
          ) {
            errors.documentNumber = 'Wrong document length or format';
          }
          if (
            inputs.documentType === 'ID' &&
            (inputs.documentNumber.length !== 8 ||
              !regexNumeric.test(inputs.documentNumber))
          ) {
            errors.documentNumber = 'Wrong document length or format';
          }
          if (
            inputs.documentType === 'PP' &&
            (inputs.documentNumber.length > 12 ||
              !regexAlphanumeric.test(inputs.documentNumber))
          ) {
            errors.documentNumber = 'Wrong document length or format';
          }
        }
      } else if (inputs.accountType === 'EA') {
        if (empty(inputs.incorporationCountry)) {
          errors.incorporationCountry = 'Required field';
        }
        if (empty(inputs.billingCountry)) {
          errors.billingCountry = 'Required field';
        }
        if (empty(inputs.documentType)) {
          errors.documentType = 'Required field';
        }
        if (empty(inputs.documentNumber)) {
          errors.documentNumber = 'Required field';
        }
      } else {
        errors.accountType = 'Incorrect Account Type';
      }
    } else {
      errors.accountType = 'Required field';
    }

    setErrors(errors);
    return errors;
  };

  let initialState = {
    validated: false
  };

  let {
    inputs,
    errors,
    setErrors,
    onInputChangeValidation,
    onCheckboxChangeValidation,
    onSubmitSimple,
    onInputChangeByName,
    onInputFileChange,
    setInputs
  } = useForm(initialState, onSubmitCallback, customValidations);

  useEffect(() => {
    getCountries();
  }, []);

  let optionsDocument = [];
  if (!empty(inputs) && inputs.residenceCountry === 'PE') {
    optionsDocument = [
      { value: 'ID', label: context.t('National identity document') },
      { value: 'RP', label: context.t('Residence Permit') }
    ];
  } else {
    optionsDocument = [{ value: 'PP', label: context.t('Passport') }];
  }

  const documentTypesCorporate = [
    { value: 'TIN', label: 'TIN' },
    { value: 'EIN', label: 'EIN' },
    { value: 'VAT', label: 'VAT' },
    { value: 'NINO', label: 'NINO' },

    { value: 'RUC', label: 'RUC' },
    { value: 'RUT', label: 'RUT' },
    { value: 'NIF', label: 'NIF' },
    { value: 'RIF', label: 'RIF' }
  ];

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-bnx-v2 lighting modal-disclaimer modal-updating-data">
        <div className={'bnx-v2-loading' + (loading ? ' show' : '')}>
          <Spinner animation="border" />
        </div>
        <Modal.Header>
          <Modal.Title
            dangerouslySetInnerHTML={{
              __html: context.t('Updating of <b>personal data</b>')
            }}
          />
        </Modal.Header>
        <Form noValidate validated={inputs.validated} onSubmit={onSubmitSimple}>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col xl="10">
                <FormSelector
                  required
                  name="accountType"
                  label="Select your account type"
                  defaultOption="Select your account type"
                  options={accountType}
                  onChange={onInputChangeValidation}
                  value={inputs.accountType}
                  errors={errors}
                />
              </Col>
              {!empty(inputs) &&
              !empty(inputs.accountType) &&
              !empty(inputs.accountType) ? (
                inputs.accountType === 'PA' ? (
                  <React.Fragment>
                    <Col xl="10">
                      <Row>
                        <Col xl="6">
                          <FormSelector
                            required
                            name="birthCountry"
                            label="Birth Country"
                            defaultOption="Birth Country"
                            options={countries}
                            onChange={onInputChangeValidation}
                            value={inputs.birthCountry}
                            errors={errors}
                          />
                        </Col>
                        <Col xl="6">
                          <FormSelector
                            required
                            name="residenceCountry"
                            label="Residence country"
                            defaultOption="Residence country"
                            options={countries}
                            onChange={onInputChangeValidation}
                            value={inputs.residenceCountry}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="10">
                      <Row className="mt-2">
                        <Col xl="6">
                          <FormSelector
                            required
                            name="documentType"
                            label="Document Type"
                            defaultOption="Document Type"
                            options={optionsDocument}
                            onChange={onInputChangeValidation}
                            value={inputs.documentType}
                            errors={errors}
                          />
                        </Col>
                        <Col xl="6">
                          <FormField
                            required
                            name="documentNumber"
                            label="Document Number"
                            placeholder="Document Number"
                            onChange={onInputChangeValidation}
                            value={inputs.documentNumber}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {!empty(inputs) &&
                    !empty(inputs.residenceCountry) &&
                    inputs.residenceCountry === 'PE' &&
                    !empty(inputs.documentType) &&
                    inputs.documentType === 'RP' ? (
                      <Col xl="10">
                        <Row className="mt-2">
                          <Col xl="6">
                            <FormDate
                              required
                              type="text"
                              name="ceBirthday"
                              label="Birthday"
                              placeholder="dd/MM/YYYY"
                              onChange={onInputChangeByName}
                              value={inputs.ceBirthday}
                              errors={errors}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                ) : inputs.accountType === 'EA' ? (
                  <React.Fragment>
                    <Col xl="10">
                      <Row>
                        <Col xl="6">
                          <FormSelector
                            required
                            name="incorporationCountry"
                            label="Country of incorporation"
                            defaultOption="Country of incorporation"
                            options={countries}
                            onChange={onInputChangeValidation}
                            value={inputs.incorporationCountry}
                            errors={errors}
                          />
                        </Col>
                        <Col xl="6">
                          <FormSelector
                            required
                            name="billingCountry"
                            label="Billing Country"
                            defaultOption="Billing Country"
                            options={countries}
                            onChange={onInputChangeValidation}
                            value={inputs.billingCountry}
                            errors={errors}
                          />
                        </Col>
                        <Col xl="6">
                          <FormSelector
                            required
                            name="documentType"
                            label="Document Type"
                            defaultOption="Document Type"
                            options={documentTypesCorporate}
                            onChange={onInputChangeValidation}
                            value={inputs.documentType}
                            errors={errors}
                          />
                        </Col>
                        <Col xl="6">
                          <FormField
                            required
                            name="documentNumber"
                            label="Document Number"
                            placeholder="Document Number"
                            onChange={onInputChangeValidation}
                            value={inputs.documentNumber}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </React.Fragment>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Row>
            {!empty(generalErrors) && !empty(generalErrors.main) ? (
              <Row className="justify-content-center mt-1 mb-4 error-msg">
                <Col xl="10">{context.t(generalErrors.main)}</Col>
              </Row>
            ) : (
              ''
            )}
            {!empty(generalErrors) && !empty(generalErrors.multiple) ? (
              <Row className="justify-content-center mt-1 mb-4 error-msg">
                <Col xl="10">
                  <ul className="error-box">
                    {generalErrors.multiple.map(e => <li>{context.t(e)}</li>)}
                  </ul>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary" className="btn-bnxv2">
              {context.t('Save')}
            </Button>
            <Button variant="secondary" onClick={() => sendDecline()}>
              {context.t('Try later')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

UpdateInformationModalForm.contextTypes = {
  t: PropTypes.func
};

const mapStateToProps = ({ i18nState: { lang } }) => {
  return { lang };
};

export default connect(mapStateToProps)(UpdateInformationModalForm);
