import React from 'react'
import PropTypes from 'prop-types'
import reverseBanexcard from '../../../../images/reverse-card.png'
import VerificationInput from "react-verification-input";
import FormButtonV2 from '../../../../components/common/BnxFormsComponents/FormButton';
import BanexcardController from '../../controllers/BanexcardController';
import { connect } from 'react-redux';
import './activation.css'
import { Spinner } from '../../../../components/Spinner/Spinner';
import { useHistory } from 'react-router-dom';
import { CardGradient, Loader } from '../../components';
import isEmpty from 'is-empty';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { deliveryStatuses } from '../../constants/deliveryStatuses';

function Activation(props, context) {
  const { userInfo, banexcard, hasBanexCard, loadingBanexCard, levelBasic } = props
  const [code, setCode] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState({})
  const ht = useHistory()
  const onChange = (ev) => {
    if(ev.target.value.length <= 8){
      setCode(ev.target.value)
    }
  }

  const handleActivation = async () => {
    setLoading(true)
    const response = await BanexcardController.setActivationCodeToAssociation({ userInfo, activationCode: code })
    setLoading(false)
    if(response){
      return setResult({
        type: "success",
        title: context.t("Card successfully activated"),
        message: context.t("Your Banexcard VISA Physical card has been successfully activated.\n Now you can use it in stores when making your purchases."),
        actions:[
          {
            label: context.t("Accept"),
            action: () => ht.push("/cards"),
          }
        ]
      })
    }{
      setResult({
        type: "error",
        title: context.t("Your card could not be activated"),
        message: context.t("We are sorry, your Banexcard VISA Physical could not be activated, please try again or write to us to help you by Chat or to soporte@banexcoin.com"),
        actions:[
          {
            label: context.t("Understood"),
            action: () => setResult({}),
          }
        ]
      })
    }
  }

  const isDisabled = code.length < 8 || loading

  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard || (!isEmpty(levelBasic.banexcardData) && levelBasic.banexcardData.isActive) || banexcard.banexcardRequestData.status !== deliveryStatuses.DELIVERED) return <Redirect to="/cards" />

  if(!isEmpty(result)){
    return <div className="activation" style={{maxWidth: "500px"}}>
      <CardGradient {...result} />
    </div>
  }
  return (
    <div className='activation'>
      <h2>{context.t("Activate Physical Banexcard")}</h2>
      <div className='banexcard-container'>
        <div className='activation-message'>
          <p>{context.t("Activation code")}</p>
          <p style={{margin:"0"}}>XXXX XXXXX</p>
        </div>
      <img src={reverseBanexcard} alt="banexcard reverse" style={{borderRadius:"3.5rem"}} width={300} />
      </div>
      <h4>{context.t("Enter the activation code")}</h4>
      <p className='description'>{context.t("This code is for one-time use. You can find it on the back of your physical card.")}</p>
      <VerificationInput
        removeDefaultStyles
        autoFocus={true}
        length={8}
        validChars="/^\d+$/"
        container={{
          className: "pt-4 pb-4",
        }}
        characters={{
          className: "characters",
        }}
        character={{
          className: "character",
          classNameInactive: "character--inactive",
          classNameSelected: "character--selected",
        }}
        inputField={{
          onChange: onChange,
        }}
      />
      <FormButtonV2 disabled={isDisabled} className="" onClick={isDisabled ? null : handleActivation}>
        {
          loading ? <Spinner /> : <span>{context.t("Activate card")}</span>
        }
      </FormButtonV2>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    levelBasic: state.completedUserInfo.level_basic,
    userInfo,
    banexcard: state.banexCard,
    loadingBanexCard
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
}

Activation.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
