import React, { useState } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import {
  Button,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Alert,
  InputGroup,
  Spinner
} from 'react-bootstrap';
import UploaderBnx from './UploaderBnx';
import PhoneInput from 'react-phone-input-2';
import { confirmAlert } from 'react-confirm-alert';
import CropImage from './CropImage';
import moment from 'moment';
import 'react-phone-input-2/lib/style.css';
import 'moment/locale/es.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { padStart, cleanText } from './../../../helpers/lib';
import Hexagon from './../../../images/hexagon.svg';

const myCustomLocale = {
  // months list by order
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],

  // week days by order
  weekDays: [
    {
      name: 'Domingo', // used for accessibility
      short: 'D', // displayed at the top of days' rows
      isWeekend: true // is it a formal weekend or not?
    },
    {
      name: 'Lunes',
      short: 'M'
    },
    {
      name: 'Martes',
      short: 'M'
    },
    {
      name: 'Miercoles',
      short: 'M'
    },
    {
      name: 'Jueves',
      short: 'J'
    },
    {
      name: 'Viernes',
      short: 'F'
    },
    {
      name: 'Sabado',
      short: 'S',
      isWeekend: true
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Siguiente mes',
  previousMonth: 'Mes anterior',
  openMonthSelector: 'Abrir selector de mes',
  openYearSelector: 'Abrir selector de año',
  closeMonthSelector: 'Cerrar selector de mes',
  closeYearSelector: 'Cerrar Selector de año',
  defaultPlaceholder: 'Seleccionar...',

  // for input range value
  from: 'de',
  to: 'a',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false
};

let localeData =
  (localStorage.getItem('language') || 'es') === 'es' ? myCustomLocale : 'en';

const disableCheck = (data, name, status, isEditable) => {
  let disableBool = false;
  if (empty(data[name]) && empty(status)) {
    return false;
  }
  let Rules = [
    {
      status: 1,
      disabled: true
    },
    {
      status: 5,
      disabled: true,
      hasEditables: true
    },
    {
      status: 6,
      disabled: false
    },
    {
      status: 7,
      disabled: true
    }
  ];
  let rule = Rules.find(r => r.status === status);
  if (!empty(rule)) {
    if (rule.disabled) {
      if (rule.hasEditables) {
        disableBool = isEditable ? false : true;
      } else {
        disableBool = true;
      }
    }
  }

  return disableBool;
};

const setDateVal = val => {
  let fullDate = `${val.year}-${padStart(val.month, 2, '0')}-${padStart(
    val.day,
    2,
    '0'
  )}`;
  return fullDate;
};

const getDateVal = val => {
  let day = parseInt(moment(val, 'YYYY-MM-DD').format('DD'));
  let month = parseInt(moment(val, 'YYYY-MM-DD').format('MM'));
  let year = parseInt(moment(val, 'YYYY-MM-DD').format('YYYY'));
  return {
    day,
    month,
    year
  };
};

const lng =  localStorage.getItem('language') || 'en';

const AutoFormV2 = (props, context) => {
  let { isDisabled, validateField, err, setErr, deleteFile = () =>{} } = props;

  const { steps, inputs, requestedLevel, setValidate = ()=>{}, isBanexCard = false, loadingActions = false } = props;
  let editableFields = [];
  if (inputs['edit_fields']) {
    editableFields = JSON.parse(inputs['edit_fields']);
  }
  var today = new Date();

  var currentdate =
    today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
  const StepFields = steps.map((x, i) => {
    let groups = x.groups;
    let indexTab = 1;

    const GroupFields = groups.map((x, i) => {
      let fields = x.fields;
      const FormFields = fields.map((x, i) => {
        let field = x;
        let isEditable = editableFields.includes(field.name);
        let One = [];
        let proper = !empty(field.props[0]) ? field.props[0] : {};
        let isRequired = !empty(proper.required) ? proper.required : false;
        let subdata = !empty(field.subdata) ? field.subdata : {};
        let placeh = empty(proper.placeholder)
          ? { placeholder: context.t(field.label) + (isRequired ? ' *' : '') }
          : {
              placeholder:
                context.t(proper.placeholder) + (isRequired ? ' *' : '')
            };
        let eight = proper.isEighteen
          ? {
              max: moment()
                .subtract(18, 'years')
                .format('YYYY-MM-DD')
            }
          : '';

        const isAlertModal = async (title, message, fieldname) => {
          confirmAlert({
            title: context.t(title),
            message: context.t(message),
            buttons: [
              {
                label: context.t('Got it'),
                className: 'btn-Got_it'
              },
              {
                label: context.t("Don't show again"),
                className: 'btn_no_show',
                onClick: () => {
                  localStorage.setItem(fieldname, 'false');
                }
              }
            ]
          });
        };

        let disabled_ =
          empty(inputs[field.name]) && empty(inputs.status)
            ? false
            : inputs.status === 6
              ? false
              : inputs.status === 5
                ? isEditable
                  ? false
                  : true
                : inputs.status === 1
                  ? true
                  : false;
        let disabled = isDisabled;
        let display = false;
        let coincidenceD = 0;
        if (!empty(field.displayOn)) {
          for (let i in field.displayOn) {
            let d = field.displayOn[i];
            if (d.hasOwnProperty('isEqualTo')) {
              if (d.isEqualTo) {
                if (inputs[d.parent] === d.conditionValue) {
                  coincidenceD++;
                }
              } else {
                if (
                  !empty(inputs[d.parent]) &&
                  inputs[d.parent] !== d.conditionValue
                ) {
                  coincidenceD++;
                }
              }
            } else {
              if (inputs[d.parent] === d.conditionValue) {
                coincidenceD++;
              }
            }
          }

          if (field.displayOn.length === coincidenceD) {
            display = true;
          }
        } else {
          display = true;
        }

        if (!empty(proper.placeholder)) {
          proper.placeholder = context.t(proper.placeholder);
        }

        if (
          field.type === 'text' ||
          field.type === 'number' ||
          field.type === 'email'
        ) {
          if (empty(field.displayOn) || display) {
            if (
              ((field.name === 'linked_economic_group_doc_type' ||
                field.name === 'linked_economic_group_name') &&
                field.props[0].disabled) ||
              (inputs.is_invoice_with_ruc === true &&
                inputs.is_sunat_validating === true)
            ) {
              disabled = true;
            }
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                <Form.Group
                  controlId={field.name}
                  className={
                    (inputs.status === 5 && isEditable
                      ? 'markaseditable'
                      : '') + (err[field.name] ? ' error-field-group' : '')
                  }
                  {...placeh}
                  {...proper}>
                  <InputGroup className="rounded-circle-box">
                    <Form.Label
                      className={
                        (inputs.status === 5 && isEditable
                          ? 'markaseditable'
                          : '') +
                        (empty(inputs[field.name]) ? ' hide-label' : '')
                      }
                      {...placeh}
                      {...proper}>
                      {context.t(field.label)}{' '}
                      {isRequired ? <span className="asterisk">*</span> : ''}
                    </Form.Label>
                    <Form.Control
                      name={field.name}
                      type={field.type}
                      {...placeh}
                      {...proper}
                      className={
                        inputs.status === 5 && isEditable
                          ? 'markaseditable'
                          : '' + (empty(inputs[field.name]) ? ' center_control' : '')
                      }
                      defaultValue={cleanText(inputs[field.name])}
                      value={cleanText(inputs[field.name])}
                      disabled={
                        inputs.status === 5
                          ? isEditable
                            ? false
                            : true
                          : disabled
                      }
                      onChange={props.onInputChange}
                      tabIndex={indexTab}
                    />
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">
                    {!empty(field.customError) ? context.t(field.customError) :
                        context.t('You must enter the {name} field', {
                          name: context.t(field.label)
                    })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          }
        } else if (field.type === 'date') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                <Form.Group
                  controlId={field.name}
                  className={
                    (inputs.status === 5 && isEditable
                      ? 'markaseditable'
                      : '') + (err[field.name] ? ' error-field-group' : '')
                  }>
                  <InputGroup className="rounded-circle-box">
                    <Form.Label
                      className={
                        empty(inputs[field.name]) ? ' hide-label' : ''
                      }>
                      {context.t(field.label)}{' '}
                      {isRequired ? (
                        <span className="asterisk">*</span>
                      ) : (
                        currentdate
                      )}
                    </Form.Label>
                    <DatePicker
                      formatInputText={()=>!empty(inputs[field.name])
                        ? moment(inputs[field.name]).format(lng === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY')
                        : proper.hasOwnProperty('isNormal') && proper.isNormal
                          ? moment().format(lng === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY')
                          : proper.hasOwnProperty('isEighteen') &&
                            proper.isEighteen
                            ? moment()
                                  .subtract(18, 'years')
                                  .format(lng === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY')
                              
                            : moment().format(lng === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY')}
                      className={(empty(inputs[field.name]) ? ' center_control' : '')}
                      value={
                        !empty(inputs[field.name])
                          ? getDateVal(
                              moment(inputs[field.name]).format('YYYY-MM-DD')
                            )
                          : proper.hasOwnProperty('isNormal') && proper.isNormal
                            ? getDateVal(moment().format('YYYY-MM-DD'))
                            : proper.hasOwnProperty('isEighteen') &&
                              proper.isEighteen
                              ? getDateVal(
                                  moment()
                                    .subtract(18, 'years')
                                    .format('YYYY-MM-DD')
                                )
                              : getDateVal(moment().format('YYYY-MM-DD'))
                      }
                      disabled={
                        inputs.status === 5
                          ? isEditable
                            ? false
                            : true
                          : disabled
                      }
                      onChange={e => {
                        props.onInputChangeByName(field.name, setDateVal(e));
                      }}
                      maximumDate={
                        !proper.hasOwnProperty('defaultMinDate')
                          ? proper.hasOwnProperty('isNormal') && proper.isNormal
                            ? getDateVal(
                                moment()
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')
                              )
                            : getDateVal(
                                moment()
                                  .subtract(18, 'years')
                                  .format('YYYY-MM-DD')
                              )
                          : getDateVal(
                              moment()
                                .add(50, 'years')
                                .format('YYYY-MM-DD')
                            )
                      }
                      minimumDate={
                        proper.hasOwnProperty('defaultMinDate') &&
                        !empty(proper.defaultMinDate)
                          ? getDateVal(
                              moment(proper.defaultMinDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            )
                          : getDateVal(
                              moment()
                                .subtract(80, 'years')
                                .format('YYYY-MM-DD')
                            )
                      }
                      inputPlaceholder="Select a date" // placeholder
                      inputClassName={
                        'form-control responsive-datepicker' +
                        (disabled ? ' disabled-input' : '')
                      } // custom class
                      shouldHighlightWeekends
                      locale={localeData}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the {name} field', {
                      name: context.t(field.label)
                    })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          }
        } else if (field.type === 'file') {
          if (empty(field.displayOn) || display) {
            One.push(
              <React.Fragment key={i}>
                {!empty(subdata) &&
                subdata[0].hasOwnProperty('stepTexts') &&
                !empty(subdata[0].stepTexts) ? (
                  <Col xl={!empty(field.size) ? field.size : '6'}>
                    {subdata[0].stepTitle ? (
                      <b className="v2-list-title">
                        {context.t(subdata[0].stepTitle)}
                      </b>
                    ) : (
                      ''
                    )}
                    <ol className="v2-list">
                      {subdata[0].stepTexts.map((t, i) => {
                        return (
                          <li
                            key={i}
                            dangerouslySetInnerHTML={{
                              __html: context.t(t.text)
                            }}
                          />
                        );
                      })}
                    </ol>
                  </Col>
                ) : (
                  ''
                )}
                {!empty(field.size) && field.size == '12' ? (
                  <Col xl={'12'} className="text-center mt-2 mb-2">
                    <img src={Hexagon} width="20%" />
                  </Col>
                ) : (
                  ''
                )}
                <Col
                  xl={!empty(field.size) ? field.size : '6'}
                  className={
                    inputs.status === 5 && isEditable ? 'markaseditable' : ''
                  }
                  {...placeh}
                  {...proper}>
                  <Form.Group
                    controlId={field.name}
                    className={`${err[field.name] ? ' error-field-group' : ''} ${(field.filetype === 'image' &&
                    inputs.status === 5 &&
                    isEditable
                      ? ' markaseditable'
                      : '')}`}>
                    <InputGroup
                      className={
                        'rounded-circle-box form_file_v2' +
                        (field.filetype !== 'image' &&
                        inputs.status === 5 &&
                        isEditable
                          ? ' markaseditable'
                          : '')
                      }>
                      <Form.Label
                        className={
                          (inputs.status === 5 && isEditable
                            ? 'markaseditable'
                            : '') +
                          (empty(inputs[field.name]) ? ' hide-label' : '')
                        }
                        {...placeh}
                        {...proper}>
                        {context.t(field.label)}{' '}
                        {isRequired ? <span className="asterisk">*</span> : ''}
                      </Form.Label>
                      {field.filetype === 'image' ? (
                        <CropImage
                          name={field.name}
                          {...proper}
                          subdata={subdata}
                          onInputFileChange={props.onInputFileChange}
                          deleteFile={() => deleteFile(field.name)}
                          disabled={
                            inputs.status === 5
                              ? isEditable
                                ? false
                                : true
                              : disabled
                          }
                          setBlobByName={props.setBlobByName}
                          imagesBlob={props.imagesBlob}
                          className={
                            inputs.status === 5 && isEditable
                              ? 'markaseditable'
                              : ''
                          }
                        />
                      ) : (
                        <UploaderBnx
                          name={field.name}
                          type={field.type}
                          className={
                            inputs.status === 5 && isEditable
                              ? 'markaseditable'
                              : ''
                          }
                          {...placeh}
                          {...proper}
                          subdata={subdata}
                          onInputFileChange={props.onInputFileChange}
                          disabled={
                            inputs.status === 5
                              ? isEditable
                                ? false
                                : true
                              : disabled
                          }
                          label={context.t(field.label)}
                        />
                      )}
                    </InputGroup>
                    <Form.Text
                      id={'subInfo' + field.name}
                      className="text-warning">
                      {!empty(subdata)
                        ? subdata.map(function(object, i) {
                            if (object.type === 'download-link') {
                              return (
                                <a
                                  href={context.t(object.href)}
                                  className={
                                    inputs.status === 5 && isEditable
                                      ? 'markaseditable'
                                      : ''
                                  }
                                  {...placeh}
                                  {...proper}
                                  key={i}
                                  target="_blank">
                                  {context.t(object.label)}
                                </a>
                              );
                            } else if (object.type === 'download-text') {
                              if (
                                !empty(object.href) &&
                                !empty(inputs[field.name])
                              ) {
                                return (
                                  <div key={i} className={object.className}>
                                    {context.t(object.label)}
                                  </div>
                                );
                              } else {
                                return (
                                  <span
                                    key={i}
                                    className={`contenedor ${
                                      inputs.status === 5 && isEditable
                                        ? 'markaseditable'
                                        : ''
                                    }`}
                                    {...placeh}
                                    {...proper}>
                                    {context.t(object.label)}
                                    <img src={context.t(object.href)} />
                                  </span>
                                );
                              }
                            }
                          })
                        : ''}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {inputs[field.name] === null ? 
                        context.t('The uploaded file must be a {accepted} type', {
                          accepted: field.props[0].accept
                        }) :
                        context.t('You must upload the {name} file', {
                        name: context.t(field.label)
                        })
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {!empty(field.additional) &&
                  <Col dangerouslySetInnerHTML={{__html: field.additional}}/>
                }
              </React.Fragment>
            );
          }
        } else if (field.type === 'select') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                <Form.Group
                  controlId={field.name}
                  className={
                    (inputs.status === 5 && isEditable
                      ? 'markaseditable'
                      : '') + (err[field.name] ? ' error-field-group' : '')
                  }
                  {...placeh}
                  {...proper}>
                  <InputGroup className="rounded-circle-box">
                    <Form.Label
                      className={
                        (inputs.status === 5 && isEditable
                          ? 'markaseditable'
                          : '') +
                        (empty(inputs[field.name]) && inputs[field.name] !== 0
                          ? ' hide-label'
                          : '')
                      }
                      {...placeh}
                      {...proper}>
                      {context.t(field.label)}{' '}
                      {isRequired ? <span className="asterisk">*</span> : ''}
                      {!empty(proper.title) && !empty(proper.message) ? (
                        <span
                          onClick={() => {
                            if (
                              !empty(proper.title) &&
                              !empty(proper.message)
                            ) {
                              isAlertModal(
                                context.t(proper.title),
                                context.t(proper.message),
                                'modal_alert_' + field.name
                              );
                            }
                          }}
                          className="info_alert">
                          ?
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      onClick={() => {
                        if (!empty(proper.title) && !empty(proper.message)) {
                          let isALert = localStorage.getItem(
                            'modal_alert_' + field.name
                          );
                          if (empty(isALert) || isALert === 'true') {
                            isAlertModal(
                              context.t(proper.title),
                              context.t(proper.message),
                              'modal_alert_' + field.name
                            );
                          }
                        }
                      }}
                      as={field.type}
                      name={field.name}
                      type={field.type}
                      className={
                        inputs.status === 5 && isEditable
                          ? 'markaseditable'
                          : '' + (empty(inputs[field.name]) ? ' center_control' : '')
                      }
                      {...placeh}
                      {...proper}
                      onChange={props.onInputChange}
                      disabled={
                        inputs.status === 5
                          ? isEditable
                            ? false
                            : true
                          : disabled
                      }
                      value={inputs[field.name]}
                      tabIndex={indexTab}
                      isInvalid={!empty(err[field.name]) ? true : false}>
                      {!empty(field.options) ? (
                        <React.Fragment>
                          <option value="" className='display-none'>
                            {context.t(placeh.placeholder)}
                          </option>
                          {field.options.map((option, index) => {
                            var keys = Object.keys(option);
                            return (
                              <option
                                key={index}
                                value={option[keys[0]]}
                                className={
                                  option[keys[0]] === '' ? 'title_select' : ''
                                }
                                disabled={
                                  keys.includes('label') ? true : false
                                }>
                                {context.t(option[keys[1]])}
                              </option>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        <option value="-1" className='display-none'>{context.t(x.loaderText || 'Select option')}</option>
                      )}
                    </Form.Control>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the {name} field', {
                      name: context.t(field.label)
                    })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          }
        } else if (field.type === 'option-button') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                <Form.Group controlId={field.name}>
                  <Form.Label
                    className={
                      (inputs.status === 5 && isEditable
                        ? 'markaseditable'
                        : '') +
                      (err[field.name] ? ' error-field-group' : '') +
                      (empty(inputs[field.name]) ? ' hide-label' : '')
                    }
                    {...placeh}
                    {...proper}>
                    {context.t(field.label)}{' '}
                    {isRequired ? <span className="asterisk">*</span> : ''}
                  </Form.Label>
                  {!empty(field.options) ? (
                    <React.Fragment>
                      {field.options.map((option, index) => {
                        var keys = Object.keys(option);
                        return (
                          <>
                            <label
                              for={'ra' + field.name + index}
                              className={
                                inputs.status === 5 && isEditable
                                  ? 'markaseditable'
                                  : ''
                              }
                              {...placeh}
                              {...proper}>
                              {context.t(option[keys[1]])}
                            </label>
                            <input
                              onClick={() => {
                                let grupos =
                                  steps[props.currentStep - 1].groups;
                                let validar = validateField(grupos, inputs);
                                setErr(validar);
                                if (empty(validar)) {
                                  setValidate(false);
                                  props.setCurrentStep(props.currentStep + 1);
                                } else {
                                  setValidate(true);
                                }
                              }}
                              type="radio"
                              onChange={props.onInputChange}
                              id={'ra' + field.name + index}
                              name={field.name}
                              value={option[keys[0]]}
                              className={
                                inputs.status === 5 && isEditable
                                  ? 'markaseditable'
                                  : ''
                              }
                              {...placeh}
                              {...proper}
                            />
                          </>
                        );
                      })}
                    </React.Fragment>
                  ) : null}
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the {name} field', {
                      name: context.t(field.label)
                    })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          }
        } else if (field.type === 'tel') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                <Form.Group
                  controlId={field.name}
                  className={
                    (!empty(inputs[field.name])
                      ? inputs[field.name].length < 9
                        ? 'invalid'
                        : 'valid'
                      : 'invalid') +
                    (err[field.name] ? ' error-field-group' : '')
                  }>
                  <InputGroup className="rounded-circle-box">
                    <Form.Label>
                      {context.t(field.label)}{' '}
                      {isRequired ? <span className="asterisk">*</span> : ''}
                    </Form.Label>
                    <PhoneInput
                      inputClass={'form-pay-phone-custom'}
                      onChange={value => {
                        props.onInputChangeByName(field.name, value);
                      }}
                      country={'pe'}
                      value={inputs.phone || ''}
                      isValid={(value, country) => {
                        if (value.length < 9) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      inputProps={{
                        id: field.name,
                        name: field.name,
                        requiredmessage: context.t(
                          'You must enter the phone number'
                        ),
                        required: isRequired,
                        tabIndex: indexTab,
                        autoComplete:
                          proper.autoComplete === field.name ? field.name : 'on'
                      }}
                      disabled={
                        inputs.status === 5
                          ? isEditable
                            ? false
                            : true
                          : disabled
                      }
                      className={
                        inputs.status === 5 && isEditable
                          ? 'markaseditable'
                          : ''
                      }
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {context.t('You must enter the {name} field', {
                      name: context.t(field.label)
                    })}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            );
          }
        } else if (field.type === 'info') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col xl={!empty(field.size) ? field.size : '6'} key={i}>
                {field.subdata.type === 'text' ? (
                  <>
                    <Form.Group
                      className={
                        (field.subdata.type === 'text' ? 'info_self' : '') +
                        (err[field.name] ? ' error-field-group' : '')
                      }
                      controlId={field.name}>
                      <Form.Label>
                        {context.t(field.label)}{' '}
                        {isRequired ? <span className="asterisk">*</span> : ''}
                      </Form.Label>
                      <Form.Text className="text-muted">
                        {context.t(field.subdata.content)}
                      </Form.Text>
                    </Form.Group>
                  </>
                ) : null}
                {field.subdata.type === 'image' ? (
                  <>
                    <Form.Label>
                      {context.t(field.label)}{' '}
                      {isRequired ? <span className="asterisk">*</span> : ''}
                    </Form.Label>
                    <Form.Group controlId={field.name}>
                      <Form.Text className="selfie">
                        <img src={context.t(field.subdata.content)} />
                      </Form.Text>
                    </Form.Group>
                  </>
                ) : null}
              </Col>
            );
          }
        } else if (field.type === 'checkbox') {
          if (empty(field.displayOn) || display) {
            One.push(
              <Col
                xl={!empty(field.size) ? field.size : '6'}
                key={i}
                className={
                  !empty(proper.parentClassName) ? proper.parentClassName : ''
                }>
                <Form.Group
                  controlId={field.name}
                  className={err[field.name] ? ' error-field-group' : ''}>
                  <Form.Check
                    inline
                    className={
                      inputs.status === 5 && isEditable ? 'markaseditable' : ''
                    }>
                    <Form.Check.Input
                      type={field.type}
                      name={field.name}
                      isValid
                      onChange={props.onInputChange}
                      checked={inputs[field.name]}
                      disabled={
                        inputs.status === 5
                          ? isEditable
                            ? false
                            : true
                          : disabled
                      }
                      {...proper}
                    />
                    <Form.Check.Label
                    className='checkbox_form_v2' dangerouslySetInnerHTML={{ __html: `${context.t(field.label)}`}}>
                    </Form.Check.Label>
                    {isRequired ? <span class="asterisk">*</span> : ''}
                    {!empty(proper.hasOwnProperty('popup')) &&
                    !empty(proper.popup) &&
                    !empty(proper.popup.title) &&
                    !empty(proper.popup.message) ? (
                      <React.Fragment>
                        &nbsp;&nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="2x"
                          onClick={() => {
                            if (
                              !empty(proper.popup.title) &&
                              !empty(proper.popup.message)
                            ) {
                              isAlertModal(
                                context.t(proper.popup.title),
                                context.t(proper.popup.message),
                                'modal_alert_' + field.name
                              );
                            }
                          }}
                        />
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                    {/****FontAwesome info****/}
                    {field.name === 'is_invoice_with_ruc' ? (
                      <React.Fragment>
                        <div>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="2x"
                            title={context.t(
                              'By selecting this option you must indicate your personal RUC,\notherwise your invoices will be issued by your identity document.'
                            )}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </Form.Check>
                  <Form.Control.Feedback type="invalid">
                    {!empty(field.customError) && context.t(field.customError)}
                  </Form.Control.Feedback>
                  {inputs.is_sunat_validating === true &&
                  field.name === 'is_invoice_with_ruc' ? (
                    <div style={{ color: 'gray', marginTop: '25px' }}>
                      {context.t('Searching RUC Information, please wait...')}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Col>
            );
          }
        }
        ++indexTab;
        return <React.Fragment key={i}>{One}</React.Fragment>;
      });

      return (
        <div className="v2-group-fields" key={i}>
          <h3>{context.t(x.title)}</h3>
          <Row>{FormFields}</Row>
        </div>
      );
    });

    let currentChild = i + 1;

    let isCurrent = props.currentStep === currentChild ? true : false;

    return (
      <React.Fragment key={i}>
        <div
          className={
            'step-container ' +
            (isCurrent ? 'active' : '') +
            ' paso' +
            currentChild +
            ` ${props.className || ''}`
          }>
          <h1
            className="v2-general-title"
            dangerouslySetInnerHTML={{
              __html: context.t(props.title || 'Verify your identity')
            }}
          />
          {GroupFields}

          {!empty(props.messageData) ? (
            <Row className="message-box">
              <Col xl="12">
                {props.messageData.type === 'error' ? (
                  <Alert variant={'danger'}>{props.messageData.text}</Alert>
                ) : (
                  <Alert variant={'success'}>{props.messageData.text}</Alert>
                )}
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row>
            <Col xl="12" className="text-center">
              {steps.length > 1 ? (
                <div className="steps-bottom">
                  {Array.apply(null, { length: steps.length }).map((p, i) => {
                    return (
                      <React.Fragment key={i}>
                        <span
                          className={`step-bttm ${
                            i + 1 === props.currentStep
                              ? 'active'
                              : i + 1 < props.currentStep
                                ? 'previous'
                                : ''
                          }`}>
                          {i + 1}
                        </span>
                        {i + 1 < steps.length ? (
                          <span className="step-line" />
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
          {(!isDisabled && inputs.status !== 4) &&
            <Row>
              <Col xl="12" className="information_form_v2">
              *{context.t('Please make sure that the personal data is correctly entered, before sending the information')}.
              </Col>
            </Row>
          }
          <Row className="h-auto v2-form-bottom">
            <Col xl="12" className="text-right">
              {steps.length > 1 ? (
                props.currentStep > 1 && props.currentStep <= steps.length ? (
                  <Button
                    variant="light"
                    type="button"
                    disabled={
                      isBanexCard ? loadingActions : inputs.is_sunat_validating === true ? true : false
                    }
                    onClick={() => {
                      if(loadingActions) return;
                      props.setCurrentStep(props.currentStep - 1);
                    }}>
                      {context.t('Back')}
                  </Button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {!isDisabled ? (
                <React.Fragment>
                  <Button
                    variant="light"
                    className={
                      empty(err) && inputs.validated ? 'validated-success' : ''
                    }
                    type={(empty(err) && inputs.status !== 4) ? 'submit': 'button'}
                    style={{
                      display:
                        steps.length === props.currentStep ? 'block' : 'none'
                    }}
                    disabled={
                      isBanexCard ? loadingActions :
                      requestedLevel === 2
                        ? false
                        : !empty(inputs)
                          ? !props.isShareholder
                            ? empty(inputs.legal_entity_type)
                              ? true
                              : false
                            : false
                          : false
                    }>
                      {loadingActions ? <Spinner animation="border" variant="light" /> : context.t( !empty(props.submitText) ? props.submitText : 'Submit')}
                  </Button>
                </React.Fragment>
              ) : (
                ''
              )}
              {steps.length !== props.currentStep ? (
                props.currentStep < steps.length ? (
                  <Button
                    disabled={
                      isBanexCard ? loadingActions :
                      (inputs.hasOwnProperty('linked_economic_group') &&
                        inputs.linked_economic_group === 'Yes' &&
                        empty(inputs.linked_economic_group_name)) ||
                      (inputs.hasOwnProperty('is_invoice_with_ruc') &&
                        inputs.hasOwnProperty('personal_ruc') &&
                        inputs.is_invoice_with_ruc === true &&
                        (inputs.personal_ruc.length != 11 ||
                          inputs.is_sunat_validating === true))
                        ? true
                        : false
                    }
                    variant="light"
                    type="button"
                    className={ 
                      empty(err) && inputs.validated ? 'validated-success' : 'secondary-color'
                    }
                    onClick={() => {
                      if(loadingActions) return;
                      let grupos = steps[props.currentStep - 1].groups;
                      let validar = validateField(grupos, inputs);
                      setErr(validar);
                      if (empty(validar)) {
                        setValidate(false);
                        props.setCurrentStep(props.currentStep + 1);
                      } else {
                        setValidate(true);
                      }
                    }}>
                      {loadingActions ? <Spinner animation="border" variant="light" /> : context.t('Next')}
                  </Button>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  });

  return (
    <div>
      <Row>
        <div className={`step-container ${[1,5,4].includes(inputs.status) ? 'active': ''} ${props.className}`}>
          {inputs.status === 1 && (inputs.next_level !== inputs.user_level) ? (
            <Row className="message-box">
              <Col xl="12">
                <Alert variant={'success'}>
                  {context.t(
                    'Your request is under review. Within the next 24 business hours, you will receive a notification via email.'
                  )}
                </Alert>
              </Col>
            </Row>
          ) : inputs.status === 5 ? (
            <Row className="message-box">
              <Col xl="12">
                <Alert variant={'warning'}>
                  {context.t(
                    'Your request has been observed, please proceed to correct the data in the fields highlighted in orange.'
                  )}
                </Alert>
              </Col>
            </Row>
          ) : inputs.status === 4 ? (
            <Row className="message-box">
              <Col xl="12">
                <Alert variant={'warning'}>
                  <p dangerouslySetInnerHTML={{
                      __html: context.t(
                        "I'm sorry, but we couldn't approve your request because we found inconsistent information or multiple requests from you. If you think we made a mistake, please let us know by emailing us at {email} or contacting us at {link}. We're here to assist you!",
                        {
                          email: '<a class="text-link email" href="mailto:soporte@banexcoin.com">soporte@banexcoin.com</a>',
                          link: '<a class="text-link" href="https://soporte.banexcoin.com">soporte.banexcoin.com</a>'
                        },
                      )
                  }}/>
                </Alert>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>
        {StepFields}
      </Row>
    </div>
  );
};

AutoFormV2.contextTypes = {
  t: PropTypes.func.isRequired
};

export default AutoFormV2;
