import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import Header from './KYCComponent/KYCV2Header';
import './KYCV2Page.css';
import imgIndependent from './../../images/independent-worker.png';
import imgDependent from './../../images/dependent-worker.png';
import apex from 'apex-web/lib/apex';
import LoadingBnx from './../../components/LoadingBnx';

const mapStateToProps = ({ user: { selectedAccountId } }) => {
  return { selectedAccountId };
};

const KYCV2VIPPage = (props, context) => {
  try {
    const { selectedAccountId, blocked } = props;
    const [vipConfig, setVipConfig] = useState({});
    document.title = `Banexcoin - ${context.t('VIP Level')}`;
    const [loading, setLoading] = useState(0);

    const getAccountConfig = async () => {
      setLoading(1);
      try {
        let gac = await apex.connection.GetAccountConfig({
          AccountId: selectedAccountId,
          OMSId: 1
        });
        let kycType = gac.find(a => a.Key === 'KYC_VIP');
        setVipConfig(kycType);
      } catch (error) {
        console.log('Error on getAccountConfig', error);
      }
      setLoading(0);
    };

    useEffect(
      () => {
        if (!empty(selectedAccountId)) {
          getAccountConfig();
        }
      },
      [selectedAccountId]
    );

    return (
      <React.Fragment>
        <LoadingBnx loading={loading} />
        <div className={'v2-bnx lighting' + (blocked ? ' blocked-window' : '')}>
          <Header />
          <div className="container pb-5">
            <div className="v2-step">
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <div className="box-light">
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: context.t('<b>Master</b> level increase')
                      }}
                    />
                    <p>{context.t('Select the way you receive your income')}</p>
                  </div>
                </Col>
                {empty(vipConfig) ||
                (!empty(vipConfig) && vipConfig.Value === 'DEPENDENT') ? (
                  <Col md={{ span: 6, offset: 3 }}>
                    <Link
                      className="box-light onerow"
                      to="/verification/master-dependent">
                      <div className="text-box">
                        <b>{context.t('Dependent worker')}</b>
                      </div>
                      <div className="image-box cloudbg">
                        <img src={imgDependent} />
                      </div>
                    </Link>
                  </Col>
                ) : (
                  ''
                )}
                {empty(vipConfig) ||
                (!empty(vipConfig) && vipConfig.Value === 'INDEPENDENT') ? (
                  <Col md={{ span: 6, offset: 3 }}>
                    <Link
                      className="box-light onerow"
                      to="/verification/master-independent">
                      <div className="text-box">
                        <b>{context.t('Independent worker')}</b>
                      </div>
                      <div className="image-box cloudbg">
                        <img src={imgIndependent} />
                      </div>
                    </Link>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('KYCV2VIPPage error', e);
    return <div>Error:</div>;
  }
};

KYCV2VIPPage.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  null
)(KYCV2VIPPage);
