import React from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import HoverImage from 'react-hover-image';
import logo from '../../../images/isotipo.svg';
import logoBanex from '../../../images/logo-banexcoin-white-middleorange.svg';
import logoBanexDark from '../../../images/logo-banexcoin.svg';
import './APLogo.css';

const APLogo = props => {
  const { customClass, theme } = props;
  const bemClasses = getBEMClasses('ap-logo', customClass);

  return (
    <div className={bemClasses() + ' my-first-step'}>
      <img
        src={!empty(theme) && theme === 'light' ? logoBanexDark : logoBanex}
        className={bemClasses('img')}
        alt="Logo"
      />
    </div>
  );
};

APLogo.defaultProps = {
  customClass: ''
};

APLogo.propTypes = {
  customClass: PropTypes.string
};

export default APLogo;
