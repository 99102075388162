
import { connect } from "react-redux";
import ExchangePage from "./ExchangePage";
import PropTypes from "prop-types";
import resize from 'apex-web/lib/hocs/resize';
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import { productPositionAssetsSelector } from "apex-web/lib/redux/selectors/productPositionSelectors";

const mapStateToProps = (state) => {
  const {
    apexCore: {
      instrument: { instruments, selectedInstrumentId },
      level1
    },
    user: {
      userInfo: { UserName },
      verificationLevelConfigs
    },
    instrumentsByAccountId
  } = state;
  return {
    instruments,
    level1,
    selectedInstrumentId,
    currentUserName: UserName,
    instrumentsByAccountId,
    verificationLevelConfigs,
    products: productPositionAssetsSelector(state),
    isAuthenticated: state.auth.isAuthenticated,
  };
};

ExchangePage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  selectInstrument
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(resize(ExchangePage));
