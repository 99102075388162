import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getBEMClasses } from '../helpers/cssClassesHelper';

import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';

import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import PropTypes from 'prop-types';

import './BuySellPage.css';
import BuySellV2Component from './BuySellV2/BuySellV2Component';

const classes = getBEMClasses('eotc-buy-sell-layout');

const BuySellPage = (props, context) => {

  useEffect(() => {
    document.body.classList.add('no_snackbar');
    return () => {
      document.body.classList.remove('no_snackbar');
    };
  }, []);

  return (
    <div className='new-buy-sell-component'>
      <h1 className={classes('title')}>{context.t('Buy and Sell')}</h1>
      <div>
        <BuySellV2Component />
      </div>
      <div className={classes('footer')}>
        <PageFooterLayout />
      </div>
    </div>
  )
};

BuySellPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  const { user: { userInfo } } = state;
  return {
    userInfo
  };
};

export default withAuthentication(connect(mapStateToProps, null)(BuySellPage));
