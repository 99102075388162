import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { parseNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { InnerInput } from 'apex-web/lib/components/common/APInput';
import BigNumber from 'bignumber.js';
let _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


const separators = ['.', ','];

const InnerNumberInput = function (_React$Component) {
    _inherits(InnerNumberInput, _React$Component);
    function InnerNumberInput() {
        let _ref;

        let _temp, _this, _ret, args;

        _classCallCheck(this, InnerNumberInput);

        for (let _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = InnerNumberInput.__proto__ || Object.getPrototypeOf(InnerNumberInput)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            displayValue: ''
        }, _this.handleChange = function (e) {
            const _this$props = _this.props,
                allowNegative = _this$props.allowNegative,
                customChange = _this$props.customChange;

            const inputValue = e.target.value;

            const value = parseNumberToLocale(inputValue, null, !allowNegative);
            const validSeparator = !inputValue.includes('..') && !inputValue.includes(',,');

            if (!Number.isNaN(value) && _this.validateDecimalPlaces(inputValue) && validSeparator) {
                _this.setState({ displayValue: inputValue });
                _this.props.input.onChange(value);

                if (customChange) {
                    customChange(value);
                }
            }
        }, _this.handleFocus = function (e) {
            if (Number(_this.state.displayValue) === 0) {
                _this.setState({ displayValue: '' });
            }
        }, _this.validateDecimalPlaces = function (valueString) {
            const decimalPlaces = _this.props.decimalPlaces;

            if (decimalPlaces >= 0) {
                const decimals = valueString.split('.')[1];

                return !decimals || decimals.length <= decimalPlaces;
            }

            return true;
        }, _this.isSeparator = function (value) {
            return separators.includes(value);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }
    _createClass(InnerNumberInput, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            const input = this.props.input.value
            const display= this.state.displayValue
            const handleValue = (type) =>{
                if(type === "buy-sell"){
                    return (typeof input === "object") ? new BigNumber(input).toFixed() : input
                }else{
                    return (typeof display === "object") ? new BigNumber(display).toFixed() : display
                }
            }
            if (prevProps.input.value !== this.props.input.value) {
                if (this.props.input.value === '' || Number(this.state.displayValue) !== 0 && (Number.isNaN(Number(this.props.input.value))) && !this.isSeparator(this.state.displayValue)) {
                    this.setState({
                        displayValue: this.props.input.value
                    });
                } else
                 if (!this.isSeparator(this.state.displayValue)) {
                    this.setState({
                        displayValue: handleValue(this.props.customClass)
                    });
                }
            }
            if(prevProps.max !== this.props.max){
                const balance = this.props.balance.replaceAll(',','')
                this.handleChange({ target:{ value: balance }})
            }
        }
    }, {
        key: 'render',
        value: function render() {
            const _this2 = this;

            const _props = this.props,
                input = _props.input,
                passedProps = _objectWithoutProperties(_props, ['input', 'allowNegative', 'customChange', 'decimalPlaces']);

            return React.createElement(InnerInput, Object.assign({
                step: 'any'
            }, passedProps, {
                autoComplete: 'off',
                type: 'number',
                input: Object.assign({}, input, {
                    value: this.state.displayValue,
                    onChange: this.handleChange,
                    onBlur: function onBlur() {
                        return input.onBlur();
                    }
                }),
                onClick: function onClick() {
                    input.onFocus();
                    _this2.handleFocus('onClick');
                },
                onFocus: function onFocus() {
                    input.onFocus();
                    _this2.handleFocus('onFocus');
                }
            }));
        }
    }]);

    return InnerNumberInput;
}(React.Component);

let APNumberInput = function APNumberInput(props) {
    return React.createElement(Field, Object.assign({ name: props.name, component: InnerNumberInput }, props));
};

APNumberInput.defaultProps = {
    allowNegative: false
};

APNumberInput.propTypes = {
    allowNegative: PropTypes.bool,
    decimalPlaces: PropTypes.number
};

export default APNumberInput;