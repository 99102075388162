import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import AffiliateActiveTagContainer from 'apex-web/lib/components/AffiliateComponents/AffiliateActiveTagContainer';
import AffiliateCountContainer from './AffiliateCountContainer';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import path from '../helpers/path';
import config from '../config';
import './AffiliateProgramPage.css';

const affiliateProgramPageClasses = getBEMClasses('affiliate-program-page');

const AffiliateProgramPage = (props, context) => {
  let { Use2FA, accounts, selectedAccountId } = props;
  let currentAccount = accounts.filter(
    account => account.AccountId == selectedAccountId
  );
  if (empty(currentAccount)) {
    return (
      <div className={affiliateProgramPageClasses()}>
        {context.t('Loading')}
      </div>
    );
  } else {
    if (currentAccount[0].VerificationLevel === 0) {
      return (
        <div className={affiliateProgramPageClasses()}>
          <h1 className={affiliateProgramPageClasses('header')}>
            {context.t('You need to increase your verification level')}
          </h1>
          <p className={affiliateProgramPageClasses('description')}>
            {context.t(
              'For your security, some of our services require you to provide basic or additional levels of verification.'
            )}
            {context.t(
              'To verify your account or to check what your current verification level is, see below.'
            )}
            <br />
            <br />
            <Link
              to="/settings/verification-level"
              className="btn btn-success orange_custom p-3">
              {context.t('Start Verification')}
            </Link>
          </p>
        </div>
      );
    }
  }
  return (
    <div className={affiliateProgramPageClasses()}>
      <div className={affiliateProgramPageClasses('main-header')}>
        <h1 className={affiliateProgramPageClasses('header')}>
          {context.t('Join Banexcoin Referral Program​: Refer a friend and win.', {
            n: config.global.siteName
          })}
        </h1>
        <p className={affiliateProgramPageClasses('description')}>
          {context.t(
            `In Banexcoin, for each user you refer, you will earn when they carry out an exchange.\n \n Our referral program allows you to obtain a bonus for each exchange transaction carried out by a user who has registered with your referral link. The bonus consists of 10% for the referral based on the commissions that Banexcoin charges for exchange, buy and sale operations in the pairs listed on the platform. You can refer by sharing your link to as many people as you want, there is no limit of referrals.\n \n How does it work? Create an referral link, copy it, and share it with your friends. They can click on the referral link and sign up for an account, you will see increase the number of referrals on this page.`
          )}
        </p>
      </div>
      <div className={affiliateProgramPageClasses('body')}>
        <div className={affiliateProgramPageClasses('container')}>
          <div className={affiliateProgramPageClasses('container-header')}>
            <h1
              className={affiliateProgramPageClasses(
                'container-header--title'
              )}>
              {context.t('Referral Code​')}
            </h1>
          </div>
          <AffiliateActiveTagContainer
            route={`${window.location.origin}${path('')}/signup?aff=`}
          />
        </div>
        <div className={affiliateProgramPageClasses('container')}>
          <div className={affiliateProgramPageClasses('container-header')}>
            <h1
              className={affiliateProgramPageClasses(
                'container-header--title'
              )}>
              {context.t('Number of Active Referrals')}
            </h1>
          </div>
          <AffiliateCountContainer />
        </div>
      </div>
    </div>
  );
};

AffiliateProgramPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: {
    selectedAccountId,
    accounts,
    userInfo: { Use2FA }
  }
}) => {
  return { selectedAccountId, accounts, Use2FA };
};

export default connect(mapStateToProps)(AffiliateProgramPage);
