import React from 'react';
import { connect } from 'react-redux';
import empty from 'is-empty';
import { Redirect } from 'react-router-dom';
import { redirectFromLogin } from 'apex-web/lib/redux/actions/authActions';
import FullPageSpinner from 'apex-web/lib/components/common/FullPageSpinner/FullPageSpinner';
import path from 'apex-web/lib/helpers/path';
import LoadingBnx from './../components/LoadingBnx';

function withAuthenticationCoink(WrappedComponent) {
  const ModifiedComponent = props => {
    let pathroute = !empty(props.location)
      ? props.location.pathname.split('/')
      : '/';
    let token = pathroute[pathroute.length - 1];
    const {
      isConnected,
      isAuthenticated,
      location,
      pendingAuth,
      redirectFromLogin
    } = props;
    let Aptoken = localStorage.getItem('token');

    if (
      (!empty(Aptoken) && !isAuthenticated && !isConnected) ||
      (!empty(Aptoken) && !isAuthenticated && isConnected)
    ) {
      return <LoadingBnx loading={1} />;
    }
    if (pendingAuth) {
      return <FullPageSpinner />;
    }
    if (!isAuthenticated) {
      redirectFromLogin(location);
      return (
        <Redirect
          to={path(`/login`)}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = state => {
    return {
      isConnected: state.wsConnection.isConnected,
      isAuthenticated: state.auth.isAuthenticated,
      pendingAuth: state.auth.pending
    };
  };

  const mapDispatchToProps = dispatch => ({
    redirectFromLogin: location => dispatch(redirectFromLogin(location))
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModifiedComponent);
}

export default withAuthenticationCoink;
