import React from 'react';
import PropTypes from 'prop-types';
import Recommendations from './Recommendations'
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { formatNumbersWithoutRound, excludeProductSymbols, FormatDecimalCoinkWithSimbols } from './../../../../pages/utils'
import empty from 'is-empty';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var FiatWithdrawDetailsComponent = (function(_React$Component) {
  _inherits(FiatWithdrawDetailsComponent, _React$Component);

  function FiatWithdrawDetailsComponent() {
    _classCallCheck(this, FiatWithdrawDetailsComponent);

    return _possibleConstructorReturn(
      this,
      (
        FiatWithdrawDetailsComponent.__proto__ ||
        Object.getPrototypeOf(FiatWithdrawDetailsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(FiatWithdrawDetailsComponent, [
    {
      key: 'render',
      value: function render() {
        var _this2 = this;
        var _props = this.props,
          Amount = _props.data.Amount,
          TicketAmount = _props.TicketAmount,
          ProductSymbol = _props.product.ProductSymbol,
          balance = _props.balance,
          fee = _props.fee,
          detailsInfo = _props.detailsInfo;
        var getItem = SidePaneDetailsComponent.getItem;

        function repeatStringNumTimes(string, times) {
          var repeatedString = '';
          while (times > 0) {
            repeatedString += string;
            times--;
          }
          return repeatedString;
        }
        const formatToNumber = (numbr, places) => {
          numbr = !empty(numbr) ? numbr : 0.0;
          return Number(numbr.toFixed(places));
        };
        const formatFee = (numbr, places) => {
          let denom = Number('1' + repeatStringNumTimes('0', places || 1));
          numbr = !empty(numbr) ? numbr : 0.0;
          return Math.floor(denom * numbr) / denom;
        };
        let newBalance = formatFee(balance, 2);

        let TotalWithdrawValue = formatNumbersWithoutRound(Amount || TicketAmount, 2);

        var currentBalance = balance
        ? formatNumberToLocale(formatNumbersWithoutRound(balance, 2), ProductSymbol)
        : '-';

        var amount =
          !empty(Amount) && !TicketAmount
            ? Amount
              ? formatNumberToLocale(formatNumbersWithoutRound(Amount, 2), ProductSymbol)
              : '-'
            : TicketAmount
              ? formatNumberToLocale(formatNumbersWithoutRound(TicketAmount - fee, 2), ProductSymbol)
              : '-';
        
        let remainingBalance =
          balance && (Amount || TicketAmount)
            ? `${formatNumberToLocale(
                formatToNumber(newBalance, 2) -
                TotalWithdrawValue,
                ProductSymbol
              )}`
            : '-';

        let TotalWithdraw =
          Amount || TicketAmount
            ? `${formatNumberToLocale(
                formatToNumber(TotalWithdrawValue, 2),
                ProductSymbol
              )}`
            : '-';

        const newTotalWithdraw = 
            TotalWithdraw === '-'
            ? TotalWithdraw
            : FormatDecimalCoinkWithSimbols(TotalWithdraw, ProductSymbol);

        let Fee =
          Amount || TicketAmount
            ? `${formatNumberToLocale(fee, ProductSymbol)}`
            : '-';

        const handleRemainingBalance = () => {
          let result = '-';
          if (Amount || TicketAmount) {
            result = FormatDecimalCoinkWithSimbols(remainingBalance , ProductSymbol);
            if (remainingBalance.includes('-')) {
              result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span> 
            }
          }
          return result;
        }


        const handleAmount = () => {
          let result = '-';
          if (Amount || TicketAmount) {
            result = FormatDecimalCoinkWithSimbols(amount , ProductSymbol);
            if (remainingBalance.includes('-')) {
              result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span> 
            }
          }
          return result;
        }
        
        const newItems = ProductSymbol.includes(excludeProductSymbols) ? 'Current Balance' : 'Current Balance in {ProductSymbol}';
        const items = [
          getItem(
            this.context.t( newItems, {
              ProductSymbol: ProductSymbol
            }),
            FormatDecimalCoinkWithSimbols(currentBalance , ProductSymbol)
          ),
          getItem(this.context.t('Total to withdraw'), FormatDecimalCoinkWithSimbols(TotalWithdraw , ProductSymbol)),
          getItem(this.context.t('Fee'), FormatDecimalCoinkWithSimbols(Fee , ProductSymbol)),

          getItem(this.context.t('Amount to receive'), handleAmount()),

          getItem(this.context.t('Remaining Balance'), handleRemainingBalance())
        ];
        const info = detailsInfo.map(function(item) {
          if (item.useLink) {
            return React.createElement(
              'a',
              { href: item.linkAddress },
              _this2.context.t('{text}', { text: item.text })
            );
          } else {
            return _this2.context.t(item.text);
          }
        });

        return React.createElement(
          Recommendations
        );
      }
    }
  ]);

  return FiatWithdrawDetailsComponent;
})(React.Component);

FiatWithdrawDetailsComponent.propTypes = {
  FullName: PropTypes.string,
  Amount: PropTypes.number,
  BankAccountName: PropTypes.string,
  BankAccountNumber: PropTypes.string,
  BankAddress: PropTypes.string,
  Comment: PropTypes.string
};

FiatWithdrawDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawDetailsComponent;
