/* global TradingView */

import React from 'react';
import PropTypes from 'prop-types';
import ChartAPI from 'apex-web/lib/services/tradingView/ChartAPI';
import jstz from 'jstimezonedetect';
import { Spinner } from '../../../components/Spinner/Spinner';
import path from 'apex-web/lib/helpers/path';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import { getCSSVar } from 'apex-web/lib/helpers/cssVarHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import config from "../config"

const tvChartClasses = getBEMClasses(['tv-chart']);

/* These items were either non-working or buggy within our TradingView implementation,
and should stay disabled until they are fixed or reassessed. */
const disabledFeatures = [
  'header_settings',
  'control_bar',
  'context_menus',
  'header_symbol_search',
  'header_compare',
  'use_localstorage_for_settings',
  'save_chart_properties_to_local_storage',
  'show_chart_property_page',
  'header_resolutions',
  'go_to_date'
];

class TVChartComponent extends React.Component {
  componentDidUpdate() {
    this.initChart();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !this.props.selectedInstrument.InstrumentId &&
      nextProps.selectedInstrument.InstrumentId
    ) {
      return true;
    }
    return !!window.TradingView && !!nextState && !!nextState.chartInitialized;
  }

  componentDidMount() {
    this.cssVariable = getCSSVar();
    this.scripts = {};

    this.script('/vendor/jquery-3.3.1.min.js')
      .then(() => {
        return this.script('/vendor/tradingView/charting_library.min.js');
      })
      .then(() => {
        this.initChart();
      });
  }

  initChart() {
    this.wsUri = getGateway();
    const timezone = jstz.determine().name();
    const currentTimezone = timezone ? timezone : config.TradingView.timezone;

    if (!this.chart) {
      if (!window.tvChartDataFeed) {
        window.tvChartDataFeed = new ChartAPI.UDFCompatibleDatafeed(
          this.wsUri,
          null,
          this.props.customGateway,
          currentTimezone,
          increment => convertIncrementToIntDecimalPlaces(increment)
          );
        }
        
        this.createChart(window.tvChartDataFeed);
      } else {
      (this.props.selectedInstrument && this.chart && typeof this.chart.setSymbol === "function") && this.chart.setSymbol(this.props.selectedInstrument.Symbol || "", '15');
    }
  }

  script = location => {
    return new Promise(resolve => {
      this.scripts[location] = document.createElement('script');
      this.scripts[location].src = path(location);
      this.scripts[location].type = 'text/javascript';
      this.scripts[location].async = true;
      this.scripts[location].onload = resolve;
      document.body.appendChild(this.scripts[location]);
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
    if (this.chart) {
      try {
        this.chart.remove();
      } catch (e) {
        console.warn('silently handled chart error: ', e);
      }
      this.scripts['/vendor/tradingView/charting_library.min.js'].remove();
      this.scripts['/vendor/jquery-3.3.1.min.js'].remove();
    }
  }

  createChart = dataFeed => {
    const loading_BgColor = this.cssVariable(
      '--tv-chart__loading-screen-bg-color'
    );
    const bgColor = this.cssVariable('--tv-chart__bg-color');
    const gridColor = this.cssVariable('--tv-chart__grid-color');
    const gridColorVertical = this.cssVariable(
      '--tv-chart__grid-vertical-color'
    );
    const gridColorHorizontal = this.cssVariable(
      '--tv-chart__grid-horizontal-color'
    );
    const scaleFontColor = this.cssVariable('--tv-chart__scale-font-color');
    const scaleLineColor = this.cssVariable('--tv-chart__scale-line-color');
    const scaleBgColor = this.cssVariable('--tv-chart__scale-bg-color');
    const graphUpColor = this.cssVariable('--tv-chart__graph-up-color');
    const graphDownColor = this.cssVariable('--tv-chart__graph-down-color');
    const graphBorderUpColor = this.cssVariable(
      '--tv-chart__graph-border-up-color'
    );
    const graphBorderDownColor = this.cssVariable(
      '--tv-chart__graph-border-down-color'
    );
    const graphUpOpaqueColor = `rgba(${this.cssVariable(
      '--pc__accent-additive'
    )}, .6)`;
    const graphDownOpaqueColor = `rgba(${this.cssVariable(
      '--pc__accent-subtractive'
    )}, .6)`;

    const custom_css_url =
      this.props.theme === 'light'
        ? ''
        : '/vendor/tradingView/charting_library-dark-theme.css';
    const chartOptions = {
      symbol: this.props.selectedInstrument.Symbol,
      datafeed: dataFeed,
      library_path: path('/vendor/tradingView/'),
      autosize: true,
      interval: '15',
      container_id: 'trading-view-chart',
      loading_screen: { backgroundColor: loading_BgColor },
      custom_css_url: path(custom_css_url),
      overrides: {
        'paneProperties.background': bgColor,
        'paneProperties.gridProperties.color': gridColor,
        'paneProperties.vertGridProperties.color': gridColorVertical,
        'paneProperties.horzGridProperties.color': gridColorHorizontal,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 25,
        'scalesProperties.textColor': scaleFontColor,
        'scalesProperties.lineColor': scaleLineColor,
        'scalesProperties.showLeftScale': false,
        'scalesProperties.showRightScale': true,
        'scalesProperties.backgroundColor': scaleBgColor,
        volumePaneSize: 'large',
        'mainSeriesProperties.candleStyle.upColor': graphUpColor,
        'mainSeriesProperties.candleStyle.downColor': graphDownColor,
        'mainSeriesProperties.candleStyle.borderUpColor': graphBorderUpColor,
        'mainSeriesProperties.candleStyle.borderDownColor': graphBorderDownColor,
        'mainSeriesProperties.candleStyle.drawWick': true,
        'mainSeriesProperties.hollowCandleStyle.upColor': graphUpColor,
        'mainSeriesProperties.hollowCandleStyle.downColor': bgColor,
        'mainSeriesProperties.hollowCandleStyle.borderUpColor': graphBorderUpColor,
        'mainSeriesProperties.hollowCandleStyle.borderDownColor': graphBorderDownColor,
        'mainSeriesProperties.hollowCandleStyle.drawWick': false,
        'mainSeriesProperties.haStyle.upColor': graphUpColor,
        'mainSeriesProperties.haStyle.downColor': graphDownColor,
        'mainSeriesProperties.haStyle.borderUpColor': graphBorderUpColor,
        'mainSeriesProperties.haStyle.borderDownColor': graphBorderDownColor,
        'mainSeriesProperties.haStyle.drawWick': false,
        'mainSeriesProperties.barStyle.upColor': graphUpColor,
        'mainSeriesProperties.barStyle.downColor': graphDownColor,
        'mainSeriesProperties.lineStyle.color': graphUpColor,
        'mainSeriesProperties.lineStyle.linewidth': '4',
        'mainSeriesProperties.areaStyle.color1': graphUpColor,
        'mainSeriesProperties.areaStyle.color2': graphUpOpaqueColor,
        'mainSeriesProperties.areaStyle.linecolor': graphUpColor,
        'mainSeriesProperties.baselineStyle.topFillColor1': graphUpOpaqueColor,
        'mainSeriesProperties.baselineStyle.topFillColor2': graphUpOpaqueColor,
        'mainSeriesProperties.baselineStyle.bottomFillColor1': graphDownOpaqueColor,
        'mainSeriesProperties.baselineStyle.bottomFillColor2': graphDownOpaqueColor,
        'mainSeriesProperties.baselineStyle.topLineColor': graphUpColor,
        'mainSeriesProperties.baselineStyle.bottomLineColor': graphDownColor
      },
      studies_overrides: {
        'volume.volume.color.0': graphDownOpaqueColor,
        'volume.volume.color.1': graphUpOpaqueColor
      }
    };

    const localOverrides = config.TradingView || {};

    let disabled_features;
    if ('disabled_features' in localOverrides) {
      disabled_features = [
        ...new Set([...disabledFeatures, ...localOverrides.disabled_features])
      ];
    } else {
      disabled_features = disabledFeatures;
    }

    let enabled_features = [];
    if ('enabled_features' in localOverrides) {
      enabled_features = localOverrides.enabled_features.filter(
        item => !disabledFeatures.includes(item)
      );
    }

    this.timeout = setTimeout(() => {
      const el = document.getElementById('trading-view-chart');
      if(!el || !TradingView) return;
      this.chart = new TradingView.widget({
        ...chartOptions,
        ...localOverrides,
        disabled_features,
        enabled_features
      });
      this.chart.onChartReady(() => {
        this.setState({ chartInitialized: true });
      });
    }, 2000);
  };

  render() {
    return (
      <div
        className={tvChartClasses('', {
          'with-depth-chart':
            config.TradingLayout.showDepthChart && window.innerHeight >= 1080
        })}>
        <div
          id="trading-view-chart"
          className={tvChartClasses('chart-container')}>
            <div className='spinner-container-trading'>
              <Spinner color='orange' />
            </div>
        </div>
      </div>
    );
  }
}

TVChartComponent.propTypes = {
  selectedInstrument: PropTypes.object
};

TVChartComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default TVChartComponent;
