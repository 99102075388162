import isEmpty from "is-empty";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputElement from "../../../../bnxv2/Components/InputElement/InputElement";

import { CardGradient } from "../../../components";
import { OptionsForm } from "../../../components";

function RemoveCardForm(props, context) {
  const { handleSteps } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [otherReasonValue, setOtherReasonValue] = useState("");
  const [errors, setErrors] = useState({});
  const ht = useHistory();
  const optionsLabels = {
    1: "Loss or theft of card",
    2: "No longer need the Banexcard",
    3: "Technical issues using my card",
    4: "High fees and costs",
    5: "Other reason",
  };
  
  const selectedOptionIsOther = selectedOption === context.t(optionsLabels["5"]);
  
  const handleErrors = (value) => {
    let errors = {};
    if (selectedOptionIsOther && isEmpty(value)) {
      errors.otherReason = "Required field";
    }
    setErrors(errors);
    return errors;
  };

  const canExecuteAction =
    !isEmpty(selectedOption) &&
    !loading &&
    ((selectedOptionIsOther && !isEmpty(otherReasonValue)) ||
      !selectedOptionIsOther);

  const onChangeOtherReason = (e) => {
    const { value } = e.target;
    setOtherReasonValue(value);
    const errors = handleErrors(value);
    if (!isEmpty(errors)) return;
  };

  const otherReasonInputProps = {
    values: {
      title: "",
      value: otherReasonValue,
      name: "otherReason",
      type: "textarea",
      placeholder:  context.t("Write your reason here"),
    },
    actions: {
      onChange: onChangeOtherReason,
    },
    error: errors.otherReason,
  };

  const handleExecuteRemoveCard = () => {
    handleSteps.next();
  };

  const actions = [
    {
      label: context.t("Cancel"),
      action: () => ht.push("/cards/settings"),
      className: "secondary",
    },
    {
      label:context.t("Delete card"),
      action: canExecuteAction ? handleExecuteRemoveCard : null,
      otherAttributes: {
        disabled: !canExecuteAction,
      },
    },
  ];

  const options = [
    {
      title: context.t(optionsLabels["1"]),
      onClick: () => {},
    },
    {
      title: context.t(optionsLabels["2"]),
      onClick: () => {},
    },
    {
      title: context.t(optionsLabels["3"]),
      onClick: () => {},
    },
    {
      title: context.t(optionsLabels["4"]),
      onClick: () => {},
    },
    {
      title: context.t(optionsLabels["5"]),
      onClick: () => {},
    },
  ];

  const handleSelectedOption = (title) => setSelectedOption(title);

  return (
    <>
      <h2>{context.t("Delete Virtual Banexcard")}</h2>
      <p>{context.t("Are you sure you want to delete your card?")}</p>
      <CardGradient actions={actions} customClassName="remove-banexcard-w">
        <div className="content">
          <p>{context.t("Choose the reason why you delete your card")}</p>
            <OptionsForm options={options} handleSelectedOption={handleSelectedOption} selectedOption={selectedOption}/> 
          {selectedOptionIsOther && <InputElement {...otherReasonInputProps} />}
        </div>
      </CardGradient>
    </>
  );
}

RemoveCardForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default RemoveCardForm;
