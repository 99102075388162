import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import empty from 'is-empty';
import genericError from './../../../images/coink-imgs/generic-error.svg';
import miniIsoBanexcoin from './../../../images/coink-imgs/mini-iso-banexcoin.svg';
import { Trans, TransP } from '../CoinkUtils';
import { getLoginToken } from './../CoinkHooks';
import VerificationInput from 'react-verification-input';
import apex from 'apex-web/lib/apex';
import { onLogin } from '../../bnxv2/hooks/authActions';
import LoadingCoink from '../../../components/LoadingCoink';
import './stepper.css';
import Spinner from '../Components/Spinner/Spinner';

const CoinkStepLoginError = (props, context) => {
  let { propsFunction, errorCode, setonLogin, redirect } = props;
  const windowUrl = window.location.search;
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(true);
  const [reloadInput, setReloadInput] = useState(true);
  const [focusInput, setFocusInput] = useState(true);
  const [errorMessageOtp, seterrorMessageOtp] = useState();
  const search = new URLSearchParams(windowUrl.substring(0));
  const params = Object.fromEntries(search);

  const resendOtp = async () => {
    setLoading(true);
    try {
      let data = params;
      let payload = {
        url: window.location.href,
        userId: Number(data.u),
        token: data.token,
        seed: data.seed,
        publicKey: data.pk,
        cid: data.cid,
        resend: 1
      };
      let loginNow = await getLoginToken(payload);
      if (!empty(loginNow) && !empty(loginNow.data)) {
        let dataLog = {
          APIKey: loginNow.data.APIKey,
          Signature: loginNow.data.signature,
          UserId: loginNow.data.UserId.toString(),
          Nonce: loginNow.data.Nonce
        };
        const app = await apex.connection.AuthenticateUser(dataLog);
        if (!empty(app)) {
          if (app.Authenticated) {
            localStorage.setItem('cid', data.cid);
            setonLogin(app);
            redirect('/coink/dashboard/');
          }
        }
      }
    } catch (error) {
      console.log('Error on resendOtp', error);
      if (
        !empty(error) &&
        !empty(error.response) &&
        !empty(error.response.data) &&
        !empty(error.response.data.message)
      ) {
        setIsError(true);
        if (error.response.data.code === 'OK200') {
          setIsError(false);
        }
        seterrorMessageOtp(error.response.data.message);
      }
    }
    setLoading(false);
  };

  const loginWithTwoFa = async twofacode => {
    setLoading(true);
    setReloadInput(true);
    try {
      let data = params;
      let payload = {
        url: window.location.href,
        userId: Number(data.u),
        token: data.token,
        seed: data.seed,
        publicKey: data.pk,
        cid: data.cid,
        otp: twofacode
      };

      let loginNow = await getLoginToken(payload);
      if (!empty(loginNow) && !empty(loginNow.data)) {
        let dataLog = {
          APIKey: loginNow.data.APIKey,
          Signature: loginNow.data.signature,
          UserId: loginNow.data.UserId.toString(),
          Nonce: loginNow.data.Nonce
        };
        const app = await apex.connection.AuthenticateUser(dataLog);
        if (!empty(app)) {
          if (app.Authenticated) {
            localStorage.setItem('cid', data.cid);
            setonLogin(app);
            setLoading(false);
            redirect('/coink/dashboard/');
          }
        }
      }
    } catch (error) {
      console.log('Error on LoginWithTwoFA', error);
      setReloadInput(false);
      setFocusInput(false);
      setTimeout(() => {
        setReloadInput(true);
      }, 1);
      if (
        !empty(error) &&
        !empty(error.response) &&
        !empty(error.response.data) &&
        !empty(error.response.data.message)
      ) {
        setIsError(true);
        seterrorMessageOtp(error.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="step-bnx">
      <div className="pb-5 pt-3 parr-spc containerSteppr">
        {errorCode === 'CORE006' ? (
          <React.Fragment>
            <div className="all">
              <div className="body">
                <p>
                  <span>
                    <b className="ft-17">{context.t('Introduce el código ')}</b>
                    <b className="ft-16">
                      {context.t('que te enviamos por SMS')}
                    </b>
                  </span>
                </p>
                <TransP
                  text={context.t(
                    'Por seguridad tenemos que validar que eres tú. Hemos enviado un SMS con un código de verificación.'
                  )}
                />
                <div style={{ height: '75px' }}>
                  {reloadInput ? (
                    <VerificationInput
                      removeDefaultStyles
                      autoFocus={focusInput}
                      length={6}
                      validChars="/^\d+$/"
                      container={{
                        className: 'pt-3 pb-4'
                      }}
                      characters={{
                        className: 'characters'
                      }}
                      character={{
                        className: 'character',
                        classNameInactive: 'character--inactive',
                        classNameSelected: 'character--selected'
                      }}
                      inputField={{
                        onChange: e => {
                          e.persist();
                          let length = e.target.value.length;
                          if (length >= 6) {
                            loginWithTwoFa(e.target.value);
                            setTimeout(() => {
                              document.getElementById('inputVeri').blur();
                            }, 1);
                          }
                        },
                        inputMode: 'numeric',
                        id: 'inputVeri'
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                {!empty(errorMessageOtp) && (
                  <b
                    className={
                      'action-twofa-message' + (isError ? ' error' : ' success')
                    }>
                    {context.t(errorMessageOtp)}
                  </b>
                )}
              </div>
              <div className="v2-field botton footer">
                <b className="ft-16">¿No recibiste el SMS?</b>
                <Button
                  type="button"
                  className="btn v2-field btn-bnxv2 btn-primary btn_resend"
                  onClick={() => {
                    resendOtp();
                  }}
                  disabled={loading}>
                  {loading ? <Spinner /> : 'Reenviar código'}
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : errorCode === 'INVALID_API_KEY' ? (
          <React.Fragment>
            <div className="containerMesg">
              <div>
                <img src={genericError} className="poster_cover" />
                <p>
                  <span>
                    <b className="ft-17">{context.t('Ups! ')}</b>
                    <b className="ft-16">{context.t('Ocurrió un problema')}</b>
                  </span>
                </p>
                <Trans
                  text={context.t(
                    'Ocurrió un error al iniciar sesión en Banexcoin. <b>Su sesión es inválida</b>, vuelva a Coink y reintente nuevamente.'
                  )}
                />
              </div>
            </div>
          </React.Fragment>
        ) : !empty(params.action) && params.action === 'unlogin' ? (
          <React.Fragment>
            <div className="containerMesg">
              <div>
                <img src={genericError} className="poster_cover" />
                <p>
                  <span>
                    <b className="ft-17">{context.t('Ups! ')}</b>
                    <b className="ft-16">
                      {context.t('Hemos detectado algo inusual en tu sesión')}
                    </b>
                  </span>
                </p>
                <TransP
                  text={context.t(
                    'Por favor, regresa a Coink y vuelve a ingresar a la comunidad Banexcoin.'
                  )}
                />
                <TransP
                  text={context.t(
                    '(Haz clic en la "X" en la parte superior izquierda)'
                  )}
                  className="fs-12 pt-12"
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="containerMesg">
              <div>
                <img src={genericError} className="poster_cover" />
                <p>
                  <span>
                    <b className="ft-17">{context.t('Ups! ')}</b>
                    <b className="ft-16">{context.t('Ocurrió un problema')}</b>
                  </span>
                </p>
                <Trans
                  text={context.t(
                    'Ocurrió un error al iniciar sesión en Banexcoin. Por favor, si cambió de red, vuelva a Coink y reintente nuevamente.'
                  )}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

CoinkStepLoginError.contextTypes = {
  t: PropTypes.func
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    setonLogin: payload => {
      onLogin(dispatch, payload);
    },
    redirect: path => {
      ownProps.history.push(path);
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(CoinkStepLoginError)
);
