import React from 'react';
import PropTypes from 'prop-types';
import APRadioTab from 'apex-web/lib/components/common/APRadioTab/APRadioTab';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  sellValue,
  buyValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

const BuySellTabs = (props, context) => {
  const { baseClass, onClick, defaultValue } = props;

  const classes = getBEMClasses(baseClass);

  return (
    <div className={classes('buy-sell-wrapper')}>
      <div className={classes('buy-sell-radio-tab-wrapper')}>
        <APRadioTab
          customClass={classes()}
          onClick={onClick}
          defaultValue={false}
          items={[
            {
              text: context.t('Buy'),
              value: buyValue,
              styleName: 'additive',
              dataTest: 'Buy Side'
            },
            {
              text: context.t('Sell'),
              value: sellValue,
              styleName: 'subtractive',
              dataTest: 'Sell Side'
            }
          ]}
          name="side"
        />
      </div>
    </div>
  );
};

BuySellTabs.propTypes = {
  baseClass: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

BuySellTabs.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BuySellTabs;
