import React from "react";
import PropTypes from "prop-types";
import CardGradient from "../CardGradient";
import bnxBlack from "../../../../images/bnx-black.svg";
import Option from "./Option";
import "./menu-card.css";

function MenuCard(props, context) {
  const {options, bottomOption, title, description, customClassName = ""} = props
  return (
    <CardGradient customClassName={`banexcard-max-w menu-card ${customClassName}`}>
      <div className="main-options-container">
        <div className="main-options">
          <div className="title">
            <p>
              <strong>Banexcard</strong> {title}{" "}
              <span className="badge">{context.t("Enabled")}</span>
            </p>{" "}
            <small>{description}</small>
          </div>
          <div className="options">
            {options.map((item) => <Option {...item}/> )}
          </div>
        </div>
        <img
          src={bnxBlack}
          style={{ margin: "2rem 0 3rem" }}
          alt="bnx black icon"
          width={50}
        />
        {bottomOption && <Option {...bottomOption}/>}
      </div>
    </CardGradient>
  );
}

MenuCard.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node
  })),
  bottomOption: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node
  }),
};

MenuCard.contextTypes = {
  t: PropTypes.func.isRequired
}
export default MenuCard;
