import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  AttentionModal,
  RemoveCardForm,
  RemoveCardResult,
  TwoFaConfirmation,
} from "./Steps";
import { connect } from "react-redux";
import { Loader } from "../../components";
import { Redirect } from "react-router-dom";
import "./remove-card.css";

function RemoveCard(props, context) {
  const { hasBanexCard, loadingBanexCard } = props;
  const [step, setStep] = useState(1);
  const ht = useHistory();

  const handleSteps = {
    next: () => setStep((step) => step + 1),
    previous: () => setStep((step) => step - 1),
  };

  const componentSteps = {
    1: <RemoveCardForm handleSteps={handleSteps} />,
    2: (
      <AttentionModal handleSteps={handleSteps} />
    ),
    3: <TwoFaConfirmation handleSteps={handleSteps} action={()=>{}}/>,
    4: <RemoveCardResult handleSteps={handleSteps} />,
  };

  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard) return <Redirect to="/cards" />

  return <section className="remove-banexcard-page">
    {componentSteps[step]}
  </section>;
}

RemoveCard.contextTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    userInfo,
    loadingBanexCard,
  };
};
export default connect(mapStateToProps)(RemoveCard);
