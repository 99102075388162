import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DownloadIcon from "../../../../images/icons-v2/download.svg";
import CardReceiveIcon from "../../../../images/icons-v2/card-receive.svg";
import "./movements-history.css";
import isEmpty from "is-empty";
import { connect } from "react-redux";
import BanexcardController from "../../controllers/BanexcardController";
import { Spinner } from '../../../../components/Spinner/Spinner';
import { currencyFormatter } from "../../../utils";
import { Loader } from "../../components";
import { Redirect } from "react-router-dom";
import xlsx from "json-as-xlsx"

const MovementsHistoryPage = (props, context) => {
  const { hasBanexCard, loadingBanexCard, userInfo } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetBanexcardTransactions = async () => {
    setLoading(true);
    const response = await BanexcardController.getBanexcardTransactions({
      userInfo,
    });
    setLoading(false);
    setData(response);
  };

  useEffect(() => {
    if (isEmpty(userInfo)) return;
    handleGetBanexcardTransactions();
  }, [userInfo]);

  const handleDownload = () => {
    let settings = {
      fileName: "Banexcard-transactions", 
      writeMode: "writeFile", 
      writeOptions: {}, 
    }
    
    const xlsxConfig = [{
      sheet: "Banexcard - transactions",
      columns:[
        { label: "Status", value: "merchantName" },
        { label: "Asset", value: _ => "USD" },
        { label: "Amount", value: row => currencyFormatter({value: row.totalAmount, currency: "USD"}) },
        { label: "Card", value: row => row.isVirtual ? "Virtual" : "Physical" },
        { label: "Type", value: row => row.isCredit ? "Credit" : "Debit" },
        { label: "Operation", value: "transactionType" },
        { label: "Date", value: row => new Date(row.transactionDate).toLocaleString() },
      ],
      content: data
    }]
    xlsx(xlsxConfig, settings)
  }

  
  if(loadingBanexCard) return <Loader />
  if(!hasBanexCard) return <Redirect to="/cards" />

  return (
    <div className="movements-history">
      <div>
        <div className="download-history">
          <div onClick={handleDownload}>
            <p>{context.t("Download")}</p>
            <img src={DownloadIcon} alt="Download" />
          </div>
        </div>
        <div className="banex-table">
          <div className="banex-head">
            <div className="banex-row">
              <div className="banex-cell">{context.t("Status")}</div>
              <div className="banex-cell">{context.t("Asset")}</div>
              <div className="banex-cell">{context.t("Amount")}</div>
              <div className="banex-cell">{context.t("Card")}</div>
              <div className="banex-cell">{context.t("Type")}</div>
              <div className="banex-cell">{context.t("Operation")}</div>
              <div className="banex-cell">{context.t("Date")}</div>
            </div>
          </div>
          <div className="banex-body">
            {loading ? (
              <Spinner />
            ) : (
              data.map(
                (row, index) => (
                  <div class={`banex-row ${row.transactionStatus}-row`} key={index}>
                    <div class="banex-cell">
                      <img src={CardReceiveIcon} alt={row.merchantName} />
                      <div className={`banex-state ${row.transactionStatus}-row`}>
                        <p>{row.merchantName}</p>
                      </div>
                    </div>
                    <div class="banex-cell">
                      <img
                        src={`/local/product-icons/usd-48px.svg`}
                        alt="USD"
                        width={24}
                        height={24}
                      />
                      <div>
                        {"USD"}
                        <p className="product-full-name">{"United States Dollar"}</p>
                      </div>
                    </div>
                    <div class="banex-cell">{currencyFormatter({value: row.totalAmount, currency: "USD"})}</div>
                    <div class="banex-cell">
                      {context.t(row.isVirtual ? "Virtual" : "Physical")}
                    </div>
                    <div class="banex-cell">{context.t(row.isCredit ? "Credit" : "Debit")}</div>
                    <div class="banex-cell">{row.transactionType}</div>
                    <div class="banex-cell">{new Date(row.transactionDate).toLocaleString()}</div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MovementsHistoryPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { banexCard: { hasBanexCard, loadingBanexCard }, user: { userInfo } } = state;
  return {
    hasBanexCard,
    userInfo,
    loadingBanexCard,
  };
};

export default connect(mapStateToProps)(MovementsHistoryPage);
