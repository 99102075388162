import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CardGradient, OptionsForm } from '../../../components'
import isEmpty from 'is-empty'

function OtherLocationFirst(props, context) {
  const { handleSteps } = props
  const [selectedOption, setSelectedOption] = useState("")
  const actions = [
    {
      label: context.t("Previous"),
      className: "secondary",
      action:() => {
        handleSteps.previous()
      }
    },
    {
      label: context.t("Next"),
      action:() => {
        if(isEmpty(selectedOption)) return
        if(selectedOption === context.t("Shipping to Province")){
          return handleSteps.custom(3)
        }
        handleSteps.next()
      },
      otherAttributes:{
        disabled: isEmpty(selectedOption)
      }
    }
  ]

  const handleSelectedOption = (title) => setSelectedOption(title)
  const options = [
    {
      title: context.t("Shipping within Lima Metropolitana or Callao"),
      description:context.t("We will send your card to the address you indicate")
    },
    {
      title: context.t("Shipping to Province"),
      description: context.t("We will provide you with a list of locations where you can pick up your card")
    },
  ]
  return (
    <div className='other-location-first'>
      <h2 className='title-main'>{context.t("Physical Card Shipping")}</h2>
      <p className='description-main'>{context.t("Where will we send your Banexcoin Visa Physical Card?")}</p>
      <CardGradient actions={actions}>
        <p>{context.t("Shipping options")}</p>
        <OptionsForm options={options} handleSelectedOption={handleSelectedOption} selectedOption={selectedOption} containerClassName="delivery-scrollable-list"/>
        <p style={{fontSize: "1.2rem", marginTop: "2rem", textAlign: "left"}}>¡Estamos emocionados de ofrecer el servicio de envío de la Tarjeta Banexcard Física en Perú en este momento! Actualmente, estamos trabajando arduamente para ampliar nuestros servicios de envío y muy pronto estaremos habilitando envíos fuera de Perú para todos nuestros clientes. ¡Mantente atento a emocionantes actualizaciones y gracias por ser parte de la familia Banexcard!</p>
      </CardGradient>
    </div>
  )
}

OtherLocationFirst.contextTypes = {
  t: PropTypes.func.isRequired
}
export default OtherLocationFirst