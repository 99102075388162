import HandlerBackend from "../../../helpers/HandlerBackend";

export const generateKYC = async (userId, accountId) => {
    const path = `/api/v1/generate`;
    const configRequest = {
        userId,
        method: "POST",
        path,
        data: {
            banexcoin_account_id: accountId
        }
    };
    return await HandlerBackend.sendRequest(configRequest);
}