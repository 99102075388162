import React from "react";
import "./videoModal.css";
import { useParams } from "react-router-dom";
import closeIcon from "../../../../images/close_icon_white.svg";
const videos = {
  natural: "https://www.youtube.com/embed/0T8yj_RdV_4?autoplay=1&rel=0",
  corporative: "",
};
function videoModal({onClose}) {
  const params = useParams();
  return (
    <div className="video-modal-container">
      <div className="video-modal">
        <img className="close-icon" id="video-close-icon" src={closeIcon} onClick={onClose} alt="close icon" width={20}/>
        <iframe
          width="1290"
          height="715"
          src={videos[params.type]}
          title="¿Cómo registrarte en Banexcoin?"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default videoModal;
