export const STATUS_PROCESS = {
    New: 'In progress',
    AdminProcessing: 'In progress',
    Accepted: 'Complete',
    Rejected: 'Rejected',
    SystemProcessing: 'In progress',
    FullyProcessed: 'Complete',
    Failed: 'In progress',
    Pending: 'In progress',
    Pending2Fa: 'Pending confirmation by mail',
    Confirmed2Fa: 'In progress',
    AutoAccepted: 'Complete',
    Delayed: 'In progress',
    UserCancelled: 'Cancelled',
    AdminCancelled: 'Rejected',
    AmlProcessing: 'In progress',
    AmlAccepted: 'In progress',
    AmlRejected: 'Rejected',
    AmlFailed: 'Rejected',
    LimitsAccepted: 'In progress',
    LimitsRejected: 'In progress',
    Submitted: 'In progress',
    Confirmed: 'In progress',
    ManuallyConfirmed: 'In progress',
    ManualReview: 'In progress'
};