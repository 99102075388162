import HandlerBackend from '../../helpers/HandlerBackend';

export const getTrustList = async (userId, params = {}) => {
  let path = `/api/v1/client/trust-list`;

  if (params) {
    Object.keys(params).forEach(key => {
      path += `/${params[key]}`;
    });
  }

  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const validateTrustList = async (userId, data) => {
  const path = '/api/v1/client/trust-list/validate';

  return await HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const saveTrustList = async (userId, data) => {
  const path = '/api/v1/client/trust-list';

  return await HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path,
    data: data
  });
};

export const updateTrustList = async (userId, id, data) => {
  const path = `/api/v1/client/trust-list/${id}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'put',
    path,
    data: data
  });
};
