import React from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateFormRenderer } from '../../../helpers/formTemplateHelper';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import config from 'apex-web/lib/config';

import './SendAddress.css';

var baseClasses = getBEMClasses('send-address');

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var SendAddressComponent = (function(_React$Component) {
  _inherits(SendAddressComponent, _React$Component);

  function SendAddressComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SendAddressComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SendAddressComponent.__proto__ ||
          Object.getPrototypeOf(SendAddressComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.getProductData = function() {
          _this.props.selectWithdrawProduct(_this.props.product.ProductId);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(SendAddressComponent, [
    {
      key: 'componentWillMount',
      value: function componentWillMount() {
        this.getProductData();
      }
    },
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        const moneyAddress = (this.props.product.ProductId === 1) ? 'ToExternalBitcoinAddress' : 'ToExternalEthereumAddress';
        this.props.selectWithdrawTemplate(this.props.product.ProductId, moneyAddress);
      }
    },
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          withdrawInfo = _props.withdrawInfo,
          template = _props.withdrawInfo.template,
          product = _props.product,
          ProductId = _props.product.ProductId,
          userEmail = _props.userEmail,
          useExternalAddress = _props.useExternalAddress,
          selectWithdrawTemplate = _props.selectWithdrawTemplate,
          WalletsDisabledMessage = _props.WalletsDisabledMessage;

        if (config.global.gateway === 'wss://api.banexcoin.com/WSGateway/') {
          if (ProductId === 1) {
            withdrawInfo.templateTypes = [
              {
                AccountProviderId: 1,
                TemplateName: 'ToExternalBitcoinAddress'
              }
            ];
          } else if (ProductId === 14) {
            withdrawInfo.templateTypes = [
              {
                AccountProviderId: 14,
                TemplateName: 'ToExternalEthereumAddress',
                AccountProviderName: 'USDC-ERC20'
              }
            ];
            template = {
              TemplateType: 'ToExternalEthereumAddress',
              Comment: '',
              ExternalAddress: ''
            };
          }
        } else {
          if (ProductId === 1) {
            withdrawInfo.templateTypes = [
              {
                AccountProviderId: 5,
                TemplateName: 'ToExternalBitcoinAddress'
              }
            ];
          } else if (ProductId === 4) {
            withdrawInfo.templateTypes = [
              {
                AccountProviderId: 4,
                TemplateName: 'ToExternalEthereumAddress',
                AccountProviderName: 'USDC-ERC20'
              }
            ];
            template = {
              TemplateType: 'ToExternalEthereumAddress',
              Comment: '',
              ExternalAddress: ''
            };
          }
        }

        var showTemplateFields = Object.keys(template).length;
        var showTemplateTypeSelect =
          withdrawInfo.templateTypes.length > 0 &&
          !Object.keys(withdrawInfo.templateInfo).length &&
          !withdrawInfo.isLoading;
        var templateTypes = [
          { value: '', label: this.context.t('Select an option') }
        ].concat(
          _toConsumableArray(
            withdrawInfo.templateTypes.map(function(template) {
              return {
                value: template.TemplateName,
                label: template.TemplateName
              };
            })
          )
        );

        var hasError = withdrawInfo.error || WalletsDisabledMessage;

        return React.createElement(
          'div',
          null,
          withdrawInfo.withdrawStatus.success
            ? React.createElement(
                'div',
                { className: baseClasses('success') },
                this.context.t('Your withdraw ticket was created successfully.')
              )
            : React.createElement(
                'div',
                { className: baseClasses('form-body') },
                hasError
                  ? React.createElement(
                      'div',
                      { className: baseClasses('error') },
                      hasError
                    )
                  : React.createElement(
                      React.Fragment,
                      null,
                      withdrawInfo.isLoading &&
                        React.createElement(Spinner, null),
                      showTemplateTypeSelect &&
                        React.createElement(
                          React.Fragment,
                          null,
                          React.createElement(
                            'p',
                            null,
                            this.context.t(
                              'Select an option to continue the withdraw process'
                            )
                          ),
                          React.createElement(APSelect, {
                            name: 'TemplateType',
                            customClass: baseClasses(),
                            label: this.context.t('Options for withdraw'),
                            onSelect: function onSelect(value) {
                              return selectWithdrawTemplate(ProductId, value);
                            },
                            options: templateTypes
                          })
                        ),
                      showTemplateFields > 0 &&
                        React.createElement(
                          'div',
                          { className: baseClasses('section') },
                          templateFormRenderer(
                            template,
                            baseClasses(),
                            this.context,
                            userEmail,
                            useExternalAddress,
                            product
                          )
                        )
                    )
              )
        );
      }
    }
  ]);

  return SendAddressComponent;
})(React.Component);

SendAddressComponent.defaultProps = {
  withdrawInfo: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    TemplateForm: {},
    withdrawStatus: {},
    getWithdrawFee: function getWithdrawFee() {}
  }
};

SendAddressComponent.propTypes = {
  getWithdrawFee: PropTypes.func,
  selectWithdrawProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    ProductSymbol: PropTypes.string,
    ProductId: PropTypes.number
  }).isRequired,
  withdrawInfo: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    TemplateForm: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    withdrawStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

SendAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendAddressComponent;
