import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Line } from 'react-chartjs-2';
import { Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import apex from 'apex-web/lib/apex';
import '../styles/components/common/StandaloneForm.css';
import './TermsConditionsPage.css';

const lang = localStorage.getItem('language');

class PairPricesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDefault: [
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10
      ]
    };
  }

  syncChart() {
    (async () => {
      var FromDate = moment();
      FromDate = FromDate.subtract(30, 'days');
      FromDate = FromDate.format('YYYY-MM-DD');

      let ToDate = moment().format('YYYY-MM-DD');
      let GetTickerHistory = await apex.connection.GetTickerHistory({
        InstrumentId: 1,
        Interval: 60 * 60 * 24,
        FromDate: FromDate,
        ToDate: ToDate,
        OMSId: 1
      });
      if (!empty(GetTickerHistory)) {
        let dataPrices = [];
        for (let gth in GetTickerHistory) {
          let p = GetTickerHistory[gth];
          dataPrices.push(p[2]);
        }
        this.setState({ dataDefault: dataPrices });
      }
    })();
  }

  componentDidMount() {
    this.syncChart();
    this.chartReference = React.createRef();
  }

  render() {
    let { level1, instruments } = this.props;

    let newLevel1 = [];
    for (var i in level1) {
      newLevel1.push(level1[i]);
    }
    if (empty(newLevel1)) {
      return <React.Fragment />;
    }
    const getInstrumentID = pair => {
      let pairs = [
        {
          pair: 'BTCUSDPEN',
          currentId: 1,
          instrumentidOne: 1,
          instrumentidTwo: 5,
          sideProduct1: 'BTC',
          sideProductName1: 'BTC',
          sidePrice1: 'BestOffer',
          sideProduct2: 'USD',
          sidePrice2: 'BestBid',
          sidePrice3: 'BestOffer',
          sideProduct3: 'PEN',
          sidePrice3: 'BestBid',
          type: 'normal'
        }
      ];
      return pairs.find(p => p.pair == pair);
    };

    let pairName = this.props.match.params.pairName;
    if (empty(getInstrumentID(pairName))) {
      return <React.Fragment>PAR NO ENCONTRADO</React.Fragment>;
    }
    let {
      pair,
      sideProduct1,
      sideProductName1,
      sideProduct2,
      sideProductName2,
      sideProduct3,
      sideProductName3,
      instrumentidOne,
      instrumentidTwo
    } = getInstrumentID(pairName);

    let numberFormat = new Intl.NumberFormat('es-US');
    let pricesOne = newLevel1.filter(
      lvl => lvl.InstrumentId === instrumentidOne
    )[0];
    let instrumentOne = instruments.filter(
      lvl => lvl.InstrumentId === instrumentidOne
    )[0];

    let priceCurrentOneSymbol = !empty(instrumentOne)
      ? instrumentOne.Product2Symbol === 'USD'
        ? 'US$'
        : instrumentOne.Product2Symbol
      : '';

    let priceCurrentOne =
      priceCurrentOneSymbol +
      ' ' +
      (!empty(pricesOne) ? numberFormat.format(pricesOne.BestOffer) : '');

    let pricesTwo = newLevel1.filter(
      lvl => lvl.InstrumentId === instrumentidTwo
    )[0];
    let instrumentTwo = instruments.filter(
      lvl => lvl.InstrumentId === instrumentidTwo
    )[0];

    let priceCurrentTwoSymbol = !empty(instrumentTwo)
      ? instrumentTwo.Product2Symbol === 'PEN'
        ? 'S/'
        : instrumentTwo.Product2Symbol
      : 'Vacio';

    let priceCurrentTwo =
      priceCurrentTwoSymbol +
      ' ' +
      (!empty(pricesTwo) ? numberFormat.format(pricesTwo.BestOffer) : '');

    let TotalVolume = !empty(pricesOne) ? pricesOne.Rolling24HrVolume : 0;
    let CurrentDayNumTrades = !empty(pricesOne)
      ? pricesOne.CurrentDayNumTrades
      : 0;
    let CurrentDayPxChangeOne = !empty(pricesOne)
      ? (pricesOne.CurrentDayPxChange / 100).toFixed(2)
      : 0;

    const seo = {
      title: pair + ' - Banexcoin',
      description: 'Tarjeta de precios en Banexcoin para ' + pair,
      url: 'https://www.banexcoin.com/prices/' + pair,
      image:
        'https://www.banexcoin.com/local/landing-page/images/preview_banexcoin.png'
    };

    let data = {
      labels: [
        '',
        '-30d',
        '',
        '',
        '',
        '-25d',
        '',
        '',
        '',
        '-20d',
        '',
        '',
        '',
        '-15d',
        '',
        '',
        '',
        '-10d',
        '',
        '',
        '',
        '-5d',
        '',
        '',
        '',
        'Hoy',
        '',
        ''
      ],

      datasets: [
        {
          label: pair,
          backgroundColor: 'rgba(253, 122, 34, 0.4)',
          borderWidth: 0,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: this.state.dataDefault
        }
      ]
    };

    const options = {
      backgroundColor: '#000000',
      tooltips: true,
      elements: {
        line: {
          fill: true,
          backgroundColor: 'rgba(253, 122, 34, 0.0)',
          borderColor: 'rgba(253, 122, 34, 0.0)'
        },
        point: {
          backgroundColor: 'rgba(255,255,255,0.0)',
          radius: 0,
          pointStyle: 'rect',
          hoverRadius: 15
        }
      },
      legend: {
        display: false,
        label: {
          fontColor: 'white'
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              max: this.props.maxY,
              min: 0,
              stepSize: 3,
              display: false
            },
            gridLines: {
              display: false
            }
          }
        ],
        xAxes: [
          {
            position: 'top',
            ticks: {
              max: this.props.maxX,
              min: 0,
              stepSize: 3,
              display: true,
              color: '#fff',
              fontColor: 'white'
            },
            gridLines: {
              color: 'rgba(255,255,255,0.0)',
              lineWidth: 2,
              display: false
            }
          }
        ]
      },
      title: {
        display: this.props.display,
        text: this.props.title
      }
    };

    let language = localStorage.getItem('language');
    moment.locale(language);

    const divStyle = {
      backgroundColor: '#000',
      marginRight: '-20px'
    };

    const divStyleOverflow = {
      overflow: 'hidden',
      backgroundColor: '#000'
    };

    return (
      <div style={{ backgroundColor: '#000' }}>
        <Helmet
          title={seo.title}
          meta={[
            {
              name: 'description',
              property: 'og:description',
              content: seo.description
            },
            { property: 'og:title', content: seo.title },
            { property: 'og:url', content: seo.url },
            { property: 'og:image', content: seo.image },
            { property: 'og:image:type', content: 'image/png' },
            { property: 'twitter:image:src', content: seo.image },
            { property: 'twitter:title', content: seo.title },
            { property: 'twitter:description', content: seo.description }
          ]}>
          <link rel="canonical" href={seo.url} />
        </Helmet>
        <div className="card-master text-center">
          <Button
            type="button"
            onClick={() => {
              html2canvas(document.querySelector('#capture'), {
                backgroundColor: 'transparent',
                scrollY: 0,
                scale: 3,
                dpi: 144
              }).then(canvas => {
                document.querySelector('#generateimage').appendChild(canvas);

                /* */
                let downloadLink = document.createElement('a');
                let filename =
                  sideProduct1.toLowerCase() +
                  moment()
                    .format('h:mm:ss a')
                    .replace(/\s/g, '_') +
                  '.png';
                downloadLink.setAttribute('download', filename);
                let canvasSelected = document.querySelector(
                  '#generateimage canvas'
                );
                let dataURL = canvasSelected.toDataURL('image/png');
                let url = dataURL.replace(
                  /^data:image\/png/,
                  'data:application/octet-stream'
                );
                downloadLink.setAttribute('href', url);
                downloadLink.click();
              });
            }}>
            Capturar
          </Button>
          <Container>
            <Row>
              <Col
                xs={5}
                className="center-card"
                id="capture"
                style={divStyleOverflow}>
                <div className="card-time">
                  {moment().format('dddd LL')}
                  <br />
                  {moment().format('h:mm:ss a [GMT]Z')}
                </div>
                <Row className="card-row">
                  <Col
                    xs={2}
                    className="side-logo"
                    style={{
                      backgroundImage: `url(/local/landing-page/images/products-png/${sideProduct1.toLowerCase()}-48px.png)`
                    }}
                  />
                  <Col
                    className="cant-letter text-left"
                    style={{ paddingTop: '17px' }}>
                    <b>{sideProductName1}</b>
                  </Col>
                  <Col className="cant-letter righted text-right">
                    <br />
                    <b>{priceCurrentOne}</b>
                    <br />
                    <b>{priceCurrentTwo}</b>
                    <br />
                    <span className="colored">24h /</span>{' '}
                    <span
                      className={
                        pricesOne.Rolling24HrPxChange > 0
                          ? 'coloredBtcUp'
                          : 'coloredBtcDown'
                      }>
                      {parseFloat(pricesOne.Rolling24HrPxChange).toFixed(2)}%
                    </span>
                  </Col>
                </Row>
                <div className="chart-container" style={divStyle}>
                  <Line
                    ref={this.chartReference}
                    data={data}
                    options={options}
                  />
                </div>
                <div
                  className="chart-title"
                  style={{ backgroundColor: '#000' }}>
                  {'Precio del ' +
                    (sideProductName1 === 'BTC'
                      ? 'BITCOIN'
                      : sideProductName1) +
                    ' en Banexcoin'}
                </div>
                <div className="chart-info-container">
                  <Row className="chart-info-title">
                    <Col>
                      Ult 24 Hrs <br />({instrumentOne.Symbol})
                    </Col>
                    <Col>
                      Hoy <br />({instrumentOne.Symbol})
                    </Col>
                    <Col>
                      Rank <br />
                    </Col>
                  </Row>
                  <Row className="chart-info-sub">
                    <Col className="cant-letter righted text-center">
                      <span
                        className={
                          pricesOne.Rolling24HrPxChange > 0
                            ? 'coloredBtcUp'
                            : 'coloredBtcDown'
                        }>
                        {parseFloat(pricesOne.Rolling24HrPxChange).toFixed(2)}%
                      </span>
                    </Col>

                    <Col className="cant-letter righted text-center">
                      <span
                        className={
                          pricesOne.CurrentDayPxChange > 0
                            ? 'coloredBtcUp'
                            : 'coloredBtcDown'
                        }>
                        {parseFloat(pricesOne.CurrentDayPxChange).toFixed(2)}%
                      </span>
                    </Col>

                    <Col className="cant-letter righted text-center">
                      <span className="coloredBtcUp">#1</span>
                    </Col>
                  </Row>

                  <div className="separator-c" />
                  <Row className="chart-info-title">
                    <Col>Cambio % Ult 24 Horas ({instrumentOne.Symbol})</Col>
                  </Row>
                  <Row className="chart-info-sub">
                    <Col className="cant-letter righted text-center">
                      <span
                        className={
                          CurrentDayPxChangeOne > 0
                            ? 'coloredBtcUp'
                            : 'coloredBtcDown'
                        }>
                        {parseFloat(CurrentDayPxChangeOne).toFixed(2)}%
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="chart-info-footer" />
              </Col>
            </Row>
          </Container>
        </div>
        <div
          id="generateimage"
          className="text-center"
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

PairPricesPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  var _ref = state.apexCore ? state.apexCore : {},
    level1 = _ref.level1,
    instrument = _ref.instrument;

  var { instruments } = instrument;
  return {
    level1,
    instruments
  };
};

export default connect(mapStateToProps)(PairPricesPage);
