import React from 'react';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import { checkForVerifyEmailParam, checkForResetPassParam, checkForConfirmWithdrawParam, checkForDepositConfirmationParam, checkForLoginParam, checkForVerifyDeviceParam } from 'apex-web/lib/helpers/emailLinksHelper';
import { checkForVerifyAddressParam } from 'apex-web/lib/helpers/homePageLayoutHelper';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import { STAND_ALONE_COMPONENTS } from './emailLinksLayoutsConstants';

let _createClass = function () { function defineProperties(target, props) { for (let i = 0; i < props.length; i++) { let descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


let EmailLinksLayout = function (_React$Component) {
  _inherits(EmailLinksLayout, _React$Component);

  function EmailLinksLayout() {
    let _ref;

    let _temp, _this, _ret,args;

    _classCallCheck(this, EmailLinksLayout);

    for (let _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = EmailLinksLayout.__proto__ || Object.getPrototypeOf(EmailLinksLayout)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      StandAloneComponent: undefined
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(EmailLinksLayout, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (checkForVerifyEmailParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyEmailContainer
      });else if (checkForConfirmWithdrawParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.ConfirmWithdrawContainer
      });else if (checkForResetPassParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.ResetPasswordNoAuthFormContainer
      });else if (checkForDepositConfirmationParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.DepositConfirmationContainer
      });else if (checkForLoginParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.StandaloneLoginContainer
      });else if (checkForVerifyAddressParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyAddressContainer
      });else if (checkForVerifyDeviceParam()) return this.setState({
        StandAloneComponent: STAND_ALONE_COMPONENTS.VerifyDeviceContainer
      });
    }
  }, {
    key: 'render',
    value: function render() {
      let StandAloneComponent = this.state.StandAloneComponent;

      return StandAloneComponent ? React.createElement(StandAloneComponent, null) : null;
    }
  }]);

  return EmailLinksLayout;
}(React.Component);

export default redirectIfAuthorized(EmailLinksLayout, defaultPath.path);