import { connect } from 'react-redux';
import get from 'lodash/get';
import WalletComponent from './WalletDetailsComponent';
import { fetchingSelector, productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { baseCurrencyPriceSelector } from 'apex-web/lib/redux/selectors/baseCurrenciesSelectors';
import { openSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { SIDE_PANES } from 'apex-web/lib/constants/sidePanesConstants';
import isEmpty from 'is-empty';

var mapStateToProps = function mapStateToProps(state) {
  var position = state.apexCore.position;

  var fetching = fetchingSelector(state);
  var positionDetails = productPositionAssetsSelector(state).find(function (i) {
    return i.ProductId === position.selectedProductId;
  });
  var baseCurrency = baseCurrencyPriceSelector(state, positionDetails.ProductId);

  var verificationLevelConfig = get(state, ['user', 'verificationLevelConfigs', 'Products'], []).find(function (i) {
    return i.ProductId === position.selectedProductId;
  }) || {};

  return {
    positionDetails: positionDetails,
    fetching: fetching,
    baseCurrency: baseCurrency,
    verificationLevelConfig: verificationLevelConfig,
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    openWhitelistSidepane: function openWhitelistSidepane(props) {
      return dispatch(openSidePane(SIDE_PANES.WHITE_LIST_ADDRESS, Object.assign({}, props, {
        hideHeader: true
      })));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletComponent);