import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import empty from 'is-empty';
import PropTypes from 'prop-types';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import PhoneInput from 'react-phone-input-2';
import { Col, Row, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  getPhoneValidationInfo,
  phoneValidationStepTwo,
  cleanPhone,
  getIPInfo
} from './utils';
import { updateValidation as _updateValidation } from './../redux/actions/set_validation';
import { useForm } from '../hooks/formHooks';
import LoadingBnx from './../components/LoadingBnx';
import VerificationInput from 'react-verification-input';
import oneTab from './../images/oneTab.png';
import oneTabDark from './../images/oneTabDark.png';
import { phoneValidation } from './PhoneValidationServices';
import isEmpty from 'is-empty';
import { callAPI } from 'apex-web/lib/redux/actions/apiActions';
import StatusMessage from '../components/StatusMessage/StatusMessage';
const InteriorPagePhoneConfirmation = (props, context) => {
  let {
    UserId,
    Accounts,
    selectedAccountId,
    UserInfo: { Use2FA },
    setBlocked = () => {},
    blocked,
    updateValidation,
    theme,
    changeTheme = () => {},
    defaultTheme,
    location,
    prefix,
    getAccountInfo,
    setResendGenerate = () => {}
  } = props;

  let timeoutMessage = 4000;
  let prePath = !empty(prefix) ? prefix : '';
  let pathToken =
    !empty(location) && !empty(location.pathname)
      ? location.pathname.split('/')
      : '';
  let token =
    !empty(pathToken) && !empty(pathToken[pathToken.length - 1])
      ? pathToken[pathToken.length - 1]
      : '';

  let pathToActivate =
    prePath + '/settings/user' + (!empty(token) ? `/${token}` : '');

  const currentAccount = async() => {
    let account = {};
    const response = await getAccountInfo({"omsId": 1, "accountId": selectedAccountId})
    account = response;
    return account;
  };
  if (empty(UserId)) {
    return <React.Fragment />;
  }

  const [country, setCountry] = useState('us');
  const [loading, setLoading] = useState(0);
  const [step, setStep] = useState(1);
  const [validation, setValidation] = useState(null);
  const [verificationLevel, setVerificationLevel] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loaderPhoneInfo, setLoaderPhoneInfo] = useState(true);
  const [loadingPhoneValidation, setLoadingPhoneValidation] = useState(false);
  const [reloadInput, setReloadInput] = useState(true);
  const [phoneData,setPhoneData] = useState({
    code:"",
    phone:""
  })
  const [phoneIsValid , setPhoneIsValid] = useState(true);
  const [messageAttempts, setMessageAttempts] = useState(false);
  const [counter, setCounter] = useState(30);
  const [errorAttempt, setErrorAttempts] = useState("");
  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const scrollToActivate = id => {
    const element = document.getElementById(id);
    if (!empty(element)) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getValidation = async () => {
    setLoading(1);
    setLoaderPhoneInfo(false);
    try {
      let currentAccountInformation = await currentAccount();
      setVerificationLevel(currentAccountInformation.VerificationLevel);
      if (currentAccountInformation.VerificationLevel >= 0) {
        let response = await getPhoneValidationInfo(
          UserId,
          currentAccountInformation.VerificationLevel
        );
        setValidation(response.data.validation);
        if (response.data.validation !== 'SUCCESSFUL') {
          setBlocked(true);
        } else {
          updateValidation({ 'phone': true, })
        }
        if (
          response.data.hasOwnProperty('phone') &&
          !empty(response.data.phone)
        ) {
          setInputs(inputs => ({
            ...inputs,
            code: response.data.phoneCountryCode,
            phone: response.data.phone
          }));
          setPhoneData({
            code: response.data.phoneCountryCode,
            phone: response.data.phone
          })
        }
      }
    } catch (error) {
      console.log('Error on getValidation', error);
    }
    setLoading(0);
  };

  const sendStepTwo = async phone => {
    let result = {};
    try {
      let response = await phoneValidationStepTwo(UserId, phone);
      result = response.data;
    } catch (error) {
      console.log('Error on sendStepOne', error);
      throw error;
    }
    return result;
  };
  const startGetIpInfo = async () => {
    try {
      let data = await getIPInfo();
      if (!empty(data)) {
        setCountry(data.country.toLowerCase());
      }
    } catch (error) {
      console.log('error on startGetIpInfo', error);
    }
  };

  useEffect(() => {
    startGetIpInfo();
  }, []);

  useEffect(
    () => {
      if (
        !empty(props.UserInfo) &&
        !empty(Accounts) &&
        !empty(selectedAccountId)
      ) {
        let ttt = localStorage.getItem('token');
        if (loaderPhoneInfo) getValidation();
      }
    },
    [props.UserInfo, Accounts, selectedAccountId]
  );

  useEffect(
    () => {
      if (props.hasOwnProperty('location') && !empty(props.location.pathname)) {
        let coinkPath = props.location.pathname.split('/');
        if (!empty(coinkPath) && coinkPath[1] === 'coink') {
          if (props.hasOwnProperty('defaultTheme')) {
            let def = defaultTheme + ' body-step-bnx';
            if (theme !== def) {
              changeTheme(def);
            }
          }
        }
      }

      if (location.pathname === pathToActivate) {
        if (!empty(validation) && validation !== 'SUCCESSFUL' && !Use2FA) {
          scrollToActivate('2FABox');
        }
      }
    },
    [location.pathname]
  );

  const goBackStepOne = num => {
    setInputs(inputs => ({
      ...inputs,
      validated: false
    }));
    setStep(Number(num));
  };

  useEffect(() => {
    const storedCounter = localStorage.getItem('counterCode');
    if (storedCounter !== null) {
      const storedCounterValue = parseInt(storedCounter, 10);
      setCounter(storedCounterValue);
      if (storedCounterValue > 0) {
        msgAttempts("You exceeded the maximum number of attempts");
      }
    }
  }, []);


  const msgAttempts = (msg) => {
    if (msg && msg === "You exceeded the maximum number of attempts") {
      setMessageAttempts(true);
      setIsCountdownActive(true);
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 0) {
            setErrorAttempts("");
            setMessageAttempts(false);
            clearInterval(intervalId);
            localStorage.removeItem('counterCode');
            return counter;
          }
          const minutes = Math.floor(prevCounter / 60);
          const seconds = prevCounter % 60;
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');
          setErrorAttempts(`${context.t("You exceeded the maximum number of attempts, you must wait:")} ${formattedMinutes}:${formattedSeconds}`);
          localStorage.setItem('counterCode', prevCounter - 1);
          return prevCounter - 1;
        });
      }, 1000);
    } else {
      setError(msg);
    }
  };

  const resendCode = async () => {
    setLoading(1);
    try {
      const result = await phoneValidation(UserId, phoneData);
      if (result.data.message === "success") {
        setSuccess("The verification SMS was successfully resent");
        setError("");
        setTimeout(function () {
          setSuccess("");
        }, timeoutMessage);
      }
    } catch (error) {
      const msg =
        !isEmpty(error.response) && !isEmpty(error.response.data)
          ? error.response.data.message
          : "";
      msgAttempts(msg);
      toast.warn(context.t(msg), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(0);
  };

  const sendCode = async (phone, onClose) => {
    setLoading(1);
    try {
      const result = await phoneValidation(UserId, phoneData);
      if (result.data.message === "success") {
        setError("");
        setSuccess("");
        goBackStepOne(2);
        setTimeout(function () {
          setSuccess("");
        }, timeoutMessage);
      }
    } catch (error) {
      const msg =
        !isEmpty(error.response) && !isEmpty(error.response.data)
          ? error.response.data.message
          : "";
      msgAttempts(msg);
      goBackStepOne(1);
      toast.warn(context.t(msg), {
        position: toast.POSITION.TOP_CENTER,
      });
    }finally{
      setLoading(0);
      onClose(); 
      setPhoneIsValid(false)
    }
  };
  
  const formattedPhoneNumber = (value) =>
    `+${cleanPhone(value).slice(1, 3)} ${cleanPhone(value).slice(
      3,
      6
    )} ${cleanPhone(value).slice(6, 9)} ${cleanPhone(value).slice(9)}`;

  const confirmPhone = phone => {
    const miDiv = document.getElementById("react-confirm-alert");
    if(miDiv) miDiv.remove();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="message-confirm-phone toconfirm">
            <StatusMessage type="PENDING">
              <div className="react-confirm-alert confirm-phone-box">
                <div className="react-confirm-alert-body">
                  <div
                      style={{ fontSize: '16px', textAlignLast: "center", marginTop: '1rem' }}
                      dangerouslySetInnerHTML={{
                        __html: context.t(
                          "You will receive a code via text message to the phone number: {phone} Select <span>Confirm</span> if it's correct; otherwise, choose <span>No</span> to correct it.",
                          {
                            phone: `<h2>${formattedPhoneNumber(phone)}</h2>`,
                          }
                        ),
                      }}
                    ></div>
                  <div className="react-confirm-alert-button-group">
                    <div className="container_buttons_modal">
                      <button
                        onClick={() => {
                          sendCode(phone, onClose);
                        }}
                        className="btn-confirm-alert"
                      >
                        {context.t("Confirm")}
                      </button>
                      <button
                        onClick={() => {
                          onClose();
                        }}
                        className="btn-close-alert"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </StatusMessage>
          </div>
        );
      }
    });
  };

  const handleClose = () => setBlocked(false);

  const handleValidationPhone = async () => {
    let phoneIsValid = false
    try {
      setLoadingPhoneValidation(true)
      let completePhone  = inputs.phone
      if(!isEmpty(phoneData) && !isEmpty(phoneData.phone)){
        completePhone = `+${phoneData.dialCode}${phoneData.phone}`
      }
      const response = await phoneValidation(UserId,phoneData)
      if(!isEmpty(response.data) && response.data.message === "success"){
        phoneIsValid = true
      }
    } catch (error) {
      if(!isEmpty(error.response) && !isEmpty(error.response.data)){
        setErrors({...errors,phonecode:error.response.data.message})
      }
    }finally{
      setLoadingPhoneValidation(false)
    }
    return phoneIsValid
  }

  const onSubmitCallback = async (inputs, form) => {
    try {
      if (empty(errors)) {
        if (step === 1) {
          confirmPhone(cleanPhone(inputs.phone));
        } else if (step === 2) {
          setLoading(1);
          let result = await sendStepTwo(inputs.phonecode);
          if (result.message === 'success') {
            goBackStepOne(3);
            setIsCountdownActive(false)
            updateValidation({ 'phone': true, });
            setError('');
          }
        }
      }
    } catch (error) {
      setReloadInput(false);
      setTimeout(() => {
        setReloadInput(true);
      }, 1);
      let msg = '';
      if (
        error.hasOwnProperty('response') &&
        !empty(error.response.data.data)
      ) {
        msg = error.response.data.data.error_message;
        setPhoneIsValid(false)
      } else {
        msg = error.message;
      }
      setError(msg);
      setTimeout(() => {
        setError('');
      }, timeoutMessage);
    }
    setLoading(0);
  };

  const customValidations = inputs => {
    let errors = {};
    if (step === 1) {
      if (empty(cleanPhone(inputs.phone))) {
        errors.phone = 'You must enter a phone number';
      }
    }
    if (step === 2) {
      if (empty(inputs.phonecode)) {
        errors.phonecode = 'You must enter the confirmation code';
      }
    }
    return errors;
  };

  let initialState = {
    validated: false
  };

  let {
    inputs,
    errors,
    setErrors,
    setInputs,
    onSubmitSimple,
    onInputChangeByName,
  } = useForm(initialState, onSubmitCallback, customValidations);
  if (
    validation === 'SUCCESSFUL' ||
    empty(validation) ||
    (!empty(verificationLevel) && verificationLevel < 0)
  ) {
    return (
      <React.Fragment>
        <LoadingBnx loading={loading} />
      </React.Fragment>
    );
  }

  const formatPhoneNumber = (value, { dialCode, countryCode }) => {
    const phoneWithoutDialCode = value.replace(dialCode, "");
    const formattedPhone = `+${value}`;
  
    return {
      dialCode,
      code: countryCode.toUpperCase(),
      phone: phoneWithoutDialCode,
      formatted: formattedPhone,
    };
  };
  
  const handlePeruPhoneNumber = (phoneData) => {
    const { phone, formatted } = phoneData;
    let isValid = true;
    let errorText = "";
    if (phone.length !== 9) {
      isValid = false;
      errorText = "The phone number in Peru must have exactly 9 digits";
    } else if (phone.charAt(0) !== "9") {
      isValid = false;
      errorText = "The phone number in Peru must start with '9'";
    }  
    if (isValid) {
      onInputChangeByName('phone', formatted);
      delete errors["phonecode"];
      setPhoneIsValid(true);
      setError("");
    } else {
      setError(errorText);
      setPhoneIsValid(false);
    }
  };  

  return (
    <div className='message-confirm-phone initial'>
    <StatusMessage 
      type={step === 1 || step === 2 ? 'INFO' : 'SUCCESS'}>
        <div className="validation-phone banner">
          <div className="container text-center">
            <Row>
              <Col md={{ span: 12, offset: 0 }}>
                {step === 1 && step === 2 ? (
                  <h1>{context.t('Please confirm your phone number')}</h1>
                ) : (
                  ''
                )}
              </Col>
              <Form
                noValidate
                validated={inputs.validated}
                encType="multipart/form-data"
                onSubmit={onSubmitSimple}
                className="col-md-12 offset-md-0">
                {step === 1 ? (
                  <React.Fragment>
                    <Col>
                      <p className="col-md-8 offset-md-2 p-0 subtitle-information">
                        {context.t('Enter your country code and phone number')}
                      </p>
                      <div className="p-0  subtitle-form-header">
                      <Form.Group
                        className={
                          'col-md-8 offset-md-2 p-0 ' +
                          (!empty(errors) ? 'invalid' : '')
                        }>
                        <Form.Control.Feedback type="invalid">
                          {!empty(errors) && !empty(errors.phone)
                            ? context.t(errors.phone)
                            : ''}
                        </Form.Control.Feedback>
                        <PhoneInput
                        placeholder=''
                          country={country}
                          inputClass={
                            'confirm-phone ' +
                            (!empty(errors) && !empty(errors.phone)
                              ? 'is-invalid'
                              : '')
                          }
                          onChange={(value, data) => {
                            const formattedPhone = formatPhoneNumber(value, data);
                            setPhoneData(formattedPhone);                          
                            if (data.countryCode.toUpperCase() === "PE") {
                              handlePeruPhoneNumber(formattedPhone);
                            } else {
                              onInputChangeByName('phone', formattedPhone.formatted);
                              delete errors["phonecode"];
                              setError("");
                            }
                          }}
                          masks={{"pe": "... ... ..."}}
                          value={cleanPhone(inputs.phone) || ''}
                          inputProps={{
                            id: 'phone',
                            name: 'phone',
                            requiredmessage: context.t(
                              'You must enter the phone number'
                            ),
                            required: true,
                            autoComplete: 'off',
                            tabIndex: '1',
                            autoFocus: true
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!empty(errors) && !empty(errors.phonecode)
                            ? context.t(errors.phonecode)
                            : ''}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    </Col>                   
                  </React.Fragment>
                ) : step === 2 ? (
                  <React.Fragment>
                    <Col>
                      <p className="col-md-8 offset-md-2 p-0 subtitle-information">
                        {context.t(
                          'We sent you an SMS with a confirmation code to the phone {phone}.',
                          {
                            phone: `${
                              !empty(inputs.phone)
                                ? `${formattedPhoneNumber(inputs.phone)}`
                                : ''
                            }`
                          }
                        )}{' '}
                      </p>
                    </Col>
                    <Col className="p-0">
                      <Form.Group
                        className={
                          'col-md-8 offset-md-2 p-0 ' +
                          (!empty(errors) ? 'invalid' : '')
                        }>
                        <Form.Control.Feedback type="invalid">
                          {!empty(errors) && !empty(errors.phonecode)
                            ? context.t(errors.phonecode)
                            : ''}
                        </Form.Control.Feedback>
                        {reloadInput ? (
                          <VerificationInput
                            removeDefaultStyles
                            placeholder={'*'}
                            autoFocus={true}
                            length={6}
                            validChars="/^\d+$/"
                            container={{
                              className: 'pt-2 pb-2 solid-confirm'
                            }}
                            characters={{
                              className: 'characters'
                            }}
                            character={{
                              className: 'character',
                              classNameInactive: 'character--inactive',
                              classNameSelected: 'character--selected'
                            }}
                            inputField={{
                              onChange: e => {
                                e.persist();
                                let value = e.target.value;
                                let numericValue = value.replace(/\D/g, '');
                                if (value !== numericValue) {
                                  e.target.value = numericValue;
                                }
                                if (numericValue.length !== 6) {
                                  setPhoneIsValid(false);
                                } else {
                                  setPhoneIsValid(true);
                                }
                                onInputChangeByName('phonecode', numericValue);
                              }
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {!empty(errors) && !empty(errors.twofacode)
                            ? context.t(errors.twofacode)
                            : ''}
                        </Form.Control.Feedback>
                        <p className='subtitle-footer'>
                          <span className="btn-link disabled-resend">
                            {context.t("Didn't receive SMS?")}{' '}
                            <b
                              className={ messageAttempts && 'disabled-resend-code' }
                              onClick={() => {
                                resendCode();
                              }}>
                              {context.t('Resend')}
                            </b>
                          </span>
                          <span className="btn-link">
                            <b
                            className={ messageAttempts && 'disabled-resend-code' }
                              onClick={() => {
                                goBackStepOne(1);
                                setIsCountdownActive(false);
                              }}>
                              {context.t('Change phone number')}
                            </b>
                          </span>
                        </p>
                      </Form.Group>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col>
                      <p className="subtitle-information last">
                        {context.t(
                          'Great! now your phone {phone} has been validated.',
                          {
                            phone: `${
                              !empty(inputs.phone)
                                ? `${formattedPhoneNumber(inputs.phone)}`
                                : ''
                            }`
                          }
                        )}
                      </p>
                    </Col>
                    <Col className="container_buttons_modal">
                      <Button
                        variant="primary"
                        className="button_V2 purple"
                        type="button"
                        tabIndex="3"
                        onClick={() => {
                          setValidation('SUCCESSFUL');
                          setBlocked(false);
                          handleClose();
                          setResendGenerate(true)
                        }}>
                        {context.t('Done')}
                      </Button>
                    </Col>
                  </React.Fragment>
                )}
                  {(!empty(error) || (isCountdownActive && !empty(errorAttempt))) && (
                    <>
                      <p className="error-msg error-valid-phone">{context.t(error)}</p>
                      {isCountdownActive && (
                        <p className="error-msg error-valid-phone">{context.t(errorAttempt)}</p>
                      )}
                    </>
                  )}
                {!empty(success) ? (
                  <p className="success-msg">{context.t(success)}</p>
                ) : (
                  ''
                )}
                {step === 1 || step === 2 ? (
                  <Col className="container_buttons_modal group-buttons">
                    <Button
                      disabled={loading === 1 || loadingPhoneValidation  || messageAttempts}
                      variant="primary"
                      className="btn-phone"
                      type="submit"
                      tabIndex="3">
                      {context.t((loadingPhoneValidation || loading) ? "Loading..." : (step === 1 ? 'Send Code' : 'Confirm phone'))}
                    </Button>
                  </Col>
                ) : (
                ''
                )}
              </Form>
            </Row>
          </div>
        </div>
    </StatusMessage>
  </div>
  );
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    ApToken: state.auth.token,
    UserId: state.user.userInfo.UserId,
    UserConfig: state.user.userConfig,
    UserInfo: state.user.userInfo,
    Accounts: state.user.accounts,
    selectedAccountId: state.user.selectedAccountId
  };
};
const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getAccountInfo: payload => {
      return dispatch(callAPI('GetAccountInfo', payload)).then(function (res) {
        return res;
      });
    },
    updateValidation: payload => {
      dispatch(_updateValidation(payload));
    }
  };
};

InteriorPagePhoneConfirmation.contextTypes = {
  t: PropTypes.func.isRequired
};

export default withAuthentication(
  connect(mapStateToProps,mapDispatchToProps)(InteriorPagePhoneConfirmation)
);
