import { connect } from 'react-redux';
import ResetPasswordFormComponent from './ResetPasswordFormComponent';
import {
  fetchResetPassword,
  setResetPasswordError,
  clearResetPasswordError
} from 'apex-web/lib/redux/actions/resetPasswordActions';
import { getQueryParam } from 'apex-web/lib/helpers/queryParamsHelper';
import { reduxForm, formValueSelector, clearFields } from 'redux-form';
import { validatePassword } from 'apex-web/lib/helpers/formValidations';

const d1 = getQueryParam('d1');
const userid = getQueryParam('UserId');
const verifycode = getQueryParam('verifycode');

const ResetPasswordForm = reduxForm({
  form: 'resetPassword',
  onSubmit: function onSubmit(payload, dispatch) {
    const errorMessage = validatePassword(payload.password);
    if (errorMessage) {
      dispatch(
        clearFields(
          'resetPassword',
          false,
          true,
          'password',
          'matchingPassword'
        )
      );
      dispatch(setResetPasswordError({ errorMessage: errorMessage }));
    } else {
      dispatch(
        fetchResetPassword({
          verifycode: verifycode,
          userid: userid,
          d1: d1,
          formValues: payload
        })
      );
    }
  }
})(ResetPasswordFormComponent);

const selector = formValueSelector('resetPassword');

const mapStateToProps = function mapStateToProps(state) {
  const processingResetPassword = state.apiProcessing.processingResetPassword,
    _state$resetPassword = state.resetPassword,
    message = _state$resetPassword.message,
    fetchError = _state$resetPassword.error,
    validateError = _state$resetPassword.validateError;

  return {
    passwordValue: selector(state, 'password'),
    processingResetPassword: processingResetPassword,
    message: message,
    fetchError: fetchError,
    validateError: validateError
  };
};

const mapDispatchToProps = {
  clearResetPasswordError: clearResetPasswordError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordForm);
