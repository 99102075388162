import { connect } from 'react-redux';
import GetGoogle2FARecoveryKeyModal from './GetGoogle2FARecoveryKeyModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator'
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions'
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { getGoogle2FARecoveryCode } from 'apex-web/lib/redux/actions/userActions';
import { reduxForm, reset } from 'redux-form';

const mapStateToProps = function mapStateToProps(state) {
  const form = state.form;

  const recoveryForm = form.google2FARecoveryKeyModal || { values: {} };

  return {
    recoveryForm: recoveryForm,
    initialValues: {
      recoveryKey: ''
    }
  };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
    onCancel: function onCancel() {
      return dispatch(reset('google2FARecoveryKeyModal'));
    }
  };
};

const containerForm = reduxForm({
  destroyOnUnmount: true,
  form: 'google2FARecoveryKeyModal',
  onSubmit: function onSubmit(payload, dispatch) {
    return dispatch(getGoogle2FARecoveryCode(payload.code));
  },
  onChange: function onChange(values, dispatch, props) {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(GetGoogle2FARecoveryKeyModal);

const container = connect(mapStateToProps, mapDispatchToProps)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.GET_GOOGLE_2FA_RECOVERY_CODE
})(container);