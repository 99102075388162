import { connect } from 'react-redux';
import { change } from 'redux-form';
import AdvancedOrderSidePaneComponent from './AdvancedOrderSidePaneComponent';
import { clearOrderForm } from 'apex-web/lib/redux/actions/orderEntryActions';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';

var mapStateToProps = function mapStateToProps(state) {
  var form = state.form;

  return {
    form: form.advancedOrderEntry
  };
};

var mapDispatchToProps = {
  setBuyOrder: function setBuyOrder() {
    return change('advancedOrderEntry', 'side', buyValue);
  },
  setSellOrder: function setSellOrder() {
    return change('advancedOrderEntry', 'side', sellValue);
  },
  clearAdvancedOrderEntryForm: function clearAdvancedOrderEntryForm() {
    return clearOrderForm('advancedOrderEntry');
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedOrderSidePaneComponent);
