import React, { useState } from 'react';
import { FormSelectText } from '../../common/BnxFormsComponents';

const NetworkSelector = (props, context) => {
  const [selected, setSelected] = useState('');
  return (
    <FormSelectText
      classNameContainer="network-selector-container"
      required
      name="network-deposit"
      label="Withdraw Network"
      warning="Attention: The network withdrawal must match the deposit network or you will lose the funds"
      defaultOption="Select network"
      value={selected || props.defaultValue}
      options={props.options}
      errors={{}}
      onChange={e => {
        e.persist();
        setSelected(e.target.value);
      }}
    />
  );
};

export default NetworkSelector;
