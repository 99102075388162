import React from 'react';
import './KYCSettingsComponent.css';
import './KYCFormSidePaneComponent.css';

var KYCSettingsComponent = function KYCSettingsComponent(props) {
  var LevelsComponent = props.LevelsComponent;

  return React.createElement(LevelsComponent, null);
};

export default KYCSettingsComponent;
