import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import SendReceiveTwoFactorAuthForm from './SendReceiveTwoFactorAuthForm';

import './SendReceiveTwoFactorAuthModal.css';

var SendReceiveTwoFactorAuthModal = function SendReceiveTwoFactorAuthModal(
  props,
  context
) {
  var handleSubmit = props.handleSubmit,
    submitting = props.submitting,
    isOpen = props.isOpen,
    close = props.close,
    changeFieldValue = props.changeFieldValue,
    error = props.error;

  return React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: context.t('Two-Factor Authentication'),
      onCancel: function onCancel() {
        close();
        changeFieldValue('enableTwoFactorAuth', 'use2FA', true);
      },
      close: close
    },
    React.createElement(SendReceiveTwoFactorAuthForm, {
      handleSubmit: handleSubmit,
      submitting: submitting,
      error: error
    })
  );
};

SendReceiveTwoFactorAuthModal.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

SendReceiveTwoFactorAuthModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

SendReceiveTwoFactorAuthModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveTwoFactorAuthModal;
