import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LogoBanex from '../images/logo-banexcoin.svg';

const lang = localStorage.getItem('language');
var img;
if (lang == 'es') {
  img = 'espana';
} else {
  img = 'estados-unidos';
}

const HeaderB = (props, context) => {
  return (
    <header>
      <nav className="nav-top-container show-background">
        <div className="nav-top-container-cented">
          <a href="/" className="nav-logo-link">
            <img
              src={LogoBanex}
              className="ap-logo__img standalone-form__logo__img"
            />
          </a>
          <div className="landing-nav-menu">
            {/*<label class="switch"><input type="checkbox" id="check-theme"/><div></div></label>*/}
            <a
              href="/login/"
              className="ap-button__btn ap-button__btn--general signin lang"
              key="login">
              {context.t('Login')}
            </a>
            <a
              href="/signup/"
              className="ap-button__btn ap-button__btn--general signup lang"
              key="signup">
              {context.t('Sign up')}
            </a>
            <li className="nav-item dropdown right-men">
              <span className="nav-link dropdown-toggle" id="dropdown09">
                {' '}
                <span className="lang" key="langactual">
                  <img
                    src={'/local/landing-page/images/country/' + img + '.png'}
                    width="20px"
                    alt="esp"
                  />&nbsp;&nbsp;&nbsp;&nbsp;▼
                </span>
                <div className="dropdown-menu" aria-labelledby="dropdown09">
                  <button className="translate" id="english">
                    <img
                      className="lozad"
                      src="/local/landing-page/images/country/estados-unidos.png"
                      data-src="/local/landing-page/images/country/estados-unidos.png"
                      width="20px"
                      alt="usa"
                    />{' '}
                    English
                  </button>
                  <button className="`translate`" id="espanol">
                    <img
                      className="lozad"
                      src="/local/landing-page/images/country/espana.png"
                      data-src="/local/landing-page/images/country/espana.png"
                      width="20px"
                      alt="esp"
                    />{' '}
                    Español
                  </button>
                  {/*<button class="translate" id="fr"><img src="/local/landing-page/images/country/espana.png" width="20px"/>  Français</button>*/}
                </div>
              </span>
            </li>
          </div>
        </div>
      </nav>
      <div className="blankspace" />
    </header>
  );
};
HeaderB.contextTypes = {
  t: PropTypes.func.isRequired
};
export default HeaderB;
