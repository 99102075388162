//import empty from 'is-empty';
import queryString from 'query-string';
import empty from 'is-empty';
import moment from 'moment';
import 'moment/locale/es.js';

export function buildSecureGetParams(params) {
  try {
    const result = queryString.stringify(params);
    return btoa(result);
  } catch (error) {
    console.error('Error: buildSecureGetParams :: ', error);
    return '';
  }
}

export function getSecureGetParams(params) {
  try {
    const result = queryString.parse(atob(params));
    return result;
  } catch (error) {
    console.error('Error: getSecureGetParams::', error);
    return {};
  }
}

export function formatNumber(currency, num) {
  num = parseFloat(num);
  return (
    currency + ' ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
}

export function getNormalLevelStatus(statusNumber) {
  const LEVELS_STATUS = [
    {
      id: 1,
      name: 'Pendiente',
      isBlocked: true,
      status: 'INPROGRESS',
      action: 'CLOSED',
      titleBtn: 'In progress'
    },
    {
      id: 2,
      name: 'Enviado a DIG_iD_VERIF',
      isBlocked: true,
      status: 'INPROGRESS',
      action: 'CLOSED',
      titleBtn: 'In progress'
    },
    {
      id: 3,
      name: 'Recibido por DIG_iD_VERIF',
      isBlocked: true,
      status: 'INPROGRESS',
      action: 'CLOSED',
      titleBtn: 'In progress'
    },
    {
      id: 4,
      name: 'Rechazado',
      isBlocked: false,
      status: 'REJECTED',
      action: 'OPEN',
      titleBtn: 'Rejected'
    },
    {
      id: 5,
      name: 'Información incorrecta',
      isBlocked: false,
      status: 'REJECTED',
      action: 'OPEN',
      titleBtn: 'Request observed'
    },
    {
      id: 6,
      name: 'Modificar información',
      isBlocked: false,
      status: 'DONE',
      action: 'OPEN',
      titleBtn: 'In progress'
    },
    {
      id: 7,
      name: 'Aprobado',
      isBlocked: true,
      status: 'DONE',
      action: 'CLOSED',
      titleBtn: 'Verified'
    },
    {
      id: 8,
      name: 'Información editada',
      isBlocked: true,
      status: 'DONE',
      action: 'OPEN',
      titleBtn: 'In progress'
    },
    {
      id: null,
      name: 'No registrado',
      isBlocked: false,
      status: 'OPEN',
      action: 'OPEN',
      titleBtn: 'Start Verification'
    },
    {
      id: 'NEW_RECORD',
      name: 'Nuevo registro en BD',
      isBlocked: false,
      status: 'OPEN',
      action: 'OPEN',
      titleBtn: 'Finish verification'
    },
    {
      id: 'INCORRECT_INFORMATION',
      name: 'Informacion incorrecta rechazada por cumplimiento',
      isBlocked: false,
      status: 'REJECTED',
      action: 'OPEN',
      titleBtn: 'Request observed'
    },
    {
      id: 'REVIEW_REQUESTED',
      name: 'Pendiente',
      isBlocked: false,
      status: 'INPROGRESS',
      action: 'CLOSED',
      titleBtn: 'In progress'
    },
    {
      id: 'INPROCCESS',
      name: 'En proceso',
      isBlocked: false,
      status: 'INPROGRESS',
      action: 'CLOSED',
      titleBtn: 'In progress'
    },
    {
      id: 'ACCEPTED',
      name: 'Aprobado',
      isBlocked: false,
      status: 'DONE',
      action: 'OPEN',
      titleBtn: 'Verified'
    }
  ];

  return LEVELS_STATUS.find(l => l.id === statusNumber);
}

export const formatTitle = ({ value, type = true }) => {
  const buySell = {
    completado: 'exitosa',
    no_completado: 'fallida',
    en_proceso: 'en proceso',
    rechazado: 'rechazada',
    pendiente: 'pendiente'
  };
  const depositWithdraw = {
    completado: 'exitoso',
    no_completado: 'fallido',
    en_proceso: 'en proceso',
    nuevo: 'nuevo',
    rechazado: 'rechazado',
    pendiente: 'pendiente'
  };
  return type ? buySell[value] : depositWithdraw[value];
};

export function getStatusCoink(original) {
  let statuses = {
    PENDING: 'Pendiente',
    Pending: 'Pendiente',
    Pending2Fa: 'Pendiente',
    PROVIDER_ERROR: 'Error de proveedor',
    'WITHDRAW - COINK START': 'En proceso',
    'WITHDRAW - COINK ERROR': 'No completado',
    'WITHDRAW - COINK END': 'En proceso',
    PAID_OUT: 'Completado',
    ERROR_PROCESSING: 'No completado',
    PROCESSED: 'En proceso',
    PROCESSING_TRADE: 'En proceso',
    PROCESSED_TRADE: 'Completado',
    WORKING_TRADE: 'En proceso',
    ERROR_PROCESSING_TRADE: 'No completado',
    'TRADE - SAVE REQUEST': 'En proceso',
    'TRADE - SENDING SMS': 'En proceso',
    'TRADE - SENDING SMS ERROR': 'No completado',
    'TRADE - WAITING 2FA': 'En proceso',
    'TRADE - OTP INVALID': 'No completado',
    'TRADE - OTP VALID': 'En proceso',
    'TRADE - START': 'En proceso',
    'TRADE - FAILED': 'No completado',
    'TRADE - PROCESSED': 'En proceso',
    'TRADE - WITHDRAW BANEXCOIN IN PROCESS': 'En proceso',
    'TRADE - WITHDRAW BANEXCOIN ERROR (CREATE)': 'No completado',
    'TRADE - WITHDRAW BANEXCOIN CREATE ERROR': 'No completado',
    'TRADE - WITHDRAW BANEXCOIN APPROVAL ERROR': 'No completado',
    'TRADE - WITHDRAW BANEXCOIN PROCESSED': 'En proceso',
    'TRADE - DEPOSIT COINK ERROR': 'No completado',
    'TRADE - DEPOSIT COINK IN PROCESS': 'En proceso',
    'TRADE - DEPOSIT PROCESSED': 'En proceso',
    'TRADE - COMPLETE': 'Completado',
    'REVERT - START': 'En proceso de devolución',
    'REVERT - ERROR': 'Devolución no completada',
    'REVERT - DONE': 'Devolución completada',
    'TRADE - SELL COMPLETE': 'Completado',
    'TRADE - SELL WAITING 2FA': 'En proceso',
    'TRADE - SELL BANEXCOIN PROCESSED': 'En proceso',
    'TRADE - SELL BANEXCOIN ERROR (CREATE)': 'No completado',
    'TRADE - SELL BANEXCOIN CREATE ERROR': 'No completado',
    'TRADE - SELL BANEXCOIN APPROVAL ERROR': 'No completado',
    'TRADE - SELL OTP INVALID': 'No completado',
    'TRADE - SELL WITHDRAW BANEXCOIN IN PROCESS': 'En proceso',
    'TRADE - SELL BANEXCOIN ORDER AP ERROR': 'No completado',
    'TRADE - SELL DEPOSIT COINK IN PROCESS': 'En proceso',
    'TRADE - BUY COMPLETE': 'Completado',
    'TRADE - BUY WAITING 2FA': 'En proceso',
    'TRADE - BUY BANEXCOIN PROCESSED': 'En proceso',
    'TRADE - BUY BANEXCOIN ERROR (CREATE)': 'No completado',
    'TRADE - BUY BANEXCOIN CREATE ERROR': 'No completado',
    'TRADE - BUY BANEXCOIN APPROVAL ERROR': 'No completado',
    'TRADE - BUY OTP INVALID': 'No completado',
    'TRADE - BUY WITHDRAW BANEXCOIN IN PROCESS': 'En proceso',
    'TRADE - BUY BANEXCOIN ORDER AP ERROR': 'No completado',
    'TRADE - BANEXCOIN ORDER AP ERROR': 'No completado',

    New: 'Nuevo',
    Submitted: 'En proceso',
    Rejected: 'Rechazado',
    FullyProcessed: 'Completado',
    Failed: 'En proceso',
    AdminProcessing: 'En proceso',
    AdminCancelled: 'Rechazado'
  };

  return !empty(statuses[original]) ? statuses[original] : original;
}

export function convertType(value) {
  if (value === 'undefined') return undefined;
  if (value === 'null') return null;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
}

export function cleanText(value) {
  if (value === 'undefined') return '';
  if (value === 'null') return '';
  return value;
}

export function authorizedLevel() {
  return [10, 11, 12, 13, 14, 18,101];
}

export function getLevelsList() {
  return [0, 10, 11, 12, 13, 14, 17, 18, 100, 101];
}

export function getLevelsListNoResident() {
  return [0, 11, 12, 13, 14, 17, 18];
}

export function isMayor(currentLevel, levelNumber) {
  let levels = getLevelsList();
  let findIndexCurrent = levels.indexOf(currentLevel);
  let findIndexLevel = levels.indexOf(levelNumber);

  return findIndexCurrent > findIndexLevel;
}

export function isMayorIgual(currentLevel, levelNumber) {
  let levels = getLevelsList();
  let findIndexCurrent = levels.indexOf(currentLevel);
  let findIndexLevel = levels.indexOf(levelNumber);

  return findIndexCurrent >= findIndexLevel;
}

export function validateLevelNumber(currentLevel, virtualRequestedLevel) {
  let levels = currentLevel === 0 ? getLevelsListNoResident() : getLevelsList();
  let currentLevelIndex = levels.indexOf(currentLevel);

  let isValid = false;
  if (levels[currentLevelIndex + 1] === virtualRequestedLevel) {
    isValid = true;
  }
  return isValid;
}

export function validateField(grupos, inputs) {
  let errorFields = {};

  for (let g in grupos) {
    let fields = grupos[g].fields;
    for (let i in fields) {
      let campo = fields[i];
      let name = campo.name;
      let type = campo.type;
      let required = !empty(campo.props) ? campo.props[0].required : false;

      let value = !empty(convertType(inputs[name] + ''));

      if (campo.hasOwnProperty('displayOn') && !empty(campo.displayOn)) {
        let p = campo.displayOn;
        let allValidToDisplay = 0;
        for (let ii in p) {
          let pr = p[ii];
          if (pr.hasOwnProperty('isEqualTo')) {
            if (pr.isEqualTo) {
              if (inputs[pr.parent] === pr.conditionValue) {
                if (required && value) {
                  allValidToDisplay++;
                }
                if (!required) {
                  allValidToDisplay++;
                }
              } else {
                allValidToDisplay++;
              }
            } else {
              if (inputs[pr.parent] !== pr.conditionValue) {
                if (required && value) {
                  allValidToDisplay++;
                }
                if (!required) {
                  allValidToDisplay++;
                }
              } else {
                allValidToDisplay++;
              }
            }
          } else {
            if (inputs[pr.parent] === pr.conditionValue) {
              if (required && value) {
                if (
                  !empty(campo.props[0]) &&
                  campo.props[0].hasOwnProperty('minLength') &&
                  campo.props[0].hasOwnProperty('maxLength')
                ) {
                  if (
                    inputs[name].length <= campo.props[0].minLength &&
                    inputs[name].length >= campo.props[0].maxLength
                  ) {
                    allValidToDisplay++;
                  }
                } else if (
                  !empty(campo.props[0]) &&
                  campo.props[0].hasOwnProperty('minLength') &&
                  !campo.props[0].hasOwnProperty('maxLength')
                ) {
                  if (inputs[name].length <= campo.props[0].minLength) {
                    allValidToDisplay++;
                  }
                } else if (
                  !empty(campo.props[0]) &&
                  !campo.props[0].hasOwnProperty('minLength') &&
                  campo.props[0].hasOwnProperty('maxLength')
                ) {
                  if (inputs[name].length >= campo.props[0].maxLength) {
                    allValidToDisplay++;
                  }
                } else {
                  allValidToDisplay++;
                }
              }
              if (!required) {
                allValidToDisplay++;
              }
            } else {
              allValidToDisplay++;
            }
          }
        }

        if (allValidToDisplay !== p.length && allValidToDisplay < p.length) {
          errorFields[name] = true;
        }
      } else {
        if (type === 'date') {
          let b = moment(convertType(inputs[name]), 'YYYY-MM-DD');
          if (!b.isValid()) {
            errorFields[name] = true;
          }
        } else if (type === 'tel') {
          if (empty(convertType(inputs[name] + ''))) {
            errorFields[name] = true;
          } else {
            if (inputs[name].length < 9) {
              errorFields[name] = true;
            }
          }
        } else if (type === 'checkbox') {
          if (required) {
            if (!value) {
              errorFields[name] = true;
            }
          }
        } else {
          if (required) {
            if (!value) {
              errorFields[name] = true;
            } else {
              if (
                !empty(campo.props[0]) &&
                campo.props[0].hasOwnProperty('minLength') &&
                campo.props[0].hasOwnProperty('maxLength')
              ) {
                if (
                  inputs[name].length > campo.props[0].minLength &&
                  inputs[name].length < campo.props[0].maxLength
                ) {
                  errorFields[name] = true;
                }
              }
              if (
                !empty(campo.props[0]) &&
                campo.props[0].hasOwnProperty('minLength') &&
                !campo.props[0].hasOwnProperty('maxLength')
              ) {
                if (inputs[name].length > campo.props[0].minLength) {
                  errorFields[name] = true;
                }
              }
              if (
                !empty(campo.props[0]) &&
                !campo.props[0].hasOwnProperty('minLength') &&
                campo.props[0].hasOwnProperty('maxLength')
              ) {
                if (inputs[name].length < campo.props[0].maxLength) {
                  errorFields[name] = true;
                }
              }
            }
          }
        }
      }
    }
  }
  console.log('error validation', errorFields);
  return errorFields;
}

export function padStart(number, digits = 2, emptyDigit = 0) {
  let length = 0;
  let n = Math.abs(number);
  let absoluteNumber = n;
  do {
    n /= 10;
    length++;
  } while (n >= 1);
  const prefix = Array(Math.max(digits - length + 1, 0)).join(emptyDigit);
  return number < 0 ? `-${prefix}${absoluteNumber}` : prefix + number;
}

export function allowedTemp() {
  return ['132.251.194.138', '209.45.51.218'];
}
