import React from "react";
import PropTypes from "prop-types";
import { CardGradient } from "../index";
import VerificationInput from "react-verification-input";
import "./twofa.css";

function TwoFa(props, context) {
  const { handleSteps = {
    next: () => {},
    previous: () => {}
  }, action } = props;

  const onChange2FA = async (ev) => {
    const { value } = ev.target;
    if (value.length < 6) return;
    await action(value);
  };
  return (
    <CardGradient
      customClassName="two-fa-confirmation-w"
      title={context.t("Enter 2FA digital token")}
      message={context.t("Enter the 6-digit code or Digital Token (2FA) that the Google Authenticator App shows you, to confirm this operation.")}
      backAction={handleSteps.previous}
    >
      <VerificationInput
        removeDefaultStyles
        autoFocus={true}
        length={6}
        validChars="/^\d+$/"
        container={{
          className: "pt-4 pb-4",
        }}
        characters={{
          className: "characters",
        }}
        character={{
          className: "character",
          classNameInactive: "character--inactive",
          classNameSelected: "character--selected",
        }}
        inputField={{
          onChange: onChange2FA,
        }}
      />
    </CardGradient>
  );
}

TwoFa.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default TwoFa;
