import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { Link } from 'react-router-dom';

const SecondSection = (props, context) => {
  const { instruments, level1 } = props;
  return (
    <section className="page-section page-section--default py-xl-5 theme-light">
      <div className="page-section__container container-hero px-6 px-sm-4 py-8 py-xl-5">
        <div className="header header--full-width mb-6 mb-lg-9">
          <div className="header__container mx-auto">
            <div className="header__text ">
              <div className="heading-body">
                <div className="title">
                  <h2 className="text-center">
                    {context.t('Getting started is very easy')}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cards cards--default row mt-n8 small">
          <div className="card-default d-flex mt-4 card-section col">
            <div className="card-default__container d-flex flex-column w-100 pt-4 pb-lg-2 px-xl-8">
              <div className="image__wrapper d-flex justify-content-center align-items-center raster-img">
                <img
                  src="/local/landing-page/images/manya/Pasos-04.svg"
                  width={268}
                  height={268}
                  typeof="foaf:Image"
                  className="image"
                />
              </div>
              <div className="heading-body">
                <div className="title">
                  <p>{context.t('Register and verify your email')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-default d-flex mt-4 card-section col">
            <div className="card-default__container d-flex flex-column w-100 pt-4 pb-lg-2 px-xl-8">
              <div className="image__wrapper d-flex justify-content-center align-items-center raster-img">
                <img
                  src="/local/landing-page/images/manya/Pasos-03.svg"
                  width={268}
                  height={268}
                  typeof="foaf:Image"
                  className="image"
                />
              </div>
              <div className="heading-body">
                <div className="title">
                  <p>{context.t('Enter and validate your identification')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-default d-flex mt-4 card-section col">
            <div className="card-default__container d-flex flex-column w-100 pt-4 pb-lg-2 px-xl-8">
              <div className="image__wrapper d-flex justify-content-center align-items-center raster-img">
                <img
                  src="/local/landing-page/images/manya/Pasos-01.svg"
                  width={268}
                  height={268}
                  typeof="foaf:Image"
                  className="image"
                />
              </div>
              <div className="heading-body">
                <div className="title">
                  <p>{context.t('Deposit your funds')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-default d-flex mt-4 card-section col">
            <div className="card-default__container d-flex flex-column w-100 pt-4 pb-lg-2 px-xl-8">
              <div className="image__wrapper d-flex justify-content-center align-items-center raster-img">
                <img
                  src="/local/landing-page/images/manya/Pasos-02.svg"
                  width={268}
                  height={268}
                  typeof="foaf:Image"
                  className="image"
                />
              </div>
              <div className="heading-body">
                <div className="title">
                  <p>{context.t('Ready! Start exchanging')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="heading-body">
          <div className="paragraph-text">
            <p className="text-center">&nbsp;</p>
            <p className="text-center">
              {context.t(
                'The digital platform for the exchange of cryptocurrencies and fiat money that gives way to your financial independence'
              )}.{' '}
              <Link to="/login/" target="_blank">
                {context.t('Enter now')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

SecondSection.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  }
}) => {
  return { instruments, level1 };
};

export default connect(mapStateToProps)(SecondSection);
