import React from 'react';
import { FormSelectText } from '../../common/BnxFormsComponents';

const NetworkSelector = (props, context) => {
  const {
    options = [],
    value = '',
    setNetwork = () => {},
    className = '',
  } = props;
  const onlyOption = options.length < 2;
  return (
    <FormSelectText
      required
      name="network-withdraw"
      label={`${!onlyOption ? 'Select ' : ''}Deposit Network`}
      warning={`Attention: ${onlyOption ? 'The deposit network' : 'The network you select to deposit'} must match the withdrawal network or you will lose the funds`}
      classNameContainer={`${onlyOption ? 'only-item' : ''}${className}`}
      defaultOption="Select network"
      options={options}
      value={value}
      onChange={e => {
        e.persist();
        setNetwork(e.target.value);
      }}
      errors={{}}
    />
  );
};

export default NetworkSelector;
