import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "../../../../components/Spinner/Spinner";
import "./loader.css"

function Loader(_, context) {
  return (
    <div className="banexcard-page-loading">
      <Spinner />
      <p>{context.t("Loading information from your Banexcard")}...</p>
    </div>
  );
}
Loader.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Loader;
