import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import destinationTagHelper from 'apex-web/lib/helpers/destinationTagHelper';
import { jsonRed } from '../SendForm/SelectRedData';
import NetworkSelector from './NetworkSelector';
import empty from 'is-empty';
import { authorizedLevel } from './../../../helpers/lib';
import InformationIcon from './../../../images/bnx-icons/information-circle.svg'
import './ReceiveAddress.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import APIcon from '../../common/APIcon';

var bemClasses = getBEMClasses('receive-address');

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}
var ReceiveAddressComponent = (function(_React$Component) {
  _inherits(ReceiveAddressComponent, _React$Component);

  function ReceiveAddressComponent() {
    _classCallCheck(this, ReceiveAddressComponent);

    var _this = _possibleConstructorReturn(
      this,
      (
        ReceiveAddressComponent.__proto__ ||
        Object.getPrototypeOf(ReceiveAddressComponent)
      ).apply(this, arguments)
    );
    _this.onchangeNetwork = function(valor) {
      return _this.setState({ network: valor });
    };
    _this.handleCopy = (text) => {
      return _this.setState({ copyText: text });
    };
    _this.changeLoading = () => {
      return _this.setState({ loading: false });
    }
    _this.state = {
      network: '',
      copyText: '',
      loading: true
    };
    return _this;
  }

  _createClass(ReceiveAddressComponent, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        const { ProductSymbol} = this.props.product;
        const jsonNetwork = jsonRed();
        const datosNetwork = jsonNetwork['deposit'][ProductSymbol];
        if(datosNetwork.length === 1){
          this.onchangeNetwork(datosNetwork[0].value)
        }
      }
    },
    {
      key: 'componentWillMount',
      value: function componentWillMount() {
        var _props = this.props,
          selectDepositProduct = _props.selectDepositProduct,
          product = _props.product;

        selectDepositProduct(product.ProductId);
      }
    },
    {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var _props2 = this.props,
          addressList = _props2.deposit.depositInfo,
          _props2$deposit = _props2.deposit,
          isLoading = _props2$deposit.isLoading,
          error = _props2$deposit.error,
          product = _props2.product,
          showSnack = _props2.showSnack,
          WalletsDisabledMessage = _props2.WalletsDisabledMessage,
          symbol = _props2.product.ProductSymbol,
          selectedAccountId = _props2.selectedAccountId,
          accounts = _props2.accounts;
        // Protocol prefix for the QR Code, lowercase name with no spaces.
        var depositType = product.ProductFullName.toLowerCase().replace(
          /\W/g,
          ''
        );
        var selectedAddress = void 0;

        if (!isLoading && addressList.length) {
          selectedAddress = addressList[addressList.length - 1];
        }

        var _destinationTagHelper = destinationTagHelper(selectedAddress),
          _destinationTagHelper2 = _slicedToArray(_destinationTagHelper, 2),
          address = _destinationTagHelper2[0],
          destinationTag = _destinationTagHelper2[1];

        let jsonNetwork = jsonRed();
        let datosNetwork = jsonNetwork['deposit'][symbol];
        let isHide = !empty(this.state.network) ? '' : ' hide';

        let currentAccount = accounts.find(
          element => element.AccountId === selectedAccountId
        );
          
        if(datosNetwork.length === 1 && address){
          const network = datosNetwork[0]
          const newNetwork = this.context.t(network.value === '1' ? 'Bitcoin Network' : network.sub)
          const message = this.context.t(`My {product} ({symbol}) wallet on Banexcoin ({network}) is: {address}`,
          {
            product: product.ProductFullName,
            symbol: product.ProductSymbol,
            network: newNetwork,
            address
          })
          if(this.state.loading){
            this.handleCopy(message)
            this.changeLoading()
          }
        }
        
        const copyAddress = (isShare) => {
          showSnack({
            id: "RECEIVE_ADDRESS_COPY",
            text: this.context.t(isShare ? "The information has been copied to the clipboard." : "The address has been copied to the clipboard."),
            type: "success"
          });
          navigator.clipboard.writeText(isShare ? this.state.copyText : address)
        }

        const changeNetwork = (value) => {
          const network = datosNetwork.find(e => e.value === value)
          const newNetwork = this.context.t(network.value === '1' ? 'Bitcoin Network' : network.sub)
          const message = this.context.t(`My {product} wallet address is:\n{network}.\nAddress: {address}`,
          {
            product: product.ProductFullName,
            network: newNetwork,
            address
          })
          this.handleCopy(message)
          this.onchangeNetwork(value)
        }

        if (!authorizedLevel().includes(currentAccount.VerificationLevel)) {
          return (
            <Link
              className="verification-required__container"
              to="/settings/verification-level"
              onClick={() => {
                var cerrar = document.getElementsByClassName(
                  'ap-sidepane__close-button'
                );
                if (cerrar) {
                  cerrar[0].click();
                }
              }}>
              <div className="verification-required">
                <span className="isvg loaded ap-icon ap-icon--alert ap-icon--small verification-required__warning verification-required__warning--alert verification-required__warning--small">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 12 12">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M5.01 1.194c-.006-.05-.01-.1-.01-.15C5 .466 5.448 0 6 0s1 .467 1 1.043c0 .051-.004.102-.01.15C8.72 1.73 10 3.64 10 5.914l.5 2.435h-9L2 5.913C2 3.64 3.28 1.73 5.01 1.193zm-.51 9.24h3C7.5 11.3 6.828 12 6 12s-1.5-.7-1.5-1.565zM.522 8.349h10.956a.522.522 0 0 1 0 1.043H.522a.522.522 0 1 1 0-1.043z"
                    />
                  </svg>
                </span>
                <span className="ap--label verification-required__not-verified ">
                  {this.context.t(
                    'Verify your identity to start trading. Click here to verify.'
                  )}
                </span>
              </div>
            </Link>
          );
        }

        return React.createElement(
          "section",
          { className: bemClasses() },
          <div>
          <p className='transfer-title'>{this.context.t("Receive {product} from external wallets.", {
            product: product.ProductFullName,
          })}</p>
        </div>,
          selectedAddress &&
            React.createElement(
              React.Fragment,
              null,
              <p className={`${bemClasses("copy-address-text")} crypto-initial-title`}>
                {this.context.t("{product} deposit address", {
                  product: product.ProductFullName,
                })}
                <OverlayTrigger
                key={"level-for-user-tooltip"}
                placement={"right"}
                overlay={
                  <Tooltip id={`level-for-user-tooltip`}>
                    <div className="tooltip-general">
                      {this.context.t(
                        "Copy and paste your {product} address into your external wallet.",{
                          product: product.ProductFullName
                        }
                      )}
                    </div>
                  </Tooltip>
                }
              >
                <img src={InformationIcon} width={20} className='ml-2' alt="Information icon" />
              </OverlayTrigger>
              </p>,
              <NetworkSelector
                options={datosNetwork}
                setNetwork={changeNetwork}
                value={this.state.network}
              />,
              React.createElement(
                "div",
                { className: bemClasses("copy-address-payload") + isHide  + ' product-text-v2'},
                this.context.t('{product} network wallet:', { product: product.ProductFullName })
              ),
              React.createElement(
                "div",
                {
                  className: bemClasses("copy-address-payload") + isHide,
                },
                <div className={bemClasses("address")}>
                  <span className='address-text'>{address}</span>
                  <span onClick={() => copyAddress(false)} className='icon-share'>
                    <OverlayTrigger
                      key={"copy"}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`copy`}>
                          <div className="tooltip-general">
                            {this.context.t('Copy')}
                          </div>
                        </Tooltip>
                      }>
                      <div>
                        <APIcon name="copy" customClass={bemClasses("copy-icon")}/>
                      </div>
                    </OverlayTrigger>
                  </span>
                  <span onClick={() => copyAddress(true)} className='icon-share copy'>
                      <OverlayTrigger
                        key={"share"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`share`}>
                            <div className="tooltip-general">
                              {this.context.t('Share')}
                            </div>
                          </Tooltip>
                        }>
                        <div>
                          <APIcon name="share" customClass={bemClasses("copy-icon")} />
                        </div>
                      </OverlayTrigger>
                    </span>
                </div>
              ),
              React.createElement(
                "p",
                { className: bemClasses("description") + isHide + ' description-text-v2'},
                this.context.t(
                  "Or scan the QR code from the external wallet to receive your funds:"
                )
              ),
              React.createElement(
                "div",
                { className: bemClasses("qr-code") + isHide },
                React.createElement(APQRCode, {
                  value: depositType + ":" + selectedAddress,
                })
              ),
              destinationTag &&
                React.createElement(
                  "div",
                  {
                    onClick: function onClick() {
                      showSnack({
                        id: "RECEIVE_ADDRESS_COPY",
                        text: _this2.context.t(
                          "The destination tag has been copied to the clipboard."
                        ),
                      });
                      copyToClipboard(destinationTag);
                    },
                  },
                  React.createElement(
                    "p",
                    { className: bemClasses("destination-tag-text") },
                    this.context.t("Be sure to include this destination tag:")
                  ),
                  React.createElement(
                    "span",
                    { className: bemClasses("address") },
                    destinationTag
                  ),
                  React.createElement(APIcon, {
                    name: "copy",
                    customClass: bemClasses("copy-icon"),
                  })
                )
            ),
          isLoading &&
            React.createElement(Spinner, {
              text: this.context.t("Loading url..."),
              customClass: bemClasses,
            }),
          WalletsDisabledMessage
            ? React.createElement(
                "span",
                { className: bemClasses("error") },
                this.context.t(WalletsDisabledMessage)
              )
            : error &&
                React.createElement(
                  "span",
                  { className: bemClasses("error") },
                  this.context.t(error)
                )
        );
      }
    }
  ]);

  return ReceiveAddressComponent;
})(React.Component);

ReceiveAddressComponent.defaultProps = {
  deposit: {
    templateInfo: {},
    template: {},
    isLoading: false,
    error: '',
    depositInfo: {},
    depositStatus: {}
  },
  product: {}
};

ReceiveAddressComponent.propTypes = {
  product: PropTypes.object,
  deposit: PropTypes.shape({
    templateInfo: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    product: PropTypes.number,
    depositInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    depositStatus: PropTypes.shape({
      success: PropTypes.bool,
      RequestCode: PropTypes.string
    }).isRequired
  })
};

ReceiveAddressComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveAddressComponent;
