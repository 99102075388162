import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { Button, Form, Row, Col, Tab, Tabs } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import 'moment/locale/es.js';
import { DatePickerInput } from 'rc-datepicker';
import moment from 'moment';
import empty from 'is-empty';
import { useForm } from '../../../hooks/formHooks';
import {
  submitFormData,
  getCountries,
  getLevelBasic,
  editFormData
  // sendKYCNotification
} from './KYC_IMFormStepComponentHooks';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import Countries from '../../Countries';
//CSS
import './KYC_IMFormStepComponent.css';

const KYCIMFormStepComponentClasses = getBEMClasses(
  'KYC_IMFormStepComponent-page'
);

const KYC_IMFormStepComponent = (
  { selectedAccountId, userInfo, accounts },
  context
) => {
  try {
    if (empty(userInfo) || empty(selectedAccountId)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }
    const [loading, setLoading] = useState(0);
    const [key, setKey] = useState(1);
    const [countries, setCountries] = useState({});
    const [basicLevel, setBasicLevel] = useState({});
    const [attachedPep, setAttachedPep] = useState(false);
    const [documentDataType, setDocumentDataType] = useState(0);
    const [backDocumentType, setBackDocumentType] = useState(0);
    const [facesType, setFacesType] = useState(0);

    const showPep = e => {
      let show = e.target.value === '1' ? true : false;
      setAttachedPep(show);
    };

    let currentAccount = [];
    for (let i = 0; i < accounts.length; i++) {
      var cuentaEncontrada = accounts[i];
      if (cuentaEncontrada.AccountId === selectedAccountId) {
        currentAccount.push(cuentaEncontrada);
      }
    }
    useEffect(() => {
      async function loadLevelBasic() {
        setLoading(1);

        try {
          let result = await getLevelBasic(userInfo.UserId, selectedAccountId);
          setBasicLevel(result.data.level_basic);
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadLevelBasic();
      }
    }, []);

    useEffect(
      () => {
        inputs = {
          ...inputs,
          first_name: basicLevel.firstName,
          middle_name: basicLevel.middleName,
          last_name: basicLevel.lastName,
          second_last_name: basicLevel.secondLastName,
          birthday: moment(basicLevel.birthday, 'YYYY-MM-DD'),
          nationality: basicLevel.nationality,
          birth_country: basicLevel.birthCountry,
          address_main: basicLevel.addressMain,
          occupation: basicLevel.occupation,
          work_center: basicLevel.workCenter,
          phone: basicLevel.phone,
          is_pep: basicLevel.isPep,
          billing_country: basicLevel.billingCountry,
          billing_address: basicLevel.billingAddress,
          billing_state: basicLevel.billingState,
          billing_city: basicLevel.billingCity,
          billing_zip: basicLevel.billingZip,
          document_type: basicLevel.documentType,
          document_country: basicLevel.documentCountry,
          document_number: basicLevel.documentNumber,
          document_data: basicLevel.documentData,
          back_document_data: basicLevel.backDocumentData,
          faces_images: basicLevel.facesImages
        };
        setInputs(inputs);
      },
      [basicLevel]
    );

    const isValidUrl = string => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };
    const onChangeCallback = async inputs => {
      console.log('inputs', inputs);
    };

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        if (basicLevel.status === 5) {
          let result = await editFormData(
            userInfo.UserId,
            inputs,
            selectedAccountId
          );
        } else {
          let result = await submitFormData(
            userInfo.UserId,
            inputs,
            selectedAccountId
          );
        }

        var cerrar = document.getElementsByClassName(
          'ap-sidepane__close-button'
        );

        if (cerrar) {
          cerrar[0].click();
        }
        toast.success(
          context.t(
            'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n' +
              'An email with the response to your request.'
          ),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );

        setLoading(1);

        try {
          let resultRequest = await getLevelBasic(
            userInfo.UserId,
            selectedAccountId
          );
          setBasicLevel(resultRequest.data.level_basic);
          setKey(1);
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};
      if (empty(inputs.birthday)) {
        errors.birthday = true;
      } else {
        var m = moment(inputs.birthday, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.birthday = true;
        }
      }

      return errors;
    };

    useEffect(() => {
      if (!empty(userInfo)) {
        getListCountries();
      }
    }, []);

    const getListCountries = async () => {
      setLoading(1);
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
      } catch (error) {
        getListCountries();
        // toast.warn(context.t('Information could not accesible'), {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
      setLoading(0);
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : ''
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    const langFixed = localStorage.getItem('language');
    var lang = localStorage.getItem('language');

    const pepForm = attachedPep ? (
      <Form.Group
        controlId="pep_document"
        title={context.t('Upload required PEP Document')}>
        <Form.Label>
          {context.t('Upload required PEP Document')}{' '}
          <span className="asterisk">*</span>
          <div className="download-document-box">
            {lang === 'es' ? (
              <span>
                {context.t(
                  'Download the document file and fill it out. Then, upload the file to validate it.'
                )}
                <a href="https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/es/Anexo%20A%20-%20Formulario%20PEP%20Banexcoin.pdf">
                  <img
                    className="document-image-download"
                    src="/local/landing-page/images/doc-file.png"
                    alt="Doc File ES"
                  />
                </a>
              </span>
            ) : (
              <span>
                {context.t(
                  'Download the document file and fill it out. Then, upload the file to validate it.'
                )}
                <a href="https://banexcoin.sgp1.digitaloceanspaces.com/banex-docs/pep-document/en/Annex%20A%20-%20PEP%20Form%20Banexcoin.pdf">
                  <img
                    className="document-image-download"
                    src="/local/landing-page/images/doc-file.png"
                    alt="Doc File EN"
                  />
                </a>
              </span>
            )}
          </div>
        </Form.Label>
        <Form.Control
          required
          name="pep_document"
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={onInputFileChange}
        />
        <Form.Control.Feedback type="invalid">
          {context.t('You must attach a PEP document')}
        </Form.Control.Feedback>
      </Form.Group>
    ) : null;

    return (
      <React.Fragment>
        <Loading loading={loading} />
        {basicLevel.status === 4 ? (
          <div>{context.t('Your account has been locked.')}</div>
        ) : (
          <div className={KYCIMFormStepComponentClasses()}>
            <h1>{context.t('Raise my customer level to Beginner')}</h1>
            <p>
              {context.t(
                'To raise the customer level you must fill out the form below'
              )}
            </p>
            {!empty(basicLevel) && basicLevel.status !== 5 ? (
              <Tabs activeKey={key} onSelect={k => setKey(k)}>
                <Tab eventKey={1} title={context.t('Personal Data')}>
                  <Row>
                    <Col xl="4">
                      <p>{context.t('Email')}:</p>
                      <p>{basicLevel.email}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="3">
                      <p>{context.t('First Name')}:</p>
                      <p>{basicLevel.firstName}</p>
                    </Col>

                    <Col xl="3">
                      <p>{context.t('Middle Name')}:</p>
                      <p>{basicLevel.middleName}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Last Name')}:</p>
                      <p>{basicLevel.lastName}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Second Last Name')}:</p>
                      <p>{basicLevel.secondLastName}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Birthday')}:</p>
                      <p>{basicLevel.birthday}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Nationality')}:</p>
                      <p>{basicLevel.nationality}</p>
                    </Col>
                    <Col xl="3">
                      <p>{context.t('Birth Country')}:</p>
                      <p>{basicLevel.birthCountry}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p>{context.t('Address Main')}:</p>
                      <p>{basicLevel.addressMain}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p>
                        {context.t('Activity, occupation, trade or profession')}:
                      </p>
                      <p>{basicLevel.occupation}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Work Center')}:</p>
                      <p>{basicLevel.workCenter}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Phone')}:</p>
                      <p>{basicLevel.phone}</p>
                    </Col>
                    <Col xl="6">
                      <p>
                        {context.t('Is a PEP? (People Exposed Politically)')}:
                      </p>
                      <p>
                        {basicLevel.isPep === '1'
                          ? context.t('Yes')
                          : context.t('No')}
                      </p>
                      <p>
                        <a href={basicLevel.pepDocument}>
                          {context.t('Download PEP Document')}{' '}
                          <img
                            className="document-image-download"
                            src="/local/landing-page/images/doc-file.png"
                            alt="Doc File ES"
                          />
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Billing Country')}:</p>
                      <p>{basicLevel.billingCountry}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Billing State')}:</p>
                      <p>{basicLevel.billingState}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Billing City')}:</p>
                      <p>{basicLevel.billingCity}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Billing Zip')}:</p>
                      <p>{basicLevel.billingZip}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p>{context.t('Billing Address')}:</p>
                      <p>{basicLevel.billingAddress}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <Button
                        variant="info"
                        type="button"
                        onClick={() => setKey(2)}
                        style={{ padding: '1em', width: '40%' }}>
                        {context.t('Next')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey={2} title={context.t('Documents')}>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Type')}:</p>
                      <p>{basicLevel.documentType}</p>
                    </Col>
                    <Col xl="6">
                      <p>{context.t('Document Number')}:</p>
                      <p>{basicLevel.documentNumber}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Country')}:</p>
                      <p>{basicLevel.documentCountry}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Document Data')}:</p>
                      <img
                        src={
                          !empty(basicLevel.documentData)
                            ? isValidUrl(basicLevel.documentData)
                              ? basicLevel.documentData
                              : URL.createObjectURL(basicLevel.documentData)
                            : '/local/landing-page/images/no-image-box.png'
                        }
                        width="60px"
                        alt={context.t('Document Data')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Back Document Data')}:</p>
                      <img
                        src={
                          !empty(basicLevel.backDocumentData)
                            ? isValidUrl(basicLevel.backDocumentData)
                              ? basicLevel.backDocumentData
                              : URL.createObjectURL(basicLevel.backDocumentData)
                            : '/local/landing-page/images/no-image-box.png'
                        }
                        width="60px"
                        alt={context.t('Back Document Data')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p>{context.t('Faces Images')}:</p>
                      <img
                        src={
                          !empty(basicLevel.facesImages)
                            ? isValidUrl(basicLevel.facesImages)
                              ? basicLevel.facesImages
                              : URL.createObjectURL(basicLevel.facesImages)
                            : '/local/landing-page/images/no-image-box.png'
                        }
                        width="60px"
                        alt={context.t('Faces Images')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <p className="success-msj">
                        {context.t(
                          'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n An email with the response to your request.'
                        )}
                      </p>
                    </Col>
                    <Col xl="6">
                      <Button
                        variant="warning"
                        type="button"
                        onClick={() => setKey(1)}
                        style={{ width: '40%' }}>
                        {context.t('Back')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            ) : (
              <Form
                noValidate
                validated={inputs.validated}
                encType="multipart/form-data"
                onSubmit={onSubmit}>
                <Tabs activeKey={key} onSelect={k => setKey(k)}>
                  <Tab eventKey={1} title={context.t('Personal Data')}>
                    <Row>
                      <Col xl="4">
                        <Form.Group controlId="email">
                          <Form.Label>{context.t('Email')}</Form.Label>
                          <p>{inputs.email}</p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {empty(basicLevel.firstName) ? (
                        <Col xl="3">
                          <Form.Group controlId="first_name">
                            <Form.Label>
                              {context.t('First Name')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="first_name"
                              type="text"
                              defaultValue={inputs.first_name || ''}
                              onChange={onInputChange}
                              placeholder={context.t('First Name')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must put first name')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('First Name')}:</p>
                          <p>{basicLevel.firstName}</p>
                        </Col>
                      )}
                      {empty(basicLevel.middleName) ? (
                        <Col xl="3">
                          <Form.Group controlId="middle_name">
                            <Form.Label>
                              {context.t('Middle name')}{' '}
                              <span className="asterisk" />
                            </Form.Label>
                            <Form.Control
                              name="middle_name"
                              defaultValue={inputs.middle_name || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Middle name')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must put middle name')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('Middle Name')}:</p>
                          <p>{basicLevel.middleName}</p>
                        </Col>
                      )}
                      {empty(basicLevel.lastName) ? (
                        <Col xl="3">
                          <Form.Group controlId="last_name">
                            <Form.Label>
                              {context.t('Last Name')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="last_name"
                              defaultValue={inputs.last_name || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Last Name')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must put Last Name')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('Last Name')}:</p>
                          <p>{basicLevel.lastName}</p>
                        </Col>
                      )}
                      {empty(basicLevel.secondLastName) ? (
                        <Col xl="3">
                          <Form.Group controlId="second_last_name">
                            <Form.Label>
                              {context.t('Second Last Name')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="second_last_name"
                              defaultValue={inputs.second_last_name || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Second Last Name')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must put Second Last Name')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('Second Last Name')}:</p>
                          <p>{basicLevel.secondLastName}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.birthday) ? (
                        <Col xl="6">
                          <Form.Group controlId="birthday">
                            <Form.Label>
                              {context.t('Date of Birth')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <DatePickerInput
                              autoComplete="off"
                              showOnInputClick
                              onChange={value => {
                                onInputChangeByName('birthday', value);
                              }}
                              displayFormat="DD/MM/YYYY"
                              returnFormat="YYYY-MM-DD"
                              name="birthday"
                              // value={inputs.birthday || ""}
                              locale={langFixed}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className={errors.birthday ? 'showError' : ''}>
                              {context.t(
                                'You must choose a valid date of birth'
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Birthday')}:</p>
                          <p>{basicLevel.birthday}</p>
                        </Col>
                      )}
                      {empty(basicLevel.nationality) ? (
                        <Col xl="3">
                          <Form.Group controlId="nationality">
                            <Form.Label>
                              {context.t('Nationality')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Countries
                              countries={countries}
                              handleCountryChange={onInputChange}
                              name="nationality"
                              value={inputs.nationality || ''}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must put Nationality')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('Nationality')}:</p>
                          <p>{basicLevel.nationality}</p>
                        </Col>
                      )}
                      {empty(basicLevel.birthCountry) ? (
                        <Col xl="3">
                          <Form.Group controlId="birth_country">
                            <Form.Label>
                              {context.t('Birth Country')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Countries
                              countries={countries}
                              handleCountryChange={onInputChange}
                              name="birth_country"
                              value={inputs.birth_country || ''}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the birth of country')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="3">
                          <p>{context.t('Birth Country')}:</p>
                          <p>{basicLevel.birthCountry}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.addressMain) ? (
                        <Col xl="12">
                          <Form.Group controlId="address_main">
                            <Form.Label>
                              {context.t('Main address')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="address_main"
                              defaultValue={inputs.address_main || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t(
                                'Main address, Zip Code, City, State, Country'
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the main address')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="12">
                          <p>{context.t('Address Main')}:</p>
                          <p>{basicLevel.addressMain}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.occupation) ? (
                        <Col xl="6">
                          <Form.Group controlId="occupation">
                            <Form.Label>
                              {context.t(
                                'Activity, occupation, trade or profession'
                              )}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="occupation"
                              defaultValue={basicLevel.occupation || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t(
                                'Activity, occupation, trade or profession'
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t(
                                'You must enter the Activity, occupation, trade or profession'
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>
                            {context.t(
                              'Activity, occupation, trade or profession'
                            )}:
                          </p>
                          <p>{basicLevel.occupation}</p>
                        </Col>
                      )}
                      {empty(basicLevel.workCenter) ? (
                        <Col xl="6">
                          <Form.Group controlId="work_center">
                            <Form.Label>
                              {context.t('Work Center')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="work_center"
                              defaultValue={basicLevel.workCenter || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Work Center')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the Work Center')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Work Center')}:</p>
                          <p>{basicLevel.workCenter}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.phone) ? (
                        <Col xl="6">
                          <Form.Group controlId="phone">
                            <Form.Label>
                              {context.t('Phone')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <PhoneInput
                              inputClass={'form-pay-phone-custom'}
                              onChange={value => {
                                onInputChangeByName('phone', value);
                              }}
                              value={inputs.phone || ''}
                              inputExtraProps={{
                                id: 'phone',
                                name: 'phone',
                                requiredmessage: context.t(
                                  'You must enter the phone number'
                                ),
                                required: true
                              }}
                              // isValid={value => {
                              //   console.log("isValid", this.phone, inputs.phone)
                              //   if (this.phone) {
                              //     const { selectedCountry: { format, dialCode }, formattedNumber } = this.phone.state;
                              //     return (
                              //       format.length === formattedNumber.length
                              //     );
                              //   }
                              // }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the phone number')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Phone')}:</p>
                          <p>{basicLevel.phone}</p>
                        </Col>
                      )}
                      {isNaN(basicLevel.isPep) ? (
                        <Col xl="6">
                          <Form.Group
                            controlId="is_pep"
                            title={context.t(
                              'Is a PEP? (People Exposed Politically)'
                            )}>
                            <Form.Label>
                              {context.t(
                                'Is a PEP? (People Exposed Politically)'
                              )}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              required
                              name="is_pep"
                              value={inputs.is_pep || ''}
                              onChange={e => {
                                showPep(e);
                                onInputChange(e);
                              }}>
                              <option value="-1">
                                {context.t('Select if is Pep')}
                              </option>
                              <option value="1">{context.t('Yes')}</option>
                              <option value="0">{context.t('No')}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>
                            {context.t(
                              'Is a PEP? (People Exposed Politically)'
                            )}:
                          </p>
                          <p>
                            {basicLevel.isPep === '1'
                              ? context.t('Yes')
                              : context.t('No')}
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="12">{pepForm}</Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="info"
                          type="button"
                          onClick={() => setKey(2)}
                          style={{ padding: '1em', width: '40%' }}>
                          {context.t('Next')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey={2} title={context.t('Billing Information')}>
                    <Row>
                      {empty(basicLevel.billingCountry) ? (
                        <Col xl="6">
                          <Form.Group controlId="billing_country">
                            <Form.Label>
                              {context.t('Billing Country')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Countries
                              countries={countries}
                              handleCountryChange={onInputChange}
                              name="billing_country"
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the billing country')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Billing Country')}:</p>
                          <p>{basicLevel.billingCountry}</p>
                        </Col>
                      )}
                      {empty(basicLevel.billingState) ? (
                        <Col xl="6">
                          <Form.Group controlId="billing_state">
                            <Form.Label>
                              {context.t('Billing State')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="billing_state"
                              defaultValue={inputs.billing_state || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Billing State')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the billing state')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Billing State')}:</p>
                          <p>{basicLevel.billingState}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.billingCity) ? (
                        <Col xl="6">
                          <Form.Group controlId="billing_city">
                            <Form.Label>
                              {context.t('Billing City')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="billing_city"
                              defaultValue={inputs.billing_city || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Billing City')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the billing city')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Billing City')}:</p>
                          <p>{basicLevel.billingCity}</p>
                        </Col>
                      )}
                      {empty(basicLevel.billingZip) ? (
                        <Col xl="6">
                          <Form.Group controlId="billing_zip">
                            <Form.Label>
                              {context.t('Billing Zip')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="billing_zip"
                              defaultValue={inputs.billing_zip || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Billing Zip')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the billing zip')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Billing Zip')}:</p>
                          <p>{basicLevel.billingZip}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.billingAddress) ? (
                        <Col xl="12">
                          <Form.Group controlId="billing_address">
                            <Form.Label>
                              {context.t('Billing Address')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              required
                              name="billing_address"
                              defaultValue={inputs.billing_address || ''}
                              type="text"
                              onChange={onInputChange}
                              placeholder={context.t('Billing Address')}
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the billing address')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="12">
                          <p>{context.t('Billing Address')}:</p>
                          <p>{basicLevel.billingAddress}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="form-group">
                          <Form.Text className="text-muted">
                            * {context.t('Required fields')}
                          </Form.Text>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="warning"
                          type="button"
                          onClick={() => setKey(1)}
                          style={{ width: '40%' }}>
                          {context.t('Back')}
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Button
                          variant="info"
                          type="button"
                          onClick={() => setKey(3)}
                          style={{ width: '40%' }}>
                          {context.t('Next')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey={3} title={context.t('Documents')}>
                    <Row>
                      {empty(basicLevel.documentType) ? (
                        <Col xl="6">
                          <Form.Group controlId="document_type">
                            <Form.Label>
                              {context.t('Document Type')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              required
                              name="document_type"
                              defaultValue={basicLevel.documentType || ''}
                              onChange={onInputChange}>
                              <option value="">
                                {context.t('Select Document Type')}
                              </option>
                              {inputs.nationality === 'PE' ? (
                                <option value="ID">
                                  {context.t('National identity document')}
                                </option>
                              ) : (
                                <option value="PP">
                                  {context.t('Passport')}
                                </option>
                              )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the document type')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Document Type')}:</p>
                          <p>{basicLevel.documentType}</p>
                        </Col>
                      )}
                      {empty(basicLevel.documentNumber) ? (
                        <Col xl="6">
                          <Form.Group controlId="document_number">
                            <Form.Label>
                              {context.t('Document Number')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            {inputs.nationality === 'PE' ? (
                              <Form.Control
                                required
                                name="document_number"
                                defaultValue={inputs.document_number || ''}
                                type="number"
                                onInput={e => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 8);
                                }}
                                onChange={onInputChange}
                                placeholder={context.t('Document Number')}
                              />
                            ) : (
                              <Form.Control
                                required
                                name="document_number"
                                defaultValue={inputs.document_number || ''}
                                type="text"
                                onInput={e => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 12);
                                }}
                                onChange={onInputChange}
                                placeholder={context.t('Document Number')}
                              />
                            )}
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the document number')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Document Number')}:</p>
                          <p>{basicLevel.documentNumber}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.documentCountry) ? (
                        <Col xl="6">
                          <Form.Group controlId="document_country">
                            <Form.Label>
                              {context.t('Document Country')}{' '}
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Countries
                              countries={countries}
                              handleCountryChange={onInputChange}
                              name="document_country"
                            />
                            <Form.Control.Feedback type="invalid">
                              {context.t('You must enter the document country')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Document Country')}:</p>
                          <p>{basicLevel.documentCountry}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.documentData) ? (
                        <div>
                          <Col xl="10">
                            <Form.Group
                              controlId="document_data"
                              title={context.t('Document Data')}>
                              <Form.Label>
                                {context.t('Document Data')}{' '}
                                {context.t(
                                  '(Supported formats: .JPG and .PNG, maximum size 5 MB)'
                                )}{' '}
                                <span className="asterisk">**</span>
                              </Form.Label>
                              <Form.Control
                                required
                                name="document_data"
                                type="file"
                                accept="image/x-png,image/png,image/gif,image/jpeg"
                                onChange={e => {
                                  onInputFileChange(e);
                                  if (!empty(e.target.value)) {
                                    // 0 = sin validar
                                    // 1 = validado
                                    // 2 = invalido
                                    let fileExt = e.target.value
                                      .split('.')
                                      .pop();
                                    if (
                                      fileExt === 'jpg' ||
                                      fileExt === 'jpeg' ||
                                      fileExt === 'png'
                                    ) {
                                      setDocumentDataType(1);
                                    } else {
                                      setDocumentDataType(2);
                                    }
                                    console.log('fileExt', fileExt);
                                  } else {
                                    setDocumentDataType(2);
                                  }
                                }}
                                isInvalid={
                                  documentDataType === 1 ||
                                  documentDataType === 0
                                    ? false
                                    : true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {context.t(
                                  documentDataType == 2
                                    ? 'The uploaded file must be a jpg/png image type'
                                    : 'You must attach back document data and the file size must be less than or equal to 2MB'
                                )}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xl="2">
                            <img
                              src={
                                !empty(inputs.document_data)
                                  ? isValidUrl(inputs.document_data)
                                    ? inputs.document_data
                                    : URL.createObjectURL(inputs.document_data)
                                  : '/local/landing-page/images/no-image-box.png'
                              }
                              width="60px"
                              alt={context.t('Document Data')}
                            />
                          </Col>
                        </div>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Document Data')}:</p>
                          <img
                            src={
                              !empty(basicLevel.documentData)
                                ? isValidUrl(basicLevel.documentData)
                                  ? basicLevel.documentData
                                  : URL.createObjectURL(basicLevel.documentData)
                                : '/local/landing-page/images/no-image-box.png'
                            }
                            width="60px"
                            alt={context.t('Document Data')}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.backDocumentData) ? (
                        <div>
                          <Col xl="10">
                            <Form.Group
                              controlId="back_document_data"
                              title={context.t('Back Document Data')}>
                              <Form.Label>
                                {context.t('Back Document Data')}{' '}
                                {context.t(
                                  '(Supported formats: .JPG and .PNG, maximum size 5 MB)'
                                )}{' '}
                                <span className="asterisk">**</span>
                              </Form.Label>
                              <Form.Control
                                required
                                name="back_document_data"
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg,image/heic,image/png"
                                onChange={e => {
                                  onInputFileChange(e);
                                  if (!empty(e.target.value)) {
                                    // 0 = sin validar
                                    // 1 = validado
                                    // 2 = invalido
                                    let fileExt = e.target.value
                                      .split('.')
                                      .pop();
                                    if (
                                      fileExt === 'jpg' ||
                                      fileExt === 'jpeg' ||
                                      fileExt === 'png'
                                    ) {
                                      setBackDocumentType(1);
                                    } else {
                                      setBackDocumentType(2);
                                    }
                                    console.log('fileExt', fileExt);
                                  } else {
                                    setBackDocumentType(2);
                                  }
                                }}
                                isInvalid={
                                  backDocumentType === 1 ||
                                  backDocumentType === 0
                                    ? false
                                    : true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {context.t(
                                  backDocumentType == 2
                                    ? 'The uploaded file must be a jpg/png image type'
                                    : 'You must attach back document data and the file size must be less than or equal to 2MB'
                                )}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xl="2">
                            <img
                              src={
                                !empty(inputs.back_document_data)
                                  ? isValidUrl(inputs.back_document_data)
                                    ? inputs.back_document_data
                                    : URL.createObjectURL(
                                        inputs.back_document_data
                                      )
                                  : '/local/landing-page/images/no-image-box.png'
                              }
                              width="60px"
                              alt={context.t('Back Document Data')}
                            />
                          </Col>
                        </div>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Back Document Data')}:</p>
                          <img
                            src={
                              !empty(basicLevel.backDocumentData)
                                ? isValidUrl(basicLevel.backDocumentData)
                                  ? basicLevel.backDocumentData
                                  : URL.createObjectURL(
                                      basicLevel.backDocumentData
                                    )
                                : '/local/landing-page/images/no-image-box.png'
                            }
                            width="60px"
                            alt={context.t('Back Document Data')}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {empty(basicLevel.facesImages) ? (
                        <React.Fragment>
                          <Col xl="6" className="bg-orange">
                            <Form.Group
                              controlId="faces_images"
                              title={context.t('Faces Images')}>
                              <Form.Label>
                                {context.t('Faces Images')}{' '}
                                {context.t(
                                  '(Supported formats: .JPG and .PNG, maximum size 5 MB)'
                                )}{' '}
                                <span className="asterisk">**</span>
                              </Form.Label>
                              <Form.Control
                                required
                                name="faces_images"
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg,image/heic,image/png"
                                onChange={e => {
                                  onInputFileChange(e);
                                  if (!empty(e.target.value)) {
                                    // 0 = sin validar
                                    // 1 = validado
                                    // 2 = invalido
                                    let fileExt = e.target.value
                                      .split('.')
                                      .pop();
                                    if (
                                      fileExt === 'jpg' ||
                                      fileExt === 'jpeg' ||
                                      fileExt === 'png'
                                    ) {
                                      setFacesType(1);
                                    } else {
                                      setFacesType(2);
                                    }
                                    console.log('fileExt', fileExt);
                                  } else {
                                    setFacesType(2);
                                  }
                                }}
                                isInvalid={
                                  facesType === 1 || facesType === 0
                                    ? false
                                    : true
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {context.t(
                                  facesType == 2
                                    ? 'The uploaded file must be a jpg/png image type'
                                    : 'You must attach back document data and the file size must be less than or equal to 2MB'
                                )}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <img
                              src={
                                !empty(inputs.faces_images)
                                  ? isValidUrl(inputs.faces_images)
                                    ? inputs.faces_images
                                    : URL.createObjectURL(inputs.faces_images)
                                  : '/local/landing-page/images/selfie-example.png'
                              }
                              width="100%"
                              alt={context.t('Faces Images')}
                            />
                          </Col>
                          <Col xl="6" className="bg-orange">
                            <p className="important-msj">
                              {context.t(
                                'Note: To upload your selfie photo, you must upload this way:'
                              )}
                            </p>
                            <p className="important-msj">
                              <b>{context.t('Document to use: ')}</b>
                              {inputs.document_type === 'PP'
                                ? context.t('Passport')
                                : inputs.document_type === 'ID'
                                  ? context.t('National identity document')
                                  : ''}
                              <br />
                              <br />
                              {inputs.document_type === 'PP'
                                ? context.t(
                                    "You must write the word “Banexcoin” on a piece of paper, followed by the current date and passport number provided. Next to it, the photo must be taken with your passport (this must have a photo) and the user's face, as shown in the following example:"
                                  )
                                : inputs.document_type === 'ID'
                                  ? context.t(
                                      "You must write the word “Banexcoin” on a piece of paper, followed by the current date and identity document number provided. Next to it, the photo must be taken with your identity document (this must have a photo) and the user's face, as shown in the following example:"
                                    )
                                  : ''}
                              <br />
                              <b>
                                *{' '}
                                {context.t(
                                  'The picture must be sharp, legible and without reflections, in addition to the user’s face, it must be fully visualized and the user must not have any accessories, such as glasses, cap or others.'
                                )}
                              </b>
                            </p>
                          </Col>
                        </React.Fragment>
                      ) : (
                        <Col xl="6">
                          <p>{context.t('Faces Images')}:</p>
                          <img
                            src={
                              !empty(basicLevel.facesImages)
                                ? isValidUrl(basicLevel.facesImages)
                                  ? basicLevel.facesImages
                                  : URL.createObjectURL(basicLevel.facesImages)
                                : '/local/landing-page/images/no-image-box.png'
                            }
                            width="60px"
                            alt={context.t('Faces Images')}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="form-group">
                          <Form.Text className="text-muted">
                            **{' '}
                            {context.t(
                              'Files size must be less than or equal to 2MB'
                            )}
                          </Form.Text>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Button
                          variant="warning"
                          type="button"
                          onClick={() => setKey(2)}
                          style={{ width: '40%' }}>
                          {context.t('Back')}
                        </Button>
                      </Col>
                      <Col xl="6">
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ width: '40%' }}>
                          {context.t('Send')}
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Form>
            )}
          </div>
        )}
      </React.Fragment>
    );
  } catch (e) {
    return <div>Error:</div>;
  }
};

KYC_IMFormStepComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(KYC_IMFormStepComponent);
