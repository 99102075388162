import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebook,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import LogoBanex from '../images/logo-banexcoin-white.svg';

const FooterB = (props, context) => {
  return (
    <React.Fragment>
      <div className="row row-contact element-content footer-block">
        <div className="col-flex footer-menus">
          <ul className="element-content-child">
            <li className="first lang" key="foot_about_us">
              {context.t('Us')}
            </li>
            {/* <li><a href="/acerca.html" className="lang" key="foot_about_us">{context.t('Us')}</a></li> */}
            <li>
              <a href="/fees-commissions" className="lang" key="foot_fees">
                {context.t('Fees & Commissions')}
              </a>
            </li>
            {/* <li><a href="/monedas.html" className="lang" key="foot_currencies">MONEDAS</a></li> */}
            {/* <li><a href="/niveles-clientes.html" className="lang" key="foot_levels">NIVELES DE CLIENTES</a></li> */}
          </ul>
          <ul className="element-content-child">
            <li className="first lang" key="foot_support">
              {context.t('Support')}
            </li>
            {/* <li><a href="/seguridad.html" className="lang" key="foot_security">SEGURIDAD</a></li> */}
            <li>
              <a
                href="https://soporte.banexcoin.com"
                className="lang"
                key="foot_support_center">
                {context.t('Support Center')}
              </a>
            </li>
          </ul>
          <ul className="element-content-child">
            <li className="first lang" key="foot_policies">
              POLITICAS
            </li>
            <li>
              <a href="/kyc-aml-policy" className="lang" key="foot_kyc">
                {context.t('KYC Policies')}
              </a>
            </li>
            <li>
              <a href="/kyc-aml-policy" className="lang" key="foot_aml">
                {context.t('AML Policies')}
              </a>
            </li>
          </ul>
          <p className="right-side">
            <a
              href="https://www.facebook.com/Banexcoin-SA-1966167210356313/"
              className="socialy">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://twitter.com/banexcoin" className="socialy">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.instagram.com/banexcoin/" className="socialy">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </p>
        </div>
      </div>
      <footer
        className="elementor-element elementor-element-d13c04c elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
        data-id="d13c04c"
        data-element_type="section">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-4f53145 elementor-column elementor-col-33 elementor-top-column"
              data-id="4f53145"
              data-element_type="column">
              <div className="elementor-column-wrap  elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-cefeeea elementor-widget elementor-widget-image"
                    data-id="cefeeea"
                    data-element_type="widget"
                    data-widget_type="image.default">
                    <div className="elementor-widget-container">
                      <div className="elementor-image logoimg">
                        <img
                          width={1}
                          height={1}
                          src="/local/logos/logo-banexcoin-white.svg"
                          className="attachment-large size-large"
                          alt="Banexcoin Logo Blanco"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-4839c19 elementor-column elementor-col-33 elementor-top-column"
              data-id="4839c19"
              data-element_type="column">
              <div className="elementor-column-wrap  elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-848a0e0 elementor-widget elementor-widget-heading"
                    data-id="848a0e0"
                    data-element_type="widget"
                    data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                      <h3
                        className="elementor-heading-title elementor-size-default lang"
                        key="copyright_banexcoin">
                        Copyright © 2019 Banexcoin.{' '}
                        {context.t('All rights reserved')}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-d5cc8a3 elementor-widget elementor-widget-heading"
                    data-id="d5cc8a3"
                    data-element_type="widget"
                    data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                      <h3 className="elementor-heading-title elementor-size-default">
                        <span className="lang" key="use_allow_terms">
                          {context.t(
                            'By using this website you are accepting the'
                          )}{' '}
                          <a href="/terms-conditions">
                            {context.t('Terms and Conditions')}
                          </a>
                          {context.t(', ')}
                          <a href="/kyc-aml-policy">
                            {context.t('Privacy and Cookies Policies')}
                          </a>
                        </span>
                      </h3>
                      <h3 className="elementor-heading-title elementor-size-default">
                        <span className="lang" key="use_allow_terms">
                          {context.t('For more information go to ')}
                          <a
                            href="https://soporte.banexcoin.com"
                            target="_blank">
                            {context.t('Support Center')}
                          </a>
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-673e146 elementor-column elementor-col-33 elementor-top-column"
              data-id="673e146"
              data-element_type="column">
              <div className="elementor-column-wrap  elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-378c910 elementor-widget elementor-widget-heading"
                    data-id="378c910"
                    data-element_type="widget"
                    data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                      <h3 className="elementor-heading-title elementor-size-default m4l">
                        <a
                          href="https://manya.pe"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        <a
                          href="https://manya.pe/"
                          target="_blank"
                          className="made-by"
                          rel="noopener noreferrer">
                          {context.t('Made with')}{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 36 36"
                            style={{ enableBackground: 'new 0 0 36 36' }}
                            xmlSpace="preserve">
                            <path
                              className="st0"
                              d="M35.9,11.8C35.9,6.4,31.5,2,26,2c-3.3,0-6.2,1.6-8,4.1C16.2,3.6,13.3,2,10,2c-5.4,0-9.9,4.4-9.9,9.9  c0,0.8,0.1,1.5,0.3,2.2C1.8,22.6,11.2,31.6,18,34c6.8-2.5,16.2-11.4,17.6-20C35.8,13.4,35.9,12.6,35.9,11.8z"
                            />
                          </svg>{' '}
                          {context.t('for Manya.pe')}
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
FooterB.contextTypes = {
  t: PropTypes.func.isRequired
};
export default FooterB;
