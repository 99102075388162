import  { useEffect } from 'react'
export const useFreshChat = () => {
  const handleIsVisibleFreshChat = () => {
    if(!window.fcWidget) return
    if(window.innerWidth < 600){
        window.fcWidget.hide()
      }
    if(window.innerWidth > 600){
      window.fcWidget.show()
    }
  }

  useEffect(() => {
    handleIsVisibleFreshChat()
    window.addEventListener('resize', () => {
      handleIsVisibleFreshChat()
    });
    return () => {
      window.removeEventListener('resize', () => {
        handleIsVisibleFreshChat()
      });
    }
  },[window.fcWidget])
  return {}
}