//import moment from 'moment';
import apex from 'apex-web/lib/apex';
import HandlerBackend from '../../../../helpers/HandlerBackend';
import empty from 'is-empty';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';

export const getFiatAccounts = (currencyCode, userId) => {
  let path = `/api/withdrawal-fiat-accounts/?currencyCode=${currencyCode}`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

const getInstrumentByProduct = ProductId => {
  let InstrumentId = null;
  if (ProductId === 1) {
    InstrumentId = 5;
  }
  return InstrumentId;
};

export const submitFormData = async data => {
  let InstrumentId = getInstrumentByProduct(data.ProductId);
  let prices = {};

  if (!empty(InstrumentId)) {
    prices = await apex.connection.GetLevel1({
      OMSId: 1,
      InstrumentId: InstrumentId
    });
  }

  let payload = {
    OMSId: 1,
    AccountId: data.AccountId,
    ProductId: data.ProductId,
    Amount: data.amount,
    TemplateType: 'Standard',
    TemplateForm:
      '{"Amount":"' +
      data.amount +
      '","FullName":"' +
      data.full_name +
      '","Language":"' +
      ' ' +
      '","BankAddress":"' +
      data.bank_country_source +
      '","Comment":"' +
      data.comments +
      '","BankAccountNumber":"' +
      data.account_source +
      '","BankAccountName":"' +
      data.bank_name_source +
      '","SwiftCode":"' +
      data.bank_swift +
      '","Prices":"' +
      JSON.stringify(prices) +
      '","BankCity":"' +
      data.bank_city +
      '"}'
  };
  console.log('payload:', payload);
  const result = await apex.connection.CreateWithdrawTicket(payload);
  if (result.result) {
    var dat = {
      user_id: data.banexcoin_user_id,
      user_name: data.banexcoin_user_name,
      amount: data.amount,
      currency: data.assetName
    };
    // let resultNot = await sendNotification(
    //   data.banexcoin_user_id,
    //   dat,
    //   data.AccountId
    // );
    // console.log('resultNot::::', resultNot);
  }

  return result;
};

export const sendNotification = async (userId, data, UserAccountId) => {
  console.log(data);
  let headers = {
    // 'Content-Type': 'multipart/form-data'
  };
  let path = '/api/notifications';

  let model = {
    notification_type: 'WITHDRAW_REQUEST',
    user_id: data.user_id,
    account_id: UserAccountId,
    user_name: data.user_name,
    amount_number: data.amount,
    currency_symbol: data.currency,
    payment_method: 'Efectivo',
    bank_city: data.bank_city
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const getWithdrawPayload = async (data, allfiatAccounts) => {
  let accountData = allfiatAccounts.find(afa => afa.id === data.account_source);
  let ticketBankData = {};
  if (!empty(accountData)) {
    if (accountData.transferType === 'NATIONAL') {
      ticketBankData = {
        ...ticketBankData,
        accountTransferType: accountData.transferType,
        accountSource: accountData.accountNumber,
        accountType: accountData.accountType,
        accountSourceCci: accountData.accountNumberCci,
        bankNameSource: accountData.beneficiaryBankname,
        bankCountrySource: accountData.countryName,
        bankCity: !empty(accountData.city)
          ? accountData.city
          : accountData.bankCity
      };
    } else if (accountData.transferType === 'DOMESTIC_TRANSFER_US') {
      ticketBankData = {
        ...ticketBankData,
        accountTransferType: accountData.transferType,
        accountSource: accountData.accountNumber,
        bankAddressSource: accountData.bankAddress,
        abaRoutingNumberSource: accountData.abaRoutingNumber,
        bankNameSource: accountData.beneficiaryBankname,
        bankCountrySource: accountData.countryName,
        beneficiaryName: accountData.fullName,
        beneficiaryAddress: accountData.beneficiaryAddress,
        beneficiaryCountryName: accountData.beneficiaryCountryName,
        beneficiaryCity: accountData.beneficiaryCity,
        beneficiaryState: accountData.beneficiaryState,
        beneficiaryZip: accountData.beneficiaryZip,
        beneficiaryReference: accountData.beneficiaryReference
      };
    } else if (accountData.transferType === 'DOMESTIC_TRANSFER_US_WITH_BANK') {
      ticketBankData = {
        ...ticketBankData,
        accountTransferType: accountData.transferType,
        accountSource: accountData.accountNumber,
        bankAddressSource: accountData.bankAddress,
        bankNameSource: accountData.beneficiaryBankname,
        abaRoutingNumberSource: accountData.abaRoutingNumber,
        swiftBicCode: accountData.swiftBicCode,
        bankCountrySource: accountData.countryName,

        beneficiaryName: accountData.fullName,
        beneficiaryAddress: accountData.beneficiaryAddress,
        beneficiaryCountryName: accountData.beneficiaryCountryName,
        beneficiaryCity: accountData.beneficiaryCity,
        beneficiaryZip: accountData.beneficiaryZip,

        intermediaryBankCountryName: accountData.intermediaryBankCountryName,
        intermediaryBankname: accountData.intermediaryBankname,
        intermediaryBankAddress: accountData.intermediaryBankAddress,
        intermediaryBankCity: accountData.intermediaryBankCity,
        intermediaryBankState: accountData.intermediaryBankState,
        intermediaryBankZip: accountData.intermediaryBankZip,
        intermediarySwiftBicCode: accountData.intermediarySwiftBicCode,
        intermediaryAbaRoutingNumber: accountData.intermediaryAbaRoutingNumber,
        intermediaryReference: accountData.intermediaryReference
      };
    } else if (accountData.transferType === 'INTERNATIONAL') {
      ticketBankData = {
        ...ticketBankData,
        accountTransferType: accountData.transferType,
        bankCountrySource: accountData.countryName,
        bankNameSource: accountData.beneficiaryBankname,
        accountSource: accountData.accountNumber,
        bankAddressSource: accountData.bankAddress,
        swiftBicCode: accountData.swiftBicCode,

        beneficiaryName: accountData.fullName,
        beneficiaryAddress: accountData.beneficiaryAddress,
        beneficiaryCountryName: accountData.beneficiaryCountryName,
        beneficiaryCity: accountData.beneficiaryCity,
        beneficiaryZip: accountData.beneficiaryZip,

        intermediaryBankCountryName: accountData.intermediaryBankCountryName,
        intermediaryBankname: accountData.intermediaryBankname,
        intermediaryBankAddress: accountData.intermediaryBankAddress,
        intermediaryBankCity: accountData.intermediaryBankCity,
        intermediaryBankState: accountData.intermediaryBankState,
        intermediaryBankZip: accountData.intermediaryBankZip,
        intermediarySwiftBicCode: accountData.intermediarySwiftBicCode,
        intermediaryAbaRoutingNumber: accountData.intermediaryAbaRoutingNumber,
        intermediaryReference: accountData.intermediaryReference
      };
    } else if (
      accountData.transferType === 'INTERNATIONAL_WITHOUT_INTERMEDIARY_BANK'
    ) {
      ticketBankData = {
        ...ticketBankData,
        accountTransferType: accountData.transferType,
        bankCountrySource: accountData.countryName,
        bankNameSource: accountData.beneficiaryBankname,
        accountSource: accountData.accountNumber,
        bankAddressSource: accountData.bankAddress,
        swiftBicCode: accountData.swiftBicCode,

        beneficiaryName: accountData.fullName,
        beneficiaryAddress: accountData.beneficiaryAddress,
        beneficiaryCountryName: accountData.beneficiaryCountryName,
        beneficiaryCity: accountData.beneficiaryCity,
        beneficiaryZip: accountData.beneficiaryZip
      };
    }
    
    ticketBankData.diferenteMoneda = "NO";
    if(data.assetName !== accountData.currencyCode) {
      ticketBankData.diferenteMoneda = "YES";
    }
  }

  let InstrumentId = getInstrumentByProduct(data.ProductId);
  let prices = {};

  if (!empty(InstrumentId)) {
    prices = await apex.connection.GetLevel1({
      OMSId: 1,
      InstrumentId: InstrumentId
    });
  }

  let templateData = {
    Amount: data.amount,
    FullName: data.full_name,
    Language: ' ',
    Comment: data.comments,
    ...ticketBankData,
    TermsCondition: String(data.confirmWithdraw),
    TermsConditionInterbank: String(data.confirmOpInterbank)
  };

  let payload = {
    OMSId: 1,
    AccountId: data.AccountId,
    ProductId: data.ProductId,
    Amount: data.amount,
    TemplateType: 'Standard',
    TemplateForm: JSON.stringify(templateData)
  };

  return payload;
};

export const callAPI = (endpoint, apiPayload) => async (dispatch, getState) => {
  const state = getState();
  const { validate2FARequiredEndpoints } = state.api;
  if (validate2FARequiredEndpoints[endpoint.toLowerCase()]) {
    const apiCall = async data =>
      await apex.connection.RPCPromise(endpoint, data);

    return new Promise((resolve, reject) => {
      dispatch(
        openModal(MODAL_TYPES.REQUIRE_2FA, {
          apiPayload: JSON.stringify(apiPayload),
          apiCall,
          resolve,
          reject
        })
      );
    });
  }

  // Normal API call.
  const res = await apex.connection.RPCPromise(endpoint, apiPayload);
  return JSON.parse(res.o);
};
