import HandlerBackend from "../../../../helpers/HandlerBackend";

class SignupNaturalCoinkController{
    static getDataByCoinkToken = async ({ coinkToken }) => {
        try {
            const response = await HandlerBackend.sendRequest({
                method: 'GET',
                path:`/api/v2/coink/signup/${coinkToken}`
            })
            if(response.status === 200) return response.data
            return false
        } catch (error) {
            return error.response.data
        }
    }

    static coinkSignupNatural = async ({data, token}) => {
        try {
            const response = await HandlerBackend.sendRequest({
                method: 'POST',
                path:`/api/v2/coink/signup/finish`,
                data:{
                    ...data,
                    token
                }
            })
            if(response.status === 200 && response.data.message === "success") return true 
            return false
        } catch (error) {
            return false
        }
    }
}

export default SignupNaturalCoinkController;