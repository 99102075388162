import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../DashboardPage.css';
import DashboardOverviewV2 from '../DashboardOverviewV2';
import RecentActivityTableContainer from '../../components/RecentActivity/RecentActivityContainer';
import Wallets from './Wallets';
import isEmpty from 'is-empty';
import DepositWithdrawals from './DepositWithdrawals';
import { callAPI } from '../utils';

const Overview = (props, context) => {

    const { selectedAccountId, accounts } = props;

    const [tabSelected, setTabSelected] = useState('Market Price')
    const [tabElement, setTabElement] = useState({
        'Market Price': <DashboardOverviewV2 />,
    })

    useEffect(() => {
        if (!isEmpty(selectedAccountId) && !isEmpty(accounts)) {
            const currentAccount = accounts.find(account => account.AccountId === selectedAccountId) || {}
            const level = currentAccount.VerificationLevel || 0
            if (![0, 17].includes(level)) {
                setTabElement({
                    'Wallets': <Wallets />,
                    'Recent activities': <RecentActivityTableContainer />,
                    'Market Price': <DashboardOverviewV2 />,
                    'Withdrawals': <DepositWithdrawals />,

                })
                setTabSelected('Wallets')
            }
        }
    }, [selectedAccountId, accounts])

    useEffect(() => {
        if(!isEmpty(props.availableInstruments)){
            props.availableInstruments.forEach(instrument => {
                props.subscribeLevel1({
                    OMSId: 1,
                    Symbol: instrument
                })
            })
        }
        return () => {
            if(!isEmpty(props.availableInstruments)){
                props.availableInstruments.forEach(instrument => {
                    props.unsubscribeLevel1({
                        OMSId: 1,
                        Symbol: instrument
                    })
                })
                props.subscribeLevel1({
                    OMSId: 1,
                    Symbol: props.availableInstruments[0]
                })
            }
        }
    }, [props.availableInstruments])
    
    return (
        <div className="market-overview-container-table-container">
            <div className='market-overview-container-header'>
                {Object.keys(tabElement).map((title, key) => <h2 key={key} onClick={() => setTabSelected(title)} className={tabSelected === title ? 'tab-active' : ''}>{context.t(title)}</h2>)}
            </div>
            <div className='table-container'>
                {Object.keys(tabElement).map((value, key) => <div key={key} className={`overview-data ${tabSelected === value ? 'data-active' : ''}`}>{tabElement[value]}</div>)}
            </div>
        </div>
    )
}

const mapStateToProps = ({
    user: { selectedAccountId, accounts },
    instrumentsByAccountId
}) => {
    const availableInstruments = instrumentsByAccountId[0] && instrumentsByAccountId[0].hasOwnProperty('instrumentsByAccountId') ? instrumentsByAccountId[0]['instrumentsByAccountId']['buysell'] : []
    return { selectedAccountId, accounts, availableInstruments };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
	return {
	  subscribeLevel1: payload => {
        return dispatch(callAPI('SubscribeLevel1', payload)).then(res => {
            return res;
        });
      },
      unsubscribeLevel1: payload => {
        return dispatch(callAPI('UnsubscribeLevel1', payload)).then(res => {
          return res;
        });
      },
	};
};

Overview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
