import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import NavBarBeta from './NavBarBeta';
import LandingCalculator from '../LandingCalculator';
import Ticket from './TicketBeta';
import { Link } from 'react-router-dom';

const FirstSection = (props, context) => {
  const { instruments, level1 } = props;
  const [subStatus, setDubStatus] = useState(-1);
  return (
    <section className={'page-section bnx-hero theme-dark'}>
      <div className={'header_top' + (subStatus > -1 ? ' theme-light' : '')}>
        <div
          className={
            'header_top-wrapper container-hero d-xl-flex align-items-center justify-content-between'
          }>
          <div className="logo-block d-flex align-items-center flex-nowrap">
            <a href="/" className="link logo d-block py-xl-2 py-0 clearfix">
              <img
                src={
                  '/local/logos/logo-banexcoin' +
                  (subStatus > -1 ? '' : '-white-middleorange') +
                  '.svg'
                }
                className="logo-beta image"
                alt="Banexcoin Logo"
              />
            </a>
          </div>
          <NavBarBeta subStatus={subStatus} setDubStatus={setDubStatus} />
          <div
            id="block-home-pages-header-cta"
            className="block-block-content7d46cee3-8366-4373-ae4d-970a9ff85ba6">
            <div className="content">
              <div className="ctas">
                <div className="ctas__wrapper d-flex align-items-center flex-wrap m-n1">
                  <Link
                    to="/login/"
                    className="cta cta--xsmall cta--secondary m-1">
                    <span className="cta__text ">{context.t('Login')}</span>
                  </Link>
                  <Link
                    to="/signup/"
                    className="cta cta--xsmall cta--secondary m-1 primary-beta">
                    <span className="cta__text ">{context.t('Sign up')}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header__bottom">
        <section className="page-section page-section--hero">
          <div className="page-section__container container-hero px-6 px-sm-4 py-8 py-lg-18">
            <div className="content-layout content-layout--equal content-layout-- row">
              <div className="content-layout__column content-layout__column--left col-md-6 d-flex flex-column my-auto">
                <div className="content-layout__column-container h-100">
                  <div className="heading-body">
                    <div className="title">
                      <div className="content__container">
                        <p className="content__container__text">
                          {context.t('With')} <b>Banexcoin</b>
                        </p>
                        <ul className="content__container__list">
                          <li className="content__container__list__item">
                            {context.t('you buy')}
                          </li>
                          <li className="content__container__list__item">
                            {context.t('you sell')}
                          </li>
                          <li className="content__container__list__item">
                            {context.t('you invest')}
                          </li>
                          <li className="content__container__list__item">
                            {context.t('you grow')}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="paragraph-text">
                      <p dir="ltr">
                        {context.t('Buy and Sell bitcoins quickly and safely,')}
                        <br />
                        {context.t(
                          'soon you can also change your Soles and Dollars at the best price'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-layout__column content-layout__column--right col-md-6 d-flex flex-column pt-8 pt-md-0 my-auto">
                <LandingCalculator />
              </div>
            </div>
          </div>
          <div className="container-hero container-ticket-beta">
            <Ticket instruments={instruments} level1={level1} />
          </div>
        </section>
      </div>
    </section>
  );
};

FirstSection.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  }
}) => {
  return { instruments, level1 };
};

export default connect(mapStateToProps)(FirstSection);
