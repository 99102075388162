import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withAuthentication from "apex-web/lib/hocs/withAuthentication";
import { Modal, Table } from "react-bootstrap";
import { FormButtonV2 } from "../../../components/common/BnxFormsComponents";
import { Spinner } from "../../Spinner/Spinner";
import SuccessIcon from "../../../images/coink-imgs/successIconV2.svg";
import ErrorIcon from "../../../images/coink-imgs/errorIconV2.svg";
import PendingIcon from "../../../images/coink-imgs/pendingIconV2.svg";
import "./modal.css";
import { FormatDecimalCoinkWithOutSimbols, FormatDecimalCoinkWithSimbols, amountWithCurrency, formatCopCurrency } from "../../../pages/utils";
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import copyToClipboard from "apex-web/lib/helpers/clipboardHelper";
import APIcon from "apex-web/lib/components/common/APIcon";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import BigNumber from "bignumber.js";
const labels = {
  FullyProcessed: "Completed",
  UserCancelled: "Cancelled",
  Rejected: "Rejected",
};

const RecentActivitiesDetailsModal = (props, context) => {
  const {
    show,
    handleClose,
    data = {},
    loading,
    isDepositOrWithdraw,
    instruments,
    activityType,
    products,
    isCoink,
    side,
    showSnack
  } = props;
  const currentInstrument =
  instruments.find((item) => item.InstrumentId === data.InstrumentId) || {};

  const [currentProduct, setCurrentProduct] = React.useState({});
  const [sellCurrentProduct, setSellCurrentProduct] = React.useState({});
  const [currency, setCurrency] = React.useState("");

  useEffect(() => {
    if(isEmpty(data)) return
    if(!isDepositOrWithdraw && isEmpty(currentInstrument)) return
    let product = products.find(
      (item) =>
        item.ProductFullName === data.AssetName ||
        item.Product === currentInstrument.Product1Symbol
    ) || {};
    let sellProduct = products.find(
      (item) =>
        item.ProductFullName === data.AssetName ||
        item.Product === currentInstrument.Product2Symbol
    ) || {};
    if(!isDepositOrWithdraw){
      const quantityIncrement = String(new BigNumber(currentInstrument.QuantityIncrement).toFixed()).split(".")[1].length
      const priceIncrement = String(new BigNumber(currentInstrument.PriceIncrement).toFixed()).split(".")[1].length
      product.DecimalPlaces = quantityIncrement
      sellProduct.DecimalPlaces = priceIncrement
    }
    setCurrentProduct(product)
    const currency = isDepositOrWithdraw
    ? product.Product
    : (
      instruments.find((item) => item.InstrumentId === data.InstrumentId) ||
      {}
      ).Product1Symbol;
    setCurrency(currency)
    setSellCurrentProduct(sellProduct)
  },[data, currentInstrument, instruments, products])
  
  const imageType = {
    success: SuccessIcon,
    pending: PendingIcon,
    pending2fa: PendingIcon,
    rejected: ErrorIcon,
    fullyprocessed: SuccessIcon,
    new: PendingIcon,
    usercancelled: ErrorIcon,
  };
  const styleType = data.Status ? data.Status.toUpperCase() : "";
  const img = data.Status ? imageType[data.Status.toLowerCase()] : SuccessIcon;

  const formattedAmount = (value) => {
    if (isCoink) return value
    else return Intl.NumberFormat("es-PE", {
      currency: "PEN",
      minimumFractionDigits: currentProduct.DecimalPlaces,
      maximumFractionDigits: currentProduct.DecimalPlaces,
    }).format(value)
  
  };

  const TemplateFormRows = () => {
    if (isCoink || !data.TemplateForm) return null;
    let templateFormData = {};
    try {
      templateFormData = JSON.parse(data.TemplateForm);
    } catch (error) {}
    if (templateFormData.hasOwnProperty("ExternalAddress")) {
      return <>
        <tr>
          <td className="container-help-text">{context.t("External Address")}</td>
          <td className={`text-right`}><span>{templateFormData.ExternalAddress}</span>{" "} <span onClick={() => {
            showSnack(
              context.t(
                'The address has been copied to the clipboard.'
              )
            );
            copyToClipboard(templateFormData.ExternalAddress);
          }}><APIcon name="copy"/></span></td>
        </tr>
        <tr>
          <td className="container-help-text">{context.t("Comment")}</td>
          <td className={`text-right`}>{templateFormData.Comment}</td>
        </tr>
      </>;
    }
    return (
      <>
        <tr>
          <td className="container-help-text">{context.t("Destination Bank")}</td>
          <td className={`text-right`}>{templateFormData.bankNameSource}</td>
        </tr>
        <tr>
          <td className="container-help-text">{context.t("Account Number")}</td>
          <td className={`text-right`}>{templateFormData.accountSource}</td>
        </tr>
      </>
    );
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="buysell-confirm recent-activities-modal"
      backdropClassName="buysell-backdrop"
    >
      <Modal.Header>
        <Modal.Title className={`statusTransaction firstType_${styleType}`}>
          <div className="contentIconStatus">
            <div className={`spaceConIcon secondType_${styleType}`}>
              <img src={img} width={25} />
            </div>
          </div>
          <p style={{ marginTop: "1rem", fontWeight: "normal" }}>
            {context.t(labels[data.Status] || data.Status || "Completed")}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100px",
            }}
          >
            <Spinner color="orange" />
          </div>
        ) : (
          <Table hover className="buysell-details">
            <tbody>
              <tr>
                <td className="container-help-text" style={{textTransform:"capitalize"}}>
                  {context.t(`Operation Detail`)}
                </td>
                <td className={`text-right`}>
                  {context.t(isDepositOrWithdraw ? activityType : side)} {currency}
                </td>
              </tr>
              <tr>
                <td className="container-help-text">{context.t("Date")}</td>
                <td className="text-right">
                  {
                    new Date(
                      isDepositOrWithdraw
                        ? data.CreatedTimestamp
                        : data.TradeTimeMS
                    )
                      .toLocaleString()
                      .split(",")[0]
                  }
                </td>
              </tr>
              <tr>
                <td className="container-help-text">{context.t("Hour")}</td>
                <td className="text-right">
                  {
                    new Date(
                      isDepositOrWithdraw
                        ? data.CreatedTimestamp
                        : data.TradeTimeMS
                    )
                      .toLocaleString()
                      .split(",")[1]
                  }
                </td>
              </tr>
             {
              activityType === "trades" && !isDepositOrWithdraw && (
                <tr>
                    <td className="container-help-text">
                      {context.t(`${side !== "Buy" ? "Sold" : "Purchased"} amount`)}
                    </td>
                    <td className="text-right">
                    {amountWithCurrency({
                      amount:formattedAmount(!isCoink ? Number(data[side === "Sell" ? "Value" : "Quantity"]).toFixed(
                        currentProduct.DecimalPlaces
                      ) : formatCopCurrency(Number(data["Quantity"]), currentProduct.DecimalPlaces).slice(2), side === "Sell"),
                      product: currentProduct,
                    })}
                    </td>
                  </tr>
              )
             }
              <tr>
                <td className="container-help-text">
                  {isDepositOrWithdraw ? context.t("Amount") : context.t(`${side === "Buy" ? "Sold" : "Purchased"} amount`)}
                </td>
                <td className="text-right">
                {amountWithCurrency({
                  amount:formattedAmount(!isCoink ? Number(isDepositOrWithdraw ? (data.Amount) : data[side !== "Sell" ? "Value" : "Quantity"]).toFixed(
                    currentProduct.DecimalPlaces
                  ) :   formatCopCurrency(Number(isDepositOrWithdraw ? (data.Amount) : data["Value"]), sellCurrentProduct.DecimalPlaces).slice(2), side !== "Sell"),
                  product: isDepositOrWithdraw ? currentProduct : sellCurrentProduct,
                })}
                </td>
              </tr>
              <tr>
                <td className="container-help-text">
                  {context.t("Banexcoin Commission")}
                </td>
                <td className="text-right">
                  {amountWithCurrency({
                    amount: formattedAmount(!isCoink ? Number(isDepositOrWithdraw ? data.FeeAmt : data.Fee).toFixed(
                      currentProduct.DecimalPlaces
                    ) : formatCopCurrency(Number(isDepositOrWithdraw ? data.FeeAmt : data.Fee),  side === "Buy" ? currentProduct.DecimalPlaces : sellCurrentProduct.DecimalPlaces).slice(2)),
                    product: isDepositOrWithdraw ? currentProduct : side === "Buy" ? currentProduct : sellCurrentProduct,
                  })}
                </td>
              </tr>
              <tr>
                <td className="container-help-text">
                  {context.t("Total amount to receive")}
                </td>
                <td className="text-right">
                  {amountWithCurrency({
                    amount: formattedAmount(!isCoink ? Number(isDepositOrWithdraw ? (data.Amount - data.FeeAmt) : (data[side === "Sell" ? "Value" : "Quantity"] - data.Fee)).toFixed(
                      currentProduct.DecimalPlaces
                    ) : formatCopCurrency(Number(isDepositOrWithdraw ? (data.Amount - data.FeeAmt) : (data[side === "Sell" ? "Value" : "Quantity"] - data.Fee)), side === "Buy" ? currentProduct.DecimalPlaces : sellCurrentProduct.DecimalPlaces).slice(2)),
                    product: isDepositOrWithdraw ? currentProduct : side === "Buy" ? currentProduct : sellCurrentProduct,
                  })}
                </td>
              </tr>
              <TemplateFormRows />
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <FormButtonV2
          variant={"secondary"}
          className="submit-button product-details-modal"
          onClick={handleClose}
        >
          {context.t("Close")}
        </FormButtonV2>
      </Modal.Footer>
    </Modal>
  );
};

RecentActivitiesDetailsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
const mapDispatchToProps = function(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};


export default withAuthentication(connect(null, mapDispatchToProps)(RecentActivitiesDetailsModal));
