import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import isEmpty from "is-empty";
import { Link } from "react-router-dom";
import "./breadcrumbs.css";

function Breadcrumbs(props, context) {
  const { location } = useHistory();

  const urlToNavigate = (item) => {
    if(["cards", "delivery", "physical-card", "request"].includes(item)) return `/cards`
    return `/cards/${item}`
  }
  if (
    isEmpty(location.pathname) ||
    (!isEmpty(location.pathname) &&
      location.pathname.slice(1).split("/").length < 2) ||
      location.pathname.slice(1).split("/").includes("request")
  )
    return <></>;
  return (
    <div className="breadcrumbs">
      {location.pathname
        .slice(1)
        .split("/")
        .map((item) => {
          const formattedItemValue = item.replaceAll("-", " ");
          if (item === location.pathname.split("/").reverse()[0]) {
            return <p>{context.t(formattedItemValue)}</p>;
          }
          return <><Link to={urlToNavigate(item)}>{context.t(formattedItemValue)}</Link><span> / </span></>
        })}
    </div>
  );
}
Breadcrumbs.contextTypes = {
  t: PropTypes.func.isRequired,
}
export default Breadcrumbs;
