import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  BanexcardPage,
  VerificationStartPage,
  IncrementLevelPage,
  RequestStatusPage,
  SettingsPage,
  RechargePage,
  RemoveCardPage,
  DeliveryPhysicalCardPage,
  MovementsHistoryPage,
  ActivationPage,
  BanexcardPinCreationPage
} from '../pages/Banexcard/pages'
import { Breadcrumbs } from './Banexcard/components';

function BanexcardRoutes({ banexcardInfo, getCardInfo }) {
  return (
    <>
    <Breadcrumbs />
    <Switch>
      <Route exact path='/cards'>
        <BanexcardPage banexcardInfo={banexcardInfo} getCardInfo={getCardInfo}/>
      </Route>
      <Route exact path='/cards/verification-start'>
        <VerificationStartPage />
      </Route>
      <Route exact path='/cards/increment-level'>
        <IncrementLevelPage />
      </Route>
      <Route exact path='/cards/request/:status'>
        <RequestStatusPage />
      </Route>
      <Route path='/cards/recharge'>
        <RechargePage />
      </Route>
      <Route exact path='/cards/settings'>
        <SettingsPage banexcardInfo={banexcardInfo}/>
      </Route>
      <Route path='/cards/settings/remove-card'>
        <RemoveCardPage />
      </Route>
      <Route path='/cards/delivery/physical-card'>
        <DeliveryPhysicalCardPage />
      </Route>
      <Route path='/cards/movements-history'>
        <MovementsHistoryPage />
      </Route>
      <Route path='/cards/physical-card/activation'>
        <ActivationPage />
      </Route>
      <Route path='/cards/physical-card/pin'>
        <BanexcardPinCreationPage />
      </Route>
    </Switch>
    </>
  )
}

export default BanexcardRoutes