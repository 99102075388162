import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import APQRCode from 'apex-web/lib/components/common/APQRCode/APQRCode';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';
import { required } from 'apex-web/lib/helpers/formValidations';
import config from 'apex-web/lib/config';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import CopyIcon from '../../../images/icons-v2/light-copy.svg';

import 'apex-web/lib/components/EnableTwoFactorAuth/Enable2FAModal.css';

const baseClasses = getBEMClasses('enable-2fa-modal');

const Enable2FAForm = function Enable2FAForm(props, context) {
    const GoogleQRCode = props.GoogleQRCode,
        handleSubmit = props.handleSubmit,
        userName = props.userName,
        showSnack = props.showSnack;
    const siteName = config.global.siteName;


    const code = 'otpauth://totp/' + userName + '?secret=' + GoogleQRCode + '&issuer=' + encodeURI(siteName);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return React.createElement(
        'form',
        { onSubmit: handleSubmit },
        React.createElement(
            'div',
            { className: baseClasses() },
            React.createElement(
                'div',
                null,
                React.createElement(
                    'ol',
                    { className: baseClasses('text-container') },
                    React.createElement(
                        'li',
                        { className: baseClasses('text-item') },
                        context.t('Download a Two-Factor Authentication app to your phone such as Authy or Google Authenticator')
                    ),
                    React.createElement(
                        'li',
                        { className: `${baseClasses('text-item')} item-copy-v2` },
                        context.t(windowWidth > 768 ? 'Use the app to scan QR code.' : 'Enter this key in your authentication application.'),
                        windowWidth <= 768 && React.createElement(
                            'div',
                            { className: baseClasses('copy-address-payload qr-code-container') },
                            React.createElement('div', {}, GoogleQRCode),
                            React.createElement('span', {}, '|'),
                            React.createElement('img', {
                                src: CopyIcon,
                                alt: 'copy',
                                onClick: function onClick() {
                                    showSnack({
                                        id: 'GoogleQRCode_copy',
                                        type: 'success',
                                        text: context.t('The key has been copied to the clipboard.')
                                    });
                                    copyToClipboard(GoogleQRCode);
                                }
                            }),
                        )
                    ),
                    React.createElement(
                        'li',
                        { className: `${baseClasses('text-item')} item-copy-v2` },
                        context.t('Type in code from your phone.'),
                        React.createElement(APAutoTabInput, {
                            name: 'code',
                            type: 'input',
                            numberOfInputs: 6,
                            validate: [required]
                        })
                    )
                ),
            ),
            windowWidth > 768 &&
            React.createElement(
                'div',
                { className: baseClasses('qr-code') },
                React.createElement(APQRCode, { value: code })
            )
        )
    );
};

Enable2FAForm.defaultProps = {
    handleSubmit: function handleSubmit() { },
    submitting: false
};

Enable2FAForm.propTypes = {
    handleSubmit: PropTypes.func
};

Enable2FAForm.contextTypes = {
    t: PropTypes.func.isRequired
};

export default Enable2FAForm;