import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import { submitFormData, getCorporateData } from './CorporateLevelPageHooks';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import 'moment/locale/es.js';
//CSS
import './CorporateLevelPage.css';
// import Countries from '../../components/Countries';

const RobotSvg = require('../../images/robot.svg');
const corporateLevelPageClasses = getBEMClasses('corporatelevel-page');

const CorporateLevel = (props, context) => {
  const { selectedAccountId, userInfo, accounts } = props;
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>{context.t('Loading...')}</div>
        </React.Fragment>
      );
    }
    const [corporateData, setCorporateData] = useState({});
    const [charged, setCharged] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(0);
    const [level, setLevel] = useState({});

    useEffect(
      () => {
        async function loadLevel() {
          setLoading(1);

          try {
            let result = await getCorporateData(
              userInfo.UserId,
              selectedAccountId
            );
            if (
              !empty(result) &&
              !empty(result.data) &&
              !empty(result.data.corporatedata)
            ) {
              setCorporateData(result.data.corporatedata[0]);
              console.log('result.data', result);
              if (!empty(result.data.corporatedata[0].id)) {
                setCharged(true);
                setRedirect(true);
                console.log('redirigir....');
                // context.router.history.push('/corporate-level-people');
                // return function cleanup() {
                //   abortController.abort();
                // };
              }
            }
          } catch (error) {
            toast.warn(context.t('Information could not accesible'), {
              position: toast.POSITION.TOP_CENTER
            });
          }

          setLoading(0);
        }

        if (!empty(selectedAccountId)) {
          loadLevel();
        }
      },
      [selectedAccountId]
    );

    useEffect(
      () => {
        if (!empty(level)) {
          inputs = {
            ...inputs,
            corporate_name: level.corporate_name,
            corporate_ruc: level.corporate_ruc,
            corporate_purpose: level.corporate_purpose,
            economic_group: level.economic_group,
            economic_address: level.economic_address,
            phone_number: level.phone_number,
            contact_person: level.contact_person,
            company_country: level.company_country
          };
          setInputs(inputs);
        }
      },
      [level]
    );

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result = await submitFormData(userInfo.UserId, inputs);
        if (result.data.message == 'success') {
          // toast.success(
          //   context.t(
          //     'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n an email with the response to your request.'
          //   ),
          //   { position: toast.POSITION.TOP_CENTER }
          // );
          toast.success(context.t('Success'), {
            position: toast.POSITION.TOP_CENTER
          });
          setCharged(true);
          //context.router.history.push('/corporate-level-people');
        } else {
          toast.error(context.t('There is an error'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};
      /*if (empty(inputs.birthday)) {
        errors.birthday = true;
      } else {
        var m = moment(inputs.birthday, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.birthday = true;
        }
      }*/

      return errors;
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName,
      email: !empty(userInfo.Email) ? userInfo.Email : ''
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    return userInfo.Use2FA ? (
      !redirect ? (
        !charged ? (
          <React.Fragment>
            <Loading loading={loading} />
            <div className={corporateLevelPageClasses()}>
              <h1>{context.t('Raise user level to corporate')}</h1>
              <p>
                {context.t(
                  'To raise the user level you must fill out the form below.'
                )}
              </p>
              <Form
                noValidate
                validated={inputs.validated}
                encType="multipart/form-data"
                onSubmit={onSubmit}>
                <Row>
                  <Col xl="4">
                    <Form.Group controlId="email">
                      <Form.Label>{context.t('Email')}</Form.Label>
                      <p>{inputs.email}</p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4">
                    <Form.Group controlId="corporate_name">
                      <Form.Label>
                        {context.t(
                          'Denomination or Full Legal Name of Company'
                        )}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="corporate_name"
                        type="text"
                        defaultValue={inputs.corporate_name || ''}
                        onChange={onInputChange}
                        placeholder={context.t(
                          'Denomination or Full Legal Name of Company'
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must enter the denomination or Full Legal Name of Company'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="corporate_ruc">
                      <Form.Label>
                        {context.t('Corporate ruc')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="corporate_ruc"
                        defaultValue={inputs.corporate_ruc || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder={context.t('Corporate Ruc')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must put corporate ruc')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="corporate_purpose">
                      <Form.Label>
                        {context.t('Corporate purpose')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="corporate_purpose"
                        defaultValue={inputs.corporate_purpose || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder={context.t('Corporate Purpose')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must put corporate purpose')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <Form.Group controlId="economic_group">
                      <Form.Label>
                        {context.t('Economic group')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="economic_group"
                        defaultValue={inputs.economic_group || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder={context.t('Economic Group')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must enter the economic group')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="6">
                    <Form.Group controlId="economic_address">
                      <Form.Label>
                        {context.t('Economic Address')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="economic_address"
                        defaultValue={inputs.economic_address || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder={context.t('Economic Address')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must enter the economic address')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4">
                    <Form.Group controlId="phone_number">
                      <Form.Label>
                        {context.t('Phone number')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <PhoneInput
                        inputClass={'form-pay-phone-custom'}
                        onChange={value => {
                          onInputChangeByName('phone_number', value);
                        }}
                        value={inputs.phone_number || ''}
                        inputExtraProps={{
                          id: 'phone_number',
                          name: 'phone_number',
                          requiredmessage: context.t(
                            'You must enter the phone number'
                          ),
                          required: true
                        }}
                        // isValid={value => {
                        //   if (this.phone_number) {
                        //     const {
                        //       selectedCountry: { format, dialCode },
                        //       formattedNumber
                        //     } = this.phone_number.state;
                        //     return format.length === formattedNumber.length;
                        //   }
                        // }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must enter the phone number')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="contact_person">
                      <Form.Label>
                        {context.t('Contact person')}{' '}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="contact_person"
                        type="text"
                        defaultValue={inputs.contact_person || ''}
                        onChange={onInputChange}
                        placeholder={context.t('Contact Person')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('You must place the contact person')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group
                      controlId="company_country"
                      title="Compane country">
                      <Form.Label>{context.t('Company Country')}</Form.Label>
                      {/*<Countries/>*/}
                      <Form.Control
                        required
                        name="company_country"
                        defaultValue={inputs.company_country || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder={context.t('Company Country')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4">
                    <Form.Group
                      controlId="constitution_act"
                      title="Constitution act">
                      <Form.Label>
                        {context.t('Constitution Act')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="constitution_act"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach constitution act and the file size must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="incorporation_document">
                      <Form.Label>
                        {context.t('Incorporation document')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="incorporation_document"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach the incorporation document and the size of the files must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="billing_file">
                      <Form.Label>
                        {context.t('Billing file')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="billing_file"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach the billing file and the size of the files must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="4">
                    <Form.Group
                      controlId="financial_statements"
                      title="Finalcial statement">
                      <Form.Label>
                        {context.t('financial statements')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="financial_statements"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach financial statements and the file size must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="affidavit_income_tax">
                      <Form.Label>
                        {context.t('Affidavit income tax')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="affidavit_income_tax"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach the affidavit income tax and the size of the files must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xl="4">
                    <Form.Group controlId="support_origin_funds">
                      <Form.Label>
                        {context.t('Support origin funds')}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        name="support_origin_funds"
                        type="file"
                        onChange={onInputFileChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t(
                          'You must attach the support origin funds and the size of the files must be less than or equal to 2MB'
                        )}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="form-group">
                      <Form.Text className="text-muted">
                        * {context.t('Required fields')}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>

                <Row className="footer-actions">
                  <Col xl="4">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btnsendprimary"
                      style={{ padding: '1em 1.5em' }}>
                      {context.t('Send')}
                    </Button>
                    <Link
                      className="btn btn-secondary"
                      to="/settings/verification-level"
                      style={{ padding: '1em 1.5em' }}>
                      {context.t('Back')}
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </React.Fragment>
        ) : (
          <Redirect to="/corporate-level-people" />
        )
      ) : (
        <Redirect to="/corporate-level-people" />
      )
    ) : (
      <React.Fragment>
        <div className={corporateLevelPageClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.log('error interno', e);
    return <div>Error:</div>;
  }
};

CorporateLevel.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.object.isRequired
  }),
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(CorporateLevel);
