import React, { useState } from 'react'
import PropTypes from "prop-types";
import { CardGradient, OptionsForm } from '../../../components';
import { deliveryTypes } from '../../../constants/deliveryTypes';
import isEmpty from 'is-empty';

const AddressForm = (props, context) => {
    const { handleSteps: { previous, custom }, address = "", isMetropolitanLima, setSelectedRoad } = props
    const [selectedOption , setSelectedOption] = useState("")
    
    const actions = [
        {
            label: context.t("Next"),
            action: () => {
                if(isEmpty(selectedOption)) return
                if(selectedOption === context.t("Use other address")){
                    setSelectedRoad(deliveryTypes.WITHOUT_REGISTER_LOCATION)
                    // TODO: SEND TO DELIVERY ADDRESS FORM
                }else{
                    setSelectedRoad(deliveryTypes.WITH_REGISTER_LOCATION)
                    if(isMetropolitanLima){
                        return custom(2)
                    }
                }
                
            },
            otherAttributes:{
                disabled: isEmpty(selectedOption)
              }
        }
    ];

    const options = [
        {
            title: context.t("Use my registration address"),
            description: address
        },
        {
            title: context.t("Use other address"),
            description: context.t("Enter a new address here where we will send your card")

        }
    ]

    const handleSelectedOption = (title) => setSelectedOption(title)

    return (
        <div className='address-form'>
            <h2 className='title-main'>{context.t("Physical card shipping")}</h2>
            <p className='description-main'>{context.t("Where will we send your Banexcoin Visa Physical card?")}</p>
            <CardGradient
                actions={actions}
                customClassName='address-list'
            >
                <div className='description-text'>{context.t("To have your Banexcard just select the shipping address.")}</div>
                <OptionsForm options={options} handleSelectedOption={handleSelectedOption} selectedOption={selectedOption}/>
                <div className='description-text'>{context.t("Remember that the issuance of the card is FREE, we will only deduct 5 USD from the available balance of your card for the purpose of sending your Physical card according to the coverage areas within Peru.")}</div>
            </CardGradient>
        </div>
    )
}
AddressForm.contextTypes = {
    t: PropTypes.func.isRequired,
};
export default AddressForm