import { LANGUAGES } from "../../../constants/languages";
import { getAccountDeleteReasons, getCommercialCampaigns, postDeleteAccount, patchCommercialCampaigns } from "../Services/UserAccountServices";
import { toast } from 'react-toastify';

class UserAccountController {
   static loadAccountDeleteReasons = async ({
      UserId,
      languageSelected,
      setListDeleteReasons
   }) => {
      const language = LANGUAGES.includes(languageSelected) ? languageSelected : 'en';
      const result = await getAccountDeleteReasons(UserId, language);
      if (result) {
         setListDeleteReasons(result);
      }
   };
   static deleteAccount = async ({
      UserId,
      info,
      setMessageSuccess,
      context
   }) => {
      const data = {
         reasonOption: info.optSelected,
         reasonDetails: info.reasonDetails,
         feedbackDetails: info.commentary
      };
      const result = await postDeleteAccount(UserId, data);
      if (result) {
         setMessageSuccess(true);
      } else {
         toast.warn(context.t('Information could not be saved'), {
            position: toast.POSITION.TOP_CENTER
         });
      }
   }
   static loadCommercialCampaigns = async ({
      UserId,
      setDataActive,
      setinitialData,
      setLoading
   }) => {
      setLoading(true);
      const result = await getCommercialCampaigns(UserId);
      const data = {
         emailProcessingData: result.emailProcessingData,
         smsProcessingData: result.smsProcessingData
      }
      setDataActive(data)
      setinitialData(data)
      setLoading(false)   
   };
   static updateCommercialCampaigns = async ({
      UserId,
      data,
      setinitialData,
      errorModal,
      successModal
   }) => {
      const result = await patchCommercialCampaigns(UserId, data);
      if (result) {
         setTimeout(() => {
               successModal()
         }, 2);
         setinitialData(elements => ({...elements, ...data}));
      } else {
         setTimeout(() => {
            errorModal()
         }, 2);
      };
   }
}

export default UserAccountController;
