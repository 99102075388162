import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import ScheduleReportFormComponent from './ScheduleReportFormComponent';
import { openModal, MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { getReportsPayload } from 'apex-web/lib/helpers/reportsPayloadHelper';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const FORM = 'scheduleReport';
const selector = formValueSelector(FORM);

const mapStateToProps = function mapStateToProps(state) {
  const _state$user = state.user,
    accounts = _state$user.accounts,
    selectedAccountId = _state$user.selectedAccountId;

  const startDate = selector(state, 'startDate');

  return {
    accounts: accounts,
    selectedAccountId: selectedAccountId,
    initialValues: _defineProperty({
      type: 'TradeActivity',
      frequency: 'Daily'
    }, selectedAccountId, true),
    buttonDisabled: !startDate || startDate === '__/__/____'
  };
};

const ScheduleReportForm = reduxForm({
  form: FORM,
  onSubmit: function onSubmit(values, dispatch, ownProps) {
    const payload = getReportsPayload(values, ownProps);
    return dispatch(openModal(MODAL_TYPES.CONFIRM_CYCLICAL_REPORT, { payload: payload }));
  }
})(ScheduleReportFormComponent);

export default connect(mapStateToProps)(ScheduleReportForm);