import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APAutoTabInput from 'apex-web/lib/components/common/APAutoTabInput/APAutoTabInput';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText/APLabelWithText';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import copyToClipboard from 'apex-web/lib/helpers/clipboardHelper';
import { required } from 'apex-web/lib/helpers/formValidations';

import './GetGoogle2FARecoveryKeyModal.css';

const baseClasses = getBEMClasses('recovery-2fa-modal');

let GetGoogle2FARecoveryKeyModal = function GetGoogle2FARecoveryKeyModal(props, context) {
  const handleSubmit = props.handleSubmit,
      isOpen = props.isOpen,
      close = props.close,
      _onCancel = props.onCancel,
      recoveryForm = props.recoveryForm,
      showSnack = props.showSnack;


  return React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: context.t('Recover your Google 2FA code'),
      close: close,
      onCancel: function onCancel() {
        _onCancel();
        close();
      } },
    React.createElement(
      'form',
      { onSubmit: handleSubmit, className: baseClasses() },
      React.createElement(APAutoTabInput, {
        label: context.t('Digit your authentication code here to reveal your setup key'),
        name: 'code',
        type: 'input',
        numberOfInputs: 6,
        validate: [required]
      }),
      recoveryForm.values.recoveryKey && React.createElement(
        'div',
        {
          className: baseClasses('recovery-code-container'),
          onClick: function onClick() {
            showSnack({
              id: 'RECEIVE_RECOVERY_CODE_COPY',
              text: context.t('The recovery code has been copied to the clipboard.')
            });
            copyToClipboard(recoveryForm.values.recoveryKey);
          } },
        React.createElement(APLabelWithText, {
          className: baseClasses('copy-recovery-code'),
          name: 'recoveryKey',
          label: context.t('Copy your recovery code and keep it in a safe place')
        }),
        recoveryForm.values.recoveryKey,
        React.createElement(APIcon, { name: 'copy', customClass: baseClasses('copy-icon') })
      )
    )
  );
};

GetGoogle2FARecoveryKeyModal.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

GetGoogle2FARecoveryKeyModal.propTypes = {
  close: PropTypes.func.isRequired,
  showSnack: PropTypes.func.isRequired,
  recoveryForm: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

GetGoogle2FARecoveryKeyModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default GetGoogle2FARecoveryKeyModal;