import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import empty from "is-empty";
import Spinner from "react-bootstrap/Spinner";
import RecentActivityTableComponent from "../../RecentActivity/RecentActivityTable/RecentActivityTableComponent";
import config from "../../../config";
import {
  formatActivity,
  getColumns,
} from "../../../helpers/recentActivityHelper";
import { getFilteredActivity } from "../../../redux/selectors/recentActivitySelector";
import HandlerBackend from "../../../helpers/HandlerBackend";
import RecentActivitiesDetailsModal from "./RecentActivitiesDetailsModal";
import { callAPI } from "apex-web/lib/redux/actions/apiActions";

var mapStateToProps = function mapStateToProps(state, ownProps) {
  let params = {
    userId: state.user.userInfo.UserId,
    selectedAccountId: state.user.selectedAccountId,
    userInfo: state.user.userInfo,
    instruments: state.instrument ? state.instrument.instruments : [],
    products: state.product ? state.product.products : [],
  };

  var usePagination = config.OrderHistoryData.usePagination;

  var columns = getColumns(
    ownProps.width,
    ownProps.themeModifier,
    ownProps.context
  );

  return {
    usePagination: usePagination,
    columns: columns,
    params: params,
    ownProps: ownProps,
    state: state,
    isCoink: [100,101].includes(state.user.verificationLevelConfigs.Level)
  };
};

class RecentActivityTableComponentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searching: false,
      txResults: [],
      showDetailsModal: false,
      detailsLoader: true,
      activityDetails: {},
      activityType: ""
    };
  }

  async getAllTxIds(userId, accountId) {
    let result = [];
    try {
      let path = "/api/txid";
      let params = {
        accountId: `${accountId}`,
      };
      let configRequest = {
        userId,
        method: "post",
        path,
        data: params,
      };
      let response = await HandlerBackend.sendRequest(configRequest);
      result = !empty(response.data) ? response.data : [];
      this.setState({ txResults: result });
      this.setState({ loading: false });
    } catch (error) {
      throw error;
    }
    return result;
  }

  isDepositOrWithdraw = (type) => type === "withdraw" || type === "deposit";

  getActivityDetailsById = async (method, accountId, txId, type) => {
    try {
      this.setState({ detailsLoader: true });
      const payload = this.isDepositOrWithdraw(type)
        ? {
            OMSId: 1,
            AccountId: accountId,
            RequestCode: txId,
          }
        : {
            OMSId: 1,
            AccountId: accountId,
            ExecutionId: txId,
          };
      const response = await method(payload);
      if(this.isDepositOrWithdraw(type)){
        if(response.hasOwnProperty("RequestCode")){
          return this.setState({ activityDetails: response });
        }
      }else{
        if(response.length > 0){
          return this.setState({ activityDetails: response[0] });
        }
      }
    } catch (error) {
      this.setState({ activityDetails: {} });
    } finally {
      this.setState({ detailsLoader: false });
    }
  };
  render() {
    let { ownProps, state, params, getWithdrawTicket, getTradesHistory, getDepositTicket } =
      this.props;
    let { txResults, searching, loading } = this.state;
    if (
      !empty(params.userId) &&
      !empty(params.selectedAccountId) &&
      !searching
    ) {
      this.getAllTxIds(params.userId, params.selectedAccountId);
      this.setState({ searching: true });
    }

    var filteredActivity = getFilteredActivity(
      state,
      ownProps.filterForSelected
    );
    var content = formatActivity(filteredActivity, ownProps.context, txResults);
    return (
      <div className={"container-lock " + (!loading ? "ready" : "")}>
        <div className="overlay-lock">
          <Spinner animation="grow" />
        </div>
        <RecentActivityTableComponent
          {...this.props}
          content={content}
          onRowClicked={(el) => {
            this.setState({ showDetailsModal: true, activityType: el.Type, side: el.Action.params.Side });
            this.getActivityDetailsById(
             el.Type === "withdraw" ? getWithdrawTicket : el.Type === "deposit" ? getDepositTicket : getTradesHistory,
              params.userInfo.AccountId,
              el.Status.id,
              el.Type
            );
          }}
        />
        <RecentActivitiesDetailsModal
          show={this.state.showDetailsModal}
          loading={this.state.detailsLoader}
          handleClose={() => {
            this.setState({ showDetailsModal: false });
          }}
          data={this.state.activityDetails}
          isDepositOrWithdraw={this.isDepositOrWithdraw(this.state.activityType)}
          instruments={params.instruments}
          activityType={this.state.activityType}
          products={params.products}
          isCoink={this.props.isCoink}
          side={this.state.side}
        />
      </div>
    );
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    getWithdrawTicket: function (payload) {
      return dispatch(callAPI("GetWithdrawTicket", payload)).then((res) => res);
    },
    getTradesHistory: function (payload) {
      return dispatch(callAPI("GetTradesHistory", payload)).then((res) => res);
    },
    getDepositTicket: function (payload) {
      return dispatch(callAPI("GetDepositTicket", payload)).then((res) => res);
    },
  };
};

RecentActivityTableComponentBox.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentActivityTableComponentBox);
