import empty from 'is-empty';
import HandlerBackend from '../helpers/HandlerBackend';
import apex from 'apex-web/lib/apex';
import {
  openModal,
  MODAL_TYPES
} from 'apex-web/lib/redux/actions/modalActions';
import axios from 'axios';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';

export const mant = () => {
  function getMilliSeconds(string) {
    var date;
    if (string) {
      date = new Date(string);
    } else {
      date = new Date();
    }

    var milliseconds = date.getTime();
    return milliseconds;
  }
  function getDatetimeString(timestamp) {
    let string = new Date(timestamp).toISOString();
    return string;
  }
  const start_maintenance = getMilliSeconds('01/20/2022 16:00:00 UTC'); //03/17/2020 01:00:00 UTC
  const end_maintenance = getMilliSeconds('01/20/2022 16:30:00 UTC'); //03/17/2020 05:00:00 UTC

  // ahora
  const ahora = new Date().getTime();

  if (ahora >= start_maintenance && ahora <= end_maintenance) {
    return true;
  } else {
    return false;
  }
};

export const getLabel = prelabel => {
  const LABELS = [
    {
      id: 'company_legal_name',
      name: 'Denomination or Full Legal Name of Company'
    },
    {
      id: 'legal_entity_type',
      name: 'Type of Legal Entity'
    },
    {
      id: 'non_profit',
      name: 'Non-Profit'
    },
    {
      id: 'other_non_profit',
      name: 'Other Non-Profit'
    },
    {
      id: 'other_legal_entity_type',
      name: 'Other Type of Legal Entity'
    },
    {
      id: 'taxpayer_identification_number_type',
      name: 'Type of Taxpayer Identification Number'
    },
    {
      id: 'other_taxpayer_identification_number_type',
      name: 'Other Type of Taxpayer Identification Number'
    },
    {
      id: 'taxpayer_identification_number',
      name: 'Taxpayer Identification Number'
    },
    {
      id: 'corporate_purpose',
      name: 'Corporate Purpose'
    },
    {
      id: 'company_address',
      name: 'Company Address'
    },
    {
      id: 'city',
      name: 'City'
    },
    {
      id: 'state',
      name: 'State/Department/Province/District'
    },
    {
      id: 'corporate_district',
      name: 'Municipality / District'
    },
    {
      id: 'corporate_building',
      name: 'Number / Building'
    },
    {
      id: 'corporate_apt_number',
      name: 'Apartment Number / Office'
    },
    {
      id: 'zip_code',
      name: 'Zip Code'
    },
    {
      id: 'country_incorporation',
      name: 'Country of incorporation'
    },
    {
      id: 'registration_date',
      name: 'Registration Date (Constitution)'
    },
    {
      id: 'linked_economic_group',
      name: 'Linked economic group (if applicable)'
    },
    {
      id: 'company_phone',
      name: 'Company Phone'
    },
    {
      id: 'full_name',
      name: 'Full name'
    },
    {
      id: 'job_position',
      name: 'Job Position'
    },
    {
      id: 'phone',
      name: 'Phone'
    },
    {
      id: 'company_constitution_act',
      name: 'Company Constitution Act'
    },
    {
      id: 'ruc_file',
      name: 'RUC file'
    },
    {
      id: 'incorporation_document',
      name:
        'Incorporation Document / Validity of Power of Attorney no later than 3 months'
    },
    {
      id: 'receipt_public_service',
      name:
        'Receipt of Public Service (Electricity, Telephone, Water, etc. not more than 3 months)'
    },
    {
      id: 'last_income_tax_return',
      name: 'Last income tax return'
    },
    {
      id: 'affidavit_origins_funds',
      name: 'Affidavit of Origins of funds'
    },
    {
      id: 'NEW_RECORD',
      name: 'New Record'
    },
    {
      id: 'INCORRECT_INFORMATION',
      name: 'Incorrect Information'
    },
    {
      id: 'REVIEW_REQUESTED',
      name: 'Review Requested'
    },
    {
      id: 'PERSON_SHAREHOLDER',
      name: 'Shareholder Person'
    },
    {
      id: 'NO_SHAREHOLDER',
      name: 'No Shareholder'
    },
    {
      id: 'COMPANY_SHAREHOLDER',
      name: 'Shareholder Company'
    },
    {
      id: '0',
      name: 'No'
    },
    {
      id: '1',
      name: 'Yes'
    },
    {
      id: 'linked_economic_group_name',
      name: 'Linked Economic Group Name'
    },
    {
      id: 'linked_economic_group_doc_type',
      name: 'Linked Economic Group Doc Type'
    },
    {
      id: 'linked_economic_group_doc_number',
      name: 'Linked Economic Group Doc Number'
    },
    {
      id: 'linked_economic_group_country',
      name: 'Linked Economic Group Country'
    },
    {
      id: 'linked_economic_group_doc_other_type',
      name: 'Other Linked Economic Group Doc Type'
    }
  ];
  let label = LABELS.find(l => l.id === prelabel);
  if (!empty(label)) {
    return label.name;
  } else {
    return prelabel;
  }
};

export const getPhoneValidationInfo = (userId, level) => {
  const path = `/api/v2/phone/validation/info`;
  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    path
  });
};

export const phoneValidationStepTwo = async (userId, phonecode) => {
  let headers = {};
  let path = '/api/phone/validation/step2';

  let model = {
    phonecode
  };

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const callAPI = (endpoint, apiPayload) => async (dispatch, getState) => {
  const state = getState();
  const { validate2FARequiredEndpoints } = state.api;
  if (validate2FARequiredEndpoints[endpoint.toLowerCase()]) {
    const apiCall = async data =>
      await apex.connection.RPCPromise(endpoint, data);

    return new Promise((resolve, reject) => {
      dispatch(
        openModal(MODAL_TYPES.REQUIRE_2FA, {
          apiPayload: JSON.stringify(apiPayload),
          apiCall,
          resolve,
          reject
        })
      );
    });
  }

  // Normal API call.
  const res = await apex.connection.RPCPromise(endpoint, apiPayload);
  return JSON.parse(res.o);
};

export const cleanPhone = phone => {
  if (!phone) {
    return '';
  }
  let cleanNotNumber = phone.replace(/\D/g, '');
  return `+${cleanNotNumber.replace(/\+/g, '').replace(/ /g, '')}`;
  /*
    Pruebas unitarias
    console.log("prueba 1", cleanPhone("++++++++++99 sd 9  9"))
    console.log("prueba 2", cleanPhone("++++++++++99  9  9"))
    console.log("prueba 2", cleanPhone("99 /--- 9  9"))
    console.log("prueba 2", cleanPhone(null))
    console.log("prueba 2", cleanPhone(""))
  */
};

export const fixNumber = (x, decimalsQty = 2) => {
  if (empty(x)) {
    return '0.0';
  }
  x = x.toFixed(decimalsQty);
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};

export const circlePaymentError = [
  {
    code: 'payment_failed',
    description: 'Payment failed due to an unknown reason'
  },
  {
    code: 'payment_fraud_detected',
    description: 'Card suspected to be used for fraud'
  },
  {
    code: 'payment_denied',
    description: 'Payment denied by provider'
  },
  {
    code: 'payment_not_supported_by_issuer',
    description: 'Issuer bank was unable to process the transaction'
  },
  {
    code: 'payment_not_funded',
    description: 'There were insufficient funds to cover the payment amount'
  },
  {
    code: 'payment_stopped_by_issuer',
    description: 'A stop has been placed by the issuer or customer'
  },
  {
    code: 'payment_canceled',
    description: 'Payment was canceled'
  },
  {
    code: 'payment_returned',
    description: 'Wire or ACH payment was returned'
  },
  {
    code: 'payment_failed_balance_check',
    description:
      'Payment failed the Plaid balance check due to insufficient funds'
  },
  {
    code: 'payment_unprocessable',
    description: 'The provided encrypted data could not be processed'
  },
  {
    code: 'card_failed',
    description:
      'The payment failed due to a problem with the card such as an incorrect card number'
  },
  {
    code: 'card_invalid',
    description: 'The card number was invalid'
  },
  {
    code: 'card_cvv_invalid',
    description: 'Incorrect CVV value provided'
  },
  {
    code: 'card_expired',
    description: 'Card expired'
  },
  {
    code: 'card_limit_violated',
    description: 'The amount or frequency of payments exceeded card limits'
  },
  {
    code: 'card_not_honored',
    description: 'The issuing bank did not approve the payment'
  },
  {
    code: 'card_account_ineligible',
    description: 'The card is not linked to an eligible bank account'
  },
  {
    code: 'card_restricted',
    description:
      'The issuing bank has restricted this card to prevent fraud as a suspicious activity was detected'
  },
  {
    code: 'unauthorized_transaction',
    description:
      'The user has advised the bank that the payment was not authorized'
  },
  {
    code: 'bank_account_ineligible',
    description:
      'The account is not eligible and no other default account was found'
  },
  {
    code: 'bank_transaction_error',
    description: 'The bank reported an error processing the transaction'
  },
  {
    code: 'invalid_account_number',
    description: 'The account number is invalid or missing'
  },
  {
    code: 'invalid_wire_rtn',
    description: 'The wire routing number is invalid'
  },
  {
    code: 'invalid_ach_rtn',
    description: 'The ACH routing number is invalid'
  },
  {
    code: 'PROVIDER_ERROR',
    description: 'Provider error'
  }
];

export const circleCardVerificationError = [
  {
    code: 'verification_failed',
    description: 'Verification failed due to an unknown reason'
  },
  {
    code: 'verification_fraud_detected',
    description: 'Card suspected to be used for fraud'
  },
  {
    code: 'risk_denied',
    description: 'Card denied by Provider Risk Service'
  },
  {
    code: 'verification_not_supported_by_issuer',
    description: 'Issuer bank was unable to process the transaction'
  },
  {
    code: 'verification_stopped_by_issuer',
    description: 'A stop has been placed on the card'
  },
  {
    code: 'card_failed',
    description:
      'Verification failed due to a problem with the card such as the card number does not exist'
  },
  {
    code: 'card_invalid',
    description: 'The card number was invalid'
  },
  {
    code: 'card_address_mismatch',
    description:
      'The billing address provided in the card creation request did not match the one recorded by the issuer bank'
  },
  {
    code: 'card_zip_mismatch',
    description:
      'The postal code provided in the card creation request did not match the one recorded by the issuer bank'
  },
  {
    code: 'card_cvv_invalid',
    description: 'Incorrect CVV value provided'
  },
  {
    code: 'card_expired',
    description: 'Card expired'
  },
  {
    code: 'card_not_honored',
    description: 'The issuing bank did not authorize the card'
  },
  {
    code: 'card_account_ineligible',
    description: 'The card is not linked to an eligible bank account'
  },
  {
    code: 'card_limit_violated',
    description: 'The amount or frequency of payments exceeded card limits'
  },
  {
    code: 'card_cvv_required',
    description: 'The cvv is either incorrect or missing'
  }
];

export const circleACHAccountError = [
  {
    code: 'verification_failed',
    description: 'Verification failed due to an unknown reason'
  },
  {
    code: 'bank_account_authorization_expired',
    description: 'The authorization information expired'
  },
  {
    code: 'bank_account_error',
    description:
      'Account information is unreachable with the provided credentials'
  },
  {
    code: 'bank_account_ineligible',
    description:
      'Unable to find an eligible account with the provided credentials'
  },
  {
    code: 'bank_account_not_found',
    description:
      'Failed to find a bank account with the provided account number'
  },
  {
    code: 'bank_account_unauthorized',
    description: 'Verification failed due to invalid credentials'
  },
  {
    code: 'unsupported_routing_number',
    description: 'The ACH routing number is not supported'
  },
  {
    code: 'risk_denied',
    description: 'Bank account denied by Provider Risk Service'
  }
];

export const circlePayoutError = [
  {
    code: 'insufficient_funds',
    description: 'Exchange insufficient funds'
  },
  {
    code: 'transaction_denied',
    description:
      'The transaction was denied as the fiat account is not verified'
  },
  {
    code: 'transaction_failed',
    description: 'Transaction failed due to unknown reason'
  },
  {
    code: 'transaction_returned',
    description: 'The transaction was returned'
  },
  {
    code: 'bank_transaction_error',
    description: 'The bank reported an error processing the transaction'
  },
  {
    code: 'fiat_account_limit_exceeded',
    description: 'The Fiat account limit exceeded'
  },
  {
    code: 'invalid_bank_account_number',
    description: 'The bank account number is invalid or missing'
  },
  {
    code: 'invalid_ach_rtn',
    description: 'The ach routing number is invalid'
  },
  {
    code: 'invalid_wire_rtn',
    description: 'The wire routing number is invalid'
  }
];

export const circleTransferError = [
  {
    code: 'transfer_failed',
    description: 'The transfer failed due to unknown reasons'
  },
  {
    code: 'transfer_denied',
    description: 'The transfer was denied by Provider Risk Service'
  },
  {
    code: 'blockchain_error',
    description: 'There was an error processing the transfer on-chain'
  },
  {
    code: 'insufficient_funds',
    description: 'There was not enough funding to cover the transfer amount'
  }
];

export const statusCircle = s => {
  let estados = [
    {
      code: 'PROCESSED',
      name: 'primary'
    },
    {
      code: 'PASS',
      name: 'success'
    },
    {
      code: 'PROCESSED_TRADE',
      name: 'success'
    },
    {
      code: 'ERROR_PROCESSING_TRADE',
      name: 'success'
    },
    {
      code: 'PROCESSING_TRADE',
      name: 'success'
    },
    {
      code: 'WORKING_TRADE',
      name: 'success'
    },
    {
      code: 'ERROR_PROCESSING',
      name: 'danger'
    },
    {
      code: 'ERROR',
      name: 'danger'
    },
    {
      code: 'FAIL',
      name: 'danger'
    },
    {
      code: 'PROVIDER_ERROR',
      name: 'danger'
    }
  ];
  let found = estados.find(e => e.code === s);
  return !empty(found) ? found.name : 'secondary';
};

export const getIPInfo = async () => {
  let response = {};
  try {
    let headers = {};
    let data = {};
    let url = 'https://ipapi.co/json/';

    response = await axios({
      url: `${url}`,
      method: 'GET',
      headers,
      data
    });
    if (response.hasOwnProperty('data') && !empty(response.data)) {
      response = response.data;
    }
  } catch (error) {
  }
  return response;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatPercentage(x) {
  return x.toFixed(2) + '%';
}

export var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

export var convertIncrementToIntDecimalPlaces = function convertIncrementToIntDecimalPlaces(
  increment
) {
  if (!increment || increment >= 1) return 0;

  return parseFloat(increment.toExponential().split('-')[1]);
};
export const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const drawAxiosError = error => {
  let errorMessage = 'Internal Error';
  if (error.response) {
    if (
      !empty(error.response.data) &&
      !empty(error.response.data.data) &&
      !empty(error.response.data.data.error_message)
    ) {
      errorMessage = error.response.data.data.error_message;
    }
    if (!empty(error.response.data) && !empty(error.response.data.message)) {
      errorMessage = error.response.data.message;
    }
  } else {
    errorMessage = error.message;
  }
  if (errorMessage.includes('Invalid UUID')) {
    errorMessage = 'Invalid query ID';
  }
  return capitalizeFirstLetter(errorMessage);
};

export const drawAxiosErrorPayload = error => {
  let errorMessage = {
    code: '00000',
    message: 'Internal Error'
  };
  if (!empty(error.response)) {
    if (!empty(error.response.data) && !empty(error.response.data.message)) {
      errorMessage = error.response.data;
    }
  }
  return errorMessage;
};

export const countDecimals = function(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const num2strEUR = n => {
  if (typeof n === 'string' || n instanceof String || !n) {
    n = Number(n || 0);
  }
  let str = n.toFixed(2).replace('.', ',');
  return [...str].reduce((r, c, i, a) => {
    r += c;
    let p = a.length - i;
    if (p % 3 === 1 && p > 4) r += '.';
    return r;
  }, '');
};

export const currencyFormat = num => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const fixNumberWithoutRound = (x, decimalsQty = 2) => {
  if (empty(x)) {
    return 0.0;
  }
  return (
    Math.floor(x * Math.pow(10, decimalsQty)) / Math.pow(10, decimalsQty)
  ).toFixed(decimalsQty);
};

export const fixNumberWithoutRound2 = (x, digits = 2) => {
  var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = x.toString().match(re);
  return m ? parseFloat(m[1]) : x.valueOf();
};

export const formatNumbersWithoutRound = (amount, decimalplaces = 2) => {
  if(isNaN(amount)){
    return 0.0
  }
  const fullAmount = String(amount)
  if(!fullAmount.includes(".")) {
    return amount
  }
  const splitSide = fullAmount.split(".")
  const integerSide = splitSide[0]
  const decimalSide = splitSide[1]
  const newDecimalSide = decimalSide.substring(0, decimalplaces)
  return Number(`${integerSide}.${newDecimalSide}`);
}

export const formatNumberWithDecimals = (balance) => {
  const trimmedBalance = balance.trim();
  const isValidNumber = /^\d{1,3}(,\d{3})*\.\d{2,3}$/.test(trimmedBalance);
  if (!isValidNumber) {
    return balance;
  }
  const [integerNumber, decimalNumber] = trimmedBalance.split('.');
  const integerNumberWithPoints = integerNumber.replace(/,/g, '.');
  const formattedNumber = `${integerNumberWithPoints},${decimalNumber}`;
  return formattedNumber;
};

export const includeProductSymbols = ['COP', 'USD', 'BTC', 'ETH', 'USDC'];

export const formatNumberWithDecimalsWithCOP = (value = "") => {
  const trimmedValue = value.trim();
  const currencyRegex = new RegExp(`^(${includeProductSymbols.join('|')})\\s+(\\d{1,3}(,\\d{3})*\\.\\d{2})$`, 'i');
  const matchesCurrencyPattern = currencyRegex.exec(trimmedValue);

  if (matchesCurrencyPattern) {
    const currency = matchesCurrencyPattern[1];
    const numberPart = matchesCurrencyPattern[2];

    if (currency.toUpperCase() === 'COP') {
      const isValidNumber = /^\d{1,3}(,\d{3})*\.\d{2}$/.test(numberPart);

      if (isValidNumber) {
        const [integerNumber, decimalNumber] = numberPart.split('.');
        const integerNumberWithPoints = integerNumber.replace(/,/g, '.');
        const formattedNumber = `${integerNumberWithPoints},${decimalNumber} $`;
        return formattedNumber;
      }
    } else {
      return value;
    }
  }

  return value;
};

export const excludeProductSymbols = ['COP'];

export const replaceSeparatorsInCoinkAmount = value => String(value).replaceAll(",","_").replaceAll(".",",").replaceAll("_",".")

export const FormatDecimalCoinkWithSimbols = (value, ProductSymbol) => {
  const isCoink = localStorage.getItem("is-co") === "true"
  if(!isCoink) return value
  const result =
  value === "-"
  ? value
  : ProductSymbol && excludeProductSymbols.includes(ProductSymbol)
  ? '$' + " " + formatNumberWithDecimals(value) 
  : replaceSeparatorsInCoinkAmount(value) + (ProductSymbol ? ' ' + ProductSymbol : '');
  return result;
};

export const FormatDecimalCoinkWithOutSimbols = (value, ProductSymbol) => {
  const isCoink = localStorage.getItem("is-co") === "true"
  if(!isCoink) return value
  const result =
  value === "-"
  ? value
  : ProductSymbol && excludeProductSymbols.includes(ProductSymbol)
  ? formatNumberWithDecimals(value)
  : replaceSeparatorsInCoinkAmount(value);
  return result;
};

export const getSwipingConfig = async (userInfo, setState, context) => {
    try {
      const path = `/api/v2/coink/swiping-mode/${userInfo.AccountId}`;
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        path
      });
      if(response.data.message === "success" && !isEmpty(response.data.data)){
        setState(response.data.data.swipingMode)
        return true
      }
    } catch (error) {
      toast.warn(context.t("Could not get swiping configuration."),{
        position: toast.POSITION.TOP_CENTER
      })
      return null;
    }
}
export const postSwipingConfig = async (userInfo, swipingMode, context) => {
    try {
      const path = `/api/v2/coink/swiping-mode/${userInfo.AccountId}`;
      const response = await HandlerBackend.sendRequest({
        userId: userInfo.UserId,
        path,
        method: "POST",
        data: {
          swipingMode
        }
      });
      if(response.data.message === "success"){
        return true
      }
      return false
    } catch (error) {
      return false
    }
}

export const formattedCoinkAmountWithoutDots = (value) => Number(String(value).replaceAll('.','').replaceAll(',','.'))

export const formatCopCurrency = (value, decimalPlaces) => {
  return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: decimalPlaces || 8 }).format(value)
}
export const jsonToQueryString = (json) => {
  return Object.keys(json)
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
    .join('&');
}

export const ticksToDateString = (ticks) => {
  if (isNaN(ticks)) {
      //      0001-01-01T00:00:00.000Z
      return "NANA-NA-NATNA:NA:BA.TMAN";
  }
  const epochTicks = 621355968000000000;
  const ticksPerMillisecond = 10000;
  const maxDateMilliseconds = 8640000000000000;

  // convert the ticks into something javascript understands
  const ticksSinceEpoch = ticks - epochTicks;
  const millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond;

  if (millisecondsSinceEpoch > maxDateMilliseconds) {
      //      +035210-09-17T07:18:31.111Z
      return "+WHOAWH-OA-ISTOO:FA:RA.WAYZ";
  }

  // output the result in something the human understands
  return moment(millisecondsSinceEpoch).format('YYYY-MM-DD HH:mm:ss');
};

const fiatCurrencies = {
  "USD":{
    symbol: "$",
    completeName: "United States Dollar",
  },
  "PEN":{
    symbol: "S/",
    completeName: "Peruvian Sol",
  },
  "COP":{
    symbol: "$",
    completeName: "Colombian Pesos",
  },
}
export const amountWithCurrency = ({amount, product}) => {
  if(isEmpty(product)) return amount
  if(product.ProductType === "NationalCurrency"){
    return `${fiatCurrencies[product.ProductSymbol || product.Product].symbol} ${amount}`
  }
  return `${amount} ${product.ProductSymbol || product.Product}`
}

export const numberWithCommas2 = (value, decimalPlaces) => Number(value).toLocaleString('en-US', { minimumFractionDigits: decimalPlaces || 8, maximumFractionDigits: decimalPlaces || 8 }) 

export const googleAnalitycs = (name) => {
  if (window.location.hostname.includes("banexcoin.com")) {
    window.gtag('event', name);
  }
}


export const currencyFormatter = ({value, currency = "USD"}) => {
  if(isEmpty(value)) return `${fiatCurrencies[currency].symbol || ""} 0.00`
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency,
  }).format(String(value))
}
export const sanitizeValue = (value) => {
  if (isEmpty(value)) return '';
  const specialCases_text = [
    '',
    'null',
    'undefined',
    'NaN',
    null,
    undefined,
    NaN
  ];
  if (specialCases_text.includes(value)) return '';
  return value;
}

export const convertSnakeCaseToCamelCase = (text) => {
  return text.replace(/_([a-z])/g, (_, letter) => {
    return letter.toUpperCase();
  });
}

export const cardNumberMask = (cardNumber) => {
  if(isEmpty(cardNumber)) return ""
  const firstPart = cardNumber.slice(0, 4);
  const lastPart = cardNumber.slice(-4);
  return `${firstPart} **** **** ${lastPart}`;
}