import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEMHelper from 'react-bem-helper';


import './APLabelWithText.css';

const baseClasses = new BEMHelper({

  name: 'ap-label-with-text',
  outputIsString: true
});

const LabelWithText = props => {
  const { label, text, customClass, classModifiers, otherClassName } = props;
  const customClasses = new BEMHelper({
    name: customClass,
    outputIsString: true
  });

  return (
    <div
      className={`${classnames(
        baseClasses(),
        customClasses('lwt-container', classModifiers)
      )} ${otherClassName}`}>
      <label
        className={classnames(
          'ap--label',
          baseClasses('label'),
          customClasses('lwt-label', classModifiers)
        )}>
        {label}
      </label>
      <p
        className={classnames(
          baseClasses('text'),
          customClasses('lwt-text', classModifiers)
        )}>
        {text}
      </p>
    </div>
  );
};

LabelWithText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  customClass: PropTypes.string,
  classModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.bool)
  ]),
  dataTest: PropTypes.string
};

LabelWithText.defaultProps = {
  customClass: 'custom-label-with-text'
};

export default LabelWithText;
