import moment from 'moment';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getLevel = async userId => {
  let path = '/api/level';
  return HandlerBackend.sendRequest({
    userId,
    method: 'get',
    path
  });
};

export const submitFormData = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate-people';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('full_name', data.full_name);
  model.append('country', data.country);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('address_main', data.address_main);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('identification_number', data.identification_number);
  model.append('document_type', data.document_type);
  model.append('is_pep', data.is_pep);
  model.append('is_legal_representative', data.is_legal_representative);
  model.append('address_billing', data.address_billing);
  model.append('proof_residence', data.proof_residence);
  model.append('photo', data.photo);
  model.append('photo_front', data.photo_front);
  model.append('photo_back', data.photo_back);
  model.append('percentage_number', data.percentage_number);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};

export const submitFormDataChildren = async (userId, data) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };
  let path = '/api/corporate-add-corporate-subshareholder';
  let model = new FormData();

  model.append('banexcoin_user_id', data.banexcoin_user_id);
  model.append('banexcoin_user_name', data.banexcoin_user_name);
  model.append('full_name', data.full_name);
  model.append('country', data.country);
  model.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
  model.append('address_main', data.address_main);
  model.append('email', data.email);
  model.append('phone', data.phone);
  model.append('identification_number', data.identification_number);
  model.append('document_type', data.document_type);
  model.append('is_pep', data.is_pep);
  model.append('is_legal_representative', data.is_legal_representative);
  model.append('address_billing', data.address_billing);
  model.append('upload[]', data.proof_residence);
  model.append('upload[]', data.photo);
  model.append('upload[]', data.photo_front);
  model.append('upload[]', data.photo_back);
  model.append('percentage_number', data.percentage_number);
  model.append('parent_corporate_levels_id', data.parent_corporate_levels_id);

  return HandlerBackend.sendRequest({
    userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
