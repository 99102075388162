import React, {useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";
import authenticatorLogo from "../../images/google-authenticator.png"
import "./modal2fa.css";
import closeIcon from '../../images/icons/icon-close.svg'
import VerificationInput from 'react-verification-input';
import { Spinner } from "../Spinner/Spinner";
import isEmpty from "is-empty";

const Modal2fa = (props, context) => {
  const { onClick, show, setShow, handleReturn } = props;
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if(String(code).length !== 6) {
      setError(context.t("Invalid code"));
      return;
    }
    setLoading(true);
    const response = await onClick({ code, setError });
    setLoading(false);
    if(response) handleClose();
  };

  const onChange2FA = e => {
    e.persist();
    if(error.length > 0) setError("");
    setCode(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setLoading(false);
  };

  const canExecuteWithdraw = !loading && !isEmpty(code) && code.length === 6;

  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-bnx-v2 lighting modal-disclaimer modal-2fa"
      >
        <Modal.Header className="modal-2fa-header">
          <img onClick={handleClose} src={closeIcon} style={{filter:"invert(1)"}}/>
          <p>{context.t("Confirm withdraw")}</p>
        </Modal.Header>
        <Modal.Body className="modal-2fa-body">
          <Row>
            <Col xl="12" className="text-center">
              <img src={authenticatorLogo} alt="authenticator logo" />
              <p className="desc">{context.t("Enter here the 6-digit digital token (2FA) provided by the Google Authenticator App.")}</p>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="text-center">
              <VerificationInput
                removeDefaultStyles
                autoFocus={true}
                length={6}
                validChars="/^\d+$/"
                container={{
                  className: 'pt-2 pb-4'
                }}
                characters={{
                  className: 'characters'
                }}
                character={{
                  className: 'character',
                  classNameInactive: 'character--inactive',
                  classNameSelected: 'character--selected'
                }}
                inputField={{
                  onChange: onChange2FA
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="text-center">
              <p className="text-danger">{context.t(error)}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="modal-2fa-withdraw-coink">
        <Button variant="secondary" onClick={!loading ? handleReturn : null} disabled={loading}>
            {context.t("Return")}
          </Button>
          <Button variant="primary" onClick={canExecuteWithdraw ? handleClick : null} disabled={!canExecuteWithdraw}>
            {loading ? <Spinner/> : context.t("Withdraw")}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

Modal2fa.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    user: { userInfo },
  } = state;
  return { userInfo };
};

export default connect(mapStateToProps, null)(Modal2fa);
