import React from "react";
import arrowNext from "../../../../images/icons-v2/ArrowRight.svg";
import isEmpty from "is-empty";
import APSwitch from "../../../../components/common/APSwitch/APSwitch";
import "../../../../components/Switch/switch.css"

function Option(props) {
  const [desplegableIsOpen, setDesplegableIsOpen] = React.useState(false);
  const [height, setHeight] = React.useState(60);
  const {
    title,
    description,
    onClick = () => {},
    icon,
    children,
    type,
    value,
  } = props;
  if (type === "desplegable") {
    return (
      <div
        className="option desplegable"
        style={{ height }}
        onClick={() => {
          if(desplegableIsOpen){
            setHeight(60);
            }else{
              const height = document.getElementsByClassName("option desplegable")[0].scrollHeight
              setHeight(height)
            }
            setDesplegableIsOpen(!desplegableIsOpen);
        }}
      >
        <div className="desplegable-first">
          <div className="desplegable-second">
            <div className="image-container">
              <img src={icon} alt="card icon" />
            </div>
            <div className="title-description">
              <p>{title}</p>
              <p>{description}</p>
            </div>
          </div>
          <img
            src={arrowNext}
            alt=""
            className={`icon ${desplegableIsOpen ? "open" : ""}`}
          />
        </div>
        {children && children}
      </div>
    );
  }

  if (type === "toggle") {
    return (
      <div className="option toggle">
        <div>
          <div className="image-container">
            <img src={icon} alt="card icon" />
          </div>
          <div className="title-description">
            <p>{title}</p>
            <p>{description}</p>
          </div>
        </div>
        <APSwitch
          input={{ value }}
          onClick={onClick}
        />
      </div>
    );
  }
  return (
    <div className="option" onClick={onClick}>
      <div>
        <div className="image-container">
          <img src={icon} alt="card icon" />
        </div>
        <div className="title-description">
          <p>{title}</p>
          <p>{description}</p>
        </div>
      </div>
      <img src={arrowNext} alt="" />
    </div>
  );
}

export default Option;
