import axios from 'axios';
import HandlerBackend from "../../../helpers/HandlerBackend";

const urlApiGlobalServices = process.env.REACT_APP_API_GLOBAL_SERVICES
const urlTokenGlobalServices = process.env.REACT_APP_TOKEN_GLOBAL_SERVICES
const urlApiB89 = process.env.REACT_APP_API_B89

export const getB89ParametersByAccountId = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/parameters/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await HandlerBackend.sendRequest(configRequest);
}
export const getB89TransactionsByAccountId = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/transactions/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await HandlerBackend.sendRequest(configRequest);
}
export const getB89BalanceyAccountId = async (userId, banexcoinAccountId) => {
    const path = `/api/b89/balance/${banexcoinAccountId}`;
    const configRequest = {
        userId,
        method: "GET",
        path
    };
    return await HandlerBackend.sendRequest(configRequest);
}

export const getToken = async () => {
    return await axios({
        url: `${urlApiGlobalServices}/oauth/access-token`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Basic ${urlTokenGlobalServices}`
        },
        data: {
            'grant_type': 'client_credentials'
        }
    });
}

export const getCardInfo = async (cardId, productId, partnerId, accessToken, clientId) => {
    return await axios({
        url: `${urlApiB89}/api/v1/person/card?cardId=${cardId}&productId=${productId}&partnerId=${partnerId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'access_token': accessToken,
            'client_id': clientId
        }
    });
}