import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { parseNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { InnerInput } from "apex-web/lib/components/common/APInput/APInput";
import BigNumber from 'bignumber.js';

const separators = ['.', ','];

class InnerNumberInput extends React.Component { state = {
    displayValue: ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      if (
        this.props.input.value === '' ||
        (Number(this.state.displayValue) !== 0 &&
          (Number(this.props.input.value) === 0 ||
            Number.isNaN(Number(this.props.input.value))) &&
          !this.isSeparator(this.state.displayValue))
      ) {
        this.setState({
          displayValue: ''
        });
      } else if (!this.isSeparator(this.state.displayValue)) {
        this.setState({
          displayValue: new BigNumber(this.props.input.value).toFixed()
        });
      }
    }
  }

  handleChange = e => {
    const { allowNegative, customChange } = this.props;
    let inputValue = e.target.value === '.' ? '0.' : e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    const value = parseNumberToLocale(inputValue, null, !allowNegative);
    const validSeparator = !inputValue.includes('..') && !inputValue.includes(',,');

    if (!Number.isNaN(value) && this.validateDecimalPlaces(inputValue)  && validSeparator) {
      this.setState({ displayValue: inputValue });
      this.props.input.onChange && this.props.input.onChange(value);

      if (customChange) {
        customChange(value);
      }
    }
  };

  handleFocus = e => {};

  validateDecimalPlaces = valueString => {
    const { decimalPlaces } = this.props;
    if (decimalPlaces >= 0) {
      const decimals = valueString.split('.')[1];

      return !decimals || decimals.length <= decimalPlaces;
    }

    return true;
  };

  isSeparator = value => separators.includes(value);

  render() {
    const {
      input,
      allowNegative,
      customChange,
      decimalPlaces,
      type = "number",
      ...passedProps
    } = this.props;
    const {onBlur = () => {}, onFocus = () => {}} = input;
    return (
      <InnerInput
        step="any"
        {...passedProps}
        autoComplete="off"
        type={type}
        input={{
          ...input,
          value: this.state.displayValue,
          onChange: this.handleChange,
          onBlur
        }}
        onClick={() => {
         onFocus();
          this.handleFocus('onClick');
        }}
        onFocus={() => {
         onFocus();
          this.handleFocus('onFocus');
        }}
      />
    );
  }
}

const APNumberInput = props => <Field name={props.name} component={InnerNumberInput} {...props} />

APNumberInput.defaultProps = {
  allowNegative: false
};

APNumberInput.propTypes = {
  allowNegative: PropTypes.bool,
  decimalPlaces: PropTypes.number
};

export default APNumberInput;
