import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PriceChart from "./PriceChartContainer";
import DepthChart from "./DepthChart/DepthChartContainer";
import "./exchangeChart.css";
import OrdersBookContainer from "../OrdersBook/OrdersBookContainer";
import arrowCircle from "../../../images/down-arrow-circle.svg";
import { callAPI } from "apex-web/lib/redux/actions/apiActions";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { selectInstrument } from 'apex-web/lib/redux/actions/instrumentActions';
import RecentTradesContainer from "../RecentTrades/RecentTradesContainer";

const sections = {
  Chart: "Chart",
  OrderBook: "OrderBook",
  RecentTrades: "RecentTrades",
};
const chartTypes = {
  PriceChart: "PriceChart",
  DepthChart: "DepthChart",
};
const ChartContainer = (props, context) => {
  const { subscribeTicker, tickers = {}, level2 = {} } = props;
  const [selectedChartType, setSelectedChartType] = useState(
    chartTypes.PriceChart
  );
  const [loading,setLoading] = useState(true)
  const [selectedSection, setSelectedSection] = useState(sections.Chart);

  const customSelectorSectionClassName = (id) =>
    selectedSection === id ? "selected" : "";
  const customChartTypeClassName = (id) => selectedChartType === id ? "selected" : "";

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth < 785) return
        setSelectedSection(sections.Chart);
    }
    window.addEventListener("resize",handleResize);
    subscribeTicker({OMSId:1,InstrumentId:2,Interval:900,IncludeLastCount:5000})
    return () => {
      window.removeEventListener("resize",handleResize);
    }
  },[])

  useEffect(() => {
    if(loading && !isEmpty(tickers) && !tickers.fetching && !isEmpty(level2) && !level2.fetching){
      setLoading(false)
    }
  },[tickers, level2])

  const sectionComponents = {
    "OrderBook": () => <OrdersBookContainer />,
    "RecentTrades": () => <RecentTradesContainer />,
    "PriceChart": () => <PriceChart />,
    "DepthChart": () => <DepthChart />,
  }


  if(loading) return <></>

  return (
    <div style={{ height: "500px!important" }}>
      <div className="selector-content">
        <div className="section-selector">
          <button
            className={`${customSelectorSectionClassName(
              sections.Chart
            )} selector-mobile`}
            onClick={() => setSelectedSection(sections.Chart)}
          >
            {context.t(sections.Chart)}
          </button>
          <button
            className={`${customSelectorSectionClassName(
              sections.OrderBook
            )} selector-mobile`}
            onClick={() => setSelectedSection(sections.OrderBook)}
          >
            {context.t("Order book")}
          </button>
          <button
            className={`${customSelectorSectionClassName(
              sections.RecentTrades
            )} selector-mobile`}
            onClick={() => setSelectedSection(sections.RecentTrades)}
          >
            {context.t("Recent trades")}
          </button>
        </div>
        <div className="chart-types">
          {selectedSection === sections.Chart && (
            <>
              <button
                className={`${customChartTypeClassName(
                  chartTypes.PriceChart
                )} selector-desktop`}
                onClick={() => setSelectedChartType(chartTypes.PriceChart)}
              >
                {context.t("Price chart")}
              </button>
              <button
                className={`${customChartTypeClassName(
                  chartTypes.DepthChart
                )} selector-desktop`}
                onClick={() => setSelectedChartType(chartTypes.DepthChart)}
              >
                {context.t("Depth chart")}
              </button>
            </>
          )}
          {selectedSection === sections.Chart && (
            <div className="select">
              <div className="selector-mobile selector-chart-type-title">
                <p>{context.t(selectedChartType)}</p>
                <img src={arrowCircle} alt="" width={25} />
              </div>
              <ul className="selector-chart-type">
                <li
                  onClick={() => {
                    setSelectedSection(sections.Chart);
                    setSelectedChartType(chartTypes.PriceChart);
                  }}
                >
                  {context.t("Price chart")}
                </li>
                <li
                  onClick={() => {
                    setSelectedSection(sections.Chart);
                    setSelectedChartType(chartTypes.DepthChart);
                  }}
                >
                  {context.t("Depth chart")}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {sections.Chart !== selectedSection ? 
        (sectionComponents[selectedSection] && sectionComponents[selectedSection]()) : 
        selectedChartType === chartTypes.PriceChart ? <PriceChart /> : <DepthChart />
      }
    </div>
  );
};

ChartContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { tickers, level2} = state
  return {
    tickers,
    level2
  };
};
const mapDispatchToProps = dispatch => {
  return {
    subscribeTicker: payload => {
      return dispatch(callAPI('SubscribeTicker', payload)).then(function (res) {
        return res;
      });
    },
    selectInstrument: instrument => dispatch(selectInstrument(instrument))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartContainer);
