import React, { useState } from 'react';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge
} from 'react-bootstrap';
import { transferTypes, accountTypes, bankLogos } from './accountDefinitions';
import { connect } from 'react-redux';
import {
  IconCheckSecurity,
  IconDelete,
  IconWarningn
} from '../../components/common/BnxIcons';
import { disabledFiatAccount, updateAliasAccount } from '../../components/AccountComponents/AccountHooks';
import { confirmAlert } from 'react-confirm-alert';
import edit from '../../images/icons/edit.svg';
import check from '../../images/icons/check.svg';
import cross from '../../images/icons/cross.svg';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';

const accountV2 = getBEMClasses('v2-accounts');

const AccountRow = (props, context) => {
  let {
    account,
    selectedAccountId,
    UserId,
    setLoading,
    getListAccounts,
    showSnack,
    tets
  } = props;
  let nametransferType = {};
  const [editable, setEditable] = useState(false);
  const [aliasBank, setAliasBank] = useState(account.alias);
  const [ allAccount , setAllAccount] = useState(false);

  const [errors, setErrors] = useState("");

  if (!empty(account.transferType)) {
    nametransferType = transferTypes.find(a => a.type === account.transferType);
  }

  let nameAccountType = {};
  if (!empty(account.accountType)) {
    nameAccountType = accountTypes.find(a => a.type === account.accountType);
  }
  let alias = '';
  if (!empty(account.alias)) {
    let arrAlias = account.alias.split(' ');
    let rest = arrAlias.slice(1);
    alias = `<b>${arrAlias[0]}</b> ${rest.join(' ')}`;
  }
  let bankLogo = '';
  if (!empty(account.beneficiaryBankname)) {
    let found = bankLogos.find(l =>
      account.beneficiaryBankname.includes(l.name)
    );
    bankLogo = !empty(found) ? found.image : '';
  }
  let editpath = '/settings/accounts/edit/pe/';
  if (!empty(nametransferType)) {
    editpath = nametransferType.pathEdit;
  }

  const handleInputAlias = (inputs) => {
    const aliasValue = inputs.target.value.trim();
    const errors = empty(aliasValue) ? { alias: "Campo obligatorio" } : {};
    setErrors(errors);
    setAliasBank(aliasValue);
  };

  const cancelEditAlias = () => {
    setEditable(false);
    setAliasBank(account.alias);
    setErrors("");
  };

  const changeAliasBankAccount = async (bankId) => {
    setLoading(1);
    const payload = { alias: aliasBank };
    try {
      if (payload.alias !== account.alias) {
        const response = await updateAliasAccount(bankId, UserId, selectedAccountId, payload); 
        if (!empty(response) && response.hasOwnProperty("data") && response.data.message === "success") {
          await getListAccounts();
          setTimeout(() => {
            showSnack({
              id: `change_alias_${bankId}`,
              text: context.t("The bank alias was changed successfully."),
              type: "success",
              buttons: [{ text: context.t("Got it"), className: "button_V2 purple" }],
            });
          }, 1);
        }
      } else {
        setTimeout(() => {
          showSnack({
            id: `change_alias_same_${bankId}`,
            text: context.t('The Alias cannot be the same.'),
            type: "warning",
            buttons: [{ text: context.t("Got it"), className: "button_V2 purple" }],
          });
        }, 1);
      }
    } catch (error) {
      const errorMessage = !error
      ? "The bank alias could not be edited, please reload the page. If the problem persists, please contact support."
      : error.response.data.message
      setTimeout(() => {
        showSnack({
          id: `change_alias_error_${bankId}`,
          text: context.t(errorMessage),
          type: "warning",
          buttons: [{ text: context.t("Got it"), className: "button_V2 purple" }],
        });
      }, 1); 
      cancelEditAlias();
    } finally {
      setLoading(0);
    }
  };
  
  const confirmAliasEdit = (id) => {
    showSnack({
      id: `edit_alias_confirm_${id}`,
      text: context.t("Are you sure to edit the alias for this bank account?"),
      type: 'pending',
      buttons: [
        { text: context.t('Cancel'), className: 'button_V2 grey' },
        {
          text: context.t('Yes, edit it'),
          className: 'button_V2 purple',
          loadingText: true,
          onClick: () => changeAliasBankAccount(id),
        },
      ],
    });
  };

  const deleteBankAccount = async (id) => {
    setLoading(1);
    try {
      const disable = await disabledFiatAccount(id, UserId, selectedAccountId);
  
      if (!empty(disable) && disable.hasOwnProperty('data') && disable.data.message === 'success') {
        setTimeout(() => {
          showSnack({
            id: `delete_alias_${id}`,
            text: context.t("The bank account was deleted successfully."),
            type: "success",
            buttons: [{ text: context.t("Got it"), className: "button_V2 purple" }],
          });
        },1);
        window.location.reload(true);
      }
    } catch (error) {
      setTimeout(() => {
        showSnack({
          id: `delete_alias_error_${id}`,
          text: context.t("The bank account could not be deleted, please reload the page. If the problem persists, please contact support."),
          type: "warning",
          buttons: [{ text: context.t("Got it"), className: "button_V2 purple" }]
        });
      }, 1);
    } finally {
      setLoading(0);
    }
  };
  
  const confirmDelete = (id, alias) => {
    showSnack({
      id: 'treatment_data',
      text: `${context.t('Are you sure to delete this bank account with the alias')} ${alias}?`,
      type: 'pending',
      buttons: [
        { text: context.t('Cancel'), className: 'button_V2 grey' },
        {
          text: context.t('Yes, Delete it'),
          className: 'button_V2 purple',
          loadingText: true,
          onClick: () => deleteBankAccount(id),
        },
      ],
    });
  };
  
  return (
    <React.Fragment>
      <Col xl="4">
        <div className={accountV2('item')}>
          <Row>
            <Col xl="7" className={accountV2("account-alias")}>
              <div>
                {!editable ? (
                  <h3
                    className={accountV2("item--title")}
                    dangerouslySetInnerHTML={{
                      __html: context.t("{alias}", {
                        alias: !empty(alias)
                          ? alias
                          : `<i>${context.t("No Alias")}</i>`,
                      }),
                    }}
                  />
                ) : (
                  <>
                    <div className="v2-field nooneline valid form-group">
                      <input
                        className="form-control"
                        type="text"
                        value={aliasBank}
                        onChange={handleInputAlias}
                      />
                    </div>
                    <div className="invalid-feedback-alias">
                      <p>{errors.alias}</p>
                    </div>
                  </>
                )}
              </div>
              {account.status === 1 ? (
                <div className="icons-alias">
                  <button
                    className={errors.alias && "btn-disable-icon"}
                    disabled={errors.alias && true}
                    type={!editable ? "submit" : "button"}
                    onClick={() =>
                      setEditable(
                        !editable ? !editable : confirmAliasEdit(account.id)
                      )
                    }
                  >
                    <img src={!editable ? edit : check} alt="edit image" />
                  </button>
                  {editable && (
                    <button type="button" onClick={() => cancelEditAlias()}>
                      <img src={cross} alt="cross" />
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col xl="5" className="text-right">
              <h3
                className={
                  accountV2('currency-data') +
                  ` text-center mark-regulated reg-${account.availableAccount.toLowerCase()}`
                }>
                {account.availableAccount === 'YES' ? (
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-affirmative`}>
                        <span
                          className="children-tooltip"
                          dangerouslySetInnerHTML={{
                            __html: context.t(
                              'Your account is <b>ready</b> to use in <b>Withdrawal</b> and <b>Deposit</b>'
                            )
                          }}
                        />
                      </Tooltip>
                    }>
                    <IconCheckSecurity />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-affirmative`}>
                        <span
                          className="children-tooltip"
                          dangerouslySetInnerHTML={{
                            __html: context.t(
                              '<b>Disabled account</b>. Your need to update this bank account to use it'
                            )
                          }}
                        />
                      </Tooltip>
                    }>
                    <IconWarningn />
                  </OverlayTrigger>
                )}

                <img
                  src={`/local/product-icons/${account.currencyCode.toLowerCase()}-48px.svg`}
                />
                {account.currencyCode}
              </h3>
            </Col>
          </Row>
          <Row className={accountV2('item--row')}>
            {!empty((account.fullName || '').trim()) && (
              <Col xl={5}>
                <b className={accountV2('item--subtitle')}>
                  {context.t('Account holder')}
                </b>
                <div className={accountV2('item--subtitle-value')}>
                  {account.fullName}
                </div>
              </Col>
            )}
            <Col xl={7}>
              {!empty(nameAccountType) ? (
                <>
                  <b className={accountV2('item--subtitle')}>
                    {context.t('Account Type')}
                  </b>
                  <div className={accountV2('item--subtitle-value')}>
                    {context.t(nameAccountType.name)}
                  </div>
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row className={accountV2('item--row')}>
            {!empty(account.beneficiaryBankname) ? (
              <Col xl={5}>
                <b className={accountV2('item--subtitle')}>
                  {context.t('Bank name')}
                </b>
                <div className={accountV2('item--subtitle-value')}>
                  {!empty(bankLogo) ? (
                    <img src={bankLogo} />
                  ) : (
                    account.beneficiaryBankname
                  )}
                </div>
              </Col>
            ) : (
              ''
            )}
            <Col xl={7}>
              <b className={accountV2('item--subtitle')}>
                {context.t('Country')}
              </b>
              <div className={accountV2('item--subtitle-value')}>
                {account.countryName}
              </div>
            </Col>
          </Row>
          <Row className={accountV2('item--row')}>
            <Col xl={12}>
              <b className={accountV2('item--subtitle')}>
                {context.t('Account Number')}
              </b>
              <div className={accountV2('item--subtitle-value')}>
                {account.accountNumber}
              </div>
            </Col>
            <Col xl={12}>
              <b className={accountV2('item--subtitle')}>
                {context.t('CCI Account')}
              </b>
              <div className={accountV2('item--subtitle-value')}>
                {account.accountNumberCci}
              </div>
            </Col>
          </Row>
          <Row className={accountV2('item--bottom')}>
            {account.status === 0 ? (
              <Badge bg="secondary">{context.t('Pending Validation')}</Badge>
            ) : (
              ''
            )}
            {account.status === 1 ? (
              <>
                <Col xl={6} style={{ padding: 0 }}>
                  <div className={'bank-account-badge-container'}>
                    <Badge bg="primary">{context.t('Account Validated')}</Badge>
                  </div>
                </Col>
                <Col xl={6}>
                  <div className={accountV2('item--bottom-action-container')}>
                    <Button
                      type="button"
                      className={accountV2('item--action-btn delete')}
                      onClick={() => confirmDelete(account.id, account.alias)}>
                      <IconDelete />
                      {context.t('Delete')}
                    </Button>
                  </div>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
        </div>
      </Col>
    </React.Fragment>
  );
};

AccountRow.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

const mapStateToProps = (global) => {
  const {
    user: { userInfo, selectedAccountId },
    auth,
  } = global;
  return { userInfo, selectedAccountId, auth, global };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRow);
