import React from 'react';
import PropTypes from 'prop-types';
import './inputElement.css';
import isEmpty from 'is-empty';
import NumberFormat from 'react-number-format';

const InputElement = ({ inputType = '', values, actions, error }, context) => {
  const {
    title,
    icon,
    name,
    value,
    type,
    options = [],
    placeholder = 'Seleccione una opción',
    rightIcon,
    required = false,
    disabled = false,
    thousandSeparator = ",",
    decimalSeparator = ".",
    customClassName = "",
    ...props
  } = values;
  const { onChange, onChangeSecondary, ...otherActions } = actions;

  const emptyValidation = !isEmpty(value) && value.length > 0 ? " " : "empty"
  const errorValidation = !isEmpty(error) && error !== true

  return (
    <div className={`input-element ${!isEmpty(icon) ? 'first' : 'second'} ${disabled ? "input-disabled" : ""} ${customClassName}`}>
      {
        type === "textarea" ?
          <div className={`textarea-container ${errorValidation ? "error" : ""}`}>
            <span>{context.t(title)}</span>
            <textarea name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              ></textarea>
          </div>
          : <div className={`${errorValidation ? "error" : ""}`}>
            {!isEmpty(icon) && (
              <div className="inp-icon-element">
                <img src={icon} alt="icon input" />
              </div>
            )}
            <label className={`label-text ${type === 'date' ? 'input-date' : ''}`}>
              {type === 'select' ?
                <React.Fragment>
                  <select
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    {...props}
                    {...otherActions}
                    className={`select-${emptyValidation}`}
                  >
                    <option value='' style={{ display: 'none' }} />
                    {!isEmpty(options) && options.map(({ value, label }, index) =>
                      <option key={index} value={value}>
                        {context.t(label)}
                      </option>
                    )}
                  </select>
                  <p className={`select-text-${emptyValidation}`}>{context.t(title)}</p>
                </React.Fragment>
                :
                <React.Fragment>
                  {inputType === 'currency' ?
                    <NumberFormat
                      type={'decimal'}
                      inputMode="decimal"
                      isNumericString={true}
                      thousandSeparator={thousandSeparator}
                      decimalSeparator={decimalSeparator}
                      allowNegative={false}
                      className={`${emptyValidation} inputnotdate`}
                      name={name}
                      value={value}
                      onChange={onChange}
                      required={required}
                      disabled={disabled}
                      {...props}
                      {...otherActions}
                    /> : 
                    <input
                      className={`${emptyValidation} inputnotdate`}
                      type={type || 'text'}
                      name={name}
                      value={value}
                      onChange={onChange}
                      required={required}
                      disabled={disabled}
                      {...props}
                      {...otherActions}
                    />
                }
                  <p className={emptyValidation}>{context.t(title)}</p>
                </React.Fragment>
              }
            </label>
            {rightIcon && (
              <div className="content-icon">
                <img
                  src={rightIcon}
                  alt="icon to show password"
                  onClick={onChangeSecondary}
                />
              </div>
            )}
          </div>
      }
      {errorValidation && <span className='message-error'>{context.t(error)}</span>}
    </div>
  );
};

InputElement.contextTypes = {
  t: PropTypes.func
};

export default InputElement;
