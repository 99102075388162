import { connect } from 'react-redux';
import { get } from 'lodash';
import SendReceiveSidePaneComponent from '../../components/SendReceiveSidePane/SendReceiveSidePaneComponent';
import {
  createWithdrawTicket,
  getWithdrawFee as _getWithdrawFee
} from './../../actions/withdrawActions';
import {
  requestTransferFunds,
  transferFunds,
  getTransfers
} from 'apex-web/lib/redux/actions/transferActions';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';
import { saveTrustList } from '../TrustList/TrustListHooks';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { withRouter } from 'react-router-dom';

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

var maptStateToProps = function maptStateToProps(state, ownProps) {
  var productId = get(ownProps, 'product.ProductId', null);
  var productSymbol = get(ownProps, 'product.ProductSymbol', null);
  var position = positionByProductIdSelector(productId)(state);
  var decimalPlaces = state.apexCore.product.decimalPlaces[productSymbol];
  var product = Object.assign({}, ownProps.product, {
    decimalPlaces: decimalPlaces
  });
  var _config$global = config.global,
    allowTransfer = _config$global.allowTransfer,
    hideFees = _config$global.hideFees;
  var _state$withdraw = state.withdraw,
    fee = _state$withdraw.withdrawFee,
    template = _state$withdraw.template;

  return {
    position: position,
    fee: fee,
    template: template,
    product: product,
    allowTransfer: allowTransfer,
    hideFees: hideFees,
    UserId: state.auth.userId
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    receiveFunds: function receiveFunds(payload) {
      return dispatch(requestTransferFunds(payload, ownProps.name));
    },
    sendFunds: async function sendFunds(payload, dataTrustList) {
      var selectedAccountId = payload.selectedAccountId,
        rest = _objectWithoutProperties(payload, ['selectedAccountId']);

      if (Object.keys(dataTrustList).length > 2) {
        const userId = dataTrustList.userId;
        delete dataTrustList.userId;
        const responseSaveTrustList = await saveTrustList(
          userId,
          dataTrustList
        );

        dispatch(
          showSnack({
            id:
              responseSaveTrustList.status === 200
                ? 'transferSuccess'
                : 'transferError',
            text: responseSaveTrustList.data.message,
            type: responseSaveTrustList.status === 200 ? 'success' : 'warning'
          })
        );
      }

      return dispatch(transferFunds(rest, ownProps.name)).then(function() {
        return dispatch(getTransfers(selectedAccountId));
      });
    },
    sendToAddress: async function sendToAddress(payload, dataTrustList) {
      if (Object.keys(dataTrustList).length > 2) {
        const userId = dataTrustList.userId;
        delete dataTrustList.userId;
        const responseSaveTrustList = await saveTrustList(
          userId,
          dataTrustList
        );

        dispatch(
          showSnack({
            id:
              responseSaveTrustList.status === 200
                ? 'transferSuccess'
                : 'transferError',
            text: responseSaveTrustList.data.message,
            type: responseSaveTrustList.status === 200 ? 'success' : 'warning'
          })
        );
      }

      return dispatch(createWithdrawTicket(payload, ownProps));
    },
    getWithdrawFee: function getWithdrawFee(productId, amount) {
      return dispatch(_getWithdrawFee(productId, amount));
    }
  };
};

export default withRouter(connect(
  maptStateToProps,
  mapDispatchToProps
)(SendReceiveSidePaneComponent));
