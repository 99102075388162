import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import empty from 'is-empty';

class Countries extends Component {
  loadOptions(countries) {
    let options = [];

    options.push(
      <option key="0" value="">
        Seleccionar País
      </option>
    );
    countries.map(country =>
      options.push(
        <option key={country.countryCode} value={country.countryCode}>
          {country.countryName}
        </option>
      )
    );

    return options;
  }

  render() {
    let countries = this.props.countries;
    let notSelectedMessage = this.props.notSelectedMessage;
    //let nombre = this.props.nombre;
    let dv = this.props.dv;

    return !empty(countries) ? (
      <Form.Control
        as="select"
        defaultValue={dv}
        onChange={this.props.handleCountryChange}
        required
        name={this.props.name || 'country'}>
        {this.loadOptions(countries)}
      </Form.Control>
    ) : empty(countries) && !empty(notSelectedMessage) ? (
      <Form.Control
        type="text"
        disabled={true}
        className="not-selected"
        defaultValue={notSelectedMessage}
      />
    ) : (
      <Form.Control
        type="text"
        disabled={true}
        className="not-selected"
        defaultValue={this.context.t('Loading...')}
      />
    );
  }
}

Countries.contextTypes = {
  t: PropTypes.func
};

export default Countries;
