import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import { openRetailSendReceiveSidePane, openRetailFiatSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { Link } from 'react-router-dom';
import isEmpty from 'is-empty';
import { Spinner } from '../../components/Spinner/Spinner';
import BigNumber from 'bignumber.js';
import { FormatDecimalCoinkWithOutSimbols, FormatDecimalCoinkWithSimbols, amountWithCurrency, formatCopCurrency } from '../utils';
const makeBigNumber = value => new BigNumber(isNaN(value) ? 0 : value);

const useFetchProducts = (products) => {
    const [loading, setLoading] = useState(true);
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        if (products) {
            setAllProducts(products)
            loading && setLoading(false)
        }
    }, [products])

    return { loading, allProducts }
}

const Wallets = (props, context) => {
    const { products, positions, openRetailFiatSidePane, openSendReceiveSidePane, isCoink } = props;
    const { loading, allProducts } = useFetchProducts(products);
    const btcProduct = Object.values(positions).find(e => e.ProductSymbol === "BTC")
    const priceBtcDollar = (positions && btcProduct) ? btcProduct.NotionalRate : 0
    let priceCopDollar = {}
    if(isCoink){
        priceCopDollar = positions ? Object.values(positions).find((el) => el.ProductSymbol === "COP") : 0
    }
    return (
        <div className='table-container'>
            <div className="divTable divTable-v2">
                {loading ? <div className='no-data-text'><Spinner color='orange'/></div> : isEmpty(allProducts) ? <div className='no-data-text'>{context.t('No currencies available')}</div> :
                    <div className="divTableBody">
                        <div className="divTableRow header">
                            <div className="divTableCell left-text"><p>{context.t("Currency")}</p></div>
                            <div className="divTableCell left-text desk"><p>{context.t("Amount available")}</p></div>
                            <div className="divTableCell left-text"><p>{context.t("Amount in BTC")}</p></div>
                            <div className="divTableCell text desk"><p>{context.t("Action")}</p></div>
                        </div>
                        {allProducts.map((product, key) => {
                            const currency = positions ? Object.values(positions).find(e => e.ProductSymbol === product.ProductSymbol) : {}
                            const amountAvailableByCurrency = Number(makeBigNumber(currency.Amount).minus(makeBigNumber(currency.Hold)).toFixed(product.DecimalPlaces))
                            const amountAvailableUSD = Number(makeBigNumber(currency.NotionalValue).minus(makeBigNumber(currency.NotionalHoldAmount)))
                            const amountAvailableBTC = priceBtcDollar !== 0 ? Number(amountAvailableUSD) / priceBtcDollar : 0
                            const amountAvailableCOP = !isEmpty(priceCopDollar) ? Number(amountAvailableUSD) / priceCopDollar.NotionalRate : 0
                            const isCrypto = product.ProductType === "CryptoCurrency"
                            const detailProduct = {
                                ProductFullName: product.ProductFullName,
                                ProductId: product.ProductId,
                                ProductSymbol: product.ProductSymbol,
                                iconFileName: product.iconFileName
                            }
                            return (
                                <div className="divTableRow" key={key}>
                                    <div className="divTableCell">
                                        <div>
                                            {product.iconFileName.includes('.svg') ? <img className='icon-current' alt="product icon" height="24px" src={'/local/product-icons/' + product.iconFileName} /> : <div className='icon-current' />}
                                            <span className='symbol-current'>{product.Product}</span>
                                            <span className='name-current'>{context.t(product.ProductFullName)}</span>
                                            <div className='mob'>
                                                <p className='subtitle-current'>{context.t("Amount available")}</p>
                                                <p className='text-v2'>
                                                    {amountAvailableByCurrency ? formatNumberToLocale(amountAvailableByCurrency, product.ProductSymbol) : '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divTableCell desk"><p className='text-v2'>{
                                    amountAvailableByCurrency 
                                    ? amountWithCurrency({
                                        amount: FormatDecimalCoinkWithOutSimbols(formatNumberToLocale(amountAvailableByCurrency, product.ProductSymbol), product.ProductSymbol ),
                                        product
                                    })
                                    : '-'}</p>
                                    </div>
                                    <div className="divTableCell">
                                        <p className='text-v2'>
                                            {amountAvailableByCurrency && amountAvailableUSD && amountAvailableBTC ? (
                                                <>{!isCoink ? formatNumberToLocale(amountAvailableBTC, 'BTC') : FormatDecimalCoinkWithOutSimbols(formatNumberToLocale(amountAvailableBTC, 'BTC'), "BTC")}<span> {`≈  ${amountWithCurrency({
                                                    amount: !isCoink ? formatNumberToLocale(amountAvailableUSD, "USD") : formatCopCurrency(makeBigNumber(amountAvailableCOP).decimalPlaces(2), 2).slice(2),
                                                    product: products.find(e => e.Product === (isCoink ? 'COP' : 'USD')) || {},
                                                })}`}</span></>
                                            ) : '-'}
                                        </p>
                                        {isCrypto ?
                                            <div className='market-actions-button-container mob'>
                                                <button onClick={() => openSendReceiveSidePane(false, detailProduct)} className="market-actions-button">{context.t('Deposit')}</button>
                                                <button onClick={() => openSendReceiveSidePane(true, detailProduct)} className="market-actions-button">{context.t('Withdraw')}</button>
                                                <Link to='/buy-sell' className="market-actions-button">{context.t('Trade')}</Link>
                                            </div>
                                            : <div className='market-actions-button-container mob'>
                                                 <button onClick={() => openRetailFiatSidePane(true, detailProduct)} to='/buy-sell' className="market-actions-button">{context.t('Deposit')}</button>
                                                <button onClick={() => openRetailFiatSidePane(false, detailProduct)} to='/buy-sell' className="market-actions-button">{context.t('Withdraw')}</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="divTableCell text desk">
                                        {isCrypto ?
                                            <div className='market-actions-button-container'>
                                                <button onClick={() => openSendReceiveSidePane(false, detailProduct)} className="market-actions-button">{context.t('Deposit')}</button>
                                                <button onClick={() => openSendReceiveSidePane(true, detailProduct)} className="market-actions-button">{context.t('Withdraw')}</button>
                                                <Link to='/buy-sell' className="market-actions-button">{context.t('Trade')}</Link>
                                            </div>
                                            : <div className='market-actions-button-container'>
                                                 <button onClick={() => openRetailFiatSidePane(true, detailProduct)} to='/buy-sell' className="market-actions-button">{context.t('Deposit')}</button>
                                                <button onClick={() => openRetailFiatSidePane(false, detailProduct)} to='/buy-sell' className="market-actions-button">{context.t('Withdraw')}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div >
        </div >
    )
}

Wallets.contextTypes = {
    t: PropTypes.func.isRequired
};


const mapStateToProps = function mapStateToProps(state) {
    const accountId = state.user.userInfo.AccountId;
    const products = productPositionAssetsSelector(state);
    const productsByLevel = state.productsByLevel;
    const isCoink = !isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false
    const newProducts = (!isEmpty(products) && !isEmpty(productsByLevel)) ? products.filter(e => productsByLevel.includes(e.ProductSymbol)) : false;
    const positions = state.position.positions[accountId];
    return { products: newProducts, positions, isCoink };
};

const mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
    return {
        openRetailFiatSidePane: function openRetailFiatSidePane(isSend, product) {
            dispatch(openRetailFiatSidePane(isSend, product))
        },
        openSendReceiveSidePane: function openSendReceiveSidePane(isSend, product) {
            dispatch(openRetailSendReceiveSidePane(isSend, product));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallets)