import React from 'react';
import PropTypes from 'prop-types';

import GenericPaymentWorkflowContainer from './GenericPaymentWorkflow/GenericPaymentWorkflowContainer';
import CryptoWalletDepositWorkflowContainer from './CryptoWalletDepositWorkflow/CryptoWalletDepositWorkflowContainer';
import ManualDepositWorkflowContainer from './ManualDepositWorkflow/ManualDepositWorkflowContainer';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var DepositWorkflowsComponent = (function(_React$Component) {
  _inherits(DepositWorkflowsComponent, _React$Component);

  function DepositWorkflowsComponent() {
    _classCallCheck(this, DepositWorkflowsComponent);

    return _possibleConstructorReturn(
      this,
      (
        DepositWorkflowsComponent.__proto__ ||
        Object.getPrototypeOf(DepositWorkflowsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(DepositWorkflowsComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          DepositWorkflow = _props.templateInfo.DepositWorkflow,
          submitting = _props.submitting,
          isLoading = _props.isLoading,
          onSubmit = _props.onSubmit,
          setBankDetails = _props.setBankDetails;

        var WorkflowComponent = null;

        if (isLoading) {
          return null;
        }

        switch (DepositWorkflow) {
          case 'CryptoWallet': {
            WorkflowComponent = React.createElement(
              CryptoWalletDepositWorkflowContainer,
              null
            );
            break;
          }
          case 'ManualDeposit': {
            WorkflowComponent = React.createElement(
              ManualDepositWorkflowContainer,
              {
                submitting: submitting,
                onSubmit: onSubmit,
                setBankDetails: setBankDetails
              }
            );
            break;
          }
          case 'GenericPayment': {
            WorkflowComponent = React.createElement(
              GenericPaymentWorkflowContainer,
              null
            );
            break;
          }
          default: {
            break;
          }
        }
        return WorkflowComponent;
      }
    }
  ]);

  return DepositWorkflowsComponent;
})(React.Component);

DepositWorkflowsComponent.propTypes = {
  templateInfo: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};
DepositWorkflowsComponent.defaultProps = {
  templateInfo: {},
  onSubmit: function onSubmit() {},
  submitting: false
};

export default DepositWorkflowsComponent;
