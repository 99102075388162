import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

const Switch = function Switch(props, context) {
  const input = props.input,
      onClick = props.onClick,
      customClass = props.customClass;

  const value = input.value;
  const modifier = { on: value };
  const text = value ? context.t('On') : context.t('Off');
  const bemClasses = getBEMClasses('ap-switch', customClass);

  const handleClick = function handleClick() {
    onClick(value);
    if(input.onChange) input.onChange(!value);
  };

  return React.createElement(
    'div',
    { className: bemClasses('switch', modifier), onClick: handleClick },
    React.createElement('div', { className: bemClasses('toggle', modifier) }),
    React.createElement(
      'div',
      { className: bemClasses('text', modifier) },
      text
    )
  );
};

Switch.contextTypes = {
  t: PropTypes.func.isRequired
};

const APSwitch = function APSwitch(props) {
  return React.createElement(Switch, props);
};

export default APSwitch;