import { connect } from 'react-redux';
import { reduxForm, change, SubmissionError } from 'redux-form';
import { sendTwoFactorAuthCodeForTransfers } from 'apex-web/lib/redux/actions/transferActions';
import SendReceiveTwoFactorAuthModal from './SendReceiveTwoFactorAuthModal';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';

var mapStateToProps = function mapStateToProps(state) {
  return {
    GoogleQRCode: state.user.qrCode.ManualCode,
    userName: state.user.userInfo.UserName
  };
};

var mapDispatchToProps = {
  changeFieldValue: change
};

var containerForm = reduxForm({
  form: 'twoFactorAuthTransactionalModal',
  onSubmit: function onSubmit(payload, dispatch) {
    return dispatch(sendTwoFactorAuthCodeForTransfers(payload.code)).then(
      function(response) {
        if (!response || !response.authenticated) {
          throw new SubmissionError({
            _error: 'Invalid Code'
          });
        }
        return true;
      }
    );
  },
  onChange: function onChange(values, dispatch, props) {
    if (values.code && values.code.length === 6) {
      props.submit();
    }
  }
})(SendReceiveTwoFactorAuthModal);

var container = connect(
  mapStateToProps,
  mapDispatchToProps
)(containerForm);

export default modalDecorator({
  name: MODAL_TYPES.SEND_REQUEST_2FA
})(container);
