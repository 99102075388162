import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { productPositionAssetsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import isEmpty from 'is-empty';
import './balance.css'
import CircleBxn from './shared/CircleBxn';
import PropTypes from 'prop-types';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { Spinner } from '../../components/Spinner/Spinner';
import BigNumber from 'bignumber.js';
import ArrowIcon from './../../images/icons-v2/ArrowRight.svg';
import {FormatDecimalCoinkWithSimbols, amountWithCurrency, formatCopCurrency, formattedCoinkAmountWithoutDots} from '../utils'
const makeBigNumber = value => new BigNumber(isNaN(value) ? 0 : value);

const useFetchProducts = (products) => {
    const [loading, setLoading] = useState(true);
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        if (products && !isEmpty(products)) {
            setAllProducts(products)
            loading && setLoading(false)
        }
    }, [products])

    return { loading, allProducts }
}

const Balance = (props, context) => {
    const { products = [], isCoink, level1 } = props;
    const selectRef = useRef(null);
    const { loading, allProducts } = useFetchProducts(products);
    const [balanceByProducts, setBalanceByProducts] = useState({})
    const [selectedProduct, setSelectedProduct] = useState('')

    const handleClickInside = (name) => {
        if (selectRef.current) {
            if(selectRef.current.style.cssText) return selectRef.current.style = null
            selectRef.current.style.height = `${selectRef.current.scrollHeight}px`;
            selectRef.current.style.transition  = `height .3s`;
        }
    };

    const handleClickOutside = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) selectRef.current.style = null;
    };
  
    const searchDecimal = (symbol) => {
        const decimal = allProducts.find(e => e.Product === symbol);
        return !isEmpty(decimal) ? decimal.DecimalPlaces : 2;
    }

    const fiatValueByUserType = 'USD'
    useEffect(() => {
        if(!isEmpty(allProducts)) {
            let products = {}
            const totalUSD = allProducts.reduce((acc, product) => { 
                const amount = Number(makeBigNumber(product.NotionalValue).minus(makeBigNumber(product.NotionalHoldAmount)).toFixed(searchDecimal(fiatValueByUserType)))
                return makeBigNumber(acc).plus(amount).toFixed(searchDecimal(fiatValueByUserType))
            }, 0)
            allProducts.forEach(e => {
                let result = 0;
                if (e.ProductSymbol === fiatValueByUserType) {
                    result = totalUSD
                } else {
                    const priceDollar = allProducts.find(i => i.ProductSymbol === e.ProductSymbol).NotionalRate
                    result = allProducts.reduce((acc, product) => { 
                        let amount = 0
                        if(makeBigNumber(product.NotionalValue).minus(makeBigNumber(product.NotionalHoldAmount)).toFixed(searchDecimal(product.ProductSymbol)) > 0){   
                            amount = Number(makeBigNumber(Number(makeBigNumber(product.NotionalValue).minus(makeBigNumber(product.NotionalHoldAmount))) / priceDollar).toFixed(searchDecimal(e.ProductSymbol)))
                        }
                        return makeBigNumber(acc).plus(amount).toFixed(searchDecimal(e.ProductSymbol))
                    }, 0)
                }
                products[e.Product] = result
            })
            if(isEmpty(selectedProduct)){
                setSelectedProduct(isCoink ? 'COP' : allProducts[0].Product)
            }
            setBalanceByProducts(products)
        }
    }, [allProducts])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const mainBalance = !balanceByProducts[selectedProduct] ? 0 : Number(balanceByProducts[selectedProduct]).toLocaleString(isCoink ? 'es-CO' : 'en-US',{ minimumFractionDigits: searchDecimal(selectedProduct) })

    
    return (
        <div className='balance-componen'>
            <CircleBxn color='orange' className='circleA' />
            <CircleBxn color='purple' className='circleB' />
            <CircleBxn color='purple' className='circleC' />
            <div className={`title-Balance ${isEmpty(balanceByProducts) ? 'initial-balance-loader' : ''}`}>
                {context.t('Total balance')}
                {!isEmpty(balanceByProducts) && 
                    <div className='select-balance'>
                        <div className='all-items-balance' ref={selectRef} onClick={handleClickInside}>
                            <div className='first-item-balance'>
                                {selectedProduct}
                                <img className='arrow-down-item' src={ArrowIcon} alt='Arrow icon' width={20} height={20}/>
                            </div>
                            {Object.keys(balanceByProducts).filter(e => e !== selectedProduct).map((name, key) =>
                                <div onClick={() => setSelectedProduct(name)} key={key}>{name}</div>
                            )}
                        </div>
                    </div>
                }
            </div>
            {loading ?
                <div className='initial-loader-balance' >
                    <Spinner color='orange' />
                </div> :
                <React.Fragment>
                    <h2 className='title-BTC'>{amountWithCurrency({
                        amount: mainBalance || 0,
                        product: products.find(e => e.Product === selectedProduct) || {},
                    })}</h2>
                    {selectedProduct === 'BTC' ?
                        <p className='title-USD'>≈ {amountWithCurrency({
                            amount: Number(balanceByProducts[isCoink ? 'COP' : 'USD']).toLocaleString(isCoink ? 'es-ES' : 'en-US',{ minimumFractionDigits: searchDecimal(isCoink ? 'COP' : 'USD') }),
                            product: products.find(e => e.Product === (isCoink ? 'COP' : 'USD')) || {},
                        })}</p> : 
                        <p className='title-USD'>≈ { Number(balanceByProducts['BTC']).toLocaleString(isCoink ? 'es-ES' : 'en-US',{ minimumFractionDigits: searchDecimal('BTC') }) } BTC</p>
                    }
                    
                </React.Fragment>
            }
            <p>{context.t('You can view your total balance in the currency of your choice')}.</p>
        </div>
    )
}
Balance.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = function mapStateToProps(state) {
    const products = productPositionAssetsSelector(state);
    const productsByLevel = state.productsByLevel;
    return { 
        products: (!isEmpty(products) && !isEmpty(productsByLevel)) ? products.filter(e => productsByLevel.includes(e.ProductSymbol)) : false,
        isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
        level1: !isEmpty(state.level1) ? state.level1 : {}
    };
};
export default connect(mapStateToProps)(Balance);