const initialState = {
    'phone': false,
    '2FA': []
}

const validation = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_STATE_VALIDATION':
            return { ...action.payload };
        case 'UPDATE_STATE_VALIDATION':
            return { ...state, ...action.payload };
        case 'RESET_STATE_VALIDATION':
            return initialState;
        default:
            return state;
    }
};

export default validation;