import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  DropdownButton,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from '../../hooks/formHooks';
import Loading from '../../components/Loading';
import { getMyCreditCards, newPaymentAttempt } from './EasyBuyHooks';
import EasyBuyPagePrice from './EasyBuyComponents/EasyBuyPagePrice';
import EasyBuyPageClosed from './EasyBuyPageClosed';
import EasyBuyPageVerify from './EasyBuyPageVerify';
import { circlePaymentError, cleanPhone, callAPI } from '../utils';
import RecentActivityTableContainer from './../../components/RecentActivity/RecentActivityContainer';

import './EasyBuyPage.css';

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  },
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts, instruments, level1 };
};

const EasyBuyBeta = (props, context) => {
  try {
    const {
      userInfo,
      selectedAccountId,
      accounts,
      level1,
      instruments,
      subscribeLevel1
    } = props;
    let currentAccountInfo = accounts.find(
      a => a.AccountId === selectedAccountId
    );

    let tdcLimits = {
      minimunAmount: 20,
      maximumAmount: 5000
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(0);
    const [stepModal, setStepModal] = useState('one');
    const [levelName, setLevelName] = useState('');
    const [initialState, setInitialState] = useState({
      selected_card_id: ''
    });
    const [myCreditCards, setMyCreditCards] = useState({});
    const [myCurrentDataForm, setMyCurrentDataForm] = useState({
      aprox_to_buy: '0.00',
      selected_card_id: null
    });
    const [error, setError] = useState(false);
    const [formO, setFormO] = useState();
    const [responsePayment, setResponsePayment] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const getCardInfo = id => {
      let card = myCreditCards.find(c => c.cardIdCircle === id);
      return card;
    };

    const getPrice = name => {
      let response = {};
      if (!empty(instruments)) {
        let instrumentInfo = instruments.find(i => i.Symbol === name);
        if (!empty(instrumentInfo)) {
          if (!empty(level1)) {
            let prices = [];
            for (let le in level1) {
              prices.push(level1[le]);
            }
            response = prices.find(
              l => l.InstrumentId === instrumentInfo.InstrumentId
            );
            if (!empty(response)) {
              return response;
            }
          }
        }
      }
      return response;
    };

    const findPrice = (levelPrices, amount, pair) => {
      let instrumentsNew = Object.keys(instruments).map(p => instruments[p]);
      let instument = instrumentsNew.find(p => p.Symbol === pair);
      if (empty(instument)) {
        return { btcToBuyAprox: 0.0, fee: 0.0, instument: {} };
      }
      let instrumentId = instument.InstrumentId;

      let price = levelPrices[instrumentId];

      let total = parseFloat(!empty(amount) ? amount : 0);
      let feeProcessingPercentage = parseFloat(4);
      let feeInternal = parseFloat(0.75);
      if (empty(total)) {
        return { btcToBuyAprox: 0.0, fee: 0.0, instument: {} };
      }
      let feeProcessing = total * (feeProcessingPercentage / 100);
      let amountCalc = total - (feeProcessing + feeInternal);
      let banexcoinComission = 0.015;
      let btcPriceBanexcoin = price.BestOffer * (1 + banexcoinComission);

      let btcToBuyAprox = parseFloat(
        parseFloat(amountCalc / btcPriceBanexcoin).toFixed(8)
      );

      return {
        btcToBuyAprox,
        btcPriceBanexcoin: btcPriceBanexcoin,
        amountTotal: amountCalc,
        fee: feeProcessing,
        BestOffer: price.BestOffer,
        instument: instument
      };
    };

    const calcPrice = (amount, currencySymbol = null) => {
      let currency = currencySymbol;
      let pair = `${currency}USD`;
      let price = getPrice(pair);
      let AproxToBuy = 0.0;
      let amountTotal = 0.0;
      let feeProcessing = 0.0;
      let btcPriceBanexcoin = 0.0;

      if (!empty(price) && !empty(Number(amount)) && !empty(currency)) {
        if (price.BestOffer > 0) {
          let amountNumber = Number(amount);
          let feeProcessingPercentage = 4;
          let feeInternalFixed = 0.75;
          feeProcessing = amountNumber * (feeProcessingPercentage / 100);
          amountTotal = amountNumber - (feeProcessing + feeInternalFixed);

          let banexcoinComission = 0.015;
          btcPriceBanexcoin = price.BestOffer * (1 + banexcoinComission);

          AproxToBuy = parseFloat(
            parseFloat(amountTotal / btcPriceBanexcoin).toFixed(8)
          );
        }
      }
      setMyCurrentDataForm(myCurrentDataForm => ({
        ...myCurrentDataForm,
        fee_processing: feeProcessing,
        amount_total: amountTotal,
        aprox_to_buy: AproxToBuy,
        btcPriceBanexcoin: btcPriceBanexcoin
      }));
      return AproxToBuy;
    };

    const onSimpleChange = e => {
      e.persist();
      setInputs(inputs => ({
        ...inputs,
        [e.target.name]: e.target.value
      }));
    };

    document.title = context.t('Easy Buy') + ' - Banexcoin';

    async function startData() {
      setLoading(1);
      try {
        let selectedCardId = '',
          phoneNumber = '';
        let currentAccountInfo = accounts.find(
          a => a.AccountId === selectedAccountId
        );

        let levels = [
          { level: 1, name: 'Beginner Level' },
          { level: 2, name: 'Intermediate Level' },
          { level: 3, name: 'Master Level' },
          { level: 4, name: 'Corporate Level' }
        ];
        let levelInfo = levels.find(
          l => l.level === currentAccountInfo.VerificationLevel
        );
        if (!empty(levelInfo)) {
          setLevelName(levelInfo.name);
        }
        let cards = await getMyCreditCards(userInfo.UserId, selectedAccountId);
        if (cards.data.totalCount > 0) {
          setMyCreditCards(cards.data.data);
          let firstResponse = !empty(cards.data.data[0].payloadResponse)
            ? JSON.parse(cards.data.data[0].payloadResponse)
            : {};

          phoneNumber = firstResponse.metadata.phoneNumber;
          selectedCardId = cards.data.data[0].cardIdCircle;
          setMyCurrentDataForm(myCurrentDataForm => ({
            ...myCurrentDataForm,
            selected_card_id: cards.data.data[0].cardIdCircle
          }));
        }

        let states = {
          validated: false,
          banexcoin_user_id: userInfo.UserId,
          banexcoin_user_name: userInfo.UserName,
          email: !empty(userInfo.Email) ? userInfo.Email : '',
          currency: 'BTC'
        };
        setInputs({
          ...inputs,
          ...states,
          selected_card_id: selectedCardId,
          email: !empty(userInfo.Email) ? userInfo.Email : '',
          phone_number: phoneNumber,
          session_id: `${userInfo.UserId}-${Number(moment())}`
        });
        setInitialState(states);
      } catch (error) {
        setError(true);
        toast.error(context.t('Error loading information'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    }

    const onSelectCard = c => {
      let dataBilling = JSON.parse(c.payloadResponse);
      setInputs(inputs => ({
        ...inputs,
        selected_card_id: c.cardIdCircle,
        email: dataBilling.metadata.email,
        phone_number: dataBilling.metadata.phoneNumber,
        session_id: `${userInfo.UserId}-${Number(moment())}`
      }));
      setMyCurrentDataForm(myCurrentDataForm => ({
        ...myCurrentDataForm,
        selected_card_id: c.cardIdCircle
      }));
    };
    const startPayment = async (inputs, form, newOnClose) => {
      setLoading(1);
      try {
        let dataModel = {
          amount: {
            amount: inputs.amount,
            currency: 'USD'
          },
          verification: 'cvv',
          source: {
            id: inputs.selected_card_id,
            type: 'card'
          },
          cardData: {
            cvv: inputs.cvv
          },
          metadata: {
            email: inputs.email,
            phoneNumber: cleanPhone(`${inputs.phone_number}`),
            sessionId: inputs.session_id
          },
          product: inputs.currency,
          productTotalInitial: Number(
            findPrice(level1, inputs.amount, 'BTCUSD').btcToBuyAprox
          ),
          otp: inputs.twofacode
        };
        let pay = await newPaymentAttempt(
          userInfo.UserId,
          dataModel,
          selectedAccountId
        );
        let resp = pay.data;
        setResponsePayment(resp);
        let responsePayload = JSON.parse(resp.payloadResponse);
        if (resp.status === 'PENDING') {
          setErrorMessage('');
          setStepModal('success');
        } else {
          let errorText = circlePaymentError.find(
            e => e.code === responsePayload.errorCode
          );
          setErrorMessage(
            context.t(
              !empty(errorText) ? errorText.description : 'Unknown error'
            )
          );
        }
      } catch (error) {
        console.log('error on start payment', error);
        if (error.hasOwnProperty('response') && !empty(error.response)) {
          if (
            error.response.hasOwnProperty('data') &&
            error.response.hasOwnProperty('data')
          ) {
            console.log(
              'Error message response:',
              error.response.data.data.message
            );
            setErrorMessage(error.response.data.data.message);
          } else {
            setErrorMessage(error.message);
          }
        } else {
          setErrorMessage('An error has occurred');
        }
      }
      setLoading(0);
    };

    const onSubmitCallback = async (inputs, form) => {
      try {
        setFormO(form);
        handleShow();
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
    };

    const customValidations = inputs => {
      let errors = {};
      if (empty(inputs.amount)) {
        errors.amount = 'You must enter a amount';
      } else {
        if (parseFloat(inputs.amount) > tdcLimits.maximumAmount) {
          errors.amount = context.t(
            'Your amount exceeds the maximum {max} in Banexcoin',
            { max: tdcLimits.maximumAmount.toFixed(2) }
          );
        }
        if (parseFloat(inputs.amount) < tdcLimits.minimunAmount) {
          errors.amount = context.t(
            'Your amount is less than the minimum {min} in Banexcoin',
            { min: tdcLimits.minimunAmount.toFixed(2) }
          );
        }
      }
      setErrors({
        ...errors
      });
      return errors;
    };

    let {
      inputs,
      errors,
      setInputs,
      setErrors,
      onInputChange,
      onSubmit,
      onSubmitSimple,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(accounts) && !empty(selectedAccountId)) {
          startData();
          subscribeLevel1({
            OMSId: 1,
            Symbol: 'BTCUSD'
          });
        } else {
          setLoading(1);
        }
      },
      [userInfo, accounts, selectedAccountId]
    );

    useEffect(
      () => {
        if (!empty(inputs.currency) && !empty(inputs.amount)) {
          calcPrice(inputs.amount, inputs.currency);
        }
      },
      [level1, inputs]
    );

    useEffect(
      () => {
        if (!empty(inputs)) {
          customValidations(inputs);
        }
      },
      [inputs]
    );

    const newOnClose = () => {
      handleClose();
      setStepModal('one');
    };
    const newOnCloseOnFinish = () => {
      handleClose();
    };

    let au = [
      'andrewvergel',
      'david.arrarte',
      'lc',
      'msrangelh',
      'binancetest',
      'thespeed94',
      'daas',
      'len',
      'TestBanexcoinLC18dic',
      'soporte',
      'david.arrarte10',
      'AdriKat13'
    ];

    if (!au.includes(userInfo.UserName)) {
      return <EasyBuyPageClosed />;
    }

    if (
      !empty(currentAccountInfo) &&
      currentAccountInfo.VerificationLevel < 1
    ) {
      return <EasyBuyPageVerify />;
    }

    if (
      !empty(currentAccountInfo) &&
      currentAccountInfo.VerificationLevel === 4
    ) {
      return <EasyBuyPageVerify />;
    }

    return (
      <React.Fragment>
        <Loading loading={loading} />
        {error ? (
          <div className="container credit-card-buy">
            <Row className="justify-content-md-center">
              <Col lg={8} className="error-window">
                <div className="error-window-container">
                  <FontAwesomeIcon
                    className="card-title-icon"
                    icon={faExclamationCircle}
                  />
                  <h2>{context.t('An internal error has ocurred')}</h2>
                  <span
                    onClick={() => {
                      window.location.reload(false);
                    }}>
                    {context.t('Reload page')}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <React.Fragment>
            <div className="container credit-card-buy">
              <EasyBuyPagePrice
                inputs={inputs}
                calcPrice={calcPrice}
                newOnClose={newOnClose}
                stepModal={stepModal}
                myCurrentDataForm={myCurrentDataForm}
                startPayment={startPayment}
                form={formO}
                show={show}
                setShow={setShow}
                handleClose={handleClose}
                handleShow={handleShow}
                loading={loading}
                onSimpleChange={onSimpleChange}
                responsePayment={responsePayment}
                errorMessage={errorMessage}
                newOnCloseOnFinish={newOnCloseOnFinish}
                myCreditCards={myCreditCards}
                findPrice={findPrice}
              />
              <Row className="justify-content-md-center">
                <Col lg={8}>
                  <Row className="col-fix">
                    <Col lg={6}>
                      <Form
                        noValidate
                        validated={inputs.validated}
                        encType="multipart/form-data"
                        onSubmit={onSubmitSimple}>
                        <div className="card-lefted">
                          <div className="card-lefted-header">
                            <h2 className="card-title">
                              {context.t('Buy with credit card')}
                            </h2>
                            <h2 className="card-title2">
                              {context.t(levelName)}
                            </h2>
                            <h2 className="card-title3">
                              {context.t(
                                'Maximum amount allowed to buy: {amount}',
                                {
                                  amount: '5000 USD'
                                }
                              )}
                            </h2>
                          </div>
                          <div className="card-lefted-body">
                            <div className="btn-container pb-2">
                              <Link
                                to="/easy-buy/my-payment-attempts/10/1"
                                className="btn btn-banexcoin">
                                {context.t('My payment attempts')}
                              </Link>
                            </div>

                            <Form.Group
                              controlId="formBasicEmail"
                              className={
                                !empty(errors.amount)
                                  ? 'is-invalid'
                                  : 'is-valid'
                              }>
                              <Form.Label>{context.t('Amount')}</Form.Label>
                              <InputGroup size="lg">
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="inputGroup-sizing-lg">
                                    USD
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  name="amount"
                                  type="number"
                                  aria-label="USD"
                                  aria-describedby="inputGroup-sizing-sm"
                                  step="0.01"
                                  className="normal"
                                  autoComplete={'off'}
                                  onChange={onSimpleChange}
                                  value={
                                    !empty(inputs.amount) ? inputs.amount : ''
                                  }
                                  isInvalid={
                                    !empty(errors.amount) ? true : false
                                  }
                                  placeholder={`${tdcLimits.minimunAmount.toFixed(
                                    0
                                  )} - ${tdcLimits.maximumAmount.toFixed(0)}`}
                                />
                                {!empty(errors.amount) ? (
                                  <Form.Control.Feedback type="invalid">
                                    {context.t(errors.amount)}
                                  </Form.Control.Feedback>
                                ) : (
                                  ''
                                )}
                              </InputGroup>
                            </Form.Group>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01">
                                <Form.Label>
                                  {context.t('Cards')}
                                  <Link to="/easy-buy/my-credit-cards">
                                    {context.t('My credit cards')}
                                  </Link>
                                </Form.Label>
                                <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="success"
                                  title={
                                    !empty(
                                      myCurrentDataForm.selected_card_id
                                    ) &&
                                    !empty(
                                      getCardInfo(
                                        myCurrentDataForm.selected_card_id
                                      )
                                    ) ? (
                                      <React.Fragment>
                                        <Dropdown.Item>
                                          <span>
                                            {`XXXX XXXX XXXX ${
                                              getCardInfo(
                                                myCurrentDataForm.selected_card_id
                                              ).cardLast4Digits
                                            }`}
                                            <b>{`(${
                                              getCardInfo(
                                                myCurrentDataForm.selected_card_id
                                              ).cardExpirationDate
                                            })`}</b>
                                          </span>
                                          <img
                                            src={
                                              'local/landing-page/images/icons/credit-card/' +
                                              getCardInfo(
                                                myCurrentDataForm.selected_card_id
                                              ).network.toLowerCase() +
                                              '.png'
                                            }
                                          />
                                        </Dropdown.Item>
                                      </React.Fragment>
                                    ) : (
                                      <div className="none-card">
                                        {context.t('New credit card')}
                                      </div>
                                    )
                                  }
                                  id="input-group-dropdown-1"
                                  className="credit-dropdown"
                                  size="lg">
                                  <Link
                                    to="/easy-buy/add-card"
                                    className="dropdown-item">
                                    <span className="credit-card-link">
                                      {context.t('Add new credit card')}
                                    </span>
                                  </Link>
                                  <Dropdown.Divider />
                                  {!empty(myCreditCards)
                                    ? myCreditCards
                                        .filter(c => c.status === 'PASS')
                                        .map((d, i) => (
                                          <React.Fragment key={i}>
                                            <Dropdown.Item
                                              onClick={() => {
                                                onSelectCard(d);
                                              }}>
                                              <span>
                                                {`XXXX XXXX XXXX ${
                                                  d.cardLast4Digits
                                                }`}
                                                <b>{`(${
                                                  d.cardExpirationDate
                                                })`}</b>
                                              </span>
                                              <img
                                                src={
                                                  'local/landing-page/images/icons/credit-card/' +
                                                  d.network.toLowerCase() +
                                                  '.png'
                                                }
                                              />
                                            </Dropdown.Item>
                                          </React.Fragment>
                                        ))
                                    : ''}
                                </DropdownButton>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="5"
                                controlId="validationCustom01">
                                <Form.Label>{context.t('Currency')}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="currency"
                                  isInvalid={
                                    !empty(errors) && !empty(errors.currency)
                                      ? true
                                      : false
                                  }
                                  isValid={
                                    empty(errors) || empty(errors.currency)
                                      ? true
                                      : false
                                  }
                                  onChange={onSimpleChange}
                                  value={
                                    !empty(inputs.currency)
                                      ? inputs.currency
                                      : ''
                                  }>
                                  <option value="BTC">
                                    {context.t('BTC')}
                                  </option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="7"
                                controlId="validationCustom02">
                                <Form.Label className="label-whitespace" />
                                <Form.Control
                                  required
                                  type="number"
                                  placeholder="0.00"
                                  value={
                                    !empty(inputs.currency) &&
                                    !empty(inputs.amount)
                                      ? myCurrentDataForm.aprox_to_buy
                                      : '0.00'
                                  }
                                  className="middled"
                                  readOnly={true}
                                />
                                <Form.Text className="text-bottom-info">
                                  {context.t('Approximate amount')}
                                </Form.Text>
                              </Form.Group>
                            </Form.Row>
                          </div>
                          <div className="card-lefted-footer">
                            <Button
                              type="submit"
                              variant="banexcoin"
                              disabled={
                                !empty(myCurrentDataForm.selected_card_id) &&
                                !empty(
                                  getCardInfo(
                                    myCurrentDataForm.selected_card_id
                                  )
                                ) &&
                                empty(errors)
                                  ? false
                                  : true
                              }>
                              {context.t('Buy {currency1} with {currency2}', {
                                currency1: 'BTC',
                                currency2: 'USD'
                              })}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                    <Col lg={6}>
                      <div className="card-righted align-self-center">
                        <div className="card-righted-header">
                          <h2 className="card-title important">
                            <FontAwesomeIcon
                              className="card-title-icon"
                              icon={faExclamationCircle}
                            />
                            {context.t('Important announcement')}
                          </h2>
                        </div>
                        <div className="card-righted-body">
                          <p className="parr-text">
                            {context.t(
                              'When using the credit card purchase take into account the following:'
                            )}
                          </p>
                          <p className="parr-text">
                            *{' '}
                            {context.t(
                              'You can only make purchases with a card of your property.'
                            )}
                          </p>
                          <p className="parr-text">
                            *{' '}
                            {context.t(
                              'The service has a commission of 4% of the purchase amount in USD plus 0.75 USD per transaction.'
                            )}
                          </p>
                          <p className="parr-text">
                            *{' '}
                            {context.t(
                              'Your information is secure, Banexcoin does not store card information.'
                            )}
                          </p>
                          <p className="parr-text">
                            *{' '}
                            {context.t(
                              'The amount loaded on your card will be in USD, check with your Card issuer for charges for changing your local currency or possible additional charges.'
                            )}
                          </p>
                          <p className="parr-text">
                            *{' '}
                            {context.t(
                              'The amount in BTC to buy shown is approximate, the final amount to receive in your Banexcoin Account will be the one that results when making the exchange after the transaction with your card is approved.'
                            )}
                          </p>
                          <p className="parr-text">
                            * {context.t('Terms and conditions apply.')}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="container credit-card-buy pt-5 recentactivitybox">
              <Row className="justify-content-md-center">
                <Col lg={8}>
                  <RecentActivityTableContainer context={context} />
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  } catch (e) {
    console.error('EasyBuyBeta error', e);
    return <div>Error:</div>;
  }
};

EasyBuyBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    subscribeLevel1: payload =>
      dispatch(callAPI('SubscribeLevel1', payload)).then(prices => {
        return prices;
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EasyBuyBeta);
