import React from 'react';
import SuccessIcon from '../../images/successIcon.png';
import ErrorIcon from '../../images/errorIcon.png';
import PendingIcon from '../../images/pendingIcon.png';
import InfoIcon from '../../images/infoIcon.png';
import './statusMessage.css';
import isEmpty from 'is-empty';
const StatusMessage = (
  { type = 'SUCCESS', title = '', message = '', children },
  context
) => {
  const imageType = {
    success: SuccessIcon,
    pending: PendingIcon,
    error: ErrorIcon,
    info: InfoIcon
  };
  const styleType = type.toUpperCase();
  const img = imageType[type.toLowerCase()];
  return (
    <div className={`statusTransaction firstType_${styleType} ${!isEmpty(children) ? 'child' : ''}`}>
      <div className="contentIconStatus">
        <div className={`spaceConIcon secondType_${styleType}`}>
          <img src={img} width={35} />
        </div>
      </div>
      {!isEmpty(title) && (
        <h2
          className="titInfoDetail"
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      )}
      {!isEmpty(message) &&
        <p
          className={isEmpty(title) ? 'white-text' : ''}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        />}
      {children && children}
    </div>
  );
};
export default StatusMessage;