import React from 'react'
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CardGradient, Tooltip } from '../../../components'

const RechargeModal = ({ setNextView }, context) => {

    const ht = useHistory();

    const actions = [
        {
            label: context.t("I will do it later"),
            action: () => setNextView(true),
            className: "secondary",
        },
        {
            label: context.t("Yes, recharge"),
            action: () => ht.push('/cards/recharge'),
        },
    ];

    return (
        <CardGradient
            type='pending'
            actions={actions}
            customClassName='recharge-modal'
        >
            <h2>
                {context.t('You just need to make a recharge')}
                <Tooltip
                    id="recharge-card"
                    text={context.t("To recharge your Banexcard you need USD Coin (USDC) in your account, you can: \n- Use the available USDC balance in your Banexcoin Wallet \n- Deposit S/ or $ and buy USDC to recharge\n- Transfer USDC from an external wallet to your Banexcoin wallet")}
                    className='tooltip-recharge'
                />
            </h2>
            <div className='container-text'>
                <p>{context.t("To request your Physical Banexcard it is necessary that you have made a recharge to your Virtual Banexcard.")}</p>
                <p>{context.t("Remember that the issuance of the card is FREE, we will only deduct 5 USD from the available balance of your card for the purpose of sending your Physical card according to the coverage areas within Peru.")}</p>
            </div>
        </CardGradient>
    )
}

RechargeModal.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default RechargeModal