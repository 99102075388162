import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import APLabelWithText from "apex-web/lib/components/common/APLabelWithText/APLabelWithText";
import "apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetails.css";
import { formatNumberToLocale } from "apex-web/lib/helpers/numberFormatter";
import { connect } from "react-redux";
import { amountWithCurrency, formatCopCurrency, formatNumbersWithoutRound } from "../../../pages/utils";
import SendReceiveConfirmModalComponent from "../SendReceiveConfirmModal/SendReceiveConfirmModalComponent";
import SendReceiveTwoFactorAuthContainer from "../SendReceiveTwoFactorAuth/SendReceiveTwoFactorAuthContainer";
import isEmpty from "is-empty";

const bemClasses = getBEMClasses("sidepane-details");

function SidePaneDetailsComponent(props, context) {
  const {
    title,
    info = [],
    classModifiers,
    finalAmount,
    link,
    useExternalAddress,
    isSend,
    withdrawDetails,
    data,
    fee,
    balance,
    total,
    product,
    submitData,
    openConfirmModal,
    closeConfirmModal,
    hideFees,
    isCoink
  } = props;
  let infoHeader = "";
  const { ProductSymbol } = product
  const [items, setItems] = useState([]);
  const Amount2 = finalAmount
  const getItem  =(label, text) => ({label, text})
  
  const getValue = (value) => {
    const splittedValue = Number(String(value).split(" ")[0])
    if(isNaN(splittedValue)) return value
    return amountWithCurrency({
      amount: !isCoink ? splittedValue : formatCopCurrency(Number(splittedValue), product.DecimalPlaces).slice(2),
      product,
    })
  }
  const cleanPrices = (amount, steps) => {
    return Number(Number(amount).toFixed(steps));
  };
  const amount = useExternalAddress
  ? cleanPrices(Amount2, 8)
  : cleanPrices(total, 8);
  const amountFee = formatNumberToLocale(fee, ProductSymbol);
  const amountText = useExternalAddress
  ? context.t("Amount to receive")
    : context.t("Amount to Send");
  const remainingBalance = formatNumberToLocale(
    cleanPrices(formatNumbersWithoutRound(balance, 8) - amount, 8),
    ProductSymbol
  );
  const limitationsAmount =
    ProductSymbol === "BTC"
      ? "0.0001 BTC"
      : ProductSymbol === "USDC"
      ? "10 USDC"
      : "";
  const remainingBalanceBanexcoinTransfer = formatNumberToLocale(
    balance - Amount2,
    ProductSymbol
  );
  const handleItemsConstructor = () => {
    let items = [];
    if (isSend && useExternalAddress) {
      const handleRemainingBalance = () => {
        let result = "-";
        if (amount) {
          const newAmount = amount - amountFee;
          if (!balance || remainingBalance.includes("-") || newAmount <= 0) {
            result = (
              <span className="danger-message-v1">
                {context.t("Insufficient balance")}
              </span>
            );
          } else {
            result = `${remainingBalance} ${ProductSymbol}`;
          }
        }
        return result;
      };
      const handleAmountToReceive = () => {
        let result = "-";
        if (amount) {
          const newAmount = amount - amountFee;
          result = `${formatNumberToLocale(
            newAmount,
            ProductSymbol
          )} ${ProductSymbol}`;
          if (remainingBalance.includes("-") || newAmount <= 0) {
            result = (
              <span className="danger-message-v1">
                {context.t("Insufficient balance")}
              </span>
            );
          }
        }
        return result;
      };
      items.push(
        getItem(
          context.t("Current Balance in {ProductSymbol}", {
            ProductSymbol: ProductSymbol,
          }),
          balance
            ? getValue(formatNumberToLocale(balance, ProductSymbol))
            : "-"
        )
      );
      items.push(
        getItem(
          context.t("Total to withdraw"),
          amount && fee ? getValue(formatNumberToLocale(amount, ProductSymbol)) : "-"
        )
      );
      items.push(
        getItem(
          context.t("Fee"),
          amount && fee
            ? getValue(formatNumberToLocale(amountFee, ProductSymbol))
            : "-"
        )
      );
      items.push(getItem(amountText, getValue(handleAmountToReceive())));
      items.push(
        getItem(context.t("Remaining Balance"), getValue(handleRemainingBalance()))
      );
      items.push(
        getItem(
          context.t("Minimum withdrawal amount {amount}.", {
            amount: getValue(limitationsAmount),
          })
        )
      );
    }

    if (!isSend && !useExternalAddress) {
      items.push(
        getItem(
          this.context.t('Amount to Request'),
          data.Amount
            ? getValue(formatNumberToLocale(Amount2, ProductSymbol))
            : '-'
        )
      );
    }

    if (isSend && !useExternalAddress) {
      infoHeader = context.t("Note");
      items.push(
        getItem(
          context.t("Current Balance in {ProductSymbol}", {
            ProductSymbol: ProductSymbol,
          }),
          balance
            ? getValue(formatNumberToLocale(balance, ProductSymbol))
            : "-"
        )
      );
      items.push(
        getItem(
          amountText,
          Amount2
            ? getValue(formatNumberToLocale(Amount2, ProductSymbol))
            : "-"
        )
      );
      items.push(
        getItem(
          context.t("Remaining Balance"),
          Amount2 && balance
            ? getValue(remainingBalanceBanexcoinTransfer)
            : "-"
        )
      );
    }
    setItems(items);
  };
  useEffect(() => {
    handleItemsConstructor();
  }, [data, amount, fee]);

  const isHtmlString = typeof info === "string";
  return (
    <>
      <header className={bemClasses("header", classModifiers)}>{title}</header>
      <section className={bemClasses("body")}>
        {items &&
          items.map(({ label, text }, i) => {
            let content = text;
            if (i === 1 && text && text !== "-") {
              content = getValue(finalAmount)
            }
            return (
              <APLabelWithText
                key={label}
                label={label}
                text={content}
                customClass={bemClasses()}
              />
            );
          })}
      </section>
      {!items ||
        (items.length === 0 && <div className={bemClasses("divider")} />)}
      <section className={bemClasses("information", classModifiers)}>
        {infoHeader && (
          <div className={bemClasses("information-header")}>{infoHeader}</div>
        )}
        {info &&
          (isHtmlString ? (
            <div dangerouslySetInnerHTML={{ __html: info }} />
          ) : (
            info.map((item, index) => (
              <div
                className={bemClasses("information-text", classModifiers)}
                key={index}
              >
                {item}
              </div>
            ))
          ))}
        {link && <a href={link.url}> {context.t(link.text)} </a>}
      </section>
      <SendReceiveConfirmModalComponent
         isOpen= { openConfirmModal }
         useExternalAddress= { useExternalAddress }
         close = { closeConfirmModal }
         product= { product }
         isSend= { isSend }
         values= { {
          ...data,
          Amount: finalAmount,
         } }
         balance= { balance }
         total= { total }
         fee= { fee }
         onConfirm= {() => {
          submitData( {
            ...data,
            Amount: finalAmount,
           } );
         }}
         hideFees= { hideFees }
         isCoink = { isCoink }
      />
      <SendReceiveTwoFactorAuthContainer/>
    </>
  );
}

SidePaneDetailsComponent.defaultProps = {
  link: {
    text: "",
    url: "",
  },
};
SidePaneDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};
SidePaneDetailsComponent.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  info: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  infoHeader: PropTypes.string,
  link: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    withdrawDetails: state.withdrawDetails,
    isCoink: !isEmpty(state.user) &&!isEmpty(state.user.verificationLevelConfigs) ? [100,101].includes(state.user.verificationLevelConfigs.Level) : false,
  }
}

export default connect(mapStateToProps)(SidePaneDetailsComponent);
