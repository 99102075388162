import React from 'react';
import PropTypes from 'prop-types';
import APSelect from 'apex-web/lib/components/common/APSelect/APSelect';
import APDatePicker from 'apex-web/lib/components/common/APDatePicker/APDatePicker';
import APButton from 'apex-web/lib/components/common/APButton';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

var baseClasses = getBEMClasses('activity');

var RecentActivityHeaderFormComponent = function RecentActivityHeaderFormComponent(
  props,
  context
) {
  var resetActivePage = props.resetActivePage,
    setEndDate = props.setEndDate,
    clearFilters = props.clearFilters,
    endDate = props.endDate;

  return React.createElement(
    'form',
    { className: baseClasses('select-buttons') },
    React.createElement(APSelect, {
      name: 'type',
      customClass: baseClasses(),
      label: context.t('Activity type:'),
      options: [
        { value: 'all', label: context.t('All Activities') },
        { value: 'trades', label: context.t('Trades') },
        { value: 'withdraw', label: context.t('Withdrawals') },
        { value: 'deposit', label: context.t('Deposits') }
      ],
      onSelect: resetActivePage,
      showTriangles: true
    }),
    React.createElement(APDatePicker, {
      name: 'startDate',
      customClass: baseClasses('from'),
      customChange: function customChange(value) {
        resetActivePage();
        if (value && !endDate) {
          setEndDate(new Date());
        }
      },
      label: context.t('Date range:'),
      format: function format(value) {
        return value;
      },
      maxDate: new Date()
    }),
    React.createElement(APDatePicker, {
      name: 'endDate',
      customClass: baseClasses('to'),
      label: context.t('to'),
      format: function format(value) {
        return value;
      },
      customChange: resetActivePage,
      maxDate: new Date()
    }),
    React.createElement(
      APButton,
      {
        customClass: baseClasses(),
        classModifiers: 'clear-filters',
        type: 'button',
        onClick: clearFilters
      },
      context.t('Clear Filters')
    )
  );
};

RecentActivityHeaderFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default RecentActivityHeaderFormComponent;
