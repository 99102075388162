import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import empty from 'is-empty';
import {
  formatPercentageValue,
  formatNumberToLocale
} from 'apex-web/lib/helpers/numberFormatter';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faQuestionCircle,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';
library.add(faQuestionCircle, faCheckSquare, faChevronDown, faChevronUp);

const NavBarBeta = (props, context) => {
  let { subStatus, setDubStatus } = props;
  console.log('props', props);
  let navLinks = [
    {
      id: '1',
      title: 'Get started',
      link: '#',
      sub: [
        {
          title: 'About us',
          icon: 'question-circle',
          link: '',
          description: 'Get to know Banexcoin and its benefits.'
        },
        {
          title: 'Levels',
          icon: 'question-circle',
          link: '',
          description: 'Get to know Banexcoin and its benefits.'
        }
      ]
    },
    {
      id: '2',
      title: 'Developers',
      link: '#',
      sub: [
        {
          title: 'Documentation',
          icon: 'question-circle',
          link: '#',
          description: 'Get your user information and others actions.'
        },
        {
          title: 'Bug Bounty Program',
          icon: 'question-circle',
          link: '#',
          description: 'Get to know Banexcoin and its benefits.'
        }
      ]
    },
    {
      id: '3',
      title: 'Blog',
      link: '#'
    }
  ];

  const showSubLinks = id => {
    let setted = id === subStatus ? -1 : id;
    console.log('before', subStatus);
    setDubStatus(setted);
    console.log('after', subStatus);
  };

  const Navigator = navLinks.map((x, i) => {
    return (
      <React.Fragment key={x.id}>
        {!empty(x.sub) ? (
          <span
            to={x.link}
            className="bnx_navlink m-1"
            onClick={() => {
              showSubLinks(x.id, 1);
            }}>
            {context.t(x.title)}
            <FontAwesomeIcon
              className="mx-2"
              icon={subStatus === x.id ? 'chevron-up' : 'chevron-down'}
            />
          </span>
        ) : (
          <Link to={x.link} className="bnx_navlink m-1">
            {context.t(x.title)}
          </Link>
        )}
      </React.Fragment>
    );
  });

  const NavigatorSub = navLinks.map((x, i) => {
    let sublinks = [];
    for (let i in x.sub) {
      let s = x.sub[i];
      sublinks.push(
        <Link to={s.link} key={i} className={'card-link-container col-4 pr-0'}>
          <div className="card-link-container_body px-5 py-3 d-flex">
            <div className="image__container justify-content-center align-items-center d-flex">
              <FontAwesomeIcon icon={s.icon} />
            </div>
            <div className="card-link-body">
              <p className="paragraph-text paragraph-text--strong">
                {context.t(s.title)}
              </p>
              <p className="paragraph-text paragraph-text--small">
                {context.t(s.description)}
              </p>
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div
        key={x.id}
        className={
          'tab-pane fade container row' + (subStatus === x.id ? ' active' : '')
        }>
        {sublinks}
      </div>
    );
  });

  return (
    <nav
      id="block-mainnavigation"
      className={
        'menu row position-static clearfix align-items-center ' +
        (subStatus > -1 ? ' open' : '')
      }>
      <div className="tab-list_tabs nav nav-menu justify-content-center align-items-start">
        {Navigator}
      </div>
      <div className="tab-list__content tab-content pt-10 pb-12">
        <div className="container-hero">{NavigatorSub}</div>
      </div>
    </nav>
  );
};

NavBarBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  apexCore: {
    instrument: { instruments },
    level1
  }
}) => {
  return { instruments, level1 };
};

export default connect(mapStateToProps)(NavBarBeta);
