import React from 'react';
import KYCBaseContainer from '../KYC';
import KYC_IMLevelsContainer from './KYC_IMLevelsContainer';

var KYC_IMComponent = function KYC_IMComponent() {
  return React.createElement(KYCBaseContainer, {
    LevelsComponent: KYC_IMLevelsContainer
  });
};

export default KYC_IMComponent;
