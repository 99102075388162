import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import empty from 'is-empty';
import { toast } from 'react-toastify';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import NumberFormat from 'react-number-format';
import { getBEMClasses } from '../../../helpers/cssClassesHelper';
import { useForm } from '../../../hooks/formHooks';
import { countries } from '../../../config/staticCountry';
import Loading from '../../../components/Loading';
import {
  getLevelNormal,
  getLevelCorporateBeta,
  submitAddCreditCard
} from './../EasyBuyHooks';
import './../EasyBuyPage.css';

import CreditCardInput from './CreditCardInput';
import { cleanPhone } from '../../utils';

const easyBuyPageClasses = getBEMClasses('easybuy-page');

const mapStateToProps = ({
  user: { selectedAccountId, userInfo, accounts }
}) => {
  return { selectedAccountId, userInfo, accounts };
};

const EasyBuyPageAddCardBeta = (props, context) => {
  try {
    const { userInfo, selectedAccountId, accounts } = props;

    const [loading, setLoading] = useState(0);
    const [initialState, setInitialState] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    document.title = `${context.t('Easy Buy')} - ${context.t(
      'Add Card'
    )} - Banexcoin`;

    async function startData() {
      setLoading(1);
      let currentAccountInfo = accounts.find(
        a => a.AccountId === selectedAccountId
      );
      let metadata = {};

      if (currentAccountInfo.VerificationLevel >= 4) {
        let corporateLevel = await getLevelCorporateBeta(
          userInfo.UserId,
          selectedAccountId
        );
        if (!empty(corporateLevel.data.corporatedata)) {
          let { phone } = JSON.parse(
            corporateLevel.data.corporatedata.dataForm
          );
          metadata.phone = `${phone}`;
        } else {
          metadata.phone = '';
        }
      } else {
        let normalLevel = await getLevelNormal(
          userInfo.UserId,
          selectedAccountId
        );
        if (!empty(normalLevel.data.level_basic)) {
          let fullname = [
            normalLevel.data.level_basic.firstName,
            normalLevel.data.level_basic.middleName,
            normalLevel.data.level_basic.lastName,
            normalLevel.data.level_basic.secondLastName
          ];
          let notNullNames = fullname.filter(
            x => x !== null && x !== 'undefined'
          );
          let returnValue = notNullNames.join(' ');
          returnValue = returnValue.replace(/  +/g, ' ');

          metadata.phone = `${
            normalLevel.data.level_basic.banexcoinUserByBanexcoinUserId.phone
          }`;
          metadata.cardholder = `${returnValue}`;
        } else {
          metadata.phone = '+';
        }
      }
      let states = {
        validated: false,
        banexcoin_user_id: userInfo.UserId,
        banexcoin_user_name: userInfo.UserName,
        email: !empty(userInfo.Email) ? userInfo.Email : '',
        card_number: '',
        phone: metadata.phone,
        cardholder: metadata.cardholder
      };
      setInputs({
        ...inputs,
        ...states
      });
      setInitialState(states);
      setLoading(0);
    }

    useEffect(
      () => {
        if (!empty(userInfo) && !empty(accounts) && !empty(selectedAccountId)) {
          startData();
        } else {
          setLoading(1);
        }
      },
      [userInfo, accounts, selectedAccountId]
    );

    const onSubmitCallback = async (inputs, form) => {
      try {
        setLoading(1);
        if (empty(errors)) {
          let arryExp = inputs.expiration_date.split('/');
          let month = arryExp[0];
          let year = arryExp[1];
          let dataModel = {
            expMonth: Number(month),
            expYear: Number(year),
            cardData: {
              number: inputs.card_number,
              cvv: inputs.cvv_code
            },
            billingDetails: {
              name: inputs.cardholder,
              country: inputs.country,
              district: inputs.district,
              line1: inputs.address,
              line2: '',
              city: inputs.city,
              postalCode: inputs.zip_code
            },
            metadata: {
              email: inputs.email,
              phoneNumber: cleanPhone(inputs.phone),
              sessionId: `${inputs.banexcoin_user_id}-${Number(moment())}`,
              ipAddress: '172.33.222.1'
            }
          };

          let result = await submitAddCreditCard(
            userInfo.UserId,
            dataModel,
            selectedAccountId
          );

          if (result.data.error) {
            setErrorMessage(result.data.message);
            toast.warn(context.t(result.data.message), {
              position: toast.POSITION.TOP_CENTER
            });
          } else {
            setSuccessMessage(context.t('Information saved successfully'));
            setErrorMessage('');
            setInputs({
              ...initialState
            });
            form.reset();
            toast.success(context.t('Information saved successfully'), {
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
      } catch (error) {
        console.error('error on add card:::', error.response.data);
        if (error.hasOwnProperty('data') && error.data.message) {
          setErrorMessage(error.data.message);
        } else {
          if (!empty(error.response.data)) {
            setErrorMessage(error.response.data.data.message);
          }
        }

        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const customValidations = inputs => {
      let errors = {};

      if (
        empty(
          inputs.hasOwnProperty('cardholder') ? inputs.cardholder.trim() : ''
        )
      ) {
        errors.cardholder = context.t('You must enter the {name} field', {
          name: context.t('Cardholder')
        });
      }
      if (
        empty(
          inputs.hasOwnProperty('card_number') ? inputs.card_number.trim() : ''
        )
      ) {
        errors.card_number = context.t('You must enter the {name} field', {
          name: context.t('Card number')
        });
      } else {
        if (inputs.card_number.length < 16) {
          errors.card_number = context.t('Invalid credit card number length');
        }
      }
      if (
        empty(
          inputs.hasOwnProperty('expiration_date')
            ? inputs.expiration_date.trim()
            : ''
        )
      ) {
        errors.expiration_date = context.t('You must enter the {name} field', {
          name: context.t('Expiration date')
        });
      } else {
        let arr = inputs.expiration_date.split('/');
        if (!isNaN(arr[0]) && !isNaN(arr[1])) {
          let month = arr[0].match(/\d+/)[0];
          let year = arr[1].match(/\d+/)[0];
          if (month.length === 2 && year.length === 4) {
            if (Number(month) > 12) {
              errors.expiration_date = context.t(
                'You must enter a valid expiration month'
              );
            }
            if (Number(year) < Number(moment().format('YYYY'))) {
              errors.expiration_date = context.t(
                'You must enter a valid expiration year'
              );
            }
            let insertedYear = year;
            let insertedMonth = month.toString().padStart(2, '0');
            let timestampInserted = Number(
              moment(`${insertedYear}-${insertedMonth}-01 23:59:59`)
            );
            let currentYear = moment().year();
            let currentMonth = (moment().month() + 1)
              .toString()
              .padStart(2, '0');
            let currentTime = Number(
              moment(`${currentYear}-${currentMonth}-01 23:59:59`)
            );
            if (timestampInserted < currentTime) {
              errors.expiration_date = context.t(
                'The expiration date is invalid'
              );
            }
          } else {
            errors.expiration_date = context.t(
              'You must enter the {name} field',
              { name: context.t('Expiration date') }
            );
          }
        } else {
          errors.expiration_date = context.t(
            'You must enter the {name} field',
            { name: context.t('Expiration date') }
          );
        }
      }
      if (
        empty(inputs.hasOwnProperty('cvv_code') ? inputs.cvv_code.trim() : '')
      ) {
        errors.cvv_code = context.t('You must enter the {name} field', {
          name: context.t('Security code')
        });
      }
      if (
        empty(inputs.hasOwnProperty('address') ? inputs.address.trim() : '')
      ) {
        errors.address = context.t('You must enter the {name} field', {
          name: context.t('Address')
        });
      }
      if (
        empty(inputs.hasOwnProperty('country') ? inputs.country.trim() : '')
      ) {
        errors.country = context.t('You must enter the {name} field', {
          name: context.t('Country')
        });
      }
      if (empty(inputs.hasOwnProperty('city') ? inputs.city.trim() : '')) {
        errors.city = context.t('You must enter the {name} field', {
          name: context.t('City')
        });
      }
      if (
        empty(inputs.hasOwnProperty('district') ? inputs.district.trim() : '')
      ) {
        errors.district = context.t('You must enter the {name} field', {
          name: context.t('District')
        });
      }
      if (
        empty(inputs.hasOwnProperty('zip_code') ? inputs.zip_code.trim() : '')
      ) {
        errors.zip_code = context.t('You must enter the {name} field', {
          name: context.t('Zip code')
        });
      }

      setErrors(errors);
      return errors;
    };

    let {
      inputs,
      errors,
      setErrors,
      setInputs,
      onInputChange,
      onSubmit,
      onSubmitSimple,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    const onchangeData = data => {
      setInputs(imagesBlob => ({
        ...imagesBlob,
        card_number: data.credit_card_number
      }));
    };

    const onChangeExp = e => {
      e.persist();
      let errors = {};
      let date = e.target.value;
      let arr = date.split('/');
      if (!isNaN(arr[0]) && !isNaN(arr[1])) {
        let month = arr[0].match(/\d+/)[0];
        let year = arr[1].match(/\d+/)[0];
        if (month.length === 2 && year.length === 4) {
          if (Number(month) > 12) {
            errors.expiration_date = context.t(
              'You must enter a valid expiration month'
            );
          }
          if (Number(year) < Number(moment().format('YYYY'))) {
            errors.expiration_date = context.t(
              'You must enter a valid expiration year'
            );
          }
          let insertedYear = year;
          let insertedMonth = month.toString().padStart(2, '0');
          let timestampInserted = Number(
            moment(`${insertedYear}-${insertedMonth}-01 23:59:59`)
          );
          let currentYear = moment().year();
          let currentMonth = (moment().month() + 1).toString().padStart(2, '0');
          let currentTime = Number(
            moment(`${currentYear}-${currentMonth}-01 23:59:59`)
          );
          if (timestampInserted < currentTime) {
            errors.expiration_date = context.t(
              'The expiration date is invalid'
            );
          }
        } else {
          errors.expiration_date = context.t(
            'You must enter the {name} field',
            { name: context.t('Expiration date') }
          );
        }
      }
      setErrors(errors);
    };

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <div className="container credit-card-buy">
          <Row className="justify-content-md-center">
            <Col lg={8}>
              <Row className="col-fix add-card">
                <h2 className="card-title">
                  {context.t('Add new credit card')}
                </h2>
                <Form
                  noValidate
                  validated={inputs.validated}
                  encType="multipart/form-data"
                  onSubmit={onSubmitSimple}>
                  <Col lg={6}>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="cardholder">
                        <Form.Label>{context.t('Cardholder')}</Form.Label>
                        <Form.Control
                          required
                          name="cardholder"
                          type="text"
                          placeholder={context.t('Cardholder')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.cardholder) ? true : false}
                          isValid={empty(errors.cardholder) ? true : false}
                          onChange={onInputChange}
                          value={
                            !empty(inputs.cardholder) ? inputs.cardholder : ''
                          }
                          readOnly={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cardholder}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="ccard_number">
                        <Form.Label>{context.t('Card number')}</Form.Label>
                        <CreditCardInput
                          required
                          name="card_number"
                          type="text"
                          placeholder={context.t('Card number')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.card_number) ? true : false}
                          isValid={empty(errors.card_number) ? true : false}
                          onChange={onchangeData}
                          value={inputs.card_number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.card_number}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="6" controlId="expiration_date">
                        <Form.Label>{context.t('Expiration date')}</Form.Label>
                        <NumberFormat
                          required
                          customInput={FormControl}
                          name="expiration_date"
                          type="text"
                          className="form-control"
                          isInvalid={
                            !empty(errors.expiration_date) ? true : false
                          }
                          isValid={empty(errors.expiration_date) ? true : false}
                          onChange={e => {
                            onInputChange(e);
                            onChangeExp(e);
                          }}
                          format="##/####"
                          placeholder="MM/YYYY"
                          mask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                          value={
                            !empty(inputs.expiration_date)
                              ? inputs.expiration_date
                              : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.expiration_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="cvv_code">
                        <Form.Label>{context.t('Security code')}</Form.Label>
                        <Form.Control
                          required
                          name="cvv_code"
                          type="password"
                          placeholder={context.t('CVV')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.cvv_code) ? true : false}
                          isValid={empty(errors.cvv_code) ? true : false}
                          onChange={onInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cvv_code}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <Col lg={6}>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="address">
                        <Form.Label>{context.t('Address')}</Form.Label>
                        <Form.Control
                          required
                          name="address"
                          type="text"
                          placeholder={context.t('Address')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.address) ? true : false}
                          isValid={empty(errors.address) ? true : false}
                          onChange={onInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="6" controlId="country">
                        <Form.Label>{context.t('Country')}</Form.Label>
                        <Form.Control
                          as="select"
                          name="country"
                          isInvalid={!empty(errors.country) ? true : false}
                          isValid={empty(errors.country) ? true : false}
                          onChange={onInputChange}>
                          <option value="">{context.t('Select')}</option>
                          {countries.map((d, i) => (
                            <option key={i} value={d.abbreviation}>
                              {d.country}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.country}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="city">
                        <Form.Label>{context.t('City')}</Form.Label>
                        <Form.Control
                          required
                          name="city"
                          type="text"
                          placeholder={context.t('City')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.city) ? true : false}
                          isValid={empty(errors.city) ? true : false}
                          onChange={onInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="6" controlId="district">
                        <Form.Label>{context.t('District')}</Form.Label>
                        <Form.Control
                          required
                          name="district"
                          type="text"
                          placeholder={context.t('District')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.district) ? true : false}
                          isValid={empty(errors.district) ? true : false}
                          onChange={onInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.district}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="zip_code">
                        <Form.Label>{context.t('Zip code')}</Form.Label>
                        <Form.Control
                          required
                          name="zip_code"
                          type="text"
                          placeholder={context.t('Zip code')}
                          autoComplete={'off'}
                          isInvalid={!empty(errors.zip_code) ? true : false}
                          isValid={empty(errors.zip_code) ? true : false}
                          onChange={onInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.zip_code}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <Alert
                    variant="danger"
                    className={
                      'credit-error ' + (!empty(errorMessage) ? 'show-msg' : '')
                    }
                    onClose={() => setErrorMessage('')}
                    dismissible>
                    <Alert.Heading>
                      {context.t(
                        'There was an error in submitting your information. Please try again.'
                      )}
                    </Alert.Heading>
                    <p>
                      {`${context.t('Reason')}: ${context.t(errorMessage)}`}
                    </p>
                  </Alert>
                  <Alert
                    variant="success"
                    className={
                      'credit-success ' +
                      (!empty(successMessage) ? 'show-msg' : '')
                    }
                    onClose={() => setSuccessMessage('')}
                    dismissible>
                    <Alert.Heading>
                      {context.t(
                        'Your credit card is being processed. You can see the status of your card in'
                      )}
                      <Link to="/easy-buy/my-credit-cards">
                        {context.t('My Credit Cards')}
                      </Link>
                    </Alert.Heading>
                  </Alert>
                  <div className="card-lefted-footer">
                    <Button type="submit" variant="banexcoin">
                      {context.t('Save')}
                    </Button>
                    <Link to="/easy-buy" className="btn btn-secondary">
                      {context.t('Cancel')}
                    </Link>
                  </div>
                </Form>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.error('Error on EasyBuyAddCard', e);
    return <div>Error:</div>;
  }
};

EasyBuyPageAddCardBeta.contextTypes = {
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(EasyBuyPageAddCardBeta);
