import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import {
  getFiatAccounts,
  getBankCountries
} from '../components/AccountComponents/AccountHooks';
import { toast } from 'react-toastify';
import empty from 'is-empty';
import Loading from '../components/Loading';

import './AccountPage.css';
import AccountListContainer from './AccountV2/AccountListContainer';

const accountPageClasses = getBEMClasses('account-page');

const AccountPage = (props, context) => {
  let { userInfo } = props;
  if (empty(userInfo)) {
    return (
      <React.Fragment>
        <div>Loading..</div>
      </React.Fragment>
    );
  }
  const [loading, setLoading] = useState(0);

  document.title = context.t('My Banking Accounts') + ' - Banexcoin';

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className={accountPageClasses() + ' minhfix'}>
        <AccountListContainer setLoading={setLoading} loading={loading} />
      </div>
    </React.Fragment>
  );
};

AccountPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { userInfo } }) => {
  return { userInfo };
};

export default connect(mapStateToProps)(AccountPage);
