import React from 'react';
import PropTypes from 'prop-types';
import APTable from 'apex-web/lib/components/common/APTable';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { getOrderPriceToDisplay } from 'apex-web/lib/helpers/orderHistoryHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';
import FiltersContainer from '../Filters/FiltersContainer';
const baseClass = getBEMClasses('order-history-table');

const OpenOrdersComponent = (
  { data, instruments, cancelOrders, fetching, config, selectedInstrument },
  context
) => {
  const { maxLines, usePagination } = config;

  const columns = [
    {
      header: context.t('Pair'),
      dataTest: 'Pair',
      cell: (row, { selectedInstrument }) =>
        selectedInstrument ? selectedInstrument.Symbol : ''
    },
    {
      header: context.t('Side'),
      dataTest: 'Side',
      cell: row => (
        <div className={baseClass(row.Side === 'Buy' ? 'buy' : 'sell')}>
          {row.Side}
        </div>
      )
    },
    {
      header: context.t('Type'),
      dataTest: 'Type',
      cell: row => (row.IsQuote ? context.t('Quote') : row.OrderType)
    },
    {
      header: context.t('Size'),
      dataTest: 'Size',
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass('size')}>
          {row.quantity &&
            formatNumberToLocale(
              row.quantity,
              convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              )
            )}
        </div>
      )
    },
    {
      header: context.t('Price'),
      dataTest: 'Price',
      cell: (row, { selectedInstrument }) => (
        <div className={baseClass('price')}>
          {getOrderPriceToDisplay(
            row,
            convertIncrementToIntDecimalPlaces(
              selectedInstrument.PriceIncrement
            )
          )}
        </div>
      )
    },
    {
      header: context.t('Date/Time'),
      dataTest: 'Date/Time',
      cell: row => formatDateTime(row.ReceiveTime)
    },
    {
      header: context.t('Status'),
      dataTest: 'Status',
      cell: row => row.OrderState
    },
    {
      header: context.t('Action'),
      dataTest: 'Action',
      cell: function cell(row) {
        return React.createElement(CancelAPButton, {
          text: context.t('Cancel'),
          customClass: baseClass(),
          dataTest: 'Cancel',
          onClick: function onClick() {
            cancelOrders([row.OrderId]);
          }
        });
      }
    }
  ];

  const rowProps = row => ({
    'data-tip': true,
    'data-for': `orderInfo-${row.OrderId}`,
    selectedInstrument:
      selectedInstrument ||
      instruments.find(item => item.InstrumentId === row.Instrument) ||
      {}
  });

  return (
    <div>
      <APTable
        {...{
          columns,
          rows: data,
          baseClass,
          fetching,
          rowProps,
          pageSize: maxLines,
          usePagination,
          empty: <span className='empty-orders'>{context.t("You have no open orders.")}</span>,
          componentName: 'OpenOrdersComponent'
        }}
      />
    </div>
  );
};

/*
 this.context.t('Open Orders');
 */
OpenOrdersComponent.title = 'Open Orders';

OpenOrdersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  cancelOrders: PropTypes.func.isRequired
};

OpenOrdersComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default OpenOrdersComponent;
