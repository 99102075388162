import HandlerBackend from "../../../helpers/HandlerBackend";

class BanexcardService{
  static getBanexcardBalance = async ({userInfo}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/balance/${userInfo.AccountId}`
    })
    return response.data;
  }
  static getBanexcardTransactions = async ({userInfo, limit}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/transactions/${userInfo.AccountId}?limit=${limit}`
    })
    return response.data;
  }
  static getBanexcardConfiguration = async ({userInfo}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/card/config/${userInfo.AccountId}`
    })
    return response.data;
  }
  static postBanexcardConfiguration = async ({userInfo, body}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/config/${userInfo.AccountId}`,
      data: body
    })
    return response.data;
  }
  static postActivationCodeToAssociation = async ({userInfo, body}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/associate/${userInfo.AccountId}`,
      data: body
    })
    return response.data;
  }
  static postPinConfiguration = async ({userInfo, body}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/pin/${userInfo.AccountId}`,
      data: body
    })
    return response.data;
  }
  static postRequestPhysicalCard = async ({userInfo, body}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/physical/request/${userInfo.AccountId}`,
      data: body
    })
    return response.data;
  }
  static getCalculateFee = async ({userInfo, data}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/card/recharge/fee/${data.amount}/${data.currency}`
    })
    return response.data;
  }
  static postRechargeCard = async ({userInfo, body}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/recharge/${userInfo.AccountId}`,
      data: body
    })
    return response.data;
  }
  static getPhysicalCardRequestById = async ({userInfo, requestId}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "POST",
      path: `/api/b89/card/physical/request/${requestId}`
    })
    return response.data;
  }
  static getPhysicalCardLocations = async ({userInfo, requestId}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/card/physical/locations`
    })
    return response.data;
  }
  static getRequestPhysicalCardById = async ({userInfo, requestId}) => {
    const response = await HandlerBackend.sendRequest({
      userId: userInfo.UserId,
      method: "GET",
      path: `/api/b89/card/physical/request/${requestId}`
    })
    return response.data;
  }
}

export default BanexcardService;