const initialState = {
    'loadingBanexCard': true,
    'banexCardOptionIsVisible': false,
    'hasBanexCard': false,
    'userLevel': 0,
    'balanceData': {},
    config: []
}

const banexCard = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_BANEXCARD':
            return { ...action.payload };
        case 'UPDATE_BANEXCARD':
            return { ...state, ...action.payload };
        case 'SET_BALANCE_DATA':
            return { ...state, balanceData: action.payload };
        case 'SET_BANEXCARD_CONFIG':
            return { ...state, config: action.payload };
        case 'RESET_BANEXCARD':
            return initialState;
        default:
            return state;
    }
};

export default banexCard;