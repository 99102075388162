import React, { useEffect } from "react";

function Countdown({ initialValue }) {
  const [value, setValue] = React.useState(initialValue);

  const convertNumberToMinutes = (number) => {
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}
  let interval = null;
  useEffect(() => {
    interval = setInterval(() => {
      setValue((prevTimer) => {
        if (prevTimer === 0) {
          return initialValue;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <p>{convertNumberToMinutes(value)}</p>;
}

export default Countdown;
