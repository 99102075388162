import { connect } from 'react-redux';
import FilledOrdersComponent from './FilledOrdersComponent';
import { filledOrdersSelectorFactory } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { instrumentSelectorInstruments } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { cancelOrders } from 'apex-web/lib/redux/actions/orderHistoryActions';

const mapStateToProps = () => {
  const filledOrdersSelector = filledOrdersSelectorFactory();

  return (state, { config, selectedInstrument }) => {
    const filledOrders = filledOrdersSelector(state, config);
    const instruments = instrumentSelectorInstruments(state);
    const { activeRequests } = state.apexCore.orderHistory;
    const fetching = activeRequests > 0;

    return {
      data: filledOrders,
      instruments,
      fetching,
      selectedInstrument
    };
  };
};

const mapDispatchToProps = {
  cancelOrders
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilledOrdersComponent);
