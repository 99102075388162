import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import { formatNumbersWithoutRound } from './../../../pages/utils'
import { connect } from 'react-redux';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var SendReceiveDetailsComponent = (function(_React$Component) {
  _inherits(SendReceiveDetailsComponent, _React$Component);

  function SendReceiveDetailsComponent() {
    _classCallCheck(this, SendReceiveDetailsComponent);

    return _possibleConstructorReturn(
      this,
      (
        SendReceiveDetailsComponent.__proto__ ||
        Object.getPrototypeOf(SendReceiveDetailsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(SendReceiveDetailsComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          isSend = _props.isSend,
          useExternalAddress = _props.useExternalAddress,
          total = _props.total,
          balance = _props.balance,
          fee = _props.fee,
          Amount = isSend ? _props.withdrawDetails.amount : _props.details.Amount,
          ProductSymbol = _props.product.ProductSymbol;

        var getItem = SidePaneDetailsComponent.getItem;

        var title = isSend
          ? this.context.t('')
          : this.context.t('Instructions');
        var amountText = useExternalAddress
          ? this.context.t('Amount to receive')
          : this.context.t('Amount to Send');
        var feeText = useExternalAddress
          ? this.context.t('Fee')
          : this.context.t('Fee');

        const cleanPrices = (amount, steps) => {
          return Number(Number(amount).toFixed(steps));
        };

        var amount = useExternalAddress
          ? cleanPrices(Amount, 8)
          : cleanPrices(total, 8);
        var totalAmountBalance = formatNumberToLocale(
          amount,
          ProductSymbol
        );
        var amountFee = formatNumberToLocale(fee, ProductSymbol);
        var remainingBalance = formatNumberToLocale(
          cleanPrices(
            formatNumbersWithoutRound(balance, 8) - (amount),
            8
          ),
          ProductSymbol
        );
        var remainingBalanceBanexcoinTransfer = formatNumberToLocale(
          balance - Amount,
          ProductSymbol
        );

        var classModifiers = isSend ? 'top' : '';

        let limitationsAmount =
          ProductSymbol === 'BTC'
            ? '0.0001 BTC'
            : ProductSymbol === 'USDC'
              ? '10 USDC'
              : '';

        var items = [];
        var info = [];
        var infoHeader = '';
        var infoSpan = (
          <span className="font-info">
            {this.context.t('without deposit fees.')}
          </span>
        );

        if (isSend && useExternalAddress) {
          const handleRemainingBalance = () => {
            let result = '-';
            if (amount) {
              if (!balance || remainingBalance.includes('-')) {
                result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span>
              } else {
                result = `${remainingBalance} ${ProductSymbol}`;
              }
            }
            return result;
          }
          const handleAmountToReceive = () => {
            let result = '-';
            if (amount) {
              result = `${formatNumberToLocale(amount - amountFee, ProductSymbol)} ${ProductSymbol}`;
              if (remainingBalance.includes('-')) {
                result = <span className='danger-message-v1'>{this.context.t('Insufficient balance')}</span> 
              }
            }
            return result;
          }
          items.push(
            getItem(
              this.context.t('Current Balance in {ProductSymbol}', {
                ProductSymbol: ProductSymbol
              }),
              balance
                ? formatNumberToLocale(balance, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              this.context.t('Total to withdraw'),
              amount && fee ? totalAmountBalance + ' ' + ProductSymbol : '-'
            )
          );
          items.push(
            getItem(
              feeText,
              amount && fee
                ? formatNumberToLocale(amountFee, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              amountText,
              handleAmountToReceive()
            )
          );
          items.push(
            getItem(
              this.context.t('Remaining Balance'),
              handleRemainingBalance()
            )
          );
          items.push(
            getItem(
              this.context.t('Minimum withdrawal amount {amount}.', {
                amount: limitationsAmount
              })
            )
          );
        }

        if (isSend && !useExternalAddress) {
          infoHeader = this.context.t('Note');
          items.push(
            getItem(
              this.context.t('Current Balance in {ProductSymbol}', {
                ProductSymbol: ProductSymbol
              }),
              balance
                ? formatNumberToLocale(balance, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              amountText,
              Amount
                ? formatNumberToLocale(Amount, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              this.context.t('Remaining Balance'),
              Amount && balance
                ? remainingBalanceBanexcoinTransfer + ' ' + ProductSymbol
                : '-'
            )
          );
          info.push(
            this.context.t(
              'If the sender is not a registered user of Retail, they will receive an email invitation to create an account and claim their funds.'
            )
          );
        }

        if (!isSend && !useExternalAddress) {
          items.push(
            getItem(
              this.context.t('Amount to Request'),
              Amount
                ? formatNumberToLocale(Amount, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          info.push(
            <p className="b-transf">
              {this.context.t('Use this form to request funds via email.')}
            </p>
          );
          info.push(
            <p className="p-transf">
              {this.context.t(
                'Note - if the requestee is not a registered user of Retail, they will receive an email invitation to create an account.'
              )}
            </p>
          );
        }
        if (!isSend && useExternalAddress) {
          info.push(
            <p className="e-wall">
              {this.context.t(
                'You can always use the address shown to deposit {product} into your Banexcoin Account {span}',
                {
                  product: ProductSymbol,
                  span: infoSpan
                }
              )}
            </p>
          );
          info.push(
            this.context.t(
              'You will be able to have the funds deposited automatically when the corresponding network confirms your transaction {time} times. Confirmation can take up to {minutes} minutes or even longer, this time is NOT dependent on Banexcoin.',
              {
                time: ProductSymbol === 'BTC' ? 3 : 20,
                minutes: ProductSymbol === 'BTC' ? 30 : 5
              }
            )
          );
        }

        return React.createElement(SidePaneDetailsComponent, {
          title: title,
          items: [],
          info: info,
          infoHeader: infoHeader,
          classModifiers:
            classModifiers + (useExternalAddress ? ' text-external-box' : '')
        });
      }
    }
  ]);

  return SendReceiveDetailsComponent;
})(React.Component);

SendReceiveDetailsComponent.propTypes = {
  isSend: PropTypes.bool,
  useExternalAddress: PropTypes.bool,
  details: PropTypes.object,
  fee: PropTypes.number,
  total: PropTypes.number
};

SendReceiveDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

SendReceiveDetailsComponent.mapStateToProps = function(state) {
  return {
    withdrawDetails: state.withdrawDetails,
  }
}

export default connect(SendReceiveDetailsComponent.mapStateToProps)(SendReceiveDetailsComponent);
