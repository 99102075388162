import empty from 'is-empty';
import HandlerBackend from '../../helpers/HandlerBackend';

export const getAllDeposits = (status, userId) => {
  let headers = {
    'Content-Type': 'application/json'
  };

  let path = `/api/v1/deposit/${status}`;

  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'get',
    headers,
    path
  });
};

export const submitDepositAffidavit = (data, userId) => {
  let headers = {};
  let path = `/api/v1/deposit`;
  let model = {
    id: data.id,
    affidavit_source_funds: !empty(data.affidavit_source_funds)
      ? data.affidavit_source_funds
      : '',
    affidavit_source_funds_others: !empty(data.affidavit_source_funds_others)
      ? data.affidavit_source_funds_others
      : ''
  };
  return HandlerBackend.sendRequest({
    userId: userId,
    method: 'post',
    headers,
    path,
    data: model
  });
};
