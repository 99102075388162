import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import '../UserSettingsPage.css';
import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';
import isEmpty from 'is-empty';
import UserAccountController from '../../components/DeleteAccount/Controller/UserAccountController';
import APSwitch from '../../components/common/APSwitch/APSwitch';
import { showSnack as _showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { Spinner } from '../../components/Spinner/Spinner';

const securityPageClasses = getBEMClasses('user-settings-page');

const SecurityPage = (props, context) => {

  const { UserId, level, showSnack } = props;

  const languageSelected = localStorage.getItem('language');
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [listDeleteReasons, setListDeleteReasons] = useState([]);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [initialData, setinitialData] = useState({});
  const [dataActive, setDataActive] = useState({
    emailProcessingData: false,
    smsProcessingData: false
  });
  const [loading, setLoading] = useState(true);

  const listAccountDeleteReasons = () => {
    UserAccountController.loadAccountDeleteReasons({
      UserId,
      languageSelected,
      setListDeleteReasons
    })
  }

  const commercialCampaigns = () => {
    UserAccountController.loadCommercialCampaigns({
      UserId,
      setDataActive,
      setinitialData,
      setLoading
    })
  }

  const updateCommercialCampaigns = async () => {
    const data = {}
    Object.keys(dataActive).forEach(key => {
      if (dataActive[key] !== initialData[key]) {
        data[key] = dataActive[key]
      }
    })
    await UserAccountController.updateCommercialCampaigns({
      UserId,
      data,
      setinitialData,
      errorModal,
      successModal
    })
  }

  const deleteUserAccount = (data) => {
    UserAccountController.deleteAccount({
      UserId,
      info: data,
      setMessageSuccess,
      context
    })
  }

  const goBack = () => {
    setShowDeleteAccount(false);
    setMessageSuccess(false);
  }

  const handleChangeData = (name, value) => {
    setDataActive({
      ...dataActive,
      [name]: value
    })
  }

  const validateChanges = () => (dataActive.emailProcessingData !== initialData.emailProcessingData || dataActive.smsProcessingData !== initialData.smsProcessingData)
  const dataChange = validateChanges();

  const showDataTreatmentModal = () => {
    showSnack({
      id: 'treatment_data',
      text: context.t("Are you sure about updating your data processing settings (campaigns) in Banexcoin?. Keep in mind that you can only perform this action once a day."),
      type: 'pending',
      buttons: [
        {
          text: context.t('Cancel'),
          className: 'button_V2 grey'
        },
        {
          text: context.t('Yes'),
          className: 'button_V2 purple',
          loadingText: true,
          onClick: () => updateCommercialCampaigns()
        }
      ]
    });
  }

  const successModal = () => {
    showSnack({
      id: 'treatment_data2',
      text: context.t("The changes have been successfully applied"),
      type: 'success'
    })
  }

  const errorModal = () => {
    showSnack({
      id: 'treatment_data1',
      text: context.t("Recently, you have updated your data processing settings (campaigns) in Banexcoin. Please note that this action can only be performed once per day."),
      type: 'warning',
      buttons: [
        {
          text: context.t('Got it'),
          className: 'button_V2 purple'
        }
      ]
    })
    setDataActive({
      emailProcessingData: initialData.emailProcessingData,
      smsProcessingData: initialData.smsProcessingData
    })
  }

  const validateLevel = () => (![100, 101].includes(level))

  useEffect(() => {
    if (!isEmpty(UserId)) {
      listAccountDeleteReasons();
    }
  }, [UserId, languageSelected])

  useEffect(() => {
    if (!isEmpty(UserId)) {
      commercialCampaigns();
    }
  }, [UserId])

  return (
    <React.Fragment>
      <div className={securityPageClasses('maincontainer security')}>
        {showDeleteAccount ?
          <DeleteAccount
            list={listDeleteReasons}
            deleteUserAccount={deleteUserAccount}
            messageSuccess={messageSuccess}
            goBack={goBack} /> :
          <div className={securityPageClasses('container2 security')}>
            {validateLevel() &&
              <div>
                <div className='container-delete-account'>
                  <h2>{context.t('Data treatment')}</h2>
                  <p>{context.t('About the campaigns in Banexcoin')}:</p>
                  <div className='content-security-switch'>
                    {loading ?
                      <div className='content-loading-data-treatment'><Spinner color='orange' /></div> :
                      <React.Fragment>
                        <div className='container-switch'>
                          <p>{context.t('Text message')}</p>
                          <div className='settings-enable-two-factor-auth'>
                            <div className='settings-enable-two-factor-auth__switch'>
                              <APSwitch
                                input={{ value: dataActive.smsProcessingData }}
                                onClick={(value) => handleChangeData('smsProcessingData', !value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='container-switch'>
                          <p>{context.t('Email')}</p>
                          <div className='settings-enable-two-factor-auth'>
                            <div className='settings-enable-two-factor-auth__switch'>
                              <APSwitch
                                input={{ value: dataActive.emailProcessingData }}
                                onClick={(value) => handleChangeData('emailProcessingData', !value)}
                              />
                            </div>
                          </div>
                        </div>
                        <button disabled={!dataChange} onClick={() => dataChange && showDataTreatmentModal()}>{context.t('Save changes')}</button>
                      </React.Fragment>
                    }
                  </div>
                </div>
              </div>
            }
            <div id={'2FABox'}>
              <div className='container-delete-account'>
                <h2>{context.t('Delete my account')}</h2>
                <p>{context.t('Choose the reasons why you leave us')}{` :(`}</p>
                <button onClick={() => setShowDeleteAccount(true)}>{context.t('Delete account')}</button>
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

SecurityPage.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = (props) => {
  const UserId = props.user.userInfo.UserId;
  const level = props.user.accounts[0] ? props.user.accounts[0].VerificationLevel : 0;
  return { UserId, level };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showSnack: function showSnack(text) {
      return dispatch(_showSnack(text));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage);
