import { connect } from 'react-redux';
import ConfirmBlockTradeComponent from './ConfirmBlockTradeModalComponent';
import modalDecorator from 'apex-web/lib/hocs/modalDecorator';
import { MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import {
  placeOrder,
  clearOrderForm
} from 'apex-web/lib/redux/actions/orderEntryActions';
import { orderTotalSelector } from 'apex-web/lib/redux/selectors/orderTotalSelector';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import { orderFormTypes } from 'apex-web/lib/constants/sendOrder/orderFormTypes';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

const mapStateToProps = state => {
  const {
    form,
    apexCore: { product }
  } = state;

  const selectedInstrument = selectedInstrumentSelector(state);
  const productId =
    form.reportBlockTrade.values.fee.ProductId ||
    form.reportBlockTrade.values.fee.FeeProductId;
  const feeSymbol = productId
    ? product.products.find(item => item.ProductId === productId).Product
    : '';

  const orderTotal = form.reportBlockTrade
    ? orderTotalSelector(state, {
        form: form.reportBlockTrade.values
      })
    : 0;

  return {
    formObj: form.reportBlockTrade || { values: {} },
    selectedInstrument,
    feeSymbol,
    orderTotal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmReport: form =>
      dispatch(placeOrder(orderFormTypes.block, form)).then(result => {
        if (result.status === 'Accepted') {
          dispatch(clearOrderForm('reportBlockTrade'));
        }

        if (result.result === false) {
          dispatch(
            showSnack({
              id: 'confirmBlockTradeFail',
              text: result.detail,
              type: 'warning'
            })
          );
        }
      })
  };
};

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmBlockTradeComponent);

export default modalDecorator({
  name: MODAL_TYPES.CONFIRM_BLOCK_REPORT
})(container);
