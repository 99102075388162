import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import { Button, Form, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
// import { DatePickerInput } from 'rc-datepicker';
// import moment from 'moment';
import empty from 'is-empty';
import { useForm } from '../../hooks/formHooks';
import {
  submitFormData
  // getLevel
} from './CorporateLevelPageAddCorporateShareholderHooks';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import Countries from '../../components/Countries';
import {
  // getCorporateData,
  // enableFiatAccount,
  getCountries
} from './PeopleHooks';
//CSS
import './IntermediateLevelPage.css';

const RobotSvg = require('../../images/robot.svg');
const SharePercentage = require('../../images/porcentaje-share.png');
const intermediateLevelPageClasses = getBEMClasses('intermediatelevel-page');

const CorporateInstitutionForm = ({ userInfo }, context) => {
  try {
    if (empty(userInfo)) {
      return (
        <React.Fragment>
          <div>Loading..</div>
        </React.Fragment>
      );
    }

    const [loading, setLoading] = useState(0);
    const [level, setLevel] = useState({});
    const [countries, setCountries] = useState({});
    const [showed, setShowed] = useState([true, false, false]);

    const markStep = (steped, variable) => {
      var format = [false, false, false];
      format[steped] = variable;
      console.log('get format', format);
      setShowed(format);
    };

    const getListCountries = async () => {
      setLoading(1);
      try {
        let result = await getCountries(userInfo.UserId);
        setCountries(result.data.countries);
      } catch (error) {
        toast.warn(context.t('Information could not accesible'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    useEffect(() => {
      if (!empty(userInfo)) {
        getListCountries();
      }
    }, []);

    useEffect(() => {
      async function loadLevel() {
        setLoading(1);
        try {
        } catch (error) {
          toast.warn(context.t('Information could not accesible'), {
            position: toast.POSITION.TOP_CENTER
          });
        }

        setLoading(0);
      }

      if (!empty(userInfo)) {
        loadLevel();
      }
    }, []);

    const onSubmitCallback = async inputs => {
      try {
        setLoading(1);
        let result = await submitFormData(userInfo.UserId, inputs);
        toast.success(
          context.t(
            'Your data has been registered successfully, in a maximum period of 72 hours you will receive\n' +
              'An email with the response to your request.'
          ),
          {
            position: toast.POSITION.TOP_CENTER
          }
        );
      } catch (error) {
        toast.warn(context.t('Information could not be saved'), {
          position: toast.POSITION.TOP_CENTER
        });
      }
      setLoading(0);
    };

    const handleCountryChange = async event => {
      event.persist();
      setInputs(inputs => ({
        ...inputs,
        country: event.target.value
      }));
    };

    const customValidations = inputs => {
      let errors = {};

      return errors;
    };

    let initialState = {
      validated: false,
      banexcoin_user_id: userInfo.UserId,
      banexcoin_user_name: userInfo.UserName
    };

    let {
      inputs,
      errors,
      setInputs,
      onInputChange,
      onSubmit,
      onInputChangeByName,
      onInputFileChange
    } = useForm(initialState, onSubmitCallback, customValidations);

    return userInfo.Use2FA ? (
      <React.Fragment>
        <Loading loading={loading} />
        <div className={intermediateLevelPageClasses()}>
          <h1>{context.t('Add New Company as Shareholder')}</h1>
          <p>
            {context.t(
              'To raise the user level you must fill out the form below.'
            )}
          </p>

          <Form
            noValidate
            validated={inputs.validated}
            encType="multipart/form-data"
            onSubmit={onSubmit}>
            <Row>
              <Col xl="12">
                <div className="form-group">
                  <Form.Text className="text-muted">
                    * {context.t('Required fields')}
                  </Form.Text>
                </div>
              </Col>
            </Row>
            <div className={'daas-step-box ' + (showed[0] ? 'showed' : '')}>
              <div className="cloud">
                <div className="circle ok">1</div>
                <div className="circle">2</div>
                <div className="circle">3</div>
              </div>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="identification_number">
                    <Form.Label>
                      {context.t('Identification number')}{' '}
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="identification_number"
                      type="number"
                      defaultValue={inputs.identification_number || ''}
                      onChange={onInputChange}
                      placeholder={context.t('Identification number')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the identification number')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="company_name">
                    <Form.Label>
                      {context.t('Company name')}{' '}
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="company_name"
                      defaultValue={inputs.company_name || ''}
                      type="text"
                      onChange={onInputChange}
                      placeholder={context.t('Company name')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must put the company name')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="country" title="Company country">
                    <Form.Label>{context.t('Company Country')}</Form.Label>
                    <Countries
                      nombre={'country'}
                      dv={inputs.country || ''}
                      countries={countries}
                      onChange={onInputChange}
                      handleCountryChange={handleCountryChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12" className="footer-form daas-footer">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      markStep(1, true);
                    }}
                    style={{ padding: '1em' }}>
                    {context.t('Next')}
                  </Button>
                </Col>
              </Row>
            </div>
            <div className={'daas-step-box ' + (showed[1] ? 'showed' : '')}>
              <div className="cloud">
                <div className="circle">1</div>
                <div className="circle ok">2</div>
                <div className="circle">3</div>
              </div>
              <Row className="daas-descript-title">
                <Col xl="12">
                  <div className="header-titlesub">
                    {context.t('Percentage Number %')}{' '}
                    <span className="asterisk">*</span>
                  </div>
                </Col>
              </Row>
              <Row className="daas-descript-icon">
                <Col xl="12">
                  <img src={SharePercentage} alt="Shareholder percentage" />
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="percentage_number">
                    <div className="parent-inline">
                      <Form.Control
                        required
                        name="percentage_number"
                        defaultValue={inputs.percentage_number || ''}
                        type="text"
                        onChange={onInputChange}
                        placeholder="40"
                      />
                      <Form.Label className="percentage-inline"> %</Form.Label>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the Percentage Number')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12" className="footer-form daas-footer">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      markStep(0, true);
                    }}
                    style={{ padding: '1em' }}>
                    {context.t('Back')}
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      markStep(2, true);
                    }}
                    style={{ padding: '1em' }}>
                    {context.t('Next')}
                  </Button>
                </Col>
              </Row>
            </div>
            <div className={'daas-step-box ' + (showed[2] ? 'showed' : '')}>
              <div className="cloud">
                <div className="circle">1</div>
                <div className="circle">2</div>
                <div className="circle ok">3</div>
              </div>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="address_main">
                    <Form.Label>
                      {context.t('Main address')}{' '}
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="address_main"
                      defaultValue={inputs.address_main || ''}
                      type="text"
                      onChange={onInputChange}
                      placeholder={context.t('Main address')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the main address')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="address_billing">
                    <Form.Label>
                      {context.t('Billing Address')}{' '}
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="address_billing"
                      defaultValue={inputs.address_billing || ''}
                      type="text"
                      onChange={onInputChange}
                      placeholder={context.t('Billing Address')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the billing address')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="phone">
                    <Form.Label>
                      {context.t('Phone')} <span className="asterisk">*</span>
                    </Form.Label>
                    <PhoneInput
                      inputClass={'form-pay-phone-custom'}
                      onChange={value => {
                        onInputChangeByName('phone', value);
                      }}
                      value={inputs.phone || ''}
                      inputExtraProps={{
                        id: 'phone',
                        name: 'phone',
                        requiredmessage: context.t(
                          'You must enter the phone number'
                        ),
                        required: true
                      }}
                      // isValid={value => {
                      //   if (this.phone) {
                      //     const {
                      //       selectedCountry: { format, dialCode },
                      //       formattedNumber
                      //     } = this.phone.state;
                      //     return format.length === formattedNumber.length;
                      //   }
                      // }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the phone number')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Form.Group controlId="email">
                    <Form.Label>
                      {context.t('Email')} <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="email"
                      type="email"
                      defaultValue={inputs.email || ''}
                      onChange={onInputChange}
                      placeholder={context.t('Email')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('You must enter the email')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl="12" className="footer-form daas-footer">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      markStep(1, true);
                    }}
                    style={{ padding: '1em' }}>
                    {context.t('Back')}
                  </Button>
                  {/* <Button
                    variant="primary"
                    type="submit"
                    style={{ padding: '1em', width: '20%' }}>
                    {context.t('Save and add another')}
                  </Button> */}
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ padding: '1em' }}>
                    {context.t('Save and Go Back')}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={intermediateLevelPageClasses()}>
          <div className="error-accessing-message">
            <Link to="/settings/user">
              <img src={RobotSvg} className=" roboto" alt="roboto" />
              <div className="text">
                {context.t('Enable 2FA to use this functionality')}
                <button type="button">{context.t('Activate it here')}</button>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) {
    console.log('error interno', e);
    return <div>Error:</div>;
  }
};

CorporateInstitutionForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  user: { selectedAccountId, accounts, userInfo }
}) => {
  return { selectedAccountId, accounts, userInfo };
};

export default connect(mapStateToProps)(CorporateInstitutionForm);
