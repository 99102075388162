import React, { useEffect, useState } from "react";
import empty from "is-empty";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";
import "./modaldepositWithdraw.css";
import closeIcon from '../../../images/icons/icon-close.svg';
import { FormatDecimalCoinkWithSimbols, ticksToDateString } from "../../utils";
import { formatNumberToLocale } from "../../../helpers/numberFormatter";
import iso from '../../../images/iso-bnx.svg';

const Modal2fa = (props, context) => {
  const { show, setShow, details, actionName } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateForm, setTemplateForm] = useState({});
  const [withdrawTransactionTemplate, setWithdrawTransactionTemplate] = useState({});

  const handleClick = async () => {
    handleClose()
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setLoading(false);
  };

  const fullDate = ticksToDateString(details.createdOnTicks);

  const setDetailsTemplate = (details) => {
    try {
      setTemplateForm(JSON.parse(details.templateForm));
    } catch (error) {
      
    }
    try {
      setWithdrawTransactionTemplate(JSON.parse(details.withdrawTransactionDetails));
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(details) setDetailsTemplate(details);
  }, [details])
  
  return (
    <React.Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="modal-bnx-v2 lighting modal-disclaimer modal-2fa"
      >
        <Modal.Header className="modal-2fa-header">
          <img onClick={handleClose} src={closeIcon} style={{ filter: "invert(1)" }} />
        </Modal.Header>
        <Modal.Body className="modal-2fa-body ticket-detail-v2" style={{
          backgroundImage: `url(${iso})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '30%',
        }}>
          <h3 style={{textAlign: 'center'}}>{context.t(details.statusName)}</h3>
          <h3 style={{textAlign: 'center'}}>{FormatDecimalCoinkWithSimbols(formatNumberToLocale(details.amount, (details.product ? details.product.DecimalPlaces : 2)), (details.product ? details.product.Product : 'USD'))}</h3>
          <Row>
            <Col xl="6" className="text-left side-currency-title">
              {context.t("{action} Details", { action: context.t(actionName) })}
            </Col>
            <Col xl="6" className="text-right">
              <span className="side-currency">{context.t("{currency} Withdrawal", { currency: details.symbol })}</span>
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="text-left">
              {context.t("Date")}
            </Col>
            <Col xl="6" className="text-right">
              {fullDate.split(" ")[0]}
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="text-left">
              {context.t("Time")}
            </Col>
            <Col xl="6" className="text-right">
              {fullDate.split(" ")[1]}
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="text-left">
              {context.t("Currency")}
            </Col>
            <Col xl="6" className="text-right">
              {details.assetName}
            </Col>
          </Row>
          {Number(details.feeAmount || 0) > 0 && <Row>
            <Col xl="6" className="text-left">
              {context.t("Fee")}
            </Col>
            <Col xl="6" className="text-right">
              {FormatDecimalCoinkWithSimbols(formatNumberToLocale(details.feeAmount, (details.product ? details.product.DecimalPlaces : 2)), (details.product ? details.product.Product : 'USD'))}
            </Col>
          </Row>}
          {templateForm && (
            <>
              {!empty(templateForm.Comment) && <Row>
                <Col xl="6" className="text-left">
                  {context.t("Note")}
                </Col>
                <Col xl="6" className="text-right">
                  {templateForm.Comment}
                </Col>
              </Row>}
              {!empty(templateForm.bankNameSource) && <Row>
                <Col xl="6" className="text-left">
                  {context.t("Destionation bank")}
                </Col>
                <Col xl="6" className="text-right">
                  {templateForm.bankNameSource}
                </Col>
              </Row>}
              {!empty(templateForm.accountSource) && <Row>
                <Col xl="6" className="text-left">
                  {context.t("Account number")}
                </Col>
                <Col xl="6" className="text-right">
                  {templateForm.accountSource}
                </Col>
              </Row>}
              {!empty(templateForm.ExternalAddress) && details.product && details.product.ProductType == "CryptoCurrency" && <Row>
                <Col xl="6" className="text-left">
                  {context.t("Address")}
                </Col>
                <Col xl="6" className="text-right">
                  {templateForm.ExternalAddress}
                </Col>
              </Row>}
            </>
          )}
          {withdrawTransactionTemplate && details.product && details.product.ProductType == "CryptoCurrency" && (
            <>
              {!empty(withdrawTransactionTemplate.TxId) && <Row>
                <Col xl="6" className="text-left">
                  {context.t("Tx ID")}
                </Col>
                <Col xl="6" className="text-right">
                  {withdrawTransactionTemplate.TxId}
                </Col>
              </Row>}
            </>
          )}
          <img
            src="/local/logos/logo-banexcoin-white-middleorange.svg"
            className="logo-v2"
            style={{
              width: "40%",
              margin: "10px auto 5px auto",
              display: "block",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClick} disabled={loading}>
            {context.t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

Modal2fa.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    user: { userInfo },
  } = state;
  return { userInfo };
};

export default connect(mapStateToProps, null)(Modal2fa);
